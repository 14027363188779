import { userInitialState } from "./InitialState";
import { userTypes } from "./Types";

export const UserReducer = (state = userInitialState, { payload, type }) => {
  switch (type) {
    case userTypes.SET_USER_DATA:
      return {
        ...state,
        user: payload, 
      };
    case userTypes.SET_USER_IMAGE:
      return {
        ...state,
        userImage: payload,
      };

    default:
      break;
  }
};
