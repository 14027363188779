import React, {useState} from "react";
import {Card, Dots, Fade, Modal, PageTopbar} from "../../components";
import {farsiDigitToEnglish} from "../../utils/Functions";

export const IntroduceNumberContainer = () => {
  const [ContactsS, setContactsS] = useState<any>();
  const [support, setSupport] = useState('contacts' in navigator && 'ContactsManager' in window);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [loading, setLoading] = useState(false);
  const [phoneNumbersModal, setPhoneNumbersModal] = useState(false);

  if (support) {
    checkProperties();
  }

  async function checkProperties() {
    // @ts-ignore
    const supportedProperties = await navigator.contacts.getProperties();
    if (!supportedProperties.includes('tel')) {
      setSupport(false);
    }
  }

  async function getContacts() {
    const props = [];
    props.push('tel');
    const opts = {multiple: true};

    try {
      // @ts-ignore
      const contacts = await navigator.contacts.select(props, opts);
      setContactsS(contacts);
    } catch (ex) {
    }

  }

  //
  // function handleResults(contacts) {
  //   ulResults.classList.toggle('success', true);
  //   ulResults.classList.toggle('error', false);
  //   ulResults.innerHTML = '';
  //   renderResults(contacts);
  // }
  //
  // function enableProp(cbox) {
  //   cbox.removeAttribute('disabled');
  //   cbox.setAttribute('checked', 'checked');
  // }
  //
  // function renderResults(contacts) {
  //   contacts.forEach((contact) => {
  //     const lines = [];
  //     if (contact.name) lines.push(`<b>Name:</b> ${contact.name.join(', ')}`);
  //     if (contact.email) lines.push(`<b>E-mail:</b> ${contact.email.join(', ')}`);
  //     if (contact.tel) lines.push(`<b>Telephone:</b> ${contact.tel.join(', ')}`);
  //     if (contact.address) {
  //       contact.address.forEach((address) => {
  //         lines.push(`<b>Address:</b> ${JSON.stringify(address)}`);
  //       });
  //     }
  //     if (contact.icon) {
  //       contact.icon.forEach((icon) => {
  //         const imgURL = URL.createObjectURL(icon);
  //         lines.push(`<b>Icon:</b> <img src="${imgURL}">`);
  //       });
  //     }
  //     lines.push(`<b>Raw:</b> <code>${JSON.stringify(contact)}</code>`);
  //     const li = document.createElement('li');
  //     li.innerHTML = lines.join('<br>');
  //     ulResults.appendChild(li);
  //   });
  //   const strContacts = JSON.stringify(contacts, null, 2);
  //   console.log(strContacts);
  // }

  return (
    <Fade>
      <PageTopbar title={'معرفی کاربر'} showKv={false}/>
      <Modal active={phoneNumbersModal} close={() => setPhoneNumbersModal(false)}>
        <div className="phone-modal">
          <div className="title">
            شماره های انتخاب شده:
          </div>
          <div className="phone-modal__list">
            {ContactsS && ContactsS.map(item => (
              <div className="phone-modal__item">
                09140310200
              </div>
            ))}
          </div>
        </div>
      </Modal>
      <div className="wrapper-fix">
        <Card>
          <>
            <div className="title">شماره موبایل</div>
            <div className="row">
              <input
                type="text"
                className="input"
                value={phoneNumber}
                onChange={(e) =>
                  setPhoneNumber(farsiDigitToEnglish(e.target.value))
                }
                placeholder="09123456789"
              />
              <div
                className={`create-shop__inviter__button ${phoneNumber.length === 0 && "hidden"}`}
                onClick={() => {
                }}
              >
                {loading ? (
                  <Dots color="light"/>
                ) : (
                  "ثبت"
                )}
              </div>
            </div>
          </>

          <div className={`button ${!support && 'disable'}`} onClick={getContacts}>افزودن از مخاطبین</div>
        </Card>
        <Card>
          <div className="col">
            <div className="title">شماره‌های افزوده شده:</div>
          </div>
        </Card>
      </div>
    </Fade>
  );
};