import React, {useState, useRef, useEffect} from "react";
import {
  PageTopbar,
  SVG,
  Selectbox,
  Modal,
  Fade,
  Dots,
  Spinner,
} from "components";
import {submitContact, getAllContacts, deleteContact} from "utils/Services";
import toast from "react-hot-toast";
import {useHistory, useLocation} from "react-router-dom";
import {farsiDigitToEnglish} from "utils/Functions";

export const SubmitContacts = () => {
  const phoneRef = useRef(null);
  const [modalStatus, toggleModalStatus] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingDelete, setLoadingDelete] = useState({status: false, id: 0});
  const [contacts, setContacts] = useState([]);
  const history = useHistory();
  const location = useLocation();

  function useQuery() {
    const {search} = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  let query = useQuery();

  const handleSubmit = () => {
    const phoneValue = phoneRef.current.value;
    if (phoneValue.length !== 11) {
      toast.error("فرمت شماره را درست وارد نمایید");
      return;
    }
    if (phoneValue == "") {
      toast.error("شماره تماس را وارد نمایید");
      return;
    }
    if (contacts.length == 5) {
      toast.error("تنها 5 شماره می‌توانید وارد کنید");
      return;
    }
    setLoadingSubmit(true);
    submitContact(phoneValue)
      .then((res) => {
        if (res) {
          toast.success("شماره تماس با موفقیت اضافه شد");
          getContactsNumber();
          phoneRef.current.value = "";
          setTimeout(() => {
            toggleModalStatus(false);
          }, 100);
        }
      })
      .catch((err) => {
        if (err) {
          toast.error("به مشکل برخوردیم !");
        }
      })
      .finally(() => setLoadingSubmit(false));
  };

  const getContactsNumber = () => {
    setLoading(true);
    getAllContacts()
      .then((res) => {
        if (res) {
          setContacts(res.data);
        }
      })
      .catch((err) => {
        if (err) {
          toast.error("به مشکل برخوردیم !");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDelete = (id) => {
    setLoadingDelete({...loadingDelete, status: true, id: id});
    deleteContact(id)
      .then((res) => {
        if (res) {
          toast.success("شماره تماس با موفقیت حذف شد");
          setTimeout(() => {
            getContactsNumber();
          }, 500);
        }
      })
      .catch((err) => {
        if (err) toast.error("به مشکل برخوردیم");
      })
      .finally(() => {
        setLoadingDelete({...loadingDelete, status: false, id: id});
      });
  };

  useEffect(() => {
    getContactsNumber();
  }, []);

  useEffect(() => {
    history.replace("/myshop/contact" + location.search);
  }, []);

  return (
    <Fade>
      <div className="creation-donate mycity wrapper-fix">
        <PageTopbar title="ثبت فروشگاه" showKv={false}/>
        <div className="creation-donate__wrapper">
          <div className="creation-donate__title">
            <span>اطلاعات تماس</span>
          </div>
          <div
            onClick={() => toggleModalStatus(true)}
            className="creation-donate__condition"
          >
            <span>افزودن شماره تماس</span>
            <SVG svg="km-phone"/>
          </div>
          <div className="creation-contact">
            {loading ? (
              <Dots color="dark"/>
            ) : contacts.length == 0 ? (
              <div className="row w-full j-c">
                <span>
                  آیتمی برای نمایش وجود ندارد
                </span>
              </div>
            ) : (
              contacts.map((item, index) => (
                <div key={index} className="creation-contact__item">
                  {item.num}

                  {query.get('edit') === 'true' && (
                    loadingDelete.status && loadingDelete.id == item.id ? (
                      <Dots color="dark"/>
                    ) : (
                      <div
                        className="row a-c"
                        onClick={() => handleDelete(item.id)}
                      >
                        <SVG svg="delete-contact"/>
                      </div>
                    ))}
                </div>
              ))
            )}
          </div>
        </div>
        <Modal
          active={modalStatus}
          close={() => {
            toggleModalStatus(false);
            phoneRef.current.value = "";
          }}
        >
          <div className="creation-donate__modal">
            <div className="creation-donate__modal__header">
              افزودن شماره تماس
            </div>
            <input
              type="tel"
              ref={phoneRef}
              className="input"
              placeholder="شماره تماس خود را وارد نمایید"
              onChange={(e) => (phoneRef.current.value = farsiDigitToEnglish(e.target.value))}
            />
            <div
              onClick={() => handleSubmit()}
              className="creation-donate__action"
            >
              {loadingSubmit ? (
                <Dots color="light"/>
              ) : (
                <>
                  <span>ثبت شماره</span>
                  <SVG svg="km-phone"/>
                </>
              )}
            </div>
          </div>
        </Modal>
      </div>
    </Fade>
  );
};
