import { Card, CheckBox, Dots, Fade, PageTopbar } from "../../components";
import { Link, useHistory, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
    getMyUnitInfoAPI,
    removeFromSaleAPI,
    sellMyUnitAPI,
} from "../../utils/Services/KartlandUnits";
import toast from "react-hot-toast";
import { farsiDigitToEnglish } from "utils/Functions";
import { getHintAPI } from "utils";

export const KartlandSellUnitContainer = () => {
    const [submitLoading, setSubmitLoading] = useState(false);
    const [sellingPrice, setSellingPrice] = useState("");
    const [showSellingUnit, setShowSellingUnit] = useState(true);
    const [unitDetails, setUnitDetails] = useState<any>();
    const [unitDetailsLoading, setUnitDetailsLoading] = useState(true);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [hint, setHint] = useState({
        title: "",
        content: "",
    });
    const params: any = useParams();
    const history = useHistory();

    const getMyUnitInfo = () => {
        setUnitDetailsLoading(true);
        getMyUnitInfoAPI(params.id).then((res) => {
            if (res.status === 200) {
                setUnitDetails(res.data);
                setShowSellingUnit(res.data.display);
                setUnitDetailsLoading(false);
                if (res.data.isSold) {
                    setSellingPrice(res.data.sellPrice);
                }
            }
        });
    };

    const deleteSell = () => {
        setDeleteLoading(true);
        removeFromSaleAPI(params.id)
            .then((res) => {
                if (res.status === 200) {
                    toast.success("فروش ویترین لغو شد");
                    setDeleteLoading(false);
                    history.goBack();
                    getMyUnitInfo();
                }
            })
            .catch((err) => {
                toast.error("لغو فروش ویترین با مشکل مواجه شد");
            });
    };

    const booleanHanlder = (value) => {
        switch (value) {
            case 1:
            case true:
                return "1";
            case 0:
            case false:
                return "0";
        }
    };

    const sellMyUnit = () => {
        if (sellingPrice === "") {
            toast.error("قیمت مورد نظر را وارد کنید");
            return;
        }
        setSubmitLoading(true);
        sellMyUnitAPI(params.id, sellingPrice, booleanHanlder(showSellingUnit))
            .then((res) => {
                if (res.status === 200) {
                    toast.success("واحد برای فروش قرار گرفت");
                    setSubmitLoading(false);
                    history.goBack();
                }
            })
            .catch((err) => {
                const error = err.response.data.data[0].price[0]
                    .match(/\d/g)
                    .join("");
                if (error) {
                    toast.error(`حداقل قیمت فروش واحد ${error} تومان است`);
                } else {
                    toast.error("فروش واحد با مشکل مواجه شد");
                }
                setSubmitLoading(false);
            });
    };

    const getHint = () => {
        getHintAPI("متن ثابت صفحه فروش واحدهای کاربران")
            .then((response) => {
                setHint(response);
            })
            .catch(() => {})
            .finally(() => {});
    };

    useEffect(() => {
        getMyUnitInfo();
        getHint();
        history.replace(`/kartland/sell/${params.id}`);
    }, []);

    return (
        <Fade>
            <PageTopbar title="فروش ویترین" showKv={false} />
            <Card>
                <div className="kartland-sell">
                    <div className="kartland-sell__price">
                        <div className="title">
                            قیمت مورد نظر برای فروش (تومان)
                        </div>
                        <input
                            className={"input"}
                            value={sellingPrice}
                            onChange={(e) =>
                                setSellingPrice(
                                    farsiDigitToEnglish(e.target.value)
                                )
                            }
                            style={{ marginBottom: 0 }}
                        />
                        <div
                            style={{ fontSize: "0.7rem", color: "#5e61ed" }}
                            className="input__hint"
                        >
                            {hint.content}
                        </div>
                    </div>
                    {/* <div className="kartland-sell__public">
            <input
              type="checkbox"
              id={"public"}
              className=""
              name="public"
              checked={showSellingUnit}
              onChange={() => setShowSellingUnit(!showSellingUnit)} />
            <label className={"title"} htmlFor="public">واحد را در فروشگاه کاربران نشان بده</label>
          </div> */}
                </div>
            </Card>

            <div className={"w-full bottom-16"}>
                <div
                    className="button mb-8 width-full-16"
                    onClick={() => !submitLoading && sellMyUnit()}
                >
                    {submitLoading ? <Dots color="light" /> : "ثبت"}
                </div>

                {unitDetails?.isSold === 1 && (
                    <div
                        className="button red width-full-16"
                        onClick={() => !deleteLoading && deleteSell()}
                    >
                        {deleteLoading ? <Dots color="light" /> : "لغو فروش"}
                    </div>
                )}
            </div>
        </Fade>
    );
};
