import {Fade, Spinner, SVG} from "components";
import {useEffect, useState} from "react";
import {useHistory, useLocation} from "react-router";
import Slider from "react-slick";
import welcomeImage from "../../assets/images/intro/welcome.svg";
import propertyImage from "../../assets/images/intro/property.svg";
import personalImage from "../../assets/images/intro/personal.svg";
import goalImage from "../../assets/images/intro/goal.svg";
import adsImage from "../../assets/images/intro/ads.svg";
import startImage from "../../assets/images/intro/start.svg";
import {getHintAPI} from "utils";

export const IntroContainer = () => {
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const introSeen = localStorage.getItem("introSeen");
  const [slider, setSlider] = useState(null);
  const location = useLocation();
  const [step, setStep] = useState(1);
  const [hint1, setHint1] = useState<any>({
    title: '',
    content: ''
  });
  const [hint2, setHint2] = useState<any>({
    title: '',
    content: ''
  });
  const [hint3, setHint3] = useState<any>({
    title: '',
    content: ''
  });
  const [hint4, setHint4] = useState<any>({
    title: '',
    content: ''
  });
  const [hint5, setHint5] = useState<any>({
    title: '',
    content: ''
  });
  const [hint6, setHint6] = useState<any>({
    title: '',
    content: ''
  });

  const introData = [
    {
      title: hint1.title,
      description: (
        <div>
          {hint1.content}
        </div>
      ),
      image: welcomeImage,
    },
    {
      title: hint2.title,
      description: (
        <div>
          {hint2.content}
        </div>
      ),
      image: propertyImage,
    },
    {
      title: hint3.title,
      description: (
        <div>
          {hint3.content}
        </div>
      ),
      image: personalImage,
    },
    {
      title: hint4.title,
      description: (
        <div>
          {hint4.content}
        </div>
      ),
      image: goalImage,
    },
    {
      title: hint5.title,
      description: (
        <div>
          {hint5.content}
        </div>
      ),
      image: adsImage,
    },
    {
      title: hint6.title,
      description: (
        <div>
          {hint6.content}
        </div>
      ),
      image: startImage,
    },
  ];

  const handleChangeSteps = () => {
    if (step === introData.length) {
      history.goBack();
      return;
    }
    slider.slickGoTo(step);
    setStep((prev) => prev + 1);
  };

  const getHint1 = () => {
    getHintAPI('اسلایدهای اول برنامه اسلاید 1')
      .then(response => {
        setHint1(response);
      })
      .catch(() => setHint1(false))
      .finally(() => {
      });
  };

  const getHint2 = () => {
    getHintAPI('اسلایدهای اول برنامه اسلاید 2')
      .then(response => {
        setHint2(response);
      })
      .catch(() => setHint2(false))
      .finally(() => {
      });
  };

  const getHint3 = () => {
    getHintAPI('اسلایدهای اول برنامه اسلاید 3')
      .then(response => {
        setHint3(response);
      })
      .catch(() => setHint3(false))
      .finally(() => {
      });
  };

  const getHint4 = () => {
    getHintAPI('اسلایدهای اول برنامه اسلاید 4')
      .then(response => {
        setHint4(response);
      })
      .catch(() => setHint4(false))
      .finally(() => {
      });
  };

  const getHint5 = () => {
    getHintAPI('اسلایدهای اول برنامه اسلاید 5')
      .then(response => {
        setHint5(response);
      })
      .catch(() => setHint5(false))
      .finally(() => {
      });
  };

  const getHint6 = () => {
    getHintAPI('اسلایدهای اول برنامه اسلاید 6')
      .then(response => {
        setHint6(response);
      })
      .catch(() => setHint6(false))
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (introSeen !== null && location.search !== "?show") {
      history.push("/");
    } else {
      localStorage.setItem("introSeen", "true");
    }
    getHint1();
    getHint2();
    getHint3();
    getHint4();
    getHint5();
    getHint6();
  }, []);

  useEffect(() => {
    history.replace("/intro" + history.location.search);
  }, []);


  return (
    <Fade>
      <Spinner active={loading} color={'dark'}/>
      <div className="intro">
        <div className="intro__slider">
          <Slider
            arrows={false}
            infinite={false}
            dots={true}
            afterChange={(index) => setStep(index + 1)}
            ref={(slider) => setSlider(slider)}
            speed={50}
          >
            {introData.map((introItem, index) => (
              <div className={`intro__item ${step === index + 1 && "active"}`}>
                <img src={introItem.image} alt=""/>

                <div className="intro__item__title">{introItem.title}</div>

                <div className="intro__item__description">
                  {introItem.description}
                </div>
              </div>
            ))}
          </Slider>
        </div>

        <div className="intro__button" onClick={() => handleChangeSteps()}>
          <div className="intro__button__shape">
            <SVG svg="bottom-shape"/>
          </div>

          {step !== introData.length ? "صفحه بعدی" : "بزن بریم!"}
        </div>
      </div>
    </Fade>
  );
};
