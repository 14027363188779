import "./Spinner.scss";

export const Dots = ({color = "dark"}) => {
  return (
    <div className={`spinners ${color}`}>
      <div className="spinners__item1" />
      <div className="spinners__item2" />
      <div className="spinners__item3" />
    </div>
  );
};
