import StickerImage from '../assets/images/stickers/flowers.png';
import HeartImage from '../assets/images/stickers/heart.png';
import QuestionImage from '../assets/images/stickers/question.png';
import AngryImage from '../assets/images/stickers/angry.png';
import HelloImage from '../assets/images/stickers/hello.png';

export const stickersImages = [
  {
    src: StickerImage,
    title: '[flowers]'
  },
  {
    src: HeartImage,
    title: '[heart]'
  },
  {
    src: QuestionImage,
    title: '[question]'
  },
  {
    src: AngryImage,
    title: '[angry]'
  },
  {
    src: HelloImage,
    title: '[hello]'
  },
];

export const returnStickerImage = sticker => {
  switch (sticker) {
    case '[flowers]':
      return stickersImages[0].src;

    case '[heart]':
      return stickersImages[1].src;

    case '[question]':
      return stickersImages[2].src;

    case '[angry]':
      return stickersImages[3].src;

    case '[hello]':
      return stickersImages[4].src;
  
    default:
      break;
  }
}