import {Card, Dots, Fade, NotFoundBox, PageTopbar, SVG} from 'components';
import {Tabs} from 'containers/OrdersContainer/dependencies';
import moment from 'jalali-moment';
import {useEffect, useState} from 'react';
import {payRequestAPI} from 'utils';
import {currency} from 'utils/Functions';
import {useHistory} from "react-router-dom";

export const CashPaymentHistoryContainer = () => {
  const [loading, setLoading] = useState(true);
  const [cashHistory, setCashHistory] = useState([]);
  const [activeTab, setActiveTab] = useState(1);
  const history = useHistory();

  const tabs = [
    {
      title: 'فعال',
      icon: 'checkout-active'
    },
    {
      title: 'درحال بررسی',
      icon: 'checkout-doing'
    },
    {
      title: 'موفق',
      icon: 'checkout-success'
    },
    {
      title: 'بازگشتی',
      icon: 'checkout-moneyBack'
    },
  ];

  const getCashHistory = (type) => {
    payRequestAPI(type)
      .then(response => {
        setCashHistory(response.data);
      })
      .catch(err => {
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleTabType = () => {
    switch (activeTab) {
      case 1:
        return 'active';

      case 2:
        return 'doing';

      case 3:
        return 'success';

      case 4:
        return 'moneyBack';

      default:
        break;
    }
  };

  useEffect(() => {
    switch (activeTab) {
      case 1:
        getCashHistory('active');
        break;

      case 2:
        getCashHistory('doing');
        break;

      case 3:
        getCashHistory('success');
        break;

      case 4:
        getCashHistory('moneyBack');
        break;

      default:
        break;
    }
  }, [activeTab]);

  useEffect(() => {
    history.replace("/cash/history");
  }, []);

  return (
    <Fade>
      <PageTopbar title="تاریخچه برداشت" showKv={false}/>
      <Card>
        <div className="mycity">
          <Tabs items={tabs} activeTab={activeTab} setActiveTab={tab => {
            setLoading(true);
            setActiveTab(tab);
          }}/>
        </div>
        {loading ? (
          <div style={{margin: '200px auto'}}>
            <Dots color="dark"/>
          </div>
        ) : (
          <div className="cash-history__checkout">
            {cashHistory.length === 0 ? (
              <div style={{marginTop: 200}}>
                <NotFoundBox title="تاریخچه‌ای یافت نشد" icon={`checkout-${handleTabType()}`}/>
              </div>
            ) : (
              cashHistory.map((cashHistoryItem, index) => (
                <div className="cash-history__checkout__item" key={index}>
                  <div className="row a-c j-sb">
                    <div className="row a-c j-sb">
                      <div className={`cash-history__checkout__circle ${handleTabType()}`}>
                        <SVG svg={`checkout-${handleTabType()}`}/>
                      </div>
                      <div className="cash-history__checkout__item__price">
                        {currency(cashHistoryItem.amount)} تومان
                      </div>
                    </div>

                    <div className="cash-history__checkout__item__date">
                      {moment(cashHistoryItem.created_at, "YYYY/MM/DD HH:mm:ss")
                        .locale("fa")
                        .format("HH:mm | YYYY/MM/DD")}
                    </div>
                  </div>

                  {cashHistoryItem.comment !== null && (
                    <div className="cash-history__checkout__item__info">
                      {cashHistoryItem.comment}
                    </div>
                  )}

                  {cashHistoryItem.bankRefId !== null && (
                    <div className="cash-history__checkout__item__info">
                      <div>
                        کد پیگیری:
                      </div>
                      {cashHistoryItem.bankRefId}
                    </div>
                  )}

                  {cashHistoryItem.shebaId !== null && (
                    <div className="cash-history__checkout__item__info">
                      <div>
                        شماره شبا:
                      </div>
                      IR-{cashHistoryItem.shebaId}
                    </div>
                  )}

                  {cashHistoryItem.shebaName !== null && (
                    <div className="cash-history__checkout__item__info">
                      <div>
                        صاحب سپرده:
                      </div>
                      {cashHistoryItem.shebaName}
                    </div>
                  )}

                  {cashHistoryItem.depositId !== null && (
                    <div className="cash-history__checkout__item__info">
                      <div>
                        شماره تراکنش:
                      </div>
                      {cashHistoryItem.depositId}
                    </div>
                  )}

                </div>
              ))
            )}
          </div>
        )}
      </Card>
    </Fade>
  );
};