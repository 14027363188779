import { Card, SVG, Lowprice, Modal } from "components";
import { transformPrice } from "utils/Functions";
import { useUser } from "hooks";
import { Link, useHistory } from "react-router-dom";
import toast from "react-hot-toast";
import React, { useState } from "react";

export const Variation = ({
    colors,
    active,
    setActiveVariation,
    variationStatus,
    toggleShowVariation,
    setVariation,
    productVariations,
    toggleShowTokenPrice,
    setTokenPriceType,
    handleShowVariations,
    handleAvailableVariation,
    setShowKvInfo,
    setShowKvInfoPrice,
    seller,
    sevenStarUserPrice,
    captainUserPrice,
    citizenUserPrice,
    normalUserPrice,
    pid,
}) => {
    const {
        userState: { user },
    } = useUser();
    const history = useHistory();
    const user_token = localStorage.getItem("user_token");
    const [modals, setModals] = useState({
        price: false,
        getKv: false,
        kvAssign: false,
    });

    return (
        <React.Fragment>
            <Modal
                active={modals.price}
                close={() => {
                    setModals({ ...modals, price: false });
                }}
                hint="price"
            >
                <div className="modal-hint__title">
                    <span style={{ color: "#000000", marginLeft: 4 }}>
                        کاربر گرامی،
                    </span>
                    {user.detail?.fullName}
                </div>

                <div className="modal-hint__description">
                    {active.priceList?.map(
                        (star, index) =>
                            index + 2 > user?.detail?.star &&
                            (index + 1 === user?.detail?.star ? (
                                <React.Fragment>
                                    <div className="modal-hint__description__you">
                                        <span className="ico">
                                            <SVG svg="lamp" />
                                        </span>
                                        شما
                                        <span className="bold-yellow">
                                            {" " +
                                                user?.detail?.star +
                                                " ستاره "}
                                        </span>
                                        هستید و قیمت این کالا براتون میشه
                                        <span className="bold-red">
                                            {" " +
                                                transformPrice(star.price) +
                                                " تومان"}
                                        </span>
                                        . بعد از خرید هم می‌تونید با آزاد سازی
                                        یک KV مبلغ
                                        <span className="bold-purple">
                                            {" " +
                                                transformPrice(star.kv) +
                                                " تومان "}
                                        </span>
                                        هدیه نقدی دریافت کنید.
                                        {/* <div
                      className="modal-hint__more nonean"
                      onClick={() => { setModals({ ...modals, getKv: true }) }}
                      style={{ borderBottom: 'none', marginBottom: 0 }}
                    >
                      <SVG svg="hint-more" />
                      چطوری می‌تونم KV آزاد کنم؟
                    </div> */}
                                    </div>
                                </React.Fragment>
                            ) : (
                                <div>
                                    <span className="lmp">
                                        <SVG svg="lamp-off" />
                                    </span>
                                    اگه
                                    <span className="bold-yellow">
                                        {" " + (index + 1) + " ستاره "}
                                    </span>
                                    بشی قیمت براتون میشه
                                    <span className="bold-red">
                                        {" " +
                                            transformPrice(star.price) +
                                            " تومان "}
                                    </span>
                                    بعد از آزاد سازی KV هم
                                    <span className="bold-purple">
                                        {" " +
                                            transformPrice(star.kv) +
                                            " تومان "}
                                    </span>
                                    هدیه می‌گیرید.
                                </div>
                            ))
                        // <div
                        //   className={`token-price__item ${index === user?.detail?.star - 1 && "active"
                        //     }`}
                        //   key={index}
                        // >
                        //   {/* {handleShowInfo(type, index)} */}
                        // </div>
                    )}
                </div>
                <div
                    className="modal-hint__more nonean"
                    onClick={() => {
                        setModals({ ...modals, getKv: true });
                    }}
                    style={{}}
                >
                    <SVG svg="hint-more" />
                    چطوری می‌تونم KV آزاد کنم؟
                </div>
                <div
                    className="modal-hint__more__link"
                    onClick={() => history.push("/profile")}
                >
                    {/* <SVG svg="hint-more" />  */}
                    چطوری ‌می‌تونم ستاره بگیرم؟
                </div>
            </Modal>

            <Modal
                active={modals.getKv}
                close={() => {
                    setModals({ ...modals, getKv: false });
                }}
                hint="getKv"
            >
                <div className="modal-hint__title">راهنمای آزادسازی KV</div>

                <div className="modal-hint__description">
                    بعد از خرید، در بخش{" "}
                    <Link to="/profile/orders" style={{ color: "red" }}>
                        سفارشات من
                    </Link>
                    ، سفارش را باز کنید و برای هر محصول یک KV از کیفت آزاد کنید
                    تا هدیشو بگیرید. البته باید به اندازه کافی KV داشته باشید.
                </div>

                <div
                    className="modal-hint__more"
                    onClick={() => {
                        setModals({ ...modals, kvAssign: true });
                    }}
                >
                    <SVG svg="hint-more" /> راهنمای گرفتن KV
                </div>

                <div className="row a-c" style={{ gap: "4px" }}>
                    <div
                        className="modal-hint__button solid"
                        onClick={() => {
                            setModals({ ...modals, getKv: false });
                        }}
                    >
                        بازگشت
                    </div>

                    <div
                        className="modal-hint__button"
                        onClick={() => {
                            setModals({
                                price: false,
                                getKv: false,
                                kvAssign: false,
                            });
                        }}
                    >
                        فروشگاه
                    </div>
                </div>
            </Modal>

            <Modal
                active={modals.kvAssign}
                close={() => {
                    setModals({ ...modals, kvAssign: false });
                }}
                hint="kvAssign"
            >
                <div className="modal-hint__title">راهنمای گرفتن KV</div>

                <div className="modal-hint__description">
                    با معرفی افراد جدید و دیدن صفحات کارتلا می‌تونید KV بگیرید،
                    فقط یادتون باشه هرچی تعداد ستاره های پروفایلتون بیشتر باشه
                    KV های بیشتری می‌گیرید. موجودی و صورتحساب KV هاتون هم همیشه
                    در کیف KV قابل مشاهده است.
                </div>

                <div
                    className="modal-hint__more"
                    onClick={() => {
                        history.push(`/pay?https://kartaak.com/rules`);
                        setTimeout(() => {
                            if (history.location.pathname.includes("/pay"))
                                window.location.href = `https://kartaak.com/rules`;
                        }, 600);
                    }}
                >
                    <SVG svg="hint-more" /> اطلاعات بیشتر...
                </div>

                <div className="row a-c" style={{ gap: "4px" }}>
                    <div
                        className="modal-hint__button solid"
                        onClick={() => {
                            setModals({ ...modals, kvAssign: false });
                        }}
                    >
                        بازگشت
                    </div>

                    <div
                        className="modal-hint__button"
                        onClick={() => {
                            setModals({
                                price: false,
                                getKv: false,
                                kvAssign: false,
                            });
                        }}
                    >
                        فروشگاه
                    </div>
                </div>
            </Modal>
            <Card>
                <div className="product__variation" id="variation">
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                        }}
                    >
                        {colors.length > 0 &&
                            (colors.length === 1 &&
                            Number(colors[0].id) === 1 ? null : (
                                <div className="product__variation__colors">
                                    <div className="product__variation__title">
                                        رنگ
                                    </div>

                                    <div className="product__variation__colors__list">
                                        {colors.map((color, index) => (
                                            <div
                                                className={`product__variation__colors__item ${
                                                    color.id ===
                                                        Number(
                                                            active.color_id
                                                        ) && "active"
                                                }`}
                                                key={index}
                                                onClick={() => {
                                                    toggleShowVariation(false);
                                                    setVariation(
                                                        "color",
                                                        Number(color.id)
                                                    );
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        backgroundColor: `#${color.hex_code}`,
                                                    }}
                                                />
                                                {color.title}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ))}

                        <div className="product__variation__warranty">
                            {Number(active.waranty_id) !== 1 &&
                                active?.detail?.warranty}
                        </div>
                    </div>

                    <div
                        className={`product__variation__item ${
                            variationStatus && "opened"
                        } ${active?.detail?.IsStock === null && "deactive"}`}
                    >
                        {productVariations.length > 0 &&
                        active?.detail?.IsStock !== null ? (
                            <div className="active">
                                <div className="product__variation__item__info">
                                    <div className="product__variation__item__seller">
                                        تامین‌کننده: {seller}
                                    </div>
                                    {/* <button
                                        className="button"
                                        onClick={() => {
                                            // history.push(`/onlineStore/${active.storeId}`);
                                            history.push(`/seller/${pid}`);
                                        }}
                                    >
                                        ورود به فروشگاه
                                    </button> */}
                                </div>

                                <div className="product__variation__item__price">
                                    <div className="product__variation__item__price__foryou__title">
                                        قیمت کالا:
                                    </div>
                                    <div
                                        style={{
                                            position: "relative",
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                    >
                                        <Lowprice />
                                        {transformPrice(+active.price) + " "}
                                        تومان
                                        {/* {transformPrice(+active.kartaakPrice) + " "}تومان */}
                                    </div>
                                </div>
                                <hr />
                                <div className="product__variation__item__price">
                                    <div
                                        style={{ fontWeight: 700 }}
                                        className="product__variation__item__price__foryou__title"
                                        id="red"
                                    >
                                        قیمت برای شما:
                                    </div>
                                    <div className="row a-c">
                                        {user?.isCitizen === 1 && (
                                            <div className="product__variation__item__price__foryou__price">
                                                {transformPrice(
                                                    +citizenUserPrice
                                                ) + " "}{" "}
                                                تومان
                                            </div>
                                        )}
                                        {user?.isCaptain === 1 &&
                                            user?.isCitizen === 0 && (
                                                <div className="product__variation__item__price__foryou__price">
                                                    {transformPrice(
                                                        +captainUserPrice
                                                    ) + " "}{" "}
                                                    تومان
                                                </div>
                                            )}
                                        {user?.detail?.star === 7 &&
                                            user?.isCaptain === 0 &&
                                            user?.isCitizen === 0 && (
                                                <div className="product__variation__item__price__foryou__price">
                                                    {transformPrice(
                                                        +sevenStarUserPrice
                                                    ) + " "}{" "}
                                                    تومان
                                                </div>
                                            )}
                                        {user?.detail?.star !== 7 &&
                                            user?.isCaptain === 0 &&
                                            user?.isCitizen === 0 && (
                                                <div className="product__variation__item__price__foryou__price">
                                                    {transformPrice(
                                                        +normalUserPrice
                                                    ) + " "}{" "}
                                                    تومان
                                                </div>
                                            )}
                                    </div>
                                </div>

                                {user?.isCitizen !== 1 && (
                                    <div
                                        style={{
                                            border: "1px solid gray",
                                            borderRadius: "10px",
                                            marginBottom: "0.5rem",
                                        }}
                                    >
                                        <div
                                            style={{
                                                borderRadius:
                                                    "10px 10px 0px 0px",
                                                marginBottom: "0.5rem",
                                            }}
                                            className="product-item__buy__more"
                                        >
                                            قیمت ارزان‌تر برای :
                                        </div>

                                        <div style={{ padding: "0.5rem" }}>
                                            {user?.detail?.star !== 7 &&
                                                user?.isCaptain === 0 &&
                                                user?.isCitizen === 0 && (
                                                    <div className="product__variation__item__price">
                                                        <Link
                                                            className="product__variation__item__price__foryou__title"
                                                            id="red"
                                                            to={"/profile"}
                                                            style={{
                                                                padding: "5px",
                                                                color: "#21bbe1",
                                                                cursor: "pointer",
                                                                border: "1px solid #21bbe1",
                                                                borderRadius:
                                                                    "5px",
                                                            }}
                                                        >
                                                            کاربر 7 ستاره
                                                        </Link>
                                                        <div className="row a-c">
                                                            <div
                                                                className="product__variation__item__price__foryou__price"
                                                                style={{
                                                                    fontSize:
                                                                        "0.8rem",
                                                                }}
                                                            >
                                                                {transformPrice(
                                                                    +sevenStarUserPrice
                                                                ) + " "}{" "}
                                                                تومان
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}

                                            {(user?.detail?.star !== 7 &&
                                                user?.isCaptain === 0 &&
                                                user?.isCitizen === 0) ||
                                            (user?.detail?.star === 7 &&
                                                user?.isCaptain === 0 &&
                                                user?.isCitizen === 0) ? (
                                                <div className="product__variation__item__price">
                                                    <Link
                                                        className="product__variation__item__price__foryou__title"
                                                        id="red"
                                                        to={"/profile/friends"}
                                                        style={{
                                                            padding: "5px",
                                                            color: "#21bbe1",
                                                            cursor: "pointer",
                                                            border: "1px solid #21bbe1",
                                                            borderRadius: "5px",
                                                        }}
                                                    >
                                                        کاربر کاپیتان
                                                    </Link>
                                                    <div className="row a-c">
                                                        <div
                                                            className="product__variation__item__price__foryou__price"
                                                            style={{
                                                                fontSize:
                                                                    "0.8rem",
                                                            }}
                                                        >
                                                            {transformPrice(
                                                                +captainUserPrice
                                                            ) + " "}{" "}
                                                            تومان
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div></div>
                                            )}
                                            {(user?.detail?.star !== 7 &&
                                                user?.isCaptain === 0 &&
                                                user?.isCitizen === 0) ||
                                            (user?.detail?.star === 7 &&
                                                user?.isCaptain === 0 &&
                                                user?.isCitizen === 0) ||
                                            (user?.isCaptain === 1 &&
                                                user?.isCitizen === 0) ? (
                                                <div className="product__variation__item__price">
                                                    <Link
                                                        to={"/kartland"}
                                                        className="product__variation__item__price__foryou__title"
                                                        id="red"
                                                        style={{
                                                            padding: "5px",
                                                            color: "#21bbe1",
                                                            cursor: "pointer",
                                                            border: "1px solid #21bbe1",
                                                            borderRadius: "5px",
                                                        }}
                                                    >
                                                        کاربر سیتی‌زن
                                                    </Link>
                                                    <div className="row a-c">
                                                        <div
                                                            className="product__variation__item__price__foryou__price"
                                                            style={{
                                                                fontSize:
                                                                    "0.8rem",
                                                            }}
                                                        >
                                                            {transformPrice(
                                                                +citizenUserPrice
                                                            ) + " "}{" "}
                                                            تومان
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div></div>
                                            )}
                                        </div>
                                    </div>
                                )}

                                {/* <div className="product__variation__item__price">
                                    <div
                                        style={{ color: "#db2c2c" }}
                                        className="product__variation__item__price__foryou__title"
                                    >
                                        KP محصول:
                                    </div>
                                    <div
                                        style={{
                                            position: "relative",
                                            display: "flex",
                                            alignItems: "center",
                                            color: "#db2c2c",
                                        }}
                                    >
                                        {transformPrice(active.kpGift) + " "}عدد
                                    </div>
                                </div> */}

                                <div
                                    className="product__variation__item__price"
                                    style={{
                                        flexDirection: "column",
                                        alignItems: "flex-start",
                                    }}
                                >
                                    <div className="product__variation__item__price__foryou__title">
                                        محدوده ارسال :
                                        {active?.detail?.shippingRangeCity ==
                                            null &&
                                        active?.detail?.shippingRangeState ==
                                            null
                                            ? " سراسر کشور "
                                            : active?.detail
                                                  ?.shippingRangeState !=
                                                  null &&
                                              active?.detail
                                                  ?.shippingRangeCity == null
                                            ? " استانی "
                                            : " شهرستانی "}
                                    </div>
                                    <div
                                        style={{
                                            position: "relative",
                                            display: "flex",
                                            alignItems: "center",
                                            flexWrap: "wrap",
                                            justifyContent: "flex-start",
                                        }}
                                    >
                                        {active?.detail?.shippingRangeCity ==
                                            null &&
                                        active?.detail?.shippingRangeState ==
                                            null
                                            ? ""
                                            : active?.detail
                                                  ?.shippingRangeState !=
                                                  null &&
                                              active?.detail
                                                  ?.shippingRangeCity == null
                                            ? active?.detail?.shippingRangeState?.map(
                                                  (st, index) =>
                                                      index + 1 ===
                                                      active?.detail
                                                          ?.shippingRangeState
                                                          ?.length ? (
                                                          <span>
                                                              {st?.name}
                                                          </span>
                                                      ) : (
                                                          <span>
                                                              {st?.name}،{" "}
                                                          </span>
                                                      )
                                              )
                                            : active?.detail?.shippingRangeCity?.map(
                                                  (st, index) =>
                                                      index + 1 ===
                                                      active?.detail
                                                          ?.shippingRangeCity
                                                          ?.length ? (
                                                          <span>
                                                              {st?.name}
                                                          </span>
                                                      ) : (
                                                          <span>
                                                              {st?.name}،{" "}
                                                          </span>
                                                      )
                                              )}
                                    </div>
                                </div>

                                {/*<div className="product__variation__item__price" id="purple">*/}
                                {/*  <div*/}
                                {/*    className="product__variation__item__price__foryou__title"*/}
                                {/*    id="purple"*/}
                                {/*  >*/}
                                {/*    هدیه آزادسازی KV:*/}
                                {/*  </div>*/}
                                {/*  <div*/}
                                {/*    style={{*/}
                                {/*      position: "relative",*/}
                                {/*      display: "flex",*/}
                                {/*      alignItems: "center",*/}
                                {/*    }}*/}
                                {/*  >*/}
                                {/*    {transformPrice(*/}
                                {/*      active?.priceList[user?.detail?.star - 1]?.kv*/}
                                {/*    ) + " "}{" "}*/}
                                {/*    تومان*/}
                                {/*  </div>*/}
                                {/*</div>*/}

                                {/*<div*/}
                                {/*  className="product__variation__item__better"*/}
                                {/*  onClick={() => {*/}
                                {/*    if (user?.detail?.star === 7) {*/}
                                {/*      setModals({ ...modals, getKv: true });*/}
                                {/*    } else {*/}
                                {/*      setModals({ ...modals, price: true });*/}
                                {/*    }*/}
                                {/*    // setTokenPriceType("all");*/}
                                {/*    // toggleShowTokenPrice(true);*/}
                                {/*  }}*/}
                                {/*>*/}
                                {/*  {user?.detail?.star === 7*/}
                                {/*    ? "راهنمای آزادسازی KV"*/}
                                {/*    : "خرید ارزان‌تر + هدیه بیشتر"}*/}
                                {/*  <div className="product__variation__item__better__arrow">*/}
                                {/*    <SVG svg="arrow" />*/}
                                {/*  </div>*/}
                                {/*</div>*/}
                            </div>
                        ) : (
                            <div className="deactive">
                                محصول موجود نمی‌باشد.
                            </div>
                        )}

                        {variationStatus &&
                            handleShowVariations()
                                .sort((a, b) => {
                                    return a - b;
                                })
                                .map(
                                    (variation, index) =>
                                        variation !== active && (
                                            <div
                                                className="other"
                                                key={index}
                                                onClick={(e) => {
                                                    if (
                                                        (
                                                            e.target as HTMLInputElement
                                                        ).id !== "your-price"
                                                    ) {
                                                        toggleShowVariation(
                                                            false
                                                        );
                                                        setActiveVariation(
                                                            variation
                                                        );
                                                    }
                                                }}
                                            >
                                                <div className="row j-sb">
                                                    <div className="product__variation__item__price">
                                                        <Lowprice />
                                                        {transformPrice(
                                                            variation.price
                                                        ) + " "}
                                                        تومان
                                                    </div>

                                                    {handleAvailableVariation(
                                                        variation
                                                    ) ? (
                                                        <div
                                                            className="product__variation__item__title-bg"
                                                            id="your-price"
                                                            onClick={() => {
                                                                if (
                                                                    user_token ===
                                                                        null ||
                                                                    user_token ===
                                                                        undefined
                                                                ) {
                                                                    toast(
                                                                        "برای مشاهده‌ی قیمت شما، ابتدا وارد شوید."
                                                                    );
                                                                    history.push(
                                                                        "/login"
                                                                    );
                                                                } else {
                                                                    toggleShowTokenPrice(
                                                                        true
                                                                    );
                                                                }
                                                            }}
                                                        >
                                                            <div
                                                                className="product__variation__item__title"
                                                                // style={{ color: '#FD6547' }}
                                                                id="your-price"
                                                            >
                                                                قیمت برای شما
                                                                <SVG svg="arrow" />
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div className="product__variation__item__title notfound">
                                                            ناموجود
                                                            <SVG svg="times" />
                                                        </div>
                                                    )}
                                                </div>

                                                <div className="product__variation__item__info">
                                                    <div className="product__variation__item__seller">
                                                        فروشنده: {seller}
                                                    </div>
                                                    <div className="product__variation__item__seller">
                                                        {Number(
                                                            variation.waranty_id
                                                        ) !== 1 &&
                                                            variation.detail
                                                                ?.Warranty}
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                )}

                        {/* {active?.detail?.IsStock !== null && (
            <div className="product__variation__item__kv-price">
              <div
                className="product__variation__item__kv-price__title"
              onClick={() => {
                setShowKvInfo(true);
                setShowKvInfoPrice(
                  active?.priceList[user?.detail?.star - 1]?.kv
                );
              }}
              >
                هدیه‌ آزادسازی KV:
                <SVG svg="info" />
              </div>
              <div className="product__variation__item__kv-price__info">
                {productVariations.length > 0 && (
                  <div className="product__variation__item__kv-price__info__price">
                    {transformPrice(
                      active?.priceList[user?.detail?.star - 1]?.kv
                    ) + " "}{" "}
                    تومان
                  </div>
                )}
                {user?.detail?.star !== 7 && (
                  <div
                    className="product__variation__item__kv-price__info__more"
                    onClick={() => {
                      setTokenPriceType("kv");
                      toggleShowTokenPrice(true);
                    }}
                  >
                    بیشتر
                    <br />
                    ...
                  </div>
                )}
              </div>
            </div>
          )} */}

                        {handleShowVariations().length > 1 && (
                            <div
                                className="product__variation__item__more"
                                onClick={() =>
                                    toggleShowVariation((prev) => !prev)
                                }
                            >
                                {variationStatus ? "بستن" : "فروشندگان دیگر"}
                            </div>
                        )}
                    </div>
                    {/*<div className="product__variation__warranty">
              <div className="product__variation__title">گارانتی</div>

              <div className="product__variation__warranty__list">
                {handleShowWarranty().map((warrantyItem, index) => (
                  <div
                    key={index}
                    className={`product__variation__warranty__list__item ${Number(activeVariation.waranty_id) ===
                      Number(warrantyItem.waranty_id) && "active"
                      }`}
                    onClick={() =>
                      handleSetVariation(
                        "warranty",
                        Number(warrantyItem.waranty_id)
                      )
                    }
                  >
                    {warrantyItem.detail.Warranty}
                  </div>
                ))}
              </div>
            </div>

            <div className="product__variation__price">
              <div>قیمت:</div>
              <div>
                {transformPrice(activeVariation.price) + " "}
                تومان
              </div>
            </div> */}
                </div>
            </Card>
        </React.Fragment>
    );
};
