import { SVG } from "components";

export const NotFound = ({ icon, text }) => {
  return (
    <div className="generator__not-found">
      <div className="generator__not-found__logo">
        <SVG svg={icon} />
      </div>
      <span className="generator__not-found__title">{text}</span>
    </div>
  );
};
