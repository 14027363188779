export const Counter = ({ amount = '' }) => {
  return (
    <div className="km-donate__show-count">
      <span className="km-donate__show-count__title">
        تعداد KM مورد انتقال:
      </span>
      <span className="km-donate__show-count__value">{amount} KM</span>
    </div>
  );
};
