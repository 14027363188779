import { Card, Dots, Fade, Modal, PageTopbar, Spinner, SVG } from "components";
import { Tabs } from "containers/OrdersContainer/dependencies";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useHistory, useLocation } from "react-router-dom";
import {
    getHintAPI,
    getIntroducerRequestLogAPI,
    IntroducerRequestCountAPI,
    mySubmittedStoresAPI,
    sendInternetPackageAPI,
    submiterOrAdminStoreRequestAPI,
} from "utils";
import { NotFound } from "../MyAdsContainer/dependencies";
import { myIntroduceStoresAPI } from "../../utils/Services/Kartland/MyIntroduceStores";
import { deleteIntroducedStoreAPI } from "../../utils/Services/Kartland/DeleteIntroducedStore";
import { myScoresAPI } from "../../utils/Services/Kartland/MyScores";
import { useUser } from "../../hooks";
import badge from "../../assets/images/badge.png";
import { cloneDeep } from "lodash";
import { prizeHistoryAPI } from "../../utils/Services/Kartland/PrizeHistory";
import { submitterRequestAPI } from "../../utils/Services/Introduce/SubmitterRequest";
import { storeCounterAPI } from "../../utils/Services/Introduce/StoreCounter";
import { acceptedByAdminStoreAPI } from "../../utils/Services/Kartland/AcceptedByAdminStore";
import { farsiDigitToEnglish } from "utils/Functions";

export const MyIntroduceStoresContainer = () => {
    const location = useLocation();
    const history = useHistory();
    const {
        userState: { user },
    } = useUser();
    const [introduceStores, setIntroduceStores] = useState([]);
    const [submittedStores, setSubmittedStores] = useState({
        submitWithoutActivate: [],
        submitWithActivate: [],
    });
    const [loading, setLoading] = useState(true);
    const [activeTab, setActiveTab] = useState(0);
    const [deleteModal, setDeleteModal] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [deletingItem, setDeletingItem] = useState(0);
    const [receivePrizeModal, setReceivePrizeModal] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState("");
    const [operator, setOperator] = useState("");
    const [myOperator, setMyOperator] = useState("");
    const [sendPrizeLoading, setSendPrizeLoading] = useState(false);
    const [receiveType, setReceiveType] = useState(0);
    const [scoreLogs, setScoreLogs] = useState([]);
    const [getScoresLoading, setGetScoresLoading] = useState(true);
    const [scoreDetail, setScoreDetail] = useState(-1);
    const [myScores, setMyScores] = useState({
        log: [],
        userScore: {
            adminScore: 0,
            giftCount: 0,
            remainingScore: 0,
        },
        count: 0,
    });
    const [prizeCount, setPrizeCount] = useState([]);
    const [prizeLog, setPrizeLog] = useState([]);
    const [prizeHistory, setPrizeHistory] = useState(-1);
    const [counter, setCounter] = useState(20);
    const [submitterGuide, setSubmitterGuide] = useState(false);
    const [requestLoading, setRequestLoading] = useState(false);
    const [storeIntroduced, setStoreIntroduced] = useState<any>(1);
    const [submittedStoresLoading, setSubmittedStoresLoading] = useState(false);
    const [storeType, setStoreType] = useState(false);
    const [acceptedAdminStore, setAcceptedAdminStore] = useState([]);

    const [inviterCode, setInviterCode] = useState("");
    const [inviteCodeStatus, setInviteCodeStatus] = useState(false);
    const [inviteCodeLoading, setInviteCodeLoading] = useState(false);
    const [unitDetail, setUnitDetail] = useState({
        inviter: "",
    });
    const handleSubmitInviteCode = () => {
        setInviteCodeLoading(true);

        submiterOrAdminStoreRequestAPI(inviterCode)
            .then((response) => {
                if (response.status === 200) {
                    toast.success("درخواست شما با موفقیت ارسال شد");
                    setUnitDetail({ ...unitDetail, inviter: inviterCode });
                    setInviteCodeStatus(true);
                }
            })
            .catch((error) => {
                console.log(error.response);
                if (
                    error?.response?.data?.msg === "You have already requested"
                ) {
                    toast.error("قبلا درخواست داده شده است");
                } else if (
                    error?.response?.data?.data === "userInviter not found"
                ) {
                    toast.error("کد یا شماره تلفن صحیح نیست");
                } else if (error?.response?.status === 403) {
                    toast.error("شما کارشناس یا کارشناس ارشد نیستید");
                } else if (
                    error?.response?.data.msg === "you can not invite yourself"
                ) {
                    toast.error("شما نمیتوانید خودتان را دعوت کنید");
                } else if (
                    error?.response?.data.msg ===
                    "you can not invite submiter or adminStore"
                ) {
                    toast.error(
                        "شما نمیتوانید کارشناس یا کارشناس ارشد را دعوت کنید"
                    );
                }
                // } else if(error?.response?.status === 402) {
                //   toast.error("این کاربر تا به الان هیچ فروشگاهی معرفی نکرده است.");
                // }
                //toast.error("خطایی رخ داده است");
            })
            .finally(() => {
                setInviteCodeLoading(false);
            });
    };

    const [introducerRequestLogs, setIntroducerRequestLogs] = useState([]);

    const getIntroducerRequestLogs = () => {
        getIntroducerRequestLogAPI()
            .then((response) => {
                if (response.status === 200) {
                    setIntroducerRequestLogs(response.data);
                }
            })
            .catch((error) => {
                toast.error("خطایی رخ داده است");
            });
    };

    const [acceptTermsModal, setAcceptTermsModal] = useState(false);
    const [ruleHint, setRuleHint] = useState<any>(`
    1-	کاربری که کارشناس می شود هیچ اجباری برای همکاری نداشته و کارشناس شدن مسئولیتی را برای او ایجاد نمی کند.
    2-	کارشناسان می توانند فقط فروشگاه هایی را که در محدوده دیدشان است تایید یا رد کنند. 
    3-	هر کارشناس می تواند افراد جدیدی را به عنوان کارشناس معرفی نماید. افراد معرفی شده باید درخواست کارشناسی خود را  ارسال کنند، کارتک در پذیرش یا رد درخواستها مختار است.
    4-	اگر کارشناس اطلاعات اشتباه برای کارتک ارسال نماید بلافاصله تعلیق شده و امتیازات دریافتی او مسدود خواهد شد. 
    5-	کارشناسان هیچگونه ارتباط استخدامی یا قراردادی با کارتک نداشته و بابت همکاری فقط امتیاز دریافت می کنند. امتیازات همکاری توسط کارتک تعیین شده و کارشناس هر گونه تغییر را می پذیرد.
    6-	کارتک می تواند در هر زمانی و به هر دلیلی کارشناس را به کاربر عادی تبدیل نماید.
  `);
    const [loadingAcceptTermsButton, setLoadingAcceptTermsButton] =
        useState(false);
    const [loadStoreCount, setLoadStoreCount] = useState(false);
    const [introducerRequestCount, setIntroducerRequestCount] =
        useState<any>(0);

    const handleStartCollaboration = () => {
        if (
            introducerRequestCount.accepted >= introducerRequestCount.recomended
        ) {
            setLoadingAcceptTermsButton(true);
            if (user.storeSubmiter !== 1 && user.isAdminStore !== 1) {
                !requestLoading && submitterRequest();
            }
        } else {
            toast.error("تعداد فروشگاه های معرفی کرده به حد نصاب نرسیده است.");
        }
    };

    useEffect(() => {
        if (loadStoreCount) {
            IntroducerRequestCountAPI()
                .then((response) => {
                    if (response.status === 200) {
                        setIntroducerRequestCount(response.data);
                        setLoadStoreCount(true);
                        setAcceptTermsModal(true);
                    }
                })
                .catch((error) => {
                    if (
                        error?.response?.data?.msg ==
                        "you dont have accepted store"
                    ) {
                        toast.error(
                            "شما هیچ فروشگاه تایید شده ای ندارید . تعداد مورد نیاز برای تایید : " +
                                error?.response?.data?.data?.recomended
                        );
                    } else {
                        toast.error("خطایی رخ داده است");
                    }
                    setAcceptTermsModal(false);
                    setLoadStoreCount(false);
                });
        }
    }, [loadStoreCount]);

    const handleType = (type) => {
        switch (type) {
            case "introducer":
                return "افزودن";
            case "submiter":
                return "تائید";
            default:
                return "";
        }
    };

    const [tabs, setTabs] = useState([
        {
            title: "درانتظار تائید",
            icon: "waiting",
        },
        {
            title: "تائید شده",
            icon: "scores",
        },
    ]);

    const getMyIntroduceStores = () => {
        setLoading(true);
        setSubmittedStoresLoading(true);
        myIntroduceStoresAPI()
            .then((response) => {
                if (response.status === 200) {
                    setIntroduceStores(response.data);
                }
            })
            .catch((err) => {
                toast.error("خطایی در برقراری ارتباط رخ داد");
            })
            .finally(() => {
                setLoading(false);
                setSubmittedStoresLoading(false);
            });
    };

    const getMySubmittedStores = () => {
        setLoading(true);
        setSubmittedStoresLoading(true);
        mySubmittedStoresAPI()
            .then((response) => {
                if (response.status === 200) {
                    setSubmittedStores(response.data);
                }
            })
            .catch((err) => {
                toast.error("خطایی در برقراری ارتباط رخ داد");
            })
            .finally(() => {
                setLoading(false);
                setSubmittedStoresLoading(false);
            });
    };

    const deleteIntroduceStore = (id) => {
        setDeleteLoading(true);
        deleteIntroducedStoreAPI(id)
            .then((response) => {
                if (response.status === 200) {
                    setDeleteModal(false);
                    toast.success("فروشگاه با موفقیت حذف شد");
                    getMyIntroduceStores();
                    getMySubmittedStores();
                }
            })
            .catch((err) => {
                toast.error("خطایی در برقراری ارتباط رخ داد");
            })
            .finally(() => setDeleteLoading(false));
    };

    const getScores = () => {
        setLoading(true);
        myScoresAPI()
            .then((response) => {
                if (response.status === 200) {
                    setMyScores(response.data);
                }
            })
            .catch((err) => {
                if (!(err.response.status === 404)) {
                    toast.error("خطایی در برقراری ارتباط رخ داد");
                }
            })
            .finally(() => {
                setLoading(false);
                setGetScoresLoading(false);
            });
    };

    const getScoreCounter = () => {
        setLoading(true);
        storeCounterAPI()
            .then((response) => {
                if (response.status === 200) {
                    setStoreIntroduced(response.data.registerdCounter);
                }
            })
            .catch((err) => {
                toast.error("خطایی در برقراری ارتباط رخ داد");
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const getPrizeLog = () => {
        setLoading(true);
        prizeHistoryAPI()
            .then((response) => {
                if (response.status === 200) {
                    setPrizeLog(response.data);
                }
            })
            .catch((err) => {
                toast.error("خطایی در برقراری ارتباط رخ داد");
            })
            .finally(() => {
                setLoading(false);
                setGetScoresLoading(false);
            });
    };

    const getAcceptedAdminStore = () => {
        setLoading(true);
        acceptedByAdminStoreAPI()
            .then((response) => {
                if (response.status === 200) {
                    setAcceptedAdminStore(response.data);
                }
            })
            .catch((err) => {
                toast.error("خطایی در برقراری ارتباط رخ داد");
            })
            .finally(() => {
                setLoading(false);
                setGetScoresLoading(false);
            });
    };

    // const prizeBoxes = () => {
    //   for (let i = 0; i < myScores.userScore.giftCount; i++) {
    //     let currPrize = cloneDeep(prizeCount);
    //     currPrize.push('a');
    //     setPrizeCount(currPrize);
    //   }
    // };

    const sendGift = () => {
        setSendPrizeLoading(true);
        toast.loading("لطفا کمی صبر کنید");
        sendInternetPackageAPI(
            receiveType === 0 ? myOperator : operator,
            receiveType === 0 ? user.mobile_number : phoneNumber
        )
            .then((res) => {
                if (res.status === 200) {
                    toast.success("بسته اینترنت برای شما ارسال شد!");
                    setReceivePrizeModal(false);
                }
            })
            .catch((err) => {
                if (
                    err.response.data.msg ===
                    "this number dont have kartaak account"
                ) {
                    toast.error("این شماره در کارتک عضو نمی‌باشد!");
                } else {
                    toast.error("عملیات با خطا مواجه شد!");
                    toast.error("لطفا با پشتیبانی تماس حاصل فرمائید");
                }
            })
            .finally(() => setSendPrizeLoading(false));
        countTimer();
    };

    const submitterRequest = () => {
        setRequestLoading(true);
        submitterRequestAPI()
            .then((res) => {
                if (res.status === 200) {
                    toast.success("درخواست شما برای کارتک ارسال شد");
                }
            })
            .catch((err) => {
                if (err.response.status === 400) {
                    toast.error("شما قبلا توسط کاربری دیگر دعوت شده اید");
                } else if (err.response.status === 403) {
                    toast.error("شما شرایط کارشناسی را ندارید");
                } else if (err.response.status === 406) {
                    toast.error("شما کاربر 7 ستاره نیستید.");
                } else {
                    toast.error("مشکلی در ارسال درخواست رخ داد");
                }
            })
            .finally(() => {
                setRequestLoading(false);
                setLoadingAcceptTermsButton(false);
            });
    };

    const countTimer = () => {
        let localCounter = 20;
        setInterval(() => {
            setCounter(localCounter);
            localCounter--;
        }, 1000);
    };

    useEffect(() => {
        if (activeTab == 1) {
            history.replace("/my-introduces?waiting");
            setLoading(false);
        } else if (activeTab == 2) {
            history.replace("/my-introduces?score");
        } else if (activeTab == 3) {
            // history.replace("/my-introduces?prize");
            getIntroducerRequestLogs();
        }
    }, [activeTab]);

    useEffect(() => {
        if (location.search.substr(1) === "score") {
            setActiveTab(2);
        }
        if (
            location.search.substr(1) === "waiting" ||
            location.search.substr(1) === ""
        ) {
            setActiveTab(1);
        }
        // if (location.search.substr(1) === "prize") {
        //   setActiveTab(3);
        // }
    }, [location.search]);

    useEffect(() => {
        if (user.storeSubmiter === 1) {
            getMySubmittedStores();
        }
        if (user.isAdminStore) {
            getAcceptedAdminStore();
        }
    }, [user]);

    useEffect(() => {
        getMyIntroduceStores();
        getScores();
        getPrizeLog();
        getScoreCounter();
    }, []);

    useEffect(() => {
        if (user.storeSubmiter === 1 || user.isAdminStore === 1) {
            let tempTab = cloneDeep(tabs);
            tempTab.push({ title: "معرفی کارشناس" });
            // tempTab[2].title = (user.isAdminStore || user.storeSubmiter) ? 'معرفی کارشناس' : 'درخواست برای کارشناس شدن';
            setTabs(tempTab);
        }
    }, [user]);

    // useEffect(() => {
    //   if (phoneNumber.length === 11) {
    //     checkOperator();
    //   }
    // }, [phoneNumber]);

    // useEffect(() => {
    //   if (!getScoresLoading) {
    //     prizeBoxes();
    //     let logs = cloneDeep(myScores.log);
    //     let currScores = [];
    //     let size = myScores.userScore.adminScore / 200;
    //     while (logs.length > 0) {
    //       currScores.push(logs.splice(0, size));
    //     }
    //     setScoreLogs(currScores);
    //   }
    // }, [getScoresLoading]);

    const [hintOpenStatus, setHintOpenStatus] = useState(false);
    const [hintText, setHintText] = useState("");
    const [hintText2, setHintText2] = useState<any>("");
    useEffect(() => {
        // getHintAPI("راهنمای ثابت صفحه معرفی فروشگاه جدید")
        //   .then((response) => {
        //     setHintText(response.content);
        //   })
        //   .catch((error) => {
        //     setHintText("دریافت راهنما با خطا مواجه شد");
        //   });
        // getHintAPI("راهنمای ثابت کارشناسان")
        //     .then((response) => {
        //         setHintText2(response);
        //     })
        //     .catch((error) => {
        //         setHintText2("دریافت راهنما با خطا مواجه شد");
        //     });
    }, []);

    return (
        <Fade>
            <PageTopbar
                title="کارشناس شو"
                circleHint={
                    user.storeSubmiter === 1 ? hintText2.content : hintText
                }
                showKv={false}
            />
            {/*<Spinner active={loading} color="dark" /> */}

            <Modal
                active={submitterGuide}
                close={() => setSubmitterGuide(false)}
            >
                <div className="hints__title">{hintText2.title}</div>
                <div className="hints__content">
                    {hintText2.content?.split("\n")?.map((str) => (
                        <div>{str}</div>
                    ))}
                </div>
            </Modal>

            <Modal active={storeType} close={() => setStoreType(false)}>
                <div className="">
                    <div
                        // onClick={() => history.push("/createshop")}
                        onClick={() => history.push("/mycity")}
                        className="button mb-8"
                    >
                        ایجاد فروشگاه خودم
                    </div>
                    <div
                        onClick={() => history.push("/introducestore")}
                        className="button green"
                    >
                        معرفی فروشگاه دیگران
                    </div>
                </div>
            </Modal>

            <Modal active={deleteModal} close={() => setDeleteModal(false)}>
                <div className={"deleting-modal"}>
                    <div className={"deleting-modal__title"}>
                        آیا از حذف فروشگاه مطمئن هستید؟
                    </div>
                    <div className={"deleting-modal__actions"}>
                        <div
                            onClick={() =>
                                deleteLoading === false &&
                                deleteIntroduceStore(deletingItem)
                            }
                            className={"button red"}
                        >
                            {deleteLoading ? (
                                <Dots color={"light"} />
                            ) : (
                                <span>حذف</span>
                            )}
                        </div>
                        <div
                            onClick={() => setDeleteModal(false)}
                            className={"button disable"}
                        >
                            بازگشت
                        </div>
                    </div>
                </div>
            </Modal>

            {/*<Modal active={receivePrizeModal} close={() => setReceivePrizeModal(false)}>*/}
            {/*  <div className={"my-introduce__prize__modal"}>*/}
            {/*    <div className={"my-introduce__prize__modal__header"}>*/}
            {/*      <div className={receiveType === 0 && "my-introduce__prize__modal__header__underline"}*/}
            {/*           onClick={() => setReceiveType(0)}>برای خودم*/}
            {/*      </div>*/}
            {/*      <div className={receiveType === 1 && "my-introduce__prize__modal__header__underline"}*/}
            {/*           onClick={() => setReceiveType(1)}>برای دیگران*/}
            {/*      </div>*/}
            {/*    </div>*/}

            {/*    <div className={"my-introduce__prize__modal__line"}/>*/}

            {/*    <div className={"my-introduce__prize__modal__detail"}>*/}
            {/*      {receiveType === 0 && <div className="my-introduce__prize__modal__detail__me">*/}
            {/*        <div className="my-introduce__prize__modal__detail__me__number">*/}
            {/*          {`شماره همراه: ${user.mobile_number}`}*/}
            {/*        </div>*/}
            {/*        <div className="my-introduce__prize__modal__detail__me__operator">*/}
            {/*          {`اپراتور: ${operatorName(myOperator)}`}*/}
            {/*        </div>*/}
            {/*      </div>}*/}

            {/*      {receiveType === 1 && <div className="my-introduce__prize__modal__detail__other">*/}
            {/*        <div className="my-introduce__prize__modal__detail__me__number">*/}
            {/*          <div className="title">شماره همراه</div>*/}
            {/*          <input*/}
            {/*            type={'number'}*/}
            {/*            className={'input'}*/}
            {/*            placeholder={'09123456789'}*/}
            {/*            value={phoneNumber}*/}
            {/*            onChange={event => setPhoneNumber(event.target.value)}*/}
            {/*          />*/}
            {/*        </div>*/}
            {/*        {phoneNumber.length === 11 && operator &&*/}
            {/*          <div className="my-introduce__prize__modal__detail__me__operator">*/}
            {/*            {`اپراتور: ${operatorName(operator)}`}*/}
            {/*          </div>}*/}
            {/*      </div>}*/}
            {/*    </div>*/}

            {/*    <div className="my-introduce__prize__modal__warning">*/}
            {/*      توجه: برای دریافت این هدیه نباید بسته اینترنتی رزرو داشته باشید درغیر این صورت هدیه شما باطل خواهد شد!*/}
            {/*    </div>*/}

            {/*    <div className={"my-introduce__prize__modal__actions"}>*/}
            {/*      <div*/}
            {/*        onClick={() => !sendPrizeLoading && sendGift()}*/}
            {/*        className={"button"}*/}
            {/*      >*/}
            {/*        {sendPrizeLoading ? <span>{counter}</span> : <span>دریافت</span>}*/}
            {/*      </div>*/}
            {/*      <div*/}
            {/*        onClick={() => setReceivePrizeModal(false)}*/}
            {/*        className={"button disable"}*/}
            {/*      >*/}
            {/*        بازگشت*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</Modal>*/}

            <div className="my-introduce wrapper-fix">
                <div style={{ padding: "0px 10px" }}>
                    {/* <SlidingHint
            openTitle={user.storeSubmiter === 1 ? "راهنمای کارشناسی" : "راهنمای معرفی فروشگاه جدید"}
            text={user.storeSubmiter === 1 ? hintText2.content : hintText}
            open={hintOpenStatus}
            onClick={() => {
              setHintOpenStatus(!hintOpenStatus);
            }}
          /> */}
                </div>

                <div className="my-introduce__header">
                    {/* {
            user.storeSubmiter !== 1 &&
          } */}
                    <div className="col a-c ">
                        {
                            <img
                                onClick={() => {
                                    if (
                                        user.storeSubmiter !== 1 &&
                                        user.isAdminStore !== 1
                                    ) {
                                        setLoadStoreCount(true);
                                    }
                                }}
                                className={`my-introduce__badge mr0 ${
                                    user.storeSubmiter !== 1 &&
                                    user.isAdminStore !== 1 &&
                                    "deActive"
                                }`}
                                src={badge}
                                alt="badge"
                            />
                        }
                        <span>
                            {user.storeSubmiter !== 1 && user.isAdminStore !== 1
                                ? "کارشناسی"
                                : ""}
                        </span>
                    </div>

                    {/* {user.storeSubmiter !== 1 && myScores?.count >= storeIntroduced &&
            <div onClick={() => !requestLoading && submitterRequest()} className="my-introduce__req button">
              {requestLoading ? <Dots color={'light'}/> : <span>درخواست کارشناسی</span>}
            </div>
          } */}

                    <div
                        onClick={() => {
                            if (user.detail.presenceStore) {
                                history.push("/introducestore");
                            } else {
                                setStoreType(true);
                            }
                        }}
                        className={`my-introduce__create submiter mb-0`}
                    >
                        ثبت فروشگاه جدید
                        <SVG svg="store" />
                    </div>
                </div>
                <div className="ads-tab">
                    <Tabs
                        activeTab={activeTab}
                        setActiveTab={setActiveTab}
                        items={tabs}
                    />
                </div>

                {loading ? (
                    <div className="mt-20">
                        <Dots color="dark" />
                    </div>
                ) : (
                    <>
                        {activeTab === 1 && (
                            <Card>
                                {submittedStoresLoading ? (
                                    <Spinner color="dark" />
                                ) : submittedStores.submitWithoutActivate
                                      .length === 0 &&
                                  introduceStores.length === 0 ? (
                                    <NotFound
                                        icon="shop-add"
                                        text="موردی یافت نشد"
                                    />
                                ) : (
                                    <>
                                        {submittedStores.submitWithoutActivate
                                            .length > 0 &&
                                            submittedStores.submitWithoutActivate.map(
                                                (store) => (
                                                    <div
                                                        className={
                                                            "my-introduce__submit__item"
                                                        }
                                                    >
                                                        <div className="my-introduce__introduce__item__title">
                                                            درانتظار تائید کارتک
                                                        </div>
                                                        <div className="my-introduce__submit__item__details">
                                                            <div className="my-introduce__submit__item__pic">
                                                                <img
                                                                    src={
                                                                        store.photo
                                                                    }
                                                                    alt="store-photo"
                                                                />
                                                            </div>
                                                            <div className="my-introduce__submit__item__info">
                                                                <div className="my-introduce__submit__item__info__name">
                                                                    <div className="my-introduce__introduce__item__top__name">
                                                                        {
                                                                            store.name
                                                                        }
                                                                    </div>
                                                                    <div className="my-introduce__introduce__item__top__category">
                                                                        {
                                                                            store.firstCategoryName
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div className="my-introduce__submit__item__info__number">
                                                                    <div className="my-introduce__introduce__item__top__name">
                                                                        {
                                                                            store.mobile_number
                                                                        }
                                                                    </div>
                                                                    {/*<div className="my-introduce__introduce__item__bottom__delete">*/}
                                                                    {/*  <SVG svg="create-remove"/>*/}
                                                                    {/*</div>*/}
                                                                </div>
                                                                <div className="my-introduce__submit__item__info__address">
                                                                    <div className="my-introduce__introduce__item__top__name">
                                                                        {
                                                                            store.address
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            )}
                                        {introduceStores.length > 0 &&
                                            introduceStores.map(
                                                (store) =>
                                                    store.activeByUser ===
                                                        0 && (
                                                        <div
                                                            className={
                                                                "my-introduce__introduce__item"
                                                            }
                                                        >
                                                            <div className="my-introduce__introduce__item__title">
                                                                درانتظار تائید
                                                                کارشناس{" "}
                                                                <small
                                                                    className={
                                                                        "my-introduce__introduce__item__title__score"
                                                                    }
                                                                ></small>
                                                            </div>
                                                            <div className="my-introduce__introduce__item__top">
                                                                <div className="my-introduce__introduce__item__top__name">
                                                                    {store.name}
                                                                </div>
                                                                <div className="my-introduce__introduce__item__top__category">
                                                                    {
                                                                        store.firstCategoryName
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className="my-introduce__introduce__item__bottom">
                                                                <div className="my-introduce__introduce__item__bottom__name">
                                                                    {
                                                                        store.address
                                                                    }
                                                                </div>
                                                                <div
                                                                    className="my-introduce__introduce__item__bottom__delete"
                                                                    onClick={() => {
                                                                        setDeletingItem(
                                                                            store.id
                                                                        );
                                                                        setDeleteModal(
                                                                            true
                                                                        );
                                                                    }}
                                                                >
                                                                    <SVG svg="create-remove" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                            )}
                                    </>
                                )}
                            </Card>
                        )}

                        {activeTab === 2 && (
                            <React.Fragment>
                                <Card>
                                    {myScores.log.length == 0 ? (
                                        <NotFound
                                            icon="bookmark-ads"
                                            text="تاکنون فروشگاهی ثبت نکرده‌اید"
                                        />
                                    ) : (
                                        <div className="saved-ads">
                                            {/*<div className="my-introduce__score__log">*/}
                                            {/*  <div className="row">*/}
                                            {/*    <div className="">امتیاز فعلی:</div>*/}
                                            {/*    <div className="mr-6">{myScores.userScore?.remainingScore}</div>*/}
                                            {/*  </div>*/}
                                            {/*  <div className="row">*/}
                                            {/*    <div className="">امتیاز جایزه:</div>*/}
                                            {/*    <div className="mr-6">{myScores.userScore?.adminScore}</div>*/}
                                            {/*  </div>*/}
                                            {/*  <div className=""></div>*/}
                                            {/*</div>*/}

                                            {acceptedAdminStore?.map(
                                                (score, index) => (
                                                    <div className="my-introduce__score__box admin-store">
                                                        <div className="my-introduce__score__sub">
                                                            <div className="my-introduce__score__sub__item">
                                                                {`فروشگاه ${score.name} توسط شما (کارشناس ارشد) تایید شد.`}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            )}

                                            {myScores.log?.map(
                                                (score, index) => (
                                                    <div className="my-introduce__score__box admin">
                                                        <div className="my-introduce__score__sub">
                                                            <div className="my-introduce__score__sub__item">
                                                                {score.userType ===
                                                                "introducer"
                                                                    ? `فروشگاه ${score.storeName} توسط کارشناس تایید شد.`
                                                                    : `فروشگاه ${score.storeName} توسط کارتک تایید شد.`}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    // score.length === myScores.userScore?.adminScore / 200 ? (
                                                    //   <div className={'my-introduce__score__box'}>
                                                    //     <div
                                                    //       key={index}
                                                    //       className="my-introduce__score"
                                                    //       onClick={() => scoreDetail === index ? setScoreDetail(-1) : setScoreDetail(index)}
                                                    //     >
                                                    //       <div className="my-introduce__score__number">
                                                    //         {`${myScores.userScore?.adminScore} امتیاز`}
                                                    //       </div>
                                                    //       <div className={`my-introduce__score__detail ${scoreDetail === index && 'active'}`}>
                                                    //         <SVG svg={'arrow'}/>
                                                    //       </div>
                                                    //     </div>
                                                    //
                                                    //     {scoreDetail === index &&
                                                    //       <div className="my-introduce__score__sub">
                                                    //         {scoreLogs[index].map(item => (
                                                    //           <div className="my-introduce__score__sub__item">
                                                    //             {`200 امتیاز بابت ${handleType(item.userType)} فروشگاه ${item.storeName}`}
                                                    //           </div>
                                                    //         ))}
                                                    //       </div>
                                                    //     }
                                                    //   </div>
                                                    // ) : (
                                                    //   // <div className={'col'}>
                                                    //   //   {score.map(item => (
                                                    //   //     <div className="my-introduce__score__item">
                                                    //   //       {`200 امتیاز بابت ${handleType(item.userType)} فروشگاه ${item.storeName}`}
                                                    //   //     </div>
                                                    //   //   ))}
                                                    //   // </div>
                                                    //
                                                    //   <div className={'my-introduce__score__box'}>
                                                    //     <div
                                                    //       key={index}
                                                    //       className="my-introduce__score"
                                                    //       onClick={() => scoreDetail === index ? setScoreDetail(-1) : setScoreDetail(index)}
                                                    //     >
                                                    //       <div className="my-introduce__score__number">
                                                    //         {`مجموعه امتیاز فعلی: ${myScores.userScore?.remainingScore} امتیاز`}
                                                    //       </div>
                                                    //       {/*<div className={`my-introduce__score__detail ${scoreDetail === index && 'active'}`}>*/}
                                                    //       {/*  <SVG svg={'arrow'}/>*/}
                                                    //       {/*</div>*/}
                                                    //     </div>
                                                    //
                                                    //
                                                    //     <div className="my-introduce__score__sub">
                                                    //       {scoreLogs[index].map(item => (
                                                    //         <div className="my-introduce__score__sub__item">
                                                    //           {`200 امتیاز بابت ${handleType(item.userType)} فروشگاه ${item.storeName}`}
                                                    //         </div>
                                                    //       ))}
                                                    //     </div>
                                                    //
                                                    //   </div>
                                                    // )
                                                )
                                            )}
                                        </div>
                                    )}
                                </Card>
                            </React.Fragment>
                        )}

                        {activeTab === 3 && (
                            <React.Fragment>
                                <Card>
                                    {user.isAdminStore === 1 ||
                                    user.storeSubmiter === 1 ? (
                                        <div>
                                            <div className="title">
                                                شماره موبایل یا کد کارتک
                                            </div>
                                            <div className="row">
                                                <input
                                                    type="text"
                                                    className="input"
                                                    value={inviterCode}
                                                    onChange={(e) =>
                                                        setInviterCode(
                                                            farsiDigitToEnglish(
                                                                e.target.value
                                                            )
                                                        )
                                                    }
                                                />
                                                <div
                                                    className={`create-shop__inviter__button ${
                                                        inviteCodeStatus &&
                                                        "edit"
                                                    } ${
                                                        inviterCode.length ===
                                                            0 && "hidden"
                                                    }`}
                                                    onClick={() => {
                                                        if (inviteCodeStatus) {
                                                            setInviterCode("");
                                                            setInviteCodeStatus(
                                                                false
                                                            );
                                                            setUnitDetail({
                                                                ...unitDetail,
                                                                inviter: "",
                                                            });
                                                        } else {
                                                            handleSubmitInviteCode();
                                                        }
                                                    }}
                                                >
                                                    {inviteCodeLoading ? (
                                                        <Dots color="light" />
                                                    ) : inviteCodeStatus ? (
                                                        "ویرایش"
                                                    ) : (
                                                        "ثبت"
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div
                                            onClick={() =>
                                                !requestLoading &&
                                                submitterRequest()
                                            }
                                            className="my-introduce__req button"
                                        >
                                            {requestLoading ? (
                                                <Dots color={"light"} />
                                            ) : (
                                                <span>درخواست کارشناسی</span>
                                            )}
                                        </div>
                                    )}

                                    <div>
                                        {introducerRequestLogs.length > 0 ? (
                                            introducerRequestLogs.map(
                                                (item, index) =>
                                                    user.isAdminStore === 1 ||
                                                    user.storeSubmiter === 1 ? (
                                                        <div
                                                            style={{
                                                                borderRadius:
                                                                    "50px",
                                                                border: "3px solid red",
                                                                padding:
                                                                    "5px 20px",
                                                                marginBottom:
                                                                    "10px",
                                                            }}
                                                        >
                                                            <span>
                                                                درخواست معرفی{" "}
                                                                {
                                                                    item
                                                                        ?.userInfo
                                                                        ?.name
                                                                }
                                                            </span>
                                                            <br />
                                                            <span>
                                                                وضعیت :{" "}
                                                                {item?.status ===
                                                                1 ? (
                                                                    <span
                                                                        style={{
                                                                            color: "green",
                                                                        }}
                                                                    >
                                                                        تایید شد
                                                                    </span>
                                                                ) : item?.status ===
                                                                  0 ? (
                                                                    <span
                                                                        style={{
                                                                            color: "red",
                                                                        }}
                                                                    >
                                                                        رد شده
                                                                    </span>
                                                                ) : (
                                                                    <span
                                                                        style={{
                                                                            color: "brown",
                                                                        }}
                                                                    >
                                                                        در
                                                                        انتظار
                                                                    </span>
                                                                )}
                                                            </span>
                                                        </div>
                                                    ) : (
                                                        index === 0 && (
                                                            <div>
                                                                <div
                                                                    style={{
                                                                        borderRadius:
                                                                            "50px",
                                                                        border: "3px solid red",
                                                                        padding:
                                                                            "5px 20px",
                                                                        marginTop:
                                                                            "10px",
                                                                    }}
                                                                >
                                                                    {item?.userInviteInfo !=
                                                                    null ? (
                                                                        <>
                                                                            <span>
                                                                                {
                                                                                    item
                                                                                        ?.userInviteInfo
                                                                                        ?.name
                                                                                }{" "}
                                                                                شما
                                                                                را
                                                                                معرفی
                                                                                کرده.
                                                                            </span>
                                                                            <br />
                                                                            <span>
                                                                                وضعیت
                                                                                :{" "}
                                                                                {item?.status ===
                                                                                1 ? (
                                                                                    <span
                                                                                        style={{
                                                                                            color: "green",
                                                                                        }}
                                                                                    >
                                                                                        تایید
                                                                                        شد
                                                                                    </span>
                                                                                ) : item?.status ===
                                                                                  0 ? (
                                                                                    <span
                                                                                        style={{
                                                                                            color: "red",
                                                                                        }}
                                                                                    >
                                                                                        رد
                                                                                        شده
                                                                                    </span>
                                                                                ) : (
                                                                                    <span
                                                                                        style={{
                                                                                            color: "brown",
                                                                                        }}
                                                                                    >
                                                                                        در
                                                                                        انتظار
                                                                                    </span>
                                                                                )}
                                                                            </span>
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            <span>
                                                                                وضعیت
                                                                                درخواست
                                                                                شما
                                                                                :{" "}
                                                                                {item?.status ===
                                                                                1 ? (
                                                                                    <span
                                                                                        style={{
                                                                                            color: "green",
                                                                                        }}
                                                                                    >
                                                                                        تایید
                                                                                        شد
                                                                                    </span>
                                                                                ) : item?.status ===
                                                                                  0 ? (
                                                                                    <span
                                                                                        style={{
                                                                                            color: "red",
                                                                                        }}
                                                                                    >
                                                                                        رد
                                                                                        شده
                                                                                    </span>
                                                                                ) : (
                                                                                    <span
                                                                                        style={{
                                                                                            color: "brown",
                                                                                        }}
                                                                                    >
                                                                                        در
                                                                                        انتظار
                                                                                    </span>
                                                                                )}
                                                                            </span>
                                                                        </>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        )
                                                    )
                                            )
                                        ) : (
                                            <NotFound
                                                icon="shop-add"
                                                text="موردی یافت نشد"
                                            />
                                        )}
                                    </div>
                                </Card>
                            </React.Fragment>
                        )}

                        {/*{activeTab === 3 && (*/}
                        {/*  <React.Fragment>*/}
                        {/*    <Card>*/}
                        {/*      {myScores.userScore.giftCount > 0 &&*/}
                        {/*        <>*/}
                        {/*          {prizeCount.map(item => (*/}
                        {/*            <div className="my-introduce__prize">*/}
                        {/*              <div className="my-introduce__prize__title">*/}
                        {/*                بسته اینترنت 30 روزه 3 گیگابایت*/}
                        {/*              </div>*/}
                        {/*              <div className="my-introduce__prize__action">*/}
                        {/*                <div onClick={() => setReceivePrizeModal(true)}*/}
                        {/*                     className="my-introduce__prize__action__use">*/}
                        {/*                  دریافت*/}
                        {/*                </div>*/}
                        {/*              </div>*/}
                        {/*            </div>*/}
                        {/*          ))}*/}
                        {/*        </>}*/}
                        {/*      {prizeLog.length > 0 && prizeLog.map((prize, index) => (*/}
                        {/*        <div className="my-introduce__prize">*/}
                        {/*          <div className="my-introduce__prize__title">*/}
                        {/*            بسته اینترنت 30 روزه 3 گیگابایت*/}
                        {/*          </div>*/}
                        {/*          {prize.created_at && <div className="my-introduce__prize__desc">*/}
                        {/*            <div>تاریخ دریافت:</div>*/}
                        {/*            <div className={'mr-6'}>{moment(prize.created_at, "YYYY/MM/DD")*/}
                        {/*              .locale("fa")*/}
                        {/*              .format("YYYY/MM/DD")}</div>*/}
                        {/*          </div>}*/}
                        {/*          <div className="my-introduce__prize__action">*/}
                        {/*            <div onClick={() => prizeHistory === index ? setPrizeHistory(-1) : setPrizeHistory(index)}*/}
                        {/*                 className="my-introduce__prize__action__history">*/}
                        {/*              تاریخچه*/}
                        {/*              <div className={`my-introduce__score__detail ${prizeHistory === index && 'active'}`}>*/}
                        {/*                <SVG svg={'arrow'}/>*/}
                        {/*              </div>*/}
                        {/*            </div>*/}
                        {/*          </div>*/}

                        {/*          {prizeHistory === index && <div className="my-introduce__score__sub">*/}
                        {/*            {prize.store.map(item => (*/}
                        {/*              <div className="my-introduce__score__sub__item">*/}
                        {/*                {`200 امتیاز بابت ${handleType(item.userType)} فروشگاه ${item.storeName}`}*/}
                        {/*              </div>*/}
                        {/*            ))}*/}
                        {/*          </div>}*/}
                        {/*        </div>*/}
                        {/*      ))}*/}
                        {/*      /!*</>}*!/*/}
                        {/*    </Card>*/}
                        {/*  </React.Fragment>*/}
                        {/*)}*/}
                    </>
                )}
            </div>
            <Modal
                active={acceptTermsModal}
                close={() => {
                    setAcceptTermsModal(false);
                    setLoadStoreCount(false);
                }}
            >
                {introducerRequestCount !== 0 ? (
                    <div className="marketing__rules">
                        <div className="marketing__rules__title">
                            {ruleHint?.title}
                        </div>

                        <div
                            className="marketing__rules__description"
                            id="marketingRules"
                        >
                            {ruleHint?.split("\n")?.map((str) => (
                                <div>{str}</div>
                            ))}
                        </div>

                        <hr style={{ width: "-webkit-fill-available" }} />

                        <div className="marketing__rules__description">
                            تعداد فروشگاه معرفی شده مورد نیاز برای کارشناس شدن :{" "}
                            {introducerRequestCount?.recomended}
                            <br />
                            تعداد فروشگاه های معرفی شده توسط شما :{" "}
                            {introducerRequestCount?.accepted}
                        </div>

                        <hr style={{ width: "-webkit-fill-available" }} />
                        <div className="marketing__rules__bottom">
                            <div
                                className={`marketing__rules__button ${
                                    !loadingAcceptTermsButton
                                        ? "marketing__rules__accept"
                                        : "marketing__rules__disabled"
                                }`}
                                onClick={() => {
                                    handleStartCollaboration();
                                }}
                            >
                                {loadingAcceptTermsButton ? (
                                    <Dots color="dark" />
                                ) : (
                                    "پذیرش قوانین و ارسال درخواست"
                                )}
                            </div>
                            {/* <div className="marketing__rules__button marketing__rules__reject">
                انصراف
              </div> */}
                        </div>
                    </div>
                ) : (
                    <Dots color="dark" />
                )}
            </Modal>
        </Fade>
    );
};
