import {useState, useEffect} from "react";
import QrReader from "react-qr-reader";
import {useHistory, useParams} from "react-router-dom";
// import publicIp from "public-ip";
import {useAuth} from "hooks";
import {checkQrCode} from "../../utils/Services/API";
import {SVG, Modal} from "components";
import toast from "react-hot-toast";
import {handleInputBlur, handleInputFocus} from "utils/Functions";
import {joinClubAPI, TransferBlueKmWithLinkAPI} from "utils";

export const QRCodeContainer = () => {
  const {onSetLoginStatus} = useAuth();
  const [linkInput, setLinkInput] = useState("");
  const [modalStatus, setModalStatus] = useState({
    visible: false,
    type: "",
    count: null,
    text: "",
  });
  const [scaningStatus, setScaningStatus] = useState(false);
  const [hasKm, setHasKm] = useState(-1);
  const [storeId, setStoreId] = useState<any>(null);
  const history = useHistory();
  const userToken = localStorage.getItem("user_token");
  const {id}: any = useParams();

  // publicIp.v4().then(res => console.log(res));
  // console.log(window.navigator.userAgent)

  useEffect(() => {
    if (id !== undefined) {
      handleOnScan(`https://app.kartaak.com/qr/${id}`);
      history.replace(`/qr/${id}`);
    } else {
      history.replace("/qr");
    }
  }, []);
  console.log(storeId);
  const handleOnScan = (data?: any) => {
    // data =
    //   "https://app.kartaak.com/qr/TBEXUHWPLJOS";

    if (data !== null && data !== undefined)
      if (data.includes("app.kartaak.com/qr/")) {
        // start
        if (data.includes("km")) {
          setScaningStatus(true);
          TransferBlueKmWithLinkAPI(data.replace("https://app.kartaak.com/qr/km/", ""))
            .then(response => {
              if (response.status === 200) {
                toast.success('KP با موفقیت به شما اهدا شد');
                history.push('/wallet/km');
              }
            })
            .catch(err => {
              if (err.response.status === 403) {
                setModalStatus({
                  ...modalStatus,
                  visible: true,
                  type: "error",
                  text: "این بارکد KP قبلا استفاده شده است.",
                });
              }
              if (err.response.status === 404) {
                setModalStatus({
                  ...modalStatus,
                  visible: true,
                  type: "error",
                  text: "بارکد KP یافت نشد.",
                });
              }
            })
            .finally(() => {
              setScaningStatus(false);
            });
          return;
        }

        if (data.includes("token")) {
          const newToken = data.replace(
            "https://app.kartaak.com/qr/token/",
            ""
          );
          localStorage.setItem("user_token", newToken);
          onSetLoginStatus(true);
          history.push('/profile');
          return;
        }

        if (data.includes("club")) {
          setScaningStatus(true);
          joinClubAPI(data.replace("https://app.kartaak.com/qr/club/", ""))
            .then(response => {
              if (response.status === 200) {
                // toast.success('فروشگاه با موفقیت اسکن شد.');
                // history.push('/mycity?club');
                setStoreId(data.replace("https://app.kartaak.com/qr/club/", ""))
                response.data ? setHasKm(1) : setHasKm(0);
                setModalStatus({
                  ...modalStatus,
                  visible: true,
                  type: "success-club",
                  text: "برای دریافت KV باید به فروشگاه امتیاز بدهید."
                });
              }
            })
            .catch(err => {
              err.response.data.data ? setHasKm(1) : setHasKm(0);
              if (err.response.status === 400) {
                setStoreId(data.replace("https://app.kartaak.com/qr/club/", ""))
                setModalStatus({
                  ...modalStatus,
                  visible: true,
                  type: "success-club",
                  text: "برای دریافت KV باید به فروشگاه امتیاز بدهید."
                });
              }
              if (err.response.status === 403) {
                setModalStatus({
                  ...modalStatus,
                  visible: true,
                  type: "error",
                  text: "نمی‌توان بیشتر از 3 بار در ماه نظر گذاشت. \n"
                });
              }
            })
            .finally(() => {
              setScaningStatus(false);
            });
          return;
        }

        setScaningStatus(true);
        const serverResponse = checkQrCode(
          userToken,
          data.replace("https://app.kartaak.com/qr/", "")
        );
        serverResponse.then((response) => {
          if (response.status === 401) {
            //nothing
            toast.error("پیش از اسکن بارکد، بایستی وارد شوید");
            history.push("/login");
            return;
          } else if (response.status === 403) {
            toast.error("بارکدی فرستاده نشد.");
            setModalStatus({
              ...modalStatus,
              visible: true,
              type: "error",
              text: "بارکدی فرستاده نشد.",
            });
            return;
          } else if (response.status === 404) {
            setModalStatus({
              ...modalStatus,
              visible: true,
              type: "error",
              text: "بارکد اسکن شده، معتبر نمی‌باشد.",
            });
            return;
          } else if (response.status >= 200 && response.status <= 299) {
            setModalStatus({
              visible: true,
              type: "success",
              count: response.data.data.count,
              text: "محتوا در کارتلای شما قرار گرفت!",
            });
            return;
          }
          setScaningStatus(false);
        });
      }
  };

  const handleInsertLink = (e) => {
    const value = e.target.value;
    setLinkInput(value);
  };

  const handleClickSubmit = async () => {
    if (linkInput.length <= 0) {
      const text = await navigator.clipboard.readText();
      setLinkInput(text);
    } else if (linkInput.includes("https://app.kartaak.com/qr/")) {
      // const findIndex = linkInput.indexOf("qr/");
      // const code = linkInput.substr(findIndex + 3);
      handleOnScan(linkInput);
    } else {
      toast.error("لینک وارد شده معتبر نمی باشد.");
    }
  };

  return (
    <div className="qrcode__wrapper">
      <div className="qrcode__parent">
        <div className="qrcase">
          <div className="qrcase__border__top-left">
            <SVG svg="qr-top-left"/>
          </div>
          <div className="qrcase__border__top-right">
            <SVG svg="qr-top-right"/>
          </div>
          <div className="qrcase__border__bottom-left">
            <SVG svg="qr-bottom-left"/>
          </div>
          <div className="qrcase__border__bottom-right">
            <SVG svg="qr-bottom-right"/>
          </div>

          <div className="qrcase__activebar">
            <SVG svg="qr-activeBar"/>
          </div>
        </div>
        <QrReader
          delay={300}
          onError={() => !scaningStatus && handleOnScan()}
          onScan={(e) => !scaningStatus && handleOnScan(e)}
          className="scaner"
          facingMode="environment"
        />
      </div>
      <div className="qrcode__body">
        <div className="qrcode__body__title">
          <span>اسکن بارکد</span>
        </div>
        <div className="qrcode__body__help">
          <span>
            در صورتی که بارکد ندارید یا دوربین شما فعال نیست، می‌توایند لینک
            بارکد را وارد نمایید.
          </span>
        </div>
        <div className="qrcode__body__order-link">
          <input
            placeholder="https://app.kartaak.com/qr/..."
            value={linkInput}
            className="qrcode__body__order-link__input"
            onChange={(e) => handleInsertLink(e)}
            dir={"ltr"}
            onFocus={() => handleInputFocus()}
            onBlur={() => handleInputBlur()}
          />
          <div
            onClick={() => handleClickSubmit()}
            className={`qrcode__body__order-link__btn ${linkInput.length > 0 && "active"
            }`}
          >
            <span>{linkInput.length > 0 ? "اعمال" : "جای گذاری"}</span>
          </div>
        </div>
      </div>
      <Modal
        active={modalStatus.visible}
        close={() => setModalStatus({...modalStatus, visible: false})}
        fullWidth
      >
        <div className="qrcode__modal">
          <SVG
            svg={`${modalStatus.type == "error" ? "red-error" : "green-check"}`}
          />
          <div className="qrcode__modal__accept">
            <span
              className={`qrcode__modal__accept__title ${modalStatus.type == "error" ? "error" : "success"
              }`}
            >
              {modalStatus.type == "error"
                ? "اسکن ناموفق بود"
                : "اسکن موفقیت آمیز بود"}
            </span>
            <span className="qrcode__modal__accept__bio">
              {modalStatus.count !== null
                ? `${modalStatus.count} ${modalStatus.text}`
                : modalStatus.text}
            </span>
            {hasKm === 0 && <span className="qrcode__modal__accept__notkm">
              {'KP ندارد'}
            </span>}
            {hasKm === 1 && <span className="qrcode__modal__accept__km">
              {'KP دارد'}
            </span>}
            <div className="qrcode__modal__accept__action">
              <div
                onClick={() =>
                  modalStatus.type == "error"
                    ? setModalStatus({...modalStatus, visible: false})
                    : (modalStatus.type === 'success-club' ? history.push(`/shop/comment/${storeId}`) : history.push("/kartella"))
                }
                className="qrcode__modal__accept__action__submit"
              >
                <span>
                  {modalStatus.type == "error" ? "بازگشت" : (modalStatus.type === 'success-club' ? 'رفتن به باشگاه‌ها' : "بازگشت به کارتلا")}
                </span>
                <SVG svg="backward-icon"/>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};
