import { ParticipationCertificateContainer } from 'containers';
import React from 'react';

const Participationcertificate = () => {
  return (
    <ParticipationCertificateContainer />
  );
}

export default Participationcertificate;
