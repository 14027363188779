import {Card, Fade, PageTopbar, Spinner, SVG} from "components";
import React, {useState, useEffect} from "react";
import {useHistory} from "react-router-dom";
import lotteryImg from '../../assets/images/lotteryImg.png';

export const NewLotteryContainer = () => {
  const history = useHistory();
  // *****************_______________________________________bache jan in ro baadan true kon_______________________________________________________________________***************
  const [loading, setloading] = useState(false);
  const [newLotteryloading, setNewLotteryLoading] = useState(false);
  const [newLotteryList, setNewLotteryList] = useState([]);


  // const getNewLottery = () => {
  //     setNewLotteryLoading(true);
  //     getNewLotteryAPI().then((res) => {
  //         if (res.status === 200) {
  //             setNewLotteryList(res.data);
  //             // setSellerDetailsLoading(false);
  //             // setIsFavorite(res.data.favorite);
  //         }
  //     }).catch(err => {

  //     });
  // };


  useEffect(() => {
    history.replace("/new-lottery");
    // getNewLottery()
  }, []);

  return (
    <React.StrictMode>
      <Fade>
        <Spinner active={loading} color="dark"/>
        <PageTopbar title="قرعه‌کشی" showKv={false}/>
        <Card>

          <div className="mainLotteryPage">
            <div className="mainLotteryPage__inner">
              {/*
                            {newLotteryList.length > 0 &&
                                newLotteryList.map((item, index) => ( */}
              {/* ))} */}

              <div className="mainLotteryPage__inner__lottery-img">
                <img src={lotteryImg} alt="lotteryImg"/>
                <p> قرعه کشی مزدا3 </p>
              </div>

              <div className="mainLotteryPage__inner__lottery-img">
                <img src={lotteryImg} alt="lotteryImg"/>
                <p> قرعه کشی مزدا3 </p>
              </div>
              <div className="mainLotteryPage__inner__lottery-img">
                <img src={lotteryImg} alt="lotteryImg"/>
                <p> قرعه کشی النترا </p>
              </div>
              <div className="mainLotteryPage__inner__lottery-img">
                <img src={lotteryImg} alt="lotteryImg"/>
                <p> قرعه کشی سامسونگُS9 </p>
              </div>
              <div className="mainLotteryPage__inner__lottery-img">
                <img src={lotteryImg} alt="lotteryImg"/>
                <p> قرعه کشی آیفون </p>
              </div>
              <div className="mainLotteryPage__inner__lottery-img">
                <img src={lotteryImg} alt="lotteryImg"/>
                <p> قرعه کشی آیفون </p>
              </div>
              <div className="mainLotteryPage__inner__lottery-img">
                <img src={lotteryImg} alt="lotteryImg"/>
                <p> قرعه کشی آیفون </p>
              </div>
              <div className="mainLotteryPage__inner__lottery-img">
                <img src={lotteryImg} alt="lotteryImg"/>
                <p> قرعه کشی آیفون </p>
              </div>
              <div className="mainLotteryPage__inner__lottery-img">
                <img src={lotteryImg} alt="lotteryImg"/>
                <p> قرعه کشی آیفون </p>
              </div>

            </div>
          </div>
        </Card>


      </Fade>
    </React.StrictMode>
  );
};