import { cartInitialState } from "./InitialState";
import { cartTypes } from "./Types";

export const CartReducer = (state = cartInitialState, { payload, type }) => {
  switch (type) {
    case cartTypes.SET_CART_DATA:
      return {
        ...state,
        cart: payload,
      };

    default:
      break;
  }
};
