import {useState} from 'react'
import './Toggle.scss'

export const Toggle = ({value, onChange}) => {

  return (
    <label className="switch">
      <input className="switch__input" type="checkbox" checked={value} onChange={onChange}/>
      <span className="slider round"/>
    </label>
  )
}