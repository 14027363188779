import { useEffect } from "react";
import { Fade, PageTopbar, SVG, Card,Hint } from "components";
import { useParams, useHistory } from "react-router-dom";

export const CardHistory = () => {
  const history = useHistory();
  const params: any = useParams();

  const handleDepositWithdrawName = (type) => {
    if (type === "payment") {
      return "withdraw";
    } else if (type === "checkout") {
      return "checkout";
    } else {
      return "deposit";
    }
  };

  useEffect(() => {
    history.replace("/cards/history/" + params.type + "/" + params.id);
  }, []);

  return (
    <Fade>
      <PageTopbar title="تاریخچه ی کارت دایموند" showKv={false} />
      <div className="card-list">
        <Card>
          <div className="cards__item">
            <div className="cards__item__body">
              <div className="cards__item__body__icon">
                <SVG svg="cards-diamond" />
              </div>
              <div className="cards__item__body__description">
                <span className="cards__item__body__description__title">
                  کارت دایموند
                </span>
                <span className="cards__item__body__description__value">
                  این کارت هم اکنون هر ماه برای شما ۹ امتیاز دارد
                </span>
              </div>
              <div className="cards__float-hint">
              <Hint theme="blue-purple"/>
            </div>
            </div>
          </div>
          <div className="cards__history">
            {["", ""].map((cashHistoryItem, index) => (
              <>
                <div className="cards__history__item" key={index}>
                  <div
                    className={`cards__history__type ${handleDepositWithdrawName(
                      "deposit"
                    )}`}
                  >
                    <SVG
                      svg={
                        handleDepositWithdrawName("checkout") == "checkout"
                          ? "withdraw"
                          : handleDepositWithdrawName("checkout")
                      }
                    />
                  </div>
                  <div className="cards__history__detail">
                    <div className="row">
                      <div
                        className={`cards__history__detail__type ${handleDepositWithdrawName(
                          "deposit"
                        )}`}
                      >
                        <SVG svg="cards-history-detail" />
                      </div>
                      <div className="col cards__history__detail__box">
                        <div className="cards__history__detail__amount">
                          {2 + "KV"}
                        </div>
                        <div className="cards__history__detail__source">
                          جهت خرید
                        </div>
                      </div>
                      <div className="cards__history__detail__date">
                        11/22/1396
                      </div>
                    </div>
                  </div>
                </div>
                {index !== 1 && <div className="cards__history__seperator" />}
              </>
            ))}
          </div>
        </Card>
      </div>
    </Fade>
  );
};
