import { Dots, Fade, Modal, PageTopbar, Spinner, SVG } from "components";
import moment from "jalali-moment";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useHistory } from "react-router";
import { farsiDigitToEnglish, transformPrice } from "utils/Functions";
import { MoneyWalletAPI } from "utils/Services/Wallet";
import { SettlementFee } from "utils/Services";
import { Hint } from "components/Hint";
import { getHintAPI } from "utils";

export const CashHistoryContainer = () => {
    const user_token = localStorage.getItem("user_token");
    const [loading, setLoading] = useState(true);
    const [loadingPay, setLoadingPay] = useState(false);
    const [activePay, setActivePay] = useState(false);
    const [shabaValue, setShabaValue] = useState("");
    const [payValue, setPayValue] = useState("");
    const history = useHistory();
    const [finalStep, setFinalStep] = useState(false);
    const [showHint, setShowHint] = useState(false);
    const [cashHistory, setCashHistory] = useState({
        detail: [],
        freeze: 0,
        info: {
            amount: 0,
        },
    });
    const [hint, setHint] = useState<any>({
        title: "",
        content: "",
    });

    const getUserWallet = () => {
        const APIResult = MoneyWalletAPI(user_token);
        APIResult.then((response) => {
            setCashHistory(response.data.data);
            setLoading(false);
        });
    };

    const handleDepositWithdrawName = (type) => {
        if (type === "payment") {
            return "withdraw";
        } else if (type === "checkout") {
            return "checkout";
        } else {
            return "deposit";
        }
    };

    const handleSourceTitle = (type) => {
        if (type === "payment") {
            return "برداشت از حساب";
        } else {
            return "واریز به حساب";
        }
    };

    const handleSubmitShaba = () => {
        if (shabaValue.length === 0) {
            toast.error("شماره شبای خود را وارد نمایید.");
            return;
        }

        if (shabaValue.length < 22) {
            toast.error("شماره شبای خود را به درستی وارد نمایید.");
            return;
        }
    };

    const handleSubmitPay = () => {
        if (loadingPay) return;
        if (Number(payValue) > Number(cashHistory.info.amount)) {
            toast.error("موجودی شما برای برداشت کافی نیست.", {
                id: "empty amount",
            });
            return;
        } else if (shabaValue.length === 0) {
            toast.error("شماره شبای خود را وارد نمایید.", {
                id: "empty sheba",
            });
            return;
        } else if (shabaValue.length < 22) {
            toast.error("شماره شبای خود را به درستی وارد نمایید.", {
                id: "sheba length",
            });
            return;
        } else if (payValue == "") {
            toast.error("مبلغ درخواستی را وارد نمایید.", {
                id: "empty payValue",
            });
        } else if (Number(payValue) < 1000) {
            toast.error("حداقل میزان برداشت ، 1,000 تومان می باشد.", {
                id: "withdraw handler",
            });
        } else {
            setFinalStep(true);
        }
    };

    const handleSecondSubmit = () => {
        setLoadingPay(true);
        // fix test to shaba information of owner
        SettlementFee(Number(payValue), shabaValue, "test")
            .then((res) => {
                if (res) {
                    getUserWallet();
                    setActivePay(false);
                }
            })
            .catch((err) => {
                toast.error("خطای ارتباط با سرور ، مجددا تلاش نمایید.");
                console.log(err.response);
            })
            .finally(() => setLoadingPay(false));
    };

    const handleOpenPayModal = () => {
        if (cashHistory.info.amount > 25000) {
            setActivePay(true);
        } else toast.error("حداقل موجودی قابل برداشت 25,000 تومان می باشد.");
    };

    const handleClosePayModal = () => {
        setPayValue("");
        setActivePay(false);
    };

    const getHint = () => {
        getHintAPI("راهنمای کلی 10")
            .then((response) => {
                setHint(response);
            })
            .catch(() => setHint(false))
            .finally(() => {});
    };

    useEffect(() => {
        getUserWallet();
        getHint();
        history.replace("/cash");
    }, []);

    return (
        <React.StrictMode>
            <Fade>
                <Spinner active={loading} color="dark" />
                <PageTopbar
                    title="تاریخچه کیف پول"
                    showKv={false}
                    circleHint={hint?.content}
                />
                {/*         
        <Modal active={showHint} close={() => setShowHint(false)} hint="price">
          <div className="hints__title">
            {hint.title}
          </div>
          <div className="hints__content">
            {hint.content?.split('\n')?.map(str => <div>{str}</div>)}
          </div>
        </Modal> */}

                <Modal
                    active={activePay}
                    close={(close) => {
                        setActivePay(!close);
                        setFinalStep(false);
                    }}
                >
                    {finalStep ? (
                        <div className="cash-history__pay__final">
                            <div className="cash-history__pay__final__text">
                                شما درخواست واریز
                                {" " + payValue + " "}
                                تومان به شماره شبای
                                {" " + shabaValue + " "}
                                به‌نام ....... را دارید.
                            </div>

                            <div className="cash-history__pay__final__footer">
                                <div
                                    onClick={() => handleSecondSubmit()}
                                    className="cash-history__pay__final__accept"
                                >
                                    {loadingPay ? (
                                        <Dots color="light" />
                                    ) : (
                                        "تایید"
                                    )}
                                </div>

                                <div
                                    className="cash-history__pay__final__decline"
                                    onClick={() => setFinalStep(false)}
                                >
                                    بازگشت
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="cash-history__pay">
                            <div className="cash-history__pay__header">
                                <div>دریافت وجه</div>
                                <div
                                    className="cash-history__pay__header__icon"
                                    onClick={() => handleClosePayModal()}
                                >
                                    <SVG svg="times" />
                                </div>
                            </div>

                            <div className="cash-history__pay__shaba">
                                شماره شبا
                            </div>

                            <div className="cash-history__pay__field">
                                <div className="cash-history__pay__field__input">
                                    <input
                                        type="text"
                                        value={shabaValue}
                                        onChange={(e) =>
                                            setShabaValue(
                                                farsiDigitToEnglish(
                                                    e.target.value
                                                )
                                            )
                                        }
                                        maxLength={24}
                                        // placeholder="شماره شبای شما"
                                    />
                                    <span>-IR</span>
                                </div>
                                {/* DONT REMOVE THIS */}
                                {/* <div
                    className="cash-history__pay__field__submit"
                    onClick={() => handleSubmitShaba()}
                  >استعلام</div> */}
                                {/* DONT REMOVE THIS */}
                            </div>

                            {/* DONT REMOVE THIS */}
                            {/* <div className="cash-history__pay__user">
                <SVG svg="cash-user" />
                به نام: امید حسنی
              </div> */}
                            {/* DONT REMOVE THIS */}

                            <div className="cash-history__pay__shaba">
                                <span>مبلغ درخواستی شما</span>
                                <span
                                    onClick={() =>
                                        setPayValue(
                                            String(cashHistory.info.amount)
                                        )
                                    }
                                    className="cash-history__pay__shaba__max"
                                >
                                    تمام موجودی
                                </span>
                            </div>

                            <input
                                type="tel"
                                value={payValue}
                                onChange={(e) =>
                                    setPayValue(
                                        farsiDigitToEnglish(e.target.value)
                                    )
                                }
                                placeholder={`مبلغ به تومان`}
                                className="cash-history__pay__input"
                            />

                            {/* <div className="cash-history__pay__price">
              <div>مبلغ</div>
              <div>{transformPrice(240500000)} تومان</div>
            </div> */}

                            <div
                                className="cash-history__pay__submit"
                                onClick={() => handleSubmitPay()}
                            >
                                درخواست تسویه حساب
                            </div>
                        </div>
                    )}
                </Modal>
                <div className="cash-history">
                    <div className="row a-c">
                        <div className="cash-history__circle"></div>
                        <div className="cash-history__card">
                            <div className="cash-history__card__detail">
                                <div className="row j-sb">
                                    <div className="row">
                                        {/* <SVG svg="cash-bag" /> */}
                                        <div className="cash-history__card__title">
                                            کیف نقدی
                                        </div>
                                    </div>

                                    <div className="cash-history__card__amount">
                                        موجودی حساب
                                    </div>
                                </div>

                                <div
                                    style={{ color: "#eb4d4c" }}
                                    className="cash-history__card__price"
                                >
                                    {isNaN(cashHistory.info.amount)
                                        ? "در حال محاسبه"
                                        : transformPrice(
                                              cashHistory.info.amount
                                          )}
                                </div>
                                <div className="cash-history__card2">
                                    قابل برداشت :
                                </div>
                                <div className="cash-history__card__price">
                                    {isNaN(cashHistory.info.amount)
                                        ? "در حال محاسبه"
                                        : transformPrice(
                                              cashHistory.info.amount -
                                                  cashHistory.freeze
                                          )}{" "}
                                    تومان
                                </div>
                            </div>

                            <div className="cash-history__card__action">
                                <div
                                    className="row a-c j-sb cash-history__card__action__col"
                                    onClick={() => handleOpenPayModal()}
                                >
                                    برداشت
                                    <SVG svg="black-harvest" />
                                </div>
                                <div
                                    className="row a-c j-sb cash-history__card__action__col"
                                    onClick={() =>
                                        history.push("/cash/history")
                                    }
                                >
                                    تاریخچه برداشت
                                    <SVG svg="cash-history" />
                                </div>
                            </div>
                        </div>
                    </div>

                    {cashHistory.detail ? (
                        <div className="cash-history__seperator" />
                    ) : (
                        <div className="cash-history__not-found">
                            <span>تاریخچه ای برای کیف پول وجود ندارد.</span>
                        </div>
                    )}

                    {cashHistory?.detail &&
                        cashHistory?.detail?.map((cashHistoryItem, index) => (
                            <React.StrictMode>
                                <div className="cash-history__item" key={index}>
                                    <div
                                        className={`cash-history__type ${handleDepositWithdrawName(
                                            cashHistoryItem.operation
                                        )}`}
                                    >
                                        <SVG
                                            svg={
                                                handleDepositWithdrawName(
                                                    cashHistoryItem.operation
                                                ) == "checkout"
                                                    ? "withdraw"
                                                    : handleDepositWithdrawName(
                                                          cashHistoryItem.operation
                                                      )
                                            }
                                        />
                                    </div>
                                    <div className="cash-history__detail">
                                        <div className="row">
                                            <div
                                                className={`cash-history__detail__type ${handleDepositWithdrawName(
                                                    cashHistoryItem.operation
                                                )}`}
                                            >
                                                <SVG
                                                    svg={
                                                        handleDepositWithdrawName(
                                                            cashHistoryItem.operation
                                                        ) == "checkout"
                                                            ? "withdraw-money"
                                                            : handleDepositWithdrawName(
                                                                  cashHistoryItem.operation
                                                              ) + "-money"
                                                    }
                                                />
                                            </div>

                                            <div className="col">
                                                <div className="cash-history__detail__amount">
                                                    {transformPrice(
                                                        cashHistoryItem.amount
                                                    ) + " تومان"}
                                                </div>
                                                <div className="cash-history__detail__source">
                                                    {cashHistoryItem.comment}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="cash-history__detail__date">
                                            {moment(
                                                cashHistoryItem?.created_at,
                                                "YYYY-MM-DD HH:mm:ss"
                                            )
                                                .locale("fa")
                                                .format("jYYYY/jM/DD")}
                                        </div>
                                    </div>
                                </div>

                                <div className="cash-history__seperator" />
                            </React.StrictMode>
                        ))}
                </div>
            </Fade>
        </React.StrictMode>
    );
};
