import {
    Card,
    Dots,
    Fade,
    Modal,
    NotFoundBox,
    PageTopbar,
    Spinner,
    SVG,
} from "../../components";
import React, { useEffect, useState } from "react";
import { Tabs } from "../OrdersContainer/dependencies";
import toast from "react-hot-toast";
import { useHistory } from "react-router-dom";
import badge from "../../assets/images/badge.png";
import { useUser } from "../../hooks";
import {
    getHintAPI,
    setUserContentUserRequestAPI,
    contentsForAdminEditorAPI,
    contentsForEditorAPI,
    contentListAPI,
    listOfContentVideosAPI,
    listOfVideosAPI,
    setSenderToEditorRequestAPI,
    UserContentRequestCountAPI,
    productsForOperatorAPI,
} from "../../utils";
import { farsiDigitToEnglish } from "utils/Functions";
import { cloneDeep } from "lodash";

export const OperatorContainer = () => {
    const history = useHistory();
    const {
        userState: { user },
    } = useUser();
    const [loading, setLoading] = useState(false);
    const [activeTab, setActiveTab] = useState(1);
    const [hintText, setHintText] = useState("");
    const [products, setProducts] = useState([]);

    const [tabs, setTabs] = useState([
        {
            title: "محتوا من",
            icon: "scores",
        },
    ]);

    const getProducts = () => {
        setLoading(true);
        productsForOperatorAPI()
            .then((res) => {
                if (res.status === 200) {
                    setProducts(res.data);
                }
            })
            .catch((err) => {
                toast.success("خطایی در دریافت اطلاعات رخ داده است.");
            })
            .finally(() => setLoading(false));
    };

    // const getHint = () => {
    //     getHintAPI("راهنمای اپراتور محصولات")
    //         .then((response) => {
    //             setHintText(response.content);
    //         })
    //         .catch((error) => {
    //             setHintText("دریافت راهنما با خطا مواجه شد");
    //         });
    // };

    useEffect(() => {
        getProducts();
        history.replace("/operator-management");
    }, []);

    return (
        <Fade>
            <PageTopbar
                title="بررسی محصولات"
                showKv={false}
                circleHint={hintText}
            />

            <div className="wrapper-fix">
                <Card>
                    {/*<div className="my-introduce__header row">*/}

                    {/*  <div className="col a-c" style={{marginBottom: "12px"}}>*/}
                    {/*    {*/}
                    {/*      <img*/}
                    {/*        onClick={() => {*/}
                    {/*          if(user.isEditor === 0) {*/}
                    {/*            setLoadStoreCount(true);*/}
                    {/*          }*/}
                    {/*        }}*/}
                    {/*        className={`my-introduce__badge mr0 ${*/}
                    {/*          user.isEditor === 0 && "deActive"*/}
                    {/*        }`}*/}
                    {/*        src={badge}*/}
                    {/*        alt="badge"*/}
                    {/*      />*/}
                    {/*    }*/}
                    {/*    <span>{user.isEditor === 0 ? "ویراستاری" : ""}</span>*/}
                    {/*  </div>*/}

                    {/*  <div onClick={() => {*/}
                    {/*    history.push('/create-content');*/}
                    {/*  }} className={`my-introduce__create submiter`}>*/}
                    {/*    ساخت محتوا جدید*/}
                    {/*  </div>*/}
                    {/*</div>*/}

                    {/*{tabs.length > 1 && <Tabs*/}
                    {/*  activeTab={activeTab}*/}
                    {/*  setActiveTab={setActiveTab}*/}
                    {/*  items={tabs}*/}
                    {/*/>}*/}

                    {activeTab === 1 && (
                        <div className="content__list">
                            {loading ? (
                                <Dots color={"dark"} />
                            ) : (
                                <>
                                    {products.length > 0 ? (
                                        <>
                                            {products?.length > 0 && (
                                                <div className={"title"}>
                                                    در انتظار بررسی
                                                </div>
                                            )}
                                            {products?.map((item, index) => (
                                                <div
                                                    onClick={() => {
                                                        history.push(
                                                            `/operator-product/${item.id}`
                                                        );
                                                    }}
                                                    className={
                                                        "my-introduce__submit__item"
                                                    }
                                                >
                                                    <div className="my-introduce__submit__item__title">
                                                        {`فروشگاه: ${item?.seller}`}
                                                    </div>
                                                    <div className="my-introduce__submit__item__details">
                                                        <div className="my-introduce__submit__item__pic">
                                                            <img
                                                                src={
                                                                    item
                                                                        ?.image[0]
                                                                }
                                                                alt="content-photo"
                                                            />
                                                        </div>
                                                        <div className="my-introduce__submit__item__info">
                                                            <div className="my-introduce__submit__item__info__address mb-8">
                                                                <div className="my-introduce__introduce__item__top__name">
                                                                    دسته‌بندی:{" "}
                                                                    {
                                                                        item
                                                                            ?.category[0]
                                                                            ?.item
                                                                            ?.title
                                                                    }
                                                                </div>
                                                            </div>

                                                            <div className="my-introduce__submit__item__info__name">
                                                                <div className="my-introduce__introduce__item__top__name">
                                                                    {
                                                                        item?.title
                                                                    }
                                                                </div>
                                                            </div>

                                                            <div className="my-introduce__submit__item__info__number">
                                                                <div className="my-introduce__introduce__item__top__name">
                                                                    {`${item?.description.slice(
                                                                        0,
                                                                        12
                                                                    )}`}
                                                                </div>
                                                                {/*<div className="my-introduce__introduce__item__bottom__delete">*/}
                                                                {/*  <SVG svg="create-remove"/>*/}
                                                                {/*</div>*/}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </>
                                    ) : (
                                        <NotFoundBox
                                            title="محصولی برای بررسی وجود ندارد"
                                            fullScreen={true}
                                            icon="kartella"
                                        />
                                    )}
                                </>
                            )}
                        </div>
                    )}
                </Card>
            </div>
        </Fade>
    );
};
