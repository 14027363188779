import { SVG } from 'components';
import { SwitchUser } from 'components/SwitchUser';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import './MyKartaak.scss';

export const MyKartaak = ({ showSwitchUser = false, setShowSwitchUser }) => {

  return (
    <div className="profile__items">

      {/*<div className='profile__items__row'>*/}
      {/*  <Link to={`/profile/info`} className="profile__items__item svg-coral">*/}
      {/*    اطلاعات فردی*/}
      {/*    <SVG svg="profile" />*/}
      {/*  </Link>*/}

      {/*  <Link to={`/profile/addresses`} className="profile__items__item svg-darkorange">*/}
      {/*    آدرس‌های من*/}
      {/*    <SVG svg="profile-location" />*/}
      {/*  </Link>*/}
      {/*</div>*/}

      {/*<div className='profile__items__row'>*/}
      {/*  <Link to={`/profile/orders`} className="profile__items__item svg-indigo">*/}
      {/*    سفارشات من*/}
      {/*    <SVG svg="profile-shop" />*/}
      {/*  </Link>*/}

      {/*  <Link to={`/favorites`} className="profile__items__item svg-red">*/}
      {/*    محصولات موردعلاقه*/}
      {/*    <SVG svg="profile-favorites" />*/}
      {/*  </Link>*/}
      {/*</div>*/}

      <div className='profile__items__row'>
        <Link to={`/setting`} className="profile__items__item svg-aqua">
          تنظیمات
          <SVG svg="setting" />
        </Link>

        {/*<div onClick={() => setShowSwitchUser(true)} className="profile__items__item svg-steelblue">*/}
        {/*  تغییر حساب کاربری*/}
        {/*  <SVG svg="create-user" />*/}
        {/*</div>*/}

        <Link to={`/profile/addresses`} className="profile__items__item svg-darkorange">
          آدرس‌های من
          <SVG svg="profile-location" />
        </Link>
      </div>

    </div>
  );
}