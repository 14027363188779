import React, {useEffect, useRef, useState} from "react";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import "mapbox-gl/dist/mapbox-gl.css";
import {SVG, Modal, Dots} from "components";
import {useHistory} from "react-router-dom";
import toast from "react-hot-toast";
import ReactDOM from "react-dom";
import {nearStoresAPI} from "../../../utils/Services/Introduce/NearStores";

mapboxgl.accessToken =
  "pk.eyJ1Ijoia2FydGFhayIsImEiOiJjbDNsaGw4NDIwY3R1M2VwNXAxZXNhemltIn0.oG3Q1VAF7wcyaUUvSCKxVw";

var currMarkers = [];

const IntroduceShopLocation = ({
                                 onSubmit,
                                 close,
                                 defaultLat = 32.674155,
                                 defaultLng = 51.657354,
                                 showStore = false
                               }) => {
  const history = useHistory();
  const mapContainer = useRef(null);
  const addressRef = useRef(null);
  // const [addressValue, setAddressValue] = useState(entryAddress);
  const map = useRef(null);
  const [lat, setLat] = useState(defaultLat);
  const [lng, setLng] = useState(defaultLng);
  const [zoom, setZoom] = useState(17);
  const [modal, setModal] = useState(false);
  const [current, setCurrent] = useState({});
  const [loading, setLoading] = useState(false);
  const [storeList, setStoreList] = useState([]);
  const [windowHeight, setWindowHeight] = useState(0);
  const [phaze, setPhaze] = useState(1);
  const [markers, setMarkers] = useState([]);
  const [activePopup, setActivePopup] = useState({status: false, data: []});
  const [showStores, setShowStores] = useState(showStore);
  console.log(lat);
  const geolocate = new mapboxgl.GeolocateControl({
    positionOptions: {
      enableHighAccuracy: true,
    },
    setZoom: 17,
    fitBoundsOptions: {maxZoom: 17},
    trackUserLocation: false,
    // showUserHeading: true,
  });

  const goToUserLocation = () => {
    const el = document.getElementsByClassName("mapboxgl-ctrl-geolocate");
    el && el[0].click();
  };

  const handleSubmit = () => {
    // if (addressValue == "") {
    //   toast.error("آدرس متنی را وارد نمایید");
    //   return;
    // }
    setLoading(true);
    onSubmit({lat: current.lat, lng: current.lng});
  };

  const goToUserLocation2 = () => {
    try {
      navigator.geolocation.getCurrentPosition(
        (res) => {
          console.log(res);
          setLat(res.coords.latitude);
          setLng(res.coords.longitude);
          map.current.flyTo({
            center: [parseFloat(res.coords.longitude), parseFloat(res.coords.latitude) + 0.0006],
            zoom: 17,
            speed: 4
          });
        },
        (err) => {
          // err.code === 1 &&
          toast.error('دسترسی به موقعیت جغرافیایی ناموفق بود');
        }
      );
      const el = document.getElementsByClassName("mapboxgl-ctrl-geolocate");
      el && el[0].click();
    } catch (error) {
    }
  };

  const renderMarker = () => {
    markers.map((item) => {
      const elMarker = document.createElement("div");
      elMarker.id = item.id;
      elMarker.style.maxHeight = "42px";
      ReactDOM.render(
        (
          <div className={'map__pin-box'}>
            <div className="map__pin-box__name">{item.name}</div>
            <SVG
              svg="map-marker"
              fillColor={
                item.lat === defaultLat && item.lng === defaultLng ? "rgb(10,10,10)" : "#a1a1a1"
              }
            />
          </div>
        ),
        elMarker
      );
      const newMarker = new mapboxgl.Marker(elMarker, {
        anchor: "bottom",
        offset: [0, 4],
      })
        .setLngLat([parseFloat(item.lng), parseFloat(item.lat)])
        .addTo(map.current);

      newMarker.getElement().addEventListener("click", (e) => {
        const centerLatLng = map.current.getCenter();
        const currZoom = map.current.getZoom();
        // history.replace(
        //   `/map?lat=${centerLatLng.lat}&lng=${centerLatLng.lng}&zoom=${currZoom}`
        // );
        // map.current.flyTo({
        //   center: [parseFloat(item.lng), parseFloat(item.lat) + 0.0006],
        //   zoom: 17,
        //   speed: 4,
        // });
        setTimeout(() => {
          //TODO
          setActivePopup({
            status: true,
            data: {
              name: item.name,
            },
          });
        }, 200);
      });
      currMarkers.push(newMarker);
    });
  };

  const getNearestLocation = (lat, lng) => {
    nearStoresAPI(lat, lng)
      .then((res) => {
        if (res) {
          setMarkers(res.data);
        }
      })
      .finally(() => {
      });
  };

  useEffect(() => {
    if (map.current) return; // initialize map only once

    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      // style: "mapbox://styles/mapbox/streets-v11",
      style: "mapbox://styles/kartaak/cl37c9d8c001y14la74hqn2ze",
      center: [lng, lat],
      zoom: zoom,
      minZoom: 4.5,
      maxZoom: 20.5,
      localFontFamily: "VazirFaNum",
      localIdeographFontFamily: "VazirFaNum",
      logoPosition: "top-left",
      touchPitch: false,
    });
    map.current.dragRotate.disable();
    map.current.touchZoomRotate.disableRotation();

    map.current.addControl(geolocate);

    map.current.setMaxBounds([
      [44.032434, 25.057032],
      [63.33335, 39.78205],
    ]);

    // map.current.setMaxBounds([
    //   [defaultLng - 5, defaultLat - 5],
    //   [32.64595, 33],
    // ]);

    map.current.on("load", function () {
      map.current.addLayer(
        {
          id: "country-boundaries",
          source: {
            type: "vector",
            url: "mapbox://mapbox.country-boundaries-v1",
          },
          "source-layer": "country_boundaries",
          type: "fill",
          paint: {
            "fill-color": "#efe9e1",
            "fill-opacity": 0.9,
          },
        },
        "country-label"
      );

      map.current.setFilter("country-boundaries", [
        "in",
        "iso_3166_1_alpha_3",
        "PAK",
        "IRQ",
        "AFG",
        "KWT",
        "TKM",
        "QAT",
        "TUR",
        "SAU",
        "AZE",
        "ARE",
        "ARM",
      ]);
    });

    setCurrent(map.current.getCenter());

    map.current.on("moveend", () => {
      setCurrent(map.current.getCenter());
    });
  });

  useEffect(() => {
    // if (map.current) return; // initialize map only once
    if (modal) return; // initialize map only once

    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/kartaak/cl37c9d8c001y14la74hqn2ze",
      center: [lng, lat],
      zoom: zoom,
      minZoom: 4.5,
      maxZoom: 20.5,
      localFontFamily: "VazirFaNum",
      localIdeographFontFamily: "VazirFaNum",
      logoPosition: "top-left",
      touchPitch: false,
    });
    map.current.dragRotate.disable();
    map.current.touchZoomRotate.disableRotation();

    map.current.addControl(geolocate);

    map.current.setMaxBounds([
      [44.032434, 25.057032],
      [63.33335, 39.78205],
    ]);

    // map.current.setMaxBounds([
    //   [defaultLng - 5, defaultLat - 5],
    //   [defaultLng + 5, defaultLat + 5],
    // ]);

    map.current.on("load", function () {
      map.current.addLayer(
        {
          id: "country-boundaries",
          source: {
            type: "vector",
            url: "mapbox://mapbox.country-boundaries-v1",
          },
          "source-layer": "country_boundaries",
          type: "fill",
          paint: {
            "fill-color": "#efe9e1",
            "fill-opacity": 0.9,
          },
        },
        "country-label"
      );

      map.current.setFilter("country-boundaries", [
        "in",
        "iso_3166_1_alpha_3",
        "PAK",
        "IRQ",
        "AFG",
        "KWT",
        "TKM",
        "QAT",
        "TUR",
        "SAU",
        "AZE",
        "ARE",
        "ARM",
      ]);
    });

    setCurrent(map.current.getCenter());

    map.current.on("moveend", () => {
      setCurrent(map.current.getCenter());
    });
  }, [windowHeight, modal]);

  useEffect(() => {
    setWindowHeight(window.innerHeight);
    // console.log(window.innerHeight);
  }, [window.innerHeight]);

  useEffect(() => {
    if (
      document.getElementsByClassName("mapboxgl-ctrl-geolocate")[0] !==
      undefined
    )
      goToUserLocation();
  }, [document.getElementsByClassName("mapboxgl-ctrl-geolocate")[0]]);

  useEffect(() => {
    currMarkers.forEach((marker) => {
      marker.remove();
    });
    renderMarker();
  }, [markers]);


  useEffect(() => {
    if (current.lat !== undefined && current.lng !== undefined && !showStores) {
      getNearestLocation(current.lat, current.lng);
    }
  }, [current]);


  useEffect(() => {
    if (showStore) {
      getNearestLocation(lat, lng);
    }
  }, [showStore]);

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        zIndex: 6,
        width: "100%",
        height: window.innerHeight,
        overflow: "hidden",
        backgroundColor: "#e6e4e0",
      }}
    >
      <div className="map__top-right"/>

      <div className="map__geolocate top-right" onClick={() => close()}>
        <SVG svg="times"/>
      </div>
      {!showStores && <div
        className="map__geolocate under-top-right"
        onClick={() => goToUserLocation2()}
      >
        <SVG svg="geolocate"/>
      </div>}
      <div
        ref={mapContainer}
        style={{
          height: "100%",
        }}
      />
      <div
        className="map"
        style={{
          height: "64px",
          width: "۶۰px",
          position: "fixed",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div style={{marginBottom: 24}}>
          <SVG svg="add-location-marker"/>
        </div>
      </div>

      <div
        className={`button fixed left-0 right-0 bottom-16 z-index-2 width-full-16 ${
          modal && "exitAnimation"
        }`}
        onClick={() => handleSubmit()}
      >
        ثبت موقعیت جغرافیایی
      </div>

      {/*{!showStores && <div*/}
      {/*  className={`button green fixed left-0 right-0 bottom-16 z-index-2 width-full-16 ${*/}
      {/*    modal && "exitAnimation"*/}
      {/*  }`}*/}
      {/*  onClick={() => {*/}
      {/*    setShowStores(true);*/}
      {/*    getNearestLocation(current.lat, current.lng);*/}
      {/*  }}*/}
      {/*>*/}
      {/*  بررسی فروشگاه‌های اطراف*/}
      {/*</div>}*/}
      {/*<Modal active={modal} close={() => close()}>*/}
      {/*  <div className="mt-8">*/}
      {/*    <span className="">*/}
      {/*      آدرس متنی{" "}*/}
      {/*      <small className="creation-location__title__slug">*/}
      {/*        ( برای نمایش به کاربر )*/}
      {/*      </small>*/}
      {/*    </span>*/}
      {/*  </div>*/}
      {/*  <textarea*/}
      {/*    placeholder="آدرس محل فروشگاه را وارد نمایید"*/}
      {/*    value={entryString + addressValue.substr(entryString.length)}*/}
      {/*    className=" mt-10 input withoutHeight"*/}
      {/*    name="text"*/}
      {/*    rows={10}*/}
      {/*    onChange={(e) => setAddressValue(e.target.value)}*/}
      {/*  />*/}
      {/*  <div*/}
      {/*    className="button"*/}
      {/*    onClick={() => {*/}
      {/*      if (addressValue.length === 0) {*/}
      {/*        toast.error("آدرس فروشگاه را وارد نمایید");*/}
      {/*        return;*/}
      {/*      }*/}
      {/*      setModal(false);*/}
      {/*    }}*/}
      {/*  >*/}
      {/*    {loading ? <Dots color="light"/> : "ثبت آدرس"}*/}
      {/*  </div>*/}
      {/*</Modal>*/}
      {/* <div
        className="map"
        style={{
          borderRadius: "50%",
          backgroundColor: "red",
          position: "fixed",
        }}
      /> */}
    </div>
  );
};

export default IntroduceShopLocation;
