import {Request} from "../api-call";

export const reportStoreAPI = async (storeId, storeType, message) => {
  // let formData = new FormData();
  // formData.append("message", message);
  const {data} = await Request.post(
    `/api/v3/kartland/userReport?storeId=${storeId}&type=${storeType}&message=${message}`
  );
  return data;
};
