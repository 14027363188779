import { useEffect } from "react";
import { Fade, PageTopbar, SVG, Card, Hint } from "components";
import { useParams, useHistory } from "react-router-dom";

export const CardList = () => {
  const history = useHistory();
  const params: any = useParams();

  useEffect(() => {
    history.replace("/cards/list/" + params.type);
  }, []);

  return (
    <Fade>
      <PageTopbar title="تاریخچه ی کارت ها" showKv={false} />
      <div className="card-list">
        <Card>
          {["", "", ""].map((item, index) => (
            <div className="cards__item">
              <div className="cards__item__body">
                <div className="cards__item__body__icon">
                  <SVG svg="cards-diamond" />
                </div>
                <div className="cards__item__body__description">
                  <span className="cards__item__body__description__title">
                    کارت دایموند
                  </span>
                  <span className="cards__item__body__description__value">
                    این کارت هم اکنون هر ماه برای شما ۹ امتیاز دارد
                  </span>
                </div>
              </div>
              <div className="cards__item__footer">
                <span>تاریخچه</span>
                <SVG svg="cards-history" />
              </div>
              <div className="cards__float-hint">
                <Hint theme="blue-purple" />
              </div>
            </div>
          ))}
        </Card>
      </div>
    </Fade>
  );
};
