import { useState } from "react";
import { SVG, Share } from "components";
import { useUser } from "hooks";
import toast from "react-hot-toast";
import { useHistory } from "react-router-dom";

export const Identifier = () => {
  const history = useHistory();
  const {
    userState: { user },
  } = useUser();
  const [showSharing, setShowSharing] = useState(false);

  const copy = () => {
    const value: any = document.getElementById("identifier_panel_code");
    value.select();
    document.execCommand("copy");

    toast.success("کد کارتک شما کپی شد!");
  };

  return (
    <div className="marketing__identifier">
      <Share
        withBtnNav
        active={showSharing}
        setClose={(close) => setShowSharing(close)}
        title="ارسال کد نمایندگی کارتک"
        value={`این کد نمایندگی کارتک را به دوستان خود بدهید`}
        link={user.detail?.inviteCode}
      />
      <div className="marketing__identifier__code">
        <span className="marketing__identifier__code__title">
          کد نماینده معرف :
        </span>
        <input
          readOnly
          type="text"
          value={user.detail?.inviteCode}
          id="identifier_panel_code"
          className="marketing__identifier__code__value"
        />
      </div>
      <div className="marketing__identifier__actions">
        <div
          onClick={() => setShowSharing(true)}
          className="marketing__identifier__actions__share"
        >
          <SVG svg="share" />
          <span>اشتراک گذاری</span>
        </div>
        <div
          onClick={() => copy()}
          className="marketing__identifier__actions__copy"
        >
          <span>کپی کردن کد</span>
          <SVG svg="copy" />
        </div>
      </div>
      <div
        onClick={() => history.push("/collaboration")}
        className="marketing__identifier__footer"
      >
        <div className="marketing__identifier__footer__shape">
          <SVG svg="bottom-shape" color="white" />
        </div>
        <div className="marketing__identifier__footer__shape reverse">
          <SVG svg="bottom-shape" color="white" />
        </div>
        <SVG svg="partners" />
        <span>همکاران من</span>
      </div>
    </div>
  );
};
