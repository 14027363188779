import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router } from "react-router-dom";
import { AppContextProvider } from "./context/AppContext";
import { Toaster } from "react-hot-toast";
import { AuthProvider, CardProvider, UserProvider } from "providers";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

// import { hotjar } from "react-hotjar";

// hotjar.initialize(2544415, 6);

// Sentry.init({
//   dsn: "https://c65e4bf33c8a434dbb16121568ad68df@o4504037233393664.ingest.sentry.io/4504037279793152",
//   beforeBreadcrumb(breadcrumb, hint) {
//     if (breadcrumb.category === "console") {
//       return null;
//     }
//     return breadcrumb;
//   },
//   integrations: [new Integrations.BrowserTracing()],
//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 0.4,
// });

ReactDOM.render(
    <React.StrictMode>
        <AppContextProvider>
            <AuthProvider>
                <UserProvider>
                    <CardProvider>
                        <Toaster
                            position="top-center"
                            gutter={8}
                            containerStyle={{
                                top: 8,
                            }}
                            toastOptions={{
                                duration: 2300,
                                style: {
                                    borderRadius: 50,
                                    padding: "6px 12px",
                                    fontSize: 13,
                                    fontWeight: 700,
                                    background: "#F9F9FB",
                                    border: "3px solid #ffffff",
                                    width: "100%",
                                    maxWidth: "max-content",
                                    boxShadow:
                                        "0px 4px 30px rgba(0, 0, 0, 0.15)",
                                },
                            }}
                        />
                        <Router>
                            <App />
                        </Router>
                    </CardProvider>
                </UserProvider>
            </AuthProvider>
        </AppContextProvider>
    </React.StrictMode>,
    document.getElementById("KShop")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
