import React, {useState, useEffect} from "react";
import {SVG, Spinner, Fade, Modal, Dots} from "components";
import {Link, useHistory} from "react-router-dom";
import {
  GetCategories,
  adsKvValueAPI,
  kartellaHasAdsAPI,
  hasSepcialAdsAPI,
  adsSpecialCountAPI,
  adsScanInfoAPI,
  adsChargeAPI,
} from "utils/Services/Kartella";
import {getQrAPI, checkQrCode} from "utils/Services/API";
import {useUser} from "hooks";
import {adsCategoryCountAPI} from "utils/Services/Kartella/AdsCategoryCount";
import toast from "react-hot-toast";
import {useImageOnLoad} from "hooks";

export const KartellaContainer = () => {
  const {
    userState: {user},
  } = useUser();
  const user_token = localStorage.getItem("user_token");
  const {handleImageOnLoad, css} = useImageOnLoad();
  const [seenStatus, setSeenStatus] = useState([]);
  const [specialSeenStatus, setSpecialSeenStatus] = useState<any>({});
  const [categorysData, setCategorysData] = useState([]);
  const [activeCategory, setActiveCategory] = useState<any>([]);
  const [hintModal, setHintModal] = useState({
    kvInfo: false,
    kvDose: false,
    kvAssign: false,
    qrCharge: false,
    qrInfo: false,
    qrMine: false,
    qrDose: false,
  });
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [modalImage, setModalImage] = useState(null);
  const [modalCat, setModalCat] = useState(null);
  const [modalCatTitle, setModalCatTitle] = useState("");
  const [modalCatCount, setModalCatCount] = useState([]);
  const [modalKvCount, setModalKvCount] = useState([]);
  const [lastScan, setLastScan] = useState<any>({});
  const [chargeLoading, setChargeLoading] = useState(false);
  const history = useHistory();

  const getAdsKv = () => {
    const api = adsKvValueAPI();
    api.then((response) => {
      setModalKvCount(response.data);
    });
  };

  const getCategoryAds = (cat) => {
    const api = adsCategoryCountAPI(cat);
    api.then((response) => {
      // var currSeenStatus = cloneDeep(seenStatus);
      // currSeenStatus.push(response.data[0]);
      // setSeenStatus(currSeenStatus);

      setModalCatCount(response.data[0]);
    });
  };

  const getSpecialAds = () => {
    const api = adsSpecialCountAPI();
    api.then((response) => {
      setModalCatCount(response.data[0]);
    });
  };

  const getUserQr = () => {
    setChargeLoading(true);
    const api = getQrAPI(user_token);
    api.then((response) => {
      switch (response.status) {
        case 200:
          scanQrForCharge(response.data.data.barcode.code);
          break;
        case 404:
          setChargeLoading(false);
          setHintModal({...hintModal, qrCharge: true});
          break;
        default:
          break;
      }
    });
  };

  const scanQrForCharge = (userQrCode) => {
    const serverResponse = checkQrCode(user_token, userQrCode);
    serverResponse.then((response) => {
      if (response.status === 401) {
        toast.error("پیش از اسکن بارکد، بایستی وارد شوید");
        history.push("/login");
      } else if (response.status === 403) {
        toast.error("بارکدی فرستاده نشد.");
      } else if (response.status === 404) {
        toast.error("بارکد شما، معتبر نمی‌باشد.");
      } else if (response.status >= 200 && response.status <= 299) {
        if (response.data.data.count == 0) {
          toast.error("محتوایی برای شارژ کردن وجود ندارد.");
        } else {
          getKartellaHasAds();
          toast.success(
            `${response.data.data.count} محتوا در کارتلای شما قرار گرفت!`
          );
        }
      }
      setChargeLoading(false);
    });
  };

  const onClickCharge = () => {
    if (!chargeLoading) getUserQr();
  };

  const getKartellaHasAds = () => {
    kartellaHasAdsAPI().then((response) => {
      setSeenStatus(response.data);
    });
  };

  const getSpecialHasAds = () => {
    hasSepcialAdsAPI().then((response) => {
      setSpecialSeenStatus(response);
    });
  };

  const getLastScan = () => {
    adsScanInfoAPI().then((response) => {
      setLastScan(response.data);
    });
  };

  const chargeKartella = () => {
    adsChargeAPI()
      .then((response) => {
        if (response.status === 200) {
          if (response.data.count === 0) {
            toast.error(`محتوایی برای شارژ شدن پیدا نشد.`);
            setHintModal({...hintModal, qrCharge: false});
          } else {
            toast.success(`${response.data.count} محتوا برای شما شارژ شد.`);
            getKartellaHasAds();
          }
          getLastScan();
        }
      })
      .catch((err) => {
        if (err.response.status) {
          toast.error("بارکدی یافت نشد.");
        }
      });
  };

  useEffect(() => {
    // axios('https://dev.kartaak.com/v3/adminPanel/token/kv/3')

    // history.replace("/kartella");
    // getUserDataFromApi();
    GetCategories().then((res) => {
      setLoading(false);
      if (res && res.data.length > 0) {
        setCategorysData(res.data);
        setActiveCategory([res.data[0]]);
      } else setLoading(false);
    });

    // setShowHint(true);
    // getKartellaHasAds();
    // getSpecialHasAds();
    // getLastScan();
  }, []);

  return (
    <Fade>
      <div className="kartella">
        <Modal active={showModal} close={() => setShowModal(false)}>
          {/* <div
            onClick={() => setShowModal(false)}
            className="kartella__scores__close"
          >
            <SVG svg="times" />
          </div> */}
          <div
            style={{
              marginBottom: 8,
              fontSize: 13,
              fontWeight: 700,
              textAlign: "center",
            }}
          >
            {modalCatTitle}
          </div>

          <div className="kartella__scores">
            {[{}, {}, {}, {}, {}, {}, {}].map((item, index) => (
              <div
                className="kartella__scores__item"
                style={{
                  background: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.5)) ,url(${modalImage})`,
                }}
                onClick={() => {
                  if (modalCatCount[index]?.unseen === 0) {
                    toast.error("محتوایی برای نمایش وجود ندارد.");
                    return;
                  } else {
                    if (modalCatTitle === "برای شما") {
                      history.push(
                        `./ads?rate=${index + 1}&specific=true&type=unSeen`
                      );
                    } else {
                      history.push(
                        `./ads?rate=${index + 1}&cat=${modalCat}&type=unSeen`
                      );
                    }
                  }
                }}
              >
                <div className="kartella__scores__count">
                  <span>{index + 1}</span>
                </div>

                <div className="kartella__scores__title">
                  {/* <div className="kartella__scores__number">
                    <SVG svg="seen" />
                  </div> */}
                  <div className="kartella__scores__number__title">محتوا</div>
                  <div className="kartella__scores__kv__number">
                    {modalCatCount[index]?.unseen}
                  </div>
                </div>

                <div className="kartella__scores__title kv">
                  <div className="kartella__scores__kv">
                    <SVG svg="kv"/>
                  </div>
                  <div className="kartella__scores__kv__number">
                    {/* <Dots color="light" /> */}
                    {modalKvCount[index]}
                  </div>
                </div>

                <div className="kartella__scores__play">
                  <SVG svg="play"/>
                </div>
              </div>
            ))}
          </div>
          {user?.detail?.star < 7 && (
            <React.Fragment>
              <div className="kartella__scores__header">
                <div className="kartella__scores__header__title">
                  شما
                  <span>{user?.detail?.star}</span>
                  ستاره هستید.
                  {/* <br /> */}
                  <Link to="/profile">ستاره‌هاتونو</Link>
                  بیشتر کنید تا KV بیشتری بگیرید.
                </div>
              </div>
            </React.Fragment>
          )}
        </Modal>

        <div className={`kartella__categories ${!loading && "active"}`}>
          {loading ? (
            <Dots color="dark"/>
          ) : (
            <React.Fragment>
              {categorysData.map((category, index) => (
                <div
                  className={`kartella__categories__item ${seenStatus[index]?.isItems === true ? "active" : "deactive"
                  }`}
                  key={index}
                  onClick={() => {
                    history.push(`/kartella/seen?catId=${category.id}&catTitle=${category.title}`);
                    // history.push(`/ads?cat=${category.id}&type=seen`);
                    // if (seenStatus.length === 0) {
                    //   toast.error("محتوایی برای نمایش وجود ندارد.");
                    //   return;
                    // }
                    // if (seenStatus[index]?.isItems === false) {
                    //   toast.error("محتوایی برای نمایش وجود ندارد.");
                    //   return;
                    // }
                    // setShowModal(true);
                    // setModalImage(`${category.photo}`);
                    // setModalCat(category.id);
                    // setModalCatTitle(category.title);
                    // getAdsKv();
                    // getCategoryAds(category.id);
                  }}
                >
                  <img
                    onLoad={handleImageOnLoad}
                    style={{...css.fullSize}}
                    src={`${category.photo}`}
                    className="kartella__categories__item__image"
                    alt="fullImage"
                  />
                  <div className="kartella__categories__item__title">
                    {seenStatus.map(
                      (seenStatusItem, seenStatusIndex) =>
                        seenStatusIndex === index && (
                          <div
                            className={`kartella__categories__item__seen ${seenStatusItem.isItems === true
                              ? "active"
                              : "deactive"
                            }`}
                          />
                        )
                    )}
                    {category.title}
                  </div>
                </div>
              ))}
            </React.Fragment>
          )}
        </div>
        {/* <Spinner color="dark" active={loading} /> */}

      </div>
    </Fade>
  );
};
