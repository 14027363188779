import React from "react";
import "./ShopLoading.scss";

export const ShopLoading = () => {
  const items = [{}, {}, {}, {}];

  return (
    <div className="shop-loading">
      {items.map(() => (
        <div className="shop-loading__item">
          <div className="col">
            <div className="shop-loading__item__image" />
            <div className="shop-loading__item__desc" />
            <div className="shop-loading__item__price" />
          </div>
          <div className="shop-loading__item__button" />
        </div>
      ))}
    </div>
  );
};
