import React, { useEffect, useState } from "react";
import {
    Fade,
    PageTopbar,
    SVG,
    Card,
    Hint,
    Spinner,
    Modal,
    NotFoundBox,
    Dots,
    Selectbox,
    SlidingHint,
} from "components";
import {
    buyCardAPI,
    getCardStoreAPI,
    getHintAPI,
    getGiftCardListAPI,
    getOperatorAPI,
    ReceiveGiftCardAPI,
    adsChargeAPI,
} from "utils";
import { useUser } from "hooks";
import { Tabs } from "containers/OrdersContainer/dependencies";
import toast from "react-hot-toast";
// import {CardsModal} from "./dependencies";
import { Link, useHistory } from "react-router-dom";
import card from "../../assets/images/card.jpg";
import { createUserAddressAPI, getUserAddresses } from "utils/Services/API";
import { CreateAddress } from "../../components/CreateAddress";

export const CardBoxContainer = ({ children }) => {
    const history = useHistory();
    const { getUserDataFromApi } = useUser();
    const [loading, setLoading] = useState(true);
    const [buyLoading, setBuyLoading] = useState(-1);
    const [giftLoading, setGiftLoading] = useState(-1);
    const [cardsList, setCardsList] = useState([]);
    const [myCards, setMyCards] = useState([]);
    const [showHint, setShowHint] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [showFail, setShowFail] = useState(false);
    const [hintDescription, setHintDescription] = useState("");
    const [activeTab, setActiveTab] = useState(1);
    const [hintModal, setHintModal] = useState({
        kvInfo: false,
        kvDose: false,
        kvAssign: false,
        qrCharge: false,
        qrInfo: false,
        qrMine: false,
        qrDose: false,
    });
    const [showHelpRadio, setShowHelpRadio] = useState(false);
    const [showHelp, setShowHelp] = useState(true);
    const [showCondition, setShowCondition] = useState(-1);
    const [sortValue, setSortValue] = useState("");
    const [filterValue, setFilterValue] = useState("");
    const [receivePrizeModal, setReceivePrizeModal] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState("");
    const [operator, setOperator] = useState("");
    const [myOperator, setMyOperator] = useState("");
    const [receiveType, setReceiveType] = useState(0);
    const {
        userState: { user },
    } = useUser();
    const [buyingCard, setBuyingCard] = useState({ cardId: -1, index: -1 });
    const [ghasedak, setGhasedak] = useState(false);
    const [cardGiftType, setCardGiftType] = useState("");
    const [cardLoading, setCardLoading] = useState(false);
    const [counter, setCounter] = useState(20);
    const [hint1, setHint1] = useState<any>("");
    const [hint2, setHint2] = useState<any>("");
    const [hintLoading, setHintLoading] = useState(true);

    const [showAddressModal, setShowAddressModal] = useState(false);
    const [showLoadingAddressModal, setShowLoadingAddressModal] =
        useState(false);
    const user_token = localStorage.getItem("user_token");
    const [addresses, setAddresses] = useState([]);
    const [modalSelectedAddress, setModalSelectedAddress] = useState({});
    const [addNewAddress, setAddNewAddress] = useState({
        active: false,
        address: null,
    });
    const [currentCardID, setCurrentCardID] = useState(0);
    const [currentCardIndex, setCurrentCardIndex] = useState(0);
    const [buyButtonSpinner, setBuyButtonSpinner] = useState(false);
    const [productCard, setProductCard] = useState(false);
    const [reciverValue, setReciverValue] = useState("");
    const [slidingHintStatus, setSlidingHintStatus] = useState(false);
    const [slidingHintText, setSlidingHintText] = useState("");

    const getCardsList = () => {
        setCardLoading(true);
        getCardStoreAPI(filterValue, sortValue, reciverValue, "")
            .then((response) => {
                if (response.status === 200) {
                    setCardsList(response.data);
                }
            })
            .catch((err) => {})
            .finally(() => {
                setLoading(false);
                setCardLoading(false);
            });
    };

    const getGiftCardsList = () => {
        getGiftCardListAPI()
            .then((response) => {
                if (response.status === 200) {
                    setMyCards(response.data);
                }
            })
            .catch((err) => {})
            .finally(() => {
                // setLoading(false);
            });
    };

    const buyCard = (cardId, index) => {
        setBuyLoading(index);
        buyCardAPI(cardId)
            .then((response) => {
                if (response.status === 200) {
                    // ReceiveGift(cardId, -1);
                    getUserDataFromApi();
                    getCardsList();
                    ReceiveGift(response.data.cardId, -1);
                }
            })
            .catch((err) => {
                if (err.response.status === 403) {
                    toast.error("مقدار KV شما کافی نیست.");
                    setBuyLoading(-1);
                    return;
                }
                toast.error("شما شرایط خرید این کارت را ندارید.");
                setBuyLoading(-1);
            })
            .finally(() => {
                // setBuyLoading(-1);
                setAddNewAddress({ active: false, address: null });
                setShowAddressModal(false);
                setBuyButtonSpinner(false);
            });
    };

    const ReceiveGift = (cardId, index) => {
        setGiftLoading(index);
        let addressFormData = new FormData();
        if (productCard) {
            const tempAddress =
                modalSelectedAddress["state"]["name"] +
                " ، " +
                modalSelectedAddress["city"]["name"] +
                " ، " +
                modalSelectedAddress["address"].toString() +
                " . کد پستی : " +
                modalSelectedAddress["postalCode"];
            addressFormData.append("address", tempAddress);
        }
        ReceiveGiftCardAPI(
            cardId,
            ghasedak
                ? receiveType === 0
                    ? user.mobile_number
                    : phoneNumber
                : "",
            ghasedak ? (receiveType === 0 ? myOperator : operator) : "",
            productCard && addressFormData
        )
            .then((response) => {
                if (response.status === 200) {
                    getGiftCardsList();
                    setShowSuccess(true);
                    setReceivePrizeModal(false);
                }
            })
            .catch((err) => {
                if (err.response.status === 402) {
                    toast.error("این کارت قبلا استفاده شده است.");
                } else if (err.response.status === 403) {
                    setShowFail(true);
                    setReceivePrizeModal(false);
                } else {
                    toast.error("شما شرایط خرید این کارت را ندارید.");
                }
            })
            .finally(() => {
                setGiftLoading(-1);
                setBuyLoading(-1);
                setProductCard(false);
                setGhasedak(false);
            });
    };

    const unitType = (type) => {
        switch (type) {
            case "whiteKM":
                return <span>عدد KM</span>;
            case "redKM":
                return <span>عدد KP</span>;
            case "money":
                return <span>تومان</span>;
        }
    };
    // const getHint = () => {
    //   getHintAPI("صفحات داخلی کارتلا در باره KV بیشتر بدانید سوال 1")
    //     .then((response) => {
    //       setHint1(response);
    //     })
    //     .catch(() => setHint1(false))
    //     .finally(() => setHintLoading(false));

    //   getHintAPI("صفحات داخلی کارتلا در باره KV بیشتر بدانید سوال 2")
    //     .then((response) => {
    //       setHint2(response);
    //     })
    //     .catch(() => setHint2(false))
    //     .finally(() => {
    //     });
    // };

    const getGiftType = (type) => {
        switch (type) {
            case "whiteKM":
                return "KM";
            case "redKM":
                return "KP";
            case "money":
                return "تومان";
            case "chance":
                return "شانس";
            case "discount":
                return "درصد تخفیف";
            case "charge":
                return "شارژ";
            case "internet":
                return "بسته اینترنت";
            case null:
            default:
                return "";
        }
    };

    const getGiftAmount = (card) => {
        switch (card.giftType) {
            case "charge":
                return `${card.chargeAmount} هزار تومان`;
            case "internet":
                return `${card.internetAmount} گیگابایت`;
            default:
                return `${card.giftAmount} عدد`;
        }
    };

    const cardType = (type) => {
        switch (type) {
            case "redKM":
                return "KP";
            case "whiteKM":
                return "KM";
            case "money":
                return "نقدی";
            case "chance":
                return "شانس قرعه‌کشی";
            case "discount":
                return "کارت تخفیف";
            case "charge":
                return "شارژ تلفن همراه";
            case "internet":
                return "بسته اینترنت";
            case "product":
                return "";
        }
    };

    const sortName = (sort) => {
        switch (sort) {
            case "desc":
                return "گران‌ترین";
            case "asc":
                return "ارزان‌ترین";
            default:
                return "مرتب‌سازی";
        }
    };

    const filterName = (filter) => {
        switch (filter) {
            case "whiteKM":
                return "KM";
            case "redKM":
                return "KP";
            case "money":
                return "نقدی";
            case "chance":
                return "شانس";
            case "discount":
                return "تخفیف";
            case "charge":
                return "شارژ";
            case "internet":
                return "اینترنت";
            default:
                return "نوع";
        }
    };

    const checkMyOperator = () => {
        getOperatorAPI(user.mobile_number)
            .then((response) => {
                if (response.status === 200) {
                    if (response.data.OperatorID !== -1) {
                        setMyOperator(response.data.OperatorID);
                    } else {
                        toast.error(
                            "لطفا شماره موبایل خود را بدرستی وارد کنید"
                        );
                    }
                }
            })
            .catch((err) => {})
            .finally(() => {});
    };

    const checkOperator = () => {
        getOperatorAPI(phoneNumber)
            .then((response) => {
                if (response.status === 200) {
                    if (response.data.OperatorID !== -1) {
                        setOperator(response.data.OperatorID);
                    } else {
                        toast.error(
                            "لطفا شماره موبایل خود را بدرستی وارد کنید"
                        );
                    }
                }
            })
            .catch((err) => {})
            .finally(() => {});
    };

    const operatorName = (operator) => {
        switch (operator) {
            case 1:
                return "ایرانسل";
            case 2:
                return "همراه اول";
            case 3:
                return "رایتل";
            default:
                return "-";
        }
    };

    const handleSubmitAddress = (newAddress) => {
        setShowLoadingAddressModal(true);
        const APIResult = createUserAddressAPI(user_token, newAddress);
        APIResult.then((response) => {
            if (response.status === 200) {
                toast.success("آدرس جدید ایجاد شد.");
                getUserAddressList();
                setModalSelectedAddress({});
                setAddNewAddress({ active: false, address: null });
            } else {
                toast.error("مقادیر وارد شده کامل نمی‌باشد.");
            }
        });
        setModalSelectedAddress({});
    };

    const getUserAddressList = () => {
        const userToken = localStorage.getItem("user_token");

        const serverResponse = getUserAddresses(userToken);
        serverResponse.then((response) => {
            if (response.status >= 400) {
                localStorage.removeItem("user_token");
                history.push("/login");
            } else {
                setAddresses(response.data.data.address);
                setShowLoadingAddressModal(false);
            }
            setLoading(false);
        });
        setModalSelectedAddress(false);
    };

    useEffect(() => {
        getGiftCardsList();
        history.replace("/cardbox");
        if (localStorage.getItem("cardStore-hint") === "0") setShowHelp(false);
    }, []);

    useEffect(() => {
        getCardsList();
    }, [sortValue, filterValue, reciverValue]);

    useEffect(() => {
        if (phoneNumber.length === 11) {
            checkOperator();
        }
    }, [phoneNumber]);

    useEffect(() => {
        checkMyOperator();
    }, [user.mobile_number]);

    useEffect(() => {
        getHintAPI("متن راهنمای ثابت صفحه کارت استور")
            .then((response) => {
                setSlidingHintText(response.content);
            })
            .catch(() => toast.error("درافت راهنما با خطا مواجه شد"));
    }, []);

    return (
        <Fade>
            {/* <PageTopbar  title="کارت‌ها" showKv={true} toggleBackward={false}/> */}
            <PageTopbar
                title="کارت باکس"
                // toggleBookmark
                toggleStar
                // onClickKartBox={() => history.push("/myads")}
            />
            <Spinner active={loading} color="dark" />

            <Modal
                active={receivePrizeModal}
                close={() => {
                    setReceivePrizeModal(false);
                    setGhasedak(false);
                }}
            >
                <div className={"my-introduce__prize__modal"}>
                    <div className={"my-introduce__prize__modal__header"}>
                        <div
                            className={
                                receiveType === 0 &&
                                "my-introduce__prize__modal__header__underline"
                            }
                            onClick={() => setReceiveType(0)}
                        >
                            برای خودم
                        </div>
                        <div
                            className={
                                receiveType === 1 &&
                                "my-introduce__prize__modal__header__underline"
                            }
                            onClick={() => setReceiveType(1)}
                        >
                            برای دیگران
                        </div>
                    </div>

                    <div className={"my-introduce__prize__modal__line"} />

                    <div className={"my-introduce__prize__modal__detail"}>
                        {receiveType === 0 && (
                            <div className="my-introduce__prize__modal__detail__me">
                                <div className="my-introduce__prize__modal__detail__me__number">
                                    {`شماره همراه: ${user.mobile_number}`}
                                </div>
                                <div className="my-introduce__prize__modal__detail__me__operator">
                                    {`اپراتور: ${operatorName(myOperator)}`}
                                </div>
                            </div>
                        )}

                        {receiveType === 1 && (
                            <div className="my-introduce__prize__modal__detail__other">
                                <div className="my-introduce__prize__modal__detail__me__number">
                                    <div className="title">شماره همراه</div>
                                    <input
                                        type={"number"}
                                        className={"input"}
                                        placeholder={"09123456789"}
                                        value={phoneNumber}
                                        onChange={(event) =>
                                            setPhoneNumber(event.target.value)
                                        }
                                    />
                                </div>
                                {phoneNumber.length === 11 && operator && (
                                    <div className="my-introduce__prize__modal__detail__me__operator">
                                        {`اپراتور: ${operatorName(operator)}`}
                                    </div>
                                )}
                            </div>
                        )}
                    </div>

                    {cardGiftType === "internet" && (
                        <div className="my-introduce__prize__modal__warning">
                            توجه: برای دریافت این هدیه نباید بسته اینترنتی رزرو
                            داشته باشید درغیر این صورت هدیه شما باطل خواهد شد!
                        </div>
                    )}

                    <div className={"my-introduce__prize__modal__actions"}>
                        <div
                            onClick={() =>
                                buyLoading === -1 &&
                                buyCard(buyingCard.cardId, buyingCard.index)
                            }
                            className={"button"}
                        >
                            {buyLoading === buyingCard.index ? (
                                <Dots color={"light"} />
                            ) : (
                                <span>دریافت</span>
                            )}
                        </div>
                        <div
                            onClick={() => setReceivePrizeModal(false)}
                            className={"button disable"}
                        >
                            بازگشت
                        </div>
                    </div>
                </div>
            </Modal>

            <Modal
                active={hintModal.kvInfo}
                close={() => {
                    setHintModal({ ...hintModal, kvInfo: false });
                }}
                hint="getKv"
            >
                <div className="modal-hint__title">{hint1.title}</div>

                <div className="modal-hint__description">
                    {hintLoading && <Dots color="dark" />}
                    {hint1.content?.split("\n")?.map((str) => (
                        <div>{str}</div>
                    ))}
                </div>

                <div
                    className="modal-hint__more"
                    onClick={() => setHintModal({ ...hintModal, kvDose: true })}
                >
                    <SVG svg="hint-more" /> {hint2.title}
                </div>
            </Modal>

            <Modal
                active={hintModal.kvDose}
                close={() => {
                    setHintModal({ ...hintModal, kvDose: false });
                }}
                hint="price"
            >
                <div className="modal-hint__title">{hint2.title}</div>

                <div className="modal-hint__description">
                    {hint2.content?.split("\n")?.map((str) => (
                        <div>{str}</div>
                    ))}
                </div>

                {/* <div
            className="modal-hint__more"
            onClick={() => setHintModal({ ...hintModal, kvAssign: true })}
          >
            <SVG svg="hint-more" /> مزایای داشتن KV چیست؟
          </div> */}

                <div className="row a-c" style={{ gap: "4px" }}>
                    <div
                        className="modal-hint__button solid"
                        onClick={() =>
                            setHintModal({ ...hintModal, kvDose: false })
                        }
                    >
                        بازگشت
                    </div>

                    <div
                        className="modal-hint__button"
                        onClick={() =>
                            setHintModal({
                                ...hintModal,
                                kvInfo: false,
                                kvDose: false,
                            })
                        }
                    >
                        برو به کارت استور
                    </div>
                </div>
            </Modal>

            <Modal
                active={hintModal.kvAssign}
                close={() => {
                    setHintModal({ ...hintModal, kvAssign: false });
                }}
                hint="kvAssign"
            >
                <div className="modal-hint__title">
                    گرفتن KV چه فایده‌ای داره؟
                </div>

                <div className="modal-hint__description">
                    با آزادسازی KV بعد از خرید هر محصول در
                    <Link to="/market" style={{ color: "red", marginRight: 3 }}>
                        فروشگاه
                    </Link>
                    ، یک هدیه نقدی دریافت می‌کنید و ضمنا هر ماهی که بیشتر از 200
                    KV بگیرید در
                    <Link
                        to="/lottery"
                        style={{ color: "red", marginRight: 1, marginLeft: 1 }}
                    >
                        {" "}
                        قرعه‌کشی{" "}
                    </Link>
                    ماهانه کارتک می‌تونید جایزه بگیرید.
                </div>

                <div
                    className="modal-hint__more"
                    onClick={() => {
                        history.push(`/pay?https://kartaak.com/rules`);
                        setTimeout(() => {
                            if (history.location.pathname.includes("/pay"))
                                window.location.href = `https://kartaak.com/rules`;
                        }, 600);
                    }}
                >
                    <SVG svg="hint-more" /> اطلاعات بیشتر
                </div>

                <div className="row a-c" style={{ gap: "4px" }}>
                    <div
                        className="modal-hint__button solid"
                        onClick={() =>
                            setHintModal({ ...hintModal, kvAssign: false })
                        }
                    >
                        بازگشت
                    </div>

                    <div
                        className="modal-hint__button"
                        onClick={() =>
                            setHintModal({
                                ...hintModal,
                                kvInfo: false,
                                kvDose: false,
                                kvAssign: false,
                            })
                        }
                    >
                        برو به کارتلا
                    </div>
                </div>
            </Modal>
            <Modal
                active={hintModal.qrCharge}
                close={() => {
                    setHintModal({ ...hintModal, qrCharge: false });
                }}
                hint="getKv"
            >
                <div className="modal-hint__title">شارژ کارتلا</div>

                <div className="modal-hint__description">
                    برای شارژ کارتلا باید یکی از بارکدهای کارتک را اسکن کنید.
                </div>

                {/* <div className="modal-hint__more" onClick={() => setHintModal({ ...hintModal, kvDose: true })}>
            <SVG svg="hint-more" />
          </div> */}

                <div className="col a-c" style={{ gap: "4px" }}>
                    <div
                        className="modal-hint__more j-c"
                        onClick={() =>
                            setHintModal({ ...hintModal, qrInfo: true })
                        }
                    >
                        راهنمای پیدا کردن بارکد های کارتک
                    </div>

                    {/* <div
              className="modal-hint__button green"
              onClick={() => chargeKartella()}
            >
              فعلا شارژ کن
            </div> */}
                </div>
            </Modal>

            <Modal
                active={hintModal.qrInfo}
                close={() => {
                    setHintModal({ ...hintModal, qrInfo: false });
                }}
                hint="price"
            >
                <div className="modal-hint__title">
                    راهنمای پیدا کردن بارکد های کارتک
                </div>

                <div className="modal-hint__description">
                    روی گوشی بعضی از کاربران و در بعضی از اماکن خصوصی و عمومی
                    می‌تونید بارکد کارتک را پیدا کنید.
                </div>

                <div
                    className="modal-hint__more"
                    onClick={() => setHintModal({ ...hintModal, qrMine: true })}
                >
                    <SVG svg="hint-more" />
                    شرایط داشتن بارکد اختصاصی
                </div>

                <div className="row a-c" style={{ gap: "4px" }}>
                    <div
                        className="modal-hint__button solid"
                        onClick={() =>
                            setHintModal({ ...hintModal, qrInfo: false })
                        }
                    >
                        بازگشت
                    </div>

                    <div
                        className="modal-hint__button"
                        onClick={() =>
                            setHintModal({
                                ...hintModal,
                                qrCharge: false,
                                qrInfo: false,
                            })
                        }
                    >
                        برو به کارت استور
                    </div>
                </div>
            </Modal>

            <Modal
                active={showHint}
                close={() => {
                    setShowHint(false);
                    setHintDescription("");
                }}
            >
                <div style={{ fontSize: 13, fontWeight: 500 }}>
                    {hintDescription?.split("\n")?.map((str) => (
                        <div>{str}</div>
                    ))}
                </div>
            </Modal>

            <Modal active={showSuccess} close={() => setShowSuccess(false)}>
                <div className="row j-c">
                    <SVG svg="success" />
                </div>

                <div className="cards__modal__success">
                    خرید کارت با موفقیت ثبت شد!
                </div>
                <div className="button" onClick={() => setShowSuccess(false)}>
                    بازگشت
                </div>
            </Modal>

            <Modal active={showFail} close={() => setShowFail(false)}>
                <div className="row j-c">
                    <SVG svg="payment-fail" />
                </div>

                <div className="cards__modal__fail">
                    خرید کارت با مشکل رو‌به‌رو شد!
                </div>
                <div className="cards__modal__fail">
                    لطفا با پشتیبانی ارتباط برقرار کنید
                </div>
                <div className="button" onClick={() => setShowFail(false)}>
                    بازگشت
                </div>
            </Modal>
            <div className="cards">
                <Card>
                    <Fade>
                        {cardsList.map(
                            (card, index) =>
                                card.type === "gift" && (
                                    <div className={"gift-card"}>
                                        <div className="gift-card__info">
                                            <div className="gift-card__info__icon">
                                                <img
                                                    src={`${card.photo}`}
                                                    alt=""
                                                />
                                            </div>
                                            <div className="gift-card__info__description">
                                                <div className="gift-card__info__help">
                                                    {/* <Hint/> */}
                                                </div>
                                                <div className="gift-card__info__title">
                                                    {card.title}
                                                </div>
                                                <div className="gift-card__info__amount">
                                                    {`${getGiftAmount(
                                                        card
                                                    )} ${getGiftType(
                                                        card.giftType
                                                    )}`}
                                                </div>
                                            </div>
                                        </div>
                                        {/*<div className="gift-card__countdown">*/}
                                        {/*  <div className="gift-card__countdown__line"/>*/}
                                        {/*  <div className="gift-card__countdown__time">*/}
                                        {/*    48:34:43*/}
                                        {/*  </div>*/}
                                        {/*  <div className="gift-card__countdown__line"/>*/}
                                        {/*</div>*/}
                                        <div
                                            className="gift-card__button"
                                            onClick={() =>
                                                buyLoading === -1 &&
                                                ReceiveGift(card.id, index)
                                            }
                                        >
                                            {buyLoading === index ? (
                                                <Dots color="light" />
                                            ) : (
                                                <>
                                                    <span>دریافت</span>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                )
                        )}
                        {myCards.length > 0 && (
                            <div className={"title"}>
                                کارت‌هایی که قبلا دریافت کرده‌اید
                            </div>
                        )}
                        {myCards.map(
                            (card, index) =>
                                card.isPaid === 1 && (
                                    <>
                                        <div className={"gift-card deactive"}>
                                            <div className="gift-card__info">
                                                <div className="gift-card__info__icon">
                                                    <img
                                                        src={card.image}
                                                        alt=""
                                                    />
                                                </div>
                                                <div className="gift-card__info__description">
                                                    <div className="gift-card__info__help">
                                                        {/* <Hint/> */}
                                                    </div>
                                                    <div className="gift-card__info__title">
                                                        {card.title}
                                                    </div>
                                                    <div className="gift-card__info__amount">
                                                        {`${getGiftAmount(
                                                            card
                                                        )} ${getGiftType(
                                                            card.giftType
                                                        )}`}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )
                        )}
                        {/* {myCards.length === 0 && cardsList.length === 0 && (
                <div style={{ marginTop: 200 }}>
                  <NotFoundBox
                    title="کارتی یافت نشد!"
                    bio=""
                    icon="cards-mine"
                  />
                </div>
              )} */}
                    </Fade>
                </Card>
            </div>
            {/* <CardsModal/> */}

            <Modal
                active={showAddressModal}
                close={() => {
                    setShowAddressModal(false);
                    setModalSelectedAddress({});
                    setAddresses([]);
                }}
            >
                {showLoadingAddressModal === true ? (
                    <div className="my-introduce__address-modal">
                        <Spinner
                            active={showLoadingAddressModal}
                            color="dark"
                        />
                    </div>
                ) : (
                    <>
                        <h6 style={{ marginBottom: "10px" }}>انتخاب آدرس : </h6>
                        <div className="my-introduce__address-modal__add-address">
                            <Card>
                                {addresses.length > 0 ? (
                                    addresses.map((address) => (
                                        <div
                                            key={address.id}
                                            className="user__address"
                                            style={{
                                                margin: "10px 0px",
                                                border: "1px solid lightgray",
                                                borderRadius: "10px",
                                                backgroundColor:
                                                    address.id ===
                                                    modalSelectedAddress["id"]
                                                        ? "#17c7a7"
                                                        : "#e8e8e8",
                                            }}
                                            onClick={(e) => {
                                                setModalSelectedAddress(
                                                    address
                                                );
                                            }}
                                        >
                                            <div className="user__address__icon">
                                                <SVG svg="location" />
                                            </div>

                                            <div className="user__address__info">
                                                <div className="user__address__title">
                                                    {address.title}
                                                </div>
                                                <div className="user__address__text">
                                                    {address.address}
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <h4>لطفا یک آدرس اضافه کنید</h4>
                                )}
                            </Card>
                        </div>

                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                flexWrap: "nowrap",
                                gap: "5px",
                            }}
                        >
                            <button
                                className="button red"
                                style={{
                                    fontFamily: "inherit",
                                    height: "45px",
                                    fontSize: "12px",
                                }}
                                onClick={() => {
                                    setAddNewAddress({
                                        active: true,
                                        address: null,
                                    });
                                }}
                            >
                                افزودن آدرس جدید
                            </button>

                            {addresses.length > 0 && (
                                <button
                                    className="button green"
                                    style={{
                                        fontFamily: "inherit",
                                        height: "45px",
                                        fontSize: "12px",
                                    }}
                                    onClick={() => {
                                        if (buyButtonSpinner) {
                                            toast.loading(
                                                "در حال پردازش ااطلاعات"
                                            );
                                        } else if (
                                            Object.entries(modalSelectedAddress)
                                                .length === 0
                                        ) {
                                            toast.error(
                                                "لطفا یک آدرس انتخاب نمایید."
                                            );
                                        } else {
                                            setBuyButtonSpinner(true);
                                            buyCard(
                                                currentCardID,
                                                currentCardIndex
                                            );
                                        }
                                    }}
                                >
                                    {buyButtonSpinner === true ? (
                                        <Dots color={"light"} />
                                    ) : (
                                        "خرید"
                                    )}
                                </button>
                            )}
                        </div>
                    </>
                )}
            </Modal>

            <CreateAddress
                active={addNewAddress}
                setClose={(e) => {
                    setAddNewAddress({ active: false, address: null });
                }}
                onSubmit={(id, address) => {
                    handleSubmitAddress(address);
                }}
                isFullScreen={true}
            />
        </Fade>
    );
};
