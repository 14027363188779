import "./VideoManager.scss";
import { SVG } from "components";
import { useEffect, useState, useRef } from "react";
import { Fragment } from "react";
import * as tus from "tus-js-client";
import { toast } from "react-hot-toast";
import { makeVideoAPI } from "utils";
import { CircleProgress } from 'react-gradient-progress';

export const VideoManager = ({
  videoAaddress = null,
  videoSetter = null,
  uploadVideoFunction = false,
  videoIdSetter,
  errorOccurred,
  flagSetter,
  finishUpload
}) => {
  const [video, setVideo] = useState(null);
  const [videoToShow, setVideoToShow] = useState(videoAaddress);
  const inputVideo = useRef(null);
  const [videoId, setVideoId] = useState("");
  const [videoName, setVideoName] = useState("");
  const date = new Date()
  const [showProgress, setShowProgress] = useState(false);
  const [percentage, setPercentage] = useState(0);
  const channelId = "2cc1ee78-3e55-4010-8949-ad14bf6b672a"
  const authorization = "Apikey 59d3d3cc-0a17-51a8-acbd-6eea9bd57044";

  const handleVideo = (e) => {
    if (e.target.files.length === 0) return;
    videoSetter(e.target.files[0]);
    const file = e.target.files[0];
    setVideo(file);
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = (e) => {
      setVideoToShow(e.target.result);
    };
  };

  const handleOpenFileManager = () => {
    inputVideo.current.click();
  };

  const uploadFileWithTus = (file: any = video) => {
    //200mb
    if (file.size > 209715200) {
      toast.error("حجم فایل بیش از حد مجاز است. حد مجاز 200 مگابایت.");
      errorOccurred(true);
      return;
    }

    uploadVideoFunction && getVideoDuration(file).then((duration) => {
      if (duration > 61) {
        toast.error("زمان ویدیو بیش از حد مجاز است. حد مجاز 1 دقیقه.");
        errorOccurred(true);
      } else {
        setShowProgress(true);
        const now = date.getTime()        
        setVideoName(now + file.name);

        let options = {
          "url": `https://napi.arvancloud.com/vod/2.0/channels/${channelId}/files`,
          "authorization": `${authorization}`,
          "acceptLanguage": "en",
          "uuid": file.name + file.size + file.lastModified
        };

        let upload = new tus.Upload(file, {
          chunkSize: 1048576,
          endpoint: options.url,
          retryDelays: [0, 500, 1000, 1500, 2000, 2500],
          headers: {
              'Authorization': options.authorization,
              'Accept-Language': options.acceptLanguage
          },
          metadata: {
              filename: file.name,
              filetype: file.type
          },
          onError: function (error) {
            toast.error("خطا در آپلود فایل");
          },
          onProgress: function (bytesUploaded, bytesTotal) {
            let percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2);
            setPercentage(+percentage);
          },
          onSuccess: function () {
            //@ts-ignore
            let fullAddress = upload.url.split("/");
            let tempVideoId = fullAddress[fullAddress.length - 1];
            setVideoId(tempVideoId);
          },
        });

        upload.findPreviousUploads().then(function (previousUploads) {
          if (previousUploads.length) {
            upload.resumeFromPreviousUpload(previousUploads[0]);
          }
          upload.start();
        });
      }
    });
  };

  const makeVideo = () => {
    makeVideoAPI(videoId, videoName, "FIX_BOTTOM_LEFT", 1)
      .then((response) => {
        videoIdSetter(response.data.id);
      })
      .catch((error) => {
        toast.error("خطا در آپلود فایل");
        errorOccurred(true);
      }).finally(() => {
        setShowProgress(false);
        finishUpload(true);
      });
  };

  const getVideoDuration = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        //@ts-ignore
        const media = new Audio(reader.result);
        media.onloadedmetadata = () => resolve(media.duration);
      };
      reader.readAsDataURL(file);
      reader.onerror = (error) => reject(error);
    });

  useEffect(() => {
    if (videoId) {
      makeVideo();
    }
  }, [videoId]);

  useEffect(() => {
    if (uploadVideoFunction && video) {
      uploadFileWithTus();
    } else {
      flagSetter(false);
    }
  }, [uploadVideoFunction]);

  useEffect(() => {
    videoAaddress && setVideoToShow(videoAaddress);
  }, [])

  return (
    <div
      className="videoManagerContainer"
      onClick={() => handleOpenFileManager()}
    >
      {videoToShow && (
        <div
          className="videoManagerContainer__close"
          onClick={(e) => {
            e.stopPropagation();
            inputVideo.current.value = null;
            setVideo(null);
            videoSetter(null);
          }}
        >
          <SVG svg={"close"} />
        </div>

      )}

      {videoToShow ? (
        <video
          src={videoToShow}
          controls
          controlsList={"nodownload"}
          disablePictureInPicture={true}
          disableRemotePlayback={true}
        />
      ) : (
        <Fragment>
          <h1 className="title">انتخاب ویدیو</h1>
          <SVG svg={"video-player"} />
        </Fragment>
      )}
      <input type="file" ref={inputVideo} onChange={(e) => handleVideo(e)} accept="video/mp4,video/x-m4v,video/*" />

      {videoToShow ? (
        <div
          className="videoManagerContainer__refresh"
          onClick={(e) => {
            e.stopPropagation();
            handleOpenFileManager();
          }}
        >
          <SVG svg={"cycle-refresh"} />
        </div>
      ) : null}

      {
        showProgress ? (
          <div className="videoManagerContainer__progress">
            <p>در حال بارگزاری ویدیو</p>
            <CircleProgress percentage={percentage} strokeWidth={16} secondaryColor="#f0f0f0" />
          </div>
        ) : null
       }
    </div>
  );
};
