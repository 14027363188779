import { Fragment, useState, useEffect } from "react";
import { PageTopbar, SVG, Modal, CounterInput, Fade } from "components";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { Main, Qr, Phone } from "./dependencies";

export const KmDonateContainer = ({ children }) => {
  const history = useHistory();
  const { km_type, status } = useParams<any>();
  const param = useParams();
  const location = useLocation();
  const query = useQuery();
  const type = query.get("type");

  const handleKmType = (value) => {
    switch (value) {
      case "blue":
        return "KP";
      case "white":
        return "KM";
      case "red":
        return "KP";
      default:
        return "";
        break;
    }
  };

  function useQuery() {
    return new URLSearchParams(location.search);
  }

  useEffect(() => {
    history.replace(`/transaction/donate` + history.location.search);
  }, []);

  return (
    <Fade>
      <div className="km-donate">
        <PageTopbar title={`اهدای ${handleKmType(type)}`} showKv={false} />
        <div className="km-donate__wrapper mycity">
          {query.get("status") == "main" && <Main type={type} />}
          {query.get("status") == "qr" && <Qr />}
          {query.get("status") == "phone" && <Phone />}
        </div>
      </div>
    </Fade>
  );
};
