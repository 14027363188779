import { useEffect, useState } from "react";
import { Fade, PageTopbar, CheckBox, Selectbox, SVG, Card, Hint, Dots } from "components";
import { farsiDigitToEnglish } from "utils/Functions";
import { getOperatorAPI } from "utils";

export const OperatorChargeContainer = () => {

  const [userPhoneNumber, setUserPhoneNumber] = useState("09");
  const [operatorId, setOperatorId] = useState(0)
  const [operatorName, setOperatorName] = useState('')
  const [type, setType] = useState(0)
  const [loading, setLoading] = useState(false)

  const getOparator = (mobile) => {
    setLoading(true)
    getOperatorAPI(mobile).then(res => {
      console.log('====================================');
      console.log(res);
      console.log(res.data.OperatorID);
      console.log(res.data.OperatorName);

      setOperatorId(res.data.OperatorID);
      setOperatorName(res.data.OperatorName)
      // showOpType()
      console.log('====================================');
    }).catch(err => {
      // toast.error('به مشکلی برخوردیم');
    }).finally(() => {
      setLoading(false)
    });
  };


  useEffect(() => {
    if (userPhoneNumber.length == 11) {
      getOparator(userPhoneNumber);
    }
  }, [userPhoneNumber]);

  // const showOpType = () => {
  //   switch (operatorId) {
  //     case 1:
  //       return (
  //         <div className="operator-charge">
  //           <div className="operator-charge__operator-img">
  //             <span>{operatorName}</span>
  //           </div>
  //           <div className="operator-charge__checkBox" style={{ marginLeft: 16 }}>
  //             <CheckBox
  //               disable
  //               defaultValue={type === 1 && true}
  //               onChange={(value) => {
  //                 if (value) {
  //                   setType(1);
  //                 }
  //               }}
  //             />
  //             <div className="operator-charge__checkBox__title">دائمی</div>
  //           </div>

  //           <div className="operator-charge__checkBox">
  //             <CheckBox
  //               disable
  //               defaultValue={type === 2 && true}
  //               onChange={(value) => {
  //                 if (value) {
  //                   setType(2);
  //                 }
  //               }}
  //             />
  //             <div className="operator-charge__checkBox__title">اعتباری</div>
  //           </div>
  //         </div>
  //       );
  //   }
  // };

  return (
    <Fade>
      <PageTopbar title="خرید  اینترنت " />
      <Card>
        <div className="buy-internet__title">شماره تلفن</div>
        <div className="buy-internet__phoneNum">
          <input className="input"
            dir="auto"
            type="tel"
            placeholder="09123456789"
            value={userPhoneNumber}
            onChange={(e) =>
              setUserPhoneNumber(e.target.value)

            } />
        </div>
        {userPhoneNumber.length === 11 && (
          <div className="operator-charge">
            {loading ? (<Dots color="dark" />) :
              <>
                <div className="operator-charge__operator-img">
                  <SVG svg="checked" />
                  <span>{operatorName}</span>
                </div>
                <div className="operator-charge__checkBox">
                  <div className="operator-charge__checkBox__content">
                    <CheckBox
                      disable
                      defaultValue={type === 1 && true}
                      onChange={(value) => {
                        if (value) {
                          setType(1);
                        }
                      }}
                    />
                    <div className="operator-charge__checkBox__title">دائمی</div>
                  </div>

                  <div className="operator-charge__checkBox">
                    <div className="operator-charge__checkBox__content">
                      <CheckBox
                        disable
                        defaultValue={type === 2 && true}
                        onChange={(value) => {
                          if (value) {
                            setType(2);
                          }
                        }}
                      />
                      <div className="operator-charge__checkBox__title">اعتباری</div>
                    </div>
                  </div>
                </div>
              </>}
          </div>

        )}


      </Card>
    </Fade>
  );
};
