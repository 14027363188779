import { createContext, FC, ReactNode, useReducer } from "react";
import { authInitialState } from "./InitialState";
import { AuthReducer } from "./Reducer";

export const AuthStateContext = createContext(undefined);
export const AuthDispatchContext = createContext(undefined);

export const AuthProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [authState, authDispatch] = useReducer(AuthReducer, authInitialState);

  return (
    <AuthDispatchContext.Provider value={{ authDispatch }}>
      <AuthStateContext.Provider value={{ authState }}>
        {children}
      </AuthStateContext.Provider>
    </AuthDispatchContext.Provider>
  );
};
