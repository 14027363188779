import React, { useEffect, useState } from "react";
import "./Header.scss";
import { SVG, FillProfile, Modal, Dots } from "components";
import ProfileImage from "../../../assets/images/profile.png";
import { Link, useHistory } from "react-router-dom";
import toast from "react-hot-toast";
import FallImage from "../../../assets/images/Profile/fall.jpg";
import WinterImage from "../../../assets/images/Profile/winter.gif";
import Winter3Image from "../../../assets/images/Profile/winter-3.gif";
import SpringImage from "../../../assets/images/Profile/spring.jpg";
import SummerImage from "../../../assets/images/Profile/summer.jpg";
import { useUser } from "hooks";
import { transformPrice } from "utils/Functions";
import { getHintAPI, MoneyWalletAPI } from "utils";
import moment from "jalali-moment";
import { MyKartaak } from "../MyKartaak";
import { SwitchUser } from "components/SwitchUser";

const wallets = [
    {
        title: "کیف نقدی",
        className: "cash",
        icon: "wallet-cash",
        type: "cash",
        path: "/cash",
    },
    {
        title: "کیف KV",
        className: "kv",
        icon: "wallet-kv",
        type: "kv",
        path: "/wallet",
    },
    {
        title: "کیف KP",
        className: "redkm",
        icon: "wallet-km",
        type: "redkm",
        path: "/wallet/km?red",
    },
    {
        title: "کیف KM",
        className: "whitekm",
        icon: "wallet-km",
        type: "whitekm",
        path: "/wallet/km?white",
    },
    // {
    //   title: 'قرعه‌کشی',
    //   className: 'lottery',
    //   icon: 'wallet-lottery',
    //   type: 'lottery',
    //   path: '/lottery'
    // },
];

export const Header = ({
    getUserFunc,
    starDetail,
    getUserStars,
    loading,
    setLoading,
}) => {
    const user_token = localStorage.getItem("user_token");
    const stars = [{}, {}, {}, {}, {}, {}, {}];
    const [showFillProfile, setShowFillProfile] = useState(false);
    const [activeFillProfile, setActiveFillProfile] = useState(0);
    const [infoStarModal, setInfoStarModal] = useState(false);
    const history = useHistory();
    const {
        userState: { user, userImage },
    } = useUser();
    const [cashHistory, setCashHistory] = useState({
        detail: [],
        info: {
            amount: 0,
        },
    });
    const [walletItemShow, setWalletItemShow] = useState({
        kv: user?.detail?.isShowWallet,
        km: user?.detail?.isShowWallet,
        lottery: null,
        cash: user?.detail?.isShowWallet,
    });
    const [myKartaakToggle, setMyKartaakToggle] = useState(true);
    const [showSwitchUser, setShowSwitchUser] = useState(false);

    const [hint, setHint] = useState();
    const [fullhint, setFullhint] = useState(false);

    const handleChangeShow = (walletType) => {
        setWalletItemShow({
            ...walletItemShow,
            [walletType]: !walletItemShow[walletType],
        });
    };

    const handleSelectStar = (index) => {
        if (user.detail?.star <= 0) {
            toast.error("ستاره‌ای یافت نشد!");
            return;
        } else {
            if (user.detail?.star >= 7) {
                toast.error("شما 7 ستاره هستید.");
                return;
            }
            // if (user.detail?.star < 4) {
            //   setActiveFillProfile(user.detail?.star + 1);
            // } else if (user.detail?.star >= 4) {
            //   // if (index < 5)
            //   //   setActiveFillProfile(user.star + 1);
            //   // else {
            //   switch (index + 1) {
            //     case 1:
            //     case 2:
            //     case 3:
            //     case 4:
            //       if (starDetail.education === null) setActiveFillProfile(5);
            //       else if (starDetail.employment === null) {
            //         setActiveFillProfile(6);
            //       } else setActiveFillProfile(7);
            //       break;
            //
            //     case 5:
            //       if (starDetail.education === null) setActiveFillProfile(index + 1);
            //       else if (starDetail.employment === null) setActiveFillProfile(6);
            //       else setActiveFillProfile(7);
            //       break;
            //
            //     case 6:
            //       if (starDetail.employment === null) setActiveFillProfile(index + 1);
            //       else if (starDetail.education === null) setActiveFillProfile(5);
            //       else setActiveFillProfile(7);
            //       break;
            //
            //     case 7:
            //       if (starDetail.marital === null) setActiveFillProfile(index + 1);
            //       else if (starDetail.education === null) setActiveFillProfile(5);
            //       else setActiveFillProfile(6);
            //       break;
            //
            //     default:
            //       break;
            //     // }
            //   }
            // }
            setActiveFillProfile(user.detail?.star + 1);
            setShowFillProfile(true);
        }
    };

    const handleSetFillProfile = (activeFill) => {
        switch (activeFill) {
            case 2:
                return "city";

            case 3:
                return "gender";

            case 4:
                return "age";

            case 5:
                return "education";

            case 6:
                return "employment";

            case 7:
                return "marital";

            default:
                return "";
        }
    };

    const handleShowBgImage = () => {
        const currMonth = Number(moment(Date.now()).locale("fa").format("MM"));
        switch (currMonth) {
            case 1:
            case 2:
            case 3:
                return SpringImage;

            case 4:
            case 5:
            case 6:
                return SummerImage;

            case 7:
            case 8:
            case 9:
                return FallImage;

            case 10:
            case 11:
                return WinterImage;

            case 12:
                return Winter3Image;

            default:
                break;
        }
    };

    const handleShowWalletValue = (walletType) => {
        switch (walletType) {
            case "kv":
                if (walletItemShow.kv) {
                    return (
                        <React.Fragment>
                            {user.kv}
                            <div
                                id="showIcon"
                                onClick={() =>
                                    setWalletItemShow({
                                        ...walletItemShow,
                                        kv: false,
                                    })
                                }
                            >
                                {/* <SVG svg='unseen' /> */}
                            </div>
                        </React.Fragment>
                    );
                } else {
                    return (
                        // <div
                        //   id="showIcon"
                        //   onClick={() => setWalletItemShow({ ...walletItemShow, kv: true })}
                        // >
                        //   <SVG svg='seen' />
                        // </div>
                        null
                    );
                }

            case "cash":
                if (walletItemShow.cash) {
                    return (
                        <React.Fragment>
                            {transformPrice(cashHistory?.info?.amount) +
                                " تومان"}
                            <div
                                id="showIcon"
                                onClick={() =>
                                    setWalletItemShow({
                                        ...walletItemShow,
                                        cash: false,
                                    })
                                }
                            >
                                {/* <SVG svg='unseen' /> */}
                            </div>
                        </React.Fragment>
                    );
                } else {
                    return (
                        // <div
                        //   id="showIcon"
                        //   onClick={() => setWalletItemShow({ ...walletItemShow, cash: true })}
                        // >
                        //   <SVG svg='seen' />
                        // </div>
                        null
                    );
                }

            case "km":
                if (walletItemShow.km) {
                    return (
                        <React.Fragment>
                            {user.redKm}
                            <div
                                id="showIcon"
                                onClick={() =>
                                    setWalletItemShow({
                                        ...walletItemShow,
                                        km: false,
                                    })
                                }
                            >
                                {/* <SVG svg='unseen' /> */}
                            </div>
                        </React.Fragment>
                    );
                } else {
                    return (
                        // <div
                        //   id="showIcon"
                        //   onClick={() => setWalletItemShow({ ...walletItemShow, km: true })}
                        // >
                        //   <SVG svg='seen' />
                        // </div>
                        null
                    );
                }

            case "lottery":
                return user.Lottery > 199
                    ? user.Lottery - 199 + " شانس"
                    : 200 - user.Lottery + " امتیاز تا شرکت در قرعه‌کشی";

            default:
                break;
        }
    };

    // const getUserWallet = () => {
    //     const APIResult = MoneyWalletAPI(user_token);
    //     APIResult.then((response) => {
    //         setCashHistory(response.data.data);
    //         // setLoading(false);
    //     });
    // };

    // const handleShowActive = (walletType) => {
    //     if (walletType === "lottery") {
    //         if (user.Lottery > 199) {
    //             return "";
    //         } else {
    //             return "#6a6c80";
    //         }
    //     }
    // };

    // const handleCollapseBank = () => {
    //     sessionStorage.setItem(
    //         "profileCollapse",
    //         sessionStorage.getItem("profileCollapse") === "1" ? "0" : "1"
    //     );
    //     setMyKartaakToggle(!myKartaakToggle);
    // };

    // useEffect(() => {
    //   if (sessionStorage.getItem('profileCollapse') === '1') {
    //     setMyKartaakToggle(true);
    //   } else {
    //     setMyKartaakToggle(false);
    //   }
    //   getUserWallet();
    // }, []);

    const getHint = () => {
        getHintAPI("متن ثابت صفحه کارتک من")
            .then((response) => {
                setHint(response);
            })
            .catch(() => setHint(false))
            .finally(() =>
                setLoading((prevState) => ({
                    ...prevState,
                    hint: false,
                }))
            );
    };

    useEffect(() => {
        setWalletItemShow({
            kv: user?.detail?.isShowWallet,
            km: user?.detail?.isShowWallet,
            lottery: null,
            cash: user?.detail?.isShowWallet,
        });
    }, [user]);

    useEffect(() => {
        getHint();
    }, []);

    return (
        <React.StrictMode>
            <FillProfile
                type={handleSetFillProfile(activeFillProfile)}
                active={showFillProfile}
                closeFillProfile={(close) => {
                    setShowFillProfile(!close);
                    getUserFunc();
                    getUserStars();
                }}
            />

            <SwitchUser
                active={showSwitchUser}
                onClose={() => setShowSwitchUser(false)}
            />

            <div
                className={`profile__header__info ${!loading && "active"} ${
                    myKartaakToggle && "toggle"
                }`}
            >
                <div
                    className="col a-c j-sb profile__header__info__body"
                    style={{
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                        }}
                        className="profile__header__info__body__bg"
                    >
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "0.5rem",
                            }}
                        >
                            <Link
                                to={"/profile/info"}
                                className="profile__header--item1"
                            >
                                <img
                                    src={
                                        userImage !== ""
                                            ? `data:image/png;base64,${userImage}`
                                            : ProfileImage
                                    }
                                    alt=""
                                />
                                <div className="profile__header__edit">
                                    <SVG svg="img-edit" />
                                </div>
                            </Link>

                            <Link
                                to={"/profile/info"}
                                className="profile__header--item2"
                            >
                                <div className="profile__header__name">
                                    <SVG svg={"edit-profile"} color={"white"} />{" "}
                                    {user.detail?.fullName}
                                </div>
                                <div className="profile__header__phone">
                                    {user.mobile_number}
                                </div>
                            </Link>
                        </div>

                        <div>
                            <div style={{ width: "100%", cursor: "pointer" }}>
                                {user.detail?.star < 7 && (
                                    <div
                                        style={{
                                            color: "white",
                                            fontWeight: "500",
                                            fontSize: "13px",
                                            textAlign: "center",
                                            paddingTop: "12px",
                                        }}
                                    >
                                        <span>ستاره بگیر!</span>
                                    </div>
                                )}

                                <div className="profile__get-star__shape" />
                                <div className="row a-c j-c profile__get-star__item">
                                    <div
                                        className="profile__stars"
                                        style={{ padding: "1rem" }}
                                    >
                                        {stars.map((star, index) => (
                                            <div
                                                className={`profile__stars__item ${
                                                    index + 1 <=
                                                        user.detail?.star &&
                                                    "active"
                                                }`}
                                                key={index}
                                                onClick={() =>
                                                    handleSelectStar(index)
                                                }
                                            >
                                                <SVG svg="profile-star" />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"profile__header__info__hint"}>
                    <div>
                        {/* <div className="title">{hint.title}</div> */}
                        {/* <div className="kartland-buy__hint__text">{hint.content}</div> */}
                        {fullhint
                            ? hint?.content
                            : `${hint?.content?.slice(0, 100)}... `}
                        {!fullhint ? (
                            <span
                                style={{ color: "#fd6547" }}
                                onClick={() => setFullhint(true)}
                                className=""
                            >
                                ادامه مطلب
                            </span>
                        ) : (
                            <span
                                style={{ color: "#fd6547" }}
                                onClick={() => setFullhint(false)}
                                className=""
                            >
                                بستن
                            </span>
                        )}
                    </div>
                </div>

                {/* className="profile__header__info__new-bank" */}

                <div style={{ display:'flex' , justifyContent:'center'}} className="profile__header__info__new-bank" >
                    {/* <div
                        onClick={() => {
                            history.push("/wallet");
                        }}
                    >
                        KV
                    </div> */}
                    <div
                    style={{ background: 'red' }}
                        onClick={() => {
                            history.push("/wallet/km?red");
                        }}
                    >
                        KP
                    </div>
                    {/* <div
                        onClick={() => {
                            history.push("/wallet/km?white");
                        }}
                    >
                        KM
                    </div> */}
                </div>
            </div>

            <div
                className={`profile__header__mykartaak__collapse ${
                    myKartaakToggle && "active"
                }`}
            >
                {/*<div*/}
                {/*  onClick={() => handleCollapseBank()}*/}
                {/*  className={`profile__header__info__footer ${*/}
                {/*    myKartaakToggle && "active"*/}
                {/*  }`}*/}
                {/*>*/}
                {/*  پروفایل*/}
                {/*  <SVG svg="arrow" />*/}
                {/*</div>*/}

                {/*<MyKartaak*/}
                {/*  showSwitchUser={showSwitchUser}*/}
                {/*  setShowSwitchUser={(value) => setShowSwitchUser(value)}*/}
                {/*/>*/}
            </div>

            {/* <div className="profile__header__wallets" onClick={() => {}}>
            {wallets.map((walletItem, index) => (
              <div
                onClick={(e) =>
                  e.target.id !== "showIcon" && history.push(walletItem.path)
                }
                key={index}
                className={`profile__header__wallets__item profile__header__wallets__${
                  walletItem.className
                } ${!loading && "active"}`}
                style={{
                  backgroundColor: `${handleShowActive(walletItem.type)}`,
                }}
              >
                <div className="profile__header__wallets__item__shape" />
                <div className="profile__header__wallets__item__shape second" />

                <div className="row a-c">
                  <div className="profile__header__wallets__item__icon">
                    <SVG svg={walletItem.icon} />
                  </div>

                  <div className="">{walletItem.title}</div>

                  <div className="profile__header__wallets__item__arrow">
                    <SVG svg="arrow" />
                  </div>
                </div>

                <div className="profile__header__wallets__item__value">
                  {handleShowWalletValue(walletItem.type)}
                </div>
              </div>
            ))}
          </div> */}

            <Modal active={infoStarModal} close={() => setInfoStarModal(false)}>
                <div className="kv-info">لورم اپیسوم یک متن ساختگی می باشد</div>
            </Modal>
        </React.StrictMode>
    );
};
