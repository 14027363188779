import { useEffect, useState } from "react";
import { SVG, Fade, Dots } from "components";
import { confirmWhiteKmTransfer } from "utils/Services";
import { useHistory } from "react-router-dom";
import toast from "react-hot-toast";

export const Info = ({ forwardedValue, setForwardedValue }) => {
    const history = useHistory();
    const { phone, count, name } = forwardedValue;
    const [loading, setLoading] = useState(false);
    const [done, setDone] = useState(false);

    const handleSubmit = () => {
        setLoading(true);
        confirmWhiteKmTransfer(phone, count)
            .then((res) => {
                if (res) {
                    toast.success("درخواست شما با موفقیت ثبت شد.");
                    setDone(true);
                    setTimeout(() => {
                        history.goBack();
                        history.goBack();
                    }, 500);
                }
            })
            .catch((err) => {
                if (err.response.status == 400) {
                    toast.error("شماره درخواستی شما وجود ندارد!", {
                        id: "phone-notfound",
                    });
                }
                if (err.response.status == 403) {
                    toast.error("موجودی شما برای مقدار بیشتر کافی نمی باشد!", {
                        id: "no-enough-km",
                    });
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        if (name == "")
            history.replace("/transaction/transfer?type=white&status=phone");
    }, []);

    return (
        <Fade>
            <div className={`km-transfer__box ${done && "exit"}`}>
                <div className="km-transfer__box__header">
                    <span className="km-transfer__box__header__title">
                        تعداد KM مورد انتقال :
                    </span>
                    <span className="km-transfer__box__header__value">
                        {count} KM
                    </span>
                </div>
                <div className="km-transfer__box__footer">
                    <div className="row a-c mt-10">
                        <div onClick={() => history.goBack()}>
                            <SVG svg="km-pen" />
                        </div>
                        <span className="km-transfer__box__footer__number">
                            {phone}
                        </span>
                    </div>
                    <span className="km-transfer__box__footer__name">
                        {name}
                    </span>
                </div>
                <div className="km-transfer__box__circle">
                    <SVG svg="km-transfer" />
                </div>
            </div>
            <div className="km-donate__actions">
                <div
                    onClick={() => loading === false && handleSubmit()}
                    className="km-donate__actions__qr"
                >
                    {loading ? (
                        <Dots color="light" />
                    ) : (
                        <>
                            <span>انتقال</span>
                            <SVG svg="km-donate" />
                        </>
                    )}
                </div>
            </div>
        </Fade>
    );
};
