import { useState } from "react";
import { SVG, Modal, CounterInput, Dots } from "components";
import { useHistory } from "react-router-dom";
import { useUser } from "../../../../hooks";
import { getRedKmHistoryAPI, sellRedKmAPI } from "../../../../utils";
import toast from "react-hot-toast";
import { farsiDigitToEnglish } from "utils/Functions";

export const Wallet = ({
    loading,
    refreshRedKmAmount,
    kmHistory,
    type,
    setType,
}) => {
    const history = useHistory();
    const [sellRedKmModal, setSellRedKmModal] = useState(false);
    const [redKmSellAmount, setRedKmSellAmount] = useState<any>(1);
    const [redKmLoading, setRedKmLoading] = useState(false);
    const {
        userState: { user },
    } = useUser();

    const actionData = {
        red: [
            // { icon: "Treasure-black", title: "گنجینه 28250" },
            { icon: "km-sell", title: "فروش به کارتک" },
        ],
        blue: [
            {
                icon: "km-donate-icon",
                title: "اهدا",
                transactionType: "donate",
                status: "main",
            },
        ],
        white: [
            {
                icon: "km-transfer",
                title: "انتقال",
                transactionType: "transfer",
                status: "phone",
            },
            // {
            //   icon: "km-sell",
            //   title: "واگذاری به کارتک",
            //   transactionType: "queue",
            // },
            // {
            //     icon: "consultant",
            //     title: "کد مشاوره",
            //     transactionType: "consultant",
            // },
        ],
    };

    const sellRedKm = () => {
        if (redKmSellAmount <= kmHistory["red"]?.totalAmount) {
            setRedKmLoading(true);
            sellRedKmAPI(redKmSellAmount)
                .then((response) => {
                    if (response.status === 200) {
                        toast.success("فروش KP با موفقیت انجام شد.");
                        setSellRedKmModal(false);
                        refreshRedKmAmount();
                    }
                })
                .catch((err) => {
                    toast.error("مشکلی در فروش KP رخ داد.");
                })
                .finally(() => {
                    setRedKmLoading(false);
                });
        } else {
            toast.error("تعداد KP شما کافی نمی باشد.");
        }
    };

    return (
        <div className="km__wallet wrapper-fix">
            <div className="km__wallet__type">
                {type === "red" && (
                    <div
                        onClick={() => setType("red")}
                        className={`km__wallet__type__circle ${
                            type == "red" && "active"
                        }`}
                    >
                        <div className="km__wallet__type__item red" />
                    </div>
                )}
                {/* <div
          onClick={() => kmHistory.blue?.logs?.length !== 0 && setType("blue")}
          className={`km__wallet__type__circle ${type == "blue" && "active"}`}
        >
          <div className="km__wallet__type__item blue" />
        </div> */}
                {type === "white" && (
                    <div
                        onClick={() => setType("white")}
                        className={`km__wallet__type__circle ${
                            type == "white" && "active"
                        }`}
                    >
                        <div className="km__wallet__type__item white" />
                    </div>
                )}
            </div>
            <div className="km__wallet__cart">
                <div className={`km__wallet__cart__detail ${type}`}>
                    {/* <div className={`km__wallet__cart__detail__title-action ${loading === true && 'active'} ${type}`}>

          </div> */}
                    {type === "red" && (
                        <div
                            className={`km__wallet__cart__detail__count red`}
                            onClick={() => setType("red")}
                        >
                            <div
                                className={`km__wallet__cart__detail__count__circle red`}
                            >
                                <SVG svg="kp-logo" />
                            </div>
                            <span>{kmHistory.red?.totalAmount}</span>
                        </div>
                    )}
                    {/* <div className={`km__wallet__cart__detail__count blue`} onClick={() => kmHistory.blue?.logs?.length !== 0 && setType("blue")}>
            <div className={`km__wallet__cart__detail__count__circle blue`}>
              <SVG svg="km-logo" />
            </div>
            <span>
              {kmHistory.blue?.totalAmount}
            </span>
          </div> */}
                    {type === "white" && (
                        <div
                            className={`km__wallet__cart__detail__count white`}
                            onClick={() => setType("white")}
                        >
                            <div
                                className={`km__wallet__cart__detail__count__circle white`}
                            >
                                <SVG svg="km-logo" />
                            </div>
                            <span>{kmHistory.white?.totalAmount}</span>
                        </div>
                    )}
                </div>
                {/* <div className={`km__wallet__cart__detail ${type}`}>
          <div className={`km__wallet__cart__detail__title-action ${loading === true && 'active'} ${type}`}>
            <span>کیف KM</span>
            {type === 'red' &&
              <div onClick={() => loading === false && refreshRedKmAmount()}><SVG svg="refresh" /></div>}
            <div id="my-km-price" className={`${type}`}>
              <span>ارزش KM های من</span>
              <SVG svg="arrow" />
            </div>
          </div>
          <div className={`km__wallet__cart__detail__count ${type}`}>
            <div className={`km__wallet__cart__detail__count__circle ${type}`}>
              <SVG svg="km-logo" />
            </div>
            <span>
              {kmHistory[type]?.totalAmount}
            </span>
          </div>
        </div> */}
                <div className="km__wallet__cart__action">
                    {actionData[type].map((item, index) => (
                        <div
                            onClick={() => {
                                if (type == "red") {
                                    // switch (index) {
                                    //     case 0:
                                    //         history.push("/trove");
                                    //         break;
                                    //     case 1:
                                    //         setSellRedKmModal(true);
                                    //         break;
                                    // }
                                    setSellRedKmModal(true);
                                    return;
                                }
                                history.push(
                                    `/transaction/${item.transactionType}?type=${type}&status=${item.status}`
                                );
                            }}
                            className="km__wallet__cart__action__item"
                        >
                            <div className="km__wallet__cart__action__item__icon">
                                <SVG svg={item.icon} />
                            </div>
                            <span>{item.title}</span>
                        </div>
                    ))}
                </div>
            </div>

            <Modal
                active={sellRedKmModal}
                close={() => setSellRedKmModal(false)}
            >
                <div className="km__wallet__cart__sell-red-modal">
                    <div className={"km__wallet__cart__sell-red-modal__title"}>
                        تعداد KP برای فروش
                    </div>
                    {/*<div className={'km__wallet__cart__sell-red-modal__input'}>*/}
                    {/*  <CounterInput disabled={false} step={1} max={5000} defaultValue={1}*/}
                    {/*                onChange={value => setRedKmSellAmount(value)}/>*/}
                    {/*</div>*/}
                    <input
                        className={"km__wallet__cart__sell-red-modal__input"}
                        min={1}
                        max={5000}
                        type="text"
                        placeholder="مقدار مورد نظر را وارد نمایید"
                        onChange={(event) =>
                            setRedKmSellAmount(
                                farsiDigitToEnglish(event.target.value)
                            )
                        }
                    />
                    <div
                        className="km__wallet__cart__sell-red-modal__action"
                        onClick={() => !redKmLoading && sellRedKm()}
                    >
                        {redKmLoading ? (
                            <Dots color="light" />
                        ) : (
                            <>
                                <span>فروش</span>
                                <SVG svg="km-sell" />
                            </>
                        )}
                    </div>
                </div>
            </Modal>
        </div>
    );
};
