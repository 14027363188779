import React, { useEffect, useState } from "react";
import {
    Fade,
    PageTopbar,
    SVG,
    Card,
    Hint,
    Spinner,
    Modal,
    NotFoundBox,
    Dots,
    Selectbox,
    SlidingHint,
    CheckBox,
    Toggle,
} from "components";
import {
    buyCardAPI,
    getCardStoreAPI,
    getHintAPI,
    getGiftCardListAPI,
    getOperatorAPI,
    ReceiveGiftCardAPI,
    adsChargeAPI,
} from "utils";
import { useUser } from "hooks";
import { Tabs } from "containers/OrdersContainer/dependencies";
import toast from "react-hot-toast";
import { CardsModal } from "./dependencies";
import { Link, useHistory } from "react-router-dom";
import card from "../../assets/images/card.jpg";
import { createUserAddressAPI, getUserAddresses } from "utils/Services/API";
import { CreateAddress } from "../../components/CreateAddress";
import { Popup } from "components/Popup/Popup";
import { SemanticClassificationFormat } from "typescript";
import { count, log } from "console";
import { FileUploader } from "components/FileUploader";

export const CardsContainer = ({ children }) => {
    const history = useHistory();
    const { getUserDataFromApi } = useUser();
    const [loading, setLoading] = useState(true);
    const [buyLoading, setBuyLoading] = useState(-1);
    const [giftLoading, setGiftLoading] = useState(-1);
    const [cardsList, setCardsList] = useState([]);
    const [myCards, setMyCards] = useState([]);
    const [showHint, setShowHint] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [showFail, setShowFail] = useState(false);
    const [hintDescription, setHintDescription] = useState("");
    const [activeTab, setActiveTab] = useState(1);
    const [hintModal, setHintModal] = useState({
        kvInfo: false,
        kvDose: false,
        kvAssign: false,
        qrCharge: false,
        qrInfo: false,
        qrMine: false,
        qrDose: false,
    });
    const [showHelpRadio, setShowHelpRadio] = useState(false);
    const [showHelp, setShowHelp] = useState(true);
    const [showCondition, setShowCondition] = useState(-1);
    const [sortValue, setSortValue] = useState("");
    const [filterValue, setFilterValue] = useState("");
    const [reciverValue, setReciverValue] = useState("");
    const [receivePrizeModal, setReceivePrizeModal] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState("");
    const [operator, setOperator] = useState("");
    const [myOperator, setMyOperator] = useState("");
    const [receiveType, setReceiveType] = useState(0);
    const {
        userState: { user },
    } = useUser();
    const [buyingCard, setBuyingCard] = useState({ cardId: -1, index: -1 });
    const [ghasedak, setGhasedak] = useState(false);
    const [cardGiftType, setCardGiftType] = useState("");
    const [cardLoading, setCardLoading] = useState(false);
    const [counter, setCounter] = useState(20);
    const [hint1, setHint1] = useState<any>("");
    const [hint2, setHint2] = useState<any>("");
    const [hintLoading, setHintLoading] = useState(true);

    const [showAddressModal, setShowAddressModal] = useState(false);
    const [showLoadingAddressModal, setShowLoadingAddressModal] =
        useState(false);
    const user_token = localStorage.getItem("user_token");
    const [addresses, setAddresses] = useState([]);
    const [modalSelectedAddress, setModalSelectedAddress] = useState({});
    const [addNewAddress, setAddNewAddress] = useState({
        active: false,
        address: null,
    });
    const [currentCardID, setCurrentCardID] = useState(0);
    const [currentCardIndex, setCurrentCardIndex] = useState(0);
    const [buyButtonSpinner, setBuyButtonSpinner] = useState(false);
    const [productCard, setProductCard] = useState(false);

    const [slidingHintStatus, setSlidingHintStatus] = useState(false);
    const [slidingHintText, setSlidingHintText] = useState("");
    const [showFilterDiv, setShowFilterDiv] = useState(false);
    const [showFilter, setShowFilter] = useState(false);
    const [applyReciverValue, setApplyReciverValue] = useState(false);
    const [newDataReciverFilter, setNewDataReciverFilter] = useState(false);
    const [checkValue, setCheckValue] = useState(false);
    const [showPattern, setShowPattern] = useState(false);
    const [showPopupGift, setShowPopupGift] = useState(false);
    const [showPopupReciver, setShowPopupReciver] = useState(false);
    const [pricePopup, setPricePopup] = useState(false);
    const [showPopupPayment, setShowPopupPayment] = useState(false);
    const [loadingToggle, setLoadingToggle] = useState(false);
    const [activeWhiteKm, setActiveWhiteKm] = useState(false);
    const [activeRedKm, setActiveRedKm] = useState(false);
    const [activeMoney, setActiveMoney] = useState(false);
    const [activeChance, setActiveChance] = useState(false);
    const [activeDiscount, setActiveDiscount] = useState(false);
    const [activeCharge, setActiveCharge] = useState(false);
    const [activeInternet, setActiveInternet] = useState(false);
    const [active, setActive] = useState(false);
    const [activeCapitan, setActiveCapitan] = useState(false);
    const [activeCitizen, setActiveCitizen] = useState(false);
    const [activeGoldPanel, setActiveGoldPanel] = useState(false);
    const [activeMoreSeven, setActiveMoreSeven] = useState(false);
    const [activeLessSeven, setActiveLessSeven] = useState(false);
    const [activePrice, setActivePrice] = useState("");
    const [showSortPopup, setShowSortPopup] = useState(false);
    const [activeDes, setActiveDes] = useState(false);
    const [activeDef, setActiveDef] = useState(true);
    const [activeAsc, setActiveAsc] = useState(false);
    const [gift, setGift] = useState("");
    const [userType, setUserType] = useState("");
    const [userValue, setUserValue] = useState(false);
    let countToggle = 0;
    let countfilter = 0;
    const [paymentType, setPaymentType] = useState("");
    const ToggleStatus = () => {
        // setActive(!active);
        // if (storeDetail.isActive === 0) {
        toast.error("فیلتر با موفقیت انجام شد!");
        return;
    };
    const getCardsList = () => {
        setCardLoading(true);
        getCardStoreAPI(filterValue, sortValue, reciverValue, activePrice)
            .then((response) => {
                if (response.status === 200) {
                    setCardsList(response.data);
                }
            })
            .catch((err) => {})
            .finally(() => {
                setLoading(false);
                setCardLoading(false);
            });
    };

    const priceType = (type) => {
        switch (type) {
            case "kv":
                return "KV";
            case "rkm":
                return "KP";
            case "wkm":
                return "KM";
        }
    };

    const getGiftCardsList = () => {
        getGiftCardListAPI()
            .then((response) => {
                if (response.status === 200) {
                    setMyCards(response.data);
                }
            })
            .catch((err) => {})
            .finally(() => {
                // setLoading(false);
            });
    };

    const buyCard = (cardId, index) => {
        setBuyLoading(index);
        buyCardAPI(cardId)
            .then((response) => {
                if (response.status === 200) {
                    // ReceiveGift(cardId, -1);
                    getUserDataFromApi();
                    getCardsList();
                    ReceiveGift(response.data.cardId, -1);
                }
            })
            .catch((err) => {
                if (err.response.status === 403) {
                    toast.error("مقدار KV شما کافی نیست.");
                    setBuyLoading(-1);
                    return;
                }
                toast.error("شما شرایط خرید این کارت را ندارید.");
                setBuyLoading(-1);
            })
            .finally(() => {
                // setBuyLoading(-1);
                setAddNewAddress({ active: false, address: null });
                setShowAddressModal(false);
                setBuyButtonSpinner(false);
            });
    };

    const ReceiveGift = (cardId, index) => {
        setGiftLoading(index);
        let addressFormData = new FormData();
        if (productCard) {
            const tempAddress =
                modalSelectedAddress["state"]["name"] +
                " ، " +
                modalSelectedAddress["city"]["name"] +
                " ، " +
                modalSelectedAddress["address"].toString() +
                " . کد پستی : " +
                modalSelectedAddress["postalCode"];
            addressFormData.append("address", tempAddress);
        }
        ReceiveGiftCardAPI(
            cardId,
            ghasedak
                ? receiveType === 0
                    ? user.mobile_number
                    : phoneNumber
                : "",
            ghasedak ? (receiveType === 0 ? myOperator : operator) : "",
            productCard && addressFormData
        )
            .then((response) => {
                if (response.status === 200) {
                    getGiftCardsList();
                    setShowSuccess(true);
                    setReceivePrizeModal(false);
                }
            })
            .catch((err) => {
                if (err.response.status === 402) {
                    toast.error("این کارت قبلا استفاده شده است.");
                } else if (err.response.status === 403) {
                    setShowFail(true);
                    setReceivePrizeModal(false);
                } else {
                    toast.error("شما شرایط خرید این کارت را ندارید.");
                }
            })
            .finally(() => {
                setGiftLoading(-1);
                setBuyLoading(-1);
                setProductCard(false);
                setGhasedak(false);
            });
    };

    const unitType = (type) => {
        switch (type) {
            case "whiteKM":
                return <span>عدد KM</span>;
            case "redKM":
                return <span>عدد KP</span>;
            case "money":
                return <span>تومان</span>;
        }
    };
    // const getHint = () => {
    //     getHintAPI("صفحات داخلی کارتلا در باره KV بیشتر بدانید سوال 1")
    //         .then((response) => {
    //             setHint1(response);
    //         })
    //         .catch(() => setHint1(false))
    //         .finally(() => setHintLoading(false));

    //     getHintAPI("صفحات داخلی کارتلا در باره KV بیشتر بدانید سوال 2")
    //         .then((response) => {
    //             setHint2(response);
    //         })
    //         .catch(() => setHint2(false))
    //         .finally(() => {});
    // };

    const getGiftType = (type) => {
        switch (type) {
            case "whiteKM":
                return "KM";
            case "redKM":
                return "KP";
            case "money":
                return "تومان";
            case "chance":
                return "شانس";
            case "discount":
                return "درصد تخفیف";
            case "charge":
                return "شارژ";
            case "internet":
                return "بسته اینترنت";
            case null:
            default:
                return "";
        }
    };

    const getGiftAmount = (card) => {
        switch (card.giftType) {
            case "charge":
                return `${card.chargeAmount} هزار تومان`;
            case "internet":
                return `${card.internetAmount} گیگابایت`;
            default:
                return `${card.giftAmount} عدد`;
        }
    };

    console.log(cardsList)

    const cardType = (type) => {
        switch (type) {
            case "redKM":
                return "KP";
            case "whiteKM":
                return "KM";
            case "money":
                return "نقدی";
            case "chance":
                return "شانس قرعه‌کشی";
            case "discount":
                return "کارت تخفیف";
            case "charge":
                return "شارژ تلفن همراه";
            case "internet":
                return "بسته اینترنت";
            case "product":
                return "";
        }
    };

    const sortName = (sort) => {
        switch (sort) {
            case "desc":
                return "گران‌ترین";
            case "asc":
                return "ارزان‌ترین";
            default:
                return "مرتب کنید";
        }
    };

    // const filterName = (filter) => {
    //   switch (filter) {
    //     case 'whiteKM':
    //       return 'KM سفید';
    //     case 'redKM':
    //       return 'KM قرمز';
    //     case 'money':
    //       return 'نقدی';
    //     case 'chance':
    //       return 'شانس';
    //     case 'discount':
    //       return 'تخفیف';
    //     case 'charge':
    //       return 'شارژ';
    //     case 'internet':
    //       return 'اینترنت';
    //     default:
    //       return 'نوع';
    //   }
    // };
    const filterName = (filter) => {
        switch (filter) {
            case "whiteKM":
                return "KM";
            case "redKM":
                return "KP";
            case "money":
                return "نقدی";
            case "chance":
                return "شانس";
            case "discount":
                return "تخفیف";
            case "charge":
                return "شارژ";
            case "internet":
                return "اینترنت";
            default:
                return "نوع هدیه";
        }
    };
    const reciversType = (filter) => {
        switch (filter) {
            case "isCapitan":
                return "کاپیتان ";
            case "citizen":
                return "سیتیزن ";
            case "moreThan7Star":
                return "کاربر با 7 ستاره";
            case "isSevenStar":
                return "کاربر کمتر از 7 ستاره";
            default:
                return "نوع کاربر";
        }
    };

    const checkMyOperator = () => {
        getOperatorAPI(user.mobile_number)
            .then((response) => {
                if (response.status === 200) {
                    if (response.data.OperatorID !== -1) {
                        setMyOperator(response.data.OperatorID);
                    } else {
                        toast.error(
                            "لطفا شماره موبایل خود را بدرستی وارد کنید"
                        );
                    }
                }
            })
            .catch((err) => {})
            .finally(() => {});
    };

    const checkOperator = () => {
        getOperatorAPI(phoneNumber)
            .then((response) => {
                if (response.status === 200) {
                    if (response.data.OperatorID !== -1) {
                        setOperator(response.data.OperatorID);
                    } else {
                        toast.error(
                            "لطفا شماره موبایل خود را بدرستی وارد کنید"
                        );
                    }
                }
            })
            .catch((err) => {})
            .finally(() => {});
    };

    const operatorName = (operator) => {
        switch (operator) {
            case 1:
                return "ایرانسل";
            case 2:
                return "همراه اول";
            case 3:
                return "رایتل";
            default:
                return "-";
        }
    };

    const handleSubmitAddress = (newAddress) => {
        setShowLoadingAddressModal(true);
        const APIResult = createUserAddressAPI(user_token, newAddress);
        APIResult.then((response) => {
            if (response.status === 200) {
                toast.success("آدرس جدید ایجاد شد.");
                getUserAddressList();
                setModalSelectedAddress({});
                setAddNewAddress({ active: false, address: null });
            } else {
                toast.error("مقادیر وارد شده کامل نمی‌باشد.");
            }
        });
        setModalSelectedAddress({});
    };

    const getUserAddressList = () => {
        const userToken = localStorage.getItem("user_token");

        const serverResponse = getUserAddresses(userToken);
        serverResponse.then((response) => {
            if (response.status >= 400) {
                localStorage.removeItem("user_token");
                history.push("/login");
            } else {
                setAddresses(response.data.data.address);
                setShowLoadingAddressModal(false);
            }
            setLoading(false);
        });
        setModalSelectedAddress(false);
    };
    const sortListPrice = [
        { name: "پیش‌فرض", id: "" },
        { name: "گران‌ترین", id: "desc" },
        {
            name: "ارزان‌ترین",
            id: "asc",
        },
    ];
    const sortListType = [
        { name: "همه", id: "" },
        { name: "KM", id: "whiteKM" },
        { name: "KP", id: "redKM" },
        { name: "نقدی", id: "money" },
        { name: "شانس قرعه‌کشی", id: "chance" },
        { name: "شارژ", id: "charge" },
        { name: "تخفیف", id: "discount" },
        { name: "اینترنت", id: "internet" },
        { name: "محصول", id: "product" },
    ];
    // const filterAs =[{name: 'همه', id: ''},
    // {name: 'نوع هدیه', id: 'giftType'},
    // {name: 'نوع کاربر', id: 'UserType'},
    // ]
    const reciverType = [
        { name: "همه", id: "" },
        { name: "کاپیتان", id: "isCapitan" },
        { name: "سیتیزن", id: "citizen" },
        { name: "   7 ستاره", id: "moreThan7Star" },
        { name: " کمتر از 7 ستاره", id: "lessThan7Star" },
    ];

    useEffect(() => {
        getGiftCardsList();
        history.replace("/cards");
        if (localStorage.getItem("cardStore-hint") === "0") setShowHelp(false);
    }, []);

    useEffect(() => {
        getCardsList();
    }, [sortValue, filterValue, reciverValue, activePrice]);
    // useEffect(() => {
    //   getCardsList();
    // }, [checkValue]);

    useEffect(() => {
        if (phoneNumber.length === 11) {
            checkOperator();
        }
    }, [phoneNumber]);

    useEffect(() => {
        checkMyOperator();
    }, [user.mobile_number]);

    useEffect(() => {
        getHintAPI("متن راهنمای ثابت صفحه کارت استور")
            .then((response) => {
                setSlidingHintText(response.content);
            })
            .catch(() => toast.error("درافت راهنما با خطا مواجه شد"));
    }, []);

    return (
        <Fade>
            {/* <PageTopbar  title="کارت‌ها" showKv={true} toggleBackward={false}/> */}
            <PageTopbar
                title="کارت‌ها"
                cardbox
                toggleStar
                onClickKartBox={() => history.push("/cardbox")}
                showKv={false}
                circleHint={slidingHintText}
            />
            <Spinner active={loading} color="dark" />

            <Modal
                active={receivePrizeModal}
                close={() => {
                    setReceivePrizeModal(false);
                    setGhasedak(false);
                }}
            >
                <div className={"my-introduce__prize__modal"}>
                    <div className={"my-introduce__prize__modal__header"}>
                        <div
                            className={
                                receiveType === 0 &&
                                "my-introduce__prize__modal__header__underline"
                            }
                            onClick={() => setReceiveType(0)}
                        >
                            برای خودم
                        </div>
                        <div
                            className={
                                receiveType === 1 &&
                                "my-introduce__prize__modal__header__underline"
                            }
                            onClick={() => setReceiveType(1)}
                        >
                            برای دیگران
                        </div>
                    </div>

                    <div className={"my-introduce__prize__modal__line"} />

                    <div className={"my-introduce__prize__modal__detail"}>
                        {receiveType === 0 && (
                            <div className="my-introduce__prize__modal__detail__me">
                                <div className="my-introduce__prize__modal__detail__me__number">
                                    {`شماره همراه: ${user.mobile_number}`}
                                </div>
                                <div className="my-introduce__prize__modal__detail__me__operator">
                                    {`اپراتور: ${operatorName(myOperator)}`}
                                </div>
                            </div>
                        )}

                        {receiveType === 1 && (
                            <div className="my-introduce__prize__modal__detail__other">
                                <div className="my-introduce__prize__modal__detail__me__number">
                                    <div className="title">شماره همراه</div>
                                    <input
                                        type={"number"}
                                        className={"input"}
                                        placeholder={"09123456789"}
                                        value={phoneNumber}
                                        onChange={(event) =>
                                            setPhoneNumber(event.target.value)
                                        }
                                    />
                                </div>
                                {phoneNumber.length === 11 && operator && (
                                    <div className="my-introduce__prize__modal__detail__me__operator">
                                        {`اپراتور: ${operatorName(operator)}`}
                                    </div>
                                )}
                            </div>
                        )}
                    </div>

                    {cardGiftType === "internet" && (
                        <div className="my-introduce__prize__modal__warning">
                            توجه: برای دریافت این هدیه نباید بسته اینترنتی رزرو
                            داشته باشید درغیر این صورت هدیه شما باطل خواهد شد!
                        </div>
                    )}

                    <div className={"my-introduce__prize__modal__actions"}>
                        <div
                            onClick={() =>
                                buyLoading === -1 &&
                                buyCard(buyingCard.cardId, buyingCard.index)
                            }
                            className={"button"}
                        >
                            {buyLoading === buyingCard.index ? (
                                <Dots color={"light"} />
                            ) : (
                                <span>دریافت</span>
                            )}
                        </div>
                        <div
                            onClick={() => setReceivePrizeModal(false)}
                            className={"button disable"}
                        >
                            بازگشت
                        </div>
                    </div>
                </div>
            </Modal>

            <Modal
                active={hintModal.kvInfo}
                close={() => {
                    setHintModal({ ...hintModal, kvInfo: false });
                }}
                hint="getKv"
            >
                <div className="modal-hint__title">{hint1.title}</div>

                <div className="modal-hint__description">
                    {hintLoading && <Dots color="dark" />}
                    {hint1.content?.split("\n")?.map((str) => (
                        <div>{str}</div>
                    ))}
                </div>

                <div
                    className="modal-hint__more"
                    onClick={() => setHintModal({ ...hintModal, kvDose: true })}
                >
                    <SVG svg="hint-more" /> {hint2.title}
                </div>
            </Modal>

            <Modal
                active={hintModal.kvDose}
                close={() => {
                    setHintModal({ ...hintModal, kvDose: false });
                }}
                hint="price"
            >
                <div className="modal-hint__title">{hint2.title}</div>

                <div className="modal-hint__description">
                    {hint2.content?.split("\n")?.map((str) => (
                        <div>{str}</div>
                    ))}
                </div>

                {/* <div
            className="modal-hint__more"
            onClick={() => setHintModal({ ...hintModal, kvAssign: true })}
          >
            <SVG svg="hint-more" /> مزایای داشتن KV چیست؟
          </div> */}

                <div className="row a-c" style={{ gap: "4px" }}>
                    <div
                        className="modal-hint__button solid"
                        onClick={() =>
                            setHintModal({ ...hintModal, kvDose: false })
                        }
                    >
                        بازگشت
                    </div>

                    <div
                        className="modal-hint__button"
                        onClick={() =>
                            setHintModal({
                                ...hintModal,
                                kvInfo: false,
                                kvDose: false,
                            })
                        }
                    >
                        برو به کارت استور
                    </div>
                </div>
            </Modal>

            <Modal
                active={hintModal.kvAssign}
                close={() => {
                    setHintModal({ ...hintModal, kvAssign: false });
                }}
                hint="kvAssign"
            >
                <div className="modal-hint__title">
                    گرفتن KV چه فایده‌ای داره؟
                </div>

                <div className="modal-hint__description">
                    با آزادسازی KV بعد از خرید هر محصول در
                    <Link to="/market" style={{ color: "red", marginRight: 3 }}>
                        فروشگاه
                    </Link>
                    ، یک هدیه نقدی دریافت می‌کنید و ضمنا هر ماهی که بیشتر از 200
                    KV بگیرید در
                    <Link
                        to="/lottery"
                        style={{ color: "red", marginRight: 1, marginLeft: 1 }}
                    >
                        {" "}
                        قرعه‌کشی{" "}
                    </Link>
                    ماهانه کارتک می‌تونید جایزه بگیرید.
                </div>

                <div
                    className="modal-hint__more"
                    onClick={() => {
                        history.push(`/pay?https://kartaak.com/rules`);
                        setTimeout(() => {
                            if (history.location.pathname.includes("/pay"))
                                window.location.href = `https://kartaak.com/rules`;
                        }, 600);
                    }}
                >
                    <SVG svg="hint-more" /> اطلاعات بیشتر
                </div>

                <div className="row a-c" style={{ gap: "4px" }}>
                    <div
                        className="modal-hint__button solid"
                        onClick={() =>
                            setHintModal({ ...hintModal, kvAssign: false })
                        }
                    >
                        بازگشت
                    </div>

                    <div
                        className="modal-hint__button"
                        onClick={() =>
                            setHintModal({
                                ...hintModal,
                                kvInfo: false,
                                kvDose: false,
                                kvAssign: false,
                            })
                        }
                    >
                        برو به کارتلا
                    </div>
                </div>
            </Modal>
            <Modal
                active={hintModal.qrCharge}
                close={() => {
                    setHintModal({ ...hintModal, qrCharge: false });
                }}
                hint="getKv"
            >
                <div className="modal-hint__title">شارژ کارتلا</div>

                <div className="modal-hint__description">
                    برای شارژ کارتلا باید یکی از بارکدهای کارتک را اسکن کنید.
                </div>

                {/* <div className="modal-hint__more" onClick={() => setHintModal({ ...hintModal, kvDose: true })}>
            <SVG svg="hint-more" />
          </div> */}

                <div className="col a-c" style={{ gap: "4px" }}>
                    <div
                        className="modal-hint__more j-c"
                        onClick={() =>
                            setHintModal({ ...hintModal, qrInfo: true })
                        }
                    >
                        راهنمای پیدا کردن بارکد های کارتک
                    </div>

                    {/* <div
              className="modal-hint__button green"
              onClick={() => chargeKartella()}
            >
              فعلا شارژ کن
            </div> */}
                </div>
            </Modal>

            <Modal
                active={hintModal.qrInfo}
                close={() => {
                    setHintModal({ ...hintModal, qrInfo: false });
                }}
                hint="price"
            >
                <div className="modal-hint__title">
                    راهنمای پیدا کردن بارکد های کارتک
                </div>

                <div className="modal-hint__description">
                    روی گوشی بعضی از کاربران و در بعضی از اماکن خصوصی و عمومی
                    می‌تونید بارکد کارتک را پیدا کنید.
                </div>

                <div
                    className="modal-hint__more"
                    onClick={() => setHintModal({ ...hintModal, qrMine: true })}
                >
                    <SVG svg="hint-more" />
                    شرایط داشتن بارکد اختصاصی
                </div>

                <div className="row a-c" style={{ gap: "4px" }}>
                    <div
                        className="modal-hint__button solid"
                        onClick={() =>
                            setHintModal({ ...hintModal, qrInfo: false })
                        }
                    >
                        بازگشت
                    </div>

                    <div
                        className="modal-hint__button"
                        onClick={() =>
                            setHintModal({
                                ...hintModal,
                                qrCharge: false,
                                qrInfo: false,
                            })
                        }
                    >
                        برو به کارت استور
                    </div>
                </div>
            </Modal>

            <Modal
                active={showHint}
                close={() => {
                    setShowHint(false);
                    setHintDescription("");
                }}
            >
                <div style={{ fontSize: 13, fontWeight: 500 }}>
                    {hintDescription?.split("\n")?.map((str) => (
                        <div>{str}</div>
                    ))}
                </div>
            </Modal>

            <Modal active={showSuccess} close={() => setShowSuccess(false)}>
                <div className="row j-c">
                    <SVG svg="success" />
                </div>

                <div className="cards__modal__success">
                    خرید کارت با موفقیت ثبت شد!
                </div>
                <div className="button" onClick={() => setShowSuccess(false)}>
                    بازگشت
                </div>
            </Modal>

            <Modal active={showFail} close={() => setShowFail(false)}>
                <div className="row j-c">
                    <SVG svg="payment-fail" />
                </div>

                <div className="cards__modal__fail">
                    خرید کارت با مشکل رو‌به‌رو شد!
                </div>
                <div className="cards__modal__fail">
                    لطفا با پشتیبانی ارتباط برقرار کنید
                </div>
                <div className="button" onClick={() => setShowFail(false)}>
                    بازگشت
                </div>
            </Modal>

            <Popup
                showPass={showPopupGift}
                title={"انتخاب نوع هدیه"}
                close={() => setShowPopupGift(false)}
            >
                <div className="col j-sb a-c w-full">
                    <div className="row j-sb a-c w-full mb-8">
                        <div className="filter-title">KM</div>
                        <div className="row">
                            <Toggle
                                value={activeWhiteKm}
                                onChange={() => {
                                    if (!loadingToggle) {
                                        setActiveWhiteKm(!activeWhiteKm);
                                        {
                                            activeWhiteKm
                                                ? setGift("")
                                                : setGift("km");
                                        }
                                        setActiveRedKm(false);
                                        setActiveMoney(false);
                                        setActiveChance(false);
                                        setActiveDiscount(false);
                                        setActiveCharge(false);
                                        setActiveInternet(false);
                                        {
                                            activeWhiteKm
                                                ? setFilterValue("")
                                                : setFilterValue("whiteKM");
                                        }
                                        setActive(true);
                                        // ToggleStatus();
                                    }
                                }}
                            />
                        </div>
                    </div>
                    <div className="row j-sb a-c w-full mb-8">
                        <div className="filter-title">KP</div>
                        <div className="row">
                            <Toggle
                                value={activeRedKm}
                                onChange={() => {
                                    if (!loadingToggle) {
                                        setActiveWhiteKm(false);
                                        setActiveRedKm(!activeRedKm);
                                        {
                                            activeRedKm
                                                ? setGift("")
                                                : setGift("kp");
                                        }
                                        setActiveMoney(false);
                                        setActiveChance(false);
                                        setActiveDiscount(false);
                                        setActiveCharge(false);
                                        setActiveInternet(false);
                                        {
                                            activeRedKm
                                                ? setFilterValue("")
                                                : setFilterValue("redKM");
                                        }
                                        setActive(true);
                                        // ToggleStatus();
                                    }
                                }}
                            />
                        </div>
                    </div>
                    <div className="row j-sb a-c w-full mb-8">
                        <div className="filter-title">نقدی</div>
                        <div className="row">
                            <Toggle
                                value={activeMoney}
                                onChange={() => {
                                    if (!loadingToggle) {
                                        setActiveWhiteKm(false);
                                        setActiveRedKm(false);
                                        setActiveMoney(!activeMoney);
                                        {
                                            activeMoney
                                                ? setGift("")
                                                : setGift("نقدی");
                                        }
                                        setActiveChance(false);
                                        setActiveDiscount(false);
                                        setActiveCharge(false);
                                        setActiveInternet(false);
                                        {
                                            activeMoney
                                                ? setFilterValue("")
                                                : setFilterValue("money");
                                        }
                                        setActive(true);
                                        // ToggleStatus();
                                    }
                                }}
                            />
                        </div>
                    </div>
                    <div className="row j-sb a-c w-full mb-8">
                        <div className="filter-title">شانس</div>
                        <div className="row">
                            <Toggle
                                value={activeChance}
                                onChange={() => {
                                    if (!loadingToggle) {
                                        setActiveWhiteKm(false);
                                        setActiveRedKm(false);
                                        setActiveMoney(false);
                                        setActiveChance(!activeChance);
                                        {
                                            activeChance
                                                ? setGift("")
                                                : setGift("شانس");
                                        }
                                        setActiveDiscount(false);
                                        setActiveCharge(false);
                                        setActiveInternet(false);
                                        {
                                            activeChance
                                                ? setFilterValue("")
                                                : setFilterValue("chance");
                                        }
                                        setActive(true);
                                        // ToggleStatus();
                                    }
                                }}
                            />
                        </div>
                    </div>
                    <div className="row j-sb a-c w-full mb-8">
                        <div className="filter-title">تخفیف</div>
                        <div className="row">
                            <Toggle
                                value={activeDiscount}
                                onChange={() => {
                                    if (!loadingToggle) {
                                        setActiveWhiteKm(false);
                                        setActiveRedKm(false);
                                        setActiveMoney(false);
                                        setActiveChance(false);
                                        setActiveDiscount(!activeDiscount);
                                        {
                                            activeDiscount
                                                ? setGift("")
                                                : setGift("تخفیف");
                                        }
                                        setActiveCharge(false);
                                        setActiveInternet(false);
                                        {
                                            activeDiscount
                                                ? setFilterValue("")
                                                : setFilterValue("discount");
                                        }
                                        setActive(true);
                                        // ToggleStatus();
                                    }
                                }}
                            />
                        </div>
                    </div>
                    <div className="row j-sb a-c w-full mb-8">
                        <div className="filter-title">شارژ</div>
                        <div className="row">
                            <Toggle
                                value={activeCharge}
                                onChange={() => {
                                    if (!loadingToggle) {
                                        setActiveWhiteKm(false);
                                        setActiveRedKm(false);
                                        setActiveMoney(false);
                                        setActiveChance(false);
                                        setActiveDiscount(false);
                                        setActiveCharge(!activeCharge);
                                        {
                                            activeCharge
                                                ? setGift("")
                                                : setGift("شارژ");
                                        }
                                        setActiveInternet(false);
                                        {
                                            activeCharge
                                                ? setFilterValue("")
                                                : setFilterValue("charge");
                                        }
                                        setActive(true);
                                        // ToggleStatus();
                                    }
                                }}
                            />
                        </div>
                    </div>
                    <div className="row j-sb a-c w-full mb-8">
                        <div className="filter-title">بسته اینترنت</div>
                        <div className="row">
                            <Toggle
                                value={activeInternet}
                                onChange={() => {
                                    if (!loadingToggle) {
                                        setActiveWhiteKm(false);
                                        setActiveRedKm(false);
                                        setActiveMoney(false);
                                        setActiveChance(false);
                                        setActiveDiscount(false);
                                        setActiveCharge(false);
                                        setActiveInternet(!activeInternet);
                                        {
                                            activeInternet
                                                ? setGift("")
                                                : setGift("بسته اینترنت");
                                            {
                                                activeInternet
                                                    ? setFilterValue("")
                                                    : setFilterValue(
                                                          "internet"
                                                      );
                                            }
                                            setActive(true);
                                            // ToggleStatus();
                                        }
                                    }
                                }}
                            />
                        </div>
                    </div>
                </div>
            </Popup>

            <Popup
                showPass={showPopupReciver}
                title={"انتخاب نوع کاربر"}
                close={() => setShowPopupReciver(false)}
            >
                <div className="col j-sb a-c w-full">
                    <div className="row j-sb a-c w-full mb-8">
                        <div className="filter-title">کاپیتان</div>
                        <div className="row">
                            <Toggle
                                value={activeCapitan}
                                onChange={() => {
                                    if (!loadingToggle) {
                                        setActiveCapitan(!activeCapitan);
                                        {
                                            activeCapitan
                                                ? setUserType("")
                                                : setUserType("کاپیتان");
                                        }
                                        setActiveCitizen(false);
                                        setActiveMoreSeven(false);
                                        setActiveLessSeven(false);
                                        {
                                            activeCapitan
                                                ? setReciverValue("")
                                                : setReciverValue("isCapitan");
                                        }
                                        setUserValue(true);
                                    }
                                }}
                            />
                        </div>
                    </div>
                    <div className="row j-sb a-c w-full mb-8">
                        <div className="filter-title">سیتیزن</div>
                        <div className="row">
                            <Toggle
                                value={activeCitizen}
                                onChange={() => {
                                    {
                                        setActiveCapitan(false);
                                        setActiveCitizen(!activeCitizen);
                                        {
                                            activeCitizen
                                                ? setUserType("")
                                                : setUserType("سیتیزن");
                                        }
                                        setActiveMoreSeven(false);
                                        setActiveLessSeven(false);
                                        {
                                            activeCitizen
                                                ? setReciverValue("")
                                                : setReciverValue("citizen");
                                        }
                                        setUserValue(true);
                                    }
                                }}
                            />
                        </div>
                    </div>
                    <div className="row j-sb a-c w-full mb-8">
                        <div className="filter-title">کاربر عادی</div>
                        <div className="row">
                            <Toggle
                                value={activeLessSeven}
                                onChange={() => {
                                    {
                                        setActiveCapitan(false);
                                        setActiveCitizen(false);
                                        setActiveMoreSeven(false);
                                        setActiveLessSeven(!activeLessSeven);
                                        {
                                            activeLessSeven
                                                ? setUserType("")
                                                : setUserType("کاربر عادی");
                                        }
                                        {
                                            activeLessSeven
                                                ? setReciverValue("")
                                                : setReciverValue("user");
                                        }
                                        setUserValue(true);
                                    }
                                }}
                            />
                        </div>
                    </div>
                    <div className="row j-sb a-c w-full mb-8">
                        <div className="filter-title">کاربر هفت ستاره</div>
                        <div className="row">
                            <Toggle
                                value={activeMoreSeven}
                                onChange={() => {
                                    {
                                        setActiveCapitan(false);
                                        setActiveCitizen(false);
                                        setActiveMoreSeven(!activeMoreSeven);
                                        {
                                            activeMoreSeven
                                                ? setUserType("")
                                                : setUserType(
                                                      "کاربر هفت ستاره"
                                                  );
                                        }
                                        setActiveLessSeven(false);
                                        {
                                            activeMoreSeven
                                                ? setReciverValue("")
                                                : setReciverValue(
                                                      "isSevenStar"
                                                  );
                                        }
                                        setUserValue(true);
                                    }
                                }}
                            />
                        </div>
                    </div>
                </div>
            </Popup>

            <Popup
                showPass={pricePopup}
                title={"انتخاب نوع پرداخت"}
                close={() => setPricePopup(false)}
            >
                <div className="col j-sb a-c w-full">
                    <div className="row j-sb a-c w-full mb-8">
                        <div className="filter-title">KV</div>
                        <div className="row">
                            <Toggle
                                value={activePrice === "kv"}
                                onChange={() => {
                                    if (!loadingToggle) {
                                        activePrice === "kv"
                                            ? setActivePrice("")
                                            : setActivePrice("kv");
                                    }
                                }}
                            />
                        </div>
                    </div>
                    <div className="row j-sb a-c w-full mb-8">
                        <div className="filter-title">KM</div>
                        <div className="row">
                            <Toggle
                                value={activePrice === "wkm"}
                                onChange={() => {
                                    if (!loadingToggle) {
                                        activePrice === "wkm"
                                            ? setActivePrice("")
                                            : setActivePrice("wkm");
                                    }
                                }}
                            />
                        </div>
                    </div>
                    <div className="row j-sb a-c w-full mb-8">
                        <div className="filter-title">KP</div>
                        <div className="row">
                            <Toggle
                                value={activePrice === "rkm"}
                                onChange={() => {
                                    if (!loadingToggle) {
                                        activePrice === "rkm"
                                            ? setActivePrice("")
                                            : setActivePrice("rkm");
                                    }
                                }}
                            />
                        </div>
                    </div>
                </div>
            </Popup>

            <Popup
                showPass={showSortPopup}
                title={"مرتب کنید"}
                close={() => setShowSortPopup(false)}
            >
                <div className="col j-sb a-c w-full">
                    <div className="sort">
                        <div
                            className="sort-title"
                            onClick={() => {
                                setSortValue("");
                            }}
                        >
                            پیش‌فرض
                        </div>
                        <CheckBox
                            defaultValue={activeDef}
                            disable
                            type="radio"
                            onChange={() => {
                                setActiveDef(!activeDef);
                                setActiveAsc(false);
                                setActiveDes(false);
                                {
                                    activeDef
                                        ? setSortValue("")
                                        : setSortValue("");
                                }
                            }}
                        />
                    </div>
                    <div className="sort">
                        <div
                            className="sort-title"
                            onClick={() => {
                                setSortValue("asc");
                            }}
                        >
                            ارزان‌ترین
                        </div>
                        <CheckBox
                            defaultValue={activeAsc}
                            disable
                            type="radio"
                            onChange={() => {
                                setActiveAsc(!activeAsc);
                                setActiveDef(false);
                                setActiveDes(false);
                                {
                                    activeAsc
                                        ? setSortValue("")
                                        : setSortValue("asc");
                                }
                            }}
                        />
                    </div>
                    <div className="sort">
                        <div
                            className="sort-title"
                            onClick={() => {
                                setSortValue("desc");
                            }}
                        >
                            گران‌ترین
                        </div>
                        <CheckBox
                            defaultValue={activeDes}
                            disable
                            type="radio"
                            onChange={() => {
                                setActiveDes(!activeDes);
                                setActiveAsc(false);
                                setActiveDef(false);
                                {
                                    activeDes
                                        ? setSortValue("")
                                        : setSortValue("desc");
                                }
                            }}
                        />
                    </div>
                </div>
            </Popup>

            <div className="cards">
                <Card>
                    {/* <SlidingHint
            openTitle="راهنمای کارت استور"
            open={slidingHintStatus}
            onClick={() => setSlidingHintStatus(!slidingHintStatus)}
            text={slidingHintText}
          /> */}

                    {/* <div className="cards__item">
            <div className="cards__item__body">
              <div className="cards__item__body__icon">
                <SVG svg="cards-diamond" />
              </div>
              <div className="cards__item__body__description">
                <span className="cards__item__body__description__title">
                  کارت ونوس
                </span>
                <span className="cards__item__body__description__value">
                  در پایان هر ماه درصدی از فروش کارتک بر اساس امتیازات، بین
                  کارتهای ونوس تقسیم می شود
                </span>
              </div>
            </div>
            <div className="cards__item__footer">
              <span>گرفتن کارت ونوس</span>
              <SVG svg="lock" />
            </div>
            <div className="cards__float-hint">
              <Hint theme="blue-purple" />
            </div>
          </div> */}

                    {/* {activeTab === 1 && ( */}
                    {cardLoading ? (
                        <div
                            style={{ width: "100%", height: "100px" }}
                            className={"flex a-c j-c"}
                        >
                            <Dots color={"dark"} />
                        </div>
                    ) : (
                        <Fade>
                            <div
                                onClick={() => {
                                    setShowFilterDiv(true);
                                }}
                                className="row a-c mb-10 mr-12"
                            >
                                <div className="filter-svg">
                                    <SVG svg="filter" />
                                </div>
                                <div
                                    style={{
                                        fontSize: "14px",
                                        color: "#21bbe1",
                                    }}
                                    className="w-full row mr-6 mb-4"
                                >
                                    <div className="row j-c a-c">فیلترها</div>‌
                                    <div
                                        style={{
                                            overflowX: "scroll",
                                            marginLeft: "18px",
                                            paddingLeft: "8px",
                                        }}
                                        className=" w-full row mr-12"
                                    >
                                        <div
                                            className="gift-type"
                                            onClick={() => {
                                                setShowPopupGift(true);
                                            }}
                                        >
                                            نوع هدیه
                                        </div>
                                        {active && gift !== "" && (
                                            <div className="gift-value">
                                                {" "}
                                                {gift}{" "}
                                            </div>
                                        )}

                                        <div
                                            className="gift-type"
                                            onClick={() => {
                                                setShowPopupReciver(true);
                                            }}
                                        >
                                            نوع کاربر
                                        </div>
                                        {userValue && userType !== "" && (
                                            <div className="gift-value">
                                                {" "}
                                                {userType}{" "}
                                            </div>
                                        )}

                                        <div
                                            className="gift-type"
                                            onClick={() => {
                                                setPricePopup(true);
                                            }}
                                        >
                                            نوع پرداخت
                                        </div>
                                        {activePrice !== "" && (
                                            <div className="gift-value">
                                                {" "}
                                                {priceType(activePrice)}{" "}
                                            </div>
                                        )}
                                        {/* <div onClick={() => { setShowPopupPayment(true) }} className="mr-12" style={{ fontSize: '12px', color: '#17cfad', whiteSpace: 'nowrap', borderRadius: '10px', padding: "5px", border: "1px solid #17cfad", background: 'white' }}>نوع پرداخت</div> */}
                                        {/* <div className="mr-12" style={{ fontSize: '12px', color: '#21bbe1', whiteSpace: 'nowrap',borderRadius:'10px',padding:"5px",border:"1px solid #17cfad" }}> { 'fcszc'} </div> */}
                                    </div>
                                </div>
                            </div>
                            <div className="cards__options">
                                <div
                                    className="j-c a-c mt-16 mb-16 mr-12"
                                    style={{
                                        fontSize: "14px",
                                        fontWeight: 700,
                                    }}
                                >
                                    {cardsList.length > 0 &&
                                        `${cardsList.length} کارت موجود است.`}
                                    {cardsList.length === 0 &&
                                        "کارتی موجود نیست."}
                                </div>
                                <div className="cards__options__sort">
                                    {/* <Selectbox
                    data={sortListPrice}
                    placeholder={sortName(sortValue)}
                    onChange={(value) => {
                      setSortValue(value);
                    }}
                    onChangeString={(value) => {
                    }}
                    openAll={true}
                    selectable={true}
                  /> */}
                                    <div className="sort-choice">
                                        <SVG svg="sort-filter"></SVG>
                                        <div
                                            onClick={() => {
                                                setShowSortPopup(true);
                                            }}
                                            className="j-c a-c mt-16 mb-16 mr-6"
                                            style={{
                                                fontSize: "12px",
                                                color: "#21bbe1",
                                            }}
                                        >
                                            مرتب کنید
                                        </div>
                                    </div>
                                </div>

                                {/* <div className="cards__options__sort"> */}
                                {/*<div className="title">مرتب‌سازی براساس:</div>*/}
                                {/* <Selectbox-----------
                      data={sortListType}
                      placeholder={filterName(filterValue)}
                      onChange={(value) => {
                        setFilterValue(value);
                      }}
                      onChangeString={(value) => {
                      }}
                      openAll={true}
                      selectable={true}
                    /> */}
                                {/* <SVG svg="filter-icon" color="#ffffff"/> */}
                                {/* <Selectbox
                      data={filterAs}
                      placeholder={filterName(filterValue)}
                      onChange={(value) => {
                        setFilterValue(value);
                      }}
                      onChangeString={(value) => {
                      }}
                      openAll={true}
                      selectable={true}
                    /> */}
                                {/* </div> */}
                                {/* <div className="cards__options__sort"> */}
                                {/* <Selectbox--------
                      data={reciverType}
                      placeholder={reciversType(reciverValue)}
                      onChange={(value) => {
                        setReciverValue(value)
                        setApplyReciverValue(true)
                      }}
                      onChangeString={(value) => {
                      }}
                      openAll={true}
                      selectable={true}
                    /> */}
                                {/* </div> */}
                                {/* <div className="cards__options__sort"> */}
                                {/* <Selectbox-----------
                      data={reciverType}
                      placeholder={"نوع پرداخت"}
                      onChange={(value) => {
                        setReciverValue(value);
                      }}
                      onChangeString={(value) => {
                      }}
                      openAll={true}
                      selectable={true}
                    /> */}
                                {/* </div> */}
                                {/* <div style={{ marginRight: "2px", marginBottom: '25px' }} className="row j-c a-c">
                    <CheckBox
                      disable
                      type="check"
                      onChange={() => {
                        // getCardsList();
                        // setShowFilterDiv(false);
                        // setApplyReciverValue(true)
                      }}
                    />
                  </div> */}
                            </div>
                            {/* <div className="row a-c p15">
                <div className="cards__options__sort">
                  <Selectbox
                    data={sortListPrice}
                    placeholder={sortName(sortValue)}
                    onChange={(value) => {
                      setSortValue(value);
                    }}
                    onChangeString={(value) => {
                    }}
                    openAll={true}
                    selectable={true}
                  />
                </div>
              </div> */}

                            {cardsList.map(
                                (cardItem, index) =>
                                    cardItem.type === "buy" && (
                                        <div
                                            className="cards__item white"
                                            key={index}
                                        >
                                            <div
                                                className="cards__item__body"
                                                // style={{ borderColor: cardItem.color }}
                                            >
                                                {/*<div className="cards__item__title">*/}
                                                {/*  {cardItem.title}*/}
                                                {/*</div>*/}
                                                {/*<div className="cards__item__image">*/}
                                                {/*  <img src={cardItem.photo} alt="" loading="lazy"/>*/}
                                                {/*</div>*/}

                                                <div className="cards__item__detail">
                                                    <div className="cards__item__detail__title">
                                                        {cardItem.title}
                                                    </div>
                                                    <div className="cards__item__detail__desc">
                                                        <div className="cards__item__detail__desc__image">
                                                            <img
                                                                src={
                                                                    cardItem.photo
                                                                }
                                                                alt=""
                                                                loading="lazy"
                                                            />
                                                        </div>
                                                        <div className="cards__item__detail__desc__type">
                                                            <div className="cards__item__detail__desc__type__name">
                                                                {/*شانس قرعه کشی گوشی ایفون 13 پلاس با رنگ زرد*/}
                                                                {cardItem.giftType ===
                                                                "product"
                                                                    ? cardItem.description
                                                                    : cardType(
                                                                          cardItem.giftType
                                                                      )}
                                                            </div>
                                                            {cardItem.giftType ===
                                                                "internet" && (
                                                                <div className="cards__item__detail__desc__type__number">
                                                                    30 روزه
                                                                </div>
                                                            )}
                                                            <div className="cards__item__detail__desc__type__number">
                                                                {cardItem.giftType !==
                                                                    "product" &&
                                                                    `${getGiftAmount(
                                                                        cardItem
                                                                    )}`}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="cards__item__detail__condition">
                                                        <div>
                                                            شرایط خرید کارت:
                                                        </div>
                                                        <div
                                                            onClick={() =>
                                                                setShowCondition(
                                                                    index
                                                                )
                                                            }
                                                        >
                                                            {showCondition !==
                                                                index &&
                                                            cardItem.guide
                                                                .length > 34 ? (
                                                                <>
                                                                    {`${cardItem.guide.slice(
                                                                        0,
                                                                        34
                                                                    )}`}
                                                                    <span className="cards__item__detail__condition__show-details">
                                                                        {" "}
                                                                        نمایش
                                                                        بیشتر
                                                                        ...
                                                                    </span>
                                                                </>
                                                            ) : (
                                                                cardItem.guide
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="cards__item__detail__price">
                                                        <div className="cards__item__detail__price__kv">
                                                            <div>قیمت:</div>
                                                            <div>{`${
                                                                cardItem.price
                                                            } ${priceType(
                                                                cardItem.priceType
                                                            )}`}</div>
                                                        </div>
                                                        <div
                                                            className={`cards__item__detail__price__buy ${
                                                                !cardItem
                                                                    .isCondition
                                                                    ?.isCondition &&
                                                                "deactive"
                                                            }`}
                                                            onClick={() => {
                                                                if (
                                                                    cardItem
                                                                        .isCondition
                                                                        ?.isCondition &&
                                                                    buyLoading ===
                                                                        -1
                                                                ) {
                                                                    if (
                                                                        cardItem.ghasedak ===
                                                                        1
                                                                    ) {
                                                                        setReceivePrizeModal(
                                                                            true
                                                                        );
                                                                        setBuyingCard(
                                                                            {
                                                                                cardId: cardItem.id,
                                                                                index: index,
                                                                            }
                                                                        );
                                                                        setCardGiftType(
                                                                            cardItem.giftType
                                                                        );
                                                                        setGhasedak(
                                                                            true
                                                                        );
                                                                    } else if (
                                                                        cardItem.giftType ===
                                                                        "product"
                                                                    ) {
                                                                        setCurrentCardID(
                                                                            cardItem.id
                                                                        );
                                                                        setCurrentCardIndex(
                                                                            index
                                                                        );
                                                                        setShowLoadingAddressModal(
                                                                            true
                                                                        );
                                                                        setShowAddressModal(
                                                                            true
                                                                        );
                                                                        getUserAddressList();
                                                                        setProductCard(
                                                                            true
                                                                        );
                                                                    } else {
                                                                        console.log('sina')
                                                                        buyCard(
                                                                            cardItem.id,
                                                                            index
                                                                        );
                                                                    }
                                                                } else if (
                                                                    buyLoading ===
                                                                    -1
                                                                ) {
                                                                    toast.error(
                                                                        "شما شرایط دریافت این کارت را ندارید."
                                                                    );
                                                                }
                                                            }}
                                                        >
                                                            {buyLoading ===
                                                            index ? (
                                                                <Dots
                                                                    color={
                                                                        "light"
                                                                    }
                                                                />
                                                            ) : (
                                                                <span>
                                                                    خرید
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>

                                                    {/* <div className="cards__item__gift">
                          <div className="cards__item__gift__icon">
                            <SVG svg="card-gift" color={cardItem.color} />
                          </div>
                          {cardItem.giftAmount} {unitType(cardItem.giftType)}
                        </div> */}
                                                    {/*<div className="cards__item__description">*/}
                                                    {/*  /!* <div className="cards__item__gift__icon">*/}
                                                    {/*    <SVG svg="condition" color={cardItem.color} />*/}
                                                    {/*  </div> *!/*/}
                                                    {/*  {cardItem.guide}*/}
                                                    {/*</div>*/}
                                                </div>
                                                {/* <div className="cards__item__body__wrapper">
                        <div className="col">
                          <span className="cards__item__body__description__title">
                            {cardItem.title}
                          </span>
                          <span className="cards__item__body__description__count">
                            {cardItem.giftAmount} {unitType(cardItem.giftType)}
                          </span>
                        </div>
                      </div>
                      <div className="cards__hint">
                        <div className="cards__hint__line"></div>
                        <div
                          className="cards__hint__box"
                          onClick={() => {
                            setShowHint(true);
                            setHintDescription(cardItem.guide);
                          }}
                        >
                          <SVG svg="info" />
                          <span>راهنمای خرید</span>
                        </div>
                        <div className="cards__hint__line"></div>
                      </div>
                      <div className="cards__price">
                        <span>قیمت:</span>
                        <span>{cardItem.priceType + " " + cardItem.price}</span>
                      </div> */}
                                            </div>

                                            {/*<div className={`cards__item__footer ${!cardItem.isCondition?.isCondition && 'deactive'}`}*/}
                                            {/*     onClick={() => {*/}
                                            {/*       if (cardItem.isCondition?.isCondition && buyLoading === -1) {*/}
                                            {/*         buyCard(cardItem.id, index);*/}
                                            {/*       } else if (buyLoading === -1) {*/}
                                            {/*         // toast.error('شما شرایط دریافت این کارت را ندارید.');*/}
                                            {/*       }*/}
                                            {/*     }}>*/}
                                            {/*  {buyLoading === index ? (*/}
                                            {/*    <Dots color="dark"/>*/}
                                            {/*  ) : (*/}
                                            {/*    <div className="cards__item__footer__button">*/}
                                            {/*      <span className="cards__item__footer__button__title">قیمت:</span>*/}
                                            {/*      <div className="cards__item__footer__button__price">*/}
                                            {/*        <span>{cardItem.priceType + " " + cardItem.price}</span>*/}
                                            {/*        <SVG svg="kv"/>*/}
                                            {/*      </div>*/}

                                            {/*      /!* <SVG svg={cardItem.isCondition?.isCondition ? "unlock" : "lock"} /> *!/*/}
                                            {/*    </div>*/}
                                            {/*  )}*/}
                                            {/*</div>*/}
                                        </div>
                                    )
                            )}
                            {cardsList.length === 0 && (
                                <div style={{ marginTop: 200 }}>
                                    <NotFoundBox
                                        title="کارتی یافت نشد!"
                                        bio=""
                                        icon="profile-card"
                                    />
                                </div>
                            )}
                            {/*
                <div className={`kartella__help ${!showHelp && "deactive"}`}>
                  <div
                    className="kartella__help__cta"
                    id="hintArea"
                    onClick={(e) => {
                      if ((e.target as HTMLInputElement).id === "hintArea") {
                        getHint();
                        setHintModal({...hintModal, kvInfo: true});
                      }
                    }}
                  >
                    <div
                      className="kartella__help__close"
                      onClick={() => {
                        setShowHelp(false);
                        if (showHelpRadio) localStorage.setItem("cardStore-hint", "0");
                      }}
                    >
                      <SVG svg="times"/>
                    </div>

                    <div className="kartella__help__title" id="hintArea">
                      درباره‌ی KV بیشتر بدانید
                    </div>
                  </div>

                  <div
                    className="kartella__help__show"
                    onClick={() => setShowHelpRadio((prev) => !prev)}
                  >
                    <div
                      className={`kartella__help__show__radio ${showHelpRadio && "active"
                      }`}
                    >
                      <div/>
                    </div>
                    <div>دیگه نمایش نده</div>
                  </div>
                </div>
                */}
                        </Fade>
                    )}

                    {activeTab === 2 && (
                        <Fade>
                            {cardsList.map(
                                (card, index) =>
                                    card.type === "gift" && (
                                        <div className={"gift-card"}>
                                            <div className="gift-card__info">
                                                <div className="gift-card__info__icon">
                                                    <img
                                                        src={`${card.photo}`}
                                                        alt=""
                                                    />
                                                </div>
                                                <div className="gift-card__info__description">
                                                    <div className="gift-card__info__help">
                                                        <Hint />
                                                    </div>
                                                    <div className="gift-card__info__title">
                                                        {card.title}
                                                    </div>
                                                    <div className="gift-card__info__amount">
                                                        {`${getGiftAmount(
                                                            card
                                                        )} ${getGiftType(
                                                            card.giftType
                                                        )}`}
                                                    </div>
                                                </div>
                                            </div>
                                            {/*<div className="gift-card__countdown">*/}
                                            {/*  <div className="gift-card__countdown__line"/>*/}
                                            {/*  <div className="gift-card__countdown__time">*/}
                                            {/*    48:34:43*/}
                                            {/*  </div>*/}
                                            {/*  <div className="gift-card__countdown__line"/>*/}
                                            {/*</div>*/}
                                            <div
                                                className="gift-card__button"
                                                onClick={() =>
                                                    buyLoading === -1 &&
                                                    ReceiveGift(card.id, index)
                                                }
                                            >
                                                {buyLoading === index ? (
                                                    <Dots color="light" />
                                                ) : (
                                                    <>
                                                        <span>دریافت</span>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    )
                            )}
                            {myCards.length > 0 && (
                                <div className={"title"}>
                                    کارت‌هایی که قبلا دریافت کرده‌اید
                                </div>
                            )}
                            {myCards.map(
                                (card, index) =>
                                    card.isPaid === 1 && (
                                        <>
                                            <div
                                                className={"gift-card deactive"}
                                            >
                                                <div className="gift-card__info">
                                                    <div className="gift-card__info__icon">
                                                        <img
                                                            src={card.image}
                                                            alt=""
                                                        />
                                                    </div>
                                                    <div className="gift-card__info__description">
                                                        <div className="gift-card__info__help">
                                                            <Hint />
                                                        </div>
                                                        <div className="gift-card__info__title">
                                                            {card.title}
                                                        </div>
                                                        <div className="gift-card__info__amount">
                                                            {`${getGiftAmount(
                                                                card
                                                            )} ${getGiftType(
                                                                card.giftType
                                                            )}`}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )
                            )}
                            {/* {myCards.length === 0 && cardsList.length === 0 && (
                <div style={{ marginTop: 200 }}>
                  <NotFoundBox
                    title="کارتی یافت نشد!"
                    bio=""
                    icon="cards-mine"
                  />
                </div>
              )} */}
                        </Fade>
                    )}
                </Card>
            </div>
            <CardsModal />

            <Modal
                active={showAddressModal}
                close={() => {
                    setShowAddressModal(false);
                    setModalSelectedAddress({});
                    setAddresses([]);
                }}
            >
                {showLoadingAddressModal === true ? (
                    <div className="my-introduce__address-modal">
                        <Spinner
                            active={showLoadingAddressModal}
                            color="dark"
                        />
                    </div>
                ) : (
                    <>
                        <h6 style={{ marginBottom: "10px" }}>انتخاب آدرس : </h6>
                        <div className="my-introduce__address-modal__add-address">
                            <Card>
                                {addresses.length > 0 ? (
                                    addresses.map((address) => (
                                        <div
                                            key={address.id}
                                            className="user__address"
                                            style={{
                                                margin: "10px 0px",
                                                border: "1px solid lightgray",
                                                borderRadius: "10px",
                                                backgroundColor:
                                                    address.id ===
                                                    modalSelectedAddress["id"]
                                                        ? "#17c7a7"
                                                        : "#e8e8e8",
                                            }}
                                            onClick={(e) => {
                                                setModalSelectedAddress(
                                                    address
                                                );
                                            }}
                                        >
                                            <div className="user__address__icon">
                                                <SVG svg="location" />
                                            </div>

                                            <div className="user__address__info">
                                                <div className="user__address__title">
                                                    {address.title}
                                                </div>
                                                <div className="user__address__text">
                                                    {address.address}
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <h4>لطفا یک آدرس اضافه کنید</h4>
                                )}
                            </Card>
                        </div>

                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                flexWrap: "nowrap",
                                gap: "5px",
                            }}
                        >
                            <button
                                className="button red"
                                style={{
                                    fontFamily: "inherit",
                                    height: "45px",
                                    fontSize: "12px",
                                }}
                                onClick={() => {
                                    setAddNewAddress({
                                        active: true,
                                        address: null,
                                    });
                                }}
                            >
                                افزودن آدرس جدید
                            </button>

                            {addresses.length > 0 && (
                                <button
                                    className="button green"
                                    style={{
                                        fontFamily: "inherit",
                                        height: "45px",
                                        fontSize: "12px",
                                    }}
                                    onClick={() => {
                                        if (buyButtonSpinner) {
                                            toast.loading(
                                                "در حال پردازش ااطلاعات"
                                            );
                                        } else if (
                                            Object.entries(modalSelectedAddress)
                                                .length === 0
                                        ) {
                                            toast.error(
                                                "لطفا یک آدرس انتخاب نمایید."
                                            );
                                        } else {
                                            setBuyButtonSpinner(true);
                                            buyCard(
                                                currentCardID,
                                                currentCardIndex
                                            );
                                        }
                                    }}
                                >
                                    {buyButtonSpinner === true ? (
                                        <Dots color={"light"} />
                                    ) : (
                                        "خرید"
                                    )}
                                </button>
                            )}
                        </div>
                    </>
                )}
            </Modal>

            <CreateAddress
                active={addNewAddress}
                setClose={(e) => {
                    setAddNewAddress({ active: false, address: null });
                }}
                onSubmit={(id, address) => {
                    handleSubmitAddress(address);
                }}
                isFullScreen={true}
            />
        </Fade>
    );
};
