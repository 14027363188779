import { useEffect, useState } from "react";
import Slider from "react-slick";
import "./Information.scss";
import { SVG, Dots } from "components";
import { Chip } from "components/Chip";
import { useRef } from "react";
import { useHistory } from "react-router";
import { Line } from "rc-progress";
import moment from "jalali-moment";
import { transformPrice } from "utils/Functions";
import { MoneyWalletAPI } from "utils/Services";

export const Information = ({
    detail = {
        cash: {
            liquidity: 0,
        },
        tokens: {
            KV: 0,
        },
    },
}) => {
    const history = useHistory();
    const sliderRef = useRef(null);
    const [cashHistory, setCashHistory] = useState({
        detail: [],
        info: {
            amount: 0,
        },
    });
    const [loading, setLoading] = useState(true);
    const user_token = localStorage.getItem("user_token");
    // const beforeChange = (prev: number, next: number) => {
    //   const prevSlideElement = sliderRef.current.innerSlider.list.querySelector(
    //     `[data-index="${prev}"]`
    //   );
    //   const nextSlideElement = sliderRef.current.innerSlider.list.querySelector(
    //     `[data-index="${next}"]`
    //   );
    //   setTimeout(() => {
    //     prevSlideElement.classList.remove("next-slide-anim");
    //     nextSlideElement.classList.add("bounceIn");
    //   });
    // };

    const settings = {
        // centerMode: true,
        infinite: true,
        // centerPadding: "35px",
        slidesToShow: 1,
        speed: 50,
        arrows: false,
        dots: true,
        // rtl: true
        // beforeChange,
    };

    const getUserWallet = () => {
        const APIResult = MoneyWalletAPI(user_token);
        APIResult.then((response) => {
            setCashHistory(response.data.data);
            setLoading(false);
        });
    };

    useEffect(() => {
        getUserWallet();
    }, []);

    return (
        <Slider {...settings} ref={sliderRef}>
            <div
                className="sliderBox cash"
                onClick={() => history.push("/cash")}
            >
                <div className="sliderBox__header">
                    <div className="sliderBox__header__title">
                        <span>کیف نقدی</span>
                        {/* <SVG svg="cash-bag" /> */}
                    </div>
                    <div className="sliderBox__header__cash">
                        <div className="sliderBox__header__cash__value">
                            <span className="sliderBox__header__cash__value__lable">
                                موجودی حساب
                            </span>
                            <span className="sliderBox__header__cash__value__number">
                                {loading ? (
                                    <Dots color="light" />
                                ) : (
                                    transformPrice(cashHistory.info.amount)
                                )}
                            </span>
                        </div>
                        <div className="sliderBox__header__cash__type">
                            <span>تومان</span>
                        </div>
                    </div>
                </div>

                {/* <div className="sliderBox__footer"
          onClick={() => history.push('/cash')}
        >
          <div className="sliderBox__footer__shape">
            <SVG svg="bottom-shape" color="#ffffff" />
          </div>
          <div className="sliderBox__footer__shape last">
            <SVG svg="bottom-shape" color="#21bbe1" />
          </div>
          <div className="sliderBox__footer__harvest">
            <SVG svg="harvest" />
            <span>برداشت</span>
          </div>
          <div className="sliderBox__footer__history">
            <span>تاریخچه</span>
            <SVG svg="history" />
          </div>
        </div> */}
            </div>

            <div className="slider-box">
                <div
                    className="token-history__card"
                    onClick={() => history.push("/wallet")}
                >
                    <div
                        className="col j-sb"
                        style={{
                            height: "100%",
                            width: "100%",
                            marginLeft: 100,
                        }}
                    >
                        <div className="row a-c">
                            {/* <SVG svg="kv-shape" /> */}
                            کیف KV
                        </div>

                        <div className="col">
                            <div className="row j-sb mb-16">
                                <div>زمان باقی‌مانده</div>
                                <div>
                                    {moment().jDaysInMonth() - moment().jDate()}
                                    روز
                                </div>
                            </div>

                            <div className="row">
                                <Line
                                    className="marketing__qr__box__footer__info__progress"
                                    percent={
                                        ((moment().jDaysInMonth() -
                                            moment().jDate()) *
                                            100) /
                                        moment().jDaysInMonth()
                                    }
                                    strokeWidth={2}
                                    trailWidth={2}
                                    strokeColor="#17CFAD"
                                    trailColor="#ffffff"
                                    transition="1"
                                />
                            </div>
                        </div>
                    </div>

                    <div className="token-history__jeton">
                        <div className="token-history__jeton__circle">
                            <SVG svg="kv" />
                        </div>
                        <div className="token-history__jeton__count">
                            {detail.tokens?.KV}
                        </div>
                    </div>
                </div>

                {/* <div className="slider-box__kv__button"
          
        >
          <SVG svg="history" />
          تاریخچه
        </div> */}
            </div>
        </Slider>
    );
};
