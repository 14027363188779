import React, { useState, useEffect } from "react";
import { NewAddress, SVG, Padding, PageTopbar, Spinner } from "components";
import { cloneDeep } from "lodash";
import {
  getUserAddresses,
  deleteUserAddress,
  createUserAddressAPI,
  updateUserAddressAPI,
} from "../utils/Services/API";
import { useHistory } from "react-router-dom";
import toast from "react-hot-toast";
import { CreateAddress } from "components/CreateAddress";

const Addresses = () => {
  const user_token = localStorage.getItem("user_token");
  const [showNewAddress, setShowNewAddress] = useState({
    active: false,
    address: null,
  });
  const [addresses, setAddresses] = useState([{}]);
  const [loading, setLoading] = useState(true);
  const history = useHistory();

  useEffect(() => {
    history.replace("/profile/addresses");
  }, []);

  const handleSubmitAddress = (id, newAddress, isUpdate) => {
    const currAddresses = cloneDeep(addresses);
    if (isUpdate) {
      const APIResult = updateUserAddressAPI(user_token, id, newAddress);
      APIResult.then((response) => {
        if (response.status === 200) {
          toast.success("آدرس با موفقیت بروزرسانی شد");
          getUserAddressList();
          setShowNewAddress({ active: false, address: null });
        } else {
          toast.error("مقادیر وارد شده کامل نمی‌باشد.");
        }
      });
      return;
    }

    const APIResult = createUserAddressAPI(user_token, newAddress);
    APIResult.then((response) => {
      if (response.status === 200) {
        toast.success("آدرس جدید ایجاد شد.");
        const newAddresses = currAddresses.concat(newAddress);
        setAddresses(newAddresses);
        setShowNewAddress({ active: false, address: null });
      } else {
        toast.error("مقادیر وارد شده کامل نمی‌باشد.");
      }
    });
  };

  const handleShowNewAddress = (address) => {
    setShowNewAddress({ active: true, address: address });
  };

  const getUserAddressList = () => {
    const userToken = localStorage.getItem("user_token");

    const serverResponse = getUserAddresses(userToken);
    serverResponse.then((response) => {
      if (response.status >= 400) {
        localStorage.removeItem("user_token");
        history.push("/login");
      } else {
        setAddresses(response.data.data.address);
      }
      setLoading(false);
    });
  };

  const removeUserAddress = (addressId) => {
    setLoading(true);
    const userToken = localStorage.getItem("user_token");

    const serverResponse = deleteUserAddress(userToken, addressId);
    serverResponse.then((response) => {
      if (response.status === 401) {
        localStorage.removeItem("user_token");
        history.push("/login");
        setLoading(false);
      } else {
        toast.success("آدرس انتخابی حذف شد.");
        getUserAddressList();
      }
    });
  };

  useEffect(() => {
    getUserAddressList();
  }, []);

  return (
    <div className="addresses">
      <PageTopbar title="آدرس‌های من" />
      <Spinner active={loading} color="dark" />
      {addresses.length > 0 ? (
        addresses.map((address, index) => (
          <Padding>
            <div className="user__address" key={index}>
              <div
                className="row a-c"
                onClick={() => handleShowNewAddress(address)}
              >
                <div className="user__address__icon">
                  <SVG svg="location" />
                </div>

                <div className="user__address__info">
                  <div className="user__address__title">{address.title}</div>
                  <div className="user__address__text">{address.address}</div>
                </div>
              </div>

              <div className="user__address__tools">
                <div
                  className="uat__delete"
                  onClick={() => removeUserAddress(address.id)}
                >
                  <SVG svg="times" />
                </div>

                <div
                  className="uat__edit"
                  onClick={() => handleShowNewAddress(address)}
                >
                  <SVG svg="edit-rec" />
                </div>
              </div>
            </div>
          </Padding>
        ))
      ) : (
        <div className="user__address__not-found">
          <div className="user__address__not-found__logo">
            <SVG svg="location" />
          </div>
          <span className="user__address__not-found__title">
            آدرسی وجود ندارد
          </span>
          <span className="user__address__not-found__description">
            برای خرید آدرس را اضافه کنید
          </span>
        </div>
      )}

       <div
        className="user__address__order-button"
        onClick={() => setShowNewAddress({ ...showNewAddress, active: true })}
      >
        <div className="user__address__shape">
          <SVG svg="bottom-shape" color="#21BBE1" />
        </div>

        <div className="user__address__order-button__title">
          افزودن آدرس جدید
        </div>
        <div className="user__address__order-button__icon">
          <SVG svg="marker" />
        </div>
      </div>

       <CreateAddress
        active={showNewAddress}
        setClose={(close) =>
          setShowNewAddress({ active: false, address: null })
        }
        onSubmit={(id, result, isUpdate) =>
          handleSubmitAddress(id, result, isUpdate)
        }
        isFullScreen={true}
      />

      {/* <NewAddress*/}
      {/*  active={showNewAddress}*/}
      {/*  setClose={(close) =>*/}
      {/*    setShowNewAddress({ active: false, address: null })*/}
      {/*  }*/}
      {/*  onSubmit={(result) => handleSubmitAddress(result)}*/}
      {/*/>*/}
    </div>
  );
};

export default Addresses;
