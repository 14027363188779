import {Card, CheckBox, Dots, Fade, PageTopbar, SVG} from "components";
import React, {createContext, useEffect, useState} from "react";
import toast from "react-hot-toast";
import {useHistory} from "react-router-dom";
import {createAdsAPI, createMessageAPI} from "utils/Services/API";
import FillCustomAge from "./FillCustom/FillCustomAge/FillCustomAge";
import FillCustomCity from "./FillCustom/FillCustomCity/FillCustomCity";
import FillCustomEducation from "./FillCustom/FillCustomEducation/FillCustomEducation";
import FillCustomEmployment from "./FillCustom/FillCustomEmployment/FillCustomEmployment";
import FillCustomGender from "./FillCustom/FillCustomGender/FillCustomGender";
import FillCustomMarital from "./FillCustom/FillCustomMarital/FillCustomMarital";
import {ImageEditor} from "../../components/ImageEditor";
import img from "../../assets/images/kartellaMock.jpg";
import imageCompression from "browser-image-compression";

export const CreateMessageContext = createContext({});

export const CreateMessageContainer = () => {
  const user_token = localStorage.getItem("user_token");
  const history = useHistory();
  const [isCustomize, setIsCustomize] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [characterCount, setCharacterCount] = useState(0);
  const [messageDetail, setMessageDetail] = useState({
    text: "",
    media: {
      url: "",
      file: null,
    },
  });
  const [customizeDetail, setCustomizeDetail] = useState({
    address: {
      countryId: 1,
      countryValue: "تعیین نشده",
      stateId: 0,
      stateValue: "",
      cityId: 0,
      cityValue: "",
      districtId: 0,
      districtValue: "",
    },
    gender: {
      gender: 0,
      genderValue: "تعیین نشده",
    },
    age: {
      from: 12,
      to: 99,
    },
    education: {
      educationStatus: 0,
      educationStatusValue: "تعیین نشده",
      educationDegree: 0,
      educationDegreeValue: "",
      studyId: 0,
      studyField: "",
    },
    employment: {
      employment: 0,
      employmentValue: "تعیین نشده",
      job: 0,
      jobValue: "",
      guild: 0,
      freelanceJob: 0,
      emeritus: 0,
      readyToWork: 0,
      skill: 0,
    },
    marital: {
      marital: 0,
      maritalValue: "تعیین نشده",
      maritalChildren: 0,
      maritalChildrenCount: null,
    },
  });
  const [getKmCustom, setGetKmCustom] = useState(false);
  const [adsImage, setAdsImage] = useState<any>({file: null, url: null});
  const [picture, setPicture] = useState<any>("");
  const [showImageEditor, setShowImageEditor] = useState(false);
  const [compressLoading, setCompressLoading] = useState(0);

  const compressOptions = {
    maxSizeMB: 0.6,
    maxWidthOrHeight: 1024,
    onProgress: (percentage) => {
      setCompressLoading(percentage);
    },
  };

  const checkMessageType = () => {
    if (messageDetail.text.length === 0) {
      if (adsImage.file !== null) {
        return "picture";
      } else {
        return 'error';
      }
    } else {
      if (adsImage.file !== null) {
        return "text_picture";
      } else {
        return 'text';
      }
    }
  };

  const handleSubmitMessage = () => {
    if (checkMessageType() === 'error') {
      toast.error("متن یا تصویر را انتخاب نمایید.");
      return;
    }

    // if (messageDetail.text.length === 0) {
    //   toast.error("متن پیام را وارد نمایید");
    //   return;
    // }
    // if (messageDetail.media.file === null) {
    //   toast.error("تصویر یا ویدیوی پیام را وارد نمایید");
    //   return;
    // }

    setSubmitLoading(true);

    var formData = new FormData();

    formData.append("text", messageDetail.text);
    formData.append("type", checkMessageType());

    if (!isCustomize) {
      formData.append("isCustomize", "");
    } else {
      formData.append("isCustomize", "1");
      formData.append("isGetKmFromStore", getKmCustom ? "1" : "0");

      customizeDetail.address.countryId !== 1 &&
      formData.append("country", String(customizeDetail.address.countryId));
      customizeDetail.address.stateId !== 0 &&
      formData.append("state", String(customizeDetail.address.stateId));
      customizeDetail.address.cityId !== 0 &&
      formData.append("city", String(customizeDetail.address.cityId));

      customizeDetail.gender.gender !== 0 &&
      formData.append("gender", String(customizeDetail.gender.gender));

      formData.append("FromAge", String(customizeDetail.age.from));
      formData.append("ToAge", String(customizeDetail.age.to));

      formData.append(
        "EduStatus",
        String(customizeDetail.education.educationStatus)
      );
      customizeDetail.education.educationDegree !== 0 &&
      formData.append(
        "EduDegree",
        String(customizeDetail.education.educationDegree)
      );
      customizeDetail.education.studyId !== 0 &&
      formData.append("EduField", String(customizeDetail.education.studyId));

      formData.append(
        "employment",
        String(customizeDetail.employment.employment)
      );
      customizeDetail.employment.job !== 0 &&
      formData.append("job", String(customizeDetail.employment.job));
      customizeDetail.employment.guild !== 0 &&
      formData.append("guilds", String(customizeDetail.employment.guild));
      customizeDetail.employment.freelanceJob !== 0 &&
      formData.append(
        "Freelancejob",
        String(customizeDetail.employment.freelanceJob)
      );
      customizeDetail.employment.emeritus !== 0 &&
      formData.append(
        "emeritus",
        String(customizeDetail.employment.emeritus)
      );
      customizeDetail.employment.readyToWork !== 0 &&
      formData.append(
        "readyToWork",
        String(customizeDetail.employment.readyToWork)
      );
      customizeDetail.employment.skill !== 0 &&
      formData.append("skill", String(customizeDetail.employment.skill));

      // customizeDetail.marital.marital !== 0 &&
      formData.append("marital", String(customizeDetail.marital.marital));
      customizeDetail.marital.maritalChildrenCount !== null &&
      formData.append(
        "children",
        String(customizeDetail.marital.maritalChildrenCount)
      );
    }
    
    if(adsImage.file) {
      const compressedFile = imageCompression(adsImage.file, compressOptions);
      compressedFile.then((compressedImage) => {
        formData.append("picture", compressedImage);
        createMessageAPI(user_token, formData)
          .then((response) => {
            if (response.status === 200) {
              toast.success("پیام شما با موفقیت ثبت شد.");
              history.goBack();
            }
          })
          .catch((err) => {
            console.log("fffffffffffff");
            console.log("err 1 : ", err.response);
          })
          .finally(() => setSubmitLoading(false));
      });
    } else {
      createMessageAPI(user_token, formData)
        .then((response) => {
          if (response.status === 200) {
            toast.success("پیام شما با موفقیت ثبت شد.");
            history.goBack();
          }
          if (response.status === 400) {
            toast.error("به محدودیت ارسال رسیده اید.");
          }
        })
        .catch((err) => {}).finally(() => setSubmitLoading(false));
    }

    // createMessageAPI(user_token, formData)
    //   .then((response) => {
    //     if (response.status === 200) {
    //       toast.success("پیام شما با موفقیت ثبت شد.");
    //       history.goBack();
    //     }
    //   })
    //   .catch((err) => {
    //   })
    //   .finally(() => setSubmitLoading(false));
  };

  useEffect(() => {
    history.replace("/myshop/club/createmessage");
  }, []);

  return (
    <Fade>
      <PageTopbar title="ایجاد پیام" showKv={false}/>
      <ImageEditor
        active={showImageEditor}
        onClose={(close) => {
          setShowImageEditor(!close);
        }}
        onConfirm={(cropper) => {
          setShowImageEditor(false);
          setAdsImage((prev) => ({
            ...prev,
            url: cropper.getCroppedCanvas().toDataURL().substr(22),
          }));
          cropper.getCroppedCanvas().toBlob((blob) => {
            setAdsImage((prev) => ({
              ...prev,
              file: blob,
            }));
          });
          setPicture(false);
        }}
      />
      <div className="wrapper-fix">
        <Card>
          {/* <div className="title">تصویر یا ویدیوی پیام</div> */}
          <div
            onClick={() => setShowImageEditor(true)}
            className="creation-information__photo"
          >
            {picture ? (
              <>
                <img src={`${picture}`} alt=""/>
                <span>تغییر عکس</span>
              </>
            ) : adsImage.url !== null ? (
              <>
                <img
                  src={`data:image/png;base64,${adsImage.url}`}
                  alt="vitrin"
                />
                <span>تغییر عکس</span>
              </>
            ) : (
              <>
                <SVG svg="create-photo"/>
                <span>آپلود عکس</span>
              </>
            )}
          </div>

          <textarea
            rows={10}
            placeholder="متن پیام"
            className="textarea"
            value={messageDetail.text}
            onChange={(e) => {
              setCharacterCount(e.target.value.length);
              {
                characterCount < 499 && setMessageDetail({...messageDetail, text: e.target.value});
              }
            }}/>
          {/* <p>{characterCount}</p> */}
        </Card>
        <Card>
          <div className="title row a-c mb-12">
            <CheckBox
              defaultValue={isCustomize}
              type="check"
              onChange={(value) => setIsCustomize(value ? true : false)}
            />
            هدف‌گیری گیرندگان پیام
          </div>

          {isCustomize && (
            <CreateMessageContext.Provider
              value={{
                detail: {get: customizeDetail, set: setCustomizeDetail},
              }}
            >
              <div className="create-message__custom">
                <div className="title">محل سکونت</div>
                <FillCustomCity close={() => {
                }}/>
              </div>
              <div className="create-message__custom">
                <div className="title">جنسیت</div>
                <FillCustomGender close={() => {
                }}/>
              </div>
              <div className="create-message__custom">
                <div className="title">سن</div>
                <FillCustomAge close={() => {
                }}/>
              </div>
              <div className="create-message__custom">
                <div className="title">وضعیت تحصیلی</div>
                <FillCustomEducation close={() => {
                }}/>
              </div>
              <div className="create-message__custom">
                <div className="title">وضعیت شغلی</div>
                <FillCustomEmployment close={() => {
                }}/>
              </div>
              <div className="create-message__custom">
                <div className="title">تاهل</div>
                <FillCustomMarital close={() => {
                }}/>
              </div>
              <div className="create-message__custom">
                <div className="row a-c title">
                  <CheckBox
                    defaultValue={getKmCustom}
                    type="check"
                    onChange={(value) => setGetKmCustom(value)}
                  />
                  قبلا از فروشگاه KM گرفته‌اند.‌
                </div>
              </div>
            </CreateMessageContext.Provider>
          )}

          <div
            className="button green"
            onClick={() => !submitLoading && handleSubmitMessage()}
          >
            {submitLoading ? <Dots color="light"/> : "ثبت پیام"}
          </div>
        </Card>
      </div>
    </Fade>
  );
};
