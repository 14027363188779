import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react'
import {
  Dots,
  Fade,
  Lowprice,
  ProductItem,
  ShopHeader,
  Spinner,
  SVG,
  WrapperCard,
} from 'components'
import { AppContext } from '../../context/AppContext'
import { Link, useHistory, useLocation } from 'react-router-dom'
import {
  getCategoriesAPI,
  getProductsAPI,
  getSliderAIP,
  getSpecialProductsAPI,
} from 'utils/Services/API'
import Slider from 'react-slick'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import ProductImage from '../../assets/images/product.png'
import { useAuth, useCart, useUser } from '../../hooks'
import { cloneDeep } from 'lodash'
import axios from 'axios'
import { transformPrice } from 'utils/Functions'

export const ShopContainer = () => {
  const {
    userState: { user },
  } = useUser()
  const {
    authState: { isLogged },
  } = useAuth()
  const query = useQuery()

  const settings = {
    infinite: true,
    autoplay: true,
    pauseOnHover: true,
    autoplaySpeed: 4000,
    slidesToShow: 1,
    speed: 150,
    arrows: false,
    dots: true,
  }

  const { cart } = useContext(AppContext)
  const [products, setProducts] = useState({
    new: [],
    all: [],
  })
  const [specialProducts, setSpecialProducts] = useState([])
  const [slicedProducts, setSlicedProducts] = useState([])

  const [categories, setCategories] = useState([])

  const [loading, setLoading] = useState({
    sliderImages: true,
    specialProducts: true,
    allProducts: true,
    categories: true,
  })

  const [currPage, setCurrPage] = useState(1)
  const [lazyLoading, setLazyLoading] = useState(false)
  const [canLoadMore, setCanLoadMore] = useState(true)
  const [recentlyProductViewed, setRecentlyProductViewed] = useState(null)
  const [sliders, setSliders] = useState([])
  const [marketName, setMarketName] = useState('')
  const { getCartDataFromApi } = useCart()
  const history = useHistory()

  const specialProductSplice = () => {
    if (specialProducts) {
      const newSlicedProducts = []
      const divider = 8
      for (let i = 0; i < specialProducts.length; i += divider) {
        newSlicedProducts.push(specialProducts.slice(i, i + divider))
      }
      setSlicedProducts(newSlicedProducts)
    }
  }

  const validateNUll = prop => {
    if (prop === null || prop === undefined) return ''
    else return prop
  }

  function useQuery() {
    return new URLSearchParams(useLocation().search)
  }

  const isBottom = el => {
    return el?.getBoundingClientRect()?.bottom <= window.innerHeight + 50
  }

  useEffect(() => {
    window.history.scrollRestoration = 'manual'
  }, [])

  useEffect(() => {
    getProducts(currPage)
  }, [currPage])

  const trackScrolling = () => {
    const wrappedElement = document.getElementById('product-full')
    if (isBottom(wrappedElement) && !lazyLoading && canLoadMore) {
      setLazyLoading(true)
      setCurrPage(currPage + 1)
      document.removeEventListener('scroll', trackScrolling)
    }
  }

  const getSpecialCustomersProducts = () => {
    getSpecialProductsAPI()
      .then(response => {
        setSpecialProducts(response?.data?.data?.products)
        setMarketName(response?.data?.data?.market?.name)
      })
      .finally(() =>
        setLoading(prevState => ({
          ...prevState,
          specialProducts: false,
        }))
      )
  }

  // const getMostProducts = () => {
  //     const APIResult = getProductsAPI({ sort: "most_viewed" });
  //     APIResult.then((response) => {
  //         setProducts((prevState) => ({
  //             ...prevState,
  //             most: response.data.data,
  //         }));
  //     }).finally(() =>
  //         setLoading((prevState) => ({
  //             ...prevState,
  //             allProducts: false,
  //         }))
  //     );
  // };

  const getSliderImages = () => {
    const api = getSliderAIP()
    api
      .then(response => {
        setSliders(response.data?.data?.slider)
      })
      .finally(() =>
        setLoading(prevState => ({
          ...prevState,
          sliderImages: false,
        }))
      )
  }

  const getCategories = () => {
    const apiResult = getCategoriesAPI()
    apiResult
      .then(response => {
        setCategories(Object.values(response.data.data))
      })
      .finally(() =>
        setLoading(prevState => ({
          ...prevState,
          categories: false,
        }))
      )
  }

  const getProducts = async page => {
    const APIResult =
      validateNUll(query.get('sort')) === 'special'
        ? getSpecialProductsAPI()
        : getProductsAPI({
            query: validateNUll(query.get('search')),
            sort: validateNUll(query.get('sort')),
            category: validateNUll(query.get('category')),
            page: page,
          })
    APIResult.then(response => {
      var currProducts = cloneDeep(products)
      if (
        validateNUll(query.get('sort')) === 'most_viewed' ||
        validateNUll(query.get('sort')) === 'special'
      ) {
        response?.data?.data?.forEach(element => {
          currProducts.push(element)
        })
      } else {
        response?.data?.data?.data?.forEach(element => {
          currProducts?.all?.push(element)
        })
      }

      localStorage.setItem('products_loadmore', 'true')

      if (response?.data?.data?.next_page_url === null) {
        setCanLoadMore(false)
        localStorage.setItem('products_loadmore', 'false')
      }

      setProducts(currProducts)
      setLoading(prevState => ({
        ...prevState,
        allProducts: false,
      }))
      setLazyLoading(false)
    })
  }

  useEffect(() => {
    if (!isLogged) {
      history.replace('/login')
    } else {
      history.replace('/market')

      try {
        setRecentlyProductViewed(JSON.parse(localStorage.getItem('rpv')).reverse())
      } catch (error) {
        console.log(error)
      }

      getCategories()
      getSliderImages()
      getSpecialCustomersProducts()
      getCartDataFromApi()
      getProducts(1)
      if (history.action === 'POP') {
        setCanLoadMore(Boolean(localStorage.getItem('products_loadmore')))
      }
      //getMostProducts();
      //getProducts();
      //getSpecialProducts();
    }
  }, [])

  useEffect(() => {
    specialProductSplice()
  }, [specialProducts])

  const location = useLocation()
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0)
  }, [location.pathname])

  useEffect(() => {
    document.addEventListener('scroll', trackScrolling)
    return () => {
      document.removeEventListener('scroll', trackScrolling)
    }
  })

  const showRate = number =>
    [{}, {}, {}, {}, {}].map((start, index) => (
      <div className={`product-list-item__rate ${index < number && 'fill'}`}>
        <SVG svg='rate-star' />
      </div>
    ))

  console.log(marketName)

  return (
    <Fade>
      {/* <Spinner active={loading} color="dark" /> */}
      <ShopHeader
        createLink=''
        type='shopList'
        marketName={marketName}
        homeCategories={categories}
        isLoading={loading}
      />

      <div className='home'>
        <div className={`home__sliderBoxWrapper ${loading.sliderImages ? 'skeleton' : ''}`}>
          <React.Fragment>
            <div className='home-product-list'>
              <div className='home-product-list__body'>
                {loading.specialProducts &&
                  Array(5)
                    .fill('')
                    .map(() => <Skeleton className='home-product-list__shortSkeleton' />)}
              </div>
              {/* <div
                                style={{
                                    marginBottom: "3px",
                                    marginTop: "8px",
                                    justifyContent: "center",
                                }}
                                className="home-product-list__header"
                            >
                                <div
                                    style={{}}
                                    className="home-product-list__header__title"
                                >
                                    فروشگاه اینترنتی
                                    {marketName && (
                                        <span
                                            style={{
                                                marginRight: "5px",
                                                color: "#db2c2c",
                                            }}
                                        >
                                            {`${marketName}`}
                                        </span>
                                    )}
                                </div>
                            </div> */}
              {slicedProducts.map(products => (
                <div className='home-product-list'>
                  <div className='home-product-list__header'>
                    {/* <Link
                                                    className="home-product-list__header__all-button"
                                                    to={`/products?sort=special`}
                                                >
                                                    مشاهده همه
                                                </Link> */}
                  </div>
                  <div className='home-product-list__body'>
                    {slicedProducts.length > 0 &&
                      products.map(product => <ProductItem product={product} />)}
                  </div>
                </div>
              ))}

              {loading.sliderImages && (
                <Skeleton height={210} className='home__sliderBoxSkeleton' />
              )}
            </div>

            <div style={{ padding: '0.5rem 0rem' }} className='home__slider'>
              {sliders.map((slider, index) => (
                <div className='home__slider__item'>
                  <img
                    key={index}
                    src={`data:image/png;base64,${slider.photo}`}
                    alt=''
                    onClick={() => {
                      if (slider.targetType === 'product') {
                        history.push(`/product/${slider.target}`)
                      } else if (slider.targetType === 'category') {
                        history.push(`/category?${slider.target}`)
                      }
                    }}
                  />
                </div>
              ))}
            </div>
          </React.Fragment>
        </div>

        <div className='home__container'>
          {/* <div className="home-product-list">
                        <div className="home-product-list__header">
                            <div className="home-product-list__header__title">
                                محصولات ویژه
                            </div>
                            <Link
                                className="home-product-list__header__all-button"
                                to={`/products?special=true`}
                            >
                                مشاهده همه
                            </Link>
                        </div>
                        <div className="home-product-list__body">
                            {products.best.length > 0
                                ? products.best.map((product) => (
                                      <ProductItem product={product} />
                                  ))
                                : Array(4)
                                      .fill("")
                                      .map(() => (
                                          <Skeleton className="home-product-list__skeleton" />
                                      ))}
                        </div>
                    </div> */}

          <div className='home-product-list'>
            {/* <div className="home-product-list__header">
                            <div className="home-product-list__header__title">
                                بازار کارتک
                            </div>
                        </div> */}
            <div className='product-full' id='product-full'>
              <div style={{ marginTop: '0' }} className='product-full__wrapper'>
                <div style={{ paddingBottom: '0.5rem' }} className='product-full__list'>
                  {products.all.length > 0
                    ? products.all.map((product, index) => (
                        <React.Fragment>
                          <WrapperCard>
                            <Link
                              style={{
                                alignItems: 'center',
                                height: '8rem',
                                padding: '0rem 0.5rem 0rem 1rem',
                              }}
                              className='product-list-item__detail'
                              to={`/product/${product.id}`}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                }}
                              >
                                <div className='product-list-item__image'>
                                  <img
                                    loading='lazy'
                                    src={
                                      product.image && product.image[0]
                                        ? `${product.image[0]}`
                                        : ProductImage
                                    }
                                    alt=''
                                  />
                                </div>
                                <div
                                  style={{
                                    padding: '5px 10px 1px 1px',
                                    fontSize: '10px',
                                    color: '#195190',
                                  }}
                                >
                                  {product.seller}
                                </div>
                              </div>
                              <div className='product-list-item__info'>
                                <div className='product-list-item__top'>
                                  <div
                                    style={{
                                      paddingTop: '0.5rem',
                                    }}
                                    className='product-list-item__title'
                                  >
                                    {product.title}
                                  </div>
                                </div>
                                {user?.isCitizen !== 1 && (
                                  <div
                                    style={{
                                      padding: '0.9rem',
                                      bottom: '0px',
                                      left: '-1px',
                                    }}
                                    className='product-list-item__tag'
                                  >
                                    قیمت برای شما
                                    <SVG svg='arrow' />
                                  </div>
                                )}

                                <div className='product-list-item__bottom'>
                                  <div className='product-item__buy discount'>
                                    <div className='product-item__buy__price'>
                                      <Lowprice />
                                      <div
                                        style={{
                                          padding: '0rem 0.5rem',
                                        }}
                                        className='product-item__buy__price__discount'
                                      >
                                        {` ${transformPrice(Number(product.defaultPrice[0]))}`}
                                        <div className='product-item__buy__price__discount__toman'>
                                          تومان
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className='row a-c'>
                                    <div
                                      style={{
                                        justifyContent: 'flex-end',
                                        padding: '0rem 0.5rem',
                                      }}
                                      className='product-item__buy__price j-sb'
                                    >
                                      <div className='product-item__buy__price__discount a-c'>
                                        {transformPrice(
                                          Number(
                                            product.defaultPrice[product.defaultPrice.length - 1]
                                          )
                                        )}
                                      </div>
                                      {/* //TODO : ba asase karbar */}
                                      <div className='product-item__buy__title'>تومان</div>
                                    </div>
                                  </div>
                                </div>
                                {product.productColor.length > 0 && (
                                  <div className='product-list-item__colors'>
                                    {product.productColor.map(
                                      (productColor, productColorIndex) =>
                                        Number(productColor.id) !== 1 &&
                                        productColorIndex <= 3 && (
                                          <div
                                            style={{
                                              backgroundColor: `#${productColor.hex_code}`,
                                            }}
                                            key={productColorIndex}
                                            className='product-list-item__colors__item'
                                          />
                                        )
                                    )}
                                  </div>
                                )}
                              </div>
                            </Link>
                          </WrapperCard>
                        </React.Fragment>
                      ))
                    : Array(5)
                        .fill('')
                        .map(() => <Skeleton className='home-product-list__longSkeleton' />)}
                </div>
                {lazyLoading && <Dots />}
              </div>
            </div>
          </div>
        </div>

        {/* {recentlyProductViewed !== null && recentlyProductViewed.length > 0 && (
          <div className="home-product-list">
            <div className="home-product-list__header">
              <div className="home-product-list__header__title">
                آخرین بازدیدها
              </div>
            </div>
            <div className="home-product-list__body">
              {!loading ? (
                recentlyProductViewed.map((product, index) => (
                  <WrapperCard>
                    <Link
                      className="product-list-item__detail"
                      to={`/product/${product.id}`}
                    >
                      <div className="product-list-item__image">
                        <img
                          id={index}
                          src={
                            product.image && product.image[0]
                              ? `data:image/png;base64,${product.image[0]}`
                              : ProductImage
                          }
                          alt=""
                        />
                      </div>
                      <div className="product-list-item__info">
                        <div className="product-list-item__top">
                          <div className="product-list-item__title">
                            {product.title}
                          </div>
                        </div>
                        <div className="product-list-item__bottom">
                          <div className="product-list-item__rate-wrapper">
                            {showRate(product.stars)}
                          </div>
                          <div className="product-list-item__price">
                            {transformPrice(Number(product.defaultPrice[0]))}{" "}
                            تومان
                          </div>
                        </div>
                      </div>
                    </Link>
                  </WrapperCard>

                ))
              ) : (
                <ShopLoading />
              )}
            </div>
          </div>
        )} */}
      </div>
    </Fade>
  )
}
