import { SVG } from 'components';
import React from 'react';
import './PanelLogs.scss';

export const PanelLogs = ({
  title = '',
  description = '',
  date = '',
  type = ''
}) => {
  return (
    <div className="panel-logs">
      <div className={`panel-logs__image ${type}`}>
        <SVG svg="selected" />
      </div>

      <div className="col">
        <div className="panel-logs__title">
          {title}
        </div>
        <div className="panel-logs__description">
          {description}
        </div>
        <div className="panel-logs__date">

        </div>
      </div>
    </div>
  );
}