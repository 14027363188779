import {Card, CounterInput, Dots, Fade, PageTopbar, SVG} from 'components';
import {useUser} from 'hooks';
import React, {useEffect, useRef, useState} from 'react';
import toast from 'react-hot-toast';
import {addKmToSaleAPI, kmSaleQueueAPI} from 'utils';
import {useHistory} from "react-router-dom";

export const KmQueueContainer = () => {
  const {userState: {user}} = useUser();
  const [loading, setLoading] = useState(true);
  const [kmSaleQueue, setKmSaleQueue] = useState<any>({});
  const [activeMonth, setActiveMonth] = useState<any>({
    title: 'فروردین',
    id: 1
  });
  const [kmAmount, setKmAmount] = useState(0);
  const [addToSaleLoading, setAddToSaleLoading] = useState(false);
  const countRef = useRef(null);
  const history = useHistory();

  const months = [
    {
      title: 'فروردین',
      id: 1
    },
    {
      title: 'اردیبهشت',
      id: 2
    },
    {
      title: 'خرداد',
      id: 3
    },
    {
      title: 'تیر',
      id: 4
    },
    {
      title: 'مرداد',
      id: 5
    },
    {
      title: 'شهریور',
      id: 6
    },
    {
      title: 'مهر',
      id: 7
    },
    {
      title: 'آبان',
      id: 8
    },
    {
      title: 'آذر',
      id: 9
    },
    {
      title: 'دی',
      id: 10
    },
    {
      title: 'بهمن',
      id: 11
    },
    {
      title: 'اسفند',
      id: 12
    }
  ];

  const getKmQueue = () => {
    setLoading(true);
    kmSaleQueueAPI()
      .then(response => {
        setKmSaleQueue(response.data);
      })
      .catch(err => {
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleAddToQueue = () => {
    if (user.whiteKm < kmAmount) {
      toast.error('شما KM ای برای قراردادن در صف فروش ندارید');
      return;
    }
    setAddToSaleLoading(true);
    addKmToSaleAPI(kmAmount)
      .then(response => {
        if (response.status === 200) {
          toast.success('KM های شما با موفقیت برای فروش قرار گرفت');
          getKmQueue();
        }
      })
      .catch(err => toast.error('خطا در فروش KM'))
      .finally(() => setAddToSaleLoading(false));
  };

  useEffect(() => {
    getKmQueue();
  }, []);

  useEffect(() => {
    history.replace("/transaction/queue");
  }, []);

  return (
    <Fade>
      <PageTopbar title="صف فروش KM" showKv={false}/>
      <Card>
        <div className="title mycity">مقدار KM برای قرارگیری در صف فروش</div>

        <CounterInput ref={countRef} step={25} max={user.whiteKm} defaultValue={25}
                      onChange={(value) => setKmAmount(value)}/>

        <div className="button green" onClick={() => !addToSaleLoading && handleAddToQueue()} style={{marginTop: 12}}>
          {addToSaleLoading ? (
            <Dots color="light"/>
          ) : (
            'قراردادن KM در صف فروش'
          )}
        </div>

      </Card>

      {/*<div className="kmqueue__months">*/}
      {/*  {months.map((monthItem, index) => (*/}
      {/*    <div key={index} onClick={() => setActiveMonth(monthItem)}*/}
      {/*         className={`kmqueue__months__item ${activeMonth.id === monthItem.id && 'active'}`}>*/}
      {/*      {monthItem.title}*/}
      {/*    </div>*/}
      {/*  ))}*/}
      {/*</div>*/}

      <Card>
        <div className="title">تاریخچه صف فروش KM</div>
        {loading ? (
          <Dots color="dark"/>
        ) : (
          (kmSaleQueue.unPay === 0 && kmSaleQueue.paid === 0) ? (
            <div className="kmqueue__notfound">
              تاریخچه
            </div>
          ) : (
            <>
              <div className="kmqueue__history">
                <div className="kmqueue__history__box">
                  <div className="kmqueue__history__box__title">واگذار شده</div>
                  <div className="kmqueue__history__amount">{kmSaleQueue.unPay}</div>
                </div>
                <div className={'kmqueue__history__svg'}>
                  <div className="kmqueue__history__svg__line"/>
                  <SVG svg="km-logo"/>
                  <div className="kmqueue__history__svg__line"/>
                </div>
                <div className="kmqueue__history__box">
                  <div className="kmqueue__history__box__title">نقد شده</div>
                  <div className="kmqueue__history__amount">{kmSaleQueue.paid}</div>
                </div>
              </div>
            </>
          )
        )}
      </Card>
    </Fade>
  );
};