import {useState, useEffect} from "react";
import {SVG, KV, Spinner, Fade, Modal} from "components";
import {useHistory, useLocation} from "react-router-dom";
import {
  adsDetailAPI,
  adsSaveAPI,
  adsSeenAPI,
  adsViewAPI,
  adsSeenListAPI,
  adsSpecificSeenAPI,
} from "utils";
import {GetAdsByRate} from "utils/Services";
import toast from "react-hot-toast";
import ReactSpinnerTimer from "react-spinner-timer";
import kvSound from "../assets/sounds/kv.mp3";
import {useUser} from "hooks";
import {cloneDeep, remove} from "lodash";
import TapImage from "../assets/images/tap.png";
import {showSingleVideoAPI} from "../utils";
import "../../node_modules/video-react/dist/video-react.css";
import {Player, BigPlayButton, LoadingSpinner} from 'video-react';
import {listOfVideosAPI} from "../utils/Services/UserContent/ListOfVideos";

const Ads = () => {
  const [audio] = useState(new Audio(kvSound));
  const [loading, setLoading] = useState(true);
  const [lazyLoad, setLazyLoad] = useState(false);
  const [savedAds, setSavedAds] = useState([]);
  const history = useHistory();
  const location = useLocation();
  const query = useQuery();
  const [adsList, setAdsList] = useState([]);
  const [activeSlider, setActiveSlider] = useState(-1);
  const [toggleComplete, setToggleComplete] = useState([]);
  const [currPage, setCurrPage] = useState(1);
  const [canLoadMore, setCanLoadMore] = useState(true);
  const [tapStatus, setTapStatus] = useState([]);
  const [modalContent, setModalContent] = useState({
    title: "",
    value: "",
  });
  const {getUserDataFromApi, userState: {user}} = useUser();
  const [videos, setVideos] = useState([]);

  function useQuery() {
    return new URLSearchParams(location.search);
  }

  const handleAdsSeen = (adsId) => {
    if (
      query.get("type") !== "seen" &&
      query.get("id") === null &&
      Number(query.get("rate")) < 8
    ) {
      const APIResult = adsSeenAPI(adsId);
      APIResult.then((response) => {
        if (response.status === 200) {
          if (user.detail.isSound) {
            audio.play();
          }
          toast.success(`KV ${response.data.kv} دریافت کردید.`);
          // setToggleComplete(true);
          getUserDataFromApi();
        }
      })
        .catch((err) => console.log(err))
        .finally(() => {
          document.getElementById("ads_container").style.overflow = "auto";
          setActiveSlider(-1);
        });
    } else if (Number(query.get("rate")) === 8) {
      const APIResult = adsSpecificSeenAPI(adsId);
      APIResult.then((response) => {
        if (response.status === 200) {
          if (user.detail.isSound) {
            audio.play();
          }
          toast.success(`KV ${response.data.kv} دریافت کردید.`);
          // setToggleComplete(true);
          getUserDataFromApi();
        }
      })
        .catch((err) => console.log(err))
        .finally(() => {
          document.getElementById("ads_container").style.overflow = "auto";
          setActiveSlider(-1);
        });
    }
  };

  const videosList = () => {
    listOfVideosAPI().then(res => {
      if (res.status === 200) {
        setVideos(res.data);
      }
    }).catch(err => {

    }).finally();
  };

  const handleAdsClicked = (ads) => {
    // if (!ads.image) {
    //   getSingleVideo(ads.video_url);
    // }

    let currTapStatus = cloneDeep(tapStatus);
    currTapStatus.push(ads.id);
    setTapStatus(currTapStatus);

    document
      .getElementById("ads_container")
      .scrollTo(0, document.getElementById(ads.id).offsetTop);
    document.getElementById("ads_container").style.overflow = "hidden";
    setActiveSlider(ads.id);
  };

  const toggleSaveAds = (e, adsId) => {
    e.stopPropagation();
    const APIResult = adsSaveAPI(adsId);
    APIResult.then((response) => {
      if (response.status === 200 && response.msg == "Saved") {
        toast.success("محتوا ذخیره شد.");

        let currSavedAds = cloneDeep(savedAds);
        currSavedAds.push(adsId);
        setSavedAds(currSavedAds);
      } else if (response.status === 200 && response.msg == "Removed") {
        toast.success("محتوا از ذخیره‌ها حذف شد.");

        let currSavedAds = cloneDeep(savedAds);
        remove(currSavedAds, (ads) => ads === adsId);
        setSavedAds(currSavedAds);
      } else toast.error("مشکلی در ذخیره‌ی محتوا پیش آمده.");
    }).catch((err) => {
      // toast.success("تبلیغ از ذخیره‌ها حذف شد.");
      // setSavedAds(false);
    });
  };

  const getAds = (rate, cat, type, page) => {
    const APIResult = adsDetailAPI({
      rate: rate,
      cat: cat,
      type: type,
      page: page,
    });
    APIResult.then((response) => {
      if (response.status === 404) {
        toast.error("محتوایی یافت نشد!");
        history.goBack();
        return;
      }
      setAdsList(response.data[0].data);
      if (response.data[0].data?.length == 0) {
        toast.error("محتوایی یافت نشد!");
        history.goBack();
      }
    });
  };

  const getSeenAds = (cat) => {
    adsSeenListAPI(cat).then((response) => {
      if (response.status === 404) {
        toast.error("محتوایی یافت نشد!");
        history.goBack();
        return;
      }
      if (response.data.data?.length == 0) {
        toast.error("محتوایی یافت نشد!");
        history.goBack();
        return;
      } else {
        const currTapStatus = cloneDeep(tapStatus);
        const currToggleComplete = cloneDeep(toggleComplete);
        response.data.data.forEach((ads) => {
          currTapStatus.push(ads.id);
          currToggleComplete.push(ads.id);
        });
        setTapStatus(currTapStatus);
        setToggleComplete(currToggleComplete);
        setAdsList(response.data.data);
      }
    });
  };

  const getAdsWithRate = (rate, page) => {
    GetAdsByRate({rate: rate, page: page})
      .then((res) => {
        if (res) {
          if (res.data[0].next_page_url === null) {
            setCanLoadMore(false);
          }
          var currAds = cloneDeep(adsList);
          res.data[0]?.data?.forEach((content) => {
            currAds.push(content);
          });
          setAdsList(currAds);
          if (res.data[0].data?.length == 0) {
            toast.error("محتوایی یافت نشد!");
            history.goBack();
          }
        } else return;
      })
      .catch((res) => {
        if (res.status === 404) {
          toast.error("محتوایی یافت نشد!");
          history.goBack();
          return;
        }
      })
      .finally(() => {
        setLazyLoad(false);
      });
  };

  const getAdsDetail = (id) => {
    const api = adsViewAPI(id);
    api.then((response) => {
      if (response.status === 404) {
        toast.error("محتوایی یافت نشد!");
        history.goBack();
        return;
      }
      setAdsList([response]);
    }).finally(() => setLoading(false));
  };

  const handleFinish = (adsId) => {
    const currToggleComplete = cloneDeep(toggleComplete);
    currToggleComplete.push(adsId);
    setToggleComplete(currToggleComplete);
    handleAdsSeen(adsId);
  };

  const scrollTracking = () => {
    const wrappedElement = document.getElementById("ads_container");
    const adsContainerClientHeight = wrappedElement.clientHeight;
    const adsContainerScrollHeight = wrappedElement.scrollHeight;
    const adsContainerScrollTop = wrappedElement.scrollTop;

    if (
      adsContainerScrollTop >
      adsContainerScrollHeight - adsContainerClientHeight - 80 &&
      canLoadMore &&
      !lazyLoad
    ) {
      setLazyLoad(true);
      const rate = query.get("rate");
      getAdsWithRate(rate, currPage + 1);
      setCurrPage((prev) => prev + 1);
      document
        .getElementById("ads_container")
        ?.removeEventListener("scroll", scrollTracking);
    }
  };

  const handleShowAdsModal = (title, value) => {
    setModalContent({
      title: title,
      value: value,
    });
  };

  const copy = (elementId) => {
    const value = document.getElementById(elementId);
    value.select();
    document.execCommand("copy");

    toast.success("کپی شد.");
  };

  useEffect(() => {
    const id = query.get("id");
    if (query.get("variety") == "bookmark" && !loading) {
      setToggleComplete([Number(id)]);
      setSavedAds([Number(id)]);
    }
    // if (id !== null) {
    //   setToggleComplete([Number(id)]);
    //   // setSavedAds([Number(id)]);
    // }
  }, [loading]);

  useEffect(() => {
    history.replace(`/ads${location.search}`);
    const rate = query.get("rate");
    const cat = query.get("cat");
    const type = query.get("type");
    const id = query.get("id");
    videosList();

    if (id !== null) {
      setTapStatus([Number(id)]);
      // setSavedAds([Number(id)]);
      setToggleComplete([Number(id)]);
      getAdsDetail(id);
      setCanLoadMore(false);
      return;
    }

    if (type === "seen") {
      getSeenAds(cat);
      return;
    }

    if (cat === null) {
      getAdsWithRate(rate, currPage);
      return;
    }
    getAds(rate, cat, type, currPage);
  }, []);

  useEffect(() => {
    document
      .getElementById("ads_container")
      .addEventListener("scroll", scrollTracking);
    return () => {
      document
        .getElementById("ads_container")
        ?.removeEventListener("scroll", scrollTracking);
    };
  });

  useEffect(() => {
    let currAds = cloneDeep(adsList);

    currAds.map(item => {
      videos.filter(video => {
        if (video.contentId === item.id) {
          item.thumbnail = video.thumbnail;
        }
      });
    });

    setAdsList(currAds);

  }, [videos, adsList.length]);

  return (
    <Fade>
      <Spinner active={loading} color="dark"/>
      <Modal
        active={modalContent.title === "آدرس"}
        close={() => setModalContent({title: "", value: ""})}
      >
        <div className="col">
          <div className="show-ads__modal__header">
            <div className="show-ads__modal__title">{modalContent.title}</div>
          </div>
          <div className="show-ads__modal__relative" onClick={() => copy("modal_link")}>
            <div className="show-ads__modal__copy">
              <SVG svg="copy"/>
            </div>
            <textarea
              readOnly
              rows={6}
              className="show-ads__modal__input"
              type="text"
              value={modalContent.value}
              id="modal_address"
            />
          </div>
        </div>
      </Modal>

      <Modal
        active={modalContent.title === "تلفن"}
        close={() => setModalContent({title: "", value: ""})}
      >
        <div className="col">
          <div className="show-ads__modal__header">
            <div className="show-ads__modal__title">{modalContent.title}</div>
          </div>
          <a
            className="show-ads__modal__call"
            href={`tel:+${modalContent.value}`}
          >
            <SVG svg="call"/> {modalContent.value}
          </a>
        </div>
      </Modal>

      <Modal
        active={modalContent.title === "موبایل"}
        close={() => setModalContent({title: "", value: ""})}
      >
        <div className="col">
          <div className="show-ads__modal__header">
            <div className="show-ads__modal__title">{modalContent.title}</div>
          </div>
          <a
            className="show-ads__modal__call"
            href={`tel:+${modalContent.value}`}
          >
            <SVG svg="call"/> {modalContent.value}
          </a>
        </div>
      </Modal>

      <Modal
        active={modalContent.title === "لینک"}
        close={() => setModalContent({title: "", value: ""})}
      >
        <div className="col">
          <div className="show-ads__modal__header">
            <div className="show-ads__modal__title">نشانی اینترنتی</div>
          </div>
          <div className="show-ads__modal__relative" onClick={() => copy("modal_link")}>
            <div className="show-ads__modal__copy">
              <SVG svg="copy"/>
            </div>
            <input
              readOnly
              dir="auto"
              className="show-ads__modal__input"
              type="text"
              value={modalContent.value}
              id="modal_link"
            />
          </div>
        </div>
      </Modal>

      <audio src={kvSound} autoPlay={false}/>
      <div className="show-ads__header">
        <KV/>
        {/* <div className="show-ads__header__title">
            {adsItem.adsCat} {adsItem.rate}
          </div> */}

        <div
          className="show-ads__header__close"
          onClick={() => history.goBack()}
        >
          <SVG svg="times"/>
        </div>
      </div>

      <div className="show-ads" id="ads_container">
        {adsList.map((adsItem, index) => (
          <div
            className={`show-ads__item`}
            id={adsItem.id}
            onClick={() => {
              !tapStatus.includes(adsItem.id) && handleAdsClicked(adsItem);
            }}
            key={index}
          >
            <div
              className={`show-ads__item__section ${tapStatus.includes(adsItem.id) && "active"
              }`}
            >
              <div
                className={`show-ads__item__tap ${tapStatus.includes(adsItem.id) && "deactive"
                }`}
              >
                <img src={TapImage} alt=""/>
              </div>
              <div
                className="show-ads__item__section__image"
                style={{backgroundImage: `url(${adsItem.image ? adsItem.image : adsItem?.thumbnail?.thumbnail_url})`}}
              >
                <div className="show-ads__item__section__image__layer"/>
                {adsItem.image ? <img
                    src={`${adsItem.image}`}
                    className=""
                    onLoad={() => setLoading(false)}
                  /> :
                  // <div>
                  <Player
                    playsInline
                    poster={adsItem?.thumbnail?.thumbnail_url}
                    src={adsItem?.thumbnail?.video_url}
                  >
                    <BigPlayButton position="center"/>
                    <LoadingSpinner/>
                  </Player>
                  // </div>

                  // <div className="r1_iframe_embed">
                  // <iframe
                  //   src="https://player.arvancloud.com/index.html?config=https://kartaakco.arvanvod.com/gWMjDN7v1V/jzYLBvL8Km/origin_config.json"
                  //   style={{border: '0 #ffffff none'}}
                  //   name="16567612969203207j12cc17yc96gfdggg95ggsdsh0508.mp4"
                  //   frameBorder="0"
                  //   allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  //   allowFullScreen={true} webkitallowfullscreen={true} mozallowfullscreen={true}/>
                  // </div>
                }
              </div>
              <div className="col h-100">
                <div className="show-ads__item__section__info">
                  <div className="show-ads__item__section__title">
                    {adsItem.title}
                  </div>

                  {activeSlider === adsItem.id &&
                    tapStatus.includes(adsItem.id) &&
                    !toggleComplete.includes(adsItem.id) && (
                      <ReactSpinnerTimer
                        timeInSeconds={Number(adsItem.show_time)}
                        totalLaps={1}
                        isRefresh={false}
                        onLapInteraction={(lap) =>
                          lap.isFinish && handleFinish(Number(adsItem.id))
                        }
                      />
                    )}
                  {toggleComplete.includes(adsItem.id) && (
                    <div
                      className={`show-ads__item__section__bookmark ${savedAds.includes(adsItem.id) && "active"
                      }`}
                      onClick={(e) => toggleSaveAds(e, adsItem.id)}
                    >
                      <SVG svg="bookmark-ads"/>
                    </div>
                  )}
                </div>
                <div className="col h-100 j-sb">
                  <div className="show-ads__item__section__description">
                    {adsItem?.description?.split('\n')?.map(str => <div>{str}</div>)}
                  </div>
                  <div className="show-ads__item__section__footer">
                    <div className="show-ads__item__section__category">
                      آلبوم - {adsItem.adsCat + " " + adsItem.rate}
                    </div>
                    <div className="row a-c">
                      <div
                        onClick={() => {
                          adsItem.url !== null &&
                          handleShowAdsModal("لینک", adsItem.url);
                        }}
                        className={`show-ads__item__section__cta ${adsItem.url !== null && "active"
                        }`}
                      >
                        <SVG svg="kartella-link"/>
                      </div>
                      <div
                        onClick={() => {
                          adsItem.mobileNumber !== null &&
                          handleShowAdsModal("موبایل", adsItem.mobileNumber);
                        }}
                        className={`show-ads__item__section__cta ${adsItem.mobileNumber !== null && "active"
                        }`}
                      >
                        <SVG svg="kartella-mobile"/>
                      </div>
                      <div
                        onClick={() => {
                          adsItem.phoneNumber !== null &&
                          handleShowAdsModal("تلفن", adsItem.phoneNumber);
                        }}
                        className={`show-ads__item__section__cta ${adsItem.phoneNumber !== null && "active"
                        }`}
                      >
                        <SVG svg="kartella-call"/>
                      </div>
                      <div
                        onClick={() => {
                          adsItem.address !== null &&
                          handleShowAdsModal("آدرس", adsItem.address);
                        }}
                        className={`show-ads__item__section__cta ${adsItem.address !== null && "active"
                        }`}
                      >
                        <SVG svg="kartella-location"/>
                      </div>
                    </div>
                    {/* {adsItem.url !== null && (
                      <div className="row a-c">
                        {adsItem.url}
                        <SVG svg="link" />
                      </div>
                    )} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="show-ads__scroll">
        <SVG svg="arrows"/>
      </div>
    </Fade>
  );
};

export default Ads;

//   <img
//     src={`${adsItem.image}`}
//     onLoad={() => setLoading(false)}
//   />
//   {/* <video
//     className="show-ads__cover"
//     autoPlay={true}
//     onCanPlay={() => setLoading(false)}
//     // onLoadStart={() => setLoading(false)}
//   >
//     <source
//       src="https://www.w3schools.com/html/mov_bbb.mp4"
//       // src="https://assets.mixkit.co/videos/preview/mixkit-portrait-of-a-woman-in-a-pool-1259-large.mp4"
//       type="video/mp4"
//     />
//   </video> */}
