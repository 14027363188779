import {PageTopbar, SVG, Card, Fade, Dots, Modal, Spinner, Toggle, CheckBox, ImageCropper} from "components";
import {ImageEditor} from "../../../../components/ImageEditor";
import React, {useEffect, useState} from "react";
import {
  deleteStorePictureAPI,
  getStorePicturesAPI,
  getUserStoreAPI,
  setStorePictureAPI,
  updateStorePictureAPI
} from "../../../../utils";
import {toast} from "react-hot-toast";
import imageCompression from "browser-image-compression";
import {updateStoreAPI} from "../../../../utils/Services/Kartland/UpdateStore";
import {useHistory, useLocation} from "react-router-dom";
import {toggleOwnerInfoAPI} from "../../../../utils/Services/Kartland/ToggleOwnerInfo";

export const CreationInformation = () => {
  const [showImageEditor, setShowImageEditor] = useState(false);
  const [adsImage, setAdsImage] = useState<any>({file: null, url: null});
  const [compressLoading, setCompressLoading] = useState(0);
  const [storePictures, setStorePictures] = useState([]);
  const [getPicturesLoading, setGetPicturesLoading] = useState(false);
  const [updatingPic, setUpdatingPic] = useState(0);
  const [deletingModal, setDeletingModal] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deletingItem, setDeletingItem] = useState(null);
  const [storeLoading, setStoreLoading] = useState(true);
  const [storeDescription, setStoreDescription] = useState<any>({
    description: '',
    descriptionEdit: '',
    descriptionNotif: '',
    descriptionStatus: 0,
  });
  const [storeOwnerInfo, setStoreOwnerInfo] = useState<any>();
  const [storeColor, setStoreColor] = useState("000000");
  const [rejectModal, setRejectModal] = useState(false);
  const [rejectReason, setRejectReason] = useState('');
  const [rejectType, setRejectType] = useState('pic');
  const [waitingShow, setWaitingShow] = useState(false);
  const [rejectShow, setRejectShow] = useState(false);
  const [activePictures, setActivePictures] = useState([]);
  const history = useHistory();
  const location = useLocation();

  function useQuery() {
    const {search} = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  let query = useQuery();

  const compressOptions = {
    maxSizeMB: 0.7,
    maxWidthOrHeight: 1024,
    onProgress: (percentage) => {
      setCompressLoading(percentage);
    },
  };

  const sendStorePicture = () => {
    setStoreLoading(true);
    const compressedFile = imageCompression(adsImage.file, compressOptions);
    compressedFile.then((compressed) => {
      var data = new FormData();
      data.append("picture", compressed);
      setStorePictureAPI(data).then(res => {
        if (res.status === 200) {
          toast.success("عکس با موفقیت برای بررسی فرستاده شد");
          setAdsImage({file: null, url: null});
          setStoreLoading(false);
          getStorePictures();
        }
      });
    });
  };

  const updateStorePicture = () => {
    const compressedFile = imageCompression(adsImage.file, compressOptions);
    compressedFile.then((compressed) => {
      var data = new FormData();
      data.append("picture", compressed);
      updateStorePictureAPI(updatingPic, data).then(res => {
        if (res.status === 200) {
          toast.success("عکس با موفقیت برای بررسی فرستاده شد");
          setAdsImage({file: null, url: null});
          getStorePictures();
          setUpdatingPic(0);
        }
      });
    });
  };

  const deleteStorePicture = (id) => {
    if (activePictures.length === 1) {
      toast.error('فروشگاه حتما باید یک عکس فعال داشته باشد');
      return;
    }
    setDeleteLoading(true);
    deleteStorePictureAPI(id).then(res => {
      if (res.status === 200) {
        toast.success("عکس با موفقیت حذف شد");
        getStorePictures();
        setDeleteLoading(false);
        setDeletingModal(false);
      }
    });
  };

  const getStorePictures = () => {
    setGetPicturesLoading(true);
    getStorePicturesAPI().then(res => {
      if (res.status === 200) {
        setStorePictures(res.data);
        let currActives = [];
        res.data.map(pic => {
          if (pic.pictureStatus == 1) {
            currActives.push(pic);
          }
        });
        setActivePictures(currActives);
      }
    }).catch(err => {
      toast.error('به مشکلی برخوردیم');
    }).finally(() => setGetPicturesLoading(false));
  };

  const getUserStore = () => {
    setStoreLoading(true);
    getUserStoreAPI().then(res => {
      if (res.status === 200) {
        setStoreLoading(false);
        setStoreDescription({
          description: res.data.description,
          descriptionEdit: res.data.descriptionEdit,
          descriptionNotif: res.data.descriptionNotif,
          descriptionStatus: res.data.descriptionStatus
        });
        setStoreOwnerInfo(res.data.ownerInfo);
        // setStoreColor(res.data[0].color);
      }
    }).catch(err => {
      toast.error('به مشکلی برخوردیم');
    }).finally(() => {
    });
  };

  const updateStore = () => {
    updateStoreAPI(storeDescription.description === null ? '' : storeDescription.description, storeColor).then(res => {
      if (res.status === 200) {
        toast.success("اطلاعات با موفقیت برای بررسی فرستاده شد");
        getUserStore();
      }
    });
  };

  const toggleOwnerInfo = () => {
    toggleOwnerInfoAPI().then(res => {
      if (res.status === 200) {
        toast.success("اطلاعات با موفقیت بروز شد");
        setStoreOwnerInfo(!storeOwnerInfo);
      }
    });
  };

  const handleChildElementClick = (e) => {
    e.stopPropagation();
    setDeletingModal(true);
  };

  useEffect(() => {
    getStorePictures();
    getUserStore();
  }, []);

  useEffect(() => {
    if (adsImage.file !== null && updatingPic === 0) {
      sendStorePicture();
    }
  }, [adsImage.file]);

  useEffect(() => {
    if (adsImage.file !== null && updatingPic > 0) {
      updateStorePicture();
    }
  }, [adsImage.file]);

  useEffect(() => {
    history.replace("/myshop/information" + location.search);
  }, []);

  return (
    <Fade>
      <PageTopbar title="مشخصات فروشگاه" showKv={false}/>
      <Spinner active={storeLoading} color={"dark"}/>
      <ImageCropper
        active={showImageEditor}
        onClose={(close) => {
          setShowImageEditor(!close);
        }}
        onConfirm={(cropper) => {
          setShowImageEditor(false);
          // setAdsImage((prev) => ({
          //   ...prev,
          //   url: cropper.getCroppedCanvas().toDataURL().substr(22),
          // }));
          // setAdsImage((prev) => ({
          //   ...prev,
          //   file: cropper,
          // }));
          setAdsImage({file: cropper, url: URL.createObjectURL(cropper)})
        }}
      />
      {/*<ImageEditor*/}
      {/*  active={showImageEditor}*/}
      {/*  onClose={(close) => {*/}
      {/*    setShowImageEditor(!close);*/}
      {/*  }}*/}
      {/*  onConfirm={(cropper) => {*/}
      {/*    setShowImageEditor(false);*/}
      {/*    setAdsImage((prev) => ({*/}
      {/*      ...prev,*/}
      {/*      url: cropper.getCroppedCanvas().toDataURL().substr(22),*/}
      {/*    }));*/}
      {/*    cropper.getCroppedCanvas().toBlob((blob) => {*/}
      {/*      setAdsImage((prev) => ({*/}
      {/*        ...prev,*/}
      {/*        file: blob,*/}
      {/*      }));*/}
      {/*    });*/}
      {/*  }}*/}
      {/*/>*/}
      <Modal active={deletingModal} close={() => setDeletingModal(false)}>
        <div className={"deleting-modal"}>
          <div className={"deleting-modal__title"}>
            آیا از حذف تصویر مطمئن هستید؟
          </div>
          <div className={"deleting-modal__actions"}>
            <div onClick={() => deleteLoading === false && deleteStorePicture(deletingItem)} className={"button red"}>
              {deleteLoading ? (
                <Dots color={"light"}/>
              ) : (<span>حذف</span>)}
            </div>
            <div onClick={() => setDeletingModal(false)} className={"button disable"}>
              بازگشت
            </div>
          </div>
        </div>
      </Modal>

      <Modal active={rejectModal} close={() => setRejectModal(false)}>
        <div className={"reject-modal"}>
          <div className={"reject-modal__title"}>
            {rejectType === 'pic' ? 'تصویر شما به دلیل زیر رد شد:' : 'توضحیات شما به دلیل زیر رد شد:'}
          </div>
          <div className={"reject-modal__reason"}>
            {rejectReason}
          </div>
          <div className={"reject-modal__actions"}>
            {rejectType === 'pic' && <div onClick={() => setShowImageEditor(true)} className={"button green"}>
              <span>ویرایش</span>
            </div>}
            <div onClick={() => setRejectModal(false)} className={"button disable"}>
              بازگشت
            </div>
          </div>
        </div>
      </Modal>



      <div className="wrapper-fix">
        <div className="store-pictures-container__title title mycity">
          عکس های فروشگاه :
        </div>
        <div className={"store-pictures-container"}>
          {getPicturesLoading === false ? (storePictures.map(pic => (
            pic.photo !== false && (
              <div onClick={() => {
                if (query.get('edit') === 'true') {
                  setShowImageEditor(true);
                  setUpdatingPic(pic.id);
                }
              }} className={"store-pictures-container__item"}>
                {query.get('edit') === 'true' && (
                  <div onClick={(e) => {
                    handleChildElementClick(e);
                    setDeletingItem(pic.id);
                  }} className={"store-pictures-container__item__delete"}>
                    <SVG svg={"delete"}/>
                  </div>
                )}
                <img
                  loading="lazy"
                  src={pic.photo}
                  alt="store-pic"
                  className="store-pictures-container__image"
                />
                {query.get('edit') === 'true' && <div className="store-pictures-container__edit">
                  ویرایش تصویر
                </div>}
              </div>
            )

          ))) : (
            <Dots color={"dark"}/>
          )}
          {storePictures.length < 5 && query.get('edit') === 'true' &&
            <div onClick={() => setShowImageEditor(true)} className="store-pictures-container__additem">
              <SVG svg="create-photo"/>
              <span>آپلود عکس</span>
            </div>
          }
        </div>

        {query.get('edit') === 'true' && (
          <>
            {waitingShow && <div className="store-pictures-container__title title">
              عکس های درانتظار بررسی :
            </div>}
            <div className={"store-pictures-container"}>
              {getPicturesLoading === false ? (storePictures.map(pic => (
                <>
                  {pic.pictureStatus == 2 && (
                    <div onClick={() => {
                    }} className={"store-pictures-container__item"}
                    >
                      {/*<div onClick={(e) => {*/}
                      {/*  handleChildElementClick(e);*/}
                      {/*  setDeletingItem(pic.id);*/}
                      {/*}} className={"store-pictures-container__item__delete"}>*/}
                      {/*  <SVG svg={"delete"}/>*/}
                      {/*</div>*/}
                      {waitingShow === false && setWaitingShow(true)}
                      <img
                        loading="lazy"
                        src={pic.photoedit}
                        alt="store-pic"
                        className="store-pictures-container__image"
                      />
                      <div className="store-pictures-container__edit">
                        در انتطار بررسی
                      </div>
                    </div>
                  )}
                </>
              ))) : (
                <Dots color={"dark"}/>
              )}
            </div>
          </>
        )}

        {query.get('edit') === 'true' && (
          <>
            {rejectShow && <div className="store-pictures-container__title title">
              عکس های رد شده :
            </div>}
            <div className={"store-pictures-container"}>
              {getPicturesLoading === false ? (storePictures.map(pic => (
                <>
                  {pic.pictureStatus == 3 && (
                    <div onClick={() => {
                      setUpdatingPic(pic.id);
                      setRejectModal(true);
                      setRejectType('pic');
                      setRejectReason(pic.pictureNotif);
                    }} className={"store-pictures-container__item"}
                    >
                      {/*<div onClick={(e) => {*/}
                      {/*  handleChildElementClick(e);*/}
                      {/*  setDeletingItem(pic.id);*/}
                      {/*}} className={"store-pictures-container__item__delete"}>*/}
                      {/*  <SVG svg={"delete"}/>*/}
                      {/*</div>*/}
                      {rejectShow === false && setRejectShow(true)}
                      <img
                        loading="lazy"
                        src={pic.photoedit}
                        alt="store-pic"
                        className="store-pictures-container__image"
                      />
                      <div className="store-pictures-container__edit">
                        رد شده
                      </div>
                    </div>
                  )}
                </>
              ))) : (
                <Dots color={"dark"}/>
              )}
            </div>
          </>
        )}

        <Card>
          <div className="title">
            توضیحات{" "}
            <small className="creation-information__title__slug">
              ( برای نمایش به کاربر )
            </small>
          </div>
          <textarea
            disabled={query.get('edit') !== 'true'}
            value={storeDescription.description}
            onChange={e => setStoreDescription({...storeDescription, description: e.target.value})}
            placeholder="توضیحات را وارد کنید"
            className="textarea"
            rows={6}
          />

          {storeDescription.descriptionStatus != 1 && storeDescription.descriptionEdit && (
            <>
              <div className="row g-4">
                <div className="title">
                  توضیحات{" "}
                  <small className="creation-information__title__slug">
                    {storeDescription.descriptionStatus == 2 && '( در انتظار بررسی )'}
                    {storeDescription.descriptionStatus == 3 && '( رد شده )'}
                  </small>
                </div>
                {storeDescription.descriptionStatus == 3 && <div onClick={() => {
                  setRejectReason(storeDescription.descriptionNotif);
                  setRejectModal(true);
                  setRejectType('desc');
                }} className="">
                  <SVG svg="myshop-comments"/>
                </div>}
              </div>
              <textarea
                disabled={true}
                value={storeDescription.descriptionEdit}
                onChange={e => setStoreDescription({...storeDescription, description: e.target.value})}
                placeholder="توضیحات را وارد کنید"
                className="textarea mb-12"
                rows={6}
              />
            </>
          )}


          {/*  <div className="title">*/}
          {/*    رنگ{" "}*/}
          {/*    <small className="creation-information__title__slug">*/}
          {/*      ( برای نمایش به کاربر )*/}
          {/*    </small>*/}
          {/*  </div>*/}
          {/*  <input*/}
          {/*    type={"color"}*/}
          {/*    onChange={e=>console.log(e.target.value)}*/}
          {/*    placeholder="رنگ را وارد کنید"*/}
          {/*    className="input"*/}
          {/*  />*/}

          <div className="setting__item option">
            <div className={'setting__item__title'}>
              نمایش اطلاعات فروشنده
            </div>
            <Toggle value={storeOwnerInfo} onChange={toggleOwnerInfo}/>
          </div>
        </Card>

        <div onClick={() => storeLoading === false && updateStore()} className="shop-creation__action">
          <span>ثبت مشخصات و ادامه</span>
          <SVG svg="arrow"/>
        </div>
      </div>

    </Fade>
  );
};
