import AddNewUsersContainer from 'containers/AddNewUsersContainer/AddNewUsersContainer';
import { CityContainer } from 'containers/CollaborateContainer/Logs';
import React from 'react';

const AddNewUsers = () => {
  return (
    <AddNewUsersContainer />
  );
}

export default AddNewUsers;