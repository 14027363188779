import { SVG } from "components";
import { useUser } from "hooks";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useHistory, Link } from "react-router-dom";
import { getUserStoreAPI, unseenMessagesCountAPI } from "utils";

export const ProfileItems = ({ myShops }) => {
    const {
        userState: { user },
    } = useUser();
    const history = useHistory();

    // const handleShopClick = () => {
    //     if (myShops?.length === 0) {
    //         history.push("/createProvider");
    //     } else if (
    //         myShops?.length > 0 &&
    //         myShops[0].isActive === 0 &&
    //         user?.detail?.internetStore === null
    //     ) {
    //         toast.error("درخواست شما در حال بررسی است.");
    //     } else if (
    //         myShops?.length > 0 &&
    //         myShops[0].isActive === 1 &&
    //         user?.detail?.internetStore === null
    //     ) {
    //         history.replace("/create-admin-store");
    //     } else if (
    //         myShops?.length > 0 &&
    //         user?.detail?.internetStore === true
    //     ) {
    //         window.open("https://adminstore.kartaak.com/", "_blank").focus();
    //     }
    // };

    return (
        <div>
            {/*<div className='profile__items__row'>*/}
            {/*  <Link to={`/collab-intro`} className="profile__items__item svg-green">*/}
            {/*    نمایندگی*/}
            {/*    <SVG svg="marketing" />*/}
            {/*  </Link>*/}
            {/*  <Link to={`/kartak-yar`} className="profile__items__item svg-coral">*/}
            {/*    کارتک یار*/}
            {/*    <SVG svg="note-favorite" />*/}
            {/*  </Link>*/}
            {/*  <Link to={`/profile/friends`} className="profile__items__item svg-blue">*/}
            {/*    کاپیتان*/}
            {/*    <SVG svg="profile-friends" />*/}
            {/*  </Link>*/}
            {/*</div>*/}
            {/*<div className='profile__items__row'>*/}
            {/*  <Link to={`/kartaak-fun`} className="profile__items__item svg-darkorange">*/}
            {/*    کارتک‌فان*/}
            {/*    <SVG svg="kartaak-fun" />*/}
            {/*  </Link>*/}
            {/*  <Link to={`/kartland`} className="profile__items__item svg-aqua">*/}
            {/*    کارتلند*/}
            {/*    <SVG svg="kartland" />*/}
            {/*  </Link>*/}‌
            {/*  <Link to={`#`} className="profile__items__item half svg-indigo">*/}
            {/*    خدمات کارتک*/}
            {/*    <SVG svg="kartaak-services" />*/}
            {/*  </Link>*/}
            {/*</div>*/}
            <div className="profile__items__row">
                <Link
                    to={`/addnewusers`}
                    className="profile__items__item svg-purple"
                    style={{
                        justifyContent: " center",
                        backgroundColor: "#17c7a7",
                        margin: "22px auto 0",
                    }}
                >
                    دعوت از دوستان جدید
                    {/* <SVG svg="friends" /> */}
                </Link>
            </div>
            <div className="profile__items__row">
                <Link
                    to={`/new-trove`}
                    className="profile__items__item svg-purple"
                >
                     گنجینه 28250
                    <SVG svg='stampProfile' />

                </Link>

                <Link
                    to={`/profile/friends`}
                    className="profile__items__item svg-darkorange"
                >
                    کاپیتان
                   <SVG svg='profileFlag' />

                </Link>
            </div>
            
            {/* <div className="profile__items__row"> */}
                {/* <Link to={`/cash`} className="profile__items__item svg-cash">
                   حسابداری
                    <SVG svg="wallet-cash" />
                </Link> */}
                {/* <Link to={`/cards`} className="profile__items__item kartStore">
                    کارت استور
                    <SVG svg="cards-mine" />
                </Link> */}

                {/* <div
                    style={{ cursor: "pointer" }}
                    className="profile__items__item svg-coral"
                    onClick={() => {
                        handleShopClick();
                    }}
                >
                    {(myShops?.length === 0 || myShops[0]?.isActive === 0) &&
                        "تامین‌کنندگی"}
                    {myShops?.length > 0 &&
                        user?.detail?.internetStore === null &&
                        myShops[0].isActive === 1 &&
                        "تکمیل اطلاعات"}
                    {myShops?.length > 0 &&
                        user?.detail?.internetStore === true &&
                        "ادمین استور"}
                    <SVG svg="map-store" />
                </div> */}
                {/* <Link to={`/wallet`} className="profile__items__item svg-purple">
          امتیاز KV
          <SVG svg="wallet-kv"/>
        </Link>

        <Link to={`/wallet/km?red`} className="profile__items__item svg-red">
          امتیاز KP
          <SVG svg="wallet-kp"/>
        </Link> */}
            {/* </div> */}
            <div className="profile__items__row">
                <Link
                    to={`/profile/orders`}
                    className="profile__items__item svg-purple"
                >
                    سفارشات من
                    <SVG svg="profile-shop" />
                </Link>

                <Link
                    to={`/profile/addresses`}
                    className="profile__items__item svg-darkorange"
                >
                    آدرس‌های من
                    <SVG svg="profile-location" />
                </Link>
            </div>
            <div className="profile__items__row">
                {/* <Link
                    to={`/channel-store`}
                    className="profile__items__item svg-darkorange"
                >
                    <span>
                        صندوق پیام ها
                        {unseenMessages != 0 && (
                            <span
                                style={{
                                    backgroundColor: "#eb4d4c",
                                    borderRadius: "50%",
                                    width: "20px",
                                    height: "20px",
                                    display: "inline-block",
                                    color: "white",
                                    textAlign: "center",
                                    marginRight: "5px",
                                }}
                            >
                                {unseenMessages}
                            </span>
                        )}
                    </span>
                    <SVG svg="club-messages" />
                </Link> */}
                <Link
                    to={`/following-store`}
                    className="profile__items__item svg-seagreen"
                >
                    علاقمندی‌ها
                    <SVG svg="seller-bookmark" />
                </Link>
                <Link to={`/setting`} className="profile__items__item svg-aqua">
                    تنظیمات
                    <SVG svg="setting" />
                </Link>

                {/* <Link to={`/cash`} className="profile__items__item svg-cash">
          کیف پول
          <SVG svg="wallet-cash"/>
        </Link> */}
            </div>
            <div className="profile__items__row">
                {/* <div
                    style={{ cursor: "pointer" }}
                    className="profile__items__item svg-coral"
                    onClick={() => {
                        handleShopClick();
                    }}
                >
                    {(myShops?.length === 0 || myShops[0]?.isActive === 0) &&
                        "درخواست تامین‌کنندگی"}
                    {myShops?.length > 0 &&
                        user?.detail?.internetStore === null &&
                        myShops[0].isActive === 1 &&
                        "تکمیل اطلاعات"}
                    {myShops?.length > 0 &&
                        user?.detail?.internetStore === true &&
                        "ادمین استور"}
                    <SVG svg="map-store" />
                </div> */}

                {/* <Link to={`/reports`} className="profile__items__item">
                    گزارشات من
                    <SVG svg="map-reports" />
                </Link> */}

                {/* <Link
                    to={`/draw`}
                    className="profile__items__item svg-seagreen"
                >
                    قرعه‌کشی
                    <SVG svg="wallet-lottery" />
                </Link> */}
                {/* <Link to={`/profile/addresses`} className="profile__items__item svg-darkorange">
          آدرس‌های من
          <SVG svg="profile-location"/>
        </Link> */}
                {/* <Link to={`/setting`} className="profile__items__item svg-aqua">
          تنظیمات
          <SVG svg="setting"/>
        </Link> */}
            </div>
            {/*<div className='profile__items__row'>*/}
            {/*  <Link to={`/kartland`} className="profile__items__item svg-aqua">*/}
            {/*    کارتلند*/}
            {/*    <SVG svg="kartland" />*/}
            {/*  </Link>*/}
            {/*  <Link to={`/kartak-yar`} className="profile__items__item svg-coral">*/}
            {/*    کارتک یار*/}
            {/*    <SVG svg="note-favorite" />*/}
            {/*  </Link>*/}
            {/*</div>*/}
        </div>
    );
};
