import { createContext, FC, ReactNode, useReducer } from "react";
import { userInitialState } from "./InitialState";
import { UserReducer } from "./Reducer";

export const UserContext = createContext(undefined);

export const UserProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [userState, userDispatch] = useReducer(UserReducer, userInitialState);
  // console.log(userState, "user");

  return (
    <UserContext.Provider value={{ userState, userDispatch }}>
      {children}
    </UserContext.Provider>
  );
};
