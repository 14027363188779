import React, { useState, useEffect } from "react";
import {SVG} from "components";
import { Link } from "react-router-dom";
import { categoriesMenuItems } from "../../../utils/data";
import "./Categories.scss";
import { getCategoriesAPI } from "../../../utils/Services/API";

export const CategoriesMenu = ({ active = false, setClose }) => {
  const [categories, setCategories] = useState([]);
  const user_token = localStorage.getItem("user_token");

  const getCategories = () => {
    const apiResult = getCategoriesAPI(user_token);
    apiResult.then((response) => {
      setCategories(response.data.data);
    });
  };

  useEffect(() => {
    getCategories();
  }, []);

  return (
    <div className={`categories-menu ${active && "active"}`}>
      <div className="categories-menu__close" onClick={() => setClose(false)}>
        <SVG svg="times" />
        بستن
      </div>
      {categoriesMenuItems.apps.map((appItem, index) => (
        <Link className="list-item__link" to={appItem.path} key={index}>
          <div className="">
            <div
              className="list-item__icon"
              style={{ backgroundColor: appItem.color.bg }}
            >
              <SVG svg={appItem.icon} color={appItem.color.solid} />
            </div>
            {appItem.title}
          </div>
          <div className="list-item__link--item2">
            <SVG svg="arrow" />
          </div>
        </Link>
      ))}

      <div className="title" style={{ marginTop: 40 }}>
        دسته‌بندی محصولات
      </div>

      {categories.map((categoryItem, index) => (
        <Link
          className="list-item__link"
          // to={categoryItem.path}
          key={index}
        >
          <div className="">
            {/* <div className="list-item__icon" style={{ backgroundColor: categoryItem.color.bg }}>
                <SVG svg={categoryItem.icon} />
              </div> */}
            {categoryItem.title}
          </div>
          <div className="list-item__link--item2">
            <SVG svg="arrow" />
          </div>
        </Link>
      ))}
    </div>
  );
};
