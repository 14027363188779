import { Fade, PageTopbar, Spinner, SVG } from "components";
import { useEffect, useState } from "react";
import { getHintAPI } from "utils";
import { useHistory } from "react-router-dom";

const Introduction = () => {
    const history = useHistory();
    const [activeCollapse, setActiveCollapse] = useState(0);
    const [loading, setLoading] = useState({
        hint1: true,
        hint2: true,
        hint3: true,
        hint4: true,
        hint5: true,
        hint6: true,
    });
    const [hint1, setHint1] = useState({
        title: "",
        content: "",
    });
    const [hint2, setHint2] = useState({
        title: "",
        content: "",
    });
    const [hint3, setHint3] = useState({
        title: "",
        content: "",
    });
    const [hint4, setHint4] = useState({
        title: "",
        content: "",
    });
    const [hint5, setHint5] = useState({
        title: "",
        content: "",
    });
    const [hint6, setHint6] = useState({
        title: "",
        content: "",
    });

    const getHint1 = () => {
        getHintAPI("سیاستها و خط مشی کارتک 1")
            .then((response) => {
                setHint1(response);
            })
            .catch(() => setHint1(false))
            .finally(() => {
                setLoading((prevState) => ({
                    ...prevState,
                    hint1: false,
                }));
            });
    };

    const getHint2 = () => {
        getHintAPI("راهنمای کلی 1")
            .then((response) => {
                setHint2(response);
            })
            .catch(() => setHint2(false))
            .finally(() => {
                setLoading((prevState) => ({
                    ...prevState,
                    hint2: false,
                }));
            });
    };

    const getHint3 = () => {
        getHintAPI("معرفی کارتک 3")
            .then((response) => {
                setHint3(response);
            })
            .catch(() => setHint3(false))
            .finally(() => {
                setLoading((prevState) => ({
                    ...prevState,
                    hint3: false,
                }));
            });
    };

    const getHint4 = () => {
        getHintAPI("صفحات داخلی کیف KM سفید")
            .then((response) => {
                setHint4(response);
            })
            .catch(() => setHint4(false))
            .finally(() => {
                setLoading((prevState) => ({
                    ...prevState,
                    hint4: false,
                }));
            });
        getHintAPI("صفحات داخلی کیف KM قرمز")
            .then((response) => {
                setHint5(response);
            })
            .catch(() => setHint5(false))
            .finally(() => {
                setLoading((prevState) => ({
                    ...prevState,
                    hint5: false,
                }));
            });
        getHintAPI("صفحات داخلی کیف KV")
            .then((response) => {
                setHint6(response);
            })
            .catch(() => setHint6(false))
            .finally(() => {
                setLoading((prevState) => ({
                    ...prevState,
                    hint6: false,
                }));
            });
    };

    useEffect(() => {
        getHint1();
        getHint2();
        getHint3();
        getHint4();
    }, []);

    useEffect(() => {
        history.replace("/introduction");
    }, []);

    return (
        <Fade>
            <PageTopbar title="معرفی کارتک" showKv={false} />
            <Spinner
                active={
                    loading.hint1 ||
                    loading.hint2 ||
                    loading.hint3 ||
                    loading.hint4 ||
                    loading.hint5 ||
                    loading.hint6
                }
                color={"dark"}
            />

            <div className="about">
                <div
                    className={`about__item ${
                        activeCollapse === 1 && "active"
                    }`}
                    onClick={() =>
                        activeCollapse === 1
                            ? setActiveCollapse(0)
                            : setActiveCollapse(1)
                    }
                >
                    {hint1.title}
                    <SVG svg="arrow" />
                </div>
                {activeCollapse === 1 && (
                    <div className="about__description">
                        {hint1.content?.split("\n")?.map((str) => (
                            <div>{str}</div>
                        ))}
                    </div>
                )}

                <div
                    className={`about__item ${
                        activeCollapse === 2 && "active"
                    }`}
                    onClick={() =>
                        activeCollapse === 2
                            ? setActiveCollapse(0)
                            : setActiveCollapse(2)
                    }
                >
                    {hint2.title}
                    <SVG svg="arrow" />
                </div>
                {activeCollapse === 2 && (
                    <div className="about__description">
                        {hint2.content?.split("\n")?.map((str) => (
                            <div>{str}</div>
                        ))}
                    </div>
                )}

                <div
                    className={`about__item ${
                        activeCollapse === 3 && "active"
                    }`}
                    onClick={() =>
                        activeCollapse === 3
                            ? setActiveCollapse(0)
                            : setActiveCollapse(3)
                    }
                >
                    {hint3.title}
                    <SVG svg="arrow" />
                </div>
                {activeCollapse === 3 && (
                    <div className="about__description">
                        {hint3.content?.split("\n")?.map((str) => (
                            <div>{str}</div>
                        ))}
                    </div>
                )}

                <div
                    className={`about__item ${
                        activeCollapse === 4 && "active"
                    }`}
                    onClick={() =>
                        activeCollapse === 4
                            ? setActiveCollapse(0)
                            : setActiveCollapse(4)
                    }
                >
                    {"امتیازهای کارتک"}
                    <SVG svg="arrow" />
                </div>
                {activeCollapse === 4 && (
                    <>
                        {/* <div className="about__title">{hint6.title}</div>
                        <div className="about__description">
                            {hint6.content?.split("\n")?.map((str) => (
                                <div>{str}</div>
                            ))}
                        </div> */}
                        <div className="about__title">{hint5.title}</div>
                        <div className="about__description">
                            {hint5.content?.split("\n")?.map((str) => (
                                <div>{str}</div>
                            ))}
                        </div>
                        {/* <div className="about__title">{hint4.title}</div> */}
                        {/* <div className="about__description">
                            {hint4.content?.split("\n")?.map((str) => (
                                <div>{str}</div>
                            ))}
                        </div> */}
                    </>
                )}
            </div>
        </Fade>
    );
};

export default Introduction;
