import { FC } from "react";
import { SVG } from "components";
import { useUser } from "hooks";
import { useHistory } from "react-router-dom";
import ProfileImage from "../../assets/images/profile.png";
import "./UserStarBox.scss";

type UserStarBoxType = {
  toggleEdit?: boolean;
  toggleNamePhone?: boolean;
};
export const UserStarBox: FC<UserStarBoxType> = ({
  toggleEdit,
  toggleNamePhone,
}) => {
  const stars = [{}, {}, {}, {}, {}, {}, {}];
  const {
    userState: { user, userImage },
  } = useUser();
  const history = useHistory();

  return (
    <div className="user-star">
      <div className="user-star--item1">
        {userImage !== "" ? (
          <img
            loading="lazy"
            src={`data:image/png;base64,${userImage}`}
            alt="user profile"
          />
        ) : (
          <img src={ProfileImage} alt="user profile" />
        )}

        {stars.map((star, index) => (
          <div
            className={`user-star__level ${
              index + 1 <=  user.detail?.star && "active"
            }`}
          >
            <SVG svg="profile-level" />
          </div>
        ))}

        <div className={`user-star__edit ${!toggleEdit && "hidden"}`}>
          <SVG svg="img-edit" />
        </div>
      </div>

      <div className={`user-star--item2 ${!toggleNamePhone && "hidden"}`}>
        <div className="user-star__name">{user.detail?.fullName}</div>
        <div className="user-star__phone">{user.mobile_number}</div>
      </div>
    </div>
  );
};
