import {Card, Fade, PageTopbar, Spinner, SVG} from "../../../components";
import {useState, useEffect} from 'react';
import {getAllJoinClubMessageAPI, seenClubMessageAPI} from "utils";
import {toast} from 'react-hot-toast';
import moment from 'jalali-moment';
import {Link, useHistory} from 'react-router-dom';
import "react-loading-skeleton/dist/skeleton.css";

export const Channel = () => {

  const [clubMessages, setClubMessages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentMessage, setCurrentMessage] = useState({});
  const [currentMessageSeenId, setCurrentMessageSeenId] = useState(-1);
  const [firstUnseenMessage, setFirstUnseenMessage] = useState(false);
  const [textError, setTextError] = useState("");

  const history = useHistory();

  const getAllMessage = () => {
    getAllJoinClubMessageAPI().then((response) => {
      if (response.status === 200) {
        setClubMessages(response.data);
        if (response.data.length === 0) {
          setTextError("پیامی یافت نشد.");
        }
      }
    }).catch((error) => {
      console.log(error);
      if (error.response.status === 403) {
        setTextError("عضو باشگاهی نیستید.");
      } else {
        toast.error("هنگام دریافت پیام ها خطایی رخ داده است.");
        setTextError("پیامی یافت نشد.");
      }
    }).finally(() => {
      setLoading(false);
    });
  };

  const executeScroll = () => {
    setTimeout(() => {
      //document.getElementsByClassName("channel-store-messages__message__seen")[0].scrollIntoView();
      if (!firstUnseenMessage) {
        const element = document.getElementsByClassName("channel-store-messages__message__seen")[0];
        if (element) {
          let position = element.getBoundingClientRect();
          window.scrollTo(position.left, position.top + window.scrollY - 60);
          setFirstUnseenMessage(true);
        }
      }
    }, 1000);
  };

  useEffect(() => {
    history.replace("/channel-store");
    getAllMessage();
  }, []);

  useEffect(() => {
    if (Object.entries(currentMessage).length !== 0) {
      console.log(currentMessage);
      seenClubMessageAPI(currentMessage['id']).then((response) => {
        if (response.status === 200) {
          setClubMessages(clubMessages.map(changeMessage => {
            if (changeMessage.id == currentMessageSeenId) {
              changeMessage.seen = true;
            }
            return changeMessage;
          }));
        }
      }).catch((error) => {
        console.log(error);
        toast.error("مشاهده پیام با خطا مواجه شد.");
      });
    }
  }, [currentMessage]);

  useEffect(() => {
    if (currentMessageSeenId !== -1) {
      clubMessages.find(message => {
        if (message.id == currentMessageSeenId && !message.seen) {
          setCurrentMessage(message);
        }
      });
    }
  }, [currentMessageSeenId]);

  return (
    <Fade>
      <PageTopbar title="کانال پیام‌ها" showKv={false}/>
      <div className="wrapper-fix">
        <Card>
          <div className="channel-store-messages">
            {
              loading ? <Spinner active={loading} color="dark"/> :
                clubMessages.length !== 0 ?
                  clubMessages.map((message, index) => (
                    <>
                      <div
                        className={`channel-store-messages__message ${!message.seen && "channel-store-messages__message__seen"}`}
                        onClick={() => setCurrentMessageSeenId(message.id)}>
                        <div className="channel-store-messages__message__header col j-sb a-c">
                          <div className="channel-store-messages__message__header__media">
                            {
                              message.photo !== null
                                ?
                                <img src={message.photo} alt="" loading="lazy"/>
                                :
                                <video src={message.video}></video>
                            }
                          </div>
                          <div className="channel-store-messages__message__header__text">
                            <span>{message.text}</span>
                          </div>
                        </div>
                        <div className="channel-store-messages__message__footer row j-sb a-c">
                          <div className="row">
                            <SVG svg={'logo'} color={"#DD2D2E"}/>
                            <Link to={`/seller/${message.store.id}`}>{message.store.name}</Link>
                          </div>
                          <span>{moment(message.created_at).locale("fa").format("YYYY/MM/DD")}</span>
                        </div>
                      </div>
                      {index + 1 == clubMessages.length ? executeScroll() : null}
                    </>
                  ))
                  : <div className="channel-store-messages__nof-found">{textError}</div>
            }

          </div>
        </Card>
      </div>
    </Fade>
  );
};