import { Fade, NotFoundBox, PageTopbar, Spinner } from 'components';
import { PanelLogs } from 'components/PanelLogs';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { collaborateScoreLogsAPI } from 'utils';

const ScoreLogContainer = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [scoreHistory, setScoreHistory] = useState([]);

  const getScoreHistory = () => {
    collaborateScoreLogsAPI().then((res) => {
      if (res) {
        setScoreHistory(res.data.ThisMonthHistory);
        setLoading(false);
      } else return;
    });
  }

  useEffect(() => {
    history.replace('/collaborate/logs/score');
    getScoreHistory();
  }, []);

  return (
    <Fade>
      <PageTopbar title="تاریخچه امتیازات کارتک" />
      <Spinner active={loading} color="dark" />
      {
        scoreHistory.length === 0 ? (
          <NotFoundBox
            title="تاریخچه‌ای برای امتیاز کارتک یافت نشد"
            fullScreen={true}
            icon="score"
          />
        ) : (
          <div className="col" style={{ padding: '0 12px' }} className="ios-top">
            {scoreHistory.map(panelHistoryItem => (
              <PanelLogs
                // title="جذب محتوا از کارتلا"
                description={panelHistoryItem.comment}
                // date={
                //   moment(panelHistoryItem.created_at, "YYYY/MM/DD")
                //     .locale("fa")
                //     .format("YYYY/MM/DD")
                // }
                type="score-logs"
              />
            ))}
          </div>
        )
      }
    </Fade>
  );
}

export default ScoreLogContainer;
