import { forwardRef, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { SVG, CounterInput, Dots, Fade } from "components";
import { checkWhiteKmTransfer } from "utils/Services";
import toast from "react-hot-toast";
import { farsiDigitToEnglish } from "utils/Functions";

export const Phone = ({ type, forwardedValue, setForwardedValue }) => {
    const history = useHistory();
    const phoneRef = useRef(null);
    const countRef = useRef(null);
    const { phone, name, count } = forwardedValue;
    const [loading, setLoading] = useState(false);

    const phoneNumberChange = (e) => {
        const value = farsiDigitToEnglish(e.target.value);
        console.log(value);
        if (value >= 12) return;
        phoneRef.current.value = value;
    };
    const handleSubmit = () => {
        const phoneInputRefValue = phoneRef.current?.value;

        if (phoneInputRefValue.length < 11) {
            toast.error("شماره موبایل را به درستی وارد نمایید");
            return;
        }
        setLoading(true);
        checkWhiteKmTransfer(phoneInputRefValue, count)
            .then((res) => {
                if (res) {
                    setForwardedValue({
                        ...forwardedValue,
                        name: res.data.destinationUserFullName,
                        phone: phoneInputRefValue,
                    });
                    history.push(
                        `/transaction/transfer?type=${type}&status=info`
                    );
                }
            })
            .catch((err) => {
                if (err.response.status == 400) {
                    toast.error("شماره درخواستی شما وجود ندارد!", {
                        id: "phone-notfound",
                    });
                }
                if (err.response.status == 403) {
                    toast.error("موجودی شما برای مقدار بیشتر کافی نمی باشد!", {
                        id: "no-enough-km",
                    });
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };
    return (
        <Fade>
            <label>شماره تلفن</label>
            <input
                ref={phoneRef}
                placeholder="شماره تلفن را وارد کنید"
                className="km-transfer__phone"
                onChange={(e) => phoneNumberChange(e)}
            />
            <label>تعداد KM</label>
            <CounterInput
                ref={countRef}
                step={25}
                max={5000}
                defaultValue={25}
                onChange={(value) =>
                    setForwardedValue({
                        ...forwardedValue,
                        count: value,
                    })
                }
            />
            <div className="km-transfer__actions">
                <div
                    onClick={() => loading === false && handleSubmit()}
                    className="km-transfer__actions__submit"
                >
                    {loading ? (
                        <Dots color="light" />
                    ) : (
                        <>
                            <span>ثبت مشخصات و ادامه</span>
                            <SVG svg="arrow" />
                        </>
                    )}
                </div>
            </div>
        </Fade>
    );
};
