import React, { useState, useEffect } from "react";
import {
  SVG,
  KV,
  PageTopbar,
  Padding,
  Fade,
  Share,
  Spinner,
  Dots,
  Modal,
} from "components";
import { Line } from "rc-progress";
import QRCode from "react-qr-code";
import { useHistory } from "react-router-dom";
import { checkQrCode, getQrAPI } from "utils/Services/API";
import toast from "react-hot-toast";
import { Hint } from "components/Hint";
import { getHintAPI } from "utils";

export const QrCodeBox = ({ color, setNotFound }) => {
  const [toggleQrBackground, setToggleQrBackground] = useState(false);
  const [userCode, setUserCode] = useState("");
  const [codeTime, setCodeTime] = useState("");
  const [showSharing, setShowSharing] = useState(false);
  const [loading, setLoading] = useState(true);
  const [userQrLoading, setUserQrLoading] = useState(false);
  const user_token = localStorage.getItem("user_token");
  const history = useHistory();
  const [showHint, setShowHint] = useState(false);
  const [hint, setHint] = useState<any>({
    title: '',
    content: ''
  });

  const saveQr = () => {
    setToggleQrBackground(true);
    setTimeout(() => {
      const svg = document.getElementById("QRCode");
      const svgData = new XMLSerializer().serializeToString(svg);
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      const img = new Image();
      img.onload = () => {
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0);
        const pngFile = canvas.toDataURL("image/png");
        const downloadLink = document.createElement("a");
        downloadLink.download = "QRCode";
        downloadLink.href = `${pngFile}`;
        downloadLink.click();
      };
      img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
      setToggleQrBackground(false);
    }, 500);
  };

  const getQr = () => {
    const api = getQrAPI(user_token);
    api.then((response) => {
      switch (response.status) {
        case 200:
          setUserCode(response.data.data.barcode.code);
          setCodeTime(response.data.data.barcode.expireDay);
          break;

        case 404:
          setNotFound(true);
          break;

        default:
          break;
      }
      setLoading(false);
    });
  };

  const scanQr = () => {
    setUserQrLoading(true);
    const serverResponse = checkQrCode(user_token, userCode);
    serverResponse.then((response) => {
      if (response.status === 401) {
        toast.error("پیش از اسکن بارکد، بایستی وارد شوید");
        history.push("/login");
      } else if (response.status === 403) {
        toast.error("بارکدی فرستاده نشد.");
      } else if (response.status === 404) {
        toast.error("بارکد شما، معتبر نمی‌باشد.");
      } else if (response.status >= 200 && response.status <= 299) {
        toast.success(
          `${response.data.data.count} تبلیغ در کارتلای شما قرار گرفت!`
        );
      }
      setUserQrLoading(false);
    });
  };

  const getHint = () => {
    getHintAPI('صفحات داخلی بارکد من')
      .then(response => {
        setHint(response)
      })
      .catch(() => setHint(false))
      .finally(() => { })
  }

  useEffect(() => {
    getQr();
    getHint();
  }, []);

  return (
    <>
      {/* <Spinner active={loading} color="dark" /> */}
      <Share
        withBtnNav
        active={showSharing}
        setClose={(close) => setShowSharing(close)}
        title="ارسال بارکد"
        value={`با قرار داد این لینک و دیدن تبلیغات در کارتک KV بگیرید
        \n لینک تبلیغ :
        https://app.kartaak.com/qr/${userCode}
        \n\n وبسایت کارتک : 
        `}
        link={`https://kartaak.com`}
      />

      <Modal active={showHint} close={() => setShowHint(false)} hint='getKv'>
        <div className="hints__title">
          {hint.title}
        </div>
        <div className="hints__content">
          {hint.content?.split('\n')?.map(str => <div>{str}</div>)}
        </div>
      </Modal>

      {
        loading ? (
          <Dots color="dark" />
        ) : (
          <React.Fragment>

            <div className="generator__qrbox">
              <div className="generator__qrbox__header">
                <QRCode
                  id="QRCode"
                  value={`https://app.kartaak.com/qr/${userCode}`}
                  size={220}
                  bgColor={toggleQrBackground ? "white" : "transparent"}
                  fgColor={color}
                />
              </div>
              <div className="generator__qrbox__footer">
                <div className="generator__qrbox__footer__shape">
                  <SVG svg="bottom-shape" color="#ffffff" />
                </div>
                <div className="generator__qrbox__footer__shape reverse">
                  <SVG svg="bottom-shape" color="#ffffff" />
                </div>
                <div className="generator__qrbox__footer__info">
                  <span className="generator__qrbox__footer__info__title">
                    زمان باقی مانده
                  </span>
                  <span className="generator__qrbox__footer__info__days">
                    {codeTime} روز
                  </span>
                </div>
                <Line
                  className="generator__qrbox__footer__info__progress"
                  percent={(Number(codeTime) * 100) / 30}
                  strokeWidth={2}
                  trailWidth={2}
                  strokeColor="#17CFAD"
                  trailColor="#B3BDCC"
                  transition="1"
                />
              </div>
            </div>
            <div className="mb-8 mt-8 row j-c">
              <Hint onClick={() => setShowHint(true)} theme="blue" />
            </div>
            <div className="generator__qrbox__actions">
              <div
                onClick={() => setShowSharing(true)}
                className="generator__qrbox__actions__share"
              >
                <SVG svg="share" />
                <span>اشتراک گذاری</span>
              </div>
              <div
                onClick={() => scanQr()}
                className="generator__qrbox__actions__save"
              >
                {userQrLoading ? (
                  <Dots />
                ) : (
                  <React.Fragment>
                    <span>شارژ کارتلا با بارکد من</span>
                    <SVG svg="save-gallery" />
                  </React.Fragment>
                )}
              </div>
            </div>
          </React.Fragment>
        )
      }
    </>
  );
};
