import { Fade, NotFoundBox, PageTopbar, Spinner } from "components";
import { PanelLogs } from "components/PanelLogs";
import moment from "jalali-moment";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { collaborateKartellaLogsAPI, getUnitHistoryAPI } from "utils";

export const UnitLogContainer = () => {
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [unitHistory, setUnitHistory] = useState([]);

    const getKartellaHistory = () => {
        getUnitHistoryAPI().then((response) => {
            setUnitHistory(response.data);
            setLoading(false);
        });
    };

    useEffect(() => {
        history.replace("/collaborate/logs/unit");
        getKartellaHistory();
    }, []);

    return (
        <Fade>
            <PageTopbar title="سوابق ویترین‌های کارتلند" />
            <Spinner active={loading} color="dark" />
            {unitHistory.length === 0 ? (
                <NotFoundBox
                    title="تاریخچه‌ای یافت نشد"
                    fullScreen={true}
                    icon="kartella"
                />
            ) : (
                <div className="col ios-top" style={{ padding: "0 12px" }}>
                    {unitHistory.map((unit) => (
                        <PanelLogs
                            // title="جذب محتوا از کارتلا"
                            description={unit.comment}
                            // date={
                            //   moment(kartellaHistoryItem.created_at, "YYYY/MM/DD")
                            //     .locale("fa")
                            //     .format("YYYY/MM/DD")
                            // }
                            type="kartella-logs"
                        />
                    ))}
                </div>
            )}
        </Fade>
    );
};
