import {
    Card,
    Dots,
    Fade,
    Modal,
    PageTopbar,
    Spinner,
    SVG,
} from "../../components";
import React, { useEffect, useState } from "react";
import { Tabs } from "../OrdersContainer/dependencies";
import toast from "react-hot-toast";
import { useHistory } from "react-router-dom";
import badge from "../../assets/images/badge.png";
import { useUser } from "../../hooks";
import {
    getHintAPI,
    setUserContentUserRequestAPI,
    contentsForAdminEditorAPI,
    contentsForEditorAPI,
    contentListAPI,
    listOfContentVideosAPI,
    listOfVideosAPI,
    setSenderToEditorRequestAPI,
    UserContentRequestCountAPI,
} from "../../utils";
import { farsiDigitToEnglish } from "utils/Functions";
import { cloneDeep } from "lodash";

export const ContentManagementContainer = () => {
    const history = useHistory();
    const {
        userState: { user },
    } = useUser();
    const [loading, setLoading] = useState(false);
    const [activeTab, setActiveTab] = useState(1);
    const [contentList, setContentList] = useState<any>({});
    const [editorContentList, setEditorContentList] = useState<any>({});
    const [adminContentList, setAdminContentList] = useState<any>({});
    const [hintText, setHintText] = useState("");
    const [videos, setVideos] = useState([]);
    const [kartellaVideos, setKartellaVideos] = useState([]);
    const [editorRequest, setEditorRequest] = useState(false);

    const [showModalEditorRequest, setShowModalEditorRequest] = useState(false);
    const [inviterCode, setInviterCode] = useState("");
    const [inviteCodeStatus, setInviteCodeStatus] = useState(false);
    const [inviteCodeLoading, setInviteCodeLoading] = useState(false);
    const [unitDetail, setUnitDetail] = useState({
        inviter: "",
    });
    const [videoLoading, setVideoLoading] = useState(true);

    const handleSubmitInviteCode = () => {
        setInviteCodeLoading(true);

        setUserContentUserRequestAPI(inviterCode)
            .then((response) => {
                if (response.status === 200) {
                    toast.success("درخواست شما با موفقیت ارسال شد");
                    setUnitDetail({ ...unitDetail, inviter: inviterCode });
                    setInviteCodeStatus(true);
                    setShowModalEditorRequest(false);
                }
            })
            .catch((error) => {
                if (
                    error?.response?.data?.msg === "You have already requested"
                ) {
                    toast.error("قبلا درخواست داده شده است");
                } else if (
                    error?.response?.data?.data === "userInviter not found"
                ) {
                    toast.error("کد یا شماره تلفن صحیح نیست");
                } else if (error?.response?.status === 403) {
                    toast.error("شما سردبیر نیستید.");
                } else if (
                    error?.response?.data.msg === "you can not invite yourself"
                ) {
                    toast.error("شما نمیتوانید خودتان را دعوت کنید");
                } else if (
                    error?.response?.data.msg ===
                    "you can not invite adminEditor"
                ) {
                    toast.error("شما نمیتوانید سردبیر را دعوت کنید");
                }
            })
            .finally(() => {
                setInviteCodeLoading(false);
            });
    };

    const [tabs, setTabs] = useState([
        {
            title: "محتوا من",
            icon: "scores",
        },
    ]);

    const [acceptTermsModal, setAcceptTermsModal] = useState(false);
    const [ruleHint, setRuleHint] = useState<any>(`
    1-	کاربری که ویراستار می شود هیچ اجباری برای همکاری نداشته و ویراستار شدن مسئولیتی را برای او ایجاد نمی کند.
    2-	هر ویراستار می تواند افراد جدیدی را به عنوان ویراستار معرفی نماید. افراد معرفی شده باید درخواست ویراستاری خود را ارسال کنند، کارتک در پذیرش یا رد درخواستها مختار است.
    3-	اگر ویراستار اطلاعات اشتباه برای کارتک ارسال نماید بلافاصله تعلیق شده و امتیازات دریافتی او مسدود خواهد شد. 
    4-	ویراستار هیچگونه ارتباط استخدامی یا قراردادی با کارتک نداشته و بابت همکاری فقط امتیاز دریافت می کنند. امتیازات همکاری توسط کارتک تعیین شده و ویراستار هر گونه تغییر را می پذیرد.
    5-	کارتک می تواند در هر زمانی و به هر دلیلی ویراستار را به کاربر عادی تبدیل نماید.
  `);
    const [loadingAcceptTermsButton, setLoadingAcceptTermsButton] =
        useState(false);
    const [loadStoreCount, setLoadStoreCount] = useState(false);
    const [introducerRequestCount, setIntroducerRequestCount] =
        useState<any>(0);

    const handleStartCollaboration = () => {
        if (
            introducerRequestCount.accepted >= introducerRequestCount.recomended
        ) {
            setLoadingAcceptTermsButton(true);
            senderToEditor();
        } else {
            toast.error("تعداد محتوا های تایید شده به حد نصاب نرسیده است.");
        }
    };

    useEffect(() => {
        if (loadStoreCount) {
            UserContentRequestCountAPI()
                .then((response) => {
                    if (response.status === 200) {
                        setIntroducerRequestCount(response.data);
                        setLoadStoreCount(true);
                        setAcceptTermsModal(true);
                    }
                })
                .catch((error) => {
                    if (
                        error?.response?.data?.msg ===
                        "you dont have accepted content"
                    ) {
                        toast.error(
                            "شما هیچ محتوای تایید شده ای ندارید . تعداد محتوای مورد نیاز برای ویراستاری : " +
                                error?.response?.data?.data?.recomended
                        );
                    } else {
                        toast.error("خطایی رخ داده است.");
                    }
                    setAcceptTermsModal(false);
                    setLoadStoreCount(false);
                });
        }
    }, [loadStoreCount]);

    useEffect(() => {
        if (user.isAdminEditor === 1) {
            setEditorRequest(true);
        }

        let currTabs = cloneDeep(tabs);
        if (user.isEditor > 0) {
            currTabs.push({
                title: "ویراستار",
                icon: "",
            });
        }

        if (user.isAdminEditor === 1) {
            currTabs.push({
                title: "سر دبیر",
                icon: "",
            });
        }

        if (user.isAdminEditor === 1) {
            currTabs.push({
                title: "معرفی ویراستار",
                icon: "",
            });
        }
        // } else if (user.isEditor === 0) {
        //   currTabs.push({
        //     title: "درخواست ویراستاری",
        //     icon: "",
        //   });
        // }

        setTabs(currTabs);
    }, [user]);

    const getContents = () => {
        setLoading(true);
        contentListAPI()
            .then((res) => {
                if (res.status === 200) {
                    setContentList(res.data);
                }
            })
            .catch((err) => {
                toast.success("خطایی در دریافت اطلاعات رخ داده است.");
            })
            .finally(() => setLoading(false));
    };

    const getContentsForEditor = () => {
        setLoading(true);
        contentsForEditorAPI()
            .then((res) => {
                if (res.status === 200) {
                    setEditorContentList(res.data);
                }
            })
            .catch((err) => {
                toast.success("خطایی در دریافت اطلاعات رخ داده است.");
            })
            .finally(() => setLoading(false));
    };

    const getContentsForAdmin = () => {
        setLoading(true);
        contentsForAdminEditorAPI()
            .then((res) => {
                if (res.status === 200) {
                    setAdminContentList(res.data);
                }
            })
            .catch((err) => {
                toast.success("خطایی در دریافت اطلاعات رخ داده است.");
            })
            .finally(() => setLoading(false));
    };

    // const getHint = () => {
    //     getHintAPI("راهنمای ثابت ایجاد محتوای جدید")
    //         .then((response) => {
    //             setHintText(response.content);
    //         })
    //         .catch((error) => {
    //             setHintText("دریافت راهنما با خطا مواجه شد");
    //         });
    // };

    const videosList = () => {
        listOfContentVideosAPI()
            .then((res) => {
                if (res.status === 200) {
                    setVideos(res.data);
                }
            })
            .catch((err) => {})
            .finally(() => {
                setVideoLoading(false);
            });
    };

    const videosListKartella = () => {
        listOfVideosAPI()
            .then((res) => {
                if (res.status === 200) {
                    setKartellaVideos(res.data);
                }
            })
            .catch((err) => {})
            .finally();
    };

    const [senderToEditorLoading, setSenderToEditorLoading] = useState(false);
    const senderToEditor = () => {
        setSenderToEditorLoading(true);
        setSenderToEditorRequestAPI()
            .then((response) => {
                if (response.status === 200) {
                    toast.success("درخواست شما برای کارتک ارسال شد");
                }
            })
            .catch((err) => {
                if (err.response.status === 400) {
                    toast.error("شما قبلا درخواست داده اید");
                } else if (err.response.status === 403) {
                    toast.error("شما شرایط ویراستاری را ندارید");
                } else if (err.response.status === 406) {
                    toast.error("شما کاربر 7 ستاره نیستید.");
                } else {
                    toast.error("مشکلی در ارسال درخواست رخ داد");
                }
            })
            .finally(() => {
                setSenderToEditorLoading(false);
            });
    };

    useEffect(() => {
        if (user.isAdminEditor === 1) {
            getContentsForAdmin();
        }
        if (user.isEditor > 0) {
            getContentsForEditor();
        }
    }, [user]);

    useEffect(() => {
        getContents();
        history.replace("/content-management");
        videosList();
        videosListKartella();
    }, []);

    useEffect(() => {
        let currAds = cloneDeep(contentList);
        currAds?.contentApproved?.map((item) => {
            videos.filter((video) => {
                if (video.contentId === item.id) {
                    item.thumbnail = video.thumbnail;
                }
            });
        });

        currAds?.contentEdit?.map((item) => {
            videos.filter((video) => {
                if (video.contentId === item.id) {
                    item.thumbnail = video.thumbnail;
                }
            });
        });

        currAds?.contentReject?.map((item) => {
            videos.filter((video) => {
                if (video.contentId === item.id) {
                    item.thumbnail = video.thumbnail;
                }
            });
        });

        currAds?.contentWaiting?.map((item) => {
            videos.filter((video) => {
                if (video.contentId === item.id) {
                    item.thumbnail = video.thumbnail;
                }
            });
        });

        setContentList(currAds);
    }, [videos, Object.keys(contentList).length]);

    useEffect(() => {
        let currEditorAds = cloneDeep(editorContentList);
        currEditorAds?.contentNotEditor?.map((item) => {
            videos.filter((video) => {
                if (video.contentId === item.id) {
                    item.thumbnail = video.thumbnail;
                }
            });
        });

        currEditorAds?.editorContents?.contentApproved?.map((item) => {
            videos.filter((video) => {
                if (video.contentId === item.id) {
                    item.thumbnail = video.thumbnail;
                }
            });
        });

        currEditorAds?.editorContents?.contentEdit?.map((item) => {
            videos.filter((video) => {
                if (video.contentId === item.id) {
                    item.thumbnail = video.thumbnail;
                }
            });
        });

        currEditorAds?.editorContents?.contentReject?.map((item) => {
            videos.filter((video) => {
                if (video.contentId === item.id) {
                    item.thumbnail = video.thumbnail;
                }
            });
        });

        setEditorContentList(currEditorAds);
    }, [videos, Object.keys(editorContentList).length]);

    useEffect(() => {
        let currAdminAds = cloneDeep(adminContentList);
        currAdminAds?.adminEditorContents?.contentApproved?.map((item) => {
            kartellaVideos.filter((video) => {
                if (video.contentId === item.id) {
                    item.thumbnail = video.thumbnail;
                }
            });
        });

        currAdminAds?.adminEditorContents?.contentNotApproved?.map((item) => {
            kartellaVideos.filter((video) => {
                if (video.contentId === item.id) {
                    item.thumbnail = video.thumbnail;
                }
            });
        });

        currAdminAds?.listAdsPending?.map((item) => {
            kartellaVideos.filter((video) => {
                if (video.contentId === item.id) {
                    item.thumbnail = video.thumbnail;
                }
            });
        });

        setAdminContentList(currAdminAds);
    }, [kartellaVideos, Object.keys(adminContentList).length]);

    return (
        <Fade>
            <PageTopbar
                title="ویراستار شو"
                showKv={false}
                circleHint={hintText}
            />

            <div className="wrapper-fix">
                <Card>
                    <div className="my-introduce__header row">
                        <div
                            className="col a-c"
                            style={{ marginBottom: "12px" }}
                        >
                            {
                                <img
                                    onClick={() => {
                                        if (user.isEditor === 0) {
                                            setLoadStoreCount(true);
                                        }
                                    }}
                                    className={`my-introduce__badge mr0 ${
                                        user.isEditor === 0 && "deActive"
                                    }`}
                                    src={badge}
                                    alt="badge"
                                />
                            }
                            <span>
                                {user.isEditor === 0 ? "ویراستاری" : ""}
                            </span>
                        </div>

                        <div
                            onClick={() => {
                                history.push("/create-content");
                            }}
                            className={`my-introduce__create submiter`}
                        >
                            ساخت محتوا جدید
                        </div>
                    </div>

                    {tabs.length > 1 && (
                        <Tabs
                            activeTab={activeTab}
                            setActiveTab={setActiveTab}
                            items={tabs}
                        />
                    )}

                    {activeTab === 1 && (
                        <div className="content__list">
                            {loading ? (
                                <Dots color={"dark"} />
                            ) : (
                                <>
                                    {contentList?.contentWaiting?.length >
                                        0 && (
                                        <div className={"title"}>
                                            در انتظار بررسی
                                        </div>
                                    )}
                                    {contentList?.contentWaiting?.map(
                                        (item, index) => (
                                            <div
                                                onClick={() => {
                                                    history.push(
                                                        `/content-edit/${item.id}`
                                                    );
                                                }}
                                                className={
                                                    "my-introduce__submit__item"
                                                }
                                            >
                                                <div className="my-introduce__submit__item__title">
                                                    {`نویسنده: ${
                                                        item.user.nickname
                                                            ? item.user.nickname
                                                            : item.user.name
                                                    }`}
                                                </div>
                                                <div className="my-introduce__submit__item__details">
                                                    <div className="my-introduce__submit__item__pic">
                                                        {item?.video_url ==
                                                        null ? (
                                                            <img
                                                                src={
                                                                    item
                                                                        ?.pictureContent
                                                                        ?.path
                                                                }
                                                                alt="content-photo"
                                                            />
                                                        ) : videoLoading ? (
                                                            <Dots />
                                                        ) : (
                                                            <img
                                                                src={
                                                                    item
                                                                        ?.thumbnail
                                                                        ?.data
                                                                        ?.thumbnail_url
                                                                }
                                                                alt="content-photo"
                                                            />
                                                        )}
                                                    </div>
                                                    <div className="my-introduce__submit__item__info">
                                                        <div className="my-introduce__submit__item__info__address mb-8">
                                                            <div className="my-introduce__introduce__item__top__name">
                                                                دسته‌بندی:{" "}
                                                                {
                                                                    item
                                                                        .categoryName
                                                                        .title
                                                                }
                                                            </div>
                                                        </div>

                                                        <div className="my-introduce__submit__item__info__name">
                                                            <div className="my-introduce__introduce__item__top__name">
                                                                {item.title}
                                                            </div>
                                                        </div>

                                                        <div className="my-introduce__submit__item__info__number">
                                                            <div className="my-introduce__introduce__item__top__name">
                                                                {`${item?.descriptions.slice(
                                                                    0,
                                                                    12
                                                                )}`}
                                                            </div>
                                                            {/*<div className="my-introduce__introduce__item__bottom__delete">*/}
                                                            {/*  <SVG svg="create-remove"/>*/}
                                                            {/*</div>*/}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    )}

                                    {contentList?.contentEdit?.length > 0 && (
                                        <div className={"title"}>
                                            نیاز به ویرایش
                                        </div>
                                    )}
                                    {contentList?.contentEdit?.map(
                                        (item, index) => (
                                            <div
                                                onClick={() =>
                                                    history.push(
                                                        `/content-edit/${item.id}`
                                                    )
                                                }
                                                className={
                                                    "my-introduce__submit__item"
                                                }
                                            >
                                                <div className="my-introduce__submit__item__title">
                                                    {`نویسنده: ${
                                                        item.user.nickname
                                                            ? item.user.nickname
                                                            : item.user.name
                                                    }`}
                                                </div>
                                                <div className="my-introduce__submit__item__details">
                                                    <div className="my-introduce__submit__item__pic">
                                                        {item?.video_url ==
                                                        null ? (
                                                            <img
                                                                src={
                                                                    item
                                                                        ?.pictureContent
                                                                        ?.path
                                                                }
                                                                alt="content-photo"
                                                            />
                                                        ) : videoLoading ? (
                                                            <Dots />
                                                        ) : (
                                                            <img
                                                                src={
                                                                    item
                                                                        ?.thumbnail
                                                                        ?.data
                                                                        ?.thumbnail_url
                                                                }
                                                                alt="content-photo"
                                                            />
                                                        )}
                                                    </div>
                                                    <div className="my-introduce__submit__item__info">
                                                        <div className="my-introduce__submit__item__info__address mb-8">
                                                            <div className="my-introduce__introduce__item__top__name">
                                                                دسته‌بندی:{" "}
                                                                {
                                                                    item
                                                                        .categoryName
                                                                        .title
                                                                }
                                                            </div>
                                                        </div>

                                                        <div className="my-introduce__submit__item__info__name">
                                                            <div className="my-introduce__introduce__item__top__name">
                                                                {item.title}
                                                            </div>
                                                        </div>

                                                        <div className="my-introduce__submit__item__info__number">
                                                            <div className="my-introduce__introduce__item__top__name">
                                                                {`${item?.descriptions.slice(
                                                                    0,
                                                                    12
                                                                )}`}
                                                            </div>
                                                            {/*<div className="my-introduce__introduce__item__bottom__delete">*/}
                                                            {/*  <SVG svg="create-remove"/>*/}
                                                            {/*</div>*/}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    )}

                                    {contentList?.contentApproved?.length >
                                        0 && (
                                        <div className={"title"}>تائید شده</div>
                                    )}
                                    {contentList?.contentApproved?.map(
                                        (item, index) => (
                                            <div
                                                className={
                                                    "my-introduce__submit__item"
                                                }
                                            >
                                                <div className="my-introduce__submit__item__title">
                                                    {`نویسنده: ${
                                                        item.user.nickname
                                                            ? item.user.nickname
                                                            : item.user.name
                                                    }`}
                                                </div>
                                                <div className="my-introduce__submit__item__details">
                                                    <div className="my-introduce__submit__item__pic">
                                                        {item?.video_url ==
                                                        null ? (
                                                            <img
                                                                src={
                                                                    item
                                                                        ?.pictureContent
                                                                        ?.path
                                                                }
                                                                alt="content-photo"
                                                            />
                                                        ) : videoLoading ? (
                                                            <Dots />
                                                        ) : (
                                                            <img
                                                                src={
                                                                    item
                                                                        ?.thumbnail
                                                                        ?.data
                                                                        ?.thumbnail_url
                                                                }
                                                                alt="content-photo"
                                                            />
                                                        )}
                                                    </div>
                                                    <div className="my-introduce__submit__item__info">
                                                        <div className="my-introduce__submit__item__info__address mb-8">
                                                            <div className="my-introduce__introduce__item__top__name">
                                                                دسته‌بندی:{" "}
                                                                {
                                                                    item
                                                                        .categoryName
                                                                        .title
                                                                }
                                                            </div>
                                                        </div>

                                                        <div className="my-introduce__submit__item__info__name">
                                                            <div className="my-introduce__introduce__item__top__name">
                                                                {item.title}
                                                            </div>
                                                        </div>

                                                        <div className="my-introduce__submit__item__info__number">
                                                            <div className="my-introduce__introduce__item__top__name">
                                                                {`${item?.descriptions.slice(
                                                                    0,
                                                                    12
                                                                )}`}
                                                            </div>
                                                            {/*<div className="my-introduce__introduce__item__bottom__delete">*/}
                                                            {/*  <SVG svg="create-remove"/>*/}
                                                            {/*</div>*/}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    )}

                                    {contentList?.contentReject?.length > 0 && (
                                        <div className={"title"}>رد شده</div>
                                    )}
                                    {contentList?.contentReject?.map(
                                        (item, index) => (
                                            <div
                                                className={
                                                    "my-introduce__submit__item"
                                                }
                                            >
                                                <div className="my-introduce__submit__item__title">
                                                    {`نویسنده: ${
                                                        item.user.nickname
                                                            ? item.user.nickname
                                                            : item.user.name
                                                    }`}
                                                </div>
                                                <div className="my-introduce__submit__item__details">
                                                    <div className="my-introduce__submit__item__pic">
                                                        {item?.video_url ==
                                                        null ? (
                                                            <img
                                                                src={
                                                                    item
                                                                        ?.pictureContent
                                                                        ?.path
                                                                }
                                                                alt="content-photo"
                                                            />
                                                        ) : videoLoading ? (
                                                            <Dots />
                                                        ) : (
                                                            <img
                                                                src={
                                                                    item
                                                                        ?.thumbnail
                                                                        ?.data
                                                                        ?.thumbnail_url
                                                                }
                                                                alt="content-photo"
                                                            />
                                                        )}
                                                    </div>
                                                    <div className="my-introduce__submit__item__info">
                                                        <div className="my-introduce__submit__item__info__address mb-8">
                                                            <div className="my-introduce__introduce__item__top__name">
                                                                دسته‌بندی:{" "}
                                                                {
                                                                    item
                                                                        .categoryName
                                                                        .title
                                                                }
                                                            </div>
                                                        </div>

                                                        <div className="my-introduce__submit__item__info__name">
                                                            <div className="my-introduce__introduce__item__top__name">
                                                                {item.title}
                                                            </div>
                                                        </div>

                                                        <div className="my-introduce__submit__item__info__number">
                                                            <div className="my-introduce__introduce__item__top__name">
                                                                {`${item?.descriptions.slice(
                                                                    0,
                                                                    12
                                                                )}`}
                                                            </div>
                                                            {/*<div className="my-introduce__introduce__item__bottom__delete">*/}
                                                            {/*  <SVG svg="create-remove"/>*/}
                                                            {/*</div>*/}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    )}
                                </>
                            )}
                        </div>
                    )}

                    {user.isEditor > 0 && activeTab === 2 && (
                        <div className="content__list">
                            {loading ? (
                                <Dots color={"dark"} />
                            ) : (
                                <>
                                    {editorContentList?.contentNotEditor
                                        ?.length > 0 && (
                                        <div className={"title"}>
                                            در انتظار بررسی
                                        </div>
                                    )}
                                    {editorContentList?.contentNotEditor?.map(
                                        (item, index) => (
                                            <div
                                                onClick={() =>
                                                    history.push(
                                                        `/confirm-editor/${item.id}`
                                                    )
                                                }
                                                className={
                                                    "my-introduce__submit__item"
                                                }
                                            >
                                                <div className="my-introduce__submit__item__title">
                                                    {`نویسنده: ${
                                                        item.user.nickname
                                                            ? item.user.nickname
                                                            : item.user.name
                                                    }`}
                                                </div>
                                                <div className="my-introduce__submit__item__details">
                                                    <div className="my-introduce__submit__item__pic">
                                                        {item?.video_url ==
                                                        null ? (
                                                            <img
                                                                src={
                                                                    item
                                                                        ?.pictureContent
                                                                        ?.path
                                                                }
                                                                alt="content-photo"
                                                            />
                                                        ) : videoLoading ? (
                                                            <Dots />
                                                        ) : (
                                                            <img
                                                                src={
                                                                    item
                                                                        ?.thumbnail
                                                                        ?.data
                                                                        ?.thumbnail_url
                                                                }
                                                                alt="content-photo"
                                                            />
                                                        )}
                                                    </div>
                                                    <div className="my-introduce__submit__item__info">
                                                        <div className="my-introduce__submit__item__info__address mb-8">
                                                            <div className="my-introduce__introduce__item__top__name">
                                                                دسته‌بندی:{" "}
                                                                {
                                                                    item
                                                                        .categoryName
                                                                        .title
                                                                }
                                                            </div>
                                                        </div>

                                                        <div className="my-introduce__submit__item__info__name">
                                                            <div className="my-introduce__introduce__item__top__name">
                                                                {item.title}
                                                            </div>
                                                        </div>

                                                        <div className="my-introduce__submit__item__info__number">
                                                            <div className="my-introduce__introduce__item__top__name">
                                                                {`${item?.descriptions.slice(
                                                                    0,
                                                                    12
                                                                )}`}
                                                            </div>
                                                            {/*<div className="my-introduce__introduce__item__bottom__delete">*/}
                                                            {/*  <SVG svg="create-remove"/>*/}
                                                            {/*</div>*/}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    )}

                                    {editorContentList?.editorContents
                                        ?.contentApproved?.length > 0 && (
                                        <div className={"title"}>
                                            تائید کرده
                                        </div>
                                    )}
                                    {editorContentList?.editorContents?.contentApproved?.map(
                                        (item, index) => (
                                            <div
                                                className={
                                                    "my-introduce__submit__item"
                                                }
                                            >
                                                <div className="my-introduce__submit__item__title">
                                                    {`نویسنده: ${
                                                        item.user.nickname
                                                            ? item.user.nickname
                                                            : item.user.name
                                                    }`}
                                                </div>
                                                <div className="my-introduce__submit__item__details">
                                                    <div className="my-introduce__submit__item__pic">
                                                        {item?.video_url ==
                                                        null ? (
                                                            <img
                                                                src={
                                                                    item
                                                                        ?.pictureContent
                                                                        ?.path
                                                                }
                                                                alt="content-photo"
                                                            />
                                                        ) : videoLoading ? (
                                                            <Dots />
                                                        ) : (
                                                            <img
                                                                src={
                                                                    item
                                                                        ?.thumbnail
                                                                        ?.data
                                                                        ?.thumbnail_url
                                                                }
                                                                alt="content-photo"
                                                            />
                                                        )}
                                                    </div>
                                                    <div className="my-introduce__submit__item__info">
                                                        <div className="my-introduce__submit__item__info__address mb-8">
                                                            <div className="my-introduce__introduce__item__top__name">
                                                                دسته‌بندی:{" "}
                                                                {
                                                                    item
                                                                        .categoryName
                                                                        .title
                                                                }
                                                            </div>
                                                        </div>

                                                        <div className="my-introduce__submit__item__info__name">
                                                            <div className="my-introduce__introduce__item__top__name">
                                                                {item.title}
                                                            </div>
                                                        </div>

                                                        <div className="my-introduce__submit__item__info__number">
                                                            <div className="my-introduce__introduce__item__top__name">
                                                                {`${item?.descriptions.slice(
                                                                    0,
                                                                    12
                                                                )}`}
                                                            </div>
                                                            {/*<div className="my-introduce__introduce__item__bottom__delete">*/}
                                                            {/*  <SVG svg="create-remove"/>*/}
                                                            {/*</div>*/}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    )}

                                    {editorContentList?.editorContents
                                        ?.contentEdit?.length > 0 && (
                                        <div className={"title"}>
                                            نیاز به ویرایش
                                        </div>
                                    )}
                                    {editorContentList?.editorContents?.contentEdit?.map(
                                        (item, index) => (
                                            <div
                                                className={
                                                    "my-introduce__submit__item"
                                                }
                                            >
                                                <div className="my-introduce__submit__item__title">
                                                    {`نویسنده: ${
                                                        item.user.nickname
                                                            ? item.user.nickname
                                                            : item.user.name
                                                    }`}
                                                </div>
                                                <div className="my-introduce__submit__item__details">
                                                    <div className="my-introduce__submit__item__pic">
                                                        {item?.video_url ==
                                                        null ? (
                                                            <img
                                                                src={
                                                                    item
                                                                        ?.pictureContent
                                                                        ?.path
                                                                }
                                                                alt="content-photo"
                                                            />
                                                        ) : videoLoading ? (
                                                            <Dots />
                                                        ) : (
                                                            <img
                                                                src={
                                                                    item
                                                                        ?.thumbnail
                                                                        ?.data
                                                                        ?.thumbnail_url
                                                                }
                                                                alt="content-photo"
                                                            />
                                                        )}
                                                    </div>
                                                    <div className="my-introduce__submit__item__info">
                                                        <div className="my-introduce__submit__item__info__address mb-8">
                                                            <div className="my-introduce__introduce__item__top__name">
                                                                دسته‌بندی:{" "}
                                                                {
                                                                    item
                                                                        .categoryName
                                                                        .title
                                                                }
                                                            </div>
                                                        </div>

                                                        <div className="my-introduce__submit__item__info__name">
                                                            <div className="my-introduce__introduce__item__top__name">
                                                                {item.title}
                                                            </div>
                                                        </div>

                                                        <div className="my-introduce__submit__item__info__number">
                                                            <div className="my-introduce__introduce__item__top__name">
                                                                {`${item?.descriptions.slice(
                                                                    0,
                                                                    12
                                                                )}`}
                                                            </div>
                                                            {/*<div className="my-introduce__introduce__item__bottom__delete">*/}
                                                            {/*  <SVG svg="create-remove"/>*/}
                                                            {/*</div>*/}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    )}

                                    {editorContentList?.editorContents
                                        ?.contentReject?.length > 0 && (
                                        <div className={"title"}>رد شده</div>
                                    )}
                                    {editorContentList?.editorContents?.contentReject?.map(
                                        (item, index) => (
                                            <div
                                                className={
                                                    "my-introduce__submit__item"
                                                }
                                            >
                                                <div className="my-introduce__submit__item__title">
                                                    {`نویسنده: ${
                                                        item.user.nickname
                                                            ? item.user.nickname
                                                            : item.user.name
                                                    }`}
                                                </div>
                                                <div className="my-introduce__submit__item__details">
                                                    <div className="my-introduce__submit__item__pic">
                                                        {item?.video_url ==
                                                        null ? (
                                                            <img
                                                                src={
                                                                    item
                                                                        ?.pictureContent
                                                                        ?.path
                                                                }
                                                                alt="content-photo"
                                                            />
                                                        ) : videoLoading ? (
                                                            <Dots />
                                                        ) : (
                                                            <img
                                                                src={
                                                                    item
                                                                        ?.thumbnail
                                                                        ?.data
                                                                        ?.thumbnail_url
                                                                }
                                                                alt="content-photo"
                                                            />
                                                        )}
                                                    </div>
                                                    <div className="my-introduce__submit__item__info">
                                                        <div className="my-introduce__submit__item__info__address mb-8">
                                                            <div className="my-introduce__introduce__item__top__name">
                                                                دسته‌بندی:{" "}
                                                                {
                                                                    item
                                                                        .categoryName
                                                                        .title
                                                                }
                                                            </div>
                                                        </div>

                                                        <div className="my-introduce__submit__item__info__name">
                                                            <div className="my-introduce__introduce__item__top__name">
                                                                {item.title}
                                                            </div>
                                                        </div>

                                                        <div className="my-introduce__submit__item__info__number">
                                                            <div className="my-introduce__introduce__item__top__name">
                                                                {`${item?.descriptions.slice(
                                                                    0,
                                                                    12
                                                                )}`}
                                                            </div>
                                                            {/*<div className="my-introduce__introduce__item__bottom__delete">*/}
                                                            {/*  <SVG svg="create-remove"/>*/}
                                                            {/*</div>*/}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    )}
                                </>
                            )}
                        </div>
                    )}

                    {((user.isEditor > 0 &&
                        activeTab === 3 &&
                        user.isAdminEditor === 1) ||
                        (user.isEditor === 0 &&
                            activeTab === 2 &&
                            user.isAdminEditor === 1)) && (
                        <div className="content__list">
                            {loading ? (
                                <Dots color={"dark"} />
                            ) : (
                                <>
                                    {adminContentList?.listAdsPending?.length >
                                        0 && (
                                        <div className={"title"}>
                                            در انتظار بررسی
                                        </div>
                                    )}
                                    {adminContentList?.listAdsPending?.map(
                                        (item, index) => (
                                            <div
                                                onClick={() =>
                                                    history.push(
                                                        `/admin-editor-content-edit/${item.details.id}/${item.goal}`
                                                    )
                                                }
                                                className={
                                                    "my-introduce__submit__item"
                                                }
                                            >
                                                <div className="my-introduce__submit__item__title">
                                                    {`نویسنده: ${
                                                        item.details.user
                                                            .nickname
                                                            ? item.details.user
                                                                  .nickname
                                                            : item.details.user
                                                                  .full_name
                                                    }`}
                                                </div>
                                                <div className="my-introduce__submit__item__details">
                                                    <div className="my-introduce__submit__item__pic">
                                                        {console.log(item)}
                                                        {item?.video_url ==
                                                        null ? (
                                                            <img
                                                                src={
                                                                    item
                                                                        ?.details
                                                                        ?.image
                                                                }
                                                                alt="content-photo"
                                                            />
                                                        ) : videoLoading ? (
                                                            <Dots />
                                                        ) : (
                                                            <img
                                                                src={
                                                                    item
                                                                        ?.thumbnail
                                                                        ?.data
                                                                        ?.thumbnail_url
                                                                }
                                                                alt="content-photo"
                                                            />
                                                        )}
                                                    </div>
                                                    <div className="my-introduce__submit__item__info">
                                                        <div className="my-introduce__submit__item__info__address mb-8">
                                                            <div className="my-introduce__introduce__item__top__name">
                                                                دسته‌بندی:{" "}
                                                                {
                                                                    item.details
                                                                        .adsCat
                                                                }
                                                            </div>
                                                        </div>

                                                        <div className="my-introduce__submit__item__info__name">
                                                            <div className="my-introduce__introduce__item__top__name">
                                                                {
                                                                    item.details
                                                                        .title
                                                                }
                                                            </div>
                                                        </div>

                                                        <div className="my-introduce__submit__item__info__number">
                                                            <div className="my-introduce__introduce__item__top__name">
                                                                {`${item.details?.description.slice(
                                                                    0,
                                                                    12
                                                                )}`}
                                                            </div>
                                                            {/*<div className="my-introduce__introduce__item__bottom__delete">*/}
                                                            {/*  <SVG svg="create-remove"/>*/}
                                                            {/*</div>*/}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    )}

                                    {adminContentList?.adminEditorContents
                                        ?.contentApproved?.length > 0 && (
                                        <div className={"title"}>تائید شده</div>
                                    )}
                                    {adminContentList?.adminEditorContents?.contentApproved?.map(
                                        (item, index) => (
                                            <div
                                                className={
                                                    "my-introduce__submit__item"
                                                }
                                            >
                                                <div className="my-introduce__submit__item__title">
                                                    {`نویسنده: ${
                                                        item.user.nickname
                                                            ? item.user.nickname
                                                            : item.user
                                                                  .full_name
                                                    }`}
                                                </div>
                                                <div className="my-introduce__submit__item__details">
                                                    <div className="my-introduce__submit__item__pic">
                                                        {item?.video_url ==
                                                        null ? (
                                                            <img
                                                                src={
                                                                    item?.image
                                                                }
                                                                alt="content-photo"
                                                            />
                                                        ) : videoLoading ? (
                                                            <Dots />
                                                        ) : (
                                                            <img
                                                                src={
                                                                    item
                                                                        ?.thumbnail
                                                                        ?.data
                                                                        ?.thumbnail_url
                                                                }
                                                                alt="content-photo"
                                                            />
                                                        )}
                                                    </div>
                                                    <div className="my-introduce__submit__item__info">
                                                        <div className="my-introduce__submit__item__info__address mb-8">
                                                            <div className="my-introduce__introduce__item__top__name">
                                                                دسته‌بندی:{" "}
                                                                {item
                                                                    ?.categoryName
                                                                    ?.title !=
                                                                undefined
                                                                    ? item
                                                                          .categoryName
                                                                          .title
                                                                    : item?.adsCat}
                                                            </div>
                                                        </div>

                                                        <div className="my-introduce__submit__item__info__name">
                                                            <div className="my-introduce__introduce__item__top__name">
                                                                {item.title}
                                                            </div>
                                                        </div>

                                                        <div className="my-introduce__submit__item__info__number">
                                                            <div className="my-introduce__introduce__item__top__name">
                                                                {`${item.description.slice(
                                                                    0,
                                                                    12
                                                                )}`}
                                                            </div>
                                                            {/*<div className="my-introduce__introduce__item__bottom__delete">*/}
                                                            {/*  <SVG svg="create-remove"/>*/}
                                                            {/*</div>*/}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    )}

                                    {adminContentList?.adminEditorContents
                                        ?.contentNotApproved?.length > 0 && (
                                        <div className={"title"}>رد شده</div>
                                    )}
                                    {adminContentList?.adminEditorContents?.contentNotApproved?.map(
                                        (item, index) => (
                                            <div
                                                className={
                                                    "my-introduce__submit__item"
                                                }
                                            >
                                                <div className="my-introduce__submit__item__title">
                                                    {`نویسنده: ${
                                                        item.user.nickname
                                                            ? item.user.nickname
                                                            : item.user
                                                                  .full_name
                                                    }`}
                                                </div>
                                                <div className="my-introduce__submit__item__details">
                                                    <div className="my-introduce__submit__item__pic">
                                                        {item?.video_url ==
                                                        null ? (
                                                            <img
                                                                src={
                                                                    item
                                                                        ?.pictureContent
                                                                        ?.path
                                                                }
                                                                alt="content-photo"
                                                            />
                                                        ) : videoLoading ? (
                                                            <Dots />
                                                        ) : (
                                                            <img
                                                                src={
                                                                    item
                                                                        ?.thumbnail
                                                                        ?.data
                                                                        ?.thumbnail_url
                                                                }
                                                                alt="content-photo"
                                                            />
                                                        )}
                                                    </div>
                                                    <div className="my-introduce__submit__item__info">
                                                        <div className="my-introduce__submit__item__info__address mb-8">
                                                            <div className="my-introduce__introduce__item__top__name">
                                                                دسته‌بندی:{" "}
                                                                {
                                                                    item
                                                                        .categoryName
                                                                        .title
                                                                }
                                                            </div>
                                                        </div>

                                                        <div className="my-introduce__submit__item__info__name">
                                                            <div className="my-introduce__introduce__item__top__name">
                                                                {item.title}
                                                            </div>
                                                        </div>

                                                        <div className="my-introduce__submit__item__info__number">
                                                            <div className="my-introduce__introduce__item__top__name">
                                                                {`${item?.descriptions.slice(
                                                                    0,
                                                                    12
                                                                )}`}
                                                            </div>
                                                            {/*<div className="my-introduce__introduce__item__bottom__delete">*/}
                                                            {/*  <SVG svg="create-remove"/>*/}
                                                            {/*</div>*/}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    )}
                                </>
                            )}
                        </div>
                    )}

                    {activeTab === tabs.length && (
                        <div className="content__list">
                            <div>
                                {user.isAdminEditor === 1 && (
                                    <>
                                        <div className="title">
                                            شماره موبایل یا کد کارتک
                                        </div>
                                        <div className="row">
                                            <input
                                                type="text"
                                                className="input"
                                                value={inviterCode}
                                                onChange={(e) =>
                                                    setInviterCode(
                                                        farsiDigitToEnglish(
                                                            e.target.value
                                                        )
                                                    )
                                                }
                                            />
                                            <div
                                                className={`create-shop__inviter__button ${
                                                    inviteCodeStatus && "edit"
                                                } ${
                                                    inviterCode.length === 0 &&
                                                    "hidden"
                                                }`}
                                                onClick={() => {
                                                    if (inviteCodeStatus) {
                                                        setInviterCode("");
                                                        setInviteCodeStatus(
                                                            false
                                                        );
                                                        setUnitDetail({
                                                            ...unitDetail,
                                                            inviter: "",
                                                        });
                                                    } else {
                                                        handleSubmitInviteCode();
                                                    }
                                                }}
                                            >
                                                {inviteCodeLoading ? (
                                                    <Dots color="light" />
                                                ) : inviteCodeStatus ? (
                                                    "ویرایش"
                                                ) : (
                                                    "ثبت"
                                                )}
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    )}

                    <Modal
                        active={acceptTermsModal}
                        close={() => {
                            setAcceptTermsModal(false);
                            setLoadStoreCount(false);
                        }}
                    >
                        {introducerRequestCount !== 0 ? (
                            <div className="marketing__rules">
                                <div className="marketing__rules__title">
                                    {ruleHint?.title}
                                </div>

                                <div
                                    className="marketing__rules__description"
                                    id="marketingRules"
                                >
                                    {ruleHint?.split("\n")?.map((str) => (
                                        <div>{str}</div>
                                    ))}
                                </div>

                                <hr
                                    style={{ width: "-webkit-fill-available" }}
                                />

                                <div className="marketing__rules__description">
                                    تعداد محتوای تایید شده مورد نیاز برای
                                    ویراستار شدن :{" "}
                                    {introducerRequestCount?.recomended}
                                    <br />
                                    تعداد محتوای تایید شده توسط شما :{" "}
                                    {introducerRequestCount?.accepted}
                                </div>

                                <hr
                                    style={{ width: "-webkit-fill-available" }}
                                />
                                <div className="marketing__rules__bottom">
                                    <div
                                        className={`marketing__rules__button ${
                                            !senderToEditorLoading
                                                ? "marketing__rules__accept"
                                                : "marketing__rules__disabled"
                                        }`}
                                        onClick={() => {
                                            handleStartCollaboration();
                                        }}
                                    >
                                        {senderToEditorLoading ? (
                                            <Dots color="dark" />
                                        ) : (
                                            "پذیرش قوانین و ارسال درخواست"
                                        )}
                                    </div>
                                    {/* <div className="marketing__rules__button marketing__rules__reject">
                    انصراف
                  </div> */}
                                </div>
                            </div>
                        ) : (
                            <Dots color="dark" />
                        )}
                    </Modal>
                </Card>
            </div>
        </Fade>
    );
};
