import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { getParticipationUnitAPI } from "utils/Services/KartlandUnits";
import { Fade, PageTopbar, Spinner, SVG } from "../../components";
import empty from "../../assets/images/empty-kartland.png";

export const KartlandParticipationHistoryContainer = () => {
  const [loading, setLoading] = useState(true);
  const [userUnits, setUserUnits] = useState([]);
  const history = useHistory();

  const getParticipationUnits = () => {
    getParticipationUnitAPI()
      .then((response) => {
        setUserUnits(response.data);
      })
      .catch((err) => {})
      .finally(() => setLoading(false));
  };

  const handleShowBlockType = (blockType) => {
    switch (blockType) {
      case 25:
      case 100:
        return "بلوک ۲۵تایی فورشاپ";

      case 50:
      case 200:
        return "بلوک ۵۰تایی فورشاپ";

      default:
        break;
    }
  };

  useEffect(() => {
    getParticipationUnits();
  }, []);

  return (
    <Fade>
      <Spinner active={loading} color={"dark"} />
      <div className={"kartland"}>
        <PageTopbar title={"تاریخچه مشارکت"} showKv={false} white={true} />

        <div className={"kartland__user-buy-wrapper"}>
          <div
            className="button"
            onClick={() => history.push("/kartland/participation/buy")}
            style={{ marginBottom: 16 }}
          >
            مشارکت در فروش واحد های کارتلند
          </div>

          {userUnits?.length === 0 && (
            <div className="kartland__wrapper__empty">
              <div className="kartland__wrapper__empty__image">
                <img src={empty} alt="empty" />
              </div>
              <div className="kartland__wrapper__empty__text">
                بلوک مشارکتی ندارید
              </div>
            </div>
          )}

          <div className="kartland-participation__wrapper">
            {userUnits?.map((userUnit, index) => (
              <Link
                to={`/kartland/participation/${userUnit.id}`}
                className="kartland-participation__block"
                key={index}
              >
                <div className="kartland-participation__item__header">
                  <div className="kartland-participation__item__circle">
                    <SVG svg="kartland" />
                  </div>
                  <div className="col">
                    <div>{handleShowBlockType(userUnit.blockType)}</div>

                    {/* <div className="subtitle">شهرستان {userUnit.cityname}</div> */}
                  </div>
                </div>

                <div className="kartland-participation__item__row">
                  <div className="kartland-participation__item__title">
                    وضعیت فروش:
                  </div>
                  <div className="kartland-participation__item__value green">
                    {userUnit.blockType - userUnit.soldOut === 0
                      ? "فروش رفته"
                      : `${
                          (userUnit.blockType - userUnit.soldOut) / 4
                        } فورشاپ مانده`}
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </Fade>
  );
};
