import {Request} from "../../api-call";

export const updateMessageAPI = async (id, type, message, pic) => {
  const {data} = await Request.post(`/api/v3/kartland/customerClubMessage/${id}?type=${type}&message=${message}`, pic, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
  return data;
};
