import { KartellaContainer } from 'containers/CollaborateContainer/Logs';
import React from 'react';

const CollaborateKartellaLogs = () => {
  return (
    <KartellaContainer />
  );
}

export default CollaborateKartellaLogs;
