import { SVG } from "components";
import { useState } from "react";
import { Link } from "react-router-dom";
import "./Bank.scss";

export const Bank = () => {
    const [activeBank, setActiveBank] = useState(
        sessionStorage.getItem("bankCollapse") === "1"
    );

    const handleCollapseBank = () => {
        if (!activeBank) {
            setTimeout(() => {
                window.scrollTo(0, 630);
            }, 100);
        }
        sessionStorage.setItem(
            "bankCollapse",
            sessionStorage.getItem("bankCollapse") === "1" ? "0" : "1"
        );
        setActiveBank(!activeBank);
    };

    // useEffect(() => {
    //   if (sessionStorage.getItem("bankCollapse") === "1") {
    //     setActiveBank(true);
    //   } else {
    //     setActiveBank(false);
    //   }
    // }, []);

    return (
        <div className={`profile__bank ${activeBank && "active"}`}>
            <div
                className={`profile__slider__collapse svg-cash ${
                    activeBank && "active"
                }`}
                onClick={() => handleCollapseBank()}
            >
                کارتک بانک
                <SVG svg="arrow" />
            </div>

            <div className={`profile__items__item__apps ${"active"}`}>
                <div
                    className={`profile__items__item__bank ${
                        activeBank && "active"
                    }`}
                >
                    <div className="profile__items__row">
                        <Link
                            to={`/cash`}
                            className="profile__items__item svg-cash"
                        >
                            کیف نقدی
                            <SVG svg="wallet-cash" />
                        </Link>

                        <Link
                            to={`/wallet`}
                            className="profile__items__item svg-purple"
                        >
                            کیف KV
                            <SVG svg="wallet-kv" />
                        </Link>
                    </div>

                    <div className="profile__items__row">
                        <Link
                            to={`/wallet/km?red`}
                            className="profile__items__item svg-red"
                        >
                            کیف KP
                            <SVG svg="wallet-kp" />
                        </Link>

                        <Link
                            to={`/wallet/km?white`}
                            className="profile__items__item svg-indigo"
                        >
                            کیف KM
                            <SVG svg="wallet-km" />
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};
