import {
    AcceptRule,
    CheckBox,
    CounterInput,
    Dots,
    Fade,
    Modal,
    PageTopbar,
    Selectbox,
    SVG,
    UpdateUserInfo,
} from "../../components";
import React, { useEffect, useRef, useState } from "react";
import {
    getCitiesAPI,
    getCountriesAPI,
    getHintAPI,
    getStatesAPI,
    GetWhiteKmPrice,
} from "../../utils";
import {
    CheckBuyUnitWithKm,
    CheckUserIsCitizenAPI,
} from "utils/Services/KartlandUnits";
import toast from "react-hot-toast";
import { checkPanelCodeAPI } from "../../utils/Services/API";
import {
    buyFromKartaakAPI,
    getCashUnitCountAPI,
    getKmUnitCountAPI,
    getUnitsCountByCityAPI,
} from "../../utils/Services/KartlandUnits";
import { useHistory, useLocation } from "react-router-dom";
import { currency, farsiDigitToEnglish } from "../../utils/Functions";
import { useUser } from "../../hooks";

export const KartlandBuyUnitContainer = () => {
    const user_token = localStorage.getItem("user_token");

    const [unitDetail, setUnitDetail] = useState({
        name: "",
        inviter: "",
        country: 1,
        state: 0,
        city: 0,
        count: 0,
    });
    const [inviterCode, setInviterCode] = useState("");
    const {
        getUserDataFromApi,
        userState: { user },
    } = useUser();
    const [showFillProfile, setShowFillProfile] = useState(false);
    const [showFillProfileModal, setShowFillProfileModal] = useState(false);
    const [userInfo, setUserInfo] = useState({
        name: "",
        lastname: "",
        nationalCode: "",
    });
    const unitCount = useRef(null);
    const payWithWhiteKm = useRef(null);
    const [payWhiteKm, setPayWhiteKm] = useState<any>();
    const [inviteCodeStatus, setInviteCodeStatus] = useState(false);
    const [inviteCodeLoading, setInviteCodeLoading] = useState(false);
    const [data, setData] = useState<any>({
        countries: [],
        states: [],
        cities: [],
        subCities: [],
        districts: [],
    });
    const [submitLoading, setSubmitLoading] = useState(false);
    const [checkoutType, setCheckoutType] = useState("cash");
    const [currCity, setCurrCity] = useState("");
    const [unitsCount, setUnitsCount] = useState({
        totalUnit: 0,
        allSoldOutUnit: 0,
        remainingUnit: 0,
        donateSoldOutUnit: 0,
        allCashUnit: 0,
        remainingCashUnit: 0,
        cashSoldOutUnit: 0,
        allKmUnit: 0,
        remainingKmUnit: 0,
        kmSoldOutUnit: 0,
        price: 0,
    });
    const [payPrice, setPayPrice] = useState<any>(0);
    const [whiteKmPrice, setWhiteKmPrice] = useState(0);
    const [showAlert, setShowAlert] = useState(false);
    const [hint, setHint] = useState<any>("");
    const [hintLoading, setHintLoading] = useState(true);
    const [readCompleted, setreadCompleted] = useState(false);
    const [activeStateName, setActiveStateName] = useState("");
    const [acceptedRule, setAcceptedRule] = useState(false);
    const [activeCityName, setActiveCityName] = useState("");
    const [showBuyUnitWithKm, setShowBuyUnitWithKm] = useState(false);
    const history = useHistory();
    const query = useQuery();

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }

    const getHint = () => {
        getHintAPI("قوانین و هشدارها 3")
            .then((response) => {
                setHint(response);
            })
            .catch(() => setHint(false))
            .finally(() => setHintLoading(false));
    };

    const getCountries = () => {
        getCountriesAPI()
            .then((response) => {
                setData({ ...data, countries: response.data });
            })
            .catch(() => {
                toast.error("خطا در بارگذاری کشورها");
            })
            .finally(() => {});
    };

    const getStates = (countryId) => {
        getStatesAPI(countryId)
            .then((response) => {
                setData({ ...data, states: response.data });
            })
            .catch(() => toast.error("خطا در بارگذاری استان‌ها"))
            .finally(() => {});
    };

    const getCities = (stateId) => {
        getCitiesAPI(stateId)
            .then((response) => {
                setData({ ...data, cities: response.data });
            })
            .catch(() => toast.error("خطا در بارگذاری شهرستان‌ها"))
            .finally(() => {});
    };

    const handleSubmitInviteCode = () => {
        setInviteCodeLoading(true);

        CheckUserIsCitizenAPI(inviterCode)
            .then((checkCitizenResponse) => {
                if (checkCitizenResponse.status === 200) {
                    if (checkCitizenResponse.data[0] === true) {
                        toast.success("معرف فورشاپ با موفقیت ثبت شد");
                        setInviteCodeStatus(true);
                    } else if (checkCitizenResponse.data[0] === false) {
                        toast.error(
                            `کاربر معرف ${checkCitizenResponse.data[1]} سیتیزن نمی‌باشد.`
                        );
                    } else if (checkCitizenResponse.data[0] == null) {
                        toast.error(
                            `شما نمی‌توانید خودتان را به عنوان معرف ثبت کنید.`
                        );
                    } else {
                        toast.error(`خطایی هنگام ثبت معرف رخ داد.`);
                    }
                } else {
                    toast.error("مشکلی در خرید فورشاپ رخ داد");
                }
            })
            .catch((err) => {
                if (err.response.data.status === 404) {
                    toast.error("کاربر معرف یافت نشد");
                } else {
                    toast.error("مشکلی در خرید فورشاپ رخ داد");
                }
            })
            .finally(() => {
                setInviteCodeLoading(false);
            });

        /*
        checkPanelCodeAPI(user_token, inviterCode).then((response) => {
        if (response.status === 200) {
          if (!response.data.data.Credit) {
            toast.error("کد وارد شده منقضی شده است.");
            return;
          } else {
            toast.success("معرف فورشاپ با موفقیت ثبت شد");
            setInviteCodeStatus(true);
            setUnitDetail({...unitDetail, inviter: inviterCode});
            return;
          }
        } else if (response.status === 404) {
          toast.error("پنلی با این کد پیدا نشد.");
          return;
        }
    }).catch((err) => {
    }).finally(() => setInviteCodeLoading(false));
    */
    };

    const getUnistCount = () => {
        getUnitsCountByCityAPI(unitDetail.city)
            .then((res) => {
                if (res.status === 200) {
                    setUnitsCount(res.data);
                }
            })
            .catch(() => {
                toast.error("error");
            })
            .finally();
    };

    // const getKmUnitCount = () => {
    //   getKmUnitCountAPI(unitDetail.city)
    //     .then((res) => {
    //       if (res.status === 200) {
    //         setKmUnitCount(res.data);
    //       }
    //     })
    //     .catch(() => {
    //       toast.error("error");
    //     })
    //     .finally();
    // };

    // const getCashUnitCount = () => {
    //   getCashUnitCountAPI(unitDetail.city)
    //     .then((res) => {
    //       if (res.status === 200) {
    //         setCashUnitCount(res.data);
    //         setPayPrice(res.data.price);
    //       }
    //     })
    //     .catch(() => {
    //       toast.error("error");
    //     })
    //     .finally();
    // };

    const fetchWhiteKmPrice = () => {
        GetWhiteKmPrice()
            .then((res) => {
                if (res) {
                    setWhiteKmPrice(Number(res.data));
                }
            })
            .catch((err) => {
                if (err) toast.error("به مشکل برخوردیم");
            });
    };

    const handleSubmitUnit = () => {
        if (unitDetail.country === 0) {
            toast.error("کشور را انتخاب کنید.");
            return;
        }

        if (unitDetail.state === 0) {
            toast.error("استان را انتخاب کنید.");
            return;
        }

        if (unitDetail.city === 0) {
            toast.error("شهرستان را انتخاب کنید.");
            return;
        }

        // if (inviterCode === "" && user.citizenInviter == null) {
        //     toast.error("معرف را وارد کنید.");
        //     return;
        // }

        // if (!inviteCodeStatus && user.citizenInviter == null) {
        //     toast.error("معرف را ثبت کنید.");
        //     return;
        // }

        setSubmitLoading(true);
        buyFromKartaakFunc();
    };

    const buyFromKartaakFunc = () => {
        buyFromKartaakAPI(
            checkoutType,
            payWhiteKm * 25,
            unitDetail.country,
            unitDetail.state,
            unitDetail.city,
            user.citizenInviter != null ? "" : inviterCode,
            checkoutType === "km" ? 16 : unitDetail.count * 16
        )
            .then((response) => {
                if (
                    response.status === 200 &&
                    response.msg == "unit purchased from WhiteKM"
                ) {
                    toast.success("فورشاپ با موفقیت خریداری شد");
                    history.replace("/kartland");
                }
                if (
                    response.status === 200 &&
                    response.data.payID &&
                    response.data.tk
                ) {
                    toast.success("در حال انتقال به درگاه پرداخت...");

                    const paymentURL = `${process.env.REACT_APP_DEV_IP}api/v3/kartland/kartaakUnit/${response.data.payID}/payment/${response.data.tk}`;
                    history.push(`/pay?${paymentURL}`);
                    setTimeout(() => {
                        if (history.location.pathname.includes("/pay"))
                            window.location.href = `${paymentURL}`;
                    }, 600);
                }
            })
            .catch((err) => {
                if (err.response.data.msg == "you are not have 7 star") {
                    toast.error("شمار کاربر 7 ستاره نیستید");
                } else if (
                    err.response.data.data[0] ==
                    "witheKm : whiteKM request is more than unitPrice"
                ) {
                    toast.error("ارزش KM وارد شده بیشتر از مبلغ فورشاپ است");
                } else if (
                    err.response.data.data[0] ==
                    "The amount of Request whiteKM is more than the user whiteKm"
                ) {
                    toast.error("مقدار KM وارد شده بیشتر از موجودی KM شماست");
                } else if (
                    err.response.data.data[0] ==
                    "The user bought the unit from whiteKm in the past"
                ) {
                    toast.error("شما قبلا فورشاپ با KM خریداری کرده‌اید");
                } else if (
                    err?.response?.data?.data[0]?.witheKm[0] ==
                    "The withe km must be at least 1."
                ) {
                    toast.error("حداقال یک km باید داشته باشید.");
                } else {
                    toast.error("مشکلی در خرید فورشاپ رخ داد");
                }
            })
            .finally(() => setSubmitLoading(false));
    };

    const UserInfoHandler = () => {
        // if (userInfo.name?.length === 0 || userInfo.name === null
        //   || userInfo.lastname?.length === 0 || userInfo.lastname === null
        //   || userInfo.nationalCode?.length === 0 || userInfo.nationalCode === null || userInfo.nationalCode?.length !== 10) {
        //   setShowFillProfile(true);
        // } else {
        //   setShowFillProfile(false);
        // }
        if (
            user.name?.length === 0 ||
            user.name === null ||
            user.lastname?.length === 0 ||
            user.lastname === null ||
            user.nationalCode?.length === 0 ||
            user.nationalCode === null ||
            user.nationalCode?.length !== 10
        ) {
            setShowFillProfile(true);
        } else {
            setShowFillProfile(false);
        }
    };

    const getMaxWhiteKm = () => {
        const kmCount =
            (unitsCount.price * unitDetail.count * 16) / whiteKmPrice;
        if (
            user.whiteKm * unitDetail.count >
            unitsCount.price * unitDetail.count
        ) {
            return user.whiteKm;
        } else {
            if (user.whiteKm > kmCount) {
                return kmCount / 25;
            } else {
                return Math.floor(user.whiteKm / 25);
            }
        }
    };

    const checkBuyUnitKm = () => {
        CheckBuyUnitWithKm(Number(user.id), unitDetail.city)
            .then((res) => {
                if (res) {
                    if (
                        res.data[0] ==
                        "The user can buy the unit from whiteKm !"
                    ) {
                        setShowBuyUnitWithKm(true);
                    } else {
                        setShowBuyUnitWithKm(false);
                    }
                }
            })
            .catch((err) => {
                if (err) {
                    toast.error("به مشکل برخوردیم!");
                }
            });
    };

    useEffect(() => {
        // getCountries();
        getStates(1);
        getHint();
        fetchWhiteKmPrice();
        // if (query.get('country') !== null){
        //   setUnitDetail({ ...unitDetail, country: Number(query.get('country')) });
        //   getStates(query.get('country'));
        // }

        // if (query.get('state') !== null){
        //   setUnitDetail({ ...unitDetail, state: Number(query.get('state')) });
        //   getStates(1);
        //   getCities(query.get('state'));
        // }

        // if (query.get('city') !== null){
        //   setUnitDetail({ ...unitDetail, city: Number(query.get('city')) });
        // }
    }, []);

    useEffect(() => {
        setUserInfo({
            name: user.name,
            lastname: user.lastname,
            nationalCode: user.nationalCode,
        });
        UserInfoHandler();
    }, [user]);

    useEffect(() => {
        if (unitDetail.city !== 0) {
            getUnistCount();
            checkBuyUnitKm();
        }
    }, [unitDetail.city]);

    useEffect(() => {
        let totalKm = (whiteKmPrice * payWhiteKm * 25) / 16;
        if (checkoutType == "km") {
            if (totalKm >= unitsCount.price) {
                setPayPrice(0);
            } else {
                setPayPrice(unitsCount.price - totalKm);
            }
        } else {
            setPayPrice(unitsCount.price * unitDetail.count);
        }
    }, [payWhiteKm, checkoutType, unitsCount.price, unitDetail.count]);

    useEffect(() => {
        document
            .getElementById("buyUnitAlert")
            ?.addEventListener("scroll", () => {
                document.getElementById("buyUnitAlert").scrollTop >
                document.getElementById("buyUnitAlert")?.scrollHeight -
                    document.getElementById("buyUnitAlert")?.clientHeight -
                    20
                    ? setreadCompleted(true)
                    : setreadCompleted(false);
            });
        return () => {
            document
                .getElementById("buyUnitAlert")
                ?.removeEventListener("scroll", () => {
                    document.getElementById("buyUnitAlert").scrollTop >
                    document.getElementById("buyUnitAlert")?.scrollHeight -
                        document.getElementById("buyUnitAlert")?.clientHeight -
                        20
                        ? setreadCompleted(true)
                        : setreadCompleted(false);
                });
        };
    }, [document.getElementById("buyUnitAlert")?.scrollTop]);

    useEffect(() => {
        getUserDataFromApi();
    }, []);

    return (
        <Fade>
            <Modal active={showAlert} close={(close) => setShowAlert(false)}>
                <div className="buy-panel__alert__title">{hint.title}</div>
                <div
                    className={`buy-panel__alert__description ${
                        readCompleted && "deactive"
                    }`}
                    id={"buyUnitAlert"}
                >
                    {hintLoading && <Dots color="dark" />}
                    {hint.content?.split("\n")?.map((str) => (
                        <div>{str}</div>
                    ))}
                </div>
                <div className="row buy-panel__alert__bottom">
                    <div
                        className={`buy-panel__alert__button  ${
                            readCompleted ? "accept" : "disabled"
                        }`}
                        onClick={() => {
                            if (readCompleted) {
                                setAcceptedRule(true);
                                setShowAlert(false);
                            } else {
                                document
                                    .getElementById("buyUnitAlert")
                                    .scrollTo(0, 2500);
                            }
                            // localStorage.setItem('panelSeen', 'true')
                        }}
                    >
                        قوانین را می‌پذیرم
                    </div>
                    <div
                        className="buy-panel__alert__button reject"
                        onClick={() => setShowAlert(false)}
                    >
                        انصراف
                    </div>
                </div>
            </Modal>
            <UpdateUserInfo
                showUpdate={showFillProfileModal}
                setShowUpdate={(value) => {
                    value && handleSubmitUnit();
                    setShowFillProfileModal(false);
                }}
                userInfo={userInfo}
                setUserInfo={setUserInfo}
                getUser={getUserDataFromApi}
                useForCitizen={true}
                isNationalCodeRequired={true}
            />
            <div className={"kartland"}>
                <PageTopbar title="خرید فورشاپ" showKv={false} white={true} />
                <div className={"kartland__user-buy-wrapper"}>
                    {/* <div className="title white">کشور</div>
          <Selectbox
            data={data.countries}
            placeholder="انتخاب کشور"
            onChange={(value) => {
              setUnitDetail({...unitDetail, country: value});
              getStates(value);
              history.replace(`/kartland/buy?country=${value}`);
            }}
            onChangeString={(value) => {
              console.log(value);
            }}
          /> */}

                    {unitDetail.country !== 0 && (
                        <React.Fragment>
                            <div className="title white">استان</div>
                            <Selectbox
                                data={data.states}
                                placeholder="انتخاب استان"
                                onChange={(value) => {
                                    setUnitDetail({
                                        ...unitDetail,
                                        state: value,
                                        city: 0,
                                    });
                                    getCities(value);
                                    history.replace(
                                        `/kartland/buy?country=${unitDetail.country}&state=${value}`
                                    );
                                }}
                                onChangeString={(value) => {
                                    setActiveStateName(value);
                                }}
                                disabled={unitDetail.country === 0}
                                openAll={unitDetail.state !== 0 ? true : false}
                            />
                        </React.Fragment>
                    )}

                    {unitDetail.state !== 0 && (
                        <React.Fragment>
                            <div className="title white">شهرستان</div>
                            <Selectbox
                                data={data.cities}
                                placeholder="انتخاب شهرستان"
                                onChange={(value) => {
                                    setUnitDetail({
                                        ...unitDetail,
                                        city: value,
                                    });
                                    setCheckoutType("cash");
                                    history.replace(
                                        `/kartland/buy?country=${unitDetail.country}&state=${unitDetail.state}&city=${value}`
                                    );
                                }}
                                onChangeString={(value) => {
                                    setCurrCity(value);
                                    setActiveCityName(value);
                                }}
                                disabled={unitDetail.state === 0}
                                openAll={unitDetail.city !== 0 ? true : false}
                            />
                        </React.Fragment>
                    )}

                    {unitDetail.city !== 0 && (
                        <div className={"mb-12"}>
                            <div className="kartland-buy__options mb-8">
                                {unitDetail.city !== 0 && (
                                    <div
                                        className="kartland-buy__options__header"
                                        onClick={() => {
                                            history.push(
                                                `/pay?https://fa.wikipedia.org/wiki/شهرستان_${activeCityName}`
                                            );
                                            setTimeout(() => {
                                                if (
                                                    history.location.pathname.includes(
                                                        "/pay"
                                                    )
                                                )
                                                    window.location.href = `https://fa.wikipedia.org/wiki/شهرستان_${activeCityName}`;
                                            }, 600);
                                        }}
                                    >
                                        شهرستان {activeCityName} را بیشتر
                                        بشناسید.
                                    </div>
                                )}

                                <div className="kartland-buy__options__option mb-4">
                                    <div className="kartland-buy__options__option__title">
                                        تعداد کل فورشاپ‌های کارتلند در{" "}
                                        {currCity}:
                                    </div>
                                    {` ${unitsCount.totalUnit / 16} عدد`}
                                </div>

                                {checkoutType === "cash" && (
                                    <>
                                        <div className="kartland-buy__options__option mb-4">
                                            <div className="kartland-buy__options__option__title">
                                                تعداد فورشاپ‌های موجود:
                                            </div>
                                            {` ${
                                                unitsCount.remainingUnit / 16
                                            }`}
                                        </div>
                                    </>
                                )}

                                <div className="kartland-buy__options__option mb-8">
                                    <div className="kartland-buy__options__option__title">
                                        قیمت هر فورشاپ:
                                    </div>
                                    {` ${currency(
                                        unitsCount.price * 16
                                    )} تومان`}
                                </div>

                                {unitsCount.remainingKmUnit > 0 &&
                                    showBuyUnitWithKm && (
                                        <div className="row">
                                            <CheckBox
                                                type="check"
                                                onChange={(value) =>
                                                    setCheckoutType(
                                                        value ? "km" : "cash"
                                                    )
                                                }
                                            />
                                            <span>{`خرید با بسته های 25 تایی KM`}</span>
                                        </div>
                                    )}
                            </div>
                        </div>
                    )}

                    {unitDetail.city !== 0 && checkoutType === "cash" && (
                        <div className={"mb-12"}>
                            <div className="title white">تعداد فورشاپ:</div>
                            <div className="kartland-buy__pay-km">
                                <CounterInput
                                    height={true}
                                    ref={unitCount}
                                    step={1}
                                    max={unitsCount.remainingCashUnit}
                                    defaultValue={1}
                                    unitMode={true}
                                    onChange={(value) =>
                                        setUnitDetail({
                                            ...unitDetail,
                                            count: value,
                                        })
                                    }
                                />
                            </div>
                        </div>
                    )}

                    {unitDetail.city !== 0 &&
                        checkoutType === "km" &&
                        showBuyUnitWithKm && (
                            <div className={"mb-12"}>
                                <div className="title white">
                                    تعداد بسته های 25 تایی KM
                                </div>
                                <div className="kartland-buy__pay-km">
                                    <CounterInput
                                        height={true}
                                        step={1}
                                        max={getMaxWhiteKm()}
                                        packageMode={true}
                                        defaultValue={getMaxWhiteKm()}
                                        onChange={(value) =>
                                            setPayWhiteKm(value)
                                        }
                                    />
                                </div>
                            </div>
                        )}

                    {/* {unitDetail.city !== 0 && user.citizenInviter == null && (
                        <>
                            <div className="title white">
                                شماره موبایل یا کد کارتک معرف
                            </div>
                            <div className="row">
                                <input
                                    type="text"
                                    className="input"
                                    value={inviterCode}
                                    onChange={(e) =>
                                        setInviterCode(
                                            farsiDigitToEnglish(e.target.value)
                                        )
                                    }
                                    placeholder="شماره موبایل یا کد کارتک معرف"
                                    required={true}
                                />
                                <div
                                    className={`create-shop__inviter__button ${
                                        inviteCodeStatus && "edit"
                                    } ${inviterCode.length === 0 && "hidden"}`}
                                    onClick={() => {
                                        if (inviteCodeStatus) {
                                            setInviterCode("");
                                            setInviteCodeStatus(false);
                                            setUnitDetail({
                                                ...unitDetail,
                                                inviter: "",
                                            });
                                        } else {
                                            handleSubmitInviteCode();
                                        }
                                    }}
                                >
                                    {inviteCodeLoading ? (
                                        <Dots color="light" />
                                    ) : inviteCodeStatus ? (
                                        "ویرایش"
                                    ) : (
                                        "ثبت"
                                    )}
                                </div>
                            </div>
                        </>
                    )} */}

                    {unitDetail.city !== 0 && (
                        <>
                            <div className="kartland-buy__price">
                                <div className="">مبلغ قابل پرداخت:</div>
                                <div className="">
                                    {currency(payPrice * 16)} تومان
                                </div>
                            </div>
                        </>
                    )}
                </div>

                <div className={"w-full absolute bottom-16"}>
                    <div className="row a-c j-c" style={{ margin: "8px auto" }}>
                        <CheckBox
                            defaultValue={acceptedRule}
                            type="check"
                            onChange={(value) =>
                                setAcceptedRule(value ? true : false)
                            }
                        />
                        <AcceptRule
                            title="خرید فورشاپ"
                            color="light"
                            onCLick={() => setShowAlert(true)}
                        />
                    </div>
                    <div
                        className={`button width-full-16`}
                        onClick={() => {
                            if (!acceptedRule) {
                                // toast.error(
                                //     "ابتدا قوانین و مقررات خرید فورشاپ را مطالعه و بپذیرید."
                                // );
                                setShowAlert(true);
                                return;
                            }
                            if (showFillProfile) {
                                setShowFillProfileModal(true);
                            } else {
                                !submitLoading && handleSubmitUnit();
                            }
                        }}
                    >
                        {submitLoading ? <Dots color="light" /> : "خرید فورشاپ"}
                    </div>
                </div>
            </div>
        </Fade>
    );
};
