import { CheckBox, SVG } from 'components';
import { CreateAdsContext } from 'containers';
import React, { Children, useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { fillProfileAPI } from 'utils/Services/API';

const FillCustomMarital = ({
  close
}) => {
  const [marital, setMarital] = useState(-1);
  const [maritalValue, setMaritalValue] = useState('');
  const [children, setChildren] = useState(0);
  const [childrenCount, setChildrenCount] = useState(null);
  const createAds = useContext(CreateAdsContext);

  const handleActivateSubmit = () => {
    if (marital === -1)
      return false;
    else
      return true;
  }

  const handleSubmitForm = () => {
    if (handleActivateSubmit()) {
      const APIRequest = (marital === 1 || marital === 0) ? {
        marital: marital,
        maritalValue: maritalValue,
        maritalChildren: children,
        maritalChildrenCount: childrenCount
      } : {
        marital: marital,
        maritalValue: maritalValue,
        maritalChildren: children,
        maritalChildrenCount: childrenCount
      };

      createAds.detail.set(prev => ({
        ...prev,
        marital: APIRequest
      }));

      createAds.active(prev => ({
        ...prev,
        marital: {
          active: true
        }
      }));

      close(true);
    } else {
      toast.error("برای دریافت امتیاز، لطفا تمامی مقادیر را وارد نمایید.");
    }
  }

  useEffect(() => {
    setMarital(createAds.detail.get.marital.marital);
    setChildren(createAds.detail.get.marital.maritalChildren);
    setChildrenCount(createAds.detail.get.marital.maritalChildrenCount);
  }, [createAds]);

  return (
    <div className="fill-profile__form">

      <div className="row">
        <div className="row" style={{ marginLeft: 16 }}>
          <CheckBox
            disable
            defaultValue={marital === 1 && true}
            onChange={value => {
              if (value) {
                setMarital(1);
                setChildren(0);
                setMaritalValue('مجرد');
              }
            }}
          />
          <div className="fill-profile__form__title">
            مجرد
          </div>
        </div>

        <div className="row" style={{ marginLeft: 16 }}>
          <CheckBox
            disable
            defaultValue={marital === 2 && true}
            onChange={value => {
              if (value) {
                setMarital(2);
                setMaritalValue('متاهل');
              }
            }}
          />
          <div className="fill-profile__form__title">
            متاهل
          </div>
        </div>

        <div className="row">
          <CheckBox
            disable
            defaultValue={marital === 0 && true}
            onChange={value => {
              if (value) {
                setMarital(0);
                setMaritalValue('تعیین نشده');
              }
            }}
          />
          <div className="fill-profile__form__title">
            همه
          </div>
        </div>
      </div>

      {/* {marital === 2 && (
        <div className="row">
          <div className="row" style={{ marginLeft: 16 }}>
            <CheckBox
              disable
              defaultValue={children === 1 && true}
              onChange={value => {
                if (value) {
                  setChildren(1);
                  setChildrenCount(null);
                  setMaritalValue('متاهل بدون فرزند');
                }
              }}
            />
            <div className="fill-profile__form__title">
              بدون فرزند
            </div>
          </div>

          <div className="row">
            <CheckBox
              disable
              defaultValue={children === 2 && true}
              onChange={value => value && setChildren(2)}
            />
            <div className="fill-profile__form__title">
              دارای فرزند
            </div>
          </div>
        </div>
      )} */}

      {/* {children === 2 && (
        <React.StrictMode>
          <div className="fill-profile__form__title">
            تعداد فرزند
          </div>
          <input
            placeholder="اختیاری"
            type="number"
            className="fill-profile__form__input"
            value={childrenCount}
            onChange={e => setChildrenCount(e.target.value)}
          />
        </React.StrictMode>
      )} */}

      <div
        className={`fill-profile__form__button ${handleActivateSubmit() && 'active'}`}
        onClick={() => handleSubmitForm()}
      >
        <div className="fill-profile__form__button__shape">
          <SVG svg="bottom-shape" color={handleActivateSubmit() ? '#21bbe1' : '#b3bdcc'} />
        </div>
        ثبت
        <div className="fill-profile__form__button__star">
          <SVG svg="profile-star" />
        </div>
      </div>
    </div>
  );
}

export default FillCustomMarital;
