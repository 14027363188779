import { SVG } from "components";

export const Tabs = ({ activeTab, setActiveTab, items }) => {
    return (
        <div className="orders__tab">
            {items.map((item, index) => (
                <div
                    className={`orders__tab__item ${
                        activeTab === index + 1 && "active"
                    }`}
                    onClick={() => setActiveTab(index + 1)}
                >
                    {item.icon !== undefined && <SVG svg={item.icon} />}
                    {item.title}
                </div>
            ))}
            <div
                className={`orders__tab__toggle`}
                style={{
                    right: `${(100 / items.length) * (activeTab - 1)}%`,
                    width: `${100 / items.length}%`,
                }}
            />
        </div>
    );
};

//TODO : inja active tab ro avaz kardam
