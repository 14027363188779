import { useEffect, useState } from "react";
import { Fade, PageTopbar, SVG, Card, Hint, Selectbox } from "components";
import { useParams, useHistory } from "react-router-dom";
import { farsiDigitToEnglish } from "utils/Functions";

export const BuyInternetContainer = () => {

  const [userPhoneNumber, setUserPhoneNumber] = useState("09");
  const [packages, setPackages] = useState([
    { title: "package1", id: 1 },
    { title: "package2", id: 2 },
    { title: "package3", id: 3 },
    { title: "package4", id: 4 },
  ]);

  const handleEnterPhoneNumber = (phoneNumber) => {
    if (phoneNumber.length >= 12) return;
    else setUserPhoneNumber(phoneNumber);
  };

  return (
    <Fade>
      <PageTopbar title="خرید اینترنت" />
      <Card>
        <div className="buy-internet__title">شماره تلفن</div>
        <div className="buy-internet__phoneNum">
          <input className="input"
            dir="auto"
            type="tel"
            placeholder="125550012"
            value={`09${userPhoneNumber.substr(2)}`}
            onChange={(e) =>
              handleEnterPhoneNumber(farsiDigitToEnglish(e.target.value))
            } />
        </div>
        <div className="buy-internet__operator-content">
          <div className="buy-internet__operator-content__item"></div>
          <div className="buy-internet__operator-content__item"></div>
          <div className="buy-internet__operator-content__item"></div>
          <div className="buy-internet__operator-content__item"></div>
          <div className="buy-internet__operator-content__item"></div>
          <div className="buy-internet__operator-content__item"></div>
        </div>
        <div className="buy-internet__operator-content__selectBox">
          <Selectbox 
           placeholder="انتخاب کنید" 
            data={packages}

            onChange={
              (val)=>{
                console.log(val); 
              }
            }
            onChangeString={
              (val)=>{      
              }
            }
          />
        </div>
        <div></div>

      </Card>
    </Fade>
  );
};
