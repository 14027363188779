import React, { useEffect, useState, useMemo } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useHistory, useParams } from "react-router-dom";
import {
    addFavoriteAPI,
    addToCartAPI,
    decrementCartAPI,
    getProductAPI,
    isFavriteAPI,
    removeFromCartAPI,
} from "../../utils/Services/API";
import { cloneDeep, find, sortBy } from "lodash";
import toast from "react-hot-toast";
import moment from "jalali-moment";
import {
    CreateComment,
    Fade,
    Comments,
    Compare,
    Gallery,
    Share,
    Spinner,
    SVG,
    Card,
    Dots,
    TokenPrice,
    Lowprice,
    Modal,
    PageTopbar,
} from "components";
import ProductImage from "../../assets/images/product.png";
import { currency, transformPrice } from "utils/Functions";
import { useCart, useUser } from "hooks";

import {
    KvInfo,
    TopBar,
    Cover,
    Variation,
    Tab,
} from "../ProductContainer/dependencies";
import { confirmProductAPI } from "../../utils/Services/OnlineStore/Operator/ConfirmProduct";

export const ProductDetail = () => {
    const user_token = localStorage.getItem("user_token");
    const {
        userState: { user },
    } = useUser();
    const {
        cartState: { cart },
        getCartDataFromApi,
        setCartData,
        checkProductWithCart,
    } = useCart();
    const { id } = useParams<any>();
    const [productDetail, setProductDetail] = useState<any>({});
    const [productImages, setProductImages] = useState([]);
    const [productVariations, setProductVariations] = useState([]);
    const [productColors, setProductColors] = useState([]);
    const [productFeatures, setProductFeatures] = useState([]);
    const [productComments, setproductComments] = useState([]);
    const [activeReplyOpened, setActiveReplyOpened] = useState<number>(0);
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [showShare, setShowShare] = useState(false);
    const [showCompare, setShowCompare] = useState(false);
    const [activeVariation, setActiveVariation] = useState<any>({});
    const [activeColor, setActiveColor] = useState(0);
    const [activeWarranty, setActiveWarranty] = useState(0);
    const [isFavorite, setIsFavorite] = useState(false);
    const [currImage, setCurrImage] = useState(false);
    const [showCommentDetail, setShowCommentDetail] = useState(false);
    const [parentCommentForReplies, setParentCommentForReplies] =
        useState(null);
    const [showComment, setShowComment] = useState(false);
    // const [existOnCart, setExistOnCart] = useState<any>(false);
    const [counterLoading, setCounterLoading] = useState(false);
    const [activeTab, setActiveTab] = useState("description");
    const [showVariations, setShowVariations] = useState(false);
    const [showTokenPrice, setShowTokenPrice] = useState(false);
    const [disableInc, setDisableInc] = useState(false);
    const [showKvInfo, setShowKvInfo] = useState(false);
    const [showKvInfoPrice, setShowKvInfoPrice] = useState(0);
    const [tokenPriceType, setTokenPriceType] = useState("");
    const [seller, setSeller] = useState("");
    const [submitLoading, setSubmitLoading] = useState(false);
    const [rejectMessage, setRejectMessage] = useState("");
    const [rejectModal, setRejectModal] = useState<boolean>(false);

    const [userPrice, setUserPrice] = useState({
        normal: 0,
        sevenStar: 0,
        captain: 0,
        citizen: 0,
    });

    const [pid, setPid] = useState(-1);

    let existOnCart = checkProductWithCart(activeVariation.id);

    const tabs = [
        {
            type: "description",
            title: "توضیحات",
        },
    ];

    const getProductDetail = () => {
        const APIResult = getProductAPI(id);
        APIResult.then((response) => {
            if (response.status === 404) {
                toast.error("محصولی یافت نشد!");
                history.goBack();
                return;
            }
            console.log(response.data);
            setProductDetail(response.data);
            setPid(response.data.pid);
            setUserPrice({
                normal: response.data.defaultPrice[1],
                sevenStar: response.data.defaultPrice[4],
                captain: response.data.defaultPrice[0],
                citizen: response.data.defaultPrice[5],
            });

            if (response.data.variation.length > 0) {
                const sortedVariations = sortBy(
                    response.data.variation,
                    (variation) => {
                        return Number(variation.price);
                    }
                );

                for (let index = 0; index < sortedVariations.length; index++) {
                    if (
                        sortedVariations[index].available === 1 &&
                        (sortedVariations[index].detail.IsStock ||
                            sortedVariations[index].detail.IsStock !== null)
                    ) {
                        setActiveVariation(sortedVariations[index]);
                        break;
                    } else if (
                        sortedVariations.length - 1 === index &&
                        sortedVariations[index].available === 1
                    ) {
                        setActiveVariation(sortedVariations[0]);
                    }
                }
                // .forEach((variation) => {
                //   console.log(variation);
                // });
            }
            setproductComments(response.data.comment);
            setProductVariations(response.data.variation);
            setProductFeatures(response.data.features);
            setProductColors(response.data.productColor);
            setProductImages(response.data.image);
            setSeller(response.data.seller);
        }).finally(() => setLoading(false));
    };

    const handleSetVariation = (type, id) => {
        if (type === "color") {
            var res = { id: 0 };

            productVariations.forEach((variation, index) => {
                if (
                    Number(variation.color_id) === id &&
                    res.id === 0 &&
                    variation.available === 1 &&
                    variation.detail.IsStock !== null
                ) {
                    res = variation;
                    return;
                } else if (
                    index === productVariations.length - 1 &&
                    variation.available === 1
                ) {
                    productVariations.forEach((variation, index) => {
                        if (Number(variation.color_id) === id && res.id === 0) {
                            res = variation;
                            return;
                        }
                    });
                }
            });
            if (res.id !== 0) setActiveVariation(res);
        } else if ((type = "warranty")) {
            var res = { id: 0 };

            productVariations.forEach((variation) => {
                if (
                    Number(variation.color_id) ===
                        Number(activeVariation.color_id) &&
                    res.id === 0
                ) {
                    if (Number(variation.waranty_id) === id) {
                        res = variation;
                        return;
                    }
                }
            });
            if (res.id !== 0) setActiveVariation(res);
        }
    };

    const handleShowVariations = () => {
        let variationRes = [];
        productVariations.forEach((variation) => {
            if (
                Number(variation.color_id) === Number(activeVariation.color_id)
            ) {
                variationRes.push(variation);
            }
        });
        return variationRes;
    };

    const handleAvailableVariation = (variation) => {
        if (
            variation.price === undefined ||
            variation.detail.IsStock === null ||
            variation.available !== 1
        ) {
            return false;
        } else {
            return true;
        }
    };

    const handleShowProductInfo = () => {
        switch (activeTab) {
            case "description":
                return (
                    <div className="product__description">
                        {productDetail.description?.split("\n")?.map((str) => (
                            <div>{str}</div>
                        ))}
                    </div>
                );
            default:
                break;
        }
    };

    const confirmHandler = (status) => {
        setSubmitLoading(true);
        confirmProductAPI(id, status, rejectMessage)
            .then((res) => {
                if (res.status === 200) {
                    if (status === 1) {
                        toast.success("محصول با موفقیت تائید شد");
                        history.push("/operator-management");
                    }
                    if (status === 0) {
                        toast.success("محصول با موفقیت رد شد");
                        setRejectModal(false);
                        history.push("/operator-management");
                    }
                }
            })
            .catch((err) => {
                toast.error("خطایی رخ داد");
            })
            .finally(() => setSubmitLoading(false));
    };

    useEffect(() => {
        checkProductWithCart(activeVariation.id);
    }, [activeVariation]);

    useEffect(() => {
        if (user_token === null || user_token === undefined) {
            toast("برای مشاهده‌ی قیمت شما، ابتدا وارد شوید.");
            history.push("/login");
            return;
        }
        history.replace(`/operator-product/${id}`);
        getProductDetail();
    }, []);

    return (
        <div className="product wrapper-fix">
            <PageTopbar title={"جزئیات محصول"} showKv={false} />
            <Spinner active={loading} color="dark" />
            <Gallery
                active={currImage}
                currImage={currImage}
                images={productImages}
                setShow={(show) => setCurrImage(show)}
            />
            {/* <Compare
        active={showCompare}
        setShow={(show) => setShowCompare(show)}
        productDetail={productDetail}
        variation={activeVariation}
      /> */}
            <Comments
                active={showCommentDetail}
                setShow={(show) => setShowCommentDetail(show)}
                parentComment={parentCommentForReplies}
                comments={productComments}
                productId={id}
            />
            <CreateComment
                active={showComment}
                setShow={(show) => setShowComment(show)}
                replyData={{
                    productId: id,
                    parent_id: null,
                }}
            />
            <TokenPrice
                active={showTokenPrice}
                setClose={(close) => setShowTokenPrice(!close)}
                data={{
                    variation: activeVariation,
                }}
                type={tokenPriceType}
            />
            <KvInfo
                status={showKvInfo}
                toggleStatus={setShowKvInfo}
                price={showKvInfoPrice}
            />

            <Fade>
                <Card>
                    <Cover
                        data={productImages}
                        setCurrentImage={setCurrImage}
                    />
                    <div className="product__title">{productDetail.title}</div>
                </Card>

                <Variation
                    colors={productColors}
                    active={activeVariation}
                    setActiveVariation={setActiveVariation}
                    variationStatus={showVariations}
                    toggleShowVariation={setShowVariations}
                    setVariation={handleSetVariation}
                    productVariations={productVariations}
                    toggleShowTokenPrice={setShowTokenPrice}
                    setTokenPriceType={setTokenPriceType}
                    handleShowVariations={handleShowVariations}
                    handleAvailableVariation={handleAvailableVariation}
                    setShowKvInfo={setShowKvInfo}
                    setShowKvInfoPrice={setShowKvInfoPrice}
                    seller={seller}
                    normalUserPrice={userPrice.normal}
                    sevenStarUserPrice={userPrice.sevenStar}
                    captainUserPrice={userPrice.captain}
                    citizenUserPrice={userPrice.citizen}
                    pid={pid}
                />

                <Tab
                    tabs={tabs}
                    activeTab={activeTab}
                    toggleActiveTab={setActiveTab}
                />

                <div className="product__info">{handleShowProductInfo()}</div>

                <div className="product__bottom1">
                    <div className={`product__bottom1__button`}>
                        <div
                            className="button"
                            onClick={() => confirmHandler(1)}
                        >
                            تائید
                        </div>
                        <div
                            className="button red"
                            onClick={() => setRejectModal(true)}
                        >
                            رد محصول
                        </div>
                    </div>
                </div>
            </Fade>

            <Modal active={rejectModal} close={() => setRejectModal(false)}>
                <div className={"reject-modal"}>
                    <div className={"mb-10"}>
                        <div className={"reject-modal__title mb-4"}>
                            لطفا دلیل رد کردن محصول را بنویسید:
                        </div>
                        <textarea
                            className="textarea"
                            placeholder="دلیل رد محصول"
                            onChange={(e) => setRejectMessage(e.target.value)}
                            value={rejectMessage}
                            rows={4}
                            style={{ marginTop: "8px" }}
                        />
                    </div>

                    <div className={"reject-modal__actions"}>
                        <div
                            onClick={() => {
                                if (rejectMessage) {
                                    !submitLoading && confirmHandler(0);
                                } else {
                                    toast.error(
                                        "لطفا دلیل رد محصول را وارد نمایید"
                                    );
                                }
                            }}
                            className={"button red"}
                        >
                            {submitLoading ? <Dots color="light" /> : "رد"}
                        </div>
                        <div
                            onClick={() => setRejectModal(false)}
                            className={"button disable"}
                        >
                            بازگشت
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
};
