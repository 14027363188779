import {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {Card, Fade, Input, PageTopbar, SVG} from "../../components";
import mci from '../../assets/images/operators/mci.png'
import irancell from '../../assets/images/operators/irancell.png'
import rightel from '../../assets/images/operators/rightel.png'

export const ChargeContainer = () => {
  const history = useHistory();
  const [phoneNumber, setPhoneNumber] = useState('');

  useEffect(() => {
    history.replace("/charge");
  }, []);

  return (
    <Fade>
      <PageTopbar title={'خرید شارژ'} showKv={false}/>
      <Card>
        <div className="title">
          شماره تلفن
        </div>
        <input
          type={'number'}
          className={'input'}
          placeholder={'**********0913'}
          value={phoneNumber}
          onChange={event => setPhoneNumber(event.target.value)}
        />
        <div className="charge__operators">
          {/*<div className="charge__operator">*/}
          {/*  <img src={mci} alt="mci"/>*/}
          {/*</div>*/}
          {/*<div className="charge__operator">*/}
          {/*  <img src={irancell} alt="irancell"/>*/}
          {/*</div>*/}
          {/*<div className="charge__operator">*/}
          {/*  <img src={rightel} alt="rightel"/>*/}
          {/*</div>*/}

            <img className={'charge__operator-pic'} src={mci} alt="mci"/>
            <img className={'charge__operator-pic'} src={irancell} alt="irancell"/>
            <img className={'charge__operator-pic'} src={rightel} alt="rightel"/>
        </div>
        <div className="title">
          قیمت
        </div>
        <input
          type={'number'}
          className={'input'}
          placeholder={'انتخاب کنید'}
        />
      </Card>
    </Fade>
  );
};