import { Input } from "components/Inputs";
import { SVG } from "components/SVG";
import { useState, useEffect } from "react";
import "./CreateAddress.scss";
import { Selectbox } from "components";
import toast from "react-hot-toast";
import { getCitiesAPI, getStatesAPI } from "../../utils";
import { useUser } from "hooks";
import  NeshanMap  from 'react-neshan-map-leaflet';
import MarkerIcon from "../../assets/images/marker.svg";

export const CreateAddress = ({ active, setClose, onSubmit, isFullScreen, transferee = false }) => {
  const user_token = localStorage.getItem("user_token");
  const { userState: { user } } = useUser();
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [step, setStep] = useState(1);
  const [title, setTitle] = useState("");
  const [addressValue, setAddressValue] = useState("");
  const [plate, setPlate] = useState("");
  const [homeNumber, setHomeNumber] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [stateId, setStateId] = useState(-1);
  const [stateValue, setStateValue] = useState("");
  const [cityId, setCityId] = useState(-1);
  const [cityValue, setCityValue] = useState("");
  const [name, setName] = useState("");
  const [familyName, setFamilyName] = useState("");
  const [phoneNumber, setPhoneNubmer] = useState("");
  const [latlng, setLatlng] = useState([32.6749301, 51.6512963]);

  console.log(latlng);

  const [map, setMap] = useState(null);
  const [marker, setMarker] = useState(null);

  const getStates = () => {
    const APIResult = getStatesAPI(1);
    APIResult.then((response) => {
      setStates(response.data);
    });
  };

  const getCities = (entryState) => {
    const APIResult = getCitiesAPI(entryState);
    APIResult.then((response) => {
      setCities(response.data);
    });
  };
  console.log(stateId);
  console.log(cityId);
  const handleClickButton = () => {
    if (step === 1) {
      if (title === "") {
        toast.error("لطفا عنوان را وارد کنید");
        return;
      }
      if (addressValue === "") {
        toast.error("لطفا آدرس خود را وارد کنید");
        return;
      }
      // if (plate === "") {
      //   toast.error("لطفا پلاک خود را وارد کنید");
      //   return;
      // }
      // if (homeNumber === "") {
      //   toast.error("لطفا واحد را وارد کنید");
      //   return;
      // }
      if (postalCode === "") {
        toast.error("لطفا کد پستی خود را وارد کنید");
        return;
      } else if (postalCode.length !== 10) {
        toast.error("کد پستی خود را به درستی وارد کنید");
        return;
      }
      if (stateId === -1 || stateId === 0) {
        toast.error("لطفا استان خود را انتخاب کنید");
        return;
      }
      if (cityId === -1 || cityId === 0) {
        toast.error("لطفا شهر خود را انتخاب کنید");
        return;
      }
      setStep(2);
    } else if (step === 2) {
      if (name === "") {
        toast.error("لطفا نام خود را وارد کنید");
        return;
      }
      if (familyName === "") {
        toast.error("لطفا نام خانوادگی خود را وارد کنید");
        return;
      }
      if (phoneNumber === "") {
        toast.error("لطفا شماره تلفن همراه خود را وارد کنید");
        return;
      }
      
      setStep(3);
    }
    else if (step === 3) {
      if (!latlng[0] || !latlng[1]) {
        toast.error("لطفا موقعیت خود را وارد کنید");
        return;
      }
      handleSubmitNewAddress();
    }

    // if (step === 1) {
    //   if (
    //     title === "" ||
    //     addressValue === "" ||
    //     plate === "" ||
    //     homeNumber === "" ||
    //     postalCode === "" ||
    //     cityId === -1 ||
    //     cityId === 0 ||
    //     stateId === -1 ||
    //     stateId === 0
    //   ) {
    //     toast.error("لطفا همه ی مقادیر را وارد کنید");
    //     return;
    //   } else {
    //     setStep(2);
    //   }
    // } else if (step === 2) {
    //   if (name === "" || familyName === "" || phoneNumber === "") {
    //     toast.error("لطفا همه ی مقادیر را وارد کنید");
    //     return;
    //   } else {
    //     setStep(3);
    //   }
    // } else if (step === 3) {
    //   if (!latlng[0] || !latlng[1]) {
    //     toast.error("لطفا همه ی مقادیر را وارد کنید");
    //     return;
    //   } else {
    //     handleSubmitNewAddress();
    //   }
    // }
  };

  const titleUpdate = (value) => {
    setTitle(value);
  };

  const addressValueUpdate = (value) => {
    setAddressValue(value);
  };

  const plateUpdate = (value) => {
    setPlate(value);
  };

  const homeNumberUpdate = (value) => {
    setHomeNumber(value);
  };

  const nameUpdate = (value) => {
    setName(value);
  };

  const familyNameUpdate = (value) => {
    setFamilyName(value);
  };

  const phoneNumberUpdate = (value) => {
    setPhoneNubmer(value);
  };

  const postalCodeUpdate = (value) => {
    setPostalCode(value);
  };

  const firstStepInputs = (
    <div className="create-address__body">
      <div className="create-address__input">
        <div className="create-address__input__title">
          <span className="required" />
          عنوان
        </div>
        <Input value={title} onChange={titleUpdate} />
      </div>

      <div className="row g-12">
        <div className="create-address__input">
          <div className="create-address__input__title">
            <span className="required" />
            استان
          </div>
          <Selectbox
            data={states}
            placeholder="انتخاب استان"
            onChange={(value) => {
              setStateId(value);
              getCities(value);
            }}
            onChangeString={() => { }}
            defaultValue={stateValue}
          />
        </div>
        <div className="create-address__input">
          <div className="create-address__input__title">
            <span className="required" />
            شهر
          </div>
          <Selectbox
            data={cities}
            placeholder="انتخاب شهر"
            onChangeString={() => { }}
            onChange={(value) => {
              setCityId(value);
            }}
            disabled={stateId === 0 && true}
            defaultValue={cityValue}
          />
        </div>
      </div>

      <div className="create-address__input">
        <div className="create-address__input__title">
          <span className="required" />
          نشانی
        </div>
        <Input value={addressValue} onChange={addressValueUpdate} />
      </div>
      <div className="row g-12">
        {/* <div className="create-address__input">
          <div className="create-address__input__title">پلاک</div>
          <Input value={plate} onChange={plateUpdate} />
        </div>
        <div className="create-address__input">
          <div className="create-address__input__title">واحد</div>
          <Input value={homeNumber} onChange={homeNumberUpdate} />
        </div> */}
        <div className="create-address__input" style={{ minWidth: 145 }}>
          <div className="create-address__input__title">
            <span className="required" />
            کد پستی
          </div>
          <Input value={postalCode} onChange={postalCodeUpdate} type="number" />
        </div>
      </div>
    </div>
  );

  const secondStepInputs = (
    <div className="create-address__body">
      <div className="create-address__input">
        <div className="create-address__input__title">
          <span className="required" />
          نام
        </div>

        <Input value={name} onChange={nameUpdate} />
      </div>
      <div className="create-address__input">
        <div className="create-address__input__title">
          <span className="required" />
          نام خانوادگی
        </div>
        <Input value={familyName} onChange={familyNameUpdate} />
      </div>
      <div className="create-address__input">
        <div className="create-address__input__title">
          <span className="required" />
          شماره تماس
        </div>
        <Input value={phoneNumber} onChange={phoneNumberUpdate} type="number" />
      </div>
    </div>
  );

  const thirdStepInputs = (
    <div className="create-address__body">
      <NeshanMap
        options={{
          key: "web.0cde50803fc74c35934dc123292ee607",
          maptype: "dreamy-gold",
          poi: true,
          traffic: false,
          center: latlng,
          zoom: 15,
        }}
        onInit={(L, myMap) => {
          setMap(myMap);
          let marker = L.marker(latlng, {
            draggable: true,
            autoPan: true,
            autoPanSpeed: 6,
            icon: L.icon({ iconUrl: MarkerIcon, iconSize: [24, 34] }),
          })
            .addTo(myMap)
            .bindPopup("نشانگر شما");
          setMarker(marker);
          myMap.on("click", function (e) {
            setLatlng([e.latlng.lat, e.latlng.lng]);
            // myMap.panTo(e.latlng)
            marker.setLatLng(e.latlng);
          });
        }}
      />
    </div>
  );

  const showInputs = () => {
    switch (step) {
      case 1:
        return firstStepInputs;
      case 2:
        return secondStepInputs;
      case 3:
        return thirdStepInputs;
    }
  };

  const handleShowTitle = () => {
    switch (step) {
      case 1:
        return "آدرس تحویل گیرنده";
      case 2:
        return "مشخصات تحویل گیرنده";
      case 3:
        return "موقعیت جغرافیایی";
    }
  };

  const handleSubmitNewAddress = () => {
    onSubmit(
      active.address && active.address !== null ? active.address.id : "",
      {
        title,
        firstname: name,
        lastname: familyName,
        address: addressValue,
        lat: latlng[0],
        lng: latlng[1],
        postalCode,
        stateId: stateId,
        cityId: cityId,
        buildingNumber: plate,
        apartmentNumber: homeNumber,
        mobile: phoneNumber,
      },
      active.address && active.address !== null ? true : false
    );
    setClose(false);
    setStateValue("");
    setCityValue("");
    resetAllValues();
  };

  const handleClose = () => {
    setStateValue("");
    setCityValue("");
    resetAllValues();
    setClose(false);
  };

  const resetAllValues = () => {
    setStep(1);
    setTitle("");
    setAddressValue("");
    setPlate("");
    setHomeNumber("");
    setPostalCode("");
    setStateId(-1);
    setCityId(-1);
    setName("");
    setFamilyName("");
    setPhoneNubmer("");
  };

  useEffect(() => {
    getStates();
  }, []);

  useEffect(() => {
    if (transferee)
      setStep(2);
    else
      setStep(1);

    const currAddress = active.address;
    if (currAddress && currAddress !== null) {
      setTitle(currAddress.title);
      setName(currAddress.firstname);
      setFamilyName(currAddress.lastname);
      setAddressValue(currAddress.address);
      setPostalCode(currAddress.postalCode);
      setPlate(currAddress.buildingNumber);
      setHomeNumber(currAddress.apartmentNumber);
      setPhoneNubmer(currAddress.mobile);
      setStateId(currAddress.state?.id);
      setCityId(currAddress.city?.id);
      setStateValue(currAddress.state?.name);
      setCityValue(currAddress.city?.name);
      setLatlng([+currAddress.lat, +currAddress.lng]);
    } else {
      if (user) {
        setPhoneNubmer(user.mobile_number);
        setName(user.name == null ? "" : user.name);
        setFamilyName(user.lastname == null ? "" : user.lastname);
      }
    }
  }, [active]);

  return (
    <div
      className={`create-address ${active.active && "active"} ${isFullScreen && "fullScreen"
        }`}
    >
      <div className="create-address__wrapper">
        <div className="create-address__header">
          <div className="create-address__header__title">
            {step !== 1 && (
              <div
                className="create-address__header__icon back "
                onClick={() => setStep(step - 1)}
              >
                <SVG svg="arrow" />
              </div>
            )}
            {handleShowTitle()}
          </div>
          <div
            className="create-address__header__icon"
            onClick={() => handleClose()}
          >
            <SVG svg="cross" />
          </div>
        </div>
        {showInputs()}

        <div
          className="create-address__button"
          onClick={() => handleClickButton()}
        >
          <div className="create-address__shape">
            <SVG svg="bottom-shape" color="#21BBE1" />
          </div>

          <div className="create-address__button__title">
            ثبت جزئیات و ادامه
          </div>

          <div className="create-address__button__icon">
            <SVG svg="arrow-big-white" />
          </div>
        </div>
      </div>
    </div>
  );
};
