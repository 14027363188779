import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
    Fade,
    TopBar,
    Setting,
    Header,
    Card,
    Spinner,
    ProfileItems,
    Bank,
    ProfileSlider,
    Modal,
    SVG,
    Dots,
} from "components";
import {
    getUSerStarsAPI,
    deleteStoreAPI,
    unseenMessagesCountAPI,
    getUserStoreAPI,
} from "utils";
import { useUser } from "hooks";
import { toast } from "react-hot-toast";

const Profile = () => {
    const {
        userState: { user },
        getUserDataFromApi,
    } = useUser();
    const [loading, setLoading] = useState({
        profile: true,
        stars: true,
        hint: true,
        adminStore: true,
        unseenMessages: true,
    });
    const [activeMenu, setActiveMenu] = useState(false);
    const [userStars, setUserStars] = useState({});

    const [introduceStoreModal, setIntroduceStoreModal] = useState(false);
    const [introduceStoreName, setIntroduceStoreName] = useState("شما");

    const [acceptDelete, setAcceptDelete] = useState(false);
    const [buttonDeleteLoading, setButtonDeleteLoading] = useState(false);

    const [myShops, setMyShops] = useState([]);
    const [unseenMessages, setUnseenMessages] = useState(0);

    const history = useHistory();

    const newMessageCount = () => {
        unseenMessagesCountAPI()
            .then((res) => {
                if (res?.status === 200) {
                    setUnseenMessages(res?.data);
                }
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setLoading((prevState) => ({
                    ...prevState,
                    unseenMessages: false,
                }));
            });
    };

    const getMyShops = () => {
        getUserStoreAPI()
            .then((response) => {
                setMyShops([response.data]);
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setLoading((prevState) => ({
                    ...prevState,
                    adminStore: false,
                }));
            });
    };

    const getStarsDetail = () => {
        const api = getUSerStarsAPI();
        api.then((response) => {
            setUserStars(response.data.profile);
        }).finally(() => {
            setLoading((prevState) => ({
                ...prevState,
                stars: false,
            }));
        });
    };

    useEffect(() => {
        history.replace("/profile");
        getStarsDetail();
        getMyShops();
        newMessageCount();
    }, []);

    useEffect(() => {
        if (user.detail) {
            setLoading((prevState) => ({
                ...prevState,
                profile: false,
            }));
        }
    }, [user]);

    useEffect(() => {
        let tempCheckUserIntroduceStore =
            localStorage.getItem("introduceStore");
        setIntroduceStoreName(localStorage.getItem("storeName"));
        if (tempCheckUserIntroduceStore == "true") {
            localStorage.setItem("introduceStore", "false");
            setIntroduceStoreModal(true);
        }
    }, []);

    const deleteIntroduceStore = () => {
        setButtonDeleteLoading(true);
        deleteStoreAPI()
            .then((response) => {
                if (response.data.status == 200) {
                    toast.success("فروشگاه با موفقیت حذف شد");
                }
            })
            .catch((error) => {
                toast.error("حذف فروشگاه یا خطا مواجه شد");
            })
            .finally(() => {
                setAcceptDelete(false);
                setIntroduceStoreModal(false);
                setButtonDeleteLoading(false);
            });
    };

    return (
        <Fade>
            <Spinner
                active={
                    loading.stars ||
                    loading.profile ||
                    loading.adminStore ||
                    loading.unseenMessages ||
                    loading.hint
                }
                color="dark"
            />
            <Setting active={activeMenu} setClose={setActiveMenu} />
            <TopBar toggleMenu={setActiveMenu} />
            <Card>
                <Header
                    getUserFunc={getUserDataFromApi}
                    getUserStars={getStarsDetail}
                    starDetail={userStars}
                    loading={loading.hint}
                    setLoading={setLoading}
                />
                <Modal
                    active={introduceStoreModal}
                    close={() => {
                        setIntroduceStoreModal(false);
                    }}
                >
                    <p style={{ fontSize: "12px" }}>به کارتک خوش اومدی</p>
                    <p style={{ fontSize: "12px", textAlign: "justify" }}>
                        با این شماره قبلا یه فروشگاه با نام{" "}
                        <span style={{ fontWeight: "bold" }}>
                            {introduceStoreName}
                        </span>{" "}
                        در شهر کارتک ثبت شده . برای دسترسی به پنل مدیریت و تکمیل
                        اطلاعات از کلید نارنجی (
                        <span
                            className="introduce-store-modal"
                            style={{ stroke: "#fd6547" }}
                        >
                            <SVG svg="map-store" />
                        </span>
                        ) روی نقشه شهر استفاده کن.{" "}
                    </p>
                    <div className="row m15">
                        <button
                            className="button"
                            style={{ fontFamily: "inherit" }}
                            onClick={() => {
                                history.push("/mycity");
                            }}
                        >
                            تکمیل اطلاعات
                        </button>
                        <button
                            className="button red"
                            style={{ fontFamily: "inherit" }}
                            onClick={() => {
                                setAcceptDelete(true);
                                setIntroduceStoreModal(false);
                            }}
                        >
                            حذف فروشگاه
                        </button>
                    </div>
                </Modal>

                <Modal
                    active={acceptDelete}
                    close={() => {
                        setAcceptDelete(false);
                    }}
                >
                    <p style={{ fontSize: "16px" }}>
                        آیا از حذف فروشگاه مطمئن هستید ؟
                    </p>
                    <p
                        style={{
                            fontSize: "14px",
                            textAlign: "justify",
                            fontWeight: "900",
                            color: "#eb4d4c",
                        }}
                    >
                        توجه : این عمل غیرقابل بازگشت است.
                    </p>
                    <div className="row m15 g-6">
                        <button
                            className="button"
                            style={{ fontFamily: "inherit" }}
                            onClick={() => {
                                setAcceptDelete(false);
                                setIntroduceStoreModal(true);
                            }}
                        >
                            لغو
                        </button>
                        <button
                            className="button red"
                            style={{ fontFamily: "inherit" }}
                            disabled={buttonDeleteLoading}
                            onClick={() => {
                                deleteIntroduceStore();
                            }}
                        >
                            {buttonDeleteLoading ? <Dots /> : "حذف"}
                        </button>
                    </div>
                </Modal>

                <ProfileItems myShops={myShops} />
                {/*<Bank />*/}
                {/* <ProfileSlider /> */}
            </Card>
        </Fade>
    );
};

export default Profile;
