import React, { useState, useEffect } from "react";
import { Card, Dots, SVG, Modal } from "components";
import "./checkout.scss";
import BehpardakhtImage from "../../assets/images/behpardakht.png";
import toast from "react-hot-toast";
import {
    deleteUserCartAddress,
    getPaymentTokenAPI,
    getUserAddresses,
    updateCartAddressAPI,
    updateCartGatewayAPI,
    createUserAddressAPI,
    getPaymentUrl,
    updateUserAddressAPI,
    getCartAPI,
} from "utils/Services/API";
import {
    englishToPersianNumber,
    transformPrice,
    currency,
} from "utils/Functions";
import { CreateAddress } from "components/CreateAddress";
import { cloneDeep } from "lodash";
import { useHistory, useParams } from "react-router-dom";
import { useCart, useUser } from "hooks";
import { baseCartDetailAPI } from "../../utils";

const Checkout = ({
    active = false,
    setClose,
    currCart,
    finalPrice = 0,
    fullCartDetails,
    updateCurrCart,
    finalPriceEnd = 0,
}) => {
    const user_token = localStorage.getItem("user_token");
    const {
        setCartData,
        cartState: { cart },
    } = useCart();
    const {
        userState: { user },
    } = useUser();
    const [checkoutStep, setCheckoutStep] = useState(1);
    const [loading, setLoading] = useState(false);
    const [choosedAddress, setChoosedAddress] = useState(-1);
    const [choosedPayment, setChoosedPayment] = useState(-1);
    const [paymentMethodList, setPaymentList] = useState([
        { id: 1, title: "درگاه به‌پرداخت ملت" },
    ]);
    const [addressList, setAddressList] = useState([]);
    const [createAddressActive, setCreateAddressActive] = useState({
        active: false,
        address: null,
    });
    const [shippingPrice, setShippingPrice] = useState(0);
    const [shippingPriceLoading, setShippingPriceLoading] = useState(true);
    const [transferee, setTransferee] = useState(false);
    const { id } = useParams();
    const history = useHistory();

    const [showConflictCartModal, setShowConflictCartModal] = useState(false);
    const [
        showConflictCartModalAndContinue,
        setShowConflictCartModalAndContinue,
    ] = useState(false);
    const [conflictProducts, setConflictProducts] = useState([]);

    const getCart = () => {
        const APIResult = baseCartDetailAPI(fullCartDetails.id);
        APIResult.then((response) => {
            if (response.status === 401 || response.status === 404) {
                setLoading(false);
                return;
            }
            setShippingPrice(+response.data.cart.finalPrice);
            setShippingPriceLoading(false);
            setCartData([response.data.cart]);
            setLoading(false);
        });
    };

    const getUserAddressList = () => {
        const result = getUserAddresses(user_token);
        result
            .then((response) => {
                if (response.data.status >= 200 && response.data.status < 300) {
                    const addresses = response.data.data.address;
                    setAddressList(addresses);
                    if (addresses.length > 0) {
                        if (currCart[0].address_id !== null) {
                            setChoosedAddress(-1);
                            // setChoosedAddress(cart[0].user.Address);
                        }
                    }
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const updateCartAddress = async () => {
        setLoading(true);

        if (currCart[0].address_id === null) {
            const response = await updateCartAddressAPI(
                user_token,
                fullCartDetails.id,
                choosedAddress.id
            );

            setConflictProducts(response?.data?.data?.itemDeleted);

            if (response.data.msg === "this item not support for you") {
                setLoading(false);
                setShowConflictCartModal(true);
                return;
            }

            if (
                response.data.msg === "address cart updates" &&
                response.data.data.itemDeleted.length > 0
            ) {
                setLoading(false);
                setShowConflictCartModalAndContinue(true);
                return;
            }

            if (response.status >= 200 && response.status < 300) {
                setCheckoutStep(2);
                updateCartGateWay();
                getCart();
                setLoading(false);
            } else {
                const deleteResponse = await deleteUserCartAddress(
                    user_token,
                    fullCartDetails.id
                );

                if (
                    deleteResponse.status >= 200 &&
                    deleteResponse.status < 300
                ) {
                    const updateResponse = await updateCartAddressAPI(
                        user_token,
                        fullCartDetails.id,
                        choosedAddress.id
                    );

                    if (
                        updateResponse.status >= 200 &&
                        updateResponse.status < 300
                    ) {
                        setCheckoutStep(2);
                        updateCartGateWay();
                        getCart();
                        setLoading(false);
                    } else {
                        toast.error("مشکلی در ثبت آدرس پیش آمده.");
                        setLoading(false);
                    }
                }
                // toast.error("مشکلی در ثبت آدرس پیش آمده.");
                setLoading(false);
            }
        } else {
            if (Number(choosedAddress.id) !== Number(currCart[0].address_id)) {
                const deleteResponse = await deleteUserCartAddress(
                    user_token,
                    fullCartDetails.id
                );

                if (
                    deleteResponse.status >= 200 &&
                    deleteResponse.status < 300
                ) {
                    const updateResponse = await updateCartAddressAPI(
                        user_token,
                        fullCartDetails.id,
                        choosedAddress.id
                    );

                    setConflictProducts(
                        updateResponse?.data?.data?.itemDeleted
                    );

                    if (
                        updateResponse.data.msg ===
                        "this item not support for you"
                    ) {
                        setLoading(false);
                        setShowConflictCartModal(true);
                        return;
                    }

                    if (
                        updateResponse.data.msg === "address cart updates" &&
                        updateResponse.data.data.itemDeleted.length > 0
                    ) {
                        setLoading(false);
                        setShowConflictCartModalAndContinue(true);
                        return;
                    }

                    if (
                        updateResponse.status >= 200 &&
                        updateResponse.status < 300
                    ) {
                        setCheckoutStep(2);
                        updateCartGateWay();
                        getCart();
                        setLoading(false);
                    } else {
                        toast.error("مشکلی در ثبت آدرس پیش آمده.");
                        setLoading(false);
                    }
                }
            } else {
                setLoading(false);
                getCart();
                setCheckoutStep(2);
                updateCartGateWay();
            }
        }
    };

    const updateCartGateWay = async () => {
        try {
            const response = await updateCartGatewayAPI(
                user_token,
                fullCartDetails.id,
                choosedPayment.id
            );
            if (response.status >= 200 && response.status < 300) {
                // console.log("update gateway", response);
            } else {
                console.log("error", response);
            }

            // TO DO : delete current if changing the gateway
        } catch (error) {
            console.log(error);
        }
    };

    const getPaymentTokenAndRedirect = async () => {
        setLoading(true);
        try {
            const response = await getPaymentTokenAPI(
                user_token,
                fullCartDetails.id
            );
            if (response.status >= 200 && response.status < 300) {
                if (response.data.data.tk === null) {
                    toast.error("مشکلی از سمت درگاه بانکی رخ داد.");
                    return;
                } else {
                    setCartData({});
                    const paymentURL = getPaymentUrl(
                        fullCartDetails.id,
                        response.data.data.tk
                    );
                    history.push(`/pay?${paymentURL}`);
                    setTimeout(() => {
                        if (history.location.pathname.includes("/pay"))
                            window.location.href = `${paymentURL}`;
                    }, 600);
                }
            }
        } catch (error) {
        } finally {
            setLoading(false);
        }
    };

    const handleBackButton = () => {
        if (checkoutStep === 1) {
            setClose(false);
        } else {
            // if (choosedAddress.id === undefined || choosedAddress === -1) {
            //   toast("آدرس خود را انتخاب نمایید");
            // }
            // else {
            setCheckoutStep(1);
            setShippingPriceLoading(true);
        }
    };

    const handleAddressClick = (address) => {
        setChoosedAddress(address);
    };

    useEffect(() => {
        if (checkoutStep === 2) {
            updateCurrCart();
            getCart();
        }
    }, [checkoutStep]);

    const showAddresses = () =>
        addressList.map((address, index) => (
            <div
                className={`new-checkout__select-item select-address ${
                    Number(address.id) === Number(choosedAddress.id) && "active"
                }`}
                onClick={() => handleAddressClick(address)}
                key={index}
            >
                <div className="row a-c j-sb w-100">
                    <div className="col w-100">
                        <span className="new-checkout__select-item__title row a-c j-sb">
                            {address.title ? address.title : ""}
                            {Number(address.id) ===
                                Number(choosedAddress.id) && (
                                <div
                                    className="new-checkout__address__header__edit"
                                    onClick={() => {
                                        setCreateAddressActive({
                                            active: true,
                                            address: address,
                                        });
                                        setTransferee(false);
                                    }}
                                >
                                    <SVG svg="edit-rec" />
                                </div>
                            )}
                        </span>
                        {address.address}
                    </div>
                </div>
                {Number(address.id) === Number(choosedAddress.id) && (
                    <div className="row a-c select-address__transferee">
                        <div className="row a-c">
                            <div className="row a-c select-address__transferee__title">
                                تحویل گیرنده:
                            </div>
                            {choosedAddress.firstname + " "}
                            {choosedAddress.lastname}
                        </div>
                        <div className="row a-c select-address__transferee__mobile">
                            {choosedAddress.mobile}
                            <div
                                className="new-checkout__address__header__edit"
                                onClick={() => {
                                    setCreateAddressActive({
                                        active: true,
                                        address: address,
                                    });
                                    setTransferee(true);
                                }}
                                style={{ marginRight: 8 }}
                            >
                                <SVG svg="edit-rec" />
                            </div>
                        </div>
                    </div>
                )}
            </div>
        ));

    const handlePaymentClick = (paymentMethod) => {
        setChoosedPayment(paymentMethod);
    };

    const showPayment = () => {
        return paymentMethodList.map((method, index) => {
            return (
                <div
                    className={`new-checkout__select-item ${
                        method.id === choosedPayment.id && "active"
                    }`}
                    onClick={() => handlePaymentClick(method)}
                    key={index}
                >
                    {method.title}
                    <img src={BehpardakhtImage} />
                </div>
            );
        });
    };

    const handleShowTotalPrice = () => {
        if (checkoutStep === 2) {
            return finalPriceEnd;
        } else {
            return finalPrice;
        }
        let totalPrice = 0;
        currCart.forEach((cartItem) => {
            totalPrice +=
                (cartItem.price - cartItem.discount) * Number(cartItem.count);
        });
        totalPrice += Number(shippingPrice);
        // totalPrice += Number(currCart[0].shippingCost);
        return transformPrice(totalPrice);
    };

    const handleButtonClick = () => {
        if (checkoutStep === 1) {
            if (
                Number(choosedAddress.id) === -1 ||
                choosedAddress === undefined ||
                choosedAddress === null ||
                choosedAddress === -1
            ) {
                toast.error("آدرس را انتخاب کنید");
                return;
            } else if (choosedPayment === -1) {
                toast.error("درگاه پرداخت را انتخاب کنید");
            } else {
                updateCartAddress();
            }
        } else {
            getPaymentTokenAndRedirect();
        }
    };

    const handleShowItemPrice = (item) => {
        return transformPrice(Number(item.price) * Number(item.count));
    };

    const handleSubmitAddress = (id, newAddress, isUpdate) => {
        const currAddresses = cloneDeep(addressList);

        if (isUpdate) {
            const APIResult = updateUserAddressAPI(user_token, id, newAddress);
            APIResult.then((response) => {
                if (response.status === 200) {
                    toast.success("آدرس با موفقیت بروزرسانی شد");
                    getUserAddressList();
                    setCreateAddressActive({ active: false, address: null });
                } else {
                    toast.error("مقادیر وارد شده کامل نمی‌باشد.");
                }
            });
            return;
        }

        const APIResult = createUserAddressAPI(user_token, newAddress);
        APIResult.then((response) => {
            if (response.status === 200) {
                toast.success("آدرس جدید ایجاد شد.");
                const newAddresses = currAddresses.concat(newAddress);
                setAddressList(newAddresses);
                getUserAddressList();
            } else {
                toast.error("مقادیر وارد شده کامل نمی‌باشد.");
            }
        });

        setCreateAddressActive({ address: null, active: false });
    };

    useEffect(() => {
        getUserAddressList();
        // if (cart[0].user.Address && cart[0].user.Address !== null) {
        //   setChoosedAddress(cart[0].user.Address);
        // }
    }, []);

    useEffect(() => {
        if (cart[0] === undefined) {
        }
    }, [cart]);

    return (
        <div className={`new-checkout ${active && "active"}`}>
            <div className="new-checkout__info-wrapper">
                <div className="new-checkout__header-wrapper">
                    <div className="new-checkout__header">
                        <div
                            className="new-checkout__back"
                            onClick={() => handleBackButton()}
                        >
                            <SVG svg="arrow-big" />
                        </div>
                        <div className="new-checkout__title">
                            {checkoutStep === 1 && "انتخاب آدرس و درگاه پرداخت"}
                            {checkoutStep === 2 && "فاکتور خرید"}
                        </div>
                    </div>
                </div>
                <div className="new-checkout__body">
                    <div className="new-checkout__order-info">
                        <Card>
                            <div className="new-checkout__address">
                                <div className="new-checkout__address__header">
                                    <div className="new-checkout__address__header__title">
                                        محل تحویل
                                    </div>
                                    <div
                                        className="new-checkout__address__header__edit"
                                        onClick={() => setCheckoutStep(1)}
                                    >
                                        {checkoutStep === 2 && (
                                            <SVG svg="edit-rec" />
                                        )}
                                    </div>
                                </div>
                                {checkoutStep === 2 ? (
                                    <div className="new-checkout__address__chosen">
                                        <span>{choosedAddress.title} </span> :{" "}
                                        {choosedAddress.address}
                                    </div>
                                ) : (
                                    <div className="new-checkout__address__choose">
                                        {addressList &&
                                            addressList.length > 0 &&
                                            showAddresses()}
                                        <div
                                            className="new-checkout__select-item add"
                                            onClick={() => {
                                                setCreateAddressActive({
                                                    active: true,
                                                    address: null,
                                                });
                                                setTransferee(false);
                                            }}
                                        >
                                            <div className="new-checkout__select-item__text">
                                                <SVG svg="add-location" />
                                                افزودن آدرس
                                            </div>
                                            <SVG svg="plus" />
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="new-checkout__payment">
                                <div className="new-checkout__payment__header">
                                    <div className="new-checkout__payment__header__title">
                                        روش پرداخت
                                    </div>
                                    <div
                                        className="new-checkout__payment__header__edit"
                                        onClick={() => setCheckoutStep(1)}
                                    >
                                        {checkoutStep === 2 && (
                                            <SVG svg="edit-rec" />
                                        )}
                                    </div>
                                </div>
                                {checkoutStep === 2 ? (
                                    <div className="new-checkout__payment__chosen">
                                        <div className="new-checkout__payment__image">
                                            <img
                                                src={BehpardakhtImage}
                                                alt=""
                                            />
                                        </div>
                                        <div className="new-checkout__payment__title">
                                            {choosedPayment.title}
                                        </div>
                                    </div>
                                ) : (
                                    <div>{showPayment()}</div>
                                )}
                            </div>
                        </Card>
                    </div>
                </div>
            </div>

            {checkoutStep === 2 && (
                <div className="new-checkout__factor-wrapper">
                    {currCart.map((cart, index) => (
                        <div className="new-checkout__factor">
                            <div className="new-checkout__factor__item__warranty">
                                <div className="new-checkout__factor__item__col">
                                    <SVG svg="sender" />
                                    فروشنده:
                                </div>
                                <div className="new-checkout__factor__item__warranty__seperator"></div>
                                <div className="new-checkout__factor__item__warranty__value">
                                    {" " + cart.seller}
                                </div>
                            </div>

                            <div className="new-checkout__factor__items">
                                {cart?.items?.length > 0 &&
                                    cart?.items?.map((cartItem, index) => (
                                        <>
                                            <div className="new-checkout__factor__item">
                                                <div className="new-checkout__factor__item__info">
                                                    <div className="new-checkout__factor__item__title">
                                                        {
                                                            cartItem.detail
                                                                .Product.title
                                                        }
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="new-checkout__factor__item__index row j-sb">
                                                <div>
                                                    تعداد:{" "}
                                                    {" " +
                                                        englishToPersianNumber(
                                                            cartItem.count
                                                        )}
                                                </div>
                                                <div className="new-checkout__factor__item__price">
                                                    {handleShowItemPrice(
                                                        cartItem
                                                    )}
                                                    <div
                                                        style={{
                                                            fontSize: 11,
                                                            fontWeight: 400,
                                                            marginRight: 4,
                                                        }}
                                                    >
                                                        تومان
                                                    </div>
                                                </div>
                                            </div>

                                            {/*<div className="new-checkout__factor__item__kv-cost">*/}
                                            {/*  <span className="new-checkout__factor__item__col kv" >*/}
                                            {/*    <SVG svg="kv" />*/}
                                            {/*    ارزش kv:*/}
                                            {/*  </span>*/}
                                            {/*  <div className="new-checkout__factor__item__warranty__seperator"></div>*/}
                                            {/*  <span className="row a-c">*/}
                                            {/*    {`*/}
                                            {/*      ${currency(*/}
                                            {/*      cartItem.detail.Variation.priceList[*/}
                                            {/*        user.detail.star - 1*/}
                                            {/*      ].kv*/}
                                            {/*    )}*/}
                                            {/*    `}*/}
                                            {/*    <div style={{ fontSize: 10, fontWeight: 400, marginRight: 3 }}>*/}
                                            {/*      تومان*/}
                                            {/*    </div>*/}
                                            {/*  </span>*/}
                                            {/*</div>*/}

                                            <div
                                                className="col a-c w-100"
                                                style={{
                                                    paddingBottom: 12,
                                                    borderBottom:
                                                        "2px solid #ffffff",
                                                }}
                                            >
                                                {/* {Number(cartItem.detail.Variation.color_id) !== 1 && (
                            <div className="new-checkout__factor__item__color">
                              <div>
                                رنگ
                              </div>
                              <span
                                style={{
                                  backgroundColor: `#${cartItem.detail.Variation.detail.Color.HEX}`,
                                }}
                              ></span>
                            </div>
                          )} */}

                                                {/* <div className="new-checkout__factor__item__warranty">
                            <div className="new-checkout__factor__item__col">
                              <SVG svg="sender"/>
                              فروشنده:
                            </div>
                            <div className="new-checkout__factor__item__warranty__seperator"></div>
                            <div className="new-checkout__factor__item__warranty__value">
                              {" " + cart.seller}
                            </div>
                          </div> */}

                                                {/*<div className="new-checkout__factor__item__warranty">*/}
                                                {/*  <div className="new-checkout__factor__item__col">*/}
                                                {/*    <SVG svg="warranty" />*/}
                                                {/*    گـارانتــــی:*/}
                                                {/*  </div>*/}
                                                {/*  <div className="new-checkout__factor__item__warranty__seperator"></div>*/}
                                                {/*  <div className="new-checkout__factor__item__warranty__value">*/}
                                                {/*    {" " + cartItem.detail.Variation.detail.Warranty}*/}
                                                {/*  </div>*/}
                                                {/*</div>*/}
                                            </div>
                                        </>
                                    ))}
                            </div>
                            <div className="new-checkout__factor__total-price">
                                <div className="new-checkout__factor__price">
                                    <div className="new-checkout__factor__item__info">
                                        هزینه ارسال
                                    </div>
                                    <div className="new-checkout__factor__item__price">
                                        {shippingPriceLoading ? (
                                            <Dots color="dark" />
                                        ) : +cart.shippingCost > 0 ? (
                                            `${transformPrice(
                                                Number(+cart?.shippingCost)
                                            )} تومان`
                                        ) : (
                                            "رایگان"
                                        )}
                                        {/* {transformPrice(Number(cart[0].shippingCost))} تومان */}
                                    </div>
                                </div>
                                <div className="new-checkout__factor__price">
                                    <div
                                        style={{ color: "red" }}
                                        className="new-checkout__factor__item__info"
                                    >
                                        تمامی سفارشات بر اساس نشانی گیرنده بین ۱
                                        تا ۵ روز کاری ارسال می‌شوند.
                                    </div>
                                    <div className="new-checkout__factor__item__price">
                                        {/* {shippingPriceLoading ? (
                                            <Dots color="dark" />
                                        ) : cart.shippingCostWithTime ? (
                                            `${cart?.shippingCostWithTime[1]} ساعت`
                                        ) : (
                                            "ارسال سریع"
                                        )} */}
                                        {/* {transformPrice(Number(cart[0].shippingCost))} تومان */}
                                    </div>
                                </div>
                                {/* <div className="new-checkout__factor__price">
                    <div className="new-checkout__factor__item__info">تخفیف</div>
                    <div className="new-checkout__factor__item__price">
                      {handleShowTotalDiscount()} تومان
                    </div>
                  </div> */}
                            </div>
                            <div className="new-checkout__factor__final">
                                <div className="new-checkout__factor__final__title">
                                    جمع سبد این فروشگاه
                                </div>
                                <div className="new-checkout__factor__final__price">
                                    {shippingPriceLoading ? (
                                        <Dots color="dark" />
                                    ) : (
                                        transformPrice(
                                            Number(
                                                +cart.amountPayable[0] +
                                                    +cart.shippingCost
                                            )
                                        ) + " تومان"
                                    )}
                                </div>
                            </div>
                            {/* <HalfCircle reverse={true} bottom={50} left={0} />
                <HalfCircle reverse={false} bottom={50} right={0} /> */}
                        </div>
                    ))}
                </div>
            )}

            <div className="cart-new__final bottom">
                {checkoutStep === 1 ? (
                    <div
                        className="cart-new__order-button"
                        onClick={() => !loading && handleButtonClick()}
                    >
                        <div className="cart-new__shape">
                            <SVG svg="bottom-shape" color="#21BBE1" />
                        </div>

                        <div className="cart-new__shape reverse">
                            <SVG svg="bottom-shape" color="#ffffff" />
                        </div>
                        {loading ? (
                            <Dots color="light" />
                        ) : (
                            <React.Fragment>
                                <div className="cart-new__order-button__title">
                                    ثبت جزئیات و ادامه
                                </div>
                                <div className="cart-new__order-button__icon">
                                    <SVG svg="arrow-big-white" />
                                </div>
                            </React.Fragment>
                        )}
                    </div>
                ) : (
                    <div
                        className="cart-new__order-button"
                        onClick={() => !loading && handleButtonClick()}
                    >
                        <div className="cart-new__shape">
                            <SVG svg="bottom-shape" color="#21BBE1" />
                        </div>

                        <div className="cart-new__shape reverse">
                            <SVG svg="bottom-shape" color="#ffffff" />
                        </div>
                        {loading ? (
                            <Dots color="light" />
                        ) : (
                            <React.Fragment>
                                <div className="cart-new__order-button__title">
                                    ثبت فاکتور و پرداخت
                                </div>
                                <div className="cart-new__order-button__icon">
                                    <SVG svg="payment-cart" />
                                </div>
                            </React.Fragment>
                        )}
                    </div>
                )}
                <div className="cart-new__final-price">
                    {checkoutStep === 2 && shippingPriceLoading ? (
                        <Dots color="dark" />
                    ) : (
                        handleShowTotalPrice() + " تومان"
                    )}
                </div>
            </div>

            <CreateAddress
                active={createAddressActive}
                setClose={(state) => setCreateAddressActive(state)}
                onSubmit={(id, result, isUpdate) =>
                    handleSubmitAddress(id, result, isUpdate)
                }
                isFullScreen={true}
                transferee={transferee}
            />

            <Modal
                active={showConflictCartModal}
                close={() => {
                    setShowConflictCartModal(false);
                    history.push("/market");
                }}
            >
                <div style={{ textAlign: "justify", fontSize: "14px" }}>
                    این کالا ها به دلیل محدودیت استان یا شهرستان شامل آدرس شما
                    نمی‌شود :{" "}
                </div>
                <div style={{ marginTop: "15px", marginBottom: "15px" }}>
                    {showConflictCartModal &&
                        conflictProducts.length > 0 &&
                        conflictProducts?.map((shop) => (
                            <div
                                style={{
                                    margin: "5px 0px 15px 0px",
                                    padding: "5px",
                                    borderRadius: "15px",
                                    backgroundColor: "#21bbe1",
                                    fontSize: "12px",
                                    color: "white",
                                }}
                            >
                                <p>فروشگاه : {shop[0].seller}</p>
                                <hr
                                    style={{
                                        backgroundColor: "lightgray",
                                        border: "none",
                                        height: "1px",
                                    }}
                                />
                                {shop?.map((item, index) => (
                                    <div>
                                        <p>
                                            {index + 1}. {item.title}
                                        </p>
                                    </div>
                                ))}
                            </div>
                        ))}
                </div>
                <button
                    className="button"
                    style={{ fontFamily: "inherit" }}
                    onClick={() => {
                        history.push("/market");
                    }}
                >
                    بازگشت به پاساژ
                </button>
            </Modal>

            <Modal
                active={showConflictCartModalAndContinue}
                close={() => {
                    setShowConflictCartModalAndContinue(false);
                    getCart();
                    setCheckoutStep(2);
                    updateCartGateWay();
                }}
            >
                <div style={{ textAlign: "justify", fontSize: "14px" }}>
                    این کالا ها به دلیل محدودیت استان یا شهرستان شامل آدرس شما
                    نمی‌شود :{" "}
                </div>
                <div style={{ marginTop: "15px", marginBottom: "15px" }}>
                    {showConflictCartModalAndContinue &&
                        conflictProducts.length > 0 &&
                        conflictProducts?.map((shop) => (
                            <div
                                style={{
                                    margin: "5px 0px 15px 0px",
                                    padding: "5px",
                                    borderRadius: "15px",
                                    backgroundColor: "#21bbe1",
                                    fontSize: "12px",
                                    color: "white",
                                }}
                            >
                                <p>فروشگاه : {shop[0].seller}</p>
                                <hr
                                    style={{
                                        backgroundColor: "lightgray",
                                        border: "none",
                                        height: "1px",
                                    }}
                                />
                                {shop?.map((item, index) => (
                                    <div>
                                        <p>
                                            {index + 1}. {item.title}
                                        </p>
                                    </div>
                                ))}
                            </div>
                        ))}
                </div>
                <button
                    className="button"
                    style={{ fontFamily: "inherit" }}
                    onClick={() => {
                        setShowConflictCartModalAndContinue(false);
                        getCart();
                        setCheckoutStep(2);
                    }}
                >
                    ادامه خرید
                </button>
            </Modal>
        </div>
    );
};

export default Checkout;
