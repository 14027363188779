import { Fade, PageTopbar, Spinner, SVG } from "components";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getHintAPI } from "utils";

const Rules = () => {
    const [activeCollapse, setActiveCollapse] = useState(0);
    const [loading, setLoading] = useState({
        hint2: true,
        hint3: true,
        hint4: true,
    });
    // const [hint1, setFirstHint] = useState({
    //     title: "",
    //     content: "",
    // });
    const [hint2, setHint2] = useState({
        title: "",
        content: "",
    });
    const [hint3, setHint3] = useState({
        title: "",
        content: "",
    });
    const [hint4, setHint4] = useState({
        title: "",
        content: "",
    });

    const history = useHistory();

    // const getHint1 = () => {
    //   getHintAPI('سیاستها و خط مشی کارتک 1')
    //     .then(response => {
    //       setFirstHint(response)
    //     })
    //     .catch(() => setFirstHint(false))
    //     .finally(() => { })
    // }

    const getHint2 = () => {
        getHintAPI("سیاستها و خط مشی کارتک 2")
            .then((response) => {
                setHint2(response);
            })
            .catch(() => setHint2(false))
            .finally(() => {
                setLoading((prevState) => ({
                    ...prevState,
                    hint2: false,
                }));
            });
    };

    const getHint3 = () => {
        getHintAPI("سیاستها و خط مشی کارتک 3")
            .then((response) => {
                setHint3(response);
            })
            .catch(() => setHint3(false))
            .finally(() => {
                setLoading((prevState) => ({
                    ...prevState,
                    hint3: false,
                }));
            });
    };

    const getHint4 = () => {
        getHintAPI("سیاستها و خط مشی کارتک 4")
            .then((response) => {
                setHint4(response);
            })
            .catch(() => setHint4(false))
            .finally(() => {
                setLoading((prevState) => ({
                    ...prevState,
                    hint4: false,
                }));
            });
    };

    useEffect(() => {
        //getHint1();
        getHint2();
        getHint3();
        getHint4();
    }, []);

    useEffect(() => {
        history.replace("/rules");
    }, []);

    return (
        <Fade>
            <PageTopbar title="سیاست‌ها و خط مشی کارتک" showKv={false} />
            <Spinner
                active={loading.hint2 || loading.hint3 || loading.hint4}
                color={"dark"}
            />

            <div className="about">
                {/*<div className={`about__item ${activeCollapse === 1 && 'active'}`} onClick={() => activeCollapse === 1 ? setActiveCollapse(0) : setActiveCollapse(1)}>*/}
                {/*  {firstHint.title}*/}
                {/*  <SVG svg="arrow" />*/}
                {/*</div>*/}
                {/*{*/}
                {/*  activeCollapse === 1 && (*/}
                {/*    <div className="about__description">*/}
                {/*      {firstHint.content?.split('\n')?.map(str => <div>{str}</div>)}*/}
                {/*      <div style={{ marginTop: 12 }}>*/}
                {/*        اطلاعات بیشتر را می توانید در سایت کارتک به نشانی*/}
                {/*        <span*/}
                {/*          style={{ fontWeight: 700, color: '#21bbe1', margin: '0 4px' }}*/}
                {/*          onClick={() => {*/}
                {/*            history.push(`/pay?https://kartaak.com/rules`);*/}
                {/*            setTimeout(() => {*/}
                {/*              if (history.location.pathname.includes("/pay"))*/}
                {/*                window.location.href = `https://kartaak.com/rules`;*/}
                {/*            }, 600);*/}
                {/*          }}*/}
                {/*        >*/}
                {/*          www.kartaak.com*/}
                {/*        </span>*/}
                {/*        ملاحظه نمایید.*/}
                {/*      </div>*/}
                {/*    </div>*/}
                {/*  )*/}
                {/*}*/}

                <div
                    className={`about__item ${
                        activeCollapse === 2 && "active"
                    }`}
                    onClick={() =>
                        activeCollapse === 2
                            ? setActiveCollapse(0)
                            : setActiveCollapse(2)
                    }
                >
                    {hint2.title}
                    <SVG svg="arrow" />
                </div>
                {activeCollapse === 2 && (
                    <div className="about__description">
                        {hint2.content?.split("\n")?.map((str) => (
                            <div>{str}</div>
                        ))}
                    </div>
                )}

                <div
                    className={`about__item ${
                        activeCollapse === 3 && "active"
                    }`}
                    onClick={() =>
                        activeCollapse === 3
                            ? setActiveCollapse(0)
                            : setActiveCollapse(3)
                    }
                >
                    {hint3.title}
                    <SVG svg="arrow" />
                </div>
                {activeCollapse === 3 && (
                    <div className="about__description">
                        {hint3.content?.split("\n")?.map((str) => (
                            <div>{str}</div>
                        ))}
                    </div>
                )}

                <div
                    className={`about__item ${
                        activeCollapse === 4 && "active"
                    }`}
                    onClick={() =>
                        activeCollapse === 4
                            ? setActiveCollapse(0)
                            : setActiveCollapse(4)
                    }
                >
                    {hint4.title}
                    <SVG svg="arrow" />
                </div>
                {activeCollapse === 4 && (
                    <div className="about__description">
                        {hint4.content?.split("\n")?.map((str) => (
                            <div>{str}</div>
                        ))}
                    </div>
                )}
            </div>
        </Fade>
    );
};

export default Rules;
