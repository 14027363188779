import { SVG } from 'components';
import { useEffect } from 'react';
import '../../../components/FillProfile/FillProfile.scss';
import FillCustomAge from './FillCustomAge/FillCustomAge';
import FillCustomCity from './FillCustomCity/FillCustomCity';
import FillCustomEducation from './FillCustomEducation/FillCustomEducation';
import FillCustomEmployment from './FillCustomEmployment/FillCustomEmployment';
import FillCustomGender from './FillCustomGender/FillCustomGender';
import FillCustomMarital from './FillCustomMarital/FillCustomMarital';

export const FillCustom = ({
  active = false,
  type = '',
  closeFillCustom
}) => {

  const handleFillCustom = () => {
    switch (type) {
      case 'city':
        return <FillCustomCity close={closeFillCustom} />;

      case 'gender':
        return <FillCustomGender close={closeFillCustom} />;

      case 'age':
        return <FillCustomAge close={closeFillCustom} />;

      case 'education':
        return <FillCustomEducation close={closeFillCustom} />;

      case 'employment':
        return <FillCustomEmployment close={closeFillCustom} />;

      case 'marital':
        return <FillCustomMarital close={closeFillCustom} />;

      default:
        return null;
    }
  }

  const handleShowTitle = () => {
    switch (type) {
      case 'city':
        return "محل سکونت بازدیدکننده";

      case 'gender':
        return "جنسیت بازدیدکننده";

      case 'age':
        return "سن بازدیدکننده";

      case 'education':
        return "تحصیلات بازدیدکننده";

      case 'employment':
        return "وضعیت اشتغال بازدیدکننده";

      case 'marital':
        return "وضعیت تاهل بازدیدکننده";

      default:
        return null;
    }
  }

  useEffect(() => {
    if (active)
      document.getElementsByTagName('body')[0].style.overflowY = 'hidden';
    else
      document.getElementsByTagName('body')[0].style.overflowY = 'auto';
  }, [active]);

  return (
    <div className={`fill-profile ${active && 'active'}`}>
      <div className="fill-profile__container" id="fill-profile-container">
        <div className="fill-profile__header">
          <div className="fill-profile__header__title">
            {handleShowTitle()}
          </div>
          <div
            className="fill-profile__header__close"
            onClick={() => closeFillCustom(true)}
          >
            <SVG svg="times" />
          </div>
        </div>

        {handleFillCustom()}
      </div>
    </div>
  );
}