import { Request } from "../api-call";

export const GetUserDetail = async () => {
  const { data } = await Request.get("api/v2/auth/user/detail");
  return data;
};

export const GetUserDetailAPI = async () => {
  const { data , status} = await Request.get("api/v2/auth/user/detail");
  return {data : data , status : status};
};
