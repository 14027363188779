import {Request} from "../api-call";

export const updateStorePictureAPI = async (id, pic) => {
  const {data} = await Request.post(`/api/v3/kartland/picture/${id}`, pic, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
  return data;
};
