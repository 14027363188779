import React, { useState, useEffect } from "react";
import { Dots, SVG } from "components";
import toast from "react-hot-toast";
import { useHistory } from "react-router-dom";
import {
    getUserUnitNameAPI,
    setUserUnitNameAPI,
} from "utils/Services/KartlandUnits";
import { useUser } from "hooks";

export const Item = ({
    data,
    myCitizen,
    citizenUnits,
    myUnits,
    userId = null,
}) => {
    const {
        userState: { user },
    } = useUser();
    const history = useHistory();
    const [marketName, setMarketName] = useState("");
    const [mainCustomers, setMainCustomers] = useState("");
    const [marketNameLoading, setMarketNameLoading] = useState(false);
    const [marketNamePresence, setMarketNamePresence] = useState(false);
    const [vitrinsData, setVirtinsData] = useState({
        isRent: 0,
        empty: 0,
    });

    const checkUnitStatus = (item, user) => {
        if (item.owner_id === item.user_id) {
            if (item.isRent === 0) {
                return <span style={{ color: "#eb4d4c" }}>تخلیه</span>;
            } else if (item.isRent === 1) {
                return (
                    <span style={{ color: "#5e61ed" }}>
                        {item?.product?.title}
                    </span>
                );
            }
        } else if (item.owner_id !== item.user_id) {
            if (user.id === item.owner_id) {
                return <span style={{ color: "#eb4d4c" }}>واگذار شده</span>;
            } else if (user.id === item.user_id) {
                if (item.isRent === 0) {
                    return <span style={{ color: "#eb4d4c" }}>تخلیه</span>;
                } else if (item.isRent === 1) {
                    return (
                        <span style={{ color: "#5e61ed" }}>
                            {item?.product?.title}
                        </span>
                    );
                }
            }
        }
    };

    const checkPersianName = (name) => {
        setMarketNameLoading(true);
        var persianRegex = /^[\u0600-\u06FF\s]+$/;
        if (!persianRegex.test(name)) {
            toast.error("نام فروشگاه باید به فارسی تایپ شود!");
            setMarketNameLoading(false);
        } else {
            setUserUnitNameAPI(name)
                .then((response) => {
                    if (response.status === 200 || response.status === 201) {
                        toast.success("نام فروشگاه با موفقیت ثبت شد");
                    } else {
                        toast.error("مشکلی رخ داده است!");
                    }
                })
                .catch((err) => {
                    toast.error("مشکلی رخ داده است!");
                })
                .finally(() => {
                    getUserUnitName("");
                });
        }
    };

    const getUserUnitName = (userId) => {
        setMarketNameLoading(true);
        getUserUnitNameAPI(userId)
            .then((response) => {
                if (response.status === 200) {
                    setMarketName(response.data.data.name);
                    setMainCustomers(response.data.data.customer_numbers);
                    response.data.data.name && setMarketNamePresence(true);
                } else {
                    toast.error("دریافت نام فروشگاه با مشکل مواجه شد!");
                }
            })
            .catch((err) => {
                toast.error("دریافت نام فروشگاه با مشکل مواجه شد!");
            })
            .finally(() => {
                setMarketNameLoading(false);
            });
    };

    const vitrinCalculations = () => {
        let isRentCount = 0;
        data.map((vitrin) => {
            isRentCount += vitrin.isRent;
        });
        setVirtinsData({
            isRent: isRentCount,
            empty: data.length - isRentCount,
        });
    };

    useEffect(() => {
        if (!myCitizen) {
            getUserUnitName("");
        } else {
            getUserUnitName(userId);
        }
    }, []);

    useEffect(() => {
        if (data) {
            vitrinCalculations();
        }
    }, [data]);

    const submitName = () => {
        checkPersianName(marketName);
    };

    return (
        <div className="kartland__item__wrapper">
            <div
                style={{ padding: "0.8rem" }}
                className="kartland__item-detail"
            >
                <div className="title">نام شعبه</div>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <input
                        value={marketName}
                        onChange={(e) => setMarketName(e.target.value)}
                        placeholder="نام انتخابی پس از تایید قابل تغییر نیست!"
                        className="input"
                        type="text"
                        disabled={
                            (myUnits.length === 0 ||
                                marketNamePresence ||
                                myCitizen) &&
                            true
                        }
                    />
                    <div
                        style={{
                            marginTop: "0",
                            marginBottom: "1rem",
                            width: "15%",
                            marginRight: "0.3rem",
                            position: "absolute",
                            left: "32px",
                            opacity: (!marketName || marketNamePresence) && 0,
                            transition: "0.1s ease-in-out",
                        }}
                        className={`friends-invite__introduced__action active`}
                        onClick={() => submitName()}
                    >
                        {marketNameLoading ? <Dots color="light" /> : "ثبت"}
                    </div>
                </div>
                <div
                    style={{ display: "flex", justifyContent: "space-around" }}
                >
                    <div className="title">
                        مشتریان اصلی :{" "}
                        <span style={{ color: "red" }}>{mainCustomers}</span>
                    </div>
                    <div className="title">
                        ویترین‌های اشغال شده :{" "}
                        <span style={{ color: "red" }}>
                            {vitrinsData.isRent}
                        </span>
                    </div>
                    <div className="title">
                        ویترین‌های خالی :{" "}
                        <span style={{ color: "red" }}>
                            {vitrinsData.empty}
                        </span>
                    </div>
                </div>
            </div>

            {!myCitizen &&
                data.map((item, index) => (
                    <div
                        onClick={() => {
                            history.push(`/kartland/${item.id}`);
                        }}
                        className="kartland__item-detail"
                    >
                        <div className="kartland__item">
                            <div
                                style={{
                                    minWidth: "70px",
                                    minHeight: "70px",
                                    fontSize: "14px",
                                    textAlign: "center",
                                    color: "white",
                                    cursor: "pointer",
                                    backgroundColor:
                                        user.id === item.user_id
                                            ? "#1f7396"
                                            : "#fbb506",
                                }}
                                className="kartland__item__cover"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    user.id === item.user_id
                                        ? history.push(
                                              `/kartland/rent/${item.id}`
                                          )
                                        : toast.error(
                                              "این ویترین واگذار شده است!"
                                          );
                                }}
                            >
                                انتخاب محصول
                            </div>
                            <div className="kartland__item__text">
                                <div className="kartland__item__text__title">
                                    <span className="kartland__item__text__title__value">
                                        {`ویترین شماره ${item.unitnumber}`}
                                    </span>
                                    <span className="kartland__item__text__title__slug">
                                        ( {item.cityname} )
                                    </span>
                                </div>
                                <div
                                    className={`kartland__item__text__status ${
                                        item.isRent && "rented"
                                    }`}
                                >
                                    <div
                                        className={`kartland__item__text__status__dot`}
                                    />
                                    {checkUnitStatus(item, user)}
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
        </div>
    );
};
