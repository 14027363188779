import { useEffect, useState } from "react";
import { Fade, PageTopbar, SVG, Card, Hint } from "components";
import { useParams, useHistory } from "react-router-dom";

export const ChargePageContainer = () => {
  const [content, setContent] = useState([
    {
      title: "بسته ی ویژه ی مهرماه",
      PackageVolume: '20گیگابایت',
      packageTime: '20روزه'
    },
    {
      title: "بسته ی ویژه ی مهرماه",
      PackageVolume: '20گیگابایت',
      packageTime: '20روزه'
    },
    {
      title: "بسته ی ویژه ی مهرماه",
      PackageVolume: '20گیگابایت',
      packageTime: '20روزه'
    },
    {
      title: "بسته ی ویژه ی مهرماه",
      PackageVolume: '20گیگابایت',
      packageTime: '20روزه'
    }
  ]);

  return (
    <Fade>
      <PageTopbar title="خرید  اینترنت " />
      <Card>
        <div className="charge-page__available-package__title">بسته های موجود</div>
        <div className="charge-page__available-package">
          {content.map((data) => (

            <div className="charge-page__available-package__item">
              <div className="charge-page__available-package__item__top"><span>{data.title}:بسته ی ویژه ی مهرماه</span>{ }</div>
              <div className="charge-page__available-package__item__down">
                <div className="charge-page__available-package__item__down__right">
                  <div className="charge-page__available-package__item__down__right__content">
                    <SVG svg="kartland-sold" />
                    <span>حجم بسته : {data.PackageVolume}</span>
                  </div>
                </div>
                <div className="charge-page__available-package__item__down__left">
                    <div className="charge-page__available-package__item__down__left__content">
                      <SVG svg="kartland-sold" />
                      <span> زمان بسته : {data.packageTime}</span>
                    </div>
                  </div>
                </div>
              </div>
              ))
          }
            </div>
      </Card>
    </Fade>
  );
};
