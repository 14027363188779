import { SVG } from "components";
import { useState, FC, useEffect } from "react";
import "./Selectbox.scss";

export const Selectbox = ({
    disabled = false,
    createable = false,
    placeholder = "",
    data = [],
    onChange,
    onClickBox = () => {},
    onChangeString,
    resetValue = false,
    defaultValue = "",
    selectable = false,
    openAll = false,
    canChange = true,
    style = null,
}) => {
    const [inputValue, setInputValue] = useState("");
    const [showItems, setShowItems] = useState(false);
    const [showInputDiv, setShowInputDiv] = useState(1);
    const [activeItem, setActiveItem] = useState<any>({});

    const handleShowName = (obj) => {
        if (obj.name !== undefined) {
            return obj.name;
        } else if (obj.title !== undefined) {
            return obj.title;
        }
    };

    const filterData = () => {
        const filteredData = data.filter((dataItem) => {
            if (openAll) {
                if (dataItem.name !== undefined)
                    return dataItem.name.includes("");
                else if (dataItem.title !== undefined)
                    return dataItem.title.includes("");
            }
            if (dataItem.name !== undefined)
                return dataItem.name.includes(inputValue);
            else if (dataItem.title !== undefined)
                return dataItem.title.includes(inputValue);
        });

        if (filteredData.length === 0) return false;

        return (
            <div className={`selectbox__container ${showItems && "active"}`}>
                {filteredData.map((res, index) => (
                    <div
                        className="selectbox__item"
                        key={index}
                        onMouseDown={() => {
                            if (res.name !== undefined) {
                                setInputValue(res.name);
                            } else if (res.title !== undefined) {
                                setInputValue(res.title);
                            }
                            setActiveItem(res);
                            onChange(res.id);
                            setShowItems(false);
                            setShowInputDiv(1);
                            if (onChangeString !== undefined) {
                                if (res.name !== undefined)
                                    onChangeString(res.name);
                                else if (res.title !== undefined)
                                    onChangeString(res.title);
                            }
                            if (selectable) {
                                setInputValue("");
                            }
                        }}
                    >
                        {handleShowName(res)}
                    </div>
                ))}
            </div>
        );
    };

    useEffect(() => {
        if (resetValue) {
            setInputValue("");
            setActiveItem({});
        }
    }, [resetValue]);

    useEffect(() => {
        setInputValue(defaultValue);
    }, [defaultValue]);

    return (
        <div
            className={`selectbox ${showItems && "active"} ${
                disabled && "disabled"
            }`}
            onClick={() => {
                if (onChangeString !== undefined) {
                    onClickBox();
                }
            }}
        >
            {data.length !== 0 && (
                <div
                    onClick={() => {
                        setShowInputDiv(1);
                        setShowItems(false);
                    }}
                    className="selectbox__arrow"
                >
                    <SVG svg="arrow" />
                </div>
            )}
            {showInputDiv === 1 && (
                <div
                    className="inputDiv"
                    onClick={() => {
                        if (disabled === false) {
                            setShowInputDiv(0);
                            setShowItems(true);
                        }
                    }}
                >
                    {inputValue ? inputValue : placeholder}
                </div>
            )}

            {showInputDiv !== 1 && (
                <input
                    autoComplete="off"
                    id="selectbox-value"
                    disabled={disabled}
                    type="text"
                    value={inputValue}
                    onClick={() => {
                        if (disabled === false) setShowItems(true);
                        // document.getElementsByTagName('body')[0].style.overflowY = 'hidden';
                    }}
                    onChange={(e) => {
                        if (!canChange) return;

                        setInputValue(e.target.value);

                        if (activeItem.name !== undefined) {
                            if (e.target.value !== activeItem.name) {
                                setActiveItem({});
                                onChange(0);
                            }
                        } else if (activeItem.title !== undefined) {
                            if (e.target.value !== activeItem.title) {
                                setActiveItem({});
                                onChange(0);
                            }
                        }

                        if (onChangeString !== undefined) {
                            onChangeString(e.target.value);
                            onChange(0);
                        }
                    }}
                    placeholder={placeholder}
                    onBlur={() => {
                        setShowItems(false);
                        setShowInputDiv(1);
                        // document.getElementsByTagName('body')[0].style.overflowY = 'auto';
                    }}
                />
            )}

            {filterData()}
        </div>
    );
};
