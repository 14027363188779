import { useState, useEffect } from "react";
import { NotFound, SliderPanel } from "./dependencies";
import {
  PageTopbar,
  PanelBox,
  Card,
  SVG,
  Modal,
  Spinner,
  Fade,
} from "components";
import { useHistory } from "react-router-dom";

export const BuyPanelContainer = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    history.replace("/buy-panel");
  }, []);

  return (
    <Fade>
      <div className="buy-panel">
        <Spinner color="dark" active={loading} />
        <PageTopbar title="خرید پنل" toggleBackward={true} />
        <SliderPanel setLoading={setLoading} />
      </div>
    </Fade>
  );
};
