import { useEffect, useState } from "react";
import "./Slider.scss";
import Slider from "react-slick";
import { getProfileSliderAPI } from "utils";

export const ProfileSlider = () => {
  const [sliders, setSliders] = useState([]);

  const settings = {
    infinite: true,
    slidesToShow: 1,
    speed: 2000,
    arrows: false,
    dots: true,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 6000,
    cssEase: "linear",
    pauseOnHover: false,
  };

  const getSliders = () => {
    getProfileSliderAPI()
      .then((response) => {
        setSliders(response.data);
      })
      .catch((err) => { })
      .finally(() => { });
  };

  useEffect(() => {
    getSliders();
  }, []);

  return (
      <div className="profile__slider">
        <div className="profile__slider__image">
          <Slider {...settings} dotsClass="profile__slider__dot">
            {sliders?.map((slider) => (
              <img src={slider.slideFile} alt="" />
            ))}
          </Slider>
        </div>
      </div>
  );
};
