import { useState, FC, useContext } from "react";
import { CircleHint, KV, SVG, UserStarBox } from "components";
import { useHistory } from "react-router-dom";
import "./PageTopbar.scss";
import { AppContext } from "../../context/AppContext";
import { useUser } from "hooks";
import toast from "react-hot-toast";

type PageTopbarType = {
    title: string;
    colorModalWithCall?: boolean;
    colorModalTitle?: string;
    setColor?: any;
    toggleBackward?: boolean;
    toggleStar?: boolean;
    showKv?: boolean;
    staticKvCount?: number;
    hintStatus?: boolean;
    hintTitle?: string;
    hintBio?: string;
    hintAction?: boolean;
    hintActionTitle?: string;
    hintActionIcon?: string;
    toggleBookmark?: boolean;
    cardbox?: boolean;
    onClickBookmark?: any;
    onClickKartBox?: any;
    onClickHintAction?: (e) => void;
    white?: boolean;
    circleHint?: string;
    titleColor?: string;
};
export const PageTopbar: FC<PageTopbarType> = ({
    title = "",
    colorModalWithCall,
    colorModalTitle,
    setColor,
    showKv = false,
    staticKvCount = null,
    toggleBackward = true,
    toggleStar = false,
    hintStatus,
    hintTitle,
    hintBio,
    hintAction,
    hintActionTitle,
    hintActionIcon,
    onClickHintAction,
    toggleBookmark = false,
    cardbox = false,
    onClickBookmark,
    onClickKartBox,
    white = false,
    circleHint = "", // for use circleHint Please set showKv={false} when call PageTopbar
    titleColor,
}) => {
    const {
        userState: { user },
    } = useUser();
    const [showSetting, setShowSetting] = useState(false);
    const settingItems = [
        {
            color: "#4D4DCE",
            title: "آبی",
        },
        {
            color: "#EB4D4C",
            title: "قرمز",
        },
        {
            color: "#17CFAD",
            title: "سبز",
        },
        {
            color: "#976DF0",
            title: "بنفش",
        },
        {
            color: "#F88131",
            title: "نارنجی",
        },
    ];

    const handleSelectColor = (color) => {
        localStorage.setItem("support_color", JSON.stringify(color));
        setColor(color.color);
        setShowSetting(false);
    };

    const history = useHistory();

    return (
        <div className="page-topbar">
            <div
                className={`page-topbar__back ${
                    !toggleBackward ? "hidden" : null
                }`}
                onClick={() => history.goBack()}
            >
                <SVG svg="arrow-big" />
            </div>
            {showKv ? (
                <div
                    className={`page-topbar__star ${!showKv ? "hidden" : null}`}
                    onClick={() => history.push("./profile")}
                >
                    {/* <div className="page-topbar__star__circle">
          <UserStarBox />
        </div> */}
                    <div className="page-topbar__star__jeton star">
                        <div>
                            <SVG svg="user-star" />
                        </div>
                        <div className="page-topbar__star__jeton__title">
                            {user.detail && user.detail.star}
                        </div>
                    </div>

                    {/* <div className="page-topbar__star__jeton km">
          <div>
            <SVG svg="kv" />
          </div>
          <div className="page-topbar__star__jeton__title">
            {user.detail && user.detail.tokens.KM}
          </div>
        </div> */}

                    <div className="page-topbar__star__jeton kv">
                        <div>
                            <SVG svg="kv" />
                        </div>
                        <div className="page-topbar__star__jeton__title">
                            {user.detail && user.kv}
                        </div>
                    </div>
                    {/* <div className="page-topbar__star__counter">
          <SVG svg="user-star" />
          <span>{user.detail && user.detail.star}</span>
        </div> */}
                </div>
            ) : (
                circleHint.length != 0 && <CircleHint hintText={circleHint} />
            )}

            <div
                className={`page-topbar__title ${toggleStar && "withStar"} ${
                    white && "white"
                }`}
                style={{ color: titleColor }}
            >
                {title}
            </div>

            <div
                className={`page-topbar__bookmark ${
                    !toggleBookmark && "hidden"
                }`}
                tabIndex={1}
                onClick={onClickBookmark}
            >
                <div className="page-topbar__bookmark__wrapper">
                    <SVG svg="add-ads" />
                    {/* <SVG svg="mykartella" /> */}
                    آلبوم
                </div>
            </div>
            <div
                className={`page-topbar__cardbox ${!cardbox && "hidden"}`}
                tabIndex={2}
                onClick={onClickKartBox}
            >
                <div className="page-topbar__cardbox__wrapper">
                    {/* <SVG svg="add-ads" /> */}

                    <SVG svg="cards-mine" color="#ffffff" />
                    {/* <SVG svg="mykartella" /> */}
                    {/* <p style={{fontSize:"11px"}}> */}

                    <div style={{ fontSize: "11px" }} className="col a-c">
                        <div>کارت</div>
                        <div>باکس</div>
                        {/* </p> */}
                    </div>
                </div>
            </div>

            {colorModalWithCall ? (
                <>
                    <div
                        className="page-topbar__support"
                        tabIndex={1}
                        onBlur={() => setShowSetting(false)}
                    >
                        <div
                            className="page-topbar__support__config"
                            onClick={() => setShowSetting(!showSetting)}
                        >
                            <SVG svg="setting" />
                        </div>
                        <div className="page-topbar__support__call">
                            <a
                            // href="tel:+983133374809"
                            >
                                <SVG svg="call" />
                            </a>
                        </div>
                    </div>
                    <div
                        className={`page-topbar__support__setting ${
                            showSetting && "active"
                        }`}
                    >
                        <span>{colorModalTitle}</span>
                        {settingItems.map((settingItem, index) => (
                            <div
                                className="page-topbar__support__setting__item"
                                key={index}
                                onClick={() => handleSelectColor(settingItem)}
                            >
                                <div
                                    style={{
                                        backgroundColor: settingItem.color,
                                    }}
                                    className="page-topbar__support__setting__color"
                                />
                                {settingItem.title}
                            </div>
                        ))}
                    </div>
                </>
            ) : (
                showKv &&
                // <KV
                //   number={staticKvCount}
                //   hintModal={hintStatus}
                //   hintTitle={hintTitle}
                //   hintBio={hintBio}
                //   hintAction={hintAction}
                //   hintActionTitle={hintActionTitle}
                //   hintActionIcon={hintActionIcon}
                //   onClickHintAction={onClickHintAction}
                // />
                null
            )}
        </div>
    );
};
