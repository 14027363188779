import {Fade, PageTopbar, Spinner} from 'components';
import React, {useEffect, useState} from 'react';
import toast from 'react-hot-toast';
import {useHistory, useLocation} from 'react-router';
import {adsSeenListAPI} from 'utils';
import {cloneDeep} from "lodash";
import {listOfVideosAPI} from "../../utils/Services/UserContent/ListOfVideos";

export const KartellaSeenContainer = () => {
  const history = useHistory();
  const location = useLocation();
  const query = useQuery();
  const [ads, setAds] = useState([]);
  const [loading, setLoading] = useState(true);
  const [videos, setVideos] = useState([]);

  const catId = query.get("catId");
  const catTitle = query.get("catTitle");

  function useQuery() {
    return new URLSearchParams(location.search);
  }

  const getAds = () => {
    adsSeenListAPI(catId).then((response) => {
      setLoading(false);
      if (response.status === 404) {
        toast.error("محتوایی یافت نشد!");
        history.goBack();
        return;
      }
      if (response.data.data?.length == 0) {
        toast.error("محتوایی یافت نشد!");
        history.goBack();
        return;
      } else {
        let currAds = cloneDeep(ads);
        response.data.data.forEach(adsRes => {
          currAds.push(adsRes);
        });
        setAds(currAds);
      }
    })
      .catch(err => console.log(err));
  };

  const videosList = () => {
    listOfVideosAPI().then(res => {
      if (res.status === 200) {
        setVideos(res.data);
      }
    }).catch(err => {

    }).finally();
  };

  useEffect(() => {
    history.replace(`/kartella/seen${location.search}`);
    getAds();
    videosList()
  }, []);

  useEffect(() => {
    let currAds = cloneDeep(ads);

    currAds.map(item => {
      videos.filter(video => {
        if (video.contentId === item.id) {
          item.thumbnail = video.thumbnail;
        }
      });
    });

    setAds(currAds);

  }, [videos, ads.length]);

  return (
    <Fade>
      <PageTopbar title={catTitle} showKv={false}/>
      <Spinner active={loading} color="dark"/>

      <div className="kartella-seen">
        {ads.map((adsItem, index) => (
          <div
            className="kartella-seen__item"
            key={index}
            onClick={() => {
              history.push(`/ads?id=${adsItem.id}`);
            }}
          >
            <div className="kartella-seen__image">
              <img src={adsItem.image ? adsItem.image : adsItem?.thumbnail?.thumbnail_url} alt=""/>
            </div>
            <div className="kartella-seen__title">
              {adsItem.title}
            </div>
          </div>
        ))}
      </div>
    </Fade>
  );
};