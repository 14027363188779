import { Request } from "../../api-call";

export const SubmitTerm = async (nameArray, buyCountArray, kmCountArray) => {
  let query = "";
  ["", "", ""].forEach((item, index) => {
    if (index !== 0) {
      query += "&";
    }
    query += `${
      buyCountArray[index] !== undefined &&
      `donate[${index}][title]=2&donate[${index}][firstField]=${buyCountArray[index]}`
    }&${
      kmCountArray[index] !== undefined &&
      // `donate[${index}][secondField]=${kmCountArray[index]}`
      `donate[${index}][secondField]=${null}`
    }&${
      nameArray[index] !== undefined &&
      `donate[${index}][thirdField]=${nameArray[index]}`
    }`;
  });
  const { data } = await Request.post(
    `api/v3/kartland/donateCondition/setDonate?${query}`
  );
  return data;
};
