import React, { useEffect, useState } from "react";
import {
  Padding,
  Spinner,
  PageTopbar,
  Card,
  SVG,
  Share,
  Dots,
  Hint,
  Modal,
  Fade,
  SlidingHint,
} from "components";
import {
  getUserFriends,
  getUserInfo,
  setPresenterAPI,
} from "../utils/Services/API";
import { useUser } from "hooks";
import moment from "jalali-moment";
import captain from "../assets/images/diamond-captain.png";
import captain2 from "../assets/images/diamond-captain-modified.png";
import toast from "react-hot-toast";
import { useHistory } from "react-router-dom";
import {
  getCaptainConditionAPI,
  getCaptainConditionStatusAPI,
  setCaptainAPI,
} from "utils/Services/Captain";
import profileImage from "../assets/images/profile.png";
import { getHintAPI } from "utils";
import { Tabs } from "../containers/OrdersContainer/dependencies";
import { farsiDigitToEnglish } from "../utils/Functions";
import introduceNumber from "./IntroduceNumber";
import {
  introducedCounterAPI,
  introducedNumbersAPI,
  introduceNumberAPI,
} from "../utils/Services/Captain";
import { error } from "autoprefixer/lib/utils";
import { updateUserNameAPI } from "../utils/Services/Captain/UpdateUserName";
import { troveListAPI, userStampsAPI } from "../utils";

const Friends = () => {
  const {
    getUserDataFromApi,
    userState: { user },
  } = useUser();
  const user_token = localStorage.getItem("user_token");
  const [userInfo, setUserInfo] = useState({});
  const [friendsList, setFriendsList] = useState([]);
  const [showSharing, setShowSharing] = useState(false);
  const [caller, setCaller] = useState(null);
  const [loading, setLoading] = useState(true);
  const [inviteCode, setInviteCode] = useState("");
  const [inviteCodeLoading, setInviteCodeLoading] = useState(false);
  const [userTotalInvited, setUserTotalInvited] = useState(0);
  const [captainConditionStatus, setCaptainConditionStatus] = useState({});
  const [showFriends, setShowFriends] = useState(true);
  const [activeTab, setActiveTab] = useState(1);
  const [isAdding, setIsAdding] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [addNumberLoading, setAddNumberLoading] = useState(false);
  const [deleteNumberLoading, setDeleteNumberLoading] = useState(false);
  const [addedNubmers, setAddedNubmers] = useState([]);
  const [addedNubmersLoading, setAddedNubmersLoading] = useState(false);
  const [maxIntroduced, setMaxIntroduced] = useState(1);
  const [showUpdate, setShowUpdate] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const history = useHistory();
  // diamond state
  const [captainModal, setCaptainModal] = useState(false);
  const [captainHintModal, setCaptainHintModal] = useState(false);
  const [convertCaptian, setConvertCaptian] = useState(false);
  const [myCaptainCount, setMyCaptainCount] = useState(0);
  const [captainTeamCount, setCaptainTeamCount] = useState(0);
  const [hint, setHint] = useState({
    title: "",
    content: "",
  });
  const [userFirstName, setUserFirstName] = useState("");
  const [userLastName, setUserLastName] = useState("");
  const [confirmModal, setConfirmModal] = useState(false);

  const [openSlidingHint, setOpenSlidingHint] = useState(false);
  const [captainHint, setCaptainHint] = useState("");

  const [stampList, setStampList] = useState();
  const [trovesList, setTrovesList] = useState();
  const [activeTrove, setActiveTrove] = useState();
  const [userDirect, setUserDirect] = useState(0);
  const [userDirectInvite, setUserDirectInvite] = useState([]);
  const [userFriendsNewLoading, setUserFriendsNewLoading] = useState(true);

  const getFriends = () => {
    const userToken = localStorage.getItem("user_token");

    const serverResponse = getUserFriends(userToken);
    serverResponse
      .then((response) => {
        setFriendsList(response.data.data.friends_list);
        setCaller(response.data.data.caller);
        setCaptainTeamCount(response.data.data.totalCaptain);
        setMyCaptainCount(response.data.data.directCaptain);
      })
      .catch((e) => {
        toast.error("خطا در برقراری ارتباط");
        return;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const userToken = localStorage.getItem("user_token");
  const getUserFriendsNew = () => {
    getCaptainConditionStatusAPI()
      .then((userCond) => {
        setUserDirect(userCond.data.direct);

        getUserFriends(userToken)
          .then((response) => {
            if (response.status == 200) {
              setUserDirectInvite(response.data.data.friends_list);
            }
          })
          .catch((error) => {
            toast.error("دریافت اطلاعات با خطا مواجه شد.");
          })
          .finally(() => {
            setUserFriendsNewLoading(false);
          });
      })
      .catch((error) => {
        toast.error("دریافت اطلاعات با خطا مواجه شد.");
      });
  };

  const troveList = () => {
    setLoading(true);
    troveListAPI()
      .then((res) => {
        if (res.status === 200) {
          let currTroves = [];
          res.data.details.map((item) => {
            if (item.counter === 28250) {
              currTroves.push(item);
            }
          });

          setTrovesList(currTroves);
        }
      })
      .catch((err) => {})
      .finally(() => {});
  };

  const getTroveStamps = (id) => {
    setLoading(true);
    userStampsAPI(id)
      .then((res) => {
        if (res.status === 200) {
          setStampList(res.data);
        }
      })
      .catch((err) => {})
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getUserFriendsNew();
    troveList();
  }, []);

  useEffect(() => {
    if (trovesList?.length > 0) {
      setActiveTrove(trovesList[activeTab - 1]);
    }
  }, [trovesList, activeTab]);

  useEffect(() => {
    if (activeTrove?.id) {
      getTroveStamps(activeTrove.id);
    }
  }, [activeTrove]);

  useEffect(() => {
    if (userDirectInvite.length != 0) {
    }
  }, [userDirectInvite]);

  const copy = () => {
    const value = document.getElementById("panel_code");
    value.select();
    document.execCommand("copy");

    toast.success("کد کارتک شما کپی شد!");
  };

  const getUser = () => {
    const userToken = localStorage.getItem("user_token");

    const serverResponse = getUserInfo(userToken);
    serverResponse.then((response) => {
      if (response.status >= 400) {
        localStorage.removeItem("user_token");
        history.push("/login");
      } else {
        setUserInfo(response.data.detail);
      }
      setLoading(false);
    });
  };

  const handleSetPresenter = () => {
    if (
      Number(user.mobile_number) === Number(inviteCode) ||
      inviteCode === userInfo.inviteCode
    ) {
      toast.error("کد معرف وارد شده معتبر نمی‌باشد.");
      return;
    }
    setInviteCodeLoading(true);
    setPresenterAPI(user_token, inviteCode).then((response) => {
      if (response.status === 200) {
        toast.success("کد معرف شما با موفقیت ثبت شد.");
        //getFriends();
      } else {
        toast.error("کد معرف وارد شده معتبر نمی‌باشد.");
      }
      setInviteCodeLoading(false);
    });
  };

  const handleSetCaptain = () => {
    setCaptainAPI()
      .then((response) => {
        if (response.status === 200) {
          toast.success("شما با موفقیت کاپیتان شدید! 🎉");
          getUserDataFromApi();
          setCaptainModal(false);
        }
      })
      .catch((err) => {
        if (err.response.status === 403) {
          toast.error("شما شرایط کاپیتان شدن را ندارید!");
        }
      });
  };

  const getCaptainCondition = () => {
    getCaptainConditionAPI()
      .then((response) => {
        if (response.status === 200) {
          // captain has
          setConvertCaptian(true);
          setUserTotalInvited(response.data.totalInvited);
        }
      })
      .catch((err) => {
        if (err.response.status === 403) {
          // capitan nis
          setConvertCaptian(false);
          setUserTotalInvited(err.response.data.data.totalInvited);
        }
      });
  };

  const getCaptainConditionStatus = () => {
    getCaptainConditionStatusAPI().then((response) => {
      setCaptainConditionStatus(response.data);
    });
  };

  const getHint = () => {
    getHintAPI("صفحات داخلی کاپیتان")
      .then((response) => {
        setHint(response);
      })
      .catch(() => setHint(false))
      .finally(() => {});
  };

  // const getSecondHint = () => {
  //     getHintAPI("روی کارت کاپیتانی")
  //         .then((response) => {
  //             setSecondHint(response);
  //         })
  //         .catch(() => setSecondHint(false))
  //         .finally(() => {});
  // };

  const sendNumber = () => {
    if (!(phoneNumber.length === 11 && phoneNumber.match(/[0-9]+/g))) {
      toast.error("شماره موبایل را به درستی وارد کنید");
      return;
    }
    setAddNumberLoading(true);
    introduceNumberAPI(phoneNumber)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.registering.length > 0) {
            toast.success("شماره با موفقیت معرفی شد");
            getIntroducedNumbers();
            setPhoneNumber("");
            setConfirmModal(false);
          }
          if (res.data.registered.length > 0) {
            toast.error("این شماره قبلا معرفی شده است");
            setPhoneNumber("");
            setConfirmModal(false);
          }
          if (res.data.kartaak.length > 0) {
            toast.error("این شماره قبلا در کارتک ثبت‌نام کرده است");
            setPhoneNumber("");
            setConfirmModal(false);
          }
        }
      })
      .catch((error) => {})
      .finally(() => setAddNumberLoading(false));
  };

  const getIntroducedNumbers = () => {
    setAddedNubmersLoading(true);
    introducedNumbersAPI()
      .then((res) => {
        if (res.status === 200) {
          setAddedNubmers(res.data.registered);
        }
      })
      .catch((err) => {})
      .finally(() => setAddedNubmersLoading(false));
  };

  const getMaxIntroduced = () => {
    introducedCounterAPI()
      .then((res) => {
        if (res.status === 200) {
          setMaxIntroduced(res.data.mobileCounter);
        }
      })
      .catch((err) => {})
      .finally(() => {});
  };

  const updateUserName = () => {
    if (!userFirstName) {
      toast.error("لطفا نام خود را وارد نمایید");
      return;
    }
    if (!userLastName) {
      toast.error("لطفا نام خانوادگی خود را وارد نمایید");
      return;
    }
    setUpdateLoading(true);
    updateUserNameAPI(userFirstName, userLastName)
      .then((res) => {
        if (res.status === 200) {
          toast.success("اطلاعات با موفقیت بروزرسانی شد");
          setShowUpdate(false);
          setConfirmModal(true);
        }
      })
      .catch((err) => {})
      .finally(() => setUpdateLoading(false));
  };

  useEffect(() => {
    history.replace("/profile/friends");
    //getFriends();
    getUser();
    getCaptainConditionStatus();
    getCaptainCondition();
    getMaxIntroduced();
  }, []);

  useEffect(() => {
    // window.scrollTo(0, 580);
  }, [showFriends]);

  useEffect(() => {
    setUserFirstName(user.name);
    setUserLastName(user.lastname);
  }, [user]);

  useEffect(() => {
    window.scrollTo(0, 0);
    getHint();
    getIntroducedNumbers();
  }, []);

  useEffect(() => {
    getHintAPI("صفحات داخلی کاپیتان	").then((response) => {
      setCaptainHint(response.content);
    });
  }, []);

  return (
    <Fade>
      <div className="friends">
        <Modal active={showUpdate} close={() => setShowUpdate(false)}>
          <div className="title">
            برای تکمیل اطلاعات نام و نام‌خانوادگی خود را وارد نمایید
          </div>
          <div className="title">نام</div>
          <input
            type="text"
            className="input"
            value={userFirstName}
            onChange={(e) => setUserFirstName(e.target.value)}
          />

          <div className="title">نام خانوادگی</div>
          <input
            type="text"
            className="input"
            value={userLastName}
            onChange={(e) => setUserLastName(e.target.value)}
          />

          <div className="title">
            مسئولیت صحت اطلاعات وارده، به عهده کاربر می‌باشد.
          </div>

          <div
            className="button green"
            onClick={() => updateLoading === false && updateUserName()}
          >
            {updateLoading ? (
              <Dots color={"light"} />
            ) : (
              <span>ثبت اطلاعات</span>
            )}
          </div>
        </Modal>

        <Modal active={confirmModal} close={() => setConfirmModal(false)}>
          <div className="friends-invite__modal">
            <div className="friends-invite__modal__text">
              درصورت زدن کلید تائید یک پیامک به نام شما برای صاحب این شماره
              ارسال می‌شود
            </div>
            <div className="friends-invite__modal__actions">
              <div onClick={sendNumber} className="button">
                تائید
              </div>
              <div
                onClick={() => setConfirmModal(false)}
                className="button red"
              >
                انصراف
              </div>
            </div>
          </div>
        </Modal>

        <Modal
          hint={"price"}
          active={captainHintModal}
          close={() => setCaptainHintModal(false)}
        >
          <div className="captain__modal">
            {/* <div className="captain__modal__title">
              <span>{hint.title}</span>
            </div> */}
            <div className="captain__modal__wrapper">
              <div className="captain__modal__box">
                <img src={captain} alt="" height="68px" width="75px" />
                <span className="captain__modal__box__title">{hint.title}</span>
                <span className="captain__modal__box__info">
                  {hint.content?.split("\n")?.map((str) => (
                    <div>{str}</div>
                  ))}
                </span>
              </div>
              {/*<div*/}
              {/*  className="captain__modal__action"*/}
              {/*  onClick={() => handleSetCaptain()}*/}
              {/*>*/}
              {/*  <span>کاپیتان شدن</span>*/}
              {/*  <SVG svg="checked" />*/}
              {/*</div>*/}
            </div>
          </div>
        </Modal>

        <Spinner active={loading} color="dark" />
        <PageTopbar
          title="کاپیتان"
          // circleHint={captainHint}
          showKv={false}
        />
        <Share
          active={showSharing}
          setClose={(close) => setShowSharing(close)}
          title="ارسال دعوتنامه"
          value={`
      با کد کارتک من در کارتک ثبت‌نام کن و KV رایگان بگیر! 🎉
      \n کد کارتک من: ${userInfo.inviteCode}\n
      \n لینک ورود به کارتک : \n ${`https://app.kartaak.com/login?${userInfo.inviteCode}`} \n
      \n وبسایت کارتک : 
      `}
          link={`https://kartaak.com`}
        />

        {/*<Modal active={captainModal} close={() => setCaptainModal(false)}>*/}
        {/*  <div className="captain__modal">*/}
        {/*    <div className="captain__modal__title">*/}
        {/*      <span>شرایط کاپیتان شدن</span>*/}
        {/*    </div>*/}
        {/*    <div className="captain__modal__wrapper">*/}
        {/*      <div className="captain__modal__box">*/}
        {/*        <img src={captain} alt="" height="68px" width="75px" />*/}
        {/*        <span className="captain__modal__box__title">کاپیتان</span>*/}
        {/*        <span className="captain__modal__box__info">*/}
        {/*          هر کاربر 7 ستاره ای که حداقل 4 نفر با معرفی مستقیم او در کارتک*/}
        {/*          ثبت نام کرده باشند و گروهش حداقل 50 نفر عضو داشته باشد، کاپیتان*/}
        {/*          کارتک خواهد شد. کاپیتان های کارتک، ماهانه 25 KM شخصی (سفید)*/}
        {/*          جایزه می گیرند.*/}
        {/*        </span>*/}
        {/*      </div>*/}
        {/*      <div*/}
        {/*        className="captain__modal__action"*/}
        {/*        onClick={() => handleSetCaptain()}*/}
        {/*      >*/}
        {/*        <span>کاپیتان شدن</span>*/}
        {/*        <SVG svg="checked" />*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</Modal>*/}

        {/*<Modal hint={'getKv'} active={captainHintModal} close={() => setCaptainHintModal(false)}>*/}
        {/*  <div className="captain__modal">*/}
        {/*    <div className="captain__modal__title">*/}
        {/*      <span>راهنمای کاپیتان</span>*/}
        {/*    </div>*/}
        {/*    <div className="px-16 mb-16">*/}
        {/*      <span className="captain__modal__box__info">*/}
        {/*        هر کاربر هفت ستاره ای که حداقل چهار نفر با معرفی مستقیم او در*/}
        {/*        کارتک ثبت نام کرده و اعضای گروهش حداقل به پنجاه نفر رسیده باشد*/}
        {/*        میتواند کاپیتان کارتک شده و ماهانه ۲۵ km سفید به عنوان جایزه*/}
        {/*        دریافت نماید. این شرایط ممکن است تغییر کند ولی تاثیری در رتبه های*/}
        {/*        به دست آمده ندارد و کاربری که یک بار کاپیتان شود برای همیشه این*/}
        {/*        رتبه را خواهد داشت.*/}
        {/*      </span>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</Modal>*/}
        {/*<Card>*/}
        {/*  /!*<div className="title">کد کارتک شما</div>*!/*/}
        {/*  <div className="row j-sb a-c mb-8">*/}
        {/*    <div className="row a-c">*/}
        {/*      <div className="panel__box__info__title">کد کارتک شما:</div>*/}
        {/*      <input*/}
        {/*        readOnly*/}
        {/*        type="text"*/}
        {/*        value={userInfo.inviteCode}*/}
        {/*        id="panel_code"*/}
        {/*        className="panel__box__input"*/}
        {/*      />*/}

        {/*    </div>*/}

        {/*    <div className="row a-c g-6">*/}
        {/*      <div className="panel__box__info__icon" onClick={() => copy()}>*/}
        {/*        <SVG svg="copy"/>*/}
        {/*      </div>*/}
        {/*      <div*/}
        {/*        className="panel__box__info__icon shared"*/}
        {/*        onClick={() => setShowSharing(true)}*/}
        {/*      >*/}
        {/*        <SVG svg="share"/>*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  </div>*/}

        {/*  /!*<div className="friends-invite__tools">*!/*/}
        {/*  /!*  <div className="friends-invite__item">*!/*/}
        {/*  /!*    /!* <span>کد دعوت شما</span> *!/*!/*/}
        {/*  /!*    <input*!/*/}
        {/*  /!*      type="text"*!/*/}
        {/*  /!*      value={userInfo.inviteCode}*!/*/}
        {/*  /!*      id="invite_code"*!/*/}
        {/*  /!*      readOnly*!/*/}
        {/*  /!*    />*!/*/}
        {/*  /!*  </div>*!/*/}

        {/*  /!*  <div*!/*/}
        {/*  /!*    className="friends-invite__item friends-invite__copy"*!/*/}
        {/*  /!*    onClick={() => copy()}*!/*/}
        {/*  /!*  >*!/*/}
        {/*  /!*    کپی*!/*/}
        {/*  /!*    <SVG svg="copy"/>*!/*/}
        {/*  /!*  </div>*!/*/}

        {/*  /!*  <div*!/*/}
        {/*  /!*    className="friends-invite__item friends-invite__share"*!/*/}
        {/*  /!*    onClick={() => setShowSharing(true)}*!/*/}
        {/*  /!*  >*!/*/}
        {/*  /!*    <SVG svg="share"/>*!/*/}
        {/*  /!*  </div>*!/*/}
        {/*  /!*</div>*!/*/}

        {/*  {caller?.mobile_number === null && (*/}
        {/*    <div className="friends-invite__introduced">*/}
        {/*      <div className="title mt-16">کد یا شماره موبایل معرف</div>*/}
        {/*      <input*/}
        {/*        type="text"*/}
        {/*        placeholder="اگر کد یا شماره موبایل معرف دارید، وارد نمایید."*/}
        {/*        value={inviteCode}*/}
        {/*        onChange={(e) => setInviteCode(e.target.value)}*/}
        {/*      />*/}
        {/*      <div*/}
        {/*        className={`friends-invite__introduced__action ${inviteCode.length !== 0 && "active"*/}
        {/*        }`}*/}
        {/*        onClick={() => !inviteCodeLoading && handleSetPresenter()}*/}
        {/*      >*/}
        {/*        {inviteCodeLoading ? <Dots color="light"/> : "ثبت معرف"}*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  )}*/}
        {/*</Card>*/}

        {/*<div className="trove__statistics__new" onClick={() => history.push('/new-trove')}>*/}
        {/*  <div className="trove__statistics__new__item">*/}
        {/*    <div className="trove__statistics__new__item__value">*/}
        {/*      {stampList?.userWinners[0]?.registeringCounter}*/}
        {/*    </div>*/}
        {/*    <div className="trove__statistics__new__item__title">*/}
        {/*      امتیاز*/}
        {/*    </div>*/}
        {/*  </div>*/}

        {/*  <div className="trove__statistics__new__item">*/}
        {/*    <div className="trove__statistics__new__item__value">*/}
        {/*      {stampList?.userWinners[0]?.factor}*/}
        {/*    </div>*/}
        {/*    <div className="trove__statistics__new__item__title">*/}
        {/*      ضریب*/}
        {/*    </div>*/}
        {/*  </div>*/}

        {/*  <div className="trove__statistics__new__item">*/}
        {/*    <div className="trove__statistics__new__item__value">*/}
        {/*      {stampList?.userWinners[0]?.score}*/}
        {/*    </div>*/}
        {/*    <div className="trove__statistics__new__item__title">*/}
        {/*      ارزش*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</div>*/}

        {user?.isCaptain !== 1 ? (
          <Card>
            <div className={`captain ${user?.isCaptain === 1 && "active"}`}>
              {/*purple*/}
              <div className="captain__body">
                <div className="captain__body__text">{captainHint}</div>
                <div className="captain__body__col1 col">
                  <div
                    className="col a-c"
                    onClick={() => user?.isCaptain !== 1 && handleSetCaptain()}
                  >
                    <img
                      src={user?.isCaptain === 1 ? captain : captain2}
                      alt=""
                      height="102px"
                      width="112.5px"
                    />
                    <span className="captain__body__col1__description__title">
                      اینجا رو بزن تا کاپیتان بشی
                    </span>
                  </div>
                  {/*<div className="captain__body__col1__description">*/}
                  {/*  <span className="captain__body__col1__description__bio">*/}
                  {/*    {secondHint.content}*/}
                  {/*  </span>*/}
                  {/*</div>*/}
                </div>

                {/*TODO Captain*/}

                <div className="captain__body__show-nums">
                  {userFriendsNewLoading ? (
                    <Spinner active={userFriendsNewLoading} color="dark" />
                  ) : userDirectInvite.length !== 0 ? (
                    userDirect &&
                    userDirectInvite.map((user, index) =>
                      index < userDirect ? (
                        <div style={{ width : "100%"}} className="captain__body__show-nums__friend">
                          <div>
                            <span>{user.full_name}</span>
                            <span>{user.mobile_number}</span>
                          </div>
                          <div>
                            <span>
                              {moment(user.join_at, "YYYY/MM/DD")
                                .locale("fa")
                                .format("YYYY/MM/DD")}
                            </span>
                          </div>
                        </div>
                      ) : null
                    )
                  ) : (
                    <p>کاربری یافت نشد</p>
                  )}
                </div>
                {/* <div className="captain__body__col2"> */}
                {/* User Level Star */}
                {/* <div className="captain__frame">
                    <span>
                      ستاره: */}
                {/* {Number(user.detail?.star) >=
                    Number(captainConditionStatus.star) ? (
                    <>شما {user?.detail?.star} ستاره‌اید</>
                  ) : (
                    <>
                      {Number(captainConditionStatus.star) -
                        Number(user.detail?.star)}{" "}
                      ستاره تا کاپیتانی
                    </>
                  )} */}
                {/* </span>
                    <div className="captain__level">
                      {["", "", "", "", "", "", ""].map((item, index) => (
                        <div
                          className={`captain__level__star ${index + 1 <= user?.detail?.star && "active"
                            }`}
                        >
                          <SVG svg="captain-star" />
                        </div>
                      ))}
                    </div>
                  </div> */}
                {/* Direct Introduction */}
                {/* <div className="captain__frame">
                    <span>
                      {user.isCaptain
                        ? `کاپیتان های من:`
                        : "دوستان من:"}
                    </span> */}
                {/* {4 - userTotalInvited > 0 && (
                  <div className="captain__team__item rectangle">
                    <span>{4 - userTotalInvited} نفر تا کاپیتانی</span>
                  </div>
                )} */}
                {/* <div className="row" style={{ direction: "ltr" }}> */}
                {/* <div className="captain__user" onClick={() => { setShowFriends(true); window.scrollTo(0, 580); }}>
                    <SVG svg="captain-more" />
                  </div> */}
                {/* {["", "", "", "", "", "", "", "", "", "", "", "", ""].map(
                        (friendItem, index) =>
                          index <= Number(captainConditionStatus.direct) - 1 && (
                            <div div className="captain__user" key={index}>
                              {index > friendsList.length - 1 ? (
                                <SVG svg="profile-user" />
                              ) : (
                                <span>
                                  <SVG svg="profile-user" />
                                </span>
                              )}
                            </div>
                          )
                      )}
                    </div>
                  </div> */}
                {/* <div className="captain__frame">
                  <span>
                    {user.isCaptain ? "تعداد کل کاپیتان های تیم:" : "اعضای گروه:"}
                  </span>
                  <div className="captain__team">
                    <div
                      className={`captain__team__item rectangle ${user?.isCaptain === 1 && "active"
                        } ${Number(captainConditionStatus.total) - userTotalInvited <=
                        0 && "active"
                        }`}
                    >
                      {user.isCaptain === 1 ? (
                        <span>{captainTeamCount}</span>
                      ) : Number(captainConditionStatus.total) -
                        userTotalInvited <=
                        0 ? (
                        <span>
                          {captainConditionStatus.total} <span>+</span>
                        </span>
                      ) : (
                        <span>
                          {Number(captainConditionStatus.total) -
                            userTotalInvited}{" "}
                          -
                        </span>
                      )}
                    </div>
                  </div>
                </div> */}
                {/* </div> */}

                {/* <div className="captain__alert">
                  این شرایط ممکن است تغییر کند ولی هر کاربر با هر شرایطی که این رتبه را کسب نماید برای همیشه کاپیتان کارتک
                  بوده و از مزایای آن بهره مند خواهد شد.
                </div> */}
              </div>
              {/*<div*/}
              {/*  className="captain__footer"*/}
              {/*  onClick={() => user?.isCaptain !== 1 && handleSetCaptain()}*/}
              {/*>*/}
              {/*  /!* {user?.isCaptain === 1 ? (*/}
              {/*    <>*/}
              {/*      <span>شما کاپیتان کارتک هستید!</span>*/}
              {/*      <SVG svg="checked" />*/}
              {/*    </>*/}
              {/*  ) : ( *!/*/}
              {/*  <>*/}
              {/*    {convertCaptian ? (*/}
              {/*      <span>کاپیتان شو</span>*/}
              {/*    ) : (*/}
              {/*      <span>درخواست کاپیتان شدن</span>*/}
              {/*    )}*/}
              {/*    <SVG svg={convertCaptian ? "unlock" : "lock"}/>*/}
              {/*  </>*/}
              {/*</div>*/}
              <div
                className="captain__footer__stats"
                onClick={() => history.push("/new-trove")}
              >
                {/*<div className="trove__statistics__new" onClick={() => history.push('/new-trove')}>*/}
                {/* <div className="trove__statistics__new__item">
                  <div className="trove__statistics__new__item__value">
                    {stampList?.userWinners[0]?.registeringCounter}
                  </div>
                  <div className="trove__statistics__new__item__title">
                    امتیاز
                  </div>
                </div>

                <div className="trove__statistics__new__item">
                  <div className="trove__statistics__new__item__value">
                    {stampList?.userWinners[0]?.factor}
                  </div>
                  <div className="trove__statistics__new__item__title">
                    ضریب
                  </div>
                </div>

                <div className="trove__statistics__new__item">
                  <div className="trove__statistics__new__item__value">
                    {stampList?.userWinners[0]?.score}
                  </div>
                  <div className="trove__statistics__new__item__title">
                    ارزش
                  </div>
                </div> */}
                {/*</div>*/}
              </div>
            </div>
          </Card>
        ) : (
          <Card>
            {/* <SlidingHint
                openTitle="راهنما"
                open={openSlidingHint}
                onClick={() => {
                  setOpenSlidingHint(!openSlidingHint);
                }}
                text={captainHint}
              /> */}
            <div className={`captain `}>
              {/*purple*/}
              <div className="captain__body">
                <div className="captain__body__text">{captainHint}</div>
                <div className="captain__body__col1 col">
                  <div
                    className="col a-c mb-8"
                    onClick={() => user?.isCaptain !== 1 && handleSetCaptain()}
                  >
                    <img
                      src={user?.isCaptain === 1 ? captain : captain2}
                      alt=""
                      height="102px"
                      width="112.5px"
                    />
                    <span className="captain__body__col1__description__title">
                      تبریک! شما کاپیتان کارتک هستید
                    </span>
                  </div>
                  <div className="captain__body__show-nums">
                    {userFriendsNewLoading ? (
                      <Spinner active={userFriendsNewLoading} color="dark" />
                    ) : userDirectInvite.length != 0 ? (
                      userDirect &&
                      userDirectInvite.map((user, index) =>
                        index < userDirect ? (
                          <div className="captain__body__show-nums__friend">
                            <div>
                              <span>{user.full_name}</span>
                              <span>{user.mobile_number}</span>
                            </div>
                            <div>
                              <span>
                                {moment(user.join_at, "YYYY/MM/DD")
                                  .locale("fa")
                                  .format("YYYY/MM/DD")}
                              </span>
                            </div>
                          </div>
                        ) : null
                      )
                    ) : (
                      <p>کاربری یافت نشد</p>
                    )}
                  </div>
                </div>
              </div>
              {/*<div*/}
              {/*  className="captain__footer"*/}
              {/*  onClick={() => user?.isCaptain !== 1 && handleSetCaptain()}*/}
              {/*>*/}
              {/*  {user?.isCaptain === 1 ? (*/}
              {/*    <>*/}
              {/*      <span>شما کاپیتان کارتک هستید!</span>*/}
              {/*      <SVG svg="checked"/>*/}
              {/*    </>*/}
              {/*  ) : (*/}
              {/*    <>*/}
              {/*      {convertCaptian ? (*/}
              {/*        <span>کاپیتان شدن</span>*/}
              {/*      ) : (*/}
              {/*        <span>شما شرایط کاپیتان شدن را ندارید</span>*/}
              {/*      )}*/}
              {/*      <SVG svg={convertCaptian ? "unlock" : "lock"}/>*/}
              {/*    </>*/}
              {/*  )}*/}
              {/*</div>*/}

              {/* <div
                                className="captain__footer__stats"
                                onClick={() => history.push("/new-trove")}
                            >
                                <div className="trove__statistics__new__item">
                                    <div className="trove__statistics__new__item__value">
                                        {
                                            stampList?.userWinners[0]
                                                ?.registeringCounter
                                        }
                                    </div>
                                    <div className="trove__statistics__new__item__title">
                                        امتیاز
                                    </div>
                                </div>

                                <div className="trove__statistics__new__item">
                                    <div className="trove__statistics__new__item__value">
                                        {stampList?.userWinners[0]?.factor}
                                    </div>
                                    <div className="trove__statistics__new__item__title">
                                        ضریب
                                    </div>
                                </div>

                                <div className="trove__statistics__new__item">
                                    <div className="trove__statistics__new__item__value">
                                        {stampList?.userWinners[0]?.score}
                                    </div>
                                    <div className="trove__statistics__new__item__title">
                                        ارزش
                                    </div>
                                </div>
                            </div> */}
              {/*<div className="captain__more">*/}
              {/*  <Hint onClick={() => setCaptainHintModal(true)}/>*/}
              {/*</div>*/}
            </div>
          </Card>
        )}
        {/* <Padding>
          {caller?.mobile_number !== null && (
            <>
              <div className="title">دعوت شده توسط</div>
              <div className="friends__list">
                <div className="friends__list__item">
                  <div className="friends__list__image"></div>
                  <div className="col">
                    <div className="friends__list__name">{caller?.name}</div>
                    <div className="friends__list__phone">
                      {caller?.mobile_number}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </Padding> */}

        {/* {showFriends && (
          <Card>
            <Tabs
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              items={[
                {
                  title: "صف دعوت",
                  icon: 'addFriend'
                },
                {
                  title: "دوستان من",
                  icon: 'myFriends'
                },
              ]}
            />

            {activeTab === 2 && <Padding>
              <div className="title">
                دوستان من
                <span style={{ marginRight: 5, fontWeight: 300, fontSize: 12 }}>
                  ( {friendsList.length} نفر )
                </span>
              </div>

              {friendsList.length === 0 && (
                <div className="friends__not-found">
                  <div className="friends__not-found__logo">
                    <SVG svg="friends" />
                  </div>
                  <span className="friends__not-found__title">
                    دوستی وجود ندارد
                  </span>
                  <span className="friends__not-found__description">
                    با فرستادن دعوت نامه دوستان خود را دعوت کنید
                  </span>
                </div>
              )}

              <div className="friends__list">
                {friendsList.map((userFriend, index) => (
                  <div className="friends__list__item" key={index}>
                    <div className="friends__list__image" />
                    <div className={`friends__list__captain ${userFriend.is_captain && 'show'}`}>
                      <img src={captain} alt="" height="60px" width="62px" />
                    </div>
                    <div className="col" style={{ width: "100%" }}>
                      <div className="friends__list__name">
                        {userFriend.full_name}
                      </div>

                      <div className="friends__list__phone">
                        {userFriend.mobile_number}
                      </div>
                    </div>

                    <div className="friends__list__date">
                      {moment(userFriend.join_at, "YYYY/MM/DD")
                        .locale("fa")
                        .format("YYYY/MM/DD")}
                    </div>
                  </div>
                ))}
              </div>
            </Padding>}
            {activeTab === 1 && (
              <div className="friends-invite__box mt-8">
                <div className="friends-invite__max mb-8">
                  <SVG svg={"mobile-tick"} />
                  شماره موبایل دوستان خود را در صف دعوت قرار دهید تا بعد از ثبت‌نام آنها امتیاز بگیرید
                </div>

                {(userFirstName && userLastName) ? (
                  <div className="friends-invite__input">
                    <input
                      type="number"
                      className="input"
                      value={phoneNumber}
                      onChange={(e) =>
                        setPhoneNumber(farsiDigitToEnglish(e.target.value))
                      }
                      placeholder="شماره موبایل را وارد کنید مانند: 09123456789"
                    />
                    <div onClick={() => {
                      if (addedNubmers.length === maxIntroduced) {
                        toast.error(`شما نمی‌توانید همزمان بیشتر از ${maxIntroduced} نفر در صف دعوت داشته باشید`);
                        return;
                      }
                      if (!(phoneNumber.length === 11 && phoneNumber.match(/[0-9]+/g))) {
                        toast.error("شماره موبایل را به درستی وارد کنید");
                        return;
                      }
                      if (userFirstName && userLastName) {
                        setConfirmModal(true);
                      } else {
                        setShowUpdate(true);
                      }
                    }} className={`friends-invite__add ${addedNubmers.length === maxIntroduced && 'disable'}`}>
                      ثبت
                    </div>
                  </div>
                ) : (
                  <div onClick={() => setShowUpdate(true)} className="friends-invite__add-circle">
                    <SVG svg={'plus'} />
                  </div>
                )}

                {addedNubmers.length >= maxIntroduced && <div
                  className="friends-invite__max-red mb-8">{`شما نمی‌توانید همزمان بیشتر از ${maxIntroduced} نفر در صف دعوت داشته باشید`}
                </div>}

                <div className="friends-invite__line" />

                {addedNubmers.length > 0 && addedNubmers.map(number => (
                  <div className="friends-invite__added">
                    <div className="friends-invite__added__number">{number.mobile_number}</div>
                    <div className="friends-invite__added__delete">
                      هنوز ثبت نام نکرده است
                    </div>
                  </div>
                ))}
              </div>
            )}

          </Card>
        )} */}
      </div>
    </Fade>
  );
};

export default Friends;
