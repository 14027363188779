import debounce from "lodash.debounce";
import React, { useCallback, useEffect, useState } from "react";
import { devIp, getCategoriesAPI, getProductsAPI } from "../../utils/Services/API";
import { Spinner } from "components";
import "./Search.scss";
import { Link } from "react-router-dom";
import ProductImage from "../../assets/images/product.png";
import Product from "pages/Product";

export const Search = ({
  value = "",
  topPosition = false,
  setValue,
  setFilter,
  filter,
}) => {
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);

  const getProducts = (inputValue) => {
    setLoading(true);
    const APIResult = getProductsAPI({ query: inputValue });
    APIResult.then((response) => {
      setProducts(response.data.data.data);
      setLoading(false);
    });
  };

  const getCategories = () => {
    const apiResult = getCategoriesAPI();
    apiResult.then((response) => {
      if (response.status >= 200 && response.status < 300) {
        setCategories(Object.entries(response.data.data).map(category => category[1]));
      }
    });
  };

  const debounceHandler = useCallback(
    debounce((value) => {
      if (value.length > 0) {
        getProducts(value);
        document.body.style.overflowY = "hidden";
      } else {
        // setFilter(filter);
        document.body.style.overflowY = "auto";
      }
    }, 700),
    []
  );

  const handleCloseSearch = (closeAreaId) => {
    if (closeAreaId === "closeArea") {
      setValue("");
      setFilter(filter);
      document.body.style.overflowY = "auto";
    }
  };

  useEffect(() => {
    if (value.length > 0) {
      categories.length === 0 &&
        getCategories();
      setLoading(true);
      setFilter(false);
    } else {
      setProducts([]);
    }
    debounceHandler(value);
  }, [value]);

  useEffect(() => {
    // getCategories()
  }, []);

  return (
    <div
      className={`search-section ${value.length > 0 && "active"}`}
      id="search-section"
    >

      <div className="search-section__wrapper">
        <div className="search-section__categories__title">دسته‌بندی‌ها: </div>
        <div className="search-section__categories">
          {categories.filter(category => { return category.title.includes(value) })
            .map(category => (
              <Link
                to={`category?${category.id}`}
                className="search-section__category"
                onClick={() => document.body.style.overflowY = "auto"}
              >
                {category.title}
              </Link>
            ))}
        </div>

        {loading ? (
          <div className="search-section__loading">
            <Spinner color="dark" active={loading} isFullscreen={false} a />
          </div>
        ) : products.length === 0 ? (
          <div className="search-section__container">
            <div className="search-section__title">نتایج جستجو :</div>
            <div className="search-section__notfound">
              کالایی با این مشخصات پیدا نشد.
            </div>
          </div>
        ) : (
          <div className="search-section__container">
            <div className="search-section__title">نتایج جستجو :</div>
            <div className="search-section__products">
              {products.map((product, index) => (
                <Link
                  className="search-section__product"
                  key={index}
                  to={`/product/${product.id}`}
                  onClick={() => document.body.style.overflowY = "auto"}
                >
                  <div className="search-section__product__image">
                    <img
                      loading="lazy"
                      src={
                        product.image && product.image[0]
                          ? `${product.image[0]}`
                          // ? `data:image/png;base64,${product.image[0]}`
                          : ProductImage
                      }
                      id={index}
                      onError={(e) => {
                        const image = document.getElementById(index);
                        image.src = ProductImage;
                      }}
                    />
                  </div>
                  <div className="search-section__product__title">
                    {product.title}
                  </div>
                </Link>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
