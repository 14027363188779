import "./AcceptRule.scss";

export const AcceptRule = ({ onCLick = () => {}, title = "", color = "" }) => {
    return (
        <div className="accept-rule">
            <span
                className={`accept-rule__text ${color}`}
                onClick={() => onCLick()}
            >
                <span className="accept-rule__highlight">
                    قوانین و مقررات {title}
                    &nbsp;
                </span>
                را مطالعه کرده و می‌پذیرم.
            </span>
        </div>
    );
};
