import {KvAssignContainer} from 'containers'
const KvAssign = ({children}) => {
  return (
      <KvAssignContainer>
          {children}
      </KvAssignContainer>
  )
};

export default KvAssign;
