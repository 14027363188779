import { Request } from "../api-call";

export const getActiveSlRlAPI = async () => {
  const { data } = await Request.get(`api/v3/collaboration/slrl/active`);
  return data;
};

export const getAllSlRlAPI = async () => {
  const { data } = await Request.get(`api/v3/collaboration/slrl/all`);
  return data;
};

export const getSlHistoryAPI = async slId => {
  const { data } = await Request.get(`/api/v3/collaboration/slrl/sl/logs/${slId}`);
  return data;
};

export const getRlHistoryAPI = async rlId => {
  const { data } = await Request.get(`/api/v3/collaboration/slrl/rl/logs/${rlId}`);
  return data;
};

export const getUnitHistoryAPI = async () => {
  const { data } = await Request.get(`/api/v3/collaboration/slrl/unitLog`);
  return data;
};