import ProductImage from "../../../../assets/images/product.png";

export const Cover = ({ data, setCurrentImage }) => {
  return (
    <div className="product__header">
      <div className="product__gallery">
        {data &&
          data.length > 0 &&
          data.map(
            (productImage, index) =>
              index < 4 && (
                <div className="product__gallery__item">
                  <img
                    onClick={() => setCurrentImage(productImage)}
                    src={`${productImage}`}
                    // src={`data:image/png;base64,${productImage}`}
                    alt=""
                    className="product__header__image"
                    key={index}
                    height={210}
                  />
                </div>
              )
          )}
      </div>
      <div className="product__image">
        <img
          onClick={() => setCurrentImage(true)}
          src={`${data[0]}`}
          alt=""
          id={"1"}
          onError={(e) => {
            const image = document.getElementById("1") as HTMLImageElement;
            image.src = ProductImage;
          }}
        />
      </div>
    </div>
  );
};
