import {
    Card,
    CheckBox,
    Dots,
    Fade,
    PageTopbar,
    Selectbox,
    Spinner,
    SVG,
} from "../../components";
import React, { useCallback, useEffect, useState } from "react";
import {
    getZeroCategoryAPI,
    getFirstCategoryAPI,
    getStatesAPI,
    getSecondCategoryAPI,
} from "../../utils";
import toast from "react-hot-toast";
import { debounce } from "lodash";
import { useHistory, useParams } from "react-router-dom";
import {
    getMyUnitInfoAPI,
    getStoresByCityAPI,
} from "../../utils/Services/KartlandUnits";
import { searchProductByNameAPI } from "utils/Services/KartlandUnits/SearchProductByName";
import { getProductsListAPI } from "utils/Services/KartlandUnits/GetProductsList";
import { getCategoriesAPI } from "utils/Services/API";
import { getProductsListByCategoryAPI } from "utils/Services/KartlandUnits/GetProductsListByCategory";

export const KartlandRentContainer = () => {
    const params: any = useParams();
    const history = useHistory();

    const [productsList, setProductsList] = useState({
        products: [],
        productsWithoutUnit: [],
    });

    const [unitDetails, setUnitDetails] = useState<any>();
    const [unitDetailsLoading, setUnitDetailsLoading] = useState(true);
    const [data, setData] = useState<any>({
        subCities: [],
        zeroCategories: [],
        firstCategories: [],
    });
    const [zeroCats, setZeroCats] = useState([]);
    const [firstCats, setFirstCats] = useState([]);
    const [secondCats, setSecondCats] = useState([]);
    const [unitDetail, setUnitDetail] = useState({
        zeroCategories: 0,
        firstCategories: 0,
        secondCategories: 0,
    });
    const [loading, setLoading] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const [isAttached, setIsAttached] = useState(0);

    const getMyUnitInfo = () => {
        setUnitDetailsLoading(true);
        getMyUnitInfoAPI(params.id).then((res) => {
            if (res.status === 200) {
                setUnitDetails(res.data);
                setUnitDetailsLoading(false);
            }
        });
    };

    const getZeroCategories = () => {
        getZeroCategoryAPI()
            .then((response) => {
                // setData({...data, zeroCategories: response.data});
                setZeroCats(response.data);
            })
            .catch(() => {
                toast.error("خطا در بارگذاری دسته بندی ها");
            })
            .finally(() => {});
    };

    const getFirstCategories = (zeroCat) => {
        getFirstCategoryAPI(zeroCat)
            .then((response) => {
                // setData({...data, firstCategories: response.data});
                setFirstCats(response.data);
            })
            .catch(() => {
                toast.error("خطا در بارگذاری دسته بندی ها");
            })
            .finally(() => {});
    };

    const getSecondCategories = (firstCat) => {
        getSecondCategoryAPI(firstCat)
            .then((response) => {
                // setData({...data, firstCategories: response.data});
                setSecondCats(response.data);
            })
            .catch(() => {
                toast.error("خطا در بارگذاری دسته بندی ها");
            })
            .finally(() => {});
    };

    const getProductsList = () => {
        setLoading(true);
        getProductsListAPI(isAttached)
            .then((response) => {
                setProductsList(response.data);
            })
            .catch(() => toast.error("خطا در بارگذاری محصولات"))
            .finally(() => setLoading(false));
    };

    const getProductsListByCategory = () => {
        setLoading(true);
        if (
            unitDetail.zeroCategories &&
            unitDetail.firstCategories === 0 &&
            unitDetail.secondCategories === 0
        ) {
            getProductsListByCategoryAPI(unitDetail.zeroCategories)
                .then((response) => {
                    console.log(response);
                    setProductsList(response.data);
                })
                .catch(() => toast.error("خطا در بارگذاری محصولات"))
                .finally(() => setLoading(false));
        } else if (
            unitDetail.zeroCategories &&
            unitDetail.firstCategories &&
            unitDetail.secondCategories === 0
        ) {
            getProductsListByCategoryAPI(unitDetail.firstCategories)
                .then((response) => {
                    setProductsList(response.data);
                })
                .catch(() => toast.error("خطا در بارگذاری محصولات"))
                .finally(() => setLoading(false));
        } else if (
            unitDetail.zeroCategories &&
            unitDetail.firstCategories &&
            unitDetail.secondCategories
        ) {
            getProductsListByCategoryAPI(unitDetail.secondCategories)
                .then((response) => {
                    setProductsList(response.data);
                })
                .catch(() => toast.error("خطا در بارگذاری محصولات"))
                .finally(() => setLoading(false));
        }
    };

    const searchInputDebounce = useCallback(
        debounce((value) => {
            searchProductByNameAPI(value)
                .then((response) => {
                    if (response) {
                        setProductsList(response.data);
                    }
                })
                .catch((err) => {
                    if (err) {
                        toast.error("به مشکل برخوردیم");
                    }
                })
                .finally(() => [setLoading(false)]);
        }, 850),
        []
    );

    const updateSearchInput = (value) => {
        setLoading(true);
        setSearchValue(value);
        if (value.length > 0) {
            searchInputDebounce(value);
        } else if (value.length == 0) {
            setUnitDetail({
                zeroCategories: 0,
                firstCategories: 0,
                secondCategories: 0,
            });
            getProductsList();
        }
    };

    useEffect(() => {
        getProductsList();
        getMyUnitInfo();
        getZeroCategories();
    }, []);

    useEffect(() => {
        getProductsList();
    }, [
        // unitDetail.city,
        // unitDetail.subCity,
        // unitDetail.firstCategories,
        isAttached,
    ]);

    useEffect(() => {
        getProductsListByCategory();
    }, [
        unitDetail.zeroCategories,
        unitDetail.firstCategories,
        unitDetail.secondCategories,
    ]);

    useEffect(() => {
        history.replace(`/kartland/rent/${params.id}`);
    }, []);

    return (
        <div className="kartland">
            <PageTopbar title="انتخاب محصول" showKv={false} white={true} />
            <Spinner active={unitDetailsLoading} color={"dark"} />
            {unitDetailsLoading === false && (
                <>
                    <div className={"p-16"}>
                        <div className="kartland__rent__search">
                            <div className="shopheader__input__icon">
                                <SVG svg={"input-search"} />
                            </div>
                            <input
                                type="text"
                                className=""
                                onChange={(event) =>
                                    updateSearchInput(event.target.value)
                                }
                                value={searchValue}
                                placeholder="اگر نام محصول را میدانید وارد کنید"
                            />
                        </div>
                    </div>

                    <div className={"p-16"}>
                        {searchValue.length === 0 && (
                            <div className={"col"}>
                                {/* <div
                                    style={{ color: "white" }}
                                    className="flex j-se mb-30"
                                >
                                    <div className="">فیلتر بر اساس:</div>
                                    <div className="flex">
                                        <div className=""></div>
                                        <CheckBox
                                            disable
                                            defaultValue={isAttached === 0}
                                            onChange={(value) => {
                                                if (value) {
                                                    setIsAttached(0);
                                                }
                                            }}
                                        />
                                        <div className="fill-profile__form__title">
                                            همه
                                        </div>
                                    </div>
                                    <div className="flex">
                                        <CheckBox
                                            disable
                                            defaultValue={isAttached === 1}
                                            onChange={(value) => {
                                                if (value) {
                                                    setIsAttached(1);
                                                }
                                            }}
                                        />
                                        <div className="fill-profile__form__title">
                                            الصاق نشده
                                        </div>
                                    </div>
                                </div> */}
                                <div className={"flex j-sb"}>
                                    <div
                                        className={
                                            "kartland-buy__user-filter col first-filter"
                                        }
                                    >
                                        <div
                                            className={
                                                "kartland-buy__user-filter__item"
                                            }
                                        >
                                            <React.Fragment>
                                                <div className="title white">
                                                    دسته‌بندی اول
                                                </div>
                                                <Selectbox
                                                    data={zeroCats}
                                                    placeholder="انتخاب دسته‌بندی"
                                                    onChange={(value) => {
                                                        setUnitDetail({
                                                            ...unitDetail,
                                                            zeroCategories:
                                                                value,
                                                            firstCategories: 0,
                                                            secondCategories: 0,
                                                        });
                                                        getFirstCategories(
                                                            value
                                                        );
                                                    }}
                                                    onChangeString={(
                                                        value
                                                    ) => {}}
                                                    openAll={true}
                                                    // openAll={
                                                    //     unitDetail.zeroCategories !==
                                                    //     0
                                                    // }
                                                />
                                            </React.Fragment>
                                        </div>

                                        {/*<div className={"kartland-buy__user-filter__item"}>*/}
                                        {/*  <div className="title white">*/}
                                        {/*    کشور*/}
                                        {/*  </div>*/}
                                        {/*  <Selectbox*/}
                                        {/*    data={data.countries}*/}
                                        {/*    placeholder="انتخاب کشور"*/}
                                        {/*    onChange={value => {*/}
                                        {/*      setUnitDetail({...unitDetail, country: value});*/}
                                        {/*      getStates(value);*/}
                                        {/*    }}*/}
                                        {/*    onChangeString={value => {*/}
                                        {/*      console.log(value);*/}
                                        {/*    }}*/}
                                        {/*  />*/}
                                        {/*</div>*/}
                                        {/* <div
                                            className={
                                                "kartland-buy__user-filter__item"
                                            }
                                        >
                                            {unitDetail.country !== 0 && (
                                                <React.Fragment>
                                                    <div className="title white">
                                                        استان
                                                    </div>
                                                    <Selectbox
                                                        data={data.states}
                                                        placeholder="انتخاب استان"
                                                        onChange={(value) => {
                                                            setUnitDetail({
                                                                ...unitDetail,
                                                                state: value,
                                                                city: 0,
                                                                subCity: 0,
                                                            });
                                                            getCities(value);
                                                        }}
                                                        onChangeString={(
                                                            value
                                                        ) => {
                                                            console.log(value);
                                                        }}
                                                        disabled={
                                                            unitDetail.country ===
                                                            0
                                                        }
                                                        openAll={
                                                            unitDetail.state !==
                                                            0
                                                        }
                                                    />
                                                </React.Fragment>
                                            )}
                                        </div>
                                        <div
                                            className={
                                                "kartland-buy__user-filter__item"
                                            }
                                        >
                                            {unitDetail.state !== 0 && (
                                                <React.Fragment>
                                                    <div className="title white">
                                                        شهرستان
                                                    </div>
                                                    <Selectbox
                                                        data={data.cities}
                                                        placeholder="انتخاب شهرستان"
                                                        onChange={(value) => {
                                                            setUnitDetail({
                                                                ...unitDetail,
                                                                city: value,
                                                                subCity: 0,
                                                            });
                                                            getSubCities(value);
                                                        }}
                                                        onChangeString={(
                                                            value
                                                        ) => {
                                                            console.log(value);
                                                        }}
                                                        disabled={
                                                            unitDetail.state ===
                                                            0
                                                        }
                                                        openAll={
                                                            unitDetail.city !==
                                                            0
                                                        }
                                                    />
                                                </React.Fragment>
                                            )}
                                        </div> */}

                                        {/* <div
                                            className={
                                                "kartland-buy__user-filter__item"
                                            }
                                        >
                                            {unitDetail.city !== 0 && (
                                                <React.Fragment>
                                                    <div className="title white">
                                                        شهر
                                                    </div>
                                                    <Selectbox
                                                        data={data.subCities}
                                                        placeholder="انتخاب شهر"
                                                        onChange={(value) => {
                                                            setUnitDetail({
                                                                ...unitDetail,
                                                                subCity: value,
                                                            });
                                                        }}
                                                        onChangeString={(
                                                            value
                                                        ) => {
                                                            console.log(value);
                                                        }}
                                                        disabled={
                                                            unitDetail.city ===
                                                            0
                                                        }
                                                        openAll={
                                                            unitDetail.subCity !==
                                                            0
                                                        }
                                                    />
                                                </React.Fragment>
                                            )}
                                        </div> */}
                                    </div>

                                    {/*<div onClick={() => setShowFilters(!showFilters)} className="kartland__rent__filter">*/}
                                    {/*  <SVG svg={showFilters ? 'negative' : 'plus'}/>*/}
                                    {/*  <span>فیلترها</span>*/}
                                    {/*</div>*/}
                                    {/*{showFilters && (*/}

                                    <div
                                        className={
                                            "kartland-buy__user-filter col first-filter"
                                        }
                                    >
                                        <div
                                            className={
                                                "kartland-buy__user-filter__item"
                                            }
                                        >
                                            {unitDetail.zeroCategories !== 0 &&
                                                firstCats.length > 0 && (
                                                    <React.Fragment>
                                                        <div className="title white">
                                                            دسته‌بندی دوم
                                                        </div>
                                                        <Selectbox
                                                            data={firstCats}
                                                            resetValue={
                                                                unitDetail.firstCategories ===
                                                                0
                                                            }
                                                            placeholder="انتخاب دسته‌بندی"
                                                            onChange={(
                                                                value
                                                            ) => {
                                                                setUnitDetail({
                                                                    ...unitDetail,
                                                                    firstCategories:
                                                                        value,
                                                                    secondCategories: 0,
                                                                });
                                                                getSecondCategories(
                                                                    value
                                                                );
                                                            }}
                                                            onChangeString={(
                                                                value
                                                            ) => {}}
                                                            disabled={
                                                                unitDetail.zeroCategories ===
                                                                0
                                                            }
                                                            openAll={true}
                                                        />
                                                    </React.Fragment>
                                                )}
                                        </div>
                                    </div>

                                    <div
                                        className={
                                            "kartland-buy__user-filter col first-filter"
                                        }
                                    >
                                        <div
                                            className={
                                                "kartland-buy__user-filter__item"
                                            }
                                        >
                                            {unitDetail.zeroCategories !== 0 &&
                                                unitDetail.firstCategories !==
                                                    0 &&
                                                secondCats.length > 0 && (
                                                    <React.Fragment>
                                                        <div className="title white">
                                                            دسته‌بندی سوم
                                                        </div>
                                                        <Selectbox
                                                            data={secondCats}
                                                            resetValue={
                                                                unitDetail.secondCategories ===
                                                                0
                                                            }
                                                            placeholder="انتخاب دسته‌بندی"
                                                            onChange={(
                                                                value
                                                            ) => {
                                                                setUnitDetail({
                                                                    ...unitDetail,
                                                                    secondCategories:
                                                                        value,
                                                                });
                                                            }}
                                                            onChangeString={(
                                                                value
                                                            ) => {}}
                                                            disabled={
                                                                unitDetail.zeroCategories ===
                                                                0
                                                            }
                                                            openAll={true}
                                                        />
                                                    </React.Fragment>
                                                )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                        <div className={"kartland-buy__user-items"}>
                            {loading ? (
                                <div
                                    className={"flex a-c j-c full-width mt-16"}
                                >
                                    <Dots color={"light"} />
                                </div>
                            ) : productsList?.products?.length > 0 ||
                              productsList?.productsWithoutUnit?.length > 0 ? (
                                <div className="kartland__item__wrapper">
                                    {productsList?.products?.map(
                                        (item, index) => (
                                            <div
                                                onClick={() =>
                                                    history.push(
                                                        `/kartland/rent/${params.id}/${item.id}/`
                                                    )
                                                }
                                                className={
                                                    item.unit
                                                        ? "kartland__item-detail-attached"
                                                        : "kartland__item-detail-unattached"
                                                }
                                            >
                                                <div className="kartland__item">
                                                    <div className="kartland__item__cover">
                                                        <img
                                                            style={{
                                                                maxWidth:
                                                                    "100%",
                                                                maxHeight:
                                                                    "100%",
                                                                borderRadius:
                                                                    "14px",
                                                            }}
                                                            src={item.image[0]}
                                                            alt=""
                                                        />
                                                    </div>
                                                    <div className="kartland__item__text">
                                                        <div className="kartland__item__text__title">
                                                            <span className="kartland__item__text__title__value">
                                                                {item.title}
                                                            </span>
                                                            <span className="kartland__item__text__title__slug">
                                                                {/*( {item.address} )*/}
                                                            </span>
                                                        </div>
                                                        <span
                                                            style={{
                                                                color: "#195190",
                                                                fontSize:
                                                                    "12px",
                                                            }}
                                                        >
                                                            تامین کننده :{" "}
                                                            {item.seller}
                                                        </span>
                                                        {/*<div*/}
                                                        {/*  className={`kartland__item__text__status ${*/}
                                                        {/*    item.rented && "rented"*/}
                                                        {/*  }`}*/}
                                                        {/*>*/}
                                                        {/*  <div*/}
                                                        {/*    className={`kartland__item__text__status__dot ${*/}
                                                        {/*      item.rented && "rented"*/}
                                                        {/*    }`}*/}
                                                        {/*  ></div>*/}
                                                        {/*  <span>{item.status}</span>*/}
                                                        {/*</div>*/}
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    )}
                                    {productsList?.productsWithoutUnit?.map(
                                        (item, index) => (
                                            <div
                                                onClick={() =>
                                                    history.push(
                                                        `/kartland/rent/${params.id}/${item.id}/${isAttached}`
                                                    )
                                                }
                                                className="kartland__item-detail"
                                            >
                                                <div className="kartland__item">
                                                    <div className="kartland__item__cover">
                                                        <SVG svg="location" />
                                                    </div>
                                                    <div className="kartland__item__text">
                                                        <div className="kartland__item__text__title">
                                                            <span className="kartland__item__text__title__value">
                                                                {item.title}
                                                            </span>
                                                            <span className="kartland__item__text__title__slug">
                                                                {/*( {item.address} )*/}
                                                            </span>
                                                        </div>
                                                        <div
                                                            className={`kartland__item__text__status`}
                                                        >
                                                            {/* <div
                                                                className={`kartland__item__text__status__dot `}
                                                            ></div>
                                                            <span>
                                                                {
                                                                    "واحد غیرمنطبق"
                                                                }
                                                            </span> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    )}
                                </div>
                            ) : (
                                <div className="kartland__rent__notFound">
                                    محصولی یافت نشد
                                </div>
                            )}
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};
