export const navigationItems = [
    {
        title: "کارتک‌ من",
        type: "profile",
        link: "/profile",
        icon: "profile",
    },
    // {
    //     title: "شهر",
    //     type: "city",
    //     link: "/map",
    //     icon: "city",
    // },
    // {
    //   title: "کارت‌استور",
    //   type: "cards",
    //   link: "/cards",
    //   icon: "profile-card",
    // },
    {
        title: "پاساژ کارتک",
        type: "market",
        link: "/market",
        icon: "profile-shop-new",
    },
    // {
    //   title: "بارکد",
    //   type: "qr",
    //   link: "/qr",
    //   icon: "qr",
    // },
    // {
    //     title: "کارتلا",
    //     type: "kartella",
    //     link: "/kartella",
    //     icon: "kartella",
    // },
    // {
    //   title: "کارتک‌یار",
    //   type: "home",
    //   link: "/collab-intro",
    //   icon: "note-favorite",
    // },
    {
        title: "کارتلند",
        type: "kartland",
        link: "/kartland",
        icon: "kartland",
    },
];

export const kartakAppsItems = [
    // {
    //   title: "کارتک من",
    //   icon: "profile",
    //   link: "/profile",
    // },
    {
        title: "نمایندگی",
        icon: "marketing",
        link: "/collaborate",
    },
    {
        title: "کارت‌استور",
        icon: "profile-card",
        link: "/cards",
    },
    {
        title: "بارکد",
        icon: "qr",
        link: "/qr",
    },
    // {
    //     title: "کارتلا",
    //     icon: "kartella",
    //     link: "/kartella",
    // },
    {
        title: "کارتک‌مارکت",
        icon: "shop",
        link: "/market",
    },
    // {
    //   title: "شارژ",
    //   icon: "apps-charge",
    // },
    // {
    //   title: "اینترنت",
    //   icon: "apps-internet",
    // },
    // {
    //   title: "خدمات",
    //   icon: "apps-services",
    // },
    // {
    //   title: "قبض",
    //   icon: "apps-bill",
    // },
    // {
    //   title: "فروشگاه",
    //   icon: "shop",
    // },
    // {
    //   title: "کارتک من",
    //   icon: "profile",
    // },
];

export const profileMenuItems = [
    {
        title: "نمایندگی",
        path: "/collaborate",
        icon: {
            svg: "marketing",
            width: "14px",
        },
        color: {
            icon: "#2C9CDB",
            bg: "#DCEFF9",
        },
    },
    {
        title: "دوستان من",
        path: "/profile/friends",
        icon: {
            svg: "profile-friends",
            width: "18px",
        },
        color: {
            icon: "#FF7628",
            bg: "#FFE1CC",
        },
    },
    {
        title: "سفارشات من",
        path: "/profile/orders",
        icon: {
            svg: "profile-shop",
            width: "14px",
        },
        color: {
            icon: "#2C9CDB",
            bg: "#DCEFF9",
        },
    },
    {
        title: "محصولات مورد علاقه",
        path: "/favorites",
        icon: {
            svg: "profile-favorites",
            width: "18px",
        },
        color: {
            icon: "#DB2C2C",
            bg: "#F6CBCB",
        },
    },
    {
        title: "آدرس‌های من",
        path: "/profile/addresses",
        icon: {
            svg: "profile-location",
            width: "14px",
        },
        color: {
            icon: "#009D19",
            bg: "#E0FFE5",
        },
    },
    // {
    //   title: "پشتیبانی",
    //   path: "/support",
    //   icon: {
    //     svg: "message",
    //     width: "18px",
    //   },
    //   color: {
    //     icon: "#9000A8",
    //     bg: "#F8CCFF",
    //   },
    // },
    // {
    //   title: "معرفی به دوستان",
    //   path: "/profile/invite",
    //   icon: {
    //     svg: "emoj",
    //     width: "18px",
    //   },
    //   color: {
    //     icon: "#EE008F",
    //     bg: "#FFDBF1",
    //   },
    // },
];

export const categoriesMenuItems = {
    apps: [
        {
            title: "شارژ سیم‌کارت",
            color: {
                solid: "#3A9CF6",
                bg: "#C9E4FD",
            },
            path: "/charge",
            icon: "charge",
        },
        {
            title: "بسته‌ی اینترنت",
            color: {
                solid: "#9E0373",
                bg: "#FECDF0",
            },
            path: "/internet",
            icon: "internet",
        },
        {
            title: "پرداخت قبض",
            color: {
                solid: "#9E0373",
                bg: "#bab3ff",
            },
            path: "/bill",
            icon: "bill",
        },
        {
            title: "خدمات شهری",
            color: {
                solid: "#b38900",
                bg: "#ffedb3",
            },
            path: "/services",
            icon: "services",
        },
        {
            title: "کارتک من",
            color: {
                solid: "#D76700",
                bg: "#FFD7B3",
            },
            path: "/profile",
            icon: "profile",
        },
    ],
    categories: [
        {
            title: "دیجیتال",
            color: {
                solid: "#181725",
                bg: "#C8C6DC",
            },
            path: "/",
            icon: "",
        },
        {
            title: "دیجیتال",
            color: {
                solid: "#181725",
                bg: "#C8C6DC",
            },
            path: "/",
            icon: "",
        },
        {
            title: "دیجیتال",
            color: {
                solid: "#181725",
                bg: "#C8C6DC",
            },
            path: "/",
            icon: "",
        },
        {
            title: "دیجیتال",
            color: {
                solid: "#181725",
                bg: "#C8C6DC",
            },
            path: "/",
            icon: "",
        },
        {
            title: "دیجیتال",
            color: {
                solid: "#181725",
                bg: "#C8C6DC",
            },
            path: "/",
            icon: "",
        },
        {
            title: "دیجیتال",
            color: {
                solid: "#181725",
                bg: "#C8C6DC",
            },
            path: "/",
            icon: "",
        },
        {
            title: "دیجیتال",
            color: {
                solid: "#181725",
                bg: "#C8C6DC",
            },
            path: "/",
            icon: "",
        },
        {
            title: "دیجیتال",
            color: {
                solid: "#181725",
                bg: "#C8C6DC",
            },
            path: "/",
            icon: "",
        },
    ],
};

export const profileSidebarItems = [
    // {
    //   title: "آشنایی با کارتک",
    //   path: "/intro?show",
    //   icon: {
    //     svg: "profile-help"
    //   },
    //   color: {
    //     icon: "#2C9CDB",
    //   },
    // },
    // {
    //   title: "راهنماهای کارتک",
    //   path: "/about",
    //   icon: {
    //     svg: "profile-about"
    //   },
    //   color: {
    //     icon: "#DB2C2C",
    //   },
    // },
    {
        title: "معرفی کارتک",
        path: "/introduction",
        icon: {
            svg: "profile-about",
        },
        color: {
            icon: "#DB2C2C",
        },
    },
    {
        title: "خلاصه",
        path: "/intro?show",
        icon: {
            svg: "profile-help",
        },
        color: {
            icon: "#2C9CDB",
        },
    },
    {
        title: "قوانین و هشدارها",
        path: "/alerts",
        icon: {
            svg: "profile-alert",
        },
        color: {
            icon: "#ac1cd6",
        },
    },
    {
        title: "سیاست‌ها و خط مشی کارتک",
        path: "/rules",
        icon: {
            svg: "profile-role",
        },
        color: {
            icon: "#009D19",
        },
    },
    // {
    //   title: "خلاصه",
    //   path: "/roadmap",
    //   icon: {
    //     svg: "faq"
    //   },
    //   color: {
    //     icon: "#00DB0B",
    //   },
    // },
    // {
    //   title: "تنظیمات",
    //   path: "/setting",
    //   icon: {
    //     svg: "setting"
    //   },
    //   color: {
    //     icon: "#FF00F2",
    //   },
    // },
];

export const kartlandProfileSidebarItems = [
    // {
    //     title: "کارتلند و سیتی‌زن",
    //     path: "/introduction",
    //     icon: {
    //         svg: "profile-about",
    //     },
    //     color: {
    //         icon: "#DB2C2C",
    //     },
    // },
    // {
    //     title: "فورشاپ",
    //     path: "/intro?show",
    //     icon: {
    //         svg: "profile-help",
    //     },
    //     color: {
    //         icon: "#2C9CDB",
    //     },
    // },
    // {
    //     title: "مدیریت ویترین‌های اختصاصی",
    //     path: "/alerts",
    //     icon: {
    //         svg: "profile-alert",
    //     },
    //     color: {
    //         icon: "#ac1cd6",
    //     },
    // },
    // {
    //     title: "درآمدها",
    //     path: "/rules",
    //     icon: {
    //         svg: "profile-role",
    //     },
    //     color: {
    //         icon: "#009D19",
    //     },
    // },
    {
        title: "طرح‌های فروش",
        path: "/sellplans",
        icon: {
            svg: "setting",
        },
        color: {
            icon: "#FF00F2",
        },
    },
];
