import { useState, useEffect } from "react";
import { SVG, KV, PageTopbar, Padding, Fade } from "components";
import { Line } from "rc-progress";
import QRCode from "react-qr-code";
import { useHistory } from "react-router-dom";
const settingItems = [
  {
    color: "#4D4DCE",
    title: "آبی",
  },
  {
    color: "#EB4D4C",
    title: "قرمز",
  },
  {
    color: "#17CFAD",
    title: "سبز",
  },
  {
    color: "#976DF0",
    title: "بنفش",
  },
  {
    color: "#F88131",
    title: "نارنجی",
  },
];

export const Header = ({ setColor }) => {
  const [showSetting, setShowSetting] = useState(false);
  return (
    <>
      <div
        className="generator__color"
        onClick={() => setShowSetting(!showSetting)}
        tabIndex={1}
        onBlur={() => setShowSetting(false)}
      >
        <div className="generator__color__config">
          <SVG svg="setting" />
        </div>
      </div>
      <div className={`generator__color__setting ${showSetting && "active"}`}>
        <span>رنگ بارکد</span>
        {settingItems.map((settingItem, index) => (
          <div
            className="generator__color__setting__item"
            key={index}
            onClick={() => setColor(settingItem.color)}
          >
            <div
              style={{ backgroundColor: settingItem.color }}
              className="generator__color__setting__color"
            />
            {settingItem.title}
          </div>
        ))}
      </div>
      <PageTopbar
        setColor={setColor}
        colorModalTitle="رنگ بارکد"
        title="کد کیو آر"
        hintStatus={true}
        hintAction={false}
        hintTitle="راهنمای ساخت کیو ار"
        hintBio="با ورود و ثبت نام شرایط و قوانین استفاده از سرویس های کارتک را  می پذیرم با ورود و ثبت نام شرایط و قوانین استفاده از سرویس های کارتک را  می پذیرم"
      />
    </>
  );
};
