import {
    Card,
    Dots,
    Fade,
    ImageCropper,
    PageTopbar,
    Selectbox,
    Spinner,
    SVG,
    VideoManager,
} from "../../components";
import React, { useEffect, useState } from "react";
import {
    deleteContentAPI,
    GetCategories,
    getContentDetailAPI,
    showSingleVideoAPI,
} from "../../utils";
import toast from "react-hot-toast";
import imageCompression from "browser-image-compression";
import { useHistory, useParams } from "react-router-dom";
import { useUser } from "../../hooks";
import { editContentAPI } from "../../utils/Services/UserContent/EditContent";

export const EditContent = () => {
    const history = useHistory();
    const {
        getUserDataFromApi,
        userState: { user },
    } = useUser();
    const { id } = useParams<any>();
    const [showImageEditor, setShowImageEditor] = useState(false);
    const [contentImage, setContentImage] = useState({
        file: null,
        url: "",
    });
    const [contentTitle, setContentTitle] = useState("");
    const [contentDesc, setContentDesc] = useState("");
    const [contentCategory, setContentCategory] = useState({
        category: 0,
        categoryValue: "",
    });
    const [categoriesData, setCategoriesData] = useState([]);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [compressLoading, setCompressLoading] = useState(0);
    const [showFillProfileModal, setShowFillProfileModal] = useState(false);
    const [showFillProfile, setShowFillProfile] = useState(false);
    const [userInfo, setUserInfo] = useState({
        name: "",
        lastname: "",
        nickname: "",
    });
    const [loading, setLoading] = useState(true);
    const [deleteLoading, setDeleteLoading] = useState(false);

    const compressOptions = {
        maxSizeMB: 0.6,
        maxWidthOrHeight: 1024,
        onProgress: (percentage) => {
            setCompressLoading(percentage);
        },
    };

    const [videoAddress, setVideoAddress] = useState("");

    const [flag, setFlag] = useState(false);
    const [video, setVideo] = useState(null);
    const [videoId, setVideoId] = useState("");
    const [errorOccurred, setErrorOccurred] = useState(false);
    const [isVideoUploaded, setIsVideoUploaded] = useState(false);

    const handleSubmitContent = () => {
        if (contentTitle === "") {
            toast.error("عنوان محتوا را وارد نمایید.");
            return;
        }

        if (contentDesc === "") {
            toast.error("توضیحات محتوا را وارد نمایید.");
            return;
        }

        if (contentCategory.category === 0) {
            toast.error("یک دسته‌بندی برای محتوا انتخاب کنید");
            return;
        }

        if (contentImage.file === null && videoId == null) {
            toast.error("تصویر یا ویدیو محتوا را انتخاب کنید.");
            return;
        }

        if (video) {
            setFlag(true);
        }
        setSubmitLoading(true);
    };

    const deleteContent = () => {
        setDeleteLoading(true);
        deleteContentAPI(id)
            .then((res) => {
                if (res.status === 200) {
                    toast.success("محتوا با موفقیت حذف شد");
                    history.push("/content-management");
                }
            })
            .catch((err) => {
                toast.error("خطایی در دریافت اطلاعات رخ داده است.");
            })
            .finally(() => setDeleteLoading(false));
    };

    const getContent = () => {
        setLoading(true);
        getContentDetailAPI(id)
            .then((res) => {
                if (res.status === 200) {
                    setContentTitle(res.data.title);
                    setContentDesc(res.data.descriptions);

                    setContentCategory({
                        category: res.data.category,
                        categoryValue: res.data.categoryName.title,
                    });

                    if (
                        res?.data?.pictureContent?.path?.length === 32 ||
                        res?.data?.pictureContent?.path == null
                    ) {
                        getVideoURL(res?.data?.video_url);
                    } else {
                        getImage(res.data.pictureContent?.path);
                        setLoading(false);
                    }
                }
            })
            .catch((err) => {
                toast.error("خطا در بارگذاری محتوا");
            });
    };

    const getVideoURL = (videoId) => {
        showSingleVideoAPI(videoId)
            .then((res) => {
                setVideoAddress(res.data.video_url);
                setVideoId(res.data.id);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const getImage = (url) => {
        fetch(url)
            .then((res) => res.blob()) // Gets the response and returns it as a blob
            .then((blob) => {
                // Here's where you get access to the blob
                // And you can use it for whatever you want
                // Like calling ref().put(blob)
                setContentImage((prevState) => ({ url: url, file: blob }));
            });
    };

    const getCategories = () => {
        GetCategories().then((res) => {
            if (res) {
                res.data.forEach((category) => {
                    category["name"] = category["title"];
                });
                if (res.data[0]?.name) setCategoriesData(res.data);
            }
        });
    };

    const createContent = () => {
        if (contentImage.file !== null) {
            var form = new FormData();
            imageCompression(contentImage.file, compressOptions).then(
                (compressed) => {
                    form.append("picture", compressed);
                    editContentAPI(
                        id,
                        contentTitle,
                        contentDesc,
                        contentCategory.category,
                        videoId,
                        form
                    )
                        .then((response) => {
                            if (response.status === 200) {
                                toast.success("محتوا شما با موفقیت ثبت شد!");
                                history.push("/content-management");
                            }
                        })
                        .catch((err) => toast.error("خطا در ثبت محتوا"))
                        .finally(() => setSubmitLoading(false));
                }
            );
        } else {
            editContentAPI(
                id,
                contentTitle,
                contentDesc,
                contentCategory.category,
                videoId,
                form
            )
                .then((response) => {
                    if (response.status === 200) {
                        toast.success("محتوا شما با موفقیت ثبت شد!");
                        history.push("/content-management");
                    }
                })
                .catch((err) => toast.error("خطا در ثبت محتوا"))
                .finally(() => setSubmitLoading(false));
        }
    };

    useEffect(() => {
        if (isVideoUploaded) {
            createContent();
        }
    }, [isVideoUploaded]);

    useEffect(() => {
        getCategories();
        getContent();
        history.replace("/content-edit/" + id);
    }, []);

    return (
        <Fade>
            <PageTopbar title="تولید محتوا" showKv={false} />
            <Spinner active={loading} color={"dark"} />
            <ImageCropper
                active={showImageEditor}
                onClose={(close) => {
                    setShowImageEditor(!close);
                }}
                onConfirm={(image) => {
                    setShowImageEditor(false);
                    setContentImage((prev) => ({
                        url: URL.createObjectURL(image),
                        file: image,
                    }));
                }}
            />

            {!loading && (
                <div className="wrapper-fix">
                    <Card>
                        {(videoAddress == "" || videoAddress == null) &&
                        contentImage.url == "" ? (
                            <div>
                                <h3>در حال پردازش مدیا</h3>
                            </div>
                        ) : videoAddress ? (
                            <div className="video-container">
                                <VideoManager
                                    videoAaddress={videoAddress}
                                    videoSetter={setVideo}
                                    uploadVideoFunction={flag}
                                    videoIdSetter={setVideoId}
                                    errorOccurred={setErrorOccurred}
                                    flagSetter={setFlag}
                                    finishUpload={setIsVideoUploaded}
                                />
                            </div>
                        ) : (
                            <div
                                className="create-ads__final__image"
                                onClick={() => setShowImageEditor(true)}
                            >
                                <canvas
                                    className={`create-ads__canvas ${
                                        contentImage?.url !== "new" &&
                                        "deactive"
                                    }`}
                                    id="canvas-image"
                                />

                                {contentImage?.url === "" ? (
                                    <SVG svg="ads-image" />
                                ) : contentImage?.file === null ? (
                                    // shopDetail.image?.url !== ""
                                    // ? (
                                    //   <img src={`data:image/png;base64,${shopDetail.image?.url}`} alt="" />
                                    // ) : (
                                    <SVG svg="ads-image" />
                                ) : (
                                    // )
                                    <img
                                        // src={`data:image/png;base64,${shopDetail.image?.url}`}
                                        src={`${contentImage?.url}`}
                                        alt=""
                                    />
                                )}

                                {contentImage?.url === "" && (
                                    <div className="create-ads__final__image__title">
                                        بارگذاری تصویر
                                    </div>
                                )}
                            </div>
                        )}

                        <div className="title">دسته‌بندی</div>

                        <Selectbox
                            data={categoriesData}
                            placeholder="انتخاب دسته‌بندی محتوا"
                            onChange={(catId) =>
                                setContentCategory((prev) => ({
                                    ...prev,
                                    category: catId,
                                }))
                            }
                            onChangeString={(value) =>
                                setContentCategory((prev) => ({
                                    ...prev,
                                    categoryValue: value,
                                }))
                            }
                            // onClickBox={() => window.scrollTo(0, 120)}
                            openAll={true}
                            defaultValue={contentCategory.categoryValue}
                        />

                        <div className="title">عنوان محتوا</div>
                        <input
                            type="text"
                            className="input"
                            value={contentTitle}
                            onChange={(e) => setContentTitle(e.target.value)}
                            placeholder="عنوان محتوا"
                        />

                        <div className="title">متن محتوا</div>
                        <textarea
                            className="textarea"
                            placeholder="متن محتوا"
                            onChange={(e) => setContentDesc(e.target.value)}
                            value={contentDesc}
                            rows={4}
                            style={{ marginBottom: 5 }}
                        />

                        <div className="shop-creation__action2">
                            <div
                                className={`button`}
                                onClick={() => {
                                    if (
                                        contentTitle != "" &&
                                        contentDesc != "" &&
                                        contentCategory.category != 0 &&
                                        contentImage.file != null
                                    ) {
                                        createContent();
                                    }
                                    !submitLoading && handleSubmitContent();
                                }}
                            >
                                {submitLoading ? (
                                    <Dots color="light" />
                                ) : (
                                    "ویرایش محتوا"
                                )}
                            </div>

                            <div
                                className={`button red`}
                                onClick={() =>
                                    !deleteLoading && deleteContent()
                                }
                            >
                                {deleteLoading ? (
                                    <Dots color="light" />
                                ) : (
                                    "حذف محتوا"
                                )}
                            </div>
                        </div>
                    </Card>
                </div>
            )}
        </Fade>
    );
};
