import React, {useEffect, useState} from "react";
import {Card, CheckBox, Fade, PageTopbar, Spinner, SVG} from "../../../../components";
import {getUserStoreAPI, storeDiscountAPI} from "../../../../utils";
import toast from "react-hot-toast";
import {ifError} from "assert";

export const Discount = () => {
  const [storeLoading, setStoreLoading] = useState(false);
  const [discountLoading, setDiscountLoading] = useState(false);
  const [hasDiscount, setHasDiscount] = useState(false);
  const [startDiscount, setStartDiscount] = useState<any>(null);
  const [endDiscount, setEndDiscount] = useState<any>(null);

  const getUserStore = () => {
    setStoreLoading(true);
    getUserStoreAPI().then(res => {
      if (res.status === 200) {
        console.log(res.data);
        res.data.discountAmount ? setHasDiscount(true) : setHasDiscount(false);
        if (res.data.discountAmount && res.data.discountAmount.length > 4) {
          setStartDiscount(res.data.discountAmount.substr(3, 2));
          setEndDiscount(res.data.discountAmount.substr(10, 2));
        }
        if (res.data.discountAmount && res.data.discountAmount.length < 4) {
          setStartDiscount(res.data.discountAmount.substr(0, 2));
        }
        // (res.data.discountAmount && res.data.discountAmount.length > 4) ? setStartDiscount(res.data.discountAmount.substr(4, 2) : setStartDiscount(res.data.discountAmount.substr(4, 2));
      }
    }).catch(err => {
      toast.error('به مشکلی برخوردیم');
    }).finally(() => setStoreLoading(false));
  };

  const setDiscount = () => {
    console.log(endDiscount);
    if ((+startDiscount < 1 || +startDiscount > 99)) {
      toast.error('مقدار درصد را بدرستی وارد کنید!');
      return;
    }
    if ((endDiscount !== null && endDiscount !== '') && (+endDiscount < 1 || +endDiscount > 99)) {
      toast.error('مقدار درصد را بدرستی وارد کنید!');
      return;
    }
    setDiscountLoading(true);
    storeDiscountAPI(hasDiscount ? 1 : 0, endDiscount ? `از ${startDiscount}% تا ${endDiscount}%` : `${startDiscount}%`).then(response => {
      if (response.status === 200) {
        toast.success('تخفیف با موفقیت ثبت شد');
        getUserStore();
      }
    }).catch(err => {
      toast.error('ثبت تخفیف با مشکل مواجه شد');
    }).finally(() => setDiscountLoading(false));
  };

  useEffect(() => {
    getUserStore();
  }, []);
  return (
    <Fade>
      <PageTopbar title={'ثبت تخفیف'} showKv={false}/>
      <Spinner active={storeLoading} color={'dark'}/>

      <div className="wrapper-fix">
        <Card>
          <div className="title row a-c mb-12">
            <CheckBox
              defaultValue={hasDiscount}
              type="check"
              onChange={(value) => setHasDiscount(!!value)}
            />
            فعال‌سازی تخفیف برای فروشگاه
          </div>

          {hasDiscount && <div className="col">
            <div className="title">
              از
            </div>
            <input
              type="number"
              className={'input'}
              placeholder={'درصد تخفیف'}
              value={startDiscount}
              onChange={event => setStartDiscount(event.target.value)}
            />
          </div>}

          {hasDiscount && startDiscount && <div className="col">
            <div className="title">
              تا
            </div>
            <input
              type="number"
              className={'input'}
              placeholder={'درصد تخفیف'}
              value={endDiscount}
              onChange={event => setEndDiscount(event.target.value)}
            />
          </div>}
        </Card>
      </div>
      <div onClick={() => discountLoading === false && setDiscount()} className="shop-creation__action">
        <span>ثبت تخفیف و ادامه</span>
        <SVG svg="arrow"/>
      </div>
    </Fade>
  );
};