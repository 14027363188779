import { useState } from "react";
import { Modal, SVG } from "components";

export const CardsModal = () => {
  const [modalStatus, setModalStatus] = useState(false);
  return (
    <div className="cards__modal">
      <Modal active={modalStatus} close={() => setModalStatus(false)}>
        <div className="cards__modal__title">
          <span>شرایط کارت دایموند</span>
        </div>
        <div className="cards__modal__wrapper">
          <div className="cards__modal__box">
            <SVG svg="cards-diamond" />
            <span className="cards__modal__box__title">کارت دایموند</span>
            <span className="cards__modal__box__info">
              هر کاربر 7 ستاره ای که حداقل 4 نفر با معرفی مستقیم او در کارتک ثبت
              نام کرده باشند و گروهش حداقل 50 نفر عضو داشته باشد، کاپیتان کارتک
              خواهد شد. کاپیتان های کارتک، ماهانه 25 KM شخصی جایزه می
              گیرند.
            </span>
          </div>
        </div>
        <div className="cards__modal__split"></div>
        <div className="cards__modal__wrapper">
          <div className="cards__modal__price">
            <span>قیمت :</span>
            <span>24KV</span>
          </div>
          <div className="cards__modal__action">
            <span>کپیتان شدن</span>
            <SVG svg="cards-check" />
          </div>
        </div>
      </Modal>
    </div>
  );
};
