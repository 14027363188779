import {Card, Fade, PageTopbar, SVG, Toggle} from "components";
import './../../assets/styles/pages/password.scss';
import {useEffect, useState} from "react";
import {Popup} from "../../components/Popup/Popup";
import PatternLock from "react-pattern-lock";
import {Input, Modal} from "../../components";
import {useHistory} from "react-router-dom";

export const PasswordContainer = () => {
  const history = useHistory();
  const [path, setPath] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showPattern, setShowPattern] = useState(false);
  const [showLetter, setShowLetter] = useState(false);
  const [passInput, setPassInput] = useState('');

  const showPatternHandler = () => {
    setShowPattern(true);
    setShowModal(false);
  };
  const showLetterHandler = () => {
    setShowLetter(true);
    setShowModal(false);
  };

  useEffect(() => {
    history.replace("/encryption");
  }, []);

  return (
    <Fade>
      <Modal active={showModal} close={() => setShowModal(false)}>
        <div className={'pass-modal'}>
          <div className={'pass-modal__title'}>رمزگذاری</div>
          <div className={'pass-modal__buttons'}>
            <div className={'pass-modal__buttons__text'}>روش رمزگذاری را از بین نقش و پین انتخاب کنید</div>
            <div className={'pass-modal__buttons__options'}>
              <div onClick={showPatternHandler} className="pass-modal__buttons__options__pattern">
                نقش و الگو
              </div>
              <div onClick={showLetterHandler} className="pass-modal__buttons__options__letters">
                عدد و حروف
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <PageTopbar title="رمزنگاری"/>
      <Card>
        <div onClick={() => setShowModal(true)} className={'pass-create a-c j-c'}>ایجاد رمز</div>
      </Card>
      <Popup title={'رمزگذاری با الگو'} showPass={showPattern} close={() => setShowPattern(false)}>
        <div className={'pattern-input'}>
          <PatternLock
            width={300}
            pointSize={32}
            disabled={disabled || isLoading}
            size={3}
            allowJumping={true}
            path={path}
            className={'pattern-input__pattern'}
            onChange={(pattern) => {
              setPath(pattern);
            }}
            onFinish={() => {
              setIsLoading(true);
              // an imaginary api call
              setTimeout(() => {
                setDisabled(true);
                window.setTimeout(() => {
                  setDisabled(false);
                  setIsLoading(false);
                  setPath([]);
                }, 500);
              }, 500);
            }}
          />
          <div className="pattern-input__forget">رمزعبورم را فراموش کرده‌ام ؟!!</div>
        </div>
      </Popup>
      <Popup title={'رمزگذاری با حروف'} showPass={showLetter} close={() => setShowLetter(false)}>
        <div className={'letter-input'}>
          <div className={'letter-input__text'}>رمز</div>
          <Input placeholder={'رمز را وارد کنید'} type={'password'} onChange={value => setPassInput(value)}
                 value={passInput}/>
          <div className="letter-input__forget">رمزعبورم را فراموش کرده‌ام ؟!!</div>
        </div>
      </Popup>
    </Fade>
  );
};