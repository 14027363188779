import React, {useEffect, useRef, useState} from "react";
import {
  AcceptRule,
  Card,
  CheckBox,
  CounterInput,
  Dots,
  Fade,
  Modal,
  PageTopbar,
  Spinner,
  SVG,
} from "../../components";
import "../../assets/styles/pages/BuyBlueKm.scss";
import {currency, farsiDigitToEnglish} from "../../utils/Functions";
import {useUser} from "../../hooks";
import {checkPanelCodeAPI} from "../../utils/Services/API";
import toast from "react-hot-toast";
import {
  buyBlueKmAPI,
  getBlueKmHistoryAPI,
  getBlueKmPriceAPI,
  getCancelCooparationAPI,
} from "../../utils";
import {useHistory} from "react-router-dom";
import moment from "jalali-moment";
import {checkConsultantCodeAPI} from "../../utils/Services/Km/White/CheckConsultantCode";

export const BuyBlueKmContainer = () => {
  const user_token = localStorage.getItem("user_token");
  const buyCount = useRef(null);
  const [kmCount, setKmCount] = useState<any>(1);
  const [inviterCode, setInviterCode] = useState("");
  const [inviter, setInviter] = useState("");
  const [inviteCodeStatus, setInviteCodeStatus] = useState(false);
  const [inviteCodeLoading, setInviteCodeLoading] = useState(false);
  const [kmPrice, setKmPrice] = useState(100000);
  const {
    userState: {user},
  } = useUser();
  const [buyLoading, setBuyLoading] = useState(false);
  const [priceLoading, setPriceLoading] = useState(false);
  const [kmHistory, setKmHistory] = useState<any>({});
  const [showBuyModal, setShowBuyModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [cancelCooparation, setCancelCooparation] = useState([]);
  const [cancelCooparationLoading, setCancelCooparationLoading] = useState(false);
  const [showCancelCooparationModal, setShowCancelCooparationModal] = useState(false);
  const [bluekm, setBluekm] = useState(user.blueKm);
  const history = useHistory();

  const actionData = {
    blue: [
      {
        icon: "km-donate-icon",
        title: "اهدا",
        transactionType: "donate",
        status: "main",
      },
      {
        icon: "wallet-kp",
        title: "خرید KP",
        transactionType: "buy",
        status: "main",
      },
      // {
      //   icon: "myshop-givekm",
      //   title: "شرایط اهدا",
      //   transactionType: "condition",
      //   status: "main",
      // },
      // {
      //   icon: "CancelCooparation",
      //   title: "لغو همکاری",
      //   transactionType: "CancelCooperation",
      //   status: "main",
      // },
    ]
  };

  // if(user.blueKm !== 0){
  //   const actionData = {
  //     blue: [ {
  //       icon: "CancelCooparation",
  //       title: "لغو همکاری",
  //       transactionType: "CancelCooperation",
  //       status: "main",
  //     },
  //   ],
  // };
  // }

  const handleSubmitInviteCode = () => {
    setInviteCodeLoading(true);
    checkConsultantCodeAPI(inviterCode)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data) {
            toast.error("شماره تلفن یا کد وارد شده موجود نیست");
            return;
          } else {
            toast.success("شماره تلفن یا کد وارد شده تائید شد");
            setInviteCodeStatus(true);
            setInviter(inviterCode);
            return;
          }
        } else if (response.status === 404) {
          toast.error("پنلی با این کد پیدا نشد.");
          return;
        }
      })
      .catch((err) => {
      })
      .finally(() => setInviteCodeLoading(false));
  };
  const handleCancelCooparation = () => {
    setCancelCooparationLoading(true);
    getCancelCooparationAPI()
      .then((response) => {
        // setCancelCooparation(response.data)
        toast.success("با موفقیت همکاری لغو شد !");
        setShowCancelCooparationModal(false);
        getBlueKmPrice();
        getBlueKmHistory();
      })
      .catch((err) => {
        toast.error("عملیات با خطا مواجه شد");
      })
      .finally(() => setCancelCooparationLoading(false));
  };

  const buyBlueKm = () => {
    setBuyLoading(true);
    buyBlueKmAPI(inviter, 1)
      .then((response) => {
        if (
          response.status === 200 &&
          response.data.payID &&
          response.data.tk
        ) {
          toast.success("در حال انتقال به درگاه پرداخت...");

          const paymentURL = `${process.env.REACT_APP_DEV_IP}api/v3/km/blue/payment/${response.data.payID}/${response.data.tk}`;
          history.push(`/pay?${paymentURL}`);
          setTimeout(() => {
            if (history.location.pathname.includes("/pay"))
              window.location.href = `${paymentURL}`;
          }, 600);
        }
      })
      .catch((err) => {
        if (
          err.response.data.msg ==
          "this user must BlueKm Wallet under 99 to access buy km"
        ) {
          toast.error(
            "برای خرید بسته جدید مقدار KP شما باید کمتر از 100 عدد باشد"
          );
        } else {
          toast.error("مشکلی در خرید KP رخ داد");
        }
      })
      .finally(() => setBuyLoading(false));
  };

  const getBlueKmPrice = () => {
    setPriceLoading(true);
    getBlueKmPriceAPI()
      .then((res) => {
        if (res.status === 200) {
          setKmPrice(res.data);
        }
      })
      .catch((err) => {
        toast.error("خطایی رخ داد");
      })
      .finally(() => setPriceLoading(false));
  };

  const getBlueKmHistory = () => {
    setLoading(true);
    getBlueKmHistoryAPI()
      .then((response) => {
        setKmHistory(response.data);
      })
      .catch((err) => {
        toast.error("عملیات با خطا مواجه شد");
      })
      .finally(() => setLoading(false));
  };
  // const getCancelCooparation = () => {
  //   setCancelCooparationLoading(true);
  //   getCancelCooparationAPI()
  //     .then((response) => {
  //       // setKmHistory(response.data);
  //       setCancelCooparation(response.data)
  //     })
  //     .catch((err) => {
  //       toast.error("عملیات با خطا مواجه شد");
  //     })
  //     .finally(() => setCancelCooparationLoading(false));
  // };

  const handleDepositWithdrawName = (type) => {
    console.log(type);
    if (type.hasOwnProperty("detail")) {
      return "withdraw";
    }

    if (type.transactionType === 0) {
      return "withdraw";
    } else {
      return "deposit";
    }
  };

  useEffect(() => {
    getBlueKmPrice();
    getBlueKmHistory();
  }, []);

  useEffect(() => {
    history.replace("/myshop/buy-km");
  }, []);

  return (
    <Fade>
      <PageTopbar title="خرید KP" toggleBackward={true} showKv={false}/>
      <Spinner active={loading} color="dark"/>
      <Card>
        <div className="km__wallet__cart mycity">
          <div className={`km__wallet__cart__detail blue`}>
            <div className={`km__wallet__cart__detail__count blue`}>
              <div className={`km__wallet__cart__detail__count__circle blue`}>
                <SVG svg="kp-logo"/>
              </div>
              <span>{kmHistory.totalAmount}</span>
            </div>
          </div>

          <div className="km__wallet__cart__action">
            {actionData["blue"].map((item, index) => (
              (item.transactionType !== "CancelCooperation" || (item.transactionType === "CancelCooperation" && kmHistory.totalAmount > 0)) &&
              <div
                onClick={() => {
                  if (item.transactionType === "buy") {
                    setShowBuyModal(true);
                    return;
                  }
                  if (item.transactionType === "condition") {
                    // history.push(
                    //   "/myshop/give-km?edit=true"
                    // );
                    return;
                  }
                  if (item.transactionType === "CancelCooperation") {
                    setShowCancelCooparationModal(true);
                    return;
                  }
                  history.push(
                    `/transaction/${item.transactionType}?type=blue&status=${item.status}`
                  );
                }}
                className="km__wallet__cart__action__item"
              >
                <div className="km__wallet__cart__action__item__icon">
                  <SVG svg={item.icon}/>
                </div>
                <span>{item.title}</span>
              </div>
            ))}
          </div>
        </div>
      </Card>

      <Card>
        {kmHistory?.logs?.map((walletHistory, index) => (
          <>
            <div className="km__history__item" key={index}>
              <div
                className={`km__history__type ${handleDepositWithdrawName(walletHistory)}`}
              >
                <SVG
                  svg={handleDepositWithdrawName(walletHistory)}
                />
              </div>
              <div className="km__history__detail">
                <div className="col">
                  <div className="row">
                    <div
                      className={`km__history__detail__type ${handleDepositWithdrawName(walletHistory)}`}
                    >
                      <SVG svg="kp-logo"/>
                    </div>

                    <div className="col km__history__detail__box">
                      <div className="km__history__detail__amount">
                        {walletHistory.amount + " KP"}
                      </div>
                      <div className="km__history__detail__source">
                        {walletHistory.description ? walletHistory.description : walletHistory.detail}
                      </div>
                    </div>
                  </div>
                  <div className="km__history__detail__date">
                    {moment(walletHistory.created_at, "YYYY-MM-DD h:mm:ss")
                      .locale("fa")
                      .format("H:mm - YYYY/MM/DD")}
                  </div>
                </div>
              </div>
            </div>

            {index !== kmHistory?.logs?.length - 1 && (
              <div className="km__history__seperator"/>
            )}
          </>
        ))}
      </Card>

      <Modal active={showBuyModal} close={() => setShowBuyModal(false)}>
        <div className="buy-blue">
          {/*<div className="buy-blue__count">*/}
          {/*  <div className="title">بسته 100تایی KM</div>*/}
          {/*  <CounterInput*/}
          {/*    ref={buyCount}*/}
          {/*    defaultValue={1}*/}
          {/*    step={1}*/}
          {/*    max={99}*/}
          {/*    onChange={(value) => setKmCount(value)}*/}
          {/*    kmMode={true}*/}
          {/*  />*/}
          {/*</div>*/}
          <div className="mb-8">
            {user.introducedId === null && (
              <>
                <div className="title">کد یا تلفن مشاور</div>
                <div className="row">
                  <input
                    type="text"
                    className="input"
                    value={inviterCode}
                    onChange={(e) =>
                      setInviterCode(farsiDigitToEnglish(e.target.value))
                    }
                    placeholder="کد یا تلفن مشاور"
                  />
                  <div
                    className={`create-shop__inviter__button ${inviteCodeStatus && "edit"
                    } ${inviterCode.length === 0 && "hidden"}`}
                    onClick={() => {
                      if (inviteCodeStatus) {
                        setInviterCode("");
                        setInviteCodeStatus(false);
                        setInviter("");
                      } else {
                        handleSubmitInviteCode();
                      }
                    }}
                  >
                    {inviteCodeLoading ? (
                      <Dots color="light"/>
                    ) : inviteCodeStatus ? (
                      "ویرایش"
                    ) : (
                      "ثبت"
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
          {!priceLoading && (
            <div className="buy-blue__price">
              <div className="">قیمت:</div>
              <div className="">{currency(kmCount * kmPrice * 100)} تومان</div>
            </div>
          )}
        </div>

        <div
          className={`button ${kmCount < 1 && "disable"}`}
          onClick={() => !buyLoading && buyBlueKm()}
        >
          {buyLoading ? <Dots color="light"/> : "پرداخت"}
        </div>
      </Modal>
      <Modal active={showCancelCooparationModal} close={() => setShowCancelCooparationModal(false)}>
        <div className="Cancel-Cooparation-Main">
          <div className="Cancel-Cooparation-Main__text">
            <Card>
              در صورتی که از طرح خارج شوید، امکانات پنل شما محدود، علامت فروشگاهتان روی نقشه تغغیر و مبلغ باقی مانده‌ی
              KP شمابه حسابتان واریز میشود.
            </Card>
          </div>
          <div className="row j-se"
            // className="Cancel-Cooparation-Main__button"
          >
            <div className="button red" style={{width: '40%'}}
              // className="Cancel-Cooparation-Main__button__confirmation"
                 onClick={() => cancelCooparationLoading === false && handleCancelCooparation()}
            >
              {cancelCooparationLoading ? (
                <Dots color={'light'}/>
              ) : (
                <span>لغو همکاری</span>
              )}
            </div>
            <div className="button disable " style={{width: '40%'}}
                 onClick={() => setShowCancelCooparationModal(false)}>
              بازگشت
            </div>
          </div>
        </div>
      </Modal>
    </Fade>
  );
};
