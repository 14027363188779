// import { NewLotteryDetailContainer } from "containers/NewLotteryContainer/dependencies/NewLotteryDetailContainer";

import { NewLotteryDetailContainer } from "containers";

const NewLotteryDetail = () => {
  return (
    <NewLotteryDetailContainer/>
  );
}

export default NewLotteryDetail;