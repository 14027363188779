import { Card, Modal, SVG } from "components";
import { useUser } from "hooks";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useHistory } from "react-router-dom";
import { getHintAPI, startCollaborationAPI, updateMateInfoAPI } from "utils";
import { farsiDigitToEnglish } from "utils/Functions";
import MockImage from "../../../../assets/images/kartellaMock.jpg";

export const NotFound = ({ updateUserPanel }) => {
    const history = useHistory();
    const {
        userState: { user },
        getUserDataFromApi,
    } = useUser();
    const [showFillProfile, setShowFillProfile] = useState(false);
    const [userInfo, setUserInfo] = useState({
        name: "",
        lastname: "",
        nationalCode: "",
    });
    const [showCollaborationRules, setShowCollaborationRules] = useState(false);
    const [showRulesAccept, setShowRulesAccept] = useState(false);
    const [hint, setHint] = useState<any>("");
    const [ruleHint, setRuleHint] = useState<any>("");

    const handleStartCollaboration = () => {
        setShowCollaborationRules(false);
        if (user.detail?.star < 7) {
            toast.error(
                "برای کسب نمایندگی کارتک، باید حداقل کاربر 7 ستاره باشید."
            );
            return;
        } else {
            setShowFillProfile(true);
        }
    };

    const handleSubmitInfo = () => {
        if (userInfo.name?.length === 0 || userInfo.name === null) {
            toast.error("نام خود را وارد نمایید.");
            return;
        } else if (
            userInfo.lastname?.length === 0 ||
            userInfo.lastname === null
        ) {
            toast.error("نام‌خانوادگی خود را وارد نمایید.");
            return;
        } else if (
            userInfo.nationalCode?.length === 0 ||
            userInfo.nationalCode === null
        ) {
            toast.error("کد ملی خود را وارد نمایید.");
            return;
        } else if (userInfo.nationalCode?.length !== 10) {
            toast.error("کد ملی خود را به درستی وارد نمایید.");
            return;
        } else {
            updateMateInfoAPI(userInfo)
                .then((response) => {
                    if (response.status === 200)
                        startCollaborationAPI().then((startCollabResponse) => {
                            if (startCollabResponse.status === 200) {
                                updateUserPanel(true);
                                toast.success(
                                    "پنل نمایندگی با موفقیت برای شما فعال شد."
                                );
                                getUserDataFromApi();
                            }
                        });
                })
                .catch((err) => {
                    if (err.response.status === 403) {
                        startCollaborationAPI().then((startCollabResponse) => {
                            if (startCollabResponse.status === 200) {
                                updateUserPanel(true);
                                toast.success(
                                    "پنل نمایندگی با موفقیت برای شما فعال شد."
                                );
                                getUserDataFromApi();
                            }
                        });
                    } else {
                        toast.error("خطایی در ایجاد نمایندگی کارتک پیش آمد.");
                    }
                });
        }
    };

    const getHint = () => {
        // getHintAPI("صفحه ثابت شروع همکاری")
        //     .then((response) => {
        //         setHint(response);
        //     })
        //     .catch(() => setHint(false))
        //     .finally(() => {});

        getHintAPI("قوانین و هشدارها 3")
            .then((response) => {
                setRuleHint(response);
            })
            .catch(() => setRuleHint(false))
            .finally(() => {});
    };

    useEffect(() => {
        setUserInfo({
            name: user.name,
            lastname: user.lastname,
            nationalCode: user.nationalCode,
        });
    }, [user]);

    useEffect(() => {
        document
            .getElementById("marketingRules")
            ?.addEventListener("scroll", () => {
                document.getElementById("marketingRules").scrollTop >
                document.getElementById("marketingRules")?.scrollHeight -
                    document.getElementById("marketingRules")?.clientHeight -
                    20
                    ? setShowRulesAccept(true)
                    : setShowRulesAccept(false);
            });
        return () => {
            document
                .getElementById("marketingRules")
                ?.removeEventListener("scroll", () => {
                    document.getElementById("marketingRules").scrollTop >
                    document.getElementById("marketingRules")?.scrollHeight -
                        document.getElementById("marketingRules")
                            ?.clientHeight -
                        20
                        ? setShowRulesAccept(true)
                        : setShowRulesAccept(false);
                });
        };
    }, [document.getElementById("marketingRules")?.scrollTop]);

    useEffect(() => {
        getHint();
    }, []);

    return (
        <React.StrictMode>
            <Modal
                active={showFillProfile}
                close={() => setShowFillProfile(false)}
            >
                <div className="title">نام</div>
                <input
                    type="text"
                    className="input"
                    value={userInfo.name}
                    onChange={(e) =>
                        setUserInfo({ ...userInfo, name: e.target.value })
                    }
                />

                <div className="title">نام خانوادگی</div>
                <input
                    type="text"
                    className="input"
                    value={userInfo.lastname}
                    onChange={(e) =>
                        setUserInfo({ ...userInfo, lastname: e.target.value })
                    }
                />

                <div className="title">کد ملی</div>
                <input
                    type="tel"
                    className="input"
                    value={userInfo.nationalCode}
                    onChange={(e) =>
                        setUserInfo({
                            ...userInfo,
                            nationalCode: farsiDigitToEnglish(e.target.value),
                        })
                    }
                />

                <div
                    className="button green"
                    onClick={() => handleSubmitInfo()}
                >
                    ثبت اطلاعات و شروع نمایندگی
                </div>
            </Modal>
            <Modal
                active={showCollaborationRules}
                close={() => setShowCollaborationRules(false)}
            >
                <div className="marketing__rules">
                    <div className="marketing__rules__title">
                        {ruleHint.title}
                    </div>

                    <div
                        className="marketing__rules__description"
                        id="marketingRules"
                    >
                        {ruleHint.content?.split("\n")?.map((str) => (
                            <div>{str}</div>
                        ))}
                    </div>

                    <div className="marketing__rules__bottom">
                        <div
                            className={`marketing__rules__button ${
                                showRulesAccept
                                    ? "marketing__rules__accept"
                                    : "marketing__rules__disabled"
                            }`}
                            onClick={() =>
                                showRulesAccept
                                    ? handleStartCollaboration()
                                    : document
                                          .getElementById("marketingRules")
                                          .scrollTo(0, 500)
                            }
                        >
                            قوانین را می‌پذیرم
                        </div>
                        {/* <div className="marketing__rules__button marketing__rules__reject">
              انصراف
            </div> */}
                    </div>
                </div>
            </Modal>
            <div className="marketing__not-found">
                {/* <div className="marketing__not-found__logo">
          <SVG svg="panel-not-found" />
        </div> */}
                {/* <span className="marketing__not-found__title">
          پنل همکاری وجود ندارد
        </span> */}
                <img src={MockImage} alt="" className="marketing__mock" />
                <span className="marketing__not-found__description">
                    {hint.content?.split("\n")?.map((str) => (
                        <div>{str}</div>
                    ))}
                </span>
            </div>
            <div
                onClick={() => setShowCollaborationRules(true)}
                // onClick={() => handleStartCollaboration()}
                className="marketing__not-found__btn"
            >
                <div className="marketing__not-found__btn__first">
                    <SVG svg="panel" />
                </div>
                <span className="marketing__not-found__btn__title">شروع</span>
                <div className="marketing__not-found__btn__last">
                    <SVG svg="arrow" />
                </div>
            </div>
        </React.StrictMode>
    );
};
