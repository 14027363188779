import React, {useEffect, useState} from "react";
import {Spinner, PageTopbar, Card, SVG, Fade} from "components";
import moment from "jalali-moment";
import {useHistory} from "react-router-dom";
import {useUser} from "hooks";
import {getCollaborations} from "utils/Services";

export const CollaborationContainer = ({children}) => {
  const {
    userState: {user},
  } = useUser();
  const [userInfo, setUserInfo] = useState<any>({});
  const [collaborationsList, setCollaborationsList] = useState([]);
  const [showSharing, setShowSharing] = useState(false);
  const [caller, setCaller] = useState(null);
  const [loading, setLoading] = useState(true);
  const history = useHistory();

  const getCollaborationsList = () => {
    getCollaborations().then((res) => {
      if (res) {
        setCollaborationsList(res.data.Collaboration_list);
        setCaller(res.data.caller);
        setTimeout(() => {
          setLoading(false);
        }, 400);
      } else return;
    });
  };

  const handlePanelName = (type) => {
    switch (type) {
      case "white":
        return "سفید";
      case "gold":
        return "طلایی";
      case "silver":
        return "پنل نقره ای";
      case "bronze":
        return "پنل برنزی";
      default:
        return "پنل نامشخص";
    }
  };

  useEffect(() => {
    history.replace("/collaboration");
    getCollaborationsList();
  }, []);

  return (
    <Fade>
      <div className="friends">
        <Spinner active={loading} color="dark"/>
        <PageTopbar title="همکاران من"/>

        <Card>
          {caller?.mobile_number !== null && (
            <>
              <div className="title">پنل خریداری شده با کد معرف:</div>
              <div className="friends__list">
                <div className="friends__list__item">
                  <div className="friends__list__image"></div>
                  <div className="col">
                    <div className="friends__list__name">{caller?.name}</div>
                    <div className="friends__list__phone">
                      {caller?.mobile_number}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          <div className="title">
            تیم فروش من
            <span style={{marginRight: 5, fontWeight: 300, fontSize: 12}}>
              ( {collaborationsList.length} نفر )
            </span>
          </div>
          {collaborationsList.length === 0 && (
            <div className="friends__not-found">
              <div className="friends__not-found__logo">
                <SVG svg="friends"/>
              </div>
              <span className="friends__not-found__title">
                همکاری وجود ندارد
              </span>
              <span className="friends__not-found__description">
                همکار طلایی برای شما پیدا نشد.
              </span>
            </div>
          )}
          <div className="friends__list">
            {collaborationsList.map((userFriend, index) => (
              <div className="friends__list__item" key={index}>
                <div className="friends__list__image"/>

                <div className="col" style={{width: "100%"}}>
                  <div className="friends__list__name row">
                    <div className="">{userFriend.full_name}</div>
                    <div className={`friends__list__panel ${userFriend.collaborationType}`}>{`(${handlePanelName(userFriend.collaborationType)})`}</div>
                  </div>

                  <div className="friends__list__phone">
                    {userFriend.mobile_number}
                  </div>
                </div>

                <div className="friends__list__date">
                  {moment(userFriend.join_at, "YYYY-MM-DD HH:mm:ss")
                    .locale("fa")
                    .format("YYYY/MM/DD")}
                </div>
              </div>
            ))}
          </div>
        </Card>
      </div>
    </Fade>
  );
};
