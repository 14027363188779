import { Request } from "../api-call";

export const updateStoreAPI = async (desc, color) => {
  let formData = new FormData();
  formData.append("description", desc);
  const { data } = await Request.post(
    `/api/v3/kartland/store/update?color=%23${color}`,
    formData
  );
  return data;
};
