import {Request} from "../../api-call";

export const updateVitrinItemAPI = async (id, title, pic, category, price) => {
  const {data} = await Request.post(`/api/v3/kartland/vitrin/${id}?title=${title}&Category=${category}&price=${price}`, pic, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
  return data;
};
