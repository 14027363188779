import { SVG } from "components";
import { useCart } from "hooks";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./CartButton.scss";

export const CartButton = () => {
    const {
        cartState: { cart },
    } = useCart();
    const [cartCount, setCartCount] = useState(0);

    const handleShowCartItems = () => {
        var count = 0;
        if (cart !== undefined) {
            cart[0]?.detail?.items?.forEach((cartItem) => {
                count += Number(cartItem.count);
            });
            setCartCount(count);
        }
    };

    useEffect(() => {
        // handleShowCartItems();
        if (cart !== undefined) {
            setCartCount(cart[0]?.detail?.length);
        }
    }, [cart]);

    return (
        <Link to="/carts" className="cart-button">
            <SVG svg="cart-small" />
            {cartCount !== 0 && cartCount != undefined && (
                <div className="cart-button__badge">{cartCount}</div>
            )}
        </Link>
    );
};
