import { FC } from "react";
import { SVG } from "components";
import { Link } from "react-router-dom";
import "./NotFoundBox.scss";
type NotFoundBoxType = {
  title: string;
  bio?: string;
  icon: string;
  fullScreen?: boolean;
  iconFillDark?: boolean;
  iconStrokeDark?: boolean;
  withAction?: boolean;
  actionText?: string;
  actionIcon?: string;
  actionRoute?: string;
};
export const NotFoundBox: FC<NotFoundBoxType> = ({
  title,
  bio,
  icon,
  fullScreen = false,
  iconFillDark = false,
  iconStrokeDark = false,
  withAction,
  actionText,
  actionIcon,
  actionRoute,
}) => {
  return (
    <div className={`not-found ${fullScreen && "full"}`}>
      <div
        className={`not-found__logo ${iconFillDark && "fill-dark "} ${
          iconStrokeDark && "stroke-dark"
        }`}
      >
        <SVG svg={icon} />
      </div>
      <span className="not-found__title">{title}</span>
      <span className="not-found__description">{bio}</span>
      {withAction ? (
        <Link className="not-found__empty-button" to={actionRoute}>
          <div className="not-found__empty-button__title">
            <div className="not-found__empty-button__icon cart">
              <SVG svg={actionIcon} />
            </div>
            {actionText}
          </div>
          <div className="not-found__empty-button__icon">
            <SVG svg="arrow-big-white" />
          </div>
        </Link>
      ) : null}
    </div>
  );
};
