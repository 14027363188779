import { UpdateAdsContainer } from 'containers';
import React from 'react';

const UpdateAds = () => {
  return (
    <UpdateAdsContainer />
  );
}

export default UpdateAds;
