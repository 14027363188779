import React from 'react';
import './MenuButton.scss';
import {SVG} from 'components'

export const MenuButton = ({onClick}) => {
    return (
        <div className='menu-button' onClick={onClick} >
            <SVG svg='menu-button' />
        </div>
    )
}

