import React, {useState, useEffect} from "react";
import {SVG, Fade, Modal, Spinner, Share, Card, Dots, DiscountBadge, PageTopbar, CheckBox} from "components";
import {useHistory, useParams} from "react-router-dom";
import {getIntroduceStoreDetailsAPI, getSellerDetailsAPI, setFavoriteAPI} from "../../utils";
import {useUser} from "../../hooks";
import dinner2 from "../../assets/images/dinner2.png";
import moment from "jalali-moment";
import {reportStoreAPI} from "../../utils/Services/Kartland/ReportStore";
import toast from "react-hot-toast";
import {cloneDeep} from "lodash";
import {surveyReportsAPI} from "../../utils/Services/Kartland/SurveyReports";

export const IntroducredStoresContainer = () => {
  const params: any = useParams();
  const {id} = useParams<any>();
  const [introStoreDetails, setIntroStoreDetails] = useState<any>(null);
  const [loading, setloading] = useState(false);
  const [tellModal, setTellModal] = useState(false);
  const history = useHistory();
  const {
    userState: {user},
  } = useUser();
  const [reportModal, setReportModal] = useState(false);
  const [reportLoading, setReportLoading] = useState(false);
  const [reportMessage, setReportMessage] = useState('');
  const [errors, setErrors] = useState([
    {
      message: 'چنین فروشگاهی در این مکان وجود ندارد',
      active: false
    },
  ]);
  const [errorMessages, setErrorMessages] = useState({});
  const [surveyReportLoading, setSurveyReportLoading] = useState(false);
  const [storeReportsModal, setStoreReportsModal] = useState(false);


  const getStoreDetails = (id) => {
    setloading(true);
    getIntroduceStoreDetailsAPI(id)
      .then((response) => {
        if (response.data !== null) {
          setIntroStoreDetails(response.data);
          //   setShopDetail({ ...shopDetail, address: response.data.address, lat: response.data.lat, lng: response.data.lng });
        }
      })
      .catch((err) => {
      })
      .finally(() => {
        setloading(false);
      });
  };


  const sendReport = () => {
    setReportLoading(true);
    reportStoreAPI(id, 'introductionStore', reportMessage).then(res => {
      if (res.status === 200) {
        toast.success('گزارش برای مدیریت ارسال شد');
        setReportModal(false);
      }
    }).catch(err => {

    }).finally(() => setReportLoading(false));
  };


  const surveyReport = (id, status, message) => {
    if (message === undefined || message.length === 0) {
      toast.error('لطفا توضیحات را وارد نمائید');
      return;
    }

    setSurveyReportLoading(true);
    surveyReportsAPI(id, status, message, 'introductionStore').then(res => {
      if (res.status === 200) {
        toast.success('گزارش با موفقیت رسیدگی شد');
        getStoreDetails(params.id);
        setStoreReportsModal(false);
      }
    }).catch(err => {
      toast.error('خطایی رخ داد');
    }).finally(() => setSurveyReportLoading(false));
  };

  const activeErrorMessage = (index) => {
    let currErrors = cloneDeep(errors);
    currErrors.map((item, index) => {
      item.active = false;
    });
    currErrors[index].active = true;
    setErrors(currErrors);
    setReportMessage(errors[index].message);
  };

  useEffect(() => {
    history.replace("/introducred-stores/" + id);
  }, []);

  useEffect(() => {
    getStoreDetails(id);
  }, []);

  return (
    <Fade>
      <Spinner active={loading} color={"dark"}/>
      <PageTopbar title={'جزئیات فروشگاه'} showKv={false}/>
      <Modal active={tellModal} close={() => setTellModal(false)}>
        <div className="seller-tell">
          <div className="seller-tell__header">تماس با فروشگاه</div>
          <div className="seller-tell__data">
            <div className="seller-tell__data__item">
              <a href={`tel:${introStoreDetails?.mobile_number}`}>
                      <span className="seller-tell__data__item__number">
                        {introStoreDetails?.mobile_number}
                      </span>
                <div className="seller-tell__data__item__call">
                  <span>تماس</span>
                  <SVG svg="seller-call"/>
                </div>
              </a>
            </div>
          </div>
        </div>
      </Modal>
      <Modal active={storeReportsModal} close={() => setStoreReportsModal(false)}>
        <div className="seller__reports">
          <div className="seller__reports__list">
            {introStoreDetails?.reports?.reportsDetails.map(item => (
              <div className="seller__reports__item">
                <div className="seller__reports__item__date">
                  {`تاریخ گزارش : ${moment(item.created_at, "YYYY/MM/DD")
                    .locale("fa")
                    .format("YYYY/MM/DD")}`}
                </div>
                <div className="seller__reports__item__text">
                  {`نوع تخلف : ${item.message}`}
                </div>
                <textarea
                  className="textarea mb-8"
                  placeholder="توضیحات"
                  value={errorMessages[item.id]}
                  onChange={(e) => {
                    let currMessages = cloneDeep(errorMessages);
                    currMessages[item.id] = e.target.value;
                    setErrorMessages(currMessages);
                  }}
                  rows={3}
                />
                <div className="seller__reports__item__actions">
                  <div onClick={() => surveyReport(item.id, 1, errorMessages[item.id])} className={'button green'}>تائید
                    صحت گزارش
                  </div>
                  <div onClick={() => surveyReport(item.id, 0, errorMessages[item.id])} className={'button red'}>رد
                    گزارش
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Modal>

      <Modal active={reportModal} close={() => setReportModal(false)}>
        <div className="report-store">
          <div className="report-store__text">
            با ثبت گزارش صحیح ما را در اصلاح اطلاعات شهر یاری کن و امتیاز بگیر
          </div>

          <div className="report-store__options">
            {errors.map((item, index) => (
              <div className="report-store__options__item">
                <CheckBox
                  defaultValue={item.active}
                  onChange={value => {
                    if (value) {
                      activeErrorMessage(index);
                    }
                  }}/>
                <div className="report-store__options__item__message">{item.message}</div>
              </div>
            ))}
          </div>

          <div className="report-store__actions">
            <div onClick={() => !reportLoading && sendReport()} className="button red">
              {reportLoading ? (
                <Dots color={'light'}/>
              ) : (
                <span>ارسال گزارش</span>
              )}
            </div>
            <div onClick={() => setReportModal(false)} className="button disable">بازگشت</div>
          </div>
        </div>
      </Modal>


      <div className="wrapper-fix">
        <Card>
          {introStoreDetails !== null &&
            <div className="Introduced-store">
              <div className="Introduced-store__img">
                <img src={introStoreDetails?.photo} alt={'photo'}/>
              </div>
              <div className="Introduced-store__info">
                <div className="Introduced-store__info__title">
                  {introStoreDetails.name}
                </div>


                <div className="seller__body__title-wrapper__actions">
                  {(user.storeSubmiter === 1 && introStoreDetails?.reports?.count > 0) && <div
                    onClick={() => setStoreReportsModal(true)}
                    className={`seller__body__title-wrapper__actions__item report`}
                  >
                    بررسی گزارشات
                  </div>}
                  <div
                    onClick={() => setReportModal(true)}
                    className={`seller__body__title-wrapper__actions__item report`}
                  >
                    گزارش
                  </div>
                </div>


                {/*{(user.storeSubmiter === 1 && introStoreDetails?.reports?.count > 0) && <div*/}
                {/*  onClick={() => setStoreReportsModal(true)}*/}
                {/*  className={`seller__body__title-wrapper__actions__item report`}*/}
                {/*>*/}
                {/*  بررسی گزارشات*/}
                {/*</div>}*/}

                {/* <div className="Introduced-store__info__location">
                            <SVG svg="map-address"/>
                         آدرس : {introStoreDetails.address}
                            </div> */}
              </div>
            </div>
          }
          <div className="seller__body__address">
            <div className="seller__body__address__header">
              <div className="seller__body__address__header__location">
                <SVG svg="seller-location"/>
                <span>آدرس</span>
              </div>
              <div className="seller__body__address__header__navigate">
                <a
                  className="seller__body__address__header__navigate"
                  href={`geo:${introStoreDetails?.lat},${introStoreDetails?.lng}`}
                >
                  <span>مسیر یابی</span>
                  <SVG svg="seller-navigate"/>
                </a>
              </div>
            </div>
            <div className="seller__body__address__body">
                    <span>
                      {introStoreDetails?.address && introStoreDetails?.address.split("\n").map((str) => (
                        <div>{str}</div>
                      ))}
                    </span>
            </div>
          </div>
          <div className="seller__body__actions">

            <div className="seller__body__actions__text">
              اطلاعات این فروشگاه کامل نیست.
            </div>

            {/*<div*/}
            {/*  onClick={() => setTellModal(true)}*/}
            {/*  className="seller__body__actions__item tell"*/}
            {/*>*/}
            {/*  <SVG svg="seller-tell"/>*/}
            {/*  <span>شماره تلفن</span>*/}
            {/*</div>*/}

          </div>
        </Card>
      </div>
    </Fade>
  );
};
