import React, { useEffect, useState } from "react";
import {
    Padding,
    Spinner,
    PageTopbar,
    Card,
    SVG,
    Share,
    Dots,
    Hint,
    Modal,
    Fade,
    SlidingHint,
} from "components";
import captain from "../../assets/images/diamond-captain.png";
import {
    getUserFriends,
    getUserInfo,
    setPresenterAPI,
} from "../../../utils/Services/API";
import { useUser } from "hooks";
import moment from "jalali-moment";
// import captain from "../assets/images/diamond-captain.png";
import toast from "react-hot-toast";
import { useHistory, useLocation } from "react-router-dom";
import {
    getCaptainConditionAPI,
    getCaptainConditionStatusAPI,
    introducedCounterAPI,
    introducedNumbersAPI,
    introduceNumberAPI,
    setCaptainAPI,
} from "utils/Services/Captain";
import profileImage from "../assets/images/profile.png";
import { getHintAPI } from "utils";
// import { Tabs } from "../containers/OrdersContainer/dependencies";
// import introduceNumber from "./IntroduceNumber";
// import { introducedCounterAPI, introducedNumbersAPI, introduceNumberAPI } from "../utils/Services/Captain";
import { error } from "autoprefixer/lib/utils";
import { Tabs } from "containers/OrdersContainer/dependencies";
import { updateUserNameAPI } from "utils/Services/Captain/UpdateUserName";
import { cloneDeep } from "lodash";
// import { updateUserNameAPI } from "../utils/Services/Captain/UpdateUserName";
import send from "../../../assets/images/send-invite.gif";

const AddNubmer = () => {
    const location = useLocation();
    const {
        userState: { user },
    } = useUser();
    const user_token = localStorage.getItem("user_token");
    const [userInfo, setUserInfo] = useState({});
    const [friendsList, setFriendsList] = useState([]);
    const [showSharing, setShowSharing] = useState(false);
    const [caller, setCaller] = useState(null);
    const [loading, setLoading] = useState(true);
    const [inviteCode, setInviteCode] = useState("");
    const [inviteCodeLoading, setInviteCodeLoading] = useState(false);
    const [userTotalInvited, setUserTotalInvited] = useState(0);
    const [captainConditionStatus, setCaptainConditionStatus] = useState({});
    const [showFriends, setShowFriends] = useState(true);
    const [activeTab, setActiveTab] = useState(1);
    const [isAdding, setIsAdding] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState("");
    const [friendName, setFriendName] = useState("");
    const [addNumberLoading, setAddNumberLoading] = useState(false);
    const [deleteNumberLoading, setDeleteNumberLoading] = useState(false);
    const [addedNubmers, setAddedNubmers] = useState([]);
    const [addedNubmersLoading, setAddedNubmersLoading] = useState(false);
    const [maxIntroduced, setMaxIntroduced] = useState(1);
    const [showUpdate, setShowUpdate] = useState(false);
    const [updateLoading, setUpdateLoading] = useState(false);
    const history = useHistory();
    // diamond state
    const [captainModal, setCaptainModal] = useState(false);
    const [captainHintModal, setCaptainHintModal] = useState(false);
    const [convertCaptian, setConvertCaptian] = useState(false);
    const [myCaptainCount, setMyCaptainCount] = useState(0);
    const [captainTeamCount, setCaptainTeamCount] = useState(0);
    const [hint, setHint] = useState({
        title: "",
        content: "",
    });
    const [secondHint, setSecondHint] = useState({
        title: "",
        content: "",
    });
    const [userFirstName, setUserFirstName] = useState("");
    const [userLastName, setUserLastName] = useState("");
    const [confirmModal, setConfirmModal] = useState(false);
    const [remainingNumber, setRemainingNumber] = useState<any>(1);
    const [remainingItem, setRemainingItem] = useState(["a"]);

    const getFriends = () => {
        const userToken = localStorage.getItem("user_token");

        const serverResponse = getUserFriends(userToken);
        serverResponse
            .then((response) => {
                setFriendsList(response.data.data.friends_list);
                setCaller(response.data.data.caller);
                setCaptainTeamCount(response.data.data.totalCaptain);
                setMyCaptainCount(response.data.data.directCaptain);
            })
            .catch((e) => {
                toast.error("خطا در برقراری ارتباط");
                return;
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const copy = () => {
        const value = document.getElementById("panel_code");
        // value.select();
        document.execCommand("copy");

        toast.success("کد کارتک شما کپی شد!");
    };

    const getMaxIntroduced = () => {
        introducedCounterAPI()
            .then((res) => {
                if (res.status === 200) {
                    setMaxIntroduced(res.data.mobileCounter);
                }
            })
            .catch((err) => {})
            .finally(() => {});
    };

    const getIntroducedNumbers = () => {
        setAddedNubmersLoading(true);
        introducedNumbersAPI()
            .then((res) => {
                if (res.status === 200) {
                    setAddedNubmers(res.data.notRegister);
                }
            })
            .catch((err) => {})
            .finally(() => setAddedNubmersLoading(false));
    };

    const updateUserName = () => {
        if (!userFirstName) {
            toast.error("لطفا نام خود را وارد نمایید");
            return;
        }
        if (!userLastName) {
            toast.error("لطفا نام خانوادگی خود را وارد نمایید");
            return;
        }
        setUpdateLoading(true);
        updateUserNameAPI(userFirstName, userLastName)
            .then((res) => {
                if (res.status === 200) {
                    toast.success("اطلاعات با موفقیت بروزرسانی شد");
                    setShowUpdate(false);
                    setConfirmModal(true);
                }
            })
            .catch((err) => {})
            .finally(() => setUpdateLoading(false));
    };

    const sendNumber = () => {
        if (!(phoneNumber.length === 11 && phoneNumber.match(/[0-9]+/g))) {
            toast.error("شماره موبایل را به درستی وارد کنید");
            return;
        }
        setAddNumberLoading(true);
        introduceNumberAPI(phoneNumber, friendName)
            .then((res) => {
                if (res.status === 200) {
                    if (res.data.registering.length > 0) {
                        toast.success("شماره با موفقیت معرفی شد");
                        getIntroducedNumbers();
                        setPhoneNumber("");
                        setFriendName("");
                        setConfirmModal(false);
                    }
                    if (res.data.registered.length > 0) {
                        toast.error("این شماره قبلا معرفی شده است");
                        setPhoneNumber("");
                        setConfirmModal(false);
                    }
                    if (res.data.kartaak.length > 0) {
                        toast.error("این شماره قبلا در کارتک ثبت‌نام کرده است");
                        setPhoneNumber("");
                        setConfirmModal(false);
                    }
                }
            })
            .catch((error) => {})
            .finally(() => setAddNumberLoading(false));
    };

    useEffect(() => {
        getFriends();
        getMaxIntroduced();
    }, []);

    useEffect(() => {
        // window.scrollTo(0, 580);
    }, [showFriends]);

    useEffect(() => {
        setUserFirstName(user.name);
        setUserLastName(user.lastname);
    }, [user]);

    useEffect(() => {
        window.scrollTo(0, 0);
        // getHint();
        // getSecondHint();
        getIntroducedNumbers();
    }, []);

    const [hintOpenStatus, setHintOpenStatus] = useState(false);
    const [hintText, setHintText] = useState("");

    useEffect(() => {
        getHintAPI("راهنمای ثابت صفحه معرفی کاربر جدید")
            .then((response) => {
                setHintText(response.content);
            })
            .catch((error) => {
                console.log(error);
                setHintText("دریافت راهنما با خطا مواجه شد");
            });
    }, []);

    function useQuery() {
        const { search } = useLocation();

        return React.useMemo(() => new URLSearchParams(search), [search]);
    }

    let query = useQuery();

    useEffect(() => {
        history.replace("/add-number" + location.search);
        setRemainingNumber(query.get("amount"));
    }, []);

    return (
        <Fade>
            <PageTopbar title="دعوت از دوستان جدید" showKv={false} />
            <div className="wrapper-fix">
                <Card>
                    <div className="friends-invite__background">
                        <div className="friends-invite__gif">
                            <img src={send} alt="" />
                        </div>

                        <div className="">
                            <div className="friends-invite__hint">
                                {`حداکثر می‌تونی ${maxIntroduced} نفر در صف دعوت داشته باشی و برای اضافه کردن افراد جدید باید صبر کنی تا قبلیا ثبت نام کنن، پس سعی کن افرادیو دعوت کنی که مطمئنی ثبت نام می‌کنن چون امکان حذف نداری و صف دعوتت بیخودی اشغال میشه`}
                            </div>
                            <div className="friends-invite__inputs">
                                {remainingItem.map((item) => (
                                    <>
                                        <div className="friends-invite__input">
                                            <input
                                                type="number"
                                                className="input"
                                                value={phoneNumber}
                                                onChange={(e) =>
                                                    setPhoneNumber(
                                                        e.target.value
                                                    )
                                                }
                                                placeholder="شماره موبایل را وارد کنید"
                                            />
                                        </div>
                                        <div className="friends-invite__hint2">
                                            نوشتن نام دوستات اختیاریه و فقط به
                                            خودت نشون میدیم که بدونی کیو دعوت
                                            کردی
                                        </div>
                                        <div className="friends-invite__input">
                                            {/*{user.isCaptain ? <img src={captain} alt="" height="68px" width="75px"/> : <></>}*/}
                                            <input
                                                type="text"
                                                className="input"
                                                value={friendName}
                                                onChange={(e) =>
                                                    setFriendName(
                                                        e.target.value
                                                    )
                                                }
                                                placeholder="نام دوست خود را وارد کنید (اختیاری)"
                                            />
                                        </div>
                                    </>
                                ))}

                                {/*{remainingNumber > remainingItem.length && <div className="friends-invite__add-circle"*/}
                                {/*      onClick={() => {*/}
                                {/*        let currItems = cloneDeep(remainingItem);*/}
                                {/*        currItems.push('a');*/}
                                {/*        setRemainingItem(currItems);*/}
                                {/*      }}*/}
                                {/*>*/}
                                {/*  <SVG svg={'plus'}/>*/}
                                {/*</div>}*/}
                            </div>
                        </div>
                    </div>

                    <div className="friends-invite__hint mt-8">
                        در صورت زدن کلید تائید یه پیامک دعوت به نام شما برای
                        صاحب این شماره ارسال میشه
                    </div>

                    <div className="friends-invite__actions">
                        <div
                            onClick={() => {
                                if (addedNubmers.length === maxIntroduced) {
                                    toast.error(
                                        `شما نمی‌توانید همزمان بیشتر از ${maxIntroduced} نفر در صف دعوت داشته باشید`
                                    );
                                    return;
                                }
                                if (
                                    phoneNumber.substring(0, 2) != "09" ||
                                    phoneNumber.length != 11
                                ) {
                                    toast.error(
                                        "شماره موبایل را به درستی وارد کنید"
                                    );
                                    return;
                                }
                                !addNumberLoading && sendNumber();
                            }}
                            className={`button green`}
                        >
                            {addNumberLoading ? (
                                <div>
                                    <Dots color={"light"} />
                                </div>
                            ) : (
                                <span>تائید</span>
                            )}
                        </div>
                        {/*friends-invite__add ${addedNubmers.length === maxIntroduced && 'disable'}*/}
                        <div
                            onClick={() => {
                                history.push("/addnewusers");
                            }}
                            className={`button red`}
                        >
                            انصراف
                        </div>
                    </div>
                </Card>
            </div>
        </Fade>
    );
};

export default AddNubmer;
