import { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { getParticipationUnitDetailAPI } from "utils/Services/KartlandUnits";
import { Fade, PageTopbar, Spinner, SVG } from "../../../components";
import empty from "../../../assets/images/empty-kartland.png";
import moment from "jalali-moment";

export const KartlandParticipationPackageContainer = () => {
  const [loading, setLoading] = useState(true);
  const [units, setUnits] = useState([]);
  const { id } = useParams<any>();
  const history = useHistory();

  const getParticipationUnits = () => {
    getParticipationUnitDetailAPI(id)
      .then((response) => {
        var res = [];
        response.data.forEach((element, index) => {
          if (index % 4 === 0) {
            res.push(element);
          }
        });
        setUnits(res);
      })
      .catch((err) => {})
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getParticipationUnits();
  }, []);

  useEffect(() => {
    history.replace(`/kartland/participation/${id}`);
  }, []);

  return (
    <Fade>
      <div className={"kartland"}>
        <PageTopbar title={"واحدهای مشارکتی"} showKv={false} white={true} />
        <Spinner active={loading} color={"dark"} />
        <div className={"kartland__user-buy-wrapper"}>
          <Link
            to={`/certificate/participation/${id}`}
            className="kartland-participation__certificate"
          >
            گواهی مشارکت در فروش واحدهای کارتلند
          </Link>

          <div className="kartland-participation__id">
            <span>شماره سریال بلوک:</span>
            {id}
          </div>

          {units?.length === 0 && (
            <div className="kartland__wrapper__empty">
              <div className="kartland__wrapper__empty__image">
                <img src={empty} alt="empty" />
              </div>
              <div className="kartland__wrapper__empty__text">
                هنوز واحدی فروش نرفته است
              </div>
            </div>
          )}

          <div className="kartland-participation__wrapper">
            {units?.map((unit, index) => (
              <div className="kartland-participation__item" key={index}>
                <div className="kartland-participation__item__header">
                  <div className="kartland-participation__item__circle">
                    <SVG svg="kartland" />
                  </div>
                  فروش فورشاپ
                </div>

                <div className="kartland-participation__item__row">
                  <div className="kartland-participation__item__title">
                    استان:
                  </div>
                  <div className="kartland-participation__item__value">
                    {unit.statename}
                  </div>
                </div>

                <div className="kartland-participation__item__row">
                  <div className="kartland-participation__item__title">
                    شهرستان:
                  </div>
                  <div className="kartland-participation__item__value">
                    {unit.cityname}
                  </div>
                </div>

                <div className="kartland-participation__item__row">
                  <div className="kartland-participation__item__title">
                    تاریخ فروش:
                  </div>
                  <div className="kartland-participation__item__value">
                    {moment(unit.created_at).format("HH:mm - jYYYY/jM/jD")}
                  </div>
                </div>

                {/* <div className="kartland-participation__item__row">
                  <div className="kartland-participation__item__title">وضعیت فروش:</div>
                  <div className="kartland-participation__item__value green">فروش رفته</div>
                </div> */}
              </div>
            ))}
          </div>
        </div>
      </div>
    </Fade>
  );
};
