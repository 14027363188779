import React, {useState, useEffect} from "react";
import {SVG, Fade, Modal, Spinner, Share, Card, Dots, DiscountBadge, CheckBox} from "components";
import dinnerImage from "../../assets/images/dinner2.png";
import profile from "../../assets/images/profile.png";
import product from "../../assets/images/product.png";
import {useHistory, useParams} from "react-router-dom";
import {getSellerDetailsAPI, joinClubAPI, setFavoriteAPI} from "../../utils";
import moment from "jalali-moment";
import {currency} from "../../utils/Functions";
import Slider from "react-slick";
import {useUser} from "../../hooks";
import {reportStoreAPI} from "../../utils/Services/Kartland/ReportStore";
import toast from "react-hot-toast";
import {checkClubAPI} from "../../utils/Services/Kartland/Club/CheckClub";
import {cloneDeep} from "lodash";
import {surveyReportsAPI} from "../../utils/Services/Kartland/SurveyReports";

export const SellerContainer = () => {
  const params: any = useParams();
  const {
    userState: {user},
  } = useUser();
  const [timeModal, setTimeModal] = useState(false);
  const [tellModal, setTellModal] = useState(false);
  const [sellerDetails, setSellerDetails] = useState(null);
  const [sellerDetailsLoading, setSellerDetailsLoading] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);
  const [showFulltext, setShowFulltext] = useState(false);
  const [kmModal, setKmModal] = useState(false);
  const [reportModal, setReportModal] = useState(false);
  const [reportLoading, setReportLoading] = useState(false);
  const [isFavorite, setIsFavorite] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [today, setToday] = useState(false);
  const [reportMessage, setReportMessage] = useState('');
  const history = useHistory();
  const now = moment();
  const day = now.day();
  const hour = now.hours();
  const min = now.minutes();
  const [storeReportsModal, setStoreReportsModal] = useState(false);
  const [errors, setErrors] = useState([
    {
      message: 'فروشگاه KM نداد',
      active: false
    },
    {
      message: 'اطلاعات فروشگاه اشتباه بود',
      active: false
    },
    {
      message: 'چنین فروشگاهی در این مکان وجود ندارد',
      active: false
    },
    {
      message: 'بارکد فروشگاه در دسترس نبود',
      active: false
    },
  ]);
  const [errors2, setErrors2] = useState([
    {
      message: 'اطلاعات فروشگاه اشتباه بود',
      active: false
    },
    {
      message: 'چنین فروشگاهی در این مکان وجود ندارد',
      active: false
    },
    {
      message: 'بارکد فروشگاه در دسترس نبود',
      active: false
    },
  ]);
  const [errorMessages, setErrorMessages] = useState({});
  const [surveyReportLoading, setSurveyReportLoading] = useState(false);

  const activeErrorMessage = (index) => {
    let currErrors = cloneDeep(errors);
    currErrors.map((item, index) => {
      item.active = false;
    });
    currErrors[index].active = true;
    setErrors(currErrors);
    setReportMessage(errors[index].message);
  };

  const activeErrorMessage2 = (index) => {
    let currErrors = cloneDeep(errors2);
    currErrors.map((item, index) => {
      item.active = false;
    });
    currErrors[index].active = true;
    setErrors2(currErrors);
    setReportMessage(errors[index].message);
  };

  const setFavorite = (id) => {
    joinClubAPI(id).then((res) => {
      if (res.status === 200) {
        toast.success('با موفقیت عضو باشگاه شدید');
        checkClub();
      }
    }).catch(err => {
      if (err.response.status === 400) {
        toast.error('شما درحال حاظر عضو باشگاه این فروشگاه هستید');
      }
    }).finally(() => {
    });
  };

  const getSellerDetails = () => {
    setSellerDetailsLoading(true);
    getSellerDetailsAPI(params.id).then((res) => {
      if (res.status === 200) {
        setSellerDetails(res.data);
        setSellerDetailsLoading(false);
      }
    }).catch(err => {

    });
  };

  const sendReport = () => {
    setReportLoading(true);
    reportStoreAPI(params.id, 'kartlandStore', reportMessage).then(res => {
      if (res.status === 200) {
        toast.success('گزارش برای مدیریت ارسال شد');
        setReportModal(false);
      }
    }).catch(err => {

    }).finally(() => setReportLoading(false));
  };

  useEffect(() => {
    if (sellerDetails !== null) {
      checkOpen();
    }
  }, [sellerDetails]);

  const checkClub = () => {
    checkClubAPI(params.id).then(res => {
      if (res.status === 200) {
        setIsFavorite(res.data);
      }
    }).catch(err => {

    }).finally(() => {
    });
  };

  useEffect(() => {
    if (sellerDetails !== null) {
      checkOpen();
    }
  }, [sellerDetails]);

  const dayOfWeek = (day) => {
    switch (day) {
      case 0:
        return "شنبه";
      case 1:
        return "یکشنبه";
      case 2:
        return "دوشنبه";
      case 3:
        return "سه‌شنبه";
      case 4:
        return "چهارشنبه";
      case 5:
        return "پنج‌شنبه";
      case 6:
        return "جمعه";
    }
  };

  const todayOfWeek = (day) => {
    switch (day) {
      case 0:
        return 1;
      case 1:
        return 2;
      case 2:
        return 3;
      case 3:
        return 4;
      case 4:
        return 5;
      case 5:
        return 6;
      case 6:
        return 0;
    }
  };

  const checkOpen = () => {
    sellerDetails.workinghours.map((time) => {
      let openTime = time.hours.split(",")[0];
      let closeTime = time.hours.split(",")[1];
      // console.log(openTime.split(':')[0],openTime.split(':')[0] <= 2);

      if (openTime.split(":")[0] < hour && closeTime.split(":")[0] > hour) {
        setIsOpen(true);
        if (todayOfWeek(day) === time.daysOfWeek) {
          setToday(true);
        }
      } else if (
        openTime.split(":")[0] == hour ||
        closeTime.split(":")[0] == hour
      ) {
        if (openTime.split(":")[0] == hour && closeTime.split(":")[0] > hour) {
          if (openTime.split(":")[1] <= min) {
            setIsOpen(true);
            if (todayOfWeek(day) === time.daysOfWeek) {
              setToday(true);
            }
          }
        }

        if (openTime.split(":")[0] < hour && closeTime.split(":")[0] == hour) {
          if (closeTime.split(":")[1] >= min) {
            setIsOpen(true);
            if (todayOfWeek(day) === time.daysOfWeek) {
              setToday(true);
            }
          }
        }

        if (openTime.split(":")[0] == hour && closeTime.split(":")[0] == hour) {
          if (openTime.split(":")[1] <= min && closeTime.split(":")[1] >= min) {
            setIsOpen(true);
            if (todayOfWeek(day) === time.daysOfWeek) {
              setToday(true);
            }
          }
        }
      }
    });
  };

  const titleHandler = (title) => {
    switch (title) {
      case "1":
        return "بابت مجموع خرید";
      case "2":
        return "بابت خرید محصول";
      case "3":
        return "سایر شرایط";
    }
  };

  const surveyReport = (id, status, message) => {
    if (message === undefined || message.length === 0) {
      toast.error('لطفا توضیحات را وارد نمائید');
      return;
    }

    setSurveyReportLoading(true);
    surveyReportsAPI(id, status, message ? message : '', 'kartlandStore').then(res => {
      if (res.status === 200) {
        toast.success('گزارش با موفقیت رسیدگی شد');
        getSellerDetails();
        setStoreReportsModal(false);
      }
    }).catch(err => {
      toast.error('خطایی رخ داد');
    }).finally(() => setSurveyReportLoading(false));
  };

  const settings = {
    infinite: true,
    slidesToShow: 1,
    speed: 100,
    arrows: false,
    dots: true,
  };

  useEffect(() => {
    getSellerDetails();
    history.replace("/seller/" + params.id);
    checkClub();
  }, []);
  console.log(errorMessages);
  return (
    <Fade>
      <Spinner active={sellerDetailsLoading} color={"dark"}/>
      <Share
        active={showShareModal}
        setClose={() => setShowShareModal(false)}
        title={"اشتراک گذاری فروشگاه"}
        value={"از این فروشگاه بازدید کنید"}
        link={`https://app.kartaak.com/seller/${params.id}`}
      />
      <Modal active={storeReportsModal} close={() => setStoreReportsModal(false)}>
        <div className="seller__reports">
          <div className="seller__reports__list">
            {sellerDetails?.reports?.reportsDetails.map(item => (
              <div className="seller__reports__item">
                <div className="seller__reports__item__date">
                  {`تاریخ گزارش : ${moment(item.created_at, "YYYY/MM/DD")
                    .locale("fa")
                    .format("YYYY/MM/DD")}`}
                </div>
                <div className="seller__reports__item__text">
                  {`نوع تخلف : ${item.message}`}
                </div>
                <textarea
                  className="textarea mb-8"
                  placeholder="توضیحات"
                  value={errorMessages[item.id]}
                  onChange={(e) => {
                    let currMessages = cloneDeep(errorMessages);
                    currMessages[item.id] = e.target.value;
                    setErrorMessages(currMessages);
                  }}
                  rows={3}
                />
                <div className="seller__reports__item__actions">
                  <div onClick={() => surveyReport(item.id, 1, errorMessages[item.id])} className={'button green'}>تائید
                    صحت گزارش
                  </div>
                  <div onClick={() => surveyReport(item.id, 0, errorMessages[item.id])} className={'button red'}>رد
                    گزارش
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Modal>
      {
        sellerDetails !== null && (
          <>
            <div className="seller">
              <div className="seller__header">
                <div className="seller__header__bar"></div>
                <div
                  onClick={() => history.goBack()}
                  className="seller__header__action"
                >
                  <SVG svg="times"/>
                </div>
              </div>
              <div className="seller__body">
                <div className="seller__body__banner">
                  {sellerDetails.discountAmount && <div className="seller__body__banner__badge">
                    <DiscountBadge discount={sellerDetails.discountAmount}/>
                  </div>}
                  <Slider
                    {...settings}
                    dotsClass="profile__slider__dot seller__body__banner__dot"
                  >
                    {sellerDetails?.picturestores?.map((slider) => (
                      <img src={slider.photo} alt="" width="100%" height="100%"/>
                    ))}
                  </Slider>
                  {/* <img
                  width="100%"
                  height="100%"
                  src={
                    sellerDetails?.picturestores[0] !== undefined
                      ? sellerDetails?.picturestores[0]?.photo
                      : dinnerImage
                  }
                /> */}
                  <div
                    className={`seller__body__haveKM ${sellerDetails.totalBoughtKm === 0 && "deactive"
                    }`}
                  >
                    {sellerDetails.totalBoughtKm > 0 ? (
                      <SVG svg="map-km" fillColor={"#FEC1B5"} color={"#FD6547"}/>
                    ) : (
                      <SVG svg="map-km" fillColor={"#c4c4c4"} color={"#868686"}/>
                    )}
                    <span>
                    {sellerDetails.totalBoughtKm > 0 ? "دارای KP" : "بدون KP"}
                  </span>
                  </div>
                  <div className="seller__body__status">
                    <div
                      className={`status-dot ${!(isOpen && today) && "deactive"}`}
                    />
                    <span>{isOpen && today ? "باز است" : "بسته است"}</span>
                  </div>
                </div>
                <Card>
                  <div className="seller__body__title-wrapper">
                  <span className="seller__body__title-wrapper__value">
                    {sellerDetails.name}
                  </span>
                    <div className="seller__body__title-wrapper__actions">
                      {(user.storeSubmiter === 1 && sellerDetails?.reports?.count > 0) && <div
                        onClick={() => setStoreReportsModal(true)}
                        className={`seller__body__title-wrapper__actions__item report`}
                      >
                        بررسی گزارشات
                      </div>}
                      <div
                        onClick={() => setReportModal(true)}
                        className={`seller__body__title-wrapper__actions__item report`}
                      >
                        گزارش
                      </div>
                      <div
                        className="seller__body__title-wrapper__actions__item"
                        onClick={() =>
                          history.push(`/shop/comment/${sellerDetails.id}`)
                        }
                      >
                        <SVG svg="seller-comment"/>
                      </div>
                      <div
                        className="seller__body__title-wrapper__actions__item"
                        onClick={() => setShowShareModal(true)}>
                        <SVG svg="seller-share"/>
                      </div>
                      <div
                        onClick={() => setFavorite(sellerDetails.id)}
                        className={`seller__body__title-wrapper__actions__item ${isFavorite && "active-favorite"}`}
                      >
                        <SVG svg={"seller-bookmark"}/>
                      </div>
                    </div>
                  </div>
                </Card>
                <div className="seller__body__bio">
                <span className="seller__body__bio__value">
                  {sellerDetails.description?.length > 150 ? (
                    !showFulltext ? (
                      <>
                        {sellerDetails.description
                          ?.substring(0, 151)
                          .split("\n")
                          ?.map((str) => (
                            <>{str}</>
                          ))}

                        {/* {sellerDetails.description?.substring(0, 151)} */}
                        <span
                          onClick={() => setShowFulltext(true)}
                          className="seller__body__bio__more"
                        >
                          {" "}
                          ادامه توضیحات ...{" "}
                        </span>
                      </>
                    ) : (
                      sellerDetails.description
                    )
                  ) : (
                    sellerDetails.description
                      ?.split("\n")
                      ?.map((str) => <div>{str}</div>)
                  )}
                </span>
                </div>
                
                <div className="seller__body__product-list">
                  <div onClick={() => history.push(`/seller/products/${sellerDetails.id}`)} className="seller__body__menu__all">
                    <span>مشاهده همه</span>
                  </div>
                  <div style={{fontSize: "12px", margin: "8px 16px 0px 0px"}}>
                    <span>ویترین فروشگاه حضوری</span>
                  </div>
                </div>
                  
                <div className="seller__body__menu seller__body__product-list-color">
                  {sellerDetails?.vitrins?.length > 0 ? sellerDetails?.vitrins?.map((x) => (
                    <div className="seller__body__menu__item">
                      <img
                        width="100%"
                        height="100%"
                        src={x.photo !== null ? x.photo : product}
                        alt=""
                      />
                      <div className="seller__body__menu__item__title">
                        {x.title?.length > 10 ? (
                          <span>{x.title?.substring(0, 11)}...</span>
                        ) : (
                          <span>{x.title}</span>
                        )}
                      </div>
                      {!!x.price && <div className="seller__body__menu__item__price">
                        <span>{currency(x.price)} تومان</span>
                      </div>}
                    </div>
                  )) : <div style={{margin: "0 auto"}}>محصولی یافت نشد.</div>}
                </div>

                <div className="seller__body__product-list">
                  <div onClick={() => history.push(`/seller/products/${sellerDetails.id}`)} className="seller__body__menu__all">
                    {/* <span>مشاهده همه</span> */}
                  </div>
                  <div style={{fontSize: "12px", margin: "8px 16px 0px 0px"}}>
                    <span>ویترین فروشگاه اینترنتی</span>
                  </div>
                </div>
                  
                <div className="seller__body__menu seller__body__product-list-color">
                  {sellerDetails?.onlineVitrin?.length > 0 ? sellerDetails?.onlineVitrin?.map((x) => (
                    <div className="seller__body__menu__item" onClick={() => {
                      history.push(`/product/${x.id}`);
                    }}>
                      <img
                        width="100%"
                        height="100%"
                        src={x.image !== null ? x.image[0] : product}
                        alt=""
                      />
                      <div className="seller__body__menu__item__title">
                        {x.title?.length > 10 ? (
                          <span>{x.title?.substring(0, 11)}...</span>
                        ) : (
                          <span>{x.title}</span>
                        )}
                      </div>
                      {!!x.defaultPrice[1] && <div className="seller__body__menu__item__price">
                        <span>{currency(+x.defaultPrice[1])} تومان</span>
                      </div>}
                    </div>
                  )) : <div style={{margin: "0 auto"}}>محصولی یافت نشد.</div>}
                </div>

                <Card>
                  <div className="seller__body__address">
                    <div className="seller__body__address__header">
                      <div className="seller__body__address__header__location">
                        <SVG svg="seller-location"/>
                        <span>آدرس</span>
                      </div>
                      <div className="seller__body__address__header__navigate">
                        <a
                          className="seller__body__address__header__navigate"
                          href={`geo:${sellerDetails.lat},${sellerDetails.long}`}
                        >
                          <span>مسیر یابی</span>
                          <SVG svg="seller-navigate"/>
                        </a>
                      </div>
                    </div>
                    <div className="seller__body__address__body">
                    <span>
                      {sellerDetails.address.split("\n").map((str) => (
                        <div>{str}</div>
                      ))}
                    </span>
                    </div>
                  </div>
                  <div className="seller__body__actions">
                    <div
                      onClick={() => setTimeModal(true)}
                      className="seller__body__actions__item time"
                    >
                      <SVG svg="seller-time"/>
                      <span>ساعات کاری</span>
                    </div>
                    <div
                      onClick={() => setTellModal(true)}
                      className="seller__body__actions__item tell"
                    >
                      <SVG svg="seller-tell"/>
                      <span>شماره تلفن</span>
                    </div>
                    {/*<div*/}
                    {/*  onClick={() => setKmModal(true)}*/}
                    {/*  className="seller__body__actions__item km-term"*/}
                    {/*>*/}
                    {/*  <SVG svg="seller-km-fill"/>*/}
                    {/*  <span>شرایط KM</span>*/}
                    {/*</div>*/}
                  </div>
                </Card>
                {sellerDetails?.ownerInfo !== 0 && (
                  <div className={`seller__footer`}>
                    <SVG svg="seller-info-bar"/>
                    <img
                      width="100%"
                      height="100%"
                      className="seller__footer__profile"
                      src={
                        sellerDetails.userinfo?.profile_photo
                          ? sellerDetails.userinfo?.profile_photo
                          : profile
                      }
                    />
                    <div className="seller__footer__info">
                    <span className="seller__footer__info__name">
                      {sellerDetails.userinfo?.name}
                    </span>
                      <span className="seller__footer__info__bio">
                      مسئولیت صحت مطالب این صفحه بر عهده صاحب فروشگاه است
                    </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <Modal active={timeModal} close={() => setTimeModal(false)}>
              <div className="seller-time">
                <div className="seller-time__header">ساعات کاری</div>
                <div className="seller-time__data">
                  {sellerDetails.workinghours.map((x) => (
                    <div
                      className={`seller-time__data__item ${todayOfWeek(day) === x.daysOfWeek && "active"
                      }`}
                    >
                    <span
                      className={`seller-time__data__item__day ${todayOfWeek(day) === x.daysOfWeek && "active"
                      }`}
                    >
                      {dayOfWeek(x.daysOfWeek)}
                    </span>
                      <div className="seller-time__data__item__clock">
                        <span>{x.hours.split(",")[0]}</span>
                        <div className="seller-time__data__item__clock__seprator"></div>
                        <span>{x.hours.split(",")[1]}</span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </Modal>
            <Modal active={tellModal} close={() => setTellModal(false)}>
              <div className="seller-tell">
                <div className="seller-tell__header">تماس با فروشگاه</div>
                <div className="seller-tell__data">
                  {sellerDetails.contactnumber?.map((x) => (
                    <div className="seller-tell__data__item">
                      <a href={`tel:${x.num}`}>
                      <span className="seller-tell__data__item__number">
                        {x.num}
                      </span>
                        <div className="seller-tell__data__item__call">
                          <span>تماس</span>
                          <SVG svg="seller-call"/>
                        </div>
                      </a>
                    </div>
                  ))}
                </div>
              </div>
            </Modal>
            <Modal active={kmModal} close={() => setKmModal(false)}>
              <div className="seller-km">
                <div className="seller-km__header">شرایط اهدای KP</div>

                <div className="seller-km__items">
                  {sellerDetails.storeConditions.length > 0 ? (
                    sellerDetails.storeConditions.map(
                      (condition) =>
                        condition.firstFieldStatus === 1 &&
                        condition.secondFieldStatus === 1 &&
                        condition.thirdFieldStatus === 1 && (
                          <div className="seller-km__condition">
                            {/* <div className="row j-c" style={{marginTop: -16}}>
                            <SVG svg="wallet-km" />
                          </div> */}
                            <div className="">
                              {titleHandler(condition.title)}
                              {condition.title == 2
                                ? ` ${condition.thirdField}`
                                : ""}
                              {condition.title == 2
                                ? ` به تعداد ${condition.firstField} عدد، `
                                : ""}
                              {condition.title == 1
                                ? ` به مبلغ ${condition.firstField} تومان، `
                                : ""}
                              {condition.title == 3
                                ? ` ${condition.firstField} `
                                : ""}
                              <span>
                              {condition.secondField} KM دریافت خواهید کرد.
                            </span>
                            </div>
                          </div>
                        )
                    )
                  ) : (
                    <div></div>
                  )}
                </div>
              </div>
            </Modal>
            <Modal active={reportModal} close={() => setReportModal(false)}>
              <div className="report-store">
                <div className="report-store__text">
                  با ثبت گزارش صحیح ما را در اصلاح اطلاعات شهر یاری کن و امتیاز بگیر
                </div>

                <div className="report-store__options">
                  {sellerDetails.haveKm ?
                    (errors.map((item, index) => (
                      <div className="report-store__options__item">
                        <CheckBox
                          defaultValue={item.active}
                          onChange={value => {
                            if (value) {
                              activeErrorMessage(index);
                            }
                          }}/>
                        <div className="report-store__options__item__message">{item.message}</div>
                      </div>
                    )))
                    : (
                      errors2.map((item, index) => (
                        <div className="report-store__options__item">
                          <CheckBox
                            defaultValue={item.active}
                            onChange={value => {
                              if (value) {
                                activeErrorMessage(index);
                              }
                            }}/>
                          <div className="report-store__options__item__message">{item.message}</div>
                        </div>
                      ))
                    )
                  }
                </div>

                <div className="report-store__actions">
                  <div onClick={() => !reportLoading && sendReport()} className="button red">
                    {reportLoading ? (
                      <Dots color={'light'}/>
                    ) : (
                      <span>ارسال گزارش</span>
                    )}
                  </div>
                  <div onClick={() => setReportModal(false)} className="button disable">بازگشت</div>
                </div>
              </div>
            </Modal>
          </>
        )
      }
    </Fade>
  );
};
