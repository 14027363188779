import { Fade, Spinner } from 'components';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

export const GiftContainer = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);

  useEffect(() => {

    return () => {

    };
  }, []);

  useEffect(() => {
    history.replace("/gift");
  }, []);

  return (
    <Fade>
      <Spinner active={loading} color="dark" />
      <div className="gift">
        <div className="gift__image">
          <img alt="" src="" />
        </div>
        <div className="gift__title">
          شما برنده جایزه‌ شدید!
        </div>
        <div className="gift__section">
        </div>
      </div>
    </Fade>
  );
}