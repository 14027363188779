import React, { useEffect, useState } from "react";
import { Fade, PageTopbar, Spinner, SVG } from "components";
import { useHistory, useParams } from "react-router-dom";
import { getMyDirectCitizenInfoAPI } from "utils";
import toast from "react-hot-toast";
import { currency } from "utils/Functions";
import { Buy, Item } from "containers/KartLandContainer/dependencies";

export const KartlandMyCitizenDetailsContainer = () => {
    const history = useHistory();
    const { id } = useParams<{ id: any }>();
    const [loading, setLoading] = useState(true);
    const [citizenUnit, setCitizenUnit] = useState([]);

    const getCitizenInfo = () => {
        getMyDirectCitizenInfoAPI(id)
            .then((response) => {
                if (response.status === 200) {
                    setCitizenUnit(response.data);
                }
            })
            .catch((error) => {
                toast.error("دریافت اطلاعات با خطا مواجه شد.");
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        getCitizenInfo();
    }, []);

    console.log(citizenUnit);

    return (
        <Fade>
            <Spinner active={loading} color="dark" />
            <div className={"kartland"}>
                <PageTopbar
                    title="ویترین‌های سیتیزن من"
                    showKv={false}
                    white={true}
                />
                <div className={"kartland__user-buy-wrapper"}>
                    {/* <div className={"kartland-buy__actions"}>
            <div className="button" onClick={() => setActions(!actions)}>
              خرید واحد
            </div>
            <Link to={'/kartland/participation'} className={"button"}>مشارکت</Link>
          </div> */}

                    {/* {actions && (
            <div className={"kartland-buy__actions"}>
              <Link to={'/kartland/buy'} className={"button green"}>خرید از کارتک</Link>
              <Link to={`/kartland/user-buy`} className={"button green"}>خرید از کاربران</Link>
            </div>
          )} */}

                    {/* <Link style={{marginBottom: "15px"}} to={'/kartland/my-citizen'} className={"button"}>سیتیزن های من</Link> */}

                    <Item
                        myCitizen={true}
                        userId={id}
                        citizenUnits={citizenUnit}
                        myUnits=""
                        data={citizenUnit}
                    />
                    {citizenUnit.length === 0 && (
                        <div className="kartland__wrapper__empty">
                            <div className="kartland__wrapper__empty__image">
                                empty
                            </div>
                            <div className="kartland__wrapper__empty__text">
                                ویترینی وجود ندارد
                            </div>
                            <div className="kartland__wrapper__empty__subtext">
                                درحال حاضر شما ویترینی خریداری نکرده‌اید
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </Fade>
        // <Fade>
        //     <Spinner active={loading} color="dark" />
        //     <div className={"kartland wrapper-fix"}>
        //         <PageTopbar
        //             title="ویترین‌های سیتیزن من"
        //             showKv={false}
        //             white={true}
        //         />
        //         <div className="myCitizenList">
        //             {citizenUnit.length > 0 ? (
        //                 <div className="myCitizenList__list">
        //                     {citizenUnit?.map((unit) =>
        //                         unit.internetStore_id ? (
        //                             <div
        //                                 onClick={() => {
        //                                     history.push(
        //                                         `/kartland/${unit.id}`
        //                                     );
        //                                 }}
        //                                 className="myCitizenList__list__info"
        //                             >
        //                                 <div className="kartland__item__cover myCitizenList__list__info__cover">
        //                                     <SVG svg="kartland" />
        //                                 </div>
        //                                 <div className="myCitizenList__list__info__text">
        //                                     <span>
        //                                         ویترین شماره {unit?.unitnumber}{" "}
        //                                         <span style={{ color: "gray" }}>
        //                                             ({unit?.cityname})
        //                                         </span>
        //                                     </span>
        //                                     <span>
        //                                         فروشگاه : {unit?.netStore?.name}
        //                                     </span>
        //                                     <span
        //                                         style={{
        //                                             color: "#17cfad",
        //                                             fontSize: "14px",
        //                                         }}
        //                                     >
        //                                         اشغال (اینترنتی)
        //                                     </span>
        //                                     <span>
        //                                         میزان فروش :{" "}
        //                                         {currency(
        //                                             Number(
        //                                                 unit?.netStore
        //                                                     ?.accounting[0]
        //                                             )
        //                                         )}{" "}
        //                                         تومان
        //                                     </span>
        //                                 </div>
        //                             </div>
        //                         ) : unit.kartlandStore_id ? (
        //                             <div
        //                                 onClick={() => {
        //                                     history.push(
        //                                         `/kartland/${unit.id}`
        //                                     );
        //                                 }}
        //                                 className="myCitizenList__list__info"
        //                             >
        //                                 <div className="kartland__item__cover myCitizenList__list__info__cover">
        //                                     <SVG svg="kartland" />
        //                                 </div>
        //                                 <div className="myCitizenList__list__info__text">
        //                                     <span>
        //                                         ویترین شماره {unit?.unitnumber}{" "}
        //                                         <span style={{ color: "gray" }}>
        //                                             ({unit?.cityname})
        //                                         </span>
        //                                     </span>
        //                                     <span>
        //                                         فروشگاه :{" "}
        //                                         {unit?.kartstore?.name}
        //                                     </span>
        //                                     <span
        //                                         style={{
        //                                             color: "#17cfad",
        //                                             fontSize: "14px",
        //                                         }}
        //                                     >
        //                                         اشغال (حضوری)
        //                                     </span>
        //                                 </div>
        //                             </div>
        //                         ) : (
        //                             <div
        //                                 onClick={() => {
        //                                     history.push(
        //                                         `/kartland/${unit.id}`
        //                                     );
        //                                 }}
        //                                 className="myCitizenList__list__info"
        //                             >
        //                                 <div className="kartland__item__cover myCitizenList__list__info__cover">
        //                                     <SVG svg="kartland" />
        //                                 </div>
        //                                 <div className="myCitizenList__list__info__text">
        //                                     <span>
        //                                         ویترین شماره {unit?.unitnumber}{" "}
        //                                         <span style={{ color: "gray" }}>
        //                                             ({unit?.cityname})
        //                                         </span>
        //                                     </span>
        //                                     <span
        //                                         style={{
        //                                             color: "#eb4d4c",
        //                                             fontSize: "14px",
        //                                         }}
        //                                     >
        //                                         تخلیه
        //                                     </span>
        //                                 </div>
        //                             </div>
        //                         )
        //                     )}
        //                 </div>
        //             ) : (
        //                 <div className="myCitizenList__not-found">
        //                     ویترینی یافت نشد
        //                 </div>
        //             )}
        //         </div>
        //     </div>
        // </Fade>
    );
};
