import { CheckBox, Selectbox, SVG } from "components";
import newStarSound from "../../../assets/sounds/star.mp3";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import {
    fillProfileAPI,
    getFreelanceJobTypeAPI,
    getInventory,
} from "utils/Services/API";
import { useUser } from "hooks";

const FillProfileEmployment = ({ close }) => {
    const user_token = localStorage.getItem("user_token");
    const {
        userState: { user },
    } = useUser();
    const [audio] = useState(new Audio(newStarSound));

    const [employmentStatus, setEmploymentStatus] = useState(0);
    const [employmentType, setEmploymentType] = useState(0);
    const [readyToWork, setReadyToWork] = useState(0);
    const [emeritus, setEmeritus] = useState(0);
    const [skill, setSkill] = useState(0);
    const [skillField, setSkillField] = useState("");

    const [guild, setGuild] = useState(0);
    const [guildField, setGuildField] = useState("");

    const [employmentSalaried, setEmploymentSalaried] = useState(0);

    const [governmentJob, setGovernmentJob] = useState(0);
    const [governmentJobField, setGovernmentJobField] = useState("");

    const [freelanceJob, setFreelanceJob] = useState(0);
    const [freelanceJobField, setFreelanceJobField] = useState("");

    const [freelanceJobType, setFreelanceJobType] = useState(0);

    const [dataList, setDataList] = useState({
        skills: [],
        jobTitles: [],
        guilds: [],
        government: [],
        freelanceJobs: [],
        freelanceJobsTypes: [],
    });

    const handleActivateSubmit = () => {
        if (
            employmentStatus === 0 ||
            (employmentStatus === 2 && readyToWork === 0) ||
            (employmentStatus === 2 && readyToWork === 1 && emeritus === 0) ||
            (employmentStatus === 2 &&
                readyToWork === 2 &&
                skill === 0 &&
                skillField.length === 0) ||
            (employmentStatus === 1 && employmentType === 0) ||
            (employmentStatus === 1 &&
                employmentType === 2 &&
                guild === 0 &&
                guildField?.length === 0) ||
            (employmentStatus === 1 &&
                employmentType === 3 &&
                employmentSalaried === 0) ||
            // || (employmentStatus === 1 && employmentType === 3 &&  employmentSalaried === 1 && (governmentJob === 0 && governmentJobField?.length === 0))
            (employmentStatus === 1 &&
                employmentType === 3 &&
                employmentSalaried === 2 &&
                freelanceJob === 0)
        )
            return false;
        else return true;
    };

    const handleSubmitForm = () => {
        if (handleActivateSubmit()) {
            var apiRequest = {};
            if (employmentStatus === 1 && employmentType === 1) {
                apiRequest = {
                    employmentStatus: 1,
                    employmentType: 1,
                };
            }
            if (employmentStatus === 1 && employmentType === 2) {
                apiRequest =
                    guild > 0
                        ? {
                              employmentStatus: 1,
                              employmentType: 2,
                              guilds: guild,
                          }
                        : {
                              employmentStatus: 1,
                              employmentType: 2,
                              guilds: guildField,
                          };
            }
            if (
                employmentStatus === 1 &&
                employmentType === 3 &&
                employmentSalaried === 1
            ) {
                apiRequest =
                    governmentJob > 0
                        ? {
                              employmentStatus: 1,
                              employmentType: 3,
                              employmentSalaried: 1,
                              governmentJob: governmentJob,
                          }
                        : governmentJobField.length === 0
                        ? {
                              employmentStatus: 1,
                              employmentType: 3,
                              employmentSalaried: 1,
                          }
                        : {
                              employmentStatus: 1,
                              employmentType: 3,
                              employmentSalaried: 1,
                              governmentJob: governmentJobField,
                          };
            }
            if (
                employmentStatus === 1 &&
                employmentType === 3 &&
                employmentSalaried === 2
            ) {
                apiRequest =
                    freelanceJob > 0
                        ? {
                              employmentStatus: 1,
                              employmentType: 3,
                              employmentSalaried: 2,
                              freelanceJob: freelanceJob,
                              freelanceJobType: freelanceJobType,
                          }
                        : {
                              employmentStatus: 1,
                              employmentType: 3,
                              employmentSalaried: 2,
                              freelanceJob: freelanceJobField,
                              freelanceJobType: freelanceJobType,
                          };
            }
            if (employmentStatus === 2 && readyToWork === 1) {
                apiRequest = {
                    employmentStatus: 2,
                    readyToWork: 1,
                    emeritus: emeritus,
                };
            }
            if (employmentStatus === 2 && readyToWork === 2) {
                apiRequest =
                    skill > 0
                        ? {
                              employmentStatus: 2,
                              readyToWork: 2,
                              skill: skill,
                          }
                        : {
                              employmentStatus: 2,
                              readyToWork: 2,
                              skill: skillField,
                          };
            }
            const APIResult = fillProfileAPI(
                user_token,
                "employment",
                apiRequest
            );
            APIResult.then((response) => {
                if (response.status === 200) {
                    toast.success("اطلاعات ستاره ثبت شد.");
                    if (user.detail.isSound) {
                        audio.play();
                    }
                    close(true);
                }
            });
        } else {
            toast.error("برای دریافت ستاره، مقادیر الزامی را وارد نمایید.");
        }
    };

    const getSkills = () => {
        const APIResult = getInventory(user_token, "skill");
        APIResult.then((response) => {
            setDataList((prevState) => ({
                ...prevState,
                skills: response.data.data,
            }));
        });
    };

    const getJobTitles = () => {
        const APIResult = getInventory(user_token, "jobTitle");
        APIResult.then((response) => {
            setDataList((prevState) => ({
                ...prevState,
                jobTitles: response.data.data,
            }));
        });
    };

    const getGuilds = () => {
        const APIResult = getInventory(user_token, "guilds");
        APIResult.then((response) => {
            setDataList((prevState) => ({
                ...prevState,
                guilds: response.data.data,
            }));
        });
    };

    const getGovernment = () => {
        const APIResult = getInventory(user_token, "government");
        APIResult.then((response) => {
            setDataList((prevState) => ({
                ...prevState,
                government: response.data.data,
            }));
        });
    };

    const getFreelanceJobs = () => {
        const APIResult = getInventory(user_token, "freelanceJob");
        APIResult.then((response) => {
            setDataList((prevState) => ({
                ...prevState,
                freelanceJobs: response.data.data,
            }));
        });
    };

    const getFreelanceJobType = (type) => {
        const APIResult = getFreelanceJobTypeAPI(user_token, type);
        APIResult.then((response) => {
            setDataList((prevState) => ({
                ...prevState,
                freelanceJobsTypes: response.data.data,
            }));
        });
    };

    useEffect(() => {
        getSkills();
        getJobTitles();
        getGuilds();
        getGovernment();
        getFreelanceJobs();
    }, []);

    return (
        <div className="fill-profile__form">
            <audio src={newStarSound} autoPlay={false}></audio>

            <div className="row">
                <div className="row" style={{ marginLeft: 16 }}>
                    <CheckBox
                        disable
                        defaultValue={employmentStatus === 1 && true}
                        onChange={(value) => {
                            if (value) {
                                setEmploymentStatus(1);
                                setReadyToWork(0);
                                setEmeritus(0);
                                setSkill(0);
                            }
                        }}
                    />
                    <div className="fill-profile__form__title">شاغل</div>
                </div>

                <div className="row">
                    <CheckBox
                        disable
                        defaultValue={employmentStatus === 2 && true}
                        onChange={(value) => {
                            if (value) {
                                setEmploymentStatus(2);
                                setEmploymentType(0);
                                setGuild(0);
                                setEmploymentSalaried(0);
                                setGovernmentJob(0);
                                setFreelanceJob(0);
                                setFreelanceJobType(0);
                            }
                        }}
                    />
                    <div className="fill-profile__form__title">غیرشاغل</div>
                </div>
            </div>

            {employmentStatus === 2 && (
                <div className="row">
                    <div className="row" style={{ marginLeft: 16 }}>
                        <CheckBox
                            disable
                            defaultValue={readyToWork === 2 && true}
                            onChange={(value) => {
                                if (value) {
                                    setReadyToWork(2);
                                }
                            }}
                        />
                        <div className="fill-profile__form__title">
                            آماده به کار هستم
                        </div>
                    </div>

                    <div className="row">
                        <CheckBox
                            disable
                            defaultValue={readyToWork === 1 && true}
                            onChange={(value) => {
                                if (value) {
                                    setReadyToWork(1);
                                    setEmeritus(0);
                                }
                            }}
                        />
                        <div className="fill-profile__form__title">
                            آماده به کار نیستم
                        </div>
                    </div>
                </div>
            )}

            {readyToWork === 1 && (
                <div className="row">
                    <div className="row" style={{ marginLeft: 16 }}>
                        <CheckBox
                            disable
                            defaultValue={emeritus === 1 && true}
                            onChange={(value) => {
                                if (value) {
                                    setEmeritus(1);
                                    setSkill(0);
                                }
                            }}
                        />
                        <div className="fill-profile__form__title">
                            بازنشسته‌ام
                        </div>
                    </div>

                    <div className="row">
                        <CheckBox
                            disable
                            defaultValue={emeritus === 2 && true}
                            onChange={(value) => {
                                if (value) {
                                    setEmeritus(2);
                                }
                            }}
                        />
                        <div className="fill-profile__form__title">
                            بازنشسته نیستم
                        </div>
                    </div>
                </div>
            )}

            {readyToWork === 2 && (
                <React.StrictMode>
                    <div className="fill-profile__form__title">
                        <span className="required" />
                        مهارت
                    </div>

                    <Selectbox
                        openAll
                        data={dataList.skills}
                        placeholder="انتخاب مهارت"
                        onChange={(value) => setSkill(value)}
                        onChangeString={(value) => setSkillField(value)}
                    />
                </React.StrictMode>
            )}

            {employmentStatus === 1 && (
                <div className="row">
                    <div className="row" style={{ marginLeft: 16 }}>
                        <CheckBox
                            disable
                            defaultValue={employmentType === 1 && true}
                            onChange={(value) => {
                                if (value) {
                                    setEmploymentType(1);
                                    setGuild(0);
                                    setEmploymentSalaried(0);
                                    setGovernmentJob(0);
                                    setFreelanceJob(0);
                                    setFreelanceJobType(0);
                                }
                            }}
                        />
                        <div className="fill-profile__form__title">
                            خانه‌دار
                        </div>
                    </div>

                    <div className="row" style={{ marginLeft: 16 }}>
                        <CheckBox
                            disable
                            defaultValue={employmentType === 2 && true}
                            onChange={(value) => {
                                if (value) {
                                    setEmploymentType(2);
                                    setEmploymentSalaried(0);
                                    setGovernmentJob(0);
                                    setFreelanceJob(0);
                                    setFreelanceJobType(0);
                                }
                            }}
                        />
                        <div className="fill-profile__form__title">
                            صاحب کسب‌وکار
                        </div>
                    </div>

                    <div className="row">
                        <CheckBox
                            disable
                            defaultValue={employmentType === 3 && true}
                            onChange={(value) => {
                                if (value) {
                                    setEmploymentType(3);
                                    setGuild(0);
                                }
                            }}
                        />
                        <div className="fill-profile__form__title">
                            حقوق بگیر
                        </div>
                    </div>
                </div>
            )}

            {employmentType === 2 && (
                <React.StrictMode>
                    <div className="fill-profile__form__title">
                        <span className="required" />
                        صنف شغلی
                    </div>

                    <Selectbox
                        openAll
                        data={dataList.guilds}
                        placeholder="انتخاب نوع صنف"
                        onChange={(value) => setGuild(value)}
                        onChangeString={(value) => setGuildField(value)}
                    />

                    <div className="input__hint" style={{ marginTop: "-15px" }}>
                        (در صورتی که صنف شغلی شما در لیست نبود، آن را دستی وارد
                        نمایید.)
                    </div>
                </React.StrictMode>
            )}

            {employmentType === 3 && (
                <React.StrictMode>
                    <div className="row">
                        <div className="row" style={{ marginLeft: 16 }}>
                            <CheckBox
                                disable
                                defaultValue={employmentSalaried === 1 && true}
                                onChange={(value) => {
                                    if (value) {
                                        setEmploymentSalaried(1);
                                        setFreelanceJob(0);
                                        setFreelanceJobType(0);
                                    }
                                }}
                            />
                            <div className="fill-profile__form__title">
                                دولتی
                            </div>
                        </div>

                        <div className="row">
                            <CheckBox
                                disable
                                defaultValue={employmentSalaried === 2 && true}
                                onChange={(value) => {
                                    if (value) {
                                        setEmploymentSalaried(2);
                                        setGovernmentJob(0);
                                    }
                                }}
                            />
                            <div className="fill-profile__form__title">
                                خصوصی
                            </div>
                        </div>
                    </div>
                </React.StrictMode>
            )}

            {employmentSalaried === 1 && (
                <React.StrictMode>
                    <div className="fill-profile__form__title">
                        {/* <span className="required" /> */}
                        نام سازمان
                    </div>

                    <Selectbox
                        openAll
                        data={dataList.government}
                        placeholder="اختیاری"
                        onChange={(value) => setGovernmentJob(value)}
                        onChangeString={(value) => setGovernmentJobField(value)}
                    />

                    <div className="input__hint" style={{ marginTop: "-15px" }}>
                        (در صورتی که نام سازمان شما در لیست نبود، آن را دستی
                        وارد نمایید.)
                    </div>
                </React.StrictMode>
            )}

            {employmentSalaried === 2 && (
                <React.StrictMode>
                    <div className="fill-profile__form__title">
                        <span className="required" />
                        نوع سازمان
                    </div>

                    <Selectbox
                        openAll
                        data={dataList.freelanceJobs}
                        placeholder="انتخاب سازمان"
                        onChange={(value) => {
                            setFreelanceJob(value);
                            getFreelanceJobType(value);
                        }}
                        onChangeString={(value) => setFreelanceJobField(value)}
                    />

                    <div className="input__hint" style={{ marginTop: "-15px" }}>
                        (در صورتی که نوع سازمان شما در لیست نبود، آن را دستی
                        وارد نمایید.)
                    </div>

                    <Selectbox
                        openAll
                        data={dataList.freelanceJobsTypes}
                        placeholder="عنوان"
                        onChange={(value) => setFreelanceJobType(value)}
                        disabled={freelanceJob === 0 && true}
                    />

                    <div className="input__hint" style={{ marginTop: "-15px" }}>
                        (در صورتی که عنوان سازمان شما در لیست نبود، آن را دستی
                        وارد نمایید.)
                    </div>
                </React.StrictMode>
            )}
            {/*
      <Selectbox
        data={studies}
        placeholder="انتخاب رشته‌ی تحصیلی"
        onChange={value => {
          setStudyId(value);
          setStudyField('');
        }}
        onChangeString={value => setStudyField(value)}
      /> */}

            <div
                className={`fill-profile__form__button ${
                    handleActivateSubmit() && "active"
                }`}
                onClick={() => handleSubmitForm()}
            >
                <div className="fill-profile__form__button__shape">
                    <SVG
                        svg="bottom-shape"
                        color={handleActivateSubmit() ? "#21bbe1" : "#b3bdcc"}
                    />
                </div>
                ثبت
                <div className="fill-profile__form__button__star">
                    <SVG svg="profile-star" />
                </div>
            </div>
        </div>
    );
};

export default FillProfileEmployment;
