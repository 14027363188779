import axios from "axios";
import { apiVersion, devIp } from "../API";

export const tokenWalletAPI = async (token) => {
  const APIResult = await axios({
    method: "GET",
    url: devIp + "api/v3/wallet/detail",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });

  return APIResult;
};
