import { useEffect } from "react";
import { Fade, PageTopbar, CounterInput, SVG } from "components";
import { useHistory } from "react-router-dom";

export const KvConvertContainer = ({ children }) => {
  const history = useHistory();
  useEffect(() => {
    history.replace("/kv-convert");
  }, []);
  return (
    <Fade>
      <PageTopbar title="تبدیل KV به KM" showKv={false} />
      <div className="kv-convert">
        <label>تعداد KM</label>
        <CounterInput
          onChange={(value) => console.log(value)}
          defaultValue={1}
        />
        <div className="kv-convert__action">
          <span>تبدیل</span>
          <SVG svg="kv-convert" />
        </div>
      </div>
    </Fade>
  );
};
