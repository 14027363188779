import React, { useEffect, useState } from "react";
import { PageTopbar, Card, SVG, Spinner, Modal, Hint } from "components";
import { useHistory, useParams } from "react-router-dom";
import { getMyUnitInfoAPI } from "../../utils/Services/KartlandUnits";
import toast from "react-hot-toast";
import { getHintAPI } from "../../utils";
import { deleteRentedProductAPI } from "utils/Services/KartlandUnits/DeleteRentedProduct";
import { useUser } from "hooks";

export const KartLandUnitContainer = ({ children }) => {
    const history = useHistory();
    const params: any = useParams();
    const {
        userState: { user },
    } = useUser();
    const [unitDetails, setUnitDetails] = useState<any>();
    const [unitDetailsLoading, setUnitDetailsLoading] = useState(true);
    const [deleteStoreLoading, setDeleteStoreLoading] = useState(false);
    const [showHint, setShowHint] = useState(false);
    const [hint, setHint] = useState<any>();

    const getMyUnitInfo = () => {
        setUnitDetailsLoading(true);
        getMyUnitInfoAPI(params.id).then((res) => {
            if (res.status === 200) {
                setUnitDetails(res.data);
                setUnitDetailsLoading(false);
            }
        });
    };

    const getHint = () => {
        getHintAPI("راهنمای KV").then((res) => {
            setHint(res);
        });
    };

    const checkUnitStatus = (item, user) => {
        if (item.owner_id === item.user_id) {
            if (item.isRent === 0) {
                return (
                    <span className={`kartland-unit__status__value`}>
                        تخلیه است
                    </span>
                );
            } else if (item.isRent === 1) {
                return (
                    <span className={`kartland-unit__status__value rented`}>
                        محصول دارد
                    </span>
                );
            }
        } else if (item.owner_id !== item.user_id) {
            if (user.id === item.owner_id) {
                return (
                    <span className={`kartland-unit__status__value `}>
                        واگذار شده است
                    </span>
                );
            } else if (user.id === item.user_id) {
                if (item.isRent === 0) {
                    return (
                        <span className={`kartland-unit__status__value`}>
                            تخلیه است
                        </span>
                    );
                } else if (item.isRent === 1) {
                    return (
                        <span className={`kartland-unit__status__value rented`}>
                            محصول دارد
                        </span>
                    );
                }
            }
        }
    };

    // const deleteSell = () => {
    //   setDeleteLoading(true);
    //   removeFromSaleAPI(params.id).then(res => {
    //     if (res.status === 200) {
    //       toast.success("فروش واحد لغو شد");
    //       setDeleteLoading(false);
    //       getMyUnitInfo();
    //     }
    //   });
    // };

    const deleteRentedStore = () => {
        setDeleteStoreLoading(true);
        deleteRentedProductAPI(params.id).then((res) => {
            if (res.status === 200) {
                toast.success("ویترین خالی شد");
                setDeleteStoreLoading(false);
                getMyUnitInfo();
            }
        });
    };

    useEffect(() => {
        history.replace("/kartland/" + params.id);
    }, []);

    useEffect(() => {
        getMyUnitInfo();
        getHint();
    }, []);

    return (
        <div style={{ height: "100%" }} className="kartland-unit">
            <PageTopbar title="جزییات ویترین" showKv={false} />
            <Spinner active={unitDetailsLoading} color="dark" />
            <Modal active={showHint} close={() => setShowHint(false)}>
                <div className="kartland-unit__hint">
                    <div className="kartland-unit__hint__title">
                        {hint?.title}
                    </div>
                    <div className="kartland-unit__hint__body">
                        {hint?.content}
                    </div>
                </div>
            </Modal>
            {unitDetailsLoading === false && (
                <>
                    <Card>
                        <div className="kartland-unit__header">
                            <div className="kartland-unit__header__item">
                                <div className="row a-c">
                                    <SVG svg="kartland-home" />
                                    <span className="kartland-unit__header__item__title">
                                        شماره ویترین :
                                    </span>
                                </div>
                                <span className="kartland-unit__header__item__value">
                                    {unitDetails?.unitnumber}
                                </span>
                            </div>
                            <div className="kartland-unit__header__item">
                                <div className="row a-c">
                                    <SVG svg="kartland-location" />
                                    <span className="kartland-unit__header__item__title">
                                        استان :
                                    </span>
                                </div>
                                <span className="kartland-unit__header__item__value">
                                    {unitDetails?.statename}
                                </span>
                            </div>
                            <div className="kartland-unit__header__item">
                                <div className="row a-c">
                                    <SVG svg="kartland-location" />
                                    <span className="kartland-unit__header__item__title">
                                        شهرستان :
                                    </span>
                                </div>
                                <span className="kartland-unit__header__item__value">
                                    {unitDetails?.cityname}
                                </span>
                            </div>
                        </div>
                        <div className="kartland-unit__actions">
                            {user?.id === unitDetails?.owner_id && (
                                <div
                                    onClick={() =>
                                        history.push(
                                            `/certificate/${unitDetails.id}`
                                        )
                                    }
                                    className="kartland-unit__certificate"
                                >
                                    <span>گواهی مالکیت ویترین</span>
                                    <SVG svg="kartland-certificate" />
                                </div>
                            )}
                            {unitDetails?.isSold ? (
                                <div
                                    onClick={() =>
                                        history.push(
                                            `/kartland/sell/${unitDetails.id}`
                                        )
                                    }
                                    className={`kartland-unit__sell ${
                                        unitDetails.isSold && "selling"
                                    }`}
                                >
                                    <span>در انتظار فروش</span>
                                </div>
                            ) : (
                                ""
                            )}
                            {user?.id === unitDetails?.user_id &&
                            !unitDetails.isSold ? (
                                <div
                                    onClick={() =>
                                        history.push(
                                            `/kartland/sell/${unitDetails.id}`
                                        )
                                    }
                                    className="kartland-unit__sell"
                                >
                                    <span>فروش ویترین</span>
                                </div>
                            ) : (
                                ""
                            )}
                        </div>
                    </Card>
                    <Card>
                        <div className="col a-c">
                            <span className="kartland-unit__status">
                                وضعیت ویترین :
                                {checkUnitStatus(unitDetails, user)}
                            </span>
                            {user?.id === unitDetails?.user_id &&
                            !unitDetails?.isRent ? (
                                <div className="kartland-unit__not-found">
                                    <div className="kartland-unit__not-found__logo">
                                        <SVG svg="kartland" />
                                    </div>
                                    <span className="kartland-unit__not-found__title row">
                                        <div className={""}>
                                            ویترین شما محصولی ندارد
                                        </div>
                                        <div className="kartland-unit__not-found__hint">
                                            <Hint
                                                onClick={() =>
                                                    setShowHint(true)
                                                }
                                            />
                                        </div>
                                    </span>
                                    <span className="kartland-unit__not-found__description">
                                        با انتخاب یکی از محصولات پاساژ کارتک
                                        برای خود درامد کسب کنید
                                    </span>
                                    <div
                                        onClick={() =>
                                            history.push(
                                                `/kartland/rent/${unitDetails.id}`
                                            )
                                        }
                                        className={`button`}
                                    >
                                        انتخاب محصول از پاساژ کارتک
                                    </div>
                                </div>
                            ) : (
                                <>
                                    {unitDetails?.isRent &&
                                    unitDetails?.product ? (
                                        <>
                                            <img
                                                src={
                                                    unitDetails?.product
                                                        ?.image[0]
                                                }
                                                className="kartland-unit__cover"
                                                alt="unitProductImage"
                                            />
                                            <div className="kartland-unit__store__actions">
                                                <div
                                                    onClick={() =>
                                                        history.push(
                                                            `/product/${unitDetails?.product?.id}`
                                                        )
                                                    }
                                                    className="kartland-unit__visit"
                                                >
                                                    <span>
                                                        {unitDetails?.product
                                                            ? unitDetails
                                                                  ?.product
                                                                  ?.title
                                                            : null}
                                                    </span>
                                                    <SVG svg="kartland-shop" />
                                                </div>
                                                {user?.id ===
                                                    unitDetails?.user_id && (
                                                    <div
                                                        onClick={() =>
                                                            deleteStoreLoading ===
                                                                false &&
                                                            deleteRentedStore()
                                                        }
                                                        className="kartland-unit__sell selling"
                                                    >
                                                        <span>
                                                            تخلیه ویترین
                                                        </span>
                                                    </div>
                                                )}
                                            </div>
                                        </>
                                    ) : (
                                        ""
                                    )}
                                    {/*<span className="kartland-unit__title">{unitDetails?.kartstore?.name}</span>*/}
                                </>
                            )}
                        </div>
                    </Card>
                    <Card
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "1rem",
                        }}
                    >
                        <div
                            style={{ textAlign: "center" }}
                            className="kartland-unit__status"
                        >
                            جزییات فروش ویترین
                        </div>

                        {unitDetails?.history?.length === 0 ? (
                            <div className="friends__not-found">
                                <div
                                    style={{ marginTop: "10px" }}
                                    className="friends__not-found__logo"
                                >
                                    <SVG svg="km-pen" />
                                </div>
                                <span className="friends__not-found__title">
                                    تاریخچه‌ای وجود ندارد
                                </span>
                            </div>
                        ) : (
                            unitDetails?.history?.map((unit) => (
                                <div className="cash-history__detail">
                                    <div className="row">
                                        <div
                                            className={`cash-history__detail__type `}
                                        >
                                            <SVG svg="withdraw-money" />
                                        </div>

                                        <div className="col">
                                            <div
                                                style={{ marginBottom: "10px" }}
                                                className="cash-history__detail__amount"
                                            >
                                                نام محصول :{" "}
                                                <span>{unit.product_name}</span>
                                            </div>
                                            <div
                                                style={{ marginBottom: "10px" }}
                                                className="cash-history__detail__source"
                                            >
                                                شماره فاکتور :{" "}
                                                <span>{unit.orderId}</span>
                                            </div>
                                            <div className="cash-history__detail__source">
                                                تعداد :{" "}
                                                <span>{unit.count}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        )}
                    </Card>
                </>
            )}
        </div>
    );
};
