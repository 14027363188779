import axios from "axios";
import { STORAGE_KEY } from "providers";
import toast from "react-hot-toast";
import { useHistory } from "react-router-dom";
import { useAuth, useCart, useUser } from "hooks";

export const apiVersion = process.env.REACT_APP_API_VERSION;
axios.defaults.baseURL = process.env.REACT_APP_DEV_IP;
axios.defaults.headers.common["Content-Type"] = "application/json";

export const SetupInterceptors = () => {
  const history = useHistory();
  const { onSetLoginStatus } = useAuth();
  const { setCartData, getCartDataFromApi } = useCart();
  const { setUserData, getUserDataFromApi, getUserProfile } = useUser();

  axios.interceptors.request.use((config) => {
    const token = localStorage.getItem(STORAGE_KEY.TOKEN);
    if (token !== null) config.headers.Authorization = `Bearer ${token}`;
    return config;
  });
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      const expectedError =
        error.response &&
        error.response.status >= 400 &&
        error.response.status <= 500;
      if (!expectedError) {
        if ((error + "").includes("Network"))
          toast.error("خطا در برقراری ارتباط", {id: 'server error'});
        // else toast.error("خطای غیرمنتظره ای رخ داد.");
      } 
      // else if (error.response.status === 404) {
      //   toast.error("خطا در اطلاعات وارد شده" ,{id: 'not found error'})
      // }
      else if (error.response.status === 500) {
        toast.error("خطا در برقراری ارتباط با سرور", {id: 'server error'});
      } else if (error.response.status === 401) {
        if (localStorage.getItem(STORAGE_KEY.TOKEN) !== null) {
          toast.error("خطای دسترسی ، مجددا تلاش نمایید");
          localStorage.removeItem("user_token");
          localStorage.removeItem("kartella_hint");
          localStorage.removeItem("support_color");

          const secondUser = localStorage.getItem("user_token_second");
          if (secondUser !== null) {
            // history.push('/shop')
            // localStorage.setItem("user_token", secondUser);
            localStorage.removeItem("user_token_second");
            localStorage.removeItem("user_second_account");
            // getUserDataFromApi();
            // getUserProfile();
            // getCartDataFromApi();
            // history.push("/profile");
            // toast.success("تغییر حساب کاربری با موفقیت انجام شد.");
            // return;
          }
          onSetLoginStatus(false);
          setCartData({});
          setUserData({});
          setTimeout(() => history.push("/login"), 500);
        }
      } else if (
        error.response.status === 404 ||
        error.response.status === 403 ||
        error.response.status === 406
      ) {
        // toast.error("خطا در ارتباط با سرور");
      }
      return Promise.reject(error);
    }
  );
};

export const Request = {
  get: (url: string, body = {}, header = {}) => axios.get(url, body),
  post: (url: string, body = {}, config = {}) => axios.post(url, body, config),
  put: (url: string, body = {}) => axios.put(url, body),
  delete: (url: string, params = {}) => axios.delete(url, params),
};
