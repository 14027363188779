import { PanelContainer } from 'containers/CollaborateContainer/Logs';
import React from 'react';

const CollaboratePanelLogs = () => {
  return (
    <PanelContainer />
  );
}

export default CollaboratePanelLogs;
