import { Card } from "components";
import moment from "jalali-moment";
import Countdown from "react-countdown";
import { transformPrice } from "utils/Functions";
import { useEffect, useState } from "react";

export const Description = ({ cart }) => {
    const [preparationTime, setPreparationTime] = useState<any>();

    const handleCartStatus = (status) => {
        switch (status) {
            case "تحویل شده":
                return "sent";

            case "ارسال شده":
                return "sending";

            case "در حال آماده سازی":
                return "pending";

            default:
                break;
        }
    };

    const handleShowSendTime = () => {
        if (!preparationTime) return;
        const cartTime = preparationTime;
        const cartHour = Number(moment(cartTime).locale("fa").format("HH"));
        const currTime = Date.now();

        var resultTime = 0;
        var sendTime = 0;

        if (Number(cart.user?.Address?.cityId) === 51) {
            sendTime += 4 * 60 * 60 * 1000;
        } else {
            sendTime += 24 * 60 * 60 * 1000;
        }

        let remainTime = 0;
        if (cartHour >= 18 || cartHour < 8) {
            remainTime = 8 - cartHour;

            if (remainTime < 0) {
                remainTime += 24;
            }
        }

        remainTime *= 60 * 60 * 1000;
        resultTime = cartTime + sendTime + remainTime;

        if (resultTime <= currTime) {
            return (
                <div style={{ fontSize: 9, textAlign: "center" }}>
                    سفارش شما در حال پیگیری می‌باشد. <br /> از صبر و شکیبایی شما
                    سپاس‌گزاریم.
                </div>
            );
        } else {
            return (
                <Countdown
                    daysInHours={true}
                    date={Date.now() + resultTime - currTime}
                />
            );
        }
    };

    useEffect(() => {
        let temp = Math.max(
            ...cart.items.map((item) => {
                return +item.detail.Variation.preparationTime;
            })
        );

        const temp1 = new Date(cart.payment.time).setDate(
            new Date(cart.payment.time).getDate()
        );

        setPreparationTime(temp1);
    }, []);

    return (
        <Card>
            <div className="orders__info">
                <div className="orders__info__status">
                    <div className="orders__info__status__state">
                        <div className="orders__info__status__title">
                            وضعیت سفارش
                        </div>
                        <div
                            className={`orders__info__status__value ${handleCartStatus(
                                cart.status
                            )}`}
                        >
                            {cart.status}
                        </div>
                    </div>

                    {handleCartStatus(cart.status) === "sending" && (
                        <div className="row a-c j-sb mt-8">
                            <div className="orders__info__status__subtitle">
                                {cart.shipping_tracking.length < 15
                                    ? "شماره تلفن پیک"
                                    : "کد رهگیری پستی"}
                            </div>
                            <div className="orders__info__status__subvalue">
                                {cart.shipping_tracking}
                            </div>
                        </div>
                    )}

                    {handleCartStatus(cart.status) === "pending" && (
                        <div className="row a-c j-sb mt-8">
                            <div className="orders__info__status__subtitle">
                                زمان باقی‌مانده تا تحویل به
                                {Number(cart.user?.Address?.cityId) === 51
                                    ? " پیک"
                                    : " پست"}
                            </div>
                            <div className="orders__info__status__subvalue">
                                {handleShowSendTime()}
                            </div>
                        </div>
                    )}
                </div>

                <div
                    style={{
                        backgroundColor: "#f9f9fb",
                        border: "2px solid #ffffff",
                        borderRadius: "15px",
                        marginTop: "10px",
                        padding: "10px",
                    }}
                >
                    <div className="orders__info__item">
                        <div className="orders__info__title">
                            کد پیگیری سفارش
                        </div>
                        <div className="orders__info__value blue">
                            {cart.orderId}
                        </div>
                    </div>

                    <div className="orders__info__item">
                        <div className="orders__info__title">محل تحویل</div>
                        <div className="orders__info__value address">
                            {/* <div className="orders__info__value__solid">
                {cart.user.Address.title}:
              </div> */}
                            {cart.user?.Address?.address}
                        </div>
                    </div>

                    <div className="orders__info__item">
                        <div className="orders__info__title">هزینه ارسال</div>
                        <div className="orders__info__value">
                            {`${transformPrice(cart.shippingCost)} تومان`}
                        </div>
                    </div>

                    <div className="orders__info__item">
                        <div className="orders__info__title">روش پرداخت</div>
                        <div className="orders__info__value">
                            {cart.payment?.gateway}
                        </div>
                    </div>

                    <div className="orders__info__item">
                        <div className="orders__info__title">تاریخ</div>
                        <div className="orders__info__value">
                            {moment(cart?.payment?.time)
                                .locale("fa")
                                .format("H:mm - YYYY/MM/DD")}
                        </div>
                    </div>

                    <div className="orders__info__item">
                        <div className="orders__info__title">
                            مبلغ پرداخت شده
                        </div>
                        <div className="orders__info__price__value">
                            {cart.payment.amount_paid !== undefined ? (
                                <>
                                    {transformPrice(cart.payment?.amount_paid)}
                                    <div className="orders__info__price__toman">
                                        تومان
                                    </div>
                                </>
                            ) : (
                                "-"
                            )}
                        </div>
                    </div>

                    <div className="orders__info__item">
                        <div className="orders__info__title">KP جایزه</div>
                        <div className="orders__info__value">
                            {cart.recievedCountKp} عدد
                        </div>
                    </div>
                </div>
            </div>
        </Card>
    );
};
