import React, { useState, useEffect } from "react";
import {
    PageTopbar,
    Spinner,
    Fade,
    SVG,
    Dots,
    Card,
    Selectbox,
    FillProfile,
} from "components";
import { Link, useHistory } from "react-router-dom";
import {
    getCitiesAPI,
    getUserFriends,
    getUserImage,
    getUserInfo,
    requestUpdateAPI,
    setPresenterAPI,
    updateUserImage,
} from "../utils/Services/API";
import ProfileImage from "../assets/images/profile.png";
import toast from "react-hot-toast";
import { useUser } from "hooks";
import { ImageEditor } from "components/ImageEditor";
import imageCompression from "browser-image-compression";
import { SwitchUser } from "../components/SwitchUser";
import { Share } from "../components";
import { getStatesAPI, getUSerStarsAPI, updateMateInfoAPI } from "utils";

const PersonalInfo = () => {
    const [showSwitchUser, setShowSwitchUser] = useState(false);
    const userToken = localStorage.getItem("user_token");
    const {
        getUserDataFromApi,
        getUserProfile,
        userState: { user },
    } = useUser();
    const [userStars, setUserStars] = useState({
        country: "",
        state: "",
        city: "",
        gender: "",
        age: "",
        marital: "",
        employment: "",
        education: "",
        educationDegree: "",
    });

    const [userInfo, setuserInfo] = useState({
        status: true,
        statusChangeNationalCode: true,
        name: "",
        lastname: "",
        mobile_number: "",
        nationalCode: "",
    });
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [userImageFile, setUserImageFile] = useState();
    const [userImageUrl, setUserImageUrl] = useState();
    const [userImageChange, setUserImageChange] = useState(false);
    const [uploadLoading, setUploadLoading] = useState(false);
    const [showImageEditor, setShowImageEditor] = useState(false);
    // const [compressLoading, setCompressLoading] = useState(0);
    const [caller, setCaller] = useState(null);
    const [showSharing, setShowSharing] = useState(false);
    const [inviteCode, setInviteCode] = useState("");
    const [myInviteCode, setMyInviteCode] = useState("");
    const [inviteCodeLoading, setInviteCodeLoading] = useState(false);

    const [isEdit, setIsEdit] = useState({
        city: false,
        gender: false,
        age: false,
        education: false,
        employment: false,
        marital: false,
    });
    const [isActive, setIsActive] = useState(false);

    const getObjectKey = (obj, value) => {
        return Object.keys(obj).find((key) => obj[key] === value);
    };

    const compressOptions = {
        maxSizeMB: 0.25,
        maxWidthOrHeight: 1024,
        onProgress: (percentage) => {
            // setCompressLoading(percentage);
        },
    };

    const copy = () => {
        const value = document.getElementById("panel_code");
        value.select();
        document.execCommand("copy");

        toast.success("کد کارتک شما کپی شد!");
    };

    const checkNullValue = (field) => {
        switch (field) {
            case "city":
                return [
                    userStars?.country,
                    userStars?.state,
                    userStars?.city,
                    userStars?.district,
                ].filter((el) => el != null);

            case "education":
                return [
                    userStars?.education,
                    userStars?.educationDegree,
                    userStars?.study,
                ].filter((el) => el != null);

            case "employment":
                return [
                    userStars?.emeritus,
                    userStars?.readyToWork,
                    userStars?.skill,
                    userStars?.employment,
                    userStars?.employmentType,
                    userStars?.employmentGuilds,
                    userStars?.employmentSalaried,
                    userStars?.freelanceJobType,
                    userStars?.freelanceJob,
                    userStars?.governmentJob,
                ].filter((el) => el != null);

            case "marital":
                return [
                    userStars?.marital,
                    userStars?.maritalChildren,
                    userStars?.maritalChildrenCount,
                ].filter((el) => el != null);

            default:
                console.log("default");
                break;
        }
    };

    const handleGenderOnClick = () => {
        if (user?.detail?.star < 3) {
            setIsActive(true);
            setIsEdit((prevState) => ({
                ...prevState,
                gender: true,
            }));
        }
    };

    const handleSetPresenter = () => {
        if (
            Number(user.mobile_number) === Number(inviteCode) ||
            inviteCode === userInfo.inviteCode
        ) {
            toast.error("کد معرف وارد شده معتبر نمی‌باشد.");
            return;
        }
        setInviteCodeLoading(true);
        setPresenterAPI(userToken, inviteCode).then((response) => {
            if (response.status === 200) {
                toast.success("کد معرف شما با موفقیت ثبت شد.");
                getFriends();
            } else {
                toast.error("کد معرف وارد شده معتبر نمی‌باشد.");
            }
            setInviteCodeLoading(false);
        });
    };

    const getFriends = () => {
        const serverResponse = getUserFriends(userToken);
        serverResponse
            .then((response) => {
                setCaller(response.data.data.caller);
            })
            .catch(() => {
                toast.error("خطا در برقراری ارتباط");
                return;
            });
    };

    const getUser = () => {
        const userToken = localStorage.getItem("user_token");
        if (userToken === null || userToken === undefined) {
            history.push("/login");
            return;
        }
        const serverResponse = getUserInfo(userToken);
        serverResponse.then((response) => {
            if (response.status >= 400) {
                localStorage.removeItem("user_token");
                history.push("/login");
            }
            setuserInfo({
                status: response.data.detail.StatusChangeName,
                statusChangeNationalCode:
                    response.data.detail.StatusChangeNationalCode,
                name: response.data.name,
                lastname: response.data.lastname,
                mobile_number: response.data.mobile_number,
                nationalCode: response.data.nationalCode,
            });

            setMyInviteCode(response.data?.invite_code);
            // setLoading(false);
        });
    };

    const getStarsDetail = () => {
        const api = getUSerStarsAPI();
        api.then((response) => {
            setUserStars(response.data.profile);
        });
    };

    const getImage = () => {
        const userToken = localStorage.getItem("user_token");
        const serverResponse = getUserImage(userToken);
        serverResponse.then((response) => {
            if (response.status >= 400) {
                setUserImageUrl(null);
            } else {
                setUserImageUrl(response.data);
            }
            setLoading(false);
        });
    };

    const handleUpdateImage = (imageBlob) => {
        setUploadLoading(true);

        if (imageBlob !== undefined) {
            const compressedFile = imageCompression(imageBlob, compressOptions);

            compressedFile.then((compressedImage) => {
                const setUserImageResponse = updateUserImage(
                    userToken,
                    compressedImage
                );
                setUserImageResponse.then((response) => {
                    if (response.data.status === 200) {
                        getUserDataFromApi();
                        getUserProfile();
                        toast.success("تصویر پروفایل با موفقیت ویرایش شد.");
                        setUploadLoading(false);
                        setUserImageChange(false);
                    } else if (response.data.status === 400) {
                        toast.error("حجم تصویر انتخاب شده، زیاد است.");
                        setUploadLoading(false);
                    }
                });
            });
            if (!userInfo.status) {
                setLoading(false);
                return;
            }
        }
    };

    const handleSubmitForm = () => {
        if (userInfo.name === null || userInfo.lastname === null) {
            toast.error("نام و نام خانوادگی خود را تکمیل نمایید.");
            setUploadLoading(false);
            return;
        }

        setLoading(true);

        updateMateInfoAPI(userInfo)
            .then((response) => {
                if (response.status === 200) {
                    toast.success("اطلاعات شما با موفقیت ثبت شد");
                } else {
                    toast.error("مشکلی در ثبت اطلاعات پیش آمده است!");
                }
            })
            .catch((err) => {
                toast.error("مشکلی در ثبت اطلاعات پیش آمده است!");
            })
            .finally(() => {
                setLoading(false);
            });

        // const serverResponse = setUserInfo(
        //     userToken,
        //     userInfo.firstName,
        //     userInfo.lastName
        // );
        // serverResponse.then((response) => {
        //     if (response.status === 403) {
        //         const APIResult = requestUpdateAPI(userToken);
        //         APIResult.then((response) => {
        //             if (response.status === 200) {
        //                 toast.success(
        //                     "درخواست شما ارسال شد. به زودی پاسخ داده می‌شود."
        //                 );
        //                 return;
        //             } else {
        //                 toast.error("درخواست ویرایش اطلاعات قبلا ثبت شده است.");
        //             }
        //         });
        //     } else if (response.status === 400) {
        //         toast.success("خطا در ورودی وارد شده.");
        //     } else if (response.status === 200) {
        //         toast.success("پروفایل شما با موفقیت ویرایش شد.");
        //         getUserDataFromApi();
        //         setuserInfo((prev) => ({ ...prev, status: false }));
        //         getUserProfile();
        //     }
        //     setUploadLoading(false);
        //     setLoading(false);
        // });
    };

    useEffect(() => {
        history.replace("/profile/info");
        getUser();
        getImage();
        getFriends();
        getStarsDetail();
        // eslint-disable-next-line
    }, []);

    return (
        <div
            className="personal-info"
            style={{ paddingBottom: !userInfo.status && 0 }}
        >
            <Fade>
                <Spinner active={loading} color="dark" />
                <PageTopbar title="ویرایش پروفایل" showKv={false} />
                <ImageEditor
                    active={showImageEditor}
                    onClose={(close) => setShowImageEditor(!close)}
                    onConfirm={(cropper) => {
                        setShowImageEditor(false);
                        setUserImageChange(true);
                        setUserImageUrl(
                            cropper.getCroppedCanvas().toDataURL().substr(22)
                        );
                        cropper.getCroppedCanvas().toBlob((blob) => {
                            setUserImageFile(blob);
                            handleUpdateImage(blob);
                        });
                    }}
                    aspect={1 / 1}
                />
                <Share
                    active={showSharing}
                    setClose={(close) => setShowSharing(close)}
                    title="ارسال دعوتنامه"
                    value={`
      با کد کارتک من در کارتک ثبت‌نام کن و KV رایگان بگیر! 🎉
      \n کد کارتک من: ${userInfo.inviteCode}\n
      \n لینک ورود به کارتک : \n ${`https://app.kartaak.com/login?${userInfo.inviteCode}`} \n
      \n وبسایت کارتک : 
      `}
                    link={`https://kartaak.com`}
                />
                <FillProfile
                    profileInfo={true}
                    userStars={userStars}
                    active={isActive}
                    closeFillProfile={(close) => {
                        setIsEdit({
                            city: false,
                            gender: false,
                            age: false,
                            education: false,
                            employment: false,
                            marital: false,
                        });
                        setIsActive(!close);
                        getUserDataFromApi();
                        getStarsDetail();
                    }}
                    type={getObjectKey(isEdit, true)}
                />
                <Card>
                    <div className="personal-info__image">
                        {userImageUrl === null ? (
                            <img
                                src={ProfileImage}
                                alt=""
                                onClick={() => setShowImageEditor(true)}
                            />
                        ) : (
                            <img
                                style={{
                                    borderColor:
                                        userImageChange && "hsl(0deg 80% 89%)",
                                }}
                                src={
                                    userImageUrl !== ""
                                        ? `data:image/png;base64,${userImageUrl}`
                                        : ProfileImage
                                }
                                alt=""
                                onClick={() => setShowImageEditor(true)}
                            />
                        )}
                        <div
                            className={`personal-info__image__update ${
                                !userImageChange && "blue"
                            }`}
                            onClick={() => {
                                if (!userImageChange) {
                                    setShowImageEditor(true);
                                } else {
                                    !uploadLoading && handleUpdateImage();
                                }
                            }}
                        >
                            <SVG
                                svg={userImageChange ? "upload" : "img-edit"}
                            />
                            {uploadLoading ? (
                                <Dots color="light" />
                            ) : !userImageChange ? (
                                "ویرایش تصویر"
                            ) : (
                                "ثبت تصویر"
                            )}
                        </div>
                        {/* )
            } */}

                        <div className="profile__header__edit personal-info__image__edit">
                            <SVG svg="img-edit" />
                        </div>
                    </div>
                    {caller?.mobile_number !== null && (
                        <>
                            <div className="title">دعوت شده توسط</div>
                            <div className="friends__list">
                                <div className="friends__list__item">
                                    <div className="friends__list__image"></div>
                                    <div className="col">
                                        <div className="friends__list__name">
                                            {caller?.name}
                                        </div>
                                        <div className="friends__list__phone">
                                            {caller?.mobile_number}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                    <div className="row j-sb a-c mb-8">
                        <div className="row a-c">
                            <div className="panel__box__info__title">
                                کد کارتک شما:
                            </div>
                            <input
                                readOnly
                                type="text"
                                value={myInviteCode}
                                id="panel_code"
                                className="panel__box__input"
                            />
                        </div>

                        <div className="row a-c g-6">
                            <div
                                className="panel__box__info__icon"
                                onClick={() => copy()}
                            >
                                <SVG svg="copy" />
                            </div>
                            <div
                                className="panel__box__info__icon shared"
                                onClick={() => setShowSharing(true)}
                            >
                                <SVG svg="share" />
                            </div>
                        </div>
                    </div>
                    {/*<div className="friends-invite__tools">*/}
                    {/*  <div className="friends-invite__item">*/}
                    {/*    /!* <span>کد دعوت شما</span> *!/*/}
                    {/*    <input*/}
                    {/*      type="text"*/}
                    {/*      value={userInfo.inviteCode}*/}
                    {/*      id="invite_code"*/}
                    {/*      readOnly*/}
                    {/*    />*/}
                    {/*  </div>*/}
                    {/*  <div*/}
                    {/*    className="friends-invite__item friends-invite__copy"*/}
                    {/*    onClick={() => copy()}*/}
                    {/*  >*/}
                    {/*    کپی*/}
                    {/*    <SVG svg="copy"/>*/}
                    {/*  </div>*/}
                    {/*  <div*/}
                    {/*    className="friends-invite__item friends-invite__share"*/}
                    {/*    onClick={() => setShowSharing(true)}*/}
                    {/*  >*/}
                    {/*    <SVG svg="share"/>*/}
                    {/*  </div>*/}
                    {/*</div>*/}
                    {caller?.mobile_number === null && (
                        <div className="friends-invite__introduced">
                            <div className="title mt-16">
                                کد یا شماره موبایل معرف
                            </div>
                            <input
                                type="text"
                                placeholder="اگر کد یا شماره موبایل معرف دارید، وارد نمایید."
                                value={inviteCode}
                                onChange={(e) => setInviteCode(e.target.value)}
                            />
                            <div
                                className={`friends-invite__introduced__action ${
                                    inviteCode.length !== 0 && "active"
                                }`}
                                onClick={() =>
                                    !inviteCodeLoading && handleSetPresenter()
                                }
                            >
                                {inviteCodeLoading ? (
                                    <Dots color="light" />
                                ) : (
                                    "ثبت معرف"
                                )}
                            </div>
                        </div>
                    )}
                    <div
                        style={{ display: "flex", width: "100%", gap: "1rem" }}
                    >
                        <div style={{ width: "50%" }}>
                            <div className="title">نام</div>
                            <input
                                disabled={!userInfo.status}
                                type="text"
                                className={`input ${
                                    !userInfo.status && "disabled"
                                }`}
                                value={userInfo.name}
                                onChange={(e) =>
                                    setuserInfo((prevState) => ({
                                        ...prevState,
                                        name: e.target.value,
                                    }))
                                }
                            />
                        </div>
                        <div style={{ width: "50%" }}>
                            <div className="title">نام خانوادگی</div>
                            <input
                                disabled={!userInfo.status}
                                type="text"
                                className={`input ${
                                    !userInfo.status && "disabled"
                                }`}
                                value={userInfo.lastname}
                                onChange={(e) =>
                                    setuserInfo((prevState) => ({
                                        ...prevState,
                                        lastname: e.target.value,
                                    }))
                                }
                            />
                        </div>
                    </div>
                    <div
                        style={{ display: "flex", width: "100%", gap: "1rem" }}
                    >
                        <div style={{ width: "50%" }}>
                            <div className="title">شماره موبایل</div>
                            <input
                                style={{ textAlign: "left" }}
                                disabled
                                type="text"
                                className="input disabled"
                                value={userInfo.mobile_number}
                            />
                        </div>
                        <div style={{ width: "50%" }}>
                            {
                                <React.Fragment>
                                    <div className="title">کد ملی</div>
                                    <input
                                        style={{ textAlign: "left" }}
                                        disabled={
                                            !userInfo.statusChangeNationalCode
                                        }
                                        type="number"
                                        className={`input ${
                                            !userInfo.statusChangeNationalCode &&
                                            "disabled"
                                        }`}
                                        value={userInfo.nationalCode}
                                        onChange={(e) =>
                                            setuserInfo((prevState) => ({
                                                ...prevState,
                                                nationalCode: e.target.value,
                                            }))
                                        }
                                    />
                                </React.Fragment>
                            }
                        </div>
                    </div>

                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            gap: "1rem",
                            marginBottom: "1rem",
                        }}
                    >
                        <div
                            style={{
                                margin: "0",
                                cursor: "pointer",
                            }}
                            className="profile__items__profileItem"
                            onClick={() => {
                                setIsActive(true);
                                setIsEdit((prevState) => ({
                                    ...prevState,
                                    city: true,
                                }));
                            }}
                        >
                            <div
                                className={`profile__stars__item ${
                                    user?.detail?.star >= 2 && "active"
                                }`}
                            >
                                <SVG svg="profile-star" />
                            </div>
                            محل سکونت
                        </div>
                        {user?.detail?.star >= 2 && (
                            <input
                                style={{ margin: 0, marginRight: "0.5rem" }}
                                disabled
                                className={`input disabled`}
                                value={checkNullValue("city")}
                            />
                        )}
                    </div>

                    <div
                        style={{
                            display: "flex",
                            gap: "1rem",
                            paddingBottom: "1rem",
                        }}
                    >
                        {user?.detail?.star >= 3 && (
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    gap: "1rem",
                                    width: "50%",
                                }}
                            >
                                <div
                                    style={{
                                        margin: "0",
                                        cursor: "pointer",
                                    }}
                                    className="profile__items__profileItem"
                                    onClick={() => {
                                        handleGenderOnClick();
                                    }}
                                >
                                    <div
                                        className={`profile__stars__item ${
                                            user?.detail?.star >= 3 && "active"
                                        }`}
                                    >
                                        <SVG svg="profile-star" />
                                    </div>
                                    جنسیت
                                </div>

                                <input
                                    style={{ margin: 0, marginRight: "0.5rem" }}
                                    disabled
                                    className={`input disabled`}
                                    value={`${userStars?.gender}`}
                                />
                            </div>
                        )}

                        {user?.detail?.star >= 4 && (
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    gap: "1rem",
                                    width: "50%",
                                }}
                            >
                                <div
                                    style={{
                                        margin: "0",
                                        cursor: "pointer",
                                    }}
                                    className="profile__items__profileItem"
                                    onClick={() => {
                                        setIsActive(true);
                                        setIsEdit((prevState) => ({
                                            ...prevState,
                                            age: true,
                                        }));
                                    }}
                                >
                                    <div
                                        className={`profile__stars__item ${
                                            user?.detail?.star >= 4 && "active"
                                        }`}
                                    >
                                        <SVG svg="profile-star" />
                                    </div>
                                    سن
                                </div>

                                <input
                                    style={{ margin: 0, marginRight: "0.5rem" }}
                                    disabled
                                    className={`input disabled`}
                                    value={`${userStars?.age}`}
                                />
                            </div>
                        )}
                    </div>

                    {user?.detail?.star >= 5 && (
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                gap: "1rem",
                                paddingBottom: "1rem",
                            }}
                        >
                            <div
                                style={{
                                    margin: "0",
                                    cursor: "pointer",
                                }}
                                className="profile__items__profileItem"
                                onClick={() => {
                                    setIsActive(true);
                                    setIsEdit((prevState) => ({
                                        ...prevState,
                                        education: true,
                                    }));
                                }}
                            >
                                <div
                                    className={`profile__stars__item ${
                                        user?.detail?.star >= 5 && "active"
                                    }`}
                                >
                                    <SVG svg="profile-star" />
                                </div>
                                وضعیت تحصیلی
                            </div>

                            <input
                                style={{ margin: 0, marginRight: "0.5rem" }}
                                disabled
                                className={`input disabled`}
                                value={checkNullValue("education")}
                            />
                        </div>
                    )}

                    {user?.detail?.star >= 6 && (
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                gap: "1rem",
                                paddingBottom: "1rem",
                            }}
                        >
                            <div
                                style={{
                                    margin: "0",
                                    cursor: "pointer",
                                }}
                                className="profile__items__profileItem"
                                onClick={() => {
                                    setIsActive(true);
                                    setIsEdit((prevState) => ({
                                        ...prevState,
                                        employment: true,
                                    }));
                                }}
                            >
                                <div
                                    className={`profile__stars__item ${
                                        user?.detail?.star >= 6 && "active"
                                    }`}
                                >
                                    <SVG svg="profile-star" />
                                </div>
                                وضعیت شغلی
                            </div>

                            <input
                                style={{
                                    margin: 0,
                                    marginRight: "0.5rem",
                                }}
                                disabled
                                className={`input disabled`}
                                value={checkNullValue("employment")}
                            />
                        </div>
                    )}

                    {user?.detail?.star >= 7 && (
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                gap: "1rem",
                            }}
                        >
                            <div
                                style={{
                                    margin: "0",
                                    cursor: "pointer",
                                }}
                                className="profile__items__profileItem"
                                onClick={() => {
                                    setIsActive(true);
                                    setIsEdit((prevState) => ({
                                        ...prevState,
                                        marital: true,
                                    }));
                                }}
                            >
                                <div
                                    className={`profile__stars__item ${
                                        user?.detail?.star >= 7 && "active"
                                    }`}
                                >
                                    <SVG svg="profile-star" />
                                </div>
                                وضعیت تاهل
                            </div>

                            <input
                                style={{ margin: 0, marginRight: "0.5rem" }}
                                disabled
                                className={`input disabled`}
                                value={checkNullValue("marital")}
                            />
                        </div>
                    )}
                </Card>

                {!userInfo.statusChangeNationalCode ? (
                    <Card>
                        <div className="personal-info__hint">
                            <SVG svg="info" />
                            نام، نام خانوادگی، شماره‌ی موبایل، کد ملی و جنسیت پس
                            از ثبت، قابل ویرایش نیست. در صورتی که نیاز به تغییر
                            آن داشتید لطفا از طریق
                            <Link to="/support">&nbsp;پشتیبانی&nbsp;</Link>
                            درخواست خود را ثبت نمایید.
                        </div>
                    </Card>
                ) : (
                    <div className="fixed bottom">
                        <div
                            className={`button`}
                            onClick={() => !uploadLoading && handleSubmitForm()}
                        >
                            ویرایش اطلاعات
                            <SVG svg="personal" />
                        </div>
                    </div>
                )}
            </Fade>
        </div>
    );
};

export default PersonalInfo;
