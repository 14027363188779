import React, { useCallback, useEffect, useState } from "react";
import './ProductSearch.scss';
import debounce from "lodash.debounce";
import { Spinner } from 'components/Loading';
import { getProductsAPI } from "utils/Services/API";


export const ProductSearch = ({active, setClose, value}) => {

    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);
  

    const getProducts = (inputValue) => {
        const APIResult = getProductsAPI({ query: inputValue });
        APIResult.then((response) => {
          setProducts(response.data.data.data);
          setLoading(false);
        });
      };

    const debounceHandler = useCallback(
        debounce((value) => {
          if (value.length > 0) {
            getProducts(value);
            document.body.style.overflowY = "hidden";
          } else {
            // setFilter(filter);
            document.body.style.overflowY = "auto";
          }
        }, 700),
        []
      );

      useEffect(() => {
        if (value.length > 0) {
          setLoading(true);
        //   setFilter(false);
        } else {
          setProducts([]);
        }
        debounceHandler(value);
      }, [value]);

    return (
        <div className={`product-search ${active && 'active'}`} >
            <Spinner active={loading} color="dark" />
            
        </div>
    )
}

