import { SVG } from "components";
import "./Rate.scss";
export const Rate = ({ number: number }) => {
  return (
    <div className="rate-wrapper">
      {[{}, {}, {}, {}, {}].map((start, index) => (
        <div className={`rate ${index < number && "fill"}`}>
          <SVG svg="rate-star" />
        </div>
      ))}
    </div>
  );
};
