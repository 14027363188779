import { SVG, Selectbox, CheckBox } from "components";
import { CreateMessageContext } from "containers/CreateMessageContainer";
import { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { fillProfileAPI, getInventory } from "utils/Services/API";

const FillCustomEducation = ({ close }) => {
  const user_token = localStorage.getItem("user_token");
  const createAds = useContext(CreateMessageContext);

  const [status, setStatus] = useState(-1);
  const [statusValue, setStatusValue] = useState("");
  const [studyId, setStudyId] = useState(0);
  const [studyField, setStudyField] = useState("");
  const [studies, setStudies] = useState([]);
  const [degree, setDegree] = useState(0);
  const [degreeValue, setDegreeValue] = useState("");
  const [degrees] = useState([
    {
      name: "زیر دیپلم",
      id: 1,
    },
    {
      name: "دیپلم",
      id: 2,
    },
    {
      name: "فوق دیپلم",
      id: 3,
    },
    {
      name: "لیسانس",
      id: 4,
    },
    {
      name: "فوق لیسانس",
      id: 5,
    },
    {
      name: "دکتری",
      id: 6,
    },
    {
      name: "بالاتر",
      id: 7,
    },
  ]);

  const handleActivateSubmit = () => {
    // if (status === 0 || degree === 0 || (studyId === 0 && studyField.length === 0))
    //   return false;
    // else
    return true;
  };

  const handleSubmitForm = () => {
    const apiRequest =
      studyId > 0
        ? {
            educationStatus: status,
            educationStatusValue: statusValue,
            educationDegree: degree,
            educationDegreeValue: degreeValue,
            studyId: studyId,
            studyField: studyField,
          }
        : {
            educationStatus: status,
            educationStatusValue: statusValue,
            educationDegree: degree,
            educationDegreeValue: degreeValue,
            studyId: 0,
            studyField: studyField,
          };
    createAds.detail.set((prev) => ({
      ...prev,
      education: apiRequest,
    }));
  };

  const getStudies = () => {
    const APIResult = getInventory(user_token, "fieldOfStudy");
    APIResult.then((response) => {
      setStudies(response.data.data);
    });
  };

  useEffect(() => {
    setStatus(createAds.detail.get.education.educationStatus);
    setDegree(createAds.detail.get.education.educationDegree);
    setStudyId(createAds.detail.get.education.studyField);
    getStudies();
  }, []);

  useEffect(() => {
    handleSubmitForm();
  }, [status, degree, studyId]);

  return (
    <div className="fill-profile__form">
      <div className="row">
        <div className="row" style={{ marginLeft: 16 }}>
          <CheckBox
            disable
            defaultValue={status === 1 && true}
            onChange={(value) => {
              if (value) {
                setStatus(1);
                setStatusValue("درحال تحصیل");
              }
            }}
          />
          <div className="fill-profile__form__title">درحال تحصیل</div>
        </div>

        <div className="row" style={{ marginLeft: 16 }}>
          <CheckBox
            disable
            defaultValue={status === 2 && true}
            onChange={(value) => {
              if (value) {
                setStatus(2);
                setStatusValue("فارغ‌التحصیل");
              }
            }}
          />
          <div className="fill-profile__form__title">فارغ التحصیل</div>
        </div>

        <div className="row">
          <CheckBox
            disable
            defaultValue={status === 0 && true}
            onChange={(value) => {
              if (value) {
                setStatus(0);
                setStatusValue("تعیین نشده");
              }
            }}
          />
          <div className="fill-profile__form__title">همه</div>
        </div>
      </div>

      <div className="fill-profile__form__title">
        {/* <span className="required" /> */}
        مدرک تحصیلی
      </div>

      <Selectbox
        data={degrees}
        placeholder="انتخاب مدرک تحصیلی"
        onChange={(value) => setDegree(value)}
        onChangeString={(value) => setDegreeValue(value)}
        defaultValue={createAds.detail.get.education.educationDegreeValue}
      />

      {/* <div className="fill-profile__form__title">
        رشته‌ی تحصیلی
      </div>

      <Selectbox
        data={studies}
        placeholder="انتخاب رشته‌ی تحصیلی"
        onChange={value => {
          setStudyId(value);
          // setStudyField('');
        }}
        onChangeString={value => setStudyField(value)}
      /> */}
    </div>
  );
};

export default FillCustomEducation;
