import { Fade, PageTopbar, Spinner, SVG } from "components";
import { useEffect, useState } from "react";
import { getHintAPI } from "utils";
import { useHistory } from "react-router-dom";

const Alerts = () => {
    const history = useHistory();
    const [activeCollapse, setActiveCollapse] = useState(0);
    const [loading, setLoading] = useState({
        hint1: true,
        hint2: true,
        hint3: true,
        hint4: true,
    });
    const [hint1, setHint1] = useState({
        title: "",
        content: "",
    });
    const [hint2, setHint2] = useState({
        title: "",
        content: "",
    });
    const [hint3, setHint3] = useState({
        title: "",
        content: "",
    });
    const [hint4, setHint4] = useState({
        title: "",
        content: "",
    });

    const getHint1 = () => {
        getHintAPI("قوانین و هشدارها 1")
            .then((response) => {
                setHint1(response);
            })
            .catch(() => setHint1(false))
            .finally(() => {
                setLoading((prevState) => ({
                    ...prevState,
                    hint1: false,
                }));
            });
    };

    const getHint2 = () => {
        getHintAPI("قوانین و هشدارها 2")
            .then((response) => {
                setHint2(response);
            })
            .catch(() => setHint2(false))
            .finally(() => {
                setLoading((prevState) => ({
                    ...prevState,
                    hint2: false,
                }));
            });
    };

    const getHint3 = () => {
        getHintAPI("قوانین و هشدارها 3")
            .then((response) => {
                setHint3(response);
            })
            .catch(() => setHint3(false))
            .finally(() => {
                setLoading((prevState) => ({
                    ...prevState,
                    hint3: false,
                }));
            });
    };

    const getHint4 = () => {
        getHintAPI("قوانین و هشدارها 5")
            .then((response) => {
                setHint4(response);
            })
            .catch(() => setHint4(false))
            .finally(() => {
                setLoading((prevState) => ({
                    ...prevState,
                    hint4: false,
                }));
            });
    };

    // const getHint4 = () => {
    //     getHintAPI("قوانین و هشدارها 4")
    //         .then((response) => {
    //             setHint4(response);
    //         })
    //         .catch(() => setHint4(false))
    //         .finally(() => {});
    // };

    // const getHint5 = () => {
    //     getHintAPI("قوانین و هشدارها 5")
    //         .then((response) => {
    //             setHint5(response);
    //         })
    //         .catch(() => setHint5(false))
    //         .finally(() => {});
    // };

    // const getHint6 = () => {
    //     getHintAPI("قوانین و هشدارها 3")
    //         .then((response) => {
    //             setHint6(response);
    //         })
    //         .catch(() => setHint6(false))
    //         .finally(() => {});
    // };

    // const getHint7 = () => {
    //     getHintAPI("قوانین و هشدارها 7")
    //         .then((response) => {
    //             setHint7(response);
    //         })
    //         .catch(() => setHint7(false))
    //         .finally(() => {
    //             setLoading(false);
    //         });
    // };

    useEffect(() => {
        getHint1();
        getHint2();
        getHint3();
        getHint4();
        // getHint4();
        // getHint5();
        // getHint6();
        // getHint7();
    }, []);

    useEffect(() => {
        history.replace("/alerts");
    }, []);

    return (
        <Fade>
            <PageTopbar title="قوانین و هشدارها" showKv={false} />
            <Spinner
                active={
                    loading.hint1 ||
                    loading.hint2 ||
                    loading.hint3 ||
                    loading.hint4
                }
                color={"dark"}
            />

            <div className="about">
                <div
                    className={`about__item ${
                        activeCollapse === 1 && "active"
                    }`}
                    onClick={() =>
                        activeCollapse === 1
                            ? setActiveCollapse(0)
                            : setActiveCollapse(1)
                    }
                >
                    {hint1.title}
                    <SVG svg="arrow" />
                </div>
                {activeCollapse === 1 && (
                    <div className="about__description">
                        {hint1.content?.split("\n")?.map((str) => (
                            <div>{str}</div>
                        ))}
                    </div>
                )}

                <div
                    className={`about__item ${
                        activeCollapse === 2 && "active"
                    }`}
                    onClick={() =>
                        activeCollapse === 2
                            ? setActiveCollapse(0)
                            : setActiveCollapse(2)
                    }
                >
                    {hint2.title}
                    <SVG svg="arrow" />
                </div>
                {activeCollapse === 2 && (
                    <div className="about__description">
                        {hint2.content?.split("\n")?.map((str) => (
                            <div>{str}</div>
                        ))}
                    </div>
                )}

                <div
                    className={`about__item ${
                        activeCollapse === 3 && "active"
                    }`}
                    onClick={() =>
                        activeCollapse === 3
                            ? setActiveCollapse(0)
                            : setActiveCollapse(3)
                    }
                >
                    {hint3.title}
                    <SVG svg="arrow" />
                </div>
                {activeCollapse === 3 && (
                    <div className="about__description">
                        {hint3.content?.split("\n")?.map((str) => (
                            <div>{str}</div>
                        ))}
                    </div>
                )}

                <div
                    className={`about__item ${
                        activeCollapse === 8 && "active"
                    }`}
                    onClick={() =>
                        activeCollapse === 8
                            ? setActiveCollapse(0)
                            : setActiveCollapse(8)
                    }
                >
                    {hint4.title}
                    <SVG svg="arrow" />
                </div>
                {activeCollapse === 8 && (
                    <div className="about__description">
                        {hint4.content?.split("\n")?.map((str) => (
                            <div>{str}</div>
                        ))}
                    </div>
                )}

                {/*<div className={`about__item ${activeCollapse === 4 && 'active'}`} onClick={() => activeCollapse === 4 ? setActiveCollapse(0) : setActiveCollapse(4)}>*/}
                {/*  {hint4.title}*/}
                {/*  <SVG svg="arrow" />*/}
                {/*</div>*/}
                {/*{*/}
                {/*  activeCollapse === 4 && (*/}
                {/*    <div className="about__description">*/}
                {/*      {hint4.content?.split('\n')?.map(str => <div>{str}</div>)}*/}
                {/*    </div>*/}
                {/*  )*/}
                {/*}*/}

                {/*<div className={`about__item ${activeCollapse === 5 && 'active'}`} onClick={() => activeCollapse === 5 ? setActiveCollapse(0) : setActiveCollapse(5)}>*/}
                {/*  {hint5.title}*/}
                {/*  <SVG svg="arrow" />*/}
                {/*</div>*/}
                {/*{*/}
                {/*  activeCollapse === 5 && (*/}
                {/*    <div className="about__description">*/}
                {/*      {hint5.content?.split('\n')?.map(str => <div>{str}</div>)}*/}
                {/*    </div>*/}
                {/*  )*/}
                {/*}*/}

                {/*<div className={`about__item ${activeCollapse === 6 && 'active'}`} onClick={() => activeCollapse === 6 ? setActiveCollapse(0) : setActiveCollapse(6)}>*/}
                {/*  {hint6.title}*/}
                {/*  <SVG svg="arrow" />*/}
                {/*</div>*/}
                {/*{*/}
                {/*  activeCollapse === 6 && (*/}
                {/*    <div className="about__description">*/}
                {/*      {hint6.content?.split('\n')?.map(str => <div>{str}</div>)}*/}
                {/*    </div>*/}
                {/*  )*/}
                {/*}*/}

                {/*<div className={`about__item ${activeCollapse === 7 && 'active'}`} onClick={() => activeCollapse === 7 ? setActiveCollapse(0) : setActiveCollapse(7)}>*/}
                {/*  {hint7.title}*/}
                {/*  <SVG svg="arrow" />*/}
                {/*</div>*/}
                {/*{*/}
                {/*  activeCollapse === 7 && (*/}
                {/*    <div className="about__description">*/}
                {/*      {hint7.content?.split('\n')?.map(str => <div>{str}</div>)}*/}
                {/*    </div>*/}
                {/*  )*/}
                {/*}*/}
            </div>
        </Fade>
    );
};

export default Alerts;
