import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { SVG } from "components";
import "./Filter.scss";
import { getCategoriesAPI } from "utils/Services/API";
import { cloneDeep } from "lodash";
import ReactSlider from "react-slider";

export const Filter = ({ active = "", setClose, type = "sort" }) => {
  const [activeSortItem, setActiveSortItem] = useState({});
  const history = useHistory();
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);

  const sortItems = [
    {
      type: "desc",
      title: "قدیمی ترین",
    },
    {
      type: "best_selling",
      title: "پرفروش ترین",
    },
    {
      type: "popular",
      title: "محبوب ترین",
    },
    {
      type: "most_viewed",
      title: "پربازدید ترین",
    },
    {
      type: "lower_price",
      title: "ارزان‌ ترین",
    },
    {
      type: "higher_price",
      title: "گران ترین",
    },
  ];

  const getCategories = () => {
    // setLoading(true);
    // const apiResult = getCategoriesAPI();
    // apiResult.then((response) => {
    //   if (response.status >= 200 && response.status < 300) {
    //     setCategories(response.data.data);
    //   }
    // });
  };

  const handleSubmit = () => {
    let url = `/products?sort=${
      activeSortItem.type ? activeSortItem.type : "desc"
    }`;
    if (activeSortItem.category) {
      url = `${url}&category=${activeSortItem.category.id}`;
    }
    if (activeSortItem.brand) {
      url = `${url}&brand=${activeSortItem.brand.id}`;
    }
    if (activeSortItem.price) {
      url = `${url}&price=${activeSortItem.price}`;
    }
    history.push(url);
    setClose("");
  };

  useEffect(() => {
    getCategories();
  }, []);

  useEffect(() => {
    if (active !== "") handleSubmit();
  }, [activeSortItem]);

  const categoryClickHandle = (category) => {
    const activeSortItemCopy = cloneDeep(activeSortItem);
    activeSortItemCopy["category"] = category;
    setActiveSortItem(activeSortItemCopy);
  };

  const sortItemClick = (sortItem) => {
    const activeSortItemCopy = cloneDeep(activeSortItem);
    activeSortItemCopy["type"] = sortItem.type;
    activeSortItemCopy["title"] = sortItem.title;
    setActiveSortItem(activeSortItemCopy);
  };

  return (
    <div className={`filter ${active !== "" && "active"}`}>
      <div className="filter__container">
        <div className="filter__popup">
          {type === "filter" ? (
            <React.StrictMode>
              <div className="filter__popup__header">
                <div className="filter__popup__header__icon">
                  <SVG svg="outline-filter" />
                </div>
                فیلتر
              </div>
              {/* TO DO brands */}
              <div className="filter__popup__brands"></div>
              {/* <div className="filter__popup__categories">
                {categories.length > 0 &&
                  categories.map((category, index) => (
                    <div
                      className={`filter__popup__categories__item ${
                        activeSortItem.category &&
                        category.id === activeSortItem.category.id &&
                        "active"
                      }`}
                      key={index}
                      onClick={() => categoryClickHandle(category)}
                    >
                      <span>{category.title}</span>
                    </div>
                  ))}
              </div> */}
              <div className="filter__popup__price">
                <span>قیمت</span>
                <ReactSlider
                  className="select-filter__options__range"
                  trackClassName="select-filter__options__range__track"
                  defaultValue={[0, 100]}
                  ariaLabel={["Lower thumb", "Upper thumb"]}
                  // ariaValuetext={(state) =>
                  //   `Thumb value ${state.valueNow}`
                  // }
                  renderThumb={(props, state) => (
                    <div
                      {...props}
                      className="select-filter__options__range__thumb"
                    >
                      <span
                        style={{
                          fontSize: "12px",
                          position: "absolute",
                          top: "-25px",
                        }}
                      >
                        {state.valueNow}
                      </span>
                    </div>
                  )}
                  pearling
                  minDistance={10}
                />
              </div>
            </React.StrictMode>
          ) : (
            <React.StrictMode>
              {/* <div className="filter__popup__header">
                <div className="filter__popup__header__icon">
                  <SVG svg="order-line" />
                </div>
                مرتب سازی
              </div> */}

              {sortItems.map((sortItem, index) => (
                <div
                  className="row a-c filter__popup__item"
                  key={index}
                  onClick={() => sortItemClick(sortItem)}
                >
                  <div
                    className={`checkbox ${
                      activeSortItem.type === sortItem.type && "active"
                    }`}
                  ></div>
                  <div className="filter__popup__subtitle">
                    {sortItem.title}
                  </div>
                </div>
              ))}
            </React.StrictMode>
          )}
        </div>
        {/* {active !== "" && (
          <div
            className={`filter__popup__apply ${
              activeSortItem.type !== undefined && "active"
            }`}
            onClick={() => {
              activeSortItem.type !== undefined && handleSubmit();
            }}
          >
            اعمال
          </div>
        )} */}
      </div>
    </div>
  );
};
