import {Card, Fade, Modal, PageTopbar, Spinner, SVG} from "components";
import React, {useState, useEffect} from "react";
import Slider from "react-slick";
import {getLotterySliderAPI} from "utils/Services/API";
import moment from 'jalali-moment';
import {useUser} from "hooks";
import {Circle} from "rc-progress";
import {Hint} from "components/Hint";
import {getUserLotteryWinAPI, getLotteryArchiveAPI, getHintAPI} from "utils";
import {Tabs} from "containers/OrdersContainer/dependencies";
import lotteryImage from '../../assets/images/lottery.png';
import {useHistory} from "react-router-dom";

export const LotteryContainer = () => {
  const [lotteryBanners, setLotteryBanners] = useState([]);
  const [archivedLottery, setArchivedLottery] = useState([]);
  const [wonLottery, setWonLottery] = useState([]);
  const [currentShowingLottery, setCurrentShowingLottery] = useState(null);
  const [activeTab, setActiveTab] = useState(1);
  const [modalLottery, setModalLottery] = useState<any>({});
  const [modalLotteryType, setModalLotteryType] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showHint, setShowHint] = useState(false);
  let loadingCount = 0;
  const {userState: {user: userData}} = useUser();
  const [hint, setHint] = useState<any>({
    title: '',
    content: ''
  });
  const history = useHistory();
  const sliderSettings = {
    infinite: true,
    slidesToShow: 1,
    speed: 50,
    arrows: false,
    dots: true,
  };

  const handleShowModal = (type, lottery) => {
    setModalLottery(lottery);
    setModalLotteryType(type);
    setShowModal(true);
  };

  const getLotteryBanners = async () => {
    setLoading(true);
    loadingCount++;
    try {
      const response = await getLotterySliderAPI();
      if (response.status >= 200 && response.status < 300) {
        setLotteryBanners(response.data.data.slider);
      }
    } catch (error) {
    } finally {
      loadingCount--;
      if (loadingCount - 1 <= 0) {
        setLoading(false);
      }
    }
  };

  const getWonLottery = async () => {
    setLoading(true);
    loadingCount++;

    try {
      const response = await getUserLotteryWinAPI();
      if (response.status >= 200 && response.status < 300) {
        setWonLottery(response.data);
      }
    } catch (error) {
    } finally {
      loadingCount--;
      if (loadingCount - 1 <= 0) {
        setLoading(false);
      }
    }
  };

  const getArchiveLottery = async () => {
    setLoading(true);
    loadingCount++;
    try {
      const response = await getLotteryArchiveAPI();
      if (response.status >= 200 && response.status < 300) {
        setArchivedLottery(response.data);
      }
    } catch (error) {
    } finally {
      loadingCount--;
      if (loadingCount - 1 <= 0) {
        setLoading(false);
      }
    }
  };

  const getUserLotteryPoint = () => {
    return userData?.Lottery - 199 + '';
  };

  const getMonthName = (monthNumber) => {
    switch (monthNumber) {
      case 1:
        return "فروردین";
      case 2:
        return "اردیبهشت";
      case 3:
        return "خرداد";
      case 4:
        return "تیر";
      case 5:
        return "مرداد";
      case 6:
        return "شهریور";
      case 7:
        return "مهر";
      case 8:
        return "آبان";
      case 9:
        return "آذر";
      case 10:
        return "دی";
      case 11:
        return "بهمن";
      case 12:
        return "اسفند";
    }
  };

  const getHint = () => {
    getHintAPI('صفحات داخلی قرعه کشی')
      .then(response => {
        setHint(response);
      })
      .catch(() => setHint(false))
      .finally(() => {
      });
  };

  useEffect(() => {
    getLotteryBanners();
    getWonLottery();
    getArchiveLottery();
    getHint();
  }, []);

  useEffect(() => {
    history.replace("/lottery");
  }, []);

  return (
    <React.StrictMode>
      <Spinner active={loading} color="dark"/>
      <Fade>
        <PageTopbar title="قرعه‌کشی" showKv={false}/>

        <Modal active={showHint} close={(close) => setShowHint(false)} hint="kvAssign">
          <div className="hints__title">
            {hint.title}
          </div>
          <div className="hints__content">
            {hint.content?.split('\n')?.map(str => <div>{str}</div>)}
          </div>
        </Modal>

        <Modal active={showModal} close={(close) => {
          setShowModal(false);
          setModalLottery({});
        }}>
          {modalLotteryType === 'archive' && (
            <div className="lottery__archive">
              {modalLottery?.people?.map((lottery, index) => (
                <div className="lottery__archive__item" key={index}>
                  <div className="row a-c">
                    <div className="lottery__archive__user-info__image">
                      <img src="" alt=""/>
                    </div>
                    <div className="lottery__archive__user-info">
                      <div className="lottery__archive__user-info__name">
                        {lottery.user.name}
                      </div>
                      <div className="lottery__archive__user-info__number">
                        {lottery.user.mobile}
                      </div>
                    </div>
                  </div>
                  <div className="lottery__archive__gift">
                    {lottery.title}
                  </div>
                </div>
              ))}
            </div>
          )}

          {modalLotteryType === 'won' && (
            <div className="lottery__won">
              <div className="row j-c">
                <img src={lotteryImage} alt="" className="lottery__won__image"/>
              </div>

              {modalLottery?.people?.map((lottery, index) => (
                <div className="lottery__won__title" key={index}>
                  {lottery.user.id === userData.id && lottery.title}
                </div>
              ))}

              <div className="lottery__won__description">
                از کارتک با شما تماس گرفته خواهد شد.
              </div>

              <div className="lottery__won__button" onClick={() => {
                setShowModal(false);
                setModalLottery({});
              }}>
                متوجه شدم!
              </div>
            </div>
          )}
        </Modal>

        <Slider {...sliderSettings}>
          {lotteryBanners &&
            lotteryBanners.length > 0 &&
            lotteryBanners.map((banner) => (
              <div className="lottery__slide">
                <img src={`data:image/png;base64,${banner.photo}`}/>
              </div>
            ))}
        </Slider>

        <div className="lottery">
          <Card>
            <div className="lottery__content">
              <Tabs
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                items={[
                  {
                    title: "قرعه‌کشی‌های من",
                    icon: 'gift-lottery'
                  },
                  {
                    title: "قرعه‌کشی‌های کارتک",
                    icon: 'all-lottery'
                  },
                ]}
              />

              {activeTab === 1 ? (
                <Fade>
                  {currentShowingLottery && currentShowingLottery !== null && (
                    <div className="lottery__title lottery__title__future">
                      {getMonthName(currentShowingLottery.lotteryDate.month)}{" "}
                      {currentShowingLottery.lotteryDate.year}
                    </div>
                  )}
                  <div className="row j-c w-100 mb-8">
                    <Hint onClick={() => setShowHint(true)} theme="orange"/>
                  </div>
                  <div className="row a-c mb-16">
                    {Number(userData?.Lottery) < 200 ? (
                      <div
                        className="profile__lottery__item zero"
                      >
                        <div className="profile__lottery__item__icon-wrapper">
                          <Circle
                            className="profile__lottery__item__icon-hint"
                            percent={userData?.Lottery / 2}
                            strokeWidth={10}
                            strokeColor="#4D5EFF"
                            trailWidth={10}
                            trailColor="#B3BDCC"
                          ></Circle>
                          <div className="lock">
                            <SVG svg="lock"/>
                          </div>
                        </div>
                        <div className="profile__lottery__item__content">
                          <div className="profile__lottery__item__content__info">
                            <span className="profile__lottery__item__content__info__dot"></span>
                            <span className="profile__lottery__item__content__info__title">
                              {`با گرفتن ${200 - userData?.Lottery
                              } KV دیگر در قرعه کشی شرکت کنید`}
                            </span>
                            {/* <Hint onClick={() => setShowHint(true)} theme="orange" /> */}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <React.Fragment>
                        {/* <div className="lottery__circle">
                          {allLotteryHistory.length !== 0 && (
                            <div className="lottery__seperator general" />
                          )}
                        </div> */}

                        <div className="lottery__future">
                          <div className="lottery__future__info">
                            <div className="lottery__future__title">
                              شانس قرعه کشی
                            </div>
                            <div className="lottery__future__description">
                              با افزایش KV شانس خود را افزایش بدهید
                            </div>
                          </div>

                          <div className="lottery__future__jeton">
                            <div className="lottery__future__circle">
                              <SVG svg="gift-lottery"/>
                            </div>
                            {getUserLotteryPoint()}
                          </div>
                        </div>
                      </React.Fragment>
                    )}

                  </div>

                  {wonLottery.length > 0 &&
                    wonLottery.map((lottery) => (
                      <div>
                        <div className="lottery__title">
                          {getMonthName(lottery.lotteryDate.month)}
                          {" "}
                          {lottery.lotteryDate.year}
                        </div>

                        <div className="row a-c">
                          <div className="lottery__circle">
                            <div className="lottery__seperator general"/>
                          </div>

                          <div className="lottery__general">
                            <div className="lottery__general__info">
                              <div className=" lottery__general__circle">
                                <SVG svg="gift-lottery"/>
                              </div>

                              <div className="lottery__general__title">
                                شما برنده قرعه‌کشی {' ' + lottery.title + ' '}شده‌اید!
                              </div>
                            </div>

                            <div className="lottery__general__footer">
                              <div
                                className="lottery__general__button"
                                onClick={() => handleShowModal('won', lottery)}
                              >
                                جزئیات
                                <div className="lottery__general__button__see">
                                  <SVG svg="detail-lottery"/>
                                </div>
                                <div className="lottery__general__button__shape">
                                  <SVG svg="bottom-shape" color="#21bbe1"/>
                                </div>
                              </div>

                              <div className="lottery__general__winner">
                                شما برنده شده‌اید
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </Fade>
              ) : (
                archivedLottery.length > 0 &&
                archivedLottery.map((lottery) => (
                  <Fade>
                    <div className="lottery__title">
                      {getMonthName(lottery.lotteryDate.month)}{" "}
                      {lottery.lotteryDate.year}
                    </div>

                    <div className="row a-c">
                      <div className="lottery__circle">
                        <div className="lottery__seperator general"/>
                      </div>

                      <div className="lottery__general">
                        <div className="lottery__general__info">
                          <div className=" lottery__general__circle">
                            <SVG svg="winner-lottery"/>
                          </div>

                          <div className="lottery__general__title">
                            قرعه‌کشی {lottery.title}
                          </div>
                        </div>

                        <div className="lottery__general__footer">
                          <div
                            className="lottery__general__button"
                            onClick={() => handleShowModal('archive', lottery)}
                          >
                            برندگان
                            <div className="lottery__general__button__see">
                              <SVG svg="see-lottery"/>
                            </div>
                            <div className="lottery__general__button__shape">
                              <SVG svg="bottom-shape" color="#21bbe1"/>
                            </div>
                          </div>

                          <div className="lottery__general__date">
                            برگذار شده در تاریخ: {moment(lottery.date).format('jYYYY/jM/jD')}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Fade>
                ))
              )}
            </div>
          </Card>

        </div>
      </Fade>
    </React.StrictMode>
  );
};