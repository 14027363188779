import "./CircleHint.scss";
import { Fragment, useState } from "react";
import { SVG } from "components";

export const CircleHint = ({ hintText }) => {
    const [isHintVisible, setIsHintVisible] = useState(false);
    return (
        <Fragment className="CircleHint">
            <div
                className={`CircleHint__header ${
                    isHintVisible && "CircleHint__header--active"
                }`}
                onClick={() => {
                    setIsHintVisible(!isHintVisible);
                }}
            >
                {<SVG svg="bottom-shape" color="#195190" />}
                <p>راهنما</p>
                {<SVG svg="bottom-shape" color="#195190" />}
            </div>

            <div
                className={`CircleHint__body ${
                    isHintVisible && "CircleHint__body--active"
                }`}
            >
                <p>{hintText}</p>
            </div>
        </Fragment>
    );
};
