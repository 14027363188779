import React, { useEffect, useState, useMemo } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useHistory, useParams } from "react-router-dom";
import {
    addFavoriteAPI,
    addToCartAPI,
    decrementCartAPI,
    getProductAPI,
    getSpecialProductsAPI,
    isFavriteAPI,
    removeFromCartAPI,
} from "../../utils/Services/API";
import { cloneDeep, find, sortBy } from "lodash";
import toast from "react-hot-toast";
import moment from "jalali-moment";
import {
    CreateComment,
    Fade,
    Comments,
    Compare,
    Gallery,
    Share,
    Spinner,
    SVG,
    Card,
    Dots,
    TokenPrice,
    Lowprice,
    Modal,
    ProductItem,
} from "components";
import ProductImage from "../../assets/images/product.png";
import { currency, transformPrice } from "utils/Functions";
import { useCart, useUser } from "hooks";

import { KvInfo, TopBar, Cover, Variation, Tab } from "./dependencies";

export const ProductContainer = () => {
    const user_token = localStorage.getItem("user_token");
    const {
        userState: { user },
    } = useUser();
    const {
        cartState: { cart },
        getCartDataFromApi,
        setCartData,
        checkProductWithCart,
    } = useCart();
    const { id } = useParams<any>();
    const [productDetail, setProductDetail] = useState<any>({});
    const [productImages, setProductImages] = useState([]);
    const [productVariations, setProductVariations] = useState([]);
    const [productColors, setProductColors] = useState([]);
    const [productFeatures, setProductFeatures] = useState([]);
    const [productComments, setproductComments] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState({
        productUnit: "",
        userId: "",
    });
    const [activeReplyOpened, setActiveReplyOpened] = useState<number>(0);
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [showShare, setShowShare] = useState(false);
    const [showCompare, setShowCompare] = useState(false);
    const [activeVariation, setActiveVariation] = useState<any>({});
    const [activeColor, setActiveColor] = useState(0);
    const [activeWarranty, setActiveWarranty] = useState(0);
    const [isFavorite, setIsFavorite] = useState(false);
    const [currImage, setCurrImage] = useState(false);
    const [showCommentDetail, setShowCommentDetail] = useState(false);
    const [parentCommentForReplies, setParentCommentForReplies] =
        useState(null);
    const [showComment, setShowComment] = useState(false);
    // const [existOnCart, setExistOnCart] = useState<any>(false);
    const [counterLoading, setCounterLoading] = useState(false);
    const [activeTab, setActiveTab] = useState("description");
    const [showVariations, setShowVariations] = useState(false);
    const [showTokenPrice, setShowTokenPrice] = useState(false);
    const [disableInc, setDisableInc] = useState(false);
    const [showKvInfo, setShowKvInfo] = useState(false);
    const [showKvInfoPrice, setShowKvInfoPrice] = useState(0);
    const [tokenPriceType, setTokenPriceType] = useState("");
    const [seller, setSeller] = useState("");
    const [userPrice, setUserPrice] = useState({
        normal: 0,
        sevenStar: 0,
        captain: 0,
        citizen: 0,
    });
    const [showFulltext, setShowFulltext] = useState(false);

    const [pid, setPid] = useState(-1);

    let existOnCart = checkProductWithCart(activeVariation.id);

    const tabs = [
        {
            type: "description",
            title: "توضیحات",
        },
        {
            type: "comments",
            title: "نظرات",
        },
    ];

    const getProductDetail = () => {
        const APIResult = getProductAPI(id);
        APIResult.then((response) => {
            if (response.status === 404) {
                toast.error("محصولی یافت نشد!");
                history.goBack();
                return;
            }
            setPid(response.data.pid);
            setProductDetail(response.data);
            setUserPrice({
                normal: response.data.defaultPrice[3],
                sevenStar: response.data.defaultPrice[2],
                captain: response.data.defaultPrice[4],
                citizen: response.data.defaultPrice[5],
            });

            if (response.data.variation.length > 0) {
                const sortedVariations = sortBy(
                    response.data.variation,
                    (variation) => {
                        return Number(variation.price);
                    }
                );

                for (let index = 0; index < sortedVariations.length; index++) {
                    if (
                        sortedVariations[index].available === 1 &&
                        (sortedVariations[index].detail.IsStock ||
                            sortedVariations[index].detail.IsStock !== null)
                    ) {
                        setActiveVariation(sortedVariations[index]);
                        break;
                    } else if (
                        sortedVariations.length - 1 === index &&
                        sortedVariations[index].available === 1
                    ) {
                        setActiveVariation(sortedVariations[0]);
                    }
                }
                // .forEach((variation) => {
                //   console.log(variation);
                // });
            }
            setproductComments(response.data.comment);
            setProductVariations(response.data.variation);
            setProductFeatures(response.data.features);
            setProductColors(response.data.productColor);
            setProductImages(response.data.image);
            setSeller(response.data.seller);
            handleProductViewed(response.data);
            getIsFavorite();
            getCartDataFromApi();
        });
    };

    const handleShowReplyNumbers = (commentId) => {
        let counter = 0;
        productComments.map((reply) => {
            commentId === Number(reply.parent_id) && counter++;
        });
        return counter;
    };

    const handleShowReplys = (commentId: number) => {
        return productComments.map((reply) => {
            return commentId === Number(reply.parent_id) && reply.comment;
        });
    };

    const handelShowCommentReplies = (comment) => {
        setParentCommentForReplies(comment);
        setShowCommentDetail(true);
    };

    const getIsFavorite = () => {
        const APIResult = isFavriteAPI(user_token, id);
        APIResult.then((response) => {
            if (response.status === 200) {
                if (response.data.data.isFavorite === 1) setIsFavorite(true);
                else setIsFavorite(false);
            } else {
                setIsFavorite(false);
            }
            setLoading(false);
        });
    };

    const handleToggleFavorite = () => {
        const APIResult = addFavoriteAPI(user_token, id);
        APIResult.then((response) => {
            if (response.status === 200) {
                if (response.data.data === true) {
                    toast.success("به لیست علاقه‌مندی‌ها اضافه شد.", {
                        id: "inc/favorite",
                        duration: 1200,
                        position: "top-left",
                    });
                    setIsFavorite(true);
                } else {
                    toast("از لیست علاقه‌مندی‌ها حذف شد.", {
                        id: "dec/favorite",
                        duration: 1200,
                        position: "top-left",
                    });
                    setIsFavorite(false);
                }
            } else {
                toast.error("برای استفاده از لیست علاقه‌مندی‌ها، وارد شوید.");
            }
        });
    };

    // const getSpecialCustomersProducts = () => {
    //     getSpecialProductsAPI()
    //         .then((response) => {
    //             setSpecialProducts(response?.data?.data?.products);
    //             setMarketName(response?.data?.data?.market?.name);
    //         })
    //         .finally(() =>
    //             setLoading((prevState) => ({
    //                 ...prevState,
    //                 specialProducts: false,
    //             }))
    //         );
    // };

    const handleAddToCart = () => {
        setCounterLoading(true);
        let selectedProduct = {
            unit: 0,
            user: 0,
        };
        getSpecialProductsAPI()
            .then((response) => {
                selectedProduct = response.data.data.products.find(
                    (product) => product.id == id
                );
            })
            .then(() => {
                const APIResult = addToCartAPI(
                    user_token,
                    id,
                    activeVariation.id,
                    selectedProduct?.unit,
                    selectedProduct?.user
                );
                APIResult.then((response) => {
                    if (response.status === 200) {
                        if (response.data.msg === "max_buy") {
                            toast.error(
                                "محدودیت خرید این کالا برای شما به پایان رسیده است."
                            );
                            setCounterLoading(false);
                            return;
                        }
                        toast.success("کالا به سبد خرید شما افزوده شد.");
                        let newArr = [];
                        newArr.push(response.data.data.cart);
                        setCartData(newArr);
                    } else if (response.status === 204) {
                        toast.error("افزودن بیش از این تعداد ممکن نیست");
                    } else if (response.status === 401) {
                        toast.error("لطفا ابتدا وارد حساب کاربری خود شوید");
                        history.push("/login");
                    }
                    setCounterLoading(false);
                });
            });
    };

    const handleDecrement = () => {
        setCounterLoading(true);

        let currentCartId;
        cart[0]?.detail?.map((cart) => {
            cart?.items?.map((item) => {
                if (+item.varitions_id === +id) {
                    currentCartId = +item.cart_id;
                }
            });
        });

        const APIResult = decrementCartAPI(
            user_token,
            currentCartId,
            existOnCart.id
        );
        APIResult.then((response) => {
            if (response.status === 200) {
                const newArr: any = [...cart];

                let findedIndex1;
                newArr[0]?.detail?.map((cartTemp) => {
                    findedIndex1 = cartTemp.items.findIndex(
                        (x) => Number(x.varitions_id) == activeVariation.id
                    );
                });

                newArr[0]?.detail?.map((cartTemp) => {
                    if (Number(cartTemp.items[findedIndex1].count) !== 0) {
                        cartTemp.items[findedIndex1].count =
                            Number(cartTemp.items[findedIndex1].count) - 1;
                    }
                });

                setCartData(newArr);

                setCounterLoading(false);
            } else if (response.status === 401) {
                toast.error("لطفا ابتدا وارد حساب کاربری خود شوید");
                history.push("/login");
            }
        });
    };

    const handleRemoveFromCart = () => {
        let currentCartId, cartIndex;
        cart[0]?.detail?.map((cart, index) => {
            cart?.items?.map((item) => {
                if (+item.varitions_id === +id) {
                    cartIndex = +index;
                    currentCartId = +item.cart_id;
                }
            });
        });

        setCounterLoading(true);
        const APIResult = removeFromCartAPI(
            user_token,
            currentCartId,
            existOnCart.id
        );
        APIResult.then((response) => {
            if (response.status === 200) {
                const newArr: any = [...cart];

                let findedIndex;
                newArr[0]?.detail?.map((cartTemp) => {
                    findedIndex = cartTemp.items.findIndex(
                        (x) => Number(x.varitions_id) == activeVariation.id
                    );
                });

                if (findedIndex !== -1) {
                    newArr[0]?.detail[cartIndex]?.items.splice(findedIndex, 1);
                }

                setCartData(newArr);
            } else if (response.status === 401) {
                toast.error("لطفا ابتدا وارد حساب کاربری خود شوید");
                history.push("/login");
            }
            setCounterLoading(false);
        });
    };

    const handleSetVariation = (type, id) => {
        if (type === "color") {
            var res = { id: 0 };

            productVariations.forEach((variation, index) => {
                if (
                    Number(variation.color_id) === id &&
                    res.id === 0 &&
                    variation.available === 1 &&
                    variation.detail.IsStock !== null
                ) {
                    res = variation;
                    return;
                } else if (
                    index === productVariations.length - 1 &&
                    variation.available === 1
                ) {
                    productVariations.forEach((variation, index) => {
                        if (Number(variation.color_id) === id && res.id === 0) {
                            res = variation;
                            return;
                        }
                    });
                }
            });
            if (res.id !== 0) setActiveVariation(res);
        } else if ((type = "warranty")) {
            var res = { id: 0 };

            productVariations.forEach((variation) => {
                if (
                    Number(variation.color_id) ===
                        Number(activeVariation.color_id) &&
                    res.id === 0
                ) {
                    if (Number(variation.waranty_id) === id) {
                        res = variation;
                        return;
                    }
                }
            });
            if (res.id !== 0) setActiveVariation(res);
        }
    };

    const handleShowVariations = () => {
        let variationRes = [];
        productVariations.forEach((variation) => {
            if (
                Number(variation.color_id) === Number(activeVariation.color_id)
            ) {
                variationRes.push(variation);
            }
        });
        return variationRes;
    };

    const handleAvailableVariation = (variation) => {
        if (
            variation.price === undefined ||
            variation.detail.IsStock === null ||
            variation.available !== 1
        ) {
            return false;
        } else {
            return true;
        }
    };

    const handleShowProductInfo = () => {
        switch (activeTab) {
            case "description":
                return (
                    <div className="product__description">
                        {!showFulltext ? (
                            <>
                                {productDetail.description
                                    ?.substring(0, 85)
                                    ?.split("\n")
                                    ?.map((str) => (
                                        <div>{str}</div>
                                    ))}
                                <span
                                    style={{ color: "red" }}
                                    onClick={() => {
                                        setShowFulltext(true);
                                    }}
                                >
                                    ادامه توضیحات ...
                                </span>
                            </>
                        ) : (
                            <>
                                {productDetail.description
                                    ?.split("\n")
                                    ?.map((str) => (
                                        <div>{str}</div>
                                    ))}
                                <span
                                    style={{ color: "red" }}
                                    onClick={() => {
                                        setShowFulltext(false);
                                    }}
                                >
                                    بستن توضیحات
                                </span>
                            </>
                        )}
                    </div>
                );

            case "features":
                return (
                    <div className="product__features">
                        {productFeatures.map((feature, index) => (
                            <div
                                className="product__features__item"
                                key={index}
                            >
                                <div className="product__features__title">
                                    {feature.title}
                                </div>
                                <div className="product__features__description">
                                    {feature.content}
                                </div>
                            </div>
                        ))}
                    </div>
                );

            case "comments":
                return (
                    <div className="product__comments">
                        {productComments.map(
                            (comment, index) =>
                                comment.parent_id === null && (
                                    <React.StrictMode>
                                        <div
                                            className="product__comments__item"
                                            key={index}
                                            onClick={() =>
                                                handelShowCommentReplies(
                                                    comment
                                                )
                                            }
                                        >
                                            <div className="row a-c">
                                                <div className="product__comments__item__username">
                                                    {comment.user}
                                                </div>
                                                <div className="product__comments__item__date">
                                                    {moment(
                                                        comment.created_at,
                                                        "YYYY/MM/DD"
                                                    )
                                                        .locale("fa")
                                                        .format("YYYY/MM/DD")}
                                                </div>
                                            </div>

                                            <div className="product__comments__text">
                                                {comment.comment}
                                            </div>

                                            <div className="product__comments__replys">
                                                {handleShowReplyNumbers(
                                                    comment.id
                                                ) + " "}
                                                پاسخ
                                            </div>
                                        </div>

                                        {activeReplyOpened === comment.id && (
                                            <div className="product__comments__answers">
                                                {handleShowReplys(
                                                    activeReplyOpened
                                                )}
                                            </div>
                                        )}
                                    </React.StrictMode>
                                )
                        )}
                        <div
                            className="product__comments__new"
                            onClick={() => setShowComment(true)}
                        >
                            ثبت نظر جدید
                        </div>
                    </div>
                );

            default:
                break;
        }
    };

    const handleProductViewed = (product) => {
        const recentlyViewed = JSON.parse(localStorage.getItem("rpv"));

        // if (recentlyViewed === null)
        //   localStorage.setItem("rpv", JSON.stringify([product]));
        // else {
        //   if (
        //     !find(recentlyViewed, function (element) {
        //       return element.id === product.id;
        //     })
        //   ) {
        //     recentlyViewed.push(product);
        //     localStorage.setItem("rpv", JSON.stringify(recentlyViewed));
        //   }
        // }
    };

    const handleShowWarranty = () => {
        let currWarranty = [];
        productVariations.forEach((variation, index) => {
            if (
                Number(variation.color_id) === Number(activeVariation.color_id)
            ) {
                currWarranty.push(variation);
            }
        });
        return currWarranty;
    };

    useEffect(() => {
        handleShowWarranty();
        checkProductWithCart(activeVariation.id);
    }, [activeVariation]);

    useEffect(() => {
        window.scrollTo(0, 0);
        if (user_token === null || user_token === undefined) {
            toast("برای مشاهده‌ی قیمت شما، ابتدا وارد شوید.");
            history.push("/login");
            return;
        }
        history.replace(`/product/${id}`);
        getProductDetail();
        // const onScroll = (e) => {
        //   if (document.getElementById("tab").offsetTop > 357)
        //     setIsSetstickyTab(true);
        //   else setIsSetstickyTab(false);
        // };
        // window.addEventListener("scroll", onScroll);

        // return () => window.removeEventListener("scroll", onScroll);
    }, [id]);

    return (
        <div className="product">
            <Spinner active={loading} color="dark" />
            <Gallery
                active={currImage}
                currImage={currImage}
                images={productImages}
                setShow={(show) => setCurrImage(show)}
            />
            {/* <Compare
        active={showCompare}
        setShow={(show) => setShowCompare(show)}
        productDetail={productDetail}
        variation={activeVariation}
      /> */}
            <Share
                active={showShare}
                setClose={(close) => setShowShare(close)}
                value={`${productDetail.title} 
        \n مشاهده محصول در کارتک:
         https://app.kartaak.com/product/${id}
        \n\n وبسایت کارتک : 
        `}
                link={`https://kartaak.com`}
                title="اشتراک‌گذاری محصول"
            />
            <Comments
                active={showCommentDetail}
                setShow={(show) => setShowCommentDetail(show)}
                parentComment={parentCommentForReplies}
                comments={productComments}
                productId={id}
            />
            <CreateComment
                active={showComment}
                setShow={(show) => setShowComment(show)}
                replyData={{
                    productId: id,
                    parent_id: null,
                }}
            />
            <TokenPrice
                active={showTokenPrice}
                setClose={(close) => setShowTokenPrice(!close)}
                data={{
                    variation: activeVariation,
                }}
                type={tokenPriceType}
            />
            <KvInfo
                status={showKvInfo}
                toggleStatus={setShowKvInfo}
                price={showKvInfoPrice}
            />

            <Fade>
                <Card>
                    <TopBar
                        favoriteStatus={isFavorite}
                        toggleFavorite={handleToggleFavorite}
                        toggleShare={setShowShare}
                    />
                    <Cover
                        data={productImages}
                        setCurrentImage={setCurrImage}
                    />
                    <div className="product__title">{productDetail.title}</div>
                </Card>

                <Variation
                    colors={productColors}
                    active={activeVariation}
                    setActiveVariation={setActiveVariation}
                    variationStatus={showVariations}
                    toggleShowVariation={setShowVariations}
                    setVariation={handleSetVariation}
                    productVariations={productVariations}
                    toggleShowTokenPrice={setShowTokenPrice}
                    setTokenPriceType={setTokenPriceType}
                    handleShowVariations={handleShowVariations}
                    handleAvailableVariation={handleAvailableVariation}
                    setShowKvInfo={setShowKvInfo}
                    setShowKvInfoPrice={setShowKvInfoPrice}
                    seller={seller}
                    normalUserPrice={userPrice.normal}
                    sevenStarUserPrice={userPrice.sevenStar}
                    captainUserPrice={userPrice.captain}
                    citizenUserPrice={userPrice.citizen}
                    pid={pid}
                />

                <Tab
                    tabs={tabs}
                    activeTab={activeTab}
                    toggleActiveTab={setActiveTab}
                />

                <div className="product__info">{handleShowProductInfo()}</div>

                {productDetail?.similarProducts?.length > 0 && (
                    <div
                        style={{
                            marginTop: "20px",
                            marginBottom: "10px",
                            padding: "0px 10px",
                        }}
                    >
                        <p style={{ fontSize: "15px" }}>محصولات مرتبط</p>
                        <div style={{ display: "flex", overflow: "auto" }}>
                            {productDetail?.similarProducts?.map((item) => (
                                <ProductItem product={item} />
                            ))}
                        </div>
                    </div>
                )}

                <div className="product__bottom">
                    {existOnCart ? (
                        <div className="product__bottom__button-counter">
                            <div className="product__bottom__button-counter__shape">
                                <SVG svg="bottom-shape" />
                            </div>
                            <div className="product__bottom__button-counter__shape reverse">
                                <SVG svg="bottom-shape" />
                            </div>
                            <div
                                onClick={() => history.push("/carts")}
                                className="product__bottom__button-counter__cart"
                            >
                                <span>مشاهده سبد خرید</span>
                                <SVG svg="cart-small" />
                            </div>
                            <div className="product__bottom__button-counter__count-box">
                                {!counterLoading ? (
                                    <>
                                        <div
                                            onClick={() => {
                                                activeVariation.price !==
                                                    undefined &&
                                                    activeVariation.detail
                                                        .IsStock !== null &&
                                                    !disableInc &&
                                                    handleAddToCart();
                                            }}
                                            className={`product__bottom__button-counter__count-box__btn ${
                                                disableInc && "disable"
                                            }`}
                                        >
                                            <SVG svg="ads-inc" />
                                        </div>
                                        <div className="product__bottom__button-counter__count-box__value">
                                            {existOnCart.count}
                                        </div>

                                        <div
                                            onClick={() => {
                                                Number(existOnCart.count) === 1
                                                    ? handleRemoveFromCart()
                                                    : handleDecrement();
                                            }}
                                            className="product__bottom__button-counter__count-box__btn-dec"
                                        >
                                            <SVG svg="ads-dec" />
                                        </div>
                                        <div
                                            onClick={() => {
                                                handleRemoveFromCart();
                                            }}
                                            className={`product__bottom__button-counter__count-box__btn-del ${
                                                existOnCart.count > 1 &&
                                                "visible"
                                            }`}
                                        >
                                            <SVG svg="delete" />
                                        </div>
                                    </>
                                ) : (
                                    <Dots />
                                )}
                            </div>
                        </div>
                    ) : (
                        <div
                            className={`product__bottom__button ${
                                !handleAvailableVariation(activeVariation) &&
                                "deactive"
                            }`}
                            onClick={() => {
                                handleAvailableVariation(activeVariation) &&
                                    handleAddToCart();
                            }}
                        >
                            <div className="product__bottom__button__shape">
                                <SVG svg="bottom-shape" />
                            </div>
                            <div className="product__bottom__button__shape reverse">
                                <SVG svg="bottom-shape" />
                            </div>
                            {handleAvailableVariation(activeVariation) ? (
                                !counterLoading ? (
                                    "افزودن به سبد خرید"
                                ) : (
                                    <Dots color="light" />
                                )
                            ) : (
                                "ناموجود"
                            )}
                        </div>
                    )}
                </div>
            </Fade>
        </div>
    );
};
