import { authInitialState } from "./InitialState";
import { authTypes } from "./Types";

export const AuthReducer = (state = authInitialState, { payload, type }) => {
  switch (type) {
    case authTypes.SET_LOGIN_STATUS:
      return {
        ...state,
        isLogged: payload.status,
      };

    default:
      break;
  }
};
