import React, { useEffect, useState } from "react";
import Checkout from "../components/Checkout/Checkout";
import { Card, Dots, SVG } from "components";
import { Link, useHistory, useParams } from "react-router-dom";
import {
    decrementCartAPI,
    devIp,
    getCartAPI,
    incrementCartAPI,
    removeFromCartAPI,
} from "../utils/Services/API";
import { Spinner } from "components";
import toast from "react-hot-toast";
import { Fade } from "components";
import { transformPrice } from "utils/Functions";
import ProductImage from "../assets/images/product.png";
import { useCart } from "hooks";
import { cartDetailAPI } from "../utils";
import { PageTopbar } from "../components";

const Cart = () => {
    const user_token = localStorage.getItem("user_token");
    const { setCartData } = useCart();
    const [showCheckout, setShowCheckout] = useState(false);
    const [cart, setCart] = useState([]);
    const [loading, setLoading] = useState(true);
    const [cartLoading, setCartLoading] = useState(-1);
    const { id } = useParams();
    const history = useHistory();
    const [allCartData, setAllCartData] = useState();

    const getCart = () => {
        const APIResult = cartDetailAPI(id);
        APIResult.then((response) => {
            if (response.status === 401 || response.status === 404) {
                setLoading(false);
                setCartLoading(-1);
                return;
            }
            setAllCartData(response.data);
            setCart([response.data.items]);
            setCartData([response.data.items]);
            setLoading(false);
            setCartLoading(-1);
        }).catch((error) => {
            if (error.response.data.status === 404) {
                history.push("/carts");
            }
        });
    };

    const handleShowTotalPrice = () => {
        let totalPrice = 0;
        cart[0].forEach((cartItem) => {
            totalPrice += cartItem.price * Number(cartItem.count);
        });
        return transformPrice(totalPrice);
    };

    const handleCartOperator = (type, cartInfo, cartItem) => {
        setCartLoading(cartItem.id);

        switch (type) {
            case "inc":
                const incrementAPIResut = incrementCartAPI(
                    user_token,
                    allCartData.id,
                    cartItem.id
                );
                incrementAPIResut.then((response) => {
                    if (response.status === 200) {
                        if (response.data.msg === "max_buy") {
                            toast.error(
                                "محدودیت خرید این کالا برای شما به پایان رسیده است."
                            );
                            setCartLoading(-1);
                            return;
                        }
                    }
                    if (response.status === 204) {
                        toast("محدودیت انتخاب این کالا به اتمام رسیده است.");
                        setCartLoading(-1);
                        return;
                    }
                    getCart();
                });
                break;

            case "dec":
                if (Number(cartItem.count) === 1) {
                    handleRemoveFromCart(cartInfo, cartItem);
                    return;
                }
                const decrementAPIResult = decrementCartAPI(
                    user_token,
                    allCartData.id,
                    cartItem.id
                );
                decrementAPIResult.then((response) => {
                    getCart();
                });
                break;

            default:
                break;
        }
    };

    const handleRemoveFromCart = (cartInfo, cartItem) => {
        setCartLoading(cartItem.id);
        const APIResult = removeFromCartAPI(
            user_token,
            allCartData.id,
            cartItem.id
        );
        APIResult.then((response) => {
            if (response.status === 200) {
                toast.success("کالا از سبد خريد حذف شد.");
                getCart();
            }
        });
    };

    useEffect(() => {
        getCart();
        history.replace(`/cart/${id}`);
    }, []);

    return (
        <div className="cart-new wrapper-fix">
            <Spinner active={loading} color="dark" />
            {!loading && cart && cart.length > 0 ? (
                <Fade>
                    <PageTopbar
                        title={`سبد فروشگاه ${allCartData.seller}`}
                        showKv={false}
                    />
                    <Card>
                        <div className="cart-new__carts-list">
                            <div className="cart-new__body">
                                {cart.length !== 0 &&
                                    cart[0].map((cartItem, index) => (
                                        <div className="cart-new__item">
                                            <div className="cart-new__item__detail">
                                                <div className="cart-new__item__image">
                                                    <img
                                                        loading="lazy"
                                                        src={
                                                            cartItem.detail
                                                                .Product
                                                                .image &&
                                                            cartItem.detail
                                                                .Product
                                                                .image[0]
                                                                ? `${cartItem.detail.Product.image[0]}`
                                                                : // ? `data:image/png;base64,${cartItem.detail.Product.image[0]}`
                                                                  ProductImage
                                                        }
                                                    />
                                                </div>
                                                <div className="cart-new__item__info">
                                                    <div className="cart-new__item__top">
                                                        <div className="cart-new__item__title">
                                                            {
                                                                cartItem.detail
                                                                    .Product
                                                                    .title
                                                            }
                                                        </div>
                                                        <div
                                                            className="cart-new__item__remove"
                                                            onClick={() =>
                                                                handleRemoveFromCart(
                                                                    cart[0],
                                                                    cartItem
                                                                )
                                                            }
                                                        >
                                                            <SVG svg="cross" />
                                                        </div>
                                                    </div>
                                                    <div className="cart-new__item__bottom">
                                                        <div className="cart-new__item__price">
                                                            {transformPrice(
                                                                Number(
                                                                    cartItem.price
                                                                )
                                                            )}{" "}
                                                            تومان
                                                        </div>
                                                        <div className="cart-new__item__counter">
                                                            {cartLoading ===
                                                            cartItem.id ? (
                                                                <Dots />
                                                            ) : (
                                                                <React.Fragment>
                                                                    <div
                                                                        className="cart-new__item__counter__increase"
                                                                        onClick={() =>
                                                                            handleCartOperator(
                                                                                "inc",
                                                                                cart[0],
                                                                                cartItem
                                                                            )
                                                                        }
                                                                    >
                                                                        <SVG svg="plus" />
                                                                    </div>
                                                                    <div className="cart-new__item__counter__number">
                                                                        {
                                                                            cartItem.count
                                                                        }
                                                                    </div>
                                                                    <div
                                                                        className="cart-new__item__counter__decrease"
                                                                        onClick={() =>
                                                                            handleCartOperator(
                                                                                "dec",
                                                                                cart[0],
                                                                                cartItem
                                                                            )
                                                                        }
                                                                    >
                                                                        <SVG
                                                                            svg={
                                                                                Number(
                                                                                    cartItem.count
                                                                                ) ===
                                                                                1
                                                                                    ? "delete"
                                                                                    : "minus"
                                                                            }
                                                                        />
                                                                    </div>
                                                                </React.Fragment>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/*<div className="cart-new__item__properties">*/}
                                            {/*  /!* <div className="cart-new__item__property"> *!/*/}
                                            {/*  {Number(cartItem.detail.Variation.color_id) !== 1 && (*/}
                                            {/*    <div*/}
                                            {/*      style={{*/}
                                            {/*        backgroundColor: `#${cartItem.detail.Variation.detail.Color.HEX}`,*/}
                                            {/*      }}*/}
                                            {/*      className="cart-new__item__color"*/}
                                            {/*    />*/}
                                            {/*  )}*/}
                                            {/*  /!* </div> *!/*/}
                                            {/*  <div className="cart-new__item__property">*/}
                                            {/*    {cartItem.detail.Variation.detail.Warranty}*/}
                                            {/*  </div>*/}
                                            {/*</div>*/}
                                        </div>
                                    ))}
                                {/*             
            <div className="cart-new__not-available">
              <div className="cart-new__not-available__text-wrapper">
                <div className="cart-new__not-available__icon">
                  <SVG svg="circle" />
                </div>
                <div className="cart-new__not-available__title">
                  <span>1</span>
                  کالا از سبد خرید شما ناموجود شد
                </div>
              </div>
              <div className="cart-new__not-available__button">فهمیدم</div>
            </div> */}
                            </div>
                        </div>
                    </Card>

                    {cart.length > 0 && (
                        <div className="cart-new__final cart-new__final__in-cart">
                            <div
                                className="cart-new__order-button"
                                onClick={() => {
                                    //setShowCheckout(true);
                                    history.push("/carts");
                                }}
                            >
                                <div className="cart-new__shape">
                                    <SVG svg="bottom-shape" color="#21BBE1" />
                                </div>

                                <div className="cart-new__shape reverse">
                                    <SVG svg="bottom-shape" color="#ffffff" />
                                </div>

                                <div className="cart-new__order-button__title">
                                    {/* ثبت سفارش و خرید */}
                                    بازگشت به سبد خرید
                                </div>
                                <div className="cart-new__order-button__icon">
                                    <SVG svg="payment-cart" />
                                </div>
                            </div>
                            <div className="cart-new__final-price">
                                {handleShowTotalPrice()} تومان
                            </div>
                        </div>
                    )}

                    <Checkout
                        active={showCheckout}
                        setClose={(close) => setShowCheckout(close)}
                        currCart={cart}
                        updateCurrCart={() => {
                            getCart();
                        }}
                    />

                    {/* <BottomNavigation /> */}
                    {/* </Padding> */}
                </Fade>
            ) : (
                <div className="cart-new__empty-wrapper">
                    <div className="cart-new__empty">
                        <div className="cart-new__empty__icon">
                            <SVG svg="cart-big" />
                        </div>
                        <div className="cart-new__empty__title">
                            سبد خرید شما خالی است
                        </div>
                        <div className="cart-new__empty__subtitle">
                            با گشتن در پاساژ محصولات مورد علاقه‌تان را پیدا کنید
                        </div>
                    </div>
                    <Link className="cart-new__empty-button" to="/market">
                        <div className="cart-new__empty-button__title">
                            <div className="cart-new__empty-button__icon cart">
                                <SVG svg="cart-small" />
                            </div>
                            پاساژ
                        </div>
                        <div className="cart-new__empty-button__icon">
                            <SVG svg="arrow-big-white" />
                        </div>
                    </Link>
                </div>
            )}
        </div>
    );
};

export default Cart;
