import React, { useEffect, useState } from "react";
import "./Fade.scss";

export const Fade = (props) => {
    const [active, setActive] = useState(false);

    useEffect(() => {
        setActive(true);
    }, []);

    return <div className={`fade ${active && "active"}`}>{props.children}</div>;
};
