import React, { useEffect, useState } from "react";
import "./KartakApps.scss";
import { kartakAppsItems } from "../../utils/data";
import { SVG, Padding } from "components";
import { Link } from "react-router-dom";

export const KartakApps = () => {
  const [showLanded, setShowLanded] = useState(true);

  // const handleScroll = event => {
  //   if (window.scrollY > 450)
  //     setShowLanded(true);
  //   else
  //     setShowLanded(false);
  // }

  // useEffect(() => {
  //   if (window.innerHeight < 830) {
  //     window.addEventListener('scroll', handleScroll);

  //     return () => {
  //       window.removeEventListener('scroll', handleScroll);
  //     };
  //   } else {
  //     setShowLanded(true)
  //   }
  // }, []);

  return (
    <div>
      <Padding>
        <div className="apps__title">کارتک اپس</div>
      </Padding>
      <div className="apps__container">
        {kartakAppsItems.map((appItem, index) => (
          <Link className={`apps__item ${showLanded && 'shown'}`} key={index} to={appItem.link}>
            <div className="apps__item__icon">
              <SVG svg={appItem.icon} />
            </div>

            <div className="apps__item__title">{appItem.title}</div>
          </Link>
        ))}
      </div>
    </div>
  );
};
