import React, { useEffect, useState } from "react";
import ProductImage from "../assets/images/product.png";
import { PageTopbar, Spinner, Fade, Padding } from "components";
import { useHistory, useLocation } from "react-router-dom";
import { devIp, getProductAPI } from "../utils/Services/API";
import { cloneDeep, forEach } from "lodash";

const Compare = () => {
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const location = useLocation();
  const [productIds, setProductIds] = useState([]);
  const [productDetails, setProductDetails] = useState([]);
  const [features, setFeatures] = useState([]);
  const [getProductCounter, setGetProductCounter] = useState(0);

  const getProductDetail = (productIdArray, index) => {
    let currProductDetails = cloneDeep(productDetails);
    let currProductFeatures = cloneDeep(features);

    if (productIdArray[index] !== undefined) {
      const APIResult = getProductAPI(Number(productIdArray[index]));
      APIResult.then((response) => {
        currProductDetails.push(response.data);
        setProductDetails(currProductDetails);

        currProductFeatures.push(response.data.features);
        setFeatures(currProductFeatures);

        setGetProductCounter(getProductCounter + 1);
      });
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (productDetails.length !== 0) {
      getProductDetail(productIds, getProductCounter);
    }
  }, [getProductCounter]);

  useEffect(() => {
    const idArray = location.search.substr(1).split(",");

    setProductIds(idArray);
    getProductDetail(idArray, getProductCounter);

    history.replace(`/compare${location.search}`);
  }, []);

  return (
    <React.StrictMode>
      <Spinner active={loading} color="dark" />
      <Fade>
        <PageTopbar title="مقایسه‌ی محصول" />
        <Padding>

          <div className="compare">
            <div className="compare__products">
              <div className="compare__images">
                {productDetails.map((product, index) => (
                  <img
                    src={
                      `data:image/png;base64,
                  ${product.image[0]}
                  `
                    }
                    alt=""
                    key={index}
                  />
                ))}
              </div>
            </div>

            <div className="compare__info">
              {productDetails.map((product, index) => (
                <div className="compare__title" key={index}>
                  {product.title}
                </div>
              ))}
            </div>

            <div className="compare__details">
              {features[0] !== undefined &&
                features[0].map((featureItem, index) => (
                  <div className="compare__details__item" key={index}>
                    <div className="compare__details__item__title">
                      {featureItem.title}
                    </div>

                    <div className="compare__details__item__values">
                      {features.map((feature) => (
                        // feature[index]
                        // .map((featureContent, number) => (
                        //   index === number &&
                        <div key={index}>{feature[index].content}</div>
                        // ))
                      ))}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </Padding>
      </Fade>
    </React.StrictMode>
  );
};

export default Compare;
