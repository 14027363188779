import {
  Card, Dots,
  Fade,
  NotFoundBox,
  PageTopbar,
  Spinner,
  SVG,
} from "../../../../components";
import React, {useEffect, useState} from "react";
import {getStoreCommentsAPI, getUserStoreAPI} from "../../../../utils";
import {toast} from "react-hot-toast";
import GreatImage from "../../../../assets/images/emoji/great.png";
import SmileImage from "../../../../assets/images/emoji/smile.png";
import PokerImage from "../../../../assets/images/emoji/poker.png";
import SadImage from "../../../../assets/images/emoji/sad.png";
import AngryImage from "../../../../assets/images/emoji/angry.png";
import LikeImage from "../../../../assets/images/emoji/like.png";
import DislikeImage from "../../../../assets/images/emoji/dislike.png";
import moment from "jalali-moment";
import {useHistory} from "react-router-dom";
import {cloneDeep} from "lodash";

export const Comments = () => {
  const history = useHistory();
  const [storeComments, setStoreComments] = useState([]);
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [showComment, setShowComment] = useState(-1);
  const [loading, setLoading] = useState(true);
  const [commentLoading, setCommentLoading] = useState(false);

  // const getStoreId = () => {
  //   getUserStoreAPI().then((res) => {
  //     if (res.status === 200) {
  //       getStoreComments(res.data.id);
  //     }
  //   }).catch(err => {
  //     toast.error("مشکلی رخ داد");
  //   });
  // };

  const getStoreComments = (page) => {
    setCommentLoading(true);
    getStoreCommentsAPI(10, page)
      .then((res) => {
        if (res.status === 200) {
          let currComments = cloneDeep(storeComments);
          res.data?.data.map(data => {
            currComments.push(data);
          });
          setStoreComments(currComments);
          // setStoreComments([...storeComments, res.data?.data]);
          setLastPage(res.data?.last_page);
        }
      }).catch(err => {
      toast.error("مشکلی رخ داد");
    }).finally(() => {
      setLoading(false);
      setCommentLoading(false);
    });
  };

  const handleShowEmoji = (rate, type) => {
    if (type === "multiselect") {
      switch (rate) {
        case 1:
          return AngryImage;

        case 2:
          return SadImage;

        case 3:
          return PokerImage;

        case 4:
          return SmileImage;

        case 5:
          return GreatImage;

        default:
          break;
      }
    }

    if (type === "singleselect") {
      switch (rate) {
        case 1:
          return LikeImage;

        case 2:
          return DislikeImage;

        default:
          break;
      }
    }
  };

  useEffect(() => {
    getStoreComments(page);
  }, [page]);

  useEffect(() => {
    history.replace("/myshop/comments");
  }, []);

  return (
    <Fade>
      <PageTopbar title={"نظرات کاربران"}/>
      <Spinner active={loading} color="dark"/>
      <Card>
        <div className="store-comments mycity wrapper-fix">
          {storeComments.length === 0 && <NotFoundBox title="" icon=""/>}
          {storeComments.map((storeComment, index) => (
            <div
              className="store-comments__item"
              key={index}
              onClick={() => setShowComment(index)}
            >
              <div className={"store-comments__header"}>
                <div className="store-comments__header__title">
                  نظر ثبت شده در{" "}
                  {moment(storeComment.created_at)
                    .locale("fa")
                    .format("YYYY/MM/DD")}
                </div>
                <div className="store-comments__header__icon">
                  {
                    showComment !== -1 && showComment === index &&
                      <span style={{marginLeft: "10px", marginTop: "3px"}} onClick={(e) => {
                        e.stopPropagation();
                        setShowComment(-1);
                      }}>X</span>
                  }
                  <SVG svg="arrow"/>
                </div>
              </div>
              {showComment === index && (
                <div className={"store-comments__body"}>
                  <div className="store-comments__body__points">
                    {storeComment.priceProduct !== 0 && (
                      <div className="store-comments__body__points__item">
                        <div className="store-comments__body__points__item__title">
                          قیمت محصولات
                        </div>
                        <div className="store-comments__body__points__item__point">
                          <img
                            src={handleShowEmoji(
                              storeComment.priceProduct,
                              "multiselect"
                            )}
                            alt=""
                          />
                        </div>
                      </div>
                    )}
                    {storeComment.qualityProduct !== 0 && (
                      <div className="store-comments__body__points__item">
                        <div className="store-comments__body__points__item__title">
                          کیفیت محصولات
                        </div>
                        <div className="store-comments__body__points__item__point">
                          <img
                            src={handleShowEmoji(
                              storeComment.qualityProduct,
                              "multiselect"
                            )}
                            alt=""
                          />
                        </div>
                      </div>
                    )}
                    {storeComment.sellerReaction !== 0 && (
                      <div className="store-comments__body__points__item">
                        <div className="store-comments__body__points__item__title">
                          برخورد فروشنده
                        </div>
                        <div className="store-comments__body__points__item__point">
                          <img
                            src={handleShowEmoji(
                              storeComment.sellerReaction,
                              "multiselect"
                            )}
                            alt=""
                          />
                        </div>
                      </div>
                    )}
                    {storeComment.storeApparence !== 0 && (
                      <div className="store-comments__body__points__item">
                        <div className="store-comments__body__points__item__title">
                          ظاهر فروشگاه
                        </div>
                        <div className="store-comments__body__points__item__point">
                          <img
                            src={handleShowEmoji(
                              storeComment.storeApparence,
                              "multiselect"
                            )}
                            alt=""
                          />
                        </div>
                      </div>
                    )}
                    {storeComment.workingHours !== 0 && (
                      <div className="store-comments__body__points__item">
                        <div className="store-comments__body__points__item__title">
                          تعهد ساعت‌کاری
                        </div>
                        <div className="store-comments__body__points__item__point">
                          <img
                            src={handleShowEmoji(
                              storeComment.workingHours,
                              "singleselect"
                            )}
                            alt=""
                          />
                        </div>
                      </div>
                    )}
                    {storeComment.kmWarranty !== 0 && (
                      <div className="store-comments__body__points__item">
                        <div className="store-comments__body__points__item__title">
                          تعهد اهدای KP
                        </div>
                        <div className="store-comments__body__points__item__point">
                          <img
                            src={handleShowEmoji(
                              storeComment.kmWarranty,
                              "singleselect"
                            )}
                            alt=""
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="store-comments__body__text">
                    {storeComment.comment?.split("\n")?.map((str) => (
                      <div>{str}</div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          ))}

          {page < lastPage && <div
            className="button"
            onClick={() => !commentLoading && setPage(prevState => prevState + 1)}>
            {commentLoading ? (
              <Dots color={"light"}/>
            ) : (
              <span>مشاهده نظرات بیشتر...</span>
            )}
          </div>}

        </div>

        {/*<NotFoundBox*/}
        {/*  title="نظری درمورد این فروشگاه یافت نشد!"*/}
        {/*  fullScreen={true}*/}
        {/*  icon="no-comments"*/}
        {/*/>*/}
      </Card>
    </Fade>
  );
};
