import { SVG } from "components";
import { farsiDigitToEnglish } from "utils/Functions";
import './input.scss';

export const Input = ({
  icon = null,
  placeholder = '',
  type = "text",
  value = "",
  onChange,
  iconColor = '',
  border = '',
}) => {
  return (
    <div
      className={`input ${iconColor !== undefined && iconColor} ${border !== undefined && border
        } `}
    >
      <SVG icon={icon} />
      <input
        style={{
          width: "100%",
          height: '100%',
          border: 'none',
          outline: 'none',
          backgroundColor: 'inherit'
        }}
        type={type}
        value={value}
        placeholder={placeholder}
        onChange={(e) => onChange(farsiDigitToEnglish(e.target.value))}
      />
    </div>
  );
};
