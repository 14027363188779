import {
    Card,
    CounterInput,
    Dots,
    Fade,
    Input,
    Modal,
    NotFoundBox,
    PageTopbar,
    SVG,
} from "components";
import { Tabs } from "containers/OrdersContainer/dependencies";
import moment from "jalali-moment";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import {
    exitClubAPI,
    getAllFavoriteStoresAPI,
    getHintAPI,
    getUserStoreAPI,
    myClubsAPI,
} from "utils";
import emptyStore from "../../assets/images/empty-shop.png";
import toast from "react-hot-toast";
import { useUser } from "../../hooks";
import QRCode from "react-qr-code";
import { farsiDigitToEnglish } from "../../utils/Functions";
import { TransferBlueKmAPI } from "../../utils/Services/Km/Blue/TransferToMobile";
import { acceptRulesAPI } from "../../utils/Services/Kartland/AcceptRules";
import { CounterBlueKm } from "components/CounterBlueKm";

export const MyCity = () => {
    const history = useHistory();
    const {
        userState: { user },
    } = useUser();
    const kmValue = useRef(null);
    const [activeTab, setActiveTab] = useState(1);
    const [myShops, setMyShops] = useState([]);
    const [myFavoriteShops, setMyFavoriteShops] = useState([]);
    const [myClubs, setMyClubs] = useState([]);
    const [ClubMessages, setClubMessages] = useState([]);
    const [loading, setLoading] = useState(true);
    const [exitModal, setExitModal] = useState(false);
    const [deletingClub, setDeletingClub] = useState(0);
    const [exitLoading, setExitLoading] = useState(false);
    const [kmAmount, setKmAmount] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [transferLoading, setTransferLoading] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [hint, setHint] = useState<any>("");
    const [readCompleted, setreadCompleted] = useState(false);
    const [acceptedRule, setAcceptedRule] = useState(false);
    const [hintLoading, setHintLoading] = useState(true);
    const [acceptRuleLoading, setAcceptRuleLoading] = useState(false);

    const handleSubmitTransfer = () => {
        setTransferLoading(true);
        TransferBlueKmAPI(phoneNumber, kmAmount)
            .then((response) => {
                if (response.status === 200) {
                    toast.success("اهدای KP با موفقیت انجام شد.");
                    history.goBack();
                    history.goBack();
                }
            })
            .catch((err) => {
                if (err.response.status === 401) {
                    toast.error("برای اهدای KP فروشگاه شما باید فعال باید");
                } else toast.error("خطا در اهدای KP");
            })
            .finally(() => {
                setTransferLoading(false);
            });
    };

    const tabs = [
        {
            title: "فروشگاه‌ من",
            icon: "",
        },
        {
            title: "علاقه‌مندی‌ها",
            icon: "",
        },
        {
            title: "باشگاه‌ها",
            icon: "",
        },
        {
            title: "کانال پیام‌ها",
            icon: "",
        },
    ];

    const getMyShops = () => {
        getUserStoreAPI()
            .then((response) => {
                setMyShops([response.data]);
            })
            .catch((err) => {})
            .finally(() => setLoading(false));
    };

    const getMessages = () => {
        getUserStoreAPI()
            .then((response) => {
                setClubMessages(response.data);
            })
            .catch((err) => {})
            .finally(() => setLoading(false));
    };

    const getMyFavoriteShops = () => {
        getAllFavoriteStoresAPI()
            .then((response) => {
                setMyFavoriteShops(response.data);
            })
            .catch((err) => {})
            .finally(() => setLoading(false));
    };

    const getMyClubs = () => {
        myClubsAPI()
            .then((response) => {
                setMyClubs(response.data);
            })
            .catch((err) => {})
            .finally(() => setLoading(false));
    };

    const exitClub = () => {
        setExitLoading(true);
        exitClubAPI(deletingClub)
            .then((response) => {
                toast.success("از باشگاه با موفقیت خارج شدید");
                getMyClubs();
                setExitModal(false);
            })
            .catch((err) => {})
            .finally(() => setExitLoading(false));
    };

    const getHint = () => {
        getHintAPI("قوانین و هشدارها 5")
            .then((response) => {
                setHint(response);
            })
            .catch(() => setHint(false))
            .finally(() => setHintLoading(false));
    };

    const acceptRules = () => {
        setAcceptRuleLoading(true);
        acceptRulesAPI()
            .then((res) => {
                if (res.status === 200) {
                    history.push("/myshop");
                }
            })
            .catch((err) => {})
            .finally(() => {});
    };

    useEffect(() => {
        switch (activeTab) {
            case 1:
                getMyShops();
                break;

            case 2:
                getMyFavoriteShops();
                break;

            case 3:
                getMyClubs();
                break;

            case 4:
                getMessages();
                break;

            default:
                break;
        }
    }, [activeTab]);

    useEffect(() => {
        history.replace("/mycity");
        getHint();
    }, []);

    useEffect(() => {
        document
            .getElementById("buyUnitAlert")
            ?.addEventListener("scroll", () => {
                document.getElementById("buyUnitAlert").scrollTop >
                document.getElementById("buyUnitAlert")?.scrollHeight -
                    document.getElementById("buyUnitAlert")?.clientHeight -
                    20
                    ? setreadCompleted(true)
                    : setreadCompleted(false);
            });
        return () => {
            document
                .getElementById("buyUnitAlert")
                ?.removeEventListener("scroll", () => {
                    document.getElementById("buyUnitAlert").scrollTop >
                    document.getElementById("buyUnitAlert")?.scrollHeight -
                        document.getElementById("buyUnitAlert")?.clientHeight -
                        20
                        ? setreadCompleted(true)
                        : setreadCompleted(false);
                });
        };
    }, [document.getElementById("buyUnitAlert")?.scrollTop]);

    return (
        <Fade>
            <PageTopbar
                showKv={false}
                title={`فروشگاه من ${
                    myShops.length > 0 ? `( ${myShops[0].name} )` : ""
                }`}
            />
            <Modal active={showAlert} close={(close) => setShowAlert(false)}>
                <div className="buy-panel__alert__title">{hint.title}</div>
                <div
                    className={`buy-panel__alert__description ${
                        readCompleted && "deactive"
                    }`}
                    id={"buyUnitAlert"}
                >
                    {hintLoading && <Dots color="dark" />}
                    {hint.content?.split("\n")?.map((str) => (
                        <div>{str}</div>
                    ))}
                </div>
                <div className="row buy-panel__alert__bottom">
                    <div
                        className={`buy-panel__alert__button  ${
                            readCompleted ? "accept" : "disabled"
                        }`}
                        onClick={() => {
                            if (readCompleted) {
                                acceptRules();
                                setAcceptedRule(true);
                                setShowAlert(false);
                            } else {
                                document
                                    .getElementById("buyUnitAlert")
                                    .scrollTo(0, 2500);
                            }
                        }}
                    >
                        قوانین را می‌پذیرم
                    </div>
                    <div
                        className="buy-panel__alert__button reject"
                        onClick={() => setShowAlert(false)}
                    >
                        انصراف
                    </div>
                </div>
            </Modal>

            <Modal active={exitModal} close={() => setExitModal(false)}>
                <div className="deleting-modal">
                    <div className="deleting-modal__title">
                        آیا از خروج از باشگاه مطمئن هستید؟
                    </div>
                    <div className="deleting-modal__actions">
                        <div
                            onClick={() => !exitLoading && exitClub()}
                            className="button red"
                        >
                            {exitLoading ? (
                                <Dots color={"light"} />
                            ) : (
                                <span>خروج از باشگاه</span>
                            )}
                        </div>
                        <div
                            onClick={() => setExitModal(false)}
                            className="button disable"
                        >
                            بازگشت
                        </div>
                    </div>
                </div>
            </Modal>
            <Card>
                <div className="wrapper-fix">
                    <div className="mycity">
                        {!loading && myShops.length === 0 && (
                            <>
                                <div
                                    className="button mb-8"
                                    onClick={() => {
                                        if (user?.detail?.suspendStore) {
                                            toast.error(
                                                "شما در گذشته فروشگاه خود را حذف کرده اید و دیگر قابلیت ساخت فروشگاه جدید را ندارید. برای اطلاعات بیشتر به پشتیبانی پیام دهید.",
                                                {
                                                    duration: 7000,
                                                }
                                            );
                                        } else {
                                            history.push("/createshop");
                                        }
                                    }}
                                >
                                    افزودن فروشگاه
                                </div>
                                {/* {
                user?.detail?.internetStore === true ? 
                <div
                  className="button mb-8"
                  onClick={() => window.open("https://adminstore.kartaak.com/", '_blank').focus()}
                >
                  ورود به پنل فروشگاه اینترنتی
                </div>
                :
                <div
                  className="button mb-8"
                  onClick={() => history.push("/create-admin-store")}
                >
                  افزودن فروشگاه اینترنتی
                </div>
              } */}
                            </>
                        )}

                        {/*<div*/}
                        {/*  className="button green mb-8"*/}
                        {/*  onClick={() => {*/}
                        {/*    if (user.isIntroducer === 0) {*/}
                        {/*      toast.error('فروشگاه معرفی شده شما توسط کارشناس رد شد! شما دیگر صلاحیت معرفی فروشگاه به کارتک را ندارید');*/}
                        {/*      return;*/}
                        {/*    }*/}
                        {/*    if (user.collaborationType === null) {*/}
                        {/*      toast.error('برای معرفی ابتدا باید نماینده کارتک باشید');*/}
                        {/*      return;*/}
                        {/*    }*/}
                        {/*    history.push("/my-introduces?waiting");*/}
                        {/*  }}*/}
                        {/*>*/}
                        {/*  معرفی فروشگاه به کارتک*/}
                        {/*</div>*/}

                        {/*<Tabs*/}
                        {/*  items={tabs}*/}
                        {/*  activeTab={activeTab}*/}
                        {/*  setActiveTab={(tab) => {*/}
                        {/*    setActiveTab(tab);*/}
                        {/*    setLoading(true);*/}
                        {/*  }}*/}
                        {/*/>*/}
                    </div>
                    {loading ? (
                        <Dots color="dark" />
                    ) : (
                        <React.Fragment>
                            {activeTab === 1 && (
                                <Fade>
                                    {myShops.length === 0 ? (
                                        <div style={{ padding: "200px 0" }}>
                                            <NotFoundBox
                                                title={
                                                    user?.detail
                                                        ?.internetStore !==
                                                        true &&
                                                    user?.detail
                                                        ?.presenceStore !== true
                                                        ? "در حال حاضر فروشگاهی برای خود ثبت نکرده اید."
                                                        : user?.detail
                                                              ?.internetStore ===
                                                          true
                                                        ? "شما از قبل فروشگاه اینترنتی دارید. هم اکنون می توانید فروشگاه حضوری خود را نیز ایجاد کنید."
                                                        : ""
                                                }
                                                icon="shop-3-head"
                                            />
                                        </div>
                                    ) : (
                                        <Fragment>
                                            {myShops.map((myShop, index) => (
                                                <Fragment>
                                                    <div
                                                        onClick={() => {
                                                            if (myShop.rules) {
                                                                history.push(
                                                                    "/myshop"
                                                                );
                                                            } else {
                                                                history.push(
                                                                    "/shop-info?edit=true"
                                                                );
                                                            }
                                                        }}
                                                        className="mycity__shop"
                                                    >
                                                        <div className="col j-c a-c h-100">
                                                            <div className="mycity__shop__image">
                                                                <img
                                                                    src={
                                                                        myShop
                                                                            ?.picturestore[0]
                                                                            ?.photo ===
                                                                        false
                                                                            ? emptyStore
                                                                            : myShop
                                                                                  ?.picturestore[0]
                                                                                  ?.photo
                                                                    }
                                                                    alt=""
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="mycity__shop__info">
                                                            <div className="col">
                                                                <div className="mycity__shop__title">
                                                                    {`پنل مدیریتی فروشگاه حضوری`}
                                                                </div>
                                                            </div>
                                                            <div className="flex ml-6">
                                                                <SVG
                                                                    svg={
                                                                        "arrow"
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div
                                                        onClick={() => {
                                                            if (
                                                                user?.detail
                                                                    ?.internetStore
                                                            ) {
                                                                window
                                                                    .open(
                                                                        "https://adminstore.kartaak.com/",
                                                                        "_blank"
                                                                    )
                                                                    .focus();
                                                            } else {
                                                                history.push(
                                                                    `/create-admin-store`
                                                                );
                                                            }
                                                        }}
                                                        className="mycity__shop"
                                                    >
                                                        <div className="col j-c a-c h-100">
                                                            <div className="mycity__shop__image">
                                                                <SVG
                                                                    svg={
                                                                        "create-shop"
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="mycity__shop__info">
                                                            <div className="col">
                                                                <div className="mycity__shop__title">
                                                                    {`پنل مدیریتی فروشگاه اینترنتی`}
                                                                </div>
                                                            </div>
                                                            <div className="flex ml-6">
                                                                <SVG
                                                                    svg={
                                                                        "arrow"
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div
                                                        onClick={() => {
                                                            history.push(
                                                                "/myshop/club/messages"
                                                            );
                                                        }}
                                                        className="mycity__shop"
                                                    >
                                                        <div className="col j-c a-c h-100">
                                                            <div className="mycity__shop__image">
                                                                <SVG
                                                                    svg={
                                                                        "myshop-club"
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="mycity__shop__info">
                                                            <div className="col">
                                                                <div className="mycity__shop__title">
                                                                    {`باشگاه مشتریان`}
                                                                </div>
                                                            </div>
                                                            <div className="flex ml-6">
                                                                <SVG
                                                                    svg={
                                                                        "arrow"
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div
                                                        onClick={() => {
                                                            history.push(
                                                                "/myshop/trove"
                                                            );
                                                        }}
                                                        className="mycity__shop"
                                                    >
                                                        <div className="col j-c a-c h-100">
                                                            <div className="mycity__shop__image">
                                                                <SVG
                                                                    svg={
                                                                        "Treasure-color"
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="mycity__shop__info">
                                                            <div className="col">
                                                                <div className="mycity__shop__title">
                                                                    {`گنجینه 150581`}
                                                                </div>
                                                            </div>
                                                            <div className="flex ml-6">
                                                                <SVG
                                                                    svg={
                                                                        "arrow"
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div
                                                        onClick={() => {
                                                            history.push(
                                                                "/myshop/club"
                                                            );
                                                        }}
                                                        className="mycity__shop"
                                                    >
                                                        <div className="col j-c a-c h-100">
                                                            <div className="mycity__shop__image">
                                                                <SVG
                                                                    svg={
                                                                        "store-qr"
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="mycity__shop__info">
                                                            <div className="col">
                                                                <div className="mycity__shop__title">
                                                                    {`بارکد اختصاصی`}
                                                                </div>
                                                            </div>
                                                            <div className="flex ml-6">
                                                                <SVG
                                                                    svg={
                                                                        "arrow"
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Fragment>
                                            ))}
                                        </Fragment>
                                    )}

                                    {/* {myShops.length > 0 && !!myShops[0].activeByUser && !!myShops[0].isActive &&
                    <div className="myshop-club__qr2">
                      <div className="title">بارکد فروشگاه</div>
                      <QRCode
                        id="QRCode"
                        value={`https://app.kartaak.com/qr/club/${myShops[0].id}`}
                        size={160}
                        bgColor={'#ffbaba00'}
                      />
                    </div>} */}

                                    {/* {myShops.length > 0 && !!myShops[0].activeByUser && !!myShops[0].isActive && !!myShops[0].haveKm &&

                    <div className="myshop-club__donate"> */}
                                    {/*<Input*/}
                                    {/*  value={kmAmount}*/}
                                    {/*  onChange={value => setKmAmount(farsiDigitToEnglish(value))}*/}
                                    {/*/>*/}

                                    {/* <div className="title">اهدای KP</div>
                      <Input
                        value={phoneNumber}
                        placeholder="شماره تلفن یا کد کارتک را وارد کنید"
                        onChange={value => setPhoneNumber(farsiDigitToEnglish(value))}
                      /> */}

                                    {/*<div className="title">تعداد KM</div>*/}
                                    {/* <div className="myshop-club__donate__action">
                        <CounterBlueKm
                          ref={kmValue}
                          defaultValue={1}
                          step={1}
                          max={user.blueKm}
                          onChange={(value) => setKmAmount(value)}
                          kmMode={true}
                        />
                      </div> */}

                                    {/* <div onClick={() => !transferLoading && phoneNumber.length !== 0 && handleSubmitTransfer()}
                          className="button green">
                        {transferLoading ? (
                          <Dots color="light"/>
                        ) : (
                          <>
                            <span>تائید</span> */}
                                    {/*<SVG svg="km-donate"/>*/}
                                    {/* </>
                        )}
                      </div> */}
                                    {/*<div className="km-donate__actions">*/}
                                    {/*  <div className="km-donate__actions__qr"*/}
                                    {/*       onClick={() => !transferLoading && phoneNumber.length !== 0 && handleSubmitTransfer()}>*/}
                                    {/*    {transferLoading ? (*/}
                                    {/*      <Dots color="light"/>*/}
                                    {/*    ) : (*/}
                                    {/*      <>*/}
                                    {/*        <span>انتقال</span>*/}
                                    {/*        <SVG svg="km-donate"/>*/}
                                    {/*      </>*/}
                                    {/*    )}*/}
                                    {/*  </div>*/}
                                    {/*</div>*/}
                                    {/* </div>
                  } */}
                                </Fade>
                            )}

                            {activeTab === 2 && (
                                <Fade>
                                    {myFavoriteShops.length === 0 ? (
                                        <div style={{ padding: "200px 0" }}>
                                            <NotFoundBox
                                                title="فروشگاهی در علاقه‌مندی‌ها یافت نشد"
                                                icon=""
                                            />
                                        </div>
                                    ) : (
                                        myFavoriteShops.map(
                                            (favoriteShop, index) => (
                                                <Link
                                                    to={`/seller/${favoriteShop?.favorite}`}
                                                    className="mycity__shop"
                                                    key={index}
                                                >
                                                    <div className="">
                                                        <div className="mycity__shop__image">
                                                            <img
                                                                src={
                                                                    favoriteShop
                                                                        .kartstore
                                                                        ?.picture
                                                                }
                                                                alt=""
                                                                loading="lazy"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="mycity__shop__info">
                                                        <div className="col">
                                                            <div className="mycity__shop__title">
                                                                {
                                                                    favoriteShop
                                                                        .kartstore
                                                                        ?.name
                                                                }
                                                            </div>
                                                            <div className="mycity__shop__address">
                                                                {
                                                                    favoriteShop
                                                                        .kartstore
                                                                        ?.address
                                                                }
                                                            </div>
                                                        </div>

                                                        <div className="mycity__shop__date">
                                                            {moment(
                                                                favoriteShop.created_at,
                                                                "YYYY/MM/DD"
                                                            )
                                                                .locale("fa")
                                                                .format(
                                                                    "YYYY/MM/DD"
                                                                )}
                                                        </div>
                                                    </div>
                                                </Link>
                                            )
                                        )
                                    )}
                                </Fade>
                            )}

                            {activeTab === 3 && (
                                <Fade>
                                    {myClubs.length === 0 ? (
                                        <div style={{ padding: "200px 0" }}>
                                            <NotFoundBox
                                                title="فروشگاهی در باشگاه یافت نشد"
                                                icon=""
                                            />
                                        </div>
                                    ) : (
                                        myClubs.map((club, index) => (
                                            <div
                                                className="mycity__shop"
                                                key={index}
                                            >
                                                <div
                                                    onClick={() =>
                                                        history.push(
                                                            `/seller/${club?.kartlandStore_id}`
                                                        )
                                                    }
                                                    className="mycity__shop__image"
                                                >
                                                    <img
                                                        src={
                                                            club.storeinfo
                                                                ?.picture[0]
                                                                ?.photo
                                                        }
                                                        alt=""
                                                        loading="lazy"
                                                    />
                                                </div>

                                                <div
                                                    onClick={() =>
                                                        history.push(
                                                            `/seller/${club?.kartlandStore_id}`
                                                        )
                                                    }
                                                    className="mycity__shop__info"
                                                >
                                                    <div className="col">
                                                        <div className="mycity__shop__title">
                                                            {
                                                                club.storeinfo
                                                                    ?.name
                                                            }
                                                        </div>
                                                        <div className="mycity__shop__address">
                                                            {
                                                                club.storeinfo
                                                                    ?.address
                                                            }
                                                        </div>
                                                    </div>

                                                    <div className="mycity__shop__date">
                                                        {moment(
                                                            club.registerTime,
                                                            "YYYY/MM/DD"
                                                        )
                                                            .locale("fa")
                                                            .format(
                                                                "YYYY/MM/DD"
                                                            )}
                                                    </div>
                                                </div>

                                                <div className="mycity__shop__exit">
                                                    <div
                                                        onClick={() => {
                                                            setExitModal(true);
                                                            setDeletingClub(
                                                                club.id
                                                            );
                                                        }}
                                                    >
                                                        <SVG svg={"exit"} />
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    )}
                                </Fade>
                            )}

                            {activeTab === 4 && <Fade></Fade>}
                        </React.Fragment>
                    )}
                </div>
            </Card>
        </Fade>
    );
};
