import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import "mapbox-gl/dist/mapbox-gl.css";
import { SVG, Modal, CircleHint } from "components";
import { Link, useHistory, useLocation } from "react-router-dom";
import {
    getNearest,
    getNearestIntroStore,
    unseenMessagesCountAPI,
} from "../../utils";
import dinner from "assets/images/dinner.png";
import { Search } from "./dependencies";
import {
    getFirstCategoryAPI,
    getSecondCategoryAPI,
    getThirdCategoryAPI,
    getZeroCategoryAPI,
    getHintAPI,
} from "../../utils";
import { cloneDeep } from "lodash";
import { DiscountBadge, Dots } from "../../components";
import moment from "jalali-moment";
import { hexToHSL, lightOrDark } from "utils/Functions";
import toast from "react-hot-toast";
import { useUser } from "hooks";
import submitter from "../../assets/images/badge.png";
import axios from "axios";

mapboxgl.accessToken =
    "pk.eyJ1Ijoia2FydGFhayIsImEiOiJjbDNsaGw4NDIwY3R1M2VwNXAxZXNhemltIn0.oG3Q1VAF7wcyaUUvSCKxVw";

try {
    mapboxgl.setRTLTextPlugin(
        "https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-rtl-text/v0.2.3/mapbox-gl-rtl-text.js",
        null,
        true // Lazy load the plugin
    );
} catch (error) {}

var currMarkers = [];
var currIntroMarkers = [];

export const MapContainer = () => {
    const history = useHistory();
    const location = useLocation();
    const mapContainer = useRef(null);
    const map = useRef(null);
    const query = useQuery();
    const {
        userState: { user },
    } = useUser();
    const shouldGoToLastPosition = () => {
        if (query.get("lat") !== null && query.get("lng") !== null) {
            return true;
        } else {
            return false;
        }
    };
    const [lng, setLng] = useState(
        shouldGoToLastPosition() ? Number(query.get("lng")) : 51.650464890284674
    );
    const [lat, setLat] = useState(
        shouldGoToLastPosition() ? Number(query.get("lat")) : 32.67509833881112
    );
    const [zoom, setZoom] = useState(
        query.get("zoom") !== null ? query.get("zoom") : 14.5
    );

    const [current, setCurrent] = useState({});
    const [activePopup, setActivePopup] = useState({ status: false, data: [] });
    const [activePopupIntroShop, setActivePopupIntroShop] = useState({
        status: false,
        data: [],
    });
    const [filterModal, setFilterModal] = useState(false);
    const [markers, setMarkers] = useState([]);
    const [awaitingApprovalMarker, setAwaitingApprovalMarker] = useState([]);
    const [adminStoreMarker, setAdminStoreMarker] = useState([]);
    const [shopList, setShopList] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const [toggleSearch, setToggleSearch] = useState(false);
    const [zeroCategories, setZeroCategories] = useState({
        data: [],
        active: false,
    });
    const [firstCategories, setFirstCategories] = useState({
        data: [],
        active: false,
    });
    const [secondCategories, setSecondCategories] = useState({
        data: [],
        active: false,
    });
    const [thirdCategories, setThirdCategories] = useState({
        data: [],
        active: false,
    });
    const [activeZeroCategories, setActiveZeroCategories] = useState(null);
    const [activeFirstCategories, setActiveFirstCategories] = useState(null);
    const [activeSecondCategories, setActiveSecondCategories] = useState(null);
    const [activeThirdCategories, setActiveThirdCategories] = useState(null);
    const [categoriesLoading, setCategoriesLoading] = useState(false);
    const [showFirstCatMenu, setShowFirstCatMenu] = useState(false);
    const [showHint, setShowHint] = useState(false);
    const [hint, setHint] = useState({});
    const [activeCategory, setActiveCategory] = useState({
        level: -1,
        title: "",
        id: "",
    });
    const [showFilter, setShowFilter] = useState(false);
    const [locationError, setLocationError] = useState(false);
    const [showMapElements, setShowMapElements] = useState(true);
    const [isOpen, setIsOpen] = useState(false);
    const [today, setToday] = useState(false);
    const [windowHeight, setWindowHeight] = useState(0);
    const now = moment();
    const day = now.day();
    const hour = now.hours();
    const min = now.minutes();
    const [introducedStoresLoaded, setIntroducedStoresLoaded] = useState(true);
    const [hasTime, setHasTime] = useState(true);
    // const [unseenMessages, setUnseenMessages] = useState(0);
    const [submitterMode, setSubmitterMode] = useState(false);
    const [submitterLoadnig, setSubmitterLoadnig] = useState(false);
    const [storesLoading, setStoresLoading] = useState(false);

    console.log(zeroCategories);
    console.log(firstCategories);
    console.log(secondCategories);
    console.log(thirdCategories);

    const getZeroCategory = () => {
        getZeroCategoryAPI().then((res) => {
            let currCat = cloneDeep(zeroCategories);
            currCat.data = res.data;
            setZeroCategories(currCat);
        });
    };

    const getFirstCategory = (id) => {
        console.log(id);
        setCategoriesLoading(true);
        getFirstCategoryAPI(id).then((res) => {
            console.log(res);
            let currCat = cloneDeep(firstCategories);
            currCat.data = res.data;
            setFirstCategories(currCat);
            setCategoriesLoading(false);
        });
    };

    const getSecondCategory = (id) => {
        setCategoriesLoading(true);
        getSecondCategoryAPI(id).then((res) => {
            let currCat = cloneDeep(secondCategories);
            currCat.data = res.data;
            setSecondCategories(currCat);
            setCategoriesLoading(false);
        });
    };

    const getThirdCategory = (id) => {
        setCategoriesLoading(true);
        getThirdCategoryAPI(id).then((res) => {
            let currCat = cloneDeep(thirdCategories);
            currCat.data = res.data;
            setThirdCategories(currCat);
            setCategoriesLoading(false);
        });
    };

    // geolocate.on('geolocate', function (e) {
    //   map.current.setZoom(17);
    // });

    const goToUserLocation = () => {
        try {
            navigator.geolocation.getCurrentPosition(
                (res) => {
                    console.log(res);
                    setLat(res.coords.latitude);
                    setLng(res.coords.longitude);
                    // map.current.flyTo({
                    //   center: [parseFloat(res.coords.longitude), parseFloat(res.coords.latitude) + 0.0006],
                    //   zoom: 17,
                    //   speed: 4
                    // });
                },
                (err) => {
                    // err.code === 1 &&
                    setLocationError(true);
                }
            );
            const el = document.getElementsByClassName(
                "mapboxgl-ctrl-geolocate"
            );
            el && el[0].click();
        } catch (error) {}
    };

    const renderMarker = () => {
        markers.map((item) => {
            const elMarker = document.createElement("div");
            elMarker.id = item.id;
            elMarker.style.maxHeight = "42px";
            ReactDOM.render(
                item?.reports?.count > 0 ? (
                    <div className="map__report">
                        <SVG svg="map-report" fillColor={"#db2c2c"} />
                    </div>
                ) : item.haveKm ? (
                    <SVG
                        svg="map-marker"
                        fillColor={
                            item.zeroCategoryName
                                ? item.zeroCategoryName.color
                                : "#db2c2c"
                        }
                    />
                ) : (
                    <SVG
                        svg={"map-gray-marker"}
                        fillColor={
                            item.zeroCategoryName
                                ? item.zeroCategoryName.color
                                : "#db2c2c"
                        }
                    />
                ),
                elMarker
            );
            const newMarker = new mapboxgl.Marker(elMarker, {
                anchor: "bottom",
                offset: [0, 4],
            })
                .setLngLat([parseFloat(item.long), parseFloat(item.lat)])
                .addTo(map.current);

            newMarker.getElement().addEventListener("click", (e) => {
                const centerLatLng = map.current.getCenter();
                const currZoom = map.current.getZoom();
                history.replace(
                    `/map?lat=${centerLatLng.lat}&lng=${centerLatLng.lng}&zoom=${currZoom}`
                );
                map.current.flyTo({
                    center: [
                        parseFloat(item.long),
                        parseFloat(item.lat) + 0.0006,
                    ],
                    zoom: 17,
                    speed: 4,
                });
                setTimeout(() => {
                    //TODO
                    setActivePopup({
                        status: true,
                        data: {
                            id: item.id,
                            name: item.name,
                            status: item.status,
                            haveKm: item.haveKm,
                            address: item.address,
                            img: !item.hasOwnProperty("picturestores")
                                ? item.photo
                                : item?.picturestores[0].photo,
                            isIntroduced: !item.hasOwnProperty("picturestores")
                                ? true
                                : false,
                            hours: item.workinghours,
                            discount: item.discountAmount,
                            report: item.hasOwnProperty("reports")
                                ? item.reports?.count > 0 && true
                                : false,
                        },
                    });
                }, 200);
            });
            currMarkers.push(newMarker);
        });
        // const currMarkersCopy = cloneDeep(currMarkers);
        // currMarkersCopy.push(newMarker);
        // setCurrMarkers(currMarkersCopy);
    };

    const renderAwaitingApprovalMarker = () => {
        awaitingApprovalMarker.map((item) => {
            let arr = [];
            const ellMarker = document.createElement("div");
            ellMarker.id = item.store.id;
            ellMarker.style.maxHeight = "42px";
            ReactDOM.render(<SVG svg="introduced-store" />, ellMarker);
            const introMarker = new mapboxgl.Marker(ellMarker, {
                anchor: "bottom",
                offset: [0, 4],
            })
                .setLngLat([
                    parseFloat(item.store.lng),
                    parseFloat(item.store.lat),
                ])
                .addTo(map.current);
            // .getElement()
            introMarker.getElement().addEventListener("click", (e) => {
                const centerLatLng = map.current.getCenter();
                const currZoom = map.current.getZoom();
                history.replace(
                    `/map?lat=${centerLatLng.lat}&lng=${centerLatLng.lng}&zoom=${currZoom}`
                );
                map.current.flyTo({
                    center: [
                        parseFloat(item.store.lng),
                        parseFloat(item.store.lat) + 0.0006,
                    ],
                    zoom: 17,
                    speed: 4,
                });
                setTimeout(() => {
                    setActivePopupIntroShop({
                        status: true,
                        data: {
                            id: item.store.id,
                            address: item.store.address,
                            name: item.store.name,
                            isNear: item.isNearest,
                            isAdmin: false,
                        },
                    });
                }, 200);
            });
            currIntroMarkers.push(introMarker);
        });
    };

    const renderAdminStoreMarker = () => {
        adminStoreMarker.map((item) => {
            let arr = [];
            const ellMarker = document.createElement("div");
            ellMarker.id = item.id;
            ellMarker.style.maxHeight = "42px";
            ReactDOM.render(<SVG svg="admin-store-marker" />, ellMarker);
            const introMarker = new mapboxgl.Marker(ellMarker, {
                anchor: "bottom",
                offset: [0, 4],
            })
                .setLngLat([parseFloat(item.lng), parseFloat(item.lat)])
                .addTo(map.current);
            // .getElement()
            introMarker.getElement().addEventListener("click", (e) => {
                const centerLatLng = map.current.getCenter();
                const currZoom = map.current.getZoom();
                history.replace(
                    `/map?lat=${centerLatLng.lat}&lng=${centerLatLng.lng}&zoom=${currZoom}`
                );
                map.current.flyTo({
                    center: [
                        parseFloat(item.lng),
                        parseFloat(item.lat) + 0.0006,
                    ],
                    zoom: 17,
                    speed: 4,
                });
                setTimeout(() => {
                    setActivePopupIntroShop({
                        status: true,
                        data: {
                            id: item.id,
                            address: item.address,
                            name: item.name,
                            isAdmin: true,
                        },
                    });
                }, 200);
            });
            currIntroMarkers.push(introMarker);
        });
    };

    const todayOfWeek = (day) => {
        switch (day) {
            case 0:
                return 1;
            case 1:
                return 2;
            case 2:
                return 3;
            case 3:
                return 4;
            case 4:
                return 5;
            case 5:
                return 6;
            case 6:
                return 0;
        }
    };

    const checkOpen = (store) => {
        if (store.hasOwnProperty("hours") && store.hours != undefined) {
            setHasTime(true);
            store.hours.map((time) => {
                let openTime = time.hours.split(",")[0];
                let closeTime = time.hours.split(",")[1];
                // console.log(openTime.split(':')[0],openTime.split(':')[0] <= 2);

                if (
                    openTime.split(":")[0] < hour &&
                    closeTime.split(":")[0] > hour
                ) {
                    setIsOpen(true);
                    if (todayOfWeek(day) === time.daysOfWeek) {
                        setToday(true);
                    }
                } else if (
                    openTime.split(":")[0] == hour ||
                    closeTime.split(":")[0] == hour
                ) {
                    if (
                        openTime.split(":")[0] == hour &&
                        closeTime.split(":")[0] > hour
                    ) {
                        if (openTime.split(":")[1] <= min) {
                            setIsOpen(true);
                            if (todayOfWeek(day) === time.daysOfWeek) {
                                setToday(true);
                            }
                        }
                    }

                    if (
                        openTime.split(":")[0] < hour &&
                        closeTime.split(":")[0] == hour
                    ) {
                        if (closeTime.split(":")[1] >= min) {
                            setIsOpen(true);
                            if (todayOfWeek(day) === time.daysOfWeek) {
                                setToday(true);
                            }
                        }
                    }

                    if (
                        openTime.split(":")[0] == hour &&
                        closeTime.split(":")[0] == hour
                    ) {
                        if (
                            openTime.split(":")[1] <= min &&
                            closeTime.split(":")[1] >= min
                        ) {
                            setIsOpen(true);
                            if (todayOfWeek(day) === time.daysOfWeek) {
                                setToday(true);
                            }
                        }
                    }
                }
            });
        } else {
            setHasTime(false);
        }
    };

    const zeroCatClickHandler = (id) => {
        let currCat = cloneDeep(zeroCategories);
        currCat.active = true;
        setZeroCategories(currCat);
        const item = currCat.data.find((x) => x.id === id);
        setActiveZeroCategories(item);
        setActiveThirdCategories(null);
        setActiveSecondCategories(null);
        setActiveFirstCategories(null);
        getFirstCategory(id);
    };

    const firstCatClickHandler = (id) => {
        let currCat = cloneDeep(firstCategories);
        currCat.active = true;
        setFirstCategories(currCat);
        const item = currCat.data.find((x) => x.id === id);
        setActiveFirstCategories(item);
        setActiveThirdCategories(null);
        setActiveSecondCategories(null);
        getSecondCategory(id);
        setActiveCategory({
            level: 1,
            title: item.title,
            id: item.id,
        });
    };

    const secondCatClickHandler = (id) => {
        let currCat = cloneDeep(secondCategories);
        currCat.active = true;
        setSecondCategories(currCat);
        const item = currCat.data.find((x) => x.id === id);
        setActiveSecondCategories(item);
        getThirdCategory(id);
        setActiveThirdCategories(null);
        setActiveCategory({
            level: 2,
            title: item.title,
            id: item.id,
        });
    };

    const thirdCatClickHandler = (id) => {
        let currCat = cloneDeep(thirdCategories);
        currCat.active = true;
        setThirdCategories(currCat);
        const item = currCat.data.find((x) => x.id === id);
        if (
            activeThirdCategories !== null &&
            activeThirdCategories.id === item.id
        ) {
            setActiveThirdCategories(null);
        } else {
            setActiveThirdCategories(item);
        }
        setActiveCategory({
            level: 3,
            title: item.title,
            id: item.id,
        });
    };

    const activeZeroClickHandler = () => {
        let currZeroCat = cloneDeep(zeroCategories);
        currZeroCat.active = false;
        setZeroCategories(currZeroCat);
        setActiveZeroCategories(null);
        setActiveThirdCategories(null);
        setActiveSecondCategories(null);
        setActiveFirstCategories(null);
        setActiveCategory({ level: -1 });
    };

    const activeFirstClickHandler = () => {
        let currFirstCat = cloneDeep(firstCategories);
        currFirstCat.active = false;
        setFirstCategories(currFirstCat);
        setActiveThirdCategories(null);
        setActiveSecondCategories(null);
        setActiveFirstCategories(null);
        setActiveCategory({
            level: 0,
            title: activeZeroCategories.title,
            id: activeZeroCategories.id,
        });
    };

    const activeSecondClickHandler = () => {
        let currSecondCat = cloneDeep(secondCategories);
        currSecondCat.active = false;
        setSecondCategories(currSecondCat);
        setActiveThirdCategories(null);
        setActiveSecondCategories(null);
        setActiveCategory({
            level: 1,
            title: activeFirstCategories.title,
            id: activeFirstCategories.id,
        });
    };

    const activeThirdClickHandler = () => {
        let currThirdCat = cloneDeep(thirdCategories);
        currThirdCat.active = false;
        setThirdCategories(currThirdCat);
        setActiveCategory(activeSecondCategories);
        setActiveCategory({
            level: 2,
            title: activeSecondCategories.title,
            id: activeSecondCategories.id,
        });
    };

    const handleStoreStatus = (value) => {
        switch (value) {
            case 0:
                return "بسته است";
            case 1:
                return "باز است";
            default:
                break;
        }
    };

    const Popup = (data = activePopup.data) => (
        <div
            className="map__popup"
            onClick={() =>
                data.isIntroduced
                    ? history.push(`/introducred-stores/${data.id}`)
                    : history.push(`/seller/${data.id}`)
            }
        >
            {/* {console.log(data.img.photo)} */}
            <div className="map__popup__img">
                {data.discount && (
                    <div className="map__popup__img__badge">
                        <DiscountBadge discount={data.discount} />
                    </div>
                )}
                <img src={data.img ? data.img : dinner} alt={"store-pic"} />
            </div>
            {data.haveKm && (
                <div className="map__popup__km">
                    <span>{"KM دارد"}</span>
                </div>
            )}
            <span className="map__popup__title">{data.name}</span>
            <div className="map__popup__status">
                {hasTime ? (
                    <>
                        <SVG svg="map-time" />
                        <span>{isOpen && today ? "باز است" : "بسته است"}</span>
                    </>
                ) : null}
            </div>
            <div className="map__popup__address">
                <SVG svg="map-address" />
                <span>
                    {data.address
                        ? data.address
                        : "اطلاعاتی برای نمایش وجود ندارد"}
                </span>
            </div>
        </div>
    );

    const PopupIntroShop = (data = activePopupIntroShop.data) => (
        <div
            className="map__popup-intro"
            onClick={() =>
                data.isAdmin
                    ? history.push(`/confirm-store-admin/${data.id}`)
                    : data.isNear
                    ? history.push(`/validateintroduceshop/${data.id}`)
                    : toast.error("این فروشگاه در محدوده دید شما نیست")
            }
        >
            {/* {console.log(data.img.photo)} */}

            <span className="map__popup-intro__title">{data.name}</span>

            <div className="map__popup-intro__address">
                <SVG svg="map-address" />
                <span>
                    {data.address
                        ? data.address
                        : "اطلاعاتی برای نمایش وجود ندارد"}
                </span>
            </div>
        </div>
    );

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    const getNearestLocation = (type) => {
        let query = "";
        if (activeZeroCategories !== null) {
            query += `&zeroCategory=${activeZeroCategories.id}`;
            if (activeFirstCategories !== null) {
                query += `&firstCategory=${activeFirstCategories.id}`;
                if (activeSecondCategories !== null) {
                    query += `&secondCategory=${activeSecondCategories.id}`;
                    if (activeThirdCategories !== null) {
                        query += `&thirdCategory=${activeThirdCategories.id}`;
                    }
                }
            }
        }

        !submitterLoadnig &&
            getNearest(user.id ? user.id : "", current.lat, current.lng, query)
                .then((res) => {
                    if (res) {
                        setMarkers(res.data);
                        if (
                            activeZeroCategories !== null &&
                            type === "category"
                        ) {
                            setShopList(res.data);
                        } else {
                            if (type === "category") setShopList([]);
                        }
                    }
                })
                .finally(() => {
                    setStoresLoading(false);
                });
    };

    const getNearestIntroStoreLocation = () => {
        setSubmitterLoadnig(true);
        !storesLoading &&
            getNearestIntroStore(lat, lng)
                .then((res) => {
                    if (res) {
                        setAwaitingApprovalMarker(res.data.store);
                        setAdminStoreMarker(res.data.adminStore);
                        setIntroducedStoresLoaded(false);
                        setSubmitterMode(true);
                        setMarkers([]);
                    }
                })
                .catch(() => {})
                .finally(() => {
                    setSubmitterLoadnig(false);
                });
    };

    // const getHint = () => {
    //     getHintAPI("راهنمای کلی 5")
    //         .then((response) => {
    //             setHint(response);
    //         })
    //         .catch(() => setHint(false))
    //         .finally(() => {});
    // };

    const handleShowColor = (color) => {
        const hslColor = hexToHSL(color);
        return `hsl(${hslColor.h}, ${hslColor.s}%, ${
            lightOrDark(color) === "light" ? 15 : 100
        }%)`;
    };

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }

    // const newMessageCount = () => {
    //   unseenMessagesCountAPI().then(res => {
    //     if (res.status === 200) {
    //       setUnseenMessages(res.data);
    //     }
    //   }).catch(() => {

    //   }).finally(() => {
    //   });
    // };

    useEffect(() => {
        if (map.current) return; // initialize map only once

        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: "mapbox://styles/kartaak/cl37c9d8c001y14la74hqn2ze",
            center: [lng, lat],
            zoom: zoom,
            minZoom: 4.5,
            maxZoom: 18.5,
            localFontFamily: "VazirFaNum",
            localIdeographFontFamily: "VazirFaNum",
        });

        // map.current.on("move", () => {
        //   setLng(map.current.getCenter().lng.toFixed(6));
        //   setLat(map.current.getCenter().lat.toFixed(6));
        //   setZoom(map.current.getZoom().toFixed(2));
        // });

        const geolocate = new mapboxgl.GeolocateControl({
            positionOptions: {
                enableHighAccuracy: true,
            },
            setZoom: 15.2,
            fitBoundsOptions: { maxZoom: 15.2 },
            trackUserLocation: false,
            // showUserHeading: true,
        });

        map.current.addControl(geolocate);
        map.current.on("dragstart", () => {
            setActivePopup({ status: false, data: [] });
            setActivePopupIntroShop({ status: false, data: [] });
            setIsOpen(false);
            setToday(false);
            history.replace("/map");
        });

        map.current.setMaxBounds([
            [44.032434, 25.057032],
            [63.33335, 39.78205],
        ]);

        map.current.on("load", function () {
            map.current.addLayer(
                {
                    id: "country-boundaries",
                    source: {
                        type: "vector",
                        url: "mapbox://mapbox.country-boundaries-v1",
                    },
                    "source-layer": "country_boundaries",
                    type: "fill",
                    paint: {
                        "fill-color": "#efe9e1",
                        "fill-opacity": 0.9,
                    },
                },
                "country-label"
            );

            map.current.setFilter("country-boundaries", [
                "in",
                "iso_3166_1_alpha_3",
                "PAK",
                "IRQ",
                "AFG",
                "KWT",
                "TKM",
                "QAT",
                "TUR",
                "SAU",
                "AZE",
                "ARE",
                "ARM",
            ]);
        });

        setCurrent(map.current.getCenter());

        map.current.on("moveend", () => {
            const centerLatLng = map.current.getCenter();
            const currZoom = map.current.getZoom();
            // history.replace(
            //   `/map?lat=${centerLatLng.lat}&lng=${centerLatLng.lng}&zoom=${currZoom}`
            // );
            setCurrent(centerLatLng);
        });
    });

    // useEffect(() => {
    //   console.log('salam');
    //   if (
    //     document.getElementsByClassName("mapboxgl-ctrl-geolocate")[0] !==
    //     undefined
    //   )
    //     goToUserLocation();
    // }, [document.getElementsByClassName("mapboxgl-ctrl-geolocate")[0]]);

    useEffect(() => {
        if (current.lat !== undefined && submitterMode === false) {
            getNearestLocation("category");
        }
    }, [
        activeZeroCategories,
        activeFirstCategories,
        activeSecondCategories,
        activeThirdCategories,
    ]);

    useEffect(() => {
        if (current.lat !== undefined && submitterMode === false) {
            getNearestLocation("location");
        }
        // if (
        //   current.lat !== undefined &&
        //   user.storeSubmiter === 1
        // ) {
        //   getNearestIntroStoreLocation();
        // }
    }, [current, submitterMode]);

    useEffect(() => {
        if (activePopup.status !== false) {
            checkOpen(activePopup.data);
        }
    }, [activePopup]);

    useEffect(() => {
        getZeroCategory();
        history.action !== "POP" && goToUserLocation();
        history.replace(`/map${location.search}`);
        // newMessageCount();
    }, []);

    useEffect(() => {
        setWindowHeight(window.innerHeight);
        // console.log(window.innerHeight);
    }, [window.innerHeight]);

    useEffect(() => {
        // console.log("marker", currMarkers);
        currMarkers.forEach((marker) => {
            marker.remove();
        });
        // map.current._markers.forEach((marker) => {
        //   if (marker?._element?.id !== "") marker.remove();
        // });
        renderMarker();
        // renderAwaitingApprovalMarker();
    }, [markers]);

    useEffect(() => {
        if (submitterMode === false) {
            currIntroMarkers.forEach((marker) => {
                marker.remove();
            });
        }
        renderAwaitingApprovalMarker();
        renderAdminStoreMarker();
    }, [submitterMode]);

    useEffect(() => {
        // currIntroMarkers.forEach((marker) => {
        //   marker.remove();
        // });

        renderAwaitingApprovalMarker();
    }, [awaitingApprovalMarker]);

    useEffect(() => {
        // currIntroMarkers.forEach((marker) => {
        //   marker.remove();
        // });

        renderAdminStoreMarker();
    }, [adminStoreMarker]);

    // console.log(currMarkers);
    // console.log(currIntroMarkers);

    return (
        <div
            className="map__section"
            style={{
                width: "100%",
                // height: window.innerHeight,
                overflow: "hidden",
            }}
        >
            {/* <Modal hint="price" active={showHint} close={() => setShowHint(false)}>
        <div className="hints__title">{hint.title}++</div>
        <div className="hints__content">
          {hint.content?.split("\n")?.map((str) => (
            <div>{str}</div>
          ))}
        </div>
      </Modal> */}

            {/* <div className={`MainHintMapContainer ${showHint && "MainHintMapContainer--open"}`}>
        <div className="hints__title">{hint.title}++</div>
        <div className="hints__content">
          {hint.content?.split("\n")?.map((str) => (
            <div>{str}</div>
          ))}
        </div>
      </div> */}

            <Modal active={filterModal} close={() => setFilterModal(false)}>
                <div className="kv-info">لورم اپیسوم یک متن ساختگی می باشد</div>
            </Modal>

            <Modal active={locationError} close={() => setLocationError(false)}>
                <div className="hints__title" style={{ marginBottom: 16 }}>
                    برای مشاهده ی فروشگاه های اطرافتان، لطفا دسترسی به موقعیت
                    مکانی را در بخش تنظیمات دستگاه خود فعال نمایید.
                </div>
                <div
                    className="button red"
                    onClick={() => setLocationError(false)}
                >
                    متوجه شدم
                </div>
            </Modal>

            {/* <Modal active={showFilter} close={() => setShowFilter(false)}>
        <div className="title">انتخاب زیردسته {activeCategory.title}</div>
        <div className="map__category__list">
          {activeCategory.level === 0 &&
            firstCategories?.data?.map((catItem) => (
              <div
                className="map__category__item"
                onClick={() => {
                  firstCatClickHandler(catItem.id);
                  setShowFilter(false);
                }}
              >
                {catItem.title}
                <SVG svg="arrow" />
              </div>
            ))}

          {activeCategory.level === 1 &&
            secondCategories?.data?.map((catItem) => (
              <div
                className="map__category__item"
                onClick={() => {
                  secondCatClickHandler(catItem.id);
                  setShowFilter(false);
                }}
              >
                {catItem.title}
                <SVG svg="arrow" />
              </div>
            ))}

          {activeCategory.level === 2 &&
            thirdCategories?.data?.map((catItem) => (
              <div
                className="map__category__item"
                onClick={() => {
                  thirdCatClickHandler(catItem.id);
                  setShowFilter(false);
                }}
              >
                {catItem.title}
                <SVG svg="arrow" />
              </div>
            ))}
        </div>
      </Modal> */}

            <Search
                value={searchValue}
                topPosition={true}
                setValue={(value) => setSearchValue(value)}
                setFilter={() => {}}
                filter={false}
                status={toggleSearch}
                setStatus={setToggleSearch}
            />

            <div
                className={`map__menu ${showFirstCatMenu && "active"}`}
                id="closeArea"
                onClick={(e) =>
                    e.target.id === "closeArea" && setShowFirstCatMenu(false)
                }
            >
                <div className={`map__menu__container right`}>
                    <div className="row a-c mb-8">
                        <div
                            onClick={() => setShowFirstCatMenu(false)}
                            className="map__menu__container__close"
                        >
                            <SVG svg="times" />
                        </div>
                        <div className="map__menu__container__title">
                            دسته‌بندی‌ فروشگاه‌ها
                        </div>
                    </div>

                    {zeroCategories?.data?.map((catItem, index) => (
                        <div
                            className={`map__menu__container__item ${
                                activeZeroCategories === catItem && "active"
                            }`}
                            key={index}
                            onClick={() => {
                                zeroCatClickHandler(catItem.id);
                                setShowFirstCatMenu(false);
                                setActiveCategory({
                                    level: 0,
                                    title: catItem.title,
                                    id: catItem.id,
                                });
                            }}
                        >
                            <div className="row a-c">
                                <div
                                    className="map__menu__container__item__color"
                                    // style={{ backgroundColor: catItem.color }}
                                ></div>
                                <div>{catItem.title}</div>
                            </div>
                            <SVG svg="arrow" />
                        </div>
                    ))}
                </div>
            </div>

            {/* <div className="map__market" onClick={()=>history.push('/market')}>
        <div className="button">
          خرید اینترنتی از فروشگاه‌های شهر
        </div>
      </div> */}

            {/* <div
        className={`map__geolocate ${!showMapElements && "hidden-element"}`}
        onClick={() => goToUserLocation()}
      >
        <SVG svg="geolocate" />
      </div> */}

            {/* <div
        className={`map__toggle ${!showMapElements && "active"}`}
        onClick={() => setShowMapElements(!showMapElements)}
      >
        <SVG svg={showMapElements ? "unseen" : "seen"} />
      </div> */}

            {activePopup.status ? Popup() : null}
            {activePopupIntroShop.status ? PopupIntroShop() : null}

            <div
                onClick={() => {
                    if (activePopup.status) {
                        setActivePopup({ status: false, data: [] });
                        setIsOpen(false);
                        setToday(false);
                    }
                    if (activePopupIntroShop.status) {
                        setActivePopupIntroShop({ status: false, data: [] });
                        // setIsOpen(false);
                        // setToday(false);
                    }
                }}
                ref={mapContainer}
                style={{
                    height: "100%",
                }}
            />

            {/* <div className={`map__top-right ${!showMapElements && "hidden-element"}`}>
        <div
          onClick={() => setShowFirstCatMenu(true)}
          className="map__top-right__item"
        >
          <SVG svg="menu" />
        </div>

        <div className="map__top-right__item" onClick={() => setShowHint(true)}>
          <SVG svg="map-qa" />
        </div>

        <div
          className="map__top-right__item add"
          onClick={() => history.push("/mycity")}
        >
          <SVG svg="city" />
        </div>
      </div> */}

            <div className={`map__topbar ${!showMapElements && "go-hidden"}`}>
                {/*<div*/}
                {/*  onClick={() => setShowFirstCatMenu(true)}*/}
                {/*  className="map__topbar__item"*/}
                {/*>*/}
                {/*  <SVG svg="map-menu"/>*/}
                {/*</div>*/}

                {activeCategory.level !== -1 && (
                    <div
                        className="map__topbar__category"
                        // style={{
                        //     backgroundColor: `${activeZeroCategories.color}`,
                        // }}
                    >
                        <div
                            className="map__category__back"
                            onClick={() => {
                                switch (activeCategory.level) {
                                    case 0:
                                        activeZeroClickHandler();
                                        break;

                                    case 1:
                                        activeFirstClickHandler();
                                        break;

                                    case 2:
                                        activeSecondClickHandler();
                                        break;

                                    case 3:
                                        activeThirdClickHandler();
                                        break;

                                    default:
                                        break;
                                }
                            }}
                            style={{
                                color: "black",
                            }}
                        >
                            <SVG svg="arrow-round" />
                            {activeCategory.title}
                        </div>
                        {activeCategory.level !== 3 && (
                            <div
                                className="map__category__filter"
                                onClick={() => {
                                    setShowFilter(true);
                                    setTimeout(() => {
                                        document
                                            .getElementById("map__filter")
                                            .focus();
                                    }, 150);
                                }}
                            >
                                <SVG svg="map-filter" />
                            </div>
                        )}
                    </div>
                )}
                {/*ddd*/}

                {/*<div*/}
                {/*  className={`map__topbar__item`}*/}
                {/*  onClick={() => setShowHint(true)}*/}
                {/*>*/}
                {/*  <SVG svg="map-qa"/>*/}
                {/*</div>*/}

                {/*        <div*/}
                {/*          onClick={() => setToggleSearch(true)}*/}
                {/*          className="map__topbar__item"*/}
                {/*        >*/}
                {/*          <SVG svg="map-search" />*/}
                {/*        </div>*/}
            </div>

            <div className={`map__rightbar`}>
                <div
                    onClick={() => setShowFirstCatMenu(true)}
                    className="map__rightbar__item"
                >
                    <SVG svg="map-menu" />
                </div>

                <div
                    className={`map__rightbar__item ${
                        !showMapElements && "active"
                    }`}
                    onClick={() =>
                        setShowMapElements((prevState) => !prevState)
                    }
                >
                    <SVG svg={showMapElements ? "unseen" : "seen"} />
                </div>

                {/* <div
          className={`map__rightbar__item post ${!showMapElements && "go-hidden"}`}
          onClick={() => history.push('/channel-store')}
        >
          {+unseenMessages > 0 && <div className="map__leftbar__item__unseen">
            {unseenMessages}
          </div>}
          <SVG svg="club-messages"/>
        </div> */}

                {/* <div
          className={`map__rightbar__item ${!showMapElements && "go-hidden"}`}
          onClick={() => history.push('/following-store')}
        >
          <SVG svg="seller-bookmark"/>
        </div> */}

                {/* <div
          className={`map__rightbar__item ${!showMapElements && "go-hidden"}`}
          onClick={() => history.push("/mycity")}
        >
          { user?.detail?.presenceStore == true ? <SVG svg="map-store"/> : <SVG svg="plus"/> }
        </div> */}

                <div
                    onClick={() => setToggleSearch(true)}
                    className={`map__rightbar__item ${
                        !showMapElements && "go-hidden"
                    }`}
                >
                    <SVG svg="map-search" />
                </div>

                <div
                    className={`map__rightbar__item ${
                        !showMapElements && "go-hidden"
                    }`}
                    onClick={() => goToUserLocation()}
                >
                    <SVG svg="geolocate" />
                </div>

                <div
                    className={`map__rightbar__item ${
                        !showMapElements && "go-hidden"
                    }`}
                    onClick={() => history.push("/qr")}
                >
                    <SVG svg="qr" />
                </div>

                {/* <div
          className={`map__rightbar__item ${!showMapElements && "go-hidden"}`}
          onClick={() => history.push("/reports")}
        >
          <SVG svg="map-reports"/>
        </div> */}

                {user.storeSubmiter === 1 && (
                    <div
                        className={`map__rightbar__item ${
                            !showMapElements && "go-hidden"
                        }`}
                        onClick={() => {
                            if (!submitterMode) {
                                getNearestIntroStoreLocation();
                                setMarkers([]);
                            } else {
                                setAwaitingApprovalMarker([]);
                                setSubmitterMode(false);
                                setStoresLoading(true);
                            }
                        }}
                    >
                        {submitterLoadnig || storesLoading ? (
                            <Dots color={"dark"} />
                        ) : (
                            <img src={submitter} alt="submitter" />
                        )}
                    </div>
                )}
            </div>

            <Link to={"/my-introduces?waiting"}>
                <div className={`map__bottom`}>
                    فروشگاه معرفی کن امتیاز بگیر
                </div>
            </Link>

            <div className={`map__leftbar`}>
                {/* {<SVG svg="bottom-shape" color="#195190"/>}
        <div
          className={`map__leftbar__item help`}
          onClick={() => setShowHint(!showHint)}
        >
          راهنما
        </div>
        {<SVG svg="bottom-shape" color="#195190"/>} */}

                <CircleHint hintText={hint.content} />

                {/*<div*/}
                {/*  className={`map__leftbar__item`}*/}
                {/*  onClick={() => history.push('/following-store')}*/}
                {/*>*/}
                {/*  <SVG svg="seller-bookmark"/>*/}
                {/*</div>*/}

                {/*<div*/}
                {/*  className={`map__leftbar__item post`}*/}
                {/*  onClick={() => history.push('/channel-store')}*/}
                {/*>*/}
                {/*  {+unseenMessages > 0 && <div className="map__leftbar__item__unseen">*/}
                {/*    {unseenMessages}*/}
                {/*  </div>}*/}
                {/*  <SVG svg="club-messages"/>*/}
                {/*</div>*/}

                {/*        <div*/}
                {/*          onClick={() => setToggleSearch(true)}*/}
                {/*          className="map__topbar__item"*/}
                {/*        >*/}
                {/*          <SVG svg="map-search" />*/}
                {/*        </div>*/}
            </div>

            <div className={`map__shops ${!showMapElements && "go-hidden"}`}>
                {shopList.map((shop, index) => (
                    <div
                        className="map__shops__item"
                        key={index}
                        onClick={() => {
                            const centerLatLng = map.current.getCenter();
                            const currZoom = map.current.getZoom();
                            history.replace(
                                `/map?lat=${centerLatLng.lat}&lng=${centerLatLng.lng}&zoom=${currZoom}`
                            );
                            map.current.flyTo({
                                center: [
                                    parseFloat(shop.long),
                                    parseFloat(shop.lat) + 0.0006,
                                ],
                                zoom: 17,
                                speed: 4,
                            });
                            setTimeout(() => {
                                setActivePopup({
                                    status: true,
                                    data: {
                                        id: shop.id,
                                        name: shop.name,
                                        status: shop.status,
                                        haveKm: shop.haveKm,
                                        address: shop.address,
                                        img: !shop.hasOwnProperty(
                                            "picturestores"
                                        )
                                            ? shop.photo
                                            : shop?.picturestores[0].photo,
                                        hours: shop.workinghours,
                                    },
                                });
                            }, 200);
                        }}
                    >
                        <div className="row a-c">
                            <div className="map__shops__item__image">
                                <img
                                    src={
                                        shop.hasOwnProperty("picturestores")
                                            ? shop.picturestores[0]?.photo
                                            : shop.photo
                                    }
                                    alt=""
                                />
                            </div>
                            <div className="map__shops__item__title">
                                {shop.name}
                            </div>
                        </div>
                        <div className="map__shops__item__address">
                            {shop.address}
                        </div>
                    </div>
                ))}
            </div>

            <div
                id="map__filter"
                className={`map__filter ${showFilter && "active"}`}
                tabIndex={1}
                onBlur={() => setShowFilter(false)}
            >
                {/* <div className="title">انتخاب زیردسته {activeCategory.title}</div> */}
                <div className="map__category__list">
                    {activeCategory.level === 0 &&
                        firstCategories?.data?.map((catItem) => (
                            <div
                                className="map__category__item"
                                onClick={() => {
                                    firstCatClickHandler(catItem.id);
                                    setShowFilter(false);
                                }}
                            >
                                {catItem.title}
                                <SVG svg="arrow" />
                            </div>
                        ))}

                    {activeCategory.level === 1 &&
                        secondCategories?.data?.map((catItem) => (
                            <div
                                className="map__category__item"
                                onClick={() => {
                                    secondCatClickHandler(catItem.id);
                                    setShowFilter(false);
                                }}
                            >
                                {catItem.title}
                                <SVG svg="arrow" />
                            </div>
                        ))}

                    {activeCategory.level === 2 &&
                        thirdCategories?.data?.map((catItem) => (
                            <div
                                className="map__category__item"
                                onClick={() => {
                                    thirdCatClickHandler(catItem.id);
                                    setShowFilter(false);
                                }}
                            >
                                {catItem.title}
                                <SVG svg="arrow" />
                            </div>
                        ))}
                </div>
            </div>

            {/*<div className="map__toolbar">*/}
            {/*  <div*/}
            {/*    className={`map__toolbar__item ${!showMapElements && "go-hidden"}`}*/}
            {/*    onClick={() => goToUserLocation()}*/}
            {/*  >*/}
            {/*    <SVG svg="geolocate"/>*/}
            {/*  </div>*/}

            {/*  <div*/}
            {/*    onClick={() => setToggleSearch(true)}*/}
            {/*    className={`map__toolbar__item ${!showMapElements && "go-hidden"}`}*/}
            {/*  >*/}
            {/*    <SVG svg="map-search"/>*/}
            {/*  </div>*/}

            {/*  /!*<div*!/*/}
            {/*  /!*  className={`map__toolbar__item ${!showMapElements && "go-hidden"}`}*!/*/}
            {/*  /!*  onClick={() => setShowHint(true)}*!/*/}
            {/*  /!*>*!/*/}
            {/*  /!*  <SVG svg="map-qa" />*!/*/}
            {/*  /!*</div>*!/*/}

            {/*  /!*{user.storeSubmiter === 1 ?*!/*/}
            {/*  /!*  <div*!/*/}
            {/*  /!*    className={`map__toolbar__item ${!showMapElements && "go-hidden"}`}*!/*/}
            {/*  /!*    onClick={() => getNearestIntroStoreLocation()}*!/*/}
            {/*  /!*  >*!/*/}
            {/*  /!*    <SVG svg="Introducestore"/>*!/*/}
            {/*  /!*  </div>*!/*/}
            {/*  /!*  :*!/*/}
            {/*  /!*  <div*!/*/}
            {/*  /!*    className={`map__toolbar__item ${!showMapElements && "go-hidden"}`}*!/*/}
            {/*  /!*    onClick={() => setShowHint(true)}*!/*/}
            {/*  /!*  >*!/*/}
            {/*  /!*    <SVG svg="map-qa"/>*!/*/}
            {/*  /!*  </div>*!/*/}
            {/*  /!*}*!/*/}

            {/*  <div*/}
            {/*    className={`map__toolbar__item ${!showMapElements && "active"}`}*/}
            {/*    onClick={() => setShowMapElements(!showMapElements)}*/}
            {/*  >*/}
            {/*    <SVG svg={showMapElements ? "unseen" : "seen"}/>*/}
            {/*  </div>*/}

            {/*  <div*/}
            {/*    className={`map__toolbar__item ${!showMapElements && "go-hidden"}`}*/}
            {/*    onClick={() => history.push("/qr")}*/}
            {/*  >*/}
            {/*    <SVG svg="qr"/>*/}
            {/*  </div>*/}

            {/*  <div*/}
            {/*    className={`map__toolbar__item ${!showMapElements && "go-hidden"}`}*/}
            {/*    onClick={() => history.push("/mycity")}*/}
            {/*  >*/}
            {/*    <SVG svg="map-store"/>*/}
            {/*  </div>*/}
            {/*</div>*/}

            {/* {activeCategory.level !== -1 && (
        <div
          className={`map__category`}
          style={{ backgroundColor: `${activeZeroCategories.color}` }}
        >
          <div
            className="map__category__back"
            onClick={() => {
              switch (activeCategory.level) {
                case 0:
                  activeZeroClickHandler();
                  break;

                case 1:
                  activeFirstClickHandler();
                  break;

                case 2:
                  activeSecondClickHandler();
                  break;

                case 3:
                  activeThirdClickHandler();
                  break;

                default:
                  break;
              }
            }}
          >
            <SVG svg="arrow-round" />
            {activeCategory.title}
          </div>
          {activeCategory.level !== 3 && (
            <div
              className="map__category__filter"
              onClick={() => setShowFilter(true)}
            >
              <SVG svg="map-filter" />
            </div>
          )}
        </div>
      )} */}

            {/* <div className="map__top-center-wrapper">
        <div className="map__top-center">
          {zeroCategories.active === false ? (
            zeroCategories.data.map((item, index) => (
              <div
                className={`map__top-center__item`}
                onClick={() => zeroCatClickHandler(item.id)}
              >
                <div className={`map__top-center__item__select`}>
                  <span
                    className={`map__top-center__item__select__value ${
                      item.active && "active"
                    }`}
                  >
                    {item.title}
                  </span>
                </div>
              </div>
            ))
          ) : firstCategories.active === false ? (
            <>
              <div
                className={`map__top-center__item active`}
                onClick={() => activeZeroClickHandler()}
              >
                <div className={`map__top-center__item__select active`}>
                  <div className="cat-product__category__icon">
                    <SVG svg="arrow-round" />
                  </div>
                  <span
                    className={`map__top-center__item__select__value active`}
                  >
                    {activeZeroCategories.title}
                  </span>
                </div>
              </div>
              <div className="map__top-center__sub">
                {!categoriesLoading ? (
                  firstCategories.data.map((item) => (
                    <div
                      className={`map__top-center__item`}
                      onClick={() => firstCatClickHandler(item.id)}
                    >
                      <div className={`map__top-center__item__select`}>
                        <span
                          className={`map__top-center__item__select__value`}
                        >
                          {item.title}
                        </span>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className={`map__top-center__item__load`}>
                    <Dots color="dark" />
                  </div>
                )}
              </div>
            </>
          ) : secondCategories.active === false ? (
            <>
              <div
                className={`map__top-center__item active`}
                onClick={() => activeFirstClickHandler()}
              >
                <div className={`map__top-center__item__select active`}>
                  <div className="cat-product__category__icon">
                    <SVG svg="arrow-round" />
                  </div>
                  <span
                    className={`map__top-center__item__select__value active`}
                  >
                    {activeFirstCategories.title}
                  </span>
                </div>
              </div>
              <div className="map__top-center__sub">
                {!categoriesLoading ? (
                  secondCategories.data.map((item) => (
                    <div
                      className={`map__top-center__item`}
                      onClick={() => secondCatClickHandler(item.id)}
                    >
                      <div className={`map__top-center__item__select`}>
                        <span
                          className={`map__top-center__item__select__value`}
                        >
                          {item.title}
                        </span>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className={`map__top-center__item__load`}>
                    <Dots color="dark" />
                  </div>
                )}
              </div>
            </>
          ) : (
            <>
              <div
                className={`map__top-center__item active`}
                onClick={() => activeSecondClickHandler()}
              >
                <div className={`map__top-center__item__select active`}>
                  <div className="cat-product__category__icon">
                    <SVG svg="arrow-round" />
                  </div>
                  <span
                    className={`map__top-center__item__select__value active`}
                  >
                    {activeSecondCategories.title}
                  </span>
                </div>
              </div>
              <div className="map__top-center__sub">
                {!categoriesLoading ? (
                  thirdCategories.data.map((item) => (
                    <div
                      className={`map__top-center__item`}
                      onClick={() => thirdCatClickHandler(item.id)}
                    >
                      <div
                        className={`map__top-center__item__select  ${
                          activeThirdCategories === item && "active"
                        }`}
                      >
                        <span
                          className={`map__top-center__item__select__value  ${
                            activeThirdCategories === item && "active"
                          }`}
                        >
                          {item.title}
                        </span>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className={`map__top-center__item__load`}>
                    <Dots color="dark" />
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div> */}

            {/* <div
        className="map"
        style={{
          borderRadius: "50%",
          backgroundColor: "red",
          position: "fixed",
        }}
      /> */}
        </div>
    );
};
