import {Dots} from "components";
import {useEffect} from "react";
import {useHistory, useLocation} from "react-router-dom";

const PaymentRedirect = () => {
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (history.action === 'POP') {
      history.push('/');
    } else {
      history.replace(`${location.pathname}${location.search}`);
    }
  }, [history]);

  return (
    <div className="payment">
      <div className="payment__title">
        درحال انتقال به تارنما ...
      </div>
      <Dots color="dark"/>
    </div>
  );
};

export default PaymentRedirect;
