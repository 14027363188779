import {
  Card,
  CounterInput,
  Dots,
  Fade,
  Modal,
  NotFoundBox,
  PageTopbar,
  Spinner,
  SVG,
} from "components";
import moment from "jalali-moment";
import React, {useEffect, useRef, useState} from "react";
import QRCode from "react-qr-code";
import {getStoreClubAPI, getUserStoreAPI} from "utils";
import {useHistory} from "react-router-dom";
import toast from "react-hot-toast";
import {TransferBlueKmAPI} from "utils/Services/Km/Blue/TransferToMobile";
import {useUser} from "hooks";

export const ClubContainer = () => {
  const giveAmount = useRef(null);
  const [clubList, setClubList] = useState([]);
  const [userStore, setUserStore] = useState(0);
  const [storeDetail, setStoreDetail] = useState<any>({});
  const [loading, setLoading] = useState(true);
  const {
    userState: {user},
  } = useUser();
  const [kmAmount, setKmAmount] = useState<any>(1);
  const [showGiveKm, setShowGiveKm] = useState(false);
  const [giveKmLoading, setGiveKmLoading] = useState(false);
  const [giveKmUser, setGiveKmUser] = useState("");
  const history = useHistory();

  const getClubList = () => {
    getStoreClubAPI()
      .then((response) => {
        setClubList(response.data);
      })
      .catch((err) => {
      })
      .finally(() => setLoading(false));
  };

  const getUserStore = () => {
    getUserStoreAPI()
      .then((response) => {
        setUserStore(response.data?.id);
        setStoreDetail(response.data);
      })
      .catch((err) => {
      })
      .finally(() => {
      });
  };

  const SubmitGiveKm = () => {
    setGiveKmLoading(true);
    TransferBlueKmAPI(giveKmUser, kmAmount)
      .then((response) => {
        if (response.status === 200) {
          toast.success("اهدای KP با موفقیت انجام شد.");
          setGiveKmUser("");
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          toast.error('برای اهدای KP فروشگاه شما باید فعال باید');
        } else toast.error('خطا در اهدای KP');
      })
      .finally(() => {
        setShowGiveKm(false);
        setGiveKmLoading(false);
      });
  };

  useEffect(() => {
    history.replace("/myshop/club");
    getUserStore();
    getClubList();
  }, []);

  return (
    <Fade>
      <PageTopbar showKv={false} title="باشگاه مشتریان فروشگاه"/>
      <Spinner active={loading} color={"dark"}/>

      <Card>
        <div className="wrapper-fix">
          <div className="mycity mb-8">
            {(!!storeDetail.activeByUser && !!storeDetail.isActive) &&
              <div className="myshop-club__qr">
              <QRCode
                id="QRCode"
                value={`https://app.kartaak.com/qr/club/${userStore}`}
                size={220}
              />
            </div>}
          </div>
          {clubList?.length !== 0 ? (
            <React.Fragment>
              {/*<div*/}
              {/*  onClick={() => {*/}
              {/*    if (*/}
              {/*      storeDetail.totalBoughtKm === 0 &&*/}
              {/*      storeDetail.totalDonatedKm === 0*/}
              {/*    ) {*/}
              {/*      toast.error(*/}
              {/*        "برای استفاده از این قابلیت، اقدام به خرید KM نمایید."*/}
              {/*      );*/}
              {/*    } else {*/}
              {/*      history.push("/myshop/club/messages");*/}
              {/*    }*/}
              {/*  }}*/}
              {/*  className={`myshop-club__messages ${*/}
              {/*    storeDetail.totalBoughtKm === 0 &&*/}
              {/*    storeDetail.totalDonatedKm === 0 &&*/}
              {/*    "deactive"*/}
              {/*  }`}*/}
              {/*>*/}
              {/*  <div className="row a-c">*/}
              {/*    <SVG svg="message" />*/}
              {/*    <div>پیام‌ها</div>*/}
              {/*  </div>*/}
              {/*  <SVG svg="arrow" />*/}
              {/*</div>*/}
              <div className="title">
                اعضای باشگاه
                <span style={{marginRight: 5, fontWeight: 300, fontSize: 12}}>
                  ( {clubList.length} نفر )
                </span>
              </div>
              <div className="myshop-club__customers">
                {/*{clubList.map((customer, index) => (*/}
                {/*  <div className="myshop-club__customer" key={index}>*/}
                {/*    <div className="row a-c">*/}
                {/*      <div className="myshop-club__customer__image">*/}
                {/*        <SVG svg="profile-user" />*/}
                {/*      </div>*/}
                {/*      <div style={{ fontSize: 13, marginLeft: 8 }}>کاربر:</div>*/}
                {/*      <div className="myshop-club__customer__mobile">*/}
                {/*        {customer.userinfo?.invite_code}*/}
                {/*      </div>*/}
                {/*    </div>*/}

                {/*    <div className="myshop-club__customer__action">*/}
                {/*      {moment(customer.created_at, "YYYY/MM/DD")*/}
                {/*        .locale("fa")*/}
                {/*        .format("YYYY/MM/DD")}*/}
                {/*      <div*/}
                {/*        className="myshop-club__customer__action__gift"*/}
                {/*        onClick={() => {*/}
                {/*          setShowGiveKm(true);*/}
                {/*          setGiveKmUser(customer.userinfo?.invite_code);*/}
                {/*        }}*/}
                {/*      >*/}
                {/*        <SVG svg="gift" />*/}
                {/*      </div>*/}
                {/*    </div>*/}
                {/*  </div>*/}
                {/*))}*/}
              </div>
            </React.Fragment>
          ) : (
            <div style={{padding: "140px 0 70px"}}>
              <NotFoundBox
                icon="myshop-club"
                title="عضوی در باشگاه پیدا نشد."
                bio="با اسکن بارکد فروشگاه توسط کاربران، اقدام به ایجاد باشگاه مشتریان نمایید."
              />
            </div>
          )}
        </div>
      </Card>
    </Fade>
  );
};
