import React from "react";
import "./WrapperCard.scss";

export const WrapperCard = ({ children, vitrin = false }) => {
    return (
        <div
            style={{ border: vitrin && "2px solid #21bbe1" }}
            className="wrapper-card"
        >
            {children}
        </div>
    );
};
