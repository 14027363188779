import {
  Card,
  Fade,
  PageTopbar,
  Selectbox,
  Spinner,
  SVG,
  Dots,
} from "components";
import {ImageEditor} from "components/ImageEditor";
import React, {useEffect, useState} from "react";
import toast from "react-hot-toast";
import {useHistory, useParams} from "react-router";
import {adsViewAPI, GetCategories} from "utils";
import {updateAdsAPI} from "utils/Services/API";
import imageCompression from "browser-image-compression";

export const UpdateAdsContainer = () => {
  const user_token = localStorage.getItem("user_token");
  const {id} = useParams<any>();
  const history = useHistory();
  const [adsDetail, setAdsDetail] = useState({
    id: "",
    title: "",
    description: "",
    phoneNumber: "",
    mobileNumber: "",
    address: "",
    category: 0,
    categoryValue: "",
    url: "",
  });
  const [adsImage, setAdsImage] = useState<any>({file: null, url: null});
  const [categoriesData, setCategoriesData] = useState([]);
  const [showImageEditor, setShowImageEditor] = useState(false);
  const [loading, setLoading] = useState(true);
  const [compressLoading, setCompressLoading] = useState(0);
  const [submitLoading, setSubmitLoading] = useState(false);

  const compressOptions = {
    maxSizeMB: 0.25,
    maxWidthOrHeight: 1024,
    onProgress: (percentage) => {
      setCompressLoading(percentage);
    },
  };

  const getAdsDetail = () => {
    const api = adsViewAPI(id);
    api.then((response) => {
      if (response.status === 404) {
        // toast.error("تبلیغی یافت نشد!");
        // history.goBack();
        return;
      } else {
        setAdsDetail({
          id: response.id,
          title: response.title,
          category: response.catId,
          description: response.description,
          phoneNumber: response.phoneNumber,
          mobileNumber: response.mobileNumber,
          address: response.address,
          categoryValue: response.adsCat,
          url: response.url,
        });
        setAdsImage({url: response.image});
      }
      setLoading(false);
    });
  };

  const updateAds = () => {
    setSubmitLoading(true);
    if (adsImage.file) {
      const compressedFile = imageCompression(adsImage.file, compressOptions);
      compressedFile.then((compressedImage) => {
        updateAdsAPI(user_token, adsDetail, compressedImage).then(
          (response) => {
            if (response.status === 400) {
            } else if (response.status === 200) {
              setSubmitLoading(false);
              toast.success("محتوای شما با موفقیت ویرایش شد.");
              history.goBack();
            }
          }
        );
      });
    } else {
      updateAdsAPI(user_token, adsDetail, adsImage.file).then((response) => {
        if (response.status === 400) {
        } else if (response.status === 200) {
          setSubmitLoading(false);
          toast.success("محتوای شما با موفقیت ویرایش شد.");
          history.goBack();
        }
      });
    }
  };

  useEffect(() => {
    history.replace(`/ads/update/${id}`);
    getAdsDetail();
    GetCategories().then((res) => {
      if (res) {
        res.data.forEach((category) => {
          category["name"] = category["title"];
        });
        if (res.data[0]?.name) setCategoriesData(res.data);
      }
    });
  }, []);

  return (
    <Fade>
      <PageTopbar title="ویرایش محتوا" showKv={false}/>

      <Spinner active={loading} color="dark"/>

      <ImageEditor
        active={showImageEditor}
        onClose={(close) => setShowImageEditor(!close)}
        onConfirm={(cropper) => {
          setShowImageEditor(false);
          setAdsImage((prev) => ({
            ...prev,
            url: cropper.getCroppedCanvas().toDataURL().substr(22),
          }));
          cropper.getCroppedCanvas().toBlob((blob) => {
            setAdsImage((prev) => ({
              ...prev,
              file: blob,
            }));
          });
        }}
      />

      <Card>
        {/* <div className="title ios-top">دسته‌بندی</div>

        <Selectbox
          data={categoriesData}
          placeholder="انتخاب دسته‌بندی محتوا"
          onChange={(catId) => setAdsDetail({ ...adsDetail, category: catId })}
          onChangeString={
            (value) => { }
            // setAdsDetail({ ...adsDetail, categoryValue: value })
          }
          defaultValue={adsDetail.categoryValue}
        /> */}

        <div className="title">عنوان محتوا</div>

        <input
          className="input"
          type="text"
          placeholder="تیتر بالای صفحه را بنویسید"
          value={adsDetail.title}
          onChange={(e) =>
            setAdsDetail({...adsDetail, title: e.target.value})
          }
        />

        <div className="title">
          توضیحات محتوا (حداقل 10 و حداکثر 300 کاراکتر)
        </div>

        <textarea
          className="textarea"
          placeholder="توضیحات محتوا"
          value={adsDetail.description}
          onChange={(e) =>
            setAdsDetail({...adsDetail, description: e.target.value})
          }
          rows={3}
        ></textarea>

        <div className="title">آدرس</div>
        <textarea
          className="textarea"
          placeholder="اختیاری"
          value={adsDetail.address}
          rows={3}
          onChange={(e) => {
            setAdsDetail({...adsDetail, address: e.target.value});
          }}
        ></textarea>

        <div className="title">تلفن</div>
        <input
          dir="auto"
          className="input"
          type="tel"
          placeholder="اختیاری"
          value={adsDetail.phoneNumber}
          onChange={(e) => {
            setAdsDetail({...adsDetail, phoneNumber: e.target.value});
          }}
        />

        <div className="title">موبایل</div>
        <input
          dir="auto"
          className="input"
          type="tel"
          placeholder="اختیاری"
          value={adsDetail.mobileNumber}
          onChange={(e) => {
            setAdsDetail({...adsDetail, mobileNumber: e.target.value});
          }}
        />

        <div className="title">نشانی اینترنتی</div>
        <input
          dir="auto"
          className="input"
          type="url"
          placeholder="اختیاری"
          value={adsDetail.url}
          onChange={(e) => {
            setAdsDetail({...adsDetail, url: e.target.value});
          }}
        />

        {/* <div className="title">تصویر محتوا</div> */}

        {/* <div
          className="create-ads__final__image"
          onClick={() => setShowImageEditor(true)}
        >
          <canvas
            className={`create-ads__canvas ${adsImage.url !== "new" && "deactive"
              }`}
            id="canvas-image"
          />

          {adsImage.url !== null ? (
            <img src={`${adsImage.url}`} alt="" />
          ) : adsImage.file === undefined ? (
            // adsImage.url !== ""
            // ? (
            //   <img src={`data:image/png;base64,${adsImage.url}`} alt="" />
            // ) : (
            <SVG svg="ads-image" />
          ) : (
            // )
            <SVG svg="ads-image" />
          )}

          {adsImage.url === null && (
            <div className="create-ads__final__image__title">
              بارگذاری تصویر
            </div>
          )}

          <div className="create-ads__final__image__edit">ویرایش</div>
        </div> */}

        <div className="create-ads__button" onClick={() => updateAds()}>
          {compressLoading !== 0 ? (
            <div>در حال پردازش تصویر ({compressLoading}%)</div>
          ) : submitLoading ? (
            <Dots color="light"/>
          ) : (
            "ویرایش محتوا"
          )}
        </div>
      </Card>
    </Fade>
  );
};
