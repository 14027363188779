import './DiscountBadge.scss';
import {SVG} from "../SVG";

export const DiscountBadge = ({discount}) => {


  return (
    <div className={'discount-badge'}>
      <SVG svg={'discount-badge'}/>
      <div className="discount-badge__body">
        <div className="discount-badge__top">
          تخفیف
        </div>
        <div className="discount-badge__mid">{discount}</div>
      </div>
    </div>
  );
  // 50% تا 75%
};