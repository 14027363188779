import React, { useRef, useEffect, useState } from "react";
import Slider from "react-slick";
import {
    SVG,
    Modal,
    Dots,
    CheckBox,
    CounterInput,
    AcceptRule,
} from "components";
import {
    panelList,
    checkCode,
    hasCollaborationInviteCodeAPI,
    buyGoldPanelAPI,
    GetWhiteKmPrice,
    getHintAPI,
} from "utils/Services";
import { farsiDigitToEnglish, transformPrice } from "utils/Functions";
import toast from "react-hot-toast";
import { useUser } from "hooks";
import { useHistory } from "react-router-dom";
import { checkPanelCodeAPI } from "../../../../utils/Services/API";

export const SliderPanel = ({ setLoading }) => {
    const user_token = localStorage.getItem("user_token");
    const history = useHistory();
    const sliderRef = useRef(null);
    const {
        getUserDataFromApi,
        userState: { user },
    } = useUser();
    const [panelsData, setPanelsData] = useState([]);
    const [modalStatus, setModalStatus] = useState(false);
    const [userPanelType, setUserPanelType] = useState({
        gold: false,
        bronze: false,
        silver: false,
        white: false,
    });
    const [selectedPanel, setSelectedPanel] = useState({
        id: 0,
        name: "",
        time: "",
        reward: "",
        price: 0,
    });
    const [toggleCode, setToggleCode] = useState(true);
    const [loadingCode, setLoadingCode] = useState(false);
    const [loadingPay, setLoadingPay] = useState(false);
    const [confirm, setConfirm] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [hasInviteCode, setHasInviteCode] = useState(false);
    const [paymentType, setPaymentType] = useState<"cash" | "km">("cash");
    const [whiteKmCount, setWhiteKmCount] = useState(0);
    const [whiteKmCountPack, setWhiteKmCountPack] = useState(0);
    const [whiteKmPrice, setWhiteKmPrice] = useState(0);
    const [code, setCode] = useState("");
    const [hint, setHint] = useState<any>("");
    const [hintLoading, setHintLoading] = useState(true);
    const [readCompleted, setreadCompleted] = useState(false);
    const [acceptedRule, setAcceptedRule] = useState(false);
    const [inviteCodeStatus, setInviteCodeStatus] = useState(false);
    const [inviteCodeLoading, setInviteCodeLoading] = useState(false);
    const [inviterCode, setInviterCode] = useState("");

    const settings = {
        infinite: false,
        slidesToShow: 1,
        speed: 50,
        arrows: false,
        dots: true,
    };

    const handlePanelName = (type) => {
        switch (type) {
            case "white":
                return "پنل سفید";
            case "gold":
                return "پنل طلایی";
            case "silver":
                return "پنل نقره ای";
            case "bronze":
                return "پنل برنزی";
            default:
                return "پنل نامشخص";
        }
    };

    const handleBuy = (panel) => {
        if (!acceptedRule) {
            toast.error("ابتدا قوانین خرید نمایندگی را مطالعه و بپذیرید.");
            return;
        }
        if (user.detail?.star < 7) {
            toast.error("برای خرید پنل نمایندگی، نیاز به حداقل 7 ستاره دارید.");
            return;
        }

        switch (panel.type) {
            case "white":
                if (
                    !userPanelType.gold &&
                    !userPanelType.bronze &&
                    !userPanelType.silver &&
                    !userPanelType.white
                ) {
                    setSelectedPanel({
                        id: panel.id,
                        name: handlePanelName(panel.type),
                        time: panel.creditTime,
                        reward: panel.reward,
                        price: panel.price,
                    });
                    setModalStatus(true);
                } else toast.error("تنها امکان خرید پنل بالاتر وجود دارد");
                break;
            case "bronze":
                if (
                    !userPanelType.gold &&
                    !userPanelType.silver &&
                    !userPanelType.bronze
                ) {
                    setSelectedPanel({
                        id: panel.id,
                        name: handlePanelName(panel.type),
                        time: panel.creditTime,
                        reward: panel.reward,
                        price: panel.price,
                    });
                    setModalStatus(true);
                } else toast.error("تنها امکان خرید پنل بالاتر وجود دارد");
                break;
            case "silver":
                if (!userPanelType.gold && !userPanelType.silver) {
                    setSelectedPanel({
                        id: panel.id,
                        name: handlePanelName(panel.type),
                        time: panel.creditTime,
                        reward: panel.reward,
                        price: panel.price,
                    });
                    setModalStatus(true);
                } else toast.error("تنها امکان خرید پنل بالاتر وجود دارد");
                break;
            case "gold":
                if (!userPanelType.gold) {
                    setSelectedPanel({
                        id: panel.id,
                        name: handlePanelName(panel.type),
                        time: panel.creditTime,
                        reward: panel.reward,
                        price: panel.price,
                    });
                    setModalStatus(true);
                } else toast.error("تنها امکان خرید پنل بالاتر وجود دارد");
                break;
            default:
                break;
        }
    };

    const onSubmitCode = () => {
        if (code.length <= 0) {
            toast.error("کد مورد نظر را وارد نمایید");
            return;
        } else setLoadingCode(true);
        checkCode(code)
            .then((res) => {
                if (res) {
                    toast.success("کد معرف با موفقیت ثبت شد");
                    setToggleCode(false);
                    getUserDataFromApi();
                    setLoadingCode(false);
                }
            })
            .catch((err) => {
                setLoadingCode(false);
                if (err.response.status == 403) {
                    toast.error("این کد برای شما معتبر نمی باشد");
                } else if (err.response.status == 406) {
                    toast.error("معرف شما معتبر نمی باشد");
                } else if (err.response.status == 404) {
                    toast.error("این کد وجود ندارد");
                }
            });
    };

    const handleCloseModal = () => {
        setConfirm(false);
        setToggleCode(true);
        setModalStatus(false);
    };

    const handlePay = () => {
        setLoadingPay(true);
        // requestBeforeBuy(selectedPanel.id).then((res) => {
        //   if (res) {
        //     if (res.data.payID && res.data.tk) {
        //       setLoadingPay(false);
        //       history.push(
        //         `/pay?https://api.kartaak.com/api/v2/collaborationPanel/${res.data.payID}/payment/${res.data.tk}`
        //       );
        //       setTimeout(() => {
        //         if (history.location.pathname.includes('/pay'))
        //           window.location.href = (`/pay?https://api.kartaak.com/api/v2/collaborationPanel/${res.data.payID}/payment/${res.data.tk}`);
        //       }, 600);
        //     } else toast.error("خطایی رخ داده است لطفا با پشتیبانی تماس بگیرید");
        //   }
        //   setLoadingPay(false);
        // });
        buyGoldPanelAPI(paymentType, whiteKmCount, code)
            .then((response) => {
                if (
                    response &&
                    response.msg == "gold panel Activated by WhiteKm"
                ) {
                    history.replace("/collaborate");
                    toast.success("پنل با موفقیت خریداری شد");
                }
                if (response.data.payID && response.data.tk) {
                    setLoadingPay(false);
                    history.push(
                        `/pay?${process.env.REACT_APP_DEV_IP}api/v3/collaboration/${response.data.payID}/payment/${response.data.tk}`
                    );
                    setTimeout(() => {
                        if (history.location.pathname.includes("/pay"))
                            window.location.href = `${process.env.REACT_APP_DEV_IP}api/v3/collaboration/${response.data.payID}/payment/${response.data.tk}`;
                    }, 600);
                }
                setLoadingPay(false);
            })
            .catch((err) => {
                setLoadingPay(false);
                if (err.response.status == 403) {
                    toast.error("این کد برای شما معتبر نمی باشد");
                } else if (err.response.status == 406) {
                    toast.error("معرف شما معتبر نمی باشد");
                } else if (err.response.status == 404) {
                    toast.error("این کد وجود ندارد");
                }
            });
    };

    const handleConfirm = () => {
        if (toggleCode && !confirm) {
            setConfirm(true);
        }
    };

    const getPanelsList = () => {
        panelList().then((res) => {
            if (res) {
                setPanelsData(res.data.panels);
                setLoading(false);
            } else return;
        });
    };

    const hasCollaborationInviteCode = () => {
        hasCollaborationInviteCodeAPI().then((response) => {
            setHasInviteCode(response.data.isInviter);
        });
    };

    const onChangeWhiteKmCountPackage = (value) => {
        if (value > user.whiteKm) {
            toast.error("مقدار وارد شده بیشتر از دارایی شما می باشد");
            return;
        }
        setWhiteKmCount(value * 25);
    };

    const fetchWhiteKmPrice = () => {
        GetWhiteKmPrice()
            .then((res) => {
                if (res) {
                    setWhiteKmPrice(Number(res.data));
                }
            })
            .catch((err) => {
                if (err) toast.error("به مشکل برخوردیم");
            });
    };

    // const getHint = () => {
    //   getHintAPI("صفحات داخلی نمایندگی کارتک قوانین خرید پنل طلایی")
    //     .then((response) => {
    //       setHint(response);
    //     })
    //     .catch(() => setHint(false))
    //     .finally(() => setHintLoading(false));
    // };

    const handleSubmitInviteCode = () => {
        setInviteCodeLoading(true);
        checkPanelCodeAPI(user_token, inviterCode)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.data.Credit) {
                        toast.error("کد وارد شده منقضی شده است.");
                        return;
                    } else if (response.data.data.panelType === "white") {
                        toast.error("معرف باید پنل طلایی داشته باشد");
                        return;
                    } else if (response.data.data.panelType) {
                        toast.success("معرف پنل با موفقیت ثبت شد");
                        setInviteCodeStatus(true);
                        setCode(inviterCode);
                        return;
                    }
                } else if (response.status === 404) {
                    toast.error("پنلی با این کد پیدا نشد.");
                    return;
                }
            })
            .catch((err) => {})
            .finally(() => setInviteCodeLoading(false));
    };

    useEffect(() => {
        hasCollaborationInviteCode();
        // getUserPanel();
        getPanelsList();
        fetchWhiteKmPrice();
        // localStorage.getItem('panelSeen') !== 'true' &&
    }, []);

    useEffect(() => {
        const kmCount = selectedPanel.price / whiteKmPrice;

        if (user.whiteKm > kmCount) {
            setWhiteKmCountPack(kmCount / 25);
        } else {
            setWhiteKmCountPack(Math.floor(user.whiteKm / 25));
        }
    }, [selectedPanel]);

    useEffect(() => {
        document
            .getElementById("buyPanelAlert")
            ?.addEventListener("scroll", () => {
                document.getElementById("buyPanelAlert").scrollTop >
                document.getElementById("buyPanelAlert")?.scrollHeight -
                    document.getElementById("buyPanelAlert")?.clientHeight -
                    20
                    ? setreadCompleted(true)
                    : setreadCompleted(false);
            });
        return () => {
            document
                .getElementById("buyPanelAlert")
                ?.removeEventListener("scroll", () => {
                    document.getElementById("buyPanelAlert").scrollTop >
                    document.getElementById("buyPanelAlert")?.scrollHeight -
                        document.getElementById("buyPanelAlert")?.clientHeight -
                        20
                        ? setreadCompleted(true)
                        : setreadCompleted(false);
                });
        };
    }, [document.getElementById("buyPanelAlert")?.scrollTop]);

    return (
        <>
            <Modal active={showAlert} close={(close) => setShowAlert(false)}>
                <div className="buy-panel__alert__title">{hint.title}</div>
                <div
                    className={`buy-panel__alert__description ${
                        readCompleted && "deactive"
                    }`}
                    id={"buyPanelAlert"}
                >
                    {hintLoading && <Dots color="dark" />}
                    {hint.content?.split("\n")?.map((str) => (
                        <div>{str}</div>
                    ))}
                </div>
                <div className="row buy-panel__alert__bottom">
                    <div
                        className={`buy-panel__alert__button  ${
                            readCompleted ? "accept" : "disabled"
                        }`}
                        onClick={() => {
                            if (readCompleted) {
                                setAcceptedRule(true);
                                setShowAlert(false);
                            } else {
                                document
                                    .getElementById("buyPanelAlert")
                                    .scrollTo(0, 2500);
                            }
                        }}
                    >
                        قوانین را می‌پذیرم
                    </div>
                    <div
                        className="buy-panel__alert__button reject"
                        onClick={() => setShowAlert(false)}
                    >
                        انصراف
                    </div>
                </div>
            </Modal>

            <Slider {...settings} ref={sliderRef}>
                {panelsData.map(
                    (panel) =>
                        panel.type === "gold" && (
                            <>
                                <div
                                    className={`buy-panel__slider ${panel.type}`}
                                >
                                    <div
                                        className={`buy-panel__slider__title ${panel.type}`}
                                    >
                                        <span>
                                            {handlePanelName(panel.type)}
                                        </span>
                                    </div>
                                    <div
                                        className={`buy-panel__slider__price ${panel.type}`}
                                    >
                                        <span>{`${
                                            panel.price == 0
                                                ? "رایگان"
                                                : transformPrice(panel.price) +
                                                  " تومان"
                                        }`}</span>
                                    </div>

                                    <div className="buy-panel__slider__features">
                                        <div className="buy-panel__slider__features__item">
                                            <span
                                                className={`buy-panel__slider__features__item__title ${panel.type}`}
                                            >
                                                مدت اعتبار پنل
                                            </span>
                                            <span
                                                className={`buy-panel__slider__features__item__counter ${panel.type}`}
                                            >
                                                {`${panel.creditTime} ماه`}
                                            </span>
                                        </div>

                                        <div className="buy-panel__slider__features__item">
                                            <span
                                                className={`buy-panel__slider__features__item__title ${panel.type}`}
                                            >
                                                پاداش جذب تبلیغات برای کارتلا
                                            </span>
                                            <span
                                                className={`buy-panel__slider__features__item__counter ${panel.type}`}
                                            >
                                                {panel.reward} %
                                            </span>
                                        </div>

                                        <div className="buy-panel__slider__features__item">
                                            <span
                                                className={`buy-panel__slider__features__item__title ${panel.type}`}
                                            >
                                                پاداش فروش فورشاپ‌های کارتلند
                                            </span>
                                            <span
                                                className={`buy-panel__slider__features__item__counter ${panel.type}`}
                                            >
                                                {panel.reward} %
                                            </span>
                                        </div>

                                        {/* <div className="buy-panel__slider__features__item">
                      <span
                        className={`buy-panel__slider__features__item__title ${panel.type}`}
                      >
                        پاداش فروش پنل‌های طلایی نمایندگان
                      </span>
                      <span
                        className={`buy-panel__slider__features__item__counter ${panel.type}`}
                      >
                        15 %
                      </span>
                    </div> */}

                                        {/* <div className="buy-panel__slider__features__item">
                      <span
                        className={`buy-panel__slider__features__item__title ${panel.type}`}
                      >
                        پاداش جذب مشارکت واحدهای کارتلند
                      </span>
                      <span
                        className={`buy-panel__slider__features__item__counter ${panel.type}`}
                      >
                        5 %
                      </span>
                    </div> */}

                                        {/*<div className="buy-panel__slider__features__item">*/}
                                        {/*  <span*/}
                                        {/*    className={`buy-panel__slider__features__item__title ${panel.type}`}*/}
                                        {/*  >*/}
                                        {/*    پاداش فروش بسته‌های 100 تایی KM*/}
                                        {/*  </span>*/}
                                        {/*  <span*/}
                                        {/*    className={`buy-panel__slider__features__item__counter ${panel.type}`}*/}
                                        {/*  >*/}
                                        {/*    12.500 تومان*/}
                                        {/*  </span>*/}
                                        {/*</div>*/}

                                        {/* <div className="buy-panel__slider__features__item">
                      <span
                        className={`buy-panel__slider__features__item__title ${panel.type}`}
                      >
                        پاداش 250.000 تومان فروش نقدی نمایندگان مستقیم
                      </span>
                      <span
                        className={`buy-panel__slider__features__item__counter ${panel.type}`}
                      >
                        1 امتیاز کارتک
                      </span>
                    </div> */}

                                        {/* <div className="buy-panel__slider__features__item">
                  <span
                    className={`buy-panel__slider__features__item__title ${panel.type}`}
                  >
                    پاداش فروش
                  </span>
                  <span
                    className={`buy-panel__slider__features__item__counter ${panel.type}`}
                  >
                    {panel.sell} درصد
                  </span>
                </div>
                <div className="buy-panel__slider__features__item">
                  <span
                    className={`buy-panel__slider__features__item__title ${panel.type}`}
                  >
                    امتیاز کارتک
                  </span>
                  <span
                    className={`buy-panel__slider__features__item__counter ${panel.type}`}
                  >
                    {panel.kartaakScore} امتیاز
                  </span>
                </div> */}
                                    </div>

                                    <div
                                        onClick={() => handleBuy(panel)}
                                        className={`buy-panel__slider__action ${
                                            panel.type
                                        } ${!acceptedRule && "disable"}`}
                                    >
                                        <span>خرید پنل</span>
                                        <SVG svg="panel" />
                                    </div>
                                </div>

                                {/* {panel.type === "gold" && (
                  <section className="buy-panel__slider__more">
                    <div className="buy-panel__slider__more__title">
                      امتیازات فروش این پنل
                    </div>

                    <div className="buy-panel__slider__more__seperator" />

                    <div className="buy-panel__slider__more__item">
                      <div className="buy-panel__slider__more__value">
                        {" "}
                        {panel.sell} درصد
                      </div>
                      <div className="buy-panel__slider__more__key">
                        <span>پاداش فروش</span>
                      </div>
                    </div>
                    <div className="buy-panel__slider__more__item">
                      <div className="buy-panel__slider__more__value">
                        {panel.kartaakScore} امتیاز
                      </div>
                      <div className="buy-panel__slider__more__key">
                        <span>امتیاز کارتک</span>
                      </div>
                    </div>

                    <div className="buy-panel__slider__more__item">
                      <div className="buy-panel__slider__more__value">
                        {panel.inviterPercentage} درصد
                      </div>
                      <div className="buy-panel__slider__more__key">
                        <span>پاداش معرف</span>
                      </div>
                    </div>
                  </section>
                )} */}
                            </>
                        )
                )}
            </Slider>

            <div className="row a-c j-c" style={{ margin: "8px 12px" }}>
                <CheckBox
                    defaultValue={acceptedRule}
                    type="check"
                    onChange={(value) => setAcceptedRule(value)}
                />
                <AcceptRule
                    title="خرید پنل نمایندگی"
                    color="dark"
                    onCLick={() => setShowAlert(true)}
                />
            </div>

            <Modal
                active={modalStatus}
                close={() => handleCloseModal()}
                fullWidth
            >
                <div className="buy-panel__modal">
                    <div className="buy-panel__modal__header">
                        <span>{`خرید  ${selectedPanel.name}`}</span>
                    </div>
                    {confirm ? (
                        <div className="buy-panel__modal__confirm">
                            <span className="buy-panel__modal__confirm__title">
                                آیا بدون معرف ادامه می‌دهید؟
                            </span>
                            <div className="buy-panel__modal__confirm__action">
                                <div
                                    onClick={() => handlePay()}
                                    className="buy-panel__modal__confirm__action__submit"
                                >
                                    {loadingPay ? (
                                        <Dots color="light" />
                                    ) : (
                                        "تایید"
                                    )}
                                </div>
                                <div
                                    onClick={() => setConfirm(false)}
                                    className="buy-panel__modal__confirm__action__cancel"
                                >
                                    انصراف
                                </div>
                            </div>
                        </div>
                    ) : (
                        <>
                            {toggleCode && !hasInviteCode && (
                                <div className="buy-panel__modal__code">
                                    <span className="buy-panel__modal__code__title">
                                        کد یا شماره موبایل معرف
                                    </span>
                                    {/*<div className="buy-panel__modal__code__wrapper">*/}
                                    {/*  <input*/}
                                    {/*    value={code}*/}
                                    {/*    className="buy-panel__modal__code__wrapper__input"*/}
                                    {/*    placeholder="اختیاری"*/}
                                    {/*    onChange={(e) => setCode(e.target.value)}*/}
                                    {/*  />*/}
                                    {/*</div>*/}
                                    <div className="row w-100">
                                        <input
                                            type="text"
                                            className="input"
                                            value={inviterCode}
                                            onChange={(e) =>
                                                setInviterCode(
                                                    farsiDigitToEnglish(
                                                        e.target.value
                                                    )
                                                )
                                            }
                                            placeholder="شماره موبایل یا کد کارتک معرف"
                                        />
                                        <div
                                            className={`create-shop__inviter__button ${
                                                inviteCodeStatus && "edit"
                                            } ${
                                                inviterCode.length === 0 &&
                                                "hidden"
                                            }`}
                                            onClick={() => {
                                                if (inviteCodeStatus) {
                                                    setInviterCode("");
                                                    setInviteCodeStatus(false);
                                                } else {
                                                    handleSubmitInviteCode();
                                                }
                                            }}
                                        >
                                            {inviteCodeLoading ? (
                                                <Dots color="light" />
                                            ) : inviteCodeStatus ? (
                                                "ویرایش"
                                            ) : (
                                                "ثبت"
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )}
                            <div className="buy-panel__modal__factor">
                                <span className="buy-panel__modal__factor__title">
                                    فاکتور
                                </span>
                                <div className="buy-panel__modal__factor__time">
                                    <SVG svg="panel-duration" />
                                    <span>{`مدت اعتبار پنل از زمان خرید ${selectedPanel.time} ماه است`}</span>
                                </div>

                                <div className="buy-panel__modal__factor__km">
                                    <CheckBox
                                        type="check"
                                        onChange={(value) => {
                                            if (user.whiteKm === 0) {
                                                toast.error(
                                                    "شما KM برای استفاده ندارید."
                                                );
                                            } else {
                                                setPaymentType(
                                                    value ? "km" : "cash"
                                                );
                                            }
                                        }}
                                    />
                                    <span>{`خرید با بسته های 25 تایی KM`}</span>
                                </div>

                                {paymentType == "km" ? (
                                    <>
                                        <div
                                            className={`buy-panel__modal__factor__count ${
                                                paymentType == "km"
                                                    ? "show"
                                                    : "hide"
                                            }`}
                                        >
                                            <CounterInput
                                                step={1}
                                                max={whiteKmCountPack}
                                                defaultValue={whiteKmCountPack}
                                                onChange={(value) =>
                                                    onChangeWhiteKmCountPackage(
                                                        value
                                                    )
                                                }
                                                packageMode={true}
                                            />
                                            <div className="row a-c j-sb mt-16">
                                                <span className="buy-panel__modal__factor__white-km__count__title">
                                                    تعداد KM :
                                                </span>
                                                <span className="buy-panel__modal__factor__white-km__count__value">
                                                    {whiteKmCount}
                                                </span>
                                            </div>
                                        </div>
                                        <span className="buy-panel__modal__factor__price__title mt-10 row a-c g-4">
                                            مبلغ قابل پرداخت
                                            <span className="buy-panel__modal__factor__price__title unit">
                                                (تومان)
                                            </span>
                                            :
                                        </span>

                                        <div className="buy-panel__modal__factor__price j-fe">
                                            <span className="buy-panel__modal__factor__price__value">
                                                {selectedPanel.price -
                                                    whiteKmCount * whiteKmPrice}
                                            </span>
                                            <span className="buy-panel__modal__factor__price__value">
                                                ={" "}
                                                {transformPrice(
                                                    whiteKmCount * whiteKmPrice
                                                )}{" "}
                                                -{" "}
                                                {transformPrice(
                                                    selectedPanel.price
                                                )}
                                            </span>
                                        </div>
                                    </>
                                ) : (
                                    <div className="buy-panel__modal__factor__price j-sb">
                                        <span className="buy-panel__modal__factor__price__title">
                                            مبلغ قابل پرداخت :
                                        </span>
                                        <span className="buy-panel__modal__factor__price__value">
                                            {`${
                                                selectedPanel.price == 0
                                                    ? "رایگان"
                                                    : `${transformPrice(
                                                          selectedPanel.price
                                                      )}`
                                            }`}{" "}
                                            تومان
                                        </span>
                                    </div>
                                )}
                            </div>
                            <div
                                onClick={() =>
                                    code.length !== 0
                                        ? handlePay()
                                        : handleConfirm()
                                }
                                className="buy-panel__modal__action"
                            >
                                {loadingPay ? (
                                    <Dots color="light" />
                                ) : (
                                    <>
                                        <span>
                                            {selectedPanel.name == "پنل سفید"
                                                ? "فعالسازی"
                                                : "تایید و پرداخت"}
                                        </span>
                                        <SVG svg="panel" />
                                    </>
                                )}
                            </div>
                        </>
                    )}
                </div>
            </Modal>
        </>
    );
};
