import { useState } from 'react';
import { defaults, Chart, Bar } from 'react-chartjs-2';
import zoomPlugin from 'chartjs-plugin-zoom';
import './BarChart.scss';

Chart.register(zoomPlugin);

export const BarChart = ({ chartData, max_x = 6 }) => {
  defaults.font.family = 'VazirFaNum';

  return (
    <Bar
      data={chartData}
      options={{
        responsive: true,
        scales: {
          x: {
            max: max_x
          },
          y: {
            // beginAtZero: true,
            ticks: {
              stepSize: 1
            }
          }
        },
        plugins: {
          zoom: {
            zoom: {
              wheel: {
                enabled: false,
              },
              pinch: {
                enabled: false,
              },
              mode: 'x',
              drag: {
                enabled: true
              }
            },
            pan: {
              // enabled: true,
              // overScaleMode: 'x'
            },
            limits: {
            },
          }
        }
      }}
      className="bar-chart"
      height={350}
    />
  );
}