import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { SVG } from "components";
import "./Compare.scss";
import ProductPlaceholderImage from "../../assets/images/product.png";
import { devIp, getFeatureGroupProductsAPI } from "../../utils/Services/API";

export const Compare = ({
  setShow,
  active = false,
  productDetail,
  variation,
}) => {
  const [searchInput, setSearchInput] = useState("");
  const [productsList, setProductsList] = useState([]);

  const getProductList = async () => {
    try {
      if (productDetail && productDetail.feature_group_id) {
        const response = await getFeatureGroupProductsAPI(
          productDetail.feature_group_id
        );
        if (response.status === 200) {
          setProductsList(response.data.data);
        } else {
          setProductsList([]);
        }
      }
    } catch (error) {
      return;
    }
  };

  const handleSearchInput = (value) => {
    setSearchInput(value);
    document
      .getElementsByClassName("add-compare")[0]
      .scrollTo(
        0,
        value !== ""
          ? document.getElementById("product-list").offsetTop - 155
          : 0
      );
  };

  useEffect(() => {
    getProductList();
  }, [productDetail]);

  const showProductListItems = () => {
    const searchedProducts = productsList.filter((product) =>
      product.title.toLowerCase().includes(searchInput.toLowerCase())
    );
    if (searchedProducts.length > 0) {
      return searchedProducts.map((product, index) => (
        <Link
          to={`/compare?${productDetail.id},${product.id}`}
          key={index}
          className="add-compare__product-item"
        >
          <div className="add-compare__product-item__info">
            <div className="add-compare__product-item__info__img">
              <img
                id={index}
                src={`${productDetail.image[0]}`}
                // src={`data:image/png;base64,${productDetail.image[0]}`}
                alt=""
                onError={(e) =>
                  (document.getElementById(index).src = ProductPlaceholderImage)
                }
              />
            </div>
            <div className="add-compare__product-item__name">
              {product.title}
            </div>
          </div>
          <SVG svg="arrow-small" />
        </Link>
      ));
    } else {
      return (
        <div className="add-compare__product-list__empty">
          محصولی برای مقایسه یافت نشد
        </div>
      );
    }
  };

  return (
    <div className={`add-compare ${active && "active"}`}>
      <div className="add-compare__header">
        <div className="add-compare__header__topbar">
          مقایسه‌ی محصول
          <div onClick={() => setShow(false)}>
            <SVG svg="times" />
          </div>
        </div>
        <input
          type="text"
          value={searchInput}
          onChange={(e) => handleSearchInput(e.target.value)}
          className="input"
          placeholder="جست و جو در میان محصولات مرتبط"
        />
      </div>

      <div className="add-compare__body">
        <div className="add-compare__chosen">
          <div className="add-compare__chosen__title">محصول انتخاب شده</div>
          <div className="add-compare__chosen__product">
            <div className="add-compare__chosen__product__info">
              <div className="add-compare__chosen__product__info__img">
                <img
                  id="chosen-product-image"
                  src={
                    productDetail.image &&
                    `${productDetail.image[0]}`
                  }
                  // src={
                  //   productDetail.image &&
                  //   `data:image/png;base64,${productDetail.image[0]}`
                  // }
                  alt=""
                  onError={(e) =>
                  (document.getElementById("chosen-product-image").src =
                    ProductPlaceholderImage)
                  }
                />
              </div>
              <div className="add-compare__chosen__product__name">
                {productDetail.title}
              </div>
            </div>
            <div className="add-compare__chosen__product__price">
              {variation.price}
              <span>تومان</span>
            </div>
          </div>
        </div>

        <div classNam="add-compare__product-list" id="product-list">
          <div className="add-compare__product-list__title">مقایسه با</div>
          <div className="add-compare__product-list__container">
            {productsList.length > 0 ? (
              showProductListItems()
            ) : (
              <div className="add-compare__product-list__empty">
                محصولی برای مقایسه یافت نشد
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
