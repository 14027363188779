import React, { useEffect, useState } from "react";
import { PageTopbar, SVG, Card, PanelBox, Fade, Spinner } from "components";
import { QrBox, NotFound, Identifier } from "./dependencies";
import { useHistory } from "react-router-dom";
import { advertisePanel } from "utils/Services";

export const MarketingContainer = () => {
  const history = useHistory();
  const [userPanelData, setUserPanelData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    history.replace("/marketing");
    getUserPanel();
  }, []);
  const getUserPanel = () => {
    advertisePanel()
      .then((res) => {
        if (res) {
          setUserPanelData(res.data.panels);
          setLoading(false);
        } else return;
      })
      .catch((err) => console.log(err));
  };
  return (
    <Fade>
      <div className="marketing">
        <Spinner active={loading} color="dark" />
        <PageTopbar title="نمایندگی کارتک" toggleBackward={false} />
        {/* <Card>
          <Identifier />
        </Card> */}
        <Card>
          {userPanelData.length > 0 ? (
            <React.StrictMode>
              <div className="marketing__panel__title">
                <span>پنل من</span>
              </div>
              {userPanelData.map((panel) => (
                <PanelBox data={panel} />
              ))}
            </React.StrictMode>
          ) : // <NotFound />
          null}
          {/* <div
            className="marketing__collab"
            onClick={() => history.push("/collaboration")}
          >
            <SVG svg="partners" />
            همکاران من
            <SVG svg="arrow" />
          </div> */}
          {/* <QrBox /> */}
        </Card>
      </div>
    </Fade>
  );
};
