import { useEffect, useState } from "react";
import Checkout from "../components/Checkout/Checkout";
import { Card, Dots, Modal, SVG, UpdateUserInfo } from "components";
import { Link, useHistory } from "react-router-dom";
import {
    decrementCartAPI,
    getCartAPI,
    incrementCartAPI,
    removeFromCartAPI,
} from "../utils/Services/API";
import { Spinner } from "components";
import toast from "react-hot-toast";
import { Fade } from "components";
import { transformPrice } from "utils/Functions";
import { useCart, useUser } from "hooks";

const Carts = () => {
    const user_token = localStorage.getItem("user_token");
    const { setCartData } = useCart();
    const [showCheckout, setShowCheckout] = useState(false);
    const [cart, setCart] = useState([]);
    const [loading, setLoading] = useState(true);
    const [cartLoading, setCartLoading] = useState(-1);
    const history = useHistory();

    console.log(setCartData);

    const {
        getUserDataFromApi,
        userState: { user },
    } = useUser();

    const [showFillProfileModal, setShowFillProfileModal] = useState(false);
    const [showFillProfile, setShowFillProfile] = useState(false);
    const [userInfo, setUserInfo] = useState({
        name: "",
        lastname: "",
        nationalCode: "",
    });

    const UserInfoHandler = () => {
        if (
            user.name?.length === 0 ||
            user.name === null ||
            user.lastname?.length === 0 ||
            user.lastname === null
        ) {
            setShowFillProfile(true);
        } else {
            setShowFillProfile(false);
        }
    };

    useEffect(() => {
        setUserInfo({
            name: user.name,
            lastname: user.lastname,
            nationalCode: user.nationalCode,
        });
        UserInfoHandler();
    }, [user]);

    const getCart = () => {
        const APIResult = getCartAPI(user_token);
        APIResult.then((response) => {
            console.log(response);
            if (response.status === 401 || response.status === 404) {
                setLoading(false);
                setCartLoading(-1);
                return;
            }
            setCart(response.data.data.cart[0]);
            setCartData(response.data.data.cart[0]);
            setLoading(false);
            setCartLoading(-1);
        });
    };

    const handleShowTotalPrice = () => {
        let totalPrice = 0;
        cart.items.forEach((cartItem) => {
            totalPrice +=
                (cartItem.price - cartItem.discount) * Number(cartItem.count);
        });
        return transformPrice(totalPrice);
    };

    const handleCartOperator = (type, cartInfo, cartItem) => {
        setCartLoading(cartItem.id);

        switch (type) {
            case "inc":
                const incrementAPIResut = incrementCartAPI(
                    user_token,
                    cartInfo.id,
                    cartItem.id
                );
                incrementAPIResut.then((response) => {
                    if (response.status === 200) {
                        if (response.data.msg === "max_buy") {
                            toast.error(
                                "محدودیت خرید این کالا برای شما به پایان رسیده است."
                            );
                            setCartLoading(-1);
                            return;
                        }
                    }
                    if (response.status === 204) {
                        toast("محدودیت انتخاب این کالا به اتمام رسیده است.");
                        setCartLoading(-1);
                        return;
                    }
                    getCart();
                });
                break;

            case "dec":
                if (Number(cartItem.count) === 1) {
                    handleRemoveFromCart(cartInfo, cartItem);
                    return;
                }
                const decrementAPIResult = decrementCartAPI(
                    user_token,
                    cartInfo.id,
                    cartItem.id
                );
                decrementAPIResult.then((response) => {
                    getCart();
                });
                break;

            default:
                break;
        }
    };

    const handleRemoveFromCart = (cartInfo, cartItem) => {
        setCartLoading(cartItem.id);
        const APIResult = removeFromCartAPI(
            user_token,
            cartInfo.id,
            cartItem.id
        );
        APIResult.then((response) => {
            if (response.status === 200) {
                toast.success("کالا از سبد خريد حذف شد.");
                getCart();
            }
        });
    };

    useEffect(() => {
        getCart();
        history.replace("/carts");
    }, []);

    return (
        <div className="cart-new wrapper-fix">
            <Spinner active={loading} color="dark" />
            {!loading && cart?.detail && cart?.detail?.length > 0 ? (
                <Fade>
                    <Card>
                        <div className="cart-new__header">
                            {/* <div className="cart-new__back">
              <SVG svg="arrow-big" />
            </div> */}
                            <div className="cart-new__title">سبد خريد</div>
                            <div
                                className="cart-new__shop-btn"
                                onClick={() => {
                                    history.push("/market");
                                }}
                            >
                                بازگشت به پاساژ
                            </div>
                            {/* <div className="cart-new__kv">
            <div className="new-checkout__kv__logo"><span>KV</span></div>
              <div className="cart-new__kv__amount">14</div>
            </div> */}
                            {/* <KV number={14} /> */}
                        </div>
                        {cart.detail.length > 0 &&
                            cart.detail.map((item, index) => (
                                <div
                                    className="cart-new__carts-list"
                                    onClick={() =>
                                        history.push(`/cart/${item.id}`)
                                    }
                                >
                                    {/* <div className="cart-new__carts-list"> */}
                                    <div className="cart-new__body">
                                        <div className="cart-new__item">
                                            <div className="cart-new__item__detail">
                                                <div className="cart-new__item__info">
                                                    <div className="cart-new__item__top">
                                                        <div className="flex j-sb a-c w-100">
                                                            <div className="cart-new__item__title">
                                                                فروشگاه:{" "}
                                                                {item.seller}
                                                            </div>
                                                            <div className="cart-new__item__number">
                                                                تعداد محصول:{" "}
                                                                {
                                                                    item.items
                                                                        .length
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="cart-new__products">
                                                        {item.items.map(
                                                            (prod) => {
                                                                return (
                                                                    <div className="cart-new__products__details">
                                                                        <img
                                                                            src={
                                                                                prod
                                                                                    .detail
                                                                                    .Product
                                                                                    .image[0]
                                                                            }
                                                                            alt=""
                                                                        />
                                                                        <div>
                                                                            <span>
                                                                                {
                                                                                    prod
                                                                                        .detail
                                                                                        .Product
                                                                                        .title
                                                                                }
                                                                            </span>
                                                                            <br />
                                                                            <span>
                                                                                تعداد
                                                                                :{" "}
                                                                                {
                                                                                    prod.count
                                                                                }
                                                                            </span>
                                                                            <br />
                                                                            <span>
                                                                                قیمت
                                                                                :{" "}
                                                                                {transformPrice(
                                                                                    Number(
                                                                                        prod.price
                                                                                    )
                                                                                )}{" "}
                                                                                تومان
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            }
                                                        )}
                                                    </div>
                                                    <div className="cart-new__item__bottom">
                                                        <div className="cart-new__item__price">
                                                            <span></span>
                                                            {/* <span>هزینه ارسال : {item.amountPayable[1] != 0 ? transformPrice(Number(+item.amountPayable[1])) + " تومان" : " رایگان"} </span> */}
                                                            <span>
                                                                جمع کل :{" "}
                                                                {transformPrice(
                                                                    Number(
                                                                        +item
                                                                            .amountPayable[0]
                                                                    )
                                                                )}{" "}
                                                                تومان
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/*
            <div className="cart-new__not-available">
              <div className="cart-new__not-available__text-wrapper">
                <div className="cart-new__not-available__icon">
                  <SVG svg="circle" />
                </div>
                <div className="cart-new__not-available__title">
                  <span>1</span>
                  کالا از سبد خرید شما ناموجود شد
                </div>
              </div>
              <div className="cart-new__not-available__button">فهمیدم</div>
            </div> */}
                                    </div>
                                </div>
                            ))}
                    </Card>

                    {true && (
                        <div className="cart-new__final">
                            <div
                                className="cart-new__order-button"
                                onClick={() => {
                                    if (showFillProfile) {
                                        setShowFillProfileModal(true);
                                    } else {
                                        setShowCheckout(true);
                                    }
                                }}
                            >
                                <div className="cart-new__shape">
                                    <SVG svg="bottom-shape" color="#21BBE1" />
                                </div>

                                <div className="cart-new__shape reverse">
                                    <SVG svg="bottom-shape" color="#ffffff" />
                                </div>

                                <div className="cart-new__order-button__title">
                                    ثبت سفارش و خرید
                                </div>
                                <div className="cart-new__order-button__icon">
                                    <SVG svg="payment-cart" />
                                </div>
                            </div>
                            <div className="cart-new__final-price">
                                {transformPrice(Number(cart.finalPrice))} تومان
                            </div>
                        </div>
                    )}

                    <Checkout
                        active={showCheckout}
                        setClose={(close) => setShowCheckout(close)}
                        currCart={cart.detail}
                        finalPrice={transformPrice(Number(cart.finalPrice))}
                        finalPriceEnd={transformPrice(
                            Number(cart.finalPriceEnd)
                        )}
                        fullCartDetails={cart}
                        updateCurrCart={() => {
                            getCart();
                        }}
                    />

                    {/* <BottomNavigation /> */}
                    {/* </Padding> */}
                </Fade>
            ) : (
                <div className="cart-new__empty-wrapper">
                    <div className="cart-new__empty">
                        <div className="cart-new__empty__icon">
                            <SVG svg="cart-big" />
                        </div>
                        <div className="cart-new__empty__title">
                            سبد خرید شما خالی است
                        </div>
                        <div className="cart-new__empty__subtitle">
                            با گشتن در پاساژ محصولات مورد علاقه‌تان را پیدا کنید
                        </div>
                    </div>
                    <Link className="cart-new__empty-button" to="/market">
                        <div className="cart-new__empty-button__title">
                            <div className="cart-new__empty-button__icon cart">
                                <SVG svg="cart-small" />
                            </div>
                            پاساژ
                        </div>
                        <div className="cart-new__empty-button__icon">
                            <SVG svg="arrow-big-white" />
                        </div>
                    </Link>
                </div>
            )}

            <UpdateUserInfo
                showUpdate={showFillProfileModal}
                setShowUpdate={(value) => {
                    value && setShowCheckout(true);
                    setShowFillProfileModal(false);
                }}
                userInfo={userInfo}
                setUserInfo={setUserInfo}
                getUser={getUserDataFromApi}
            />
        </div>
    );
};

export default Carts;
