import React, { useEffect, useState } from "react";
import { cloneDeep } from "lodash";
import { getUserQuestions, setUserQuestion } from "../utils/Services/API";
import NewMessageSound from "../assets/sounds/new.mp3";
import { returnStickerImage, stickersImages } from "../utils/Stickers";
import { commandItems } from "../utils/Commands";
import { Prompt, useHistory } from "react-router-dom";
import { Fade, Spinner, SVG, Padding, PageTopbar } from "components";
import { useUser } from "hooks";

const Support = () => {
    const {
        userState: { user },
    } = useUser();
    const [messages, setMessages] = useState([
        // {
        //   massage: 'سلام و عرض ادب و خسته نباشید. چطوری می‌تونم از کارتک خرید داشته باشم؟',
        //   created_at: '09:43',
        //   type: 'user'
        // },
        // {
        //   massage: 'الوووووو پس چرا جواب نمیدین؟؟؟؟؟؟؟',
        //   created_at: '12:46',
        //   type: 'user'
        // },
        // {
        //   massage: 'سلام و وقت بخیر خدمت کاربر عزیز، آقای کیوی پسند. ببینید شما باید برید میوه فروش بگید من بادمجان میخوام و موز خریداری کنید تا با ضربه رونالدینیو همه رو غافل گیر کنید. ممنون از اینکه همراه ما هستید دوست عزیز و گرانقدر کارتکی.',
        //   created_at: '13:59',
        //   type: 'operator'
        // },
    ]);
    const [inputValue, setInputValue] = useState("");
    const [loading, setLoading] = useState(true);
    const [showSticker, setShowSticker] = useState(false);
    const [showCommand, setShowCommand] = useState(false);
    const [supportColor, setSupportColor] = useState("#505f79");
    const [typingStatus, setTypingStatus] = useState(false);
    const [audio] = useState(new Audio(NewMessageSound));

    const history = useHistory();

    useEffect(() => {
        history.replace("/support");

        const currSupportColor = JSON.parse(
            localStorage.getItem("support_color")
        );
        if (currSupportColor !== null) {
            setSupportColor(currSupportColor.color);
        }
    }, []);

    const handleSubmitMessage = (sticker = undefined) => {
        const userToken = localStorage.getItem("user_token");

        if (inputValue.replace(/\s/g, "") === "" && sticker === undefined) {
            return;
        }
        const date = new Date();

        const serverQuestionResponse = setUserQuestion(
            userToken,
            sticker !== undefined ? sticker : inputValue
        );
        serverQuestionResponse.then((response) => {});

        const currMessages = cloneDeep(messages);
        const newMessages = currMessages.concat({
            massage: sticker !== undefined ? sticker : inputValue,
            created_at: date,
            type: "user",
        });
        setMessages(newMessages);
        setInputValue("");
        if (sticker !== undefined) document.getElementById("chat_input").blur();
        else document.getElementById("chat_input").focus();
        setShowSticker(false);
        setShowCommand(false);
    };

    const getMessages = () => {
        const userToken = localStorage.getItem("user_token");

        const serverMessagesResponse = getUserQuestions(userToken);
        serverMessagesResponse.then((response) => {
            if (response.status === 404) {
                setMessages([]);
                setLoading(false);
                return null;
            }
            if (response.status >= 400) {
                return null;
            } else {
                setMessages(response.data.data[0]);
                setLoading(false);
            }
        });
    };

    const isSticker = (str, type) => {
        if (
            type === "bool" &&
            (str === "[flowers]" ||
                str === "[heart]" ||
                str === "[question]" ||
                str === "[angry]" ||
                str === "[hello]")
        )
            return true;
        else return returnStickerImage(str);
    };

    const handleChangeColor = (color) => {
        setSupportColor(color);
    };

    const handleBorderRadius = (index, type) => {
        if (type === "operator") {
            if (
                messages[index + 1] !== undefined &&
                messages[index + 1].type === "operator" &&
                messages[index - 1] !== undefined &&
                messages[index + -1].type === "operator"
            )
                return "10px 30px 30px 10px";

            if (
                messages[index + 1] !== undefined &&
                messages[index + 1].type !== "operator"
            )
                return "10px 30px 30px 30px";

            if (messages[index + 1] === undefined) return "10px 30px 30px 30px";
        } else if (type === "user") {
            if (
                messages[index + 1] !== undefined &&
                messages[index + 1].type === "user" &&
                messages[index - 1] !== undefined &&
                messages[index + -1].type === "user"
            )
                return "30px 10px 10px 30px";

            if (
                messages[index + 1] !== undefined &&
                messages[index + 1].type !== "user"
            )
                return "30px 10px 30px 30px";

            if (messages[index + 1] === undefined) return "30px 10px 30px 30px";
        }
    };

    useEffect(() => {
        setTimeout(() => {
            window.scrollTo(0, document.body.scrollHeight);
            if (messages[messages.length - 1] !== undefined)
                if (
                    messages[messages.length - 1].operator_id !== null &&
                    messages[messages.length - 1].operator_id !== undefined
                ) {
                    if (user.detail.isSound) {
                        audio.play();
                    }
                }
        }, 50);
        // eslint-disable-next-line
    }, [messages.length]);

    useEffect(() => {
        getMessages();
        const interval = setInterval(() => {
            getMessages();
        }, 5000);

        return () => clearInterval(interval);
    }, []);

    const preWrittenMessages = ["کارتلند", "تماس", "دعوت", "نمایندگی"];

    const directMessageHandler = (value) => {
        const userToken = localStorage.getItem("user_token");
        setUserQuestion(userToken, value).then(() => {
            getMessages();
        });
    };

    return (
        <div
            className="chat"
            onKeyDown={(e) => e.key === "Enter" && handleSubmitMessage()}
        >
            <Spinner active={loading} color="dark" />
            <Fade>
                <audio src={NewMessageSound} autoPlay={false}></audio>
                <PageTopbar
                    title="پشتیبانی"
                    colorModalWithCall
                    colorModalTitle="رنگ پیام ها"
                    setColor={(color) => handleChangeColor(color)}
                    showKv={false}
                />

                <Padding>
                    {messages.length === 0 ? (
                        <div className="not-found">پیامی یافت نشد.</div>
                    ) : (
                        messages.map((message, index) => (
                            <div
                                className={`chat__row ${message.type}`}
                                key={index}
                                onClick={() => {
                                    document
                                        .getElementById("chat_input")
                                        .blur();
                                    setShowSticker(false);
                                    setShowCommand(false);
                                }}
                            >
                                <div
                                    className={`chat__message ${message.type} ${
                                        isSticker(message.massage, "bool") &&
                                        "sticker"
                                    }`}
                                    style={{
                                        backgroundColor:
                                            message.type === "operator" &&
                                            supportColor,
                                        borderRadius: handleBorderRadius(
                                            index,
                                            message.type
                                        ),
                                    }}
                                >
                                    {isSticker(message.massage, "bool") ? (
                                        <img
                                            src={isSticker(
                                                message.massage,
                                                "image"
                                            )}
                                            alt=""
                                        />
                                    ) : (
                                        <span>{message.massage}</span>
                                    )}
                                    <div className="chat__message__time">
                                        {new Date(
                                            Date.parse(message.created_at)
                                        ).getHours()}
                                        :
                                        {new Date(
                                            Date.parse(message.created_at)
                                        ).getMinutes()}
                                    </div>
                                </div>
                            </div>
                        ))
                    )}
                </Padding>

                <div className={`chat__input`}>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "100%",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <div
                            style={{
                                maxWidth: "768px",
                                padding: "5px 5px 8px",
                            }}
                            className="home__categories__container"
                        >
                            {preWrittenMessages.map((message) => (
                                <div className="home__categories__item">
                                    <div
                                        style={{
                                            fontSize: "13px",
                                            color: "white",
                                            backgroundColor: "#5e61ed",
                                            cursor: "pointer",
                                            padding: "0.3rem 1.2rem",
                                        }}
                                        className="home__categories__item__title"
                                        onClick={(e) =>
                                            directMessageHandler(message)
                                        }
                                    >
                                        {message}
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                width: "100%",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <div
                                className={`chat__input__container ${
                                    typingStatus && "active"
                                }`}
                            >
                                <div
                                    className="chat__input__send"
                                    onClick={() => handleSubmitMessage()}
                                >
                                    <SVG svg="send" />
                                </div>
                                <input
                                    id="chat_input"
                                    type="text"
                                    placeholder="چطور می‌تونیم کمکتون کنیم؟"
                                    value={inputValue}
                                    onChange={(e) =>
                                        setInputValue(e.target.value)
                                    }
                                    onBlur={() => setTypingStatus(false)}
                                    onFocus={() => setTypingStatus(true)}
                                    autoComplete="off"
                                />
                                {/* <div
              className="chat__input__command"
              onClick={() => {
                setShowCommand(!showCommand);
                setShowSticker(false);
              }}
            >
              <SVG svg="command" />
            </div> */}
                                <div
                                    className="chat__input__sticker"
                                    onClick={() => {
                                        setShowSticker(!showSticker);
                                        setShowCommand(false);
                                    }}
                                >
                                    <SVG svg="emoj" color="#625050" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={`chat__stickers ${showSticker && "active"}`}>
                    {stickersImages.map((stickerItem, index) => (
                        <div
                            className="chat__stickers__item"
                            key={index}
                            onClick={() =>
                                handleSubmitMessage(stickerItem.title)
                            }
                        >
                            <img src={stickerItem.src} alt="" />
                        </div>
                    ))}
                </div>

                <div className={`chat__commands ${showCommand && "active"}`}>
                    {commandItems.map((commandItem, index) => (
                        <div
                            className="chat__commands__item"
                            key={index}
                            onClick={() => {
                                setInputValue(commandItem.slug);
                            }}
                        >
                            <div className="chat__commands__title">
                                {commandItem.title}
                            </div>
                            <div className="chat__commands__slug">
                                {commandItem.slug}
                            </div>
                        </div>
                    ))}
                </div>
            </Fade>
        </div>
    );
};

export default Support;
