import {Fade, PageTopbar, SVG} from "../../components";
import {useHistory} from "react-router-dom";
import {useEffect} from "react";


export const KartlandSellUnitHistoryContainer = () => {
  const history = useHistory()

  useEffect(() => {
    history.replace("/kartland/sell/history");
  }, []);

  return (
    <div className={"kartland"}>
      <PageTopbar title={"تاریخچه فروش واحد"} showKv={false} white={true}/>

      <div className={"kartland__user-buy-wrapper"}>

        <div className="kartland-participation__wrapper">
          <div className="kartland-participation__item">

            <div className="cash-history__checkout__item">
              <div className="row a-c j-sb">
                <div className="row a-c j-sb">
                  <div className={`cash-history__checkout__circle`}>
                    <SVG svg={``}/>
                  </div>
                  <div className="kartland-participation__item__price">
                    واحد 1
                  </div>
                </div>

                <div className="cash-history__checkout__item__date">
                  اصفهان ، خورزوق
                </div>
              </div>


              <div className="cash-history__checkout__item__info">
                <div>

                </div>
                50 عدد
              </div>


            </div>

          </div>
        </div>
      </div>
    </div>
  );
};