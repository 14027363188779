const characters = ['k', 'm', 'c', 'z', 'd', 'a', 'q', 'w', 'f'];
const sumChars = ['c', 'w', 'm'];

export const encryption = code => {
  const decryptedCode = decryptCode(code, false);
  const encryptedCode = encryptCode(decryptedCode);
  return encryptedCode;
}

const decryptCode = (code, isEncrypt) => {
  const encryptionInfo = code.substr(0, 2);
  const encryptionCode = code.substr(3, code.length);

  let decryptedCodeNumber = '';
  let decryptedCodeKey = '';
  let decryptedCurrChar = 0;

  const encryptionTypeChar = encryptionInfo.charAt(1);
  const indexOfChars = characters.indexOf(encryptionInfo.charAt(0));
  const encryptionBaseNumber = [indexOfChars + 2, indexOfChars + 2, indexOfChars + 1, indexOfChars + 4, indexOfChars + 4];

  const encryptionType = sumChars.includes(encryptionTypeChar);

  for (let index = 0; index < encryptionCode.length; index++) {
    const currChar = encryptionCode.charAt(index);
    let currCharNumber = characters.indexOf(currChar) + 2;

    isEncrypt ? decryptedCurrChar = !encryptionType ? currCharNumber - encryptionBaseNumber[index] : currCharNumber + encryptionBaseNumber[index] : decryptedCurrChar = encryptionType ? currCharNumber - encryptionBaseNumber[index] : currCharNumber + encryptionBaseNumber[index]

    if (decryptedCurrChar > 10)
      decryptedCurrChar = decryptedCurrChar % 9;
    if (decryptedCurrChar < 2)
      decryptedCurrChar += 9;

    decryptedCodeNumber = decryptedCodeNumber.concat(decryptedCurrChar);
    decryptedCodeKey = decryptedCodeKey.concat(characters[decryptedCurrChar - 2])
  }
  return decryptedCodeKey;
}

const encryptCode = code => {
  const encryptBaseNumber = characters[Math.floor(Math.random() * characters.length)];
  const encryptType = characters[Math.floor(Math.random() * characters.length)];
  const randomChar = characters[Math.floor(Math.random() * characters.length)];
  const finalCode = encryptBaseNumber + encryptType + randomChar;
  const decryptedCode = decryptCode(finalCode.concat(code), true);
  return finalCode.concat(decryptedCode);
}