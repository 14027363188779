import { SVG } from "components";

export const Hint = ({ toggle }) => {
  return (
    <div onClick={() => toggle(true)} className="km__hint">
      <SVG svg="hint" />
      <span>در مورد KM بیشتر بخوانید</span>
    </div>
  );
};
