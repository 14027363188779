import { SVG } from "components";
import { useUser } from "hooks";
import React from "react";
import { useHistory } from "react-router";
import { transformPrice } from "utils/Functions";
import "./TokenPrice.scss";

const stars = [1, 2, 3, 4, 5, 6, 7];

export const TokenPrice = ({
  active = false,
  setClose,
  data = {
    variation: {
      detail: {
        Seller: "",
      },
      priceList: [
        {
          price: "",
          kv: "",
        },
      ],
    },
  },
  type = ''
}) => {
  const {
    userState: { user },
  } = useUser();
  const history = useHistory();

  const handleShowInfo = (type, index) => {
    switch (type) {
      case 'price':
        return (
          <React.Fragment>
            <div className="token-price__item__star">
              <SVG svg="profile-star" />
              {/* {index + 1} */}
            </div>

            <div className="token-price__item__price">
              <div className="">
                <span id="title">
                  {/* قیمت با {index + 1} ستاره: */}
                </span>
                <span id="price">
                  {/* {transformPrice(star.price) + " "} */}
                </span>
                تومان
              </div>
            </div>
          </React.Fragment>
        );

      case 'kv':
        return (
          <React.Fragment>
            <div className="token-price__item__star">
              <SVG svg="profile-star" />
              {/* {index + 1} */}
            </div>

            <div className="token-price__item__price">
              <div className="">
                <span id="title">
                  {/* هدیه‌ی نقدی آزادسازی kv با {index + 1} ستاره: */}
                </span>
                <span id="price">
                  {/* {transformPrice(star.kv) + " "} */}
                </span>
                تومان
              </div>
            </div>
          </React.Fragment>
        );

      case 'all':
        return (
          <React.Fragment>
            <div>

            </div>
          </React.Fragment>
        );

      default:
        break;
    }
  }

  return (
    <div className={`token-price ${active && "active"}`}>
      <div className="token-price__container">
        <div className="token-price__header">
          <div>
            فروشنده:
            {" " + data.variation?.detail?.Seller}
          </div>

          <div
            onClick={() => setClose(true)}
            className="token-price__header__icon"
          >
            <SVG svg="times" />
          </div>
        </div>

        <div className="token-price__body">
          {/* <span className="token-price__body__hint">
            {type === 'price' ? (
              ' قیمت '
            ) : (
              ' هدیه‌ی نقدی آزادسازی KV، '
            )}
            بر اساس ستاره های پروفایل شما
          </span>
          <span className="token-price__body__slug">
            {type === 'price' ? (
              '(ستاره بیشتر = خرید ارزان تر)'
            ) : (
              '(ستاره بیشتر = هدیه‌ی نقدی بیشتر)'
            )}
          </span> */}
          {data.variation?.priceList?.map((star, index) => (
            index + 2 > user?.detail?.star && (
              <div
                className={`token-price__item ${index === user?.detail?.star - 1 && "active"
                  }`}
                key={index}
                onClick={() => history.push('/profile')}
              >
                {handleShowInfo(type, index)}
              </div>
            )
          ))}
        </div>
      </div>
    </div>
  );
};
