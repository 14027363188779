import React, {useState, useRef, useEffect} from "react";
import {PageTopbar, SVG, Selectbox, Modal, Fade, Dots} from "components";
import {
  GetDonateCondition,
  SetDonateCondition,
  DeleteDonateCondition,
  SubmitTerm,
} from "utils/Services";
import toast from "react-hot-toast";
import {useHistory, useLocation} from "react-router-dom";

export const KmTerm = () => {
  const history = useHistory();
  const location = useLocation();
  const buyCount = useRef(null);
  const kmCount = useRef(null);
  const viewCount = useRef(null);
  const name = useRef(null);
  const termType = useRef(null);
  const [modalStatus, toggleModalStatus] = useState(false);
  const [activeTerm, setActiveTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState({status: false, id: 0});
  const [conditions, setConditions] = useState([]);
  const [buyProductCount, setbuyProductCount] = useState(1);
  const [nameArray, setNameArray] = useState([]);
  const [buyCountArray, setBuyCountArray] = useState([]);
  const [kmCountArray, setKmCountArray] = useState<any>([]);
  const [rejectModal, setRejectModal] = useState<boolean>(false);
  const [rejectReason, setRejectReason] = useState(null);
  const [waitingShow, setWaitingShow] = useState(false);
  const [rejectShow, setRejectShow] = useState(false);

  function useQuery() {
    const {search} = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  let query = useQuery();

  const data = [
    {
      key: 0,
      title: "میزان خرید مشتری",
      id: "purchaseRate",
    },
    // {
    //   key: 1,
    //   title: "تعداد بازدید مشتری",
    //   id: "viewCount",
    // },
    {
      key: 2,
      title: "خرید محصول",
      id: "buyProduct",
    },
    {
      key: 3,
      title: "سایر شرایط",
      id: "moreTerm",
    },
  ];

  const handleIncreaseProductCount = () => {
    if (buyProductCount >= 3) {
      toast.error("بیشترین مقدار محصول 3 عدد می باشد.");
      return;
    }
    setbuyProductCount((prev) => prev + 1);
  };

  const buyProductNames = (e, index) => {
    const value = e.target.value;
    let arr = [...nameArray];
    arr[index] = value;
    setNameArray(arr);
  };

  const buyProductBuyCount = (e, index) => {
    const value = e.target.value;
    let arr = [...buyCountArray];
    arr[index] = value;
    setBuyCountArray(arr);
  };

  const buyProductKmCount = (e, index) => {
    const value = e.target.value;
    let arr = [...kmCountArray];
    arr[index] = value;
    setKmCountArray(arr);
  };

  const renderInputTerm = () => {
    switch (activeTerm) {
      case "purchaseRate":
        return (
          <div className="row a-c g-12">
            <input
              ref={buyCount}
              type="number"
              className="input"
              placeholder="تعداد خرید"
              onChange={(e) => (buyCount.current.value = e.target.value)}
            />
            {/*<input*/}
            {/*  ref={kmCount}*/}
            {/*  type="number"*/}
            {/*  className="input"*/}
            {/*  placeholder="تعداد KM"*/}
            {/*  onChange={(e) => (kmCount.current.value = e.target.value)}*/}
            {/*/>*/}
          </div>
        );
      // case "viewCount":
      //   return (
      //     <div className="row a-c g-12">
      //       <input
      //         ref={viewCount}
      //         type="number"
      //         className="input"
      //         placeholder="تعداد بازدید"
      //         onChange={(e) => (viewCount.current.value = e.target.value)}
      //       />
      //       <input
      //         ref={kmCount}
      //         type="number"
      //         className="input"
      //         placeholder="تعداد KM"
      //         onChange={(e) => (kmCount.current.value = e.target.value)}
      //       />
      //     </div>
      //   );
      case "buyProduct":
        return (
          <div className="col">
            {[...Array(buyProductCount).keys()].map((item, index) => (
              <>
                <span className="creation-donate__label">
                  محصول {index + 1}
                </span>
                <input
                  className="input"
                  placeholder="نام"
                  value={nameArray[index]}
                  onChange={(e) => buyProductNames(e, index)}
                />
                <div className="row a-c g-12">
                  <input
                    type="number"
                    className="input"
                    placeholder="تعداد خرید"
                    value={buyCountArray[index]}
                    onChange={(e) => buyProductBuyCount(e, index)}
                  />
                  {/*<input*/}
                  {/*  type="number"*/}
                  {/*  className="input"*/}
                  {/*  placeholder="تعداد KM"*/}
                  {/*  value={kmCountArray[index]}*/}
                  {/*  onChange={(e) => buyProductKmCount(e, index)}*/}
                  {/*/>*/}
                </div>
              </>
            ))}
          </div>
        );
      case "moreTerm":
        return (
          <div className="row a-c g-12">
            <input
              ref={termType}
              className="input"
              placeholder="نوع شرط"
              onChange={(e) => (termType.current.value = e.target.value)}
            />
            {/*<input*/}
            {/*  ref={kmCount}*/}
            {/*  type="number"*/}
            {/*  className="input"*/}
            {/*  placeholder="تعداد KM"*/}
            {/*  onChange={(e) => (kmCount.current.value = e.target.value)}*/}
            {/*/>*/}
          </div>
        );
      default:
        break;
    }
  };

  const handleSubmit = () => {
    // api call
    const payload = handlePayload();
    const activeId = convertTitleToId();
    if (activeId == null) return;
    if (
      activeId == "2" &&
      (payload.firstField == "" ||
        // payload.secondField == "" ||
        payload.thirdField == "")
    ) {
      toast.error("تمامی فیلد ها را پر نمایید");
      return;
    } else if (payload.firstField == ""
      // || payload.secondField == ""
    ) {
      toast.error("تمامی فیلد ها را پر نمایید");
      return;
    }
    setSubmitLoading(true);
    if (activeId == "2") {
      SubmitTerm(nameArray, buyCountArray, kmCountArray)
        .then((res) => {
          if (res) {
            fetchConditions();
            toast.success("با موفقیت ثبت شد");
            setTimeout(() => {
              toggleModalStatus(false);
              clear();
            }, 300);
          }
        })
        .catch((err) => {
          if (err) {
            toast.error("به مشکل برخوردیم!");
          }
        })
        .finally(() => {
          setSubmitLoading(false);
        });
      return;
    }
    SetDonateCondition(activeId, payload)
      .then((res) => {
        if (res) {
          fetchConditions();
          toast.success("با موفقیت ثبت شد");
          setTimeout(() => {
            toggleModalStatus(false);
            clear();
          }, 300);
        }
      })
      .catch((err) => {
        if (err) {
          toast.error("به مشکل برخوردیم!");
        }
      })
      .finally(() => {
        setSubmitLoading(false);
      });
  };

  const fetchConditions = () => {
    setLoading(true);
    GetDonateCondition()
      .then((res) => {
        if (res) {
          setConditions(res.data);
        }
      })
      .catch((err) => {
        if (err) toast.error("به مشکل برخوردیم!");
      })
      .finally(() => setLoading(false));
  };

  const handleDelete = (id) => {
    setLoadingDelete({...loadingDelete, status: true, id: id});
    DeleteDonateCondition(id)
      .then((res) => {
        if (res) {
          toast.success("شرط با موفقیت حذف شد");
          fetchConditions();
        }
      })
      .catch((err) => {
        if (err) toast.error("به مشکل برخوردیم!");
      })
      .finally(() =>
        setLoadingDelete({...loadingDelete, status: false, id: id})
      );
  };

  const renderTitle = (value) => {
    const convertToInt = parseInt(value);
    switch (convertToInt) {
      case 1:
        return "میزان خرید مشتری";
      // case 2:
      //   return "تعداد بازدید مشتری";
      case 2:
        return "خرید محصول";
      case 3:
        return "سایر شرایط";
      default:
        break;
    }
  };
  const convertTitleToId = () => {
    switch (activeTerm) {
      case "purchaseRate":
        return "1";
      // case "viewCount":
      //   return "2";
      case "buyProduct":
        return "2";
      case "moreTerm":
        return "3";
      default:
        return null;
        break;
    }
  };
  const handlePayload = () => {
    const activeId = convertTitleToId();
    switch (activeId) {
      case "1":
        return {
          firstField: buyCount.current.value,
          // secondField: kmCount.current.value,
          secondField: null,
          thirdField: "",
        };
      case "2":
        return {
          firstField: buyCountArray,
          // secondField: kmCountArray,
          secondField: null,
          thirdField: nameArray,
        };
      case "3":
        return {
          firstField: termType.current.value,
          // secondField: kmCount.current.value,
          secondField: null,
          thirdField: "",
        };
      default:
        break;
    }
  };

  const clear = () => {
    if (buyCount.current) buyCount.current.value = "";
    if (kmCount.current) kmCount.current.value = "";
    if (viewCount.current) viewCount.current.value = "";
    if (name.current) name.current.value = "";
    if (termType.current) termType.current.value = "";
    setActiveTerm("");
  };
  console.log(convertTitleToId());
  useEffect(() => {
    fetchConditions();
    history.replace("/myshop/give-km" + location.search);
  }, []);

  return (
    <Fade>
      <div className="creation-donate mycity">
        <Modal active={rejectModal} close={() => setRejectModal(false)}>
          <div className={"reject-modal"}>
            {rejectReason && (
              <div className={'mb-10'}>
                <div className={"reject-modal__title mb-4"}>
                  دلیل رد شدن:
                </div>
                <div className={"reject-modal__reason"}>
                  {rejectReason}
                </div>
              </div>
            )}

            <div className={"reject-modal__actions"}>
              <div onClick={() => setRejectModal(false)} className={"button disable"}>
                بازگشت
              </div>
            </div>
          </div>
        </Modal>

        <PageTopbar title="ثبت فروشگاه" showKv={false}/>
        <div className="creation-donate__wrapper">
          <div className="creation-donate__title">
            <span>شرایط اهدای KP</span>
          </div>
          <div
            onClick={() => toggleModalStatus(true)}
            className="creation-donate__condition"
          >
            <span>افزودن شرط</span>
            <SVG svg="create-ruler"/>
          </div>
          <div className="creation-term">
            {loading ? (
              <Dots color="dark"/>
            ) : (
              <>
                {conditions.map((item, index) => (
                  item.firstField && item.secondField && (
                    <div key={item.id} className="creation-term__item">
                      <div className="grow">
                    <span className="creation-term__item__title">
                      {renderTitle(item.title)}
                    </span>
                        <div className="col">
                          {item.thirdField && (
                            <div>
                          <span className="creation-term__item__label">
                            نام :
                          </span>
                              <span className="creation-term__item__value">
                            {item.thirdField}
                          </span>
                            </div>
                          )}
                          <div className="row a-c g-16">
                            <div>
                          <span className="creation-term__item__label">
                            <span>{item.title === '3' ? 'شرط :' : 'تعداد خرید :'}</span>
                          </span>
                              <span className="creation-term__item__value">
                            {item.firstField}
                          </span>
                            </div>
                          {/*  <div className="">*/}
                          {/*<span*/}
                          {/*  dir="rtl"*/}
                          {/*  className="creation-term__item__label"*/}
                          {/*>*/}
                          {/*  تعداد KM :*/}
                          {/*</span>*/}
                          {/*    <span className="creation-term__item__value">*/}
                          {/*  {item.secondField}*/}
                          {/*</span>*/}
                          {/*  </div>*/}
                          </div>
                        </div>
                      </div>

                      {query.get('edit') === 'true' &&
                        <div className="row a-c">
                          {loadingDelete.status && loadingDelete.id == item.id ? (
                            <Dots color="dark"/>
                          ) : (
                            <div onClick={() => handleDelete(item.id)}>
                              <SVG svg="delete-contact"/>
                            </div>
                          )}
                        </div>}
                    </div>
                  )
                ))}

                {query.get('edit') === 'true' &&
                  <>
                    {waitingShow && <div className="title">
                      در انتظار بررسی
                    </div>}
                    {conditions.map((item, index) => (
                      (item.firstFieldStatus == 2 || item.secondFieldStatus == 2 || item.thirdFieldStatus == 2) && (
                        <div key={item.id} className="creation-term__item">
                          {waitingShow === false && setWaitingShow(true)}
                          <div className="grow">
                  <span className="creation-term__item__title">
                {renderTitle(item.title)}
                  </span>
                            <div className="col">
                              {item.thirdFieldEdit && (
                                <div>
                  <span className="creation-term__item__label">
                  نام :
                  </span>
                                  <span className="creation-term__item__value">
                {item.thirdFieldEdit}
                  </span>
                                </div>
                              )}
                              <div className="row a-c g-16">
                                <div>
                  <span className="creation-term__item__label">
                  <span>{item.title === '3' ? 'شرط :' : 'تعداد خرید :'}</span>
                  </span>
                                  <span className="creation-term__item__value">
                {item.firstFieldEdit}
                  </span>
                                </div>
                                <div className="">
                  <span
                    dir="rtl"
                    className="creation-term__item__label"
                  >
                  تعداد KP :
                  </span>
                                  <span className="creation-term__item__value">
                {item.secondFieldEdit}
                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/*<div className="row a-c">*/}
                          {/*  {loadingDelete.status && loadingDelete.id == item.id ? (*/}
                          {/*    <Dots color="dark"/>*/}
                          {/*  ) : (*/}
                          {/*    <div onClick={() => handleDelete(item.id)}>*/}
                          {/*      <SVG svg="delete-contact"/>*/}
                          {/*    </div>*/}
                          {/*  )}*/}
                          {/*</div>*/}
                        </div>
                      )
                    ))}
                    {rejectShow && <div className="title">
                      رد شده ها
                    </div>}
                    {conditions.map((item, index) => (
                      (item.firstFieldStatus == 3 || item.secondFieldStatus == 3 || item.thirdFieldStatus == 3) && (
                        <div key={item.id} className="creation-term__item">
                          {rejectShow === false && setRejectShow(true)}
                          <div className="grow">
                    <span className="creation-term__item__title">
                      {renderTitle(item.title)}
                    </span>
                            <div className="col">
                              {item.thirdFieldEdit && (
                                <div>
                          <span className="creation-term__item__label">
                            نام :
                          </span>
                                  <span className="creation-term__item__value">
                            {item.thirdFieldEdit}
                          </span>
                                </div>
                              )}
                              <div className="row a-c g-16">
                                <div>
                          <span className="creation-term__item__label">
                            <span>{item.title === '3' ? 'شرط :' : 'تعداد خرید :'}</span>
                          </span>
                                  <span className="creation-term__item__value">
                            {item.firstFieldEdit}
                          </span>
                                </div>
                                <div className="">
                          <span
                            dir="rtl"
                            className="creation-term__item__label"
                          >
                            تعداد KP :
                          </span>
                                  <span className="creation-term__item__value">
                            {item.secondFieldEdit}
                          </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col a-c j-se">
                            <div onClick={() => {
                              setRejectReason(item.firstFieldNotif);
                              setRejectModal(true);
                            }} className="">
                              <SVG svg="myshop-comments"/>
                            </div>
                            {loadingDelete.status && loadingDelete.id == item.id ? (
                              <Dots color="dark"/>
                            ) : (
                              <div onClick={() => handleDelete(item.id)}>
                                <SVG svg="delete-contact"/>
                              </div>
                            )}
                          </div>
                        </div>
                      )
                    ))}
                  </>}
              </>
            )}
          </div>
        </div>
        <Modal
          active={modalStatus}
          close={() => {
            toggleModalStatus(false);
            clear();
          }}
        >
          <div className="creation-donate__modal">
            <div className="creation-donate__modal__header">
              <span>افزودن شرط</span>
              {activeTerm == "buyProduct" && (
                <div
                  onClick={() => handleIncreaseProductCount()}
                  className={`creation-donate__link ${
                    buyProductCount >= 3 && "disable"
                  }`}
                >
                  <span>افزودن محصول</span>
                </div>
              )}
            </div>
            <Selectbox
              data={data}
              placeholder=" نوع شرط را انتخاب نمایید"
              onChange={(value) => {
                value !== 0 ? setActiveTerm(value) : setActiveTerm("");
              }}
              onChangeString={() => {
              }}
              resetValue
              openAll
            />
            {renderInputTerm()}
            <div
              onClick={() => handleSubmit()}
              className={`creation-term__action ${
                activeTerm == "" && "disable"
              }`}
            >
              {submitLoading ? (
                <Dots color="light"/>
              ) : (
                <>
                  <span>ثبت شرط</span>
                  <SVG svg="create-ruler"/>
                </>
              )}
            </div>
          </div>
        </Modal>
      </div>
    </Fade>
  );
};
