import {SVG, CounterInput, Input, Dots} from "components";
import {useState} from "react";
import toast from "react-hot-toast";
import {useHistory} from "react-router-dom";
import {farsiDigitToEnglish} from "utils/Functions";
import {TransferBlueKmAPI} from "../../../../utils/Services/Km/Blue/TransferToMobile";

export const Main = ({type}) => {
  const history = useHistory();
  const [kmAmount, setKmAmount] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [transferLoading, setTransferLoading] = useState(false);

  const handleSubmitTransfer = () => {
    setTransferLoading(true);
    TransferBlueKmAPI(phoneNumber, kmAmount)
      .then(response => {
        if (response.status === 200) {
          toast.success('اهدای KP با موفقیت انجام شد.');
          history.goBack();
          history.goBack();
        }
      })
      .catch(err => {
        if (err.response.status === 401) {
          toast.error('برای اهدای KP فروشگاه شما باید فعال باید');
        } else toast.error('خطا در اهدای KP');
      })
      .finally(() => {
        setTransferLoading(false);
      });
  };

  const handleDonateKm = transferType => {
    if (kmAmount.length === 0) {
      toast.error('تعداد KP را وارد نمایید');
      return;
    } else {
      history.push(`/transaction/donate?type=${type}&status=${transferType}&amount=${kmAmount}`);
    }
  };

  return (
    <>
      <div className="title">تعداد KP</div>
      <Input
        value={kmAmount}
        onChange={value => setKmAmount(farsiDigitToEnglish(value))}
      />

      <div className="title">شماره تلفن یا کد کارتک</div>
      <Input
        value={phoneNumber}
        placeholder="شماره تلفن یا کد کارتک را وارد کنید"
        onChange={value => setPhoneNumber(farsiDigitToEnglish(value))}
      />
      <div className="km-donate__actions">
        <div className="km-donate__actions__qr"
             onClick={() => !transferLoading && phoneNumber.length !== 0 && handleSubmitTransfer()}>
          {transferLoading ? (
            <Dots color="light"/>
          ) : (
            <>
              <span>انتقال</span>
              <SVG svg="km-donate"/>
            </>
          )}
        </div>
      </div>

      {/*<div className="km-donate__actions">*/}
      {/*  <div*/}
      {/*    onClick={() => handleDonateKm('phone')}*/}
      {/*    className="km-donate__actions__phone"*/}
      {/*  >*/}
      {/*    <span>انتقال از طریق شماره تلفن یا کد کارتک</span>*/}
      {/*    <SVG svg="km-phone" />*/}
      {/*  </div>*/}
      {/*  /!*<div*!/*/}
      {/*  /!*  onClick={() => handleDonateKm('qr')}*!/*/}
      {/*  /!*  className="km-donate__actions__qr"*!/*/}
      {/*  /!*>*!/*/}
      {/*  /!*  <span>ساخت QR</span>*!/*/}
      {/*  /!*  <SVG svg="km-qr" />*!/*/}
      {/*  /!*</div>*!/*/}
      {/*</div>*/}
    </>
  );
};
