import { LoginLayout } from "components/Layouts";
import { LoginContainer } from "containers";

const Login = ({ children }) => {
  return (
    <LoginLayout>
      <LoginContainer>{children}</LoginContainer>
    </LoginLayout>
  );
};

export default Login;
