import { Dots, Fade, PageTopbar, Spinner, SVG } from "../../components";
import React, { useEffect, useState } from "react";
import { currency } from "../../utils/Functions";
import { useHistory, useParams } from "react-router-dom";
import {
    getStoreByIdAPI,
    rentStoreAPI,
} from "../../utils/Services/KartlandUnits";
import empty from "../../assets/images/empty-kartland.png";
import toast from "react-hot-toast";
import dinnerImage from "../../assets/images/dinner2.png";
import moment from "jalali-moment";
import { multiStoreByIdAPI } from "../../utils";

export const KartlandStoreDetailsContainer = () => {
    const [payLoading, setPayLoading] = useState(false);
    const [storeDetails, setStoreDetails] = useState<any>(null);
    const [StoreDetailsLoading, setStoreDetailsLoading] = useState(true);
    const [data, setData] = useState([]);
    const [showAddress, setShowAddress] = useState(false);
    const [showKmCondition, setShowKmCondition] = useState(false);
    const params: any = useParams();
    const history = useHistory();

    const getStoreDetails = () => {
        setStoreDetailsLoading(true);
        multiStoreByIdAPI(params.storeId, params.type == 1 ? "p" : "i").then(
            (res) => {
                if (res.status === 200) {
                    setStoreDetails(res.data);
                    setStoreDetailsLoading(false);
                }
            }
        );
    };

    const renderTitle = (value) => {
        const convertToInt = parseInt(value);
        switch (convertToInt) {
            case 1:
                return "میزان خرید مشتری";
            case 2:
                return "تعداد بازدید مشتری";
            case 3:
                return "خرید محصول";
            case 4:
                return "سایر شرایط";
            default:
                break;
        }
    };

    const rentStore = () => {
        setPayLoading(true);
        rentStoreAPI(
            params.id,
            params.storeId,
            params.type == 1 ? "p" : "i"
        ).then((res) => {
            if (res.status === 200) {
                toast.success("فروشگاه با موفقیت الصاق شد.");
                history.push(`/kartland`);
                setPayLoading(false);
            }
        });
    };

    const roundNum = (num) => {
        if (num.toString().length < 5) return Math.floor(num / 1000) * 1000;
        if (num.toString().length === 5) return Math.floor(num / 10000) * 10000;
        if (num.toString().length === 6)
            return Math.floor(num / 100000) * 100000;
        if (num.toString().length > 6)
            return Math.floor(num / 1000000) * 1000000;
    };

    console.log(roundNum(6586963));

    useEffect(() => {
        getStoreDetails();
    }, []);

    useEffect(() => {
        history.replace(
            `/kartland/rent/${params.id}/${params.storeId}/${params.type}`
        );
    }, []);

    return (
        <div className={"kartland"}>
            <PageTopbar title="جزییات فروشگاه" showKv={false} white={true} />
            <Spinner active={StoreDetailsLoading} color="dark" />
            {storeDetails !== null ? (
                <>
                    <div className={"kartland__details"}>
                        <div className={"kartland__details__store-info"}>
                            {params.type == 1 && (
                                <div className="kartland__details__store-info__image">
                                    <img
                                        src={
                                            storeDetails?.picturestores
                                                ? storeDetails
                                                      ?.picturestores[0] !==
                                                  undefined
                                                    ? storeDetails
                                                          ?.picturestores[0]
                                                          ?.photo
                                                    : dinnerImage
                                                : dinnerImage
                                        }
                                        alt="store-image"
                                    />
                                </div>
                            )}

                            <div
                                className={
                                    "kartland__details__store-info__number"
                                }
                            >
                                <div
                                    className={
                                        "kartland__details__store-info__number__title"
                                    }
                                >
                                    نام فروشگاه:
                                </div>
                                <div
                                    onClick={() =>
                                        history.push(
                                            `/seller/${storeDetails.id}`
                                        )
                                    }
                                    className={
                                        "kartland__details__store-info__number__value"
                                    }
                                >
                                    {storeDetails.name}
                                </div>
                            </div>

                            {params.type == 1 && (
                                <div
                                    className={
                                        "kartland__details__store-info__item-row"
                                    }
                                >
                                    <div
                                        className={
                                            "kartland__details__store-info__item-row__title"
                                        }
                                    >
                                        دسته بندی:
                                    </div>
                                    <div
                                        className={
                                            "kartland__details__store-info__item-row__value"
                                        }
                                    >
                                        {storeDetails.firstCategoryName}
                                    </div>
                                </div>
                            )}

                            <div
                                className={
                                    "kartland__details__store-info__item-row"
                                }
                            >
                                <div
                                    className={
                                        "kartland__details__store-info__item-row__title"
                                    }
                                >
                                    استان:
                                </div>
                                <div
                                    className={
                                        "kartland__details__store-info__item-row__value"
                                    }
                                >
                                    {storeDetails.statename ??
                                        storeDetails.stateName}
                                </div>
                            </div>

                            <div
                                className={
                                    "kartland__details__store-info__item-row"
                                }
                            >
                                <div
                                    className={
                                        "kartland__details__store-info__item-row__title"
                                    }
                                >
                                    شهرستان:
                                </div>
                                <div
                                    className={
                                        "kartland__details__store-info__item-row__value"
                                    }
                                >
                                    {storeDetails.cityname ??
                                        storeDetails.cityName}
                                </div>
                            </div>

                            <div
                                className={
                                    "kartland__details__store-info__item-row"
                                }
                            >
                                <div
                                    className={
                                        "kartland__details__store-info__item-row__title"
                                    }
                                >
                                    شهر:
                                </div>
                                <div
                                    className={
                                        "kartland__details__store-info__item-row__value"
                                    }
                                >
                                    {storeDetails.subcityname ??
                                        storeDetails.subCityName}
                                </div>
                            </div>

                            <div
                                className={
                                    "kartland__details__store-info__item transition"
                                }
                            >
                                <div
                                    onClick={() => setShowAddress(!showAddress)}
                                    className={
                                        "kartland__details__store-info__item__title"
                                    }
                                >
                                    <div className="">آدرس</div>
                                    <div
                                        className={`kartland__details__store-info__item__title__arrow ${
                                            showAddress && "active"
                                        }`}
                                    >
                                        <SVG svg={"arrow"} />
                                    </div>
                                </div>
                                {showAddress && (
                                    <div
                                        className={
                                            "kartland__details__store-info__item__value transition"
                                        }
                                    >
                                        {storeDetails.address}
                                    </div>
                                )}
                            </div>

                            {/*<div className={"kartland__details__store-info__item"}>*/}
                            {/*  <div onClick={() => setShowKmCondition(!showKmCondition)}*/}
                            {/*       className={"kartland__details__store-info__item__title"}>*/}
                            {/*    <div className="">شرایط اهدای KM فروشگاه</div>*/}
                            {/*    <div className={`kartland__details__store-info__item__title__arrow ${showKmCondition && 'active'}`}>*/}
                            {/*      <SVG svg={'arrow'}/></div>*/}
                            {/*  </div>*/}
                            {/*  {showKmCondition &&*/}
                            {/*    <div className={"kartland__details__store-info__item__value"}>*/}
                            {/*      <div className="kartland__details__store-info__conditions">*/}
                            {/*        {storeDetails.storeConditions?.length > 0 ?*/}
                            {/*          (storeDetails.storeConditions.map((item) => (*/}
                            {/*            <div className="kartland__details__store-info__conditions__item">*/}
                            {/*              <div className="kartland__details__store-info__conditions__item__title">*/}
                            {/*                {renderTitle(item.title)}*/}
                            {/*              </div>*/}

                            {/*              <div className="kartland__details__store-info__conditions__item__name">*/}
                            {/*                {item.thirdField}*/}
                            {/*              </div>*/}

                            {/*              <div className="kartland__details__store-info__conditions__item__condition">*/}
                            {/*                {item.firstField} {item.title == 3 ? 'عدد' : ''}*/}
                            {/*              </div>*/}

                            {/*              <div className="kartland__details__store-info__conditions__item__km">*/}
                            {/*                KM {item.secondField}*/}
                            {/*              </div>*/}

                            {/*            </div>*/}
                            {/*          ))) : (*/}
                            {/*            <span className={'kartland__details__store-info__conditions__not-found'}>بدون شرایط KM</span>*/}
                            {/*          )}*/}
                            {/*      </div>*/}
                            {/*    </div>}*/}
                            {/*</div>*/}

                            {params.type == 1 && (
                                <>
                                    <div
                                        className={
                                            "kartland__details__store-info__item-row"
                                        }
                                    >
                                        <div
                                            className={
                                                "kartland__details__store-info__item-row__title"
                                            }
                                        >
                                            موجودی فعلی KP:
                                        </div>
                                        <div
                                            className={
                                                "kartland__details__store-info__item-row__value"
                                            }
                                        >
                                            {storeDetails.storeBlueKmStorage}
                                        </div>
                                    </div>

                                    <div
                                        className={
                                            "kartland__details__store-info__item-row"
                                        }
                                    >
                                        <div
                                            className={
                                                "kartland__details__store-info__item-row__title"
                                            }
                                        >
                                            بسته‌های KP خریداری شده:
                                        </div>
                                        <div
                                            className={
                                                "kartland__details__store-info__item-row__value"
                                            }
                                        >
                                            {storeDetails.totalBlueKmStoreBuy}
                                        </div>
                                    </div>

                                    {storeDetails.totalBlueKmStoreBuy > 0 && (
                                        <div
                                            className={
                                                "kartland__details__store-info__item-row"
                                            }
                                        >
                                            <div
                                                className={
                                                    "kartland__details__store-info__item-row__title"
                                                }
                                            >
                                                تاریخ خرید آخرین بسته‌ی KP:
                                            </div>
                                            <div
                                                className={
                                                    "kartland__details__store-info__item-row__value"
                                                }
                                            >
                                                {moment(
                                                    storeDetails?.lastTimeBoughtKm,
                                                    "YYYY/MM/DD"
                                                )
                                                    .locale("fa")
                                                    .format("YYYY/MM/DD")}
                                            </div>
                                        </div>
                                    )}

                                    <div
                                        className={
                                            "kartland__details__store-info__item-row"
                                        }
                                    >
                                        <div
                                            className={
                                                "kartland__details__store-info__item-row__title"
                                            }
                                        >
                                            KP های اهدا شده:
                                        </div>
                                        <div
                                            className={
                                                "kartland__details__store-info__item-row__value"
                                            }
                                        >
                                            {storeDetails.totalDonatedKm}
                                        </div>
                                    </div>

                                    {storeDetails.totalDonatedKm > 0 && (
                                        <div
                                            className={
                                                "kartland__details__store-info__item-row"
                                            }
                                        >
                                            <div
                                                className={
                                                    "kartland__details__store-info__item-row__title"
                                                }
                                            >
                                                تاریخ آخرین KP اهدا شده:
                                            </div>
                                            <div
                                                className={
                                                    "kartland__details__store-info__item-row__value"
                                                }
                                            >
                                                {moment(
                                                    storeDetails?.lastTimeDonatedKm,
                                                    "YYYY/MM/DD"
                                                )
                                                    .locale("fa")
                                                    .format("YYYY/MM/DD")}
                                            </div>
                                        </div>
                                    )}
                                </>
                            )}

                            {params.type != 1 && (
                                <>
                                    <div
                                        className={
                                            "kartland__details__store-info__item-row"
                                        }
                                    >
                                        <div
                                            className={
                                                "kartland__details__store-info__item-row__title"
                                            }
                                        >
                                            میزان فروش فروشگاه:
                                        </div>
                                        <div
                                            className={
                                                "kartland__details__store-info__item-row__value"
                                            }
                                        >
                                            {currency(
                                                roundNum(
                                                    storeDetails?.accounting[0]
                                                )
                                            )}
                                            + تومان
                                        </div>
                                    </div>

                                    <div
                                        className={
                                            "kartland__details__store-info__item-row"
                                        }
                                    >
                                        <div
                                            className={
                                                "kartland__details__store-info__item-row__title"
                                            }
                                        >
                                            تاریخ شروع فعالیت:
                                        </div>
                                        <div
                                            className={
                                                "kartland__details__store-info__item-row__value"
                                            }
                                        >
                                            {moment(
                                                storeDetails?.accounting[1],
                                                "YYYY/MM/DD"
                                            )
                                                .locale("fa")
                                                .format("YYYY/MM/DD")}
                                        </div>
                                    </div>
                                </>
                            )}

                            {/*<div className={"kartland__details__store-info__address"}>*/}
                            {/*  <div className={"kartland__details__store-info__desc__title"}>توضیحات:</div>*/}
                            {/*  <div className={"kartland__details__store-info__desc__value"}>{storeDetails.description}</div>*/}
                            {/*</div>*/}
                        </div>
                        {/*<div className={"kartland__details__price"}>*/}
                        {/*  <div className={"kartland__item__price__title"}>*/}
                        {/*    قیمت:*/}
                        {/*  </div>*/}
                        {/*  <div className={"kartland__item__price__value"}>*/}
                        {/*    fdsf*/}
                        {/*  </div>*/}
                        {/*</div>*/}
                    </div>
                    <div className={"w-full bottom-16"}>
                        <div
                            className="button width-full-16"
                            onClick={() => payLoading === false && rentStore()}
                        >
                            {payLoading ? <Dots color="light" /> : "انتخاب"}
                        </div>
                    </div>
                </>
            ) : (
                <div className="kartland__wrapper__empty">
                    <div className="kartland__wrapper__empty__image">
                        <img src={empty} alt="empty" />
                    </div>
                    <div className="kartland__wrapper__empty__text">
                        واحد مورد نظر شما یافت نشد
                    </div>
                    <div className="kartland__wrapper__empty__subtext">
                        در وارد کردن شماره واحد دقت فرمایید
                    </div>
                </div>
            )}
        </div>
    );
};
