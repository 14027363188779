import axios from "axios";
import { apiVersion, devIp } from "../API";

export const MoneyWalletAPI = async (token) => {
  const APIResult = await axios({
    method: "GET",
    url: devIp + apiVersion + "/auth/user/wallet",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });

  return APIResult;
};
