import React from "react";
import "./Share.scss";
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  TelegramIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";

export const Share = ({
  active = false,
  setClose,
  value = "",
  title = "",
  link = "",
  withBtnNav = false,
}) => {
  return (
    <div
      className={`share-container ${active && "active"} ${
        withBtnNav && "withBtnNav"
      }`}
      id="close-area"
      onClick={(e) => e.target.id === "close-area" && setClose(false)}
    >
      <div className={`share ${active && "active"}`}>
        <div className="share__subject">{title} در:</div>
        <div className="row j-sb">
          <TelegramShareButton title={value} url={link}>
            <TelegramIcon size={34} round={false} borderRadius={15} />
            <div className="share__title">تلگرام</div>
          </TelegramShareButton>

          <WhatsappShareButton title={value} url={link}>
            <WhatsappIcon size={34} round={false} borderRadius={15} />
            <div className="share__title">واتس‌اپ</div>
          </WhatsappShareButton>

          <TwitterShareButton title={value} url={link}>
            <TwitterIcon size={34} round={false} borderRadius={15} />
            <div className="share__title">توییتر</div>
          </TwitterShareButton>

          <LinkedinShareButton title={value} url={link}>
            <LinkedinIcon size={34} round={false} borderRadius={15} />
            <div className="share__title">لینکدین</div>
          </LinkedinShareButton>

          <FacebookShareButton quote={value} url={link}>
            <FacebookIcon size={34} round={false} borderRadius={15} />
            <div className="share__title">فیسبوک</div>
          </FacebookShareButton>

          <EmailShareButton body={value} url={link}>
            <EmailIcon size={34} round={false} borderRadius={15} />
            <div className="share__title">ایمیل</div>
          </EmailShareButton>
        </div>
      </div>
    </div>
  );
};
