import React, { useState, useEffect } from "react";
import {
    PageTopbar,
    SVG,
    Spinner,
    Fade,
    Modal,
    Dots,
    SlidingHint,
} from "components";
import { Link, useHistory } from "react-router-dom";
import {
    adsKvValueAPI,
    hasSepcialAdsAPI,
    adsSpecialCountAPI,
    adsScanInfoAPI,
    adsChargeAPI,
    autoChargeAPI,
} from "utils/Services/Kartella";
import { CheckAdsInRate, getHintAPI } from "utils/Services";
import { getQrAPI, checkQrCode } from "utils/Services/API";
import { useUser } from "hooks";
import foryouImage from "../../assets/images/kartella/foryou.png";
import toast from "react-hot-toast";
import moment from "jalali-moment";
import { Hint } from "components/Hint";
import { ProfileSlider } from "components";

export const KartellaUnSeenContainer = () => {
    const {
        getUserDataFromApi,
        userState: { user },
    } = useUser();
    const user_token = localStorage.getItem("user_token");
    const [hint, setHint] = useState<any>();
    const [loading, setLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [modalImage, setModalImage] = useState(null);
    const [modalCat, setModalCat] = useState(null);
    const [modalCatTitle, setModalCatTitle] = useState("");
    const [modalCatCount, setModalCatCount] = useState([]);
    const [modalKvCount, setModalKvCount] = useState([]);
    const [seenStatus, setSeenStatus] = useState([]);
    const [kvCount, setKvCount] = useState([]);
    const [checkAdsData, setCheckAdsData] = useState([]);
    const [lastScan, setLastScan] = useState<any>({});
    const [chargeLoading, setChargeLoading] = useState(false);
    const [specialSeenStatus, setSpecialSeenStatus] = useState<any>({});
    const [isUpdated, setIsUpdated] = useState(false);
    const [showHelp, setShowHelp] = useState(true);
    const [showHelpRadio, setShowHelpRadio] = useState(false);
    const [hintModal, setHintModal] = useState({
        kvInfo: false,
        kvDose: false,
        kvAssign: false,
        qrCharge: false,
        qrInfo: false,
        qrMine: false,
        qrDose: false,
    });
    const history = useHistory();
    const [hint1, setHint1] = useState<any>("");
    const [hint2, setHint2] = useState<any>("");
    const [hintLoading, setHintLoading] = useState(true);
    const [fullhint, setFullhint] = useState(false);

    const [slidingHintStatus, setSlidingHintStatus] = useState(false);
    const [slidingHintText, setSlidingHintText] = useState("");

    const getUserQr = () => {
        setChargeLoading(true);
        const api = getQrAPI(user_token);
        api.then((response) => {
            switch (response.status) {
                case 200:
                    scanQrForCharge(response.data.data.barcode.code);
                    break;
                case 404:
                    setChargeLoading(false);
                    setHintModal({ ...hintModal, qrCharge: true });
                    break;
                default:
                    break;
            }
            setIsUpdated(true);
        });
    };

    const scanQrForCharge = (userQrCode) => {
        const serverResponse = checkQrCode(user_token, userQrCode);
        serverResponse
            .then((response) => {
                if (response.status === 401) {
                    toast.error("پیش از اسکن بارکد، بایستی وارد شوید");
                    history.push("/login");
                } else if (response.status === 403) {
                    toast.error("بارکدی فرستاده نشد.");
                } else if (response.status === 404) {
                    toast.error("بارکد شما، معتبر نمی‌باشد.");
                } else if (response.status >= 200 && response.status <= 299) {
                    if (response.data.data.count == 0) {
                        // toast.error("محتوایی برای شارژ کردن وجود ندارد.");
                    } else {
                        CheckAds();
                        //   getKartellaHasAds();
                        toast.success(
                            `${response.data.data.count} محتوا در کارتلای شما قرار گرفت!`
                        );
                    }
                }
                setChargeLoading(false);
            })
            .finally(() => {
                getLastScan();
            });
    };

    //   const getKartellaHasAds = () => {
    //     kartellaHasAdsAPI().then((response) => {
    //       setSeenStatus(response.data);
    //     });
    //   };

    const onClickCharge = () => {
        if (!chargeLoading) getUserQr();
    };

    const getLastScan = () => {
        adsScanInfoAPI().then((response) => {
            setLastScan(response.data);
        });
    };

    const chargeKartella = () => {
        adsChargeAPI()
            .then((response) => {
                if (response.status === 200) {
                    if (response.data.count === 0) {
                        // toast.error(`محتوایی برای شارژ شدن پیدا نشد.`);
                        setHintModal({ ...hintModal, qrCharge: false });
                    } else {
                        toast.success(
                            `${response.data.count} محتوا برای شما شارژ شد.`
                        );
                        CheckAds();
                        setTimeout(() => {
                            setHintModal({ ...hintModal, qrCharge: false });
                        }, 200);
                        // getKartellaHasAds();
                    }
                    getLastScan();
                }
            })
            .catch((err) => {
                if (err.response.status) {
                    toast.error("بارکدی یافت نشد.");
                }
            });
    };

    const CheckAds = () => {
        CheckAdsInRate()
            .then((res) => {
                if (res) {
                    setCheckAdsData(res.data.reverse());
                } else return;
            })
            .catch((err) => console.log(err))
            .finally(() => {
                setLoading(false);
            });
    };

    // const getSpecialHasAds = () => {
    //   hasSepcialAdsAPI()
    //     .then((response) => {
    //       setSpecialSeenStatus(response);
    //     })
    //     .catch((err) => console.log(err));
    // };

    // const getSpecialAds = () => {
    //   const api = adsSpecialCountAPI();
    //   api.then((response) => {
    //     setModalCatCount(response.data[0]);
    //   });
    // };

    const getAdsKv = () => {
        const api = adsKvValueAPI();
        api.then((res) => {
            setKvCount(res.data.reverse());
        }).catch((err) => console.log(err));
    };

    const handleClickItem = (item, index) => {
        if (item == 0) {
            toast.error("محتوایی جهت نمایش وجود ندارد", {
                id: "zerochildkartellaunseen",
            });
        } else {
            history.push(
                `/ads?rate=${checkAdsData.length - index}&type=unSeen`
            );
        }
    };

    // const getHint = () => {
    //     getHintAPI("صفحات داخلی کارتلا در باره KV بیشتر بدانید سوال 1")
    //         .then((response) => {
    //             setHint1(response);
    //         })
    //         .catch(() => setHint1(false))
    //         .finally(() => setHintLoading(false));

    //     getHintAPI("صفحات داخلی کارتلا در باره KV بیشتر بدانید سوال 2")
    //         .then((response) => {
    //             setHint2(response);
    //         })
    //         .catch(() => setHint2(false))
    //         .finally(() => {});
    // };

    // const getHintSlider = () => {
    //     getHintAPI("کارتلای جدید")
    //         .then((response) => {
    //             setHint(response);
    //         })
    //         .catch(() => setHint(false));
    // };

    const autoCharge = () => {
        autoChargeAPI().then((res) => {
            if (res.status === 200) {
                if (res.data.count > 0) {
                    toast.success(`${res.data.count} تبلیغ جدید افزوده شد`);
                }
                getUserDataFromApi();
                CheckAds();
                getAdsKv();

                // getSpecialAds();
                // getSpecialHasAds();
                getLastScan();
            }
        });
    };

    useEffect(() => {
        if (localStorage.getItem("kartella_hint") === "0") setShowHelp(false);
        history.replace("/kartella");
        // getUserDataFromApi();
        // CheckAds();
        // getAdsKv();
        //
        // // getSpecialAds();
        // // getSpecialHasAds();
        // getLastScan();
        autoCharge();
    }, []);

    useEffect(() => {
        // getHintAPI("متن راهنمای ثابت صفحه کارتلا")
        //     .then((response) => {
        //         setSlidingHintText(response.content);
        //     })
        //     .catch(() => toast.error("درافت راهنما با خطا مواجه شد"));
    }, []);

    return (
        <Fade>
            <div className="kartella">
                <PageTopbar
                    title="کارتلا"
                    toggleBookmark
                    toggleStar
                    onClickBookmark={() => history.push("/myads")}
                    showKv={true}
                />

                <Modal
                    active={hintModal.kvInfo}
                    close={() => {
                        setHintModal({ ...hintModal, kvInfo: false });
                    }}
                    hint="getKv"
                >
                    <div className="modal-hint__title">{hint1.title}</div>

                    <div className="modal-hint__description">
                        {hintLoading && <Dots color="dark" />}
                        {hint1.content?.split("\n")?.map((str) => (
                            <div>{str}</div>
                        ))}
                    </div>

                    <div
                        className="modal-hint__more"
                        onClick={() =>
                            setHintModal({ ...hintModal, kvDose: true })
                        }
                    >
                        <SVG svg="hint-more" /> {hint2.title}
                    </div>

                    {/* <div className="row a-c" style={{ gap: '4px' }}>
            <div
              className="modal-hint__button solid"
              onClick={() => setHintModal({ ...hintModal, kvInfo: false })}
            >
              بازگشت
            </div>

            <div
              className="modal-hint__button"
              onClick={() => setHintModal({ ...hintModal, kvInfo: false })}
            >
              کارتلا
            </div>
          </div> */}
                </Modal>

                <Modal
                    active={hintModal.kvDose}
                    close={() => {
                        setHintModal({ ...hintModal, kvDose: false });
                    }}
                    hint="price"
                >
                    <div className="modal-hint__title">{hint2.title}</div>

                    <div className="modal-hint__description">
                        {hint2.content?.split("\n")?.map((str) => (
                            <div>{str}</div>
                        ))}
                    </div>

                    {/* <div
            className="modal-hint__more"
            onClick={() => setHintModal({ ...hintModal, kvAssign: true })}
          >
            <SVG svg="hint-more" /> مزایای داشتن KV چیست؟
          </div> */}

                    <div className="row a-c" style={{ gap: "4px" }}>
                        <div
                            className="modal-hint__button solid"
                            onClick={() =>
                                setHintModal({ ...hintModal, kvDose: false })
                            }
                        >
                            بازگشت
                        </div>

                        <div
                            className="modal-hint__button"
                            onClick={() =>
                                setHintModal({
                                    ...hintModal,
                                    kvInfo: false,
                                    kvDose: false,
                                })
                            }
                        >
                            برو به کارتلا
                        </div>
                    </div>
                </Modal>

                <Modal
                    active={hintModal.kvAssign}
                    close={() => {
                        setHintModal({ ...hintModal, kvAssign: false });
                    }}
                    hint="kvAssign"
                >
                    <div className="modal-hint__title">
                        گرفتن KV چه فایده‌ای داره؟
                    </div>

                    <div className="modal-hint__description">
                        با آزادسازی KV بعد از خرید هر محصول در
                        <Link
                            to="/market"
                            style={{ color: "red", marginRight: 3 }}
                        >
                            فروشگاه
                        </Link>
                        ، یک هدیه نقدی دریافت می‌کنید و ضمنا هر ماهی که بیشتر از
                        200 KV بگیرید در
                        <Link
                            to="/lottery"
                            style={{
                                color: "red",
                                marginRight: 1,
                                marginLeft: 1,
                            }}
                        >
                            {" "}
                            قرعه‌کشی{" "}
                        </Link>
                        ماهانه کارتک می‌تونید جایزه بگیرید.
                    </div>

                    <div
                        className="modal-hint__more"
                        onClick={() => {
                            history.push(`/pay?https://kartaak.com/rules`);
                            setTimeout(() => {
                                if (history.location.pathname.includes("/pay"))
                                    window.location.href = `https://kartaak.com/rules`;
                            }, 600);
                        }}
                    >
                        <SVG svg="hint-more" /> اطلاعات بیشتر
                    </div>

                    <div className="row a-c" style={{ gap: "4px" }}>
                        <div
                            className="modal-hint__button solid"
                            onClick={() =>
                                setHintModal({ ...hintModal, kvAssign: false })
                            }
                        >
                            بازگشت
                        </div>

                        <div
                            className="modal-hint__button"
                            onClick={() =>
                                setHintModal({
                                    ...hintModal,
                                    kvInfo: false,
                                    kvDose: false,
                                    kvAssign: false,
                                })
                            }
                        >
                            برو به کارتلا
                        </div>
                    </div>
                </Modal>

                <Modal
                    active={hintModal.qrCharge}
                    close={() => {
                        setHintModal({ ...hintModal, qrCharge: false });
                    }}
                    hint="getKv"
                >
                    <div className="modal-hint__title">شارژ کارتلا</div>

                    <div className="modal-hint__description">
                        برای شارژ کارتلا باید یکی از بارکدهای کارتک را اسکن
                        کنید.
                    </div>

                    {/* <div className="modal-hint__more" onClick={() => setHintModal({ ...hintModal, kvDose: true })}>
            <SVG svg="hint-more" />
          </div> */}

                    <div className="col a-c" style={{ gap: "4px" }}>
                        <div
                            className="modal-hint__more j-c"
                            onClick={() =>
                                setHintModal({ ...hintModal, qrInfo: true })
                            }
                        >
                            راهنمای پیدا کردن بارکد های کارتک
                        </div>

                        <div
                            className="modal-hint__button green"
                            onClick={() => chargeKartella()}
                        >
                            فعلا شارژ کن
                        </div>
                    </div>
                </Modal>

                <Modal
                    active={hintModal.qrInfo}
                    close={() => {
                        setHintModal({ ...hintModal, qrInfo: false });
                    }}
                    hint="price"
                >
                    <div className="modal-hint__title">
                        راهنمای پیدا کردن بارکد های کارتک
                    </div>

                    <div className="modal-hint__description">
                        روی گوشی بعضی از کاربران و در بعضی از اماکن خصوصی و
                        عمومی می‌تونید بارکد کارتک را پیدا کنید.
                    </div>

                    <div
                        className="modal-hint__more"
                        onClick={() =>
                            setHintModal({ ...hintModal, qrMine: true })
                        }
                    >
                        <SVG svg="hint-more" />
                        شرایط داشتن بارکد اختصاصی
                    </div>

                    <div className="row a-c" style={{ gap: "4px" }}>
                        <div
                            className="modal-hint__button solid"
                            onClick={() =>
                                setHintModal({ ...hintModal, qrInfo: false })
                            }
                        >
                            بازگشت
                        </div>

                        <div
                            className="modal-hint__button"
                            onClick={() =>
                                setHintModal({
                                    ...hintModal,
                                    qrCharge: false,
                                    qrInfo: false,
                                })
                            }
                        >
                            برو به کارتلا
                        </div>
                    </div>
                </Modal>

                <Modal
                    active={hintModal.qrMine}
                    close={() => {
                        setHintModal({ ...hintModal, qrMine: false });
                    }}
                    hint="kvAssign"
                >
                    <div className="modal-hint__title">
                        شرایط داشتن بارکد اختصاصی
                    </div>

                    <div className="modal-hint__description">
                        کافیه 2 نفر را به کارتک معرفی کنید تا بارکد اختصاصی
                        بگیرید و از اون به بعد می‌تونید هم کارتلای خودتون رو
                        شارژ کنید و هم کارتلای دیگران را.
                    </div>

                    <div
                        className="modal-hint__more"
                        onClick={() =>
                            setHintModal({ ...hintModal, qrDose: true })
                        }
                    >
                        <SVG svg="hint-more" /> مزایای داشتن بارکد اختصاصی
                    </div>

                    <div className="row a-c" style={{ gap: "4px" }}>
                        <div
                            className="modal-hint__button solid"
                            onClick={() =>
                                setHintModal({ ...hintModal, qrMine: false })
                            }
                        >
                            بازگشت
                        </div>

                        <div
                            className="modal-hint__button"
                            onClick={() =>
                                setHintModal({
                                    ...hintModal,
                                    qrCharge: false,
                                    qrMine: false,
                                    qrInfo: false,
                                })
                            }
                        >
                            برو به کارتلا
                        </div>
                    </div>
                </Modal>

                <Modal
                    active={hintModal.qrDose}
                    close={() => {
                        setHintModal({ ...hintModal, qrDose: false });
                    }}
                    hint="getKv"
                >
                    <div className="modal-hint__title">
                        مزایای داشتن بارکد اختصاصی
                    </div>

                    <div className="modal-hint__description">
                        بعد از آزادسازی KV هایی که با بارکد شما اسکن شده‌اند،
                        20% از بالاترین ارزش اون KV به حسابتون واریز می‌شه.
                    </div>

                    <div
                        className="modal-hint__more"
                        onClick={() => {
                            history.push(`/pay?https://kartaak.com/rules`);
                            setTimeout(() => {
                                if (history.location.pathname.includes("/pay"))
                                    window.location.href = `https://kartaak.com/rules`;
                            }, 600);
                        }}
                    >
                        <SVG svg="hint-more" /> اطلاعات بیشتر
                    </div>

                    <div className="row a-c" style={{ gap: "4px" }}>
                        <div
                            className="modal-hint__button solid"
                            onClick={() =>
                                setHintModal({ ...hintModal, qrDose: false })
                            }
                        >
                            بازگشت
                        </div>

                        <div
                            className="modal-hint__button"
                            onClick={() =>
                                setHintModal({
                                    ...hintModal,
                                    qrCharge: false,
                                    qrDose: false,
                                    qrInfo: false,
                                    qrMine: false,
                                })
                            }
                        >
                            برو به کارتلا
                        </div>
                    </div>
                </Modal>

                <Modal active={showModal} close={() => setShowModal(false)}>
                    {/* <div
            onClick={() => setShowModal(false)}
            className="kartella__scores__close"
          >
            <SVG svg="times" />
          </div> */}
                    <div
                        style={{
                            marginBottom: 8,
                            fontSize: 13,
                            fontWeight: 700,
                            textAlign: "center",
                        }}
                    >
                        {modalCatTitle}
                    </div>

                    <div className="kartella__scores">
                        {[{}, {}, {}, {}, {}, {}, {}].map((item, index) => (
                            <div
                                className="kartella__scores__item"
                                style={{
                                    background: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.5)) ,url(${modalImage})`,
                                }}
                                onClick={() => {
                                    if (modalCatCount[index]?.unseen === 0) {
                                        toast.error(
                                            "محتوایی برای نمایش وجود ندارد."
                                        );
                                        return;
                                    } else {
                                        if (modalCatTitle === "برای شما") {
                                            history.push(
                                                `./ads?rate=${
                                                    index + 1
                                                }&specific=true&type=unSeen`
                                            );
                                        } else {
                                            history.push(
                                                `./ads?rate=${
                                                    index + 1
                                                }&cat=${modalCat}&type=unSeen`
                                            );
                                        }
                                    }
                                }}
                            >
                                <div className="kartella__scores__count">
                                    <span>{index + 1}</span>
                                </div>

                                <div className="kartella__scores__title">
                                    {/* <div className="kartella__scores__number">
                    <SVG svg="seen" />
                  </div> */}
                                    <div className="kartella__scores__number__title">
                                        محتوا
                                    </div>
                                    <div className="kartella__scores__kv__number">
                                        {modalCatCount[index]?.unseen}
                                    </div>
                                </div>

                                <div className="kartella__scores__title kv">
                                    <div className="kartella__scores__kv">
                                        <SVG svg="kv" />
                                    </div>
                                    <div className="kartella__scores__kv__number">
                                        {/* <Dots color="light" /> */}
                                        {modalKvCount[index]}
                                    </div>
                                </div>

                                <div className="kartella__scores__play">
                                    <SVG svg="play" />
                                </div>
                            </div>
                        ))}
                    </div>
                    {user?.detail?.star < 7 && (
                        <React.Fragment>
                            <div className="kartella__scores__header">
                                <div className="kartella__scores__header__title">
                                    شما
                                    <span>{user?.detail?.star}</span>
                                    ستاره هستید.
                                    {/* <br /> */}
                                    <Link to="/profile">ستاره‌هاتونو</Link>
                                    بیشتر کنید تا KV بیشتری بگیرید.
                                </div>
                            </div>
                        </React.Fragment>
                    )}
                </Modal>

                <Spinner color="dark" active={loading} />
                {/*
        <div className={`kartella__help ${!showHelp && "deactive"}`}>
          <div
            className="kartella__help__cta"
            id="hintArea"
            onClick={(e) => {
              if ((e.target as HTMLInputElement).id === "hintArea") {
                getHint();
                setHintModal({...hintModal, kvInfo: true});
              }
            }}
          >
            <div
              className="kartella__help__close"
              onClick={() => {
                setShowHelp(false);
                if (showHelpRadio) localStorage.setItem("kartella_hint", "0");
              }}
            >
              <SVG svg="times"/>
            </div>

            <div className="kartella__help__title" id="hintArea">
              درباره‌ی KV بیشتر بدانید
            </div>
          </div>

          <div
            className="kartella__help__show"
            onClick={() => setShowHelpRadio((prev) => !prev)}
          >
            <div
              className={`kartella__help__show__radio ${showHelpRadio && "active"
              }`}
            >
              <div/>
            </div>
            <div>دیگه نمایش نده</div>
          </div>
        </div>
        */}

                {/* <div className="kartella__hint">
          <div className={`kartella__hint__title ${!loading && "active"}`}>
            {checkAdsData.length > 0 && (
              <div className="kartella__hint__date">
                آخرین به روزرسانی:
                {lastScan.userLastScanned !== undefined &&
                  lastScan.userLastScanned !== null &&
                  moment(lastScan?.userLastScanned, "YYYY/MM/DD h:mm:ss")
                    .locale("fa")
                    .format(" HH:mm - YYYY/MM/DD")}
              </div>
            )}

            <div className="row a-c">
              <div
                className="kartella__hint__charge"
                onClick={() => onClickCharge()}
              >
                {chargeLoading ? <Dots color="light" /> : "به‌روزرسانی"}
              </div>
              <div
                className="kartella__hint__solid"
                onClick={() => setHintModal({ ...hintModal, kvInfo: true })}
              >
                ?KV
              </div>
            </div>
          </div>
          {/* <div className="kartella__hint__bottuns">
            </div> 
      </div> */}

                <div className="kartaak-fun__desc">
                    {/* <div className="kartaak-fun__desc__gif"></div> */}
                    <div className="kartaak-fun__desc__slider">
                        <ProfileSlider />
                    </div>

                    <div className="kartaak-fun__desc__text">
                        {/*{hint?.content}*/}
                        {fullhint
                            ? hint?.content
                            : hint?.content &&
                              `${hint?.content.slice(0, 100)}... `}
                        {!fullhint ? (
                            <span
                                style={{ color: "#fd6547" }}
                                onClick={() => setFullhint(true)}
                                className=""
                            >
                                ادامه مطلب
                            </span>
                        ) : (
                            <span
                                style={{ color: "#fd6547" }}
                                onClick={() => setFullhint(false)}
                                className=""
                            >
                                بستن
                            </span>
                        )}
                    </div>
                </div>

                <div className="kartella-unseen">
                    <div className="flex j-sb w-100 mb-10">
                        <div
                            className="kartaak-fun__item"
                            onClick={() => history.push("/content-management")}
                        >
                            پست بذار امتیاز بگیر
                        </div>
                    </div>
                    {/* <SlidingHint
            openTitle="راهنمای کارتلا"
            open={slidingHintStatus}
            onClick={() => setSlidingHintStatus(!slidingHintStatus)}
            text={slidingHintText}
          /> */}
                    {lastScan.isNeedUpdated && (
                        <div
                            className="kartella__update"
                            onClick={() => onClickCharge()}
                        >
                            <div className="row a-c">
                                {chargeLoading ? (
                                    <Dots color="light" />
                                ) : (
                                    <>
                                        <SVG svg="ads-update" />
                                        <div className="kartella__update__title">
                                            {/* {isUpdated ?
                    'به‌روز شد' :
                    'به‌روزرسانی کنید'
                  } */}
                                            به‌روزرسانی کنید
                                        </div>
                                    </>
                                )}
                            </div>

                            <div className="kartella__update__date">
                                {lastScan.userLastScanned !== undefined &&
                                    lastScan.userLastScanned !== null &&
                                    moment(
                                        lastScan?.userLastScanned,
                                        "YYYY/MM/DD h:mm:ss"
                                    )
                                        .locale("fa")
                                        .format(" HH:mm - YYYY/MM/DD")}
                            </div>
                        </div>
                    )}
                    {checkAdsData.length == 0 ? (
                        <div className="kartella-unseen__not-found">
                            <div className="kartella-unseen__not-found__logo">
                                <SVG svg="ads-dark" />
                            </div>
                            <span className="kartella-unseen__not-found__title">
                                محتوایی در کارتلا وجود ندارد
                            </span>
                            <span className="kartella-unseen__not-found__description">
                                جهت مشاهده محتوا اقدام به به‌روزرسانی نمایید
                            </span>
                        </div>
                    ) : (
                        checkAdsData.map(
                            (item, index) =>
                                index > 0 && (
                                    <div
                                        className="kartella-unseen__ads"
                                        onClick={() =>
                                            handleClickItem(item.count, index)
                                        }
                                    >
                                        <div className="kartella-unseen__item__number">
                                            <div className="kartella-unseen__item__kv">
                                                <div className="kartella-unseen__item__kv__logo">
                                                    <SVG svg="kv" />
                                                </div>
                                                {kvCount[index]}
                                            </div>
                                            {Math.abs(index - 8)}
                                        </div>
                                        <img
                                            className="kartella-unseen__item__cover"
                                            src={item.cover}
                                        />
                                        {/* <span className="kartella-unseen__item__bio">
                  شما{" "}
                  <span className="kartella-unseen__item__bio__highlight">
                    {user?.detail?.star} ستاره
                  </span>{" "}
                  هستید و با دیدن هر محتوا در این صفحه{" "}
                  <span className="kartella-unseen__item__bio__highlight">
                    {kvCount[index + 1]} KV
                  </span>{" "}
                  می‌گیرید
                </span> */}
                                        {/* <div
                  className={`kartella-unseen__item__hexagon ${item.count == 0 && "disable"
                    }`}
                >
                  <span className="kartella-unseen__item__hexagon__count">
                    {checkAdsData.length - index}
                  </span>
                </div> */}
                                        {item.count !== 0 && (
                                            <div
                                                className={`kartella-unseen__item__available ${
                                                    item.count == 0 && "disable"
                                                }`}
                                            >
                                                <span className="kartella-unseen__item__available__count">
                                                    محتوای موجود: {item.count}
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                )
                        )
                    )}

                    {checkAdsData.length > 0 && user?.detail?.star > 1 && (
                        <div
                            className="kartella-unseen__ads"
                            onClick={() => {
                                history.push(`/ads?rate=${8}`);
                            }}
                        >
                            <div
                                className="kartella-unseen__item__number"
                                style={{ fontSize: 22 }}
                            >
                                <div className="kartella-unseen__item__kv">
                                    <div className="kartella-unseen__item__kv__logo">
                                        <SVG svg="kv" />
                                    </div>
                                    {kvCount[0]}
                                </div>
                                <div style={{ marginBottom: 24 }}>
                                    FOR
                                    <br />
                                    YOU
                                </div>
                            </div>
                            <img
                                className={`kartella-unseen__item__cover 
                `}
                                src={foryouImage}
                                style={{ objectPosition: "bottom" }}
                            />
                            {checkAdsData[0].count !== 0 && (
                                <div
                                    className={`kartella-unseen__item__available ${
                                        checkAdsData[0].count == 0 && "disable"
                                    }`}
                                >
                                    <span className="kartella-unseen__item__available__count">
                                        محتوای موجود: {checkAdsData[0].count}
                                    </span>
                                </div>
                            )}
                            {/* <span className="kartella-unseen__item__bio">برای شما</span> */}
                            {/* <div className={`kartella-unseen__item__hexagon`}></div> */}
                        </div>
                    )}
                </div>
            </div>
        </Fade>
    );
};
