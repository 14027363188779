import { SVG } from 'components/SVG';
import { useHistory } from 'react-router';
import './Chip.scss';

export const Chip = ({
  icon = "",
  title = "",
  count = 0,
  className = ""
}) => {
  const history = useHistory();

  return (
    <div className={`chip ${className}`} onClick={() => history.push('/wallet')}>
      <div className="chip__icon">
        <SVG svg={icon} />
      </div>

      {/* <div className="chip__title">
        {title}
      </div> */}

      <div className="chip__number">
        {count}
      </div>

    </div>
  );
}