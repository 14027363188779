import { Hint, Modal, SVG } from "components";
import React, { useEffect, useState } from "react";
import { getActiveSlRlAPI, getHintAPI } from "utils";
import { currency } from "utils/Functions";

const Slrl = () => {

  const [activeSlRl, setActiveSlRl] = useState({
    slCeiling: "0",
    rlCeiling: "0",
    totalSl: "0",
    totalRl: "0",
  });

  const getActiveSlRl = () => {
    getActiveSlRlAPI()
      .then((response) => {
        if (response.data !== null) {
          setActiveSlRl(response.data);
        }
      })
      .catch((err) => { })
      .finally(() => { });
  };

  const handleShowPercentages = (slRlType) => {
    switch (slRlType) {
      case "rl":
        return (activeSlRl.totalRl * 100) / activeSlRl.rlCeiling;

      case "sl":
        return (activeSlRl.totalSl * 100) / activeSlRl.slCeiling;

      default:
        return 0;
    }
  };

  useEffect(() => {
    getActiveSlRl();
  }, []);

  return (
    <>
      <div className="slrl">
        <div className="slrl__col">
          <div className="slrl__ceiling">
            <div className="slrl__ceiling__title">موجودی:</div>
            <div className="row a-c j-c">
              <div className="slrl__ceiling__price">
                {currency(activeSlRl.totalRl)}
              </div>
              <div className="slrl__ceiling__toman"> تومان</div>
            </div>
          </div>

          <div className="slrl__amount">
            <div
              className="slrl__amount__value rl"
              style={{
                height: `${handleShowPercentages("rl")}%`,
                background:
                  handleShowPercentages("rl") === 100 &&
                  `linear-gradient(
                0deg,
                rgba(242, 116, 0, 1) 0%,
                rgba(255, 214, 0, 1) 100%
              )`,
              }}
            />
          </div>

          <div className="slrl__button">
            <div className="slrl__button__title">
              <SVG svg="rl-logo" />
              {activeSlRl.rlCeiling === "0"
                ? currency(3000000)
                : currency(activeSlRl.rlCeiling)}
              <div className="slrl__button__subtitle">تومان</div>
            </div>
            {/* <SVG svg="arrow" /> */}
          </div>
        </div>

        <div className="slrl__col">
          <div className="slrl__ceiling">
            <div className="slrl__ceiling__title">موجودی:</div>
            <div className="row a-c j-c">
              <div className="slrl__ceiling__price sl">
                {currency(activeSlRl.totalSl)}
              </div>
              <div className="slrl__ceiling__toman sl"> تومان</div>
            </div>
          </div>

          <div className="slrl__amount">
            <div
              className="slrl__amount__value sl"
              style={{
                height: `${handleShowPercentages("sl")}%`,
                background:
                  handleShowPercentages("sl") === 100 &&
                  `linear-gradient(
                  0deg,
                  #2140E1 0%,
                  #21BBE1 100%
                )`,
              }}
            />
          </div>

          <div className="slrl__button">
            <div className="slrl__button__title">
              <SVG svg="sl-logo" />
              {activeSlRl.slCeiling === "0"
                ? currency(1000000)
                : currency(activeSlRl.slCeiling)}
              <div className="slrl__button__subtitle">تومان</div>
            </div>
            {/* <SVG svg="arrow" /> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Slrl;
