import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { PageTopbar, Fade } from "components";
import catalog from "assets/images/catalog.png";

const RoadMap = () => {
  const history = useHistory();

  useEffect(() => {
    history.replace("/roadmap");
  }, []);

  return (
    <Fade>
      <PageTopbar title="خلاصه" showKv={false} />
      <img src={catalog} width="100%" loading="lazy" />
    </Fade>
  );
};

export default RoadMap;
