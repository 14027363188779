import {Card, Fade, PageTopbar, Spinner, SVG} from "components";
import React, {useState, useEffect} from "react";
import {useHistory, useParams} from "react-router-dom";
import {getNewLotteryDetailsAPI} from "utils/Services/NewLottery";
import lotteryImg from '../../assets/images/lotteryImg.png';
import moment from "jalali-moment";

export const NewLotteryDetailContainer = () => {
  const history = useHistory();
  // *****************_______________________________________bache jan in ro baadan true kon_______________________________________________________________________***************
  const [newLotteryDetaiLoading, setNewLotteryDetaiLoading] = useState(true);
  const [newLotteryDetail, setNewLotteryDetail] = useState<any>([]);
  const {id} = useParams<any>();

  const getNewLotterDetail = (id) => {
    setNewLotteryDetaiLoading(true);
    getNewLotteryDetailsAPI(id).then((res) => {
      if (res.status === 200) {
        setNewLotteryDetail(res.data);
      }
    }).catch(err => {

    }).finally(() => setNewLotteryDetaiLoading(false));
  };

  useEffect(() => {
    history.replace(`/new-lottery/${id}`);
    getNewLotterDetail(id);
  }, []);

  return (
    <React.StrictMode>
      <Fade>
        <Spinner active={newLotteryDetaiLoading} color="dark"/>
        <PageTopbar title="قرعه‌کشی" showKv={false}/>
        <div className="wrapper-fix">
          <Card>
            <div className="mainLotteryPage__detaillottery">
              <div className="mainLotteryPage__detaillottery__lottery-img">
                <img src={newLotteryDetail.draw?.photo} alt="lotteryImg"/>
              </div>
              <div className="mainLotteryPage__detaillottery__description">

                <div className="mainLotteryPage__detaillottery__description__title">
                  <h3>
                    {newLotteryDetail.draw?.giftType}
                  </h3>
                </div>
                <div className="urge">
                  <div className="urge__title">به مناسبت :</div>
                  <div className="urge__des">
                    {newLotteryDetail.draw?.subject}
                  </div>
                </div>
                <div className="date">
                  <div className="date__title">تاریخ قرعه کشی :</div>
                  {/* <div className="mainLotteryPage__detailLottery__description__date__content">
                                    {newLotteryDetail.draw && moment(newLotteryDetail.draw?.completionDate, "YYYY/MM/DD")
                                        .locale("fa")
                                        .format("YYYY/MM/DD")}
                                </div> */}
                  {moment(newLotteryDetail.draw?.completionDate)
                    .locale("fa")
                    .format(" YYYY/MM/DD")}
                </div>
                {newLotteryDetail.renewalDate && <div className="renewalDate">
                  قرعه کشی تا تاریخ {moment(newLotteryDetail.renewalDate)
                  .locale("fa")
                  .format(" YYYY/MM/DD")} تمدید شد.</div>}

                {newLotteryDetail.draw?.status === null && newLotteryDetail.winner && newLotteryDetail.winner.length > 0 ?
                  <div className="winer">
                    <div className="winer__inner">
                      <div className="winer__inner__name"> برنده ی خوشانس :</div>
                      <div className="winer__inner__content-name">{newLotteryDetail.winner[0].userWinner?.name}</div>
                    </div>
                    <div className="winer__phoneNumber">{newLotteryDetail.winner[0].userWinner?.mobile_number}</div>
                  </div>
                  :
                  <div className="NumChances">
                    <div className="NumChances__title">تعداد شانس های شما :</div>
                    <div className="NumChances__score">{newLotteryDetail.myChance}</div>
                  </div>
                }
              </div>
            </div>
          </Card>
        </div>
      </Fade>
    </React.StrictMode>
  );
};