import {Login, Modal, SVG} from "components";
import {LoginContainer} from "containers";
import {useCart, useUser} from "hooks";
import {useState} from "react";
import toast from "react-hot-toast";
import {useHistory} from "react-router-dom";
import "./SwitchUser.scss";

export const SwitchUser = ({active = false, onClose}) => {
  const {
    getUserDataFromApi,
    getUserProfile,
    userState: {user},
  } = useUser();
  const {
    getCartDataFromApi,
  } = useCart();
  const [showLogin, setShowLogin] = useState(false);
  const history = useHistory();

  const handleSwitchUser = () => {
    const firstUser = localStorage.getItem("user_token");
    const secondUser = localStorage.getItem("user_token_second");

    if (secondUser !== null) {
      localStorage.setItem("user_token", secondUser);
      localStorage.setItem("user_token_second", firstUser);
    }

    const newUser = [{name: user?.detail?.fullName, phone: user?.mobile_number}];
    localStorage.setItem('user_second_account', JSON.stringify(newUser));

    getUserDataFromApi();
    getUserProfile();
    getCartDataFromApi();
    history.push('/profile');
    toast.success('تغییر حساب کاربری با موفقیت انجام شد.');
    onClose();
  };

  const handleShowAccounts = () => {
    const userAccount = JSON.parse(localStorage.getItem("user_second_account"));
    return userAccount;
  };

  return (
    <div
      className={`switch-user ${active && "active"}`}
      id="closeArea"
      onClick={(e) => e.target.id === "closeArea" && onClose()}
    >
      <Modal active={showLogin} close={() => setShowLogin(false)}>
        <Login closeModal={() => {
          setShowLogin(false);
        }}/>
      </Modal>

      <div className="switch-user__container">
        {handleShowAccounts()?.map((userAcc, index) => (
          <div key={index} className="switch-user__container__item" onClick={() => handleSwitchUser()}>
            <div></div>
            <div className="col">
              <div>{userAcc.name}</div>
              <div className="switch-user__container__item__phone">
                {userAcc.phone}
              </div>
            </div>
          </div>
        ))}

        <div className="switch-user__container__item active">
          <div></div>
          <div className="col">
            <div>{user?.detail?.fullName}</div>
            <div className="switch-user__container__item__phone">
              {user.mobile_number}
            </div>
          </div>
        </div>

        {handleShowAccounts() === null && (
          <div
            className="switch-user__container__create"
            onClick={() => setShowLogin(true)}
          >
            افزودن حساب کاربری جدید
            <SVG svg="create-user"/>
          </div>
        )}
      </div>
    </div>
  );
};
