import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
    Fade,
    Card,
    Spinner,
    PageTopbar,
    SVG,
    Modal,
    Padding,
    UpdateUserInfo,
} from "components";
import "./../assets/styles/pages/kartakYar.scss";
import { getHintAPI } from "utils";
import { useUser } from "hooks";
import { toast } from "react-hot-toast";
import Lottie from "lottie-react";
import guide from "../assets/images/Json/test6.json";
// import guide2 from '../assets/images/Json/test.json';

const KartakYar = () => {
    const [showFillProfileModal, setShowFillProfileModal] = useState(false);
    const [userInfo, setUserInfo] = useState({
        name: "",
        lastname: "",
        nationalCode: "",
    });

    const [loading, setLoading] = useState(true);
    const [hintText, setHintText] = useState("");
    const [collaborationType, setCollaborationType] = useState();
    const [isIntroducer, setIsIntroducer] = useState(-1);
    const [collaborationModal, setCollaborationModal] = useState(false);
    const [guideModal, setGuideModal] = useState(false);

    const history = useHistory();
    const style = {
        height: 461,
    };

    // useEffect(() => {
    //     getHintAPI("راهنمای ثابت صفحه کارتک یار")
    //         .then((response) => {
    //             setHintText(response.content);
    //             setLoading(false);
    //         })
    //         .catch((error) => {
    //             console.log(error);
    //         });
    // }, []);

    const redirectUser = (page: string) => {
        history.push(page);
    };

    const userContext = useUser();
    const {
        getUserDataFromApi,
        userState: { user },
    } = useUser();

    const UserInfoHandler = () => {
        if (
            user.name?.length === 0 ||
            user.name === null ||
            user.lastname?.length === 0 ||
            user.lastname === null ||
            user.nationalCode?.length === 0 ||
            user.nationalCode === null ||
            user.nationalCode?.length !== 10
        ) {
            setShowFillProfileModal(true);
        } else {
            setShowFillProfileModal(false);
        }
    };

    useEffect(() => {
        if (Object.entries(userContext.userState.user).length != 0) {
            setCollaborationType(userContext.userState.user.collaborationType);
            setIsIntroducer(userContext.userState.user.isIntroducer);
        }
        console.log(user);
    }, [userContext]);

    useEffect(() => {
        setUserInfo({
            name: user.name,
            lastname: user.lastname,
            nationalCode: user.nationalCode,
        });
        UserInfoHandler();
    }, [user]);

    return (
        <div className="kartak-yar__container">
            <UpdateUserInfo
                showUpdate={showFillProfileModal}
                setShowUpdate={(value) => {
                    getUserDataFromApi();
                }}
                userInfo={userInfo}
                setUserInfo={setUserInfo}
                getUser={getUserDataFromApi}
                useForCitizen={false}
            />

            <Modal active={guideModal} close={() => setGuideModal(false)}>
                <div className="kartak-yar__container__modal">
                    <Lottie animationData={guide} loop={true} />
                </div>
            </Modal>

            <Fade>
                <Spinner active={loading} color="dark" />
                <PageTopbar title="دفتر کار" />

                <div className="kartak-yar">
                    {/*<div className="kartak-yar__container__background-kartak"/>*/}
                    {/*<div className="kartak-yar__container__text">*/}
                    {/*  <p>{hintText}</p>*/}
                    {/*  <div className="kartak-yar__container__text__gif"/>*/}
                    {/*</div>*/}

                    <div style={{ paddingTop: "62px" }} className="">
                        <Lottie
                            className={"lottie"}
                            animationData={guide}
                            loop={true}
                        />
                    </div>

                    {/* <div className="kartak-yar__container__collab"
               onClick={() => history.push('/collaborate')}
          >جزئیات پنل
          </div> */}
                    <div className="kartak-yar__container__buttons">
                        <button
                            className="button"
                            onClick={() => redirectUser("addnewusers")}
                        >
                            <SVG svg="plus" />
                            <span>کاربر جدید</span>
                        </button>
                        <button
                            className="button"
                            onClick={() => {
                                if (collaborationType === null) {
                                    setCollaborationModal(true);
                                    //toast.error("برای ثبت فروشگاه باید نماینده سفیید یا طلایی باشید.")
                                } else if (
                                    collaborationType !== null &&
                                    isIntroducer === 0
                                ) {
                                    toast.error(
                                        "به دلیل معرفی اشتباه فروشگاه ، دیگر امکان معرفی فروشگاه ندارید ."
                                    );
                                } else if (isIntroducer === 1) {
                                    redirectUser("my-introduces?waiting");
                                } else {
                                    toast.error("درخواست شما با خطا مواجه شد.");
                                }
                            }}
                        >
                            <SVG svg="plus" />
                            <span>فروشگاه جدید</span>
                        </button>
                        <button
                            className="button"
                            onClick={() => redirectUser("/content-management")}
                        >
                            {/*<span className={`shop-creation__body__info__wrapper__soon`}>به‌زودی</span>*/}
                            <SVG svg="plus" />
                            <span>محتوای جدید</span>
                        </button>
                        {user.operator === 1 && (
                            <button
                                className="button"
                                onClick={() =>
                                    redirectUser("/operator-management")
                                }
                            >
                                {/*<span className={`shop-creation__body__info__wrapper__soon`}>به‌زودی</span>*/}
                                <SVG svg="plus" />
                                <span>اپراتور فروشگاه</span>
                            </button>
                        )}
                    </div>
                </div>

                <Modal
                    active={collaborationModal}
                    close={() => {
                        setCollaborationModal(false);
                    }}
                >
                    <p>شما باید ابتدا نماینده کارتک باشید.</p>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "10px",
                        }}
                    >
                        <button
                            style={{ fontFamily: "inherit" }}
                            className="button green"
                            onClick={() => history.push("/collaborate")}
                        >
                            نمایندگی
                        </button>
                        <button
                            style={{ fontFamily: "inherit" }}
                            className="button red"
                            onClick={() => setCollaborationModal(false)}
                        >
                            انصراف
                        </button>
                    </div>
                </Modal>
            </Fade>
        </div>
    );
};

export default KartakYar;
