import {Fade, NotFoundBox, PageTopbar} from 'components';
import React, {useEffect} from 'react';
import {useHistory} from "react-router-dom";

export const KmContainer = () => {
  const history = useHistory();

  useEffect(() => {
    history.replace("/collaborate/logs/km");
  }, []);

  return (
    <Fade>
      <PageTopbar title="سوابق فروش KM"/>
      <NotFoundBox
        title="تاریخچه‌ای برای فروش KM یافت نشد"
        fullScreen={true}
        icon="wallet-km"
      />
    </Fade>
  );
};