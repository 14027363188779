import { SVG } from "components";

export const Actions = ({ data }) => {
  return (
    <div className="kartland__actions">
      <span>{data.title}</span>
      <SVG svg={data.icon} />
    </div>
  );
};
