import { useContext } from "react";
import { AuthStateContext, AuthDispatchContext, authTypes } from "providers";
export const useAuth = () => {
  const { authState } = useContext(AuthStateContext);
  const { authDispatch } = useContext(AuthDispatchContext);
  return {
    authState,
    onSetLoginStatus: (status: boolean) => {
      authDispatch({
        type: authTypes.SET_LOGIN_STATUS,
        payload: { status },
      });
    },
  };
};
