import { Fragment, useState, useEffect, useRef } from "react";
import { PageTopbar, SVG, Modal, CounterInput, Fade } from "components";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { Phone, Info } from "./dependencies";

export const KmTransferContainer = ({ children }) => {
  const history = useHistory();
  const phoneRef = useRef(null);
  const kmCountRef = useRef(null);
  const [phoneNumberName, setPhoneNumberName] = useState("");
  const [forwardedValue, setForwardedValue] = useState({
    phone: "",
    name: "",
    count: "",
  });
  const [status, setStatus] = useState("phone");
  const [hintModal, setHintModal] = useState(false);
  const location = useLocation();
  const query = useQuery();
  const type = query.get("type");

  const handleKmType = (value) => {
    switch (value) {
      case "blue":
        return "KP";
      case "white":
        return "KM";
      case "red":
        return "KP";
      default:
        return "";
        break;
    }
  };

  function useQuery() {
    return new URLSearchParams(location.search);
  }

  useEffect(() => {
    history.replace(`/transaction/transfer` + history.location.search);
  }, []);

  return (
    <Fade>
      <div className="km-transfer">
        <PageTopbar title={`انتقال ${handleKmType(type)}`} showKv={false} />
        <div className="km-transfer__wrapper">
          {query.get("status") == "phone" && (
            <Phone
              forwardedValue={forwardedValue}
              type={type}
              setForwardedValue={setForwardedValue}
            />
          )}
          {query.get("status") == "info" && (
            <Info
              forwardedValue={forwardedValue}
              setForwardedValue={setForwardedValue}
            />
          )}
        </div>
      </div>
    </Fade>
  );
};
