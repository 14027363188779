import {forwardRef, useState, useRef, useEffect} from "react";
import {useHistory} from "react-router-dom";
import {SVG, CounterInput, Dots, Fade, PageTopbar} from "components";
import {CheckMarketer} from "utils/Services";
import toast from "react-hot-toast";

export const KmPurchaseContainer = ({children}) => {
  const history = useHistory();
  const marketerRef = useRef(null);
  const countRef = useRef(null);
  const [loading, setLoading] = useState(false);

  const phoneNumberChange = (e) => {
    const value = e.target.value;
    if (value >= 12) return;
    marketerRef.current.value = value;
  };
  const handleSubmit = () => {
    const marketerInputRefValue = marketerRef.current?.value;
    const countRefValue = countRef.current?.value;

    if (marketerInputRefValue.length < 11) {
      toast.error("شماره موبایل را به درستی وارد نمایید");
      return;
    }
    setLoading(true);
    CheckMarketerValue(marketerInputRefValue);
  };
  const CheckMarketerValue = (value) => {
    CheckMarketer(value)
      .catch((err) => {
        if (err.response.status == 404) {
          toast.error("کد بازاریاب شما معتبر نمی باشد !");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    history.replace("/wallet/km/purchase");
  }, []);

  return (
    <Fade>
      <div className="km-purchase">
        <PageTopbar title={`خرید KM`} showKv={false}/>
        <div className="km-purchase__wrapper">
          <label>کد بازاریاب</label>
          <input
            ref={marketerRef}
            placeholder="کد بازاریاب را وارد کنید"
            className="km-purchase__marketer"
            onChange={(e) => phoneNumberChange(e)}
          />
          <label>تعداد KM</label>
          <CounterInput ref={countRef} step={25} max={5000} defaultValue={25}/>
          <div className="km-purchase__actions">
            <div
              onClick={() => handleSubmit()}
              className="km-purchase__actions__submit"
            >
              {loading ? (
                <Dots color="light"/>
              ) : (
                <>
                  <span>ثبت و خرید</span>
                  <SVG svg="km-recieve"/>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </Fade>
  );
};
