import { useEffect } from "react";
import { BottomNavigation } from "components";
import { useLocation, useHistory } from "react-router-dom";
import { routes } from "utils/routes";
import "./application_layout.scss";

export const ApplicationLayout = ({ children }) => {
    const introSeen = localStorage.getItem("introSeen");
    const pathName = useLocation().pathname;
    const history = useHistory();
    // const filteredRoute: any = routes.filter((route) =>
    //   pathName.includes(route.name)
    // );
    const filteredRoute: any = routes.filter((route) => {
        if (route.path === pathName) return true;
    });

    useEffect(() => {
        if (history.location.pathname !== "/intro" && introSeen == null) {
            history.push("/intro");
        }
    }, []);

    return (
        <div
            className="app"
            style={{
                paddingBottom:
                    filteredRoute.length >= 0 &&
                    filteredRoute[0]?.navigationBar &&
                    pathName !== "/map" &&
                    "80px",
            }}
        >
            {children}
            <BottomNavigation
                hidden={
                    filteredRoute.length >= 0 &&
                    !filteredRoute[0]?.navigationBar
                }
            />
        </div>
    );
};
