import React, { useEffect, useState } from "react";
import { Fade, Padding, PageTopbar, SVG } from "../../../components";
import Slrl from "./SLRL";
import { getHintAPI } from "../../../utils";
import { Link } from "react-router-dom";

const SlRlContainer = () => {
    const [hint, setHint] = useState<any>();

    // const getHint = () => {
    //   getHintAPI("راهنمای SL و RL")
    //     .then((response) => {
    //       setHint(response);
    //     });
    // };

    return (
        <Fade>
            <PageTopbar
                title="نمایندگی کارتک"
                showKv={false}
                circleHint={hint?.content}
            />
            <Padding>
                <Slrl />

                <Link to={"/collaborate/logs/sl"} className="my-panel__item">
                    <div className="row a-c">
                        <div className={`my-panel__item__icon sl-logo`}>
                            <SVG svg={"sl-logo"} />
                        </div>
                        <div>{"تاریخچه فروش نقدی (Sell Limit)"}</div>
                    </div>

                    <SVG svg="arrow" />
                </Link>

                <Link to={"/collaborate/logs/rl"} className="my-panel__item">
                    <div className="row a-c">
                        <div className={`my-panel__item__icon rl-logo`}>
                            <SVG svg={"rl-logo"} />
                        </div>
                        <div>{"تاریخچه پاداش (Reward Limit)"}</div>
                    </div>

                    <SVG svg="arrow" />
                </Link>
            </Padding>
        </Fade>
    );
};

export default SlRlContainer;
