import React, { useEffect, useState } from "react";
import NeshanMap from "react-neshan-map-leaflet";
import "./NewAddress.scss";
import MarkerIcon from "../../assets/images/marker.svg";
import Padding from "../Padding/Padding";
import {SVG} from "components";
import { farsiDigitToEnglish } from "utils/Functions";

export const NewAddress = ({ active, setClose, onSubmit }) => {
  const [title, setTitle] = useState("");
  const [address, setAddress] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [buildingNumber, setBuildingNumber] = useState("");
  const [apartmentNumber, setApartmentNumber] = useState("");
  const [latlng, setLatlng] = useState([32.6439, 51.666]);
  const [map, setMap] = useState(null);
  const [marker, setMarker] = useState(null);

  const handleSubmitNewAddress = () => {
    onSubmit({
      title,
      firstname,
      lastname,
      address,
      lat: latlng[0],
      lng: latlng[1],
      postalCode,
      stateId: 3,
      cityId: 2,
      buildingNumber,
      apartmentNumber,
      mobile: phoneNumber,
    });
    setClose(false);
  };

  useEffect(() => {
    const currAddress = active.address;
    if (currAddress !== null) {
      setTitle(currAddress.title);
      setFirstName(currAddress.firstname);
      setLastName(currAddress.lastname);
      setAddress(currAddress.address);
      setPostalCode(currAddress.postalCode);
      setBuildingNumber(currAddress.buildingNumber);
      setApartmentNumber(currAddress.apartmentNumber);
      setPhoneNumber(currAddress.mobile);
      setLatlng([parseInt(currAddress.lat), parseInt(currAddress.lng)]);
      if (map !== null) {
        map.panTo([parseInt(currAddress.lat), parseInt(currAddress.lng)]);
        map._zoom = 15;
      }
      if (marker !== null) {
        marker.setLatLng([
          parseInt(currAddress.lat),
          parseInt(currAddress.lng),
        ]);
      }
    } else {
      if (map !== null) {
        map.panTo(latlng);
        map._zoom = 15;
      }
      if (marker !== null) {
        marker.setLatLng(latlng);
      }
    }
  }, [active]);

  return (
    <div className={`new-address ${active.active && "active"}`}>
      <div className="new-address__header">
        <div className="new-address__close" onClick={() => setClose(false)}>
          <SVG svg="times" />
        </div>

        <div
          className="new-address__button"
          onClick={() => handleSubmitNewAddress()}
        >
          افزودن آدرس جدید
        </div>
      </div>

      <div className="new-address__body">
        <div className="title">عنوان آدرس</div>
        <input
          type="text"
          className="input"
          onChange={(e) => setTitle(e.target.value)}
          value={title}
        />

        <div className="title">نشانی</div>
        <input
          type="text"
          className="input"
          onChange={(e) => setAddress(e.target.value)}
          value={address}
        />

        <div className="title">پلاک</div>
        <input
          type="text"
          className="input"
          onChange={(e) => setBuildingNumber(e.target.value)}
          value={buildingNumber}
        />

        <div className="title">واحد</div>
        <input
          type="text"
          className="input"
          onChange={(e) => setApartmentNumber(e.target.value)}
          value={apartmentNumber}
        />

        <div className="title">کدپستی</div>
        <input
          type="text"
          className="input"
          onChange={(e) => setPostalCode(e.target.value)}
          value={postalCode}
        />

        <div className="title">نام تحویل گیرنده</div>
        <input
          type="text"
          className="input"
          onChange={(e) => setFirstName(e.target.value)}
          value={firstname}
        />

        <div className="title">نام‌خانوادگی تحویل گیرنده</div>
        <input
          type="text"
          className="input"
          onChange={(e) => setLastName(e.target.value)}
          value={lastname}
        />

        <div className="title">شماره تماس تحویل گیرنده</div>
        <input
          type="tel"
          className="input"
          onChange={(e) => setPhoneNumber(farsiDigitToEnglish(e.target.value))}
          value={phoneNumber}
        />

        <div className="title">موقعیت مکانی</div>

        <NeshanMap
          options={{
            key: "web.Db53vvq18EZAQA7cVoQCjbBJsIkm4RwsPgilb2mg",
            maptype: "dreamy-gold",
            poi: true,
            traffic: false,
            center: latlng,
            zoom: 15,
          }}
          onInit={(L, myMap) => {
            setMap(myMap);
            let marker = L.marker(latlng, {
              draggable: true,
              autoPan: true,
              autoPanSpeed: 6,
              icon: L.icon({ iconUrl: MarkerIcon, iconSize: [24, 34] }),
            })
              .addTo(myMap)
              .bindPopup("نشانگر شما");
            setMarker(marker);
            myMap.on("click", function (e) {
              setLatlng([e.latlng.lat, e.latlng.lng]);
              // myMap.panTo(e.latlng)
              marker.setLatLng(e.latlng);
            });
          }}
        />
      </div>
    </div>
  );
};
