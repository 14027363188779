import { Card, Dots, Fade, PageTopbar, Spinner } from "../../../components";
import { Link, useHistory, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { getHintAPI } from "utils";
import { Buy, Item } from "containers/KartLandContainer/dependencies";
import { getUserUnitsAPI } from "utils/Services/KartlandUnits";
import empty from "../../../assets/images/empty-kartland.png";

export const UnitBuyTypeContainer = () => {
    const [loading, setLoading] = useState(true);
    const [myUnits, setMyUnits] = useState([]);
    const history = useHistory();

    const getUserUnits = () => {
        setLoading(true);
        getUserUnitsAPI().then((res) => {
            if (res.status === 200) {
                setMyUnits(res.data);
                setLoading(false);
            }
        });
    };

    useEffect(() => {
        getUserUnits();
    }, []);

    useEffect(() => {
        history.replace("/kartland/buy/options");
    }, []);

    return (
        <Fade>
            <Spinner active={loading} color="dark" />
            <div className={"kartland"}>
                <PageTopbar title="کارتلند" showKv={false} white={true} />
                <div className={"kartland__user-buy-wrapper"}>
                    {/* <div className={"kartland-buy__actions"}>
            <div className="button" onClick={() => setActions(!actions)}>
              خرید واحد
            </div>
            <Link to={'/kartland/participation'} className={"button"}>مشارکت</Link>
          </div> */}

                    {/* {actions && (
            <div className={"kartland-buy__actions"}>
              <Link to={'/kartland/buy'} className={"button green"}>خرید از کارتک</Link>
              <Link to={`/kartland/user-buy`} className={"button green"}>خرید از کاربران</Link>
            </div>
          )} */}

                    {/* <Link style={{marginBottom: "15px"}} to={'/kartland/my-citizen'} className={"button"}>سیتیزن های من</Link> */}

                    <Item
                        myCitizen={false}
                        citizenUnits=""
                        myUnits={myUnits}
                        data={myUnits}
                    />
                    {myUnits.length === 0 && (
                        <div className="kartland__wrapper__empty">
                            <div className="kartland__wrapper__empty__image">
                                <img src={empty} alt="empty" />
                            </div>
                            <div className="kartland__wrapper__empty__text">
                                ویترینی وجود ندارد
                            </div>
                            <div className="kartland__wrapper__empty__subtext">
                                درحال حاضر شما ویترینی خریداری نکرده‌اید
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </Fade>
    );
};
