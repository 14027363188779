import { useEffect, useState } from "react";
import { PageTopbar, SVG, Modal, Fade, Spinner, Card, Dots } from "components";
import { LivePrice, Hint, Wallet } from "./dependecies";
import {
    getWhiteKmHistoryAPI,
    getCurrTransferListAPI,
    setReceiverTransferActionAPI,
    setSenderTransferActionAPI,
    getBlueKmHistoryAPI,
    getRedKmHistoryAPI,
} from "utils/Services/Km";
import moment from "jalali-moment";
import Countdown from "react-countdown";
import toast from "react-hot-toast";
import { useHistory, useLocation } from "react-router-dom";
import { cloneDeep } from "lodash";
import { getHintAPI } from "utils";
import { getRedKmGroupHistoryAPI } from "../../utils/Services/Km/Red/RedKmGroupHistory";
import { useUser } from "hooks";

export const KmWalletContainer = ({ children }) => {
    const history = useHistory();
    const location = useLocation();
    const { getUserDataFromApi } = useUser();

    const [hintModal, setHintModal] = useState(false);
    const [kmTransfers, setKmTransfers] = useState({
        destination: [],
        origin: [],
    });
    const [loading, setLoading] = useState(true);
    const [redPriceLoadling, setRedPriceLoadling] = useState(false);
    const [redKmHistory, setRedKmHistory] = useState<any>({
        logs: [],
        totalAmount: 0,
    });
    const [whiteKmHistory, setWhiteKmHistory] = useState({
        logs: [],
        totalAmount: 0,
    });
    const [blueKmHistory, setBlueKmHistory] = useState({
        logs: [],
        totalAmount: 0,
    });
    const [redHistory, setRedHistory] = useState<any>([]);
    const [kmType, setKmType] = useState("white");
    const [transferLoading, setTransferLoading] = useState(-1);
    const [transferModal, setTransferModal] = useState(false);
    const [transferModalLoading, setTransferModalLoading] = useState(false);
    const [transferId, setTransferId] = useState(false);
    const [currPage, setCurrPage] = useState(1);
    const [numberOfPages, setNumberOfPages] = useState(1);
    const [canLoadMore, setCanLoadMore] = useState(true);
    const [redKmPagination, setRedKmPagination] = useState(false);
    const [redhint, setRedHint] = useState<any>({
        title: "",
        content: "",
    });
    const [whitehint, setWhiteHint] = useState<any>({
        title: "",
        content: "",
    });
    const [transferhint, setTransfeHint] = useState<any>({
        title: "",
        content: "",
    });

    const handleDepositWithdrawName = (type) => {
        if (type === 0) {
            return "withdraw";
        } else {
            return "deposit";
        }
    };

    const getWhiteKmHistory = () => {
        getWhiteKmHistoryAPI()
            .then((response) => {
                setWhiteKmHistory(response.data);
                setLoading(false);
            })
            .catch((err) => {
                toast.error("عملیات با خطا مواجه شد");
            });
    };

    const getBlueKmHistory = () => {
        getBlueKmHistoryAPI()
            .then((response) => {
                setBlueKmHistory(response.data);
                setLoading(false);
            })
            .catch((err) => {
                toast.error("عملیات با خطا مواجه شد");
            });
    };

    // const getRedKmHistory = (page) => {
    //   setRedKmPagination(true);
    //   setRedPriceLoadling(true);
    //   getRedKmHistoryAPI(15, page)
    //     .then((response) => {
    //       // setRedKmHistory(response.data);
    //       let currHistory = cloneDeep(redKmHistory);
    //       currHistory.totalAmount = response.data.totalAmount;
    //       console.log(currHistory);
    //       response.data.logs.data.forEach((item) => {
    //         currHistory.logs.push(item);
    //       });
    //
    //       if (response.data.logs.next_page_url === null) {
    //         setCanLoadMore(false);
    //       }
    //
    //       setRedKmHistory(currHistory);
    //       setLoading(false);
    //       setRedPriceLoadling(false);
    //     })
    //     .catch((err) => {
    //       toast.error("عملیات با خطا مواجه شد");
    //     })
    //     .finally(() => {
    //       setRedKmPagination(false);
    //     });
    // };

    const getGroupRedKmHistory = () => {
        setRedPriceLoadling(true);
        getRedKmGroupHistoryAPI()
            .then((response) => {
                setRedKmHistory(response.data);
                setRedHistory(Object.values(response.data?.logs));
                setLoading(false);
                setRedPriceLoadling(false);
            })
            .catch((err) => {
                toast.error("عملیات با خطا مواجه شد");
            })
            .finally(() => {
                setRedKmPagination(false);
            });
    };

    const getKmTransfers = () => {
        getCurrTransferListAPI()
            .then((response) => {
                setKmTransfers(response.data);
            })
            .catch((err) => {
                toast.error("عملیات با خطا مواجه شد");
            })
            .finally(() => {
                setTransferLoading(-1);
            });
    };

    const handleTransferAction = (
        transferId,
        transferType,
        actionType,
        index
    ) => {
        setTransferLoading(index);
        switch (transferType) {
            case "destination":
                setTransferModalLoading(true);
                setTransferLoading(index);
                setReceiverTransferActionAPI(transferId, actionType)
                    .then((response) => {
                        if (response.status === 200) {
                            // toast.success('');
                        } else if (response.status === 403) {
                            toast.error("انتقال در طرف فرستنده لغو شد");
                        }
                    })
                    .catch((err) => {
                        toast.error("عملیات با خطا مواجه شد");
                    })
                    .finally(() => {
                        getKmTransfers();
                        getWhiteKmHistory();
                        modalHandler(false, -1, false);
                    });
                break;

            case "origin":
                setTransferLoading(index);
                setSenderTransferActionAPI(transferId)
                    .then((response) => {
                        if (response.status === 200) {
                            // toast.success('');
                        }
                    })
                    .catch((err) => {
                        toast.error("عملیات با خطا مواجه شد");
                    })
                    .finally(() => {
                        getKmTransfers();
                        getWhiteKmHistory();
                    });
                break;

            default:
                break;
        }
    };

    const handleShowTransferTitle = (type, kmAmount) => {
        switch (type) {
            case "origin":
                return `درخواست انتقال ${kmAmount} KM از طرف شما`;

            case "destination":
                return `درخواست انتقال ${kmAmount} KM به شما`;

            default:
                break;
        }
    };

    const modalHandler = (active, id, loading) => {
        setTransferModal(active);
        setTransferId(id);
        setTransferModalLoading(loading);
    };

    const walletHistoryDiv = (walletHistory, index, walletType) => {
        return (
            <>
                <div className="km__history__item" key={index}>
                    <div
                        className={`km__history__type ${handleDepositWithdrawName(
                            location.search.substr(1) === "red"
                                ? walletHistory.isExist
                                : walletHistory.transactionType
                        )}`}
                    >
                        <SVG
                            svg={handleDepositWithdrawName(
                                location.search.substr(1) === "red"
                                    ? walletHistory.isExist
                                    : walletHistory.transactionType
                            )}
                        />
                    </div>
                    <div className="km__history__detail">
                        <div className="col">
                            <div className="row">
                                <div
                                    className={`km__history__detail__type ${handleDepositWithdrawName(
                                        location.search.substr(1) === "red"
                                            ? walletHistory.isExist
                                            : walletHistory.transactionType
                                    )}`}
                                >
                                    <SVG svg="km-logo" />
                                </div>

                                <div className="col km__history__detail__box">
                                    <div className="km__history__detail__amount">
                                        {location.search.substr(1) === "red"
                                            ? "1 KP"
                                            : walletHistory.amount + " KM"}
                                    </div>
                                    <div className="km__history__detail__source">
                                        {walletHistory.description}
                                    </div>
                                </div>
                            </div>
                            <div className="km__history__detail__date">
                                {moment(
                                    walletHistory.created_at,
                                    "YYYY-MM-DD h:mm:ss"
                                )
                                    .locale("fa")
                                    .format("H:mm - YYYY/MM/DD")}
                            </div>
                        </div>
                    </div>
                </div>

                {index !== walletType?.logs?.length - 1 && (
                    <div className="km__history__seperator" />
                )}
            </>
        );
    };

    const redWalletHistoryDiv = (walletHistory, index) => {
        return (
            <>
                <div className="km__history__item" key={index}>
                    <div
                        className={`km__history__type ${handleDepositWithdrawName(
                            location.search.substr(1) === "red"
                                ? walletHistory[0].isExist
                                : walletHistory.transactionType
                        )}`}
                    >
                        <SVG
                            svg={handleDepositWithdrawName(
                                location.search.substr(1) === "red"
                                    ? walletHistory[0].isExist
                                    : walletHistory.transactionType
                            )}
                        />
                    </div>
                    <div className="km__history__detail">
                        <div className="col">
                            <div className="row">
                                <div
                                    className={`km__history__detail__type ${handleDepositWithdrawName(
                                        location.search.substr(1) === "red"
                                            ? walletHistory[0].isExist
                                            : walletHistory.transactionType
                                    )}`}
                                >
                                    <SVG svg="kp-logo" />
                                </div>

                                <div className="col km__history__detail__box">
                                    <div className="km__history__detail__amount">
                                        {location.search.substr(1) === "red"
                                            ? walletHistory.length + " KP"
                                            : walletHistory.amount + " KM"}
                                    </div>
                                    <div className="km__history__detail__source">
                                        {walletHistory[0].description}
                                    </div>
                                </div>
                            </div>
                            <div className="km__history__detail__date">
                                {moment(
                                    walletHistory[0].created_at,
                                    "YYYY-MM-DD h:mm:ss"
                                )
                                    .locale("fa")
                                    .format("H:mm - YYYY/MM/DD")}
                            </div>
                        </div>
                    </div>
                </div>

                {index !== redHistory.length - 1 && (
                    <div className="km__history__seperator" />
                )}
            </>
        );
    };

    const getHint = () => {
        getHintAPI("صفحات داخلی کیف KM قرمز")
            .then((response) => {
                setRedHint(response);
            })
            .catch(() => setRedHint(false))
            .finally(() => {});

        getHintAPI("صفحات داخلی کیف KM سفید")
            .then((response) => {
                setWhiteHint(response);
            })
            .catch(() => setWhiteHint(false))
            .finally(() => {});

        getHintAPI("هشدار انتقال KM سفید برای دریافت کننده")
            .then((response) => {
                setTransfeHint(response);
            })
            .catch(() => setTransfeHint(false))
            .finally(() => {});
    };

    useEffect(() => {
        if (kmType === "white") getKmTransfers();
    }, [kmType]);

    useEffect(() => {
        history.replace(`/wallet/km${location.search}`);
        getWhiteKmHistory();
        getBlueKmHistory();
        // getRedKmHistory(1);
        getGroupRedKmHistory();
        getUserDataFromApi();
        getHint();
    }, []);

    return (
        <Fade>
            <div className="km">
                <Modal
                    active={transferModal}
                    close={() => setTransferModal(false)}
                >
                    <div className={"transfer-modal"}>
                        <div className={"transfer-modal__text"}>
                            <div className={"transfer-modal__text__title"}>
                                {transferhint.title}
                            </div>
                            <div className={"transfer-modal__text__body"}>
                                {transferhint.content}
                            </div>
                        </div>
                        <div className={"transfer-modal__actions"}>
                            <div
                                className={
                                    "km__transfer__actions__button km__transfer__accept"
                                }
                                onClick={() =>
                                    transferModalLoading === false &&
                                    handleTransferAction(
                                        transferId,
                                        "destination",
                                        "approved",
                                        -1
                                    )
                                }
                            >
                                {transferModalLoading ? (
                                    <Dots color="light" />
                                ) : (
                                    <span>تایید</span>
                                )}
                            </div>
                            <div
                                onClick={() => modalHandler(false, -1, false)}
                                className={
                                    "km__transfer__actions__button km__transfer__reject"
                                }
                            >
                                بازگشت
                            </div>
                        </div>
                    </div>
                </Modal>
                <PageTopbar
                    title={
                        location.search.substr(1) === "red"
                            ? "کیف KP"
                            : "کیف KM"
                    }
                    showKv={false}
                    circleHint={
                        location.search.substr(1) === "white"
                            ? whitehint?.content
                            : location.search.substr(1) === "red"
                            ? redhint?.content
                            : ""
                    }
                />
                <Spinner active={loading} color="dark" />
                {/* <Modal
          active={hintModal}
          close={() => setHintModal(false)}
          hint={"getKv"}
        >
          {location.search.substr(1) === "white" && (
            <>
              <div className="hints__title">{whitehint.title}</div>
              <div className="hints__content">
                {whitehint.content?.split("\n")?.map((str) => (
                  <div>{str}</div>
                ))}
              </div>
            </>
          )}

          {location.search.substr(1) === "red" && (
            <>
              <div className="hints__title">{redhint.title}</div>
              <div className="hints__content">
                {redhint.content?.split("\n")?.map((str) => (
                  <div>{str}</div>
                ))}
              </div>
            </>
          )}
        </Modal> */}
                <Card>
                    {location.search.substr(1) === "red" && <LivePrice />}

                    {kmTransfers?.destination?.map((transferItem, index) => (
                        <div className="km__transfer" key={index}>
                            <div className="row a-c j-sb">
                                <div className="km__transfer__title">
                                    {handleShowTransferTitle(
                                        "destination",
                                        transferItem.amount
                                    )}
                                </div>

                                <div className=""></div>
                            </div>

                            <div className="km__transfer__countdown">
                                <Countdown
                                    daysInHours={true}
                                    date={
                                        new Date(
                                            transferItem.created_at
                                        ).getTime() +
                                        5 * 60 * 1000
                                    }
                                />
                            </div>

                            <div className="km__transfer__actions">
                                <div
                                    className="km__transfer__actions__button km__transfer__accept"
                                    onClick={() =>
                                        modalHandler(
                                            true,
                                            transferItem.id,
                                            false
                                        )
                                    }
                                >
                                    تایید
                                </div>
                                <div
                                    className="km__transfer__actions__button km__transfer__reject"
                                    onClick={() =>
                                        transferLoading === -1 &&
                                        handleTransferAction(
                                            transferItem.id,
                                            "destination",
                                            "reject",
                                            index
                                        )
                                    }
                                >
                                    {transferLoading === index ? (
                                        <Dots color="light" />
                                    ) : (
                                        <span>انصراف</span>
                                    )}
                                </div>
                            </div>
                        </div>
                    ))}
                    {kmTransfers?.origin?.map((transferItem, index) => (
                        <div className="km__transfer" key={index}>
                            <div className="row a-c j-sb">
                                <div className="km__transfer__title">
                                    {handleShowTransferTitle(
                                        "origin",
                                        transferItem.amount
                                    )}
                                </div>

                                <div className=""></div>
                            </div>

                            <div className="km__transfer__countdown">
                                {/* {console.log(new Date(transferItem.expire).getTime())} */}
                                <Countdown
                                    daysInHours={true}
                                    date={
                                        new Date(
                                            transferItem.created_at
                                        ).getTime() +
                                        5 * 60 * 1000
                                    }
                                />
                            </div>

                            <div className="km__transfer__actions">
                                <div
                                    className="km__transfer__actions__button km__transfer__reject"
                                    onClick={() =>
                                        transferLoading === -1 &&
                                        handleTransferAction(
                                            transferItem.id,
                                            "origin",
                                            "reject",
                                            index
                                        )
                                    }
                                >
                                    {transferLoading === index ? (
                                        <Dots color="light" />
                                    ) : (
                                        <span>انصراف</span>
                                    )}
                                </div>
                            </div>
                        </div>
                    ))}
                </Card>

                {/* <Hint toggle={setHintModal} /> */}

                <Wallet
                    loading={redPriceLoadling}
                    kmHistory={{
                        red: redKmHistory,
                        white: whiteKmHistory,
                        blue: blueKmHistory,
                    }}
                    refreshRedKmAmount={getGroupRedKmHistory}
                    type={location.search.substr(1) === "red" ? "red" : "white"}
                    setType={setKmType}
                />

                <Card>
                    {location.search.substr(1) === "white" &&
                        whiteKmHistory?.logs?.map((walletHistory, index) =>
                            walletHistoryDiv(
                                walletHistory,
                                index,
                                whiteKmHistory
                            )
                        )}

                    {/*{location.search.substr(1) === "red" &&*/}
                    {/*  redKmHistory?.logs?.map((walletHistory, index) =>*/}
                    {/*    walletHistoryDiv(walletHistory, index, redKmHistory)*/}
                    {/*  )}*/}

                    {location.search.substr(1) === "red" &&
                        redHistory.map((walletHistory, index) =>
                            redWalletHistoryDiv(walletHistory, index)
                        )}

                    {kmType === "blue" &&
                        blueKmHistory?.logs?.map((walletHistory, index) =>
                            walletHistoryDiv(
                                walletHistory,
                                index,
                                blueKmHistory
                            )
                        )}

                    {/*{location.search.substr(1) === "red" && canLoadMore && (*/}
                    {/*  <div*/}
                    {/*    className={"km__transfer__actions__load-more"}*/}
                    {/*    onClick={() => {*/}
                    {/*      if (!redKmPagination) {*/}
                    {/*        setCurrPage(currPage + 1);*/}
                    {/*        getRedKmHistory(currPage + 1);*/}
                    {/*      }*/}
                    {/*    }}*/}
                    {/*  >*/}
                    {/*    {redKmPagination ? <Dots color="dark" /> : "نمایش بیشتر"}*/}
                    {/*  </div>*/}
                    {/*)}*/}
                </Card>
            </div>
        </Fade>
    );
};
