import { SVG } from "components/SVG";
import "./SlidingHint.scss";

export const SlidingHint = ({openTitle = "راهنما", open = false, onClick, text}) => {

  return (
    <div className={`SlidingHint ${open ? "SlidingHint--open" : ""}`}>
        <div className="SlidingHint__header" onClick={ () => onClick()}>
          <div className="SlidingHint__header__arrows">
            {
              <div className={open ? "SlidingHint__header__arrows__rotate" : ""}>
                <SVG svg="arrow-down"/>
                <SVG svg="arrow-down"/>
              </div>
            }
          </div>
          <p>{openTitle}</p>
          <div className="SlidingHint__header__arrows">
            {
              <div className={open ? "SlidingHint__header__arrows__rotate" : ""}>
                <SVG svg="arrow-down"/>
                <SVG svg="arrow-down"/>
              </div>
            }
          </div>
        </div>
        <div className="SlidingHint__body">
            <p>{text}</p>
        </div>
    </div>
  );
};