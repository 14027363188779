import { useState, useEffect } from "react";
import { SVG, Share, Dots } from "components";
import QRCode from "react-qr-code";
import { Counter } from "../../dependencies";
import { CreateKmLinkAPI } from "utils";
import { useLocation } from "react-router-dom";

export const Qr = () => {
  const [userCode, setUserCode] = useState("");
  const [toggleQrBackground, setToggleQrBackground] = useState(false);
  const [codeTime, setCodeTime] = useState("");
  const [loading, setLoading] = useState(true);
  const [showSharing, setShowSharing] = useState(false);
  const location = useLocation();
  const query = useQuery();

  function useQuery() {
    return new URLSearchParams(location.search);
  }

  const getQr = () => {
    const api = CreateKmLinkAPI(query.get('amount'));
    api.then((response) => {
      setUserCode(response.data.qr);
      setLoading(false);
    });
  };

  useEffect(() => {
    getQr();
  }, []);

  return (
    <>
      <Share
        withBtnNav
        active={showSharing}
        setClose={(close) => setShowSharing(close)}
        title="ارسال بارکد"
        value={`با استفاده از این لینک KM بگیرید
        \n لینک دریافت KM :
        https://app.kartaak.com/qr/km/${userCode}
        \n\n وبسایت کارتک : 
        `}
        link={`https://kartaak.com`}
      />
      <Counter amount={query.get('amount')} />
      <div className="km-donate__qrbox">
        {loading ? (
          <Dots color="dark" />
        ) : (
          <QRCode
            id="QRCode"
            value={`https://app.kartaak.com/qr/km/${userCode}`}
            size={260}
            bgColor={toggleQrBackground ? "white" : "transparent"}
          />
        )}
      </div>
      <div className="km-donate__actions row">
        <div className="km-donate__actions__share" onClick={() => setShowSharing(true)}>
          <SVG svg="share" />
          <span>اشتراک گذاری</span>
        </div>
      </div>
    </>
  );
};
