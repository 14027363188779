import { Fade, PageTopbar, Spinner } from "components";
import { Description, Summery } from "containers/OrdersContainer/dependencies";
import { useEffect, useState, Fragment } from "react";
import toast from "react-hot-toast";
import { useHistory, useParams } from "react-router-dom";
import { getCardDetailsAPI } from "utils";

export const OrderDetailsContainer = () => {
    const history = useHistory();
    const { id }: any = useParams();

    const [loading, setLoading] = useState(true);
    const [cardData, setCardData] = useState<any>();

    const getCardDetails = () => {
        getCardDetailsAPI(+id)
            .then((response) => {
                if (response.status === 200) {
                    setCardData(response.data);
                }
            })
            .catch((error) => {
                toast.error("دریافت جزییات سفارش با موفقیت انجام شد.");
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        getCardDetails();
        history.replace("/order-details/" + id);
    }, []);

    return (
        <Fade>
            <PageTopbar title={"جزییات سفارش"} showKv={false} />
            <Spinner active={loading} color={"dark"} />
            <div className="wrapper-fix card-details">
                {!loading && (
                    <Fragment>
                        <Description cart={cardData} />
                        <Summery cart={cardData} />
                    </Fragment>
                )}
            </div>
        </Fade>
    );
};
