import "./active_bar.scss";

export const ActiveBar = ({color="#21BBE1"}) => {
  return (
    <div className="activebar">
      <svg
        width="58"
        height="15"
        viewBox="0 0 58 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 14.8188C22.0081 12.2652 21.2387 0.828125 28.1875 0.828125C35.1363 0.828125 36.0095 12.2675 57.469 14.8198L0 14.8188Z"
          fill={color}
        />
      </svg>
    </div>
  );
};
