import { Fade, PageTopbar, Spinner, SVG } from "components";
import { useEffect, useState } from "react";
import { getHintAPI } from "utils";
import { useHistory } from "react-router-dom";

const Rules = () => {
    const history = useHistory();
    const [activeCollapse, setActiveCollapse] = useState(0);
    const [loading, setLoading] = useState(true);
    const [hint1, setHint1] = useState({
        title: "",
        content: "",
    });
    const [hint2, setHint2] = useState({
        title: "",
        content: "",
    });
    const [hint3, setHint3] = useState({
        title: "",
        content: "",
    });
    const [hint4, setHint4] = useState({
        title: "",
        content: "",
    });
    const [hint5, setHint5] = useState({
        title: "",
        content: "",
    });
    const [hint6, setHint6] = useState({
        title: "",
        content: "",
    });
    const [hint7, setHint7] = useState({
        title: "",
        content: "",
    });
    const [hint8, setHint8] = useState({
        title: "",
        content: "",
    });
    const [hint9, setHint9] = useState({
        title: "",
        content: "",
    });
    const [hint10, setHint10] = useState({
        title: "",
        content: "",
    });
    const [hint11, setHint11] = useState({
        title: "",
        content: "",
    });
    const [hint12, setHint12] = useState({
        title: "",
        content: "",
    });
    const [hint13, setHint13] = useState({
        title: "",
        content: "",
    });
    const [hint14, setHint14] = useState({
        title: "",
        content: "",
    });
    const [hint15, setHint15] = useState({
        title: "",
        content: "",
    });
    const [hint16, setHint16] = useState({
        title: "",
        content: "",
    });
    const [hint17, setHint17] = useState({
        title: "",
        content: "",
    });
    const [hint18, setHint18] = useState({
        title: "",
        content: "",
    });

    const getHint1 = () => {
        getHintAPI("راهنمای کلی 1")
            .then((response) => {
                setHint1(response);
            })
            .catch(() => setHint1(false))
            .finally(() => {});
    };

    // const getHint2 = () => {
    //     getHintAPI("راهنمای کلی 2")
    //         .then((response) => {
    //             setHint2(response);
    //         })
    //         .catch(() => setHint2(false))
    //         .finally(() => {});
    // };

    const getHint3 = () => {
        getHintAPI("راهنمای کلی 3")
            .then((response) => {
                setHint3(response);
            })
            .catch(() => setHint3(false))
            .finally(() => {});
    };

    // const getHint4 = () => {
    //     getHintAPI("راهنمای کلی 4")
    //         .then((response) => {
    //             setHint4(response);
    //         })
    //         .catch(() => setHint4(false))
    //         .finally(() => {});
    // };

    // const getHint5 = () => {
    //     getHintAPI("راهنمای کلی 5")
    //         .then((response) => {
    //             setHint5(response);
    //         })
    //         .catch(() => setHint5(false))
    //         .finally(() => {});
    // };

    // const getHint6 = () => {
    //     getHintAPI("راهنمای کلی 6")
    //         .then((response) => {
    //             setHint6(response);
    //         })
    //         .catch(() => setHint6(false))
    //         .finally(() => {});
    // };

    const getHint7 = () => {
        getHintAPI("راهنمای کلی 7")
            .then((response) => {
                setHint7(response);
            })
            .catch(() => setHint7(false))
            .finally(() => {});
    };

    const getHint8 = () => {
        getHintAPI("راهنمای کلی 8")
            .then((response) => {
                setHint8(response);
            })
            .catch(() => setHint8(false))
            .finally(() => {});
    };

    // const getHint9 = () => {
    //     getHintAPI("راهنمای کلی 9")
    //         .then((response) => {
    //             setHint9(response);
    //         })
    //         .catch(() => setHint9(false))
    //         .finally(() => {});
    // };

    const getHint10 = () => {
        getHintAPI("راهنمای کلی 10")
            .then((response) => {
                setHint10(response);
            })
            .catch(() => setHint10(false))
            .finally(() => {});
    };

    const getHint11 = () => {
        getHintAPI("راهنمای کلی 11")
            .then((response) => {
                setHint11(response);
            })
            .catch(() => setHint11(false))
            .finally(() => {});
    };

    // const getHint12 = () => {
    //     getHintAPI("راهنمای کلی 12")
    //         .then((response) => {
    //             setHint12(response);
    //         })
    //         .catch(() => setHint12(false))
    //         .finally(() => {});
    // };

    // const getHint13 = () => {
    //     getHintAPI("راهنمای کلی 13")
    //         .then((response) => {
    //             setHint13(response);
    //         })
    //         .catch(() => setHint13(false))
    //         .finally(() => {});
    // };

    const getHint14 = () => {
        getHintAPI("راهنمای کلی 14")
            .then((response) => {
                setHint14(response);
            })
            .catch(() => setHint14(false))
            .finally(() => {});
    };

    // const getHint15 = () => {
    //     getHintAPI("راهنمای کلی 15")
    //         .then((response) => {
    //             setHint15(response);
    //         })
    //         .catch(() => setHint15(false))
    //         .finally(() => {});
    // };

    // const getHint16 = () => {
    //     getHintAPI("راهنمای کلی 16")
    //         .then((response) => {
    //             setHint16(response);
    //         })
    //         .catch(() => setHint16(false))
    //         .finally(() => {});
    // };

    // const getHint17 = () => {
    //     getHintAPI("راهنمای کلی 17")
    //         .then((response) => {
    //             setHint17(response);
    //         })
    //         .catch(() => setHint17(false))
    //         .finally(() => {
    //             setLoading(false);
    //         });
    // };

    // const getHint18 = () => {
    //     getHintAPI("راهنمای کلی 18")
    //         .then((response) => {
    //             setHint18(response);
    //         })
    //         .catch(() => setHint18(false))
    //         .finally(() => {
    //             setLoading(false);
    //         });
    // };

    useEffect(() => {
        getHint1();
        //getHint2();
        getHint3();
        //getHint4();
        //getHint5();
        //getHint6();
        getHint7();
        getHint8();
        //getHint9();
        getHint10();
        getHint11();
        //getHint12();
        //getHint13();
        getHint14();
        //getHint15();
        //getHint16();
        //getHint17();
        //getHint18();
    }, []);

    useEffect(() => {
        history.replace("/about");
    }, []);

    return (
        <Fade>
            <PageTopbar title="راهنماهای کارتک" showKv={false} />
            <Spinner active={loading} color={"dark"} />

            <div className="about">
                <div
                    className={`about__item ${
                        activeCollapse === 1 && "active"
                    }`}
                    onClick={() =>
                        activeCollapse === 1
                            ? setActiveCollapse(0)
                            : setActiveCollapse(1)
                    }
                >
                    {hint1.title}
                    <SVG svg="arrow" />
                </div>
                {activeCollapse === 1 && (
                    <div className="about__description">
                        {hint1.content?.split("\n")?.map((str) => (
                            <div>{str}</div>
                        ))}
                    </div>
                )}

                <div
                    className={`about__item ${
                        activeCollapse === 2 && "active"
                    }`}
                    onClick={() =>
                        activeCollapse === 2
                            ? setActiveCollapse(0)
                            : setActiveCollapse(2)
                    }
                >
                    {hint2.title}
                    <SVG svg="arrow" />
                </div>
                {activeCollapse === 2 && (
                    <div className="about__description">
                        {hint2.content?.split("\n")?.map((str) => (
                            <div>{str}</div>
                        ))}
                    </div>
                )}

                {/*<div className={`about__item ${activeCollapse === 3 && 'active'}`}*/}
                {/*     onClick={() => activeCollapse === 3 ? setActiveCollapse(0) : setActiveCollapse(3)}>*/}
                {/*  {hint3.title}*/}
                {/*  <SVG svg="arrow"/>*/}
                {/*</div>*/}
                {/*{*/}
                {/*  activeCollapse === 3 && (*/}
                {/*    <div className="about__description">*/}
                {/*      {hint3.content?.split('\n')?.map(str => <div>{str}</div>)}*/}
                {/*    </div>*/}
                {/*  )*/}
                {/*}*/}

                <div
                    className={`about__item ${
                        activeCollapse === 4 && "active"
                    }`}
                    onClick={() =>
                        activeCollapse === 4
                            ? setActiveCollapse(0)
                            : setActiveCollapse(4)
                    }
                >
                    {hint4.title}
                    <SVG svg="arrow" />
                </div>
                {activeCollapse === 4 && (
                    <div className="about__description">
                        {hint4.content?.split("\n")?.map((str) => (
                            <div>{str}</div>
                        ))}
                    </div>
                )}

                <div
                    className={`about__item ${
                        activeCollapse === 5 && "active"
                    }`}
                    onClick={() =>
                        activeCollapse === 5
                            ? setActiveCollapse(0)
                            : setActiveCollapse(5)
                    }
                >
                    {hint5.title}
                    <SVG svg="arrow" />
                </div>
                {activeCollapse === 5 && (
                    <div className="about__description">
                        {hint5.content?.split("\n")?.map((str) => (
                            <div>{str}</div>
                        ))}
                    </div>
                )}

                <div
                    className={`about__item ${
                        activeCollapse === 18 && "active"
                    }`}
                    onClick={() =>
                        activeCollapse === 18
                            ? setActiveCollapse(0)
                            : setActiveCollapse(18)
                    }
                >
                    {hint18.title}
                    <SVG svg="arrow" />
                </div>
                {activeCollapse === 18 && (
                    <div className="about__description">
                        {hint18.content?.split("\n")?.map((str) => (
                            <div>{str}</div>
                        ))}
                    </div>
                )}

                <div
                    className={`about__item ${
                        activeCollapse === 6 && "active"
                    }`}
                    onClick={() =>
                        activeCollapse === 6
                            ? setActiveCollapse(0)
                            : setActiveCollapse(6)
                    }
                >
                    {hint6.title}
                    <SVG svg="arrow" />
                </div>
                {activeCollapse === 6 && (
                    <div className="about__description">
                        {hint6.content?.split("\n")?.map((str) => (
                            <div>{str}</div>
                        ))}
                    </div>
                )}

                <div
                    className={`about__item ${
                        activeCollapse === 7 && "active"
                    }`}
                    onClick={() =>
                        activeCollapse === 7
                            ? setActiveCollapse(0)
                            : setActiveCollapse(7)
                    }
                >
                    {hint7.title}
                    <SVG svg="arrow" />
                </div>
                {activeCollapse === 7 && (
                    <div className="about__description">
                        {hint7.content?.split("\n")?.map((str) => (
                            <div>{str}</div>
                        ))}
                    </div>
                )}

                <div
                    className={`about__item ${
                        activeCollapse === 8 && "active"
                    }`}
                    onClick={() =>
                        activeCollapse === 8
                            ? setActiveCollapse(0)
                            : setActiveCollapse(8)
                    }
                >
                    {hint8.title}
                    <SVG svg="arrow" />
                </div>
                {activeCollapse === 8 && (
                    <div className="about__description">
                        {hint8.content?.split("\n")?.map((str) => (
                            <div>{str}</div>
                        ))}
                    </div>
                )}

                <div
                    className={`about__item ${
                        activeCollapse === 9 && "active"
                    }`}
                    onClick={() =>
                        activeCollapse === 9
                            ? setActiveCollapse(0)
                            : setActiveCollapse(9)
                    }
                >
                    {hint9.title}
                    <SVG svg="arrow" />
                </div>
                {activeCollapse === 9 && (
                    <div className="about__description">
                        {hint9.content?.split("\n")?.map((str) => (
                            <div>{str}</div>
                        ))}
                    </div>
                )}

                <div
                    className={`about__item ${
                        activeCollapse === 10 && "active"
                    }`}
                    onClick={() =>
                        activeCollapse === 10
                            ? setActiveCollapse(0)
                            : setActiveCollapse(10)
                    }
                >
                    {hint10.title}
                    <SVG svg="arrow" />
                </div>
                {activeCollapse === 10 && (
                    <div className="about__description">
                        {hint10.content?.split("\n")?.map((str) => (
                            <div>{str}</div>
                        ))}
                    </div>
                )}

                <div
                    className={`about__item ${
                        activeCollapse === 11 && "active"
                    }`}
                    onClick={() =>
                        activeCollapse === 11
                            ? setActiveCollapse(0)
                            : setActiveCollapse(11)
                    }
                >
                    {hint11.title}
                    <SVG svg="arrow" />
                </div>
                {activeCollapse === 11 && (
                    <div className="about__description">
                        {hint11.content?.split("\n")?.map((str) => (
                            <div>{str}</div>
                        ))}
                    </div>
                )}

                <div
                    className={`about__item ${
                        activeCollapse === 12 && "active"
                    }`}
                    onClick={() =>
                        activeCollapse === 12
                            ? setActiveCollapse(0)
                            : setActiveCollapse(12)
                    }
                >
                    {hint12.title}
                    <SVG svg="arrow" />
                </div>
                {activeCollapse === 12 && (
                    <div className="about__description">
                        {hint12.content?.split("\n")?.map((str) => (
                            <div>{str}</div>
                        ))}
                    </div>
                )}

                <div
                    className={`about__item ${
                        activeCollapse === 13 && "active"
                    }`}
                    onClick={() =>
                        activeCollapse === 13
                            ? setActiveCollapse(0)
                            : setActiveCollapse(13)
                    }
                >
                    {hint13.title}
                    <SVG svg="arrow" />
                </div>
                {activeCollapse === 13 && (
                    <div className="about__description">
                        {hint13.content?.split("\n")?.map((str) => (
                            <div>{str}</div>
                        ))}
                    </div>
                )}

                {/*<div className={`about__item ${activeCollapse === 14 && 'active'}`}*/}
                {/*     onClick={() => activeCollapse === 14 ? setActiveCollapse(0) : setActiveCollapse(14)}>*/}
                {/*  {hint14.title}*/}
                {/*  <SVG svg="arrow"/>*/}
                {/*</div>*/}
                {/*{*/}
                {/*  activeCollapse === 14 && (*/}
                {/*    <div className="about__description">*/}
                {/*      {hint14.content?.split('\n')?.map(str => <div>{str}</div>)}*/}
                {/*    </div>*/}
                {/*  )*/}
                {/*}*/}

                <div
                    className={`about__item ${
                        activeCollapse === 15 && "active"
                    }`}
                    onClick={() =>
                        activeCollapse === 15
                            ? setActiveCollapse(0)
                            : setActiveCollapse(15)
                    }
                >
                    {hint15.title}
                    <SVG svg="arrow" />
                </div>
                {activeCollapse === 15 && (
                    <div className="about__description">
                        {hint15.content?.split("\n")?.map((str) => (
                            <div>{str}</div>
                        ))}
                    </div>
                )}

                <div
                    className={`about__item ${
                        activeCollapse === 16 && "active"
                    }`}
                    onClick={() =>
                        activeCollapse === 16
                            ? setActiveCollapse(0)
                            : setActiveCollapse(16)
                    }
                >
                    {hint16.title}
                    <SVG svg="arrow" />
                </div>
                {activeCollapse === 16 && (
                    <div className="about__description">
                        {hint16.content?.split("\n")?.map((str) => (
                            <div>{str}</div>
                        ))}
                    </div>
                )}

                <div
                    className={`about__item ${
                        activeCollapse === 17 && "active"
                    }`}
                    onClick={() =>
                        activeCollapse === 17
                            ? setActiveCollapse(0)
                            : setActiveCollapse(17)
                    }
                >
                    {hint17.title}
                    <SVG svg="arrow" />
                </div>
                {activeCollapse === 17 && (
                    <div className="about__description">
                        {hint17.content?.split("\n")?.map((str) => (
                            <div>{str}</div>
                        ))}
                    </div>
                )}
            </div>
        </Fade>
    );
};

export default Rules;
