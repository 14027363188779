import debounce from "lodash.debounce";
import React, {useCallback, useEffect, useState} from "react";
import {devIp, getCategoriesAPI, getProductsAPI} from "utils/Services/API";
import {filterShopAndVitrin, getAllStore} from "utils/Services";
import {Spinner, SVG} from "components";
import "./Search.scss";
import {Link} from "react-router-dom";
import ProductImage from "assets/images/product.png";
import Product from "pages/Product";
import {Item} from "containers/KartLandContainer/dependencies";

export const Search = ({
                         topPosition = false,
                         setFilter,
                         filter,
                         status,
                         setStatus,
                       }) => {
  const [value, setValue] = useState("");
  const [shops, setShops] = useState([]);
  const [allStore, setAllStore] = useState([]);
  const [vitrins, setVitrins] = useState([]);
  const [loading, setLoading] = useState(false);

  const getFilters = (inputValue) => {
    setLoading(true);
    filterShopAndVitrin(inputValue)
      .then((res) => {
        if (res) {
          setShops(res.data.store);
          setVitrins(res.data.vitrin);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const debounceHandler = useCallback(
    debounce((value) => {
      if (value.length > 0) {
        getFilters(value);
        document.body.style.overflowY = "hidden";
      } else {
        // setFilter(filter);
        document.body.style.overflowY = "auto";
      }
    }, 700),
    []
  );

  const handleCloseSearch = (closeAreaId) => {
    if (closeAreaId === "closeArea") {
      setValue("");
      setFilter(filter);
      document.body.style.overflowY = "auto";
    }
  };

  const fetchAllStore = () => {
    getAllStore().then((res) => {
      if (res) {
        setAllStore(res.data);
      }
    });
  };

  useEffect(() => {
    if (value.length > 0) {
      setLoading(true);
      setFilter(false);
    } else {
      setShops([]);
    }
    debounceHandler(value);
  }, [value]);

  useEffect(() => {
    status && fetchAllStore();
  }, [status]);
  console.log(shops);
  return (
    <div
      className={`map-search-section ${status && "active"}`}
      id="map-search-section"
    >
      <div className="map-search-section__wrapper">
        <div className="row a-c j-sb mb-10">
          <div className="map-search-section__categories__title">جستجو</div>
          <div onClick={() => setStatus(false)}>
            <SVG svg="times"/>
          </div>
        </div>
        <input
          type="text"
          placeholder="فروشگاه یا محصول مورد نظر خود را وارد نمایید..."
          className="input"
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
        {/*{value == "" ? (*/}
        {/*  <>*/}
        {/*    <div className="map-search-section__title">فروشگاه های منتخب</div>*/}
        {/*    <div className="map-search-section__container">*/}
        {/*      <div className="map-search-section__products">*/}
        {/*        {allStore.map((store, index) => (*/}
        {/*          <Link*/}
        {/*            className="map-search-section__product"*/}
        {/*            key={index}*/}
        {/*            to={`/seller/${store.id}`}*/}
        {/*            onClick={() => (document.body.style.overflowY = "auto")}*/}
        {/*          >*/}
        {/*            <div className="map-search-section__product__image">*/}
        {/*              <img*/}
        {/*                loading="lazy"*/}
        {/*                src={store.picture ? `${store.picture}` : ProductImage}*/}
        {/*                id={store.id}*/}
        {/*                onError={(e) => {*/}
        {/*                  const image: any = document.getElementById(*/}
        {/*                    String(index)*/}
        {/*                  );*/}
        {/*                  image.src = ProductImage;*/}
        {/*                }}*/}
        {/*              />*/}
        {/*            </div>*/}
        {/*            <div className="map-search-section__product__title">*/}
        {/*              {store.name}*/}
        {/*            </div>*/}
        {/*          </Link>*/}
        {/*        ))}*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  </>*/}
        {/*) : (*/}
        <>
          {loading ? (
            <div className="map-search-section__loading">
              <Spinner color="dark" active={loading} isFullscreen={false}/>
            </div>
          ) : shops?.length === 0 ? (
            <>
              <div className="map-search-section__title">نتایج جستجو :</div>
              <div className="map-search-section__container">
                <div className="map-search-section__title">فروشگاه ها</div>

                <div className="map-search-section__notfound">
                  کالایی با این مشخصات پیدا نشد.
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="map-search-section__title">
                نتایج جستجو برای {value}
              </div>
              <div className="map-search-section__title">فروشگاه ها</div>
              <div className="map-search-section__container">
                <div className="map-search-section__products">
                  {shops.map((product, index) => (
                    <Link
                      className="map-search-section__product"
                      key={index}
                      to={`/seller/${product.id}`}
                      onClick={() => (document.body.style.overflowY = "auto")}
                    >
                      <div className="map-search-section__product__image">
                        <img
                          loading="lazy"
                          src={
                            product.hasOwnProperty("picturestores") ?
                            product?.picturestores?.length > 0
                              ? `${product?.picturestores[0]?.photo}`
                              : ProductImage
                            : product.photo
                          }
                          id={product.id}
                          onError={(e) => {
                            const image: any = document.getElementById(
                              String(index)
                            );
                            image.src = ProductImage;
                          }}
                        />
                      </div>
                      <div className="map-search-section__product__title">
                        {product.name}
                      </div>
                    </Link>
                  ))}
                </div>
              </div>
            </>
          )}
          {!loading &&
            (vitrins.length == 0 ? (
              <>
                <div className="map-search-section__title">محصولات</div>
                <div className="map-search-section__container">
                  <div className="map-search-section__notfound">
                    کالایی با این مشخصات پیدا نشد.
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="map-search-section__title">محصولات</div>
                <div className="map-search-section__container">
                  <div className="map-search-section__products">
                    {vitrins.map((vitrin, index) => (
                      <Link
                        className="map-search-section__product"
                        key={index}
                        to={`/seller/${vitrin.id}`}
                        onClick={() =>
                          (document.body.style.overflowY = "auto")
                        }
                      >
                        <div className="map-search-section__product__image">
                          <img
                            loading="lazy"
                            src={
                              vitrin.photo
                                ? `${vitrin.photo}`
                                : ProductImage
                            }
                            id={vitrin.id}
                            onError={(e) => {
                              const image: any = document.getElementById(
                                String(index)
                              );
                              image.src = ProductImage;
                            }}
                          />
                        </div>
                        <div className="map-search-section__product__info">
                          <div className="map-search-section__product__title">
                            {vitrin.title}
                          </div>
                          {vitrin.price > 0 && <div className="map-search-section__product__price">
                            {vitrin.price} تومان
                          </div>}
                        </div>
                      </Link>
                    ))}
                  </div>
                </div>
              </>
            ))}
        </>
        {/*)}*/}
      </div>
    </div>
  );
};
