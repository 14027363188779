import { FC, useState } from "react";
import { SVG, Card, Share } from "components";
import { Circle, Line } from "rc-progress";
import Countdown from "react-countdown";
import moment from "jalali-moment";
import toast from "react-hot-toast";
import "./PanelBox.scss";
import { useUser } from "hooks";

type PanelBoxType = {
  data: any;
};

export const PanelBox: FC<PanelBoxType> = ({ data }) => {
  const [showSharing, setShowSharing] = useState(false);
  const {
    userState: { user },
  } = useUser();
  const [showPanelInfo, setShowPanelInfo] = useState(false);

  const handlePanelName = (type) => {
    switch (type) {
      case "white":
        return "جزئیات پنل سفید";
      case "gold":
        return "جزئیات پنل طلایی";
      case "silver":
        return "پنل نقره ای";
      case "bronze":
        return "پنل برنزی";
      default:
        return "پنل نامشخص";
    }
  };

  const handleTime = () => {
    if (data.ExpireTime) {
      const splited = data.ExpireTime.split(" ");
      const converted = moment(splited[0], "YYYY-MM-DD").format("YYYY-MM-DDT");
      const convertedWithTime = converted + splited[1];
      return convertedWithTime;
    }
  };

  const handlePercent = () => {
    if (data.ExpireTime) {
      const splited = data.ExpireTime.split(" ");
      const converted = moment(splited[0], "YYYY-MM-DD").format("YYYY-MM-DDT");
      const convertedWithTime = converted + splited[1];
      // percent
      const start = new Date(data.created_at).getTime();
      const end = new Date(convertedWithTime).getTime();

      const current = Date.now();
      const completed = ((end - current) * 100) / (end - start);
      return completed;
    }
  };

  const copy = () => {
    const value: any = document.getElementById("panel_code");
    value.select();
    document.execCommand("copy");

    toast.success("کد کارتک شما کپی شد!");
  };

  return (
    <div className={`panel__box ${data.panel}`}>
      <Share
        withBtnNav
        active={showSharing}
        setClose={(close) => setShowSharing(close)}
        title="ارسال کد پنل"
        value={`با کد پنل نمایندگی من اقدام به ثبت محتوا یا اخذ نمایندگی در کارتک نمایید
        \n کد پنل نمایندگی من : ${data.code}
        \n لینک ورود به کارتک :
        \n https://app.kartaak.com
        \n وبسایت کارتک : 
        `}
        link={`https://kartaak.com`}
      />
      <div
        className={`panel__box__header ${data.panel} ${showPanelInfo && "active"
          }`}
        onClick={() => setShowPanelInfo((prev) => !prev)}
      >
        <span>{handlePanelName(data.panel)}</span>
        <SVG svg="arrow" />
      </div>

      {showPanelInfo && (
        <div className="panel__box__body">
          <div className="panel__box__info">
            <div className="row j-sb a-c mb-8">
              <div className="row a-c">
                <div className="panel__box__info__title">کد پنل نمایندگی:</div>
                <input
                  readOnly
                  type="text"
                  value={user.detail?.inviteCode}
                  id="panel_code"
                  className="panel__box__input"
                />
              </div>

              <div className="row a-c g-6">
                <div className="panel__box__info__icon" onClick={() => copy()}>
                  <SVG svg="copy" />
                </div>
                <div
                  className="panel__box__info__icon shared"
                  onClick={() => setShowSharing(true)}
                >
                  <SVG svg="share" />
                </div>
              </div>
            </div>
            <div className="panel__box__info__description">
              می توانید از کد پنل یا شماره موبایل برای ارائه به مشتریان استفاده
              کنید
            </div>
          </div>

          {/* {data.panel === "gold" && (
            <div className="panel__box__time">
              <div className="panel__box__time__icon">
                <SVG svg="panel-duration" />
              </div>
              <div className="row a-c">
                <Circle
                  className="panel__box__time__progress"
                  percent={(Number(data.timeline) * 100) / (18 * 30)}
                  strokeWidth={9}
                  strokeColor="#eb4d4c"
                  trailWidth={9}
                  trailColor="#b3bdcc"
                />
                <div>{data.timeline + " روز"}</div>
              </div>
            </div>
          )} */}

          <div className="panel__box__item">
            <div className="row a-c">
              <div className="panel__box__item__icon">
                <SVG svg="kartella" />
              </div>
              <div className="panel__box__item__title">
                پاداش جذب محتوای کارتلا
              </div>
            </div>
            <div className="panel__box__item__value">{data.collaborationReward + "%"}</div>
          </div>

          <div className="panel__box__item">
            <div className="row a-c">
              <div className="panel__box__item__icon">
                <SVG svg="city" />
              </div>
              <div className="panel__box__item__title">
                پاداش فروش واحدهای کارتلند
              </div>
            </div>
            <div className="panel__box__item__value">{data.collaborationReward + "%"}</div>
          </div>

          {/* <div className="panel__box__item">
            <div className="row a-c">
              <div className="panel__box__item__icon">
                <SVG svg="kartland" />
              </div>
              <div className="panel__box__item__title">
                پاداش جذب مشارکت کارتلند
              </div>
            </div>
            <div className="panel__box__item__value">
              {data.panel === 'white' ?
                (
                  '2%'
                ) : (
                  '5%'
                )}
            </div>
          </div> */}

          {/* <div className="panel__box__item">
            <div className="row a-c">
              <div className="panel__box__item__icon">
                <SVG svg="wallet-km" />
              </div>
              <div className="panel__box__item__title">پاداش فروش بسته 100تایی KM</div>
            </div>
            <div className="panel__box__item__value">
              {data.panel === 'white' ?
                (
                  '5000 تومان'
                ) : (
                  '12500 تومان'
                )}
            </div>
          </div> */}

          {/* {data.panel === 'gold' && (
            <div className="panel__box__item">
              <div className="row a-c">
                <div className="panel__box__item__icon">
                  <SVG svg="friends" />
                </div>
                <div className="panel__box__item__title">
                  پاداش فروش پنل طلایی
                </div>
              </div>
              <div className="panel__box__item__value">{data.collaborationReward + "%"}</div>
            </div>
          )} */}
        </div>
      )}
    </div>
  );
};
