import React, { useContext, useEffect, useState, useRef } from "react";
import { Spinner, Fade, SVG, CheckBox, Dots } from "components";
import KartakLogo from "../../assets/images/kartak-logo.svg";
import IranImage from "../../assets/images/iran.png";
import { Link, useHistory } from "react-router-dom";
import { check_otp, getCartAPI, login } from "utils/Services/API";
import toast from "react-hot-toast";
import { useCart, useAuth, useUser } from "hooks";
import Countdown from "react-countdown";
import {
    farsiDigitToEnglish,
    handleInputBlur,
    handleInputFocus,
} from "utils/Functions";
import { loginAdmin, verifyAdminOtp } from "utils/Services";
import "./Login.scss";

export const Login = ({ closeModal }) => {
    const {
        authState: { isLogged },
        onSetLoginStatus,
    } = useAuth();
    const {
        getUserDataFromApi,
        getUserProfile,
        userState: { user },
    } = useUser();
    const { setCartData, getCartDataFromApi } = useCart();
    const passwordRef = useRef(null);
    const [loginState, setLoginState] = useState(1);
    const [userPhoneNumber, setUserPhoneNumber] = useState("09");
    const [userOTPCode, setUserOTPCode] = useState("");
    const [isNewUser, setIsNewUser] = useState(false);
    const [showPresenter, setShowPresenter] = useState(false);
    const [loading, setLoading] = useState(false);
    const [focusInput, setFocusInput] = useState(false);
    const [focusInputPassword, setFocusInputPassword] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const history = useHistory();

    const getCart = (user_token) => {
        const APIResult = getCartAPI(user_token);
        APIResult.then((response) => {
            if (response.status === 200) {
                setCartData(response.data.data.cart);
            }
        });
    };

    const handleEnterPhoneNumber = (phoneNumber) => {
        if (phoneNumber.length >= 12) return;
        else setUserPhoneNumber(phoneNumber);
    };

    const handleEnterOTPCode = (otp) => {
        setUserOTPCode(otp.toString());
        if (otp.length === 5) {
            handleSubmitOTPCode(otp);
        }
    };

    const handleOtpTime = (currCount) => {
        switch (true) {
            case currCount <= 3:
                return 60;

            case currCount === 4:
                return 300;

            case currCount === 5:
                return 1800;

            case currCount >= 6:
                return 86400;

            default:
                break;
        }
    };

    const handleRetryTime = (time) => {
        const d = Number(time);
        const h = Math.floor(d / 3600);
        const m = Math.floor((d % 3600) / 60);
        const s = Math.floor((d % 3600) % 60);
        return `${h}:${m}:${s}`;
    };

    const handleSubmitPhoneNumber = () => {
        if (user?.mobile_number === userPhoneNumber) {
            toast.error("شما در حال حاضر حساب کاربری دارید.");
            return;
        }
        if (userPhoneNumber.length < 11) {
            toast.error("لطفا شماره‌ موبایل خود را به درستی وارد نمایید.");
        } else {
            setLoading(true);
            const serverResponse = login(userPhoneNumber);

            serverResponse.then((response) => {
                if (response.data.status < 500 && response.data.status >= 400) {
                    if (response.data.status == 401) {
                        toast.error("امکان ورود شما از این درگاه وجود ندارد.");
                        setLoading(false);
                        return;
                    } else if (response.data.status == 400) {
                        setLoginState(loginState + 1);
                        setLoading(false);
                        setIsAdmin(true);
                        return;
                    } else if (response.data.status === 403) {
                        if (response.data.msg === "suspend") {
                            toast.error(
                                "دسترسی شما به حساب کارتک مسدود می‌باشد. لطفا از طریق سایت کارتک با پشتیبانی در تماس باشید."
                            );
                            setLoading(false);
                            return;
                        }
                    }
                    const count = response.data.data.count;
                    const countTime = handleOtpTime(count);
                    const lastOtpTime =
                        Date.parse(response.data.data.time) / 1000;
                    const currTime = Math.round(new Date().getTime() / 1000);
                    const retryTime = handleRetryTime(
                        countTime + lastOtpTime - currTime
                    );
                    toast(`برای ارسال مجدد کد، ${retryTime} دیگر تلاش کنید.`);
                    setLoading(false);
                    return;
                } else {
                    setIsNewUser(response.data.data.is_new);
                    setLoginState(loginState + 2);
                    toast.success("کد ورود به شماره شما ارسال شد.");
                    setLoading(false);
                }
                handleInputBlur();
            });
        }
    };

    const handleSubmitPassword = () => {
        setLoading(true);
        const userPassword = passwordRef.current.value;
        loginAdmin(userPhoneNumber, userPassword)
            .then((res) => {
                if (res) {
                    setIsNewUser(res.data.is_new);
                    setLoginState(loginState + 1);
                    toast.success("کد ورود به شماره شما ارسال شد.");
                    setLoading(false);
                } else return;
            })
            .catch((error) => {
                const response = error.response;
                if (response.status < 500 && response.status >= 400) {
                    if (response.status == 401) {
                        toast.error("رمز عبور وارد شده صحیح نمی باشد");
                        setLoading(false);
                        handleBackOnPassword();
                        return;
                    }
                    const count = response.data.data.count;
                    const countTime = handleOtpTime(count);
                    const lastOtpTime =
                        Date.parse(response.data.data.time) / 1000;
                    const currTime = Math.round(new Date().getTime() / 1000);
                    const retryTime = handleRetryTime(
                        countTime + lastOtpTime - currTime
                    );
                    setLoginState(1);
                    toast(`برای ارسال مجدد کد، ${retryTime} دیگر تلاش کنید.`);
                    setLoading(false);
                    return;
                }
            });
    };

    const handleSubmitOTPCode = (otp) => {
        if (otp.length < 5) {
            toast("کد وارد شده را تماما وارد نمایید");
        } else {
            setLoading(true);
            if (isAdmin) {
                verifyAdminOtp(userPhoneNumber, otp).then((res) => {
                    if (res) {
                        getCart(res.data.token);

                        const firstUser = localStorage.getItem("user_token");
                        localStorage.setItem("user_token", res.data.token);
                        localStorage.setItem("user_token_second", firstUser);
                        getUserDataFromApi();
                        getUserProfile();
                        getCartDataFromApi();
                        closeModal();

                        setLoading(false);
                        toast.success("تغییر حساب کاربری با موفقیت انجام شد.");
                        if (isNewUser) {
                            setShowPresenter(true);
                        } else {
                            const newUser = [
                                {
                                    name: user?.detail?.fullName,
                                    phone: user?.mobile_number,
                                },
                            ];
                            localStorage.setItem(
                                "user_second_account",
                                JSON.stringify(newUser)
                            );

                            onSetLoginStatus(true);
                            history.push("/profile");
                        }
                        setIsAdmin(false);
                    } else return;
                });
            } else {
                const serverResponse = check_otp(userPhoneNumber, otp);
                serverResponse.then((response) => {
                    if (response.data.status === 400) {
                        toast.error("کد وارد شده صحیح نیست.");
                        setLoading(false);
                    } else if (response.data.status === 200) {
                        getCart(response.data.data.token);

                        const firstUser = localStorage.getItem("user_token");
                        localStorage.setItem(
                            "user_token",
                            response.data.data.token
                        );
                        localStorage.setItem("user_token_second", firstUser);
                        getUserDataFromApi();
                        getUserProfile();
                        getCartDataFromApi();
                        closeModal(false);

                        setLoading(false);
                        toast.success("تغییر حساب کاربری با موفقیت انجام شد.");
                        // if (isNewUser) {
                        //   setShowPresenter(true);
                        // } else {

                        const newUser = [
                            {
                                name: user?.detail?.fullName,
                                phone: user?.mobile_number,
                            },
                        ];
                        localStorage.setItem(
                            "user_second_account",
                            JSON.stringify(newUser)
                        );

                        onSetLoginStatus(true);
                        history.push("/profile");
                        // }
                    }
                });
            }
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (isAdmin) {
            handleSubmitPassword();
        } else {
            handleSubmitPhoneNumber();
        }
    };

    const handleBackOnPassword = () => {
        setLoginState(1);
        setFocusInput(false);
    };

    const getPhoneNumber = (
        <div>
            <div className="title">شماره موبایل</div>
            <div className={`login__form__input ${focusInput && "focus"}`}>
                <form
                    onSubmit={handleSubmit}
                    style={{ width: "100%", height: "100%" }}
                >
                    <input
                        dir="auto"
                        type="tel"
                        placeholder="125550012"
                        value={`09${userPhoneNumber.substr(2)}`}
                        onChange={(e) =>
                            handleEnterPhoneNumber(
                                farsiDigitToEnglish(e.target.value)
                            )
                        }
                        onClick={() => window.scrollTo(0, 300)}
                        onFocus={() => {
                            handleInputFocus();
                            setFocusInput(true);
                        }}
                        onBlur={() => {
                            handleInputBlur();
                            setFocusInput(false);
                        }}
                        autoComplete="off"
                    />
                </form>

                <img src={IranImage} alt="" />
            </div>

            <div
                className={`login__form__btn ${"active"}`}
                onClick={() => !loading && handleSubmitPhoneNumber()}
            >
                <span className={`login__form__btn__title `}>
                    {loading ? <Dots color="light" /> : "افزودن حساب کاربری"}
                </span>
                <div className="login__form__btn__login-icon">
                    <SVG svg="login" />
                </div>
            </div>
        </div>
    );

    const getPassword = (
        <div>
            <div className="title">رمز عبور</div>
            <div
                className={`login__form__input password ${
                    focusInputPassword && "focus"
                }`}
            >
                <form
                    onSubmit={handleSubmit}
                    style={{ width: "100%", height: "100%" }}
                >
                    <input
                        dir="auto"
                        ref={passwordRef}
                        placeholder=""
                        type="password"
                        onChange={(e) =>
                            (passwordRef.current.value = farsiDigitToEnglish(
                                e.target.value
                            ))
                        }
                        // onClick={() => window.scrollTo(0, 300)}
                        onFocus={() => {
                            handleInputFocus();
                            setFocusInputPassword(true);
                        }}
                        onBlur={() => {
                            handleInputBlur();
                            setFocusInputPassword(false);
                        }}
                        autoComplete="off"
                    />
                </form>
            </div>
            <div className="login__form__btn-wrapper">
                <div
                    className={`login__form__btn active`}
                    onClick={() => !loading && handleSubmitPassword()}
                >
                    <span className={`login__form__btn__title `}>
                        {loading ? <Dots color="light" /> : "ورود"}
                    </span>
                    <div className="login__form__btn__login-icon">
                        <SVG svg="login" />
                    </div>
                </div>
            </div>
        </div>
    );

    const getOTPCode = (
        <div>
            <div className="title">کد 5 رقمی ارسال شده:</div>
            <div className="login__form__otp">
                <input
                    value={userOTPCode}
                    type="tel"
                    onChange={(e) =>
                        handleEnterOTPCode(farsiDigitToEnglish(e.target.value))
                    }
                    maxLength={5}
                    autoComplete="off"
                />
            </div>
        </div>
    );

    return (
        <div className="login-modal">
            {loginState === 1 && getPhoneNumber}
            {loginState === 2 && getPassword}
            {loginState === 3 && getOTPCode}
        </div>
    );
};
