import React, {useEffect, useRef, useState} from "react";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import "mapbox-gl/dist/mapbox-gl.css";
import {SVG, Modal, Dots} from "components";
import {useHistory, useLocation} from "react-router-dom";
import {submitLocation} from "utils/Services";
import {getUserStoreAPI} from "utils";
import toast from "react-hot-toast";

mapboxgl.accessToken =
  "pk.eyJ1Ijoia2FydGFhayIsImEiOiJjbDNsaGw4NDIwY3R1M2VwNXAxZXNhemltIn0.oG3Q1VAF7wcyaUUvSCKxVw";

export const SubmitShopLocation = () => {
  // useEffect(() => {
  //   navigator.geolocation.getCurrentPosition(
  //     (success) => console.log(success),
  //     (e) => {
  //       setCurrLat(e.coords.latitude);
  //       setCurrLng(e.coords.longitude);
  //       map.current.setZoom(17);
  //     }
  //   );
  // }, []);
  const history = useHistory();
  const location = useLocation();
  const mapContainer = useRef(null);
  const addressRef = useRef(null);
  const map = useRef(null);
  const [lng, setLng] = useState(51.657354);
  const [lat, setLat] = useState(32.674155);
  const [zoom, setZoom] = useState(17);
  const [modal, setModal] = useState(true);
  const [current, setCurrent] = useState({});
  const [loading, setLoading] = useState(false);

  const geolocate = new mapboxgl.GeolocateControl({
    positionOptions: {
      enableHighAccuracy: true,
    },
    setZoom: 17,
    fitBoundsOptions: {maxZoom: 17},
    trackUserLocation: false,
    showUserHeading: true,
  });

  const goToUserLocation = () => {
    const el = document.getElementsByClassName("mapboxgl-ctrl-geolocate");
    el && el[0].click();
  };

  const fetchLocation = () => {
    getUserStoreAPI()
      .then((res) => {
        if (res && res.data.length > 0) {
          addressRef.current.value = res.data[0].address;
          map.current.flyTo({
            center: [
              parseFloat(res.data[0].long),
              parseFloat(res.data[0].lat) + 0.0006,
            ],
            zoom: 17,
            speed: 4,
          });
        }
      })
      .catch((err) => {
        if (err) {
        }
      })
      .finally();
  };

  const handleSubmit = () => {
    const addressValue = addressRef.current.value;
    if (addressValue == "") {
      toast.error("آدرس متنی را وارد نمایید");
      return;
    }
    setLoading(true);
    submitLocation(current.lat, current.lng, addressValue)
      .then((res) => {
        if (res) {
          toast.success("آدرس با موفقیت ثبت شد");
          setModal(false);
          setTimeout(() => {
            history.replace("/myshop");
          }, 300);
        }
      })
      .catch((err) => {
        if (err) toast.error("خطا در ثبت آدرس!");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (map.current) return; // initialize map only once

    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/kartaak/cl37c9d8c001y14la74hqn2ze",
      center: [lng, lat],
      zoom: zoom,
      minZoom: 4.5,
      maxZoom: 17.5,
      localFontFamily: "VazirFaNum",
      localIdeographFontFamily: "VazirFaNum",
      logoPosition: "top-left",
    });
    map.current.addControl(geolocate);

    map.current.setMaxBounds([
      [44.032434, 25.057032],
      [63.33335, 39.78205],
    ]);

    map.current.on("load", function () {
      map.current.addLayer(
        {
          id: "country-boundaries",
          source: {
            type: "vector",
            url: "mapbox://mapbox.country-boundaries-v1",
          },
          "source-layer": "country_boundaries",
          type: "fill",
          paint: {
            "fill-color": "#efe9e1",
            "fill-opacity": 0.9,
          },
        },
        "country-label"
      );

      map.current.setFilter("country-boundaries", [
        "in",
        "iso_3166_1_alpha_3",
        "PAK",
        "IRQ",
        "AFG",
        "KWT",
        "TKM",
        "QAT",
        "TUR",
        "SAU",
        "AZE",
        "ARE",
        "ARM",
      ]);
    });

    setCurrent(map.current.getCenter());

    map.current.on("moveend", () => {
      setCurrent(map.current.getCenter());
    });
  });

  useEffect(() => {
    if (
      document.getElementsByClassName("mapboxgl-ctrl-geolocate")[0] !==
      undefined
    )
      goToUserLocation();
  }, [document.getElementsByClassName("mapboxgl-ctrl-geolocate")[0]]);

  useEffect(() => {
    fetchLocation();
    history.replace("/myshop/location" + location.search);
  }, []);

  return (
    <div
      style={{
        width: "100%",
        height: window.innerHeight,
        overflow: "hidden",
      }}
    >
      <div className="map__top-right"/>

      <div
        className="map__geolocate top-right"
        onClick={() => history.goBack()}
      >
        <SVG svg="map-forward"/>
      </div>
      <div
        className="map__geolocate under-top-right"
        onClick={() => goToUserLocation()}
      >
        <SVG svg="geolocate"/>
      </div>
      <div
        ref={mapContainer}
        style={{
          height: "100%",
        }}
      />
      <div
        className="map"
        style={{
          height: "64px",
          width: "۶۰px",
          position: "fixed",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div style={{marginBottom: 24}}>
          <SVG svg="add-location-marker"/>
        </div>
      </div>
      <div
        className={`button green fixed left-0 right-0 bottom-16 z-index-2 width-full-16 ${
          modal && "exitAnimation"
        }`}
        onClick={() => handleSubmit()}
      >
        ثبت موقعیت جغرافیایی
      </div>
      <Modal active={modal} close={() => history.goBack()}>
        <div className="mt-8">
          <span className="">
            آدرس متنی{" "}
            <small className="creation-location__title__slug">
              ( برای نمایش به کاربر )
            </small>
          </span>
        </div>
        <textarea
          placeholder="آدرس محل فروشگاه را وارد نمایید"
          ref={addressRef}
          className=" mt-10 input withoutHeight"
          name="text"
          rows={10}
          onChange={(e) => (addressRef.current.value = e.target.value)}
        ></textarea>
        <div
          className="button"
          onClick={() => {
            if (addressRef.current.value.length === 0) {
              toast.error("آدرس فروشگاه را وارد نمایید");
              return;
            }
            setModal(false);
          }}
        >
          {loading ? <Dots color="light"/> : "ثبت آدرس"}
        </div>
      </Modal>
      {/* <div
        className="map"
        style={{
          borderRadius: "50%",
          backgroundColor: "red",
          position: "fixed",
        }}
      /> */}
    </div>
  );
};
