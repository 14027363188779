export const commandItems = [
  {
    title: 'دستورات',
    slug: '/help'
  },
  {
    title: 'درباره‌ی کارتک',
    slug: '/about'
  },
  {
    title: 'راه‌های ارتباطی',
    slug: '/contact'
  },
  {
    title: 'کد کارتک من',
    slug: '/invite'
  },
  {
    title: 'ساز و کاز اپلیکیشن',
    slug: '/how'
  },
  {
    title: 'کیف KV',
    slug: '/kv'
  },
]