import { SVG } from "components";

export const NotFound = () => {
  return (
    <div className="kartland__not-found">
      <div className="kartland__not-found__logo">
        <SVG svg="kartland" />
      </div>
      <span className="kartland__not-found__title">واحدی وجود ندارد</span>
      <span className="kartland__not-found__description">
        در حال حاضر شما واحدی خریداری نکرده اید
      </span>
    </div>
  );
};
