import React from "react";
import { WrapperCard, SVG, Lowprice } from "components";
import "./ProductItem.scss";
import { useCart, useUser } from "hooks";
import { transformPrice } from "../../utils/Functions";
import { Link } from "react-router-dom";
import ProductImage from "../../assets/images/product.png";

export const ProductItem = ({ product }) => {
    const {
        getCartDataFromApi,
        cartState: { items },
        setCartData,
    } = useCart();

    const {
        userState: { user },
    } = useUser();

    // const toggleProductInCart = ()=>{
    //   if(items.find((item)=>Number(item.product_id) === product.id)){
    //     setCartItems(items.filter((item)=>Number(item.product_id) !== product.id))
    //   }else{
    //     setCartItems(items.push(product));
    //   }
    // }

    const checkIfDefaultAndDefaulDiscountAreEqual = () => {
        if (
            product.defaultPrice[0] &&
            product.defaultPrice[1] &&
            product.defaultPrice[1] === product.defaultPrice[0]
        ) {
            return true;
        } else {
            return false;
        }
    };

    return (
        <WrapperCard vitrin={true}>
            <Link
                style={{
                    width: "18rem",
                    display: "flex",
                    justifyContent: "space-around",
                }}
                className="product-item"
                to={`/product/${product.id}`}
            >
                <div
                    className="row"
                    style={{
                        display: "flex",
                        gap: "1rem",
                        alignItems: "center",
                        padding: "0.2rem 0.2rem",
                    }}
                >
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <div
                            className="product-item__image"
                            style={{ width: "120px", height: "120px" }}
                        >
                            {/* {!user?.isCitizen && (
                            <div
                                style={{
                                    position: "absolute",
                                    right: "0",
                                    top: "0",
                                    padding: "0.5rem",
                                }}
                            >
                                <SVG svg="discount" />
                            </div>
                        )} */}

                            {/* <span style={{position: "absolute", backgroundColor: "#21bbe1", color: "white", padding: "1px 5px", borderRadius: "15px", fontSize: "12px", top: "-5px"}}>{product?.seller}</span> */}
                            <img
                                loading="lazy"
                                src={
                                    product.image && product.image[0]
                                        ? `${product.image[0]}`
                                        : ProductImage
                                }
                                // src={product.image && product.image[0] ? `data:image/png;base64,${product.image[0]}` : ProductImage}
                                alt=""
                            />
                        </div>
                        <div
                            style={{
                                padding: "5px 10px 1px 1px",
                                fontSize: "10px",
                                color: "#195190",
                            }}
                        >
                            {product.seller}
                        </div>
                    </div>

                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                        }}
                        className="product-item__title"
                    >
                        <div
                            style={{
                                fontSize: "0.7rem",
                                fontWeight: 500,
                                marginBottom: "0.8rem",
                                width: "9rem",
                            }}
                        >
                            {product.title}
                        </div>

                        <div>
                            <div className="product-item__buy">
                                <div className="product-item__buy discount">
                                    <div className="product-item__buy__price">
                                        <Lowprice />
                                        <div className="product-item__buy__price__discount">
                                            {transformPrice(
                                                Number(product.defaultPrice[0])
                                            )}
                                            <div className="product-item__buy__price__discount__toman">
                                                تومان
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div
                                style={{ margin: "1rem 0rem" }}
                                className="row a-c"
                            >
                                <div className="product-item__buy__price j-sb">
                                    <div className="product-item__buy__price j-sb">
                                        <div className="product-item__buy__price__discount a-c">
                                            {user?.isCitizen === 1 &&
                                                product.defaultPrice[5] &&
                                                transformPrice(
                                                    Number(
                                                        product.defaultPrice[5]
                                                    )
                                                )}
                                            {user?.isCaptain === 1 &&
                                                user?.isCitizen === 0 &&
                                                transformPrice(
                                                    Number(
                                                        product.defaultPrice[4]
                                                    )
                                                )}
                                            {user?.detail?.star === 7 &&
                                                user?.isCaptain === 0 &&
                                                user?.isCitizen === 0 &&
                                                transformPrice(
                                                    Number(
                                                        product.defaultPrice[2]
                                                    )
                                                )}
                                            {user?.detail?.star !== 7 &&
                                                user?.isCaptain === 0 &&
                                                user?.isCitizen === 0 &&
                                                transformPrice(
                                                    Number(
                                                        product.defaultPrice[3]
                                                    )
                                                )}
                                        </div>
                                        <div className="product-item__buy__title">
                                            تومان
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                    }}
                    className="product-item__shop-name"
                >
                    <span style={{ marginTop: "1rem" }}>{product?.seller}</span>
                </div> */}

                {user?.isCitizen !== 1 && (
                    <div
                        className="product-item__buy__more"
                        style={{ textAlign: "center" }}
                    >
                        ارزان‌تر بخرید
                        <SVG svg="arrow" />
                    </div>
                )}
            </Link>
        </WrapperCard>
    );
};
