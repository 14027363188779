import React, {useEffect, useState} from "react";
import { Card, Fade, Modal, PageTopbar, ProductItem, Share, Spinner, SVG } from "components";
import { useHistory, useParams } from "react-router-dom";
import { storeDetailsAPI } from "utils";
import toast from "react-hot-toast";

export const OnlineStoreContainer = () => {

  const params: any = useParams();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [showShareModal, setShowShareModal] = useState(false);
  const [storeData, setStoreData] = useState<any>({});
  const [modalStatus, setModalStatus] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalData, setModalData] = useState("");
  
  const getStoreDetails = (storeId: number = params.id) => {
    storeDetailsAPI(storeId).then((response) => {
      if(response.status === 200) {
        setStoreData(response.data);
      }
    }).catch((error) => {
      toast.error("خطایی هنگام بارگزاری اطلاعات فروشگاه رخ داد");
      history.push("/market");
    }).finally(() => {
      setLoading(false);
    })
  }

  useEffect(() => {
    getStoreDetails(params.id);
  }, [])

  return (
    <Fade>
      <PageTopbar title="فروشگاه اینترنتی" />
      <Card>
        <Spinner active={loading} color="dark" />
        <div className="onlineStore">
          <div className="onlineStore__header">
            <p className="onlineStore__header__title">{storeData?.name}</p>
            <div
              className="onlineStore__header__share"
              onClick={() => setShowShareModal(true)}>
              <SVG svg="seller-share"/>
            </div>
          </div>
          <hr />
          <div className="onlineStore__storeBtn">
            <div>
              <button className="button " onClick={() => {
                setModalData(storeData?.description);
                setModalTitle("توضیحات فروشگاه : ");
                setModalStatus(true);
              }}>توضیحات</button>
              <button className="button" onClick={() => {
                setModalData(storeData?.address);
                setModalTitle("آدرس فروشگاه : ");
                setModalStatus(true);
              }}>آدرس</button>
            </div>
            <div>
              <button className="button" onClick={() => {
                setModalData(storeData?.userInfoStore?.name);
                setModalTitle("صاحب فروشگاه : ");
                setModalStatus(true);
              }}>صاحب</button>
              <button className="button" onClick={() => {
                setModalData(`
                  کشور : ${storeData?.countryName} <br>
                  استان : ${storeData?.stateName} <br>
                  شهر : ${storeData?.cityName} <br>
                  شهرستان : ${storeData?.subCityName} <br>
                  محله : ${storeData?.districtName} <br>
                `);
                setModalTitle("موقعیت مکانی : ");
                setModalStatus(true);
              }}>موقعیت</button>
            </div>
          </div>
          <hr />
          <p>ویترین فروشگاه</p>
          <div className="onlineStore__vitrin">
            <div>
              {
                storeData?.vitrin?.length > 0 ? 
                <div className="onlineStore__vitrin__products">
                  {
                    storeData.vitrin.map((product) => (
                      <ProductItem product={product}/>
                    ))
                  }
                </div> : <p>محصولی یافت نشد</p>
              }
            </div>
          </div>
        </div>

        <Modal active={modalStatus} close={() => {
          setModalStatus(false);
        }}>
          <p className="onlineStore__desc">
            <span>{modalTitle}</span>
            <hr />
            <span>{modalData.split("<br>").map(item => (
              <p>{item}</p>
            ))}</span>
          </p>
        </Modal>

        <Share
          active={showShareModal}
          setClose={() => setShowShareModal(false)}
          title={"اشتراک گذاری فروشگاه"}
          value={"از این فروشگاه بازدید کنید"}
          link={`https://app.kartaak.com/onlineStore/${params.id}`}
        />

      </Card>
    </Fade>
  );
}