import {Request} from "../api-call";

export const buyGoldPanelAPI = async (priceType, witheKm, inviteCode) => {
  const {data} = await Request.post(
    `api/v3/collaboration/activation/gold?priceType=${priceType}&witheKm=${witheKm > 0 ? witheKm : ''}&${
      inviteCode !== undefined && inviteCode?.length !== 0
        ? `inviterCode=${inviteCode}`
        : ""
    }`
  );
  return data;
};
