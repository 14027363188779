import { Card, Fade, PageTopbar, SVG, Toggle } from "components";
import { SwitchUser } from "components/SwitchUser";
import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useAuth, useCart, useUser } from "../../hooks";
import { setSoundAPI, setShowWalletAPI } from "../../utils";
import { toast } from "react-hot-toast";


export const SettingContainer = () => {
  const { userState: { user }, setUserData, getUserProfile, getUserDataFromApi } = useUser();
  const [showSwitchUser, setShowSwitchUser] = useState(false);
  const [userIsSound, setUserIsSound] = useState(user?.detail?.isSound);
  const [userIsShowWallet, setUserIsShowWallet] = useState(user?.detail?.isShowWallet);
  const history = useHistory();
  const { setCartData, getCartDataFromApi } = useCart();
  const { onSetLoginStatus } = useAuth();

  const settingItems = [
    {
      title: "صداها",
      icon: "setting-sounds",
      type: "toggle",
      value: userIsSound,
      onChange: () => changeSounds(),
    },
    // {
    //   title: "نمایش امتیاز کیف‌ها",
    //   icon: "setting-wallets",
    //   type: "toggle",
    //   value: userIsShowWallet,
    //   onChange: () => changeShowWallet(),
    // },
  ];

  const changeSounds = () => {
    setUserIsSound(!userIsSound);
    setSoundAPI().catch(err => {
      toast('درخواست با خطا مواجه شد');
    }).finally(() => {
      getUserDataFromApi();
    }
    )
  };

  const changeShowWallet = () => {
    setUserIsShowWallet(!userIsShowWallet);
    setShowWalletAPI().catch(err => {
      toast('درخواست با خطا مواجه شد');
    })
      .finally(() => {
        getUserDataFromApi();
      }
      )
  };

  const handleLogout = () => {
    localStorage.removeItem("user_token");
    localStorage.removeItem("kartella_hint");
    localStorage.removeItem("support_color");

    const secondUser = localStorage.getItem("user_token_second");
    if (secondUser !== null) {
      localStorage.setItem("user_token", secondUser);
      localStorage.removeItem('user_token_second');
      localStorage.removeItem('user_second_account');
      getUserDataFromApi();
      getUserProfile();
      getCartDataFromApi();
      history.push('/profile');
      toast.success('تغییر حساب کاربری با موفقیت انجام شد.');
      return;
    }
    onSetLoginStatus(false);

    setCartData({});
    setUserData({});
    history.push("/login");
  };

  // useEffect(() => {
  //   changeSounds();
  //   changeShowWallet()
  // }, []);

  useEffect(() => {
    history.replace("/setting");
  }, []);

  return (
    <Fade>
      <SwitchUser
        active={showSwitchUser}
        onClose={() => setShowSwitchUser(false)}
      />

      <PageTopbar title="تنظیمات" />
      <div className="setting">
        <Card>
          {settingItems.map(item => (
            <div className='setting__item'>
              <div className={'setting__item__title'}>
                <div className="setting__item__icon">
                  <SVG svg={item.icon} />
                </div>
                {item.title}
              </div>
              <Toggle value={item.value} onChange={item.onChange} />
            </div>
          ))}

          <div onClick={() => setShowSwitchUser(true)} className="mb-12 profile__items__item profile__items__item__switch">
            تغییر حساب کاربری
            <SVG svg="create-user" />
          </div>

          <div onClick={() => handleLogout()} className="profile__items__item profile__items__item__logout">
            خروج از حساب کاربری
            <SVG svg="login" />
          </div>
          {/*<Link to={'/encryption'}>*/}
          {/*  <div className='setting__item option'>*/}

          {/*    <div className={'setting__item__title'}>*/}
          {/*      <div className="setting__item__icon">*/}
          {/*        <SVG svg='setting-encryption'/>*/}
          {/*      </div>*/}
          {/*      رمزگذاری*/}
          {/*    </div>*/}
          {/*    <SVG svg="arrow"/>*/}
          {/*  </div>*/}
          {/*</Link>*/}

          {/* <div
            className="setting__item user"
            onClick={() => setShowSwitchUser(true)}
          >
            <div className="row a-c">
              <div className="setting__item__image">
                <SVG svg="create-user" />
              </div>
              افزودن یا تغییر حساب کاربری
            </div>
            <SVG svg="arrow" />
          </div> */}
        </Card>
      </div>
    </Fade>
  );
};
