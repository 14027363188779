import {Request} from "../api-call";

export const getProductsListAPI = async (isAttached) => {
  const {data} = await Request.get(`/api/v4/kartland/products/with-or-without-unit?is_attached=${isAttached}`);
  return data;
};

export const getProfitPercentageAPI = async () => {
  const {data, status} = await Request.get(`/api/v4/pricing/product-price-percents`)
  return {data: data , status : status}
}