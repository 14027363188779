import { useHistory } from "react-router-dom";
import { SVG } from "components";

export const TopBar = ({ favoriteStatus, toggleFavorite, toggleShare }) => {
    const history = useHistory();
    return (
        <div className="product__topbar">
            <div
                className="product__topbar__icon arrow"
                onClick={() => {
                    history.goBack();
                }}
            >
                <SVG svg="arrow" />
            </div>
            <div className="product__topbar__tools">
                <div
                    className={`product__topbar__icon ${
                        favoriteStatus && "active"
                    }`}
                    onClick={() => toggleFavorite()}
                >
                    <SVG svg={"profile-favorites"} />
                </div>

                {/* <div
            className="product__topbar__icon"
            onClick={() => setShowCompare(true)}
            >
            <SVG svg="comparison" />
          </div> */}
                <div
                    className="product__topbar__icon share-icon"
                    onClick={() => toggleShare(true)}
                >
                    <SVG svg="share" />
                </div>

                {/* <div className={`product__topbar__icon`} onClick={() => history.push('/support')}>
          <SVG svg={"support"} />
        </div> */}

                <div
                    className={`product__topbar__icon`}
                    onClick={() => history.push("/carts")}
                >
                    <SVG svg={"cart-small"} />
                </div>
            </div>
        </div>
    );
};
