import { CityContainer } from 'containers/CollaborateContainer/Logs';
import React from 'react';

const CollaborateCityLogs = () => {
  return (
    <CityContainer />
  );
}

export default CollaborateCityLogs;
