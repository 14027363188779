import { SVG } from "components";
export const NotFound = () => {
  return (
    <>
      <div className="buy-panel__not-found">
        <div className="buy-panel__not-found__logo">
          <SVG svg="panel-not-found" />
        </div>
        <span className="buy-panel__not-found__title">
          هم اکنون پنل فعالی وجود ندارد
        </span>
        <span className="buy-panel__not-found__description">
          با خرید پنل تبلیغاتی شما می توانید کسب درآمدی کنید
        </span>
      </div>
    </>
  );
};
