import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { addCommentAPI } from "../../utils/Services/API";
import "./CreateComment.scss";

export const CreateComment = ({ active = false, setShow, replyData }) => {
  const user_token = localStorage.getItem("user_token");
  const [commentValue, setCommentValue] = useState("");

  const handleSubmitComment = () => {
    var commentData = {
      type: "product",
      id: replyData.productId,
      comment: commentValue,
    };

    if (replyData.commentId !== null)
      commentData.parent_id = replyData.commentId;

    const APIResult = addCommentAPI(user_token, commentData);
    APIResult.then((response) => {
      if (response.status === 401) {
        toast.error("برای ثبت نظر خود، ابتدا وارد شوید.");
      } else {
        setCommentValue("");
        setShow(false);
        toast.success("نظر شما ثبت شد، پس از تایید مدیریت نمایش داده می‌شود.");
      }
    });
  };

  useEffect(() => {
    setCommentValue("");
  }, [active]);

  return (
    <div
      className={`create-comment ${active && "active"}`}
      id="close-area"
      onClick={(e) => {
        e.target.id === "close-area" && setShow(false);
      }}
    >
      <div className="create-comment__card">
        <div className="create-comment__card__title">نوشتن نظر</div>
        <textarea
          placeholder="نظر خود را بنویسید ..."
          onChange={(e) => setCommentValue(e.target.value)}
          value={commentValue}
          className="create-comment__card__input"
        ></textarea>
        <div
          className="create-comment__card__button"
          onClick={() => handleSubmitComment()}
        >
          ثبت نظر
        </div>
      </div>
    </div>
  );
};
