import {Card, Fade, PageTopbar, Spinner} from "components";
import moment from "jalali-moment";
import {useEffect, useState} from "react";
import {useLocation, useParams} from "react-router-dom";
import {getSlHistoryAPI} from "utils";
import {currency} from "utils/Functions";
import {getRlHistoryAPI} from "../../../utils";

export const SlRlLogRecordContainer = () => {
  const [loading, setLoading] = useState(true);
  const [slRlRecords, setSlRlRecords] = useState([]);
  const {id} = useParams();
  const {type} = useParams();

  const getSlRecord = () => {
    getSlHistoryAPI(Number(id))
      .then((response) => {
        setSlRlRecords(response.data);
      })
      .catch((err) => {
      })
      .finally(() => setLoading(false));
  };

  const getRlRecord = () => {
    getRlHistoryAPI(Number(id))
      .then((response) => {
        setSlRlRecords(response.data);
      })
      .catch((err) => {
      })
      .finally(() => setLoading(false));
  };

  const handleShowType = (recordType) => {
    switch (recordType) {
      case "kartella":
        return "کارتلا";

      case "kartland":
        return "کارتلند";

      case "collaboration":
        return "پنل نمایندگی";

      default:
        break;
    }
  };

  const handleShowMessage = (slRlRecord) => {
    switch (slRlRecord.type) {
      case "sl":
        // return `مبلغ ${currency(slRlRecord.amount)} تومان بابت پاداش فروش نقدی ${handleShowType(
        //   slRlRecord.department
        // )} به کاربر شماره ${slRlRecord.user} به مبلغ ${currency(
        //   slRlRecord.totalPaid
        // )}
        // در تاریخ
        // ${moment(slRlRecord.created_at, "YYYY/MM/DD")
        //   .locale("fa")
        //   .format("YYYY/MM/DD")}
        // تومان به SL شما واریز شد.`;
        return `بابت فروش نقدی ${handleShowType(
          slRlRecord.department
        )} به مبلغ ${currency(slRlRecord.totalPaid)} تومان به کاربر شماره ${
          slRlRecord.user
        } در تاریخ ${moment(slRlRecord.created_at, "YYYY/MM/DD")
          .locale("fa")
          .format("YYYY/MM/DD")} مبلغ ${currency(
          slRlRecord.amount
        )} تومان پاداش به SL شما واریز شد.`;

      case "rl":
        return `بابت فروش نقدی ${handleShowType(
          slRlRecord.department
        )} به مبلغ ${currency(slRlRecord.totalPaid)} تومان به کاربر شماره ${
          slRlRecord.user
        } در تاریخ ${moment(slRlRecord.created_at, "YYYY/MM/DD")
          .locale("fa")
          .format("YYYY/MM/DD")} مبلغ ${currency(
          slRlRecord.amount
        )} تومان پاداش به RL شما واریز شد.`;

      default:
        break;
    }
  };

  useEffect(() => {
    if (type === 'sl') {
      getSlRecord();
    } else {
      getRlRecord();
    }
  }, []);

  return (
    <Fade>
      <PageTopbar title="تاریخچه" showKv={false}/>
      <Spinner active={loading} color="dark"/>
      <Card>
        {slRlRecords.length > 0 ? slRlRecords?.map((slRlRecord, index) => (
          <div className="slrl__log" key={index}>
            <div className=""></div>
            <div className="slrl__log__title">
              {handleShowMessage(slRlRecord)}
            </div>
          </div>
        )) : (
          <div className="flex j-c a-c">تاریخچه‌ای یافت نشد!</div>
        )}
      </Card>
    </Fade>
  );
};
