import { KmQueueContainer } from 'containers';
import React from 'react';

const KmQueue = () => {
  return (
    <KmQueueContainer />
  );
}

export default KmQueue;
