import React, { useEffect, useRef, useState } from "react";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import "mapbox-gl/dist/mapbox-gl.css";
import { SVG, Modal, Dots } from "components";
import { useHistory } from "react-router-dom";
import toast from "react-hot-toast";

mapboxgl.accessToken =
  "pk.eyJ1Ijoia2FydGFhayIsImEiOiJjbDNsaGw4NDIwY3R1M2VwNXAxZXNhemltIn0.oG3Q1VAF7wcyaUUvSCKxVw";

const CreateShopLocation = ({ onSubmit, close, entryString, newAddress, jadid }) => {
  const history = useHistory();
  const mapContainer = useRef(null);
  const addressRef = useRef(null);
  const [addressValue, setAddressValue] = useState(jadid);
  const map = useRef(null);
  const [lng, setLng] = useState(51.657354);
  const [lat, setLat] = useState(32.674155);
  const [zoom, setZoom] = useState(17);
  const [modal, setModal] = useState(true);
  const [current, setCurrent] = useState({});
  const [loading, setLoading] = useState(false);
  const [enableSubmitButton, setEnableSubmitButton] = useState(false);

  const geolocate = new mapboxgl.GeolocateControl({
    positionOptions: {
      enableHighAccuracy: true,
    },
    setZoom: 17,
    fitBoundsOptions: { maxZoom: 17 },
    trackUserLocation: false,
    showUserHeading: true,
  });

  const goToUserLocation = () => {
    const el = document.getElementsByClassName("mapboxgl-ctrl-geolocate");
    el && el[0].click();
  };

  const handleSubmit = () => {
    // if (addressValue == "") {
    //   toast.error("آدرس متنی را وارد نمایید");
    //   return;
    // }
    setLoading(true);
    onSubmit({ lat: current.lat, lng: current.lng, address: addressValue });
  };

  useEffect(() => {
    if (map.current) return; // initialize map only once

    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/kartaak/cl37c9d8c001y14la74hqn2ze",
      center: [lng, lat],
      zoom: zoom,
      minZoom: 4.5,
      maxZoom: 17.5,
      localFontFamily: "VazirFaNum",
      localIdeographFontFamily: "VazirFaNum",
      logoPosition: "top-left",
      touchPitch: false,
    });
    map.current.dragRotate.disable();
    map.current.touchZoomRotate.disableRotation();

    map.current.addControl(geolocate);

    map.current.setMaxBounds([
      [44.032434, 25.057032],
      [63.33335, 39.78205],
    ]);

    map.current.on("load", function () {
      map.current.addLayer(
        {
          id: "country-boundaries",
          source: {
            type: "vector",
            url: "mapbox://mapbox.country-boundaries-v1",
          },
          "source-layer": "country_boundaries",
          type: "fill",
          paint: {
            "fill-color": "#efe9e1",
            "fill-opacity": 0.9,
          },
        },
        "country-label"
      );

      map.current.setFilter("country-boundaries", [
        "in",
        "iso_3166_1_alpha_3",
        "PAK",
        "IRQ",
        "AFG",
        "KWT",
        "TKM",
        "QAT",
        "TUR",
        "SAU",
        "AZE",
        "ARE",
        "ARM",
      ]);
    });

    setCurrent(map.current.getCenter());

    map.current.on("moveend", () => {
      setCurrent(map.current.getCenter());
    });
  });

  useEffect(() => {
    if (
      document.getElementsByClassName("mapboxgl-ctrl-geolocate")[0] !==
      undefined
    )
      goToUserLocation();
  }, [document.getElementsByClassName("mapboxgl-ctrl-geolocate")[0]]);

  return (
    <div
      onTouchMove={() => {
        if(!enableSubmitButton) {
          setEnableSubmitButton(true);
        }
      }}
      style={{
        position: "fixed",
        top: 0,
        zIndex: 6,
        width: "100%",
        height: window.innerHeight,
        overflow: "hidden",
        backgroundColor: "#e6e4e0",
      }}
    >
      <div className="map__top-right" />

      <div className="map__geolocate top-right" onClick={() => close()}>
        <SVG svg="times" />
      </div>
      <div
        className="map__geolocate under-top-right"
        onClick={() => goToUserLocation()}
      >
        <SVG svg="geolocate" />
      </div>
      <div
        ref={mapContainer}
        style={{
          height: "100%",
        }}
      />
      <div
        className="map"
        style={{
          height: "64px",
          width: "۶۰px",
          position: "fixed",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div style={{ marginBottom: 24 }}>
          <SVG svg="add-location-marker" />
        </div>
      </div>
      <div
        className={`button green fixed left-0 right-0 bottom-16 z-index-2 width-full-16 ${
          modal && "exitAnimation"
        } ${!enableSubmitButton && "disable"}`}
        onClick={() => {
          if(enableSubmitButton) {
            handleSubmit()
          } else {
            toast.error("موقعیت مکانی خود را مقداری جا‌به‌جا کنید.")
          }
        }}
      >
        ثبت موقعیت جغرافیایی
      </div>
      <Modal active={modal} close={() => close()}>
        <div className="mt-8">
          <span className="">
            آدرس متنی{" "}
            <small className="creation-location__title__slug">
              ( برای نمایش به کاربر )
            </small>
          </span>
        </div>
        <textarea
          placeholder="آدرس محل فروشگاه را وارد نمایید"
          value={entryString + addressValue.substr(entryString.length)}
          className=" mt-10 input withoutHeight"
          name="text"
          rows={10}
          onChange={(e) => {
            setAddressValue(e.target.value);
            newAddress((prev) => ({
              ...prev,
              address: e.target.value.slice(),
            }));
          }}
        />
        <div
          className="button"
          onClick={() => {
            if (addressValue.length === 0) {
              toast.error("آدرس فروشگاه را وارد نمایید");
              return;
            }
            setModal(false);
          }}
        >
          {loading ? <Dots color="light" /> : "ثبت آدرس"}
        </div>
      </Modal>
      {/* <div
        className="map"
        style={{
          borderRadius: "50%",
          backgroundColor: "red",
          position: "fixed",
        }}
      /> */}
    </div>
  );
};

export default CreateShopLocation;
