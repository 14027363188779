import React, { useEffect, useRef, useState } from "react";
import {
    Card,
    Fade,
    PageTopbar,
    SVG,
    Toggle,
    Dots,
    Modal,
    CounterInput,
} from "components";
import { useLocation, useHistory, Link } from "react-router-dom";
import {
    suspendStoreAPI,
    toggleStoreStatus,
    getHintAPI,
    getStoreMessagesAPI,
    storeMarketerAPI,
    getCancelCooparationAPI,
    buyBlueKmAPI,
    getBlueKmPriceAPI,
    StoreShowMapAPI,
} from "utils/Services";
import { getUserStoreAPI } from "utils";
import toast from "react-hot-toast";
import { currency, farsiDigitToEnglish } from "../../utils/Functions";
import { checkConsultantCodeAPI } from "../../utils/Services/Km/White/CheckConsultantCode";
import { useUser } from "../../hooks";

export const MyShopContainer = ({ children }) => {
    const history = useHistory();
    const location = useLocation();
    const {
        userState: { user },
    } = useUser();
    const [active, setActive] = useState(false);
    const [showMap, setShowMap] = useState(false);
    const [storeDetail, setStoreDetail] = useState<any>({});
    const [loadingStatus, setLoadingStatus] = useState(true);
    const [loadingToggle, setLoadingToggle] = useState(false);
    const [editMode, setEditMode] = useState(true);
    const [suspendModal, setSuspendModal] = useState(false);
    const [suspendLoading, setSuspendLoading] = useState(false);
    const [hintLoading, setHintLoading] = useState(true);
    const [storeMessages, setStoreMessages] = useState([]);
    const [hint, setHint] = useState<any>({});
    const [cancelHint, setCancelHint] = useState<any>({});
    const [winWinHint, setWinWinHint] = useState<any>({});
    const [deleteHint, setDeleteHint] = useState<any>({});
    const [winWinModal, setWinWinModal] = useState(false);
    const [cancelCooperationModal, setCancelCooperationModal] = useState(false);
    const [cancelCooparationLoading, setCancelCooparationLoading] =
        useState(false);
    const [activeWinWin, setActiveWinWin] = useState(false);
    const [winWinRules, setWinWinRules] = useState(false);
    const [showRulesAccept, setShowRulesAccept] = useState(false);
    const [showBuyModal, setShowBuyModal] = useState(false);
    const buyCount = useRef(null);
    const [kmCount, setKmCount] = useState<any>(1);
    const [kmPrice, setKmPrice] = useState(100000);
    const [buyLoading, setBuyLoading] = useState(false);
    const [priceLoading, setPriceLoading] = useState(false);
    const [inviter, setInviter] = useState("");
    const [inviterCode, setInviterCode] = useState("");
    const [inviteCodeStatus, setInviteCodeStatus] = useState(false);
    const [inviteCodeLoading, setInviteCodeLoading] = useState(false);

    const step = [
        {
            title: "مشخصات فروشگاه",
            icon: "myshop-information",
            path: `/myshop/information${editMode ? "?edit=true" : ""}`,
            needKm: false,
        },
        {
            title: "آدرس",
            icon: "myshop-location",
            path: `/myshop/location${editMode ? "?edit=true" : ""}`,
            needKm: false,
        },
        {
            title: "ویترین",
            icon: "myshop-showcase",
            path: `/myshop/showcase${editMode ? "?edit=true" : ""}`,
            needKm: false,
        },
        {
            title: "تخفیف فروشگاه حضوری",
            icon: "discount",
            path: `/myshop/discount`,
            needKm: false,
        },
        {
            title: "ساعات کاری",
            icon: "myshop-timeplan",
            path: `/myshop/timeplan${editMode ? "?edit=true" : ""}`,
            needKm: false,
        },
        {
            title: "اطلاعات تماس",
            icon: "myshop-contact",
            path: `/myshop/contact${editMode ? "?edit=true" : ""}`,
            needKm: false,
        },
        {
            title: "نظرات کاربران",
            icon: "myshop-comments",
            path: "/myshop/comments",
            needKm: false,
        },
        // {
        //   title: "کیف KM",
        //   icon: "myshop-buykm",
        //   path: "/myshop/buy-km",
        //   needKm: false
        // },
        // {
        //   title: "بارکد اختصاصی",
        //   icon: "store-qr",
        //   path: "/myshop/club",
        //   needKm: false
        // },
        // {
        //   title: "گنجینه 150581",
        //   icon: "Treasure-color",
        //   path: "/myshop/trove",
        //   needKm: false
        // },
        // {
        //   title: "پنل فروشگاه اینترنتی",
        //   icon: "create-shop",
        //   path: "/create-admin-store",
        //   needKm: false
        // },
        // {
        //   title: "شرایط اهدای KM",
        //   icon: "myshop-givekm",
        //   path: `/myshop/give-km${editMode ? "?edit=true" : ""}`,
        // },
        // {
        //   title: "ویدیو تبلیغاتی",
        //   icon: "myshop-videos",
        //   path: `/myshop/videos${editMode ? "?edit=true" : ""}`,
        //   needKm: true
        // },
        // {
        //   title: "پیام ها",
        //   icon: "message",
        //   path: `/myshop/club/messages`,
        //   needKm: true
        // },
    ];

    const winWinItems = [
        {
            title: "کیف KP",
            icon: "myshop-buykm",
            path: "/myshop/buy-km",
            needKm: false,
        },
        {
            title: "پیام ها",
            icon: "message",
            path: `/myshop/club/messages`,
            needKm: true,
        },
    ];

    const buyBlueKm = () => {
        setBuyLoading(true);
        buyBlueKmAPI(inviter, 1)
            .then((response) => {
                if (
                    response.status === 200 &&
                    response.data.payID &&
                    response.data.tk
                ) {
                    toast.success("در حال انتقال به درگاه پرداخت...");

                    const paymentURL = `${process.env.REACT_APP_DEV_IP}api/v3/km/blue/payment/${response.data.payID}/${response.data.tk}`;
                    history.push(`/pay?${paymentURL}`);
                    setTimeout(() => {
                        if (history.location.pathname.includes("/pay"))
                            window.location.href = `${paymentURL}`;
                    }, 600);
                }
            })
            .catch((err) => {
                if (
                    err.response.data.msg ==
                    "this user must BlueKm Wallet under 99 to access buy km"
                ) {
                    toast.error(
                        "برای خرید بسته جدید مقدار KM شما باید کمتر از 100 عدد باشد"
                    );
                } else {
                    toast.error("مشکلی در خرید KM رخ داد");
                }
            })
            .finally(() => setBuyLoading(false));
    };

    const getBlueKmPrice = () => {
        setPriceLoading(true);
        getBlueKmPriceAPI()
            .then((res) => {
                if (res.status === 200) {
                    setKmPrice(res.data);
                }
            })
            .catch((err) => {
                toast.error("خطایی رخ داد");
            })
            .finally(() => setPriceLoading(false));
    };

    const ToggleStatus = () => {
        // setActive(!active);
        if (storeDetail.isActive === 0) {
            toast.error("فروشگاه ابتدا باید توسط کارتک فعال شود");
            return;
        }
        setLoadingStatus(true);
        toggleStoreStatus()
            .then((res) => {
                if (res) {
                    setActive(res.data);
                    if (res.data && res.status === 200) {
                        toast.success("فروشگاه با موفقیت فعال شد");
                    } else {
                        toast.success("فروشگاه با موفقیت غیرفعال شد");
                    }
                }
            })
            .catch((err) => {
                toast.error(err.response.data.msg);
            })
            .finally(() => setLoadingStatus(false));
    };

    const fetchStatus = () => {
        getUserStoreAPI()
            .then((res) => {
                if (res.status === 200) {
                    setActive(
                        res.data?.isActive === 1 && res.data?.activeByUser === 1
                    );
                    setStoreDetail(res.data);
                    setShowMap(res.data?.showMap);
                }
            })
            .catch((err) => {
                if (err) toast.error("به مشکل برخوردیم!");
            })
            .finally(() => setLoadingStatus(false));
    };

    const suspendStore = () => {
        setSuspendLoading(true);
        suspendStoreAPI()
            .then((res) => {
                if (res.status === 200) {
                    toast.success("فروشگاه حذف شد");
                    history.push("/map");
                }
            })
            .catch((err) => {
                toast.error("خطا در حذف فروشگاه!");
            })
            .finally(() => setSuspendLoading(true));
    };

    const getStoreMessages = () => {
        getStoreMessagesAPI()
            .then((res) => {
                if (res.status === 200) {
                    setStoreMessages(res.data);
                }
            })
            .catch((err) => {})
            .finally(() => {});
    };

    const handleShowMessageColor = (messageType) => {
        switch (messageType) {
            case "info":
                return "blue";

            case "warning":
                return "yellow";

            case "alert":
                return "orange";

            case "risk":
                return "red";

            default:
                return "blue";
        }
    };

    // const storeInviter = () => {
    //   setInviteCodeLoading(true);
    //   storeMarketerAPI(inviterCode).then(res => {
    //     if (res.status === 200) {
    //       toast.success('معرف با موفقیت ثبت شد');
    //       fetchStatus();
    //     }
    //   }).catch(err => {
    //     if (err.response.status === 400) {
    //       toast.error("پنلی با این کد پیدا نشد.");
    //     }
    //   }).finally(() => setInviteCodeLoading(false));
    // };

    const handleSubmitInviteCode = () => {
        setInviteCodeLoading(true);
        checkConsultantCodeAPI(inviterCode)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data) {
                        toast.error("شماره تلفن یا کد وارد شده موجود نیست");
                        return;
                    } else {
                        toast.success("شماره تلفن یا کد وارد شده تائید شد");
                        setInviteCodeStatus(true);
                        setInviter(inviterCode);
                        return;
                    }
                } else if (response.status === 404) {
                    toast.error("پنلی با این کد پیدا نشد.");
                    return;
                }
            })
            .catch((err) => {})
            .finally(() => setInviteCodeLoading(false));
    };

    const handleActivateEditMode = () => {
        history.replace(`/myshop?edit=${!editMode}`);
        setEditMode(!editMode);
    };

    const handleCancelCooparation = () => {
        setCancelCooparationLoading(true);
        getCancelCooparationAPI()
            .then((response) => {
                // setCancelCooparation(response.data)
                toast.success("با موفقیت از طرح خارج شدید");
                setCancelCooperationModal(false);
                fetchStatus();
            })
            .catch((err) => {
                toast.error("عملیات با خطا مواجه شد");
            })
            .finally(() => setCancelCooparationLoading(false));
    };

    const getHint = () => {
        getHintAPI(
            // storeDetail.totalBoughtKm === 100 && storeDetail.totalDonatedKm === 0
            //   ?
            "هشدار حذف فروشگاه قبل از باز شدن پلمپ بسته اول"
            // : "هشدار حذف فروشگاه بعد از باز شدن پلمپ بسته اول"
        )
            .then((response) => {
                setHint(response);
            })
            .catch(() => setHint(false))
            .finally(() => setHintLoading(false));
    };

    // const getDeleteHint = () => {
    //   getHintAPI(
    //     "هشدار حذف فروشگاه"
    //   )
    //     .then((response) => {
    //       setDeleteHint(response);
    //     })
    //     .catch(() => setDeleteHint(false))
    //     .finally(() => setHintLoading(false));
    // };

    // const getCancelHint = () => {
    //   getHintAPI(
    //     "هشدار خروج از طرح وین وین"
    //   )
    //     .then((response) => {
    //       setCancelHint(response);
    //     })
    //     .catch(() => setCancelHint(false))
    //     .finally(() => setHintLoading(false));
    // };

    // const getWinWinHint = () => {
    //   getHintAPI(
    //     "قوانین طرح Win Win"
    //   )
    //     .then((response) => {
    //       setWinWinHint(response);
    //     })
    //     .catch(() => setWinWinHint(false))
    //     .finally(() => setHintLoading(false));
    // };

    const handleShowSuspendModal = () => {
        if (storeDetail.haveKm) {
            toast.error("برای حذف فروشگاه ابتدا باید از طرح win-win خارج شوید");
            return;
        }
        setSuspendModal(true);
        //getDeleteHint();
    };

    const handleShowCancelCooperationModal = () => {
        setCancelCooperationModal(true);
        //getCancelHint();
    };

    const handleShowWinWinModal = () => {
        setWinWinModal(true);
        //getWinWinHint();
    };

    const handleShowActivateAlert = () => {
        if (
            storeDetail?.workinghours?.length === 0 ||
            storeDetail?.contactnumber?.length === 0
        ) {
            return true;
        } else {
            return false;
        }
    };

    const changeStoreShowMap = () => {
        StoreShowMapAPI()
            .then((response) => {
                if (response.status === 200) {
                    toast.success("وضعیت نمایش در نقشه با موفقیت تغییر کرد");
                    setShowMap(response.data);
                }
            })
            .catch((error) => {
                toast.error("خطایی رخ داده است.");
            })
            .finally(() => {});
    };

    useEffect(() => {
        fetchStatus();
        getStoreMessages();
        if (location.search === "?edit=true") {
            setEditMode(true);
        }
        history.replace("/myshop");
        getBlueKmPrice();
    }, []);

    useEffect(() => {
        document
            .getElementById("marketingRules")
            ?.addEventListener("scroll", () => {
                document.getElementById("marketingRules").scrollTop >
                document.getElementById("marketingRules")?.scrollHeight -
                    document.getElementById("marketingRules")?.clientHeight -
                    20
                    ? setShowRulesAccept(true)
                    : setShowRulesAccept(false);
            });
        return () => {
            document
                .getElementById("marketingRules")
                ?.removeEventListener("scroll", () => {
                    document.getElementById("marketingRules").scrollTop >
                    document.getElementById("marketingRules")?.scrollHeight -
                        document.getElementById("marketingRules")
                            ?.clientHeight -
                        20
                        ? setShowRulesAccept(true)
                        : setShowRulesAccept(false);
                });
        };
    }, [document.getElementById("marketingRules")?.scrollTop]);

    return (
        <Fade>
            <PageTopbar title="پنل مدیریت فروشگاه" showKv={false} />
            <div className="wrapper-fix-shop-manage">
                <Modal
                    active={suspendModal}
                    close={() => setSuspendModal(false)}
                >
                    <div className={"deleting-modal"}>
                        <div className={"deleting-modal__title"}>
                            {hintLoading ? (
                                <Dots color="dark" />
                            ) : (
                                deleteHint.content
                            )}
                        </div>
                        <div className={"deleting-modal__actions"}>
                            <div
                                onClick={() =>
                                    !suspendLoading && suspendStore()
                                }
                                className={"button red"}
                            >
                                {suspendLoading ? (
                                    <Dots color={"light"} />
                                ) : (
                                    <span>حذف فروشگاه</span>
                                )}
                            </div>
                            <div
                                onClick={() => setSuspendModal(false)}
                                className={"button empty"}
                            >
                                بازگشت
                            </div>
                        </div>
                    </div>
                </Modal>

                <Modal active={winWinRules} close={() => setWinWinRules(false)}>
                    <div className="marketing__rules">
                        <div className="marketing__rules__title">
                            {winWinHint.title}
                        </div>

                        <div className="marketing__rules__description">
                            {winWinHint.content?.split("\n")?.map((str) => (
                                <div>{str}</div>
                            ))}
                        </div>

                        <div className="marketing__rules__bottom">
                            <div
                                className={`marketing__rules__button marketing__rules__accept`}
                                onClick={() => setWinWinRules(false)}
                            >
                                متوجه شدم
                            </div>
                            {/* <div className="marketing__rules__button marketing__rules__reject">
                انصراف
              </div> */}
                        </div>
                    </div>
                </Modal>

                <Modal
                    active={showBuyModal}
                    close={() => setShowBuyModal(false)}
                >
                    <div className="buy-blue">
                        {/*<div className="buy-blue__count">*/}
                        {/*  <div className="title">بسته 100تایی KM</div>*/}
                        {/*  <CounterInput*/}
                        {/*    ref={buyCount}*/}
                        {/*    defaultValue={1}*/}
                        {/*    step={1}*/}
                        {/*    max={99}*/}
                        {/*    onChange={(value) => setKmCount(value)}*/}
                        {/*    kmMode={true}*/}
                        {/*  />*/}
                        {/*</div>*/}
                        <div className="mb-8">
                            {user.introducedId === null && (
                                <>
                                    <div className="title">
                                        کد یا تلفن مشاور
                                    </div>
                                    <div className="row">
                                        <input
                                            type="text"
                                            className="input"
                                            value={inviterCode}
                                            onChange={(e) =>
                                                setInviterCode(
                                                    farsiDigitToEnglish(
                                                        e.target.value
                                                    )
                                                )
                                            }
                                            placeholder="کد یا تلفن مشاور"
                                        />
                                        <div
                                            className={`create-shop__inviter__button ${
                                                inviteCodeStatus && "edit"
                                            } ${
                                                inviterCode.length === 0 &&
                                                "hidden"
                                            }`}
                                            onClick={() => {
                                                if (inviteCodeStatus) {
                                                    setInviterCode("");
                                                    setInviteCodeStatus(false);
                                                    setInviter("");
                                                } else {
                                                    handleSubmitInviteCode();
                                                }
                                            }}
                                        >
                                            {inviteCodeLoading ? (
                                                <Dots color="light" />
                                            ) : inviteCodeStatus ? (
                                                "ویرایش"
                                            ) : (
                                                "ثبت"
                                            )}
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                        {!priceLoading && (
                            <div className="buy-blue__price">
                                <div className="">قیمت:</div>
                                <div className="">
                                    {currency(kmCount * kmPrice * 100)} تومان
                                </div>
                            </div>
                        )}
                    </div>

                    <div
                        className={`button ${kmCount < 1 && "disable"}`}
                        onClick={() => !buyLoading && buyBlueKm()}
                    >
                        {buyLoading ? <Dots color="light" /> : "پرداخت"}
                    </div>
                </Modal>

                <Modal
                    active={cancelCooperationModal}
                    close={() => setCancelCooperationModal(false)}
                >
                    <div className={"deleting-modal"}>
                        <div className={"deleting-modal__title"}>
                            {hintLoading ? (
                                <Dots color="dark" />
                            ) : (
                                cancelHint.content
                            )}
                        </div>
                        <div className={"deleting-modal__actions"}>
                            <div
                                className="button red"
                                // className="Cancel-Cooparation-Main__button__confirmation"
                                onClick={() =>
                                    !cancelCooparationLoading &&
                                    handleCancelCooparation()
                                }
                            >
                                {cancelCooparationLoading ? (
                                    <Dots color={"light"} />
                                ) : (
                                    <span>خروج از طرح</span>
                                )}
                            </div>
                            <div
                                onClick={() => setCancelCooperationModal(false)}
                                className={"button empty"}
                            >
                                بازگشت
                            </div>
                        </div>
                    </div>
                </Modal>

                <Modal active={winWinModal} close={() => setWinWinModal(false)}>
                    <div className="marketing__rules">
                        <div className="marketing__rules__title">
                            {winWinHint.title}
                        </div>

                        <div
                            className="marketing__rules__description"
                            id="marketingRules"
                        >
                            {winWinHint.content?.split("\n")?.map((str) => (
                                <div>{str}</div>
                            ))}
                        </div>

                        <div className="marketing__rules__bottom">
                            <div
                                className={`marketing__rules__button ${
                                    showRulesAccept
                                        ? "marketing__rules__accept"
                                        : "marketing__rules__disabled"
                                }`}
                                onClick={() => {
                                    if (showRulesAccept) {
                                        setShowBuyModal(true);
                                        setWinWinModal(false);
                                    } else {
                                        document
                                            .getElementById("marketingRules")
                                            .scrollTo(0, 1000);
                                    }
                                }}
                            >
                                قوانین را می‌پذیرم
                            </div>
                            {/* <div className="marketing__rules__button marketing__rules__reject">
                انصراف
              </div> */}
                        </div>
                    </div>
                </Modal>
                {/*<div*/}
                {/*  onClick={() => handleActivateEditMode()}*/}
                {/*  className={`shop-creation__edit ${editMode && "active"}`}*/}
                {/*>*/}
                {/*  ویرایش*/}
                {/*</div>*/}
                <Card>
                    <>
                        {handleShowActivateAlert() && (
                            <div className="shop-creation__message blue">
                                با وارد کردن اطلاعات تماس و ساعت کاری فروشگاه،
                                اقدام به فعالسازی آن نمایید.
                            </div>
                        )}

                        {storeMessages.map((message, index) => (
                            <div
                                className={`shop-creation__message ${handleShowMessageColor(
                                    message.type
                                )}`}
                                key={index}
                            >
                                {message.message}
                            </div>
                        ))}

                        {/*{storeDetail.marketerId === null && <>*/}
                        {/*  <div className="title">شماره موبایل یا کد کارتک معرف فروشگاه</div>*/}
                        {/*  <div className="row">*/}
                        {/*    <input*/}
                        {/*      type="text"*/}
                        {/*      className="input"*/}
                        {/*      value={inviterCode}*/}
                        {/*      onChange={(e) =>*/}
                        {/*        setInviterCode(farsiDigitToEnglish(e.target.value))*/}
                        {/*      }*/}
                        {/*      placeholder="شماره موبایل یا کد کارتک معرف"*/}
                        {/*    />*/}
                        {/*    <div*/}
                        {/*      className={`create-shop__inviter__button ${inviteCodeStatus && "edit"*/}
                        {/*      } ${inviterCode.length === 0 && "hidden"}`}*/}
                        {/*      onClick={() => !inviteCodeLoading && storeInviter()}*/}
                        {/*    >*/}
                        {/*      {inviteCodeLoading ? (*/}
                        {/*        <Dots color="light"/>*/}
                        {/*      ) : inviteCodeStatus ? (*/}
                        {/*        "ویرایش"*/}
                        {/*      ) : (*/}
                        {/*        "ثبت"*/}
                        {/*      )}*/}
                        {/*    </div>*/}
                        {/*  </div>*/}
                        {/*</>}*/}

                        <div className={`mycity row a-c`}>
                            <div className={`shop-creation__body__counter`}>
                                <div
                                    className={`shop-creation__body__counter__circle `}
                                >
                                    {/* {index + 1} */}
                                </div>
                            </div>
                            <div className={`shop-creation__body__info `}>
                                <div className="shop-creation__body__info__wrapper row a-c">
                                    <div
                                        className={`shop-creation__status__icon ${
                                            active && "active"
                                        }`}
                                    >
                                        <SVG svg="circle-status" />
                                    </div>
                                    {loadingStatus ? (
                                        <span>...</span>
                                    ) : active ? (
                                        <span
                                            className={`shop-creation__status__title ${
                                                active && "active"
                                            }`}
                                        >
                                            فعال
                                        </span>
                                    ) : (
                                        <span>غیر فعال</span>
                                    )}
                                </div>
                                <div className="shop-creation__body__info__wrapper__status">
                                    {/* <SVG svg="arrow" /> */}
                                    {loadingStatus ? (
                                        <Dots color="dark" />
                                    ) : (
                                        <Toggle
                                            value={active}
                                            onChange={() => {
                                                if (!loadingToggle)
                                                    ToggleStatus();
                                            }}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>

                        <div
                            className={`shop-creation__body__counter__mini-separator`}
                        ></div>

                        <div className={`shop-creation__body__info `}>
                            <div className="shop-creation__body__info__wrapper row a-c">
                                <div
                                    className={`shop-creation__status__icon ${
                                        active && "active"
                                    }`}
                                >
                                    <SVG svg="circle-status" />
                                </div>
                                <span>نمایش فروشگاه من نقشه کارتک</span>
                            </div>
                            <div className="shop-creation__body__info__wrapper__status">
                                {/* <SVG svg="arrow" /> */}
                                {loadingStatus ? (
                                    <Dots color="dark" />
                                ) : (
                                    <Toggle
                                        value={showMap}
                                        onChange={() => {
                                            changeStoreShowMap();
                                        }}
                                    />
                                )}
                            </div>
                        </div>

                        <div
                            className={`shop-creation__body__counter__mini-separator`}
                        ></div>
                    </>

                    {step.map((item, index) => (
                        <>
                            <div className={`row a-c`}>
                                <div className={`shop-creation__body__counter`}>
                                    <div
                                        className={`shop-creation__body__counter__circle `}
                                    >
                                        {index + 1}
                                    </div>
                                </div>
                                <div
                                    style={{
                                        backgroundColor: `${
                                            index === 9 && "#eb4d4c"
                                        }`,
                                        color: `${index === 9 && "white"}`,
                                    }}
                                    onClick={() => {
                                        if (
                                            item.needKm &&
                                            storeDetail?.totalBoughtKm === 0 &&
                                            storeDetail.totalDonatedKm === 0
                                        ) {
                                            toast.error(
                                                "برای استفاده از این امکان ابتدا اقدام به خرید KM کنید."
                                            );
                                        } else {
                                            index === 9 &&
                                            user?.detail?.internetStore
                                                ? window
                                                      .open(
                                                          "https://adminstore.kartaak.com/",
                                                          "_blank"
                                                      )
                                                      .focus()
                                                : history.push(item.path);
                                        }
                                    }}
                                    className={`shop-creation__body__info ${
                                        item.needKm &&
                                        storeDetail?.totalBoughtKm === 0 &&
                                        storeDetail.totalDonatedKm === 0 &&
                                        "deactive"
                                    }`}
                                >
                                    <div
                                        className={`shop-creation__body__info__wrapper row a-c`}
                                    >
                                        <SVG svg={item.icon} />
                                        <span>
                                            {index === 9 &&
                                            user?.detail?.internetStore
                                                ? "ورود به پنل فروشگاه اینترنتی"
                                                : item.title}
                                        </span>
                                    </div>
                                    <div className="shop-creation__body__info__wrapper__status ">
                                        <SVG svg={"arrow"} color={"black"} />
                                    </div>
                                </div>
                            </div>
                            <div
                                className={`shop-creation__body__counter__mini-separator ${
                                    index == step.length - 1 &&
                                    !storeDetail.haveKm &&
                                    "deactive"
                                }`}
                            />
                        </>
                    ))}

                    {storeDetail.haveKm && (
                        <div>
                            {/* <div className={`shop-creation__win-win active`}> */}
                            {/* <div onClick={() => setActiveWinWin(prevState => !prevState)} className="shop-creation__win-win__header">
                طرح برنده - برنده
              </div> */}

                            {/*<div className="shop-creation__win-win__body">*/}
                            {/*  <div className="shop-creation__win-win__body__row">*/}
                            {/*    <div className="shop-creation__win-win__body__row__item">*/}
                            {/*      */}
                            {/*    </div>*/}
                            {/*    <div className="shop-creation__win-win__body__row__item"></div>*/}
                            {/*  </div>*/}
                            {/*</div>*/}

                            {/* <div className="shop-creation__win-win__items"> */}

                            {/*<div className="profile__items__row">*/}
                            {/*  <Link to={`/profile/info`} className="profile__items__item svg-seagreen">*/}
                            {/*    کیف KM*/}
                            {/*    <SVG svg="myshop-buykm"/>*/}
                            {/*  </Link>*/}

                            {/*  <Link to={`/profile/addresses`} className="profile__items__item svg-darkorange">*/}
                            {/*    ارسال پیام*/}
                            {/*    <SVG svg="message"/>*/}
                            {/*  </Link>*/}
                            {/*</div>*/}
                            {/* <div className={`row a-c mb-8`}>
                  <div onClick={() => {
                    setWinWinRules(true);
                    getWinWinHint();
                  }}
                      className={`shop-creation__body__info`}>
                    <div
                      className={`shop-creation__body__info__wrapper row a-c`}
                    >
                      <SVG svg={'description'}/>
                      <span>توضیحات طرح</span>
                    </div>
                    <div className="shop-creation__body__info__wrapper__status ">
                      <SVG
                        svg={"arrow"}
                        color={"black"}
                      />
                    </div>
                  </div>
                </div> */}

                            {/* <div className={`row a-c mb-8`}>
                  <div onClick={() => history.push('/myshop/buy-km')}
                      className={`shop-creation__body__info`}>
                    <div
                      className={`shop-creation__body__info__wrapper row a-c`}
                    >
                      <SVG svg={'myshop-buykm'}/>
                      <span>کیف KP</span>
                    </div>
                    <div className="shop-creation__body__info__wrapper__status ">
                      <SVG
                        svg={"arrow"}
                        color={"black"}
                      />
                    </div>
                  </div>
                </div> */}

                            {/* <div className={`row a-c mb-8`}>
                  <div onClick={() => history.push('/myshop/club/messages')}
                      className={`shop-creation__body__info`}>
                    <div
                      className={`shop-creation__body__info__wrapper row a-c`}
                    >
                      <SVG svg={'myshop-club'}/>
                      <span>باشگاه مشتریان</span>
                    </div>
                    <div className="shop-creation__body__info__wrapper__status ">
                      <SVG
                        svg={"arrow"}
                        color={"black"}
                      />
                    </div>
                  </div>
                </div> */}

                            {/* <div className={`row a-c mb-8`}>
                  <div onClick={() => {
                    history.push('/myshop/trove');
                  }}
                      className={`shop-creation__body__info`}>
                    <div
                      className={`shop-creation__body__info__wrapper row a-c`}
                    >
                      <SVG svg={'28250'}/>
                      <span>گنجینه 150581</span>
                    </div>
                    <div className="shop-creation__body__info__wrapper__status ">
                      <SVG
                        svg={"arrow"}
                        color={"black"}
                      />
                    </div>
                  </div>
                </div> */}

                            {/* <div className={`row a-c mb-8`}>
                  <div onClick={() => {
                  }}
                      className={`shop-creation__body__info`}>
                    <div
                      className={`shop-creation__body__info__wrapper row a-c`}
                    >
                      <SVG svg={'myshop-videos'}/>
                      <span>ویدیو تبلیغاتی</span>
                      <span className={`shop-creation__body__info__wrapper__soon`}>به‌زودی</span>
                    </div>
                    <div className="shop-creation__body__info__wrapper__status ">
                      <SVG
                        svg={"arrow"}
                        color={"black"}
                      />
                    </div>
                  </div>
                </div> */}
                            {/* 
                {storeDetail.haveKm && <div
                  onClick={handleShowCancelCooperationModal}
                  className="shop-creation__body__cancel mb-8"
                >
                  خروج از طرح
                  <SVG svg="CancelCooparation"/>
                </div>} */}

                            {/* </div> */}
                        </div>
                    )}

                    {/* {!storeDetail.haveKm &&
            <div
              onClick={handleShowWinWinModal}
              className="shop-creation__body__win-win"
            >
              طرح برنده - برنده
              <SVG svg="delete"/>
            </div>
          } */}

                    <div
                        onClick={handleShowSuspendModal}
                        className="shop-creation__body__suspend"
                    >
                        حذف فروشگاه
                        <SVG svg="delete" />
                    </div>
                </Card>
            </div>
        </Fade>
    );
};
