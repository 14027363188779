import {
    AcceptRule,
    CheckBox,
    Dots,
    Fade,
    Modal,
    PageTopbar,
    Spinner,
    UpdateUserInfo,
} from "../../components";
import React, { useEffect, useState } from "react";
import { currency } from "../../utils/Functions";
import { Link, useHistory, useParams } from "react-router-dom";
import {
    buyUnitFromUserAPI,
    getUnitDetailsAPI,
} from "../../utils/Services/KartlandUnits";
import empty from "../../assets/images/empty-kartland.png";
import toast from "react-hot-toast";
import { useUser } from "../../hooks";
import { getHintAPI } from "utils";

export const KartlandUnitDetailsContainer = () => {
    const [payLoading, setPayLoading] = useState(false);
    const [unitDetails, setUnitDetails] = useState<any>(null);
    const [unitDetailsLoading, setUnitDetailsLoading] = useState(true);
    const {
        getUserDataFromApi,
        userState: { user },
    } = useUser();
    const [showFillProfile, setShowFillProfile] = useState(false);
    const [showFillProfileModal, setShowFillProfileModal] = useState(false);
    const [userInfo, setUserInfo] = useState({
        name: "",
        lastname: "",
        nationalCode: "",
    });
    const [hint, setHint] = useState<any>("");
    const history = useHistory();
    const params: any = useParams();
    const [showAlert, setShowAlert] = useState(false);
    const [readCompleted, setreadCompleted] = useState(false);
    const [acceptedRule, setAcceptedRule] = useState(false);

    const getUnitDetails = () => {
        setUnitDetailsLoading(true);
        getUnitDetailsAPI(params.id).then((res) => {
            if (res.status === 200) {
                if (res.data.length !== 0) {
                    setUnitDetails(res.data[0]);
                }
                setUnitDetailsLoading(false);
            }
        });
    };

    const UserInfoHandler = () => {
        if (
            user.name?.length === 0 ||
            user.name === null ||
            user.lastname?.length === 0 ||
            user.lastname === null ||
            user.nationalCode?.length === 0 ||
            user.nationalCode === null ||
            user.nationalCode?.length !== 10
        ) {
            setShowFillProfile(true);
        } else {
            setShowFillProfile(false);
        }
    };

    const handleSubmitUnit = () => {
        setPayLoading(true);
        buyUnitFromUserAPI(unitDetails.id)
            .then((response) => {
                if (response.status === 200) {
                    toast.success("در حال انتقال به درگاه پرداخت...");

                    const paymentURL = `${process.env.REACT_APP_DEV_IP}api/v3/kartland/unit/${response.data.payID}/payment/${response.data.tk}`;
                    history.push(`/pay?${paymentURL}`);
                    setTimeout(() => {
                        if (history.location.pathname.includes("/pay"))
                            window.location.href = `${paymentURL}`;
                    }, 600);
                }
            })
            .catch((err) => {
                toast.error("خرید ویترین با خطا روبه‌رو شد");
            })
            .finally(() => setPayLoading(false));
    };

    const getHint = () => {
        getHintAPI("قوانین و هشدارها 3")
            .then((response) => {
                setHint(response);
            })
            .catch(() => setHint(false))
            .finally(() => {});
    };

    useEffect(() => {
        getUnitDetails();
        getHint();
    }, []);

    useEffect(() => {
        setUserInfo({
            name: user.name,
            lastname: user.lastname,
            nationalCode: user.nationalCode,
        });
    }, [user]);

    useEffect(() => {
        UserInfoHandler();
    }, [user]);

    useEffect(() => {
        document
            .getElementById("buyUnitAlert")
            ?.addEventListener("scroll", () => {
                document.getElementById("buyUnitAlert").scrollTop >
                document.getElementById("buyUnitAlert")?.scrollHeight -
                    document.getElementById("buyUnitAlert")?.clientHeight -
                    20
                    ? setreadCompleted(true)
                    : setreadCompleted(false);
            });
        return () => {
            document
                .getElementById("buyUnitAlert")
                ?.removeEventListener("scroll", () => {
                    document.getElementById("buyUnitAlert").scrollTop >
                    document.getElementById("buyUnitAlert")?.scrollHeight -
                        document.getElementById("buyUnitAlert")?.clientHeight -
                        20
                        ? setreadCompleted(true)
                        : setreadCompleted(false);
                });
        };
    }, [document.getElementById("buyUnitAlert")?.scrollTop]);

    useEffect(() => {
        history.replace(`/kartland/details/${params.id}`);
    }, []);

    return (
        <Fade>
            <div className={"kartland"}>
                <Modal
                    active={showAlert}
                    close={(close) => setShowAlert(false)}
                >
                    <div className="buy-panel__alert__title">{hint.title}</div>
                    <div
                        className={`buy-panel__alert__description ${
                            readCompleted && "deactive"
                        }`}
                        id={"buyUnitAlert"}
                    >
                        {/* {hintLoading && <Dots color="dark" />} */}
                        {hint.content?.split("\n")?.map((str) => (
                            <div>{str}</div>
                        ))}
                    </div>
                    <div className="row buy-panel__alert__bottom">
                        <div
                            className={`buy-panel__alert__button  ${
                                readCompleted ? "accept" : "disabled"
                            }`}
                            onClick={() => {
                                if (readCompleted) {
                                    setAcceptedRule(true);
                                    setShowAlert(false);
                                } else {
                                    document
                                        .getElementById("buyUnitAlert")
                                        .scrollTo(0, 2500);
                                }
                            }}
                        >
                            قوانین را می‌پذیرم
                        </div>
                        <div
                            className="buy-panel__alert__button reject"
                            onClick={() => setShowAlert(false)}
                        >
                            انصراف
                        </div>
                    </div>
                </Modal>

                <PageTopbar title="جزییات ویترین" showKv={false} white={true} />
                <Spinner active={unitDetailsLoading} color="dark" />
                <UpdateUserInfo
                    showUpdate={showFillProfileModal}
                    setShowUpdate={(value) => {
                        value && handleSubmitUnit();
                        setShowFillProfileModal(false);
                    }}
                    userInfo={userInfo}
                    setUserInfo={setUserInfo}
                    getUser={getUserDataFromApi}
                />
                {unitDetails !== null ? (
                    unitDetails.isSold ? (
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-between",
                                height: "calc(100vh - 70px)",
                            }}
                        >
                            <div className={"kartland__details"}>
                                <div className={"kartland__details__info"}>
                                    <div
                                        className={
                                            "kartland__details__info__number"
                                        }
                                    >
                                        <div
                                            className={
                                                "kartland__details__info__number__title"
                                            }
                                        >
                                            شماره ویترین:
                                        </div>
                                        <div
                                            className={
                                                "kartland__details__info__number__value"
                                            }
                                        >
                                            {unitDetails.unitnumber}
                                        </div>
                                    </div>

                                    {/* {unitDetails?.display === 0 && (
                    <div className={"kartland__details__info__number"}>
                      <div className={"kartland__details__info__number__title"}>
                        صاحب واحد:
                      </div>
                      <div className={"kartland__details__info__number__value"}>
                        {unitDetails?.userinfo.name} | {unitDetails?.userinfo.mobile}
                      </div>
                    </div>
                  )} */}

                                    <div
                                        className={
                                            "kartland__details__info__address"
                                        }
                                    >
                                        <div
                                            className={
                                                "kartland__details__info__address__title"
                                            }
                                        >
                                            کشور:
                                        </div>
                                        <div
                                            className={
                                                "kartland__details__info__address__value"
                                            }
                                        >
                                            {unitDetails.countryname}
                                        </div>
                                        <div
                                            className={
                                                "kartland__details__info__address__title"
                                            }
                                        >
                                            استان:
                                        </div>
                                        <div
                                            className={
                                                "kartland__details__info__address__value"
                                            }
                                        >
                                            {unitDetails.statename}
                                        </div>
                                        <div
                                            className={
                                                "kartland__details__info__address__title"
                                            }
                                        >
                                            شهرستان:
                                        </div>
                                        <div
                                            className={
                                                "kartland__details__info__address__value"
                                            }
                                        >
                                            {unitDetails.cityname}
                                        </div>
                                    </div>

                                    <div
                                        className={`kartland__details__info__status ${
                                            unitDetails.isRent && "active"
                                        }`}
                                    >
                                        {unitDetails.isRent
                                            ? "محصول دارد"
                                            : "تخلیه"}
                                    </div>

                                    <div>
                                        {unitDetails.isRent &&
                                        unitDetails.internetStore_id != null ? (
                                            <div
                                                style={{
                                                    fontWeight: 500,
                                                    fontSize: "14px",
                                                    marginTop: "10px",
                                                }}
                                            >
                                                <Link
                                                    style={{ color: "blue" }}
                                                    to={`/seller/${unitDetails.kartlandStore_id}`}
                                                >
                                                    نام فروشگاه :{" "}
                                                    {unitDetails.netStore.name}
                                                </Link>
                                                <p>
                                                    مجموع فروش :{" "}
                                                    {currency(
                                                        +unitDetails.netStore
                                                            .accounting[0]
                                                    )}{" "}
                                                    تومان
                                                </p>
                                            </div>
                                        ) : (
                                            <div></div>
                                        )}
                                    </div>
                                </div>
                                <div className={"kartland__details__price"}>
                                    <div
                                        className={
                                            "kartland__item__price__title"
                                        }
                                    >
                                        قیمت:
                                    </div>
                                    <div
                                        className={
                                            "kartland__item__price__value"
                                        }
                                    >
                                        {currency(unitDetails.sellPrice)} تومان
                                    </div>
                                </div>
                            </div>
                            <div className={"w-full bottom-16"}>
                                <div
                                    className="row a-c j-c"
                                    style={{ margin: "8px auto" }}
                                >
                                    <CheckBox
                                        defaultValue={acceptedRule}
                                        type="check"
                                        onChange={(value) =>
                                            setAcceptedRule(
                                                value ? true : false
                                            )
                                        }
                                    />
                                    <AcceptRule
                                        title="خرید ویترین"
                                        color="light"
                                        onCLick={() => setShowAlert(true)}
                                    />
                                </div>
                                <div
                                    className={`button width-full-16`}
                                    onClick={() => {
                                        if (!acceptedRule) {
                                            // toast.error(
                                            //     "ابتدا قوانین و مقررات خرید ویترین را مطالعه و بپذیرید."
                                            // );
                                            setShowAlert(true);
                                            return;
                                        }
                                        if (showFillProfile) {
                                            setShowFillProfileModal(true);
                                        } else {
                                            !payLoading && handleSubmitUnit();
                                        }
                                    }}
                                >
                                    {payLoading ? (
                                        <Dots color="light" />
                                    ) : (
                                        "پرداخت و خرید ویترین"
                                    )}
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="kartland__wrapper__empty">
                            <div className="kartland__wrapper__empty__image">
                                <img src={empty} alt="empty" />
                            </div>
                            <div className="kartland__wrapper__empty__text">
                                ویترین مورد نظر شما یافت نشد
                            </div>
                            <div className="kartland__wrapper__empty__subtext">
                                در وارد کردن شماره ویترین دقت فرمایید
                            </div>
                        </div>
                    )
                ) : (
                    <div className="kartland__wrapper__empty">
                        <div className="kartland__wrapper__empty__image">
                            <img src={empty} alt="empty" />
                        </div>
                        <div className="kartland__wrapper__empty__text">
                            ویترین مورد نظر شما یافت نشد
                        </div>
                        <div className="kartland__wrapper__empty__subtext">
                            در وارد کردن شماره ویترین دقت فرمایید
                        </div>
                    </div>
                )}
            </div>
        </Fade>
    );
};
