import React, { useState, useEffect } from "react";
import { ShopHeader } from "components/ShopHeader";
import "./ProductFullList.scss";
import { SVG, Fade, Filter, Spinner, Dots, Lowprice } from "components";
import {
    getCateogryParentAPI,
    getProductsAPI,
    getSpecialProductsAPI,
} from "utils/Services/API";
import { cloneDeep } from "lodash";
import { transformPrice } from "utils/Functions";
import { directive } from "@babel/types";
import { WrapperCard } from "components/WrapperCard";
import { Link, useHistory, useLocation } from "react-router-dom";
import ProductImage from "../../assets/images/product.png";
import { useUser } from "hooks";

export const ProductFullList = ({ active = true, isLoading }) => {
    const {
        userState: { user },
    } = useUser();
    const [products, setProducts] = useState<any>([]);
    const [loading, setLoading] = useState<any>(true);
    const [lazyLoading, setLazyLoading] = useState(false);
    const [canLoadMore, setCanLoadMore] = useState(true);
    const [currPage, setCurrPage] = useState(1);
    const history = useHistory();
    const location = useLocation();
    const query = useQuery();
    const user_token = localStorage.getItem("user_token");
    const [categories, setCategories] = useState<any>([]);
    const [filterActive, setFilterActive] = useState("");
    const [filterOrSortType, setFilterOrSortType] = useState("sort");

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }

    const getProducts = (page) => {
        const APIResult =
            validateNUll(query.get("sort")) === "special"
                ? getSpecialProductsAPI()
                : getProductsAPI({
                      query: validateNUll(query.get("search")),
                      sort: validateNUll(query.get("sort")),
                      category: validateNUll(query.get("category")),
                      page: page,
                  });
        APIResult.then((response) => {
            var currProducts = cloneDeep(products);
            if (
                validateNUll(query.get("sort")) === "most_viewed" ||
                validateNUll(query.get("sort")) === "special"
            ) {
                response.data.data.forEach((element) => {
                    currProducts.push(element);
                });
            } else {
                response.data.data.data.forEach((element) => {
                    currProducts.push(element);
                });
            }

            localStorage.setItem("products_loadmore", "true");

            if (response.data.data.next_page_url === null) {
                setCanLoadMore(false);
                localStorage.setItem("products_loadmore", "false");
            }

            setProducts(currProducts);
            setLoading(false);
            setLazyLoading(false);
        });
    };

    const getParents = async (catId) => {
        try {
            const response = await getCateogryParentAPI(
                { parent_id: catId },
                user_token
            );

            if (response.status >= 200 && response.status < 300) {
                let checkingCategory = response.data;
                const cats = [checkingCategory];
                while (
                    checkingCategory[0].Parent &&
                    checkingCategory[0].Parent !== null
                ) {
                    cats.unshift(checkingCategory[0].Parent);
                    checkingCategory = checkingCategory[0].Parent;
                }
                setCategories(cats);
            }
        } catch (error) {}
    };

    const validateNUll = (prop) => {
        if (prop === null || prop === undefined) return "";
        else return prop;
    };

    const trackScrolling = () => {
        const wrappedElement = document.getElementById("product-full");
        if (isBottom(wrappedElement) && !lazyLoading && canLoadMore) {
            setLazyLoading(true);
            localStorage.setItem("products_page", String(currPage));
            setCurrPage(currPage + 1);
            getProducts(currPage + 1);
            document.removeEventListener("scroll", trackScrolling);
        }
    };

    const isBottom = (el) => {
        return el.getBoundingClientRect().bottom <= window.innerHeight + 50;
    };

    const handleFilterClick = () => {
        setFilterActive("active");
        setFilterOrSortType("filter");
    };

    const handleSortClick = () => {
        setFilterActive("active");
        setFilterOrSortType("sort");
    };

    useEffect(() => {
        document.addEventListener("scroll", trackScrolling);
        return () => {
            document.removeEventListener("scroll", trackScrolling);
        };
    });

    useEffect(() => {
        getProducts(1);
        if (history.action === "POP") {
            setCanLoadMore(Boolean(localStorage.getItem("products_loadmore")));
        } else {
            const catId = query.get("category");
            if (validateNUll(catId) !== "") {
                getParents(catId);
            }
        }

        history.replace(location.pathname + location.search);
    }, [location.search]);

    useEffect(() => {}, []);

    return (
        <Fade>
            <div
                className={`product-full ${active && "active"}`}
                id="product-full"
            >
                <ShopHeader
                    createLink=""
                    type="productList"
                    isLoading={loading}
                    showBack={true}
                />
                <Spinner active={loading} color="dark" />
                <div
                    className="product-full__filter"
                    tabIndex={1}
                    onBlur={() => setFilterActive("")}
                >
                    {/* <div
              className="product-full__filter__item"
              onClick={() => handleFilterClick()}
            >
              <div className="product-full__filter__item__icon">
                <SVG svg="outline-filter" />
              </div>
              فیلتر
            </div> */}
                    <div
                        className="product-full__filter__item"
                        onClick={() => handleSortClick()}
                    >
                        <div className="product-full__filter__item__icon">
                            <SVG svg="order-line" />
                        </div>
                        مرتب سازی
                    </div>
                    <Filter
                        active={filterActive}
                        setClose={(close) => setFilterActive(close)}
                        type={filterOrSortType}
                    />
                </div>

                <div className="product-full__wrapper">
                    {categories.length > 0 && (
                        <div className="product-full__categories">
                            {categories.map((category, index) => (
                                <div
                                    className="product-full__category"
                                    key={index}
                                >
                                    {category[0].title}
                                    {index !== categories.length - 1 && (
                                        <div className="product-full__category__icon">
                                            <SVG svg="arrow" />
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                    )}

                    <div className="product-full__list" id="product-full-list">
                        {products.length > 0 &&
                            products.map((product, index) => (
                                <React.Fragment>
                                    <WrapperCard>
                                        <Link
                                            className="product-list-item__detail"
                                            to={`/product/${product.id}`}
                                        >
                                            {/* {user?.detail?.star !== 7 && (
                                                <div className="product-list-item__tag">
                                                    قیمت برای شما
                                                    <SVG svg="arrow" />
                                                </div>
                                            )} */}
                                            <div className="product-list-item__image">
                                                <img
                                                    loading="lazy"
                                                    id={index}
                                                    src={
                                                        product.image &&
                                                        product.image[0]
                                                            ? `${product.image[0]}`
                                                            : ProductImage
                                                    }
                                                    // src={product.image && product.image[0] ? `data:image/png;base64,${product.image[0]}` : ProductImage}
                                                    alt=""
                                                    onError={(e) => {
                                                        const image =
                                                            document.getElementById(
                                                                index
                                                            ) as HTMLImageElement;
                                                        image.src =
                                                            ProductImage;
                                                    }}
                                                />
                                            </div>
                                            <div className="product-list-item__info">
                                                <div className="product-list-item__top">
                                                    <div className="product-list-item__title">
                                                        {product.title}
                                                    </div>
                                                </div>
                                                <div className="product-list-item__bottom">
                                                    {/* <div className="product-list-item__rate-wrapper">
                            {showRate(product.stars)}
                          </div> */}
                                                    <div className="product-item__buy discount">
                                                        <div className="product-item__buy__price">
                                                            <Lowprice />
                                                            <div
                                                                style={{
                                                                    padding:
                                                                        "0rem 0.5rem",
                                                                }}
                                                                className="product-item__buy__price__discount"
                                                            >
                                                                {` ${transformPrice(
                                                                    Number(
                                                                        product
                                                                            .defaultPrice[1]
                                                                    )
                                                                )}`}
                                                                <div className="product-item__buy__price__discount__toman">
                                                                    تومان
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row a-c">
                                                        <div
                                                            style={{
                                                                justifyContent:
                                                                    "flex-end",
                                                                padding:
                                                                    "0rem 0.5rem",
                                                            }}
                                                            className="product-item__buy__price j-sb"
                                                        >
                                                            <div className="product-item__buy__price__discount a-c">
                                                                {transformPrice(
                                                                    Number(
                                                                        product
                                                                            .defaultPrice[4]
                                                                    )
                                                                )}
                                                            </div>
                                                            <div className="product-item__buy__title">
                                                                تومان
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {product.productColor.length >
                                                    0 && (
                                                    <div className="product-list-item__colors">
                                                        {product.productColor.map(
                                                            (
                                                                productColor,
                                                                productColorIndex
                                                            ) =>
                                                                Number(
                                                                    productColor.id
                                                                ) !== 1 &&
                                                                productColorIndex <=
                                                                    3 && (
                                                                    <div
                                                                        style={{
                                                                            backgroundColor: `#${productColor.hex_code}`,
                                                                        }}
                                                                        key={
                                                                            productColorIndex
                                                                        }
                                                                        className="product-list-item__colors__item"
                                                                    />
                                                                )
                                                            // : (
                                                            //   '...'
                                                            // )
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                        </Link>
                                    </WrapperCard>
                                </React.Fragment>
                            ))}
                        {lazyLoading && <Dots />}
                    </div>
                </div>
            </div>
        </Fade>
    );
};
