import { Request } from "../api-call";

export const adsDetailAPI = async (query) => {
  const { data } = await Request.get(
    `/api/v2/advertise/kartella/ads/list?cat=${
      query.cat == null ? "" : query.cat
    }&rate=${query.rate}&type=${query.type}&page=${query.page}`
  );
  return data;
};
