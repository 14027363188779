import React, { useEffect, useState } from "react";
import {
  PageTopbar,
  SVG,
  Fade,
  Card,
  Dots,
} from "components";
import { useHistory } from "react-router-dom";
import {
  Tabs,
  Collection,
  Pending,
  Description,
  Summery,
  Progress,
} from "./dependencies";
import { GetMyOrders } from "utils/Services";

export const OrdersContainer = () => {
  const history = useHistory();
  const [activeTab, setActiveTab] = useState(1);
  const [loading, setLoading] = useState(true);
  const [carts, setCarts] = useState([]);
  const [unpaid, setUnpaid] = useState([]);
  const [activeCart, setActiveCart] = useState({
    items: [],
  });

  const getMyOrdersData = (type) => {
    setLoading(true);
    GetMyOrders(type).then((res) => {
      if (type === "unpaid") {
        setUnpaid(res.data.cart);
        // setLoading(false);
      } else {
        setCarts(res.data.cart);
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    getMyOrdersData("paid");
    history.replace("/profile/orders");
  }, []);

  useEffect(() => {
    if (activeTab === 1) {
      getMyOrdersData("paid");
      setLoading(true);
    } else {
      // getMyOrdersData("deActive");
      setLoading(true);
    }
  }, [activeTab]);

  return (
    <Fade>
      <div className={`orders ${activeCart.items.length === 0 ? "ios" : ""} `}>
        {activeCart.items.length === 0 ? (
          <React.Fragment>
            <PageTopbar title="تاریخچه سفارشات" showKv={false} />
            <Card>
              {unpaid?.map((unpaidItem) => (
                <Pending cart={unpaidItem} />
              ))}

              {/* <Tabs
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                items={[
                  {
                    title: "سفارشات من",
                    icon: 'orders-accepted'
                  },
                  {
                    title: "لغو شده‌ها",
                    icon: 'orders-rejected'
                  }
                ]}
              /> */}

              {loading ? <Dots /> :
              carts.length == 0 && !loading ? (
                <div className="orders__not-found">
                  <div className="orders__not-found__logo">
                    <SVG svg="profile-shop" />
                  </div>
                  <span className="orders__not-found__title">
                    محتوایی در تاریخچه سفارشات وجود ندارد
                  </span>
                </div>
              ) : (
                carts?.map((cartItem, index) => (
                  // console.log(cartItem)
                
                  <Collection
                    cart={cartItem}
                    onClick={(cart) => setActiveCart(cart)}
                    index={index}
                    getCarts={getMyOrdersData}
                  />
                ))
              )}

              {/* {loading && <Dots />} */}
            </Card>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Progress
              cart={activeCart}
              close={() => setActiveCart({ items: [] })}
            />
            <Description cart={activeCart} />
            <Summery cart={activeCart} />
          </React.Fragment>
        )}
      </div>
    </Fade>
  );
};
