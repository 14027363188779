import {Spinner, SVG} from "components";
import logo from "../../assets/images/certificate-logo.png";
import {useHistory, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {getParticipationUnitAPI} from "../../utils/Services/KartlandUnits";
import {useUser} from "../../hooks";
import moment from "jalali-moment";

export const ParticipationCertificateContainer = () => {
  const history = useHistory();
  const {id} = useParams();
  const {
    userState: {user},
  } = useUser();
  const [allBlocks, setAllBlocks] = useState([]);
  const [unitDetails, setUnitDetails] = useState();
  const [unitDetailsLoading, setUnitDetailsLoading] = useState(true);

  const getMyUnitInfo = () => {
    setUnitDetailsLoading(true);
    getParticipationUnitAPI().then((res) => {
      if (res.status === 200) {
        setAllBlocks(res.data);
        res.data.forEach((element) => {
          if (element.id === Number(id)) {
            setUnitDetails(element);
          }
        });
        setUnitDetailsLoading(false);
      }
    });
  };

  const owner = [
    {title: "نام و نام خانوادگی :", value: user?.detail?.fullName},
    {title: "شماره تلفن :", value: user?.mobile_number},
  ];

  const handleShowBlockType = (blockType) => {
    switch (blockType) {
      case 25:
      case 100:
        return "25 تایی";

      case 50:
      case 200:
        return "50 تایی";

      default:
        break;
    }
  };

  useEffect(() => {
    getMyUnitInfo();
  }, []);

  return (
    <div className="certificate">
      <div
        className={`page-topbar__back`}
        style={{zIndex: 6, top: '7px'}}
        onClick={() => history.goBack()}
      >
        <SVG svg="arrow-big"/>
      </div>
      <Spinner active={unitDetailsLoading} color="dark"/>
      {unitDetailsLoading === false && (
        <div className="certificate__blur">
          <div className="certificate__frame">
            <div className="certificate__subframe">
              <div className="certificate__body">
                <SVG svg="certificate-logo"/>
                <span className="certificate__body__title">
                  گواهی مشارکت در فروش واحدهای کارتلند
                </span>
                <div className="certificate__separator"></div>
                <div className="certificate__body__info">
                  <strong>مشخصات بلوک مشارکتی</strong>
                  <div className="certificate__separator"/>
                  {/* <div
                    className={`row a-c j-sb w-full px-16 mb-10`}
                  >
                    <span className="certificate__title">شماره پلاک:</span>
                    <span className="certificate__value">{unitDetails.unitnumber}</span>
                  </div> */}
                  <div className={`row a-c j-sb w-full px-16 mb-10`}>
                    <span className="certificate__title">نوع بلوک فورشاپ</span>
                    <span className="certificate__value">
                      {handleShowBlockType(unitDetails.blockType)}
                    </span>
                  </div>

                  <div className={`row a-c j-sb w-full px-16 mb-10`}>
                    <span className="certificate__title">
                      تعداد بلوک فورشاپ
                    </span>
                    <span className="certificate__value">
                      {allBlocks.length}
                    </span>
                  </div>

                  <div className={`row a-c j-sb w-full px-16 mb-10`}>
                    <span className="certificate__title">مجموع فورشاپ‌ها</span>
                    <span className="certificate__value">
                      {allBlocks.length * 50} عدد
                    </span>
                  </div>
                  {/* <div
                    className={`row a-c j-sb w-full px-16 mb-10`}
                  >
                    <span className="certificate__title">استان:</span>
                    <span className="certificate__value">{unitDetails.statename}</span>
                  </div>
                  <div
                    className={`row a-c j-sb w-full px-16 mb-10`}
                  >
                    <span className="certificate__title">شهرستان:</span>
                    <span className="certificate__value">{unitDetails.cityname}</span>
                  </div> */}
                  {/* <div
                    className={`row a-c j-sb w-full px-16 mb-10`}
                  >
                    <span className="certificate__title">دریافت ماهانه:</span>
                    <span className="certificate__value">25 KM سفید</span>
                  </div> */}
                </div>
                <div className="certificate__body__owner">
                  <strong>مشخصات مالک</strong>
                  <div className="certificate__separator"/>
                  {owner.map((item, index) => (
                    <div
                      className={`row a-c j-sb w-full px-16 ${
                        index + 1 !== owner.length && "mb-10"
                      }`}
                    >
                      <span className="certificate__title">{item.title}</span>
                      <span className="certificate__value">{item.value}</span>
                    </div>
                  ))}
                </div>
                <div className="certificate__body__logo">
                  <img src={logo}/>
                </div>
              </div>
              <div className="certificate__date">
                <span>تاریخ خرید:</span>
                <span>
                  {moment(unitDetails.created_at, "YYYY/MM/DD HH:mm:ss")
                    .locale("fa")
                    .format("YYYY/MM/DD")}
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
