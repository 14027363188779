import { useState, useEffect } from "react";
import { MenuButton, KV, CircleHint } from "components";
import "./ShopHeader.scss";
import { SVG } from "components";
import { ProductSearch } from "components/ProductSearch";
import { CategoriesMenu } from "components/Menu";
import { localeData } from "jalali-moment";
import { Search } from "components/Search";
import { Filter } from "components";
import { CartButton } from "components/CartButton";
import { useHistory } from "react-router";
import { handleInputBlur, handleInputFocus } from "utils/Functions";
import { useUser } from "hooks";
import { getHintAPI } from "utils";
import { Link } from "react-router-dom";

export const ShopHeader = ({
    isFilter = false,
    showBack = false,
    homeCategories = [],
    categories = [],
    type,
    isLoading = null,
    createLink,
    marketName = null,
}) => {
    const [searchValue, setSearchValue] = useState("");
    const [searchCompActive, setSearchCompActive] = useState<any>(false);
    const [hidenTopBar, sethidenTopBar] = useState(false);
    const [isTop, setIsTop] = useState(false);
    const [hintText, setHintText] = useState("");
    var lastScrollTop = 0;
    const history = useHistory();
    const {
        userState: { user },
    } = useUser();

    const updateSearchValue = (value) => {
        setSearchValue(value);
        if (value.length > 0 && !searchCompActive) {
            setSearchCompActive(true);
        }
    };

    const handleScroll = () => {
        // var st = window.pageYOffset;
        // if (st <= lastScrollTop) {
        //   sethidenTopBar(false);
        // } else {
        //   sethidenTopBar(true);
        // }
        // lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
        // const topBarElm = document.getElementById("shopheader");
        // if (topBarElm.offsetTop > 0 && !isTop) {
        //   // sethidenTopBar(false);
        //   setIsTop(true);
        // }
        //  else if (topBarElm.offsetTop === 0) {
        //   setIsTop(false);
        // }
    };

    useEffect(() => {
        getHintAPI("راهنمای خرید از پاساژ")
            .then((response) => {
                setHintText(response.content);
            })
            .catch((error) => {
                console.log(error);
                setHintText("دریافت راهنما با خطا مواجه شد");
            });
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    return (
        <div
            style={{ display: "flex", flexDirection: "column" }}
            className={`shopheader ${hidenTopBar && "hidden"} ${
                isTop && "scrolling"
            }  `}
            id="shopheader"
        >
            <div style={{ width: "100%" }} className="flex">
                {showBack ? (
                    <div
                        className="shopheader__back"
                        onClick={() => history.goBack()}
                    >
                        <SVG svg="arrow" />
                    </div>
                ) : (
                    <CartButton />

                    // <MenuButton onClick={() => history.push('/search')} />
                )}
                <div
                    className={`shopheader__input ${
                        searchValue.length !== 0 && "active"
                    }`}
                >
                    <div
                        className="shopheader__input__icon"
                        onClick={() =>
                            searchValue.length !== 0 && setSearchValue("")
                        }
                    >
                        <SVG
                            svg={
                                searchValue.length === 0
                                    ? "input-search"
                                    : "times"
                            }
                        />
                    </div>
                    <input
                        placeholder={"جستجو"}
                        value={searchValue}
                        onChange={(e) => updateSearchValue(e.target.value)}
                        // onFocus={() => handleInputFocus()}
                        // onBlur={() => handleInputBlur()}
                    />
                    {/* <Search /> */}
                </div>

                <div
                    className={`shopheader__input__icon ${
                        user.operator === 1 && "shopheader__order-list"
                    } `}
                    onClick={() => {
                        if (user.operator === 1) {
                            history.push("/operator-management");
                        }
                    }}
                >
                    {!isLoading.categories && user.operator === 1 ? (
                        <SVG svg="operator" />
                    ) : (
                        <div>
                            <CircleHint hintText={hintText} />
                        </div>
                    )}
                </div>

                {/* <KV number={14} /> */}
                <Search
                    value={searchValue}
                    topPosition={true}
                    setValue={(value) => setSearchValue(value)}
                    setFilter={() => {}}
                    filter={false}
                />
            </div>
            {!isLoading.specialProducts && (
                <div
                    style={{
                        padding: "1rem 0rem 0rem 0rem",
                        fontSize: "16px",
                        fontWeight: 700,
                        display: "flex",
                        flexDirection: "row",
                    }}
                    className="home-product-listheader__title"
                >
                    شعبه اینترنتی :
                    {marketName && (
                        <div
                            style={{
                                display: "inline-block",
                                marginRight: "5px",
                                color: "#db2c2c",
                            }}
                        >
                            {`${marketName}`}
                        </div>
                    )}
                </div>
            )}
            {type === "shopList" && (
                <div className="home__categories">
                    {/* <div className="home__categories__header"> */}
                    {/* <div className="home__categories__title">
                        دسته‌بندی محصولات
                    </div> */}
                    {/* <Link to="/search" className="home__categories__link">
                        مشاهده همه
                    </Link> */}
                    {/* </div> */}
                    <div className="home__categories__container">
                        {homeCategories.map((category, index) => (
                            <Link
                                to={`/category?${category.id}`}
                                className="home__categories__item"
                                key={index}
                            >
                                <div className="home__categories__item__title">
                                    {category.title}
                                </div>
                            </Link>
                        ))}
                    </div>
                </div>
            )}

            {type === "categoryList" && (
                <div className="cat-product__body">
                    <div
                        style={{ paddingTop: 0 }}
                        className="cat-product__categories-list"
                    >
                        {categories[0] && (
                            <div
                                className="cat-product__category"
                                onClick={() => {
                                    history.goBack();
                                }}
                            >
                                <div className="cat-product__category__icon">
                                    <SVG svg="arrow-round" />
                                </div>
                                <div
                                    style={{
                                        justifyContent: "center",
                                        alignItems: "center",
                                        textAlign: "center",
                                    }}
                                >
                                    {categories[0].title}
                                </div>
                            </div>
                        )}

                        {categories.length > 0 &&
                            categories
                                .slice(1, categories.length)
                                .map((category, index) => (
                                    <Link
                                        style={{
                                            justifyContent: "center",
                                            alignItems: "center",
                                            textAlign: "center",
                                        }}
                                        className="cat-product__category"
                                        to={`/category?${createLink()},${
                                            category.id
                                        }`}
                                        key={index}
                                    >
                                        <div
                                            style={{ minWidth: "fit-content" }}
                                        >
                                            {category.title}
                                        </div>
                                    </Link>
                                ))}
                    </div>
                </div>
            )}

            {/* <ProductSearch
        active={searchCompActive}
        setClose={(state) => setSearchCompActive(state)}
        value={searchValue}
      /> */}
            {/* <CategoriesMenu
        active={activeMenu}
        setClose={(close) => setActiveMenu(close)}
      /> */}
        </div>
    );
};
