import { SVG, Input, Dots, Fade } from "components";
import { useState } from "react";
import toast from "react-hot-toast";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { farsiDigitToEnglish } from "utils/Functions";
import { TransferBlueKmAPI } from "utils/Services/Km/Blue/TransferToMobile";
import { Counter } from "../../dependencies";

export const Phone = () => {
  const history = useHistory();
  const [phoneNumber, setPhoneNumber] = useState('');
  const [transferLoading, setTransferLoading] = useState(false);
  const location = useLocation();
  const query = useQuery();

  function useQuery() {
    return new URLSearchParams(location.search);
  }

  const handleSubmitTransfer = () => {
    setTransferLoading(true);
    TransferBlueKmAPI(phoneNumber, query.get('amount'))
      .then(response => {
        if (response.status === 200) {
          toast.success('اهدای KP با موفقیت انجام شد.');
          history.goBack();
          history.goBack();
        }
      })
      .catch(err => {
        if (err.response.status === 401) {
          toast.error('برای اهدای KP فروشگاه شما باید فعال باید');
        } else toast.error('خطا در اهدای KP');
      })
      .finally(() => { setTransferLoading(false); })
  }

  return (
    <Fade>
      <Counter amount={query.get('amount')} />
      <div className="title">شماره تلفن یا کد کارتک</div>
      <Input
        value={phoneNumber}
        placeholder="شماره تلفن یا کد کارتک را وارد کنید"
        onChange={value => setPhoneNumber(farsiDigitToEnglish(value))}
      />
      <div className="km-donate__actions">
        <div className="km-donate__actions__qr" onClick={() => !transferLoading && phoneNumber.length !== 0 && handleSubmitTransfer()}>
          {transferLoading ? (
            <Dots color="light" />
          ) : (
            <>
              <span>انتقال</span>
              <SVG svg="km-donate" />
            </>
          )}
        </div>
      </div>
    </Fade>
  );
};
