import { SVG } from "components/SVG";
import "./TabBar.scss";
import { useState, useEffect } from 'react';

const showTabContent = (contentId) => {
  console.log(contentId);
}

export const TabBarItem = ({children, itemId}) => {
  return (
    <div className={`tabBar__content`}>
      {children}
    </div>
  )
}

export const TabBar = ({tabs, children}) => {
  
  const [openTabStatus, setOpenTabStatus] = useState(true);
  const [currentItemId, setCurrentItemId] = useState("");

  useEffect(() => {
    setOpenTabStatus(!openTabStatus);
  }, [currentItemId])
  return (
    <div className={`tabBar`}>
      <div className={`tabBar__header row ${openTabStatus ? "tabBar__header__active" : ""}`}>
        {
          tabs && tabs.map(tab => (
            <div
              className = "tabBar__header__item grow row j-c a-c"
              style = {{backgroundColor: tab?.bgColor, color: tab?.txtColor}}
              onClick = {() => {
                !openTabStatus ? setCurrentItemId(tab.id) : setCurrentItemId("")
              }}
            >
              <span>{tab?.name}</span>
              <span className={`${openTabStatus && currentItemId == tab.id ? "tabBar__header__item__rotate-svg" : ""}`}><SVG svg="arrow" /></span>
            </div>
          ))
        }
        
      </div>
      <div className={`tabBar__body ${openTabStatus && "tabBar__body__active"}`} style={!openTabStatus ? {border: 0} : {}}>
        {
          openTabStatus &&
          children.map((item, index) => (
            currentItemId == item.props.itemId &&
            <div className="tabBar__body__item" style = {{backgroundColor: tabs[index]?.bgColor}} id={item.props.itemId}>{item}</div>
          ))
        }
      </div>
    </div>
  );
};

//use :
{/*
const profileHeaderTabs = [
  {
    id: "item1",
    name: "پروفایل",
    bgColor: "#21bbe1",
    txtColor: "white"
  },
  {
    id: "item2",
    name: "بانک",
    bgColor: "#fd6547",
    txtColor: "white"
  }
]

<TabBar tabs={profileHeaderTabs} >
  <TabBarItem itemId={profileHeaderTabs[0].id}>
  <TabBarItem itemId={profileHeaderTabs[1].id}>
</TabBar>
*/}