import {useEffect, useState} from "react";
import {Padding, SVG, Modal, Dots} from "components";
import {useHistory} from "react-router-dom";
import moment from "jalali-moment";
import toast from "react-hot-toast";
import {transformPrice} from "utils/Functions";
import {checkingOrderAPI, finishOrderAPI} from "../../../../utils";

export const Collection = ({cart, onClick, index, getCarts}) => {
  
  
  const history = useHistory();
  const [modalStatus, setModalStatus] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentCartDetailsId, setCurrentCartDetailsId] = useState(-1);
  const [currentCartForModal, setCurrentCartForModal] = useState<any>();

  const finishOrder = () => {
    if(currentCartDetailsId === -1) return;
    setLoading(true);
    finishOrderAPI(currentCartDetailsId).then(res => {
      if (res.status === 200 && res.data == true) {
        toast.success('سفارش تحویل داده شده و KP شما واریز گردید');
      } else {
        toast.error("در حال حاضر امکان دریافت kp این سفارش فراهم نمی‌باشد.")
      }
    }).catch(err => {
      toast.error('خطایی رخ داد');
    }).finally(() => {
      setLoading(false);
      setModalStatus(false)
      getCarts('paid');
      setCurrentCartDetailsId(-1);
    });
  };

  const checkingOrder = () => {
    if(currentCartDetailsId === -1) return;
    setLoading(true);
    checkingOrderAPI(currentCartDetailsId).then(res => {
      if (res.status === 200) {
        toast.success('سفارش شما برای بررسی و پیگیری به اپراتور ارسال شد.');
      }
    }).catch(err => {
      toast.error('خطایی رخ داد');
    }).finally(() => {
      setLoading(false);
      setModalStatus(false)
      getCarts('paid');
      setCurrentCartDetailsId(-1);
    });
  }

  return (
    <>
      <div className="orders__collection">
        <div className="orders__collection__header">
          <div className="orders__collection__header__title">
            {/* <span>({cart.detail.length} فروشگاه)</span> */}
            <span>سفارش {cart.orderId}</span>
          </div>
          <div className="orders__collection__header__trackcode">
            <span>{moment(cart?.payment?.time, "YYYY/MM/DD h:mm:ss").locale("fa").format("H:mm - YYYY/MM/DD")}</span>
          </div>
        </div>
        <div className="orders__collection__body">
          <div className="orders__collection__body__thumbnails">
            {false && cart.items.map(
              (item, index) =>
                index < 3 && (
                  <div
                    className={`orders__collection__body__thumbnails__circle item-${
                      index + 1
                    }`}
                  >
                    <img
                      loading="lazy"
                      src={`${item.detail.Product?.image[0]}`}
                      // src={`data:image/png;base64,${item.detail.Product.image[0]}`}
                      alt=""
                    />
                  </div>
                )
            )}
          </div>
        </div>

        <div className="orders__collection__list-item">
          {
            cart?.detail?.map((customeCart, cartIndex) => (
              <div className="orders__collection__list-item__base-cart">
                {
                  customeCart?.items?.map((customeItem, itemIndex) =>(
                    <>
                      {
                        itemIndex === 0 && <div className="orders__collection__list-item__base-cart__seller">
                          <span>فروشگاه : {customeCart?.seller}</span>
                          <div className="flex j-c a-c">
                            <div
                              onClick={() =>
                                !customeCart.recievedKp
                                  ? (
                                    setModalStatus(true),
                                    setCurrentCartDetailsId(customeCart.id),
                                    setCurrentCartForModal(customeCart)
                                  )
                                  : toast.error("شما قبلا kv این سفارش را آزاد کرده‌اید.")
                              }
                              className={`orders__collection__header__assign ${
                                customeCart.recievedKp && "deactive"
                              }`}
                            >
                              دریافت KP
                            </div>
                            <span onClick={() => {
                              history.push(`/order-details/${customeCart?.id}`)
                            }}>جزییات</span>
                          </div>
                        </div>
                      }
                      <div style={{display: "inline-block"}}>
                        <img src={customeItem?.detail?.Product?.image[0]} alt="" onClick={() => {
                          history.push(`/product/${customeItem?.detail?.Product?.id}`);
                        }}/>
                      </div>
                    </>
                  ))
                }
              </div>
            ))
          }
        </div>

        {/* <div
          onClick={() =>
            !cart.recievedKp
              ? setModalStatus(true)
              : toast.error("شما قبلا kv این سفارش را آزاد کرده‌اید.")
          }
          className={`orders__collection__header__assign ${
            cart.recievedKp && "deactive"
          }`}
        >
          دریافت KP
        </div> */}

        <div className="orders__collection__footer">
          <div className="orders__collection__footer__shape">
            <SVG svg="bottom-shape" color="#21bbe1"/>
          </div>
          
          {/* <div
            className="orders__collection__footer__btn"
            onClick={() => onClick(cart)}
          >
            <span>پیگیری سفارش</span>
            <SVG svg="track"/>
          </div> */}

          <div className="orders__collection__footer__bio">
            {cart.payment.amount_paid !== undefined ? (
              <span>
                قیمت کل : {transformPrice(cart.payment.amount_paid)} تومان
              </span>
            ) : (
              <span>قیمت کل: -</span>
            )}
          </div>
        </div>
      </div>
      
      <Modal active={modalStatus} close={(close) => {
          setModalStatus(!close);
          setCurrentCartDetailsId(-1);
      }}>
        <div className="kv-assign__modal">
          {currentCartForModal?.status === "در حال آماده سازی" ? (
            <span className="kv-assign__modal__title not-receved">
              سفارش شما هنوز ارسال نشده، لطفا پس از ارسال، برای آزادسازی KP اقدام کنید.
            </span>
          ) : (
            <>
              <span className="kv-assign__modal__title">
                تائید شما به منزله تحویل خرید شماست
              </span>
              <div className="kv-assign__modal__footer">
                <div
                  onClick={() => finishOrder()}
                  className="kv-assign__modal__footer__submit"
                >
                  {loading ? <Dots color={'light'}/> :<span>تحویل گرفته ام</span>}
                </div>
                <div
                  onClick={() => {
                    if(currentCartForModal.status === "در حال بررسی") {
                      toast.error("سفارش شما قبلا برای بررسی ارسال شده است.");
                    } else {
                      checkingOrder();
                    }
                  }}
                  className="kv-assign__modal__footer__reject"
                >
                  {loading ? <Dots color={'light'}/> :<span>تحویل نگرفته ام</span>}
                </div>
                
              </div>

              <div
                  onClick={() => {
                    setModalStatus(false);
                    setCurrentCartDetailsId(-1);
                  }}
                  className="kv-assign__modal__footer__edit"
                >
                  <span>انصراف</span>
                </div>
            </>
          )}
        </div>
      </Modal>
    </>
  );
};
