import { Request } from "../api-call";

export const getMyDirectCitizenAPI = async () => {
  const { data } = await Request.get(`api/v3/kartland/unit/myDirect`);
  return data;
};

export const getMyDirectCitizenInfoAPI = async (id: number) => {
  const { data } = await Request.get(`api/v3/kartland/unit/myDirect/${id}`);
  return data;
};