import "./ImageCropper.scss";

import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";

import ReactCrop, {Crop} from "react-image-crop";
import 'react-image-crop/src/ReactCrop.scss';


import {useEffect, useState, useRef} from "react";
import {Card, Dots, SVG} from "components";
import {imgPreview} from "./Dependecies/imgPreview";

export const ImageCropper = ({
                               active = false,
                               onClose,
                               onConfirm,
                               aspect = undefined,
                             }) => {
  const [image, setImage] = useState("");
  const [cropper, setCropper] = useState();
  const [loading, setLoading] = useState(false);
  const [showCropper, setShowCropper] = useState(false);
  const [crop, setCrop] = useState<any>({
    unit: '%', // Can be 'px' or '%'
    x: 25,
    y: 25,
    width: 50,
    height: 50
  });
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);
  const imgRef = useRef<HTMLImageElement>(null);

  const getCropData = () => {
    setLoading(true);
    if (imgRef !== null) {
      // cropper.getCroppedCanvas().toBlob(blob => {
      //   onConfirm({ file: blob, url: cropper.getCroppedCanvas().toDataURL() })
      // });
      imgPreview(imgRef.current, crop).then(res => {
        onConfirm(res);
      });
      // onConfirm(cropper);
    }
  };

  const onSelectFile = (e) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }

    const reader = new FileReader();
    reader.onload = () => {
      // @ts-ignore
      setImage(reader.result);
    };
    reader.readAsDataURL(files[0]);
    setShowCropper(true);
  };

  const handleEdit = () => {
    setImage("");
    // setCropper();
    setShowCropper(false);
    setLoading(false);
  };

  useEffect(() => {
    setLoading(false);
  }, [active]);

  // function onImageLoad(e) {
  //   if (aspect) {
  //     const { width, height } = e.currentTarget
  //     setCrop((width, height, aspect))
  //   }
  // }
  // console.log(crop);
  return (
    <div className={`image-editor ${active && "active"}`}>
      <div className="image-editor__header">
        انتخاب و ویرایش تصویر
        <div
          className="image-editor__header__close"
          onClick={() => {
            onClose(true);
          }}
        >
          <SVG svg="times"/>
        </div>
      </div>
      <Card>
        {image.length === 0 && (
          <div className="create-ads__final__image">
            <input
              type="file"
              accept="image/*"
              onChange={onSelectFile}
              id="imagePicker"
            />
            <SVG svg="ads-image"/>
            بارگذاری تصویر
          </div>
        )}

        <div className={`image-editor__crop ${showCropper && "active"}`}>

          <ReactCrop
            // src={image}
            crop={crop}
            style={{height: "calc(100vh - 220px)"}}
            keepSelection={true}
            onChange={(newCrop) => setCrop(newCrop)}>

            <img
              ref={imgRef}
              alt="Crop me"
              src={image}
              // style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
              // onLoad={onImageLoad}
            />

          </ReactCrop>

          {/*<Cropper*/}
          {/*  src={image}*/}
          {/*  checkOrientation={false}*/}
          {/*  initialAspectRatio={aspect}*/}
          {/*  aspectRatio={aspect}*/}
          {/*  background={false}*/}
          {/*  autoCropArea={1}*/}
          {/*  preview=".img-preview"*/}
          {/*  viewMode={1}*/}
          {/*  minCropBoxHeight={100}*/}
          {/*  minCropBoxWidth={100}*/}
          {/*  guides={true}*/}
          {/*  rotate={30}*/}
          {/*  style={{height: "calc(100vh - 220px)", width: "100%"}}*/}
          {/*  dragMode="move"*/}
          {/*  onInitialized={(instance) => {*/}
          {/*    setCropper(instance);*/}
          {/*  }}*/}
          {/*/>*/}
        </div>
        <div className="row a-c">
          {showCropper == true && (
            <>
              <div
                className={`image-editor__confirm ${image.length === 0 && "deactive"
                }`}
                onClick={() => {
                  !loading && getCropData();
                }}
              >
                <div className="product__bottom__button__shape">
                  <SVG svg="bottom-shape"/>
                </div>
                <div className="product__bottom__button__shape reverse">
                  <SVG svg="bottom-shape"/>
                </div>
                {loading ? <Dots color="light"/> : "ثبت تصویر"}
              </div>
              <div onClick={() => handleEdit()} className="image-editor__edit">
                حذف
              </div>
            </>
          )}

          {/* <div
            className="image-editor__remove"
            onClick={() => {
              console.log(document.getElementById('imagePicker'));
            }}
          >
            ویرایش
          </div> */}
        </div>
      </Card>
    </div>
  );
};
