import React, {useEffect, useState} from "react";
import {
  PageTopbar,
  SVG,
  Selectbox,
  Modal,
  Card,
  Dots,
  Fade,
  NotFoundBox,
} from "components";
import img from "../../../../assets/images/kartellaMock.jpg";
import {
  getAllVitrinAPI,
  setVitrinItemAPI,
  deleteVitrinItemAPI,
  updateVitrinItemAPI,
  getTopVitrinsAPI,
  setTopVitrinAPI,
} from "../../../../utils";
import {toast} from "react-hot-toast";
import {ImageEditor} from "../../../../components/ImageEditor";
import imageCompression from "browser-image-compression";
import {useHistory, useLocation} from "react-router-dom";
import {Tabs} from "../../../OrdersContainer/dependencies";
import {currency, farsiDigitToEnglish} from "../../../../utils/Functions";

export const CreationShowCase = () => {
  const [modalStatus, toggleModalStatus] = useState(false);
  const [title, setTitle] = useState<any>("");
  const [price, setPrice] = useState<any>("");
  const [picture, setPicture] = useState<any>("");
  const [category, setCategory] = useState<any>("");
  const [showImageEditor, setShowImageEditor] = useState(false);
  const [adsImage, setAdsImage] = useState<any>({file: null, url: null});
  const [vitrinList, setVitrinList] = useState([]);
  const [topVitrins, setTopVitrins] = useState([]);
  const [compressLoading, setCompressLoading] = useState(0);
  const [isUpdating, setIsUpdating] = useState(false);
  const [deletingModal, setDeletingModal] = useState(false);
  const [updatingItem, setUpdatingItem] = useState(null);
  const [deletingItem, setDeletingItem] = useState(null);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [vitrinListLoading, setVitrinListLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(1);
  const [existingImage, setExistingImage] = useState<any>(null);
  const [rejectModal, setRejectModal] = useState<boolean>(false);
  const [titleRejectReason, setTitleRejectReason] = useState(null);
  const [picRejectReason, setPicRejectReason] = useState(null);
  const [catRejectReason, setCatRejectReason] = useState(null);
  const [waitingShow, setWaitingShow] = useState(false);
  const [rejectShow, setRejectShow] = useState(false);
  const history = useHistory();
  const location = useLocation();

  function useQuery() {
    const {search} = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  let query = useQuery();

  const compressOptions = {
    maxSizeMB: 0.7,
    maxWidthOrHeight: 1024,
    onProgress: (percentage) => {
      setCompressLoading(percentage);
    },
  };

  const resetValues = () => {
    setTitle("");
    setCategory("");
    setPrice("");
    setPicture("");
    setAdsImage({file: null, url: null});
    setExistingImage(null);
  };

  const setVitrinItem = () => {
    if (
      title === "" ||
      category === "" ||
      adsImage.file === null
    ) {
      toast.error("لطفا همه گزینه هارو کامل کنید");
      return;
    }
    setSubmitLoading(true);
    const compressedFile = imageCompression(
      adsImage.file,
      compressOptions
    ).then((compressed) => {
      var data = new FormData();
      data.append("picture", compressed);
      setVitrinItemAPI(title, data, category, !!price ? price : "0").then((res) => {
        if (res.status === 200) {
          toast.success("محصول با موفقیت اضافه شد.");
          toggleModalStatus(false);
          getVitrinList();
          getTopVitrins();
          setSubmitLoading(false);
          resetValues();
        }
      });
    });
  };

  const updateVitrinItem = (id) => {
    if (title === "" || category === "") {
      toast.error("لطفا همه گزینه هارو کامل کنید");
      return;
    }
    if (adsImage.file !== null) {
      setSubmitLoading(true);
      const compressedFile = imageCompression(
        adsImage.file,
        compressOptions
      ).then((compressed) => {
        var data = new FormData();
        data.append("picture", compressed);
        updateVitrinItemAPI(id, title, data, category, !!price ? price : "0").then((res) => {
          if (res.status === 200) {
            toast.success("محصول با موفقیت اضافه شد.");
            toggleModalStatus(false);
            setIsUpdating(false);
            getVitrinList();
            getTopVitrins();
            setUpdatingItem(null);
            setSubmitLoading(false);
            resetValues();
          }
        });
      });
    } else {
      setSubmitLoading(true);
      var data = new FormData();
      data.append("picture", existingImage);
      updateVitrinItemAPI(id, title, data, category, !!price ? price : "0").then((res) => {
        if (res.status === 200) {
          toast.success("محصول با موفقیت اضافه شد.");
          toggleModalStatus(false);
          setIsUpdating(false);
          getVitrinList();
          getTopVitrins();
          setUpdatingItem(null);
          setSubmitLoading(false);
          resetValues();
        }
      });
    }
  };

  const deleteVitrinItem = (id) => {
    setDeleteLoading(true);
    deleteVitrinItemAPI(id).then((res) => {
      if (res.status === 200) {
        toast.success("محصول با موفقیت حذف شد.");
        setDeleteLoading(false);
        setDeletingModal(false);
        getVitrinList();
        getTopVitrins();
      }
    });
  };

  const getVitrinList = () => {
    setVitrinListLoading(true);
    getAllVitrinAPI().then((res) => {
      if (res.status === 200) {
        setVitrinList(res.data);
        setVitrinListLoading(false);
      }
    });
  };

  const getTopVitrins = () => {
    setVitrinListLoading(true);
    getTopVitrinsAPI().then((res) => {
      if (res.status === 200) {
        setTopVitrins(res.data);
        setVitrinListLoading(false);
      }
    });
  };

  const setTopVitrin = (id) => {
    setTopVitrinAPI(id)
      .then((res) => {
        if (res.status === 200) {
          getTopVitrins();
          getVitrinList();
        }
      })
      .catch((err) => {
        toast.error("محصولات ویترین نمی‌تواند بیشتر از 10 عدد باشند");
      });
  };

  const getImage = (url) => {
    fetch(url)
      .then((res) => res.blob()) // Gets the response and returns it as a blob
      .then((blob) => {
        // Here's where you get access to the blob
        // And you can use it for whatever you want
        // Like calling ref().put(blob)
        setExistingImage(blob);
      });
  };

  useEffect(() => {
    getVitrinList();
    getTopVitrins();
  }, []);

  useEffect(() => {
    history.replace("/myshop/showcase" + location.search);
  }, []);

  return (
    <Fade>
      <PageTopbar title="ثبت ویترین" showKv={false}/>
      <ImageEditor
        active={showImageEditor}
        onClose={(close) => {
          setShowImageEditor(!close);
        }}
        onConfirm={(cropper) => {
          setShowImageEditor(false);
          setAdsImage((prev) => ({
            ...prev,
            url: cropper.getCroppedCanvas().toDataURL().substr(22),
          }));
          cropper.getCroppedCanvas().toBlob((blob) => {
            setAdsImage((prev) => ({
              ...prev,
              file: blob,
            }));
          });
          setPicture(false);
        }}
      />
      <Modal active={deletingModal} close={() => setDeletingModal(false)}>
        <div className={"deleting-modal"}>
          <div className={"deleting-modal__title"}>
            آیا از حذف محصول مطمئن هستید؟
          </div>
          <div className={"deleting-modal__actions"}>
            <div
              onClick={() =>
                deleteLoading === false && deleteVitrinItem(deletingItem)
              }
              className={"button red"}
            >
              {deleteLoading ? <Dots color={"light"}/> : <span>حذف</span>}
            </div>
            <div
              onClick={() => setDeletingModal(false)}
              className={"button disable"}
            >
              بازگشت
            </div>
          </div>
        </div>
      </Modal>

      <Modal active={rejectModal} close={() => setRejectModal(false)}>
        <div className={"reject-modal"}>
          {titleRejectReason && (
            <div className={"mb-10"}>
              <div className={"reject-modal__title mb-4"}>
                دلیل رد شدن نام محصول:
              </div>
              <div className={"reject-modal__reason"}>{titleRejectReason}</div>
            </div>
          )}
          {picRejectReason && (
            <div className={"mb-10"}>
              <div className={"reject-modal__title mb-4"}>
                دلیل رد شدن عکس محصول:
              </div>
              <div className={"reject-modal__reason"}>{picRejectReason}</div>
            </div>
          )}
          {catRejectReason && (
            <div className={"mb-10"}>
              <div className={"reject-modal__title mb-4"}>
                دلیل رد شدن دسته بندی محصول:
              </div>
              <div className={"reject-modal__reason"}>{catRejectReason}</div>
            </div>
          )}

          <div className={"reject-modal__actions"}>
            <div
              onClick={() => setRejectModal(false)}
              className={"button disable"}
            >
              بازگشت
            </div>
          </div>
        </div>
      </Modal>

      <div className="wrapper-fix">
        <Card>
          <div className="creation-showcase__title mycity">
            <span>محصولات</span>
          </div>
          <div
            onClick={() => toggleModalStatus(true)}
            className="creation-showcase__condition"
          >
            <span>افزودن محصول</span>
            <SVG svg="create-shop"/>
          </div>

          <Tabs
            activeTab={activeTab}
            setActiveTab={(tab) => setActiveTab(tab)}
            items={[
              {
                title: "همه محصولات",
                icon: "",
              },
              {
                title: "محصولات ویترین",
                icon: "",
              },
            ]}
          />

          {activeTab === 1 &&
            (!vitrinListLoading ? (
              vitrinList.map((item) => (
                <>
                  {
                    // item.activeByAdmin == 1 &&
                    item.category !== "" &&
                    item.title !== "" &&
                    item.photo !== false && (
                      <div className="creation-showcase__item">
                        <div
                          onClick={() => setTopVitrin(item.id)}
                          className={`creation-showcase__item__top ${
                            item.topTen === 1 && "active"
                          }`}
                        >
                          <SVG svg={"top-vitrin"}/>
                        </div>
                        <div className="creation-showcase__item__info">
                          <div className="creation-showcase__item__image">
                            <img loading="lazy" src={`${item.photo}`} alt=""/>
                          </div>

                          <div className="col j-sb">
                            <div className="creation-showcase__item__info__title">
                              {item.title}
                            </div>

                            {!!item.price && <div className="creation-showcase__item__info__description">
                              <span>{currency(item.price)} تومان</span>
                            </div>}
                          </div>
                        </div>

                        {query.get("edit") === "true" && (
                          <>
                            <div
                              onClick={() => {
                                setDeletingItem(item.id);
                                setDeletingModal(true);
                              }}
                              className="creation-showcase__item__delete"
                            >
                              <SVG svg="create-remove"/>
                            </div>
                            <div
                              onClick={() => {
                                setIsUpdating(true);
                                toggleModalStatus(true);
                                setUpdatingItem(item.id);
                                setTitle(item.title);
                                setPrice(item.price);
                                setCategory(item.category);
                                setPicture(item.photo);
                                getImage(item.photo);
                              }}
                              className="creation-showcase__item__edit"
                            >
                              <SVG svg="create-edit-product"/>
                            </div>
                          </>
                        )}
                      </div>
                    )}
                </>
              ))
            ) : (
              <Dots color={"dark"}/>
            ))}

          {waitingShow && query.get("edit") === "true" && activeTab === 1 && (
            <div className="title">محصولات درانتظار تایید</div>
          )}

          {query.get("edit") === "true" &&
            activeTab === 1 &&
            (!vitrinListLoading ? (
              vitrinList.map((item) => (
                <>
                  {(item.categoryStatus == 2 ||
                    item.pictureStatus == 2 ||
                    item.titleStatus == 2) && (
                    <div className="creation-showcase__item">
                      {/*<div onClick={() => setTopVitrin(item.id)}*/}
                      {/*     className={`creation-showcase__item__top ${item.topTen === 1 && 'active'}`}>*/}
                      {/*  <SVG svg={"top-vitrin"}/>*/}
                      {/*</div>*/}
                      {waitingShow === false && setWaitingShow(true)}
                      <div className="creation-showcase__item__info">
                        <div className="creation-showcase__item__image">
                          <img loading="lazy" src={`${item.photoedit}`} alt=""/>
                        </div>

                        <div className="col j-sb">
                          <div className="creation-showcase__item__info__title">
                            {item.titleEdit}
                          </div>

                          {!!item.price && <div className="creation-showcase__item__info__description">
                            <span>{currency(item.price)} تومان</span>
                          </div>}
                        </div>
                      </div>

                      {/*<div onClick={() => {*/}
                      {/*  setDeletingItem(item.id);*/}
                      {/*  setDeletingModal(true);*/}
                      {/*}} className="creation-showcase__item__delete">*/}
                      {/*  <SVG svg="create-remove"/>*/}
                      {/*</div>*/}
                      {/*<div onClick={() => {*/}
                      {/*  setIsUpdating(true);*/}
                      {/*  toggleModalStatus(true);*/}
                      {/*  setUpdatingItem(item.id);*/}
                      {/*  setTitle(item.title);*/}
                      {/*  setPrice(item.price);*/}
                      {/*  setCategory(item.category);*/}
                      {/*  setPicture(item.photo);*/}
                      {/*  getImage(item.photo);*/}
                      {/*}} className="creation-showcase__item__edit">*/}
                      {/*  <SVG svg="create-edit-product"/>*/}
                      {/*</div>*/}
                    </div>
                  )}
                </>
              ))
            ) : (
              <Dots color={"dark"}/>
            ))}

          {rejectShow && query.get("edit") === "true" && activeTab === 1 && (
            <div className="title">محصولات رد شده</div>
          )}

          {query.get("edit") === "true" &&
            activeTab === 1 &&
            (!vitrinListLoading ? (
              vitrinList.map((item) => (
                <>
                  {(item.categoryStatus == 3 ||
                    item.pictureStatus == 3 ||
                    item.titleStatus == 3) && (
                    <div className="creation-showcase__item">
                      {/*<div onClick={() => setTopVitrin(item.id)}*/}
                      {/*     className={`creation-showcase__item__top ${item.topTen === 1 && 'active'}`}>*/}
                      {/*  <SVG svg={"top-vitrin"}/>*/}
                      {/*</div>*/}
                      {rejectShow === false && setRejectShow(true)}
                      <div className="creation-showcase__item__info">
                        <div className="creation-showcase__item__image">
                          <img loading="lazy" src={`${item.photoedit}`} alt=""/>
                        </div>

                        <div className="col j-sb">
                          <div className="creation-showcase__item__info__title">
                            {item.titleEdit}
                          </div>

                          {!!item.price && <div className="creation-showcase__item__info__description">
                            <span>{currency(item.price)} تومان</span>
                          </div>}
                        </div>
                      </div>

                      <div
                        onClick={() => {
                          setTitleRejectReason(item.titleNotif);
                          setPicRejectReason(item.pictureNotif);
                          setCatRejectReason(item.categoryNotif);
                          setRejectModal(true);
                        }}
                        className="creation-showcase__item__delete"
                      >
                        <SVG svg="myshop-comments"/>
                      </div>
                      <div
                        onClick={() => {
                          setIsUpdating(true);
                          toggleModalStatus(true);
                          setUpdatingItem(item.id);
                          setTitle(item.title);
                          setPrice(item.price);
                          setCategory(item.category);
                          setPicture(item.photo);
                          getImage(item.photo);
                        }}
                        className="creation-showcase__item__edit"
                      >
                        <SVG svg="create-edit-product"/>
                      </div>
                    </div>
                  )}
                </>
              ))
            ) : (
              <Dots color={"dark"}/>
            ))}

          {!vitrinListLoading && activeTab === 1 && vitrinList.length === 0 && (
            <div style={{marginTop: 20}}>
              <NotFoundBox title="محصولی یافت نشد!" bio="" icon={""}/>
            </div>
          )}

          {activeTab === 2 &&
            (!vitrinListLoading ? (
              topVitrins.map((item) => (
                <div className="creation-showcase__item">
                  <div
                    onClick={() => setTopVitrin(item.id)}
                    className={`creation-showcase__item__top`}
                  >
                    <SVG svg={"times"}/>
                  </div>
                  <div className="creation-showcase__item__info">
                    <div className="creation-showcase__item__image">
                      <img loading="lazy" src={`${item.photo}`} alt=""/>
                    </div>

                    <div className="col j-sb">
                      <div className="creation-showcase__item__info__title">
                        {item.title}
                      </div>

                      {!!item.price && <div className="creation-showcase__item__info__description">
                        <span>{currency(item.price)} تومان</span>
                      </div>}
                    </div>
                  </div>

                  {/*<div onClick={() => deleteVitrinItem(item.id)} className="creation-showcase__item__delete">*/}
                  {/*  <SVG svg="create-remove"/>*/}
                  {/*</div>*/}
                  <div
                    onClick={() => {
                      setIsUpdating(true);
                      toggleModalStatus(true);
                      setUpdatingItem(item.id);
                      setTitle(item.title);
                      setPrice(item.price);
                      setCategory(item.category);
                      setPicture(item.photo);
                      getImage(item.photo);
                    }}
                    className="creation-showcase__item__edit"
                  >
                    <SVG svg="create-edit-product"/>
                  </div>
                </div>
              ))
            ) : (
              <Dots color={"dark"}/>
            ))}

          {!vitrinListLoading && activeTab === 2 && topVitrins.length === 0 && (
            <div style={{marginTop: 20}}>
              <NotFoundBox
                title="محصولی در ویترین یافت نشد!"
                bio="محصول مورد نظر را میتوانید از لیست همه محصولات به ویترین اضافه کنید"
                icon={""}
              />
            </div>
          )}
        </Card>
      </div>

      {/*<Card>*/}
      {/*  <div onClick={() => history.goBack()} className="shop-creation__action">*/}
      {/*    <span>ثبت مشخصات و ادامه</span>*/}
      {/*    <SVG svg="arrow"/>*/}
      {/*  </div>*/}
      {/*</Card>*/}

      <Modal
        active={modalStatus}
        close={() => {
          toggleModalStatus(false);
          setIsUpdating(false);
          resetValues();
        }}
      >
        <div className="creation-showcase__modal">
          <div className="creation-showcase__modal__header">افزودن محصول</div>
          <div className="creation-showcase__modal__sub-header">عکس محصول</div>
          <div
            onClick={() => setShowImageEditor(true)}
            className="creation-information__photo"
          >
            {picture ? (
              <>
                <img src={`${picture}`} alt=""/>
                <span>تغییر عکس</span>
              </>
            ) : adsImage.url !== null ? (
              <>
                <img
                  src={`data:image/png;base64,${adsImage.url}`}
                  alt="vitrin"
                />
                <span>تغییر عکس</span>
              </>
            ) : (
              <>
                <SVG svg="create-photo"/>
                <span>آپلود عکس</span>
              </>
            )}
          </div>
          <div className="title">نام محصول</div>
          <input
            value={title}
            placeholder="نام محصول را وارد کنید"
            className="input"
            onChange={(e) => setTitle(e.target.value)}
          />
          <div className="title">قیمت محصول (تومان)</div>
          <input
            value={price}
            placeholder="قیمت محصول را وارد کنید"
            className="input"
            onChange={(e) => setPrice(farsiDigitToEnglish(e.target.value))}
          />
          <div className="title">دسته بندی محصول</div>
          <input
            value={category}
            placeholder="دسته بندی محصول را وارد کنید"
            className="input"
            onChange={(e) => setCategory(e.target.value)}
          />
          <div
            onClick={() =>
              submitLoading === false &&
              (isUpdating ? updateVitrinItem(updatingItem) : setVitrinItem())
            }
            className="creation-showcase__action"
          >
            {submitLoading ? (
              <Dots color={"light"}/>
            ) : (
              <>
                <span>ثبت محصول</span>
                <SVG svg="create-shop"/>
              </>
            )}
          </div>
        </div>
      </Modal>
    </Fade>
  );
};
