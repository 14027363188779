import {
    AcceptRule,
    Card,
    CheckBox,
    CounterInput,
    Dots,
    Fade,
    Modal,
    PageTopbar,
    Selectbox,
    SVG,
    UpdateUserInfo,
} from "../../components";
import React, { useEffect, useRef, useState } from "react";
import {
    getCitiesAPI,
    getCountriesAPI,
    getHintAPI,
    getStatesAPI,
} from "../../utils";
import toast from "react-hot-toast";
import { checkPanelCodeAPI } from "../../utils/Services/API";
import {
    buyParticipationUnitAPI,
    getParticipationPackagesAPI,
} from "../../utils/Services/KartlandUnits";
import { useHistory } from "react-router-dom";
import { currency, farsiDigitToEnglish } from "../../utils/Functions";
import { useUser } from "../../hooks";

export const KartlandBuyParticipationContainer = () => {
    const user_token = localStorage.getItem("user_token");
    const [unitDetail, setUnitDetail] = useState({
        name: "",
        inviter: "",
        country: 4151,
        state: 4151,
        city: 4151,
    });
    const [inviterCode, setInviterCode] = useState("");
    const [inviteCodeStatus, setInviteCodeStatus] = useState(false);
    const [inviteCodeLoading, setInviteCodeLoading] = useState(false);
    const [data, setData] = useState<any>({
        countries: [],
        states: [],
        cities: [],
        subCities: [],
        districts: [],
    });
    const [submitLoading, setSubmitLoading] = useState(false);
    const [fiftyPack, setFiftyPack] = useState(false);
    const [twentyFivePack, setTwentyFivePack] = useState(false);
    const [packages, setPackages] = useState<any>({});
    const [packagesLoading, setPackagesLoading] = useState(false);
    const amount = useRef();
    const [blockAmount, setBlockAmount] = useState(1);
    const history = useHistory();
    const {
        getUserDataFromApi,
        userState: { user },
    } = useUser();
    const [showFillProfile, setShowFillProfile] = useState(false);
    const [showFillProfileModal, setShowFillProfileModal] = useState(false);
    const [userInfo, setUserInfo] = useState({
        name: "",
        lastname: "",
        nationalCode: "",
    });

    const [showAlert, setShowAlert] = useState(false);
    const [hint, setHint] = useState<any>("");
    const [hintLoading, setHintLoading] = useState(true);
    const [readCompleted, setreadCompleted] = useState(false);
    const [acceptedRule, setAcceptedRule] = useState(false);

    // const getHint = () => {
    //     getHintAPI("قوانین و هشدارها 7")
    //         .then((response) => {
    //             setHint(response);
    //         })
    //         .catch(() => setHint(false))
    //         .finally(() => setHintLoading(false));
    // };

    const UserInfoHandler = () => {
        if (
            user.name?.length === 0 ||
            user.name === null ||
            user.lastname?.length === 0 ||
            user.lastname === null ||
            user.nationalCode?.length === 0 ||
            user.nationalCode === null ||
            user.nationalCode?.length !== 10
        ) {
            setShowFillProfile(true);
        } else {
            setShowFillProfile(false);
        }
    };

    const handlePrice = () => {
        if (twentyFivePack) {
            return currency(packages?.price25 * blockAmount);
        } else if (fiftyPack) {
            return currency(packages?.price50 * blockAmount);
        } else {
            return "-";
        }
    };

    const handleSubmitInviteCode = () => {
        setInviteCodeLoading(true);
        checkPanelCodeAPI(user_token, inviterCode)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.data.Credit) {
                        toast.error("کد وارد شده منقضی شده است.");
                        return;
                    } else {
                        toast.success("معرف واحد با موفقیت ثبت شد");
                        setInviteCodeStatus(true);
                        setUnitDetail({ ...unitDetail, inviter: inviterCode });
                        return;
                    }
                } else if (response.status === 404) {
                    toast.error("پنلی با این کد پیدا نشد.");
                    return;
                }
            })
            .catch((err) => {})
            .finally(() => setInviteCodeLoading(false));
    };

    const getCountries = () => {
        getCountriesAPI()
            .then((response) => {
                setData({ ...data, countries: response.data });
            })
            .catch(() => {
                toast.error("خطا در بارگذاری کشورها");
            })
            .finally(() => {});
    };

    const getStates = (countryId) => {
        getStatesAPI(countryId, "partner")
            .then((response) => {
                setData({ ...data, states: response.data });
            })
            .catch(() => toast.error("خطا در بارگذاری استان‌ها"))
            .finally(() => {});
    };

    const getCities = (stateId) => {
        getCitiesAPI(stateId, "partner")
            .then((response) => {
                let cities = [];
                response.data.partner25.map((city) => {
                    if (!cities.includes(city)) {
                        cities.push(city);
                    }
                });
                response.data.partner50.map((city) => {
                    if (!cities.includes(city)) {
                        cities.push(city);
                    }
                });
                cities.map((city) => {
                    city.name = city.cityname;
                    city.id = city.city;
                });
                setData({ ...data, cities: cities });
            })
            .catch(() => toast.error("خطا در بارگذاری شهرستان‌ها"))
            .finally(() => {});
    };

    const getParticipationPackages = () => {
        setPackagesLoading(true);
        getParticipationPackagesAPI(unitDetail.city)
            .then((res) => {
                if (res.status === 200) {
                    setPackages(res.data);
                    setPackagesLoading(false);
                }
            })
            .catch((err) => {});
    };

    const buyParticipationBlock = () => {
        if (unitDetail.country === 0) {
            toast.error("کشور را انتخاب کنید.");
            return;
        }

        if (unitDetail.state === 0) {
            toast.error("استان را انتخاب کنید.");
            return;
        }

        if (unitDetail.city === 0) {
            toast.error("شهرستان را انتخاب کنید.");
            return;
        }

        if (!fiftyPack && !twentyFivePack) {
            toast.error("نوع بلوک را انتخاب کنید.");
            return;
        }

        if (
            twentyFivePack &&
            +packages.allPartner?.maxPartner25 -
                +packages.allPartner?.maxPartner25SoldOut <=
                0
        ) {
            toast.error("این شهرستان فاقد موجودی بلوک 25تایی است");
            return;
        }
        if (
            fiftyPack &&
            +packages.allPartner?.maxPartner50 -
                +packages.allPartner?.maxPartner50SoldOut <=
                0
        ) {
            toast.error("این شهرستان فاقد موجودی بلوک 50تایی است");
            return;
        }
        setSubmitLoading(true);
        buyParticipationUnitAPI(
            twentyFivePack ? "100" : "200",
            unitDetail.city,
            unitDetail.inviter,
            blockAmount
        )
            .then((response) => {
                if (
                    response.status === 200 &&
                    response.data.payID &&
                    response.data.tk
                ) {
                    toast.success("در حال انتقال به درگاه پرداخت...");

                    const paymentURL = `${process.env.REACT_APP_DEV_IP}api/v3/kartland/partnerUnit/${response.data.payID}/payment/${response.data.tk}`;
                    history.push(`/pay?${paymentURL}`);
                    setTimeout(() => {
                        if (history.location.pathname.includes("/pay"))
                            window.location.href = `${paymentURL}`;
                    }, 600);
                }
            })
            .catch((err) => {
                toast.error("مشارکت در واحد با خطا روبه‌رو شد");
            })
            .finally(() => setSubmitLoading(false));
    };

    useEffect(() => {
        // getCountries();
        getStates(1);
    }, []);

    useEffect(() => {
        setUserInfo({
            name: user.name,
            lastname: user.lastname,
            nationalCode: user.nationalCode,
        });
    }, [user]);

    useEffect(() => {
        UserInfoHandler();
    }, [user]);

    useEffect(() => {
        if (unitDetail.city !== 0) {
            getParticipationPackages();
        }
    }, [unitDetail.city]);

    useEffect(() => {
        document
            .getElementById("buyUnitAlert")
            ?.addEventListener("scroll", () => {
                document.getElementById("buyUnitAlert").scrollTop >
                document.getElementById("buyUnitAlert")?.scrollHeight -
                    document.getElementById("buyUnitAlert")?.clientHeight -
                    20
                    ? setreadCompleted(true)
                    : setreadCompleted(false);
            });
        return () => {
            document
                .getElementById("buyUnitAlert")
                ?.removeEventListener("scroll", () => {
                    document.getElementById("buyUnitAlert").scrollTop >
                    document.getElementById("buyUnitAlert")?.scrollHeight -
                        document.getElementById("buyUnitAlert")?.clientHeight -
                        20
                        ? setreadCompleted(true)
                        : setreadCompleted(false);
                });
        };
    }, [document.getElementById("buyUnitAlert")?.scrollTop]);

    useEffect(() => {
        history.replace("/kartland/participation/buy");
    }, []);

    return (
        <Fade>
            <div className={"kartland"}>
                <UpdateUserInfo
                    showUpdate={showFillProfileModal}
                    setShowUpdate={(value) => {
                        value && buyParticipationBlock();
                        setShowFillProfileModal(false);
                    }}
                    userInfo={userInfo}
                    setUserInfo={setUserInfo}
                    getUser={getUserDataFromApi}
                />
                <PageTopbar
                    title="مشارکت در واحد"
                    showKv={false}
                    white={true}
                />
                <Modal
                    active={showAlert}
                    close={(close) => setShowAlert(false)}
                >
                    <div className="buy-panel__alert__title">{hint.title}</div>
                    <div
                        className={`buy-panel__alert__description ${
                            readCompleted && "deactive"
                        }`}
                        id={"buyUnitAlert"}
                    >
                        {hintLoading && <Dots color="dark" />}
                        {hint.content?.split("\n")?.map((str) => (
                            <div>{str}</div>
                        ))}
                    </div>
                    <div className="row buy-panel__alert__bottom">
                        <div
                            className={`buy-panel__alert__button  ${
                                readCompleted ? "accept" : "disabled"
                            }`}
                            onClick={() => {
                                if (readCompleted) {
                                    setAcceptedRule(true);
                                    setShowAlert(false);
                                } else {
                                    document
                                        .getElementById("buyUnitAlert")
                                        .scrollTo(0, 2500);
                                }
                                // localStorage.setItem('panelSeen', 'true')
                            }}
                        >
                            قوانین را می‌پذیرم
                        </div>
                        <div
                            className="buy-panel__alert__button reject"
                            onClick={() => setShowAlert(false)}
                        >
                            انصراف
                        </div>
                    </div>
                </Modal>

                <div className={"kartland__user-buy-wrapper"}>
                    {/* <div className="title white">
            کشور
          </div>
          <Selectbox
            data={data.countries}
            placeholder="انتخاب کشور"
            onChange={value => {
              setUnitDetail({ ...unitDetail, country: value });
              getStates(value);
            }}
            onChangeString={value => {
              console.log(value);
            }}
          /> */}

                    {/* {unitDetail.country !== 0 && (
            <React.Fragment>
              <div className="title white">
                استان
              </div>
              <Selectbox
                data={data.states}
                placeholder="انتخاب استان"
                onChange={value => {
                  setUnitDetail({ ...unitDetail, state: value, city: 0 });
                  getCities(value);
                }}
                onChangeString={value => {
                  console.log(value);
                }}
                disabled={unitDetail.country === 0}
                openAll={unitDetail.state !== 0}
              />
            </React.Fragment>
          )}

          {unitDetail.state !== 0 && (
            <React.Fragment>
              <div className="title white">
                شهرستان
              </div>
              <Selectbox
                data={data.cities}
                placeholder="انتخاب شهرستان"
                onChange={value => {
                  setUnitDetail({ ...unitDetail, city: value });
                  setFiftyPack(false);
                  setTwentyFivePack(false);
                }}
                onChangeString={value => {
                  console.log(value);
                }}
                disabled={unitDetail.state === 0}
                openAll={unitDetail.city !== 0 ? true : false}
              />
            </React.Fragment>
          )} */}

                    {unitDetail.city !== 0 && (
                        <>
                            <div className="kartland-buy-participation__checkout__title">
                                نوع بلوک فورشاپ:
                            </div>
                            <div
                                className={
                                    "kartland-buy-participation__checkout"
                                }
                            >
                                <div className="kartland-buy-participation__options">
                                    <div className="kartland-buy-participation__options__option mb-8">
                                        <div className="kartland-buy-participation__options__option__label">
                                            <input
                                                type="radio"
                                                value={"cash"}
                                                name={"checkout"}
                                                disabled={
                                                    (+packages.allPartner
                                                        ?.maxPartner25 -
                                                        +packages.allPartner
                                                            ?.maxPartner25SoldOut) /
                                                        25 ===
                                                        0 && true
                                                }
                                                checked={twentyFivePack}
                                                onChange={() => {
                                                    setTwentyFivePack(
                                                        !twentyFivePack
                                                    );
                                                    setFiftyPack(false);
                                                }}
                                            />
                                            <label htmlFor="">25 تایی</label>
                                        </div>
                                        <div
                                            className={`kartland-buy-participation__units__remain ${
                                                (+packages.allPartner
                                                    ?.maxPartner25 -
                                                    +packages.allPartner
                                                        ?.maxPartner25SoldOut) /
                                                    25 ===
                                                    0 && "deactive"
                                            }`}
                                        >
                                            {/*<div className="kartland-buy__units__icon">*/}
                                            {/*  <SVG svg={"kartland-remain"}/>*/}
                                            {/*</div>*/}
                                            <div className="kartland-buy-participation__units__text">
                                                تعداد بلوک های موجود:
                                            </div>
                                            <div className="kartland-buy-participation__units__value">
                                                {(+packages.allPartner
                                                    ?.maxPartner25 -
                                                    +packages.allPartner
                                                        ?.maxPartner25SoldOut) /
                                                    100}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="kartland-buy-participation__options__option">
                                        <div className="kartland-buy-participation__options__option__label">
                                            <input
                                                type="radio"
                                                value={"KM"}
                                                name={"checkout"}
                                                checked={fiftyPack}
                                                disabled={
                                                    (+packages.allPartner
                                                        ?.maxPartner50 -
                                                        +packages.allPartner
                                                            ?.maxPartner50SoldOut) /
                                                        50 ===
                                                        0 && true
                                                }
                                                onChange={() => {
                                                    setFiftyPack(!fiftyPack);
                                                    setTwentyFivePack(false);
                                                }}
                                            />
                                            <label htmlFor="">50 تایی</label>
                                        </div>
                                        <div
                                            className={`kartland-buy-participation__units__remain ${
                                                (+packages.allPartner
                                                    ?.maxPartner50 -
                                                    +packages.allPartner
                                                        ?.maxPartner50SoldOut) /
                                                    50 ===
                                                    0 && "deactive"
                                            }`}
                                        >
                                            {/*<div className="kartland-buy__units__icon">*/}
                                            {/*  <SVG svg={"kartland-remain"}/>*/}
                                            {/*</div>*/}
                                            <div className="kartland-buy-participation__units__text">
                                                تعداد بلوک های موجود:
                                            </div>
                                            <div className="kartland-buy-participation__units__value">
                                                {(+packages.allPartner
                                                    ?.maxPartner50 -
                                                    +packages.allPartner
                                                        ?.maxPartner50SoldOut) /
                                                    200}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}

                    {(twentyFivePack || fiftyPack) && unitDetail.city !== 0 && (
                        <div className={"mb-12"}>
                            <div className="title white">تعداد:</div>
                            <div className="kartland-buy__pay-km">
                                <CounterInput
                                    height={true}
                                    ref={amount}
                                    step={1}
                                    max={
                                        twentyFivePack
                                            ? (packages.allPartner
                                                  ?.maxPartner25 -
                                                  packages.allPartner
                                                      ?.maxPartner25SoldOut) /
                                              100
                                            : (packages.allPartner
                                                  ?.maxPartner50 -
                                                  packages.allPartner
                                                      ?.maxPartner50SoldOut) /
                                              200
                                    }
                                    // max={twentyFivePack ?
                                    //   (((+packages.allPartner?.maxPartner25 - +packages.allPartner?.maxPartner25SoldOut) / 25) > 10 ? 10 : (+packages.allPartner?.maxPartner25 - +packages.allPartner?.maxPartner25SoldOut) / 25)
                                    //   : (((+packages.allPartner?.maxPartner50 - +packages.allPartner?.maxPartner50SoldOut) / 50) > 10 ? 10 : (+packages.allPartner?.maxPartner50 - +packages.allPartner?.maxPartner50SoldOut) / 50)}
                                    defaultValue={1}
                                    unitMode={true}
                                    onChange={(value) => {
                                        setBlockAmount(value);
                                    }}
                                />
                            </div>
                        </div>
                    )}

                    {unitDetail.city !== 0 && user.introducedId === null && (
                        <>
                            <div className="title white">
                                شماره موبایل یا کد کارتک معرف
                            </div>
                            <div className="row">
                                <input
                                    type="text"
                                    className="input"
                                    value={inviterCode}
                                    onChange={(e) =>
                                        setInviterCode(
                                            farsiDigitToEnglish(e.target.value)
                                        )
                                    }
                                    placeholder="شماره موبایل یا کد کارتک معرف"
                                />
                                <div
                                    className={`create-shop__inviter__button ${
                                        inviteCodeStatus && "edit"
                                    } ${inviterCode.length === 0 && "hidden"}`}
                                    onClick={() => {
                                        if (inviteCodeStatus) {
                                            setInviterCode("");
                                            setInviteCodeStatus(false);
                                            setUnitDetail({
                                                ...unitDetail,
                                                inviter: "",
                                            });
                                        } else {
                                            handleSubmitInviteCode();
                                        }
                                    }}
                                >
                                    {inviteCodeLoading ? (
                                        <Dots color="light" />
                                    ) : inviteCodeStatus ? (
                                        "ویرایش"
                                    ) : (
                                        "ثبت"
                                    )}
                                </div>
                            </div>
                        </>
                    )}

                    {unitDetail.city !== 0 &&
                        (+packages?.allPartner?.maxPartner25 -
                            +packages?.allPartner?.maxPartner25SoldOut >
                            0 ||
                            +packages?.allPartner?.maxPartner50 -
                                +packages?.allPartner?.maxPartner50SoldOut >
                                0) && (
                            <>
                                <div className="kartland-buy__price">
                                    <div className="">قیمت:</div>
                                    {/*<div className="">{fiftyPack ? currency(packages?.price50) : currency(packages?.price25)}</div>*/}
                                    <div className="">
                                        {handlePrice()} تومان
                                    </div>
                                </div>
                            </>
                        )}

                    {/*{unitDetail.city !== 0 && (fiftyPack || twentyFivePack) && packagesLoading === false &&*/}
                    {/*  <div className="kartland-buy__units">*/}
                    {/*    <div className="kartland-buy__units__sold">*/}
                    {/*      <div className="kartland-buy__units__icon">*/}
                    {/*        <SVG svg={"kartland-sold"}/>*/}
                    {/*      </div>*/}
                    {/*      <div className="kartland-buy__units__text">*/}
                    {/*        تعداد واحد های شهرستان (25تایی):*/}
                    {/*      </div>*/}
                    {/*      <div className="kartland-buy__units__value">{packages.allPartner?.maxPartner25}</div>*/}
                    {/*    </div>*/}
                    {/*    <div className="kartland-buy__units__remain">*/}
                    {/*      <div className="kartland-buy__units__icon">*/}
                    {/*        <SVG svg={"kartland-remain"}/>*/}
                    {/*      </div>*/}
                    {/*      <div className="kartland-buy__units__text">*/}
                    {/*        تعداد واحد های موجود (25تایی):*/}
                    {/*      </div>*/}
                    {/*      <div*/}
                    {/*        className="kartland-buy__units__value">{+packages.allPartner?.maxPartner25 - +packages.allPartner?.maxPartner25SoldOut}</div>*/}
                    {/*    </div>*/}
                    {/*    <div className="kartland-buy__units__sold">*/}
                    {/*      <div className="kartland-buy__units__icon">*/}
                    {/*        <SVG svg={"kartland-sold"}/>*/}
                    {/*      </div>*/}
                    {/*      <div className="kartland-buy__units__text">*/}
                    {/*        تعداد واحد های شهرستان (50تایی):*/}
                    {/*      </div>*/}
                    {/*      <div className="kartland-buy__units__value">{packages.allPartner?.maxPartner50}</div>*/}
                    {/*    </div>*/}
                    {/*    <div className="kartland-buy__units__remain">*/}
                    {/*      <div className="kartland-buy__units__icon">*/}
                    {/*        <SVG svg={"kartland-remain"}/>*/}
                    {/*      </div>*/}
                    {/*      <div className="kartland-buy__units__text">*/}
                    {/*        تعداد واحد های موجود (50تایی):*/}
                    {/*      </div>*/}
                    {/*      <div*/}
                    {/*        className="kartland-buy__units__value">{+packages.allPartner?.maxPartner50 - +packages.allPartner?.maxPartner50SoldOut}</div>*/}
                    {/*    </div>*/}
                    {/*  </div>}*/}
                </div>

                <div className={"w-full absolute bottom-16"}>
                    <div className="row a-c j-c" style={{ margin: "8px auto" }}>
                        <CheckBox
                            defaultValue={acceptedRule}
                            type="check"
                            onChange={(value) =>
                                setAcceptedRule(value ? true : false)
                            }
                        />
                        <AcceptRule
                            title="مشارکت"
                            color="light"
                            onCLick={() => setShowAlert(true)}
                        />
                    </div>
                    <div
                        className={`button width-full-16 ${
                            !acceptedRule && "disable"
                        }`}
                        onClick={() => {
                            if (!acceptedRule) {
                                toast.error(
                                    "ابتدا قوانین و مقررات مشارکت را مطالعه و بپذیرید."
                                );
                                return;
                            }
                            if (showFillProfile) {
                                setShowFillProfileModal(true);
                            } else {
                                !submitLoading && buyParticipationBlock();
                            }
                        }}
                    >
                        {submitLoading ? (
                            <Dots color="light" />
                        ) : (
                            "پرداخت و مشارکت در فروش واحد"
                        )}
                    </div>
                </div>
            </div>
        </Fade>
    );
};
