import {SVG} from "components";
import {getLivePrice} from "../../../../utils";
import {useEffect, useState} from "react";
import {currency} from "utils/Functions";
import toast from "react-hot-toast";

export const LivePrice = () => {
  const [livePrice, setLivePrice] = useState(0)
  const [isLoading, setIsLoading] = useState(false)

  const getPrice = () => {
    setIsLoading(true)
    getLivePrice().then((response) => {
      setLivePrice(response.data);
    }).catch(err => {
        toast.error('عملیات با خطا مواجه شد');
    })
      .finally(() => {
        setIsLoading(false)
      })
  }

  useEffect(() => {
    getPrice();
  }, [])

  return (
    <div className="km__live-price">
      <div className="km__live-price__icon">
        <SVG svg={'KM-icon'}/>
      </div>
      <div className="km__live-price__detail">
        <span className="km__live-price__detail__title">قیمت کنونی KP : </span>
        <span className="km__live-price__detail__value">{currency(livePrice)} تومان</span>
      </div>
      <div className={`km__live-price__refresh ${isLoading === true && 'active'}`}
           onClick={() => isLoading === false && getPrice()}>
        <SVG svg="refresh"/>
      </div>
    </div>
  );
};
