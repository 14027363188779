import { SllogContainer } from 'containers/CollaborateContainer/Logs/SlLogContainer';
import React from 'react';

const CollaborateSlLogs = () => {
    return (
        <SllogContainer />
    );
}

export default CollaborateSlLogs;
