import React, {useState, FC, forwardRef, useEffect} from "react";
import {useUser} from "hooks";
import {SVG} from "components";
import toast from "react-hot-toast";
import "./CounterInput.scss";

type TCounter = {
  defaultValue: number;
  step?: number;
  max?: number;
  onChange?: any;
  height?: boolean;
  packageMode?: boolean;
  unitMode?: boolean;
  kmMode?: boolean;
  giveKm?: boolean;
};
export const CounterInput = forwardRef((props: TCounter) => {
  const {
    defaultValue = 0,
    step,
    max,
    onChange,
    height = false,
    packageMode = false,
    unitMode = false,
    kmMode = false,
    giveKm = false
  } = props;
  const {
    userState: {user},
  } = useUser();
  const [counter, setCounter] = useState<any>(defaultValue);

  const increment = () => {
    // if (!disableInput) {
    //   setCounter((prev) => prev + 1);
    //   return;
    // }
    if (counter + step > max && kmMode){
      toast.error('نمی‌توان بسته بیشتری خریداری کرد');
      return;
    }
    if (counter + step > max && giveKm){
      toast.error('KP شما بیش از این مقدار کافی نیست.');
      return;
    }
    if (counter + step > user.whiteKm && unitMode === false) {
      toast.error("موجودی شما برای مقدار بیشتر کافی نمی باشد!", {
        id: "no-enough-km",
      });
      return;
    }

    // if (counter + step > 10 && unitMode) {
    //   toast.error('حداکثر تعداد همزمان 10 عدد است');
    // }
    if (max && counter + step > max) {
      if (packageMode) {
        return;
      }
      if (unitMode) {
        // toast.error("بیشترین تعداد مجاز برای انتقال ۵۰۰۰ عدد می باشد!");
        return;
      }
      toast.error("بیشترین تعداد مجاز برای انتقال ۵۰۰۰ عدد می باشد!", {
        id: "extra-count",
      });
      return;
    }
    setCounter((prev) => prev + step);
  };
  const decrement = () => {
    if (packageMode) {
      if (counter - step == 0) {
        return;
      }
      setCounter((prev) => prev - step);
      return;
    }
    if (unitMode) {
      if (counter - step == 0) {
        return;
      }
      setCounter((prev) => prev - step);
      return;
    }
    if (counter !== defaultValue) {
      setCounter((prev) => prev - step);
    } else return;
  };

  useEffect(() => {
    onChange(counter);
  }, [counter]);

  useEffect(() => {
    setCounter(defaultValue);
  }, [defaultValue]);

  return (
    <div className={`counter-input ${height && "height"}`}>
      <div className="counter-input__action" onClick={increment}>
        <SVG svg="plus"/>
      </div>
      <input
        value={counter}
        disabled
        className="counter-input__value"
        onChange={(e) => setCounter(e.target.value)}
      />

      <div className="counter-input__action negative" onClick={decrement}>
        <SVG svg="negative"/>
      </div>
    </div>
  );
});
