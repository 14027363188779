import { findIndex } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import { devIp } from "../../utils/Services/API";
import { SVG } from "components";
import "./Gallery.scss";

export const Gallery = ({
  images = [],
  currImage = null,
  active = false,
  setShow,
}) => {
  const [activeImage, setActiveImage] = useState(null);
  const [slider, setSlider] = useState(null);

  const handleChangeImage = (image, index) => {
    setActiveImage(image);
    slider.slickGoTo(index);
  };

  useEffect(() => {
    if (active) {
      slider.slickGoTo(
        findIndex(images, (image) => {
          return image === currImage;
        })
      );
      setActiveImage(currImage);
    }
  }, [active]);

  return (
    <div className={`gallery ${active && "active"}`}>
      <div className="gallery__header">
        <div className="gallery__header__icon" onClick={() => setShow(false)}>
          <SVG svg="times" />
        </div>
        تصاویر محصول
      </div>
      <div className="gallery__slider">
        <Slider
          dots={true}
          arrows={false}
          rtl={false}
          infinite={false}
          speed={80}
          ref={(thisSlider) => setSlider(thisSlider)}
          afterChange={(index) => setActiveImage(images[index])}
        >
          {images &&
            images.map((image, index) => (
              <img
                src={`${image}`}
                // src={`data:image/png;base64,${image}`}
                alt=""
                className="gallery__image"
                key={index}
              />
            ))}
        </Slider>
      </div>
      <div className="gallery__items">
        {images &&
          images.map((image, index) => (
            <img
              className={`gallery__items__image ${
                activeImage === image && "active"
              }`}
              src={`${image}`}
              // src={`data:image/png;base64,${image}`}
              alt=""
              key={index}
              onClick={() => handleChangeImage(image, index)}
            />
          ))}
      </div>
    </div>
  );
};
