import { SVG } from "components";

export const Progress = ({ cart, close }) => {
  return (
    <>
      <div className="orders__progress__wrapper">
        <div className="orders__progress__close" onClick={() => close()}>
          <SVG svg="times" />
        </div>
        <div className="orders__progress__status">
          جزئیات سفارش
          {/* <span>{`وضعیت سفارش: ${cart.status}`}</span>
          <span></span> */}
        </div>
      </div>
      {/* <div className="orders__progress__close" onClick={() => close()}>
        <SVG svg="times" />
      </div>
      <div className="orders__progress">
        {fake.map((item, index) => (
          <>
            <div className="orders__progress__item">
              <div
                className={`orders__progress__item__dot ${item.active && "active"
                  }`}
              ></div>
              <span className={`${item.active && "active"}`}>{item.name}</span>
            </div>
            <div
              className={`orders__progress__line ${index + 1 == fake.length && "hidden"
                }`}
            ></div>
          </>
        ))}
      </div> */}
    </>
  );
};
