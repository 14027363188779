import { Redirect, Route } from "react-router-dom";
import { useAuth } from "hooks";

export const PrivateRoutes = (props) => {
    const {
        authState: { isLogged },
    } = useAuth();

    return (
        <>
            {isLogged ? (
                <Route {...props} />
            ) : (
                <Redirect
                    to={`${props.path == "/profile" ? "/login" : "/login"}`}
                />
            )}
        </>
    );
};
