import React from "react";
import "./ProductsLoading.scss";

export const ProductLoading = () => {
    const items = [{}, {}, {}, {}, {}];
    return (
        <div className="products-loading">
            {items.map(() => (
                <div className="products-loading__item">
                    <div className="products-loading__item__image"></div>
                    <div className="products-loading__item__text">
                        <div className="products-loading__item__title" />
                        <div className="products-loading__item__description" />
                    </div>
                </div>
            ))}
        </div>
    );
};
