import {PageTopbar, SVG, Selectbox} from "components";
import {useHistory} from "react-router-dom";
import {useEffect} from "react";

export const CreationOwner = () => {
  const history = useHistory();

  const dateConfig = {
    hour: {
      format: "hh",
      caption: "ساعت",
      step: 1,
    },
    minute: {
      format: "mm",
      caption: "دقیقه",
      step: 1,
    },
  };

  useEffect(() => {
    history.replace("/shop-creation/owner");
  }, []);

  return (
    <div className="creation-owner">

      <PageTopbar title="ثبت فروشگاه" showKv={false}/>
      <div className="creation-owner__wrapper">
        <div className="creation-owner__title">
          <span>عکس صاحب :</span>
        </div>
        <div className="creation-owner__photo">
          <SVG svg="create-photo"/>
          <span>آپلود عکس</span>
        </div>
        <div className="creation-owner__title">
          <span>نام صاحب</span>
        </div>
        <input
          placeholder="نام را وارد کنید"
          className="creation-owner__input"
        />
        <div className="creation-owner__title">
          <span>نام خانوادگی صاحب</span>
        </div>
        <input
          placeholder="نام خانوادگی را وارد کنید"
          className="creation-owner__input"
        />
        <div className="creation-owner__title">
          <span>کد ملی صاحب</span>
        </div>
        <input
          placeholder="کد ملی را وارد کنید"
          className="creation-owner__input"
        />
        <div className="creation-owner__title">
          <span>شماره تلفن</span>
        </div>
        <input
          placeholder="شماره تلفن را وارد کنید"
          className="creation-owner__input"
        />
      </div>
      <div className="shop-creation__action">
        <span>ثبت مشخصات و ادامه</span>
        <SVG svg="arrow"/>
      </div>
    </div>
  );
};
