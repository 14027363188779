import {
    Card,
    Dots,
    Fade,
    ImageCropper,
    Modal,
    PageTopbar,
    Selectbox,
    SVG,
    VideoManager,
} from "../../components";
import React, { useEffect, useState } from "react";
import {
    createContentAPI,
    GetCategories,
    updateMateInfoAPI,
    updateMateInfoIntroduceStoreAPI,
} from "../../utils";
import toast from "react-hot-toast";
import imageCompression from "browser-image-compression";
import { useHistory } from "react-router-dom";
import { useUser } from "../../hooks";
import { farsiDigitToEnglish } from "../../utils/Functions";
import { setNicknameAPI } from "../../utils/Services/UserContent/SetNickname";

export const CreateContentContainer = () => {
    const history = useHistory();
    const {
        getUserDataFromApi,
        userState: { user },
    } = useUser();
    const [showImageEditor, setShowImageEditor] = useState(false);
    const [contentImage, setContentImage] = useState({
        file: null,
        url: "",
    });
    const [contentTitle, setContentTitle] = useState("");
    const [contentDesc, setContentDesc] = useState("");
    const [contentCategory, setContentCategory] = useState({
        category: 0,
        categoryValue: "",
    });
    const [categoriesData, setCategoriesData] = useState([]);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [compressLoading, setCompressLoading] = useState(0);
    const [showFillProfileModal, setShowFillProfileModal] = useState(false);
    const [showFillProfile, setShowFillProfile] = useState(false);
    const [userInfo, setUserInfo] = useState({
        name: "",
        lastname: "",
        nickname: "",
    });
    const [updateLoading, setUpdateLoading] = useState(false);
    const [flag, setFlag] = useState(false);
    const [video, setVideo] = useState(null);
    const [videoId, setVideoId] = useState("");
    const [errorOccurred, setErrorOccurred] = useState(false);
    const [isVideoUploaded, setIsVideoUploaded] = useState(false);

    const compressOptions = {
        maxSizeMB: 0.6,
        maxWidthOrHeight: 1024,
        onProgress: (percentage) => {
            setCompressLoading(percentage);
        },
    };

    const handleSubmitContent = () => {
        if (contentTitle === "") {
            toast.error("عنوان محتوا را وارد نمایید.");
            setFlag(false);
            return;
        }

        if (contentDesc === "") {
            toast.error("توضیحات محتوا را وارد نمایید.");
            setFlag(false);
            return;
        }

        if (contentCategory.category === 0) {
            toast.error("یک دسته‌بندی برای محتوا انتخاب کنید");
            setFlag(false);
            return;
        }

        if (contentImage.file === null && video === null) {
            toast.error("تصویر یا ویدیو محتوا را انتخاب کنید.");
            setFlag(false);
            return;
        }

        setFlag(true);
        setSubmitLoading(true);
    };

    const createContent = () => {
        var form = new FormData();
        if (contentImage.file !== null) {
            imageCompression(contentImage.file, compressOptions).then(
                (compressed) => {
                    form.append("picture", compressed);
                    createContentAPI(
                        contentTitle,
                        contentDesc,
                        contentCategory.category,
                        videoId,
                        form
                    )
                        .then((response) => {
                            if (response.status === 200) {
                                toast.success("محتوا شما با موفقیت ثبت شد!");
                                history.push("/content-management");
                            }
                        })
                        .catch((err) => toast.error("خطا در ثبت محتوا"))
                        .finally(() => setSubmitLoading(false));
                }
            );
        } else if (video !== null && !errorOccurred) {
            createContentAPI(
                contentTitle,
                contentDesc,
                contentCategory.category,
                videoId,
                form
            )
                .then((response) => {
                    if (response.status === 200) {
                        toast.success("محتوا شما با موفقیت ثبت شد!");
                        history.push("/content-management");
                    }
                })
                .catch((err) => toast.error("خطا در ثبت محتوا"))
                .finally(() => setSubmitLoading(false));
        }
    };

    useEffect(() => {
        if (isVideoUploaded) {
            createContent();
        }
    }, [isVideoUploaded]);

    const handleSubmitInfo = () => {
        if (userInfo.name?.length === 0 || userInfo.name === null) {
            toast.error("نام خود را وارد نمایید.");
            return;
        } else if (
            userInfo.lastname?.length === 0 ||
            userInfo.lastname === null
        ) {
            toast.error("نام‌خانوادگی خود را وارد نمایید.");
            return;
        } else {
            setUpdateLoading(true);
            setNicknameAPI(
                userInfo.name ? userInfo.name : "",
                userInfo.lastname ? userInfo.lastname : "",
                userInfo.nickname ? userInfo.nickname : ""
            )
                .then((response) => {
                    if (response.status === 200) {
                        toast.success("اطلاعات با موفقیت ثبت شد");
                        handleSubmitContent();
                        setShowFillProfileModal(false);
                        getUserDataFromApi();
                        setUpdateLoading(false);
                    }
                })
                .catch((err) => {
                    if (err.response.status === 406) {
                        toast.error(
                            "برای تکمیل اطلاعات خود، باید 7 ستاره باشید."
                        );
                        return;
                    }
                    toast.error("خطایی در تکمیل اطلاعات پیش آمد.");
                })
                .finally(() => setUpdateLoading(false));
        }
    };

    const getCategories = () => {
        GetCategories().then((res) => {
            if (res) {
                res.data.forEach((category) => {
                    category["name"] = category["title"];
                });
                if (res.data[0]?.name) setCategoriesData(res.data);
            }
        });
    };

    const UserInfoHandler = () => {
        if (
            user.name?.length === 0 ||
            user.name === null ||
            user.lastname?.length === 0 ||
            user.lastname === null ||
            user.nickName?.length === 0 ||
            user.nickName === null
        ) {
            setShowFillProfile(true);
        } else {
            setShowFillProfile(false);
        }
    };

    useEffect(() => {
        setUserInfo({
            name: user.name,
            lastname: user.lastname,
            nickname: user.nickName,
        });
        UserInfoHandler();
    }, [user]);

    useEffect(() => {
        getCategories();
        history.replace("/create-content");
    }, []);

    useEffect(() => {
        if (flag) {
            setFlag(false);
            handleSubmitContent();
        }
    }, [videoId]);

    useEffect(() => {
        if (flag) {
            if (contentTitle === "") {
                toast.error("عنوان محتوا را وارد نمایید.");
            } else if (contentDesc === "") {
                toast.error("توضیحات محتوا را وارد نمایید.");
            } else if (contentCategory.category === 0) {
                toast.error("یک دسته‌بندی برای محتوا انتخاب کنید");
            } else if (contentImage.file === null && video === null) {
                toast.error("تصویر یا ویدیو محتوا را انتخاب کنید.");
            }
        }

        if (flag && errorOccurred) {
            setFlag(false);
        }
    }, [flag]);

    return (
        <Fade>
            <PageTopbar title="تولید محتوا" showKv={false} />

            <ImageCropper
                active={showImageEditor}
                onClose={(close) => {
                    setShowImageEditor(!close);
                }}
                onConfirm={(image) => {
                    setShowImageEditor(false);
                    setContentImage((prev) => ({
                        url: URL.createObjectURL(image),
                        file: image,
                    }));
                }}
            />

            <Modal
                active={showFillProfileModal}
                close={() => setShowFillProfileModal(false)}
            >
                <div className="title">نام</div>
                <input
                    type="text"
                    className="input"
                    value={userInfo.name}
                    onChange={(e) =>
                        setUserInfo((prevState) => ({
                            ...prevState,
                            name: e.target.value,
                        }))
                    }
                />

                <div className="title">نام خانوادگی</div>
                <input
                    type="text"
                    className="input"
                    value={userInfo.lastname}
                    onChange={(e) =>
                        setUserInfo((prevState) => ({
                            ...prevState,
                            lastname: e.target.value,
                        }))
                    }
                />

                <div className="title">نام مستعار (اختیاری)</div>
                <input
                    type="text"
                    className="input"
                    value={userInfo.nickname}
                    onChange={(e) =>
                        setUserInfo((prevState) => ({
                            ...prevState,
                            nickname: e.target.value,
                        }))
                    }
                />

                <div className="title">
                    درصورت نداشتن نام مستعار، نام و نام خانوادگی شما در انتهای
                    محتوا نمایش داده می‌شود.
                </div>
                <div className="title">
                    مسئولیت صحت اطلاعات وارده، به عهده کاربر می‌باشد.
                </div>

                <div
                    className="button green"
                    onClick={() =>
                        updateLoading === false && handleSubmitInfo()
                    }
                >
                    {updateLoading ? (
                        <Dots color={"light"} />
                    ) : (
                        <span>ثبت اطلاعات</span>
                    )}
                </div>
            </Modal>

            <div className="wrapper-fix">
                <Card>
                    {!video && (
                        <div
                            className="create-ads__final__image"
                            onClick={() => setShowImageEditor(true)}
                        >
                            <canvas
                                className={`create-ads__canvas ${
                                    contentImage?.url !== "new" && "deactive"
                                }`}
                                id="canvas-image"
                            />

                            {contentImage?.url === "" ? (
                                <SVG svg="ads-image" />
                            ) : contentImage?.file === null ? (
                                // shopDetail.image?.url !== ""
                                // ? (
                                //   <img src={`data:image/png;base64,${shopDetail.image?.url}`} alt="" />
                                // ) : (
                                <SVG svg="ads-image" />
                            ) : (
                                // )
                                <img
                                    // src={`data:image/png;base64,${shopDetail.image?.url}`}
                                    src={`${contentImage?.url}`}
                                    alt=""
                                />
                            )}

                            {contentImage?.url === "" && (
                                <div className="create-ads__final__image__title">
                                    بارگذاری تصویر
                                </div>
                            )}
                        </div>
                    )}
                    {!contentImage.file && (
                        <VideoManager
                            videoSetter={setVideo}
                            uploadVideoFunction={flag}
                            videoIdSetter={setVideoId}
                            errorOccurred={setErrorOccurred}
                            flagSetter={setFlag}
                            finishUpload={setIsVideoUploaded}
                        />
                    )}

                    <div className="title">دسته‌بندی</div>

                    <Selectbox
                        data={categoriesData}
                        placeholder="انتخاب دسته‌بندی محتوا"
                        onChange={(catId) =>
                            setContentCategory((prev) => ({
                                ...prev,
                                category: catId,
                            }))
                        }
                        onChangeString={(value) =>
                            setContentCategory((prev) => ({
                                ...prev,
                                categoryValue: value,
                            }))
                        }
                        // onClickBox={() => window.scrollTo(0, 120)}
                        openAll={true}
                    />

                    <div className="title">عنوان محتوا</div>
                    <input
                        type="text"
                        className="input"
                        value={contentTitle}
                        onChange={(e) => setContentTitle(e.target.value)}
                        placeholder="عنوان محتوا"
                    />

                    <div className="title">متن محتوا</div>
                    <textarea
                        className="textarea"
                        placeholder="متن محتوا"
                        onChange={(e) => setContentDesc(e.target.value)}
                        value={contentDesc}
                        rows={4}
                        style={{ marginBottom: 40 }}
                    />

                    <div
                        className={`shop-creation__action button`}
                        onClick={() => {
                            if (
                                contentTitle != "" &&
                                contentDesc != "" &&
                                contentCategory.category != 0 &&
                                contentImage.file != null &&
                                !showFillProfile
                            ) {
                                createContent();
                            }
                            if (showFillProfile) {
                                setShowFillProfileModal(true);
                            } else {
                                !submitLoading && handleSubmitContent();
                            }
                        }}
                    >
                        {submitLoading ? <Dots color="light" /> : "ثبت محتوا"}
                    </div>
                </Card>
            </div>
        </Fade>
    );
};
