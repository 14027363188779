import { Dots, Modal, SVG } from "components";
import { Hint } from "components/Hint";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { getHintAPI } from "utils";
import { createQrAPI } from "utils/Services/API";

export const NotFound = ({ setNotFound }) => {
  const user_token = localStorage.getItem('user_token');
  const [loading, setLoading] = useState(false);
  const [showHint, setShowHint] = useState(false);
  const [hint, setHint] = useState<any>({
    title: '',
    content: ''
  });

  const handleCreateQr = () => {
    setLoading(true);
    const api = createQrAPI(user_token);
    api.then(response => {
      switch (response.status) {
        case 200:
          toast.success('بارکد شما با موفقیت ایجاد شد!');
          setNotFound(false);
          break;

        case 406:
          toast.error(`شما باید ${response.data.data.InviteNum} دوست دیگر را دعوت کنید.`);
          break;

        default:
          break;
      }
      setLoading(false);
    })
  }


  const getHint = () => {
    getHintAPI('صفحات داخلی بارکد من')
      .then(response => {
        setHint(response)
      })
      .catch(() => setHint(false))
      .finally(() => { })
  }

  useEffect(() => {
    getHint();
  }, []);

  return (
    <>
      <Modal active={showHint} close={() => setShowHint(false)}>
        <div className="hints__title">
          {hint.title}
        </div>
        <div className="hints__content">
          {hint.content?.split('\n')?.map(str => <div>{str}</div>)}
        </div>
      </Modal>
      <div className="generator__not-found">
        <div className="generator__not-found__logo">
          <SVG svg="qr-generator" />
        </div>
        <span className="generator__not-found__title">
          بارکدی برای شما یافت نشد.
        </span>
        <Hint onClick={() => setShowHint(true)} theme="dark" />
      </div>

      <div className="generator__invite-btn">
        <div
          className="generator__invite-btn__title"
          onClick={() => !loading && handleCreateQr()}
        >
          {loading ? (
            <Dots color="light" />
          ) : (
            <React.Fragment>
              <SVG svg="invite-friends" />
              <span>ایجاد بارکد</span>
            </React.Fragment>
          )}
        </div>
        <div className="generator__invite-btn__arrow">
          <SVG svg="arrow" />
        </div>
      </div>
    </>
  )
};
