import { Card, Dots, Modal, SVG } from "components";
import { useUser } from "hooks";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useHistory } from "react-router-dom";
import { updateMateInfoAPI, updateMateInfoIntroduceStoreAPI } from "utils";
import { farsiDigitToEnglish } from "utils/Functions";
import { UpdateMateInfoCitizenAPI } from "utils/Services/KartlandUnits";

export const UpdateUserInfo = ({
    showUpdate,
    setShowUpdate,
    userInfo,
    setUserInfo,
    getUser,
    introduceStoreStatus = false,
    useForCitizen = false,
    isNationalCodeRequired = false,
}) => {
    const {
        userState: { user },
    } = useUser();
    const [updateLoading, setUpdateLoading] = useState(false);
    const [isFilled, setIsFilled] = useState({
        fullName: false,
        nationalCode: false,
    });

    useEffect(() => {
        if (user.name && user.lastname && !user.nationalCode) {
            setIsFilled((prevState) => ({
                ...prevState,
                fullName: true,
            }));
        } else if (!user.name && !user.lastname && user.nationalCode) {
            setIsFilled((prevState) => ({
                ...prevState,
                nationalCode: true,
            }));
        }
    }, [user]);

    const handleSubmitInfo = () => {
        if (userInfo.name?.length === 0 || userInfo.name === null) {
            toast.error("نام خود را وارد نمایید.");
            return;
        } else if (
            userInfo.lastname?.length === 0 ||
            userInfo.lastname === null
        ) {
            toast.error("نام‌خانوادگی خود را وارد نمایید.");
            return;
        } else if (
            isNationalCodeRequired &&
            (userInfo.nationalCode?.length === 0 ||
                userInfo.nationalCode === null)
        ) {
            toast.error("کد ملی خود را وارد نمایید.");
            return;
        } else if (
            isNationalCodeRequired &&
            userInfo.nationalCode?.length !== 10
        ) {
            toast.error("کد ملی خود را به درستی وارد نمایید.");
            return;
        } else {
            setUpdateLoading(true);

            if (useForCitizen) {
                UpdateMateInfoCitizenAPI(userInfo)
                    .then((response) => {
                        if (response.status === 200) {
                            toast.success("اطلاعات با موفقیت ثبت شد");
                            setShowUpdate(true);
                            getUser();
                            setUpdateLoading(false);
                        }
                    })
                    .catch((err) => {
                        toast.error("خطایی در تکمیل اطلاعات پیش آمد.");
                    })
                    .finally(() => {
                        setUpdateLoading(false);
                    });
            } else if (introduceStoreStatus) {
                updateMateInfoIntroduceStoreAPI(userInfo)
                    .then((response) => {
                        if (response.status === 200) {
                            toast.success("اطلاعات با موفقیت ثبت شد");
                            setShowUpdate(true);
                            getUser();
                            setUpdateLoading(false);
                        }
                    })
                    .catch((err) => {
                        if (err.response.status === 406) {
                            toast.error(
                                "برای تکمیل اطلاعات خود، باید 7 ستاره باشید."
                            );
                            return;
                        }
                        toast.error("خطایی در تکمیل اطلاعات پیش آمد.");
                    })
                    .finally(() => setUpdateLoading(false));
            } else {
                updateMateInfoAPI(userInfo)
                    .then((response) => {
                        if (response.status === 200) {
                            toast.success("اطلاعات با موفقیت ثبت شد");
                            setShowUpdate(true);
                            getUser();
                            setUpdateLoading(false);
                        }
                    })
                    .catch((err) => {
                        toast.error("خطایی در تکمیل اطلاعات پیش آمد.");
                    })
                    .finally(() => setUpdateLoading(false));
            }
        }
    };

    // useEffect(() => {
    //   setUserInfo({
    //     name: user.name,
    //     lastname: user.lastname,
    //     nationalCode: user.nationalCode,
    //   });
    // }, [user]);

    return (
        <React.StrictMode>
            <Modal active={showUpdate} close={() => setShowUpdate(false)}>
                <>
                    <div className="title">نام</div>
                    <input
                        type="text"
                        className="input"
                        disabled={isFilled.fullName}
                        value={userInfo.name}
                        onChange={(e) =>
                            setUserInfo({
                                ...userInfo,
                                name: e.target.value,
                            })
                        }
                    />
                </>

                <>
                    <div className="title">نام خانوادگی</div>
                    <input
                        type="text"
                        className="input"
                        disabled={isFilled.fullName}
                        value={userInfo.lastname}
                        onChange={(e) =>
                            setUserInfo({
                                ...userInfo,
                                lastname: e.target.value,
                            })
                        }
                    />
                </>

                {isNationalCodeRequired && (
                    <>
                        <div className="title">کد ملی</div>
                        <input
                            type="tel"
                            className="input"
                            disabled={isFilled.nationalCode}
                            value={userInfo.nationalCode}
                            onChange={(e) =>
                                setUserInfo({
                                    ...userInfo,
                                    nationalCode: farsiDigitToEnglish(
                                        e.target.value
                                    ),
                                })
                            }
                        />
                    </>
                )}

                <div className="title">
                    مسئولیت صحت اطلاعات وارده، به عهده کاربر می‌باشد.
                </div>

                <div
                    className="button green"
                    onClick={() =>
                        updateLoading === false && handleSubmitInfo()
                    }
                >
                    {updateLoading ? (
                        <Dots color={"light"} />
                    ) : (
                        <span>ثبت اطلاعات</span>
                    )}
                </div>
            </Modal>
        </React.StrictMode>
    );
};
