import { CheckBox, SVG } from "components";
import React, { Children, useState } from "react";
import toast from "react-hot-toast";
import { fillProfileAPI } from "utils/Services/API";
import newStarSound from "../../../assets/sounds/star.mp3";
import { useUser } from "hooks";

const FillProfileMarital = ({ close }) => {
    const user_token = localStorage.getItem("user_token");
    const {
        userState: { user },
    } = useUser();
    const [marital, setMarital] = useState(0);
    const [children, setChildren] = useState(0);
    const [childrenCount, setChildrenCount] = useState(null);
    const [audio] = useState(new Audio(newStarSound));

    const handleActivateSubmit = () => {
        if (marital === 0 || (marital === 2 && children === 0)) return false;
        // else if (/*childrenCount?.length > 0 ||*/ children === 0)
        //   return false;
        else return true;
    };

    const handleSubmitForm = () => {
        if (handleActivateSubmit()) {
            const APIRequest =
                marital === 1
                    ? {
                          marital: marital,
                      }
                    : marital === 2 && children === 1
                    ? {
                          marital: marital,
                          maritalChildren: children,
                      }
                    : childrenCount !== null
                    ? {
                          marital: marital,
                          maritalChildren: children,
                          maritalChildrenCount: childrenCount,
                      }
                    : {
                          marital: marital,
                          maritalChildren: children,
                      };

            const APIResult = fillProfileAPI(user_token, "marital", APIRequest);
            APIResult.then((response) => {
                if (response.status === 200) {
                    toast.success("اطلاعات ستاره ثبت شد.");
                    if (user.detail.isSound) {
                        audio.play();
                    }
                    close(true);
                }
            });
        } else {
            toast.error(
                "برای دریافت امتیاز، لطفا تمامی مقادیر را وارد نمایید."
            );
        }
    };

    return (
        <div className="fill-profile__form">
            <audio src={newStarSound} autoPlay={false}></audio>

            <div className="row">
                <div className="row" style={{ marginLeft: 16 }}>
                    <CheckBox
                        disable
                        defaultValue={marital === 1 && true}
                        onChange={(value) => {
                            if (value) {
                                setMarital(1);
                                setChildren(0);
                            }
                        }}
                    />
                    <div className="fill-profile__form__title">مجرد</div>
                </div>

                <div className="row">
                    <CheckBox
                        disable
                        defaultValue={marital === 2 && true}
                        onChange={(value) => value && setMarital(2)}
                    />
                    <div className="fill-profile__form__title">متاهل</div>
                </div>
            </div>

            {marital === 2 && (
                <div className="row">
                    <div className="row" style={{ marginLeft: 16 }}>
                        <CheckBox
                            disable
                            defaultValue={children === 1 && true}
                            onChange={(value) => {
                                if (value) {
                                    setChildren(1);
                                    setChildrenCount(null);
                                }
                            }}
                        />
                        <div className="fill-profile__form__title">
                            فرزند ندارم
                        </div>
                    </div>

                    <div className="row">
                        <CheckBox
                            disable
                            defaultValue={children === 2 && true}
                            onChange={(value) => value && setChildren(2)}
                        />
                        <div className="fill-profile__form__title">
                            دارای فرزند هستم
                        </div>
                    </div>
                </div>
            )}

            {children === 2 && (
                <React.StrictMode>
                    <div className="fill-profile__form__title">
                        {/* <span className="required" /> */}
                        تعداد فرزند
                    </div>
                    <input
                        placeholder="اختیاری"
                        type="number"
                        className="fill-profile__form__input"
                        value={childrenCount}
                        onChange={(e) => setChildrenCount(e.target.value)}
                    />
                </React.StrictMode>
            )}

            <div
                className={`fill-profile__form__button ${
                    handleActivateSubmit() && "active"
                }`}
                onClick={() => handleSubmitForm()}
            >
                <div className="fill-profile__form__button__shape">
                    <SVG
                        svg="bottom-shape"
                        color={handleActivateSubmit() ? "#21bbe1" : "#b3bdcc"}
                    />
                </div>
                ثبت
                <div className="fill-profile__form__button__star">
                    <SVG svg="profile-star" />
                </div>
            </div>
        </div>
    );
};

export default FillProfileMarital;
