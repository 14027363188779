import { Dots, PageTopbar, Selectbox, SVG } from "../../components";
import React, { useEffect, useState } from "react";
import {
    getCitiesAPI,
    getCountriesAPI,
    getHintAPI,
    getStatesAPI,
} from "../../utils";
import toast from "react-hot-toast";
import { Item } from "../KartLandContainer/dependencies";
import { currency, farsiDigitToEnglish } from "../../utils/Functions";
import { useHistory } from "react-router-dom";
import {
    getUnitsByCityAPI,
    getUserUnitsAPI,
} from "../../utils/Services/KartlandUnits";

export const KartlandUserBuyContainer = () => {
    const [unitCode, setUnitCode] = useState<any>("");
    const [data, setData] = useState<any>({
        countries: [],
        states: [],
        cities: [],
        subCities: [],
        districts: [],
    });
    const [unitDetail, setUnitDetail] = useState({
        country: 1,
        state: 0,
        city: 0,
    });
    const [unitsForSale, setUnitsForSale] = useState([]);
    const [unitsLoading, setUnitsLoading] = useState(false);
    const history = useHistory();
    const [order, setOrder] = useState("newest");

    const orderList = [
        {
            name: "جدیدترین",
            id: "newest",
        },
        {
            name: "قدیمی‌ترین",
            id: "oldest",
        },
        {
            name: "گران‌ترین",
            id: "expensive",
        },
        {
            name: "ارزان‌ترین",
            id: "cheapest",
        },
    ];

    const getCountries = () => {
        getCountriesAPI()
            .then((response) => {
                setData({ ...data, countries: response.data });
            })
            .catch(() => {
                toast.error("خطا در بارگذاری کشورها");
            })
            .finally(() => {});
    };

    const getStates = (countryId) => {
        getStatesAPI(countryId)
            .then((response) => {
                setData({ ...data, states: response.data });
            })
            .catch(() => toast.error("خطا در بارگذاری استان‌ها"))
            .finally(() => {});
    };

    const getCities = (stateId) => {
        getCitiesAPI(stateId)
            .then((response) => {
                setData({ ...data, cities: response.data });
            })
            .catch(() => toast.error("خطا در بارگذاری شهرستان‌ها"))
            .finally(() => {});
    };

    const getUnitsByCity = (state, city) => {
        setUnitsLoading(true);
        getUnitsByCityAPI(order, state, city)
            .then((res) => {
                if (res.status === 200) {
                    setUnitsForSale(res.data);
                }
            })
            .catch((err) => {})
            .finally(() => setUnitsLoading(false));
    };

    useEffect(() => {
        getCountries();
        getStates(1);
    }, []);

    useEffect(() => {
        getUnitsByCity(unitDetail.state, unitDetail.city);
    }, [order, unitDetail.city, unitDetail.state]);

    useEffect(() => {
        history.replace("/kartland/user-buy");
    }, []);

    return (
        <div className="kartland">
            <PageTopbar title="خرید ویترین" showKv={false} white={true} />

            <div
                className={"kartland__user-buy-wrapper"}
                style={{ paddingBottom: 12 }}
            >
                <div className={"title white"}>کد ویترین</div>
                <input
                    type="text"
                    className="input"
                    value={unitCode}
                    onChange={(e) =>
                        setUnitCode(farsiDigitToEnglish(e.target.value))
                    }
                    placeholder="کد ویترین"
                    maxLength={6}
                />
                <div
                    onClick={() =>
                        unitCode.length === 6 &&
                        history.push(
                            `/kartland/details/${unitCode.replace(/^0+/, "")}`
                        )
                    }
                    className={`button ${unitCode.length !== 6 && "disable"}`}
                >
                    ثبت کد ویترین
                </div>
            </div>

            <div
                className={"kartland__user-buy-wrapper"}
                style={{ paddingBottom: 8 }}
            >
                <div className={"kartland-buy__user-filter3"}>
                    {/* <div className={"kartland-buy__user-filter__item"}>
            <div className="title white">
              کشور
            </div>
            <Selectbox
              data={data.countries} 
              placeholder="انتخاب کشور"
              onChange={value => {
                setUnitDetail({ ...unitDetail, country: value });
                getStates(value);
              }}
              onChangeString={value => {
                console.log(value);
              }}
            />
          </div> */}
                    <div className={"kartland-buy__user-filter3__item"}>
                        {unitDetail.country !== 0 && (
                            <React.Fragment>
                                <div className="title white">استان</div>
                                <Selectbox
                                    data={data.states}
                                    placeholder="همه"
                                    onChange={(value) => {
                                        setUnitDetail({
                                            ...unitDetail,
                                            state: value,
                                        });
                                        getCities(value);
                                    }}
                                    onChangeString={(value) => {
                                        console.log(value);
                                    }}
                                    disabled={unitDetail.country === 0}
                                    openAll={unitDetail.state !== 0}
                                />
                            </React.Fragment>
                        )}
                    </div>
                    <div className={"kartland-buy__user-filter3__item"}>
                        {unitDetail.state !== 0 && (
                            <React.Fragment>
                                <div className="title white">شهرستان</div>
                                <Selectbox
                                    data={data.cities}
                                    placeholder="همه"
                                    onChange={(value) => {
                                        setUnitDetail({
                                            ...unitDetail,
                                            city: value,
                                        });
                                    }}
                                    onChangeString={(value) => {
                                        console.log(value);
                                    }}
                                    disabled={unitDetail.state === 0}
                                    openAll={unitDetail.city !== 0}
                                />
                            </React.Fragment>
                        )}
                    </div>
                </div>
                <div className={"kartland-buy__user-filter2"}>
                    <div className={"kartland-buy__user-filter3__item"}>
                        {unitDetail.country !== 0 && (
                            <React.Fragment>
                                <div className="title white">
                                    مرتب سازی بر اساس:
                                </div>
                                <Selectbox
                                    data={orderList}
                                    placeholder="مرتب سازی"
                                    defaultValue={"جدیدترین"}
                                    onChange={(value) => {
                                        setOrder(value);
                                    }}
                                    onChangeString={(value) => {}}
                                    openAll={true}
                                    canChange={false}
                                />
                            </React.Fragment>
                        )}
                    </div>
                </div>
                {unitsLoading ? (
                    <Dots color="light" />
                ) : unitsForSale.length > 0 ? (
                    <div className={"kartland-buy__user-items"}>
                        <div className="kartland__item__wrapper">
                            {unitsForSale.map((item, index) => (
                                <div
                                    onClick={() =>
                                        history.push(
                                            `/kartland/details/${item.id}`
                                        )
                                    }
                                    className="kartland__item-detail"
                                >
                                    <div className="kartland__item">
                                        <div className="kartland__item__cover">
                                            <SVG svg="location" />
                                        </div>
                                        <div className="kartland__item__text">
                                            <div className="kartland__item__text__title">
                                                <span className="kartland__item__text__title__value">
                                                    {`ویترین ${item.unitnumber}`}
                                                </span>
                                                <span className="kartland__item__text__title__slug">
                                                    {`( ${item.statename} , ${item.cityname} )`}
                                                </span>
                                            </div>
                                            <div
                                                className={`kartland__item__text__status ${
                                                    item.isRent && "rented"
                                                }`}
                                            >
                                                <div
                                                    className={`kartland__item__text__status__dot ${
                                                        item.isRent && "rented"
                                                    }`}
                                                ></div>
                                                <span>
                                                    {item.isRent === 0
                                                        ? "تخلیه"
                                                        : "محصول دارد"}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"kartland__item__price"}>
                                        <div
                                            className={
                                                "kartland__item__price__title"
                                            }
                                            style={{ color: "#eb4d4c" }}
                                        >
                                            قیمت:
                                        </div>
                                        <div
                                            className={
                                                "kartland__item__price__value"
                                            }
                                            style={{ color: "#eb4d4c" }}
                                        >
                                            {currency(item.sellPrice)} تومان
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                ) : (
                    <div
                        className="title white"
                        style={{ margin: "auto", textAlign: "center" }}
                    >
                        واحدی برای فروش یافت نشد
                    </div>
                )}
            </div>
        </div>
    );
};
