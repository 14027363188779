import React, { useEffect, useState } from "react";
import {
  Card,
  CircleHint,
  Dots,
  Fade,
  Modal,
  PageTopbar,
  Setting,
  Spinner,
  TopBar,
} from "components";
import { NotFound, Buy, Actions, Item, ProfileSlider } from "./dependencies";
import { useLocation, useHistory, Link } from "react-router-dom";
import { getUserUnitsAPI } from "../../utils/Services/KartlandUnits";
import { getHintAPI, getUserStoreAPI } from "utils";
import toast from "react-hot-toast";
import { useUser } from "hooks";

export const KartLandContainer = ({ children }) => {
  const history = useHistory();

  const [hint1, setHint1] = useState<any>();
  const [hint2, setHint2] = useState<any>();
  const [loading, setLoading] = useState(true);
  const [fullhint, setFullhint] = useState(false);
  const [actions, setActions] = useState(false);
  const [activeMenu, setActiveMenu] = useState(false);

  const getHint1 = () => {
    getHintAPI("راهنمای کلی 8")
      .then((response) => {
        setHint1(response);
      })
      .catch(() => setHint1(false))
      .finally(() => setLoading(false));
  };

  // const getHint2 = () => {
  //     getHintAPI("کارتلند جدید")
  //         .then((response) => {
  //             setHint2(response);
  //         })
  //         .catch(() => setHint2(false))
  //         .finally(() => setLoading(false));
  // };

  useEffect(() => {
    history.replace("/kartland" + history.location.search);
    getHint1();
  }, []);

  const {
    userState: { user },
  } = useUser();

  const [myShops, setMyShops] = useState([]);

  const getMyShops = () => {
    getUserStoreAPI()
      .then((response) => {
        setMyShops([response.data]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getMyShops();
  }, []);

  const handleShopClick = () => {
    if (myShops?.length === 0) {
      history.push("/createProvider");
    } else if (
      myShops?.length > 0 &&
      myShops[0].isActive === 0 &&
      user?.detail?.internetStore === null
    ) {
      toast.error("درخواست شما در حال بررسی است.");
    } else if (
      myShops?.length > 0 &&
      myShops[0].isActive === 1 &&
      user?.detail?.internetStore === null
    ) {
      history.replace("/create-admin-store");
    } else if (myShops?.length > 0 && user?.detail?.internetStore === true) {
      window.open("https://adminstore.kartaak.com/", "_blank").focus();
    }
  };

  return (
    <Fade>
      <Spinner active={loading} color="dark" />
      <div className="kartland kartland-main">
        {/* <PageTopbar
                    title="کارتلند"
                    showKv={false}
                    white={true}
                    circleHint={hint2?.content}
                    titleColor="black"
                /> */}
        <Setting kartland={true} active={activeMenu} setClose={setActiveMenu} />
        <TopBar menuStatus={false} toggleMenu={setActiveMenu} />
        <div className={`kartland__wrapper`}>
          <div className={`kartland-buy__slider`}>
            <ProfileSlider />
          </div>
          <div className={"kartland-buy__hint"}>
            {loading ? (
              <Dots color="dark" />
            ) : (
              <div>
                {/* <div className="title">{hint.title}</div> */}
                {/* <div className="kartland-buy__hint__text">{hint.content}</div> */}
                {fullhint
                  ? hint1?.content
                  : `${hint1?.content?.slice(0, 100)}... `}
                {!fullhint ? (
                  <span
                    style={{ color: "#fd6547" }}
                    onClick={() => setFullhint(true)}
                    className=""
                  >
                    ادامه مطلب
                  </span>
                ) : (
                  <span
                    style={{ color: "#fd6547" }}
                    onClick={() => setFullhint(false)}
                    className=""
                  >
                    بستن
                  </span>
                )}
              </div>
            )}
          </div>
          <div className={`kartland-buy__btn`}>
            {/* <button>
                            <Link to={"/kartland/buy"}>فورشاپ</Link>
                        </button> */}
            <button>
              <Link to={`/kartland/user-buy`}>ویترین</Link>
            </button>
            <button>
              <Link to={`/kartland/code-buy`}>فورشاپ</Link>
            </button>
          </div>
          {/* <button
                            onClick={() => {
                                toast.error(
                                    "در حال حاضر واحدی برای مشارکت موجود نمی‌باشد."
                                );
                                //history.push('/kartland/participation');
                            }}
                        >
                            مشارکت
                        </button> */}
        </div>

        {/*<Actions data={handleActions()} />*/}
        <div className={"kartland__actions"}>
          {/* <div onClick={() => history.push('/kartland/buy/options')} className={"button green"} style={{ marginLeft: 16 }}>خرید واحدهای کارتک</div>
          <div onClick={() => history.push('/kartland/user-buy')} className={"button green"}>خرید واحدهای کاربران</div> */}
          {/* <div onClick={() => setActions(!actions)} className={"button kartland-buy__actions"} >خرید واحد</div> */}
          {/* {actions && (
            <div className={"kartland-buy__actions"}>
              <Link to={'/kartland/buy'} className={"button green"}>خرید از کارتک</Link>
              <Link to={`/kartland/user-buy`} className={"button green"}>خرید از کاربران</Link>
            </div>
          )} */}
          {/* <div className={"kartland-buy__actions"}>
                        <Link to={"/kartland/buy"} className={"button"}>
                            خرید فورشاپ
                        </Link>
                        <Link
                            to={`/kartland/user-buy`}
                            className={"button red"}
                        >
                            خرید ویترین
                        </Link>
                        <Link
                            to={`/kartland/code-buy`}
                            className={"button green"}
                        >
                            خرید ویژه
                        </Link>
                    </div> */}
          {/* <div className={"kartland-buy__actions"}>
                        <div
                            className={"button"}
                            onClick={() => history.push("/addnewusers")}
                            style={{ backgroundColor: "#17c7a7" }}
                        >
                            دعوت از دوستان جدید
                        </div>
                    </div> */}

          <div
            onClick={() => history.push("/kartland/buy/options")}
            className={"button kartland-buy__actions"}
          >
              مدیریت شعبه
          </div>
          <div className={"kartland-buy__actions"}>
            <div
              onClick={() => history.push("/kartland/my-citizen")}
              className={"button"}
            >
              سیتیزن‌های من
            </div>
            {/* <div onClick={() => history.push('/kartland/participation')} className={"button"}>مشارکت</div> */}
          </div>
          <div style={{marginBottom:20}} className="button">
            <Link style={{width:'100%'}} to={`/cash`}>
              حسابداری
            </Link>
          </div>
          <div
            style={{ cursor: "pointer" }}
            className="button"
            onClick={() => {
              handleShopClick();
            }}
          >
            {(myShops?.length === 0 || myShops[0]?.isActive === 0) &&
              "تامین‌کنندگی"}
            {myShops?.length > 0 &&
              user?.detail?.internetStore === null &&
              myShops[0].isActive === 1 &&
              "تکمیل اطلاعات"}
            {myShops?.length > 0 &&
              user?.detail?.internetStore === true &&
              "ادمین استور"}
          </div>
        </div>

        {/* <Modal active={actions} close={() => setActions(!actions)}> */}
        {/* <div className={"kartland-buy__actions"}>
                    <button
                        style={{ fontFamily: "inherit" }}
                        className={"button red"}
                        onClick={() => {
                            setActions(!actions);
                        }}
                    >
                        بستن
                    </button>
                </div> */}
        {/* </Modal> */}
      </div>
    </Fade>
  );
};
