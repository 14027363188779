import { Selectbox, SVG } from "components";

export const Buy = () => {
  return (
    <div className="kartland__buy">
      <label>دسته بندی</label>
      <Selectbox
        data={[]}
        placeholder="انتخاب دسته بندی"
        onChange={(value) => {
          console.log(value);
        }}
        onChangeString={() => {}}
      />
      <div className="kartland__buy__2cols">
        <div className="kartland__buy__2cols__item">
          <label>استان</label>
          <Selectbox
            data={[]}
            placeholder="انتخاب استان"
            onChange={(value) => {
              console.log(value);
            }}
            onChangeString={() => {}}
          />
        </div>
        <div className="kartland__buy__2cols__item">
          <label>واحد</label>
          <Selectbox
            data={[]}
            placeholder="انتخاب واحد"
            onChange={(value) => {
              console.log(value);
            }}
            onChangeString={() => {}}
          />
        </div>
      </div>
      <div className="kartland__buy__info">
        <div className="row a-c kartland__buy__info__item red">
          <SVG svg="kartland-sold" />
          <span className="ml-4">تعداد واحد های فروش رفته :</span>
          <span>70</span>
        </div>
        <div className="row a-c kartland__buy__info__item green">
          <SVG svg="kartland-available" />
          <span className="ml-4">تعداد واحد های موجود :</span>
          <span>130</span>
        </div>
      </div>
      <div className="kartland__buy__price">
        <span className="kartland__buy__price__title">پرداخت نهایی :</span>
        <span className="kartland__buy__price__value">50 KM</span>
      </div>
    </div>
  );
};
