import {Card, CounterInput, Dots, Fade, Modal, PageTopbar, Spinner, SVG} from "../../../components";
import React, {useEffect, useRef, useState} from "react";
import toast from "react-hot-toast";
import {useHistory} from "react-router-dom";
import {Tabs} from "../../OrdersContainer/dependencies";
import {useUser} from "../../../hooks";
import {buyConsultantCodeAPI, consultantPriceAPI, getBlueKmPriceAPI, myConsultantCodesAPI} from "../../../utils";
import {currency} from "../../../utils/Functions";

export const ConsultantCode = () => {
  const history = useHistory();
  const {userState: {user}} = useUser();
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(1);
  const [myCodes, setMyCodes] = useState([]);
  const [showBuyModal, setShowBuyModal] = useState(false);
  const count = useRef(null);
  const [codeCount, setCodeCount] = useState(1);
  const [codePrice, setCodePrice] = useState<any>();
  const [priceLoading, setPriceLoading] = useState(false);
  const [buyLoading, setBuyLoading] = useState(false);

  let tabs = [
    {
      title: "فعال",
      icon: "scores",
    },
    {
      title: "استفاده شده",
      icon: "waiting",
    },
  ];

  const getConsultantCodes = (type) => {
    setLoading(true);
    myConsultantCodesAPI(type).then(res => {
      if (res.status === 200) {
        setMyCodes(res.data);
      }
    }).catch(err => {
      toast.success('خطایی در دریافت اطلاعات رخ داده است.');
    }).finally(() => setLoading(false));
  };

  const copy = (element) => {
    const value = document.getElementById(element);
    //@ts-ignore
    value.select();
    document.execCommand("copy");

    toast.success("کد مشاوره شما کپی شد!");
  };

  const getCodePrice = () => {
    setPriceLoading(true);
    consultantPriceAPI()
      .then((res) => {
        if (res.status === 200) {
          setCodePrice(res.data);
        }
      })
      .catch((err) => {
        toast.error("خطایی رخ داد");
      })
      .finally(() => setPriceLoading(false));
  };

  const buyCode = () => {
    setBuyLoading(true);
    buyConsultantCodeAPI(codeCount).then(res => {
      if (res.status === 200) {
        toast.success('کد با موفقیت خریداری شد');
        setShowBuyModal(false);
        getConsultantCodes('active');
      }
    }).catch(err => {
      toast.error('موجودی KM شما کافی نیست!');
    }).finally(() => setBuyLoading(false));
  };

  useEffect(() => {
    history.replace('/transaction/consultant');
    getCodePrice();
  }, []);

  useEffect(() => {
    if (activeTab === 1) {
      getConsultantCodes('active');
    }
    if (activeTab === 2) {
      getConsultantCodes('deActive');
    }
  }, [activeTab]);

  return (
    <Fade>
      <PageTopbar
        title="کد مشاوره"
        showKv={false}
      />

      <div className="wrapper-fix">
        <Card>
          <div className="my-introduce__header">

            <div onClick={() => {
              setShowBuyModal(true);
            }} className={`my-introduce__create submiter`}>
              خرید کد مشاوره
            </div>
          </div>

          <Tabs
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            items={tabs}
          />

          <div className="content__list">
            {loading ? <Dots color={'dark'}/> :
              <>
                {myCodes?.map((item, index) => (
                  <div className={'consultant__item'}>
                    <div className="consultant__item__code">
                      <div className="flex">
                        <div>{`کد مشاوره شما: `}</div>
                        <input
                          readOnly
                          type="text"
                          value={item.cCode}
                          id={`code${index}`}
                          className="consultant__item__code__input"
                        />
                        {/*<div id={'code'}>{item.cCode}</div>*/}
                      </div>

                      {item.kartlandStore_id === null &&
                        <div className="panel__box__info__icon" onClick={() => copy(`code${index}`)}>
                          <SVG svg="copy"/>
                        </div>}
                    </div>

                    {item?.kartlandStore_id !== null && <div className="consultant__item__store">
                      {`استفاده شده توسط فروشگاه: ${item?.storeInfo?.name}`}
                    </div>}

                  </div>
                ))}
              </>
            }
          </div>
        </Card>
      </div>

      <Modal active={showBuyModal} close={() => setShowBuyModal(false)}>
        <div className="buy-blue">
          <div className="buy-blue__count">
            <div className="title">تعداد کد</div>
            <CounterInput
              ref={count}
              defaultValue={1}
              step={1}
              max={99}
              onChange={(value) => setCodeCount(value)}
              kmMode={true}
            />
          </div>
          {!priceLoading && (
            <div className="buy-blue__price">
              <div className="">قیمت:</div>
              <div className="">{currency(codeCount * codePrice?.wkm)} KM</div>
            </div>
          )}
        </div>

        <div className="title">{`میزان هدیه دریافتی به ازای هر کد:  ${codePrice?.rkm} KP`}</div>

        <div
          className={`button ${codeCount < 1 && "disable"}`}
          onClick={() => !buyLoading && buyCode()}
        >
          {buyLoading ? <Dots color="light"/> : "پرداخت"}
        </div>
      </Modal>
    </Fade>
  );
};