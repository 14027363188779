import {
    AcceptRule,
    CheckBox,
    CounterInput,
    Dots,
    Fade,
    Modal,
    PageTopbar,
    Selectbox,
    SVG,
    UpdateUserInfo,
} from "../../components";
import React, { useEffect, useRef, useState } from "react";
import {
    getCitiesAPI,
    getCountriesAPI,
    getHintAPI,
    getStatesAPI,
    GetWhiteKmPrice,
} from "../../utils";
import {
    CheckBuyUnitWithKm,
    checkSpecialCodeAPI,
    CheckUserIsCitizenAPI,
} from "utils/Services/KartlandUnits";
import toast from "react-hot-toast";
import { checkPanelCodeAPI } from "../../utils/Services/API";
import {
    buyFromKartaakAPI,
    getCashUnitCountAPI,
    getKmUnitCountAPI,
    getUnitsCountByCityAPI,
} from "../../utils/Services/KartlandUnits";
import { useHistory, useLocation } from "react-router-dom";
import { currency, farsiDigitToEnglish } from "../../utils/Functions";
import { useUser } from "../../hooks";
import axios from "axios";

export const KartlandCodeBuyContainer = () => {
    const user_token = localStorage.getItem("user_token");

    const [unitDetail, setUnitDetail] = useState({
        name: "",
        code: "",
        country: 0,
        state: 0,
        city: 0,
        count: 1,
    });
    const [inviterCode, setInviterCode] = useState("");
    const [specialCode, setSpecialCode] = useState("");
    const {
        getUserDataFromApi,
        userState: { user },
    } = useUser();
    const [showFillProfile, setShowFillProfile] = useState(false);
    const [showFillProfileModal, setShowFillProfileModal] = useState(false);
    const [userInfo, setUserInfo] = useState({
        name: "",
        lastname: "",
        nationalCode: "",
    });
    const [payWhiteKm, setPayWhiteKm] = useState<any>();
    const [specialCodeStatus, setSpecialCodeStatus] = useState(false);
    const [specialCodeLoading, setSpecialCodeLoading] = useState(false);
    const [specialCodePercent, setSpecialCodePercent] = useState(0);
    const [priceToPay, setPriceToPay] = useState();
    const [data, setData] = useState<any>({
        countries: [],
        states: [],
        cities: [],
        subCities: [],
        districts: [],
    });
    const [submitLoading, setSubmitLoading] = useState(false);
    const [checkoutType, setCheckoutType] = useState("cash");
    const [currCity, setCurrCity] = useState("");
    const [unitsCount, setUnitsCount] = useState({
        totalUnit: 0,
        allSoldOutUnit: 0,
        remainingUnit: 0,
        donateSoldOutUnit: 0,
        allCashUnit: 0,
        remainingCashUnit: 0,
        cashSoldOutUnit: 0,
        allKmUnit: 0,
        remainingKmUnit: 0,
        kmSoldOutUnit: 0,
        price: 0,
    });
    const [payPrice, setPayPrice] = useState<any>(0);
    const [whiteKmPrice, setWhiteKmPrice] = useState(0);
    const history = useHistory();
    const [showAlert, setShowAlert] = useState(false);
    const [hint, setHint] = useState<any>("");
    const [hintLoading, setHintLoading] = useState(true);
    const [readCompleted, setreadCompleted] = useState(false);
    const [activeStateName, setActiveStateName] = useState("");
    const [acceptedRule, setAcceptedRule] = useState(false);
    const [activeCityName, setActiveCityName] = useState("");
    const [showBuyUnitWithKm, setShowBuyUnitWithKm] = useState(false);

    useEffect(() => {
        setPriceToPay(
            currency((payPrice * 16 * (100 - specialCodePercent)) / 100)
        );
    }, [currency, payPrice]);

    const getHint = () => {
        getHintAPI("قوانین و هشدارها 3")
            .then((response) => {
                setHint(response);
            })
            .catch(() => setHint(false))
            .finally(() => setHintLoading(false));
    };

    const getStates = (countryId) => {
        getStatesAPI(countryId)
            .then((response) => {
                setData({ ...data, states: response.data });
            })
            .catch(() => toast.error("خطا در بارگذاری استان‌ها"))
            .finally(() => {});
    };

    const getCities = (stateId) => {
        getCitiesAPI(stateId)
            .then((response) => {
                setData({ ...data, cities: response.data });
            })
            .catch(() => toast.error("خطا در بارگذاری شهرستان‌ها"))
            .finally(() => {});
    };

    const handleSubmitSpecialCode = () => {
        setSpecialCodeLoading(true);

        axios
            .post(
                `https://api.kartaak.com/api/v4/kartland/unit/discounts?code=${specialCode}`
            )
            .then((checkSpecialCodeResponse) => {
                if (
                    checkSpecialCodeResponse.status === 200 &&
                    !checkSpecialCodeResponse.data.is_use &&
                    !checkSpecialCodeResponse.data.is_expire
                ) {
                    toast.success("کد ویژه‌ی فورشاپ با موفقیت ثبت شد");
                    setSpecialCodeStatus(true);
                    setSpecialCodePercent(
                        checkSpecialCodeResponse.data.discount_percent
                    );
                    setUnitDetail({ ...unitDetail, country: 1 });
                } else if (checkSpecialCodeResponse.data.is_use) {
                    toast.error("کد ویژه قبلا استفاده شده است");
                } else if (checkSpecialCodeResponse.data.is_expire) {
                    toast.error("کد ویژه منقضی شده است");
                }
            })
            .catch((err) => {
                toast.error("کد ویژه یافت نشد");
            })
            .finally(() => {
                setSpecialCodeLoading(false);
            });

        //TODO : add special code api

        // checkSpecialCodeAPI("KHQ6jmP5lyO9")
        //     .then((checkSpecialCodeResponse) => {
        //         if (checkSpecialCodeResponse.status === 200) {
        //             console.log("code 200");
        //             console.log("submitted");
        //             toast.success("کد ویژه‌ی فورشاپ با موفقیت ثبت شد");
        //             setSpecialCodeStatus(true);
        //             setUnitDetail({ ...unitDetail, country: 1 });
        //         } else if (checkSpecialCodeResponse === 404) {
        //             console.log("code 404");
        //         }
        //     })
        //     .catch((err) => {
        //         console.log(err);
        //     })
        //     .finally(() => {
        //         setSpecialCodeLoading(false);
        //     });
    };

    const getUnistCount = () => {
        getUnitsCountByCityAPI(unitDetail.city)
            .then((res) => {
                if (res.status === 200) {
                    setUnitsCount(res.data);
                }
            })
            .catch(() => {
                toast.error("error");
            })
            .finally();
    };

    const fetchWhiteKmPrice = () => {
        GetWhiteKmPrice()
            .then((res) => {
                if (res) {
                    setWhiteKmPrice(Number(res.data));
                }
            })
            .catch((err) => {
                if (err) toast.error("به مشکل برخوردیم");
            });
    };

    const handleSubmitUnit = () => {
        if (unitDetail.country === 0) {
            toast.error("کد ویژه را وارد نمایید.");
            return;
        }

        if (unitDetail.state === 0) {
            toast.error("استان را انتخاب کنید.");
            return;
        }

        if (unitDetail.city === 0) {
            toast.error("شهرستان را انتخاب کنید.");
            return;
        }

        setSubmitLoading(true);

        //TODO : special code api to container

        axios
            .post(
                `https://api.kartaak.com/api/v4/kartland/unit/discounts?code=${specialCode}`
            )
            .then((checkSpecialCodeResponse) => {
                if (
                    checkSpecialCodeResponse.status === 200 &&
                    !checkSpecialCodeResponse.data.is_use &&
                    !checkSpecialCodeResponse.data.is_expire
                ) {
                    setSpecialCodeStatus(true);
                    setSpecialCodePercent(
                        checkSpecialCodeResponse.data.discount_percent
                    );
                    setUnitDetail({ ...unitDetail, country: 1 });
                    buyFromKartaakFunc();
                } else if (checkSpecialCodeResponse.data.is_use) {
                    toast.error("کد ویژه قبلا استفاده شده است");
                    setSpecialCodeStatus(false);
                    setSpecialCode("");
                    setSpecialCodeStatus(false);
                    setUnitDetail({
                        name: "",
                        code: "",
                        country: 0,
                        state: 0,
                        city: 0,
                        count: 1,
                    });
                } else if (checkSpecialCodeResponse.data.is_expire) {
                    toast.error("کد ویژه منقضی شده است");
                    setSpecialCodeStatus(false);
                    setSpecialCode("");
                    setSpecialCodeStatus(false);
                    setUnitDetail({
                        name: "",
                        code: "",
                        country: 0,
                        state: 0,
                        city: 0,
                        count: 1,
                    });
                }
            })
            .catch((err) => {
                toast.error("کد ویژه یافت نشد");
                setSpecialCodeStatus(false);
                setSpecialCode("");
                setSpecialCodeStatus(false);
                setUnitDetail({
                    name: "",
                    code: "",
                    country: 0,
                    state: 0,
                    city: 0,
                    count: 1,
                });
            })
            .finally(() => {
                setSubmitLoading(false);
            });
    };

    const buyFromKartaakFunc = () => {
        buyFromKartaakAPI(
            checkoutType,
            payWhiteKm * 25,
            unitDetail.country,
            unitDetail.state,
            unitDetail.city,
            user.citizenInviter != null ? "" : inviterCode,
            checkoutType === "km" ? 16 : unitDetail.count * 16,
            specialCode
        )
            .then((response) => {
                if (
                    response.status === 200 &&
                    response.msg == "unit purchased from WhiteKM"
                ) {
                    toast.success("فورشاپ با موفقیت خریداری شد");
                    history.replace("/kartland");
                }
                if (
                    response.status === 200 &&
                    !response.data.payID &&
                    !response.data.tk
                ) {
                    toast.success("فورشاپ با موفقیت خریداری شد");
                    history.replace("/kartland");
                }
                if (
                    response.status === 200 &&
                    response.data.payID &&
                    response.data.tk
                ) {
                    toast.success("در حال انتقال به درگاه پرداخت...");

                    const paymentURL = `${process.env.REACT_APP_DEV_IP}api/v3/kartland/kartaakUnit/${response.data.payID}/payment/${response.data.tk}`;
                    history.push(`/pay?${paymentURL}`);
                    setTimeout(() => {
                        if (history.location.pathname.includes("/pay"))
                            window.location.href = `${paymentURL}`;
                    }, 600);
                }
            })
            .catch((err) => {
                if (err.response.data.msg == "you are not have 7 star") {
                    toast.error("شمار کاربر 7 ستاره نیستید");
                } else {
                    toast.error("مشکلی در خرید فورشاپ رخ داد");
                }
                // else if (
                //     err.response.data.data[0] ==
                //     "witheKm : whiteKM request is more than unitPrice"
                // ) {
                //     toast.error("ارزش KM وارد شده بیشتر از مبلغ فورشاپ است");
                // } else if (
                //     err.response.data.data[0] ==
                //     "The amount of Request whiteKM is more than the user whiteKm"
                // ) {
                //     toast.error("مقدار KM وارد شده بیشتر از موجودی KM شماست");
                // } else if (
                //     err.response.data.data[0] ==
                //     "The user bought the unit from whiteKm in the past"
                // ) {
                //     toast.error("شما قبلا فورشاپ با KM خریداری کرده‌اید");
                // } else if (
                //     err?.response?.data?.data[0]?.witheKm[0] ==
                //     "The withe km must be at least 1."
                // ) {
                //     toast.error("حداقل یک km باید داشته باشید.");
                // }
            })
            .finally(() => setSubmitLoading(false));
    };

    const UserInfoHandler = () => {
        if (
            user.name?.length === 0 ||
            user.name === null ||
            user.lastname?.length === 0 ||
            user.lastname === null ||
            user.nationalCode?.length === 0 ||
            user.nationalCode === null ||
            user.nationalCode?.length !== 10
        ) {
            setShowFillProfile(true);
        } else {
            setShowFillProfile(false);
        }
    };

    const checkBuyUnitKm = () => {
        CheckBuyUnitWithKm(Number(user.id), unitDetail.city)
            .then((res) => {
                if (res) {
                    if (
                        res.data[0] ==
                        "The user can buy the unit from whiteKm !"
                    ) {
                        setShowBuyUnitWithKm(true);
                    } else {
                        setShowBuyUnitWithKm(false);
                    }
                }
            })
            .catch((err) => {
                if (err) {
                    toast.error("به مشکل برخوردیم!");
                }
            });
    };

    useEffect(() => {
        getStates(1);
        getHint();
        fetchWhiteKmPrice();
    }, []);

    useEffect(() => {
        setUserInfo({
            name: user.name,
            lastname: user.lastname,
            nationalCode: user.nationalCode,
        });
        UserInfoHandler();
    }, [user]);

    useEffect(() => {
        if (unitDetail.city !== 0) {
            getUnistCount();
            checkBuyUnitKm();
        }
    }, [unitDetail.city]);

    useEffect(() => {
        let totalKm = (whiteKmPrice * payWhiteKm * 25) / 16;
        if (checkoutType == "km") {
            if (totalKm >= unitsCount.price) {
                setPayPrice(0);
            } else {
                setPayPrice(unitsCount.price - totalKm);
            }
        } else {
            setPayPrice(unitsCount.price * unitDetail.count);
        }
    }, [payWhiteKm, checkoutType, unitsCount.price, unitDetail.count]);

    useEffect(() => {
        document
            .getElementById("buyUnitAlert")
            ?.addEventListener("scroll", () => {
                document.getElementById("buyUnitAlert").scrollTop >
                document.getElementById("buyUnitAlert")?.scrollHeight -
                    document.getElementById("buyUnitAlert")?.clientHeight -
                    20
                    ? setreadCompleted(true)
                    : setreadCompleted(false);
            });
        return () => {
            document
                .getElementById("buyUnitAlert")
                ?.removeEventListener("scroll", () => {
                    document.getElementById("buyUnitAlert").scrollTop >
                    document.getElementById("buyUnitAlert")?.scrollHeight -
                        document.getElementById("buyUnitAlert")?.clientHeight -
                        20
                        ? setreadCompleted(true)
                        : setreadCompleted(false);
                });
        };
    }, [document.getElementById("buyUnitAlert")?.scrollTop]);

    useEffect(() => {
        getUserDataFromApi();
    }, []);

    return (
        <Fade>
            <Modal active={showAlert} close={(close) => setShowAlert(false)}>
                <div className="buy-panel__alert__title">{hint.title}</div>
                <div
                    className={`buy-panel__alert__description ${
                        readCompleted && "deactive"
                    }`}
                    id={"buyUnitAlert"}
                >
                    {hintLoading && <Dots color="dark" />}
                    {hint.content?.split("\n")?.map((str) => (
                        <div>{str}</div>
                    ))}
                </div>
                <div className="row buy-panel__alert__bottom">
                    <div
                        className={`buy-panel__alert__button  ${
                            readCompleted ? "accept" : "disabled"
                        }`}
                        onClick={() => {
                            if (readCompleted) {
                                setAcceptedRule(true);
                                setShowAlert(false);
                            } else {
                                document
                                    .getElementById("buyUnitAlert")
                                    .scrollTo(0, 2500);
                            }
                        }}
                    >
                        قوانین را می‌پذیرم
                    </div>
                    <div
                        className="buy-panel__alert__button reject"
                        onClick={() => setShowAlert(false)}
                    >
                        انصراف
                    </div>
                </div>
            </Modal>
            <UpdateUserInfo
                showUpdate={showFillProfileModal}
                setShowUpdate={(value) => {
                    value && handleSubmitUnit();
                    setShowFillProfileModal(false);
                }}
                userInfo={userInfo}
                setUserInfo={setUserInfo}
                getUser={getUserDataFromApi}
                useForCitizen={true}
            />
            <div className={"kartland"}>
                <PageTopbar
                    title="خرید فورشاپ با کد ویژه"
                    showKv={false}
                    white={true}
                />
                {/* sina changed this */}
                <button onClick={() =>  window.open("http://87.107.166.118:5001", "_blank").focus()} style={{width:45, border:'none', background:'white' , display:'flex' , justifyContent:'center' , alignItems:'center' , height:45 , fontSize:10 , position:'absolute' , top: 7 , left:10 , zIndex:9999, borderRadius: 10000}}>codato</button>
                <div className={"kartland__user-buy-wrapper"}>
                    {!unitDetail.code && (
                        <React.Fragment>
                            <div className="title white">کد ویژه</div>
                            <div className="row">
                                <input
                                    type="text"
                                    className="input"
                                    value={specialCode}
                                    onChange={(e) =>
                                        setSpecialCode(
                                            farsiDigitToEnglish(e.target.value)
                                        )
                                    }
                                    placeholder="لطفا کد ویژه‌ی خود را وارد نمایید"
                                    required={true}
                                />
                                <div
                                    className={`create-shop__inviter__button ${
                                        specialCodeStatus && "edit"
                                    } ${specialCode.length === 0 && "hidden"}`}
                                    onClick={() => {
                                        if (specialCodeStatus) {
                                            setSpecialCode("");
                                            setSpecialCodeStatus(false);
                                            setUnitDetail({
                                                name: "",
                                                code: "",
                                                country: 0,
                                                state: 0,
                                                city: 0,
                                                count: 1,
                                            });
                                        } else {
                                            handleSubmitSpecialCode();
                                        }
                                    }}
                                >
                                    {specialCodeLoading ? (
                                        <Dots color="light" />
                                    ) : specialCodeStatus ? (
                                        "ویرایش"
                                    ) : (
                                        "ثبت"
                                    )}
                                </div>
                            </div>
                        </React.Fragment>
                    )}

                    {unitDetail.country !== 0 && (
                        <React.Fragment>
                            <div className="title white">استان</div>
                            <Selectbox
                                data={data.states}
                                placeholder="انتخاب استان"
                                onChange={(value) => {
                                    setUnitDetail({
                                        ...unitDetail,
                                        state: value,
                                        city: 0,
                                    });
                                    getCities(value);
                                    history.replace(
                                        `/kartland/code-buy?country=${unitDetail.country}&state=${value}`
                                    );
                                }}
                                onChangeString={(value) => {
                                    setActiveStateName(value);
                                }}
                                disabled={unitDetail.country === 0}
                                openAll={unitDetail.state !== 0 ? true : false}
                            />
                        </React.Fragment>
                    )}

                    {unitDetail.state !== 0 && (
                        <React.Fragment>
                            <div className="title white">شهرستان</div>
                            <Selectbox
                                data={data.cities}
                                placeholder="انتخاب شهرستان"
                                onChange={(value) => {
                                    setUnitDetail({
                                        ...unitDetail,
                                        city: value,
                                    });
                                    setCheckoutType("cash");
                                    history.replace(
                                        `/kartland/code-buy?country=${unitDetail.country}&state=${unitDetail.state}&city=${value}`
                                    );
                                }}
                                onChangeString={(value) => {
                                    setCurrCity(value);
                                    setActiveCityName(value);
                                }}
                                disabled={unitDetail.state === 0}
                                openAll={unitDetail.city !== 0 ? true : false}
                            />
                        </React.Fragment>
                    )}

                    {unitDetail.city !== 0 && (
                        <div className={"mb-12"}>
                            <div className="kartland-buy__options mb-8">
                                {unitDetail.city !== 0 && (
                                    <div
                                        className="kartland-buy__options__header"
                                        onClick={() => {
                                            history.push(
                                                `/pay?https://fa.wikipedia.org/wiki/شهرستان_${activeCityName}`
                                            );
                                            setTimeout(() => {
                                                if (
                                                    history.location.pathname.includes(
                                                        "/pay"
                                                    )
                                                )
                                                    window.location.href = `https://fa.wikipedia.org/wiki/شهرستان_${activeCityName}`;
                                            }, 600);
                                        }}
                                    >
                                        شهرستان {activeCityName} را بیشتر
                                        بشناسید.
                                    </div>
                                )}
                            </div>
                        </div>
                    )}

                    {unitDetail.city !== 0 && (
                        <>
                            <div className="kartland-buy__specialPrice">
                                <div className="">قیمت هر فورشاپ:</div>
                                {` ${currency(unitsCount.price * 16)} تومان`}
                            </div>
                            <div className="kartland-buy__price">
                                <div className="">مبلغ قابل پرداخت:</div>
                                {priceToPay == 0 ? (
                                    <div className="">رایگان برای شما!</div>
                                ) : (
                                    <div className="">{priceToPay} تومان</div>
                                )}
                            </div>
                        </>
                    )}
                </div>

                <div className={"w-full absolute bottom-16"}>
                    <div className="row a-c j-c" style={{ margin: "8px auto" }}>
                        <CheckBox
                            defaultValue={acceptedRule}
                            type="check"
                            onChange={(value) =>
                                setAcceptedRule(value ? true : false)
                            }
                        />
                        <AcceptRule
                            title="خرید فورشاپ"
                            color="light"
                            onCLick={() => setShowAlert(true)}
                        />
                    </div>
                    <div
                        className={`button width-full-16`}
                        onClick={() => {
                            if (!acceptedRule) {
                                // toast.error(
                                //     "ابتدا قوانین و مقررات خرید فورشاپ را مطالعه و بپذیرید."
                                // );
                                setShowAlert(true);
                                return;
                            }
                            if (showFillProfile) {
                                setShowFillProfileModal(true);
                            } else {
                                !submitLoading && handleSubmitUnit();
                            }
                        }}
                    >
                        {submitLoading ? <Dots color="light" /> : "خرید فورشاپ"}
                    </div>
                </div>
            </div>
        </Fade>
    );
};
