import React, { createContext, useEffect, useState } from "react";
import { getCartAPI } from "../utils/Services/API";

export const AppContext = createContext();

export const AppContextProvider = ({ children }) => {
  const user_token = localStorage.getItem("user_token");
  const [userInfo, setUserInfo] = useState({ detail: { tokens: { kv: 0 } } });
  const [cart, setCart] = useState({ cart: null, items: [] });

  // const getCart = () => {
  //   const APIResult = getCartAPI(user_token);
  //   APIResult.then((response) => {
  //     if (response.status === 200) setCart(response.data.data);
  //     else {
  //       return;
  //     }
  //   });
  // };

  // useEffect(() => {
  //   getCart();
  // }, []);

  return (
    <AppContext.Provider
      value={{
        user: { get: userInfo, set: setUserInfo },
        cart: { get: cart, set: setCart },
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
