import React, { useEffect } from "react";
import "./ListItem.scss";
import { Link } from "react-router-dom";
import { SVG, Padding } from "components";

export const ListItem = ({ data }) => {
  var listItem = document.getElementById("list-item");

  const handleScroll = (event) => {
    // console.log('SCROLL Y' , window.scrollY);
    // console.log('SCREEN Y' , window.screenY);
    // console.log('SCREEN TOP' , window.screenTop);
    // console.log('PAGE OFFSET' , window.pageYOffset);
    // console.log('INNER' , window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return data.map((item, index) => (
    <Link
      key={index}
      to={item.path}
      className={`profile__item`}
      id="list-item"
    >
      <div className="profile__item__name">
        <SVG svg={item.icon.svg} color={item.color.icon} />
        {item.title}
      </div>
      <div
        className="list-item__link--item2"
        style={{ display: "flex", alignItems: "center" }}
      >
        <SVG svg="arrow" />
      </div>
    </Link>
  ));
};
