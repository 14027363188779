import React, { Suspense } from "react";
import "./_index.scss";
import { useState, useEffect } from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import { routes, PrivateRoutes } from "utils/routes";
import { ApplicationLayout } from "components/Layouts";
import { SetupInterceptors } from "utils/Services";
import figlet from "figlet";
import standard from "figlet/importable-fonts/Standard.js";
import ReactGA from "react-ga";
import useErrorBoundary from "use-error-boundary";

const App = () => {
    const [initialized, setInitialized] = useState(false);
    const location = useLocation();
    const { ErrorBoundary, didCatch, error, reset } = useErrorBoundary();

    SetupInterceptors();
    useEffect(() => {
        figlet.parseFont("Standard", standard);
        figlet.text(
            "Kartaak App",
            {
                font: "Standard",
            },
            function (err, data) {
                console.log(data);
            }
        );

        if (!window.location.href.includes("localhost")) {
            ReactGA.initialize("G-ZBCF21519C");
        }
        setInitialized(true);
    }, []);

    useEffect(() => {
        if (initialized) {
            ReactGA.pageview(location.pathname + location.search);
        }
    }, [initialized, location]);

    return (
        <ApplicationLayout>
            <Switch>
                {/*<Suspense fallback={<div>Loading...</div>}>*/}
                {routes.map((route, key) =>
                    route.private ? (
                        <PrivateRoutes key={key} {...route} />
                    ) : (
                        <Route key={key} {...route} />
                    )
                )}
                {/*</Suspense>*/}
            </Switch>
        </ApplicationLayout>
    );
};

export default App;
