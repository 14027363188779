import { SVG } from "components";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { farsiDigitToEnglish } from "utils/Functions";
import { fillProfileAPI } from "utils/Services/API";
import newStarSound from "../../../assets/sounds/star.mp3";
import { useUser } from "hooks";

const FillProfileAge = ({ close }) => {
    const user_token = localStorage.getItem("user_token");
    const {
        userState: { user },
    } = useUser();
    const [age, setAge] = useState(null);
    const [audio] = useState(new Audio(newStarSound));

    const handleActivateSubmit = () => {
        if (age === null || age?.length === 0) return false;
        else return true;
    };

    const handleSubmitForm = () => {
        if (age < 12 || age > 99) {
            toast.error("سن انتخابی مجاز نیست.");
            return null;
        }

        if (handleActivateSubmit()) {
            const APIResult = fillProfileAPI(user_token, "age", { age: age });
            APIResult.then((response) => {
                if (response.status === 200) {
                    toast.success("اطلاعات ستاره ثبت شد.");
                    if (user.detail.isSound) {
                        audio.play();
                    }
                    close(true);
                }
            });
        } else {
            toast.error("سن خود را به درستی وارد نمایید.");
        }
    };

    return (
        <div className={"fill-profile__form"}>
            <audio src={newStarSound} autoPlay={false}></audio>

            <div className={`${"fill-profile__form__title"}`}>سن</div>
            <input
                placeholder={"سن"}
                type="number"
                className="fill-profile__form__input"
                value={age}
                onChange={(e) => setAge(farsiDigitToEnglish(e.target.value))}
                onBlur={() => {
                    if ((age < 12 || age > 99) && age !== null) {
                        toast.error("سن انتخابی مجاز نیست.");
                    }
                }}
            />

            <div
                className={`fill-profile__form__button ${
                    handleActivateSubmit() && "active"
                }`}
                onClick={() => handleSubmitForm()}
            >
                <div className="fill-profile__form__button__shape">
                    <SVG
                        svg="bottom-shape"
                        color={handleActivateSubmit() ? "#21bbe1" : "#b3bdcc"}
                    />
                </div>
                ثبت
                <div className="fill-profile__form__button__star">
                    <SVG svg="profile-star" />
                </div>
            </div>
        </div>
    );
};

export default FillProfileAge;
