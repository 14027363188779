import React, { useState } from "react";
import {SVG} from "components";
import "./Comments.scss";
import moment from "jalali-moment";
import { dislikeCommentAPI, likeCommentAPI } from "../../utils/Services/API";
import toast from "react-hot-toast";
import {CreateComment} from "components";

export const Comments = ({
  active = false,
  setShow,
  parentComment = null,
  comments = [],
  productId,
}) => {
  const user_token = localStorage.getItem("user_token");
  const [showComment, setShowComment] = useState(false);
  const [replyData, setReplyData] = useState({
    productId: 0,
    commentId: 0,
  });

  const handleLikeComment = (commentId) => {
    const APIResult = likeCommentAPI(commentId, user_token);
    APIResult.then((response) => {
      if (response.status === 401) {
        toast.error("لطفا وارد حساب کاربری خود شوید.");
      } else {
        toast.success("نظر کاربر را پسندید.");
      }
    });
  };

  const handleDislikeComment = (commentId) => {
    const APIResult = dislikeCommentAPI(commentId, user_token);
    APIResult.then((response) => {
      if (response.status === 401) {
        toast.error("لطفا وارد حساب کاربری خود شوید.");
      } else {
        toast.success("نظر کاربر را نپسندید.");
      }
    });
  };

  const handleReplyComment = (comment) => {
    setReplyData({
      commentId: Number(comment.id),
      productId: productId,
    });
    setShowComment(true);
  };

  const showReplies = (comment) => {
    return comments.map((reply) => (
      <div className="replyes__item-wrapper reply">
        {Number(reply.parent_id) === Number(comment.id) && (
          <div className="replyes__item ">
            <div className="replyes__item__header">
              <div className="replyes__item__title">{reply.name}</div>
              <div className="replyes__item__date">
                {moment(reply.created_at, "YYYY/MM/DD")
                  .locale("fa")
                  .format("YYYY/MM/DD")}
              </div>
            </div>
            <div className="replyes__item__body">
              <div className="replyes__item__content">{reply.comment}</div>
              <div className="replyes__item__options">
                <div
                  className="replyes__item__options__reply"
                  onClick={() => handleReplyComment(reply)}
                >
                  <SVG svg="reply" />
                </div>
                <div className="replyes__item__options__like">
                  <div
                    className="replyes__item__options__icon like"
                    onClick={() => handleDislikeComment(Number(reply.id))}
                  >
                    {reply.dislike}
                    <SVG svg="sad" />
                  </div>
                  <div
                    div
                    className="replyes__item__options__icon dislike"
                    onClick={() => handleLikeComment(Number(reply.id))}
                  >
                    {reply.like}
                    <SVG svg="happy" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {Number(reply.parent_id) === Number(comment.id) && (
          <div className="replyes__reply-body">
            <SVG svg="reply-arrow" />
            <div className="replyes__reply-list">{showReplies(reply)}</div>
          </div>
        )}
      </div>
    ));
  };

  return (
    <React.StrictMode>
      <CreateComment
        active={showComment}
        setShow={(show) => setShowComment(show)}
        replyData={replyData}
      />

      <div className={`replyes ${active && "active"}`}>
        <div className="replyes__header">
          <div onClick={() => setShow(false)}>
            <SVG svg="times" />
          </div>
        </div>
        {parentComment !== null && (
          <div className="replyes__item main">
            <div className="replyes__item__header">
              <div className="replyes__item__title">{parentComment.user}</div>
              <div className="replyes__item__date">
                {moment(parentComment.created_at, "YYYY/MM/DD")
                  .locale("fa")
                  .format("YYYY/MM/DD")}
              </div>
            </div>
            <div className="replyes__item__body">
              <div className="replyes__item__content">
                {parentComment.comment}
              </div>
              <div className="replyes__item__options">
                <div
                  className="replyes__item__options__reply"
                  onClick={() => handleReplyComment(parentComment)}
                >
                  <SVG svg="reply" />
                  نوشتن پاسخ
                </div>
                <div className="replyes__item__options__like">
                  <div
                    className="replyes__item__options__icon like"
                    onClick={() =>
                      handleDislikeComment(Number(parentComment.id))
                    }
                  >
                    {parentComment.dislike}
                    <SVG svg="sad" />
                  </div>
                  <div
                    div
                    className="replyes__item__options__icon dislike"
                    onClick={() => handleLikeComment(Number(parentComment.id))}
                  >
                    {parentComment.like}
                    <SVG svg="happy" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {parentComment !== null &&
          comments.map(
            (reply, index) =>
              Number(reply.parent_id) === parentComment.id && (
                <div className="replyes__item-wrapper" key={index}>
                  <div className="replyes__item ">
                    <div className="replyes__item__header">
                      <div className="replyes__item__title">{reply.user}</div>
                      <div className="replyes__item__date">
                        {moment(reply.created_at, "YYYY/MM/DD")
                          .locale("fa")
                          .format("YYYY/MM/DD")}
                      </div>
                    </div>
                    <div className="replyes__item__body">
                      <div className="replyes__item__content">
                        {reply.comment}
                      </div>
                      <div className="replyes__item__options">
                        <div
                          className="replyes__item__options__reply"
                          onClick={() => handleReplyComment(reply)}
                        >
                          <SVG svg="reply" />
                        </div>
                        <div className="replyes__item__options__like">
                          <div
                            className="replyes__item__options__icon like"
                            onClick={() =>
                              handleDislikeComment(Number(reply.id))
                            }
                          >
                            {reply.dislike}
                            <SVG svg="sad" />
                          </div>
                          <div
                            div
                            className="replyes__item__options__icon dislike"
                            onClick={() => handleLikeComment(Number(reply.id))}
                          >
                            {reply.like}
                            <SVG svg="happy" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {reply.parent_id !== null && (
                    <div className="replyes__reply-body">
                      <SVG svg="reply-arrow" />
                      <div className="replyes__reply-list">
                        {showReplies(reply)}
                      </div>
                    </div>
                  )}
                </div>
              )
          )}
      </div>
    </React.StrictMode>
  );
};
