import { useEffect, useState } from "react";
import {
  PageTopbar,
  Card,
  Spinner,
  PanelBox,
  SVG,
  Padding,
  Fade,
} from "components";
import { Link, useParams } from "react-router-dom";
import { userPanelById } from "utils/Services";
import { useHistory } from "react-router-dom";

type MyPanelParam = {
  panel_id: string;
};

const panelItems = [
  {
    title: 'سوابق جذب محتوا برای کارتلا',
    icon: 'kartella',
    type: 'white gold',
    path: '/'
  },
  {
    title: 'سوابق جذب فروشگاه برای شهر',
    icon: 'city',
    type: 'white gold',
    path: '/'
  },
  {
    title: 'سوابق فروش KM',
    icon: '',
    type: 'white gold',
    path: '/'
  },
  {
    title: 'سوابق فروش پنل طلایی',
    icon: '',
    type: 'gold',
    path: '/'
  },
  {
    title: 'همکاران طلایی من',
    icon: '',
    type: 'gold',
    path: '/'
  },
]

export const MyPanelContainer = ({ children }) => {
  const { panel_id } = useParams<MyPanelParam>();
  const history = useHistory();
  const [panelData, setPanelData] = useState<any>({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getUserPanelData(panel_id);
    history.replace(`/my-panel/${panel_id}`);
  }, []);

  const getUserPanelData = (id) => {
    userPanelById(id).then((res) => {
      if (res) {
        setPanelData(res.data.panel);
        setLoading(false);
      } else return;
    });
  };
  return (
    <Fade>
      <div className="my-panel">
        <Spinner active={loading} color="dark" />
        <PageTopbar title="مدیریت پنل" />
        <Card>
          <PanelBox data={panelData} />
          {panelItems.map((panelItem, index) => (
            panelItem.type.includes(panelData.adsType) &&
            <Link
              to={panelItem.path}
              key={index}
              className="my-panel__item"
            >
              <div className="row a-c">
                <div className="my-panel__item__icon">
                  <SVG svg={panelItem.icon} />
                </div>
                <div>{panelItem.title}</div>
              </div>
              <SVG svg="arrow" />
            </Link>
          ))}
        </Card>
        <Padding>
          {panelData.adsType !== 'gold' && (
            <div
              onClick={() => history.push("/buy-panel")}
              className="my-panel__action"
            >
              <div className="my-panel__action__title">
                <SVG svg="panel-not-found" />
                <span>ارتقا پنل نمایندگی</span>
              </div>
              <div className="my-panel__action__arrow">
                <SVG svg="arrow" />
              </div>
            </div>
          )}
        </Padding>
      </div>
    </Fade>
  );
};
