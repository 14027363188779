import { CartContext, cartTypes } from "providers";
import { useContext, useMemo, useCallback } from "react";
import { getCartAPI } from "../../utils/Services/API";
import { getCart } from "utils/Services";
export const useCart = () => {
  const { cartState, cartDispatch } = useContext(CartContext);

  return {
    getCartDataFromApi: async () => {      
      getCartAPI().then((res) => {
        if (res) {          
          cartDispatch({
            type: cartTypes.SET_CART_DATA,
            payload: res.data.data.cart,
          });
        } else return;
      }).catch(err => console.log(err));
    },
    checkProductWithCart: useCallback(
      (payload) => {
        if(payload) {
          if (cartState?.cart?.length > 0) {
            let tempProduct;
            cartState?.cart[0]?.detail?.map(tempCart => {
              tempProduct = tempCart.items.find(
                (x) => Number(x.varitions_id) == payload
              );
            })
            return tempProduct;
          }
        }
      },
      [cartState.cart]
    ),
    setCartData: (payload: {}) => {
      cartDispatch({
        type: cartTypes.SET_CART_DATA,
        payload: payload,
      });
    },
    cartState: useMemo(() => cartState, [cartState]),
  };
};
