import React, { useEffect, useState } from "react";
import { ShopHeader, Fade, Spinner, Card } from "components";
import { useHistory } from "react-router-dom";
import { getCategoriesAPI } from "../utils/Services/API";
import { Link } from "react-router-dom";

const Search = () => {
  const history = useHistory();
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const user_token = localStorage.getItem("user_token");

  const getCategories = () => {
    const apiResult = getCategoriesAPI(user_token);
    apiResult.then((response) => {
      setCategories(Object.values(response.data.data));
      setLoading(false);
    });
  };

  useEffect(() => {
    getCategories();
    history.replace("/search");
  }, []);

  return (
    <Fade>
      <ShopHeader />
      <Spinner active={loading} color="dark" />
      <Card>
        <div className="main-categories">
          {categories.length > 0 &&
            categories.map((category) => (
              <div
                className="main-categories__item"
                onClick={() => history.push(`/category?${category.id}`)}
              >
                <div
                  className="main-categories__item__image"
                >
                  <img src={category.photo} loading='lazy' />
                </div>
                <div className="main-categories__item__title">{category.title}</div>
              </div>
            ))}

          {/* <div className="main-categories__body">
          <figure>دسته بندی محصولات کارتک</figure>
          {categories.length > 0 &&
            categories.map((category) => (
              <figure onClick={() => { }}>
              <Link to={`/category?${category.id}`}>
              <div className="category-item">
              <img src={category.photo} loading='lazy' />
              <div className="category-item__title">{category.title}</div>
              </div>
                </Link>
              </figure>
            ))}
          </div> */}
        </div>
      </Card>
    </Fade>
  );
};

export default Search;
