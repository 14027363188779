import {Request} from "../api-call";

export const setStorePictureAPI = async (pic) => {
  const {data} = await Request.post(`/api/v3/kartland/picture`, pic, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
  return data;
};
