import { Request } from "../api-call";

export const adsSeenAPI = async (adsId) => {
  const { data } = await Request.get(
    `/api/v2/advertise/kartella/ads/setKv?adsId=${adsId}`
  );
  return data;
};

export const adsSpecificSeenAPI = async (adsId) => {
  const { data } = await Request.get(
    `/api/v2/advertise/kartella/ads/setKv?adsId=${adsId}&SpecialView=true`
  );
  return data;
};
