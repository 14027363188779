import { CheckBox, Selectbox, SVG } from "components";
import { CreateAdsContext } from "containers";
import { CreateMessageContext } from "containers/CreateMessageContainer";
import React, { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { fillProfileAPI, getInventory } from "utils/Services/API";

const FillCustomEmployment = ({ close }) => {
  const user_token = localStorage.getItem("user_token");
  const createAds = useContext(CreateMessageContext);

  const [employmentStatus, setEmploymentStatus] = useState(-1);
  const [employmentStatusValue, setEmploymentStatusValue] = useState("");
  const [employmentType, setEmploymentType] = useState(0);
  const [employmentTypeValue, setEmploymentTypeValue] = useState("");
  const [readyToWork, setReadyToWork] = useState(0);
  const [emeritus, setEmeritus] = useState(0);
  const [skill, setSkill] = useState(0);
  const [skillField, setSkillField] = useState("");

  const [guild, setGuild] = useState(0);
  const [guildField, setGuildField] = useState("");

  const [employmentSalaried, setEmploymentSalaried] = useState(0);

  const [governmentJob, setGovernmentJob] = useState(0);
  const [governmentJobField, setGovernmentJobField] = useState("");

  const [freelanceJob, setFreelanceJob] = useState(0);
  const [freelanceJobField, setFreelanceJobField] = useState("");

  const [freelanceJobType, setFreelanceJobType] = useState(0);

  const [dataList, setDataList] = useState({
    skills: [],
    jobTitles: [],
    guilds: [],
    government: [],
    freelanceJobs: [],
    freelanceJobsTypes: [],
  });

  const handleActivateSubmit = () => {
    if (
      employmentStatus === -1
      // || (employmentStatus === 2 && readyToWork === 0)
      // || (employmentStatus === 2 && readyToWork === 1 && emeritus === 0)
      // || (employmentStatus === 2 && readyToWork === 2 && (skill === 0 && skillField.length === 0))
      // || (employmentStatus === 1 && employmentType === 0)
      // || (employmentStatus === 1 && employmentType === 2 && (guild === 0 && guildField?.length === 0))
      // || (employmentStatus === 1 && employmentType === 3 && employmentSalaried === 0)
      // || (employmentStatus === 1 && employmentType === 3 && employmentSalaried === 1 && (governmentJob === 0 && governmentJobField?.length === 0))
      // || (employmentStatus === 1 && employmentType === 3 && employmentSalaried === 2 && freelanceJob === 0)
    )
      return false;
    else return true;
  };

  const handleSubmitForm = () => {
    var apiRequest = {};
    apiRequest = {
      employment: employmentStatus,
      employmentValue: employmentStatusValue,
      job: employmentType,
      jobValue: employmentTypeValue,
      guild: guild,
      freelanceJob: freelanceJob,
      emeritus: emeritus,
      readyToWork: readyToWork,
      skill: skill,
    };

    createAds.detail.set((prev) => ({
      ...prev,
      employment: apiRequest,
    }));
  };

  const getSkills = () => {
    const APIResult = getInventory(user_token, "skill");
    APIResult.then((response) => {
      setDataList((prevState) => ({
        ...prevState,
        skills: response.data.data,
      }));
    });
  };

  const getJobTitles = () => {
    const APIResult = getInventory(user_token, "jobTitle");
    APIResult.then((response) => {
      setDataList((prevState) => ({
        ...prevState,
        jobTitles: response.data.data,
      }));
    });
  };

  const getGuilds = () => {
    const APIResult = getInventory(user_token, "guilds");
    APIResult.then((response) => {
      setDataList((prevState) => ({
        ...prevState,
        guilds: response.data.data,
      }));
    });
  };

  const getGovernment = () => {
    const APIResult = getInventory(user_token, "government");
    APIResult.then((response) => {
      setDataList((prevState) => ({
        ...prevState,
        government: response.data.data,
      }));
    });
  };

  const getFreelanceJobs = () => {
    const APIResult = getInventory(user_token, "freelanceJob");
    APIResult.then((response) => {
      setDataList((prevState) => ({
        ...prevState,
        freelanceJobs: response.data.data,
      }));
    });
  };

  useEffect(() => {
    getSkills();
    getJobTitles();
    getGuilds();
    getGovernment();
    getFreelanceJobs();

    setEmploymentStatus(createAds.detail.get.employment.employment);
  }, []);

  useEffect(() => {
    handleSubmitForm();
  }, [employmentStatus, employmentType, readyToWork, emeritus, skill, guild, employmentSalaried]);

  return (
    <div className="fill-profile__form">
      <div className="row">
        <div className="row" style={{ marginLeft: 16 }}>
          <CheckBox
            disable
            defaultValue={employmentStatus === 1 && true}
            onChange={(value) => {
              if (value) {
                setEmploymentStatusValue("شاغل");
                setEmploymentStatus(1);
                setReadyToWork(0);
                setEmeritus(0);
                setSkill(0);
              }
            }}
          />
          <div className="fill-profile__form__title">شاغل</div>
        </div>

        <div className="row" style={{ marginLeft: 16 }}>
          <CheckBox
            disable
            defaultValue={employmentStatus === 2 && true}
            onChange={(value) => {
              if (value) {
                setEmploymentStatusValue("غیرشاغل");
                setEmploymentStatus(2);
                setEmploymentType(0);
                setGuild(0);
                setEmploymentSalaried(0);
                setGovernmentJob(0);
                setFreelanceJob(0);
                setFreelanceJobType(0);
              }
            }}
          />
          <div className="fill-profile__form__title">غیرشاغل</div>
        </div>

        <div className="row">
          <CheckBox
            disable
            defaultValue={employmentStatus === 0 && true}
            onChange={(value) => {
              if (value) {
                setEmploymentStatusValue("تعیین نشده");
                setEmploymentStatus(0);
                setEmploymentType(0);
                setGuild(0);
                setEmploymentSalaried(0);
                setGovernmentJob(0);
                setFreelanceJob(0);
                setFreelanceJobType(0);
              }
            }}
          />
          <div className="fill-profile__form__title">همه</div>
        </div>
      </div>

      {employmentStatus === 2 && (
        <div className="row">
          <div className="row" style={{ marginLeft: 16 }}>
            <CheckBox
              disable
              defaultValue={readyToWork === 2 && true}
              onChange={(value) => {
                if (value) {
                  setReadyToWork(2);
                  setEmploymentTypeValue("آماده ‌به‌ کار");
                }
              }}
            />
            <div className="fill-profile__form__title">آماده به کار</div>
          </div>

          {/* <div className="row">
            <CheckBox
              disable
              defaultValue={readyToWork === 1 && true}
              onChange={value => {
                if (value) {
                  setReadyToWork(1);
                  setEmeritus(0);
                  setEmploymentTypeValue('غیر آماده به کار')
                }
              }}
            />
            <div className="fill-profile__form__title">
              غیر آماده به کار
            </div>
          </div> */}
        </div>
      )}

      {/* {readyToWork === 1 && (
        <div className="row">
          <div className="row" style={{ marginLeft: 16 }}>
            <CheckBox
              disable
              defaultValue={emeritus === 1 && true}
              onChange={value => {
                if (value) {
                  setEmeritus(1);
                  setSkill(0);
                }
              }}
            />
            <div className="fill-profile__form__title">
              بازنشسته‌ام
            </div>
          </div>

          <div className="row">
            <CheckBox
              disable
              defaultValue={emeritus === 2 && true}
              onChange={value => {
                if (value) {
                  setEmeritus(2);
                }
              }}
            />
            <div className="fill-profile__form__title">
              بازنشسته نیستم
            </div>
          </div>
        </div>
      )} */}

      {/* {readyToWork === 2 && (
        <React.StrictMode>
          <div className="fill-profile__form__title">
            <span className="required" />
            مهارت
          </div>

          <Selectbox
            data={dataList.skills}
            placeholder="انتخاب مهارت"
            onChange={value => setSkill(value)}
            onChangeString={value => setSkillField(value)}
          />
        </React.StrictMode>
      )} */}

      {employmentStatus === 1 && (
        <div className="row">
          <div className="row" style={{ marginLeft: 16 }}>
            <CheckBox
              disable
              defaultValue={employmentType === 1 && true}
              onChange={(value) => {
                if (value) {
                  setEmploymentType(1);
                  setGuild(0);
                  setEmploymentSalaried(0);
                  setGovernmentJob(0);
                  setFreelanceJob(0);
                  setFreelanceJobType(0);
                  setEmploymentTypeValue("خانه‌دار");
                }
              }}
            />
            <div className="fill-profile__form__title">خانه‌دار</div>
          </div>

          <div className="row" style={{ marginLeft: 16 }}>
            <CheckBox
              disable
              defaultValue={employmentType === 2 && true}
              onChange={(value) => {
                if (value) {
                  setEmploymentType(2);
                  setEmploymentSalaried(0);
                  setGovernmentJob(0);
                  setFreelanceJob(0);
                  setFreelanceJobType(0);
                  setEmploymentTypeValue("صاحب کسب‌وکار");
                }
              }}
            />
            <div className="fill-profile__form__title">صاحب کسب‌وکار</div>
          </div>

          <div className="row">
            <CheckBox
              disable
              defaultValue={employmentType === 3 && true}
              onChange={(value) => {
                if (value) {
                  setEmploymentType(3);
                  setGuild(0);
                  setEmploymentTypeValue("حقوق بگیر");
                }
              }}
            />
            <div className="fill-profile__form__title">حقوق بگیر</div>
          </div>
        </div>
      )}

      {/* {employmentType === 2 && (
        <React.StrictMode>
          <div className="fill-profile__form__title">
            <span className="required" />
            صنف شغلی
          </div>

          <Selectbox
            data={dataList.guilds}
            placeholder="انتخاب نوع صنف"
            onChange={value => setGuild(value)}
            onChangeString={value => setGuildField(value)}
          />

          <div className="input__hint" style={{ marginTop: '-15px' }}>
            (در صورتی که صنف شغلی مد نظر شما در لیست نبود، آن را دستی وارد نمایید.)
          </div>
        </React.StrictMode>
      )} */}

      {employmentType === 3 && (
        <React.StrictMode>
          <div className="row">
            <div className="row" style={{ marginLeft: 16 }}>
              <CheckBox
                disable
                defaultValue={employmentSalaried === 1 && true}
                onChange={(value) => {
                  if (value) {
                    setEmploymentSalaried(1);
                    setFreelanceJob(0);
                    setFreelanceJobType(0);
                  }
                }}
              />
              <div className="fill-profile__form__title">دولتی</div>
            </div>

            <div className="row">
              <CheckBox
                disable
                defaultValue={employmentSalaried === 2 && true}
                onChange={(value) => {
                  if (value) {
                    setEmploymentSalaried(2);
                    setGovernmentJob(0);
                  }
                }}
              />
              <div className="fill-profile__form__title">خصوصی</div>
            </div>
          </div>
        </React.StrictMode>
      )}

      {/* {employmentSalaried === 1 && (
        <React.StrictMode>
          <div className="fill-profile__form__title">
            <span className="required" />
            شغل
          </div>

          <Selectbox
            data={dataList.government}
            placeholder="انتخاب شغل"
            onChange={value => setGovernmentJob(value)}
            onChangeString={value => setGovernmentJobField(value)}
          />

          <div className="input__hint" style={{ marginTop: '-15px' }}>
            (در صورتی که صنف شغلی مد نظر شما در لیست نبود، آن را دستی وارد نمایید.)
          </div>
        </React.StrictMode>
      )} */}

      {/* {employmentSalaried === 2 && (
        <React.StrictMode>
          <div className="fill-profile__form__title">
            <span className="required" />
            شغل
          </div>

          <Selectbox
            data={dataList.freelanceJobs}
            placeholder="انتخاب شغل"
            onChange={value => setFreelanceJob(value)}
            onChangeString={value => setFreelanceJobField(value)}
          />

          <div className="input__hint" style={{ marginTop: '-15px' }}>
            (در صورتی که صنف شغلی مد نظر شما در لیست نبود، آن را دستی وارد نمایید.)
          </div>

          <Selectbox
            data={dataList.freelanceJobs}
            placeholder="عنوان"
            onChange={value => setFreelanceJobType(value)}
          />

          <div className="input__hint" style={{ marginTop: '-15px' }}>
            (در صورتی که صنف شغلی مد نظر شما در لیست نبود، آن را دستی وارد نمایید.)
          </div>
        </React.StrictMode>
      )} */}
      {/*
      <Selectbox
        data={studies}
        placeholder="انتخاب رشته‌ی تحصیلی"
        onChange={value => {
          setStudyId(value);
          setStudyField('');
        }}
        onChangeString={value => setStudyField(value)}
      /> */}
    </div>
  );
};

export default FillCustomEmployment;
