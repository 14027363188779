import {useState, useRef, useEffect} from "react";
import {
  PageTopbar,
  Card,
  SVG,
  Spinner,
  Fade,
  NotFoundBox,
  Modal,
  Dots,
} from "components";
import {useParams} from "react-router-dom";
import {assignKv, assignCart} from "utils/Services";
import {transformPrice, currency} from "utils/Functions";
import {useUser} from "hooks";
import {useHistory} from "react-router-dom";
import toast from "react-hot-toast";
import {cloneDeep} from "lodash";

type CartParam = {
  cart_id: string;
};

export const KvAssignContainer = ({children}) => {
  const {cart_id} = useParams<CartParam>();
  const history = useHistory();
  const {
    userState: {user},
    getUserDataFromApi,
  } = useUser();
  const [cartItems, setCartItemsData] = useState([]);
  const [totalKvAssignStatus, setTotalKvAssignStatus] = useState(false);
  const [kvCount, setKvCount] = useState(0);
  const [cartItemsId, setCartItemsId] = useState([]);
  const [loading, setLoading] = useState(true);
  const [submitStatus, setSubmitStatus] = useState(false);
  const [modalStatus, setModalStatus] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);
  const [kvLoading, setKvLoading] = useState(false);

  const handleClickItemAssign = (id, count) => {
    if (totalKvAssignStatus) return;
    if (cartItemsId.length > 0 && handleActiveBar(id) == Number(count)) return;
    setSubmitStatus(true);
    setCartItemsId((prev) => [...prev, id]);
    if (kvCount > 0) setKvCount((prev) => prev - 1);
  };

  const handleClickItemUndoAssign = (id) => {
    let newArr = [...cartItemsId];
    const find = newArr.indexOf(id);
    if (find > -1) {
      newArr.splice(find, 1);
    }
    setCartItemsId(newArr);
    if (kvCount + 1 <= user.kv) setKvCount(kvCount + 1);
    if (cartItemsId.length < 2) setSubmitStatus(false);
  };

  const handleActiveBar = (id) => {
    const newArr = [...cartItemsId];
    return newArr.filter((x) => x == id).length;
  };

  const getCartList = (id) => {
    assignCart(id)
      .then((res) => {
        if (res) {
          setCartItemsData(res.data.cart.items);
          setLoading(false);
        }
      })
      .catch((err) => {
        if (err.response.status == 404) {
          setLoading(false);
        }
      });
  };

  const handleSubmit = () => {
    setKvLoading(true);
    assignKv(cartItemsId, cart_id)
      .then((res) => {
        if (res) {
          // history.push("/profile/orders");
          toast.success("با موفقیت ثبت شد.");
          getUserDataFromApi();
          history.goBack();
        }
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setKvLoading(false);
      });
  };

  const handleKvCost = (priceList) => {
    return priceList[user.detail?.star - 1].kv;
  };

  const handleSumOfChilds = (accumulator, a) => {
    return accumulator + a;
  };

  useEffect(() => {
    if (user.detail) setKvCount(user.kv);
    getCartList(cart_id);
  }, [user]);

  useEffect(() => {
    if (user.detail && kvCount == 0) {
      setTotalKvAssignStatus(true);
    } else setTotalKvAssignStatus(false);
  }, [kvCount]);

  useEffect(() => {
    history.replace(`/kv-assign/${cart_id}`);
    getUserDataFromApi();
  }, []);

  useEffect(() => {
    let selectedItems = [...cartItemsId];
    const reduceData = selectedItems.reduce((acc, curr) => {
      return acc[curr] ? ++acc[curr] : (acc[curr] = 1), acc;
    }, {});

    if (reduceData && Object.keys(reduceData).length > 0) {
      let sum = [];
      cartItems.forEach((item) => {
        sum.push(
          reduceData[item.id] == undefined
            ? 0
            : reduceData[item.id] *
            item.kv
        );
        setTotalPrice(sum.reduce(handleSumOfChilds, 0));
      });
    } else setTotalPrice(0);
  }, [cartItemsId]);

  return (
    <Fade>
      <div className="kv-assign">
        <Spinner active={loading} color="dark"/>
        {cartItems.length > 0 ? (
          <>
            <PageTopbar
              title="آزاد سازی KV"
              toggleBackward={false}
              hintStatus
              toggleStar
              hintTitle="ستاره ی بیشتری بگیرید"
              hintBio="با ورود و ثبت نام شرایط و قوانین استفاده از سرویس های کارتک را  می پذیرم با ورود و ثبت نام شرایط و قوانین استفاده از سرویس های کارتک را می پذیرم"
              hintAction
              hintActionTitle="گرفتن ستاره"
              hintActionIcon="star"
              staticKvCount={kvCount}
            />
            <Card>
              {cartItems.map((item, index) => (
                <div className="kv-assign__item">
                  <div className="kv-assign__item__header">
                    <div className="kv-assign__item__header__cover">
                      <img
                        src={`${item.detail.Product.image[0]}`}
                        // src={`data:image/png;base64,${item.detail.Product.image[0]}`}
                        alt=""
                      />
                    </div>
                    <div className="kv-assign__item__header__info">
                      <div className="kv-assign__item__header__info__title">
                        <span className="kv-assign__item__header__info__title__count">
                          {`x${item.count}`}
                        </span>
                        <span>{item.detail.Product.title}</span>
                      </div>
                      <div className="kv-assign__item__header__info__price">
                        <SVG svg="kv-value-price"/>
                        <span>{`ارزش KV : ${currency(
                          // handleKvCost(item.detail.Variation.priceList)
                          item.kv
                        )} تومان`}</span>
                      </div>
                    </div>
                  </div>

                  <div className="kv-assign__item__footer">
                    <div
                      className={`kv-assign__item__footer__shape ${totalKvAssignStatus && "deactive"
                      } ${handleActiveBar(item.id) == item.count && "deactive"
                      }`}
                    >
                      <SVG svg="bottom-shape"/>
                    </div>
                    <div className={`kv-assign__item__footer__shape reverse `}>
                      <SVG svg="bottom-shape" color="#ffffff"/>
                    </div>

                    <div className="row" style={{width: "130px"}}>
                      <div
                        onClick={() =>
                          handleClickItemAssign(item.id, item.count)
                        }
                        className={`kv-assign__item__footer__btn ${totalKvAssignStatus && "deactive"
                        } ${handleActiveBar(item.id) == item.count && "deactive"
                        }`}
                      >
                        <span
                          className={`${handleActiveBar(item.id) > 0 && "assigned"
                          }`}
                        >
                          آزاد سازی
                        </span>
                        <SVG svg="assign"/>
                      </div>
                      <div
                        onClick={() => handleClickItemUndoAssign(item.id)}
                        className={`kv-assign__item__footer__undo ${handleActiveBar(item.id) > 0 && "assigned"
                        }`}
                      >
                        <SVG svg="undo"/>
                      </div>
                    </div>

                    <div
                      className={`kv-assign__item__footer__progress ${handleActiveBar(item.id) > 0 && "assigned"
                      }`}
                    >
                      {new Array(Number(item.count))
                        .fill("")
                        .map((bar, barIndex) => (
                          <div
                            style={{
                              width: `calc(100% / ${Number(item.count)})`,
                            }}
                            className={`kv-assign__item__footer__progress__item ${barIndex < handleActiveBar(item.id) && "active"
                            }`}
                          ></div>
                        ))}
                    </div>
                  </div>
                </div>
              ))}
            </Card>
            <div className="kv-assign__total-price">
              <span>مبلغ دریافتی :</span>
              <span>{`${currency(totalPrice)} تومان`}</span>
            </div>
            <div className="kv-assign__action">
              <div
                onClick={() => cartItemsId.length > 0 && setModalStatus(true)}
                className={`kv-assign__action__submit ${!submitStatus && "disable"
                }`}
              >
                <span>ثبت</span>
              </div>
              <div
                onClick={() => history.goBack()}
                className="kv-assign__action__back"
              >
                <span>بازگشت</span>
              </div>
            </div>
            <Modal
              active={modalStatus}
              close={(close) => setModalStatus(!close)}
            >
              <div className="kv-assign__modal">
                <span className="kv-assign__modal__title">
                  با ثبت این درخواست امکان مجدد رها سازی KV برای این سفارش نیست.
                </span>
                <div className="kv-assign__modal__footer">
                  <div
                    onClick={() => !kvLoading && handleSubmit()}
                    className="kv-assign__modal__footer__submit"
                  >
                    <span>
                      {kvLoading ? <Dots color="light"/> : 'ثبت'}
                    </span>
                  </div>
                  <div
                    onClick={() => setModalStatus(false)}
                    className="kv-assign__modal__footer__edit"
                  >
                    <span>ویرایش</span>
                  </div>
                </div>
              </div>
            </Modal>
          </>
        ) : (
          <>
            <NotFoundBox
              title="شما نمی توانید برای این سبد خرید kv آزاد سازید."
              icon="kv"
              fullScreen
              iconFillDark
              withAction
              actionText="بازگشت به سفارشات"
              actionRoute="/profile/orders"
            />
          </>
        )}
      </div>
    </Fade>
  );
};
