import './FileUploader.scss'

 // @ts-ignore
export const FileUploader=()=>{
//   const token = `${localStorage.getItem(STORAGE_KEY.TOKEN)}`;
//   var data = new tus.Upload(file, {
//     // Endpoint is the upload creation URL from your tus server
//     endpoint:'',
//     //  "https://dev.kartaak.com/api/v3/userContent/uploadVideo?channel_id=2cc1ee78-3e55-4010-8949-ad14bf6b672a",
//     // Retry delays will enable tus-js-client to automatically retry on errors
//     retryDelays: [0, 3000, 5000, 10000, 20000],
//     // Attach additional meta data about the file for the server
//     metadata: {
//         filename: file.name,
//         filetype: file.type
//     },
//     headers: {
//         Authorization: `Bearer ${token}`,
//         Accept: 'application/vnd.vimeo.*+json;version=3.4'
//     },
//     // Callback for errors which cannot be fixed using retries
//     onError: function (error) {
//         console.log("خطا در آپلود: " + error)
//     },
//     // Callback for reporting upload progress
//     onProgress: function(bytesUploaded, bytesTotal) {
//         var percentage = (bytesUploaded / bytesTotal * 100).toFixed(2)
//         console.log(bytesUploaded, bytesTotal, percentage + "%")
//     },
//     // Callback for once the upload is completed
//     onSuccess: function() {
//         console.log("Download %s from %s", file.name, data.url)
//         // let test = data.url.split("/");
//         // let test1 = test[test.length - 1];
//         // console.log(test1)
//     }
// })
//   // const postUploadFile = ()=>{
    
  // }
    return(<div style={{width:"100px",height:"100px"}}>
      <input  style={{width:"100px",height:"50px"}} type="file" id="myfile" name="myfile" onChange={onchange}/>
    </div>)
}