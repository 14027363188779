import { Fade, NotFoundBox, PageTopbar, Spinner } from 'components';
import { PanelLogs } from 'components/PanelLogs';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { collaboratePanelLogsAPI } from 'utils';

export const PanelContainer = () => {

  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [panelHistory, setPanelHistory] = useState([]);

  const getPanelHistory = () => {
    collaboratePanelLogsAPI().then(response => {
      setPanelHistory(response.data);
      setLoading(false);
    })
  }

  useEffect(() => {
    history.replace('/collaborate/logs/my-team');
    getPanelHistory();
  }, []);

  return (
    <Fade>
      <PageTopbar title="سوابق فروش پنل" />
      <Spinner active={loading} color="dark" />
      {
        panelHistory.length === 0 ? (
          <NotFoundBox
            title="تاریخچه‌ای برای فروش پنل یافت نشد"
            fullScreen={true}
            icon="marketing"
          />
        ) : (
          <div className="col" style={{ padding: '0 12px' }} className="ios-top">
            {panelHistory.map(panelHistoryItem => (
              <PanelLogs
                // title="جذب محتوا از کارتلا"
                description={panelHistoryItem.comment}
                // date={
                //   moment(panelHistoryItem.created_at, "YYYY/MM/DD")
                //     .locale("fa")
                //     .format("YYYY/MM/DD")
                // }
                type="collab-logs"
              />
            ))}
          </div>
        )
      }
    </Fade>
  );
}