import React, { useEffect, useState } from "react";
import {SVG,Spinner} from "components";
import { saveAds, submitAds } from "../../utils/Services/API";
import { encryption } from "../../utils/Encryption";
import { useHistory } from "react-router-dom";
import toast from "react-hot-toast";

export const Ads = ({ ads, time, setClose }) => {
  const userToken = localStorage.getItem("user_token");
  const [adsSaveStatus, setAdsSaveStatus] = useState(false);
  const [encryptedKey, setEncryptedKey] = useState("");
  const history = useHistory();

  const [isImageLoaded, setIsImageLoaded] = useState(false);

  const handleSaveAds = (adsId) => {
    const serverResponse = saveAds(userToken, adsId);
    serverResponse.then((response) => {
      if (response.status === 200) {
        setAdsSaveStatus(!adsSaveStatus);
      }
    });
  };

  const handleSubmitAds = () => {
    const serverSubmitAds = submitAds(
      userToken,
      ads.data.key,
      encryptedKey,
      ads.data.id
    );
    serverSubmitAds.then((response) => {
      if (response.status === 200) history.push("/ads/success");
      if (response.status === 403) {
        setClose(false);
        toast.error("متاسفانه KV ای دریافت نشد!");
      }
    });
  };

  useEffect(() => {
    if (ads.data.key !== undefined) {
      setEncryptedKey(encryption(ads.data.key));
    }
  }, [ads]);

  useEffect(() => {
    setAdsSaveStatus(ads.data.is_save);
  }, [ads]);

  return (
    <React.StrictMode>
      <Spinner active={!isImageLoaded && ads.show} color="dark" />
      <div className={`ads ${ads.show && "active"}`}>
        {/* <video width="100%" height="100%" controls>
          <source src={AdsVideo } type="video/mp4" />
        </video> */}
        {/* <div className='ads__spinner' > */}
        {/* </div> */}

        <div className="ads__container">
          <div className="ads__status">
            <div
              style={{ transition: `${time}s` }}
              className={`ads__status__bar ${ads.show && "active"}`}
            ></div>
          </div>
          <div className="ads__controller">
            <div style={{ display: "flex" }}>
              <div className={`ads__controller__item ${time <= 0 && "active"}`}>
                {time <= 0 ? (
                  <div onClick={() => handleSubmitAds()}>
                    <SVG svg="s-times" />
                  </div>
                ) : (
                  <>رد کردن محتوا در: {time} ثانیه</>
                )}
              </div>
            </div>
            <div
              className={`ads__controller__item left ${
                adsSaveStatus && "active-ads"
              }`}
              onClick={() => handleSaveAds(ads.data.id)}
            >
              {adsSaveStatus ? "محتوا ذخیره شد" : "ذخیره‌ی محتوا"}{" "}
              <SVG svg="bookmark" />
            </div>
          </div>
          <img
            src={`http://185.176.33.245:6363/${ads.data.file}`}
            alt=""
            onLoad={() => {
              setIsImageLoaded(true);
            }}
          />
          <div className="ads__info">
            <div>{ads.data.title}</div>
            <div className="ads__info__desc">{ads.data.description}</div>
          </div>
        </div>
        {/* {!isImageLoaded ? (
       <div>
         <Spinner active={!isImageLoaded} />
       </div>
     ) : (
        <></>
     )} */}
      </div>
    </React.StrictMode>
  );
};
