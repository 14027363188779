import { ProductContainer } from "containers";

const Product = ({ children }) => {
  return (
    <ProductContainer>
      {children}
    </ProductContainer>
  );
}

export default Product;
