import { CheckBox, SVG, Selectbox } from "components";
import { AppContext } from "context/AppContext";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import {
    fillProfileAPI,
    getCitiesAPI,
    getCountriesAPI,
    getDistrictsAPI,
    getStatesAPI,
} from "utils/Services/API";
import newStarSound from "../../../assets/sounds/star.mp3";
import { useUser } from "hooks";

const FillProfileCity = ({ close }) => {
    const user_token = localStorage.getItem("user_token");
    const {
        userState: { user },
    } = useUser();
    const [countryId, setCountryId] = useState(0);
    const [countries, setCountries] = useState([]);

    const [stateId, setStateId] = useState(0);
    const [states, setStates] = useState([]);

    const [cityId, setCityId] = useState(0);
    const [cities, setCities] = useState([]);
    const [resetCity, setResetCity] = useState(false);

    const [districtId, setDistrictId] = useState(0);
    const [districts, setDistricts] = useState([]);
    const [resetDistrict, setResetDistrict] = useState(false);

    const [audio] = useState(new Audio(newStarSound));

    const handleActivateSubmit = () => {
        if (countryId === 0 || stateId === 0 || cityId === 0) return false;
        else return true;
    };

    const handleSubmitForm = () => {
        if (handleActivateSubmit()) {
            const apiRequest =
                districtId === 0
                    ? {
                          counrtyId: countryId,
                          stateId: stateId,
                          cityId: cityId,
                      }
                    : {
                          counrtyId: countryId,
                          stateId: stateId,
                          cityId: cityId,
                          districtId: districtId,
                      };
            const APIResult = fillProfileAPI(
                user_token,
                "stateCity",
                apiRequest
            );
            APIResult.then((response) => {
                if (response.status === 200) {
                    toast.success("اطلاعات ستاره ثبت شد.");
                    if (user.detail.isSound) {
                        audio.play();
                    }
                    close(true);
                }
            });
        } else {
            if (countryId === 0) toast.error("کشور خود را انتخاب نمایید.");
            else if (stateId === 0) toast.error("استان خود را انتخاب نمایید.");
            else if (cityId === 0) toast.error("شهر خود را انتخاب نمایید.");
        }
    };

    const getCountries = () => {
        const APIResult = getCountriesAPI(user_token);
        APIResult.then((response) => {
            setCountries(response.data.data);
        });
    };

    const getStates = () => {
        const APIResult = getStatesAPI(user_token);
        APIResult.then((response) => {
            setStates(response.data.data);
        });
    };

    const getCities = (entryState) => {
        const APIResult = getCitiesAPI(user_token, entryState);
        APIResult.then((response) => {
            setCities(response.data?.data);
        });
    };

    const getDistricts = (entryCity) => {
        const APIResult = getDistrictsAPI(user_token, entryCity);
        APIResult.then((response) => {
            setDistricts(response.data?.data);
        });
    };

    useEffect(() => {
        stateId !== 0 && setResetCity(true);
    }, [stateId]);

    useEffect(() => {
        cityId !== 0 && setResetDistrict(true);
    }, [cityId]);

    useEffect(() => {
        getCountries();
        getStates();
    }, []);

    return (
        <div className={"fill-profile__form"}>
            <audio src={newStarSound} autoPlay={false}></audio>
            <div className="row g-16">
                <div className="col w-100">
                    <div className={`${"fill-profile__form__title"}`}>
                        <span className={"required"} />
                        کشور
                    </div>

                    <Selectbox
                        openAll
                        data={countries}
                        placeholder="انتخاب کشور"
                        onChange={(value) => setCountryId(value)}
                    />
                </div>
                <div className="col w-100">
                    <div className={`${"fill-profile__form__title"}`}>
                        <span className={"required"} />
                        استان
                    </div>

                    <Selectbox
                        openAll
                        data={states}
                        placeholder="انتخاب استان"
                        onChange={(value) => {
                            setStateId(value);
                            getCities(value);
                        }}
                    />
                </div>
            </div>
            <div className="row g-16">
                <div className="col w-100">
                    <div className={`${"fill-profile__form__title"}`}>
                        <span className={"required"} />
                        شهر
                    </div>

                    <Selectbox
                        openAll
                        data={cities}
                        placeholder="انتخاب شهر"
                        onChange={(value) => {
                            setCityId(value);
                            getDistricts(value);
                            setResetCity(false);
                        }}
                        resetValue={resetCity}
                        disabled={stateId === 0 && true}
                    />
                </div>
                <div className="col w-100">
                    <div className={`${"fill-profile__form__title"}`}>
                        روستا / محله
                    </div>

                    <Selectbox
                        data={districts}
                        placeholder="انتخاب روستا یا محله"
                        onChange={(value) => {
                            setDistrictId(value);
                            setResetDistrict(false);
                        }}
                        disabled={cityId === 0 && true}
                        resetValue={resetDistrict}
                    />
                </div>
            </div>

            <div
                className={`fill-profile__form__button ${
                    handleActivateSubmit() && "active"
                }`}
                onClick={() => handleSubmitForm()}
            >
                <div className="fill-profile__form__button__shape">
                    <SVG
                        svg="bottom-shape"
                        color={handleActivateSubmit() ? "#21bbe1" : "#b3bdcc"}
                    />
                </div>
                ثبت
                <div className="fill-profile__form__button__star">
                    <SVG svg="profile-star" />
                </div>
            </div>
        </div>
    );
};

export default FillProfileCity;
