import { Fade, Modal, PageTopbar, Spinner, SVG } from "components";
import { Hint } from "components/Hint";
import { useUser } from "hooks";
import moment from "jalali-moment";
import { Line } from "rc-progress";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useHistory } from "react-router";
import { getHintAPI } from "utils";
import { getUserInfo } from "utils/Services/API";
import { tokenWalletAPI } from "utils/Services/Wallet";

export const WalletContainer = () => {
    const user_token = localStorage.getItem("user_token");
    const history = useHistory();
    const [cashHistory, setCashHistory] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showHint, setShowHint] = useState(false);
    const [hint, setHint] = useState<any>({
        title: "",
        content: "",
    });
    const { getUserDataFromApi } = useUser();

    const [userKV, setUserKV] = useState(0);

    const getUser = () => {
        const serverResponse = getUserInfo(user_token);
        serverResponse.then((response) => {
            if (response.status === 200) {
                setUserKV(response.data.kv);
            }
            setLoading(false);
        });

        tokenWalletAPI(user_token).then((response) => {
            setCashHistory(response.data.data);
        });
    };

    const getHint = () => {
        getHintAPI("صفحات داخلی کیف KV")
            .then((response) => {
                setHint(response);
            })
            .catch(() => setHint(false))
            .finally(() => {});
    };

    useEffect(() => {
        getUser();
        getHint();
        history.replace("/wallet");
        getUserDataFromApi();
    }, []);

    return (
        <React.StrictMode>
            <Fade>
                <PageTopbar
                    title="کیف KV"
                    showKv={false}
                    circleHint={hint.content}
                />
                <Spinner active={loading} color="dark" />

                {/* <Modal active={showHint} close={() => setShowHint(false)} hint='price'>
          <div className="hints__title">
            {hint.title}
          </div>
          <div className="hints__content">
            {hint.content?.split('\n')?.map(str => <div>{str}</div>)}
          </div>
          {/* <div className="cash-history__hint">
            <div className="cash-history__hint__title">
              KV  یا (Kartaak Value) چیست؟
            </div>
            -	امتیازی که با فعالیت های خود در کارتک مانند معرفی افراد جدید، دیدن صفحات مجله کارتلا و غیره به دست
            می آرید. هر چه ستاره هاتون بیشتر باشه، KV بیشتری می گیرید.
            <div className="cash-history__hint__title">
              موارد استفاده KV چیست؟
            </div>
            -	با خرید هر محصول در فروشگاه کارتک می تونید یک KV از کیفتون آزاد کنید و هدیه نقدی اونو بگیرید.
            < br />
            -	در هر ماهی که بیشتر از 200 KV بگیرید به قرعه کشی ماهانه کارتک راه پیدا می کنید، هر امتیاز بیشتر از 200، شانستونو برای برنده شدن بیشتر می کنه.
            < br />
            -	بعضی از KV ها جوایز ویژه ای دارند که پس از آزاد سازی مشخص می شوند.
            < br />
            -	KV را می‌تونید به KM سفید تبدیل کنید. (جهت کسب اطلاعات بیشتر به راهنمای KM مراجعه کنید.)
            <br />
            <span style={{ color: 'green' }}>
              -	 موجودی کیف KV در هر ماه تا پایان همان ماه معتبر می باشد.
            </span>

          </div> */}
                {/* </Modal> */}

                <div className="cash-history">
                    {/* <div className="row j-c w-100 mb-8">
            <Hint onClick={() => setShowHint(true)} theme="purple" />
          </div> */}

                    <div className="row a-c" style={{ marginBottom: 16 }}>
                        <div className="cash-history__circle purple"></div>
                        <div className="token-history__card">
                            <div
                                className="col j-sb"
                                style={{
                                    height: "100%",
                                    width: "100%",
                                    marginLeft: 80,
                                }}
                            >
                                <div className="row a-c">
                                    {/* <SVG svg="kv-shape" /> */}
                                    موجودی
                                </div>

                                <div className="col">
                                    <div className="row j-sb mb-16">
                                        <div>زمان باقی‌مانده</div>
                                        <div>
                                            {moment().jDaysInMonth() -
                                                moment().jDate()}
                                            روز
                                        </div>
                                    </div>

                                    <div className="row">
                                        <Line
                                            className="marketing__qr__box__footer__info__progress"
                                            percent={
                                                ((moment().jDaysInMonth() -
                                                    moment().jDate()) *
                                                    100) /
                                                moment().jDaysInMonth()
                                            }
                                            strokeWidth={2}
                                            trailWidth={2}
                                            strokeColor="#17CFAD"
                                            trailColor="#ffffff"
                                            transition="1"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="token-history__jeton">
                                <div className="token-history__jeton__circle">
                                    <SVG svg="kv" />
                                </div>
                                <div className="token-history__jeton__count">
                                    {userKV}
                                </div>
                            </div>
                        </div>
                    </div>

                    {cashHistory?.length !== 0 ? null : (
                        // <div className="cash-history__seperator" />
                        <div className="cash-history__not-found">
                            <span>تاریخچه ای برای کیف KV وجود ندارد.</span>
                        </div>
                    )}

                    {cashHistory
                        ?.filter((history) => history?.amount !== "0")
                        .map((cashHistoryItem, index) => (
                            <React.StrictMode>
                                <div
                                    className="cash-history__item"
                                    key={index}
                                    style={{ marginBottom: 12 }}
                                >
                                    {/* <div className={`cash-history__type ${cashHistoryItem.type}`}>
                  <SVG svg={cashHistoryItem.type} />
                </div> */}
                                    <div className="cash-history__detail">
                                        <div className="row">
                                            <div className="cash-history__detail__type kv__type">
                                                <SVG svg={"kv"} />
                                                {/* <SVG svg={cashHistoryItem.type + '-money'} /> */}
                                            </div>

                                            <div className="col">
                                                <div className="cash-history__detail__amount">
                                                    {cashHistoryItem.amount +
                                                        " KV"}
                                                </div>
                                                <div className="cash-history__detail__source">
                                                    {cashHistoryItem.content}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="cash-history__detail__date">
                                            {moment(
                                                cashHistoryItem.created_at,
                                                "YYYY-MM-DD HH:mm:ss"
                                            )
                                                .locale("fa")
                                                .format("H:mm - YYYY/MM/DD")}
                                        </div>
                                    </div>
                                </div>

                                {/* <div className="cash-history__seperator" /> */}
                            </React.StrictMode>
                        ))}
                </div>
            </Fade>
        </React.StrictMode>
    );
};
