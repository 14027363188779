import {Card, Fade, NotFoundBox, PageTopbar, Spinner} from "../../components";
import {Tabs} from "../OrdersContainer/dependencies";
import React, {useEffect, useState} from "react";
import {myReportsAPI, myStoresReportAPI, mySubmittedReportsAPI} from "../../utils";
import toast from "react-hot-toast";
import {useUser} from "../../hooks";
import moment from "jalali-moment";
import {useHistory} from "react-router-dom";

export const ReportsContainer = () => {
  const history = useHistory()
  const [activeTab, setActiveTab] = useState(1);
  const [myReports, setMyReports] = useState([]);
  const [mySubmittedReports, setMySubmittedReports] = useState([]);
  const [myStoresReport, setMyStoresReport] = useState([]);
  const [loading, setLoading] = useState(true);
  const {
    userState: {user},
  } = useUser();
  let tabs = [
    {
      title: "گزارشات من",
      icon: "waiting",
    },
    {
      title: "رسیدگی شده",
      icon: "scores",
    },
    {
      title: "گزارشات رسیده",
      icon: "scores",
    }
  ];

  const getMyReports = () => {
    setLoading(true);
    myReportsAPI().then(res => {
      if (res.status === 200) {
        setMyReports(Object.values(res.data?.details));
      }
    }).catch(err => {
      toast.error('دریافت گزارشات با خطا مواجه شد');
    }).finally(() => setLoading(false));
  };

  const getMySubmittedReports = () => {
    mySubmittedReportsAPI().then(res => {
      if (res.status === 200) {
        setMySubmittedReports(Object.values(res.data?.details));
      }
    }).catch(err => {
      toast.error('دریافت گزارشات با خطا مواجه شد');
    }).finally(() => {
    });
  };

  const getMyStoresReports = () => {
    myStoresReportAPI().then(res => {
      if (res.status === 200) {
        setMyStoresReport(res.data?.details);
      }
    }).catch(err => {
      toast.error('دریافت گزارشات با خطا مواجه شد');
    }).finally(() => {
    });
  };

  const getStatus = (item) => {
    if (item === null) {
      return <span style={{color: 'gray'}}>رسیدگی نشده</span>;
    } else if (item === 1) {
      return <span style={{color: 'green'}}>گزارش صحیح</span>;
    } else if (item === 0) {
      return <span style={{color: 'red'}}>گزارش اشتباه</span>;
    }
  };

  useEffect(() => {
    getMyReports();
    getMyStoresReports();
    getMySubmittedReports();
    history.replace('/reports')
  }, []);

  return (
    <Fade>
      <PageTopbar title={'گزارشات'} showKv={false}/>
      <Spinner active={loading} color={'dark'}/>
      <Card>

        {user.storeSubmiter === 1 && <div className="reports__tabs">
          <Tabs
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            items={tabs}
          />
        </div>}

        <div className="reports">
          {activeTab === 1 &&
            (myReports.length > 0 ?
                myReports.map(item => (
                  <div className="reports__list">
                    <div className="reports__item">
                      <div className="reports__item__name">
                        نام فروشگاه: {item.kartstore}
                      </div>
                      <div className="reports__item__report">
                        نوع گزارش: {item.message}
                      </div>
                      <div className="reports__item__status">
                        وضعیت: {getStatus(item.status)}
                      </div>
                      <div className="reports__item__date">
                        تاریخ ثبت: {moment(item.created_at, "YYYY/MM/DD")
                        .locale("fa")
                        .format("YYYY/MM/DD")}
                      </div>
                    </div>
                  </div>
                )) : (<NotFoundBox
                  title="گزارشی ثبت نکرده‌اید!!"
                  bio=""
                  icon=""
                />)
            )
          }

          {activeTab === 2 &&
            (mySubmittedReports.length > 0 ?
                mySubmittedReports.map(item => (
                  <div className="reports__list">
                    <div className="reports__item">
                      <div className="reports__item__name">
                        نام فروشگاه: {item.kartstore}
                      </div>
                      <div className="reports__item__report">
                        نوع گزارش: {item.message}
                      </div>
                      <div className="reports__item__status">
                        وضعیت: {getStatus(item.status)}
                      </div>
                      {item.status !== null && <div className="reports__item__status">
                        کارشناس: {item.userinfo.name}
                      </div>}
                      <div className="reports__item__date">
                        تاریخ ثبت: {moment(item.created_at, "YYYY/MM/DD")
                        .locale("fa")
                        .format("YYYY/MM/DD")}
                      </div>
                    </div>
                  </div>
                )) : (
                  <NotFoundBox
                    title="گزارشی ثبت نکرده‌اید!!"
                    bio=""
                    icon=""
                  />
                )
            )
          }

          {activeTab === 3 &&
            (myStoresReport.length > 0 ?
              myStoresReport.map(item => (
                <div className="reports__list">
                  <div className="reports__item">
                    <div className="reports__item__name">
                      نام فروشگاه: {item.introductionStore}
                    </div>
                    <div className="reports__item__report">
                      نوع گزارش: {item.message}
                    </div>
                    <div className="reports__item__status">
                      وضعیت: {getStatus(item.status)}
                    </div>
                    {item.status !== null && <div className="reports__item__status">
                      کارشناس: {item.submiter.name}
                    </div>}
                    <div className="reports__item__date">
                      تاریخ ثبت: {moment(item.created_at, "YYYY/MM/DD")
                      .locale("fa")
                      .format("YYYY/MM/DD")}
                    </div>
                  </div>
                </div>
              )) : (
                <NotFoundBox
                  title="گزارشی دریافت نکرده‌اید!!"
                  bio=""
                  icon=""
                />
              ))
          }
        </div>
      </Card>
    </Fade>
  );
};