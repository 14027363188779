import { SVG } from "components";
import { CreateMessageContext } from "containers/CreateMessageContainer";
import React, { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import ReactSlider from "react-slider";

const FillCustomAge = ({ close }) => {
  const [age, setAge] = useState({
    from: 12,
    to: 99,
  });
  const createAds = useContext(CreateMessageContext);

  const handleSubmitForm = () => {
    createAds.detail.set((prev) => ({
      ...prev,
      age: {
        from: age.from,
        to: age.to,
      },
    }));
  };

  useEffect(() => {
    setAge({
      from: createAds.detail.get.age.from,
      to: createAds.detail.get.age.to,
    });
  }, []);

  useEffect(() => {
    handleSubmitForm();
  }, [age]);

  return (
    <div className="fill-profile__form" style={{ paddingBottom: 125 }}>
      <div className="fill-profile__form__title">بازه‌ی سن</div>

      <ReactSlider
        className="select-filter__options__range"
        trackClassName="select-filter__options__range__track"
        defaultValue={[12, 99]}
        renderThumb={(props, state) => (
          <div {...props} className="select-filter__options__range__thumb">
            <span
              style={{
                fontSize: "16px",
                position: "absolute",
                top: "-30px",
                fontWeight: 700,
              }}
            >
              {state.valueNow}
            </span>
          </div>
        )}
        pearling
        minDistance={1}
        min={12}
        max={99}
        value={[age.from, age.to]}
        onChange={(e) => {
          setAge({ from: e[0], to: e[1] });
        }}
      />
    </div>
  );
};

export default FillCustomAge;
