import { SVG } from "components";
import { useEffect } from "react";
import "./FillProfile.scss";
import FillProfileAge from "./FillProfileAge/FillProfileAge";
import FillProfileCity from "./FillProfileCity/FillProfileCity";
import FillProfileEducation from "./FillProfileEducation/FillProfileEducation";
import FillProfileEmployment from "./FillProfileEmployment/FillProfileEmployment";
import FillProfileGender from "./FillProfileGender/FillProfileGender";
import FillProfileMarital from "./FillProfileMarital/FillProfileMarital";

export const FillProfile = ({
    active = false,
    type = "",
    closeFillProfile,
}) => {
    const handleFillProfile = () => {
        switch (type) {
            case "city":
                return <FillProfileCity close={closeFillProfile} />;

            case "gender":
                return <FillProfileGender close={closeFillProfile} />;

            case "age":
                return <FillProfileAge close={closeFillProfile} />;

            case "education":
                return <FillProfileEducation close={closeFillProfile} />;

            case "employment":
                return <FillProfileEmployment close={closeFillProfile} />;

            case "marital":
                return <FillProfileMarital close={closeFillProfile} />;

            default:
                return null;
        }
    };

    const handleShowTitle = () => {
        switch (type) {
            case "city":
                return "ساکن کجا هستید؟";

            case "gender":
                return "خانم هستید یا آقا؟";

            case "age":
                return "چند سالتونه؟";

            case "education":
                return "وضعیت تحصیلیتون چطوریه؟";

            case "employment":
                return "وضعیت شغلیتون چطوریه؟";

            case "marital":
                return "مجردید یا متاهل؟";

            default:
                return null;
        }
    };

    useEffect(() => {
        if (active)
            document.getElementsByTagName("body")[0].style.overflowY = "hidden";
        else document.getElementsByTagName("body")[0].style.overflowY = "auto";
    }, [active]);

    return (
        <div
            onClick={() => closeFillProfile(true)}
            className={`fill-profile ${active && "active"}`}
        >
            <div
                onClick={(e) => e.stopPropagation()}
                className="fill-profile__container"
            >
                <div className="fill-profile__header">
                    <div className="fill-profile__header__title">
                        {handleShowTitle()}
                    </div>
                    <div
                        className="fill-profile__header__close"
                        onClick={() => closeFillProfile(true)}
                    >
                        <SVG svg="times" />
                    </div>
                </div>
                {handleFillProfile()}
            </div>
        </div>
    );
};
