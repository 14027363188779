import { CheckBox, SVG } from "components";
import { AppContext } from "context/AppContext";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { fillProfileAPI } from "utils/Services/API";
import newStarSound from "../../../assets/sounds/star.mp3";
import { useUser } from "hooks";

const FillProfileGender = ({ close }) => {
    const user_token = localStorage.getItem("user_token");
    const {
        userState: { user },
    } = useUser();
    const [gender, setGender] = useState(0);
    const [audio] = useState(new Audio(newStarSound));

    const handleActivateSubmit = () => {
        if (gender === 0) return false;
        else return true;
    };

    const handleSubmitForm = () => {
        if (handleActivateSubmit()) {
            const APIResult = fillProfileAPI(user_token, "gender", {
                gender: gender,
            });
            APIResult.then((response) => {
                if (response.status === 200) {
                    toast.success("اطلاعات ستاره ثبت شد.");
                    if (user.detail.isSound) {
                        audio.play();
                    }
                    close(true);
                }
            });
        } else {
            toast.error("جنسیت خود را وارد نمایید.");
        }
    };

    return (
        <div className="fill-profile__form">
            <audio src={newStarSound} autoPlay={false}></audio>

            <div className="row">
                <div className="row" style={{ marginLeft: 16 }}>
                    <CheckBox
                        disable
                        defaultValue={gender === 1 && true}
                        onChange={(value) => {
                            if (value) {
                                setGender(1);
                            } else setGender(0);
                        }}
                    />
                    <div className="fill-profile__form__title">آقا</div>
                </div>

                <div className="row" style={{ marginLeft: 16 }}>
                    <CheckBox
                        disable
                        defaultValue={gender === 2 && true}
                        onChange={(value) => {
                            if (value) {
                                setGender(2);
                            } else setGender(0);
                        }}
                    />
                    <div className="fill-profile__form__title">خانم</div>
                </div>

                <div className="row" style={{ marginLeft: 16 }}>
                    <CheckBox
                        disable
                        defaultValue={gender === 3 && true}
                        onChange={(value) => {
                            if (value) {
                                setGender(3);
                            } else setGender(0);
                        }}
                    />
                    <div className="fill-profile__form__title">سایر</div>
                </div>
            </div>

            <div
                className={`fill-profile__form__button ${
                    handleActivateSubmit() && "active"
                }`}
                onClick={() => handleSubmitForm()}
            >
                <div className="fill-profile__form__button__shape">
                    <SVG
                        svg="bottom-shape"
                        color={handleActivateSubmit() ? "#21bbe1" : "#b3bdcc"}
                    />
                </div>
                ثبت
                <div className="fill-profile__form__button__star">
                    <SVG svg="profile-star" />
                </div>
            </div>
        </div>
    );
};

export default FillProfileGender;
