import {
    Card,
    Fade,
    Modal,
    Padding,
    PageTopbar,
    Spinner,
    SVG,
} from "../../components";
import React, { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { useUser } from "../../hooks";
import trove from "../../assets/images/treasure-chest.svg";
import lottery from "../../assets/images/lottery-bingo-svgrepo-com.svg";
import library from "../../assets/images/library-svgrepo-com.svg";
import kartaak from "../../assets/images/logo_kartak_k.png";
import { getHintAPI } from "../../utils";
import { set } from "react-ga";
import { ProfileSlider } from "./dependencies";

export const FunContainer = () => {
    const history = useHistory();
    const {
        userState: { user },
    } = useUser();
    const [hint, setHint] = useState<any>();
    const [troveModal, setTroveModal] = useState(false);
    const [loading, setLoading] = useState(true);
    const [fullhint, setFullhint] = useState(false);

    // const options = [
    //     {
    //         name: "گنجینه 28250",
    //         link: "/trove",
    //     },
    //     {
    //         name: "گنجینه 28250",
    //         link: "/new-trove",
    //     },
    //     {
    //         name: "گنجینه 150581",
    //         link: "/trove",
    //     },
    //     {
    //         name: "قرعه‌کشی",
    //         link: "/draw",
    //     },
    //     {
    //         name: "کتابخانه",
    //         link: "#",
    //     },
    // ];

    // const getHint = () => {
    //     setLoading(true);
    //     getHintAPI("کارتلای جدید")
    //         .then((response) => {
    //             setHint(response);
    //         })
    //         .catch(() => setHint(false))
    //         .finally(() => setLoading(false));
    // };

    useEffect(() => {
        history.replace("/kartaak-fun");
    }, []);

    const handleCollapseBank = () => {
        if (!troveModal) {
            setTimeout(() => {
                window.scrollTo(0, 630);
            }, 100);
        }
        sessionStorage.setItem(
            "bankCollapse",
            sessionStorage.getItem("bankCollapse") === "1" ? "0" : "1"
        );
        setTroveModal(!troveModal);
    };

    return (
        <Fade>
            <PageTopbar
                title={"کارتلا"}
                white={true}
                showKv={true}
                titleColor="black"
            />
            <Spinner active={loading} color={"dark"} />
            {/*<div className="wrapper-fix">*/}
            {/*<Modal active={troveModal} close={() => setTroveModal(false)}>*/}
            {/*  <div className="col">*/}
            {/*    <div className="button mb-8" onClick={() => history.push('/trove')}>28250</div>*/}
            {/*    <div className="button" onClick={() => history.push('/trove')}>281276</div>*/}
            {/*    {user?.detail?.haveStore &&*/}
            {/*      <div className="button mb-8" onClick={() => history.push('/myshop/trove')}>150581</div>}*/}
            {/*  </div>*/}
            {/*</Modal>*/}

            <div className="wrapper=fix">
                <div className="kartaak-fun">
                    {/*<div className="kartaak-fun__img">*/}
                    {/*  <img src={kartaak} alt=""/>*/}
                    {/*</div>*/}

                    <div className="kartaak-fun__desc">
                        {/* <div className="kartaak-fun__desc__gif"></div> */}
                        <div className="kartaak-fun__desc__slider">
                            <ProfileSlider />
                        </div>

                        <div className="kartaak-fun__desc__text">
                            {/*{hint?.content}*/}
                            {fullhint
                                ? hint?.content
                                : hint?.content &&
                                  `${hint?.content.slice(0, 100)}... `}
                            {!fullhint ? (
                                <span
                                    style={{ color: "#fd6547" }}
                                    onClick={() => setFullhint(true)}
                                    className=""
                                >
                                    ادامه مطلب
                                </span>
                            ) : (
                                <span
                                    style={{ color: "#fd6547" }}
                                    onClick={() => setFullhint(false)}
                                    className=""
                                >
                                    بستن
                                </span>
                            )}
                        </div>
                    </div>

                    <div className="kartaak-fun__items">
                        {/*<div className="w-100 px-16">*/}
                        {/*  <div style={{width: "100%", backgroundColor: "#2d55b7"}}*/}
                        {/*       className={`profile__bank ${troveModal && "active"}`}>*/}
                        {/*    <div*/}
                        {/*      style={{fontSize: "16px"}}*/}
                        {/*      className={`fs profile__slider__collapse svg-cash ${*/}
                        {/*        troveModal && "active"*/}
                        {/*      }`}*/}
                        {/*      onClick={() => handleCollapseBank()}*/}
                        {/*    >*/}
                        {/*      گنجینه ها*/}
                        {/*      <SVG svg="arrow"/>*/}
                        {/*    </div>*/}

                        {/*    <div className={`profile__items__item__apps ${"active"}`}>*/}
                        {/*      <div className={`profile__items__item__bank ${troveModal && "active"}`}>*/}
                        {/*        <div className="profile__items__row">*/}
                        {/*          <Link to={`/trove`}*/}
                        {/*                style={{fontSize: "14px", justifyContent: "center"}}*/}
                        {/*                className="profile__items__item svg-cash">*/}
                        {/*            28250*/}
                        {/*            /!*<SVG svg="wallet-cash"/>*!/*/}
                        {/*          </Link>*/}

                        {/*          <Link to={`/new-trove`}*/}
                        {/*                style={{fontSize: "14px", justifyContent: "center"}}*/}
                        {/*                className="profile__items__item svg-purple">*/}
                        {/*            281276*/}
                        {/*            /!*<SVG svg="wallet-kv"/>*!/*/}
                        {/*          </Link>*/}
                        {/*        </div>*/}

                        {/*        <div className="profile__items__row flex a-c">*/}
                        {/*          <Link*/}
                        {/*            to={`/myshop/trove`}*/}
                        {/*            style={{fontSize: "14px", justifyContent: "center"}}*/}
                        {/*            className="w-100 flex j-c a-c profile__items__item svg-red"*/}
                        {/*          >*/}
                        {/*            150581*/}
                        {/*            /!*<SVG svg="wallet-km"/>*!/*/}
                        {/*          </Link>*/}
                        {/*        </div>*/}
                        {/*      </div>*/}
                        {/*    </div>*/}
                        {/*  </div>*/}
                        {/*</div>*/}

                        {/* <div className="flex j-sb w-100 px-16">
              <div className="kartaak-fun__item" onClick={() => history.push('/cards')}>
                کارت استور
              </div>
            </div> */}
                    </div>
                </div>
            </div>
        </Fade>
    );
};
