import React, { useEffect, useState } from "react";
import { SVG, Card, Spinner, Modal } from "components";
import { Line } from "rc-progress";
import { useHistory } from "react-router-dom";
import { getQrAPI } from "utils/Services/API";
import { Hint } from "components/Hint";

export const QrBox = () => {
  const user_token = localStorage.getItem("user_token");
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [codeTime, setCodeTime] = useState("");
  const [showNotFound, setShowNotFound] = useState(false);
  const [showHint, setShowHint] = useState(false);

  const getQr = () => {
    const api = getQrAPI(user_token);
    api.then((response) => {
      switch (response.status) {
        case 200:
          setCodeTime(response.data.data.barcode.expireDay);
          break;

        case 404:
          setShowNotFound(true);
          break;

        default:
          break;
      }
    });
  };

  // useEffect(() => {
  //   getQr();
  // }, []);

  return (
    <React.Fragment>
      <Modal active={showHint} close={() => setShowHint(false)} hint='getKv'>
        <div className="cash-history__hint">
          چنانچه حداقل دو نفر با معرفی مستقیم شما در کارتک ثبت نام کرده باشند می
          تونید درخواست بارکد بدید تا روی گوشی تلفن همراهتون فعال بشه و از این
          به بعد می تونید کارتلای خودتون و بقیه را به روز رسانی کنید. این بارکد
          به مدت 30 روز فعاله و در صورتی که در 30 روز حداقل 200 KV کسب کرده
          باشید برای 30 روز دیگه فعال می شه.
          <br />
          <span style={{ color: "green" }}>مزایای دارنده بارکد:</span>
          <br />
          هر کاربری که با بارکد شما محتوای مجله کارتلاشو به روز رسانی کنه، بعد
          از آزاد کردن KV هایی که گرفته، معادل 20 % از بالاترین ارزش اون به
          عنوان پاداش بارکد به شما پرداخت میشه. و اگه کارتلای خودتونو باهاش به
          روز رسانی کنید این پاداش شامل خودتون هم میشه.
        </div>
      </Modal>
      {/* <div className="marketing__qr">
        <span className="marketing__qr__title">بارکد من</span>
      </div> */}
      <div className="mb-8 row j-c" >
        <Hint onClick={() => setShowHint(true)} theme="blue" />
      </div>
      <div
        className="marketing__qr__box"
        onClick={() => history.push("/qr-generator")}
      >
        <div className="marketing__qr__box__body">
          <div className="marketing__qr__box__body__wrapper">
            <div className="marketing__qr__box__body__barcode">
              <p>بارکد من</p>
              {/* <div className="marketing__qr__box__body__barcode__hint">
                <SVG svg="hint" />
                <span>جزئیات بارکد</span>
              </div> */}
            </div>
            <div className="marketing__qr__box__body__barcode-icon">
              <SVG svg="barcode-colorfull" />
            </div>
          </div>
        </div>

        {/* <div
          className="marketing__qr__box__footer"
        > */}
        {/* {showNotFound ? (
            <React.Fragment>
              <span></span>
            </React.Fragment>
          ) : (
            <div className="marketing__qr__box__footer__btn">
              <div className="bottom-navigation__shape">
                <SVG svg="bottom-shape" color="#21bbe1" />
              </div>
              <div className="bottom-navigation__shape reverse">
                <SVG svg="bottom-shape" color="#ffffff" />
              </div>
              <React.Fragment>
                <span>اشتراک گذاری</span>
                <SVG svg="share" />
              </React.Fragment>
            </div>
          )} */}
        {/* <div className="marketing__qr__box__footer__info">
            {!showNotFound ? (
              <React.Fragment>
                <span className="marketing__qr__box__footer__info__days">
                  {codeTime} روز
                </span>
                <Line
                  className="marketing__qr__box__footer__info__progress"
                  percent={(Number(codeTime) * 100) / 30}
                  strokeWidth={4}
                  trailWidth={4}
                  strokeColor="#17CFAD"
                  trailColor="#B3BDCC"
                  transition="1"
                />
              </React.Fragment>
            ) : (
              <span>ایجاد بارکد</span>
            )}
          </div> */}
        {/* </div> */}
      </div>
    </React.Fragment>
  );
};
