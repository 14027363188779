import {Request} from "../api-call";

export const getUserUnitsAPI = async () => {
  const {data} = await Request.get(`/api/v4/kartland/unit/my-unit`);
  return data;
};

export const getUserUnitNameAPI = async (userId = "") => {
  const { data , status} = await Request.get(`/api/v4/kartland/unit/market/${userId}`)
  return {data : data,  status : status}
}

export const setUserUnitNameAPI = async (marketName) => {
  const {data , status } = await Request.put(`/api/v4/kartland/unit/market?name=${marketName}`)
  return {data : data, status : status}
}