import { SVG, Card } from "components";
import { useUser } from "hooks";
import { transformPrice, currency } from "utils/Functions";

export const Summery = ({ cart }) => {
  const {
    userState: { user },
  } = useUser();

  return (
    <Card>
      <div className="orders__summery">
        <div className="orders__summery__info">
          <div className="orders__summery__info__title">
            <span>( {cart.items.length} کالا )</span>
          </div>
          {/* {cart.shipping_tracking !== null && cart.shipping_tracking?.length !== 0 && (
            <div className="orders__summery__info__track">
              <span>
                کد رهگیری ارسال:
                {cart.shipping_tracking?.length === 0
                  ? "ثبت نشده "
                  : ' ' + cart.shipping_tracking}
                {cart.shipping_tracking === null && "ثبت نشده "}
              </span>
            </div>
          )} */}
        </div>
        <div className="orders__summery__list">
          {cart.items.map((cartItem, index) => (
            <div className="orders__summery__list__item" key={index}>
              <div className="orders__summery__list__item__body">
                <div className="orders__summery__list__item__body__cover">
                  {
                    <img
                      loading="lazy"
                      src={`${cartItem.detail.Product.image[0]}`}
                      // src={`data:image/png;base64,${cartItem.detail.Product.image[0]}`}
                      alt=""
                    />
                  }
                </div>
                <div className="orders__summery__list__item__body__info">
                  <div className="orders__summery__list__item__body__info__title">
                    <span>{cartItem.detail.Product.title}</span>
                    <div className="orders__summery__list__item__body__info__title__kv-cost">
                      {/* <span className="orders__summery__list__item__body__info__title__kv-cost__slug">
                        ارزش kv:
                      </span>
                      <span>
                        {`
                          ${currency(
                            cartItem.detail.Variation.priceList[
                              user.detail.star - 1
                            ].kv
                          )} تومان
                        `}
                      </span> */}
                      <span className="orders__summery__list__item__body__info__title__kv-cost__slug">
                        قیمت واحد:
                      </span>
                      <span className="orders__summery__list__item__body__info__title__kv-cost__price">
                        {`
                          ${currency(cartItem.price)} تومان
                        `}
                      </span>
                    </div>
                  </div>
                  <div className="orders__summery__list__item__body__info__bio">
                    <div className="orders__summery__list__item__body__info__bio__count">
                      <span>{cartItem.count} عدد</span>
                    </div>
                    <div className="orders__summery__list__item__body__info__bio__price">
                      {transformPrice(cartItem.price * cartItem.count)}
                      <span>تومان</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="orders__summery__list__item__footer">
                <div className="orders__summery__list__item__footer__tags">
                  {cartItem?.detail?.Variation?.detail?.color?.name}
                </div>
                <div className="orders__summery__list__item__footer__tags">
                  <SVG svg="warranty" />
                  {cartItem?.detail?.Variation?.detail?.warranty}
                </div>
                <div className="orders__summery__list__item__footer__tags">
                  <SVG svg="sender" />
                  {cartItem?.detail?.Product?.seller}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Card>
  );
};
