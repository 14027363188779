import {SVG, Fade, PageTopbar, Card, Dots, Modal} from "components";
import React, {useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import {setShopCommentAPI} from "utils";
import GreatImage from "../../assets/images/emoji/great.png";
import SmileImage from "../../assets/images/emoji/smile.png";
import PokerImage from "../../assets/images/emoji/poker.png";
import SadImage from "../../assets/images/emoji/sad.png";
import AngryImage from "../../assets/images/emoji/angry.png";
import LikeImage from "../../assets/images/emoji/like.png";
import DislikeImage from "../../assets/images/emoji/dislike.png";
import toast from "react-hot-toast";
import {cloneDeep} from "lodash";

export const SellerCommentContainer = () => {
  const [textComment, setTextComment] = useState("");
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(-1);
  const [modalValue, setModalValue] = useState(0);
  const [userComments, setUserComments] = useState([
    {
      title: "قیمت محصولات",
      type: "priceProduct",
      value: 0,
    },
    {
      title: "کیفیت محصولات",
      type: "qualityProduct",
      value: 0,
    },
    {
      title: "برخورد فروشنده",
      type: "sellerReaction",
      value: 0,
    },
    {
      title: "ظاهر فروشگاه",
      type: "storeApparence",
      value: 0,
    },
    {
      title: "تعهد ساعت‌کاری",
      type: "workingHours",
      value: 0,
    },
    // {
    //   title: "تعهد اهدای KM",
    //   type: "kmWarranty",
    //   value: 0,
    // },
  ]);
  const {id} = useParams<any>();
  const history = useHistory();

  const handleSubmitComment = () => {
    if (!userComments[0].value && !userComments[1].value && !userComments[2].value && !userComments[3].value && !userComments[4].value) {
      toast.error('حداقل یکی از موارد امتیازدهی را پر کنید');
      return;
    }

    setLoading(true);
    let query = `comment=${textComment}&priceProduct=${userComments[0].value}&qualityProduct=${userComments[1].value}&sellerReaction=${userComments[2].value}&storeApparence=${userComments[3].value}&workingHours=${userComments[4].value}&kmWarranty=0`;

    setShopCommentAPI(id, query)
      .then((response) => {
        if (response.status === 200) {
          toast.success("نظر شما با موفقیت ثبت شد.");
          history.goBack();
        }
      })
      .catch((err) => {
        if (err.response.status === 404) {
          toast.error("برای ثبت نظر، ابتدا بارکد فروشگاه را اسکن نمایید.");
        }
      })
      .finally(() => setLoading(false));
  };

  const handleSubmitEmoji = (index) => {
    const commentIndex = userComments.findIndex(
      (userComment) => userComment === userComments[index]
    );
    let currUserComments = cloneDeep(userComments);
    currUserComments[commentIndex].value = modalValue;
    setUserComments(currUserComments);
    setShowModal(-1);
    setModalValue(0);
  };

  const handleShowEmoji = (index) => {
    switch (index) {
      case 1:
        return AngryImage;

      case 2:
        return SadImage;

      case 3:
        return PokerImage;

      case 4:
        return SmileImage;

      case 5:
        return GreatImage;

      default:
        break;
    }
  };

  const handleShowLikeEmoji = (index) => {
    switch (index) {
      case 1:
        return LikeImage;

      case 2:
        return DislikeImage;

      default:
        break;
    }
  };

  useEffect(() => {
    history.replace(`/shop/comment/${id}`);
  }, []);

  const handleRateNaming = (index, type) => {
    if (type === 4 || type === 5) {
      switch (index) {
        case 1:
          return "انجام تعهدات";

        case 2:
          return "عدم انجام تعهدات";

        default:
          break;
      }
      return;
    }
    switch (index) {
      case 1:
        return "خیلی بد";

      case 2:
        return "بد";

      case 3:
        return "متوسط";

      case 4:
        return "خوب";

      case 5:
        return "عالی";

      default:
        break;
    }
  };

  return (
    <Fade>
      <PageTopbar showKv={false} title="ثبت نظر"/>

      <Modal
        active={showModal !== -1 ? true : false}
        close={() => {
          setShowModal(-1);
          setModalValue(0);
        }}
      >
        <div className="shop-comments__rate">
          {showModal < 4 &&
            ["", "", "", "", ""].map((rate, index) => (
              <div
                className={`shop-comments__rate__item ${index + 1 === modalValue && "active"
                }`}
                onClick={() => setModalValue(index + 1)}
              >
                <img
                  src={
                    showModal > 4
                      ? handleShowLikeEmoji(index + 1)
                      : handleShowEmoji(index + 1)
                  }
                  alt=""
                />
              </div>
            ))}
          {showModal > 3 &&
            ["", ""].map((rate, index) => (
              <div
                className={`shop-comments__rate__item ${index + 1 === modalValue && "active"
                }`}
                onClick={() => setModalValue(index + 1)}
              >
                <img
                  src={
                    showModal > 3
                      ? handleShowLikeEmoji(index + 1)
                      : handleShowEmoji(index + 1)
                  }
                  alt=""
                />
              </div>
            ))}
        </div>

        {modalValue !== 0 && (
          <div className="shop-comments__rate__value">
            {handleRateNaming(modalValue, showModal)}
          </div>
        )}

        <div className="button" onClick={() => handleSubmitEmoji(showModal)}>
          ثبت
        </div>
      </Modal>

      <Card>
        <div className="mycity">
          {userComments.map((comment, index) => (
            <div
              className="shop-comments__item"
              key={index}
              onClick={() => {
                setShowModal(index);
                setModalValue(comment.value);
              }}
            >
              {comment.value !== 0 && (
                <div className="shop-comments__item__emoji">
                  <img
                    src={
                      index > 3
                        ? handleShowLikeEmoji(comment.value)
                        : handleShowEmoji(comment.value)
                    }
                    alt=""
                  />
                </div>
              )}

              <div className="shop-comments__item__section">
                {comment.title}
                <SVG svg="arrow"/>
              </div>
            </div>
          ))}
        </div>

        <textarea
          rows={6}
          className="textarea"
          placeholder="نظر خود را اینجا وارد نمایید"
          value={textComment}
          onChange={(e) => setTextComment(e.target.value)}
        />

        <div
          className="button"
          onClick={() => !loading && handleSubmitComment()}
        >
          {loading ? (
            <Dots color="light"/>
          ) : (
            <React.Fragment>ثبت نظر برای فروشگاه</React.Fragment>
          )}
        </div>
      </Card>

      {/* <div className="seller-comment">
        <div className="seller-comment__header">
          <div className="seller-comment__header__bar"></div>
          <div
            onClick={() => history.goBack()}
            className="seller-comment__header__action"
          >
            <SVG svg="times" />
          </div>
        </div>
        <div className="seller-comment__body">
          <div className="seller-comment__body__topbar">
            <span className="seller-comment__body__topbar__title">نظرات</span>
            <div className="seller-comment__body__topbar__report">
              <span>گزارش خطا</span>
              <SVG svg="seller-report" />
            </div>
          </div>
          <div className="seller-comment__body__wrapper">
            {["", "", "", "", "", "", ""].map((x) => (
              <div className="seller-comment__body__item">
                <div className="seller-comment__body__item__info">
                  <img
                    className="seller-comment__body__item__info__profile"
                    src={profile}
                    alt=""
                  />
                  <span className="seller-comment__body__item__info__name">
                    امید نظری
                  </span>
                  <span className="seller-comment__body__item__info__time">
                    20 دقیقه
                  </span>
                </div>
                <div className="seller-comment__body__item__comment">
                  <span>
                    لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و
                    با استفاده از طراحان گرافیک است. تمام و دشواری موجود در
                    ارائه موجود طراحی اساسا ... بیشتر بخوانید
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="seller-comment__footer">
          <img
            className="seller-comment__body__item__info__profile"
            src={profile}
            alt=""
          />
          <input
            placeholder="نظر خود را بنویسید ..."
            className="seller-comment__footer__input"
          />
          <div className="seller-comment__footer__submit">
            <span>ارسال</span>
            <SVG svg="seller-submit" />
          </div>
        </div>
      </div> */}
    </Fade>
  );
};
