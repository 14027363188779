import { Request } from "../../api-call";

export const submitLocation = async (lat, lng, address) => {
  let formData = new FormData();
  formData.append("address", address);
  const { data } = await Request.post(
    `/api/v3/kartland/store/setLoc?lat=${lat}&long=${lng}`,
    formData
  );
  return data;
};
