import {Request} from "../../api-call";

export const sendMessageAPI = async (type, message, pic) => {
  const {data} = await Request.post(`/api/v3/kartland/customerClubMessage?type=${type}&message=${message}`, pic, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
  return data;
};
