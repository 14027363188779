import { Dots, Fade, PageTopbar, Spinner, SVG } from "../../components";
import React, { useEffect, useState } from "react";
import { currency } from "../../utils/Functions";
import { useHistory, useParams } from "react-router-dom";
import {
    getStoreByIdAPI,
    rentStoreAPI,
} from "../../utils/Services/KartlandUnits";
import empty from "../../assets/images/empty-kartland.png";
import toast from "react-hot-toast";
import dinnerImage from "../../assets/images/dinner2.png";
import moment from "jalali-moment";
import { getProductDetailByIdAPI } from "utils/Services/Kartland/getProductDetailById";
import { rentProductAPI } from "utils/Services/KartlandUnits/ProductAttach";
import { getProfitPercentageAPI } from "utils/Services/KartlandUnits/GetProductsList";

export const KartlandProductDetailsContainer = () => {
    const [payLoading, setPayLoading] = useState(false);
    const [productDetails, setProductDetails] = useState<any>(null);
    const [productDetailsLoading, setProductDetailsLoading] = useState(true);
    const [profitPercentage, setProfitPercentage] = useState({
        personalization_share: 0,
        kp: 0,
    });
    const params: any = useParams();
    const history = useHistory();

    const getProductDetails = () => {
        setProductDetailsLoading(true);
        getProductDetailByIdAPI(params.productId)
            .then((res) => {
                if (res.status === 200) {
                    setProductDetails(res.data);
                    setProductDetailsLoading(false);
                } else {
                    toast.error("مشکلی در نمایش محصول پیش آمده است.");
                    setPayLoading(false);
                }
            })
            .catch((err) => {
                toast.error("مشکلی در نمایش محصول پیش آمده است.");
            })
            .finally(() => {
                setPayLoading(false);
            });
    };

    const getProfitPercentage = () => {
        setProductDetailsLoading(true);
        getProfitPercentageAPI()
            .then((response) => {
                if (response.status === 200) {
                    setProfitPercentage(response.data.data);
                } else {
                    toast.error("مشکلی در نمایش قیمت محصول پیش آمده است.");
                }
            })
            .catch((err) => {
                toast.error("مشکلی در نمایش قیمت محصول پیش آمده است.");
            })
            .finally(() => {
                setProductDetailsLoading(false);
            });
    };

    const rentProduct = () => {
        setPayLoading(true);
        rentProductAPI(params.id, params.productId)
            .then((res) => {
                if (res.status === 200) {
                    toast.success("محصول با موفقیت الصاق شد.");
                    history.replace(`/kartland/buy/options`);
                    setPayLoading(false);
                } else if (res.status === 403) {
                    toast.error("این محصول قبلا الصاق شده است.");
                    setPayLoading(false);
                }
            })
            .catch((e) => {
                console.log(e);
                toast.error("مشکلی در الصاق محصول پیش آمده است.");
                setPayLoading(false);
            });
    };

    const profitCalculation = (price) =>
        Math.floor(
            ((price - productDetails?.defaultPrice[1] - profitPercentage?.kp) *
                profitPercentage?.personalization_share) /
                100
        );

    useEffect(() => {
        history.replace(`/kartland/rent/${params.id}/${params.productId}`);
        getProductDetails();
        getProfitPercentage();
    }, []);

    return (
        <div className={"kartland"}>
            <PageTopbar title="جزییات محصول" showKv={false} white={true} />
            <Spinner active={productDetailsLoading} color="dark" />
            {productDetails !== null ? (
                <>
                    <div className={"kartland__details"}>
                        <div className={"kartland__details__store-info"}>
                            <div className="kartland__details__store-info__image">
                                <img
                                    src={
                                        productDetails?.image
                                            ? productDetails?.image[0] !==
                                              undefined
                                                ? productDetails?.image[0]
                                                : dinnerImage
                                            : dinnerImage
                                    }
                                    alt="store"
                                />
                            </div>

                            <div
                                className={
                                    "kartland__details__store-info__number"
                                }
                            >
                                <div
                                    // onClick={() =>
                                    //     history.push(
                                    //         `/seller/${productDetails.id}`
                                    //     )
                                    // }
                                    className={
                                        "kartland__details__store-info__number__value"
                                    }
                                    style={{
                                        color: "black",
                                        fontSize: "1.2rem",
                                    }}
                                >
                                    {productDetails.title}
                                </div>
                            </div>

                            <div
                                className={
                                    "kartland__details__store-info__number"
                                }
                            >
                                <div
                                    className={
                                        "kartland__details__store-info__number__title"
                                    }
                                >
                                    تامین کننده:
                                </div>
                                <div
                                    // onClick={() =>
                                    //     history.push(
                                    //         `/seller/${productDetails.id}`
                                    //     )
                                    // }
                                    className={
                                        "kartland__details__store-info__number__value"
                                    }
                                >
                                    {productDetails.seller}
                                </div>
                            </div>

                            <div
                                className={
                                    "kartland__details__store-info__item-row"
                                }
                            >
                                <div
                                    className={
                                        "kartland__details__store-info__item-row__title"
                                    }
                                >
                                    قیمت مصرف‌کننده:
                                </div>
                                <div
                                    className={
                                        "kartland__details__store-info__item-row__value"
                                    }
                                >
                                    {productDetails.defaultPrice[0]} تومان
                                </div>
                            </div>

                            <div
                                className={
                                    "kartland__details__store-info__number"
                                }
                            >
                                <div
                                    className={
                                        "kartland__details__store-info__number__title"
                                    }
                                >
                                    موجودی:
                                </div>
                                <div
                                    // onClick={() =>
                                    //     history.push(
                                    //         `/seller/${productDetails.id}`
                                    //     )
                                    // }
                                    className={
                                        "kartland__details__store-info__number__value"
                                    }
                                >
                                    {productDetails.available}
                                </div>
                            </div>

                            <div
                                className={
                                    "kartland__details__store-info__number"
                                }
                            >
                                <div
                                    className={
                                        "kartland__details__store-info__number__title"
                                    }
                                >
                                    تعداد فروخته‌شده تا کنون:
                                </div>
                                <div
                                    // onClick={() =>
                                    //     history.push(
                                    //         `/seller/${productDetails.id}`
                                    //     )
                                    // }
                                    className={
                                        "kartland__details__store-info__number__value"
                                    }
                                >
                                    {productDetails.totalUserBuy}
                                </div>
                            </div>

                            <div
                                className={
                                    "kartland__details__store-info__item-row"
                                }
                            >
                                <div
                                    className={
                                        "kartland__details__store-info__item-row__title"
                                    }
                                >
                                    قیمت برای کاربران عادی:
                                </div>
                                <div
                                    className={
                                        "kartland__details__store-info__item-row__value"
                                    }
                                >
                                    {productDetails.defaultPrice[3]} تومان
                                </div>
                            </div>

                            <div
                                className={
                                    "kartland__details__store-info__item-row"
                                }
                            >
                                <div
                                    className={
                                        "kartland__details__store-info__item-row__title"
                                    }
                                    style={{ color: "red" }}
                                >
                                    سهم شخصی‌سازی:
                                </div>
                                <div
                                    className={
                                        "kartland__details__store-info__item-row__value"
                                    }
                                    style={{ color: "red" }}
                                >
                                    {profitCalculation(
                                        productDetails?.defaultPrice[3]
                                    )}{" "}
                                    تومان
                                </div>
                            </div>

                            <div
                                className={
                                    "kartland__details__store-info__item-row"
                                }
                            >
                                <div
                                    className={
                                        "kartland__details__store-info__item-row__title"
                                    }
                                >
                                    قیمت برای کاربران 7 ستاره:
                                </div>
                                <div
                                    className={
                                        "kartland__details__store-info__item-row__value"
                                    }
                                >
                                    {productDetails.defaultPrice[2]} تومان
                                </div>
                            </div>

                            <div
                                className={
                                    "kartland__details__store-info__item-row"
                                }
                            >
                                <div
                                    className={
                                        "kartland__details__store-info__item-row__title"
                                    }
                                    style={{ color: "red" }}
                                >
                                    سهم شخصی‌سازی:
                                </div>
                                <div
                                    className={
                                        "kartland__details__store-info__item-row__value"
                                    }
                                    style={{ color: "red" }}
                                >
                                    {profitCalculation(
                                        productDetails?.defaultPrice[2]
                                    )}{" "}
                                    تومان
                                </div>
                            </div>

                            <div
                                className={
                                    "kartland__details__store-info__item-row"
                                }
                            >
                                <div
                                    className={
                                        "kartland__details__store-info__item-row__title"
                                    }
                                >
                                    قیمت برای کاربران کاپیتان:
                                </div>
                                <div
                                    className={
                                        "kartland__details__store-info__item-row__value"
                                    }
                                >
                                    {productDetails.defaultPrice[4]} تومان
                                </div>
                            </div>

                            <div
                                className={
                                    "kartland__details__store-info__item-row"
                                }
                            >
                                <div
                                    className={
                                        "kartland__details__store-info__item-row__title"
                                    }
                                    style={{ color: "red" }}
                                >
                                    سهم شخصی‌سازی:
                                </div>
                                <div
                                    className={
                                        "kartland__details__store-info__item-row__value"
                                    }
                                    style={{ color: "red" }}
                                >
                                    {profitCalculation(
                                        productDetails?.defaultPrice[4]
                                    )}{" "}
                                    تومان
                                </div>
                            </div>

                            <div
                                className={
                                    "kartland__details__store-info__item-row"
                                }
                            >
                                <div
                                    className={
                                        "kartland__details__store-info__item-row__title"
                                    }
                                >
                                    قیمت برای کاربران سیتیزن:
                                </div>
                                <div
                                    className={
                                        "kartland__details__store-info__item-row__value"
                                    }
                                >
                                    {productDetails.defaultPrice[5]} تومان
                                </div>
                            </div>

                            <div
                                className={
                                    "kartland__details__store-info__item-row"
                                }
                            >
                                <div
                                    className={
                                        "kartland__details__store-info__item-row__title"
                                    }
                                    style={{ color: "red" }}
                                >
                                    سهم شخصی‌سازی:
                                </div>
                                <div
                                    className={
                                        "kartland__details__store-info__item-row__value"
                                    }
                                    style={{ color: "red" }}
                                >
                                    {profitCalculation(
                                        productDetails?.defaultPrice[5]
                                    )}{" "}
                                    تومان
                                </div>
                            </div>

                            {/*<div className={"kartland__details__store-info__item"}>*/}
                            {/*  <div onClick={() => setShowKmCondition(!showKmCondition)}*/}
                            {/*       className={"kartland__details__store-info__item__title"}>*/}
                            {/*    <div className="">شرایط اهدای KM فروشگاه</div>*/}
                            {/*    <div className={`kartland__details__store-info__item__title__arrow ${showKmCondition && 'active'}`}>*/}
                            {/*      <SVG svg={'arrow'}/></div>*/}
                            {/*  </div>*/}
                            {/*  {showKmCondition &&*/}
                            {/*    <div className={"kartland__details__store-info__item__value"}>*/}
                            {/*      <div className="kartland__details__store-info__conditions">*/}
                            {/*        {productDetails.storeConditions?.length > 0 ?*/}
                            {/*          (productDetails.storeConditions.map((item) => (*/}
                            {/*            <div className="kartland__details__store-info__conditions__item">*/}
                            {/*              <div className="kartland__details__store-info__conditions__item__title">*/}
                            {/*                {renderTitle(item.title)}*/}
                            {/*              </div>*/}

                            {/*              <div className="kartland__details__store-info__conditions__item__name">*/}
                            {/*                {item.thirdField}*/}
                            {/*              </div>*/}

                            {/*              <div className="kartland__details__store-info__conditions__item__condition">*/}
                            {/*                {item.firstField} {item.title == 3 ? 'عدد' : ''}*/}
                            {/*              </div>*/}

                            {/*              <div className="kartland__details__store-info__conditions__item__km">*/}
                            {/*                KM {item.secondField}*/}
                            {/*              </div>*/}

                            {/*            </div>*/}
                            {/*          ))) : (*/}
                            {/*            <span className={'kartland__details__store-info__conditions__not-found'}>بدون شرایط KM</span>*/}
                            {/*          )}*/}
                            {/*      </div>*/}
                            {/*    </div>}*/}
                            {/*</div>*/}

                            {/*<div className={"kartland__details__store-info__address"}>*/}
                            {/*  <div className={"kartland__details__store-info__desc__title"}>توضیحات:</div>*/}
                            {/*  <div className={"kartland__details__store-info__desc__value"}>{productDetails.description}</div>*/}
                            {/*</div>*/}
                        </div>
                        {/*<div className={"kartland__details__price"}>*/}
                        {/*  <div className={"kartland__item__price__title"}>*/}
                        {/*    قیمت:*/}
                        {/*  </div>*/}
                        {/*  <div className={"kartland__item__price__value"}>*/}
                        {/*    fdsf*/}
                        {/*  </div>*/}
                        {/*</div>*/}
                    </div>
                    <div className={"w-full bottom-16"}>
                        <div
                            className="button width-full-16"
                            onClick={() =>
                                payLoading === false && rentProduct()
                            }
                        >
                            {payLoading ? <Dots color="light" /> : "انتخاب"}
                        </div>
                    </div>
                </>
            ) : (
                <div className="kartland__wrapper__empty">
                    <div className="kartland__wrapper__empty__image">
                        <img src={empty} alt="empty" />
                    </div>
                    <div className="kartland__wrapper__empty__text">
                        واحد مورد نظر شما یافت نشد
                    </div>
                    <div className="kartland__wrapper__empty__subtext">
                        در وارد کردن شماره واحد دقت فرمایید
                    </div>
                </div>
            )}
        </div>
    );
};
