import {
  AcceptRule,
  Card,
  CheckBox,
  Dots,
  Fade, ImageCropper,
  Modal, Padding,
  PageTopbar,
  Selectbox,
  Spinner,
  SVG,
  UpdateUserInfo,
} from "components";
import React, {useEffect, useState} from "react";
import toast from "react-hot-toast";
import {useHistory, useParams} from "react-router-dom";
//   import {checkPanelCodeAPI} from "utils/Services/API";

import {useUser} from "hooks";
import {ImageEditor} from "components/ImageEditor";
import {farsiDigitToEnglish} from "utils/Functions";
import {getIntroduceStoreDetailsAPI} from "utils/Services/Introduce/StoreDetails";
import {reportIntroduceStoreAPI, SubmitIntroduceStoreByImgAPI} from "utils";
import {SubmitIntroduceStoreByPhoneAPI} from "utils/Services/Introduce/SubmitIntroduceStoreByPhone";
import IntroduceShopLocation from "containers/IntroduceStoreContainer/dependencies/Location";
import imageCompression from "browser-image-compression";


export const ConfirmedIntroduceShopContainer = () => {
  const {id} = useParams<any>();
  const [shopDetail, setShopDetail] = useState({
    name: "",
    inviter: "",
    color: "ffffff",
    country: 1,
    state: 0,
    city: 0,
    subCity: 0,
    district: 0,
    zeroCat: 0,
    firstCat: 0,
    secondCat: 0,
    thirdCat: [],
    lat: 0,
    lng: 0,
    address: "",
    description: "",
    image: {
      file: null,
      url: "",
    },
  });
  const [introStoreDetails, setIntroStoreDetails] = useState<any>({
    name: "",
    address: "",
    color: "ffffff",
    country: 1,
    id: 0,
    city: 0,
    subCity: 0,
    district: 0,
    zeroCat: 0,
    firstCat: 0,
    secondCat: 0,
    warningMessage: "",
    thirdcategoryname: [],
    lat: 0,
    lng: 0,
    firstCategoryName: "",
    introduceUserInfo: "",
    image: {
      file: null,
      url: "",
    },
  });
  const [inviterCode, setInviterCode] = useState("");
  const [inviteCodeStatus, setInviteCodeStatus] = useState(false);
  const [inviteCodeLoading, setInviteCodeLoading] = useState(false);
  const [showImageEditor, setShowImageEditor] = useState(false);
  const [data, setData] = useState({
    countries: [],
    states: [],
    cities: [],
    subCities: [],
    districts: [],
  });
  const [submitLoading, setSubmitLoading] = useState(false);
  const history = useHistory();
  const [acceptedRule, setAcceptedRule] = useState(false);
  // const [intoStoreDetails, setStoreDetails] = useState<any>([])
  const [detailText, setDetailText] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [rejectDetail, setRejectDetail] = useState('');
  const [userInfo, setUserInfo] = useState({
    name: "",
    lastname: "",
    nationalCode: "",
  });
  const [loading, setloading] = useState(false);
  const [showLocation, setShowLocation] = useState(false);
  const [rejectMessage, setRejectMessage] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [addressValue, setAddressValue] = useState('');
  const [preAddress, setPreAddress] = useState('');
  const [temporaryAddress, setTemporaryAddress] = useState("");
  const [submitterComment, setSubmitterComment] = useState('');

  const {
    userState: {user},
  } = useUser();

  const getStoreDetails = (id) => {
    setloading(true);
    getIntroduceStoreDetailsAPI(id)
      .then((response) => {
        if (response.data !== null) {
          setIntroStoreDetails(response.data);
          setShopDetail({
            ...shopDetail,
            address: response.data.address,
            lat: response.data.lat,
            lng: response.data.lng
          });
          setTemporaryAddress(response.data.address);
          // setAddressValue(`${response.data.statename} , ${response.data.subcityname} , `);
          setPreAddress(`${response.data.statename} , ${response.data.subcityname} , `);
        }
      })
      .catch((err) => {
      })
      .finally(() => {
        setloading(false);
      });
  };

  const handelRejectStore = (warninig) => {
    if (rejectDetail === "") {
      toast.error("توضیحات رد فروشگاه را وارد نمایید.");
      return;
    }

    reportIntroduceStoreAPI(id, warninig)
      .then((response) => {
        setShowModal(false);
        toast.success(" با موفقیت رد شد");
      })
      .catch((err) => {
      })
      .finally(() => {

      });
  };

  const compressOptions = {
    maxSizeMB: 0.25,
    maxWidthOrHeight: 1024,
    onProgress: (percentage) => {
      // setCompressLoading(percentage);
    },
  };

  const SubmitIntroduceStoreByPhone = () => {
    if (phoneNumber.substring(0, 2) != "09" || phoneNumber.length !== 11) {
      toast.error("باید شماره موبایل فروشگاه را وارد کنی.");
      return;
    }

    if (shopDetail.image.file === null) {
      toast.error("باید عکس فروشگاهو وارد کنی.");
      return;
    }

    if (addressValue.length <= preAddress.length + 3) {
      toast.error("باید آدرس فروشگاه را وارد کنی.");
      return;
    }

    if (submitterComment.length > 128) {
      toast.error("متن توضیحات طولانیه");
      return;
    }

    setSubmitLoading(true);
    var form = new FormData();
    imageCompression(shopDetail.image.file, compressOptions).then(compressedImage => {
      form.append("picture", compressedImage);
      SubmitIntroduceStoreByImgAPI(id, addressValue, shopDetail.lat, shopDetail.lng, submitterComment, form).then(response => {
        if (response.status === 200) {
          SubmitIntroduceStoreByPhoneAPI(id, phoneNumber)
            .then((response) => {
              toast.success(" با موفقیت تایید شد");
              history.push("/my-introduces?waiting");
            })
            .catch((err) => {
              if (err.response.data.msg === "this number have store") {
                toast.error('با این شماره قبلا یک فروشگاه در کارتک ثبت شده');
              } else if (err.response.data.msg === "you are not submiter") {
                toast.error('شما کارشناس نیستید!');
              } else {
                toast.error('خطایی رخ داد');
              }
            })
            .finally(() => {
              setSubmitLoading(false);
            });
        }
      }).catch((err) => {
      })
        .finally(() => {
          setSubmitLoading(false);
        });

    });
  };

  useEffect(() => {
    getStoreDetails(id);
  }, []);

  return (
    <Fade>

      <Modal active={showModal} close={() => setShowModal(false)}>
        <div className="title">دلیل رد فروشگاه</div>
        <textarea
          className="textarea"
          rows={5}
          value={rejectMessage}
          onChange={(e) =>
            setRejectMessage(e.target.value)
          }
        />
        <div className="row j-sb">
          <div
            className="button red mr-10"
            // {`create-shop__inviter__button ${detailText.length === 0 && "hidden"}`}
            onClick={() => {
              handelRejectStore(rejectMessage);
              // setShowModal(false)
            }}
          >
            رد فروشگاه
          </div>
          <div
            className="button disable"
            // {`create-shop__inviter__button ${detailText.length === 0 && "hidden"}`}
            onClick={() => {
              setShowModal(false);
              // setShowModal(false)
            }}
          >
            بازگشت
          </div>
        </div>
      </Modal>
      <PageTopbar title="تائید فروشگاه"/>
      <Spinner active={loading} color="dark"/>

      <ImageCropper
        active={showImageEditor}
        onClose={(close) => {
          setShowImageEditor(!close);
        }}
        onConfirm={(cropper) => {
          setShowImageEditor(false);
          setShopDetail((prev) => ({
            ...prev,
            image: {
              url: URL.createObjectURL(cropper),
              file: cropper,
            },
          }));
        }}
      />

      {/*<ImageEditor*/}
      {/*  active={showImageEditor}*/}
      {/*  onClose={(close) => {*/}
      {/*    setShowImageEditor(!close);*/}
      {/*  }}*/}
      {/*  onConfirm={(cropper) => {*/}
      {/*    setShowImageEditor(false);*/}
      {/*    cropper.getCroppedCanvas().toBlob((blob) => {*/}
      {/*      setShopDetail((prev) => ({*/}
      {/*        ...prev,*/}
      {/*        image: {*/}
      {/*          url: cropper.getCroppedCanvas().toDataURL().substr(22),*/}
      {/*          file: blob,*/}
      {/*        },*/}
      {/*      }));*/}
      {/*    });*/}
      {/*  }}*/}
      {/*/>*/}

      {showLocation && (
        <IntroduceShopLocation
          close={() => setShowLocation(false)}
          onSubmit={(res) => {
            setTemporaryAddress(res.address);
            setShowLocation(false);
            setShopDetail({
              ...shopDetail,
              lat: res.lat,
              lng: res.lng,
            });
          }}
          defaultLat={shopDetail.lat === 0 ? introStoreDetails.lat : shopDetail.lat}
          defaultLng={shopDetail.lng === 0 ? introStoreDetails.lng : shopDetail.lng}
          showStore={true}
          // entryString={`${introStoreDetails.statename}، ${introStoreDetails.subcityname}، `}
          // entryAddress={temporaryAddress}
        />
      )}

      <div className="wrapper-fix">
        <Padding>
          <a href={`tel:${introStoreDetails.introduceUserInfo.mobile}}`}>
            <div className="confirm__number">
              <div className="confirm__number__text">
                اگر نیازی به هماهنگی داشتی میتونی با معرف فروشگاه تماس بگیری
              </div>
              <div className="confirm__number__phone">
                <div className="confirm__nubmer__phone__title">تماس با معرف فروشگاه:</div>
                <div className="confirm__nubmer__phone__value">
                  {introStoreDetails.introduceUserInfo?.mobile}
                </div>
              </div>
            </div>
          </a>

          <div className="confirm__info">
            <div className="confirm__number__text">
              نام و دسته بندی فروشگاه قابل ویرایش نیست. اگه اشتباه تایپی داره تائیدش کن و تو قسمت <span
              className={'confirm__info__text'}>نظر کارشناس</span> صحیحش رو
              بنویس ولی اگه کلاً اشتباه بود باید ردش کنی.
            </div>

            <div className="confirm__info__item">
              <div className="confirm__info__item__title">
                نام فروشگاه:
              </div>
              <div className="confirm__info__item__value">
                {introStoreDetails.name}
              </div>
            </div>
            <div className="confirm__info__item">
              <div className="confirm__info__item__title">
                دسته‌بندی:
              </div>
              <div className="confirm__info__item__value">
                {introStoreDetails.firstCategoryName}
              </div>
            </div>
          </div>

          <div className="confirm__location">
            <div className="confirm__location__map">
              موقعیت فروشگاه روی نقشه، تا چند متر قابل ویرایشه ولی اگه خیلی تفاوت داشت باید ردش کنی
            </div>
            <div
              className=""
              style={{borderRadius: 20, marginBottom: 16}}
              onClick={() => setShowLocation(true)}
            >
              موقعیت فروشگاه روی نقشه
              {/*<SVG svg={"arrow"}/>*/}
            </div>
          </div>
          <div className="confirm__form">
            <div className="confirm__form__title">
              {/*<span className={'confirm__form__title__star'}>*</span>*/}
              آدرس کامل فروشگاه رو برای نمایش به کاربران وارد کن
            </div>
            <textarea
              placeholder="آدرس محل فروشگاه را وارد نمایید"
              value={preAddress + addressValue.substr(preAddress.length)}
              className="input withoutHeight"
              name="text"
              rows={5}
              onChange={(e) => setAddressValue(e.target.value)}
            />


            <div className="confirm__form__item">
              <div className="confirm__form__title">شماره موبایل مالک فروشگاه رو وارد کن ( تا وقتی که مالک فروشگاه ثبت
                نام نکنه و اجازه نده، این شماره به هیچکس نمایش داده نمیشه )
              </div>
              <div className="row">
                <input
                  type="number"
                  className="input"
                  value={phoneNumber}
                  onChange={(e) => {
                    setPhoneNumber(farsiDigitToEnglish(e.target.value.replace(/ /g, '')));
                  }}
                  placeholder="شماره موبایل فروشگاه"
                />
              </div>
            </div>

            <div className="confirm__form__item">
              <div className="confirm__form__title">
                تصویر فروشگاه رو بارگذاری کن
              </div>
              <div
                className="create-ads__final__image"
                onClick={() => setShowImageEditor(true)}
              >
                <canvas
                  className={`create-ads__canvas ${shopDetail.image?.url !== "new" && "deactive"
                  }`}
                  id="canvas-image"
                />

                {shopDetail.image?.url === "" ? (
                  <SVG svg="ads-image"/>
                ) : shopDetail.image?.file === null ? (
                  // shopDetail.image?.url !== ""
                  // ? (
                  //   <img src={`data:image/png;base64,${shopDetail.image?.url}`} alt="" />
                  // ) : (
                  <SVG svg="ads-image"/>
                ) : (
                  // )
                  <img
                    src={`data:image/png;base64,${shopDetail.image?.url}`}
                    alt=""
                  />
                )}

                {shopDetail.image?.url === "" && (
                  <div className="create-ads__final__image__title">
                    بارگذاری تصویر
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="confirm__form__item mt-8">
            <div className="confirm__form__title">
              نظر کارشناس (اختیاری)
            </div>
            <textarea
              placeholder="اگه توضبحات اضافه‌ای داری اینجا تایپ کن"
              value={submitterComment}
              className="input withoutHeight"
              name="text"
              rows={5}
              onChange={(e) => setSubmitterComment(e.target.value)}
            />
          </div>
        </Padding>
        {/*<Card>*/}
        {/*  <div className="introduce-store-title">اطلاعات موجود از فروشگاه</div>*/}
        {/*  <div className="detail-introduce-store">*/}
        {/*    <div className="detail-introduce-store__item">*/}
        {/*      <div className="detail-introduce-store__item__title">*/}
        {/*        نام فروشگاه :*/}
        {/*      </div>*/}
        {/*      <div className="detail-introduce-store__item__content">*/}
        {/*        {introStoreDetails.name}*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*    <div className="detail-introduce-store__item">*/}
        {/*      <div className="detail-introduce-store__item__title">*/}
        {/*        دسته بندی فروشگاه :*/}
        {/*      </div>*/}
        {/*      <div className="detail-introduce-store__item__content">*/}
        {/*        {introStoreDetails.firstCategoryName}*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*    <div className="detail-introduce-store__item">*/}
        {/*      <div className="detail-introduce-store__item__title">*/}
        {/*        آدرس فروشگاه :*/}
        {/*      </div>*/}
        {/*      <div className="detail-introduce-store__item__content">*/}
        {/*        {introStoreDetails.address}*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*    <div className="detail-introduce-store__item j-sb">*/}
        {/*      <div className="detail-introduce-store__item__title">*/}
        {/*        شماره تلفن معرف : {introStoreDetails.introduceUserInfo?.mobile}*/}
        {/*      </div>*/}
        {/*      /!* <div className="detail-introduce-store__item__content">*/}
        {/*      {introStoreDetails.introduceUserInfo?.mobile}*/}
        {/*    </div> *!/*/}
        {/*      <div className="detail-introduce-store__item__phone">*/}
        {/*        <a href={`tel:${introStoreDetails.introduceUserInfo.mobile}}`}>*/}
        {/*          تماس*/}
        {/*          <div className="detail-introduce-store__item__phone__svg">*/}
        {/*            <SVG svg="phone"></SVG>*/}
        {/*          </div>*/}
        {/*        </a>*/}

        {/*      </div>*/}
        {/*    </div>*/}

        {/*    <div className="">*/}

        {/*      {introStoreDetails.thirdcategoryname.length > 0 && introStoreDetails.thirdcategoryname.map((item, index) => {*/}
        {/*        <div className="" key={index}>*/}
        {/*          {item.title}*/}
        {/*        </div>;*/}
        {/*      })}*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</Card>*/}

        {/* <UpdateUserInfo
          showUpdate={showFillProfileModal}
          setShowUpdate={(value) => {
              value && handleSubmitStore();
            setShowFillProfileModal(false);
          }}
          userInfo={userInfo}
          setUserInfo={setUserInfo}
          getUser={getUserDataFromApi}
        /> */}
        {/*<Card>*/}
        {/*  <div*/}
        {/*    className="create-ads__final__image"*/}
        {/*    onClick={() => setShowImageEditor(true)}*/}
        {/*  >*/}
        {/*    <canvas*/}
        {/*      className={`create-ads__canvas ${shopDetail.image?.url !== "new" && "deactive"*/}
        {/*      }`}*/}
        {/*      id="canvas-image"*/}
        {/*    />*/}

        {/*    {shopDetail.image?.url === "" ? (*/}
        {/*      <SVG svg="ads-image"/>*/}
        {/*    ) : shopDetail.image?.file === null ? (*/}
        {/*      // shopDetail.image?.url !== ""*/}
        {/*      // ? (*/}
        {/*      //   <img src={`data:image/png;base64,${shopDetail.image?.url}`} alt="" />*/}
        {/*      // ) : (*/}
        {/*      <SVG svg="ads-image"/>*/}
        {/*    ) : (*/}
        {/*      // )*/}
        {/*      <img*/}
        {/*        src={`data:image/png;base64,${shopDetail.image?.url}`}*/}
        {/*        alt=""*/}
        {/*      />*/}
        {/*    )}*/}

        {/*    {shopDetail.image?.url === "" && (*/}
        {/*      <div className="create-ads__final__image__title">*/}
        {/*        بارگذاری تصویر*/}
        {/*      </div>*/}
        {/*    )}*/}
        {/*  </div>*/}

        {/*  <div className="title">شماره موبایل فروشگاه</div>*/}
        {/*  <div className="row">*/}
        {/*    <input*/}
        {/*      type="number"*/}
        {/*      className="input"*/}
        {/*      value={phoneNumber}*/}
        {/*      onChange={(e) => {*/}
        {/*        setPhoneNumber(farsiDigitToEnglish(e.target.value.replace(/ /g, '')));*/}
        {/*      }}*/}
        {/*      placeholder="شماره موبایل فروشگاه"*/}
        {/*    />*/}
        {/*  </div>*/}

        {/*  <div*/}
        {/*    className="profile__item"*/}
        {/*    style={{borderRadius: 20, marginBottom: 16}}*/}
        {/*    onClick={() => setShowLocation(true)}*/}
        {/*  >*/}
        {/*    تغییر آدرس و موقعیت جغرافیایی فروشگاه (اختیاری)*/}
        {/*    <SVG svg={"arrow"}/>*/}
        {/*  </div>*/}
        {/*</Card>*/}

        <div className="row a-c j-c" style={{margin: "8px auto"}}>
          <CheckBox
            defaultValue={acceptedRule}
            type="check"
            onChange={(value) => setAcceptedRule(value ? true : false)}
          />
          <div className="accept-text">اطلاعات وارده شده صحیح میباشد و فروشگاه قابل تایید است.</div>
        </div>
        <div className="rej-sub-button">
          <div
            className={`button ${!acceptedRule && "disable"}`}
            onClick={() => {
              if (!acceptedRule) {
                toast.error(
                  "اطلاعات را بررسی و تایید کنید."
                );
                return;
              }
              SubmitIntroduceStoreByPhone();
            }}
          >
            {submitLoading ? <Dots color="light"/> : " تایید فروشگاه"}
          </div>
          <div
            className="button red "
            onClick={() => setShowModal(true)}
          >
            رد فروشگاه
          </div>
        </div>
      </div>
    </Fade>
  );

};
