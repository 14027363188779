export const Tab = ({ tabs, activeTab, toggleActiveTab }) => {
  return (
    <div className={`product__tab`} id="tab">
      {tabs.map((tab, index) => (
        <div
          className={`product__tab__item ${activeTab === tab.type && "active"}`}
          key={index}
          onClick={() => toggleActiveTab(tab.type)}
        >
          {tab.title}
        </div>
      ))}
    </div>
  );
};
