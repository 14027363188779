import { SVG } from "components";
import moment from "jalali-moment";
import { Link } from "react-router-dom";
import { currency } from "utils/Functions";

const SlRlLog = ({ type = "", data }) => {
  return (
    <Link to={`/collaborate/logs/slrl/${data.id}/${type}`} className="slrl__log">
      <div className="row a-c j-sb mb-8">
        <div className="row a-c">
          <SVG svg={`${type}-logo`} />
          <div className="slrl__log__title">
            سقف{" "}
            {type === "sl"
              ? currency(data.slCeiling)
              : currency(data.rlCeiling)}{" "}
            تومانی
          </div>
        </div>
        <div className="slrl__log__text">
          {moment(data.created_at, "YYYY/MM/DD")
            .locale("fa")
            .format("YYYY/MM/DD")}
        </div>
      </div>

      <div className="row j-sb slrl__log__text">
        وضعیت:
        <div className="slrl__log__text">
          {data.isDone === 0 ? "درحال تکمیل" : "تکمیل شده"}
        </div>
      </div>
    </Link>
  );
};

export default SlRlLog;
