import { CheckBox, SVG } from 'components';
import { CreateAdsContext } from 'containers';
import { AppContext } from 'context/AppContext';
import React, { useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { fillProfileAPI } from 'utils/Services/API';

const FillCustomGender = ({
  close
}) => {
  const createAds = useContext(CreateAdsContext);
  const [gender, setGender] = useState(0);
  const [genderValue, setGenderValue] = useState('تعیین نشده');

  const handleActivateSubmit = () => {
    return true;
  }

  const handleSubmitForm = () => {
    if (handleActivateSubmit()) {
      createAds.detail.set(prev => ({
        ...prev, gender: {
          gender: gender,
          genderValue: genderValue
        }
      }));

      close(true);
    } else {
      toast.error("جنسیت خود را وارد نمایید.");
    }
  }

  useEffect(() => {
    setGender(createAds.detail.get.gender.gender);
  }, [createAds]);

  return (
    <div className="fill-profile__form">

      <div className="row">
        <div className="row" style={{ marginLeft: 16 }}>
          <CheckBox
            disable
            defaultValue={gender === 1 && true}
            onChange={value => {
              if (value) {
                setGender(1);
                setGenderValue('آقا')
              }
            }}
          />
          <div className="fill-profile__form__title">
            آقا
          </div>
        </div>

        <div className="row" style={{ marginLeft: 16 }}>
          <CheckBox
            disable
            defaultValue={gender === 2 && true}
            onChange={value => {
              if (value) {
                setGender(2);
                setGenderValue('خانم')
              }
            }}
          />
          <div className="fill-profile__form__title">
            خانم
          </div>
        </div>

        <div className="row">
          <CheckBox
            disable
            defaultValue={gender === 0 && true}
            onChange={value => {
              if (value) {
                setGender(0);
                setGenderValue('تعین نشده')
              }
            }}
          />
          <div className="fill-profile__form__title">
            همه
          </div>
        </div>

      </div>

      <div
        className={`fill-profile__form__button ${handleActivateSubmit() && 'active'}`}
        onClick={() => handleSubmitForm()}
      >
        <div className="fill-profile__form__button__shape">
          <SVG svg="bottom-shape" color={handleActivateSubmit() ? '#21bbe1' : '#b3bdcc'} />
        </div>
        ثبت
        <div className="fill-profile__form__button__star">
          <SVG svg="profile-star" />
        </div>
      </div>
    </div>
  );
}

export default FillCustomGender;
