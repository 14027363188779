import { Card, Fade, PageTopbar, Spinner } from "components";
import { BarChart } from "components/Chart/Bar/BarChart";
import moment from "jalali-moment";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { adsHistoryAPI } from "utils";
import {useHistory} from "react-router-dom";

const periodItems = [
  {
    title: '7 روز گذشته',
    type: 1
  },
  {
    title: '7 هفته گذشته',
    type: 7
  },
  {
    title: '7 ماه گذشته',
    type: 30
  },
]

export const AdsHistoryContainer = () => {
  const { id } = useParams<any>();
  const history = useHistory()
  const [loading, setLoading] = useState(true);
  const [adsHistory, setAdsHistory] = useState<any>([]);
  const [activePeriod, setActivePeriod] = useState(0);
  const [publicChartData, setPublicChartData] = useState<any>({});
  const [specialChartData, setSpecialChartData] = useState<any>({});
  const [adsPublicSeen, setAdsPublicSeen] = useState(0);
  const [adsSpecialSeen, setAdsSpecialSeen] = useState(0);
  const [adsHistoryType, setAdsHistoryType] = useState('');

  const data = [
    {
      date: "2021-12-19",
      view: 4,
      SpecialView: 1
    }, {
      date: "2021-12-18",
      view: 5,
      SpecialView: 0
    }, {
      date: "2021-12-17",
      view: 6,
      SpecialView: 3
    }, {
      date: "2021-12-16",
      view: 5,
      SpecialView: 0
    }, {
      date: "2021-12-15",
      view: 4,
      SpecialView: 5
    }, {
      date: "2021-12-14",
      view: 5,
      SpecialView: 0
    }, {
      date: "2021-12-13",
      view: 6,
      SpecialView: 0
    }, {
      date: "2021-12-12",
      view: 5,
      SpecialView: 0
    }, {
      date: "2021-12-11",
      view: 4,
      SpecialView: 0
    }, {
      date: "2021-12-10",
      view: 5,
      SpecialView: 0
    }, {
      date: "2021-12-09",
      view: 6,
      SpecialView: 0
    }, {
      date: "2021-12-08",
      view: 5,
      SpecialView: 0
    }, {
      date: "2021-12-07",
      view: 4,
      SpecialView: 0
    }, {
      date: "2021-12-06",
      view: 5,
      SpecialView: 0
    }, {
      date: "2021-12-05",
      view: 6,
      SpecialView: 0
    }, {
      date: "2021-12-04",
      view: 5,
      SpecialView: 0
    }, {
      date: "2021-12-03",
      view: 4,
      SpecialView: 0
    }, {
      date: "2021-12-02",
      view: 5,
      SpecialView: 0
    }, {
      date: "2021-12-01",
      view: 6,
      SpecialView: 0
    }, {
      date: "2021-11-30",
      view: 7,
      SpecialView: 0
    }, {
      date: "2021-11-29",
      view: 6,
      SpecialView: 0
    }, {
      date: "2021-11-28",
      view: 5,
      SpecialView: 0
    }, {
      date: "2021-11-27",
      view: 5,
      SpecialView: 0
    }, {
      date: "2021-11-26",
      view: 4,
      SpecialView: 0
    }, {
      date: "2021-11-25",
      view: 3,
      SpecialView: 0
    }, {
      date: "2021-11-24",
      view: 2,
      SpecialView: 0
    }, {
      date: "2021-11-23",
      view: 1,
      SpecialView: 0
    }, {
      date: "2021-11-22",
      view: 0,
      SpecialView: 0
    }, {
      date: "2021-11-21",
      view: 1,
      SpecialView: 0
    }, {
      date: "2021-11-20",
      view: 2,
      SpecialView: 0
    }, {
      date: "2021-11-19",
      view: 3,
      SpecialView: 0
    }, {
      date: "2021-11-18",
      view: 4,
      SpecialView: 0
    }, {
      date: "2021-11-17",
      view: 5,
      SpecialView: 0
    }, {
      date: "2021-11-16",
      view: 6,
      SpecialView: 0
    }
  ];

  const getAdsHistory = () => {
    adsHistoryAPI(id, 'yearly').then(response => {
      setAdsHistory(response.data.statistics.StaticByPeriod);
      setAdsPublicSeen(response.data.statistics.totalGeneral);
      setAdsSpecialSeen(response.data.statistics.totalSpecial);
      setAdsHistoryType(response.data.type);
      setActivePeriod(1);
      setLoading(false);
    })
  }

  const handleGetHistoryByType = period => {
    if (adsHistoryType === 'DualAds') {
      setPublicChartData(
        {
          labels: handleDateToArray(period),
          datasets: [
            {
              label: 'بازدید عمومی',
              data: handleAnalyseData(period, 'public'),
              backgroundColor: [
                'rgba(54, 162, 235, 0.3)',
              ],
              borderColor: [
                'rgba(54, 162, 235, 1)',
              ],
              borderWidth: 2,
            }
          ]
        },
      );

      setSpecialChartData(
        {
          labels: handleDateToArray(period),
          datasets: [
            {
              label: 'بازدید خصوصی',
              data: handleAnalyseData(period, 'special'),
              backgroundColor: [
                'rgba(255, 99, 132, 0.3)',
              ],
              borderColor: [
                'rgba(255, 99, 132, 1)',
              ],
              borderWidth: 2,
            }
          ]
        },
      )
    }

    if (adsHistoryType === 'specialAds') {
      setSpecialChartData(
        {
          labels: handleDateToArray(period),
          datasets: [
            {
              label: 'بازدید اختصاصی',
              data: handleAnalyseData(period, 'special'),
              backgroundColor: [
                'rgba(225, 33, 105, 0.3)',
              ],
              borderColor: [
                'rgba(225, 33, 105, 1)',
              ],
              borderWidth: 2,
            },
          ]
        },
      )
    }

    if (adsHistoryType === 'publicAds') {
      setPublicChartData(
        {
          labels: handleDateToArray(period),
          datasets: [
            {
              label: 'بازدید عمومی',
              data: handleAnalyseData(period, 'public'),
              backgroundColor: [
                'rgba(54, 162, 235, 0.3)',
              ],
              borderColor: [
                'rgba(54, 162, 235, 1)',
              ],
              borderWidth: 2,
            },
          ]
        },
      )
    }
  }

  const handleDateToArray = (period) => {
    var dateArray = [];

    let periodSum = [];

    adsHistory.forEach((dataItem, index) => {
      periodSum.push(dataItem);

      if ((index + 1) % period === 0 || adsHistory.length - (dateArray.length * period) < period && adsHistory.length - 1 === index) {
        dateArray.push(periodSum);
        periodSum = [];
      }
    });

    return handleOrderDate(dateArray);
  }

  const handleOrderDate = date => {
    let resultData = [];

    date.forEach(dataItemList => {
      dataItemList.forEach((dataItem, index) => {
        if (index === 0)
          resultData.push(moment(dataItem.date, "YYYY/MM/DD").locale("fa").format("YYYY/MM/DD"));
      });
    });
    // console.log(resultData);

    return resultData;
  }

  const handleAnalyseData = (period, type) => {
    let resultData = [];
    let periodSum = [];

    adsHistory.forEach((dataItem, index) => {
      periodSum.push(dataItem);

      if ((index + 1) % period === 0 || adsHistory.length - (resultData.length * period) < period && adsHistory.length - 1 === index) {
        resultData.push(periodSum);
        periodSum = [];
      }
    });

    // console.log(resultData);
    return handleOrderData(resultData, type);
  }

  const handleOrderData = (data, type) => {
    let resultData = [];
    let periodSum = 0;

    data.forEach(dataItemList => {
      periodSum = 0;
      dataItemList.forEach(dataItem => {
        if (type === 'public') {
          periodSum += dataItem.view;
        } else {
          periodSum += dataItem.SpecialView;
        }
      });
      resultData.push(periodSum);
    });

    // console.log(resultData);
    return resultData;
  }

  const handleShowCharts = () => {
    if (adsHistoryType === 'DualAds') {
      return (
        <>
          <BarChart chartData={publicChartData} />
          <BarChart chartData={specialChartData} />
        </>
      )
    } else if (adsHistoryType === 'specialAds') {
      return (
        <BarChart chartData={specialChartData} />
      )
    } else if (adsHistoryType === 'publicAds') {
      return (
        <BarChart chartData={publicChartData} />
      )
    }
  }

  useEffect(() => {
    history.replace(`/ads/history/${id}`);
  }, []);

  useEffect(() => {
    handleGetHistoryByType(activePeriod);
  }, [activePeriod]);

  useEffect(() => {
    getAdsHistory();
  }, []);

  return (
    <Fade>
      <PageTopbar title="آمار محتوا" showKv={false} />
      <Spinner active={loading} color="dark" />
      <div className="ads-history">

        <Card>
          {adsHistoryType === 'DualAds' && (
            <>
              <div className="ads-history__seen">
                <div className="ads-history__seen__title">
                  تعداد کل بازدیدها تاکنون:
                </div>

                <div className="ads-history__seen__count">
                  {adsPublicSeen + adsSpecialSeen + ' نفر'}
                </div>
              </div>

              <div className="ads-history__seen">
                <div className="ads-history__seen__title">
                  تعداد کل بازدیدهای عمومی:
                </div>

                <div className="ads-history__seen__count">
                  {adsPublicSeen + ' نفر'}
                </div>
              </div>

              <div className="ads-history__seen last">
                <div className="ads-history__seen__title">
                  تعداد کل بازدیدهای اختصاصی:
                </div>

                <div className="ads-history__seen__count">
                  {adsSpecialSeen + ' نفر'}
                </div>
              </div>
            </>
          )}

          {adsHistoryType === 'publicAds' && (
            <div className="ads-history__seen last">
              <div className="ads-history__seen__title">
                تعداد کل بازدیدهای عمومی:
              </div>

              <div className="ads-history__seen__count">
                {adsPublicSeen + ' نفر'}
              </div>
            </div>
          )}

          {adsHistoryType === 'specialAds' && (
            <div className="ads-history__seen last">
              <div className="ads-history__seen__title">
                تعداد کل بازدیدهای اختصاصی:
              </div>

              <div className="ads-history__seen__count">
                {adsSpecialSeen + ' نفر'}
              </div>
            </div>
          )}
        </Card>

        <div className="ads-history__period">
          {
            periodItems.map((periodItem, index) => (
              <div
                className={`ads-history__period__item ${periodItem.type === activePeriod && 'active'}`}
                onClick={() => setActivePeriod(periodItem.type)}
                key={index}
              >
                {periodItem.title}
              </div>
            ))
          }
        </div>

        <Card>
          {handleShowCharts()}
        </Card>

      </div>
    </Fade>
  );
}