import React from "react";
import Favorites from "../../pages/Favorites";
import Product from "../../pages/Product";
import Profile from "../../pages/Profile";
import Search from "../../pages/Search";
import QRCode from "../../pages/QRCode";
import Login from "../../pages/Login";
import Addresses from "../../pages/Addresses";
import Support from "../../pages/Support";
import Orders from "../../pages/Orders";
import PersonalInfo from "../../pages/PersonalInfo";
import Cart from "../../pages/Cart";
import Friends from "../../pages/Friends";
import Compare from "../../pages/Compare";
import CateogryProduct from "pages/CateogryProduct";
import Shop from "pages/Shop";
import { ProductFullList } from "components";
import PaymentRedirect from "pages/PaymentRedirect";
import Kartella from "pages/Kartella";
import KartellaUnSeen from "pages/KartellaUnSeen";
import Ads from "pages/Ads";
import QrGenerator from "pages/QrGenerator";
import CashHistory from "pages/CashHistory";
import Wallet from "pages/Wallet";
import KmWallet from "pages/KmWallet";
import KmDonate from "pages/KmDonate";
import KmTransfer from "pages/KmTransfer";
import KmPurchase from "pages/KmPurchase";
import BuyPanel from "pages/Buy-Panel";
import Lottery from "pages/Lottery";
import CreateAds from "pages/CreateAds";
import KvAssign from "pages/KvAssign";
import KvConvert from "pages/KvConvert";
import UnAuth from "pages/UnAuth";
import Intro from "pages/Intro";
// import FAQ from "pages/FAQ";
import Collaboration from "pages/Collaboration";
import MyAds from "pages/MyAds";
import About from "pages/About";
import Rules from "pages/Rules";
import Adshistory from "pages/AdsHistory";
import Map from "../../pages/Map";
import Guide from "../../pages/Guide";
import RoadMap from "../../pages/RoadMap";
import UpdateAds from "pages/UpdateAds";
import Collaborate from "pages/Collaborate";
import CollaborateKartellaLogs from "pages/CollaborateKartellaLogs";
import CollaborateCityLogs from "pages/CollaborateCityLogs";
import CollaborateKmLogs from "pages/CollaborateKmLogs";
import CollaboratePanelLogs from "pages/CollaboratePanelLogs";
import Seller from "pages/Seller";
import SellerComment from "pages/SellerComment";
import CollaborateScoreLogs from "pages/CollaborateScoreLogs";
import Gift from "pages/Gift";
import CashPaymentHistory from "pages/CashPaymentHistory";
import KartellaSeen from "pages/KartellaSeen";
import KartLand from "pages/KartLand";
import KartLandUnit from "pages/KartLandUnit";
import ShopCreation from "pages/ShopCreation";
import Password from "pages/Password";
import {
  CreationInformation,
  SubmitShopLocation,
  CreationOwner,
  CreationTime,
  KmTerm,
  CreationShowCase,
  SubmitContacts, Discount, StoreTroveContainer,
} from "containers/MyShopContainer/sibling";
import Setting from "pages/Setting";
import Cards from "pages/Cards";
import { CardList, CardHistory } from "containers/CardsContainer/subsets";
import { CertificateContainer, ConfirmStoreAdmin, EditContent, ShopInformation, Trove281276Container } from "containers";
import Mycity from "pages/MyCity";
import Createshop from "pages/CreateShop";
import MyShopClub from "pages/MyShopClub";
import { Comments } from "../../containers/MyShopContainer/sibling/Comments";
import KartlandBuyUnit from "../../pages/KartlandBuyUnit";
import KartlandBuyType from "../../pages/KartlandBuyType";
import KartlandUserBuy from "../../pages/KartlandUserBuy";
import KartlandUnitDetails from "../../pages/KartlandUnitDetails";
import KartlandBuyParticipation from "../../pages/KartlandBuyParticipation";
import KartlandParticipationHistory from "../../pages/KartlandParticipationHistory";
import KartlandParticipationPackage from "../../pages/KartlandParticipationPackage";
import KartlandSellUnit from "../../pages/KartlandSellUnit";
import KartlandRent from "../../pages/KartlandRent";
import KartlandSellUnitHistory from "../../pages/KartlandSellUnitHistory";
import KartlandStoreDetails from "../../pages/KartlandStoreDetails";
import Alerts from "pages/Alerts";
import BuyBlueKm from "../../pages/BuyBlueKm";
import KmQueue from "pages/KmQueue";
import { Messages } from "../../containers/MyShopContainer/Club/Messages";
import CreateMessage from "pages/CreateMessage";
import { ProductsPage } from "../../containers/SellerContainer/dependencies/ProductsPage";
import Participationcertificate from "pages/ParticipationCertificate";
import Charge from "../../pages/Charge";
import ChargePage from "pages/ChargePage";
import BuyInternet from "pages/BuyInternet";
import OperatorCharge from "pages/OperatorCharge";
import CollaborateRlLogs from "pages/CollaborateRlLogs";
import CollaborateSlLogs from "pages/CollaborateSlLogs";
import { CollaborateSlRlLogsRecord } from "pages/CollaborateSlRlLogsRecord";
import IntroduceNumber from "../../pages/IntroduceNumber";
import NewLottery from "pages/NewLottery";
import IntroduceStore from "../../pages/IntroduceShop";
import MyIntroduceStores from "../../pages/MyIntroduceStores";
import { ConfirmedIntroduceShop } from "pages/ConfirmedIntroShop";
import NewLotteryDetail from "pages/NewLotteryDetail";
import IntroducredStores from "pages/IntroducedStore";
import KartakYar from "pages/KartakYar";
import AddNewUsers from "pages/AddNewUsers";
import Fun from "../../pages/Fun";
import Trove from "../../pages/Trove";
import AddNubmer from "../../containers/IntroduceNumberContainer/Dependencies/IntroduceSection";
import Draw from "../../pages/DrawPage";
import { Channel, FollowingStores } from "../../containers/MyCity";
import cardbox from "pages/cardbox";
import Reports from "../../pages/Reports";
import CreateContent from "../../pages/CreateContent";
import ContentManagement from "../../pages/ContentManagement";
import EditorContent from "../../pages/EditorContainer";
import AdminEditorContentEdit from "pages/AdminEditorContentEdit";
import { ConsultantCode } from "../../containers/KmWalletContainer/dependecies";
import Introduction from "../../pages/Introduction";
import CollaborateIntro from "../../containers/CollaborateContainer/CollaborateIntro";
import { UnitLogContainer } from "../../containers/CollaborateContainer/Logs/UnitLogContainer";
import SlRlContainer from "../../containers/CollaborateContainer/dependencies/SlRlContainer";
import CreateOnlineStore from "pages/CreateOnlineStore";
import Carts from "../../pages/Carts";
import OnlineStore from "pages/OnlineStoreDetails";
import KartlandMyCitizenPage from "pages/KartlandMyCitizen";
import KartlandMyCitizenDetailsPage from "pages/KartlandMyCitizenDetails";
import OrderDetails from "pages/OrderDetails";
import KartlandCodeBuy from "pages/KartlandCodeBuy";
import KartlandProductDetails from "pages/KartlandProductDetails";
import { OperatorContainer } from "containers/OperatorContainer/OperatorContainer";
import { ProductDetail } from "containers/OperatorContainer/ProductDetail";
import CreateProvider from "pages/CreateProvider";
import SellPlans from "pages/SellPlans";
// const Cards = React.lazy(() => import('pages/Cards'));

export const routes = [
  {
    path: "/",
    component: Shop,
    name: "market",
    private: false,
    exact: true,
    navigationBar: true,
  },
  // {
  //   path: "/mycity",
  //   component: Mycity,
  //   name: "my city",
  //   private: true,
  //   exact: true,
  //   navigationBar: false,
  // },
  // {
  //   path: "/createshop",
  //   component: Createshop,
  //   name: "create shop",
  //   private: true,
  //   exact: true,
  //   navigationBar: false,
  // },
  {
    path: "/createprovider",
    component: CreateProvider,
    name: "create shop",
    private: true,
    exact: true,
    navigationBar: false,
  },
  {
    path: "/introducestore",
    component: IntroduceStore,
    name: "introduce store",
    private: true,
    exact: true,
    navigationBar: false,
  },
  {
    path: "/shop/comment/:id",
    component: SellerComment,
    name: "sellerComment",
    private: true,
    exact: true,
    navigationBar: false,
  },
  {
    path: "/seller/:id",
    component: Seller,
    name: "seller",
    private: true,
    exact: true,
    navigationBar: false,
  },
  {
    path: "/seller/products/:id",
    component: ProductsPage,
    name: "sellerProducts",
    private: true,
    exact: true,
    navigationBar: false,
  },
  {
    path: "/guide",
    component: Guide,
    name: "guide",
    private: false,
    exact: true,
    navigationBar: false,
  },
  {
    path: "/roadmap",
    component: RoadMap,
    name: "roadmap",
    private: false,
    exact: true,
    navigationBar: false,
  },
  {
    path: "/collaboration",
    component: Collaboration,
    name: "collaboration",
    private: true,
    exact: true,
    navigationBar: false,
  },
  // {
  //   path: "/map",
  //   component: Map,
  //   name: "map",
  //   private: false,
  //   exact: true,
  //   navigationBar: true,
  // },
  {
    path: "/collaborate/logs/kartella",
    component: CollaborateKartellaLogs,
    name: "logs",
    private: true,
    exact: true,
    navigationBar: false,
  },
  {
    path: "/collaborate/logs/city",
    component: CollaborateCityLogs,
    name: "logs",
    private: true,
    exact: true,
    navigationBar: false,
  },
  {
    path: "/collaborate/logs/km",
    component: CollaborateKmLogs,
    name: "logs",
    private: true,
    exact: true,
    navigationBar: false,
  },
  {
    path: "/collaborate/logs/my-team",
    component: CollaboratePanelLogs,
    name: "logs",
    private: true,
    exact: true,
    navigationBar: false,
  },
  {
    path: "/collaborate/logs/invite",
    component: Collaborate,
    name: "logs",
    private: true,
    exact: true,
    navigationBar: false,
  },
  {
    path: "/collaborate/logs/score",
    component: CollaborateScoreLogs,
    name: "logs",
    private: true,
    exact: true,
    navigationBar: false,
  },
  {
    path: "/collaborate/logs/rl",
    component: CollaborateRlLogs,
    name: "logs",
    private: true,
    exact: true,
    navigationBar: false,
  },
  {
    path: "/collaborate/logs/sl",
    component: CollaborateSlLogs,
    name: "logs",
    private: true,
    exact: true,
    navigationBar: false,
  },
  {
    path: "/collaborate/logs/slrl/:id/:type",
    component: CollaborateSlRlLogsRecord,
    name: "logs",
    private: true,
    exact: true,
    navigationBar: false,
  },
  {
    path: "/collaborate",
    component: Collaborate,
    name: "collaborate",
    private: true,
    exact: true,
    navigationBar: false,
  },
  // {
  //   path: "/my-panel/:panel_id",
  //   component: MyPanel,
  //   name: "my-panel",
  //   private: true,
  //   exact: true,
  //   navigationBar: false,
  // },
  {
    path: "/buy-panel",
    component: BuyPanel,
    name: "buy-panel",
    private: true,
    exact: true,
    navigationBar: false,
  },
  {
    path: "/gift",
    component: Gift,
    name: "gift",
    private: true,
    exact: true,
    navigationBar: false,
  },
  {
    path: "/certificate/:id",
    component: CertificateContainer,
    name: "certificate",
    private: true,
    exact: true,
    navigationBar: false,
  },
  {
    path: "/certificate/participation/:id",
    component: Participationcertificate,
    name: "certificate",
    private: true,
    exact: true,
    navigationBar: false,
  },
  {
    path: "/ads/history/:id",
    component: Adshistory,
    name: "adshistory",
    private: true,
    exact: true,
    navigationBar: false,
  },
  {
    path: "/intro",
    component: Intro,
    name: "intro",
    private: false,
    exact: true,
    navigationBar: false,
  },
  {
    path: "/about",
    component: About,
    name: "about",
    private: false,
    exact: true,
    navigationBar: false,
  },
  {
    path: "/rules",
    component: Rules,
    name: "rules",
    private: false,
    exact: true,
    navigationBar: false,
  },
  {
    path: "/alerts",
    component: Alerts,
    name: "ralertsules",
    private: false,
    exact: true,
    navigationBar: false,
  },
  // {
  //   path: "/faq",
  //   component: FAQ,
  //   name: "faq",
  //   private: false,
  //   exact: true,
  //   navigationBar: false,
  // },
  {
    path: "/myads",
    component: MyAds,
    name: "myads",
    private: true,
    exact: true,
    navigationBar: false,
  },
  {
    path: "/kv-assign/:cart_id",
    component: KvAssign,
    name: "kv-assign",
    private: true,
    exact: true,
    navigationBar: false,
  },
  {
    path: "/kv-convert",
    component: KvConvert,
    name: "kv-convert",
    private: true,
    exact: true,
    navigationBar: false,
  },
  {
    path: "/lottery",
    component: Lottery,
    name: "lottery",
    private: true,
    exact: true,
    navigationBar: false,
  },
  {
    path: "/qr-generator",
    component: QrGenerator,
    name: "qr-generator",
    private: false,
    exact: true,
    navigationBar: false,
  },
  {
    path: "/cash",
    component: CashHistory,
    name: "cash",
    private: true,
    exact: true,
    navigationBar: false,
  },
  {
    path: "/cash/history",
    component: CashPaymentHistory,
    name: "cash-history",
    private: true,
    exact: true,
    navigationBar: false,
  },
  {
    path: "/wallet",
    component: Wallet,
    name: "wallet",
    private: true,
    exact: true,
    navigationBar: false,
  },
  {
    path: "/wallet/km",
    component: KmWallet,
    name: "km-wallet",
    private: true,
    exact: true,
    navigationBar: false,
  },
  {
    path: "/transaction/donate",
    component: KmDonate,
    name: "donate",
    private: true,
    exact: true,
    navigationBar: false,
  },
  {
    path: "/transaction/transfer",
    component: KmTransfer,
    name: "transfer",
    private: true,
    exact: true,
    navigationBar: false,
  },
  {
    path: "/transaction/queue",
    component: KmQueue,
    name: "queue",
    private: true,
    exact: true,
    navigationBar: false,
  },
  {
    path: "/wallet/km/purchase",
    component: KmPurchase,
    name: "km-purchase",
    private: true,
    exact: true,
    navigationBar: false,
  },
  {
    path: "/ads",
    component: Ads,
    name: "ads",
    private: true,
    exact: true,
    navigationBar: false,
  },
  {
    path: "/createads",
    component: CreateAds,
    name: "createads",
    private: true,
    exact: true,
    navigationBar: false,
  },
  {
    path: "/createads?final",
    component: CreateAds,
    name: "createads",
    private: true,
    exact: true,
    navigationBar: false,
  },
  {
    path: "/ads/update/:id",
    component: UpdateAds,
    name: "updateads",
    private: true,
    exact: true,
    navigationBar: false,
  },
  {
    path: "/profile/addresses",
    component: Addresses,
    name: "addresses",
    private: true,
    exact: true,
    navigationBar: false,
  },
  {
    path: "/login",
    component: Login,
    name: "login",
    private: false,
    exact: true,
    navigationBar: true,
  },
  // {
  //   path: "/kartella/seen",
  //   component: KartellaSeen,
  //   name: "seen",
  //   private: true,
  //   exact: true,
  //   navigationBar: false,
  // },
  // {
  //   path: "/kartella",
  //   component: KartellaUnSeen,
  //   name: "kartella",
  //   private: true,
  //   exact: true,
  //   navigationBar: true,
  // },
  {
    path: "/kartland",
    component: KartLand,
    name: "kartland",
    private: true,
    exact: true,
    navigationBar: true,
  },
  // {
  //   path: "/kartland/buy",
  //   component: KartlandBuyUnit,
  //   name: "kartland-unit",
  //   private: true,
  //   exact: true,
  //   navigationBar: false,
  // },
  {
    path: "/kartland/user-buy",
    component: KartlandUserBuy,
    name: "kartland-buy",
    private: true,
    exact: true,
    navigationBar: false,
  },
  {
    path: "/kartland/code-buy",
    component: KartlandCodeBuy,
    name: "kartland-code-buy",
    private: true,
    exact: true,
    navigationBar: false,
  },
  {
    path: "/kartland/details/:id",
    component: KartlandUnitDetails,
    name: "kartland-details",
    private: true,
    exact: true,
    navigationBar: false,
  },
  {
    path: "/kartland/participation/buy",
    component: KartlandBuyParticipation,
    name: "kartland-participation",
    private: true,
    exact: true,
    navigationBar: false,
  },
  {
    path: "/kartland/participation",
    component: KartlandParticipationHistory,
    name: "kartland-participation",
    private: true,
    exact: true,
    navigationBar: false,
  },
  {
    path: "/kartland/participation/:id",
    component: KartlandParticipationPackage,
    name: "kartland-participation",
    private: true,
    exact: true,
    navigationBar: false,
  },
  {
    path: "/kartland/my-citizen",
    component: KartlandMyCitizenPage,
    name: "kartland-participation",
    private: true,
    exact: true,
    navigationBar: false,
  },
  {
    path: "/kartland/my-citizen/:id",
    component: KartlandMyCitizenDetailsPage,
    name: "kartland-participation",
    private: true,
    exact: true,
    navigationBar: false,
  },
  {
    path: "/kartland/buy/options",
    component: KartlandBuyType,
    name: "kartland-unit",
    private: true,
    exact: true,
    navigationBar: true,
  },
  {
    path: "/kartland/rent/:id",
    component: KartlandRent,
    name: "kartland-unit",
    private: true,
    exact: true,
    navigationBar: false,
  },
  // {
  //   path: "/kartland/rent/:id/:storeId/:type",
  //   component: KartlandStoreDetails,
  //   name: "kartland-unit",
  //   private: true,
  //   exact: true,
  //   navigationBar: false,
  // },
  {
    path: "/kartland/rent/:id/:productId",
    component: KartlandProductDetails,
    name: "kartland-unit",
    private: true,
    exact: true,
    navigationBar: false,
  },
  {
    path: "/kartland/sell/history",
    component: KartlandSellUnitHistory,
    name: "kartland-unit",
    private: true,
    exact: true,
    navigationBar: false,
  },
  {
    path: "/kartland/sell/:id",
    component: KartlandSellUnit,
    name: "kartland-unit",
    private: true,
    exact: true,
    navigationBar: false,
  },
  {
    path: "/kartland/:id",
    component: KartLandUnit,
    name: "kartland-unit",
    private: true,
    exact: true,
    navigationBar: false,
  },
  {
    path: "/products",
    component: ProductFullList,
    name: "products",
    private: false,
    exact: true,
    navigationBar: false,
  },
  {
    path: "/myshop",
    component: ShopCreation,
    name: "shop-creation",
    private: true,
    exact: true,
    navigationBar: false,
  },
  {
    path: "/myshop/information",
    component: CreationInformation,
    name: "shop-creation-information",
    private: true,
    exact: true,
    navigationBar: false,
  },
  {
    path: "/myshop/club",
    component: MyShopClub,
    name: "shop-creation-club",
    private: true,
    exact: true,
    navigationBar: false,
  },
  {
    path: "/myshop/club/messages",
    component: Messages,
    name: "shop-creation-club",
    private: true,
    exact: true,
    navigationBar: false,
  },
  {
    path: "/myshop/club/createmessage",
    component: CreateMessage,
    name: "create message",
    private: true,
    exact: true,
    navigationBar: false,
  },
  {
    path: "/myshop/location",
    component: SubmitShopLocation,
    name: "my-shop-location",
    private: true,
    exact: true,
    navigationBar: false,
  },
  {
    path: "/myshop/contact",
    component: SubmitContacts,
    name: "my-shop-contact",
    private: true,
    exact: true,
    navigationBar: false,
  },
  {
    path: "/shop-creation/owner",
    component: CreationOwner,
    name: "shop-creation-owner",
    exact: true,
    private: true,
    navigationBar: false,
  },
  {
    path: "/myshop/timeplan",
    component: CreationTime,
    name: "shop-creation-time",
    exact: true,
    private: true,
    navigationBar: false,
  },
  {
    path: "/myshop/give-km",
    component: KmTerm,
    name: "my-shop-give-km",
    exact: true,
    private: true,
    navigationBar: false,
  },
  {
    path: "/myshop/comments",
    component: Comments,
    name: "my-shop-comments",
    exact: true,
    private: true,
    navigationBar: false,
  },
  {
    path: "/myshop/showcase",
    component: CreationShowCase,
    name: "shop-creation-show-case",
    exact: true,
    private: true,
    navigationBar: false,
  },
  {
    path: "/myshop/discount",
    component: Discount,
    name: "shop-creation-discount",
    exact: true,
    private: true,
    navigationBar: false,
  },
  {
    path: "/myshop/buy-km",
    component: BuyBlueKm,
    name: "shop-creation-show-case",
    exact: true,
    private: true,
    navigationBar: false,
  },
  {
    path: "/myshop/trove",
    component: StoreTroveContainer,
    name: "shop-creation-show-case",
    exact: true,
    private: true,
    navigationBar: false,
  },
  {
    path: "/product/:id",
    component: Product,
    name: "product",
    private: false,
    exact: true,
    navigationBar: false,
  },
  {
    path: "/favorites",
    component: Favorites,
    name: "favorites",
    private: true,
    exact: true,
    navigationBar: false,
  },
  {
    path: "/profile/orders",
    component: Orders,
    name: "orders",
    private: true,
    exact: true,
    navigationBar: false,
  },
  {
    path: "/profile/friends",
    component: Friends,
    name: "friends",
    private: true,
    exact: true,
    navigationBar: false,
  },
  {
    path: "/cards",
    component: Cards,
    name: "cards",
    private: true,
    exact: true,
    navigationBar: true,
  },
  {
    path: "/cards/list/:type",
    component: CardList,
    name: "cards-list",
    private: true,
    exact: true,
    navigationBar: false,
  },
  {
    path: "/cards/history/:type/:id",
    component: CardHistory,
    name: "cards-history",
    private: true,
    exact: true,
    navigationBar: false,
  },
  {
    path: "/profile/info",
    component: PersonalInfo,
    name: "info",
    private: true,
    exact: true,
    navigationBar: false,
  },
  {
    path: "/support",
    component: Support,
    name: "support",
    private: true,
    exact: true,
    navigationBar: false,
  },
  // {
  //   path: "/unauth",
  //   component: UnAuth,
  //   name: "unauth",
  //   private: false,
  //   exact: true,
  //   navigationBar: true,
  // },
  {
    path: "/search",
    component: Search,
    name: "search",
    private: false,
    exact: true,
    navigationBar: true,
  },
  {
    path: "/setting",
    component: Setting,
    name: "setting",
    private: true,
    exact: true,
    navigationBar: false,
  },
  {
    path: "/cart/:id",
    component: Cart,
    name: "cart",
    private: false,
    exact: true,
    navigationBar: true,
  },
  {
    path: "/qr",
    component: QRCode,
    name: "qr",
    private: false,
    exact: true,
    navigationBar: true,
  },
  {
    path: "/qr/:id",
    component: QRCode,
    name: "qr",
    private: false,
    exact: true,
    navigationBar: true,
  },
  {
    path: "/market",
    component: Shop,
    name: "shop",
    private: false,
    exact: true,
    navigationBar: true,
  },
  {
    path: "/compare",
    component: Compare,
    name: "compare",
    private: false,
    exact: true,
    navigationBar: false,
  },
  {
    path: "/category",
    component: CateogryProduct,
    name: "category",
    private: false,
    exact: true,
    navigationBar: true,
  },
  {
    path: "/profile",
    component: Profile,
    name: "profile",
    private: true,
    exact: true,
    navigationBar: true,
  },
  {
    path: "/pay",
    component: PaymentRedirect,
    name: "pay",
    private: false,
    exact: true,
    navigationBar: false,
  },
  {
    path: "/encryption",
    component: Password,
    name: "encryption",
    private: true,
    exact: true,
    navigationBar: false,
  },
  {
    path: "/charge",
    component: Charge,
    name: "charge",
    private: true,
    exact: true,
    navigationBar: false,
  },
  {
    path: "/chargePage",
    component: ChargePage,
    name: "charge",
    private: true,
    exact: true,
    navigationBar: false,
  },
  {
    path: "/buyInternet",
    component: BuyInternet,
    name: "internet",
    private: true,
    exact: true,
    navigationBar: false,
  },
  {
    path: "/new-lottery",
    component: NewLottery,
    name: "lottery",
    private: true,
    exact: true,
    navigationBar: false,
  },
  {
    path: "/OperatorCharge",
    component: OperatorCharge,
    name: "internet",
    private: true,
    exact: true,
    navigationBar: false,
  },
  {
    path: "/introduce",
    component: IntroduceNumber,
    name: "introduce",
    private: false,
    exact: true,
    navigationBar: false,
  },
  {
    path: "/validateintroduceshop/:id",
    component: ConfirmedIntroduceShop,
    name: "ConfirmedIntroduceShop",
    private: true,
    exact: true,
    navigationBar: false,
  },
  {
    path: "/my-introduces",
    component: MyIntroduceStores,
    name: "my-introduce",
    private: false,
    exact: true,
    navigationBar: false,
  },
  {
    path: "/draw",
    component: Draw,
    name: "Draw Page",
    private: true,
    exact: true,
    navigationBar: true,
  },
  {
    path: "/new-lottery/:id",
    component: NewLotteryDetail,
    name: "lotteryDetails",
    private: true,
    exact: true,
    navigationBar: false,
  },
  {
    path: "/introducred-stores/:id",
    component: IntroducredStores,
    name: "IntroducredStores",
    private: true,
    exact: true,
    navigationBar: false,
  },
  {
    path: "/kartak-yar",
    component: KartakYar,
    name: "Kartak Yar",
    private: true,
    exact: true,
    navigationBar: false,
  },
  {
    path: "/addnewusers",
    component: AddNewUsers,
    name: "Kartak Yar",
    private: true,
    exact: true,
    navigationBar: false,
  },
  // {
  //   path: "/kartaak-fun",
  //   component: Fun,
  //   name: "Kartaak-fun",
  //   private: true,
  //   exact: true,
  //   navigationBar: true,
  // },
  {
    path: "/trove",
    component: Trove,
    name: "trove",
    private: true,
    exact: true,
    navigationBar: false,
  },
  {
    path: "/add-number",
    component: AddNubmer,
    name: "add-nubmer",
    private: true,
    exact: true,
    navigationBar: false,
  },
  {
    path: "/following-store",
    component: FollowingStores,
    name: "following stores",
    private: true,
    exact: true,
    navigationBar: false,
  },
  {
    path: "/channel-store",
    component: Channel,
    name: "channel",
    private: true,
    exact: true,
    navigationBar: false,
  },
  {
    path: "/cardbox",
    component: cardbox,
    name: "cardbox",
    private: true,
    exact: true,
    navigationBar: false,
  },
  {
    path: "/reports",
    component: Reports,
    name: "Reports",
    private: true,
    exact: true,
    navigationBar: false,
  },
  {
    path: "/shop-info",
    component: ShopInformation,
    name: "ShopInformation",
    private: true,
    exact: true,
    navigationBar: false,
  },
  {
    path: "/create-content",
    component: CreateContent,
    name: "createContent",
    private: true,
    exact: true,
    navigationBar: false,
  },
  {
    path: "/content-management",
    component: ContentManagement,
    name: "createContent",
    private: true,
    exact: true,
    navigationBar: false,
  },
  {
    path: "/content-edit/:id",
    component: EditContent,
    name: "createContent",
    private: true,
    exact: true,
    navigationBar: false,
  },
  {
    path: "/new-trove",
    component: Trove281276Container,
    name: "trove-281276",
    private: true,
    exact: true,
    navigationBar: false,
  },
  {
    path: "/confirm-editor/:id",
    component: EditorContent,
    name: "EditorContent",
    private: true,
    exact: true,
    navigationBar: false,
  },
  {
    path: "/admin-editor-content-edit/:id/:goal",
    component: AdminEditorContentEdit,
    name: "AdminEditorContentEdit",
    private: true,
    exact: true,
    navigationBar: false,
  },
  {
    path: "/transaction/consultant",
    component: ConsultantCode,
    name: "AdminEditorContentEdit",
    private: true,
    exact: true,
    navigationBar: false,
  },
  {
    path: "/confirm-store-admin/:id",
    component: ConfirmStoreAdmin,
    name: "ConfirmStoreAdmin",
    private: true,
    exact: true,
    navigationBar: false,
  },
  {
    path: "/introduction",
    component: Introduction,
    name: "introduction",
    private: true,
    exact: true,
    navigationBar: false,
  },
  // {
  //   path: "/sellplans",
  //   component: SellPlans,
  //   name: "sellplans",
  //   private: true,
  //   exact: true,
  //   navigationBar: false,
  // },
  {
    path: "/collab-intro",
    component: CollaborateIntro,
    name: "collab-intro",
    private: true,
    exact: true,
    navigationBar: true,
  },
  {
    path: "/collaborate/logs/unit",
    component: UnitLogContainer,
    name: "collab-intro",
    private: true,
    exact: true,
    navigationBar: false,
  },
  {
    path: "/collaborate/slrl",
    component: SlRlContainer,
    name: "collab-intro",
    private: true,
    exact: true,
    navigationBar: false,
  },
  {
    path: "/create-admin-store",
    component: CreateOnlineStore,
    name: "create-admin-store",
    private: true,
    exact: true,
    navigationBar: false,
  },
  {
    path: "/carts",
    component: Carts,
    name: "create-admin-store",
    private: true,
    exact: true,
    navigationBar: true,
  },
  {
    path: "/operator-management",
    component: OperatorContainer,
    name: "create-admin-store",
    private: true,
    exact: true,
    navigationBar: true,
  },
  {
    path: "/operator-product/:id",
    component: ProductDetail,
    name: "create-admin-store",
    private: true,
    exact: true,
    navigationBar: false,
  },
  {
    path: "/onlineStore/:id",
    component: OnlineStore,
    name: "online-srote",
    private: true,
    exact: true,
    navigationBar: false,
  },
  {
    path: "/order-details/:id",
    component: OrderDetails,
    name: "OrderDetails",
    private: false,
    exact: true,
    navigationBar: false,
  },
];