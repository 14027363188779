import React,{useState} from "react";
import {SVG} from "components";

export const SearchInput = () => {
  const [searchInputValuie, setSearchInputValuie] = useState("");

  return (
    <div className="search__input">
      <input
        type="text"
        value={searchInputValuie}
        placeholder="جستجو در فروشگاه"
        onChange={(e) => setSearchInputValuie(e.target.value)}
      />
      <div className="search__input__icon">
        <SVG svg="find" />
      </div>
    </div>
  );
};
