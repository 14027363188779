import { Fade, NotFoundBox, PageTopbar } from 'components';
import React, {useEffect} from 'react';
import {useHistory} from "react-router-dom";

export const CityContainer = () => {
  const history = useHistory()

  useEffect(() => {
    history.replace("/collaborate/logs/city");
  }, []);

  return (
    <Fade>
      <PageTopbar title="سوابق جذب فروشگاه" />
      {/* {
        kartellaHistory.length === 0 && ( */}
      <NotFoundBox
        title="تاریخچه‌ای برای جذب فروشگاه یافت نشد"
        fullScreen={true}
        icon="city"
      />
      {/* )
      } */}
    </Fade>
  );
}