import { Padding, SVG } from "components";
import Checkout from "components/Checkout/Checkout";
import moment from "jalali-moment";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { transformPrice } from "utils/Functions";
import toast from "react-hot-toast";
import {
  getPaymentTokenAPI,
  getPaymentUrl,
  updateCartGatewayAPI,
} from "utils/Services/API";

export const Pending = ({ cart }) => {
  const [showCheckout, setShowCheckout] = useState(false);
  const history = useHistory();
  const user_token = localStorage.getItem("user_token");
  // const [showCheckout, setShowCheckout] = useState(false);
  const [loading, setLoading] = useState(false);

  const updateCartGateWay = async () => {
    setLoading(true);
    try {
      const response = await updateCartGatewayAPI(user_token, cart.id, 1);
      if (response.status >= 200 && response.status < 300) {
        // console.log("update gateway", response);
        getPaymentTokenAndRedirect();
      } else {
        console.log("error", response);
      }
      // TO DO : delete current if changing the gateway
    } catch (error) {
      console.log(error);
    }
  };

  const getPaymentTokenAndRedirect = async () => {
    try {
      const response = await getPaymentTokenAPI(user_token, cart.id);
      if (response.status >= 200 && response.status < 300) {

        if (response.data.data.tk === null) {
          toast.error("مشکلی از سمت درگاه بانکی رخ داد.");
          return;
        } else {
          const paymentURL = getPaymentUrl(cart.id, response.data.data.tk);
          history.push(`/pay?${paymentURL}`);
          setTimeout(() => {
            if (history.location.pathname.includes("/pay"))
              window.location.href = `${paymentURL}`;
          }, 600);
          // window.open(paymentURL, "_self");
        }
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
      {/* <Checkout
        active={showCheckout}
        setClose={(close) => setShowCheckout(close)}
        currCart={[cart]}
      /> */}
      <div className="orders__pending">
        <div className="orders__pending__header">
          <div className="orders__pending__header__title">
            <span>در انتظار پرداخت</span>
          </div>
          <div className="orders__pending__header__timer">
            <SVG svg="orders-timer" />
            <span>
              {Number(moment(cart.firstGoPayTime).format("mm")) +
                10 -
                new Date().getMinutes() +
                " "}
              دقیقه تا لغو سفارش
            </span>
          </div>
        </div>
        <div className="orders__pending__body">
          <div className="col">
            <span>
              {cart.amountPayable[0] !== undefined ? (
                <span>قیمت کل : {transformPrice(+cart.amountPayable[0])}</span>
              ) : (
                <span>قیمت کل: -</span>
              )}{" "}
              تومان
            </span>
            <p className="orders__pending__body__address">{`آدرس : ${cart.user.Address.address}`}</p>
          </div>
          <Link to="/carts" className="orders__collection__body__thumbnails">
            <SVG svg="dots" />
            {cart.items.map(
              (item, index) =>
                index < 3 && (
                  <div
                    className={`orders__collection__body__thumbnails__circle item-${
                      index + 1
                    }`}
                  >
                    <img
                      loading="lazy"
                      src={`${item.detail.Product.image}`}
                      // src={`data:image/png;base64,${item.detail.Product.image}`}
                      alt=""
                    />
                  </div>
                )
            )}
          </Link>
        </div>
        <div
          className="orders__pending__footer"
          onClick={() => {
            updateCartGateWay();
          }}
        >
          <div className="orders__pending__footer__shape">
            <SVG svg="bottom-shape" color="#21BBE1" />
          </div>
          <div className="orders__pending__footer__shape reverse">
            <SVG svg="bottom-shape" color="#21BBE1" />
          </div>
          <span>پرداخت</span>
          <SVG svg="orders-pay" />
        </div>
      </div>
    </React.Fragment>
  );
};
