import React, {useEffect, useState} from "react";
import { Fade, PageTopbar, Spinner } from "components";
import { getMyDirectCitizenAPI } from "utils";
import toast from "react-hot-toast";
import { useHistory } from "react-router-dom";

export const KartlandMyCitizenContainer = () => {

  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [citizenList, setCitizenList] = useState([]);

  const getCitizen = () => {
    getMyDirectCitizenAPI().then((response) => {
      if(response.status === 200) {
        setCitizenList(response.data);
      }
    }).catch((error) => {
      toast.error("دریافت سیتیزن ها با خطا مواجه شد.")
    }).finally(() => {
      setLoading(false);
    });
  }

  useEffect(() => {
    getCitizen();
  }, [])

  return(
    <Fade>
      <Spinner active={loading} color="dark"/>
      <div className={"kartland wrapper-fix"}>
        <PageTopbar title="سیتیزن های من" showKv={false} white={true}/>
        <div className="myCitizenList">
          {
            citizenList.length > 0 ? 
            <div className="myCitizenList__list">
              {
                citizenList?.map(citi => (
                  <div className="myCitizenList__list__data" onClick={() => {
                    history.push(`/kartland/my-citizen/${citi?.id}`)
                  }}>{citi?.detail?.fullName}</div>
                ))
              }
            </div> : <div className="myCitizenList__not-found">سیتیزنی یافت نشد</div>
          }
        </div>
      </div>
    </Fade>
  )
}