import React, { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import toast from "react-hot-toast";
import { addFavoriteAPI, devIp, getFavoritesAPI } from "../utils/Services/API";
import { cloneDeep, remove } from "lodash";
import { Fade, SVG, Padding, PageTopbar, Rate, Spinner } from "components";

const a = new Array(5);

const Favorites = () => {
  const [loading, setLoading] = useState(true);
  const user_token = localStorage.getItem("user_token");
  const [favoriteProducts, setFavoriteProducts] = useState([{}]);
  const history = useHistory();

  const getFavoriteProducts = () => {
    const APIResult = getFavoritesAPI(user_token);
    APIResult.then((response) => {
      setFavoriteProducts(response.data.data);
      setLoading(false);
    });
  };

  const showSkeletonLoading = [{}, {}, {}, {}, {}].map(() => (
    <div className="favorites__loading">
      <div className="favorites__loading__delete" />
      <div className="favorites__loading__image" />
      <div className="favorites__loading__content">
        <div className="favorites__loading__content__title" />
        <div className="favorites__loading__content__description" />
      </div>
    </div>
  ));

  const removeFromFavorites = (productId) => {
    setLoading(true);
    const APIResult = addFavoriteAPI(user_token, productId);
    APIResult.then((response) => {
      if (!response.data.data[0]) {
        const currFavoriteProducts = cloneDeep(favoriteProducts);

        remove(currFavoriteProducts, (product) => {
          return product.product.id === productId;
        });

        setFavoriteProducts(currFavoriteProducts);
        toast.success("محصول از لیست علاقه‌مندی‌ها حذف شد.");
      }
      setLoading(false);

    });
  };

  const handleRouteProduct = product => {
    history.push(`/product/${product.product.id}`);
  };

  useEffect(() => {
    getFavoriteProducts();
    history.replace("/favorites");
  }, []);

  return (
    <Fade>
      <div className="favorites">
        <Spinner active={loading} color="dark" />
        <PageTopbar title="علاقه‌مندی‌ها" />
        {!loading ? (
          favoriteProducts.length === 0 ? (
            <div className="favorites__not-found">
              <div className="favorites__not-found__logo">
                <SVG svg="heart" />
              </div>
              <span className="favorites__not-found__title">
                علاقه مندی وجود ندارد
              </span>
              <span className="favorites__not-found__description">
                با گشتن در فروشگاه محصولات مورد علاقه تان را پیدا کنید
              </span>
              <Link className="favorites__not-found__empty-button" to="/market">
                <div className="favorites__not-found__empty-button__title">
                  <div className="favorites__not-found__empty-button__icon cart">
                    <SVG svg="cart-small" />
                  </div>
                  فروشگاه
                </div>
                <div className="favorites__not-found__empty-button__icon">
                  <SVG svg="arrow-big-white" />
                </div>
              </Link>
            </div>
          ) : (
            <Padding>
              {favoriteProducts.map((product, index) => (
                <div
                  className="favorites__item"
                  key={index}
                >
                  <div
                    className="favorites__item__info"
                    onClick={() => handleRouteProduct(product)}
                  >
                    <div className="favorites__item__image">
                      <img
                        loading="lazy"
                        src={`${product.product.image[0]}`}
                        // src={`data:image/png;base64,${product.product.image[0]}`}
                        alt=""
                      />
                    </div>

                    <div className="col j-sb">
                      <div className="favorites__item__info__title">
                        {product.product.title}
                      </div>

                      <div className="favorites__item__info__description">
                        {/* <Rate number={2} /> */}
                        {/* <span>۲,۳۰۰,۰۰۰ تومان</span> */}
                      </div>
                    </div>

                  </div>

                  <div
                    className="favorites__item__delete"
                    onClick={() =>
                      removeFromFavorites(product.product.id)
                    }
                  >
                    <SVG svg="favorite-times" />
                  </div>

                </div>
              ))}
            </Padding>
          )
        ) : (
          null
        )}
      </div>
    </Fade>
  );
};

export default Favorites;
