import {
    AcceptRule,
    Card,
    CheckBox,
    Dots,
    Fade,
    Modal,
    PageTopbar,
    Selectbox,
    SVG,
    UpdateUserInfo,
} from "components";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useHistory } from "react-router-dom";
import {
    createShopAPI,
    getAllFirstCategoriesAPI,
    getCitiesAPI,
    getCountriesAPI,
    // getDistrictsAPI,
    getHintAPI,
    getStatesAPI,
    getSubCitiesAPI,
    getThirdCategoryByFirstAPI,
    introduceStoreAPI,
} from "utils";
import { cloneDeep, remove } from "lodash";
import { useUser } from "hooks";
import CreateShopLocation from "./dependencies/Location";
import { ImageEditor } from "components/ImageEditor";
import { farsiDigitToEnglish } from "utils/Functions";
import imageCompression from "browser-image-compression";
import IntroduceShopLocation from "./dependencies/Location";

export const IntroduceStoreContainer = () => {
    const user_token = localStorage.getItem("user_token");
    const [shopDetail, setShopDetail] = useState({
        name: "",
        color: "ffffff",
        country: 1,
        state: 0,
        city: 0,
        subCity: 0,
        // district: 0,
        zeroCat: 0,
        firstCat: 0,
        secondCat: [],
        thirdCat: [],
        lat: 0,
        lng: 0,
        address: "",
        image: {
            file: null,
            url: "",
        },
    });
    const [showImageEditor, setShowImageEditor] = useState(false);
    const [data, setData] = useState({
        countries: [],
        states: [],
        cities: [],
        subCities: [],
        // districts: [],
    });
    const [catData, setCatData] = useState({
        firstCats: [],
        thirdCats: [],
    });
    const [submitLoading, setSubmitLoading] = useState(false);
    const [compressLoading, setCompressLoading] = useState(0);
    const history = useHistory();
    const [showAlert, setShowAlert] = useState(false);
    const [hint, setHint] = useState<any>("");
    const [readCompleted, setreadCompleted] = useState(false);
    const [acceptedRule, setAcceptedRule] = useState(true);
    const [hintLoading, setHintLoading] = useState(true);
    const [showFillProfileModal, setShowFillProfileModal] = useState(false);
    const [showFillProfile, setShowFillProfile] = useState(false);
    const [showLocation, setShowLocation] = useState(false);
    const [activeStateName, setActiveStateName] = useState("");
    const [activeSubCityName, setActiveSubCityName] = useState("");
    const [showBarcode, setShowBarcode] = useState(false);
    const [userInfo, setUserInfo] = useState({
        name: "",
        lastname: "",
        nationalCode: "",
    });
    const {
        getUserDataFromApi,
        userState: { user },
    } = useUser();
    const [subCatModal, setSubCatModal] = useState(false);
    const [catName, setCatName] = useState("");

    const compressOptions = {
        maxSizeMB: 0.7,
        maxWidthOrHeight: 1024,
        onProgress: (percentage) => {
            setCompressLoading(percentage);
        },
    };

    const getFirstCategories = () => {
        getAllFirstCategoriesAPI()
            .then((response) => {
                setCatData({ ...catData, firstCats: response.data });
            })
            .catch((err) => {
                toast.error("خطا در بارگذاری دسته‌بندی‌ها");
            })
            .finally(() => {});
    };

    const getThirdCategories = (firstCatId) => {
        getThirdCategoryByFirstAPI(firstCatId)
            .then((response) => {
                setCatData({ ...catData, thirdCats: response.data });
            })
            .catch((err) => {})
            .finally(() => {});
    };

    const getCountries = () => {
        getCountriesAPI()
            .then((response) => {
                setData({ ...data, countries: response.data });
            })
            .catch(() => {
                toast.error("خطا در بارگذاری کشورها");
            })
            .finally(() => {});
    };

    const getStates = (countryId) => {
        getStatesAPI(countryId)
            .then((response) => {
                setData({ ...data, states: response.data });
            })
            .catch(() => toast.error("خطا در بارگذاری استان‌ها"))
            .finally(() => {});
    };

    const getCities = (stateId) => {
        getCitiesAPI(stateId)
            .then((response) => {
                setData({ ...data, cities: response.data });
            })
            .catch(() => toast.error("خطا در بارگذاری شهرستان‌ها"))
            .finally(() => {});
    };

    const getSubCities = (cityId) => {
        getSubCitiesAPI(cityId)
            .then((response) => {
                setData({ ...data, subCities: response.data });
            })
            .catch(() => toast.error("خطا در بارگذاری شهرها"))
            .finally(() => {});
    };

    // const getDistricts = (subCityId) => {
    //   getDistrictsAPI(subCityId)
    //     .then((response) => {
    //       setData({...data, districts: response.data});
    //     })
    //     .catch(() => toast.error("خطا در بارگذاری محله/ناحیه ها"))
    //     .finally(() => {
    //     });
    // };

    const handleSubmitStore = () => {
        if (shopDetail.name === "") {
            toast.error("نام فروشگاه را وارد نمایید.");
            return;
        }

        if (shopDetail.firstCat === 0) {
            toast.error("یک دسته‌بندی برای فروشگاه انتخاب کنید");
            return;
        }

        if (shopDetail.thirdCat.length === 0) {
            toast.error("یک زیردسته برای فروشگاه انتخاب کنید");
            return;
        }

        if (shopDetail.country === 0) {
            toast.error("کشور فروشگاه خود را انتخاب کنید.");
            return;
        }

        if (shopDetail.state === 0) {
            toast.error("استان فروشگاه خود را انتخاب کنید.");
            return;
        }

        if (shopDetail.city === 0) {
            toast.error("شهرستان فروشگاه خود را انتخاب کنید.");
            return;
        }

        if (shopDetail.subCity === 0) {
            toast.error("شهر/روستا فروشگاه خود را انتخاب کنید.");
            return;
        }

        if (shopDetail.lat === 0 && shopDetail.lng === 0) {
            toast.error("موقعیت جغرافیایی فروشگاه را انتخاب کنید.");
            return;
        }

        // if (shopDetail.image.file === null) {
        //     //   toast.error("تصویر فروشگاه را انتخاب کنید.");
        //     //   return;
        //     // }

        // if (shopDetail.district === 0) {
        //   toast.error('ناحیه/محله فروشگاه خود را انتخاب کنید.');

        //   return;
        // }

        setSubmitLoading(true);

        let query = `name=${shopDetail.name}&country=${
            shopDetail.country
        }&state=${shopDetail.state}&city=${shopDetail.city}&subCity=${
            shopDetail.subCity
        }
    &district=${""}
    &zeroCategory=${getCatParentCode(
        "zero",
        shopDetail.firstCat
    )}&firstCategory=${shopDetail.firstCat}&color=%23${shopDetail.color}&lat=${
            shopDetail.lat
        }&lng=${shopDetail.lng}`;

        shopDetail.thirdCat.forEach((catItem, index) => {
            query += `&secondCategory[${index}][id]=${catItem.secondCat_id}&thirdCategory[${index}][id]=${catItem.id}`;
        });

        // var form = new FormData();
        //
        // imageCompression(shopDetail.image.file, compressOptions).then(
        //   (compressed) => {
        // form.append("picture", null);
        //
        //   }
        // );
        introduceStoreAPI(query)
            .then((response) => {
                if (response.status === 200) {
                    toast.success("فروشگاه شما با موفقیت ثبت شد!");
                    history.push("/my-introduces?waiting");
                }
            })
            .catch((err) => toast.error("خطا در ثبت فروشگاه"))
            .finally(() => setSubmitLoading(false));
    };

    const getCatParentCode = (type, catId) => {
        if (type === "zero") {
            const result = catData.firstCats.find((catItem) => {
                if (catItem.id === catId) {
                    return catItem;
                }
            });
            return result.zeroCat_id;
        } else if (type === "second") {
            const result = catData.thirdCats.find((catItem) => {
                if (catItem.id === catId) {
                    return catItem;
                }
            });
            return result.secondCat_id;
        } else {
            return null;
        }
    };

    const isInThirdCats = (cat) => {
        return shopDetail.thirdCat.some((catItem) => {
            return catItem.id === cat.id;
        });
    };

    const handleSelectCat = (cat) => {
        let currThirdCats = cloneDeep(shopDetail.thirdCat);
        if (isInThirdCats(cat)) {
            remove(currThirdCats, (catItem) => {
                return catItem.id === cat.id;
            });
            setShopDetail({ ...shopDetail, thirdCat: currThirdCats });
        } else {
            currThirdCats.push(cat);
            setShopDetail({ ...shopDetail, thirdCat: currThirdCats });
        }
    };

    const handleShowCatTitle = (catId) => {
        return catData.thirdCats.find((catItem) => catItem.id === catId);
    };

    // const getHint = () => {
    //   getHintAPI("قوانین و هشدارها 5")
    //     .then((response) => {
    //       setHint(response);
    //     })
    //     .catch(() => setHint(false))
    //     .finally(() => setHintLoading(false));
    // };

    const UserInfoHandler = () => {
        // if (userInfo.name?.length === 0 || userInfo.name === null
        //   || userInfo.lastname?.length === 0 || userInfo.lastname === null
        //   || userInfo.nationalCode?.length === 0 || userInfo.nationalCode === null || userInfo.nationalCode?.length !== 10) {
        //   setShowFillProfile(true);
        // } else {
        //   setShowFillProfile(false);
        // }
        if (
            user.name?.length === 0 ||
            user.name === null ||
            user.lastname?.length === 0 ||
            user.lastname === null ||
            user.nationalCode?.length === 0 ||
            user.nationalCode === null ||
            user.nationalCode?.length !== 10
        ) {
            setShowFillProfile(true);
        } else {
            setShowFillProfile(false);
        }
    };

    useEffect(() => {
        setUserInfo({
            name: user.name,
            lastname: user.lastname,
            nationalCode: user.nationalCode,
        });
        UserInfoHandler();
    }, [user]);

    useEffect(() => {
        history.replace("/introducestore");
        getFirstCategories();
        // getCountries();
        getStates(1);
        // getHint();
    }, []);

    useEffect(() => {
        document
            .getElementById("buyUnitAlert")
            ?.addEventListener("scroll", () => {
                document.getElementById("buyUnitAlert").scrollTop >
                document.getElementById("buyUnitAlert")?.scrollHeight -
                    document.getElementById("buyUnitAlert")?.clientHeight -
                    20
                    ? setreadCompleted(true)
                    : setreadCompleted(false);
            });
        return () => {
            document
                .getElementById("buyUnitAlert")
                ?.removeEventListener("scroll", () => {
                    document.getElementById("buyUnitAlert").scrollTop >
                    document.getElementById("buyUnitAlert")?.scrollHeight -
                        document.getElementById("buyUnitAlert")?.clientHeight -
                        20
                        ? setreadCompleted(true)
                        : setreadCompleted(false);
                });
        };
    }, [document.getElementById("buyUnitAlert")?.scrollTop]);

    console.log(shopDetail.name.length);

    return (
        <Fade>
            <PageTopbar title="افزودن فروشگاه" showKv={false} />
            {/* <Modal active={showBarcode} close={() => setShowBarcode(false)}>
        <BarcodePageContainer />
      </Modal> */}
            <Modal active={subCatModal} close={() => setSubCatModal(false)}>
                <div className="w-100 flex a-c j-c title">{catName}</div>
                <div className="create-shop__tags">
                    {catData.thirdCats.map((catItem, index) => (
                        <div
                            className={`create-shop__tags__item ${
                                isInThirdCats(catItem) && "active"
                            }`}
                            // className="create-shop__tags__item"
                            onClick={() => handleSelectCat(catItem)}
                            key={index}
                        >
                            <div className={"flex a-c"}>{catItem.title}</div>
                            <div className={"flex a-c"}>
                                {!isInThirdCats(catItem) && (
                                    <CheckBox
                                        onChange={() => isInThirdCats(catItem)}
                                        type={"check"}
                                    />
                                )}
                                {isInThirdCats(catItem) && <SVG svg="times" />}
                            </div>
                        </div>
                    ))}
                </div>
            </Modal>
            {showLocation && (
                <IntroduceShopLocation
                    close={() => setShowLocation(false)}
                    onSubmit={(res) => {
                        setShowLocation(false);
                        setShopDetail({
                            ...shopDetail,
                            lat: res.lat,
                            lng: res.lng,
                        });
                    }}
                    // entryString={`${activeStateName}، ${activeSubCityName}، `}
                />
            )}
            <UpdateUserInfo
                showUpdate={showFillProfileModal}
                setShowUpdate={(value) => {
                    value && handleSubmitStore();
                    setShowFillProfileModal(false);
                }}
                userInfo={userInfo}
                setUserInfo={setUserInfo}
                getUser={getUserDataFromApi}
            />
            <div className="introduce-store-page">
                <Card>
                    {/*<div*/}
                    {/*  className="create-ads__final__image"*/}
                    {/*  onClick={() => setShowImageEditor(true)}*/}
                    {/*>*/}
                    {/*  <canvas*/}
                    {/*    className={`create-ads__canvas ${shopDetail.image?.url !== "new" && "deactive"*/}
                    {/*    }`}*/}
                    {/*    id="canvas-image"*/}
                    {/*  />*/}

                    {/*  {shopDetail.image?.url === "" ? (*/}
                    {/*    <SVG svg="ads-image"/>*/}
                    {/*  ) : shopDetail.image?.file === null ? (*/}
                    {/*    // shopDetail.image?.url !== ""*/}
                    {/*    // ? (*/}
                    {/*    //   <img src={`data:image/png;base64,${shopDetail.image?.url}`} alt="" />*/}
                    {/*    // ) : (*/}
                    {/*    <SVG svg="ads-image"/>*/}
                    {/*  ) : (*/}
                    {/*    // )*/}
                    {/*    <img*/}
                    {/*      src={`data:image/png;base64,${shopDetail.image?.url}`}*/}
                    {/*      alt=""*/}
                    {/*    />*/}
                    {/*  )}*/}

                    {/*  {shopDetail.image?.url === "" && (*/}
                    {/*    <div className="create-ads__final__image__title">*/}
                    {/*      بارگذاری تصویر*/}
                    {/*    </div>*/}
                    {/*  )}*/}
                    {/*</div>*/}

                    <div className="introduce-store__form-box">
                        <div className="row">
                            <div className="col w-100 ml-6">
                                <div className="introduce-store__title">
                                    کشور
                                </div>

                                <div className="introduce-store__box">
                                    ایران
                                </div>
                                {/*<Selectbox*/}
                                {/*  data={data.countries}*/}
                                {/*  placeholder="ایران"*/}
                                {/*  onChange={(value) => {*/}
                                {/*    setShopDetail({...shopDetail, country: value});*/}
                                {/*    getStates(value);*/}
                                {/*  }}*/}
                                {/*  onChangeString={(value) => {*/}
                                {/*    console.log(value);*/}
                                {/*  }}*/}
                                {/*  canChange={false}*/}
                                {/*/>*/}
                            </div>

                            <div className="col w-100 ml-6">
                                <div className="introduce-store__title">
                                    استان
                                </div>
                                <Selectbox
                                    data={data.states}
                                    placeholder="انتخاب استان"
                                    onChange={(value) => {
                                        setShopDetail({
                                            ...shopDetail,
                                            state: value,
                                            city: 0,
                                            subCity: 0,
                                        });
                                        getCities(value);
                                    }}
                                    onChangeString={(value) => {
                                        setActiveStateName(value);
                                    }}
                                    disabled={shopDetail.country === 0}
                                    openAll={true}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col w-100 ml-6">
                                <div className="introduce-store__title">
                                    شهرستان
                                </div>
                                <Selectbox
                                    data={data.cities}
                                    placeholder="انتخاب شهرستان"
                                    onChange={(value) => {
                                        setShopDetail({
                                            ...shopDetail,
                                            city: value,
                                            subCity: 0,
                                        });
                                        getSubCities(value);
                                    }}
                                    onChangeString={(value) => {
                                        console.log(value);
                                    }}
                                    disabled={shopDetail.state === 0}
                                    openAll={true}
                                    resetValue={shopDetail.city === 0}
                                />
                            </div>

                            <div className="col w-100 mr-6">
                                <div className="introduce-store__title">
                                    شهر / روستا
                                </div>
                                <Selectbox
                                    data={data.subCities}
                                    placeholder="انتخاب شهر یا روستا"
                                    onChange={(value) => {
                                        setShopDetail({
                                            ...shopDetail,
                                            subCity: value,
                                        });
                                        // getDistricts(value);
                                    }}
                                    onChangeString={(value) => {
                                        setActiveSubCityName(value);
                                    }}
                                    disabled={shopDetail.city === 0}
                                    openAll={true}
                                    resetValue={shopDetail.subCity === 0}
                                />
                            </div>
                            {/* {shopDetail.subCity !== 0 && (
            <div className="col w-100 mr-6">
              <div className="title">ناحیه / منطقه</div>
              <Selectbox
                data={data.districts}
                placeholder="انتخاب ناحیه یا منطقه"
                onChange={(value) =>
                  setShopDetail({...shopDetail, district: value})
                }
                onChangeString={(value) => {
                  console.log(value);
                }}
                disabled={shopDetail.subCity === 0}
              />
            </div>
          )} */}
                        </div>

                        <div
                            className="confirm__location"
                            style={{
                                borderRadius: 20,
                                marginBottom: 16,
                                fontSize: "14px",
                            }}
                            onClick={() =>
                                shopDetail.subCity !== 0 &&
                                setShowLocation(true)
                            }
                        >
                            تعیین موقعیت جغرافیایی فروشگاه روی نقشه
                            {/*<SVG svg={"arrow"}/>*/}
                        </div>

                        <div className="introduce-store__gif"></div>

                        <div className="introduce-store__title">
                            نام فروشگاه
                        </div>
                        <input
                            type="text"
                            className="input"
                            value={shopDetail.name}
                            onChange={(e) =>
                                setShopDetail({
                                    ...shopDetail,
                                    name: e.target.value,
                                })
                            }
                            placeholder="نام فروشگاه"
                            disabled={shopDetail.lat === 0}
                        />

                        <div className="row">
                            <div className="col w-100 ml-6">
                                <div className="introduce-store__title">
                                    دسته‌بندی صنفی
                                </div>
                                <Selectbox
                                    data={catData.firstCats}
                                    placeholder="انتخاب"
                                    onChange={(value) => {
                                        setShopDetail({
                                            ...shopDetail,
                                            firstCat: value,
                                            thirdCat: [],
                                        });
                                        getThirdCategories(value);
                                        setSubCatModal(true);
                                    }}
                                    onChangeString={(value) => {
                                        setCatName(value);
                                    }}
                                    openAll={shopDetail.firstCat !== 0}
                                    disabled={shopDetail.name.length === 0}
                                    canChange={shopDetail.name.length !== 0}
                                />
                            </div>

                            {/*{shopDetail.firstCat !== 0 && (*/}
                            {/*  <div className="col w-100 mr-6">*/}
                            {/*    <div className="title">زیردسته</div>*/}
                            {/*    <Selectbox*/}
                            {/*      data={catData.thirdCats}*/}
                            {/*      placeholder="انتخاب"*/}
                            {/*      onChange={(value) => {*/}
                            {/*        handleSelectCat(value);*/}
                            {/*        // setShopDetail({ ...shopDetail, thirdCat: value });*/}
                            {/*      }}*/}
                            {/*      onChangeString={(value) => {*/}
                            {/*        console.log(value);*/}
                            {/*      }}*/}
                            {/*      selectable={true}*/}
                            {/*    />*/}
                            {/*  </div>*/}
                            {/*)}*/}
                        </div>

                        {/*<div className="create-shop__tags">*/}
                        {/*  {catData.thirdCats.map((catItem, index) => (*/}
                        {/*    <div*/}
                        {/*      className={`create-shop__tags__item ${isInThirdCats(catItem) && "active"*/}
                        {/*      }`}*/}
                        {/*      // className="create-shop__tags__item"*/}
                        {/*      onClick={() => handleSelectCat(catItem)}*/}
                        {/*      key={index}*/}
                        {/*    >*/}
                        {/*      {isInThirdCats(catItem) && <SVG svg="times"/>}*/}
                        {/*      {catItem.title}*/}
                        {/*    </div>*/}
                        {/*  ))}*/}
                        {/*</div>*/}
                        {shopDetail.firstCat !== 0 && (
                            <div className="create-shop__tags2">
                                {shopDetail.thirdCat.map((catItem, index) => (
                                    <div
                                        className="create-shop__tags2__item active"
                                        onClick={() => handleSelectCat(catItem)}
                                        key={index}
                                    >
                                        {catItem.title}
                                        <SVG svg="times" />
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>

                    {/*<div className="row a-c j-c" style={{margin: "8px auto"}}>*/}
                    {/*  <CheckBox*/}
                    {/*    defaultValue={acceptedRule}*/}
                    {/*    type="check"*/}
                    {/*    onChange={(value) => setAcceptedRule(value ? true : false)}*/}
                    {/*  />*/}
                    {/*  <AcceptRule*/}
                    {/*    title="ثبت فروشگاه"*/}
                    {/*    color="dark"*/}
                    {/*    onCLick={() => setShowAlert(true)}*/}
                    {/*  />*/}
                    {/*</div>*/}
                    <div
                        className={`button ${!acceptedRule && "disable"}`}
                        onClick={() => {
                            if (showFillProfile) {
                                setShowFillProfileModal(true);
                            } else {
                                !submitLoading && handleSubmitStore();
                            }
                        }}
                    >
                        {submitLoading ? <Dots color="light" /> : "ثبت فروشگاه"}
                    </div>
                </Card>
            </div>
        </Fade>
    );
};
