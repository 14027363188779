import { Modal } from "components";
import { currency } from "utils/Functions";

export const KvInfo = ({ status, toggleStatus, price }) => {
  return (
    <Modal active={status} close={() => toggleStatus(false)}>
      <div className="kv-info">
        بعد از خرید و تحویل این کالا، می‌توانید یکی از KV های کیفتان را آزاد
        کرده و {currency(price)} تومان هدیه نقدی بگیرید.
      </div>
    </Modal>
  );
};
