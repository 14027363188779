import React from "react";
import { SVG } from "components";
import "./TopBar.scss";
import { Link } from "react-router-dom";

export const TopBar = ({ menuStatus, toggleMenu }) => {
    return (
        <React.StrictMode>
            <div
                style={{ paddingBottom: "0.7rem" }}
                className="profile__topbar"
            >
                <span className="row">
                    {/*<div*/}
                    {/*  className={`profile__topbar__action ${*/}
                    {/*    menuStatus ? "active" : "menu"*/}
                    {/*  }`}*/}
                    {/*  onClick={() => toggleMenu(true)}*/}
                    {/*>*/}
                    {/*  <SVG svg={`${menuStatus ? "times" : ""}`}/>*/}
                    {/*  {!menuStatus && "راهنما"}*/}
                    {/*</div>*/}

                    <Link to="/support" className="profile__topbar__support">
                        <span className="profile__topbar__title">پشتیبانی</span>
                        <SVG svg="support" />
                    </Link>

                    {/*<Link to="/qr" className="profile__topbar__action qr">*/}
                    {/*  <SVG svg="qr"/>*/}
                    {/*</Link>*/}
                </span>

                <div
                    className={`profile__topbar__action ${
                        menuStatus ? "active" : "menu"
                    }`}
                    onClick={() => toggleMenu(true)}
                >
                    <SVG svg={`${menuStatus ? "times" : ""}`} />
                    {!menuStatus && "راهنما"}
                </div>

                {/*<Link to="/support" className="profile__topbar__support">*/}
                {/*  <span className="profile__topbar__title">پشتیبانی</span>*/}
                {/*  <SVG svg="support" />*/}
                {/*</Link>*/}
            </div>
        </React.StrictMode>
    );
};
