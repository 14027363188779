import { Request } from "../api-call";

export const updateMateInfoAPI = async (user) => {
  const { data } = await Request.post(
    `api/v3/collaboration/UserProfile?name=${user.name}&lastName=${user.lastname}`, user.nationalCode && {
      nationalCode : user.nationalCode
    }
  );
  return data;
};
