import { createContext, FC, ReactNode, useReducer } from "react";
import { cartInitialState } from "./InitialState";
import { CartReducer } from "./Reducer";

export const CartContext = createContext(undefined);

export const CardProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [cartState, cartDispatch] = useReducer(CartReducer, cartInitialState);

  return (
    <CartContext.Provider value={{ cartState, cartDispatch }}>
      {children}
    </CartContext.Provider>
  );
};
