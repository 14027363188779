import {
    Card,
    Fade,
    Modal,
    Padding,
    PageTopbar,
    PanelBox,
    Share,
    Spinner,
    SVG,
} from "components";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import {
    advertisePanel,
    collaborateScoreLogsAPI,
    getHintAPI,
} from "utils/Services";
import { NotFound } from "containers/MarketingContainer/dependencies";
import Slrl from "./dependencies/SLRL";
import { useUser } from "../../hooks";
import toast from "react-hot-toast";

const panelTypes: any = [
    // {
    //   title: "تاریخچه فروش نقدی (Sell Limit)",
    //   icon: "sl-logo",
    //   type: "gold",
    //   path: "/collaborate/logs/sl",
    // },
    // {
    //   title: "تاریخچه پاداش (Reward Limit)",
    //   icon: "rl-logo",
    //   type: "gold",
    //   path: "/collaborate/logs/rl",
    // },
    {
        title: "پاداش فروش مستقیم تبلیغات کارتلا",
        icon: "kartella",
        type: "white gold",
        percent: "kartellaProfit",
        path: "/collaborate/logs/kartella",
    },
    {
        title: "پاداش فروش مستقیم فورشاپ کارتلند",
        icon: "city",
        type: "white gold",
        percent: "kartlandProfit",
        path: "/collaborate/logs/unit",
    },
    // {
    //   title: "تاریخچه جذب مشارکت کارتلند",
    //   icon: "city",
    //   type: "white gold",
    //   path: "#",
    // },
    // {
    //   title: "سوابق فروش بسته‌های 100تایی KM",
    //   icon: "wallet-km",
    //   type: "white gold",
    //   path: "/collaborate/logs/km",
    // },
    // {
    //   title: "تیم فروش من",
    //   icon: "friends",
    //   type: "gold",
    //   path: "/collaboration",
    // },
    // {
    //   title: "پاداش فروش تیمی",
    //   icon: "friends",
    //   type: "gold",
    //   path: "/collaborate/logs/panel",
    // },
];

export const CollaborateContainer = () => {
    const history = useHistory();
    const [userPanelData, setUserPanelData] = useState<any>({});
    const [loading, setLoading] = useState(true);
    const [userScoreData, setUserScoreData] = useState<any>({
        ThisMonth: [],
    });
    const [scoreLoading, setScoreLoading] = useState(true);
    const [showSharing, setShowSharing] = useState(false);
    const {
        userState: { user },
    } = useUser();
    const [showPanelInfo, setShowPanelInfo] = useState(false);
    const [hint, setHint] = useState({ content: "", title: "" });
    const [goldHint, setGoldHint] = useState({ content: "", title: "" });
    const [whiteHint, setWhiteHint] = useState({ content: "", title: "" });
    const [hintModal, setHintModal] = useState(false);
    const [goldHintModal, setGoldHintModal] = useState(false);

    const handlePanelName = (type) => {
        switch (type) {
            case "white":
                return "پنل سفید";
            case "gold":
                return "پنل طلایی";
            case "silver":
                return "پنل نقره ای";
            case "bronze":
                return "پنل برنزی";
            default:
                return "پنل نامشخص";
        }
    };

    const copy = () => {
        const value: any = document.getElementById("panel_code");
        value.select();
        document.execCommand("copy");

        toast.success("کد کارتک شما کپی شد!");
    };

    const getUserPanel = () => {
        advertisePanel()
            .then((res) => {
                if (res) {
                    setUserPanelData(res.data);
                    setLoading(false);
                } else return;
            })
            .catch((err) => console.log(err));
    };

    const getUserScores = () => {
        collaborateScoreLogsAPI()
            .then((res) => {
                if (res) {
                    setUserScoreData(res.data);
                    setScoreLoading(false);
                } else return;
            })
            .catch((err) => console.log(err));
    };

    const getHint = () => {
        // getHintAPI('صفحات داخلی نمایندگی کارتک قوانین خرید پنل طلایی')
        //   .then(response => {
        //     setGoldHint(response);
        //   })
        //   .catch(() => setGoldHint({content: "دریافت راهنما با خطا مواجه شد", title: ""}))
        //   .finally(() => {
        //   });

        getHintAPI("قوانین و هشدارها 3")
            .then((response) => {
                setWhiteHint(response);
            })
            .catch(() =>
                setWhiteHint({
                    content: "دریافت راهنما با خطا مواجه شد",
                    title: "",
                })
            )
            .finally(() => {});
    };

    useEffect(() => {
        history.replace("/collaborate");
        getUserPanel();
        getUserScores();
        getHint();
    }, []);

    return (
        <Fade>
            <Spinner active={loading} color="dark" />
            <PageTopbar title="نمایندگی کارتک" showKv={false} />
            <Modal active={hintModal} close={() => setHintModal(false)}>
                <div className="marketing__rules">
                    <div className="marketing__rules__title">
                        {whiteHint.title}
                    </div>

                    <div
                        className="marketing__rules__description"
                        id="marketingRules"
                    >
                        {whiteHint.content?.split("\n")?.map((str) => (
                            <div>{str}</div>
                        ))}
                    </div>

                    <div className="marketing__rules__bottom">
                        <div
                            className={`marketing__rules__button marketing__rules__accept`}
                            onClick={() => setHintModal(false)}
                        >
                            متوجه شدم
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal active={goldHintModal} close={() => setGoldHintModal(false)}>
                <div className="marketing__rules">
                    <div className="marketing__rules__title">
                        {goldHint.title}
                    </div>

                    <div
                        className="marketing__rules__description"
                        id="marketingRules"
                    >
                        {goldHint.content?.split("\n")?.map((str) => (
                            <div>{str}</div>
                        ))}
                    </div>

                    <div className="marketing__rules__bottom">
                        <div
                            className={`marketing__rules__button marketing__rules__accept`}
                            onClick={() => setGoldHintModal(false)}
                        >
                            متوجه شدم
                        </div>
                    </div>
                </div>
            </Modal>
            <div className="marketing">
                {userPanelData.panel !== null ? (
                    <React.Fragment>
                        <Card>
                            {/*{userPanelData.panel === "gold" && <Slrl/>}*/}

                            {/* {userPanelData.panel === "gold" && (
                <Link
                  to="/collaborate/logs/score"
                  className="my-panel__item__score"
                >
                  <div className="row a-c">
                    <div className="my-panel__item__score__icon">
                      <SVG svg="score" />
                    </div>
                    <div>امتیاز کارتک</div>
                  </div>
                  <div className="row a-c ">
                    <div className="my-panel__item__score__count">
                      {userScoreData?.ThisMonth[0]?.score}
                    </div>
                    <SVG svg="arrow" />
                  </div>
                </Link>
              )} */}

                            <div
                                className={`panel__box ${userPanelData.panel}`}
                            >
                                <Share
                                    withBtnNav
                                    active={showSharing}
                                    setClose={(close) => setShowSharing(close)}
                                    title="ارسال کد پنل"
                                    value={`با کد پنل نمایندگی من اقدام به ثبت محتوا یا اخذ نمایندگی در کارتک نمایید
        \n کد پنل نمایندگی من : ${userPanelData.code}
        \n لینک ورود به کارتک :
        \n https://app.kartaak.com
        \n وبسایت کارتک : 
        `}
                                    link={`https://kartaak.com`}
                                />

                                <div className="panel__box__body">
                                    <div className="panel__box__info">
                                        <div className="col j-sb a-c mb-8">
                                            <div className="row a-c">
                                                <div className="panel__box__info__title">{`شماره ${handlePanelName(
                                                    userPanelData?.panel
                                                )}:`}</div>
                                                <input
                                                    readOnly
                                                    type="text"
                                                    value={
                                                        user.detail?.inviteCode
                                                    }
                                                    id="panel_code"
                                                    className="panel__box__input"
                                                />
                                            </div>

                                            <div className="panel__box__info__description">
                                                می توانید از شماره پنل یا شماره
                                                موبایل خود به عنوان کد معرف برای
                                                بازاریابی استفاده نمایید.
                                            </div>
                                        </div>
                                        <div className="col a-c g-6">
                                            <div
                                                className="panel__box__info__icon"
                                                onClick={() => copy()}
                                            >
                                                <SVG svg="copy" />
                                            </div>
                                            <div
                                                className="panel__box__info__icon shared"
                                                onClick={() =>
                                                    setShowSharing(true)
                                                }
                                            >
                                                <SVG svg="share" />
                                            </div>
                                        </div>
                                    </div>

                                    {/*<div className="panel__box__item">*/}
                                    {/*  <div className="row a-c">*/}
                                    {/*    <div className="panel__box__item__icon">*/}
                                    {/*      <SVG svg="kartella"/>*/}
                                    {/*    </div>*/}
                                    {/*    <div className="panel__box__item__title">*/}
                                    {/*      پاداش جذب محتوای کارتلا*/}
                                    {/*    </div>*/}
                                    {/*  </div>*/}
                                    {/*  <div className="panel__box__item__value">{userPanelData.collaborationReward + "%"}</div>*/}
                                    {/*</div>*/}

                                    {/*<div className="panel__box__item">*/}
                                    {/*  <div className="row a-c">*/}
                                    {/*    <div className="panel__box__item__icon">*/}
                                    {/*      <SVG svg="city"/>*/}
                                    {/*    </div>*/}
                                    {/*    <div className="panel__box__item__title">*/}
                                    {/*      پاداش فروش واحدهای کارتلند*/}
                                    {/*    </div>*/}
                                    {/*  </div>*/}
                                    {/*  <div className="panel__box__item__value">{userPanelData.collaborationReward + "%"}</div>*/}
                                    {/*</div>*/}
                                </div>
                            </div>

                            {panelTypes.map(
                                (panelType, index) =>
                                    panelType.type.includes(
                                        userPanelData.panel
                                    ) && (
                                        <Link
                                            to={panelType.path}
                                            key={index}
                                            className="my-panel__item"
                                        >
                                            <div className="row a-c">
                                                <div
                                                    className={`my-panel__item__icon ${panelType.icon}`}
                                                >
                                                    <SVG svg={panelType.icon} />
                                                </div>
                                                <div>{panelType.title}</div>
                                            </div>

                                            <div className="">
                                                {userPanelData[
                                                    panelType.percent
                                                ] + "%"}
                                            </div>

                                            <SVG svg="arrow" />
                                        </Link>
                                    )
                            )}

                            {userPanelData.panel === "gold" && (
                                <>
                                    <Link
                                        to={"/collaboration"}
                                        style={{ backgroundColor: "#00f3ff38" }}
                                        className="my-panel__item mt-20"
                                    >
                                        <div className="row a-c">
                                            <div
                                                className={`my-panel__item__icon friends`}
                                            >
                                                <SVG svg={"friends"} />
                                            </div>
                                            <div>تیم فروش من</div>
                                        </div>

                                        <SVG svg="arrow" />
                                    </Link>

                                    <Link
                                        to={"/collaborate/logs/my-team"}
                                        style={{ backgroundColor: "#00f3ff38" }}
                                        className="my-panel__item"
                                    >
                                        <div className="row a-c">
                                            <div
                                                className={`my-panel__item__icon friends`}
                                            >
                                                <SVG svg={"friends"} />
                                            </div>
                                            <div>پاداش فروش تیمی</div>
                                        </div>

                                        <SVG svg="arrow" />
                                    </Link>

                                    <Link
                                        to={"/collaborate/slrl"}
                                        style={{
                                            backgroundColor:
                                                "rgba(255,196,0,0.6)",
                                        }}
                                        className="my-panel__item mt-20"
                                    >
                                        <div className="row a-c">
                                            <div
                                                className={`my-panel__item__icon wallet-km`}
                                            >
                                                <SVG svg={"city"} />
                                            </div>
                                            <div>
                                                پاداش فروش پنل طلایی نمایندگی
                                                (SL - RL)
                                            </div>
                                        </div>

                                        <SVG svg="arrow" />
                                    </Link>
                                </>
                            )}

                            {userPanelData.panel === "white" && (
                                <div
                                    // style={{backgroundColor: '#00f3ff38'}}
                                    className="my-panel__item"
                                    onClick={() => setHintModal(true)}
                                >
                                    <div className="row a-c">
                                        <div
                                            className={`my-panel__item__icon friends`}
                                        >
                                            <SVG svg={"help"} />
                                        </div>
                                        <div>
                                            راهنما و شرایط نمایندگی در کارتک
                                        </div>
                                    </div>

                                    <SVG svg="arrow" />
                                </div>
                            )}

                            {userPanelData.panel === "gold" && (
                                <div
                                    // style={{backgroundColor: '#00f3ff38'}}
                                    className="my-panel__item"
                                    onClick={() => setGoldHintModal(true)}
                                >
                                    <div className="row a-c">
                                        <div
                                            className={`my-panel__item__icon friends`}
                                        >
                                            <SVG svg={"help"} />
                                        </div>
                                        <div>
                                            راهنما و شرایط نمایندگی طلایی در
                                            کارتک
                                        </div>
                                    </div>

                                    <SVG svg="arrow" />
                                </div>
                            )}

                            {/*<PanelBox data={userPanelData}/>*/}
                        </Card>
                        {userPanelData.panel === "white" && (
                            <Padding>
                                <div
                                    onClick={() => {
                                        // history.push("/buy-panel");
                                        toast.error(
                                            "درحال‌حاظر خرید پنل طلایی امکان‌پذیر نمی‌باشد"
                                        );
                                    }}
                                    className="my-panel__action"
                                >
                                    <div className="my-panel__action__title">
                                        <span>خرید پنل طلایی</span>
                                        <SVG svg="panel-not-found" />
                                    </div>
                                    <div className="my-panel__action__arrow">
                                        <SVG svg="arrow" />
                                    </div>
                                </div>
                            </Padding>
                        )}
                    </React.Fragment>
                ) : (
                    <NotFound
                        updateUserPanel={(update) => {
                            if (update) {
                                setLoading(true);
                                getUserPanel();
                            }
                        }}
                    />
                )}
            </div>
        </Fade>
    );
};
