import React from 'react'
import { CategoryProductContainer } from 'containers'

const CateogryProduct = () => {
    return (
        <CategoryProductContainer/> 
    )
}

export default CateogryProduct;
