import {Card, PageTopbar, SVG} from 'components';
import {Fade} from '../../components/Fade/Fade';
import '../../assets/styles/pages/drawPage.scss';
import {Spinner} from 'components';
import {useState, useEffect} from 'react';
import {toast} from 'react-hot-toast';
import {getDrawsAPI} from './../../utils/Services';
import {Link, useHistory} from 'react-router-dom';

export const DrawPage = () => {

  const [loading, setLoading] = useState(true);
  const [drawItems, setDrawItems] = useState([]);
  const [error, setError] = useState(false);

  const history = useHistory();

  const getDraws = async () => {
    try {
      const response = await getDrawsAPI();
      if (response.status === 200) {
        setDrawItems([...response.data.activeDraw, ...response.data.finishedDraw].sort((a, b) => a.id - b.id));
        setError(false);
      }
      setLoading(false);
    } catch (error) {
      toast.error("خطا در بارگزاری اطلاعات");
      setLoading(false);
      setError(true);
    }
  };

  useEffect(() => {
    getDraws();
  }, []);

  return (
    <Fade>
      <Spinner active={loading} color="dark"/>
      <PageTopbar title="قرعه کشی"/>
      <div className="wrapper-fix"></div>
      <Card>
        <div className="draws flex j-c">
          {
            error ?
              <div className="draws__error">
                <p>خطا در بارگزاری اطلاعات</p>
                <div onClick={() => {
                  history.go(0);
                }}><SVG svg="Reload"/></div>
              </div>
              : drawItems.length != 0 ?
                drawItems.map(item => (
                  <Link to={`new-lottery/${item.id}`}>
                    <div key={item.id}
                         className={`draws__draw ${item.status === null ? "draws__draw__finish-box" : ""} `}>
                      <img src={item.photo} className={item.status === null ? "draws__draw__finish" : ""}/>
                      <div className="draws__draw__text">
                        <p>{item.giftType}</p>
                        <p>{item.subject}</p>
                      </div>
                      {
                        item.status === null && <div className="draws__draw__status-1">انجام شد</div>
                      }
                    </div>
                  </Link>
                ))
                :
                <div className="draws__not-found">
                  <p>قرعه کشی یافت نشد.</p>
                </div>
          }
        </div>
      </Card>
    </Fade>
  );
};