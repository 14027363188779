import {
    Card,
    CheckBox,
    Dots,
    Fade,
    Lowprice,
    PageTopbar,
    Selectbox,
    SVG,
    Modal,
    VideoManager,
} from "components";
import { ImageEditor } from "components/ImageEditor";
import React, { createContext, Fragment, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useHistory, useLocation } from "react-router";
import ReactSlider from "react-slider";
import { adsPriceAPI, GetCategories, getHintAPI } from "utils";
import {
    farsiDigitToEnglish,
    transformPrice,
    urlValidation,
} from "utils/Functions";
import { checkPanelCodeAPI, createAdsAPI } from "utils/Services/API";
import { FillCustom } from "./FillCustom/FillCustom";
import imageCompression from "browser-image-compression";
import { useUser } from "hooks";
import { Hint } from "components/Hint";

const stars = ["0", "1", "2", "3", "4", "5", "6", "7"];

const customItems = [
    {
        title: "محل سکونت",
        type: "city",
    },
    {
        title: "جنسیت",
        type: "gender",
    },
    {
        title: "سن",
        type: "age",
    },
    {
        title: "تحصیل",
        type: "education",
    },
    {
        title: "شغل",
        type: "employment",
    },
    {
        title: "تاهل",
        type: "marital",
    },
];

export const CreateAdsContext = createContext({});

export const CreateAdsContainer = () => {
    const {
        userState: { user },
    } = useUser();
    const user_token = localStorage.getItem("user_token");
    const history = useHistory();
    const location = useLocation();
    const [adsDetail, setAdsDetail] = useState({
        title: "",
        description: "",
        phoneNumber: "",
        mobileNumber: "",
        address: "",
        category: 0,
        categoryValue: "",
        seenCount: 1000,
        star: 1,
        panelCode: "",
        url: "",
    });
    const [panelCodeLoading, setPanelCodeLoading] = useState(false);
    const [panelCodeValue, setPanelCodeValue] = useState(0);
    const [panelCodeStatus, setPanelCodeStatus] = useState<any>({});
    const [submitedPanelCode, setSubmitedPanelCode] = useState(false);
    const [adsImage, setAdsImage] = useState({ file: null, url: null });
    const [categoriesData, setCategoriesData] = useState([]);
    const [showFinal, setShowFinal] = useState(false);
    const [showImageEditor, setShowImageEditor] = useState(false);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [showGeneral, setShowGeneral] = useState(false);
    const [adsPrice, setAdsPrice] = useState([0, 0, 0, 0, 0, 0, 0]);
    const [ruleModal, setRuleModal] = useState(false);
    const [slidingHintText, setSlidingHintText] = useState("");
    const [customize, setCustomize] = useState({
        city: {
            active: false,
        },
        gender: {
            active: false,
        },
        age: {
            active: false,
        },
        education: {
            active: false,
        },
        employment: {
            active: false,
        },
        marital: {
            active: false,
        },
    });
    const [customizeDetail, setCustomizeDetail] = useState({
        address: {
            countryId: 1,
            countryValue: "تعیین نشده",
            stateId: 0,
            stateValue: "",
            cityId: 0,
            cityValue: "",
            districtId: 0,
            districtValue: "",
        },
        gender: {
            gender: 0,
            genderValue: "تعیین نشده",
        },
        age: {
            from: 12,
            to: 99,
        },
        education: {
            educationStatus: 0,
            educationStatusValue: "تعیین نشده",
            educationDegree: 0,
            educationDegreeValue: "",
            studyId: 0,
            studyField: "",
        },
        employment: {
            employment: 0,
            employmentValue: "تعیین نشده",
            job: 0,
            jobValue: "",
            guild: 0,
            freelanceJob: 0,
            emeritus: 0,
            readyToWork: 0,
            skill: 0,
        },
        marital: {
            marital: 0,
            maritalValue: "تعیین نشده",
            maritalChildren: 0,
            maritalChildrenCount: null,
        },
    });
    const [showCustomize, setShowCustomize] = useState({
        type: "",
        show: false,
    });
    const [compressLoading, setCompressLoading] = useState(0);
    const [showHintModal, setShowHintModal] = useState({
        custom: false,
        seen: false,
    });
    const [hint1, setHint1] = useState<any>("");
    const [hint2, setHint2] = useState<any>("");

    const [updateLoading, setUpdateLoading] = useState(false);
    const [flag, setFlag] = useState(false);
    const [video, setVideo] = useState("");
    const [videoId, setVideoId] = useState("");
    const [errorOccurred, setErrorOccurred] = useState(false);
    const [isVideoUploaded, setIsVideoUploaded] = useState(false);

    const compressOptions = {
        maxSizeMB: 0.6,
        maxWidthOrHeight: 1024,
        onProgress: (percentage) => {
            setCompressLoading(percentage);
        },
    };

    const handleIncDecSeenCount = (type) => {
        if (type === "inc") {
            setAdsDetail((prevState) => ({
                ...prevState,
                seenCount: adsDetail.seenCount + 500,
            }));
        } else if (type === "dec") {
            if (adsDetail.seenCount <= 1499) {
                toast.error("حداقل تعداد بازدید، 1000 نفر می‌باشد.");
                return;
            } else {
                setAdsDetail((prevState) => ({
                    ...prevState,
                    seenCount: adsDetail.seenCount - 500,
                }));
            }
        }
    };

    const handleActivateSubmit = () => {
        if (adsDetail.title === "" || adsDetail.category === 0) {
            return false;
        } else {
            return true;
        }
    };

    const handleSubmit = () => {
        if (adsDetail.category === 0) {
            toast.error("یک دسته‌بندی برای محتوای خود وارد نمایید.");
        } else if (adsDetail.title === "") {
            toast.error("یک عنوان برای محتوای خود وارد نمایید.");
        } else if (
            !urlValidation(adsDetail.url) &&
            adsDetail.url.length !== 0
        ) {
            toast.error("لینک وارد شده معتبر نمی‌باشد.");
        } else if (adsDetail.star === 7) {
            if (customizeDetail.education.educationStatus === -1) {
                setCustomizeDetail((prev) => ({
                    ...prev,
                    education: {
                        educationStatus: 0,
                        educationStatusValue: "",
                        educationDegree: 0,
                        educationDegreeValue: "",
                        studyId: 0,
                        studyField: "",
                    },
                }));
            }
            if (customizeDetail.employment.employment === -1) {
                setCustomizeDetail((prev) => ({
                    ...prev,
                    employment: {
                        employment: 0,
                        employmentValue: "",
                        job: 0,
                        jobValue: "",
                        guild: 0,
                        freelanceJob: 0,
                        emeritus: 0,
                        readyToWork: 0,
                        skill: 0,
                    },
                }));
            }
            if (customizeDetail.marital.marital === -1) {
                setCustomizeDetail((prev) => ({
                    ...prev,
                    marital: {
                        marital: -1,
                        maritalValue: "",
                        maritalChildren: 0,
                        maritalChildrenCount: null,
                    },
                }));
            }
            setShowFinal(true);
            history.push("/createads?final");
        }
        // else if (
        //   (handleShowStar(5) && customizeDetail.education.educationStatus === -1)
        //   || (handleShowStar(6) && customizeDetail.employment.employment === -1)
        //   || (handleShowStar(7) && customizeDetail.marital.marital === -1)
        // ) {
        //   toast.error("شخصی‌سازی های موجود را تکمیل نمایید.");
        //   return;
        // }

        // else if (
        //   handleShowStar(6) &&
        //   customizeDetail.employment.employment === -1
        // ) {
        //   toast.error("شخصی سازی اشتغال بازدیدکننده را مشخص نمایید.");
        //   return;
        // } else if (handleShowStar(7) && customizeDetail.marital.marital === -1) {
        //   toast.error("شخصی سازی تاهل بازدیدکننده را مشخص نمایید.");
        //   return;
        // }
        else {
            setShowFinal(true);
            history.push("/createads?final");
        }
    };

    const handleChangeImage = (e) => {
        let reader = new FileReader();
        let file = e.target.files[0];

        reader.onloadend = () => {
            setAdsImage({
                file: file,
                url: reader.result,
            });
        };
        reader.readAsDataURL(file);
    };

    const handlePayPanel = () => {
        setSubmitLoading(true);
        if (adsImage.file == null && video == "") {
            toast.error("تصویر یا ویدیو محتوا را انتخاب کنید.");
            setFlag(false);
            setSubmitLoading(false);
            return;
            // }
            // if (adsImage.file === null) {
            //   toast.error("تصویر محتوا را وارد نمایید.");
            //   setSubmitLoading(false);
            //   return;
        } else if (adsDetail.description.length === 0) {
            toast.error("توضیحات محتوا را وارد نمایید.");
            setSubmitLoading(false);
            return;
        } else if (adsDetail.description.length < 10) {
            toast.error("حداقل تعداد حروف توضیحات می‌تواند ۱۰ کاراکتر باشد.");
            setSubmitLoading(false);
            return;
        } else if (adsDetail.description.length > 550) {
            toast.error("حداکثر تعداد حروف توضیحات می‌تواند 550 کاراکتر باشد.");
            setSubmitLoading(false);
            return;
        }

        if (adsImage.file) {
            createContent();
        }

        setFlag(true);
        setSubmitLoading(true);
    };

    const createContent = () => {
        var formData = new FormData();

        formData.append("title", adsDetail.title);
        formData.append("video_url", videoId);
        formData.append("address", adsDetail.address);
        formData.append("phoneNumber", adsDetail.phoneNumber);
        formData.append("mobileNumber", adsDetail.mobileNumber);
        formData.append("description", adsDetail.description);
        formData.append("catId", String(adsDetail.category));
        formData.append("rate", String(adsDetail.star));
        formData.append(
            "panelCode",
            !user.detail?.hasAdsBuyCode
                ? String(adsDetail.panelCode)
                : user.detail?.hasAdsBuyCode
        );
        formData.append("seen", String(adsDetail.seenCount / 500));
        formData.append("type", "image");
        formData.append("url", adsDetail.url);
        if (adsDetail.star === 1) {
            formData.append("specialVisit", "0");
        } else {
            formData.append("specialVisit", showGeneral ? "0" : "1");
        }

        if (adsDetail.star >= 2) {
            customizeDetail.address.countryId !== 1 &&
                formData.append(
                    "country",
                    String(customizeDetail.address.countryId)
                );
            customizeDetail.address.stateId !== 0 &&
                formData.append(
                    "state",
                    String(customizeDetail.address.stateId)
                );
            customizeDetail.address.cityId !== 0 &&
                formData.append("city", String(customizeDetail.address.cityId));
        }

        if (adsDetail.star >= 3) {
            customizeDetail.gender.gender !== 0 &&
                formData.append(
                    "gender",
                    String(customizeDetail.gender.gender)
                );
        }

        // if (adsDetail.star >= 4) {
        formData.append("FromAge", String(customizeDetail.age.from));
        formData.append("ToAge", String(customizeDetail.age.to));
        // }

        if (adsDetail.star >= 5) {
            if (handleShowStar(5)) {
                // customizeDetail.education.educationStatus !== 0 &&
                formData.append(
                    "EduStatus",
                    String(customizeDetail.education.educationStatus)
                );
                customizeDetail.education.educationDegree !== 0 &&
                    formData.append(
                        "EduDegree",
                        String(customizeDetail.education.educationDegree)
                    );
                customizeDetail.education.studyId !== 0 &&
                    formData.append(
                        "EduField",
                        String(customizeDetail.education.studyId)
                    );
            }

            if (handleShowStar(6)) {
                // customizeDetail.employment.employment !== 0 &&
                formData.append(
                    "employment",
                    String(customizeDetail.employment.employment)
                );
                customizeDetail.employment.job !== 0 &&
                    formData.append(
                        "job",
                        String(customizeDetail.employment.job)
                    );
                customizeDetail.employment.guild !== 0 &&
                    formData.append(
                        "guilds",
                        String(customizeDetail.employment.guild)
                    );
                customizeDetail.employment.freelanceJob !== 0 &&
                    formData.append(
                        "Freelancejob",
                        String(customizeDetail.employment.freelanceJob)
                    );
                customizeDetail.employment.emeritus !== 0 &&
                    formData.append(
                        "emeritus",
                        String(customizeDetail.employment.emeritus)
                    );
                customizeDetail.employment.readyToWork !== 0 &&
                    formData.append(
                        "readyToWork",
                        String(customizeDetail.employment.readyToWork)
                    );
                customizeDetail.employment.skill !== 0 &&
                    formData.append(
                        "skill",
                        String(customizeDetail.employment.skill)
                    );
            }

            if (handleShowStar(7)) {
                // customizeDetail.marital.marital !== 0 &&
                formData.append(
                    "marital",
                    String(customizeDetail.marital.marital)
                );
                customizeDetail.marital.maritalChildrenCount !== null &&
                    formData.append(
                        "children",
                        String(customizeDetail.marital.maritalChildrenCount)
                    );
            }
        }

        if (adsImage.file) {
            const compressedFile = imageCompression(
                adsImage.file,
                compressOptions
            );
            compressedFile.then((compressedImage) => {
                formData.append("image", compressedImage);
                const api = createAdsAPI(user_token, formData);
                try {
                    api.then((response) => {
                        if (response.status === 200) {
                            history.push(
                                `/pay?${process.env.REACT_APP_DEV_IP}api/v2/advertise/${response.data.data.payID}/payment/${response.data.data.tk}`
                            );
                            setTimeout(() => {
                                if (history.location.pathname.includes("/pay"))
                                    window.location.href = `${process.env.REACT_APP_DEV_IP}api/v2/advertise/${response.data.data.payID}/payment/${response.data.data.tk}`;
                            }, 600);
                        } else if (response.status === 400) {
                            toast.error("همه‌ی مقادیر به درستی وارد نشده‌اند.");
                        } else {
                            toast.error("مشکلی در پرداخت پیش آمده.");
                        }
                        setSubmitLoading(false);
                    });
                } catch (error) {
                    setSubmitLoading(false);
                }
            });
        } else {
            console.log(videoId);
            const api = createAdsAPI(user_token, formData);
            try {
                api.then((response) => {
                    if (response.status === 200) {
                        history.push(
                            `/pay?${process.env.REACT_APP_DEV_IP}api/v2/advertise/${response.data.data.payID}/payment/${response.data.data.tk}`
                        );
                        setTimeout(() => {
                            if (history.location.pathname.includes("/pay"))
                                window.location.href = `${process.env.REACT_APP_DEV_IP}api/v2/advertise/${response.data.data.payID}/payment/${response.data.data.tk}`;
                        }, 600);
                    } else if (response.status === 400) {
                        toast.error("همه‌ی مقادیر به درستی وارد نشده‌اند.");
                    } else {
                        toast.error("مشکلی در پرداخت پیش آمده.");
                    }
                    setSubmitLoading(false);
                });
            } catch (error) {
                setSubmitLoading(false);
            }
        }
    };

    useEffect(() => {
        if (flag) {
            setFlag(false);
            handlePayPanel();
        }
    }, [videoId]);

    useEffect(() => {
        if (isVideoUploaded) {
            createContent();
        }
    }, [isVideoUploaded]);

    const handleShowStar = (index) => {
        if (index <= adsDetail.star) {
            if (index <= 4) return true;
        }

        if (adsDetail.star > 4) {
            if (adsDetail.star === 5) {
                if (index === 5) {
                    if (customize.employment.active || customize.marital.active)
                        return false;
                    else return true;
                }
                if (index === 6) {
                    if (customize.education.active || customize.marital.active)
                        return false;
                    else return true;
                }
                if (index === 7) {
                    if (
                        customize.education.active ||
                        customize.employment.active
                    )
                        return false;
                    else return true;
                }
            }

            if (adsDetail.star === 6) {
                if (index === 5) {
                    if (customize.employment.active && customize.marital.active)
                        return false;
                    else return true;
                }
                if (index === 6) {
                    if (customize.education.active && customize.marital.active)
                        return false;
                    else return true;
                }
                if (index === 7) {
                    if (
                        customize.education.active &&
                        customize.employment.active
                    )
                        return false;
                    else return true;
                }
            }

            if (adsDetail.star === 7) {
                return true;
            }
        }
    };

    const handleSetFillCustom = (activeFill) => {
        switch (activeFill) {
            case "city":
                return "city";

            case "gender":
                return "gender";

            case "age":
                return "age";

            case "education":
                return "education";

            case "employment":
                return "employment";

            case "marital":
                return "marital";

            default:
                return "";
        }
    };

    const handlePresenterCode = () => {
        if (adsDetail.panelCode.length === 0) {
            toast.error("کد پنل نمایندگی را وارد نمایید.");
            return;
        }

        setPanelCodeLoading(true);
        const api = checkPanelCodeAPI(user_token, adsDetail.panelCode);
        api.then((response) => {
            if (response.status === 200) {
                if (!response.data.data.Credit) {
                    toast.error("کد وارد شده منقضی شده است.");
                    setPanelCodeLoading(false);
                    return;
                } else {
                    toast.success("کد نمایندگی با موفقیت اعمال شد.");
                    setPanelCodeValue(response.data.data.value);
                    setPanelCodeStatus(response.data.data);
                    setPanelCodeLoading(false);
                    setSubmitedPanelCode(true);
                    return;
                }
            } else if (response.status === 404) {
                toast.error("پنلی با این کد پیدا نشد.");
                setPanelCodeLoading(false);
                return;
            }
            setPanelCodeLoading(false);
        });
    };

    const handleShowSpecific = () => {
        if (
            customizeDetail.address.countryValue === "تعیین نشده" &&
            customizeDetail.gender.gender === 0 &&
            customizeDetail.age.from === 12 &&
            customizeDetail.age.to === 99 &&
            customizeDetail.education.educationStatus === 0 &&
            customizeDetail.employment.employment === 0 &&
            customizeDetail.marital.marital === 0
        ) {
            return false;
        } else {
            return true;
        }
    };

    const handleDescriptionChars = () => {
        const descriptionChars = adsDetail.description.length;
        if (
            (descriptionChars < 10 && descriptionChars !== 0) ||
            descriptionChars > 550
        ) {
            return "error";
        } else if (descriptionChars !== 0) {
            return "success";
        }
    };

    // const getHint = () => {
    //     getHintAPI("صفحات داخلی کارتلا 1")
    //         .then((response) => {
    //             setHint1(response);
    //         })
    //         .catch(() => setHint1(false))
    //         .finally(() => {});

    //     getHintAPI("صفحات داخلی کارتلا 2")
    //         .then((response) => {
    //             setHint2(response);
    //         })
    //         .catch(() => setHint2(false))
    //         .finally(() => {});
    // };

    const getPagePrice = (pagePrices) => {
        let prices = [];
        for (let i = 1; i < 8; i++) {
            pagePrices.forEach((element) => {
                if (element.name.charAt(9) === String(i)) {
                    prices.push(element.price);
                }
            });
        }
        console.log(prices);

        setAdsPrice(prices);
    };

    useEffect(() => {
        if (history.action === "POP") {
            setShowFinal(false);
            history.replace("/createads");
        }
    }, [location.search]);

    useEffect(() => {
        history.replace("/createads");
        GetCategories().then((res) => {
            if (res) {
                res.data.forEach((category) => {
                    category["name"] = category["title"];
                });
                if (res.data[0]?.name) setCategoriesData(res.data);
            }
        });

        adsPriceAPI().then((response) => {
            if (response.status === 200) {
                setPanelCodeValue(0);
                getPagePrice(response.data.price);
            }
        });
    }, []);

    // useEffect(() => {
    //     getHintAPI("راهنمای کلی 4")
    //         .then((response) => {
    //             setSlidingHintText(response.content);
    //         })
    //         .catch(() => toast.error("درافت راهنما با خطا مواجه شد"));
    // }, []);

    return (
        <React.StrictMode>
            <Fade>
                <PageTopbar
                    title="ایجاد محتوا"
                    showKv={false}
                    circleHint={slidingHintText}
                />
                <ImageEditor
                    active={showImageEditor}
                    onClose={(close) => setShowImageEditor(!close)}
                    onConfirm={(cropper) => {
                        setShowImageEditor(false);
                        setAdsImage((prev) => ({
                            ...prev,
                            url: cropper
                                .getCroppedCanvas()
                                .toDataURL()
                                .substr(22),
                        }));
                        cropper.getCroppedCanvas().toBlob((blob) => {
                            setAdsImage((prev) => ({
                                ...prev,
                                file: blob,
                            }));
                        });
                    }}
                />

                <Modal
                    active={showHintModal.custom}
                    close={() => {
                        setShowHintModal({ ...showHintModal, custom: false });
                    }}
                    hint="getKv"
                >
                    <div className="modal-hint__description">
                        {hint1.content?.split("\n")?.map((str) => (
                            <div>{str}</div>
                        ))}
                    </div>
                </Modal>

                <Modal
                    active={showHintModal.seen}
                    close={() => {
                        setShowHintModal({ ...showHintModal, seen: false });
                    }}
                    hint="getKv"
                >
                    <div className="modal-hint__description">
                        {hint2.content?.split("\n")?.map((str) => (
                            <span>{str}</span>
                        ))}
                    </div>
                </Modal>

                <CreateAdsContext.Provider
                    value={{
                        detail: {
                            get: customizeDetail,
                            set: setCustomizeDetail,
                        },
                        active: setCustomize,
                    }}
                >
                    <FillCustom
                        type={handleSetFillCustom(showCustomize.type)}
                        active={showCustomize.show}
                        closeFillCustom={(close) => {
                            setShowCustomize({ type: "", show: false });
                        }}
                    />
                </CreateAdsContext.Provider>

                {/* <div className={`create-ads__stars ${showCustomize.show && 'active'}`}>
          {handleShowCustomStars()}
        </div> */}

                <div className={`create-ads__final ${showFinal && "active"}`}>
                    <div className="create-ads__final__header">
                        <div
                            className="create-ads__final__header__back"
                            onClick={() => {
                                setShowFinal(false);
                                history.goBack();
                            }}
                        >
                            <SVG svg="arrow-big" />
                        </div>
                        محتوا جدید
                    </div>
                    <Card>
                        {!adsImage.file && (
                            <VideoManager
                                videoSetter={setVideo}
                                uploadVideoFunction={flag}
                                videoIdSetter={setVideoId}
                                errorOccurred={setErrorOccurred}
                                flagSetter={setFlag}
                                finishUpload={setIsVideoUploaded}
                            />
                        )}
                        {!video && (
                            <div
                                className="create-ads__final__image"
                                onClick={() => setShowImageEditor(true)}
                            >
                                <canvas
                                    className={`create-ads__canvas ${
                                        adsImage.url !== "new" && "deactive"
                                    }`}
                                    id="canvas-image"
                                />

                                {adsImage.url === null ? (
                                    <SVG svg="ads-image" />
                                ) : adsImage.file === undefined ? (
                                    // adsImage.url !== ""
                                    // ? (
                                    //   <img src={`data:image/png;base64,${adsImage.url}`} alt="" />
                                    // ) : (
                                    <SVG svg="ads-image" />
                                ) : (
                                    // )
                                    <img
                                        src={`data:image/png;base64,${adsImage.url}`}
                                        alt=""
                                    />
                                )}

                                {adsImage.url === null && (
                                    <div className="create-ads__final__image__title">
                                        بارگذاری تصویر
                                    </div>
                                )}
                            </div>
                        )}

                        <textarea
                            className="textarea"
                            placeholder="توضیحات محتوا"
                            onChange={(e) =>
                                setAdsDetail((prevState) => ({
                                    ...prevState,
                                    description: e.target.value,
                                }))
                            }
                            value={adsDetail.description}
                            rows={3}
                            style={{ marginBottom: 4 }}
                        ></textarea>

                        <div className="create-ads__final__char">
                            <div className="create-ads__final__char__title">
                                حداقل حروف توضیحات ۱۰ و حداکثر 550 کاراکتر
                                می‌باشد.
                            </div>
                            <div className="row a-c">
                                550 /{" "}
                                <span className={handleDescriptionChars()}>
                                    {adsDetail.description.length}
                                </span>
                            </div>
                        </div>

                        {/* <div className="title">کد پنل معرف</div> */}

                        <div className="create-ads__final__preview">
                            <div className="create-ads__final__preview__title">
                                جزئیات محتوا
                            </div>

                            <div className="create-ads__final__preview__box">
                                {/* <div className="title">جزئیات</div> */}

                                <div className="create-ads__final__preview__info">
                                    <div className="create-ads__final__preview__info__item">
                                        <SVG svg="ads-category" />
                                        {"دسته‌بندی: " +
                                            adsDetail.categoryValue}
                                    </div>
                                    <div className="create-ads__final__preview__info__item">
                                        <SVG svg="ads-star" />
                                        {"صفحه: " + adsDetail.star + " "}
                                    </div>
                                    {/* {handleShowSpecific() && adsDetail.star !== 1 && (
                    <div className="create-ads__final__preview__info__item">
                      <SVG svg="ads-seen" />
                      بازدید اختصاصی:
                      {" " + adsDetail.seenCount + " "}
                    </div>
                    )}
                    {!handleShowSpecific() && (
                      <div className="create-ads__final__preview__info__item">
                      <SVG svg="ads-seen" />
                      بازدید عمومی:
                      {" " + adsDetail.seenCount + " "}
                      </div>
                    )} */}
                                    {adsDetail.star === 1 ? (
                                        <div className="create-ads__final__preview__info__item">
                                            <SVG svg="ads-seen" />
                                            بازدید عمومی:
                                            {" " + adsDetail.seenCount + " "}
                                        </div>
                                    ) : (
                                        <div className="create-ads__final__preview__info__item">
                                            <SVG svg="ads-seen" />
                                            بازدید اختصاصی:
                                            {" " + adsDetail.seenCount + " "}
                                        </div>
                                    )}
                                    {showGeneral && (
                                        <div className="create-ads__final__preview__info__item">
                                            <SVG svg="ads-seen" />
                                            بازدید عمومی:
                                            {" " + adsDetail.seenCount + " "}
                                        </div>
                                    )}
                                </div>

                                {/* {adsDetail.star > 1 &&  */}
                                <div className="title">هدف‌گیری</div>

                                {/* START CUSTOM CITIES */}
                                <div className="create-ads__final__preview__custom">
                                    <SVG svg={`custom-city`} />
                                    محل سکونت:
                                    {handleShowStar(2) &&
                                    customizeDetail.address.countryId !== -1 ? (
                                        <React.Fragment>
                                            {" " +
                                                customizeDetail.address
                                                    .countryValue}
                                            {customizeDetail.address.stateId !==
                                                0 &&
                                                "، " +
                                                    customizeDetail.address
                                                        .stateValue}
                                            {customizeDetail.address.cityId !==
                                                0 &&
                                                "، " +
                                                    customizeDetail.address
                                                        .cityValue}
                                        </React.Fragment>
                                    ) : (
                                        " -"
                                    )}
                                </div>
                                {/* END CUSTOM CITIES */}

                                {/* START CUSTOM GENDER */}
                                <div className="create-ads__final__preview__custom">
                                    <SVG svg={`custom-gender`} />
                                    جنسیت:
                                    {handleShowStar(3) ? (
                                        <React.Fragment>
                                            {" " +
                                                customizeDetail.gender
                                                    .genderValue}
                                        </React.Fragment>
                                    ) : (
                                        " -"
                                    )}
                                </div>
                                {/* END CUSTOM GENDER */}

                                {/* START CUSTOM AGE */}
                                <div className="create-ads__final__preview__custom">
                                    <SVG svg={`custom-age`} />
                                    سن:
                                    {handleShowStar(4) ? (
                                        <React.Fragment>
                                            {customizeDetail.age.from === 12 &&
                                            customizeDetail.age.to === 99 ? (
                                                <React.Fragment>
                                                    تعیین نشده
                                                </React.Fragment>
                                            ) : (
                                                <React.Fragment>
                                                    {" " +
                                                        customizeDetail.age
                                                            .from +
                                                        " "}
                                                    تا
                                                    {" " +
                                                        customizeDetail.age.to +
                                                        " سال"}
                                                </React.Fragment>
                                            )}
                                        </React.Fragment>
                                    ) : (
                                        " -"
                                    )}
                                </div>
                                {/* END CUSTOM AGE */}

                                {/* START CUSTOM EDUCATION */}
                                <div className="create-ads__final__preview__custom">
                                    <SVG svg={`custom-education`} />
                                    تحصیلات:
                                    {handleShowStar(5) ? (
                                        <React.Fragment>
                                            {customizeDetail.education
                                                .educationStatus !== -1 &&
                                                " " +
                                                    customizeDetail.education
                                                        .educationStatusValue}
                                            {customizeDetail.education
                                                .educationDegree !== 0 &&
                                                "، " +
                                                    customizeDetail.education
                                                        .educationDegreeValue}
                                            {customizeDetail.education
                                                .studyField.length !== 0 &&
                                                "، " +
                                                    customizeDetail.education
                                                        .studyField}
                                        </React.Fragment>
                                    ) : (
                                        " -"
                                    )}
                                </div>
                                {/* END CUSTOM EDUCATION */}

                                {/* START CUSTOM EMPLOYMENT */}
                                <div className="create-ads__final__preview__custom">
                                    <SVG svg={`custom-employment`} />
                                    اشتغال:
                                    {handleShowStar(6) ? (
                                        // customizeDetail.employment.employment !== 0 && (
                                        <React.Fragment>
                                            {" " +
                                                customizeDetail.employment
                                                    .employmentValue +
                                                ", " +
                                                customizeDetail.employment
                                                    .jobValue}
                                        </React.Fragment>
                                    ) : (
                                        // )
                                        " -"
                                    )}
                                </div>
                                {/* END CUSTOM EMPLOYMENT */}

                                {/* START CUSTOM MARITAL */}
                                <div className="create-ads__final__preview__custom">
                                    <SVG svg={`custom-marital`} />
                                    تاهل:
                                    {handleShowStar(7) &&
                                    customizeDetail.marital.marital !== -1 ? (
                                        <React.Fragment>
                                            {" " +
                                                customizeDetail.marital
                                                    .maritalValue}
                                            {customizeDetail.marital
                                                .maritalChildrenCount !==
                                                null &&
                                                " " +
                                                    customizeDetail.marital
                                                        .maritalChildrenCount +
                                                    " فرزنده"}
                                        </React.Fragment>
                                    ) : (
                                        " -"
                                    )}
                                </div>
                                {/* END CUSTOM MARITAL */}
                            </div>
                        </div>
                    </Card>

                    {/* {!user.detail?.hasAdsBuyCode && (
                        <div className="create-ads__final__code__body">
                            <span className="create-ads__final__code__bio">
                                اگر کد پنل طلایی یکی از نمایندگان کارتک را وارد
                                نمایید
                                <span style={{ color: "red" }}>
                                    &nbsp;10% تخفیف&nbsp;
                                </span>
                                می‌گیرید.
                            </span>
                            <div className={`create-ads__final__code`}>
                                <div
                                    className={`create-ads__final__code__status ${
                                        submitedPanelCode && "active"
                                    }`}
                                >
                                    <div
                                        className={`create-ads__final__code__status__circle ${panelCodeStatus.panelType}`}
                                    />
                                    <div>
                                        نوع پنل ثبت شده:
                                        {panelCodeStatus.panelType === "gold" &&
                                            " طلایی"}
                                        {panelCodeStatus.panelType ===
                                            "white" && " سفید"}
                                    </div>
                                </div>
                                <input
                                    type="text"
                                    value={adsDetail.panelCode}
                                    onChange={(e) => {
                                        if (submitedPanelCode) {
                                            setPanelCodeStatus({});
                                            setSubmitedPanelCode(false);
                                            setPanelCodeValue(0);
                                        }
                                        setAdsDetail((prev) => ({
                                            ...prev,
                                            panelCode: farsiDigitToEnglish(
                                                e.target.value
                                            ),
                                        }));
                                    }}
                                    placeholder="کد پنل یا شماره موبایل نمایندگان کارتک (اختیاری)"
                                />
                                <div
                                    className={`create-ads__final__code__btn ${
                                        submitedPanelCode && "active"
                                    }`}
                                    onClick={() => {
                                        if (submitedPanelCode) {
                                            setPanelCodeStatus({});
                                            setSubmitedPanelCode(false);
                                            setPanelCodeValue(0);
                                            setAdsDetail((prev) => ({
                                                ...prev,
                                                panelCode: "",
                                            }));
                                        } else {
                                            handlePresenterCode();
                                        }
                                    }}
                                >
                                    {panelCodeLoading ? (
                                        <Dots color="light" />
                                    ) : submitedPanelCode ? (
                                        "تغییر کد"
                                    ) : (
                                        "ثبت"
                                    )}
                                </div>
                            </div>
                        </div>
                    )} */}

                    <div className="create-ads__final__bottom">
                        <div className="create-ads__final__bottom__price">
                            <div>قیمت:</div>
                            <div className="col a-fe">
                                {submitedPanelCode ? (
                                    Number(panelCodeValue) !== 0 ? (
                                        <React.Fragment>
                                            <div className="create-ads__final__bottom__price__low">
                                                <Lowprice />
                                                {transformPrice(
                                                    adsDetail.seenCount *
                                                        adsPrice[
                                                            adsDetail.star - 1
                                                        ]
                                                ) + " تومان"}
                                            </div>
                                            {transformPrice(
                                                adsDetail.seenCount *
                                                    adsPrice[
                                                        adsDetail.star - 1
                                                    ] *
                                                    ((100 -
                                                        Number(
                                                            panelCodeValue
                                                        )) /
                                                        100)
                                            ) + " "}
                                        </React.Fragment>
                                    ) : (
                                        <React.Fragment>
                                            {transformPrice(
                                                adsDetail.seenCount *
                                                    adsPrice[adsDetail.star - 1]
                                            ) + " "}
                                        </React.Fragment>
                                    )
                                ) : (
                                    <React.Fragment>
                                        {transformPrice(
                                            adsDetail.seenCount *
                                                adsPrice[adsDetail.star - 1]
                                        ) + " "}
                                    </React.Fragment>
                                )}
                                تومان
                            </div>
                        </div>
                        <div className="create-ads__final__bottom__rule">
                            با{" "}
                            <b onClick={() => setRuleModal(true)}>
                                شرایط و قوانین
                            </b>{" "}
                            ثبت محتوا موافق هستم.
                        </div>
                        <div
                            className="create-ads__final__bottom__btn"
                            onClick={() => {
                                if (
                                    adsDetail.title != "" &&
                                    adsDetail.description != "" &&
                                    adsDetail.category != 0 &&
                                    adsImage.file != null &&
                                    adsDetail.description.length > 550 &&
                                    adsDetail.description.length < 10
                                ) {
                                    createContent();
                                } else {
                                    !submitLoading && handlePayPanel();
                                }
                            }}
                        >
                            {/* {compressLoading !== 0 ? (
                <div>در حال پردازش تصویر ({compressLoading}%)</div>
              ) :  */}

                            {submitLoading ? (
                                <Dots color="light" />
                            ) : (
                                "ثبت محتوا و پرداخت"
                            )}
                        </div>
                    </div>
                </div>

                <div className="create-ads">
                    <Card>
                        <div className="title">دسته‌بندی</div>

                        <Selectbox
                            data={categoriesData}
                            placeholder="انتخاب دسته‌بندی محتوا"
                            onChange={(catId) =>
                                setAdsDetail((prev) => ({
                                    ...prev,
                                    category: catId,
                                }))
                            }
                            onChangeString={(value) =>
                                setAdsDetail((prev) => ({
                                    ...prev,
                                    categoryValue: value,
                                }))
                            }
                            // onClickBox={() => window.scrollTo(0, 120)}
                        />

                        <div className="title">عنوان محتوا</div>

                        <input
                            className="input"
                            type="text"
                            placeholder="تیتر بالای صفحه را بنویسید"
                            value={adsDetail.title}
                            onChange={(e) =>
                                setAdsDetail((prev) => ({
                                    ...prev,
                                    title: e.target.value,
                                }))
                            }
                        />

                        <div className="title">آدرس</div>
                        <textarea
                            className="textarea"
                            placeholder="اختیاری"
                            value={adsDetail.address}
                            rows={3}
                            onChange={(e) => {
                                setAdsDetail((prev) => ({
                                    ...prev,
                                    address: e.target.value,
                                }));
                            }}
                        ></textarea>

                        <div className="title">تلفن</div>
                        <input
                            className="input"
                            type="tel"
                            placeholder="اختیاری"
                            value={adsDetail.phoneNumber}
                            onChange={(e) => {
                                setAdsDetail((prev) => ({
                                    ...prev,
                                    phoneNumber: farsiDigitToEnglish(
                                        e.target.value
                                    ),
                                }));
                            }}
                        />

                        <div className="title">موبایل</div>
                        <input
                            className="input"
                            type="tel"
                            placeholder="اختیاری"
                            value={adsDetail.mobileNumber}
                            onChange={(e) => {
                                setAdsDetail((prev) => ({
                                    ...prev,
                                    mobileNumber: farsiDigitToEnglish(
                                        e.target.value
                                    ),
                                }));
                            }}
                        />

                        <div className="title">نشانی اینترنتی</div>
                        <input
                            className="input"
                            type="url"
                            placeholder="اختیاری"
                            value={adsDetail.url}
                            onChange={(e) => {
                                setAdsDetail((prev) => ({
                                    ...prev,
                                    url: e.target.value,
                                }));
                            }}
                            style={{ marginBottom: 0 }}
                        />
                    </Card>

                    <Card>
                        <div className="title">انتخاب صفحه</div>

                        <div className="create-ads__slider__stars">
                            {stars.map((star, index) => (
                                <div
                                    className={
                                        index <= adsDetail.star && "active"
                                    }
                                    onClick={() =>
                                        index !== 0 &&
                                        setAdsDetail((prevState) => ({
                                            ...prevState,
                                            star: index,
                                        }))
                                    }
                                >
                                    {star}
                                </div>
                            ))}
                        </div>

                        <ReactSlider
                            className="create-ads__slider"
                            value={adsDetail.star}
                            min={0}
                            max={7}
                            thumbClassName="create-ads__slider__thumb"
                            trackClassName={`create-ads__slider__track-${adsDetail.star}`}
                            onChange={(e) =>
                                e !== 0 &&
                                setAdsDetail((prevState) => ({
                                    ...prevState,
                                    star: e,
                                }))
                            }
                        />

                        <div className="create-ads__help">
                            شانس دیده شدن محتوای شما در صفحات بالاتر بیشتره، چون
                            بینندگان در این صفحات امتیاز بیشتری می‌گیرند.
                        </div>

                        <div
                            className={`create-ads__custom ${
                                adsDetail.star > 1 && "active"
                            }`}
                        >
                            <div className="create-ads__custom__header">
                                {adsDetail.star > 1
                                    ? "هدف‌گیری فعال شد"
                                    : "هدف‌گیری"}

                                <div className="create-ads__custom__header__shape right">
                                    <SVG svg="bottom-shape" />
                                </div>
                                <div className="create-ads__custom__header__shape left">
                                    <SVG svg="bottom-shape" />
                                </div>

                                <Hint
                                    onClick={() =>
                                        setShowHintModal({
                                            ...showHintModal,
                                            custom: true,
                                        })
                                    }
                                />
                            </div>

                            <div className="create-ads__custom__deactive">
                                <div className="create-ads__custom__deactive__icon">
                                    <SVG svg="lock" />
                                </div>
                                برای هدف‌گیری، باید صفحات 2 به بالا را انتخاب
                                کنید.
                            </div>

                            <div className="create-ads__custom__list">
                                {customItems.map((customItem, index) => (
                                    <div
                                        className={`create-ads__custom__item ${
                                            handleShowStar(index + 2) &&
                                            "active"
                                        }`}
                                        key={index + 2}
                                        onClick={() =>
                                            handleShowStar(index + 2) &&
                                            setShowCustomize({
                                                show: true,
                                                type: customItem.type,
                                            })
                                        }
                                    >
                                        <div className="create-ads__custom__item__circle">
                                            <SVG
                                                svg={
                                                    handleShowStar(index + 2)
                                                        ? `custom-${customItem.type}`
                                                        : "lock"
                                                }
                                            />
                                        </div>
                                        <div>{customItem.title}</div>
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className="title row a-c j-sb">
                            تعداد بازدیدکنندگان{" "}
                            {adsDetail.star > 1 && "هدف‌گیری شده "}
                            <Hint
                                onClick={() =>
                                    setShowHintModal({
                                        ...showHintModal,
                                        seen: true,
                                    })
                                }
                                theme="dark"
                            />
                        </div>

                        <div className="create-ads__counter">
                            <div
                                className="create-ads__counter__inc"
                                onClick={() => handleIncDecSeenCount("inc")}
                            >
                                <SVG svg="ads-inc" />
                            </div>

                            {adsDetail.seenCount}

                            <div
                                className="create-ads__counter__dec"
                                onClick={() => handleIncDecSeenCount("dec")}
                            >
                                <SVG svg="ads-dec" />
                            </div>
                        </div>

                        {adsDetail.star !== 1 && (
                            // {handleShowSpecific() && adsDetail.star !== 1 && (
                            <div className="create-ads__specific">
                                <React.Fragment>
                                    {/* <div className="create-ads__specific__description">
                    محتوای شما شخصی‌سازی شده و فقط برای کاربرانی که معین
                    کرده‌اید، نمایش داده می‌شود.
                  </div> */}

                                    <div className="row">
                                        <CheckBox
                                            defaultValue={
                                                adsDetail.star === 1
                                                    ? true
                                                    : showGeneral
                                            }
                                            onChange={(value) => {
                                                setShowGeneral(!showGeneral);
                                            }}
                                        />
                                        <div className="create-ads__specific__title">
                                            با زدن این دکمه، علاوه بر مخاطبین
                                            خاصی که هدف گرفته‌اید، بقیه‌ی
                                            کاربران هم محتوای شما را می‌بینند.
                                            <span>
                                                ( این امکان رایگان است. )
                                            </span>
                                        </div>
                                    </div>
                                </React.Fragment>
                            </div>
                        )}
                    </Card>

                    <div
                        className={`create-ads__button ${
                            !handleActivateSubmit() && "deactive"
                        }`}
                        onClick={() => handleSubmit()}
                    >
                        ثبت مشخصات و ادامه
                    </div>
                </div>
            </Fade>
            <Modal active={ruleModal} close={() => setRuleModal(false)}>
                <div className="create-ads__rule">
                    <span className="create-ads__rule__title">
                        شرایط و قوانین ثبت محتوا
                    </span>
                    <div className="create-ads__rule__item">
                        محتوای مغایر با قوانین جمهوری اسلامی و عرف جامعه تایید
                        نمی شود.
                    </div>
                    <div className="create-ads__rule__item">
                        لینک سایت ها و صفحات غیر مجاز (حتی اگر از لینک های کوتاه
                        شده استفاده شده باشد) تایید نمی شوند.
                    </div>
                    <div className="create-ads__rule__item">
                        تصاویر با کیفیت پایین و تصاویر اشخاص معروف بدون مجوز
                        تایید نمی شود.
                    </div>
                    <div className="create-ads__rule__item">
                        در صورت عدم تایید محتوا، امکان ویرایش صرفاً برای بخشهایی
                        که تایید نشده اند وجود دارد.
                    </div>
                    <div className="create-ads__rule__item">
                        پس از ثبت محتوا، مبلغ پرداختی به هیچ عنوان قابل استرداد
                        نیست.
                    </div>
                </div>
            </Modal>
        </React.StrictMode>
    );
};
