import {Card, Dots, Fade, PageTopbar, Spinner, SVG} from "../../../components";
import img from "../../../assets/images/kartellaMock.jpg";
import {currency} from "../../../utils/Functions";
import React, {useEffect, useState} from "react";
import {getSellerDetailsAPI} from "../../../utils";
import {useHistory, useParams} from "react-router-dom";
import toast from "react-hot-toast";

export const ProductsPage = () => {
  const [vitrinList, setVitrinList] = useState<any>([]);
  const [vitrinListLoading, setVitrinListLoading] = useState(true);
  const history = useHistory();
  const params: any = useParams();

  const getSellerDetails = () => {
    setVitrinListLoading(true);
    getSellerDetailsAPI(params.id).then((res) => {
      if (res.status === 200) {
        setVitrinList(res.data?.vitrins);
      }
    }).catch(err => {
      toast.error('دریافت محصولات با خطا مواجه شد');
    }).finally(() => setVitrinListLoading(false));
  };

  useEffect(() => {
    history.replace("/seller/products/" + params.id);
  }, []);

  useEffect(() => {
    getSellerDetails();
  }, []);
  return (
    <Fade>
      <Spinner active={vitrinListLoading} color="dark"/>
      <PageTopbar title="لیست محصولات" showKv={false}/>
      <Card>
        <div className={'seller__products'}>
          {!vitrinListLoading ? (
            vitrinList.map((item) => (
              <>
                <div className="creation-showcase__item">
                  <div className="creation-showcase__item__info">
                    <div className="creation-showcase__item__image">
                      <img loading="lazy" src={`${item.photo}`} alt=""/>
                    </div>

                    <div className="col j-sb">
                      <div className="creation-showcase__item__info__title">
                        {item.title}
                      </div>

                      {!!item.price && <div className="creation-showcase__item__info__description">
                        <span>{currency(item.price)} تومان</span>
                      </div>}
                    </div>
                  </div>
                </div>
              </>
            ))
          ) : (
            <Dots color={"dark"}/>
          )}
        </div>
      </Card>
    </Fade>
  );
};