import React, { useEffect, useState } from "react";
import {
    Padding,
    Spinner,
    PageTopbar,
    Card,
    SVG,
    Share,
    Dots,
    Hint,
    Modal,
    Fade,
    SlidingHint,
} from "components";
import captain from "../../assets/images/diamond-captain.png";
import envelope from "../../assets/images/send-envelope.jpeg";
import {
    getUserFriends,
    getUserInfo,
    setPresenterAPI,
} from "../../utils/Services/API";
import { useUser } from "hooks";
import moment from "jalali-moment";
// import captain from "../assets/images/diamond-captain.png";
import toast from "react-hot-toast";
import { Link, useHistory } from "react-router-dom";
import {
    getCaptainConditionAPI,
    getCaptainConditionStatusAPI,
    introducedCounterAPI,
    introducedNumbersAPI,
    introduceNumberAPI,
    setCaptainAPI,
} from "utils/Services/Captain";
import profileImage from "../assets/images/profile.png";
import { getHintAPI, updateMateInfoAPI } from "utils";
// import { Tabs } from "../containers/OrdersContainer/dependencies";
import { farsiDigitToEnglish } from "../../utils/Functions";
// import introduceNumber from "./IntroduceNumber";
// import { introducedCounterAPI, introducedNumbersAPI, introduceNumberAPI } from "../utils/Services/Captain";
import { error } from "autoprefixer/lib/utils";
import { Tabs } from "containers/OrdersContainer/dependencies";
import { updateUserNameAPI } from "utils/Services/Captain/UpdateUserName";
import badge from "../../assets/images/badge.png";
import { Trove281276Container } from "containers/Trove281276Container";
// import { updateUserNameAPI } from "../utils/Services/Captain/UpdateUserName";

const AddNewUsersContainer = () => {
    const {
        userState: { user },
    } = useUser();
    const user_token = localStorage.getItem("user_token");
    const [userInfo, setUserInfo] = useState({});
    const [friendsList, setFriendsList] = useState([]);
    const [showSharing, setShowSharing] = useState(false);
    const [caller, setCaller] = useState(null);
    const [loading, setLoading] = useState(true);
    const [inviteCode, setInviteCode] = useState("");
    const [inviteCodeLoading, setInviteCodeLoading] = useState(false);
    const [userTotalInvited, setUserTotalInvited] = useState(0);
    const [captainConditionStatus, setCaptainConditionStatus] = useState({});
    const [showFriends, setShowFriends] = useState(true);
    const [activeTab, setActiveTab] = useState(1);
    const [isAdding, setIsAdding] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState("");
    const [friendName, setFriendName] = useState("");
    const [addNumberLoading, setAddNumberLoading] = useState(false);
    const [deleteNumberLoading, setDeleteNumberLoading] = useState(false);
    const [addedNubmers, setAddedNubmers] = useState([]);
    const [addedNubmersLoading, setAddedNubmersLoading] = useState(false);
    const [maxIntroduced, setMaxIntroduced] = useState(1);
    const [showUpdate, setShowUpdate] = useState(false);
    const [updateLoading, setUpdateLoading] = useState(false);
    const history = useHistory();
    // diamond state
    const [captainModal, setCaptainModal] = useState(false);
    const [captainHintModal, setCaptainHintModal] = useState(false);
    const [convertCaptian, setConvertCaptian] = useState(false);
    const [myCaptainCount, setMyCaptainCount] = useState(0);
    const [captainTeamCount, setCaptainTeamCount] = useState(0);
    const [hint, setHint] = useState({
        title: "",
        content: "",
    });
    const [secondHint, setSecondHint] = useState({
        title: "",
        content: "",
    });
    const [userFirstName, setUserFirstName] = useState("");
    const [userLastName, setUserLastName] = useState("");
    const [confirmModal, setConfirmModal] = useState(false);
    const [fullhint, setFullhint] = useState(false);

    const getFriends = () => {
        setLoading(true);
        const userToken = localStorage.getItem("user_token");
        const serverResponse = getUserFriends(userToken);
        serverResponse
            .then((response) => {
                setFriendsList(response?.data?.data?.friends_list);
                setCaller(response?.data?.data?.caller);
                setCaptainTeamCount(response?.data?.data?.totalCaptain);
                setMyCaptainCount(response?.data?.data?.directCaptain);
            })
            .catch((e) => {
                toast.error("خطا در برقراری ارتباط");
                return;
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const copy = () => {
        const value = document.getElementById("panel_code");
        // value.select();
        document.execCommand("copy");

        toast.success("کد کارتک شما کپی شد!");
    };

    const getMaxIntroduced = () => {
        introducedCounterAPI()
            .then((res) => {
                if (res.status === 200) {
                    setMaxIntroduced(res.data.mobileCounter);
                }
            })
            .catch((err) => {})
            .finally(() => {});
    };

    const getIntroducedNumbers = () => {
        setAddedNubmersLoading(true);
        introducedNumbersAPI()
            .then((res) => {
                if (res.status === 200) {
                    setAddedNubmers(res.data.notRegister);
                }
            })
            .catch((err) => {})
            .finally(() => setAddedNubmersLoading(false));
    };

    const updateUserName = () => {
        if (!userFirstName) {
            toast.error("لطفا نام خود را وارد نمایید");
            return;
        }
        if (!userLastName) {
            toast.error("لطفا نام خانوادگی خود را وارد نمایید");
            return;
        }
        setUpdateLoading(true);

        //Todo : userFirstName, userLastName

        updateMateInfoAPI({
            name: userFirstName,
            lastname: userLastName,
        })
            .then((response) => {
                console.log(response.status);
                if (response.status === 200) {
                    toast.success("اطلاعات شما با موفقیت ثبت شد");
                } else {
                    toast.error("مشکلی در ثبت اطلاعات پیش آمده است!");
                }
            })
            .catch((err) => {
                toast.error("مشکلی در ثبت اطلاعات پیش آمده است!");
            })
            .finally(() => {
                setUpdateLoading(false);
            });
    };

    const sendNumber = () => {
        if (!(phoneNumber.length === 11 && phoneNumber.match(/[0-9]+/g))) {
            toast.error("شماره موبایل را به درستی وارد کنید");
            return;
        }
        setAddNumberLoading(true);
        introduceNumberAPI(phoneNumber, friendName)
            .then((res) => {
                if (res.status === 200) {
                    if (res.data.registering.length > 0) {
                        toast.success("شماره با موفقیت معرفی شد");
                        getIntroducedNumbers();
                        setPhoneNumber("");
                        setConfirmModal(false);
                    }
                    if (res.data.registered.length > 0) {
                        toast.error("این شماره قبلا معرفی شده است");
                        setPhoneNumber("");
                        setConfirmModal(false);
                    }
                    if (res.data.kartaak.length > 0) {
                        toast.error("این شماره قبلا در کارتک ثبت‌نام کرده است");
                        setPhoneNumber("");
                        setConfirmModal(false);
                    }
                }
            })
            .catch((error) => {})
            .finally(() => setAddNumberLoading(false));
    };
    useEffect(() => {
        history.replace("/addnewusers");
        getFriends();
        getMaxIntroduced();
    }, []);

    useEffect(() => {
        // window.scrollTo(0, 580);
    }, [showFriends]);

    useEffect(() => {
        setUserFirstName(user.name);
        setUserLastName(user.lastname);
    }, [user]);

    useEffect(() => {
        window.scrollTo(0, 0);
        // getHint();
        // getSecondHint();
        getIntroducedNumbers();
    }, []);

    const [hintOpenStatus, setHintOpenStatus] = useState(false);
    const [hintText, setHintText] = useState("");

    useEffect(() => {
        getHintAPI("راهنمای ثابت صفحه معرفی کاربر جدید")
            .then((response) => {
                setHintText(response.content);
            })
            .catch((error) => {
                console.log(error);
                setHintText("دریافت راهنما با خطا مواجه شد");
            });
    }, []);

    return (
        <Fade>
            <PageTopbar
                title="دعوت از دوستان جدید"
                //circleHint={hintText}
                showKv={false}
            />
            <Modal active={confirmModal} close={() => setConfirmModal(false)}>
                <div className="friends-invite__modal">
                    <div className="friends-invite__modal__text">
                        درصورت زدن کلید تائید یک پیامک به نام شما برای صاحب این
                        شماره ارسال می‌شود
                    </div>
                    <div className="friends-invite__modal__actions">
                        <div
                            onClick={() => !addNumberLoading && sendNumber()}
                            className="button"
                        >
                            {addNumberLoading ? (
                                <Dots color={"light"} />
                            ) : (
                                <span>تائید</span>
                            )}
                        </div>
                        <div
                            onClick={() => setConfirmModal(false)}
                            className="button red"
                        >
                            انصراف
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal active={showUpdate} close={() => setShowUpdate(false)}>
                <div className="title">نام</div>
                <input
                    type="text"
                    className="input"
                    value={userFirstName}
                    onChange={(e) => setUserFirstName(e.target.value)}
                />

                <div className="title">نام خانوادگی</div>
                <input
                    type="text"
                    className="input"
                    value={userLastName}
                    onChange={(e) => setUserLastName(e.target.value)}
                />

                <div className="title">
                    مسئولیت صحت اطلاعات وارده، به عهده کاربر می‌باشد.
                </div>

                <div
                    className="button green"
                    onClick={() => updateLoading === false && updateUserName()}
                >
                    {updateLoading ? (
                        <Dots color={"light"} />
                    ) : (
                        <span>ثبت اطلاعات</span>
                    )}
                </div>
            </Modal>
            <Spinner active={loading} color={"dark"} />
            <div className="introduce-page">
                <Card>
                    {showFriends && (
                        <>
                            {/* <SlidingHint
                openTitle="راهنمای معرفی کاربر جدید"
                text={hintText}
                open={hintOpenStatus}
                onClick={() => {
                  setHintOpenStatus(!hintOpenStatus);
                }}
              /> */}

                            <div
                                style={{ padding: "1rem" }}
                                className="kartland__wrapper"
                            >
                                <div className="flex a-c j-c">
                                    <div
                                        onClick={() => {
                                            if (
                                                addedNubmers.length >=
                                                maxIntroduced
                                            ) {
                                                toast.error(
                                                    "صف دعوت شما تکمیل است. لطفا به راهنما مراجعه کنید"
                                                );
                                                return;
                                            }

                                            if (userFirstName && userLastName) {
                                                history.push(
                                                    `/add-number?amount=${
                                                        maxIntroduced -
                                                        addedNubmers.length
                                                    }`
                                                );
                                            } else {
                                                setShowUpdate(true);
                                            }
                                        }}
                                        className={`profile__header__info__body ${
                                            addedNubmers.length >=
                                                maxIntroduced && "disable"
                                        } submiter`}
                                        style={{
                                            marginLeft: 0,
                                            flexDirection: "column",
                                            padding: "1rem",
                                            gap: "0.5rem",
                                            cursor: "pointer",
                                        }}
                                    >
                                        <div className="profile__header--item1">
                                            <img src={envelope} alt="" />
                                        </div>
                                        <div
                                            style={{
                                                fontSize: "1rem",
                                                fontWeight: 500,
                                                padding:'5px 50px',
                                                borderRadius:10000,
                                                border:1,
                                                borderColor:'blue',
                                                background:'white'
                                            }}
                                        >
                                            ارسال دعوت‌ نامه
                                        </div>
                                    </div>
                                </div>

                                <div
                                    style={{ margin: "10px 10px 5px 10px" }}
                                    className={"kartland-buy__hint"}
                                >
                                    {loading ? (
                                        <Dots color="dark" />
                                    ) : (
                                        <div>
                                            {fullhint
                                                ? hintText
                                                : `${hintText?.slice(
                                                      0,
                                                      150
                                                  )}... `}
                                            {!fullhint ? (
                                                <span
                                                    style={{ color: "#fd6547" }}
                                                    onClick={() =>
                                                        setFullhint(true)
                                                    }
                                                    className=""
                                                >
                                                    ادامه مطلب
                                                </span>
                                            ) : (
                                                <span
                                                    style={{ color: "#fd6547" }}
                                                    onClick={() =>
                                                        setFullhint(false)
                                                    }
                                                    className=""
                                                >
                                                    بستن
                                                </span>
                                            )}
                                        </div>
                                    )}
                                </div>

                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        gap: "0.5rem",
                                    }}
                                    className="my-introduce"
                                >
                                    {/* <div className="friends-invite__max mb-8">
                                    <SVG svg={"mobile-tick"} />
                                    شماره موبایل دوستان خود را در صف دعوت قرار دهید تا بعد از ثبت‌نام آنها امتیاز بگیرید
                                </div> */}

                                    {/* <div
                                        className="button"
                                        style={{
                                            fontSize: "13px",
                                            marginBottom: "12px",
                                            backgroundColor: "#eb4d4c",
                                            cursor: "pointer",
                                        }}
                                        onClick={() => {
                                            history.push("/new-trove");
                                        }}
                                    >
                                        گنجینه 28250
                                    </div>

                                    <div
                                        className="button"
                                        style={{
                                            fontSize: "13px",
                                            marginBottom: "12px",
                                            backgroundColor: "#5e61ed",
                                            cursor: "pointer",
                                        }}
                                        onClick={() => {
                                            history.push("/profile/friends");
                                        }}
                                    >
                                        کاپیتان
                                    </div> */}

                                    {/*{(userFirstName && userLastName) ? (*/}
                                    {/*  <>*/}

                                    {/*    /!*{user.isCaptain ? <img src={captain} alt="" height="60px" width="65px"/> : <></>}*!/*/}

                                    {/*    /!*<div className="friends-invite__input">*!/*/}
                                    {/*    /!*  <input*!/*/}
                                    {/*    /!*    type="number"*!/*/}
                                    {/*    /!*    className="input"*!/*/}
                                    {/*    /!*    value={phoneNumber}*!/*/}
                                    {/*    /!*    onChange={(e) =>*!/*/}
                                    {/*    /!*      setPhoneNumber(farsiDigitToEnglish(e.target.value))*!/*/}
                                    {/*    /!*    }*!/*/}
                                    {/*    /!*    placeholder="شماره موبایل را وارد کنید"*!/*/}
                                    {/*    /!*  />*!/*/}
                                    {/*    /!*</div>*!/*/}

                                    {/*    /!*{<div className="friends-invite__input">*!/*/}
                                    {/*    /!*  /!*{user.isCaptain ? <img src={captain} alt="" height="68px" width="75px"/> : <></>}*!/*!/*/}
                                    {/*    /!*  <input*!/*/}
                                    {/*    /!*    type="text"*!/*/}
                                    {/*    /!*    className="input"*!/*/}
                                    {/*    /!*    value={friendName}*!/*/}
                                    {/*    /!*    onChange={(e) =>*!/*/}
                                    {/*    /!*      setFriendName(e.target.value)*!/*/}
                                    {/*    /!*    }*!/*/}
                                    {/*    /!*    placeholder="نام دوست خود را وارد کنید (اختیاری)"*!/*/}
                                    {/*    /!*  />*!/*/}

                                    {/*    /!*  <div onClick={() => {*!/*/}
                                    {/*    /!*    if (addedNubmers.length === maxIntroduced) {*!/*/}
                                    {/*    /!*      toast.error(`شما نمی‌توانید همزمان بیشتر از ${maxIntroduced} نفر در صف دعوت داشته باشید`);*!/*/}
                                    {/*    /!*      return;*!/*/}
                                    {/*    /!*    }*!/*/}
                                    {/*    /!*    if (phoneNumber.substring(0, 2) != "09" || phoneNumber.length != 11) {*!/*/}
                                    {/*    /!*      toast.error("شماره موبایل را به درستی وارد کنید");*!/*/}
                                    {/*    /!*      return;*!/*/}
                                    {/*    /!*    }*!/*/}
                                    {/*    /!*    if (userFirstName && userLastName) {*!/*/}
                                    {/*    /!*      setConfirmModal(true);*!/*/}
                                    {/*    /!*    } else {*!/*/}
                                    {/*    /!*      setShowUpdate(true);*!/*/}
                                    {/*    /!*    }*!/*/}
                                    {/*    /!*  }} className={`friends-invite__add ${addedNubmers.length === maxIntroduced && 'disable'}`}>*!/*/}
                                    {/*    /!*    ثبت*!/*/}
                                    {/*    /!*  </div>*!/*/}
                                    {/*    /!*</div>*!/*/}
                                    {/*    /!*}*!/*/}

                                    {/*  </>*/}
                                    {/*) : (*/}
                                    {/*  <div onClick={() => setShowUpdate(true)} className="friends-invite__add-circle">*/}
                                    {/*    <SVG svg={'plus'}/>*/}
                                    {/*  </div>*/}
                                    {/*)}*/}
                                </div>
                            </div>
                            <Tabs
                                activeTab={activeTab}
                                setActiveTab={setActiveTab}
                                items={[
                                    {
                                        title: "صف دعوت",
                                        icon: "addFriend",
                                    },
                                    {
                                        title: "دوستان من",
                                        icon: "myFriends",
                                    },
                                ]}
                            />

                            {/*{addedNubmers.length >= maxIntroduced && <div*/}
                            {/*  className="friends-invite__max-red mb-8">{`شما نمی‌توانید همزمان بیشتر از ${maxIntroduced} نفر در صف دعوت داشته باشید`}*/}
                            {/*</div>}*/}
                            <div className="friends-invite__line" />

                            {activeTab === 1 &&
                                addedNubmers.length > 0 &&
                                addedNubmers.map((number) => (
                                    <div className="friends-invite__added">
                                        <div className="friends-invite__added__detail">
                                            <div className="friends-invite__added__name">
                                                {number.name}
                                            </div>
                                            <div className="friends-invite__added__number">
                                                {number.mobile_number}
                                            </div>
                                        </div>
                                        <div className="friends-invite__added__delete">
                                            هنوز ثبت نام نکرده است
                                        </div>
                                    </div>
                                ))}
                            {activeTab === 2 && (
                                <>
                                    <div className="title">
                                        دوستان من
                                        <span
                                            style={{
                                                marginRight: 5,
                                                fontWeight: 300,
                                                fontSize: 12,
                                            }}
                                        >
                                            ( {friendsList?.length} نفر )
                                        </span>
                                    </div>

                                    {friendsList?.length === 0 && (
                                        <div className="friends__not-found">
                                            <div className="friends__not-found__logo">
                                                <SVG svg="friends" />
                                            </div>
                                            <span className="friends__not-found__title">
                                                دوستی وجود ندارد
                                            </span>
                                            <span className="friends__not-found__description">
                                                با فرستادن دعوت نامه دوستان خود
                                                را دعوت کنید
                                            </span>
                                        </div>
                                    )}

                                    <div className="friends__list">
                                        {friendsList?.map(
                                            (userFriend, index) => (
                                                <div
                                                    className="friends__list__item"
                                                    key={index}
                                                >
                                                    <div className="friends__list__image" />
                                                    <div
                                                        className={`friends__list__captain ${
                                                            userFriend.is_captain &&
                                                            "show"
                                                        }`}
                                                    >
                                                        <img
                                                            src={captain}
                                                            alt=""
                                                            height="60px"
                                                            width="62px"
                                                        />
                                                    </div>
                                                    <div
                                                        className="col"
                                                        style={{
                                                            width: "100%",
                                                        }}
                                                    >
                                                        <div className="friends__list__name">
                                                            {
                                                                userFriend.full_name
                                                            }
                                                        </div>

                                                        <div className="friends__list__phone">
                                                            {
                                                                userFriend.mobile_number
                                                            }
                                                        </div>
                                                    </div>

                                                    <div className="friends__list__date">
                                                        {moment(
                                                            userFriend.join_at,
                                                            "YYYY/MM/DD"
                                                        )
                                                            .locale("fa")
                                                            .format(
                                                                "YYYY/MM/DD"
                                                            )}
                                                    </div>
                                                </div>
                                            )
                                        )}
                                    </div>
                                </>
                            )}

                            {activeTab === 3 && (
                                <div>
                                    <Trove281276Container />
                                </div>
                            )}
                        </>
                    )}
                </Card>
            </div>
        </Fade>
    );
};

export default AddNewUsersContainer;
