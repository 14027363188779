import {
  Card, CounterInput,
  Dots,
  Fade,
  Modal, NotFoundBox,
  PageTopbar,
  Spinner,
  SVG,
} from "../../../components";
import React, {useEffect, useRef, useState} from "react";
import {getAllMessagesAPI} from "../../../utils/Services/Kartland/Club/GetAllMessages";
import moment from "jalali-moment";
import img from "../../../assets/images/kartellaMock.jpg";
import {activeAgainMessageAPI, getActiveMessageAPI, getStoreClubAPI, getUserStoreAPI} from "../../../utils";
import {ImageEditor} from "../../../components/ImageEditor";
import {toast} from "react-hot-toast";
import imageCompression from "browser-image-compression";
import {deleteMessageAPI, sendMessageAPI} from "../../../utils";
import {updateMessageAPI} from "../../../utils";
import {useHistory} from "react-router-dom";
import {Tabs} from "../../OrdersContainer/dependencies";
import {useUser} from "../../../hooks";
import {TransferBlueKmAPI} from "../../../utils/Services/Km/Blue/TransferToMobile";
import QRCode from "react-qr-code";

export const Messages = () => {
  const history = useHistory();
  const giveAmount = useRef(null);
  const {
    userState: {user},
  } = useUser();
  const [messages, setMessages] = useState([]);
  const [myShops, setMyShops] = useState([]);
  const [activeMessage, setActiveMessage] = useState(null);
  const [createModal, setCreateModal] = useState(false);
  const [showImageEditor, setShowImageEditor] = useState(false);
  const [adsImage, setAdsImage] = useState<any>({file: null, url: null});
  const [picture, setPicture] = useState<any>("");
  const [sendMessageLoading, setSendMessageLoading] = useState(false);
  const [getMessageLoading, setGetMessageLoading] = useState(true);
  const [compressLoading, setCompressLoading] = useState(0);
  const [messageText, setMessageText] = useState("");
  const [messageOptions, setMessageOptions] = useState(false);
  const [messageType, setMessageType] = useState("pictureMessage");
  const [existingImage, setExistingImage] = useState<any>(null);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deletingItem, setDeletingItem] = useState(null);
  const [activateItem, setActivateItem] = useState(null);
  const [deletingModal, setDeletingModal] = useState(false);
  const [updatingItem, setUpdatingItem] = useState(null);
  const [isUpdating, setIsUpdating] = useState(false);
  const [rejectModal, setRejectModal] = useState<boolean>(false);
  const [activeShow, setActiveShow] = useState(false);
  const [waitingShow, setWaitingShow] = useState(false);
  const [rejectShow, setRejectShow] = useState(false);
  const [expireShow, setExpireShow] = useState(false);
  const [activeAgainLoading, setActiveAgainLoading] = useState(false);
  const [activeAgainModal, setActiveAgainModal] = useState(false);
  const [messageRejectReason, setMessageRejectReason] = useState(null);
  const [picRejectReason, setPicRejectReason] = useState(null);
  const [videoRejectReason, setVideoRejectReason] = useState(null);
  const [activeTab, setActiveTab] = useState(1);
  const [clubList, setClubList] = useState([]);
  const [kmAmount, setKmAmount] = useState<any>(1);
  const [showGiveKm, setShowGiveKm] = useState(false);
  const [giveKmLoading, setGiveKmLoading] = useState(false);
  const [giveKmUser, setGiveKmUser] = useState("");

  const resetValues = () => {
    setMessageText("");
    setPicture("");
    setAdsImage({file: null, url: null});
    setExistingImage(null);
  };

  // const getMessages = () => {
  //   setGetMessageLoading(true);
  //   getAllMessagesAPI(1).then(res => {
  //     if (res.status === 200) {
  //       setMessages(res.data);
  //     }
  //   }).catch(err => {
  //     toast.error('خطایی رخ داد');
  //   }).finally(() => setGetMessageLoading(false));
  // };

  const getMessages = () => {
    setGetMessageLoading(true);
    getActiveMessageAPI()
      .then((res) => {
        if (res.status === 200) {
          setMessages(res.data);
        }
      })
      .catch((err) => {
        toast.error("خطایی رخ داد");
      })
      .finally(() => setGetMessageLoading(false));
  };

  const deleteMessage = (id) => {
    setDeleteLoading(true);
    deleteMessageAPI(id)
      .then((res) => {
        if (res.status === 200) {
          toast.success("پیام با موفقیت حذف شد.");
          setDeleteLoading(false);
          setDeletingModal(false);
          getMessages();
        }
      })
      .catch((err) => {
      })
      .finally(() => {
      });
  };

  const getMyShops = () => {
    getUserStoreAPI()
      .then((response) => {
        setMyShops([response.data]);
      })
      .catch((err) => {
      })
  };

  const activeMessageAgain = (id) => {
    setActiveAgainLoading(true);
    activeAgainMessageAPI(id)
      .then((res) => {
        if (res.status === 200) {
          toast.success("پیام با موفقیت برای فعال سازی دوباره فرستاده شد.");
          setActiveAgainLoading(false);
          setActiveAgainModal(false);
          getMessages();
        }
      })
      .catch((err) => {
      })
      .finally(() => {
      });
  };

  const compressOptions = {
    maxSizeMB: 0.7,
    maxWidthOrHeight: 1024,
    onProgress: (percentage) => {
      setCompressLoading(percentage);
    },
  };

  const getImage = (url) => {
    fetch(url)
      .then((res) => res.blob()) // Gets the response and returns it as a blob
      .then((blob) => {
        // Here's where you get access to the blob
        // And you can use it for whatever you want
        // Like calling ref().put(blob)
        setExistingImage(blob);
      });
  };

  const getClubList = () => {
    getStoreClubAPI()
      .then((response) => {
        setClubList(response.data);
      })
      .catch((err) => {
      })
      .finally(() => setGetMessageLoading(false));
  };

  const SubmitGiveKm = () => {
    setGiveKmLoading(true);
    TransferBlueKmAPI(giveKmUser, kmAmount)
      .then((response) => {
        if (response.status === 200) {
          toast.success("اهدای KP با موفقیت انجام شد.");
          setGiveKmUser("");
        }
      })
      .catch((err) => {
        toast.error("خطا در اهدای KP");
      })
      .finally(() => {
        setShowGiveKm(false);
        setGiveKmLoading(false);
      });
  };

  useEffect(() => {
    getMessages();
    history.replace("/myshop/club/messages");
    getClubList();
    getMyShops();
    // getActiveMessage();
  }, []);

  useEffect(() => {
  }, []);

  return (
    <Fade>
      <PageTopbar title="باشگاه مشتریان" showKv={false}/>
      <Spinner active={getMessageLoading} color="dark"/>
      <Modal
        active={showGiveKm}
        close={() => {
          setShowGiveKm(false);
          setGiveKmUser("");
        }}
      >
        <div className="title">اهدای KP به کاربر {giveKmUser}</div>
        <CounterInput
          ref={giveAmount}
          defaultValue={1}
          step={1}
          max={user.blueKm}
          onChange={(value) => setKmAmount(value)}
          giveKm={false}
        />
        <div
          className="button mt-12"
          onClick={() => !giveKmLoading && SubmitGiveKm()}
        >
          {giveKmLoading ? <Dots color="light"/> : "اهدای KP"}
        </div>
      </Modal>

      <div className="wrapper-fix">

        {myShops.length > 0 && !!myShops[0].activeByUser && !!myShops[0].isActive &&
          <div className="myshop-club__qr2">
            <div className="title">بارکد فروشگاه</div>
            <QRCode
              id="QRCode"
              value={`https://app.kartaak.com/qr/club/${myShops[0].id}`}
              size={160}
              bgColor={'#ffbaba00'}
            />
          </div>
        }
        <Card>
          {/* check if has kp or no (gray button ) =  storeDetail.haveKm && */}
          <div
            onClick={() => {
              console.log(myShops[0]);
              
              myShops[0]?.haveKm ?
              history.push("/myshop/club/createmessage")
              : toast.error("ابتدا طرح win win را فعال کنید.")
            }}
            className="button blue myshop-club__messages__create"
          >
            ایجاد پیام جدید
          </div>
        </Card>

        <Tabs
          activeTab={activeTab}
          setActiveTab={(tab) => setActiveTab(tab)}
          items={[
            {
              title: "اعضای باشگاه",
              icon: "",
            },
            {
              title: "پیام ها",
              icon: "",
            },
          ]}
        />

        {activeTab === 1 &&
          <Card>
            {clubList?.length !== 0 ? (
              <React.Fragment>
                {/*<div*/}
                {/*  onClick={() => {*/}
                {/*    if (*/}
                {/*      storeDetail.totalBoughtKm === 0 &&*/}
                {/*      storeDetail.totalDonatedKm === 0*/}
                {/*    ) {*/}
                {/*      toast.error(*/}
                {/*        "برای استفاده از این قابلیت، اقدام به خرید KM نمایید."*/}
                {/*      );*/}
                {/*    } else {*/}
                {/*      history.push("/myshop/club/messages");*/}
                {/*    }*/}
                {/*  }}*/}
                {/*  className={`myshop-club__messages ${*/}
                {/*    storeDetail.totalBoughtKm === 0 &&*/}
                {/*    storeDetail.totalDonatedKm === 0 &&*/}
                {/*    "deactive"*/}
                {/*  }`}*/}
                {/*>*/}
                {/*  <div className="row a-c">*/}
                {/*    <SVG svg="message" />*/}
                {/*    <div>پیام‌ها</div>*/}
                {/*  </div>*/}
                {/*  <SVG svg="arrow" />*/}
                {/*</div>*/}
                <div className="title">
                  اعضای باشگاه
                  <span style={{marginRight: 5, fontWeight: 300, fontSize: 12}}>
                ( {clubList.length} نفر )
              </span>
                </div>
                <div className="myshop-club__customers">
                  {clubList.map((customer, index) => (
                    <div className="myshop-club__customer" key={index}>
                      <div className="row a-c">
                        <div className="myshop-club__customer__image">
                          <SVG svg="profile-user"/>
                        </div>
                        <div style={{fontSize: 13, marginLeft: 8}}>کاربر:</div>
                        <div className="myshop-club__customer__mobile">
                          {customer.userinfo?.invite_code}
                        </div>
                      </div>

                      <div className="myshop-club__customer__action">
                        {moment(customer.created_at, "YYYY/MM/DD")
                          .locale("fa")
                          .format("YYYY/MM/DD")}
                        <div
                          className="myshop-club__customer__action__gift"
                          onClick={() => {
                            setShowGiveKm(true);
                            setGiveKmUser(customer.userinfo?.invite_code);
                          }}
                        >
                          <SVG svg="gift"/>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </React.Fragment>
            ) : (
              <div style={{padding: "140px 0 70px"}}>
                <NotFoundBox
                  icon="myshop-club"
                  title="عضوی در باشگاه پیدا نشد."
                  bio="با اسکن بارکد فروشگاه توسط کاربران، اقدام به ایجاد باشگاه مشتریان نمایید."
                />
              </div>
            )}
          </Card>
        }

        {activeTab === 2 &&
          <Card>
            {activeShow && <div className="title">پیام فعال</div>}

            <div className="myshop-club__messages__list">
              {messages.length > 0 &&
                messages.map((message) => (
                  console.log(message),
                  <div className="myshop-club__messages__message col">
                    <img style={{width: "-webkit-fill-available", marginBottom: "10px"}} src={message.photo} alt="" />
                    {/* {activeShow === false && setActiveShow(true)}
                {(message.type === "pictureMessage" ||
                  message.type === "picture") && (
                  <div className="myshop-club__messages__message__image">
                    <img src={message.photo} alt="pic" />
                  </div>
                )} */}
                    {message.text}

                    <div className="myshop-club__messages__message__info">
                      {(message.type === "pictureMessage" ||
                        message.type === "message") && (
                        <div className="myshop-club__messages__message__info__text">
                          {message?.message?.length > 19
                            ? `${message?.message?.substring(0, 20)}...`
                            : message?.message}
                        </div>
                      )}
                      <div className="myshop-club__messages__message__info__date">
                        {/* تاریخ انقضای پیام:{" "} */}
                        {moment(message.created_at, "YYYY/MM/DD")
                          .locale("fa")
                          .format("YYYY/MM/DD")}
                      </div>
                    </div>

                    {/* <div className="myshop-club__messages__message__actions">
                  <div
                    onClick={() => {
                      setDeletingItem(message.id);
                      setDeletingModal(true);
                    }}
                    className="myshop-club__messages__message__actions__delete"
                  >
                    <SVG svg="create-remove" />
                  </div>
                  <div
                    onClick={() => {
                      setIsUpdating(true);
                      setCreateModal(true);
                      setUpdatingItem(message.id);
                      setMessageText(message.message);
                      setMessageType(message.type);
                      setPicture(message.photo);
                      getImage(message.photo);
                    }}
                    className="myshop-club__messages__message__actions__edit"
                  >
                    <SVG svg="create-edit-product" />
                  </div>
                </div> */}
                  </div>
                ))}
            </div>

            {waitingShow && <div className="title">پیام های درانتظار تایید</div>}

            <div className="myshop-club__messages__list">
              {messages.length > 0 &&
                messages.map(
                  (message) =>
                    (message?.messageStatus === 2 ||
                      message?.pictureStatus === 2 ||
                      message?.videoStatus === 2) && (
                      <div className="myshop-club__messages__message">
                        {waitingShow === false && setWaitingShow(true)}
                        {(message.type === "pictureMessage" ||
                          message.type === "picture") && (
                          <div className="myshop-club__messages__message__image">
                            <img src={message.photoedit} alt="pic"/>
                          </div>
                        )}

                        <div className="myshop-club__messages__message__info">
                          {(message.type === "pictureMessage" ||
                            message.type === "message") && (
                            <div className="myshop-club__messages__message__info__text">
                              {message?.messageEdit?.length > 19
                                ? `${message?.messageEdit?.substring(0, 20)}...`
                                : message?.messageEdit}
                            </div>
                          )}
                          {/*<div className="myshop-club__messages__message__info__date">*/}
                          {/*  تاریخ انقضای پیام: {moment(message.expiredTime, "YYYY/MM/DD")*/}
                          {/*  .locale("fa")*/}
                          {/*  .format("YYYY/MM/DD")}*/}
                          {/*</div>*/}
                        </div>

                        <div className="myshop-club__messages__message__actions">
                          <div
                            onClick={() => {
                              setDeletingItem(message.id);
                              setDeletingModal(true);
                            }}
                            className="myshop-club__messages__message__actions__delete"
                          >
                            <SVG svg="create-remove"/>
                          </div>

                          {/*<div onClick={() => {*/}
                          {/*  setIsUpdating(true);*/}
                          {/*  setCreateModal(true);*/}
                          {/*  setUpdatingItem(message.id);*/}
                          {/*  setMessageText(message.message);*/}
                          {/*  setMessageType(message.type);*/}
                          {/*  setPicture(message.photo);*/}
                          {/*  getImage(message.photo);*/}
                          {/*}} className="myshop-club__messages__message__actions__edit">*/}
                          {/*  <SVG svg="create-edit-product"/>*/}
                          {/*</div>*/}
                        </div>
                      </div>
                    )
                )}
            </div>

            {expireShow && <div className="title">پیام های منقضی شده</div>}

            <div className="myshop-club__messages__list">
              {messages.length > 0 &&
                messages.map(
                  (message) =>
                    message?.status === 0 &&
                    message.expiredTime !== null && (
                      <div className="myshop-club__messages__message">
                        {expireShow === false && setExpireShow(true)}
                        {(message.type === "pictureMessage" ||
                          message.type === "picture") && (
                          <div className="myshop-club__messages__message__image">
                            <img src={message.photo} alt="pic"/>
                          </div>
                        )}

                        <div className="myshop-club__messages__message__info">
                          {(message.type === "pictureMessage" ||
                            message.type === "message") && (
                            <div className="myshop-club__messages__message__info__text">
                              {message?.message?.length > 19
                                ? `${message?.message?.substring(0, 20)}...`
                                : message?.message}
                            </div>
                          )}
                          <div className="myshop-club__messages__message__info__date">
                            تاریخ اتمام نمایش:{" "}
                            {moment(message.expiredTime, "YYYY/MM/DD")
                              .locale("fa")
                              .format("YYYY/MM/DD")}
                          </div>
                        </div>

                        <div className="myshop-club__messages__message__actions">
                          <div
                            onClick={() => {
                              setDeletingItem(message.id);
                              setDeletingModal(true);
                            }}
                            className="myshop-club__messages__message__actions__delete"
                          >
                            <SVG svg="create-remove"/>
                          </div>
                          <div
                            onClick={() => {
                              setActiveAgainModal(true);
                              setActivateItem(message.id);
                            }}
                            className="myshop-club__messages__message__actions__edit"
                          >
                            <SVG svg="send-again"/>
                          </div>
                        </div>
                      </div>
                    )
                )}
            </div>

            {rejectShow && <div className="title">پیام های رد شده</div>}

            <div className="myshop-club__messages__list">
              {messages.length > 0 &&
                messages.map(
                  (message) =>
                    (message?.messageStatus === 3 ||
                      message?.pictureStatus === 3 ||
                      message?.videoStatus === 3) && (
                      <div className="myshop-club__messages__message">
                        {rejectShow === false && setRejectShow(true)}
                        {(message.type === "pictureMessage" ||
                          message.type === "picture") && (
                          <div className="myshop-club__messages__message__image">
                            <img src={message.photoedit} alt="pic"/>
                          </div>
                        )}

                        <div className="myshop-club__messages__message__info">
                          {(message.type === "pictureMessage" ||
                            message.type === "message") && (
                            <div className="myshop-club__messages__message__info__text">
                              {message?.messageEdit?.length > 19
                                ? `${message?.messageEdit?.substring(0, 20)}...`
                                : message?.messageEdit}
                            </div>
                          )}
                          {/*<div className="myshop-club__messages__message__info__date">*/}
                          {/*  تاریخ انقضای پیام: {moment(message.expiredTime, "YYYY/MM/DD")*/}
                          {/*  .locale("fa")*/}
                          {/*  .format("YYYY/MM/DD")}*/}
                          {/*</div>*/}
                        </div>

                        <div className="myshop-club__messages__message__actions">
                          <div
                            onClick={() => {
                              setDeletingItem(message.id);
                              setDeletingModal(true);
                            }}
                            className="myshop-club__messages__message__actions__delete"
                          >
                            <SVG svg="create-remove"/>
                          </div>

                          <div
                            onClick={() => {
                              setMessageRejectReason(message.messageNotif);
                              setPicRejectReason(message.pictureNotif);
                              setVideoRejectReason(message.videoNotif);
                              setRejectModal(true);
                            }}
                            className="myshop-club__messages__message__actions__edit"
                          >
                            <SVG svg="myshop-comments"/>
                          </div>

                          <div
                            onClick={() => {
                              setIsUpdating(true);
                              setCreateModal(true);
                              setUpdatingItem(message.id);
                              setMessageText(message.messageEdit);
                              setMessageType(message.type);
                              setPicture(message.photoedit);
                              getImage(message.photoedit);
                            }}
                            className="myshop-club__messages__message__actions__edit"
                          >
                            <SVG svg="create-edit-product"/>
                          </div>
                        </div>
                      </div>
                    )
                )}
            </div>
          </Card>
        }
      </div>

      <Modal active={deletingModal} close={() => setDeletingModal(false)}>
        <div className={"deleting-modal"}>
          <div className={"deleting-modal__title"}>
            آیا از حذف پیام مطمئن هستید؟
          </div>
          <div className={"deleting-modal__actions"}>
            <div
              onClick={() =>
                deleteLoading === false && deleteMessage(deletingItem)
              }
              className={"button red"}
            >
              {deleteLoading ? <Dots color={"light"}/> : <span>حذف</span>}
            </div>
            <div
              onClick={() => setDeletingModal(false)}
              className={"button empty"}
            >
              بازگشت
            </div>
          </div>
        </div>
      </Modal>

      <Modal active={activeAgainModal} close={() => setActiveAgainModal(false)}>
        <div className={"deleting-modal"}>
          <div className={"deleting-modal__title"}>
            آیا می خواهید پیام قبلی را مجدداً فعال کنید؟
          </div>
          <div className={"deleting-modal__actions"}>
            <div
              onClick={() =>
                activeAgainLoading === false && activeMessageAgain(activateItem)
              }
              className={"button blue"}
            >
              {activeAgainLoading ? (
                <Dots color={"light"}/>
              ) : (
                <span>فعال سازی</span>
              )}
            </div>
            <div
              onClick={() => setActiveAgainModal(false)}
              className={"button disable"}
            >
              بازگشت
            </div>
          </div>
        </div>
      </Modal>

      <Modal active={rejectModal} close={() => setRejectModal(false)}>
        <div className={"reject-modal"}>
          {messageRejectReason && (
            <div className={"mb-10"}>
              <div className={"reject-modal__title mb-4"}>
                دلیل رد شدن متن پیام:
              </div>
              <div className={"reject-modal__reason"}>
                {messageRejectReason}
              </div>
            </div>
          )}
          {picRejectReason && (
            <div className={"mb-10"}>
              <div className={"reject-modal__title mb-4"}>
                دلیل رد شدن عکس پیام:
              </div>
              <div className={"reject-modal__reason"}>{picRejectReason}</div>
            </div>
          )}
          {videoRejectReason && (
            <div className={"mb-10"}>
              <div className={"reject-modal__title mb-4"}>
                دلیل رد شدن ویدیو پیام:
              </div>
              <div className={"reject-modal__reason"}>{videoRejectReason}</div>
            </div>
          )}

          <div className={"reject-modal__actions"}>
            <div
              onClick={() => setRejectModal(false)}
              className={"button disable"}
            >
              بازگشت
            </div>
          </div>
        </div>
      </Modal>
    </Fade>
  );
};
