import "./check-box.scss";
import { useState, FC, useEffect } from "react";
import { SVG } from "components";

type CheckBoxType = {
  defaultValue?: boolean;
  onChange: Function;
  className?: string;
  disable?: boolean;
  type?: "radio" | "check";
};
export const CheckBox: FC<CheckBoxType> = ({
  defaultValue,
  onChange,
  className,
  disable,
  type = "radio",
}) => {
  const [value, setValue] = useState(defaultValue ? defaultValue : false);
  const handleClick = (e) => {
    setValue(value == true ? false : true);
    onChange(value == true ? false : true);
  };

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  return type == "radio" ? (
    <div className={`checkbox ${className}`} onClick={(e) => handleClick(e)}>
      <div className="checkbox__inside">
        <div className={`checkbox__inside__circle ${value && "active"}`}></div>
      </div>
    </div>
  ) : (
    <div
      className={`checkbox__square ${className}`}
      onClick={(e) => handleClick(e)}
    >
      <div className={`checkbox__square__inside ${value && "active"}`}>
        <SVG svg="checked" />
      </div>
    </div>
  );
};
