import { KmContainer } from 'containers/CollaborateContainer/Logs';
import React from 'react';

const CollaborateKmLogs = () => {
  return (
    <KmContainer />
  );
}

export default CollaborateKmLogs;
