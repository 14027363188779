import { Fade, NotFoundBox, PageTopbar, Spinner } from 'components';
import { PanelLogs } from 'components/PanelLogs';
import moment from 'jalali-moment';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { collaborateKartellaLogsAPI } from 'utils';

export const KartellaContainer = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [kartellaHistory, setKartellaHistory] = useState([]);

  const getKartellaHistory = () => {
    collaborateKartellaLogsAPI().then(response => {
      setKartellaHistory(response.data);
      setLoading(false);
    })
  }

  useEffect(() => {
    history.replace('/collaborate/logs/kartella');
    getKartellaHistory();
  }, []);

  return (
    <Fade>
      <PageTopbar title="سوابق جذب محتوا" />
      <Spinner active={loading} color="dark" />
      {
        kartellaHistory.length === 0 ? (
          <NotFoundBox
            title="تاریخچه‌ای برای جذب محتوا یافت نشد"
            fullScreen={true}
            icon="kartella"
          />
        ) : (
          <div className="col" style={{ padding: '0 12px' }} className="ios-top">
            {kartellaHistory.map(kartellaHistoryItem => (
              <PanelLogs
                // title="جذب محتوا از کارتلا"
                description={kartellaHistoryItem.comment}
                // date={
                //   moment(kartellaHistoryItem.created_at, "YYYY/MM/DD")
                //     .locale("fa")
                //     .format("YYYY/MM/DD")
                // }
                type="kartella-logs"
              />
            ))}
          </div>
        )
      }
    </Fade>
  );
}