import React, { useEffect, useState } from "react";
import {
    Fade,
    ShopHeader,
    SVG,
    ProductItem,
    Spinner,
    WrapperCard,
    Lowprice,
    Dots,
} from "components";
import { useHistory, useLocation } from "react-router-dom";
import { getCategoriesAPI, getSpecialProductsAPI } from "utils/Services/API";
import { Link } from "react-router-dom";
import { getProductsAPI } from "utils/Services/API";
import { cloneDeep } from "lodash";
import { useUser } from "hooks";
import { transformPrice } from "utils/Functions";
import ProductImage from "../../assets/images/product.png";

export const CategoryProductContainer = () => {
    const {
        userState: { user },
    } = useUser();

    const location = useLocation();

    const [marketName, setMarketName] = useState("");
    const [parentCategoryIdList, setParentCategoryIdList] = useState<any>([]);
    const [categories, setCategories] = useState<any>([]);
    const [products, setProducts] = useState<any>([]);
    const [specialProduts, setSpecialProducts] = useState([]);
    const [slicedProducts, setSlicedProducts] = useState([]);
    const [firstSubCategoriesProducts, setFirstSubCateogoriesProducts] =
        useState<any>([]);
    const [secondSubCategoriesProducts, setSecondSubCateogoriesProducts] =
        useState<any>([]);
    const [loading, setLoading] = useState<any>(false);
    const [isLoading, setIsLoading] = useState({
        specialProducts: true,
    });
    const [currPage, setCurrPage] = useState(1);
    const [lazyLoading, setLazyLoading] = useState(false);
    const [canLoadMore, setCanLoadMore] = useState(true);
    let requestCounter = 0;
    const history = useHistory();

    const isBottom = (el) => {
        return el?.getBoundingClientRect()?.bottom <= window.innerHeight + 50;
    };

    const trackScrolling = () => {
        const wrappedElement = document.getElementById("product-full");
        if (isBottom(wrappedElement) && !lazyLoading && canLoadMore) {
            setLazyLoading(true);
            localStorage.setItem("products_page", String(currPage + 1));
            setCurrPage(currPage + 1);
            //getProducts(currPage + 1);
            document.removeEventListener("scroll", trackScrolling);
        }
    };

    /* Category function start*/
    const getSubCategories = (parentCatId) => {
        setLoading(true);
        const apiResult = getCategoriesAPI();

        apiResult.then((response) => {
            // find category data by id in main categories
            const arrayResponse: any = Object.entries(response.data.data).map(
                (category) => category[1]
            );
            let parentCategory = arrayResponse.find(
                (category) => category.id == parentCatId
            );

            // find category data by id in subcategories
            if (parentCategory === undefined) {
                for (const category of arrayResponse) {
                    parentCategory = findInSubCategories(category, parentCatId);
                    if (parentCategory !== undefined) {
                        break;
                    }
                }
            }

            // get all children of a category
            const allShowingCategories = getAllChildren(parentCategory);

            setCategories(allShowingCategories);
        });
    };

    // to search in subcategories
    const findInSubCategories = (category, parentCatId) => {
        var parentCategory;

        if (category && category?.children) {
            if (typeof category?.children === "object") {
                let listChildren: any[] = Object.entries(category.children).map(
                    (child) => child[1]
                ) as any;
                for (const subCat of listChildren) {
                    if (subCat.id == parentCatId) {
                        parentCategory = subCat;
                        break;
                    } else {
                        const searchInSub = findInSubCategories(
                            subCat,
                            parentCatId
                        );
                        if (searchInSub !== undefined) {
                            parentCategory = searchInSub;
                        }
                    }
                }
            } else if (category?.children.length > 0) {
                for (const subCat of category?.children) {
                    if (subCat.id == parentCatId) {
                        parentCategory = subCat;
                        break;
                    } else {
                        const searchInSub = findInSubCategories(
                            subCat,
                            parentCatId
                        );
                        if (searchInSub !== undefined) {
                            parentCategory = searchInSub;
                        }
                    }
                }
            }

            return parentCategory;
        } else {
            return undefined;
        }
    };

    // get category childrens recursivly
    const getAllChildren = (category) => {
        const finalList = [category];
        const currentCheckingList = category?.children;

        if (category && category?.children) {
            if (typeof currentCheckingList === "object") {
                let listChildren = Object.entries(currentCheckingList);

                listChildren.forEach((cat, index) => {
                    finalList.push(cat[1]);
                });
            } else {
                currentCheckingList.forEach((cat) => {
                    finalList.push(cat);
                    // finalList.push(getAllChildren(cat));
                });
            }
        }
        return finalList.flat();
    };

    /***  category functions end  ***/
    const getProducts = (
        categoryId,
        queryObject,
        setItems,
        state,
        page,
        counter
    ) => {
        //setLoading(true);

        const APIResult = getProductsAPI({
            category: categoryId,
            sort: validateNUll(queryObject.sort),
            page: page,
        });

        APIResult.then((response) => {
            let currProducts = cloneDeep(state);
            response?.data?.data?.data?.forEach((element) => {
                currProducts.push(element);
            });

            localStorage.setItem("products_loadmore", "true");

            if (response?.data?.data?.next_page_url === null) {
                console.log(response?.data?.data);
                console.log(response?.data?.data?.next_page_url);
                setCanLoadMore(false);
                localStorage.setItem("products_loadmore", "false");
            }

            setItems(currProducts);

            requestCounter++;

            // if (categories.length >= 3 && requestCounter === 3) {
            //     setLoading(false);
            // }
            // else if (categories.length === 2 && requestCounter === 2) {
            //     setLoading(false);
            // }
            // else if (categories.length === 1 && requestCounter === 1) {
            //     setLoading(false);
            // }
            setLoading(false);
        }).then(() => {
            setLazyLoading(false);
        });
    };

    const validateNUll = (prop) => {
        if (prop === null) return "";
        else return prop;
    };

    const getSpecialCustomersProducts = () => {
        if (categories) {
            getSpecialProductsAPI(categories[0]?.id)
                .then((response) => {
                    setSpecialProducts(response?.data?.data?.products);
                    setMarketName(response?.data?.data?.market?.name);
                })
                .finally(() => {
                    setIsLoading((prevState) => ({
                        ...prevState,
                        specialProducts: false,
                    }));
                });
        }
    };

    const specialProductSplice = () => {
        if (specialProduts) {
            const newSlicedProducts = [];
            const divider = 8;
            for (let i = 0; i < specialProduts.length; i += divider) {
                newSlicedProducts.push(specialProduts.slice(i, i + divider));
            }
            setSlicedProducts(newSlicedProducts);
        }
    };

    useEffect(() => {
        specialProductSplice();
    }, [specialProduts]);

    useEffect(() => {
        if (categories[0]) {
            getSpecialCustomersProducts();
        }
        setSlicedProducts([]);
    }, [categories]);

    useEffect(() => {
        document.addEventListener("scroll", trackScrolling);
        return () => {
            document.removeEventListener("scroll", trackScrolling);
        };
    });

    useEffect(() => {
        setProducts([]);
        setFirstSubCateogoriesProducts([]);
        setSecondSubCateogoriesProducts([]);
        //get category ids from url
        const selectedCategoryId = location.search.substr(1).split(",");

        setParentCategoryIdList(selectedCategoryId);

        //get all subCategories
        getSubCategories(
            Number(selectedCategoryId[selectedCategoryId.length - 1])
        );

        history.replace(`/category?${selectedCategoryId}`);
    }, [location.search]);

    useEffect(() => {
        if (categories.length >= 1) {
            getProducts(
                Number(categories[0]?.id),
                { sort: "desc" },
                setProducts,
                products,
                currPage,
                0
            );
        }

        // get products from first sub category
        // if (categories.length >= 2 && firstSubCategoriesProducts.length === 0) {
        //     getProducts(
        //         Number(categories[1]?.id),
        //         { sort: "desc" },
        //         setFirstSubCateogoriesProducts,
        //         firstSubCategoriesProducts,
        //         1,
        //         1
        //     );
        // }

        //get products from second sub category
        // if (
        //     categories.length >= 3 &&
        //     secondSubCategoriesProducts.length === 0
        // ) {
        //     getProducts(
        //         Number(categories[2].id),
        //         { sort: "desc" },
        //         setSecondSubCateogoriesProducts,
        //         secondSubCategoriesProducts,
        //         1,
        //         2
        //     );
        // }
    }, [categories, currPage]);

    useEffect(() => {
        console.log("categories changed");
        setCurrPage(1);
        setCanLoadMore(true);
    }, [categories]);

    const createLink = () => {
        return parentCategoryIdList.reduce((res, value) => res + "," + value);
    };

    return (
        <Fade>
            <Spinner active={loading} color="dark" />

            <div className="cat-product">
                <ShopHeader
                    createLink={createLink}
                    type="categoryList"
                    isLoading={isLoading}
                    categories={categories}
                    marketName={marketName}
                />

                <div className="cat-product__container">
                    <div className="product-list">
                        {!loading && (
                            <div>
                                {/* <div
                                    style={{ justifyContent: "center" }}
                                    className="product-list__header"
                                >
                                    <div
                                        style={{ paddingTop: "1rem" }}
                                        className="product-list__header__title"
                                    >
                                        <div>
                                            فروشگاه اینترنتی{" "}
                                            {marketName && (
                                                <span
                                                    style={{
                                                        color: "#db2c2c",
                                                    }}
                                                >{`${marketName}`}</span>
                                            )}
                                        </div>
                                    </div>
                                    <Link
                                            className="product-list__header__all-button"
                                            to={`/products?category=${
                                                parentCategoryIdList[
                                                    parentCategoryIdList.length -
                                                        1
                                                ]
                                            }`}
                                        >
                                            مشاهده همه
                                        </Link>
                                </div> */}

                                {slicedProducts.map((products) => (
                                    <div className="home-product-list">
                                        <div className="home-product-list__header">
                                            {/* <Link
                                                    className="home-product-list__header__all-button"
                                                    to={`/products?sort=special`}
                                                >
                                                    مشاهده همه
                                                </Link> */}
                                        </div>
                                        <div className="home-product-list__body">
                                            {products?.map((product) => (
                                                <ProductItem
                                                    product={product}
                                                />
                                            ))}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                        {!loading && (
                            <div>
                                <div className="product-list__header">
                                    {/* <div
                                        style={{ marginTop: "1rem" }}
                                        className="product-list__header__title"
                                    >
                                        بازار کارتک
                                    </div> */}
                                </div>
                                {products.length > 0 ? (
                                    <div id="product-full">
                                        <div
                                            style={{ padding: 0 }}
                                            className="product-full"
                                        >
                                            <div
                                                style={{ marginTop: "0" }}
                                                className="product-full__wrapper"
                                            >
                                                <div
                                                    style={{
                                                        paddingBottom: "0.5rem",
                                                    }}
                                                    className="product-full__list"
                                                >
                                                    {products.map((product) => (
                                                        <React.Fragment>
                                                            <WrapperCard>
                                                                <Link
                                                                    style={{
                                                                        alignItems:
                                                                            "center",
                                                                        height: "8rem",
                                                                        padding:
                                                                            "0rem 0.5rem 0rem 1rem",
                                                                    }}
                                                                    className="product-list-item__detail"
                                                                    to={`/product/${product.id}`}
                                                                >
                                                                    <div
                                                                        style={{
                                                                            display:
                                                                                "flex",
                                                                            flexDirection:
                                                                                "column",
                                                                        }}
                                                                    >
                                                                        <div className="product-list-item__image">
                                                                            <img
                                                                                loading="lazy"
                                                                                src={
                                                                                    product.image &&
                                                                                    product
                                                                                        .image[0]
                                                                                        ? `${product.image[0]}`
                                                                                        : ProductImage
                                                                                }
                                                                                alt=""
                                                                            />
                                                                        </div>
                                                                        <div
                                                                            style={{
                                                                                padding:
                                                                                    "5px 10px 1px 1px",
                                                                                fontSize:
                                                                                    "10px",
                                                                                color: "#195190",
                                                                            }}
                                                                        >
                                                                            {
                                                                                product.seller
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div className="product-list-item__info">
                                                                        <div className="product-list-item__top">
                                                                            <div
                                                                                style={{
                                                                                    paddingTop:
                                                                                        "0.5rem",
                                                                                }}
                                                                                className="product-list-item__title"
                                                                            >
                                                                                {
                                                                                    product.title
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        {user?.isCitizen !==
                                                                            1 && (
                                                                            <div
                                                                                style={{
                                                                                    padding:
                                                                                        "0.9rem",
                                                                                    bottom: "0px",
                                                                                    left: "-1px",
                                                                                }}
                                                                                className="product-list-item__tag"
                                                                            >
                                                                                ارزان‌تر
                                                                                بخرید
                                                                                <SVG svg="arrow" />
                                                                            </div>
                                                                        )}

                                                                        <div className="product-list-item__bottom">
                                                                            <div className="product-item__buy discount">
                                                                                <div className="product-item__buy__price">
                                                                                    <Lowprice />
                                                                                    <div
                                                                                        style={{
                                                                                            padding:
                                                                                                "0rem 0.5rem",
                                                                                        }}
                                                                                        className="product-item__buy__price__discount"
                                                                                    >
                                                                                        {` ${transformPrice(
                                                                                            Number(
                                                                                                product
                                                                                                    .defaultPrice[0]
                                                                                            )
                                                                                        )}`}
                                                                                        <div className="product-item__buy__price__discount__toman">
                                                                                            تومان
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="row a-c">
                                                                                <div
                                                                                    style={{
                                                                                        justifyContent:
                                                                                            "flex-end",
                                                                                        padding:
                                                                                            "0rem 0.5rem",
                                                                                    }}
                                                                                    className="product-item__buy__price j-sb"
                                                                                >
                                                                                    <div className="product-item__buy__price__discount a-c">
                                                                                        {user?.isCitizen ===
                                                                                            1 &&
                                                                                            product
                                                                                                .defaultPrice[5] &&
                                                                                            transformPrice(
                                                                                                Number(
                                                                                                    product
                                                                                                        .defaultPrice[5]
                                                                                                )
                                                                                            )}
                                                                                        {user?.isCaptain ===
                                                                                            1 &&
                                                                                            user?.isCitizen ===
                                                                                                0 &&
                                                                                            transformPrice(
                                                                                                Number(
                                                                                                    product
                                                                                                        .defaultPrice[4]
                                                                                                )
                                                                                            )}
                                                                                        {user
                                                                                            ?.detail
                                                                                            ?.star ===
                                                                                            7 &&
                                                                                            user?.isCaptain ===
                                                                                                0 &&
                                                                                            user?.isCitizen ===
                                                                                                0 &&
                                                                                            transformPrice(
                                                                                                Number(
                                                                                                    product
                                                                                                        .defaultPrice[2]
                                                                                                )
                                                                                            )}
                                                                                        {user
                                                                                            ?.detail
                                                                                            ?.star !==
                                                                                            7 &&
                                                                                            user?.isCaptain ===
                                                                                                0 &&
                                                                                            user?.isCitizen ===
                                                                                                0 &&
                                                                                            transformPrice(
                                                                                                Number(
                                                                                                    product
                                                                                                        .defaultPrice[3]
                                                                                                )
                                                                                            )}
                                                                                    </div>

                                                                                    <div className="product-item__buy__title">
                                                                                        تومان
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        {product
                                                                            .productColor
                                                                            .length >
                                                                            0 && (
                                                                            <div className="product-list-item__colors">
                                                                                {product.productColor.map(
                                                                                    (
                                                                                        productColor,
                                                                                        productColorIndex
                                                                                    ) =>
                                                                                        Number(
                                                                                            productColor.id
                                                                                        ) !==
                                                                                            1 &&
                                                                                        productColorIndex <=
                                                                                            3 && (
                                                                                            <div
                                                                                                style={{
                                                                                                    backgroundColor: `#${productColor.hex_code}`,
                                                                                                }}
                                                                                                key={
                                                                                                    productColorIndex
                                                                                                }
                                                                                                className="product-list-item__colors__item"
                                                                                            />
                                                                                        )
                                                                                )}
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </Link>
                                                            </WrapperCard>
                                                        </React.Fragment>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="product-list__not-found">
                                        <div className="product-list__not-found__logo">
                                            <SVG svg="ads-category" />
                                        </div>
                                        <span className="product-list__not-found__title">
                                            محصولی در این دسته وجود ندارد
                                        </span>
                                    </div>
                                )}
                            </div>
                        )}
                        {lazyLoading && <Dots />} 
                    </div>
                </div>
            </div>
        </Fade>
    );
};
