import "./Spinner.scss";

export const Spinner = ({ active = false, color, isFullscreen=true }) => {
  return (
    <div className={`${isFullscreen &&  `spinner ${active && "active"}`}`}>
      <div className={`spinners ${color}`}>
        <div className="spinners__item1" />
        <div className="spinners__item2" />
        <div className="spinners__item3" />
      </div>
    </div>
  );
};
