import { PageTopbar, Spinner, SVG } from "components";
import logo from "../../assets/images/certificate-logo.png";
import { useHistory, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { getMyUnitInfoAPI } from "../../utils/Services/KartlandUnits";
import { useUser } from "../../hooks";
import moment from "jalali-moment";

export const CertificateContainer = ({ children }) => {
    const history = useHistory();
    const params: any = useParams();
    const {
        userState: { user },
    } = useUser();
    const [unitDetails, setUnitDetails] = useState<any>();
    const [unitDetailsLoading, setUnitDetailsLoading] = useState(true);

    const getMyUnitInfo = () => {
        setUnitDetailsLoading(true);
        getMyUnitInfoAPI(params.id).then((res) => {
            if (res.status === 200) {
                setUnitDetails(res.data);
                setUnitDetailsLoading(false);
            }
        });
    };

    const owner = [
        { title: "نام و نام خانوادگی :", value: user?.detail?.fullName },
        { title: "شماره تلفن :", value: user?.mobile_number },
    ];

    useEffect(() => {
        getMyUnitInfo();
    }, []);

    useEffect(() => {
        history.replace(`/certificate/${params.id}`);
    }, []);

    return (
        <div className="certificate">
            <div
                className={`page-topbar__back`}
                style={{ zIndex: 6, top: "7px" }}
                onClick={() => history.goBack()}
            >
                <SVG svg="arrow-big" />
            </div>
            {/*<div*/}
            {/*  className={`certificate__back-button`}*/}
            {/*  onClick={() => history.goBack()}*/}
            {/*>*/}
            {/*  <SVG svg="arrow-big" />*/}
            {/*</div>*/}
            <Spinner active={unitDetailsLoading} color="dark" />
            {unitDetailsLoading === false && (
                <div className="certificate__blur">
                    <div className="certificate__frame">
                        <div className="certificate__subframe">
                            <div className="certificate__body">
                                <SVG svg="certificate-logo" />
                                <span className="certificate__body__title">
                                    گواهی مالکیت ویترین تجاری شهر مجازی کارتک
                                </span>
                                <div className="certificate__separator"></div>
                                <div className="certificate__body__info">
                                    <strong>مشخصات ویترین مجازی</strong>
                                    <div className="certificate__separator" />
                                    <div
                                        className={`row a-c j-sb w-full px-16 mb-10`}
                                    >
                                        <span className="certificate__title">
                                            شماره پلاک:
                                        </span>
                                        <span className="certificate__value">
                                            {unitDetails.unitnumber}
                                        </span>
                                    </div>
                                    <div
                                        className={`row a-c j-sb w-full px-16 mb-10`}
                                    >
                                        <span className="certificate__title">
                                            استان:
                                        </span>
                                        <span className="certificate__value">
                                            {unitDetails.statename}
                                        </span>
                                    </div>
                                    <div
                                        className={`row a-c j-sb w-full px-16 mb-10`}
                                    >
                                        <span className="certificate__title">
                                            شهرستان:
                                        </span>
                                        <span className="certificate__value">
                                            {unitDetails.cityname}
                                        </span>
                                    </div>
                                </div>
                                <div className="certificate__body__owner">
                                    <strong>مشخصات مالک</strong>
                                    <div className="certificate__separator" />
                                    {owner.map((item, index) => (
                                        <div
                                            className={`row a-c j-sb w-full px-16 ${
                                                index + 1 !== owner.length &&
                                                "mb-10"
                                            }`}
                                        >
                                            <span className="certificate__title">
                                                {item.title}
                                            </span>
                                            <span className="certificate__value">
                                                {item.value}
                                            </span>
                                        </div>
                                    ))}
                                </div>
                                <div className="certificate__body__logo">
                                    <img src={logo} />
                                </div>
                            </div>
                            <div className="certificate__date">
                                <span>تاریخ خرید:</span>
                                <span>
                                    {moment(
                                        unitDetails.created_at,
                                        "YYYY/MM/DD"
                                    )
                                        .locale("fa")
                                        .format("YYYY/MM/DD")}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};
