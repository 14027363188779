import {
    Card,
    Dots,
    Fade,
    Modal,
    PageTopbar,
    SlidingHint,
    SVG,
} from "../../components";
import React, { useEffect, useState } from "react";
import {
    getHintAPI,
    newUserRankingAPI,
    rankingPrizeAPI,
    stampDetailAPI,
    troveListAPI,
    userRankingsAPI,
    userStampsAPI,
} from "../../utils";
import { useHistory } from "react-router-dom";
import { Tabs } from "../OrdersContainer/dependencies";
import moment from "jalali-moment";
import domtoimage from "dom-to-image";
import toast from "react-hot-toast";
import ProfileImage from "../../assets/images/profile.png";

export const Trove281276Container = () => {
    const history = useHistory();
    const [hintOpenStatus, setHintOpenStatus] = useState<any>(false);
    const [hintText, setHintText] = useState<any>("");
    const [troveLoading, setTroveLoading] = useState(false);
    const [stampListLoading, setStampListLoading] = useState(false);
    const [stampLoading, setStampLoading] = useState(false);

    const [trovesList, setTrovesList] = useState<any>();
    const [activeTrove, setActiveTrove] = useState<any>();
    const [activeTab, setActiveTab] = useState(1);
    const [stampList, setStampList] = useState<any>();
    const [stampDetail, setStampDetail] = useState<any>(null);
    const [stampModal, setStampModal] = useState(false);
    const [ranking, setRanking] = useState([]);
    const [rankingLoading, setRankingLoading] = useState(false);
    const [rankingModal, setRankingModal] = useState(false);
    const [prizes, setPrizes] = useState([]);
    const [downloadLoading, setDownloadLoading] = useState(false);

    const getHint = () => {
        getHintAPI("گنجینه 28250")
            .then((response) => {
                setHintText(response.content);
            })
            .catch((error) => {
                setHintText("دریافت راهنما با خطا مواجه شد");
            });
    };

    const troveList = () => {
        setTroveLoading(true);
        troveListAPI()
            .then((res) => {
                if (res.status === 200) {
                    let currTroves = [];
                    res.data.details.map((item) => {
                        if (item.counter === 28250) {
                            currTroves.push(item);
                        }
                    });

                    setTrovesList(currTroves);
                }
            })
            .catch((err) => {})
            .finally(() => setTroveLoading(false));
    };

    const getTroveStamps = (id) => {
        setStampListLoading(true);
        userStampsAPI(id)
            .then((res) => {
                if (res.status === 200) {
                    setStampList(res.data);
                }
            })
            .catch((err) => {})
            .finally(() => setStampListLoading(false));
    };

    const getStampDetail = (id) => {
        setStampLoading(true);
        stampDetailAPI(id)
            .then((res) => {
                if (res.status === 200) {
                    setStampDetail(res.data);
                }
            })
            .catch((err) => {})
            .finally(() => setStampLoading(false));
    };

    function generateImage() {
        setDownloadLoading(true);
        domtoimage
            .toPng(document.getElementById("stamp-detail"))
            .then((canvas) => {
                let img = new Image();
                img.src = canvas;
                // @ts-ignore
                document.getElementById("downloadLink").href = img.src;
                document.getElementById("downloadLink").click();
            })
            .catch((err) => {
                toast.error("دانلود موفقیت آمیز نبود");
            })
            .finally(() => setDownloadLoading(false));
    }

    const getRanking = () => {
        setRankingLoading(true);
        newUserRankingAPI()
            .then((res) => {
                if (res.status === 200) {
                    setRanking(res.data);
                }
            })
            .catch((err) => {
                toast.error("دریافت رتبه بندی با خطا مواجه شد");
            })
            .finally(() => setRankingLoading(false));
    };

    const getRankingPrizes = () => {
        setRankingLoading(true);
        rankingPrizeAPI("28250")
            .then((res) => {
                if (res.status === 200) {
                    setPrizes(res.data[0]);
                }
            })
            .catch((err) => {
                toast.error("دریافت رتبه بندی با خطا مواجه شد");
            })
            .finally(() => setRankingLoading(false));
    };

    useEffect(() => {
        history.replace("/new-trove");
        getHint();
        troveList();
        getRanking();
        getRankingPrizes();
    }, []);

    useEffect(() => {
        if (trovesList?.length > 0) {
            setActiveTrove(trovesList[activeTab - 1]);
        }
    }, [trovesList, activeTab]);

    useEffect(() => {
        if (activeTrove?.id) {
            getTroveStamps(activeTrove.id);
        }
    }, [activeTrove]);

    return (
        <Fade>
            <PageTopbar
                title={"گنجینه 28250"}
                circleHint={hintText}
                showKv={false}
            />
            <Modal active={stampModal} close={() => setStampModal(false)}>
                {/* <div onClick={() => !downloadLoading && generateImage()} className="trove__modal__download">
          {downloadLoading ?
            <Dots color={'dark'}/>
            : <SVG svg={'download'}/>}
        </div> */}
                <div id={"stamp"} className="trove__modal">
                    {stampLoading ? (
                        <Dots color={"dark"} />
                    ) : (
                        stampDetail && (
                            <div
                                id={`stamp-detail`}
                                className={"trove__modal__detail"}
                                style={{
                                    color: stampDetail.stampDetails.picture
                                        .color,
                                }}
                            >
                                <img
                                    crossOrigin="anonymous"
                                    id={"stamp-img"}
                                    src={stampDetail.stampDetails.picture.path}
                                    alt=""
                                />
                                <div className="trove__modal__name">
                                    {`بابت دعوت کاربر  ${stampDetail?.stampDetails?.UserInvited?.inv_code}`}
                                </div>
                                {/*<div className="trove__modal__address">*/}
                                {/*  {stampDetail.stampDetails.kartlandStore.state} - {stampDetail.stampDetails.kartlandStore.subCity}*/}
                                {/*</div>*/}
                                <div className="trove__modal__user">
                                    {
                                        stampDetail.stampDetails.userInfo
                                            .full_name
                                    }
                                </div>
                                <div className="trove__modal__date">
                                    {moment(
                                        stampDetail.stampDetails.created_at,
                                        "YYYY/MM/DD"
                                    )
                                        .locale("fa")
                                        .format("MMMM - YYYY")}
                                </div>
                            </div>
                        )
                    )}
                </div>

                {/*<div onClick={() => !downloadLoading && generateImage()} className="button">*/}
                {/*  {downloadLoading ? <Dots color={'light'}/> : <span>دانلود</span>}*/}
                {/*</div>*/}
                <a id="downloadLink" download="stamp.png" />
            </Modal>

            {/*<Modal active={rankingModal} close={() => setRankingModal(false)}>*/}
            {/*  <div className="trove__modal__ranking">*/}
            {/*    <div className="trove__modal__ranking__titles">*/}
            {/*      <div className="trove__modal__ranking__item__name red">نام کاربر</div>*/}
            {/*      <div className="trove__modal__ranking__item__count red">تعداد تمبر</div>*/}
            {/*    </div>*/}

            {/*    {rankingLoading ? <Dots color={'dark'}/> : (*/}
            {/*      ranking.map((item, index) => (*/}
            {/*        <div key={index} className="trove__modal__ranking__item">*/}
            {/*          <div className="trove__modal__ranking__item__name">{`${index + 1}. ${item.userInfo.full_name}`}</div>*/}
            {/*          <div className="trove__modal__ranking__item__count">{item.stampCounter}</div>*/}
            {/*        </div>*/}
            {/*      ))*/}
            {/*    )}*/}
            {/*  </div>*/}
            {/*</Modal>*/}
            <div className="wrapper-fix">
                <Card>
                    {/* <SlidingHint
          openTitle="راهنمای گنجینه 28250"
          text={hintText}
          open={hintOpenStatus}
          onClick={() => {
            setHintOpenStatus(prevState => !prevState);
          }}
        /> */}

                    {trovesList?.length > 1 && (
                        <Tabs
                            items={trovesList}
                            activeTab={activeTab}
                            setActiveTab={(tab) => {
                                setActiveTab(tab);
                            }}
                        />
                    )}

                    {!stampListLoading ? (
                        <div className="trove__header">
                            {/*<div onClick={() => {*/}
                            {/*  setRankingModal(true);*/}
                            {/*  getRanking();*/}
                            {/*}} className="trove__ranking">*/}
                            {/*  رتبه بندی*/}
                            {/*</div>*/}

                            <div className="trove__title">
                                {/* {activeTrove?.title} */}
                                28250
                            </div>

                            <div className="trove__rank">
                                <div className="trove__statistics">
                                    <div className="trove__statistics__item">
                                        <div className="trove__statistics__item__title">
                                            کل تمبرهای ایجاد شده
                                        </div>
                                        <div
                                            style={{ fontSize: "16px" }}
                                            className="trove__statistics__item__value"
                                        >
                                            {activeTrove?.stampCount}
                                        </div>
                                    </div>
                                    <div className="trove__statistics__item">
                                        <div className="trove__statistics__item__title">
                                            ارزش فعلی هر تمبر
                                        </div>
                                        <div className="trove__statistics__item__value">
                                            {activeTrove?.priceStamp?.price}{" "}
                                            تومان
                                        </div>
                                    </div>
                                </div>

                                <div className="trove__statistics__new">
                                    <div className="trove__statistics__new__item">
                                        <div className="trove__statistics__new__item__value">
                                            {stampList?.stampCount}
                                        </div>
                                        <div className="trove__statistics__new__item__title">
                                            تعداد معرفی
                                        </div>
                                    </div>

                                    {/* <div className="trove__statistics__new__item">
                                        <div className="trove__statistics__new__item__value">
                                            {stampList?.userWinners[0]?.factor}
                                        </div>
                                        <div className="trove__statistics__new__item__title">
                                            ضریب کاپیتانی
                                        </div>
                                    </div>

                                    <div className="trove__statistics__new__item">
                                        <div className="trove__statistics__new__item__value">
                                            {stampList?.userWinners[0]?.score}
                                        </div>
                                        <div className="trove__statistics__new__item__title">
                                            امتیاز نهایی
                                        </div>
                                    </div> */}
                                </div>

                                <div className="trove__stamps">
                                    {stampList?.stampDetails.length > 0 ? (
                                        stampList?.stampDetails.map(
                                            (stamp, index) => (
                                                <div
                                                    className={
                                                        "trove__stamps__stamp"
                                                    }
                                                    onClick={() => {
                                                        getStampDetail(
                                                            stamp.id
                                                        );
                                                        setStampModal(true);
                                                    }}
                                                >
                                                    <img
                                                        src={stamp.picture.path}
                                                        alt=""
                                                    />
                                                    <div className="trove__stamps__stamp__index">
                                                        <div>{index + 1}</div>
                                                    </div>
                                                </div>
                                            )
                                        )
                                    ) : (
                                        <div className={"trove__stamps__empty"}>
                                            شما تمبری در این 28250 ندارید
                                        </div>
                                    )}
                                </div>
                            </div>

                            {/* <div className="trove__rank__list">
                                <div className="trove__rank__title">
                                    آلبوم‌های برتر
                                </div>

                                <div className="trove__rank__list__items">
                                    {ranking?.map((item, index) => (
                                        <div className="trove__rank__item">
                                            <div className="trove__rank__item__details">
                                                <div className="trove__rank__item__details__pic">
                                                    <img
                                                        src={
                                                            item?.userRankInfo
                                                                ?.profile_photo
                                                                ? item
                                                                      ?.userRankInfo
                                                                      ?.profile_photo
                                                                : ProfileImage
                                                        }
                                                        alt="profile"
                                                    />
                                                </div>
                                                <div className="trove__rank__item__details__info">
                                                    {
                                                        item?.userRankInfo
                                                            ?.invite_code
                                                    }
                                                </div>
                                                <div className="trove__rank__item__details__count">
                                                    <div>{item?.score}</div>
                                                    <div>امتیاز</div>
                                                </div>
                                            </div>

                                            <div className="trove__rank__item__prize">
                                                <div className="trove__rank__icon">
                                                    <SVG svg={"collection"} />
                                                    <div
                                                        className={
                                                            "trove__rank__icon__number"
                                                        }
                                                    >
                                                        {index + 1}
                                                    </div>
                                                </div>

                                                <div className="trove__rank__item__prize__value">
                                                    {prizes[`gift${index + 1}`]}
                                                    <SVG
                                                        svg={"white-km-icon"}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div> */}
                        </div>
                    ) : (
                        <div className={"trove__loading"}>
                            <Dots color={"dark"} />
                        </div>
                    )}
                </Card>
            </div>
        </Fade>
    );
};
