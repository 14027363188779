import {Card, CheckBox, Dots, Fade, Hint, Modal, PageTopbar, Spinner, SVG} from "../../components";
import React, {createContext, useEffect, useState} from "react";
import {getContentDetailAPI, showSingleVideoAPI} from "../../utils";
import toast from "react-hot-toast";
import {useHistory, useParams} from "react-router-dom";
import ReactSlider from "react-slider";
import {FillCustom} from "../CreateAdsContainer/FillCustom/FillCustom";
import {CreateAdsContext} from "../CreateAdsContainer";
import imageCompression from "browser-image-compression";
import {createAdsAPI} from "../../utils/Services/API";
import {editorConfirmAPI} from "../../utils/Services/UserContent/EditorConfirm";
import {useUser} from "../../hooks";
import { Player, BigPlayButton } from 'video-react';

export const EditorContentContainer = () => {
  const {id} = useParams<any>();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const {userState: {user}} = useUser();
  const [contentsDetail, setContentsDetail] = useState<any>();
  const [rejectModal, setRejectModal] = useState<boolean>(false);
  const stars = ["0", "1", "2", "3", "4", "5", "6", "7"];
  const customItems = [
    {
      title: "محل سکونت",
      type: "city",
    },
    {
      title: "جنسیت",
      type: "gender",
    },
    {
      title: "سن",
      type: "age",
    },
    {
      title: "تحصیل",
      type: "education",
    },
    {
      title: "شغل",
      type: "employment",
    },
    {
      title: "تاهل",
      type: "marital",
    },
  ];

  const [activeStart, setActiveStart] = useState(1);
  const [seenCount, setSeenCount] = useState(1000);
  const [customizeDetail, setCustomizeDetail] = useState({
    address: {
      countryId: 1,
      countryValue: "تعیین نشده",
      stateId: 0,
      stateValue: "",
      cityId: 0,
      cityValue: "",
      districtId: 0,
      districtValue: "",
    },
    gender: {
      gender: 0,
      genderValue: "تعیین نشده",
    },
    age: {
      from: 12,
      to: 99,
    },
    education: {
      educationStatus: 0,
      educationStatusValue: "تعیین نشده",
      educationDegree: 0,
      educationDegreeValue: "",
      studyId: 0,
      studyField: "",
    },
    employment: {
      employment: 0,
      employmentValue: "تعیین نشده",
      job: 0,
      jobValue: "",
      guild: 0,
      freelanceJob: 0,
      emeritus: 0,
      readyToWork: 0,
      skill: 0,
    },
    marital: {
      marital: 0,
      maritalValue: "تعیین نشده",
      maritalChildren: 0,
      maritalChildrenCount: null,
    },
  });
  const [showCustomize, setShowCustomize] = useState({
    type: "",
    show: false,
  });
  const [showGeneral, setShowGeneral] = useState(false);
  const [customize, setCustomize] = useState({
    city: {
      active: false,
    },
    gender: {
      active: false,
    },
    age: {
      active: false,
    },
    education: {
      active: false,
    },
    employment: {
      active: false,
    },
    marital: {
      active: false,
    },
  });
  const [submitLoading, setSubmitLoading] = useState(false);
  const [rejectMessage, setRejectMessage] = useState('');

  const handleSubmit = (status) => {
    setSubmitLoading(true);
    var formData = new FormData();

    formData.append("rate", String(activeStart));
    formData.append("status", status);
    if (status === 0) {
      formData.append("message", rejectMessage);
    }
    formData.append("seen", String(seenCount / 500));
    formData.append("type", "image");
    if (activeStart === 1) {
      formData.append("specialVisit", "0");
    } else {
      formData.append("specialVisit", showGeneral ? "0" : "1");
    }

    if (activeStart >= 2) {
      customizeDetail.address.countryId !== 1 &&
      formData.append("country", String(customizeDetail.address.countryId));
      customizeDetail.address.stateId !== 0 &&
      formData.append("state", String(customizeDetail.address.stateId));
      customizeDetail.address.cityId !== 0 &&
      formData.append("city", String(customizeDetail.address.cityId));
    }

    if (activeStart >= 3) {
      customizeDetail.gender.gender !== 0 &&
      formData.append("gender", String(customizeDetail.gender.gender));
    }

    // if (activeStart >= 4) {
    formData.append("FromAge", String(customizeDetail.age.from));
    formData.append("ToAge", String(customizeDetail.age.to));
    // }

    if (activeStart >= 5) {
      if (handleShowStar(5)) {
        // customizeDetail.education.educationStatus !== 0 &&
        formData.append(
          "EduStatus",
          String(customizeDetail.education.educationStatus)
        );
        customizeDetail.education.educationDegree !== 0 &&
        formData.append(
          "EduDegree",
          String(customizeDetail.education.educationDegree)
        );
        customizeDetail.education.studyId !== 0 &&
        formData.append(
          "EduField",
          String(customizeDetail.education.studyId)
        );
      }

      if (handleShowStar(6)) {
        // customizeDetail.employment.employment !== 0 &&
        formData.append(
          "employment",
          String(customizeDetail.employment.employment)
        );
        customizeDetail.employment.job !== 0 &&
        formData.append("job", String(customizeDetail.employment.job));
        customizeDetail.employment.guild !== 0 &&
        formData.append("guilds", String(customizeDetail.employment.guild));
        customizeDetail.employment.freelanceJob !== 0 &&
        formData.append(
          "Freelancejob",
          String(customizeDetail.employment.freelanceJob)
        );
        customizeDetail.employment.emeritus !== 0 &&
        formData.append(
          "emeritus",
          String(customizeDetail.employment.emeritus)
        );
        customizeDetail.employment.readyToWork !== 0 &&
        formData.append(
          "readyToWork",
          String(customizeDetail.employment.readyToWork)
        );
        customizeDetail.employment.skill !== 0 &&
        formData.append("skill", String(customizeDetail.employment.skill));
      }

      if (handleShowStar(7)) {
        // customizeDetail.marital.marital !== 0 &&
        formData.append("marital", String(customizeDetail.marital.marital));
        customizeDetail.marital.maritalChildrenCount !== null &&
        formData.append(
          "children",
          String(customizeDetail.marital.maritalChildrenCount)
        );
      }
    }

    editorConfirmAPI(id, formData).then(res => {
      if (res.status === 200) {
        toast.success('محتوا با موفقیت تائید شد');
        history.push('/content-management');
      }
    }).catch(err => {
      toast.error('با خطا مواجه شدیم');
    }).finally(() => setSubmitLoading(false));
  };


  const handleShowStar = (index) => {
    if (index <= activeStart) {
      if (index <= 4) return true;
    }

    if (activeStart > 4) {
      if (activeStart === 5) {
        if (index === 5) {
          if (customize.employment.active || customize.marital.active)
            return false;
          else return true;
        }
        if (index === 6) {
          if (customize.education.active || customize.marital.active)
            return false;
          else return true;
        }
        if (index === 7) {
          if (customize.education.active || customize.employment.active)
            return false;
          else return true;
        }
      }

      if (activeStart === 6) {
        if (index === 5) {
          if (customize.employment.active && customize.marital.active)
            return false;
          else return true;
        }
        if (index === 6) {
          if (customize.education.active && customize.marital.active)
            return false;
          else return true;
        }
        if (index === 7) {
          if (customize.education.active && customize.employment.active)
            return false;
          else return true;
        }
      }

      if (activeStart === 7) {
        return true;
      }
    }
  };


  const handleIncDecSeenCount = (type) => {
    if (type === "inc") {
      setSeenCount(prevState => prevState + 500);
    } else if (type === "dec") {
      if (seenCount <= 1499) {
        toast.error("حداقل تعداد بازدید، 1000 نفر می‌باشد.");
        return;
      } else {
        setSeenCount(prevState => prevState - 500);
      }
    }
  };

  const handleSetFillCustom = (activeFill) => {
    switch (activeFill) {
      case "city":
        return "city";

      case "gender":
        return "gender";

      case "age":
        return "age";

      case "education":
        return "education";

      case "employment":
        return "employment";

      case "marital":
        return "marital";

      default:
        return "";
    }
  };

  const getContent = () => {
    setLoading(true);
    getContentDetailAPI(id).then(res => {
      if (res.status === 200) {
        setContentsDetail(res.data);
        if (
          res?.data?.pictureContent?.path?.length === 32 ||
          res?.data?.pictureContent?.path == null
        ) {          
          getVideoURL(res?.data?.video_url);
        }
      }
    }).catch(err => {
      toast.error("خطا در بارگذاری محتوا");
    }).finally(() => setLoading(false));
  };

  const [videoAddress, setVideoAddress] = useState<any>({});

  const getVideoURL = (videoId) => {
    showSingleVideoAPI(videoId)
      .then((res) => {
        setVideoAddress(res.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getContent();
    history.replace('/confirm-editor/' + id)
  }, []);

  return (
    <Fade>
      <PageTopbar title={'بررسی محتوا'} showKv={false}/>
      <Spinner active={loading} color={'dark'}/>

      <CreateAdsContext.Provider
        value={{
          detail: {get: customizeDetail, set: setCustomizeDetail},
          active: setCustomize,
        }}
      >
        <FillCustom
          type={handleSetFillCustom(showCustomize.type)}
          active={showCustomize.show}
          closeFillCustom={(close) => {
            setShowCustomize({type: "", show: false});
          }}
        />
      </CreateAdsContext.Provider>

      <div className={'wrapper-fix'} style={{position: "relative"}}>
        <div className="contents">
          <Card>
            <div className="contents__details">
              <div className="contents__details__img">
                {
                  contentsDetail?.pictureContent?.path != null ? <img src={contentsDetail?.pictureContent?.path} alt=""/> :
                  <Player
                    playsInline
                    poster={videoAddress?.thumbnail_url}
                    src={videoAddress?.video_url}
                  >
                    <BigPlayButton position="center" />
                  </Player>
                }
              </div>

              <div className="create-ads__final__preview">
                <div className="create-ads__final__preview__title">
                  جزئیات محتوا
                </div>

                <div className="create-ads__final__preview__box">
                  {/* <div className="title">جزئیات</div> */}

                  <div className="create-ads__final__preview__info">
                    <div className="create-ads__final__preview__info__item">
                      <SVG svg="ads-category"/>
                      {"دسته‌بندی: " + contentsDetail?.categoryName?.title}
                    </div>
                    <div className="create-ads__final__preview__info__item">
                      <SVG svg="ads-category"/>
                      {"عنوان: " + contentsDetail?.title}
                    </div>
                    <div className="create-ads__final__preview__info__item">
                      <SVG svg="ads-category"/>
                      {"توضیحات: " + contentsDetail?.descriptions}
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </Card>


          {user.isEditor === 1 && <Card>
            <div className="title">انتخاب صفحه</div>

            <div className="create-ads__slider__stars">
              {stars.map((star, index) => (
                <div
                  className={index <= activeStart && "active"}
                  onClick={() =>
                    index !== 0 &&
                    setActiveStart(index)
                  }
                >
                  {star}
                </div>
              ))}
            </div>

            <ReactSlider
              className="create-ads__slider"
              value={activeStart}
              min={0}
              max={7}
              thumbClassName="create-ads__slider__thumb"
              trackClassName={`create-ads__slider__track-${activeStart}`}
              onChange={(e) =>
                e !== 0 &&
                setActiveStart(e)
              }
            />

            <div className="create-ads__help">
              شانس دیده شدن محتوا در صفحات بالاتر بیشتره، چون بینندگان در
              این صفحات امتیاز بیشتری می‌گیرند.
            </div>

            <div
              className={`create-ads__custom ${activeStart > 1 && "active"}`}
            >
              <div className="create-ads__custom__header">
                {activeStart > 1 ? "هدف‌گیری فعال شد" : "هدف‌گیری"}

                <div className="create-ads__custom__header__shape right">
                  <SVG svg="bottom-shape"/>
                </div>
                <div className="create-ads__custom__header__shape left">
                  <SVG svg="bottom-shape"/>
                </div>

                {/*<Hint*/}
                {/*  onClick={() => {*/}
                {/*    // setShowHintModal({...showHintModal, custom: true})*/}
                {/*  }}*/}
                {/*/>*/}
              </div>

              <div className="create-ads__custom__deactive">
                <div className="create-ads__custom__deactive__icon">
                  <SVG svg="lock"/>
                </div>
                برای هدف‌گیری، باید صفحات 2 به بالا را انتخاب کنید.
              </div>

              <div className="create-ads__custom__list">
                {customItems.map((customItem, index) => (
                  <div
                    className={`create-ads__custom__item ${handleShowStar(index + 2) && "active"
                    }`}
                    key={index + 2}
                    onClick={() =>
                      handleShowStar(index + 2) &&
                      setShowCustomize({show: true, type: customItem.type})
                    }
                  >
                    <div className="create-ads__custom__item__circle">
                      <SVG
                        svg={
                          handleShowStar(index + 2)
                            ? `custom-${customItem.type}`
                            : "lock"
                        }
                      />
                    </div>
                    <div>{customItem.title}</div>
                  </div>
                ))}
              </div>
            </div>

            <div className="title row a-c j-sb">
              تعداد بازدیدکنندگان {activeStart > 1 && "هدف‌گیری شده "}
              {/*<Hint*/}
              {/*  onClick={() => {*/}
              {/*    // setShowHintModal({...showHintModal, seen: true});*/}
              {/*  }}*/}
              {/*  theme="dark"*/}
              {/*/>*/}
            </div>

            <div className="create-ads__counter">
              <div
                className="create-ads__counter__inc"
                onClick={() => handleIncDecSeenCount("inc")}
              >
                <SVG svg="ads-inc"/>
              </div>

              {seenCount}

              <div
                className="create-ads__counter__dec"
                onClick={() => handleIncDecSeenCount("dec")}
              >
                <SVG svg="ads-dec"/>
              </div>
            </div>

            {activeStart !== 1 && (
              // {handleShowSpecific() && activeStart !== 1 && (
              <div className="create-ads__specific">
                <React.Fragment>
                  {/* <div className="create-ads__specific__description">
                    محتوای شما شخصی‌سازی شده و فقط برای کاربرانی که معین
                    کرده‌اید، نمایش داده می‌شود.
                  </div> */}

                  <div className="row">
                    <CheckBox
                      defaultValue={activeStart === 1 ? true : showGeneral}
                      onChange={(value) => {
                        setShowGeneral(!showGeneral);
                      }}
                    />
                    <div className="create-ads__specific__title">
                      با زدن این دکمه، علاوه بر مخاطبین خاصی که هدف گرفته‌اید،
                      بقیه‌ی کاربران هم محتوای شما را می‌بینند.
                      {/*<span>( این امکان رایگان است. )</span>*/}
                    </div>
                  </div>
                </React.Fragment>
              </div>
            )}
          </Card>}


          <div className="shop-creation__action2">

            <div
              className={`button`}
              onClick={() => !submitLoading && handleSubmit(1)}
            >
              {submitLoading ? <Dots color="light"/> : "تائید"}
            </div>

            <div
              className={`button red`}
              onClick={() =>
                // !submitLoading && handleSubmit(0)}
                setRejectModal(true)}
            >
              {/*{submitLoading ? <Dots color="light"/> : "رد محتوا"}*/}
              {"رد محتوا"}
            </div>
          </div>
        </div>
      </div>

      <Modal active={rejectModal} close={() => setRejectModal(false)}>
        <div className={"reject-modal"}>

          <div className={"mb-10"}>
            <div className={"reject-modal__title mb-4"}>
              لطفا دلیل رد کردن محتوا را بنویسید:
            </div>
            <textarea
              className="textarea"
              placeholder="دلیل رد محتوا"
              onChange={(e) =>
                setRejectMessage(e.target.value)
              }
              value={rejectMessage}
              rows={4}
              style={{marginTop: '8px'}}
            />
          </div>

          <div className={"reject-modal__actions"}>
            <div
              onClick={() =>
                !submitLoading && handleSubmit(0)}
              className={"button red"}
            >
              {submitLoading ? <Dots color="light"/> : "رد"}
            </div>
            <div
              onClick={() => setRejectModal(false)}
              className={"button disable"}
            >
              بازگشت
            </div>
          </div>
        </div>
      </Modal>

    </Fade>
  );
};