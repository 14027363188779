import { SVG } from 'components';
import './Hint.scss';

export const Hint = ({ onClick = () => { }, theme = "light" }) => {
  return (
    <div className={`hint ${theme}`} onClick={() => onClick()}>
      <div className="hint__icon">
        <SVG svg="info" />
      </div>

      <div className="hint__title">
        راهنما
      </div>
    </div>
  );
}