import {
    Card,
    Dots,
    Fade,
    Modal,
    NotFoundBox,
    PageTopbar,
    Spinner,
    SVG,
} from "../../../components";
import React, { useEffect, useState } from "react";
import moment from "jalali-moment";
import { useHistory } from "react-router-dom";
import { exitClubAPI, myClubsAPI } from "../../../utils";
import toast from "react-hot-toast";
import { Tabs } from "containers/OrdersContainer/dependencies";
import { addFavoriteAPI, getFavoritesAPI } from "utils/Services/API";

export const FollowingStores = () => {
    const user_token = localStorage.getItem("user_token");
    const history = useHistory();

    const [myClubs, setMyClubs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [deletingClub, setDeletingClub] = useState(0);
    const [exitLoading, setExitLoading] = useState(false);
    const [exitModal, setExitModal] = useState(false);

    const [productsMark, setProductsMark] = useState([]);
    const [deleteModal, setDeleteModal] = useState(false);
    const [deletingProd, setDeletingProd] = useState(0);
    const [deleteLoading, setDeleteLoading] = useState(false);

    const [loadData, setLoadData] = useState(true);
    const [activeTab, setActiveTab] = useState(1);
    let tabs = [
        // {
        //     title: "فروشگاه ها",
        // },
        {
            title: "محصولات",
        },
    ];

    useEffect(() => {
        getMyClubs();
    }, []);

    const getMyClubs = () => {
        myClubsAPI()
            .then((response) => {
                setMyClubs(response.data);
            })
            .catch((err) => {})
            .finally(() => {
                setLoading(false);
                setLoadData(false);
            });
    };

    const getFavoriteProducts = () => {
        const APIResult = getFavoritesAPI(user_token);
        APIResult.then((response) => {
            if (response.status === 200) {
                setProductsMark(response.data.data);
            }
        })
            .catch((err) => {
                toast.error("دریافت لیست محصولات مورد علاقه با خطا مواجه شد.");
            })
            .finally(() => {
                setLoadData(false);
            });
    };

    const exitClub = () => {
        setExitLoading(true);
        exitClubAPI(deletingClub)
            .then((response) => {
                toast.success("از باشگاه با موفقیت خارج شدید");
                getMyClubs();
                setExitModal(false);
            })
            .catch((err) => {})
            .finally(() => {
                setExitLoading(false);
            });
    };

    const removeFromFavorites = () => {
        setDeleteLoading(true);
        setLoadData(true);
        const APIResult = addFavoriteAPI(user_token, deletingProd);
        APIResult.then((response) => {
            if (response.status === 200) {
                setDeleteModal(false);
                toast.success("محصول از لیست علاقه‌مندی‌ها حذف شد.");
            }
        })
            .catch((err) => {
                toast.error("محصول از لیست علاقه‌مندی‌ها حذف نشد.");
            })
            .finally(() => {
                getFavoriteProducts();
            });
    };

    useEffect(() => {
        setLoadData(true);
        if (activeTab === 1) {
            getFavoriteProducts();
        } else if (activeTab === 2) {
            getMyClubs();
        }
    }, [activeTab]);

    return (
        <Fade>
            <PageTopbar title="علاقمندی‌ها" showKv={false} />
            <Spinner active={loading} color={"dark"} />

            <Modal active={exitModal} close={() => setExitModal(false)}>
                <div className="deleting-modal">
                    <div className="deleting-modal__title">
                        آیا از خروج از باشگاه مطمئن هستید؟
                    </div>
                    <div className="deleting-modal__actions">
                        <div
                            onClick={() => !exitLoading && exitClub()}
                            className="button red"
                        >
                            {exitLoading ? (
                                <Dots color={"light"} />
                            ) : (
                                <span>خروج از باشگاه</span>
                            )}
                        </div>
                        <div
                            onClick={() => setExitModal(false)}
                            className="button disable"
                        >
                            بازگشت
                        </div>
                    </div>
                </div>
            </Modal>

            <Modal active={deleteModal} close={() => setDeleteModal(false)}>
                <div className="deleting-modal">
                    <div className="deleting-modal__title">
                        آیا از حذف محصول از علاقمندی‌ها مطمئن هستید؟
                    </div>
                    <div className="deleting-modal__actions">
                        <div
                            onClick={() =>
                                !deleteLoading && removeFromFavorites()
                            }
                            className="button red"
                        >
                            {deleteLoading ? (
                                <Dots color={"light"} />
                            ) : (
                                <span>حذف محصول</span>
                            )}
                        </div>
                        <div
                            onClick={() => setDeleteModal(false)}
                            className="button disable"
                        >
                            بازگشت
                        </div>
                    </div>
                </div>
            </Modal>

            <div className="wrapper-fix">
                <Card>
                    <Tabs
                        activeTab={activeTab}
                        setActiveTab={setActiveTab}
                        items={tabs}
                    />
                    {activeTab === 2 ? (
                        loadData ? (
                            <Dots />
                        ) : myClubs.length === 0 ? (
                            <div style={{ padding: "200px 0" }}>
                                <NotFoundBox
                                    title="فروشگاهی در باشگاه یافت نشد"
                                    icon=""
                                />
                            </div>
                        ) : (
                            myClubs.map((club, index) => (
                                <div
                                    className="mycity__shop white-back"
                                    key={index}
                                >
                                    <div
                                        onClick={() =>
                                            history.push(
                                                `/seller/${club?.kartlandStore_id}`
                                            )
                                        }
                                        className="mycity__shop__image"
                                    >
                                        <img
                                            src={
                                                club.storeinfo?.picture[0]
                                                    ?.photo
                                            }
                                            alt=""
                                            loading="lazy"
                                        />
                                    </div>

                                    <div
                                        onClick={() =>
                                            history.push(
                                                `/seller/${club?.kartlandStore_id}`
                                            )
                                        }
                                        className="mycity__shop__info"
                                    >
                                        <div className="col">
                                            <div className="mycity__shop__title">
                                                {club.storeinfo?.name}
                                            </div>
                                            <div className="mycity__shop__address">
                                                {club.storeinfo?.address}
                                            </div>
                                        </div>
                                        <div className="mycity__shop__date">
                                            {moment(
                                                club.registerTime,
                                                "YYYY/MM/DD"
                                            )
                                                .locale("fa")
                                                .format("YYYY/MM/DD")}
                                        </div>
                                    </div>

                                    {/*<div className="mycity__shop__exit">*/}
                                    <div
                                        className="mycity__shop__exit"
                                        onClick={() => {
                                            setExitModal(true);
                                            setDeletingClub(club.id);
                                        }}
                                    >
                                        <SVG svg={"create-remove"} />
                                    </div>
                                    {/*</div>*/}
                                </div>
                            ))
                        )
                    ) : activeTab === 1 ? (
                        loadData ? (
                            <Dots />
                        ) : productsMark.length === 0 ? (
                            <div style={{ padding: "200px 0" }}>
                                <NotFoundBox title="محصولی یافت نشد" icon="" />
                            </div>
                        ) : (
                            <div>
                                {productsMark.map((product, index) => (
                                    <div
                                        className="mycity__shop white-back"
                                        key={index}
                                    >
                                        <div
                                            onClick={() =>
                                                history.push(
                                                    `/product/${product?.product?.id}`
                                                )
                                            }
                                            className="mycity__shop__image"
                                        >
                                            <img
                                                src={product?.product?.image[0]}
                                                alt=""
                                                loading="lazy"
                                            />
                                        </div>

                                        <div
                                            onClick={() =>
                                                history.push(
                                                    `/product/${product?.product?.id}`
                                                )
                                            }
                                            className="mycity__shop__info"
                                        >
                                            <div className="col">
                                                <div className="mycity__shop__title">
                                                    {product?.product?.title}
                                                </div>
                                                <div className="mycity__shop__address">
                                                    {product?.product?.seller}
                                                </div>
                                            </div>
                                            <div className="mycity__shop__date">
                                                {moment(
                                                    product?.created_at,
                                                    "YYYY/MM/DD"
                                                )
                                                    .locale("fa")
                                                    .format("YYYY/MM/DD")}
                                            </div>
                                        </div>

                                        {/*<div className="mycity__shop__exit">*/}
                                        <div
                                            className="mycity__shop__exit"
                                            onClick={() => {
                                                setDeleteModal(true);
                                                setDeletingProd(
                                                    product?.product?.id
                                                );
                                            }}
                                        >
                                            <SVG svg={"create-remove"} />
                                        </div>
                                        {/*</div>*/}
                                    </div>
                                ))}
                            </div>
                        )
                    ) : (
                        "یافت نشد"
                    )}
                </Card>
            </div>
        </Fade>
    );
};
