import React, { FC, useState } from "react";
import { SVG } from "components";
import { useUser } from "hooks";
import "./KV.scss";

type KVType = {
  number?: number;
  hintModal?: boolean;
  hintTitle?: string;
  hintBio?: string;
  hintAction?: boolean;
  hintActionTitle?: string;
  hintActionIcon?: string;
  onClickHintAction?: (e) => void;
};

export const KV: FC<KVType> = ({
  number,
  hintModal,
  hintTitle,
  hintBio,
  hintAction,
  hintActionTitle,
  hintActionIcon,
  onClickHintAction,
}) => {
  const {
    userState: { user },
  } = useUser();
  const [toggleHint, setToggleHint] = useState(false);
  const [activeCloseHintAnimation, setActiveCloseHintAnimation] =
    useState(false);

  return (
    <>
      <div
        tabIndex={1}
        onBlur={() => {
          setActiveCloseHintAnimation(true);
          setTimeout(() => {
            setToggleHint(false);
            setActiveCloseHintAnimation(false);
          }, 500);
        }}
        className={`kv-container ${toggleHint && "active"}`}
        onClick={() => (hintModal ? setToggleHint((prev) => !prev) : null)}
      >
        {toggleHint ? (
          <SVG svg="hint" />
        ) : (
          <>
            <div
              className="kv-container__logo"
              onClick={() =>
                hintModal ? setToggleHint((prev) => !prev) : null
              }
            >
              <span>KV</span>
            </div>
            <div className="kv-container__amount">
              {number ? number : user.detail && user.kv}
            </div>
          </>
        )}
      </div>
      {toggleHint ? (
        <div className={`kv-popup-hint ${activeCloseHintAnimation && "close"}`}>
          <div className="kv-popup-hint__title">
            <span>{hintTitle}</span>
            <div
              onClick={() => {
                setActiveCloseHintAnimation(true);
                setTimeout(() => {
                  setToggleHint(false);
                  setActiveCloseHintAnimation(false);
                }, 500);
              }}
            >
              <SVG svg="times" />
            </div>
          </div>
          <div className="kv-popup-hint__bio">
            <span>{hintBio}</span>
          </div>
          {hintAction ? (
            <div className="kv-popup-hint__btn" onClick={onClickHintAction}>
              <span>{hintActionTitle}</span>
              <div>
                <SVG svg={hintActionIcon} />
              </div>
            </div>
          ) : null}
        </div>
      ) : null}
    </>
  );
};
