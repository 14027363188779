import "./Setting.scss";
import { TopBar, ListItem, SVG } from "components";
import {
    kartlandProfileSidebarItems,
    profileSidebarItems,
} from "../../../utils/data";
import { useEffect, useState } from "react";
import { getHintAPI } from "utils";

export const Setting = ({ active = false, kartland = false, setClose }) => {
    const [loading, setLoading] = useState(true);
    const [activeCollapse, setActiveCollapse] = useState(0);

    const [hint1, setHint1] = useState({
        title: "",
        content: "",
    });
    const [hint2, setHint2] = useState({
        title: "",
        content: "",
    });
    const [hint3, setHint3] = useState({
        title: "",
        content: "",
    });
    const [hint4, setHint4] = useState({
        title: "",
        content: "",
    });

    const getHint1 = () => {
        getHintAPI("کارتلند و سیتی‌زن")
            .then((response) => {
                setHint1(response);
            })
            .catch(() => setHint1(false))
            .finally(() => {
                setLoading(false);
            });
    };
    const getHint2 = () => {
        getHintAPI("ویترین و فورشاپ")
            .then((response) => {
                setHint2(response);
            })
            .catch(() => setHint2(false))
            .finally(() => {});
    };
    const getHint3 = () => {
        getHintAPI("مدیریت ویترین‌های اختصاصی")
            .then((response) => {
                setHint3(response);
            })
            .catch(() => setHint3(false))
            .finally(() => {});
    };
    const getHint4 = () => {
        getHintAPI("درآمد سیتی‌زن‌ها")
            .then((response) => {
                setHint4(response);
            })
            .catch(() => setHint4(false))
            .finally(() => {});
    };

    useEffect(() => {
        getHint1();
        getHint2();
        getHint3();
        getHint4();
    }, [loading]);

    return (
        <div
            style={{ position: kartland ? "absolute" : "fixed" }}
            className={`profile-setting ${active && "active"}`}
            id="close-aria"
            onClick={(e) => e.target.id === "close-aria" && setClose(false)}
        >
            <div
                style={{ position: "inherit" }}
                className="profile-setting__container"
            >
                <TopBar
                    menuStatus={active}
                    toggleMenu={() => setClose(false)}
                />
                <div className="profile-setting__container__items">
                    {!kartland && <ListItem data={profileSidebarItems} />}

                    {kartland && !loading && (
                        <>
                            <div
                                className={`about__item ${
                                    activeCollapse === 1 && "active"
                                }`}
                                onClick={() =>
                                    activeCollapse === 0
                                        ? setActiveCollapse(1)
                                        : setActiveCollapse(0)
                                }
                            >
                                <div className="profile__item__name">
                                    <SVG
                                        style={{ transform: "rotate(0deg)" }}
                                        svg={"kartland-home"}
                                        color="#eb4d4c"
                                    />
                                    کارتلند و سیتی‌زن
                                </div>
                                <SVG svg="arrow" />
                            </div>
                            {activeCollapse === 1 && (
                                <div className="about__description">
                                    {hint1?.content?.split("\n")?.map((str) => (
                                        <div>{str}</div>
                                    ))}
                                </div>
                            )}

                            <div
                                className={`about__item ${
                                    activeCollapse === 2 && "active"
                                }`}
                                onClick={() =>
                                    activeCollapse === 2
                                        ? setActiveCollapse(0)
                                        : setActiveCollapse(2)
                                }
                            >
                                <div className="profile__item__name">
                                    <SVG
                                        style={{ transform: "rotate(0deg)" }}
                                        svg={"kartland-sold"}
                                        color={"#eb4d4c"}
                                    />
                                    ویترین و فورشاپ
                                </div>
                                <SVG svg="arrow" />
                            </div>
                            {activeCollapse === 2 && (
                                <div className="about__description">
                                    {hint2.content?.split("\n")?.map((str) => (
                                        <div>{str}</div>
                                    ))}
                                </div>
                            )}

                            <div
                                className={`about__item ${
                                    activeCollapse === 3 && "active"
                                }`}
                                onClick={() =>
                                    activeCollapse === 3
                                        ? setActiveCollapse(0)
                                        : setActiveCollapse(3)
                                }
                            >
                                <div className="profile__item__name">
                                    <SVG
                                        style={{ transform: "rotate(180deg)" }}
                                        svg={"profile-about"}
                                        color={"#DB2C2C"}
                                    />
                                    مدیریت ویترین‌های اختصاصی
                                </div>
                                <SVG svg="arrow" />
                            </div>
                            {activeCollapse === 3 && (
                                <div className="about__description">
                                    {hint3.content?.split("\n")?.map((str) => (
                                        <div>{str}</div>
                                    ))}
                                </div>
                            )}

                            <div
                                className={`about__item ${
                                    activeCollapse === 4 && "active"
                                }`}
                                onClick={() =>
                                    activeCollapse === 4
                                        ? setActiveCollapse(0)
                                        : setActiveCollapse(4)
                                }
                            >
                                <div className="profile__item__name">
                                    <SVG
                                        style={{ transform: "rotate(0deg)" }}
                                        svg={"myshop-club"}
                                        //color={item.color.icon}
                                    />
                                    درآمد ستیی‌زن‌ها
                                </div>
                                <SVG svg="arrow" />
                            </div>
                            {activeCollapse === 4 && (
                                <div className="about__description">
                                    {hint4.content?.split("\n")?.map((str) => (
                                        <div>{str}</div>
                                    ))}
                                </div>
                            )}

                            {/* <ListItem data={kartlandProfileSidebarItems} /> */}
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};
