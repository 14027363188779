import { FC, useRef } from "react";
import "./Modal.scss";
import "./hint.scss";
import { SVG } from "components";

type modalType = {
    active: boolean;
    closeBtn?: boolean;
    close: any;
    fullWidth?: boolean;
    hint?: any;
};

export const Modal: FC<modalType> = ({
    active = false,
    closeBtn = true,
    close,
    fullWidth = false,
    children,
    hint = "",
}) => {
    const modalContainer = useRef(null);

    return (
        <div
            className={`modal ${active && "active"}`}
            id="close-aria"
            onClick={(e) => {
                (e.target as HTMLInputElement).id === "close-aria" &&
                    close(true);
            }}
        >
            <div
                className={`modal__container ${fullWidth && "full-width"}`}
                ref={modalContainer}
            >
                {closeBtn && (
                    <div className="modal__close" onClick={() => close(true)}>
                        <SVG svg="times" />
                    </div>
                )}
                {children}
            </div>

            <div
                className={`modal__shape ${hint}`}
                style={{
                    height: modalContainer?.current?.offsetHeight + 26,
                    width: modalContainer?.current?.offsetWidth + 26,
                }}
            ></div>
        </div>
    );
};
