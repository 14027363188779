import {Request} from "../../api-call";

export const setVitrinItemAPI = async (title, pic, category, price) => {
  const {data} = await Request.post(`/api/v3/kartland/vitrin?title=${title}&Category=${category}&price=${price}`, pic, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
  return data;
};
