import { SVG,Card } from "components";

export const PanelBox = () => {
  return (
    <Card>
      <div className="marketing__panel__box">
        <div className="marketing__panel__box__body">
          <span>پنل سفید</span>
        </div>
        <div className="marketing__panel__box__footer">
          <div className="marketing__panel__box__footer__status">
            <div className="marketing__panel__box__footer__status__reward">
              <div
                className={`marketing__panel__box__footer__status__icon reward`}
              >
                <SVG svg="panel-reward" />
              </div>
              <div className="marketing__panel__box__footer__status__info">
                <span className="marketing__panel__box__footer__status__info__title">
                  پاداش جذب تبلیغ
                </span>
                <span
                  className={`marketing__panel__box__footer__status__info__counter reward`}
                >
                  <div
                    className={`marketing__panel__box__footer__status__dot reward`}
                  />
                  35%
                </span>
              </div>
            </div>
            <div className="marketing__panel__box__footer__status__duration">
              <div
                className={`marketing__panel__box__footer__status__icon duration`}
              >
                <SVG svg="panel-duration" />
              </div>
              <div className="marketing__panel__box__footer__status__info">
                <span className="marketing__panel__box__footer__status__info__title">
                  مدت اعتبار پنل
                </span>
                <span
                  className={`marketing__panel__box__footer__status__info__counter duration`}
                >
                  <div
                    className={`marketing__panel__box__footer__status__dot duration`}
                  />
                  35%
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="marketing__panel__box__actions">
        <div className="marketing__panel__box__actions__manage">
          <SVG svg="monitor" />
          <span>مدیریت پنل</span>
        </div>
        <div className="marketing__panel__box__actions__upgrade">
          <span>ارتقا پنل</span>
          <SVG svg="panel" />
        </div>
      </div>
    </Card>
  );
};
