import { useUser } from "hooks";
import { useHistory } from "react-router-dom";
import { Fragment, useEffect, useState } from "react";
import {
    AcceptRule,
    Card,
    CheckBox,
    Dots,
    Fade,
    Modal,
    PageTopbar,
    Selectbox,
    Spinner,
    SVG,
    UpdateUserInfo,
} from "components";
import CreateShopLocation from "./../CreateShop/dependencies/Location";
import toast from "react-hot-toast";
import {
    getCitiesAPI,
    getDistrictsAPI,
    getHintAPI,
    getStatesAPI,
    getSubCitiesAPI,
} from "utils";
import {
    CreateOnlineStoreAPI,
    DualStoreAPI,
    GetAnotherStoreInfoAPI,
} from "utils/Services/OnlineStore";

export const CreateOnlineStoreContainer = () => {
    const [shopDetail, setShopDetail] = useState({
        name: "",
        country: 1,
        state: 0,
        city: 0,
        subCity: 0,
        district: 0,
        lat: 0,
        lng: 0,
        address: "",
        description: "",
    });

    const [shippingId, setShippingId] = useState(0);

    const [data, setData] = useState({
        countries: [],
        states: [],
        cities: [],
        subCities: [],
        districts: [],
    });
    const [catData, setCatData] = useState({
        firstCats: [],
        thirdCats: [],
    });

    const [loading, setLoading] = useState(true);
    const [submitLoading, setSubmitLoading] = useState(false);
    const history = useHistory();
    const [showAlert, setShowAlert] = useState(false);
    const [hint, setHint] = useState<any>("");
    const [readCompleted, setreadCompleted] = useState(false);
    const [acceptedRule, setAcceptedRule] = useState(true);
    const [hintLoading, setHintLoading] = useState(true);
    const [showFillProfileModal, setShowFillProfileModal] = useState(false);
    const [showFillProfile, setShowFillProfile] = useState(false);
    const [showLocation, setShowLocation] = useState(false);
    const [activeStateName, setActiveStateName] = useState("");
    const [activeSubCityName, setActiveSubCityName] = useState("");
    const [showBarcode, setShowBarcode] = useState(false);
    const [userInfo, setUserInfo] = useState({
        name: "",
        lastname: "",
        nationalCode: "",
    });
    const {
        getUserDataFromApi,
        userState: { user },
    } = useUser();
    const [havePresenceStoreStore, setHavePresenceStoreStore] = useState(false);

    const getStates = (countryId) => {
        getStatesAPI(countryId)
            .then((response) => {
                setData({ ...data, states: response.data });
            })
            .catch(() => toast.error("خطا در بارگذاری استان‌ها"))
            .finally(() => {});
    };

    const getCities = (stateId) => {
        getCitiesAPI(stateId)
            .then((response) => {
                setData({ ...data, cities: response.data });
            })
            .catch(() => toast.error("خطا در بارگذاری شهرستان‌ها"))
            .finally(() => {});
    };

    const getSubCities = (cityId) => {
        getSubCitiesAPI(cityId)
            .then((response) => {
                setData({ ...data, subCities: response.data });
            })
            .catch(() => toast.error("خطا در بارگذاری شهرها"))
            .finally(() => {});
    };

    const getDistricts = (subCityId) => {
        getDistrictsAPI(subCityId)
            .then((response) => {
                setData({ ...data, districts: response.data });
            })
            .catch(() => toast.error("خطا در بارگذاری محله/ناحیه ها"))
            .finally(() => {});
    };

    const handleSubmitStore = () => {
        // if(havePresenceStoreStore) {
        // if (true) {
        var form = new FormData();
        form.append("shipping_id", shippingId.toString());
        DualStoreAPI("", form)
            .then((response) => {
                if (response.status === 200) {
                    toast.success("اطلاعات با موفقیت ثبت شد!");
                    history.push("/profile");
                }
            })
            .catch((err) => {
                toast.error(err.response.data.msg);
            })
            .finally(() => {
                getUserDataFromApi();
                setSubmitLoading(false);
            });
        // }
        //else {

        //   if (shopDetail.name === "") {
        //     toast.error("نام فروشگاه را وارد نمایید.");
        //     return;
        //   }

        //   if (shopDetail.description === "") {
        //     toast.error("توضیحات فروشگاه را وارد نمایید.");
        //     return;
        //   }

        //   if (shopDetail.country === 0) {
        //     toast.error("کشور فروشگاه خود را انتخاب کنید.");
        //     return;
        //   }

        //   if (shopDetail.state === 0) {
        //     toast.error("استان فروشگاه خود را انتخاب کنید.");
        //     return;
        //   }

        //   if (shopDetail.city === 0) {
        //     toast.error("شهرستان فروشگاه خود را انتخاب کنید.");
        //     return;
        //   }

        //   if (shopDetail.subCity === 0) {
        //     // shopDetail.subCity = 4151;
        //     toast.error("شهر/روستا فروشگاه خود را انتخاب کنید.");
        //     return;
        //   }

        //   if (shopDetail.lat === 0 && shopDetail.lng === 0) {
        //     toast.error("موقعیت جغرافیایی فروشگاه را انتخاب کنید.");
        //     return;
        //   }

        //   // if (shopDetail.district === 0) {
        //   //   shopDetail.district = 4151;
        //   //   // toast.error('ناحیه/محله فروشگاه خود را انتخاب کنید.');
        //   //   // return;
        //   // }

        //   setSubmitLoading(true);

        //   let query = `name=${shopDetail.name}&country=${shopDetail.country}&state=${shopDetail.state}
        //   &city=${shopDetail.city}&subCity=${shopDetail.subCity}&district=${shopDetail.district == 0 ? "" : shopDetail.district}
        //   &lat=${shopDetail.lat}&lng=${shopDetail.lng}`;
        //   var form = new FormData();
        //   form.append("description", shopDetail.description);
        //   form.append("address", shopDetail.address);

        //   CreateOnlineStoreAPI(query, form).then((response) => {
        //     if (response.status === 200) {
        //       toast.success("فروشگاه شما با موفقیت ثبت شد!");
        //       history.replace("/mycity");
        //     }
        //   })
        //   .catch((err) => toast.error("خطا در ثبت فروشگاه"))
        //   .finally(() => {
        //     setSubmitLoading(false);
        //     getUserDataFromApi();
        //   });
        // }
    };

    // const getHint = () => {
    //     getHintAPI("مقررات فروش اینترنتی")
    //         .then((response) => {
    //             setHint(response);
    //         })
    //         .catch(() => setHint(false))
    //         .finally(() => setHintLoading(false));
    // };

    const UserInfoHandler = () => {
        if (
            user.name?.length === 0 ||
            user.name === null ||
            user.lastname?.length === 0 ||
            user.lastname === null ||
            user.nationalCode?.length === 0 ||
            user.nationalCode === null ||
            user.nationalCode?.length !== 10
        ) {
            setShowFillProfile(true);
        } else {
            setShowFillProfile(false);
        }
    };

    useEffect(() => {
        setUserInfo({
            name: user.name,
            lastname: user.lastname,
            nationalCode: user.nationalCode,
        });
        UserInfoHandler();
    }, [user]);

    useEffect(() => {
        getStates(1);
    }, []);

    useEffect(() => {
        document
            .getElementById("buyUnitAlert")
            ?.addEventListener("scroll", () => {
                document.getElementById("buyUnitAlert").scrollTop >
                document.getElementById("buyUnitAlert")?.scrollHeight -
                    document.getElementById("buyUnitAlert")?.clientHeight -
                    20
                    ? setreadCompleted(true)
                    : setreadCompleted(false);
            });
        return () => {
            document
                .getElementById("buyUnitAlert")
                ?.removeEventListener("scroll", () => {
                    document.getElementById("buyUnitAlert").scrollTop >
                    document.getElementById("buyUnitAlert")?.scrollHeight -
                        document.getElementById("buyUnitAlert")?.clientHeight -
                        20
                        ? setreadCompleted(true)
                        : setreadCompleted(false);
                });
        };
    }, [document.getElementById("buyUnitAlert")?.scrollTop]);

    useEffect(() => {
        history.replace("/create-admin-store");
    }, []);

    useEffect(() => {
        if (user?.detail?.presenceStore === true) {
            GetAnotherStoreInfoAPI().then((response) => {
                if (response.status === 200) {
                    if (response.data.presenceStore != null) {
                        setHavePresenceStoreStore(true);
                        setLoading(false);
                    }
                }
            });
        }
        if (
            user?.detail?.presenceStore === false ||
            user?.detail?.presenceStore == null
        ) {
            setLoading(false);
        }
    }, [user]);

    return (
        <Fade>
            <PageTopbar title="تکمیل اطلاعات" showKv={false} />
            {loading ? (
                <Spinner color="dark" active={loading} />
            ) : (
                <div className="wrapper-fix">
                    {showLocation && (
                        <CreateShopLocation
                            close={() => setShowLocation(false)}
                            onSubmit={(res) => {
                                setShowLocation(false);
                                setShopDetail({
                                    ...shopDetail,
                                    lat: res.lat,
                                    lng: res.lng,
                                    address: res.address,
                                });
                            }}
                            entryString={`${activeStateName}، ${
                                activeSubCityName
                                    ? activeSubCityName + "، "
                                    : ""
                            }`}
                            newAddress={setShopDetail}
                            jadid={shopDetail.address}
                        />
                    )}

                    <UpdateUserInfo
                        showUpdate={showFillProfileModal}
                        setShowUpdate={(value) => {
                            value && handleSubmitStore();
                            setShowFillProfileModal(false);
                        }}
                        userInfo={userInfo}
                        setUserInfo={setUserInfo}
                        getUser={getUserDataFromApi}
                    />
                    <Modal
                        active={showAlert}
                        close={(close) => setShowAlert(false)}
                    >
                        <div className="buy-panel__alert__title">
                            {hint.title}
                        </div>
                        <div
                            className={`buy-panel__alert__description ${
                                readCompleted && "deactive"
                            }`}
                            id={"buyUnitAlert"}
                        >
                            {hintLoading && <Dots color="dark" />}
                            {hint.content?.split("\n")?.map((str) => (
                                <div>{str}</div>
                            ))}
                        </div>
                        <div className="row buy-panel__alert__bottom">
                            <div
                                className={`buy-panel__alert__button  ${
                                    readCompleted ? "accept" : "disabled"
                                }`}
                                onClick={() => {
                                    if (readCompleted) {
                                        setAcceptedRule(true);
                                        setShowAlert(false);
                                    } else {
                                        document
                                            .getElementById("buyUnitAlert")
                                            .scrollTo(0, 2500);
                                    }
                                }}
                            >
                                قوانین را می‌پذیرم
                            </div>
                            <div
                                className="buy-panel__alert__button reject"
                                onClick={() => setShowAlert(false)}
                            >
                                انصراف
                            </div>
                        </div>
                    </Modal>
                    <Card>
                        {/* <p style={{ paddingBottom: 25 }}>
                            اطلاعات پنل فروشگاه اینترنتی شما از اطلاعات فروشگاه
                            حضوری خوانده می شود.
                        </p> */}

                        <div>
                            <p>روش ارسال خود را انتخاب کنید.</p>
                            <div className="flex">
                                <div className=""></div>
                                <CheckBox
                                    disable
                                    defaultValue={shippingId === 0}
                                    onChange={(value) => {
                                        if (value) {
                                            setShippingId(0);
                                        }
                                    }}
                                />
                                <div className="fill-profile__form__title">
                                    غیر رایگان
                                </div>
                            </div>

                            <div className="flex">
                                <CheckBox
                                    disable
                                    defaultValue={shippingId === 1}
                                    onChange={(value) => {
                                        if (value) {
                                            setShippingId(1);
                                        }
                                    }}
                                />
                                <div className="fill-profile__form__title">
                                    رایگان
                                </div>
                            </div>

                            <div className="flex">
                                <CheckBox
                                    disable
                                    defaultValue={shippingId === 2}
                                    onChange={(value) => {
                                        if (value) {
                                            setShippingId(2);
                                        }
                                    }}
                                />
                                <div className="fill-profile__form__title">
                                    در محدوده رایگان
                                </div>
                            </div>

                            <div className="flex">
                                <CheckBox
                                    disable
                                    defaultValue={shippingId === 3}
                                    onChange={(value) => {
                                        if (value) {
                                            setShippingId(3);
                                        }
                                    }}
                                />
                                <div className="fill-profile__form__title">
                                    خرید بالاتر از قیمت
                                </div>
                            </div>
                        </div>

                        {/* {
              !havePresenceStoreStore ? (
                <>
                  <div className="title">نام فروشگاه</div>
                  <input
                    type="text"
                    className="input"
                    value={shopDetail.name}
                    onChange={(e) =>
                      setShopDetail({...shopDetail, name: e.target.value})
                    }
                    placeholder="نام فروشگاه"
                  />

                  <div className="title">توضیحات فروشگاه</div>
                  <textarea
                    className="textarea"
                    rows={5}
                    onChange={(e) =>
                      setShopDetail({...shopDetail, description: e.target.value})
                    }
                  />

                  <div className="row">

                    {shopDetail.country !== 0 && (
                      <div className="col w-100 ml-6">
                        <div className="title">استان</div>
                        <Selectbox
                          data={data.states}
                          placeholder="انتخاب استان"
                          onChange={(value) => {
                            setShopDetail({...shopDetail, state: value});
                            getCities(value);
                          }}
                          onChangeString={(value) => {
                            setActiveStateName(value);
                          }}
                          disabled={shopDetail.country === 0}
                        />
                      </div>
                    )}

                    {shopDetail.state !== 0 && (
                      <div className="col w-100 mr-6">
                        <div className="title">شهرستان</div>
                        <Selectbox
                          data={data.cities}
                          placeholder="انتخاب شهرستان"
                          onChange={(value) => {
                            setShopDetail({...shopDetail, city: value});
                            getSubCities(value);
                          }}
                          onChangeString={(value) => {
                            console.log(value);
                          }}
                          disabled={shopDetail.state === 0}
                        />
                      </div>
                    )}
                  </div>

                  <div className="row">
                    {shopDetail.city !== 0 && (
                      <div className="col w-100 ml-6">
                        <div className="title">شهر / روستا</div>
                        <Selectbox
                          data={data.subCities}
                          placeholder="انتخاب شهر یا روستا"
                          onChange={(value) => {
                            setShopDetail({...shopDetail, subCity: value});
                            getDistricts(value);
                          }}
                          onChangeString={(value) => {
                            setActiveSubCityName(value);
                          }}
                          disabled={shopDetail.city === 0}
                        />
                      </div>
                    )}

                    {shopDetail.subCity !== 0 && (
                      <div className="col w-100 mr-6">
                        <div className="title">ناحیه / منطقه</div>
                        <Selectbox
                          data={data.districts}
                          placeholder="انتخاب ناحیه یا منطقه"
                          onChange={(value) =>
                            setShopDetail({...shopDetail, district: value})
                          }
                          onChangeString={(value) => {
                            console.log(value);
                          }}
                          disabled={shopDetail.subCity === 0}
                        />
                      </div>
                    )}
                  </div>

                  {shopDetail.city !== 0 && (
                    <div
                      className="profile__item"
                      style={{borderRadius: 20, marginBottom: 16}}
                      onClick={() => setShowLocation(true)}
                    >
                      آدرس و موقعیت جغرافیایی فروشگاه
                      <SVG svg={"arrow"}/>
                    </div>
                  )}
                </>
              ) : <p style={{paddingBottom: 25}}>اطلاعات پنل فروشگاه اینترنتی شما از اطلاعات فروشگاه حضوری خوانده می شود.</p>
            } */}

                        <hr style={{ marginBottom: "20px" }} />

                        {/* <div
                            className="row a-c j-c"
                            style={{ margin: "8px auto" }}
                        >
                            <CheckBox
                                defaultValue={acceptedRule}
                                type="check"
                                onChange={(value) =>
                                    setAcceptedRule(value ? true : false)
                                }
                            />
                            <AcceptRule
                                title="ثبت فروشگاه"
                                color="dark"
                                onCLick={() => setShowAlert(true)}
                            />
                        </div> */}
                        <div
                            className={`button ${!acceptedRule && "disable"}`}
                            onClick={() => {
                                if (!acceptedRule) {
                                    toast.error(
                                        "ابتدا قوانین و مقررات ثبت فروشگاه را مطالعه و بپذیرید."
                                    );
                                    return;
                                }
                                if (showFillProfile) {
                                    setShowFillProfileModal(true);
                                } else {
                                    !submitLoading && handleSubmitStore();
                                }
                                setShowBarcode(true);
                            }}
                        >
                            {submitLoading ? <Dots color="light" /> : "ثبت"}
                        </div>
                    </Card>
                </div>
            )}
        </Fade>
    );
};
