import React, { useEffect, useRef, useState } from "react";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import "mapbox-gl/dist/mapbox-gl.css";
import { SVG, Modal, Dots } from "components";
import { useHistory } from "react-router-dom";
import toast from "react-hot-toast";

mapboxgl.accessToken =
    "pk.eyJ1Ijoia2FydGFhayIsImEiOiJjbDNsaGw4NDIwY3R1M2VwNXAxZXNhemltIn0.oG3Q1VAF7wcyaUUvSCKxVw";

const CreateProviderLocation = ({
    onSubmit,
    close,
    entryString,
    newAddress,
    jadid,
}) => {
    const history = useHistory();
    const mapContainer = useRef(null);
    const addressRef = useRef(null);
    const [addressValue, setAddressValue] = useState(jadid);
    const map = useRef(null);
    const [lng, setLng] = useState(51.657354);
    const [lat, setLat] = useState(32.674155);
    const [zoom, setZoom] = useState(17);
    const [modal, setModal] = useState(true);
    const [current, setCurrent] = useState({});
    const [loading, setLoading] = useState(false);
    const [enableSubmitButton, setEnableSubmitButton] = useState(false);

    const geolocate = new mapboxgl.GeolocateControl({
        positionOptions: {
            enableHighAccuracy: true,
        },
        setZoom: 17,
        fitBoundsOptions: { maxZoom: 17 },
        trackUserLocation: false,
        showUserHeading: true,
    });

    const goToUserLocation = () => {
        const el = document.getElementsByClassName("mapboxgl-ctrl-geolocate");
        el && el[0].click();
    };

    const handleSubmit = () => {
        // if (addressValue == "") {
        //   toast.error("آدرس متنی را وارد نمایید");
        //   return;
        // }
        setLoading(true);
        onSubmit({ lat: current.lat, lng: current.lng, address: addressValue });
    };

    return (
        <div
            onTouchMove={() => {
                if (!enableSubmitButton) {
                    setEnableSubmitButton(true);
                }
            }}
        >
            <Modal active={modal} close={() => close()}>
                <div className="mt-8">
                    <span className="">
                        آدرس متنی{" "}
                        <small className="creation-location__title__slug">
                            ( برای نمایش به کاربر )
                        </small>
                    </span>
                </div>
                <textarea
                    placeholder="آدرس محل فروشگاه را وارد نمایید"
                    value={
                        entryString + addressValue.substr(entryString.length)
                    }
                    className=" mt-10 input withoutHeight"
                    name="text"
                    rows={10}
                    onChange={(e) => {
                        setAddressValue(e.target.value);
                        newAddress((prev) => ({
                            ...prev,
                            address: e.target.value.slice(),
                        }));
                    }}
                />
                <div
                    className="button"
                    onClick={() => {
                        if (addressValue.length === 0) {
                            toast.error("آدرس فروشگاه را وارد نمایید");
                            return;
                        }
                        setModal(false);
                    }}
                >
                    {loading ? <Dots color="light" /> : "ثبت آدرس"}
                </div>
            </Modal>
        </div>
    );
};

export default CreateProviderLocation;
