import { UserContext, userTypes } from "providers";
import { useContext, useMemo } from "react";
import { GetUserDetail, getUserProfile } from "utils/Services";
export const useUser = () => {
  const { userState, userDispatch } = useContext(UserContext);

  return {
    getUserDataFromApi: async () => {
      const data = await GetUserDetail();
      userDispatch({
        type: userTypes.SET_USER_DATA,
        payload: data,
      });
    },
    getUserProfile: async () => {
      const data = await getUserProfile();
      userDispatch({
        type: userTypes.SET_USER_IMAGE,
        payload: data,
      });
    },
    setUserData: (payload) => {
      userDispatch({
        type: userTypes.SET_USER_DATA,
        payload: payload,
      });
    },
    setUserImage: (payload) => {
      userDispatch({
        type: userTypes.SET_USER_IMAGE,
        payload: payload,
      });
    },
    userState: useMemo(() => userState, [userState]),
  };
};
