import { Card, Dots, Fade, PageTopbar, Spinner, SVG, Modal } from "components";
import { KartellaContainer, QrGeneratorContainer } from "containers";
import { Tabs } from "containers/OrdersContainer/dependencies";
import moment from "jalali-moment";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Link, useHistory, useLocation } from "react-router-dom";
import { myAdsAPI } from "utils";
import { adsSavedAPI } from "utils/Services/Kartella/AdsSaved";
import { NotFound } from "./dependencies";

export const MyAdsContainer = () => {
  const location = useLocation();
  const history = useHistory();
  const [adsDetailOpened, setAdsDetailOpened] = useState(0);
  const [loading, setLoading] = useState(true);
  const [myPendingAds, setMyPendingAds] = useState([]);
  const [myApprovedAds, setMyApprovedAds] = useState([]);
  const [myNotApprovedAds, setMyNotApprovedAds] = useState<any>([]);
  const [myDeactiveAds, setMyDeactiveAds] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [savedAdsList, setSavedAdsList] = useState([]);
  const [notApprovedModal, setNotApprovedModal] = useState<any>({
    status: false,
    data: {},
  });
  const [showAdsModal, setShowAdsModal] = useState<any>({
    status: false,
    data: {},
  });

  const getMyAds = () => {
    setLoading(true);
    const pendingApi = myAdsAPI("pending");
    pendingApi
      .then((response) => {
        setMyPendingAds(response);
      })
      .catch((err) => console.log(err));

    const approvedApi = myAdsAPI("approved");
    approvedApi
      .then((response) => {
        setMyApprovedAds(response);
        setLoading(false);
      })
      .catch((err) => console.log(err));

    const notApprovedApi = myAdsAPI("notApproved");
    notApprovedApi
      .then((response) => {
        setMyNotApprovedAds(response);
        setLoading(false);
      })
      .catch((err) => console.log(err));

    const deactiveApi = myAdsAPI("deActive");
    deactiveApi
      .then((response) => {
        setMyDeactiveAds(response);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  };

  const getAdsList = () => {
    setLoading(true);
    const serverResponse = adsSavedAPI();
    serverResponse.then((response) => {
      if (response.status === 200) {
        setSavedAdsList(response.data[0]);
        setLoading(false);
      }
    });
  };

  const handleShowDetail = (ads, type, customizeNumber) => {
    if (Number(ads.rate) < customizeNumber && customizeNumber < 5) {
      return "-";
    }

    if (Number(ads.rate) < 5 && customizeNumber > 4) {
      return "-";
    }

    if (Number(ads.rate) >= 2 && customizeNumber === 2) {
      let res = "";
      if (type.country === null || type.country === "همه") return "تعیین نشده";
      else {
        res = `کشور: ${type.country}`;
        if (type.state === null || type.state === "همه") {
          return res;
        } else {
          res = `${res}، استان: ${type.state}`;
          if (type.city === null || type.city === "همه") {
            return res;
          } else {
            res = `${res}، شهر: ${type.city}`;
            if (type.district === null || type.district === "همه") {
              return res;
            } else {
              res = `${res}، محله: ${type.district}`;
            }
            return res;
          }
        }
      }
      // else  return type.country;
    }

    // if (Number(ads.rate) >= 2 && customizeNumber === 2) {
    //   if (type.country === null || type.country === "همه") return "تعیین نشده";
    //   else return type.country;
    // }

    if (Number(ads.rate) >= 3 && customizeNumber === 3) {
      if (ads.FromAge === null) return "تعیین نشده";
      else return ads.FromAge + " تا " + ads.ToAge;
    }

    if (Number(ads.rate) >= 4 && customizeNumber === 4) {
      if (type.gender === null || type.gender === "همه") return "تعیین نشده";
      else return type.gender;
    }

    if (Number(ads.rate) >= 5) {
      if (Number(ads.rate) === 7) {
        if (customizeNumber === 5) {
          if (type.education === null || type.education === "همه")
            return "تعیین نشده";
          else return type.education;
        }
        if (customizeNumber === 6) {
          if (type.employment === null || type.employment === "همه")
            return "تعیین نشده";
          else return type.employment;
        }
        if (customizeNumber === 7) {
          if (type.marital === null || type.marital === "همه")
            return "تعیین نشده";
          else return type.marital;
        }
      }

      if (Number(ads.rate) === 6 || Number(ads.rate) === 5) {
        if (customizeNumber === 5) {
          if (type.education === null) {
            return "-";
          } else if (type.education === "همه") {
            return "تعیین نشده";
          } else {
            return type.education;
          }
        }
        if (customizeNumber === 6) {
          if (type.employment === null) {
            return "-";
          } else if (type.employment === "همه") {
            return "تعیین نشده";
          } else {
            return type.employment;
          }
        }
        if (customizeNumber === 7) {
          if (type.marital === null) {
            return "-";
          } else if (type.marital === "همه") {
            return "تعیین نشده";
          } else {
            return type.marital;
          }
        }
      }
    }
  };

  const handleType = (type) => {
    switch (type) {
      case "تایید شده":
        return "approved";
      case "تایید نشده":
        return "notapproved";
      default:
        return "pending";
    }
  };

  const adsDetail = (ads, type) => {
    return (
      <div className="my-ads__item">
        <div className="row a-c j-sb" style={{ width: "100%" }}>
          <div
            className="row"
            onClick={() => setAdsDetailOpened(ads.id)}
            style={{ width: "100%" }}
          >
            <div className={`my-ads__item__tile ${handleType(type)}`}>
              <SVG svg="myads" />
            </div>

            <div className="my-ads__item__info">
              <div className="my-ads__item__title">{ads.title}</div>

              <div className={`my-ads__item__description ${handleType(type)}`}>
                {type}
              </div>
            </div>
          </div>

          <div className="my-ads__item__left">
            <div
              className="my-ads__item__close"
              onClick={() => setAdsDetailOpened(0)}
            >
              {adsDetailOpened === ads.id && <SVG svg="times" />}
            </div>

            {/* <div className="my-ads__item__date">
              {moment(ads.created_at).locale("fa").format("H:mm - YYYY/MM/DD")}
            </div> */}
            {adsDetailOpened !== ads.id && (
              <div
                onClick={() => setAdsDetailOpened(ads.id)}
                className="my-ads__item__show-info"
              >
                مشاهده جزئیات
              </div>
            )}
          </div>
        </div>

        {adsDetailOpened === ads.id ? (
          <div className="my-ads__item__detail">
            <div className="row a-c">
              <div className="my-ads__item__detail__title">تاریخ ایجاد:</div>
              <div className="my-ads__item__detail__value">
                {moment(ads.created_at)
                  .locale("fa")
                  .format("H:mm - YYYY/MM/DD")}
              </div>
            </div>
            <div className="row a-c">
              <div className="my-ads__item__detail__title">عنوان:</div>
              <div className="my-ads__item__detail__value">{ads.title}</div>
            </div>

            <div className="row a-c">
              <div className="my-ads__item__detail__title">دسته‌بندی:</div>
              <div className="my-ads__item__detail__value">{ads.adsCat}</div>
            </div>

            <div className="row a-c">
              <div className="my-ads__item__detail__title">امتیاز:</div>
              <div className="my-ads__item__detail__value">{ads.rate}</div>
            </div>

            <div className="row a-c">
              <div className="my-ads__item__detail__title">بازدید:</div>
              <div className="my-ads__item__detail__value">{ads.seen}</div>
            </div>
            <div className="row a-c">
              <div className="my-ads__item__detail__title">لینک:</div>
              <div className="my-ads__item__detail__value">
                {ads.url ? ads.url : "-"}
              </div>
            </div>
            <div className="row a-c">
              <div className="my-ads__item__detail__title">آدرس:</div>
              <div className="my-ads__item__detail__value">
                {ads.address ? ads.address : "-"}
              </div>
            </div>
            <div className="row a-c">
              <div className="my-ads__item__detail__title">تلفن:</div>
              <div className="my-ads__item__detail__value">
                {ads.phoneNumber ? ads.phoneNumber : "-"}
              </div>
            </div>
            <div className="row a-c">
              <div className="my-ads__item__detail__title">موبایل:</div>
              <div className="my-ads__item__detail__value">
                {ads.mobileNumber ? ads.mobileNumber : "-"}
              </div>
            </div>

            <div className="row a-c">
              <div className="my-ads__item__detail__title">محل سکونت:</div>
              <div className="my-ads__item__detail__value">
                {handleShowDetail(ads, { country: ads.customize.country, state:ads.customize.state ,city:ads.customize.city, district:ads.customize.district }, 2)}
              </div>
            </div>

            <div className="row a-c">
              <div className="my-ads__item__detail__title">سن:</div>
              <div className="my-ads__item__detail__value">
                {handleShowDetail(
                  ads,
                  {
                    fromAge: ads.customize.FromAge,
                    toAge: ads.customize.toAge,
                  },
                  3
                )}
              </div>
            </div>

            <div className="row a-c">
              <div className="my-ads__item__detail__title">جنسیت:</div>
              <div className="my-ads__item__detail__value">
                {handleShowDetail(ads, { gender: ads.customize.gender }, 4)}
              </div>
            </div>

            <div className="row a-c">
              <div className="my-ads__item__detail__title">وضعیت تحصیلی:</div>
              <div className="my-ads__item__detail__value">
                {handleShowDetail(
                  ads,
                  { education: ads.customize.education },
                  5
                )}
              </div>
            </div>

            <div className="row a-c">
              <div className="my-ads__item__detail__title">وضعیت شغلی:</div>
              <div className="my-ads__item__detail__value">
                {handleShowDetail(
                  ads,
                  { employment: ads.customize.employment },
                  6
                )}
              </div>
            </div>

            <div className="row a-c">
              <div className="my-ads__item__detail__title">وضعیت تاهل:</div>
              <div className="my-ads__item__detail__value">
                {handleShowDetail(ads, { marital: ads.customize.marital }, 7)}
              </div>
            </div>
          </div>
        ) : null}
        {adsDetailOpened === ads.id && (
          <div className="row" style={{ width: "100%" }}>
            {type !== "تایید نشده" && type !== "درحال بررسی" && (
              <div
                className="my-ads__item__detail__statistic"
                onClick={() => history.push(`ads/history/${ads.id}`)}
              >
                آمار بازدید
              </div>
            )}
            {type !== "درحال بررسی" && (
              <>
                <div
                  className="my-ads__item__detail__edit"
                  onClick={() => history.push(`/ads/update/${ads.id}`)}
                >
                  ویرایش
                </div>
                {type !== "تایید شده" && (
                  <div
                    onClick={() =>
                      ads.notApprovedLog.length > 0
                        ? setNotApprovedModal({ status: true, data: ads })
                        : toast.error("دلیلی ثبت نشده است.")
                    }
                    className="my-ads__item__detail__unaccept"
                  >
                    دلایل عدم تایید
                  </div>
                )}
              </>
            )}
            <div
              className="my-ads__item__detail__show"
              onClick={() => setShowAdsModal({ status: true, data: ads })}
            >
              مشاهده
            </div>
          </div>
        )}
      </div>
    );
  };

  const handleCause = () => {
    if (notApprovedModal.status) {
      const arr = notApprovedModal.data.notApprovedLog[0].cause.split(",");

      return (
        <>
          <span className="my-ads__item__detail__cause__title">
            دلایل عدم تایید:
          </span>
          {arr.map(
            (item, index) =>
              item.length !== 0 && (
                <div className="my-ads__item__detail__cause__item" key={index}>
                  {item}
                </div>
              )
          )}
        </>
      );
    }
  };

  useEffect(() => {
    if (activeTab == 1) {
      history.replace("/myads?seen");
      setLoading(false);
    } else if (activeTab == 2) {
      history.replace("/myads?saved");
      getAdsList();
    } else if (activeTab == 3) {
      history.replace("/myads?mine");
      getMyAds();
    } else if (activeTab == 4) {
      history.replace("/myads?qr");
      setLoading(false);
    }
  }, [activeTab]);

  useEffect(() => {
    if (location.search.substr(1) === "saved") {
      setActiveTab(2);
    }
    if (location.search.substr(1) === "qr") {
      setActiveTab(4);
    }
    if (
      location.search.substr(1) === "seen" ||
      location.search.substr(1) === ""
    ) {
      setActiveTab(1);
    }
    if (location.search.substr(1) === "mine") {
      setActiveTab(3);
    }
  }, [location.search]);

  return (
    <Fade>
      <PageTopbar title="آلبوم" showKv={false} />
      {/* <Spinner active={loading} color="dark" /> */}
      {/* <div className="my-qr-section">
        <QrBox />
      </div> */}

      <div className="my-ads">
        <Link to="/createads" className="my-ads__create">
          افزودن محتوا جدید
          <SVG svg="add-ads" />
        </Link>

        <div className="ads-tab">
          <Tabs
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            items={[
              {
                title: "دیده‌شده",
                icon: "seen",
              },
              {
                title: "علاقه‌مندی‌ها",
                icon: "bookmarks",
              },
              {
                title: "محتوای‌من",
                icon: "kartella",
              },
              // {
              //   title: "بارکد من",
              //   icon: "qr",
              // },
            ]}
          />
        </div>

        {loading ? (
          <div className="mt-20">
            <Dots color="dark" />
          </div>
        ) : (
          <>
            {activeTab === 3 && (
              <React.Fragment>
                {myPendingAds.length > 0 && (
                  <Card>
                    <div className="title">محتواهای درحال بررسی</div>
                    {myPendingAds.map((pendingAds, index) =>
                      adsDetail(pendingAds, "درحال بررسی")
                    )}
                  </Card>
                )}
                {myNotApprovedAds.length > 0 && (
                  <Card>
                    <div className="title">محتواهای تایید نشده</div>
                    {myNotApprovedAds.map((notApprovedAds, index) =>
                      adsDetail(notApprovedAds, "تایید نشده")
                    )}
                  </Card>
                )}
                {myApprovedAds.length > 0 && (
                  <Card>
                    <div className="title">محتواهای تایید شده</div>
                    {myApprovedAds.map((approvedAds, index) =>
                      adsDetail(approvedAds, "تایید شده")
                    )}
                  </Card>
                )}
                {myDeactiveAds.length > 0 && (
                  <Card>
                    <div className="title">محتواهای غیر فعال</div>
                    {myDeactiveAds.map((deactiveAds, index) =>
                      adsDetail(deactiveAds, "غیر فعال")
                    )}
                  </Card>
                )}
                {myPendingAds.length === 0 &&
                  myNotApprovedAds.length === 0 &&
                  myApprovedAds.length === 0 &&
                  myDeactiveAds.length === 0 && (
                    <Card>
                      <NotFound
                        icon="ads-dark"
                        text="محتوایی برای نمایش وجود ندارد"
                      />
                    </Card>
                  )}
              </React.Fragment>
            )}

            {activeTab === 1 && (
              <React.Fragment>
                <KartellaContainer />
              </React.Fragment>
            )}

            {activeTab === 2 && (
              <React.Fragment>
                <Card>
                  {savedAdsList.length == 0 ? (
                    <NotFound
                      icon="bookmark-ads"
                      text="محتوایی برای نمایش وجود ندارد"
                    />
                  ) : (
                    <div className="saved-ads">
                      {savedAdsList?.map((savedAdsItem, index) => (
                        <div
                          key={index}
                          className="saved-ads__item"
                          onClick={() =>
                            history.push(
                              `/ads?id=${savedAdsItem.advertise_id}&variety=bookmark`
                            )
                          }
                        >
                          <div className="row a-c">
                            <div className="saved-ads__item__icon">
                              <SVG svg="myads" />
                            </div>

                            <div>{savedAdsItem.advertise.title}</div>
                          </div>
                          <div className="saved-ads__item__date">
                            {moment(savedAdsItem.created_at, "YYYY/MM/DD")
                              .locale("fa")
                              .format("YYYY/MM/DD")}
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </Card>
              </React.Fragment>
            )}

            {activeTab === 4 && (
              <React.Fragment>
                <Card>
                  <QrGeneratorContainer />
                </Card>
              </React.Fragment>
            )}
          </>
        )}
      </div>
      <Modal
        active={notApprovedModal.status}
        close={() =>
          setNotApprovedModal({ ...notApprovedModal, status: false })
        }
      >
        <div className="my-ads__item__detail__cause">
          {handleCause()}
          <span className="my-ads__item__detail__cause__description">
            توضیحات
          </span>
          <br />
          {notApprovedModal.status &&
            notApprovedModal.data.notApprovedLog[0].description}
        </div>
      </Modal>
      <Modal
        active={showAdsModal.status}
        close={() => setShowAdsModal({ ...notApprovedModal, status: false })}
      >
        <span className="my-ads__item__detail__gallery__title">
          {showAdsModal.data.title}
        </span>
        <br />
        <img
          className="my-ads__item__detail__gallery__media"
          src={`${showAdsModal.data.image}`}
          // src={`data:image/png;base64,${showAdsModal.data.ads}`}
        />
        <span className="my-ads__item__detail__gallery__description">
          {showAdsModal.data.description}
        </span>
      </Modal>
    </Fade>
  );
};
