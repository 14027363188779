import {SVG} from "../SVG";
import './Popup.scss'
import {useState} from "react";

export const Popup = ({close,showPass = false, title = '', children}) => {

    return (
        <div id={'backdrop'} className={`backdrop ${showPass && 'active'}`} onClick={(e)=>{
            (e.target as HTMLInputElement).id ==='backdrop' && close();
        }}>
            <div className={`pop-container`}>
                <div className={'pop-container__title'}>
                    <div>
                        {title}
                    </div>
                    <div onClick={()=>close(true)}>
                        <SVG svg="times"/>
                    </div>
                </div>
                <div className={'pop-container__input'}>
                    {children}
                </div>

            </div>
        </div>

    )
}