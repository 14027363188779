import {
    AcceptRule,
    Card,
    CheckBox,
    Dots,
    Fade,
    Modal,
    PageTopbar,
    SVG,
    UpdateUserInfo,
} from "../../components";
import { useHistory, useLocation } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import {
    deleteContact,
    deleteWorkingTimeAPI,
    getAllContacts,
    getHintAPI,
    getWorkingTimesAPI,
    setWorkingTimesAPI,
    submitContact,
} from "../../utils";
import { toast } from "react-hot-toast";
import { cloneDeep, remove } from "lodash";
import DatePicker from "react-mobile-datepicker";
import { farsiDigitToEnglish } from "../../utils/Functions";
import { useUser } from "../../hooks";
import { acceptRulesAPI } from "../../utils/Services/Kartland/AcceptRules";

export const ShopInformation = () => {
    const days = [
        {
            title: "شنبه",
            times: [],
        },
        {
            title: "یکشنبه",
            times: [],
        },
        {
            title: "دوشنبه",
            times: [],
        },
        {
            title: "سه شنبه",
            times: [],
        },
        {
            title: "چهارشنبه",
            times: [],
        },
        {
            title: "پنجشنبه",
            times: [],
        },
        {
            title: "جمعه",
            times: [],
        },
    ];
    const history = useHistory();
    const location = useLocation();
    const [startDate, setStartDate] = useState();
    const [start, setStart] = useState("");
    const [end, setEnd] = useState("");
    const [confirmTimeStep, setConfirmTimeStep] = useState(0);
    const [workingTimes, setWorkingTimes] = useState([]);
    const [currentDay, setCurrentDay] = useState(-1);
    const [getTimesLoading, setGetTimesLoading] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [deletingItem, setDeletingItem] = useState<any>();
    const [confirmTime, setConfirmTime] = useState({
        start: "",
        end: "",
    });
    const [daysOfWeek, setDaysOfWeek] = useState(days);
    const [showDays, setShowDays] = useState(false);
    const [activeShowDays, setActiveShowDays] = useState([0, 1, 2, 3, 4, 5, 6]);
    const [showDaysLoading, setShowDaysLoading] = useState(false);
    // phone
    const phoneRef = useRef(null);
    const [modalStatus, toggleModalStatus] = useState(false);
    const [loadingSubmit, setLoadingSubmit] = useState(false);
    const [loading, setLoading] = useState(true);
    const [loadingDelete, setLoadingDelete] = useState({
        status: false,
        id: 0,
    });
    const [contacts, setContacts] = useState([]);

    const [acceptedRule, setAcceptedRule] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [hint, setHint] = useState<any>("");
    const [readCompleted, setreadCompleted] = useState(false);
    const [userInfo, setUserInfo] = useState({
        name: "",
        lastname: "",
        nationalCode: "",
    });
    const {
        getUserDataFromApi,
        userState: { user },
    } = useUser();
    const [showFillProfileModal, setShowFillProfileModal] = useState(false);
    const [showFillProfile, setShowFillProfile] = useState(false);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [acceptRuleLoading, setAcceptRuleLoading] = useState(false);
    const [hintLoading, setHintLoading] = useState(true);

    function useQuery() {
        const { search } = useLocation();

        return React.useMemo(() => new URLSearchParams(search), [search]);
    }

    let query = useQuery();

    const removeHandler = (id) => {
        setDeleteLoading(true);
        setDeletingItem(id);
        deleteWorkingTimeAPI(id).then((res) => {
            if (res.status === 200) {
                toast.success("زمان کاری با موفقیت حذف شد");
                setDeleteLoading(false);
                getWorkingTimes();
            }
        });
    };

    const dateConfig = {
        minute: {
            format: "mm",
            caption: "دقیقه",
            step: 1,
        },
        hour: {
            format: "hh",
            caption: "ساعت",
            step: 1,
        },
    };

    const changeDayHandler = (index) => {
        setConfirmTimeStep(1);
        setCurrentDay(index);
    };

    const getWorkingTimes = () => {
        setGetTimesLoading(true);
        getWorkingTimesAPI().then((res) => {
            if (res.status === 200) {
                let currTimes = res.data;
                let currDaysOfWeek = cloneDeep(days);
                currTimes.map((item) => {
                    let time = item.hours.split(",");
                    currDaysOfWeek[item.daysOfWeek].times.push({
                        start: time[0],
                        end: time[1],
                        id: item.id,
                    });
                });
                setDaysOfWeek(currDaysOfWeek);
                setGetTimesLoading(false);
            }
        });
    };

    const pickupDate = (date) => {
        if (confirmTimeStep === 1) {
            setConfirmTimeStep(2);
            let start = `${date.getHours() < 10 ? "0" : ""}${date.getHours()}:${
                date.getMinutes() < 10 ? "0" : ""
            }${date.getMinutes()}`;
            setStart(start);
        } else if (confirmTimeStep === 2) {
            setConfirmTimeStep(0);
            let end = `${date.getHours() < 10 ? "0" : ""}${date.getHours()}:${
                date.getMinutes() < 10 ? "0" : ""
            }${date.getMinutes()}`;
            setEnd(end);
            setShowDays(true);
        }
    };

    const setStoreTime = () => {
        setShowDaysLoading(true);
        if (start !== "" && end !== "") {
            let time = `${start},${end}`;
            setGetTimesLoading(true);
            activeShowDays.map((activeDay) => {
                setWorkingTimesAPI(activeDay, time)
                    .then((res) => {
                        if (res.status === 200) {
                            toast.success("ساعت کاری اضافه شد", {
                                id: "time added",
                            });
                            setStart("");
                            setEnd("");
                            setGetTimesLoading(false);
                            getWorkingTimes();
                        }
                    })
                    .finally(() => {
                        setActiveShowDays([0, 1, 2, 3, 4, 5, 6]);
                        setShowDays(false);
                        setShowDaysLoading(false);
                    });
            });
        }
    };

    const isInGroup = (day) => {
        return activeShowDays.some((dayItem) => {
            return dayItem === day;
        });
    };

    const handleClickActiveDays = (dayIndex) => {
        let currActiveDays = cloneDeep(activeShowDays);
        if (isInGroup(dayIndex)) {
            remove(currActiveDays, (dayItem) => {
                return dayItem === dayIndex;
            });
            setActiveShowDays(currActiveDays);
        } else {
            currActiveDays.push(dayIndex);
            setActiveShowDays(currActiveDays);
        }
    };

    const handleSubmit = () => {
        const phoneValue = phoneRef.current.value;
        if (phoneValue.length !== 11) {
            toast.error("فرمت شماره را درست وارد نمایید");
            return;
        }
        if (phoneValue == "") {
            toast.error("شماره تماس را وارد نمایید");
            return;
        }
        if (contacts.length == 5) {
            toast.error("تنها 5 شماره می‌توانید وارد کنید");
            return;
        }
        setLoadingSubmit(true);
        submitContact(phoneValue)
            .then((res) => {
                if (res) {
                    toast.success("شماره تماس با موفقیت اضافه شد");
                    getContactsNumber();
                    phoneRef.current.value = "";
                    setTimeout(() => {
                        toggleModalStatus(false);
                    }, 100);
                }
            })
            .catch((err) => {
                if (err) {
                    toast.error("به مشکل برخوردیم !");
                }
            })
            .finally(() => setLoadingSubmit(false));
    };

    const getContactsNumber = () => {
        setLoading(true);
        getAllContacts()
            .then((res) => {
                if (res) {
                    setContacts(res.data);
                }
            })
            .catch((err) => {
                if (err) {
                    toast.error("به مشکل برخوردیم !");
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleDelete = (id) => {
        setLoadingDelete({ ...loadingDelete, status: true, id: id });
        deleteContact(id)
            .then((res) => {
                if (res) {
                    toast.success("شماره تماس با موفقیت حذف شد");
                    setTimeout(() => {
                        getContactsNumber();
                    }, 500);
                }
            })
            .catch((err) => {
                if (err) toast.error("به مشکل برخوردیم");
            })
            .finally(() => {
                setLoadingDelete({ ...loadingDelete, status: false, id: id });
            });
    };

    const UserInfoHandler = () => {
        // if (userInfo.name?.length === 0 || userInfo.name === null
        //   || userInfo.lastname?.length === 0 || userInfo.lastname === null
        //   || userInfo.nationalCode?.length === 0 || userInfo.nationalCode === null || userInfo.nationalCode?.length !== 10) {
        //   setShowFillProfile(true);
        // } else {
        //   setShowFillProfile(false);
        // }
        if (
            user.name?.length === 0 ||
            user.name === null ||
            user.lastname?.length === 0 ||
            user.lastname === null ||
            user.nationalCode?.length === 0 ||
            user.nationalCode === null ||
            user.nationalCode?.length !== 10
        ) {
            setShowFillProfile(true);
        } else {
            setShowFillProfile(false);
        }
    };

    const getHint = () => {
        getHintAPI("قوانین و هشدارها 5")
            .then((response) => {
                setHint(response);
            })
            .catch(() => setHint(false))
            .finally(() => setHintLoading(false));
    };

    const acceptRules = () => {
        setAcceptRuleLoading(true);
        acceptRulesAPI()
            .then((res) => {
                if (res.status === 200) {
                    history.replace("/myshop");
                }
            })
            .catch((err) => {})
            .finally(() => {});
    };

    useEffect(() => {
        setUserInfo({
            name: user.name,
            lastname: user.lastname,
            nationalCode: user.nationalCode,
        });
        UserInfoHandler();
    }, [user]);

    useEffect(() => {
        getWorkingTimes();
        getContactsNumber();
        getHint();

        history.replace("/shop-info" + location.search);
    }, []);

    useEffect(() => {
        document
            .getElementById("buyUnitAlert")
            ?.addEventListener("scroll", () => {
                document.getElementById("buyUnitAlert").scrollTop >
                document.getElementById("buyUnitAlert")?.scrollHeight -
                    document.getElementById("buyUnitAlert")?.clientHeight -
                    20
                    ? setreadCompleted(true)
                    : setreadCompleted(false);
            });
        return () => {
            document
                .getElementById("buyUnitAlert")
                ?.removeEventListener("scroll", () => {
                    document.getElementById("buyUnitAlert").scrollTop >
                    document.getElementById("buyUnitAlert")?.scrollHeight -
                        document.getElementById("buyUnitAlert")?.clientHeight -
                        20
                        ? setreadCompleted(true)
                        : setreadCompleted(false);
                });
        };
    }, [document.getElementById("buyUnitAlert")?.scrollTop]);

    return (
        <Fade>
            <PageTopbar title={"تکمیل اطلاعات فروشگاه"} showKv={false} />
            <div className="wrapper-fix">
                <Card>
                    <UpdateUserInfo
                        showUpdate={showFillProfileModal}
                        setShowUpdate={(value) => {
                            value && acceptRules();
                            setShowFillProfileModal(false);
                        }}
                        userInfo={userInfo}
                        setUserInfo={setUserInfo}
                        getUser={getUserDataFromApi}
                        introduceStoreStatus={true}
                    />

                    <DatePicker
                        theme="ios"
                        isOpen={confirmTimeStep !== 0}
                        onCancel={() => setConfirmTimeStep(0)}
                        showCaption={true}
                        onSelect={(Date) => pickupDate(Date)}
                        dateConfig={dateConfig}
                        customHeader={
                            <span className={"pickup-header"}>
                                {confirmTimeStep === 1
                                    ? "ساعت شروع فعالیت"
                                    : "ساعت پایان فعالیت"}
                            </span>
                        }
                        confirmText="تایید"
                        cancelText={"انصراف"}
                    />

                    <Modal
                        active={showDays}
                        close={() => {
                            setShowDays(false);
                            setConfirmTimeStep(0);
                            setActiveShowDays([0, 1, 2, 3, 4, 5, 6]);
                        }}
                    >
                        <div className="creation-time__days__header">
                            تعیین روزهای هفته
                        </div>
                        {days.map((day, index) => (
                            <div
                                className={`creation-time__days__item ${
                                    isInGroup(index) && "active"
                                }`}
                                onClick={() => handleClickActiveDays(index)}
                            >
                                {isInGroup(index) && <SVG svg="create-check" />}
                                {day.title}
                            </div>
                        ))}
                        <div
                            className="button green"
                            onClick={() => !showDaysLoading && setStoreTime()}
                        >
                            {showDaysLoading ? (
                                <Dots color="light" />
                            ) : (
                                "ثبت ساعت کاری"
                            )}
                        </div>
                    </Modal>

                    <Modal
                        active={modalStatus}
                        close={() => {
                            toggleModalStatus(false);
                            phoneRef.current.value = "";
                        }}
                    >
                        <div className="creation-donate__modal">
                            <div className="creation-donate__modal__header">
                                افزودن شماره تماس
                            </div>
                            <input
                                type="tel"
                                ref={phoneRef}
                                className="input"
                                placeholder="شماره تماس خود را وارد نمایید"
                                onChange={(e) =>
                                    (phoneRef.current.value =
                                        farsiDigitToEnglish(e.target.value))
                                }
                            />
                            <div
                                onClick={() => handleSubmit()}
                                className="creation-donate__action"
                            >
                                {loadingSubmit ? (
                                    <Dots color="light" />
                                ) : (
                                    <>
                                        <span>ثبت شماره</span>
                                        <SVG svg="km-phone" />
                                    </>
                                )}
                            </div>
                        </div>
                    </Modal>

                    <Modal
                        active={showAlert}
                        close={(close) => setShowAlert(false)}
                    >
                        <div className="buy-panel__alert__title">
                            {hint.title}
                        </div>
                        <div
                            className={`buy-panel__alert__description ${
                                readCompleted && "deactive"
                            }`}
                            id={"buyUnitAlert"}
                        >
                            {hintLoading && <Dots color="dark" />}
                            {hint.content?.split("\n")?.map((str) => (
                                <div>{str}</div>
                            ))}
                        </div>
                        <div className="row buy-panel__alert__bottom">
                            <div
                                className={`buy-panel__alert__button  ${
                                    readCompleted ? "accept" : "disabled"
                                }`}
                                onClick={() => {
                                    if (readCompleted) {
                                        setAcceptedRule(true);
                                        setShowAlert(false);
                                    } else {
                                        document
                                            .getElementById("buyUnitAlert")
                                            .scrollTo(0, 2500);
                                    }
                                    // localStorage.setItem('panelSeen', 'true')
                                }}
                            >
                                قوانین را می‌پذیرم
                            </div>
                            <div
                                className="buy-panel__alert__button reject"
                                onClick={() => setShowAlert(false)}
                            >
                                انصراف
                            </div>
                        </div>
                    </Modal>

                    <div className="creation-time__title">اطلاعات تماس</div>
                    <div className="creation-time__box1">
                        <div className="creation-donate__wrapper">
                            <div
                                onClick={() => toggleModalStatus(true)}
                                className="creation-donate__condition"
                            >
                                <span>افزودن شماره تماس</span>
                                <SVG svg="km-phone" />
                            </div>
                            <div className="creation-contact">
                                {loading ? (
                                    <Dots color="dark" />
                                ) : contacts.length == 0 ? (
                                    <div className="row w-full j-c">
                                        <span>آیتمی برای نمایش وجود ندارد</span>
                                    </div>
                                ) : (
                                    contacts.map((item, index) => (
                                        <div
                                            key={index}
                                            className="creation-contact__item"
                                        >
                                            {item.num}

                                            {query.get("edit") === "true" &&
                                                (loadingDelete.status &&
                                                loadingDelete.id == item.id ? (
                                                    <Dots color="dark" />
                                                ) : (
                                                    <div
                                                        className="row a-c"
                                                        onClick={() =>
                                                            handleDelete(
                                                                item.id
                                                            )
                                                        }
                                                    >
                                                        <SVG svg="delete-contact" />
                                                    </div>
                                                ))}
                                        </div>
                                    ))
                                )}
                            </div>
                        </div>
                    </div>
                    {/*<div className="creation-time__line"></div>*/}
                    <div className="creation-time__title">
                        ساعت کاری فروشگاه
                    </div>
                    <div className="creation-time__box2">
                        <div className="creation-time__body mycity">
                            {getTimesLoading === false ? (
                                daysOfWeek.map((item, index) => (
                                    <>
                                        <div className={`row a-c`}>
                                            <div
                                                className={`creation-time__body__counter`}
                                            >
                                                <div
                                                    className={`creation-time__body__counter__circle ${
                                                        item.times.length > 0 &&
                                                        "active"
                                                    }`}
                                                >
                                                    {item.times.length > 0 ? (
                                                        <SVG svg="create-check" />
                                                    ) : (
                                                        <span>{index + 1}</span>
                                                    )}
                                                </div>
                                            </div>
                                            <div
                                                onClick={() =>
                                                    query.get("edit") ===
                                                        "true" &&
                                                    item.times.length === 0 &&
                                                    changeDayHandler(index)
                                                }
                                                className={`creation-time__body__info ${
                                                    item.times.length > 0 &&
                                                    "active"
                                                }`}
                                            >
                                                <div className="row f-sh">
                                                    <span>{item.title}</span>
                                                </div>
                                                <div className="creation-time__body__info__chips">
                                                    {item.times.length > 0 &&
                                                        item.times.map(
                                                            (
                                                                time,
                                                                timeIndex
                                                            ) => (
                                                                <div
                                                                    onClick={() =>
                                                                        query.get(
                                                                            "edit"
                                                                        ) ===
                                                                            "true" &&
                                                                        deleteLoading ===
                                                                            false &&
                                                                        removeHandler(
                                                                            time.id
                                                                        )
                                                                    }
                                                                    className="creation-time__body__info__chips__item"
                                                                >
                                                                    {deleteLoading &&
                                                                    deletingItem ===
                                                                        time.id ? (
                                                                        <Dots
                                                                            color={
                                                                                "dark"
                                                                            }
                                                                        />
                                                                    ) : (
                                                                        <span>{`${time.start} - ${time.end}`}</span>
                                                                    )}
                                                                </div>
                                                            )
                                                        )}
                                                    {query.get("edit") ===
                                                        "true" &&
                                                        item.times.length >
                                                            0 && (
                                                            <div
                                                                onClick={() =>
                                                                    changeDayHandler(
                                                                        index
                                                                    )
                                                                }
                                                                className="row a-c j-c"
                                                            >
                                                                <SVG svg="create-add" />
                                                            </div>
                                                        )}
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className={`creation-time__body__counter__mini-separator  ${
                                                item.times.length === 0 &&
                                                "hidden"
                                            }`}
                                        />
                                    </>
                                ))
                            ) : (
                                <Dots color={"dark"} />
                            )}
                        </div>
                    </div>

                    <div className="row a-c j-c" style={{ margin: "8px auto" }}>
                        <CheckBox
                            defaultValue={acceptedRule}
                            type="check"
                            onChange={(value) =>
                                setAcceptedRule(value ? true : false)
                            }
                        />
                        <AcceptRule
                            title="ثبت فروشگاه"
                            color="dark"
                            onCLick={() => setShowAlert(true)}
                        />
                    </div>

                    <div
                        className={`button ${!acceptedRule && "disable"}`}
                        onClick={() => {
                            if (!acceptedRule) {
                                toast.error(
                                    "ابتدا قوانین و مقررات ثبت فروشگاه را مطالعه و بپذیرید."
                                );
                                return;
                            }
                            if (showFillProfile) {
                                setShowFillProfileModal(true);
                            } else {
                                !acceptRuleLoading && acceptRules();
                            }
                        }}
                    >
                        {acceptRuleLoading ? (
                            <Dots color="light" />
                        ) : (
                            "ثبت فروشگاه"
                        )}
                    </div>
                </Card>
            </div>
        </Fade>
    );
};
