import {
    AcceptRule,
    Card,
    CheckBox,
    Dots,
    Fade,
    Modal,
    PageTopbar,
    Selectbox,
    SVG,
    Toggle,
    UpdateUserInfo,
} from "components";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useHistory } from "react-router-dom";
import {
    createShopAPI,
    getAllFirstCategoriesAPI,
    getCitiesAPI,
    getCountriesAPI,
    getHintAPI,
    getStatesAPI,
    getSubCitiesAPI,
    getThirdCategoryByFirstAPI,
    updateMateInfoAPI,
} from "utils";
import { checkPanelCodeAPI } from "utils/Services/API";
import { cloneDeep, remove } from "lodash";
import { useUser } from "hooks";
import CreateProviderLocation from "./dependencies/Location";
import { ImageEditor } from "components/ImageEditor";
import { farsiDigitToEnglish } from "utils/Functions";
import imageCompression from "browser-image-compression";
import { BarcodePageContainer } from "containers";
import { ImageCropper } from "../../components";
import {
    DualStoreAPI,
    GetAnotherStoreInfoAPI,
} from "utils/Services/OnlineStore";

export const CreateProviderContainer = () => {
    const user_token = localStorage.getItem("user_token");

    const [haveInternetStore, setHaveInternetStore] = useState(false);
    const [newUserDetails, setNewUserDetails] = useState({
        name: "",
    });
    const [shopDetail, setShopDetail] = useState({
        name: "",
        inviter: "",
        color: "ffffff",
        country: 1,
        state: 0,
        city: 0,
        subCity: 0,
        // district: 0,
        zeroCat: 0,
        firstCat: 0,
        secondCat: 0,
        thirdCat: [],
        lat: 0,
        lng: 0,
        address: "",
        description: "",
        image: {
            file: null,
            url: "",
        },
        showMap: 1,
    });

    const [showImageEditor, setShowImageEditor] = useState(false);

    const [newState, setNewState] = useState([]);
    const [newCities, setNewCities] = useState([]);
    const [newSubCities, setNewSubCities] = useState([]);

    const [data, setData] = useState({
        countries: [],
        states: [],
        cities: [],
        subCities: [],
        // districts: [],
    });
    const [catData, setCatData] = useState({
        firstCats: [],
        thirdCats: [],
    });
    const [submitLoading, setSubmitLoading] = useState(false);
    const [compressLoading, setCompressLoading] = useState(0);
    const history = useHistory();
    const [showAlert, setShowAlert] = useState(false);
    const [hint, setHint] = useState("");
    const [readCompleted, setreadCompleted] = useState(false);
    const [acceptedRule, setAcceptedRule] = useState(false);
    const [hintLoading, setHintLoading] = useState(true);
    const [showLocation, setShowLocation] = useState(false);
    const [activeStateName, setActiveStateName] = useState("");
    const [activeSubCityName, setActiveSubCityName] = useState("");
    const [showBarcode, setShowBarcode] = useState(false);
    const [userInfo, setUserInfo] = useState({
        name: "",
        lastname: "",
        nationalCode: "",
    });
    const {
        getUserDataFromApi,
        userState: { user },
    } = useUser();

    // const getCountries = () => {
    //     getCountriesAPI()
    //         .then((response) => {
    //             setData({ ...data, countries: response.data });
    //         })
    //         .catch(() => {
    //             toast.error("خطا در بارگذاری کشورها");
    //         })
    //         .finally(() => {});
    // };

    const getStates = (countryId) => {
        getStatesAPI(countryId)
            .then((response) => {
                setData({ ...data, states: response.data });
                setNewState(response.data);
            })
            .catch(() => toast.error("خطا در بارگذاری استان‌ها"))
            .finally(() => {});
    };

    const getCities = (stateId, callBack) => {
        getCitiesAPI(stateId)
            .then((response) => {
                setData({ ...data, cities: response?.data });
                setNewCities(response?.data);
            })
            .catch(() => toast.error("خطا در بارگذاری شهرستان‌ها"))
            .finally(() => {
                // callBack();
            });
    };

    const getSubCities = (cityId) => {
        getSubCitiesAPI(cityId)
            .then((response) => {
                setData({ ...data, subCities: response.data });
                setNewSubCities(response.data);
            })
            .catch(() => toast.error("خطا در بارگذاری شهرها"))
            .finally(() => {});
    };

    const handleSubmitStore = () => {
        if (userInfo.name?.length === 0 || userInfo.name === null) {
            toast.error("نام خود را وارد نمایید.");
            return;
        }
        if (userInfo.lastname?.length === 0 || userInfo.lastname === null) {
            toast.error("نام‌خانوادگی خود را وارد نمایید.");
            return;
        }
        if (
            userInfo.nationalCode?.length === 0 ||
            userInfo.nationalCode === null
        ) {
            toast.error("کد ملی خود را وارد نمایید.");
            return;
        }
        if (userInfo.nationalCode?.length !== 10) {
            toast.error("کد ملی خود را به درستی وارد نمایید.");
            return;
        }

        if (shopDetail.name === "") {
            toast.error("نام فروشگاه را وارد نمایید.");
            return;
        }

        if (shopDetail.description === "") {
            toast.error("توضیحات فروشگاه را وارد نمایید.");
            return;
        }

        if (shopDetail.country === 0) {
            toast.error("کشور فروشگاه خود را انتخاب کنید.");
            return;
        }

        if (shopDetail.state === 0) {
            toast.error("استان فروشگاه خود را انتخاب کنید.");
            return;
        }

        if (shopDetail.city === 0) {
            toast.error("شهرستان فروشگاه خود را انتخاب کنید.");
            return;
        }

        if (shopDetail.subCity === 0) {
            toast.error("شهر/روستا فروشگاه خود را انتخاب کنید.");
            return;
        }

        setSubmitLoading(true);

        let query = `name=${shopDetail.name}&country=${
            shopDetail.country
        }&state=${shopDetail.state}&city=${shopDetail.city}&subCity=${
            shopDetail.subCity
        }  &district=${null}&color=%23${shopDetail.color}&showMap=0`;

        var form = new FormData();
        form.append("description", shopDetail.description);
        form.append("address", shopDetail.address);

        if (
            user?.name !== null &&
            user?.lastname !== null &&
            user?.nationalCode !== null
        ) {
            createShopAPI(query, form).then((response) => {
                if (response.status === 200) {
                    toast.success("فروشگاه شما با موفقیت ثبت شد!");
                    history.push("/");
                    setSubmitLoading(false);
                }
            });
        } else {
            updateMateInfoAPI(userInfo)
                .then((response) => {
                    if (response.status === 200) {
                        createShopAPI(query, form)
                            .then((response) => {
                                if (response.status === 200) {
                                    toast.success(
                                        "فروشگاه شما با موفقیت ثبت شد!"
                                    );
                                    history.push("/");
                                    setSubmitLoading(false);
                                }
                            })
                            .catch((err) => {
                                toast.error(err.response.data.msg);
                                setSubmitLoading(false);
                            })
                            .finally(() => setSubmitLoading(false));
                    } else {
                        toast.error("مشکلی پیش آمده است!");
                        setSubmitLoading(false);
                    }
                })
                .catch((err) => {
                    if (err.response.status === 406) {
                        toast.error(
                            "برای تکمیل اطلاعات خود، باید 7 ستاره باشید."
                        );
                        setSubmitLoading(false);
                        return;
                    }
                    toast.error("خطایی در تکمیل اطلاعات پیش آمد.");
                    setSubmitLoading(false);
                });
        }
    };

    const getHint = () => {
        getHintAPI("قوانین و هشدارها 5")
            .then((response) => {
                setHint(response);
            })
            .catch(() => setHint(false))
            .finally(() => setHintLoading(false));
    };

    useEffect(() => {
        setUserInfo({
            name: user.name,
            lastname: user.lastname,
            nationalCode: user.nationalCode,
        });
    }, [user]);

    useEffect(() => {
        getStates(1);
        getHint();
    }, []);

    useEffect(() => {
        history.replace("/createprovider");
    }, []);

    useEffect(() => {
        document
            .getElementById("buyUnitAlert")
            ?.addEventListener("scroll", () => {
                document.getElementById("buyUnitAlert").scrollTop >
                document.getElementById("buyUnitAlert")?.scrollHeight -
                    document.getElementById("buyUnitAlert")?.clientHeight -
                    20
                    ? setreadCompleted(true)
                    : setreadCompleted(false);
            });
        return () => {
            document
                .getElementById("buyUnitAlert")
                ?.removeEventListener("scroll", () => {
                    document.getElementById("buyUnitAlert").scrollTop >
                    document.getElementById("buyUnitAlert")?.scrollHeight -
                        document.getElementById("buyUnitAlert")?.clientHeight -
                        20
                        ? setreadCompleted(true)
                        : setreadCompleted(false);
                });
        };
    }, [document.getElementById("buyUnitAlert")?.scrollTop]);

    useEffect(() => {
        if (user?.detail?.internetStore === true) {
            GetAnotherStoreInfoAPI().then((response) => {
                if (response.status === 200) {
                    if (response.data.internetStore != null) {
                        setHaveInternetStore(true);
                        setShopDetail({
                            ...shopDetail,
                            name: response.data.internetStore.name,
                            description:
                                response.data.internetStore.description,
                            state: response.data.internetStore.state,
                            city: response.data.internetStore.city,
                            subCity: response.data.internetStore.subCity,
                            address: response.data.internetStore.address,
                            lat: response.data.internetStore.lat,
                            lng: response.data.internetStore.lng,
                        });
                        getCities(response.data.internetStore.state, () => {
                            getSubCities(response.data.internetStore.city);
                        });
                    }
                }
            });
        }
    }, [user]);

    return (
        <Fade>
            <PageTopbar title="فرم اطلاعات تامین‌کنندگان" showKv={false} />
            {/* <Modal active={showBarcode} close={() => setShowBarcode(false)}>
        <BarcodePageContainer />
      </Modal> */}
            <div className="wrapper-fix">
                {showLocation && (
                    <CreateProviderLocation
                        close={() => setShowLocation(false)}
                        onSubmit={(res) => {
                            setShowLocation(false);
                            setShopDetail({
                                ...shopDetail,
                                lat: res.lat,
                                lng: res.lng,
                                address: res.address,
                            });
                        }}
                        entryString={`${activeStateName}، ${activeSubCityName}، `}
                        newAddress={setShopDetail}
                        jadid={shopDetail.address}
                    />
                )}
                <ImageCropper
                    active={showImageEditor}
                    onClose={(close) => {
                        setShowImageEditor(!close);
                    }}
                    onConfirm={(cropper) => {
                        console.log(cropper);
                        setShowImageEditor(false);
                        // cropper.getCroppedCanvas().toBlob((blob) => {
                        //   setShopDetail((prev) => ({
                        //     ...prev,
                        //     image: {
                        //       url: cropper.getCroppedCanvas().toDataURL().substr(22),
                        //       file: blob,
                        //     },
                        //   }));
                        // });
                        setShopDetail((prev) => ({
                            ...prev,
                            image: {
                                url: URL.createObjectURL(cropper),
                                file: cropper,
                            },
                        }));
                    }}
                />
                <Modal
                    active={showAlert}
                    close={(close) => setShowAlert(false)}
                >
                    <div className="buy-panel__alert__title">{hint.title}</div>
                    <div
                        className={`buy-panel__alert__description ${
                            readCompleted && "deactive"
                        }`}
                        id={"buyUnitAlert"}
                    >
                        {hintLoading && <Dots color="dark" />}
                        {hint.content?.split("\n")?.map((str) => (
                            <div>{str}</div>
                        ))}
                    </div>
                    <div className="row buy-panel__alert__bottom">
                        <div
                            className={`buy-panel__alert__button  ${
                                readCompleted ? "accept" : "disabled"
                            }`}
                            onClick={() => {
                                if (readCompleted) {
                                    setAcceptedRule(true);
                                    setShowAlert(false);
                                } else {
                                    document
                                        .getElementById("buyUnitAlert")
                                        .scrollTo(0, 2500);
                                }
                                // localStorage.setItem('panelSeen', 'true')
                            }}
                        >
                            قوانین را می‌پذیرم
                        </div>
                        <div
                            className="buy-panel__alert__button reject"
                            onClick={() => setShowAlert(false)}
                        >
                            انصراف
                        </div>
                    </div>
                </Modal>
                <Card>
                    <div className="title">نام</div>
                    <input
                        type="text"
                        className={`input ${user?.name && "disabled"} `}
                        value={userInfo.name}
                        onChange={(e) =>
                            setUserInfo({
                                ...userInfo,
                                name: e.target.value,
                            })
                        }
                        placeholder="نام"
                        disabled={user.name && true}
                    />

                    <div className="title">نام خانوادگی</div>
                    <input
                        type="text"
                        className={`input ${user?.lastname && "disabled"} `}
                        value={userInfo.lastname}
                        onChange={(e) =>
                            setUserInfo({
                                ...userInfo,
                                lastname: e.target.value,
                            })
                        }
                        placeholder="نام خانوادگی"
                        disabled={user.lastname && true}
                    />

                    <div className="title">کد ملی</div>
                    <input
                        type="number"
                        className={`input ${user?.nationalCode && "disabled"} `}
                        value={userInfo.nationalCode}
                        onChange={(e) =>
                            setUserInfo({
                                ...userInfo,
                                nationalCode: e.target.value,
                            })
                        }
                        placeholder="کد ملی"
                        disabled={user.nationalCode && true}
                    />

                    <div className="title">نام فروشگاه</div>
                    <input
                        type="text"
                        className="input"
                        value={shopDetail.name}
                        onChange={(e) =>
                            setShopDetail({
                                ...shopDetail,
                                name: e.target.value,
                            })
                        }
                        placeholder="نام فروشگاه"
                        disabled={user?.detail?.internetStore === true}
                    />

                    <div className="title">توضیحات فروشگاه</div>
                    <textarea
                        className="textarea"
                        rows={5}
                        value={shopDetail.description}
                        onChange={(e) =>
                            setShopDetail({
                                ...shopDetail,
                                description: e.target.value,
                            })
                        }
                        disabled={user?.detail?.internetStore === true}
                    />

                    <div className="row">
                        {shopDetail.country !== 0 && (
                            <div className="col w-100 ml-6">
                                <div className="title">استان</div>
                                <Selectbox
                                    data={data.states}
                                    placeholder="انتخاب استان"
                                    onChange={(value) => {
                                        setShopDetail({
                                            ...shopDetail,
                                            state: value,
                                        });
                                        getCities(value);
                                    }}
                                    onChangeString={(value) => {
                                        setActiveStateName(value);
                                    }}
                                    disabled={
                                        user?.detail?.internetStore === true
                                            ? true
                                            : shopDetail.country === 0
                                    }
                                    defaultValue={
                                        user?.detail?.internetStore === true
                                            ? newState.find(
                                                  (item) =>
                                                      item.id ===
                                                      shopDetail.state
                                              )?.name
                                            : ""
                                    }
                                    openAll={true}
                                />
                            </div>
                        )}

                        {shopDetail.state !== 0 && (
                            <div className="col w-100 mr-6">
                                <div className="title">شهرستان</div>
                                <Selectbox
                                    data={data.cities}
                                    placeholder="انتخاب شهرستان"
                                    onChange={(value) => {
                                        setShopDetail({
                                            ...shopDetail,
                                            city: value,
                                        });
                                        getSubCities(value);
                                    }}
                                    onChangeString={(value) => {}}
                                    disabled={
                                        user?.detail?.internetStore === true
                                            ? true
                                            : shopDetail.state === 0
                                    }
                                    defaultValue={
                                        user?.detail?.internetStore === true
                                            ? newCities.find(
                                                  (item) =>
                                                      item.id ===
                                                      shopDetail.city
                                              )?.name
                                            : ""
                                    }
                                    openAll={true}
                                />
                            </div>
                        )}
                    </div>

                    <div className="row">
                        {shopDetail.city !== 0 && (
                            <div className="col w-100 ml-6">
                                <div className="title">شهر / روستا</div>
                                <Selectbox
                                    data={data.subCities}
                                    placeholder="انتخاب شهر یا روستا"
                                    onChange={(value) => {
                                        setShopDetail({
                                            ...shopDetail,
                                            subCity: value,
                                        });
                                        setShowLocation(true);
                                    }}
                                    onChangeString={(value) => {
                                        setActiveSubCityName(value);
                                    }}
                                    disabled={
                                        user?.detail?.internetStore === true
                                            ? true
                                            : shopDetail.city === 0
                                    }
                                    defaultValue={
                                        user?.detail?.internetStore === true
                                            ? newSubCities.find(
                                                  (item) =>
                                                      item.id ===
                                                      shopDetail.subCity
                                              )?.name
                                            : ""
                                    }
                                    openAll={true}
                                />
                            </div>
                        )}
                    </div>

                    <div className="row a-c j-c" style={{ margin: "8px auto" }}>
                        <CheckBox
                            defaultValue={acceptedRule}
                            type="check"
                            onChange={(value) =>
                                setAcceptedRule(value ? true : false)
                            }
                        />
                        <AcceptRule
                            title="تامین‌کنندگان"
                            color="dark"
                            onCLick={() => setShowAlert(true)}
                        />
                    </div>
                    <div
                        className={`button ${!acceptedRule && "disable"}`}
                        onClick={() => {
                            if (!acceptedRule) {
                                toast.error(
                                    "ابتدا قوانین و مقررات ثبت فروشگاه را مطالعه و بپذیرید."
                                );
                                return;
                            } else {
                                !submitLoading && handleSubmitStore();
                            }
                            setShowBarcode(true);
                        }}
                    >
                        {submitLoading ? <Dots color="light" /> : "ثبت اطلاعات"}
                    </div>
                </Card>
            </div>
        </Fade>
    );
};
