import React, { useContext, useEffect, useState, FC } from "react";
import { Link, useLocation } from "react-router-dom";
import { SVG } from "components";
import { navigationItems } from "../../utils/data";
import "./bottom_navigation.scss";
import { useCart, useAuth, useUser } from "hooks";
import { ActiveBar } from "./Dependencies";
import ProfileImage from "../../assets/images/profile.png";

type NavigationType = {
    hidden: boolean;
};

export const BottomNavigation: FC<NavigationType> = ({ hidden }) => {
    const {
        getCartDataFromApi,
        cartState: { cart },
    } = useCart();
    const {
        getUserDataFromApi,
        getUserProfile,
        setUserImage,
        userState: { user, userImage },
    } = useUser();
    const {
        authState: { isLogged },
    } = useAuth();

    const pathName = useLocation().pathname;
    const [active, setActive] = useState("");

    useEffect(() => {
        if (isLogged) {
            getUserDataFromApi();
            getUserProfile();
            getCartDataFromApi();
        } else {
            setUserImage("");
        }
        document.getElementById("navigation").style.bottom = "0";
    }, [isLogged]);

    const handleActiveRoute = () => {
        switch (pathName) {
            case "/profile":
                return "profile";
            case "/map":
                return "city";
            case "/login":
                return "profile";
            case "/market":
                return "market";
            case "/collab-intro":
                return "home";
            case "/kartella":
                return "kartella";
            case "/kartland":
                return "kartland";
            default:
                return "";
        }
    };

    return (
        <div
            className={`bottom-navigation ${hidden && "hidden"}`}
            id="navigation"
        >
            <div className="bottom-navigation__shape">
                <SVG svg="bottom-shape" color="#ffffff" />
            </div>
            <div className="bottom-navigation__shape reverse">
                <SVG svg="bottom-shape" color="#ffffff" />
            </div>

            {navigationItems.map((item, index) => (
                <Link
                    className={`bottom-navigation__item `}
                    to={
                        item.link === "/collab-intro" && user.collaborationType
                            ? "/kartak-yar"
                            : item.link
                    }
                    key={index}
                    onClick={() => {
                        if (item.type === "market") {
                            window.scrollTo({
                                top: 0,
                                left: 0,
                                behavior: "smooth",
                            });
                        }
                    }}
                >
                    <div
                        className={`bottom-navigation__item__img ${
                            item.type === "qr" && "qr"
                        } ${handleActiveRoute() === "market" && "active"}`}
                    >
                        {item.type !== "profile" ? (
                            <SVG
                                svg={item.icon}
                                color={
                                    handleActiveRoute() === item.type
                                        ? item.type === "market"
                                            ? "#ff2800"
                                            : "#21BBE1"
                                        : "black"
                                }
                                fillColor={
                                    handleActiveRoute() === item.type
                                        ? item.type === "market"
                                            ? "#ff8974"
                                            : "#ADDDF2"
                                        : ""
                                }
                            />
                        ) : (
                            <div
                                className={`bottom-navigation__item__img__profile ${
                                    handleActiveRoute() === "profile" &&
                                    "active"
                                }`}
                            >
                                {userImage !== "" ? (
                                    <img
                                        className={`bottom-navigation__item__img__profile__pic ${
                                            handleActiveRoute() === "profile" &&
                                            "active"
                                        }`}
                                        src={`data:image/png;base64,${userImage}`}
                                        alt="profile"
                                    />
                                ) : (
                                    <SVG
                                        svg={"logo"}
                                        color={
                                            handleActiveRoute() === "profile"
                                                ? "#21BBE1"
                                                : "#DD2D2E"
                                        }
                                    />
                                )}
                                <div
                                    className={`bottom-navigation__item__title ${
                                        handleActiveRoute() === item.type &&
                                        "active"
                                    }`}
                                >
                                    کارتک‌من
                                </div>
                            </div>
                        )}
                    </div>

                    <div
                        className={`bottom-navigation__item__title ${
                            handleActiveRoute() === item.type &&
                            (item.type === "market" ? "city-active" : "active")
                        }`}
                    >
                        {item.type !== "qr" &&
                            item.type !== "profile" &&
                            item.title}
                    </div>

                    {handleActiveRoute() === item.type && (
                        <ActiveBar
                            color={
                                item.type === "market" ? "#fd6547" : "#21BBE1"
                            }
                        />
                    )}
                </Link>
            ))}
        </div>
    );
};
