import React, { useEffect, useState, useRef } from "react";
import { Spinner, Fade, SVG, CheckBox, Dots, Modal } from "components";
import KartakLogo from "../../assets/images/kartak-logo.svg";
import IranImage from "../../assets/images/iran.png";
import { Link, useHistory } from "react-router-dom";
import {
    check_otp,
    getCartAPI,
    getInviteCodes,
    login,
    loginInviterDecline,
    loginNumberCheck,
} from "utils/Services/API";
import toast from "react-hot-toast";
import { useCart, useAuth } from "hooks";
import Countdown from "react-countdown";
import {
    farsiDigitToEnglish,
    handleInputBlur,
    handleInputFocus,
} from "utils/Functions";
import WheelComponent from "react-wheel-of-prizes";
import "./wheel.css";

export const LoginContainer = () => {
    const {
        authState: { isLogged },
        onSetLoginStatus,
    } = useAuth();
    const { setCartData } = useCart();
    const [loginState, setLoginState] = useState(1);
    const [userPhoneNumber, setUserPhoneNumber] = useState("09");
    const [userOTPCode, setUserOTPCode] = useState("");
    const [loading, setLoading] = useState(false);
    const [focusInput, setFocusInput] = useState(false);
    const [focusInputCode, setFocusInputCode] = useState(false);
    const [tryAgainTimer, setTryAgainTimer] = useState(null);
    const [acceptedRole, setAcceptedRole] = useState(true);
    const [countDownTimerDone, setCountDownTimerDone] = useState(false);
    const [inviteCodeModal, setInviteCodeModal] = useState(false);
    const [invitedByModal, setInvitedByModal] = useState(false);
    const [countTimer, setCountTimer] = useState(0);
    const [tryCounter, setTryCounter] = useState(0);
    const [isNewUser, setIsNewUser] = useState(false);
    const [invitedBy, setInvitedBy] = useState(false);
    const [inviteCodeLoading, setInviteCodeLoading] = useState(false);
    const [inviterLoading, setInviterLoading] = useState({
        accept: false,
        decline: false,
    });
    const [inviteCodes, setInviteCodes] = useState([]);
    const [inviteCode, setInviteCode] = useState("");
    const [inviter, setInviter] = useState<any>({});
    const [segments, setSegments] = useState([]);
    const [disabled, setDisabled] = useState(true);
    const history = useHistory();
    const timerRef = useRef(null);

    const getRandomInt = (min, max) => {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min) + min);
    };

    const segColors = [
        "#EE4040",
        "#F0CF50",
        "#815CD1",
        "#3DA5E0",
        "#34A24F",
        "#F9AA1F",
        "#EC3F3F",
        "#FF9000",
        "#EE4040",
        "#F0CF50",
        "#815CD1",
        "#3DA5E0",
        "#34A24F",
        "#F9AA1F",
        "#EC3F3F",
        "#FF9000",
        "#F0CF50",
        "#815CD1",
        "#3DA5E0",
        "#34A24F",
    ];

    const onFinished = (winner) => {
        setDisabled(false);
        setInviteCode(winner);
    };

    const handleTimerStart = () => timerRef?.current?.start();

    useEffect(() => {
        history.replace("/login" + history.location.search);
        if (isLogged) history.replace("/profile");
    }, [isLogged]);

    useEffect(() => {
        if (inviteCode && !invitedBy) {
            const inviterPerson = inviteCodes.find(
                (obj) => obj.invite_code === inviteCode
            );
            setInviter(inviterPerson);
        }
    }, [inviteCode]);

    const getCart = (user_token) => {
        const APIResult = getCartAPI(user_token);
        APIResult.then((response) => {
            if (response.status === 200) {
                setCartData(response.data.data.cart);
            }
        });
    };

    const handleEnterPhoneNumber = (phoneNumber) => {
        if (phoneNumber.length >= 12) return;
        else setUserPhoneNumber(phoneNumber);
    };

    const handleEnterOTPCode = (otp) => {
        setUserOTPCode(otp.toString());
        if (otp.length === 5) {
            handleSubmitOTPCode(otp);
        }
    };

    const handleOtpTime = (currCount) => {
        switch (true) {
            case currCount <= 3:
                return 60;

            case currCount > 3:
                return 3600;

            default:
                break;
        }
    };

    const handleResend = () => {
        setTryCounter(tryCounter + 1);
        setCountDownTimerDone(false);
        handleTimerStart();
        handleSubmitInviteCode();
    };

    const handleRetryTime = (time) => {
        const d = Number(time);
        const h = Math.floor(d / 3600);
        const m = Math.floor((d % 3600) / 60);
        const s = Math.floor((d % 3600) % 60);
        return `${h}:${m}:${s}`;
    };

    const handleSubmitPhoneNumber = () => {
        if (userPhoneNumber.length < 11) {
            toast.error("لطفا شماره‌ موبایل خود را به درستی وارد نمایید.");
        } else if (!acceptedRole) {
            toast.error("لطفا شرایط و قوانین را بپذیرید");
        } else {
            setLoading(true);
            const serverResponse = loginNumberCheck(userPhoneNumber);
            serverResponse
                .then((response) => {
                    const count = response.data.data.count;
                    const countTime = handleOtpTime(count);

                    if (response.status === 200) {
                        setTryCounter(tryCounter + 1);
                        if (response.data.data.new_user) {
                            if (!response.data.data.invited_by) {
                                setIsNewUser(true);
                                handleGetInviteCodes();
                            } else if (response.data.data.invited_by) {
                                setIsNewUser(true);
                                setInvitedBy(true);
                                setInviter({
                                    full_name:
                                        response.data.data.invited_by.full_name,
                                    invite_code:
                                        response.data.data.invited_by
                                            .invite_code,
                                });
                                setInvitedByModal(true);
                            }
                        } else if (!response.data.data.new_user) {
                            handleSubmitInviteCode();
                        }
                    } else if (response.status === 400) {
                        toast.error("عملیات ناموفق بود!");
                    } else if (response.status === 500) {
                        toast.error("خطای سرور");
                    } else if (
                        response.data.status < 500 &&
                        response.data.status >= 400
                    ) {
                        if (response.data.status == 401) {
                            toast.error(
                                "امکان ورود شما از این درگاه وجود ندارد."
                            );
                            setLoading(false);
                            return;
                        } else if (response.data.status === 403) {
                            if (response.data.msg === "suspend") {
                                toast.error(
                                    "دسترسی شما به حساب کارتک مسدود می‌باشد. لطفا از طریق سایت کارتک با پشتیبانی در تماس باشید."
                                );
                                setLoading(false);
                                return;
                            }
                        }
                        setCountTimer(countTime * 1000);
                        const lastOtpTime =
                            Date.parse(response.data.data.time) / 1000;
                        const currTime = Math.round(
                            new Date().getTime() / 1000
                        );
                        const retryTime = handleRetryTime(
                            countTime + lastOtpTime - currTime
                        );
                        setTryAgainTimer(retryTime);
                        toast(
                            `برای ارسال مجدد کد، ${retryTime} دیگر تلاش کنید.`
                        );
                        setLoading(false);
                        return;
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    };

    const handleGetInviteCodes = async () => {
        let inviteCodesArray = [];
        const serverResponse = getInviteCodes();
        await serverResponse
            .then((response) => {
                if (response.status === 200) {
                    setInviteCodes(response.data.data);
                    for (let i = 0; i < response.data.data.length; i++) {
                        inviteCodesArray.push(
                            response.data.data[i].invite_code
                        );
                    }
                }
            })
            .then(() => {
                setSegments(inviteCodesArray);
            })
            .then(() => setLoginState(loginState + 1))
            .finally(() => setLoading(false));
    };

    const handleDeleteUserInviter = async () => {
        const serverResponse = loginInviterDecline(userPhoneNumber);

        serverResponse.then((response) => {
            if (response.status === 204) {
                toast.success("رد دعوت با موفقیت انجام شد");
            } else {
                toast.error("رد دعوت با خطا مواجه شد!");
            }
        });
    };

    const handleSubmitInviteCode = () => {
        setLoading(true);
        const serverResponse = login(userPhoneNumber, inviteCode);

        serverResponse.then((response) => {
            localStorage.setItem(
                "introduceStore",
                response.data.data.introduceStore
            );
            localStorage.setItem("storeName", response.data.data.storeName);
            const count = response.data.data.count;
            const countTime = handleOtpTime(count);
            setCountTimer(countTime * 1000);

            if (response.status === 200) {
                toast.success("کد ورود به شماره شما ارسال شد.");

                if (isNewUser && !invitedBy) {
                    setLoginState(loginState + 1);
                } else if (tryCounter === 0) {
                    setLoginState(loginState + 2);
                }
                setLoading(false);
            } else if (response.data.status === 403) {
                const lastOtpTime = Date.parse(response.data.data.time) / 1000;
                const currTime = Math.round(new Date().getTime() / 1000);
                const retryTime = handleRetryTime(
                    countTime + lastOtpTime - currTime
                );
                setTryAgainTimer(retryTime);
                toast(`برای ارسال مجدد کد، ${retryTime} دیگر تلاش کنید.`);
                setLoading(false);
                return;
            } else if (response.status === 404) {
                toast.error("کد دعوت مورد نظر یافت نشد!");
                setLoading(false);
            } else if (response.status === 500) {
                toast.error("خطای سرور!");
                setLoading(false);
            }
            handleInputBlur();
        });
    };

    const handleInviterAccept = () => {
        setInviterLoading({
            ...inviterLoading,
            accept: true,
        });
        const loginResponse = login(userPhoneNumber, inviter.invite_code);

        loginResponse
            .then((response) => {
                localStorage.setItem(
                    "introduceStore",
                    response.data.data.introduceStore
                );
                localStorage.setItem("storeName", response.data.data.storeName);
                const count = response.data.data.count;
                const countTime = handleOtpTime(count);

                if (response.status === 200) {
                    toast.success("کد ورود به شماره شما ارسال شد.");
                    setLoginState(loginState + 2);
                    setLoading(false);
                } else if (response.data.status === 403) {
                    setCountTimer(countTime * 1000);
                    const lastOtpTime =
                        Date.parse(response.data.data.time) / 1000;
                    const currTime = Math.round(new Date().getTime() / 1000);
                    const retryTime = handleRetryTime(
                        countTime + lastOtpTime - currTime
                    );
                    setTryAgainTimer(retryTime);
                    toast(`برای ارسال مجدد کد، ${retryTime} دیگر تلاش کنید.`);
                    setLoading(false);
                    return;
                } else if (response.status === 500) {
                    toast.error("خطای سرور!");
                    setLoading(false);
                }
                handleInputBlur();
            })
            .finally(() => {
                setInviterLoading({
                    ...inviterLoading,
                    accept: false,
                });
                setInvitedByModal(false);
            });
    };

    const handleInviterDecline = () => {
        setInviterLoading({
            ...inviterLoading,
            decline: true,
        });
        handleDeleteUserInviter().then(() => {
            handleGetInviteCodes().then(() => {
                setInvitedByModal(false);
                setLoading(false);
                setInviterLoading({
                    ...inviterLoading,
                    decline: false,
                });
                setLoginState(loginState + 1);
            });
        });
    };

    const handleSubmitOTPCode = (otp) => {
        if (otp.length < 5) {
            toast("کد وارد شده را تماما وارد نمایید");
        } else {
            setLoading(true);
            const serverResponse = check_otp(userPhoneNumber, otp);
            serverResponse.then((response) => {
                if (response.data.status === 400) {
                    toast.error("کد وارد شده صحیح نیست.");
                    setLoading(false);
                } else if (response.data.status === 200) {
                    getCart(response.data.data.token);
                    localStorage.setItem(
                        "user_token",
                        response.data.data.token
                    );
                    setLoading(false);
                    !isNewUser && toast.success("خوش آمدید!");
                    isNewUser &&
                        toast.success(`شما توسط ${inviter.full_name} به کارتک دعوت شدید.
                    خوش آمدید!
                    `);
                    history.push("/profile");
                    onSetLoginStatus(true);
                }
            });
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        handleSubmitPhoneNumber();
    };

    const handleCountDownTimer = (props) => {
        if (props.completed) setCountDownTimerDone(true);
        return countDownTimerDone ? (
            "ارسال مجدد"
        ) : (
            <div>
                ارسال مجدد در {props.hours}:{props.minutes}:{props.seconds}
            </div>
        );
    };

    const getPhoneNumber = (
        <div>
            <div className="login__form__title">شماره موبایل</div>
            <div className={`login__form__input ${focusInput && "focus"}`}>
                <form
                    onSubmit={handleSubmit}
                    style={{ width: "100%", height: "100%" }}
                >
                    <input
                        dir="auto"
                        type="tel"
                        value={`09${userPhoneNumber.substr(2)}`}
                        onChange={(e) =>
                            handleEnterPhoneNumber(
                                farsiDigitToEnglish(e.target.value)
                            )
                        }
                        onClick={() => window.scrollTo(0, 300)}
                        onFocus={() => {
                            handleInputFocus();
                            setFocusInput(true);
                        }}
                        onBlur={() => {
                            handleInputBlur();
                            setFocusInput(false);
                        }}
                        autoComplete="off"
                    />
                </form>

                <img src={IranImage} alt="" />
            </div>

            <div className="login__form__help">
                <CheckBox
                    defaultValue={true}
                    className="login__form__help__checkbox"
                    onChange={(value) => setAcceptedRole(value)}
                />
                <span className="login__form__help__role">
                    با ورود و ثبت نام
                    <b
                        onClick={() =>
                            history.push("/pay?https://kartaak.com/rules/")
                        }
                    >
                        {" "}
                        شرایط و قوانین{" "}
                    </b>
                    استفاده از سرویس های کارتک را می پذیرم.
                </span>
            </div>
            <button
                style={{ fontFamily: "Vazir" }}
                //disabled={tryAgainTimer !== null}
                className={`login__form__btn ${acceptedRole && "active"}`}
                onClick={() => {
                    handleSubmitPhoneNumber();
                }}
            >
                <span className={`login__form__btn__title `}>
                    ورود به کارتک
                </span>
                <div className="login__form__btn__login-icon">
                    <SVG svg="login" />
                </div>
            </button>
        </div>
    );

    const getInviteCode = (
        <div>
            <div className="login__form__title">کد دعوت</div>
            <div className={`login__form__input ${focusInputCode && "focus"}`}>
                <input
                    dir="auto"
                    type="text"
                    //placeholder="اگر کد یا شماره موبایل معرف دارید، وارد نمایید."
                    value={inviteCode}
                    onChange={(e) => setInviteCode(e.target.value)}
                    onClick={() => window.scrollTo(0, 300)}
                    onFocus={() => {
                        handleInputFocus();
                        setFocusInputCode(true);
                    }}
                    onBlur={() => {
                        handleInputBlur();
                        setFocusInputCode(false);
                    }}
                    autoComplete="off"
                />
            </div>
            <div className="login__form__desc">
                برای استفاده از امکانات کارتک باید دعوتنامه داشته باشید. اگر کد
                دعوت دارید در کادر بالا وارد کنید و در غیر اینصورت از کلید زیر
                برای دریافت دعوتنامه استفاده کنید.
            </div>
            <div
                className={`friends-invite__introduced__action ${
                    inviteCode.length !== 0 && "active"
                }`}
                onClick={() => !inviteCodeLoading && handleSubmitInviteCode()}
            >
                {inviteCodeLoading ? <Dots color="light" /> : "ثبت معرف"}
            </div>
            <div
                className={`friends-invite__introduced__action active`}
                onClick={() => !inviteCodeLoading && setInviteCodeModal(true)}
            >
                {"دریافت کد دعوت"}
            </div>

            <Modal
                active={inviteCodeModal}
                close={() => setInviteCodeModal(false)}
            >
                <div>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            paddingTop: "0px",
                            paddingBottom: "0px",
                            //marginBottom: "-50px",
                        }}
                    >
                        <WheelComponent
                            segments={segments}
                            segColors={segColors}
                            onFinished={(winner) => onFinished(winner)}
                            primaryColor="black"
                            contrastColor="white"
                            buttonText="چرخش"
                            isOnlyOnce={true}
                            size={150}
                            upDuration={getRandomInt(100, 200)}
                            downDuration={getRandomInt(200, 300)}
                            fontFamily="VazirFaNum"
                        />
                    </div>
                    <div
                        className="km__wallet__cart__sell-red-modal__action mt-20"
                        onClick={() => setInviteCodeModal(false)}
                    >
                        {disabled ? (
                            <Dots color="light" />
                        ) : (
                            <>
                                <span>ادامه</span>
                                <SVG svg="km-sell" />
                            </>
                        )}
                    </div>
                </div>
            </Modal>
        </div>
    );

    const getOTPCode = (
        <div>
            <div className="login__form__title">کد 5 رقمی ارسال شده:</div>
            <div className="login__form__otp">
                <input
                    value={userOTPCode}
                    type="tel"
                    onChange={(e) =>
                        handleEnterOTPCode(farsiDigitToEnglish(e.target.value))
                    }
                    maxLength={5}
                    autoComplete="off"
                />
            </div>

            <div className="login__form__help">
                <span className="login__form__help__role">
                    کد 5 رقمی ارسال شده به شماره موبایل
                    <b onClick={() => setLoginState(1)}> {userPhoneNumber} </b>
                    را وارد نمایید.
                </span>
            </div>

            <button
                disabled={!countDownTimerDone}
                style={{ fontFamily: "VazirFaNum" }}
                className={`login__form__btn ${
                    countDownTimerDone ? "active" : ""
                }`}
                onClick={() => handleResend()}
                //onClick={() => (countDownTimerDone ? handleResend() : null)}
            >
                <span className="login__form__btn__title">
                    <Countdown
                        autoStart={true}
                        ref={timerRef}
                        date={Date.now() + countTimer}
                        renderer={(props) => handleCountDownTimer(props)}
                        //onComplete={() => setCountDownTimerDone(true)}
                    />
                </span>
                <div className="login__form__btn__login-icon">
                    <SVG svg="try-again" />
                </div>
            </button>
        </div>
    );

    return (
        <>
            <Modal active={invitedByModal} close={() => ""} closeBtn={false}>
                <div>
                    <div style={{ textAlign: "center", fontWeight: 500 }}>
                        شما توسط{" "}
                        <span style={{ fontWeight: 700, color: "red" }}>
                            {inviter?.full_name}
                        </span>{" "}
                        به کارتک دعوت شده‌اید. در صورت تمایل به ادامه‌ی ثبت‌نام
                        با این معرف روی دکمه‌ی "تایید" بزنید و در غیر اینصورت
                        دکمه‌ی "رد" را فشار دهید.
                    </div>
                    <div style={{ display: "flex", gap: "1rem" }}>
                        <div
                            style={{
                                backgroundColor: "rgb(23, 199, 167)",
                                marginLeft: "0",
                            }}
                            className="km__wallet__cart__sell-red-modal__action mt-20"
                            onClick={() => {
                                handleInviterAccept();
                            }}
                        >
                            {inviterLoading.accept ? (
                                <Dots color="light" />
                            ) : (
                                <>
                                    <span>تایید</span>
                                </>
                            )}
                        </div>
                        <div
                            style={{
                                backgroundColor: "#eb4d4c",
                                marginLeft: "0",
                            }}
                            className="km__wallet__cart__sell-red-modal__action mt-20"
                            onClick={() => {
                                handleInviterDecline();
                            }}
                        >
                            {inviterLoading.decline ? (
                                <Dots color="light" />
                            ) : (
                                <>
                                    <span>رد</span>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </Modal>

            <div className="login__header">
                <img src={KartakLogo} alt="" className="login__header__logo" />
                <div className="login__header__title">ورود به کارتک</div>
                <div className="login__header__patern"></div>
                <div className="login__header__patern2"></div>
            </div>
            <div className="login__form">
                {loginState === 1 && getPhoneNumber}
                {loginState === 2 && getInviteCode}
                {loginState === 3 && getOTPCode}
            </div>
            <Spinner active={loading} color="dark" />
        </>
    );
};
