import { useState, useEffect } from "react";
import { SVG, KV, PageTopbar, Padding, Fade } from "components";
import { Line } from "rc-progress";
import QRCode from "react-qr-code";
import { useHistory } from "react-router-dom";
import { NotFound, Header, QrCodeBox } from "./dependencies";

export const QrGeneratorContainer = () => {
  const history = useHistory();
  const [color, setColor] = useState("#000000");
  const [showNotFound, setShowNotFound] = useState(false);

  useEffect(() => {
    history.replace("/qr-generator");
  }, []);

  return (
    <Fade>
      <div className="generator"> 
        {/* <Header setColor={setColor} /> */}
        {showNotFound ? (
          <NotFound setNotFound={setShowNotFound}/>
        ) : (
          <QrCodeBox color={color} setNotFound={setShowNotFound} />
        )}
      </div>
    </Fade>
  );
};
