import { CheckBox, SVG, Selectbox } from "components";
import { CreateAdsContext } from "containers";
import { AppContext } from "context/AppContext";
import React, { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import {
    fillProfileAPI,
    getCitiesAPI,
    getCountriesAPI,
    getDistrictsAPI,
    getStatesAPI,
} from "utils/Services/API";

const FillCustomCity = ({ close }) => {
    const user_token = localStorage.getItem("user_token");

    const createAds = useContext(CreateAdsContext);

    const [countryId, setCountryId] = useState(0);
    const [countryValue, setCountryValue] = useState("");
    const [countries, setCountries] = useState([]);

    const [stateId, setStateId] = useState(0);
    const [stateValue, setStateValue] = useState("");
    const [states, setStates] = useState([]);

    const [cityId, setCityId] = useState(0);
    const [cityValue, setCityValue] = useState("");
    const [cities, setCities] = useState([]);
    const [resetCity, setResetCity] = useState(false);

    const [districtId, setDistrictId] = useState(0);
    const [districtValue, setDistrictValue] = useState("");
    const [districts, setDistricts] = useState([]);
    const [resetDistrict, setResetDistrict] = useState(false);

    const handleActivateSubmit = () => {
        // if (countryId === 0 || stateId === 0 || cityId === 0)
        //   return false;
        // else
        return true;
    };

    const handleSubmitForm = () => {
        if (handleActivateSubmit()) {
            const apiRequest =
                districtId === 0
                    ? {
                          countryId: countryId,
                          countryValue: countryValue,
                          stateId: stateId,
                          stateValue: stateValue,
                          cityId: cityId,
                          cityValue: cityValue,
                          districtId: districtId,
                          districtValue: districtValue,
                      }
                    : {
                          countryId: countryId,
                          countryValue: countryValue,
                          stateId: stateId,
                          stateValue: stateValue,
                          cityId: cityId,
                          cityValue: cityValue,
                          districtId: districtId,
                          districtValue: districtValue,
                      };

            createAds.detail.set((prev) => ({
                ...prev,
                address: apiRequest,
            }));

            close(true);
        } else {
            if (countryId === 0) toast.error("کشور خود را انتخاب نمایید.");
            else if (stateId === 0) toast.error("استان خود را انتخاب نمایید.");
            else if (cityId === 0) toast.error("شهر خود را انتخاب نمایید.");
        }
    };

    const getCountries = () => {
        const APIResult = getCountriesAPI(user_token);
        APIResult.then((response) => {
            setCountries(response.data.data);
        });
    };

    const getStates = () => {
        const APIResult = getStatesAPI(user_token);
        APIResult.then((response) => {
            setStates(response.data.data);
        });
    };

    const getCities = (entryState) => {
        const APIResult = getCitiesAPI(user_token, entryState);
        APIResult.then((response) => {
            setCities(response.data?.data);
        });
    };

    const getDistricts = (entryCity) => {
        const APIResult = getDistrictsAPI(user_token, entryCity);
        APIResult.then((response) => {
            setDistricts(response.data?.data);
        });
    };

    useEffect(() => {
        stateId !== 0 && setResetCity(true);
    }, [stateId]);

    useEffect(() => {
        cityId !== 0 && setResetDistrict(true);
    }, [cityId]);

    useEffect(() => {
        getCountries();
        getStates();
    }, []);

    return (
        <div className="fill-profile__form">
            <div className="row g-16">
                <div className="col w-100">
                    <div className="fill-profile__form__title">
                        <span className="required" />
                        کشور
                    </div>

                    <Selectbox
                        data={countries}
                        placeholder="انتخاب کشور"
                        onChange={(value) => setCountryId(value)}
                        onChangeString={(value) => setCountryValue(value)}
                        defaultValue={
                            createAds.detail.get.address.countryValue ===
                            "تعیین نشده"
                                ? ""
                                : createAds.detail.get.address.countryValue
                        }
                    />
                </div>

                <div className="col w-100">
                    <div className="fill-profile__form__title">استان</div>

                    <Selectbox
                        data={states}
                        placeholder="انتخاب استان"
                        onChange={(value) => {
                            setStateId(value);
                            getCities(value);
                        }}
                        onChangeString={(value) => setStateValue(value)}
                        defaultValue={createAds.detail.get.address.stateValue}
                        onClickBox={() =>
                            setTimeout(() => {
                                document
                                    .getElementById("fill-profile-container")
                                    .scrollTo(0, 120);
                            }, 170)
                        }
                    />
                </div>
            </div>

            <div className="row g-16">
                <div className="col w-100">
                    <div className="fill-profile__form__title">شهر</div>

                    <Selectbox
                        data={cities}
                        placeholder="انتخاب شهر"
                        onChange={(value) => {
                            setCityId(value);
                            getDistricts(value);
                            setResetCity(false);
                        }}
                        resetValue={resetCity}
                        disabled={stateId === 0 && true}
                        onChangeString={(value) => setCityValue(value)}
                        defaultValue={createAds.detail.get.address.cityValue}
                        onClickBox={() =>
                            setTimeout(() => {
                                document
                                    .getElementById("fill-profile-container")
                                    .scrollTo(0, 235);
                            }, 195)
                        }
                    />
                </div>

                <div className="col w-100">
                    <div className="fill-profile__form__title">
                        روستا / محله
                    </div>

                    <Selectbox
                        data={districts}
                        placeholder="انتخاب روستا یا محله"
                        onChange={(value) => {
                            setDistrictId(value);
                            setResetDistrict(false);
                        }}
                        disabled={cityId === 0 && true}
                        resetValue={resetDistrict}
                        onChangeString={(value) => setDistrictValue(value)}
                        defaultValue={
                            createAds.detail.get.address.districtValue
                        }
                    />
                </div>
            </div>

            <div
                className={`fill-profile__form__button ${
                    handleActivateSubmit() && "active"
                }`}
                onClick={() => handleSubmitForm()}
            >
                <div className="fill-profile__form__button__shape">
                    <SVG
                        svg="bottom-shape"
                        color={handleActivateSubmit() ? "#21bbe1" : "#b3bdcc"}
                    />
                </div>
                ثبت
                <div className="fill-profile__form__button__star">
                    <SVG svg="profile-star" />
                </div>
            </div>
        </div>
    );
};

export default FillCustomCity;
