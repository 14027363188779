import { SVG } from "components";
import KartakLogo from "../../assets/images/kartak-logo.svg";
import { useHistory } from "react-router-dom";
import {useEffect} from "react";

export const GuideContainer = ({ children }) => {
  const history = useHistory();

  useEffect(() => {
    history.replace("/guide");
  }, []);

  return (
    <div className="guide">
      <div className="guide__wrapper">
        <img src={KartakLogo} alt="" className="guide__logo" />
        <span className="guide__title">
          وب اپلیکیشن کارتک را به صفحه ی اصلی موبایل خود اضافه کنید !
        </span>
        <div className="guide__list">
          <div className="guide__list__item">
            <span>۱. در نوار پایین روی دکمه ی Share بزنید.</span>
            <SVG svg="ios-share" />
          </div>
          <div className="guide__list__item plus">
            <span>
              ۲. در منوی باز شده در قسمت پایین گزینه ی Add to Home Screen را
              انتخاب کنید.
            </span>
            <SVG svg="ios-plus" />
          </div>
          <div className="guide__list__item">
            <span>۳. در مرحله ی بعد در قسمت بالا روی Add بزنید.</span>
            <SVG svg="ios-add" />
          </div>
        </div>
      </div>
      <div onClick={() => history.push("/")} className="guide__submit">
        <span>متوجه شدم</span>
        <SVG svg="arrow" />
      </div>
    </div>
  );
};
