import axios from "axios";

export const devIp = process.env.REACT_APP_DEV_IP;
export const apiVersion = process.env.REACT_APP_API_VERSION;
export const apiVersion3 = process.env.REACT_APP_API_VERSION3;
export const apiVersion4 = process.env.REACT_APP_API_VERSION4;

const endPoints = {
    login: `${apiVersion}/auth/login`,
    loginNumberCheck: `${apiVersion}/auth/is-new-user`,
    loginInviterDecline: `${apiVersion}/auth/remove-inviter`,
    check_otp: `${apiVersion}/auth/verify`,
    requestUpdate: `${apiVersion}/auth/request_update`,
    setPresenter: `${apiVersion}/auth/user/setPresenter?invite_code=`,
    getUserFriends: `${apiVersion}/auth/user/friends`,
    getInviteCodes: `${apiVersion4}/invite-codes`,
    getUserInfo: `${apiVersion}/auth/user/detail`,
    setUserInfo: `${apiVersion}/auth/user/update`,
    setUserImage: `${apiVersion}/auth/user/photo/update`,
    getUserImage: `${apiVersion}/auth/user/photo`,
    getUserAddresses: `${apiVersion}/auth/address`,
    updateUserAddresses: `${apiVersion}/auth/address`,
    deleteUserAddress: `${apiVersion}/auth/address/`,
    setUserQuestion: `${apiVersion}/messenger/send?massage=`,
    getUserQuestions: `${apiVersion}/messenger`,
    getQrDetail: `${apiVersion}/QrBarcode/scanner?barcode=`,
    saveAds: `${apiVersion}/advertise/advertise_save?advertise_id=`,
    submitAds: `${apiVersion}/advertise/give_advertise_km`,
    savedAds: `${apiVersion}/advertise/saved_advertise_list`,
    categories: `${apiVersion}/shop/category`,
    product: `${apiVersion4}/admin-store/shop/products`,
    specialProducts: `${apiVersion4}/admin-store/shop/products/products-special-customer`,
    isFavorite: `${apiVersion}/shop/favorite/isFavorite`,
    favorite: `${apiVersion3}/adminStore/shop/favorite`,
    featureGroupProducts: `${apiVersion}/shop/product/featureGroup`,
    addComment: `${apiVersion3}/adminStore/shop/cm/push`,
    likeComment: `${apiVersion3}/adminStore/shop/cm/like`,
    dislikeComment: `${apiVersion3}/adminStore/shop/cm/dislike`,
    cart: `${apiVersion4}/admin-store/shop/cart`,
    profile: `${apiVersion}/user/profile/`,
    countries: `${apiVersion}/country`,
    states: `${apiVersion}/state`,
    inventory: `${apiVersion}/user/inventory`,
    cities: `${apiVersion}/city?stateId=`,
    districts: `${apiVersion}/districts?cityId=`,
    lotterySlider: `${apiVersion}/lottery/slider`,
    lotteryActive: `${apiVersion}/lottery?type=noArchive&paginate=10`,
    lotteryArchive: `${apiVersion}/lottery?type=archive&paginate=10`,
    userLotteries: `${apiVersion}/lottery/winner`,
    getQr: `${apiVersion}/QrBarcode`,
    getSlider: `${apiVersion}/shop/slider`,
    createAds: `${apiVersion}/advertise`,
    checkPanelCode: `${apiVersion}/advertise/checkPanelCode?panelCode=`,
};

export const getUserInfo = async (token) => {
    const result = await axios({
        method: "GET",
        url: devIp.concat(endPoints.getUserInfo),
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error.response;
        });
    return result;
};

export const getInviteCodes = async () => {
    const result = await axios({
        method: "GET",
        url: devIp.concat(endPoints.getInviteCodes),
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error.response;
        });
    return result;
};

export const setUserInfo = async (token, firstName, lastName, email) => {
    const result = await axios({
        method: "POST",
        url: devIp.concat(endPoints.setUserInfo),
        headers: {
            Authorization: `Bearer ${token}`,
        },
        data: {
            name: firstName,
            family: lastName,
            email: email,
        },
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error.response;
        });
    return result;
};

export const updateUserImage = async (token, image) => {
    var formdata = new FormData();
    formdata.append("image", image);

    const result = await axios({
        method: "POST",
        url: devIp.concat(endPoints.setUserImage),
        headers: {
            Authorization: `Bearer ${token}`,
        },
        data: formdata,
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error.response;
        });
    return result;
};

export const createAdsAPI = async (token, formData) => {
    const result = await axios({
        method: "POST",
        url: devIp.concat(endPoints.createAds),
        headers: {
            Authorization: `Bearer ${token}`,
        },
        data: formData,
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error.response;
        });
    return result;
};

export const createMessageAPI = async (token, formData) => {
    const result = await axios({
        method: "POST",
        url: devIp.concat(`api/v3/kartland/customerClubMessage/administration`),
        headers: {
            Authorization: `Bearer ${token}`,
        },
        data: formData,
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error.response;
        });
    return result;
};
//TODO : add endpoint

export const requestUpdateAPI = async (token) => {
    const result = await axios({
        method: "POST",
        url: devIp.concat(endPoints.requestUpdate),
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error.response;
        });
    return result;
};

export const getUserImage = async (token) => {
    const result = await axios({
        method: "GET",
        url: devIp.concat(endPoints.getUserImage),
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error.response;
        });
    return result;
};

export const getUserAddresses = async (token) => {
    const result = await axios({
        method: "GET",
        url: devIp.concat(endPoints.getUserAddresses),
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error.response;
        });
    return result;
};

export const getQrAPI = async (token) => {
    const result = await axios({
        method: "GET",
        url: devIp.concat(endPoints.getQr),
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error.response;
        });
    return result;
};

export const createQrAPI = async (token) => {
    const result = await axios({
        method: "POST",
        url: devIp.concat(endPoints.getQr),
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error.response;
        });
    return result;
};

export const createUserAddressAPI = async (token, addressData) => {
    const result = await axios({
        method: "POST",
        url: devIp.concat(endPoints.getUserAddresses),
        headers: {
            Authorization: `Bearer ${token}`,
        },
        data: addressData,
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error.response;
        });
    return result;
};
export const updateUserAddressAPI = async (token, id, addressData) => {
    const result = await axios({
        method: "PUT",
        url: devIp.concat(endPoints.updateUserAddresses + `/${id}`),
        headers: {
            Authorization: `Bearer ${token}`,
        },
        data: addressData,
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error.response;
        });
    return result;
};

export const deleteUserAddress = async (token, addrewssId) => {
    const result = await axios({
        method: "DELETE",
        url: devIp.concat(endPoints.deleteUserAddress + addrewssId),
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error.response;
        });
    return result;
};

export const login = async (phoneNumber, inviteCode) => {
    const result = await axios({
        method: "POST",
        url: devIp.concat(endPoints.login),
        data: {
            mobile: phoneNumber,
            invite_code: inviteCode,
        },
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error.response;
        });
    return result;
};

export const loginNumberCheck = async (phoneNumber) => {
    const result = await axios({
        method: "POST",
        url: devIp.concat(endPoints.loginNumberCheck),
        data: {
            mobile_number: phoneNumber,
        },
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error.response;
        });
    return result;
};

export const loginInviterDecline = async (phoneNumber) => {
    const result = await axios({
        method: "POST",
        url: devIp.concat(endPoints.loginInviterDecline),
        data: {
            mobile_number: phoneNumber,
        },
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error.response;
        });
    return result;
};

export const check_otp = async (phoneNumber, otp) => {
    const result = await axios({
        method: "POST",
        url: devIp.concat(endPoints.check_otp),
        data: {
            mobile: phoneNumber,
            otp: otp,
        },
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error.response;
        });
    return result;
};

export const getSliderAIP = async () => {
    const result = await axios({
        method: "GET",
        url: devIp.concat(endPoints.getSlider),
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error.response;
        });
    return result;
};

export const setPresenterAPI = async (token, inviteCode) => {
    const result = await axios({
        method: "POST",
        url: devIp.concat(endPoints.setPresenter + inviteCode),
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error.response;
        });
    return result;
};

export const checkPanelCodeAPI = async (token, inviteCode) => {
    const result = await axios({
        method: "GET",
        url: devIp.concat(endPoints.checkPanelCode + inviteCode),
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error.response;
        });
    return result;
};

export const getUserFriends = async (token) => {
    const result = await axios({
        method: "GET",
        url: devIp.concat(endPoints.getUserFriends),
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error.response;
        });
    return result;
};

export const setUserQuestion = async (token, message) => {
    const result = await axios({
        method: "POST",
        url: devIp.concat(endPoints.setUserQuestion + message),
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error.response;
        });
    return result;
};

export const getUserQuestions = async (token) => {
    const result = await axios({
        method: "GET",
        url: devIp.concat(endPoints.getUserQuestions),
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error.response;
        });
    return result;
};

export const checkQrCode = async (token, qr) => {
    const result = await axios({
        method: "GET",
        url: devIp.concat(endPoints.getQrDetail + qr),
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error.response;
        });
    return result;
};

export const saveAds = async (token, adsId) => {
    const result = await axios({
        method: "POST",
        url: devIp.concat(endPoints.saveAds + adsId),
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error.response;
        });
    return result;
};

export const submitAds = async (token, backCode, frontCode, adsId) => {
    const result = await axios({
        method: "POST",
        url: devIp.concat(endPoints.submitAds),
        headers: {
            Authorization: `Bearer ${token}`,
        },
        data: {
            advertise_id: adsId,
            b: backCode,
            f: frontCode,
        },
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error.response;
        });
    return result;
};

export const getSavedAds = async (token) => {
    const result = await axios({
        method: "POST",
        url: devIp.concat(endPoints.savedAds),
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error.response;
        });
    return result;
};

export const getCategoriesAPI = async () => {
    const result = await axios({
        method: "GET",
        url: devIp.concat(endPoints.categories),
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error.response;
        });
    return result;
};

export const updateAdsAPI = async (token, adsDetail, adsImage) => {
    let formData = new FormData();
    formData.append("image", adsImage);
    formData.append("_method", "PUT");
    const result = await axios({
        method: "POST",
        url: devIp.concat(
            endPoints.createAds +
                `/${adsDetail.id}?title=${adsDetail.title}&catId=${adsDetail.category}&url=${adsDetail.url}&description=${adsDetail.description}&address=${adsDetail.address}&phoneNumber=${adsDetail.phoneNumber}&mobileNumber=${adsDetail.mobileNumber}`
        ),
        headers: {
            Authorization: `Bearer ${token}`,
        },
        data: adsImage ? formData : { image: "", _method: "PUT" },
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error.response;
        });
    return result;
};

export const getProductsAPI = async ({
    pagination = 10,
    category = "",
    query = "",
    brand = "",
    sort = "",
    page = 1,
    special = false,
}) => {
    const result = await axios({
        method: "GET",
        url: devIp.concat(
            endPoints.product +
                `?paginate=${pagination}&category_id=${category}&q=${query}&brand_id=${brand}&sortby=${sort}&page=${page}&special=${
                    special ? 1 : 0
                }`
        ),
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error.response;
        });
    return result;
};

export const getSpecialProductsAPI = async (categoryId = "") => {
    const result = await axios({
        method: "GET",
        url: devIp.concat(
            endPoints.specialProducts + `?category_id=${categoryId}`
        ),
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error.response;
        });
    return result;
};

export const getProductAPI = async (productId) => {
    const result = await axios({
        method: "GET",
        url: devIp.concat(endPoints.product + "/" + productId),
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error.response;
        });
    return result;
};

export const isFavriteAPI = async (token, productId) => {
    const result = await axios({
        method: "GET",
        url: devIp.concat(endPoints.favorite + "/" + productId),
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error.response;
        });
    return result;
};

export const addFavoriteAPI = async (token, productId) => {
    const result = await axios({
        method: "POST",
        url: devIp.concat(endPoints.favorite + "/" + productId),
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error.response;
        });
    return result;
};

export const getFavoritesAPI = async (token) => {
    const result = await axios({
        method: "GET",
        url: devIp.concat(endPoints.favorite),
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error.response;
        });
    return result;
};

export const getFeatureGroupProductsAPI = async (featureGroupId) => {
    const result = await axios({
        method: "GET",
        url: devIp.concat(
            endPoints.featureGroupProducts + "/" + featureGroupId
        ),
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error.response;
        });
    return result;
};

export const addCommentAPI = async (token, commentData) => {
    const result = await axios({
        method: "POST",
        url: endPoints.addComment,
        data: commentData,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error.response;
        });

    return result;
};

export const likeCommentAPI = async (commentId, token) => {
    const result = await axios({
        method: "POST",
        url: endPoints.likeComment + "/" + commentId,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error.response;
        });
    return result;
};

export const dislikeCommentAPI = async (commentId, token) => {
    const result = await axios({
        method: "POST",
        url: endPoints.dislikeComment + "/" + commentId,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error.response;
        });
    return result;
};

export const addToCartAPI = async (
    token,
    productId,
    variationId,
    productUnit,
    userId
) => {
    const result = await axios({
        method: "POST",
        url: devIp.concat(endPoints.cart + "/" + productId + "/" + variationId),
        data: { product_unit: productUnit, user: userId },
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error.response;
        });
    return result;
};

export const removeFromCartAPI = async (token, cartId, cartItemId) => {
    const result = await axios({
        method: "POST",
        url: devIp.concat(
            endPoints.cart + "/" + cartId + "/drop/" + cartItemId
        ),
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error.response;
        });
    return result;
};

export const incrementCartAPI = async (token, cartId, cartItemId) => {
    const result = await axios({
        method: "POST",
        url: devIp.concat(
            endPoints.cart + "/" + cartId + "/increment/" + cartItemId
        ),
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error.response;
        });
    return result;
};

export const decrementCartAPI = async (token, cartId, cartItemId) => {
    const result = await axios({
        method: "POST",
        url: devIp.concat(
            endPoints.cart + "/" + cartId + "/decrement/" + cartItemId
        ),
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error.response;
        });
    return result;
};

export const getCartAPI = async (token) => {
    const result = await axios({
        method: "GET",
        url: devIp.concat(endPoints.cart + "?filter=active"),
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error.response;
        });
    return result;
};

export const updateCartAddressAPI = async (token, cartId, addressId) => {
    try {
        const result = await axios({
            method: "POST",
            url: `${devIp}${endPoints.cart}/${cartId}/address/${addressId}`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return result;
    } catch (error) {
        return error.response;
    }
};

export const getCateogryParentAPI = async (data, token) => {
    const url = `${devIp}${endPoints.categories}/parent?parent_id=${data.parent_id}`;
    try {
        const response = await axios({
            method: "GET",
            url: url,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        return error.response;
    }
};

export const getPaymentTokenAPI = async (token, cartId) => {
    const url = `${devIp}${endPoints.cart}/${cartId}/getToken`;
    try {
        const response = await axios({
            method: "GET",
            url: url,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response;
    } catch (error) {
        return error.response;
    }
};

export const deleteUserCartAddress = async (token, cartId) => {
    try {
        const response = axios({
            method: "DELETE",
            url: `${devIp}${endPoints.cart}/${cartId}/address`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response;
    } catch (error) {
        return error.response;
    }
};

export const updateCartGatewayAPI = async (token, cartId, gatewayId) => {
    try {
        const result = await axios({
            method: "POST",
            url: `${devIp}${endPoints.cart}/${cartId}/gateway/${gatewayId}`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return result;
    } catch (error) {
        return error.response;
    }
};

export const getPaymentUrl = (cartId, paymentToken) => {
    return `${devIp}${endPoints.cart}/${cartId}/payment/${paymentToken}`;
};

export const fillProfileAPI = async (token, type, data) => {
    const result = await axios({
        method: "POST",
        url: devIp.concat(endPoints.profile + type),
        headers: {
            Authorization: `Bearer ${token}`,
        },
        data: data,
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error.response;
        });
    return result;
};

export const getInventory = async (token, type) => {
    const result = await axios({
        method: "GET",
        url: devIp.concat(endPoints.inventory + `/${type}`),
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error.response;
        });
    return result;
};

export const getFreelanceJobTypeAPI = async (token, type) => {
    const result = await axios({
        method: "GET",
        url: devIp.concat(
            endPoints.inventory + `/freelanceJob/type?freelanceJobId=${type}`
        ),
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error.response;
        });
    return result;
};

export const getCountriesAPI = async (token) => {
    const result = await axios({
        method: "GET",
        url: devIp.concat(endPoints.countries),
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error.response;
        });
    return result;
};

export const getStatesAPI = async (token) => {
    const result = await axios({
        method: "GET",
        url: devIp.concat(endPoints.states),
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error.response;
        });
    return result;
};

export const getCitiesAPI = async (token, stateId) => {
    const result = await axios({
        method: "GET",
        url: devIp.concat(endPoints.cities + stateId),
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error.response;
        });
    return result;
};

export const getDistrictsAPI = async (token, cityId) => {
    const result = await axios({
        method: "GET",
        url: devIp.concat(endPoints.districts + cityId),
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error.response;
        });
    return result;
};

export const getLotterySliderAPI = async () => {
    let url = `${devIp}${endPoints.lotterySlider}`;
    try {
        const response = await axios({
            method: "GET",
            url: url,
        });
        return response;
    } catch (error) {
        return error.response;
    }
};

export const getActiveLotteryAPI = async (token) => {
    let url = `${devIp}${endPoints.lotteryActive}`;
    try {
        const response = await axios({
            method: "GET",
            url: url,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response;
    } catch (error) {
        return error.response;
    }
};

export const getArchiveLotteryAPI = async (token) => {
    let url = `${devIp}${endPoints.lotteryArchive}`;
    try {
        const response = await axios({
            method: "GET",
            url: url,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response;
    } catch (error) {
        return error.response;
    }
};

export const getWinnerLottriesById = async (token, id) => {
    let url = `${devIp}${endPoints.userLotteries}/${id}`;
    try {
        const response = await axios({
            method: "GET",
            url: url,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response;
    } catch (error) {
        return error.response;
    }
};
