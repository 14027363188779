import { Card, Dots, Fade, PageTopbar } from "components";
import React, { useEffect, useState } from "react";
import { getAllSlRlAPI } from "utils";
import SlRlLog from "../dependencies/SlRlLog";

export const RlLogContainer = () => {
  const [slRlLogs, setSlRlLogs] = useState([]);
  const [loading, setLoading] = useState(true);

  const getSlRlLogs = () => {
    getAllSlRlAPI()
      .then((response) => {
        if (response.data !== null) {
          setSlRlLogs(response.data);
        }
      })
      .catch((err) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getSlRlLogs();
  }, []);

  return (
    <Fade>
      <PageTopbar title="تاریخچه پاداش" />
      <Card>
        {loading ? (
          <Dots color="dark" />
        ) : (
          <div className="slrl__logs">
            {slRlLogs.map((slrl) => (
              <SlRlLog type="rl" data={slrl} />
            ))}
          </div>
        )}{" "}
      </Card>
    </Fade>
  );
};
