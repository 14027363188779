import React from "react";
import {Padding} from "components";
import "./Loading.scss";

export const ProfileLoading = ({ loading = true }) => {
  return (
    <div className={`profile-loading ${!loading && "deactive"}`}>
      <Padding>
        <div className="profile-loading__header block"></div>
        <div className="profile-loading__km block"></div>
        <div className="profile-loading__kartak">
          <div className="block"></div>
          <div className="block"></div>
          <div className="block"></div>
        </div>
        <div className="profile-loading__menu block"></div>
      </Padding>
    </div>
  );
};
