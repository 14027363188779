import {NewLotteryContainer} from 'containers';

const NewLottery = () => {
  return (
    <NewLotteryContainer />
  );
}

export default NewLottery;

// import { NewLotteryContainer } from "containers";

// const NewLottery = ({ children }) => {
//   return <NewLotteryContainer>{children}</NewLotteryContainer>;
// };

// export default NewLottery;