import { SVG, Selectbox, CheckBox } from "components";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { fillProfileAPI, getInventory } from "utils/Services/API";
import newStarSound from "../../../assets/sounds/star.mp3";
import { useUser } from "hooks";

const FillProfileEducation = ({ close }) => {
    const user_token = localStorage.getItem("user_token");
    const {
        userState: { user },
    } = useUser();
    const [studyId, setStudyId] = useState(0);
    const [studyField, setStudyField] = useState("");
    const [studies, setStudies] = useState([]);

    const [degree, setDegree] = useState(0);
    const [degrees] = useState([
        {
            name: "زیر دیپلم",
            id: 1,
        },
        {
            name: "دیپلم",
            id: 2,
        },
        {
            name: "فوق دیپلم",
            id: 3,
        },
        {
            name: "لیسانس",
            id: 4,
        },
        {
            name: "فوق لیسانس",
            id: 5,
        },
        {
            name: "دکتری",
            id: 6,
        },
        {
            name: "بالاتر",
            id: 7,
        },
    ]);

    const [status, setStatus] = useState(0);

    const [audio] = useState(new Audio(newStarSound));

    const handleActivateSubmit = () => {
        if (status === 0 || degree === 0)
            // if (status === 0 || degree === 0 || (studyId === 0 && studyField.length === 0)) FOR STUDY REQ
            return false;
        else return true;
    };

    const handleSubmitForm = () => {
        if (handleActivateSubmit()) {
            const apiRequest =
                studyId > 0
                    ? {
                          educationStatus: status,
                          educationDegree: degree,
                          studyField: studyId,
                      }
                    : {
                          educationStatus: status,
                          educationDegree: degree,
                          studyField: studyField,
                      };
            const APIResult = fillProfileAPI(
                user_token,
                "education",
                apiRequest
            );
            APIResult.then((response) => {
                if (response.status === 200) {
                    toast.success("اطلاعات ستاره ثبت شد.");
                    if (user.detail.isSound) {
                        audio.play();
                    }
                    close(true);
                }
            });
        } else {
            if (status === 0) toast.error("وضعیت تحصیل خود را مشخص نمایید.");
            else if (degree === 0)
                toast.error("مدرک تحصیلی خود را مشخص نمایید.");
            else if (studyId === 0)
                toast.error("رشته‌ی تحصیلی خود را مشخص نمایید.");
        }
    };

    const getStudies = () => {
        const APIResult = getInventory(user_token, "fieldOfStudy");
        APIResult.then((response) => {
            setStudies(response.data.data);
        });
    };

    useEffect(() => {
        getStudies();
    }, []);

    return (
        <div className={"fill-profile__form"}>
            <audio src={newStarSound} autoPlay={false}></audio>

            <div className="row">
                <div className="row" style={{ marginLeft: 16 }}>
                    <CheckBox
                        disable
                        defaultValue={status === 1 && true}
                        onChange={(value) => {
                            if (value) {
                                setStatus(1);
                            }
                        }}
                    />
                    <div className="fill-profile__form__title">درحال تحصیل</div>
                </div>

                <div className="row">
                    <CheckBox
                        disable
                        defaultValue={status === 2 && true}
                        onChange={(value) => {
                            if (value) {
                                setStatus(2);
                            }
                        }}
                    />
                    <div className="fill-profile__form__title">
                        فارغ التحصیل
                    </div>
                </div>
            </div>

            <div className={`${"fill-profile__form__title"}`}>
                <span className={"required"} />
                مدرک تحصیلی
            </div>

            <Selectbox
                openAll
                data={degrees}
                placeholder="انتخاب مدرک تحصیلی"
                onChange={(value) => setDegree(value)}
            />

            <div className="fill-profile__form__title">
                {/* <span className="required" /> */}
                رشته‌ی تحصیلی
            </div>

            <Selectbox
                openAll
                data={studies}
                placeholder="انتخاب رشته‌ی تحصیلی"
                onChange={(value) => {
                    setStudyId(value);
                    // setStudyField('');
                }}
                onChangeString={(value) => setStudyField(value)}
            />
            <div className="input__hint" style={{ marginTop: "-15px" }}>
                (در صورتی که رشته تحصیلی شما در لیست نبود، آن را دستی وارد
                نمایید.)
            </div>

            <div
                className={`fill-profile__form__button ${
                    handleActivateSubmit() && "active"
                }`}
                onClick={() => handleSubmitForm()}
            >
                <div className="fill-profile__form__button__shape">
                    <SVG
                        svg="bottom-shape"
                        color={handleActivateSubmit() ? "#21bbe1" : "#b3bdcc"}
                    />
                </div>
                ثبت
                <div className="fill-profile__form__button__star">
                    <SVG svg="profile-star" />
                </div>
            </div>
        </div>
    );
};

export default FillProfileEducation;
