import React, { useEffect, useState } from "react";
import { PageTopbar, SVG, Fade, Dots, Card, Modal } from "components";
import { useLocation, useHistory } from "react-router-dom";
import DatePicker from "react-mobile-datepicker";
import { cloneDeep, remove } from "lodash";
import { setWorkingTimesAPI, getWorkingTimesAPI, deleteWorkingTimeAPI } from "../../../../utils";
import { toast } from "react-hot-toast";


const days = [
  {
    title: "شنبه",
    times: []
  }, {
    title: "یکشنبه",
    times: []
  }, {
    title: "دوشنبه",
    times: []
  }, {
    title: "سه شنبه",
    times: []
  }, {
    title: "چهارشنبه",
    times: []
  }, {
    title: "پنجشنبه",
    times: []
  }, {
    title: "جمعه",
    times: []
  },
];

export const CreationTime = ({ children }) => {
  const history = useHistory();
  const location = useLocation();
  const [startDate, setStartDate] = useState();
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const [confirmTimeStep, setConfirmTimeStep] = useState(0);
  const [workingTimes, setWorkingTimes] = useState([]);
  const [currentDay, setCurrentDay] = useState(-1);
  const [getTimesLoading, setGetTimesLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deletingItem, setDeletingItem] = useState<any>();
  const [confirmTime, setConfirmTime] = useState({
    start: '',
    end: '',
  });
  const [daysOfWeek, setDaysOfWeek] = useState(days);
  const [showDays, setShowDays] = useState(false);
  const [activeShowDays, setActiveShowDays] = useState([0, 1, 2, 3, 4, 5, 6]);
  const [showDaysLoading, setShowDaysLoading] = useState(false);

  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  let query = useQuery();

  const removeHandler = (id) => {
    setDeleteLoading(true);
    setDeletingItem(id);
    deleteWorkingTimeAPI(id).then(res => {
      if (res.status === 200) {
        toast.success("زمان کاری با موفقیت حذف شد");
        setDeleteLoading(false);
        getWorkingTimes();
      }
    });
  };

  const dateConfig = {
    minute: {
      format: "mm",
      caption: "دقیقه",
      step: 1,
    },
    hour: {
      format: "hh",
      caption: "ساعت",
      step: 1,
    },
  };

  const changeDayHandler = (index) => {
    setConfirmTimeStep(1);
    setCurrentDay(index);
  };

  const getWorkingTimes = () => {
    setGetTimesLoading(true);
    getWorkingTimesAPI().then(res => {
      if (res.status === 200) {
        let currTimes = res.data;
        let currDaysOfWeek = cloneDeep(days);
        currTimes.map((item) => {
          let time = item.hours.split(",");
          currDaysOfWeek[item.daysOfWeek].times.push({ start: time[0], end: time[1], id: item.id });
        }
        );
        setDaysOfWeek(currDaysOfWeek);
        setGetTimesLoading(false);
      }
    });
  };

  const pickupDate = (date) => {
    if (confirmTimeStep === 1) {
      setConfirmTimeStep(2);
      let start = `${date.getHours() < 10 ? '0' : ''}${date.getHours()}:${date.getMinutes() < 10 ? '0' : ''}${date.getMinutes()}`;
      setStart(start);
    } else if (confirmTimeStep === 2) {
      setConfirmTimeStep(0);
      let end = `${date.getHours() < 10 ? '0' : ''}${date.getHours()}:${date.getMinutes() < 10 ? '0' : ''}${date.getMinutes()}`;
      setEnd(end);
      setShowDays(true);
    }
  };

  const setStoreTime = () => {
    setShowDaysLoading(true);
    if (start !== "" && end !== "") {
      let time = `${start},${end}`;
      setGetTimesLoading(true);
      activeShowDays.map(activeDay => {
        setWorkingTimesAPI(activeDay, time)
          .then(res => {
            if (res.status === 200) {
              toast.success("ساعت کاری اضافه شد", { id: 'time added' });
              setStart("");
              setEnd("");
              setGetTimesLoading(false);
              getWorkingTimes();
            }
          })
          .finally(() => {
            setActiveShowDays([0, 1, 2, 3, 4, 5, 6]);
            setShowDays(false);
            setShowDaysLoading(false);
          });
      })
    }
  }

  const isInGroup = (day) => {
    return activeShowDays.some((dayItem) => {
      return dayItem === day;
    });
  };

  const handleClickActiveDays = dayIndex => {
    let currActiveDays = cloneDeep(activeShowDays);
    if (isInGroup(dayIndex)) {
      remove(currActiveDays, (dayItem) => {
        return dayItem === dayIndex;
      });
      setActiveShowDays(currActiveDays);
    } else {
      currActiveDays.push(dayIndex);
      setActiveShowDays(currActiveDays);
    }
  }

  useEffect(() => {
    getWorkingTimes();
    history.replace("/myshop/timeplan" + location.search);
  }, []);

  return (
    <Fade>
      <PageTopbar title="ساعات کاری" showKv={false} />
      <Card>
        <DatePicker
          theme="ios"
          isOpen={confirmTimeStep !== 0}
          onCancel={() => setConfirmTimeStep(0)}
          showCaption={true}
          onSelect={(Date) => pickupDate(Date)}
          dateConfig={dateConfig}
          customHeader={
            <span className={'pickup-header'}>
              {confirmTimeStep === 1 ?
                'ساعت شروع فعالیت'
                :
                'ساعت پایان فعالیت'
              }
            </span>
          }
          confirmText="تایید"
          cancelText={'انصراف'}
        />

        <Modal active={showDays} close={() => { setShowDays(false); setConfirmTimeStep(0); setActiveShowDays([0, 1, 2, 3, 4, 5, 6]); }}>
          <div className="creation-time__days__header">
            تعیین روزهای هفته
          </div>
          {days.map((day, index) => (
            <div className={`creation-time__days__item ${isInGroup(index) && 'active'}`} onClick={() => handleClickActiveDays(index)}>
              {isInGroup(index) && (
                <SVG svg="create-check" />
              )}
              {day.title}
            </div>
          ))}
          <div className="button green" onClick={() => !showDaysLoading && setStoreTime()}>
            {showDaysLoading ? (
              <Dots color="light" />
            ) : (
              'ثبت ساعت کاری'
            )}
          </div>
        </Modal>

        <div className="creation-time__body mycity wrapper-fix">
          {getTimesLoading === false ? (daysOfWeek.map((item, index) => (
            <>
              <div className={`row a-c`}>
                <div className={`creation-time__body__counter`}>
                  <div
                    className={`creation-time__body__counter__circle ${item.times.length > 0 && "active"
                      }`}
                  >
                    {item.times.length > 0 ? (
                      <SVG svg="create-check" />
                    ) : (
                      <span>{index + 1}</span>
                    )}
                  </div>
                </div>
                <div
                  onClick={() => query.get('edit') === 'true' && item.times.length === 0 && changeDayHandler(index)}
                  className={`creation-time__body__info ${item.times.length > 0 && "active"
                    }`}
                >
                  <div className="row f-sh">
                    <span>{item.title}</span>
                  </div>
                  <div className="creation-time__body__info__chips">
                    {item.times.length > 0 && item.times.map((time, timeIndex) => (
                      <div
                        onClick={() => query.get('edit') === 'true' && deleteLoading === false && removeHandler(time.id)}
                        className="creation-time__body__info__chips__item">
                        {(deleteLoading && deletingItem === time.id) ? (
                          <Dots color={"dark"} />
                        )
                          : (<span>{`${time.start} - ${time.end}`}</span>)
                        }

                      </div>
                    ))}
                    {query.get('edit') === 'true' && item.times.length > 0 &&
                      <div onClick={() => changeDayHandler(index)} className="row a-c j-c">
                        <SVG svg="create-add" />
                      </div>}
                  </div>
                </div>
              </div>
              <div
                className={`creation-time__body__counter__mini-separator  ${item.times.length === 0 && "hidden"
                  }`}
              ></div>
            </>
          ))) : (
            <Dots color={"dark"} />
          )}
        </div>

        {/*<div className="creation-time__action">*/}
        {/*  <span>ثبت ساعات کاری و ادامه</span>*/}
        {/*  <SVG svg="arrow"/>*/}
        {/*</div>*/}
      </Card>
    </Fade>
  );
};
